import React, { Component } from 'react';

import { Form, Button, Message, Modal } from 'semantic-ui-react';
import intl from 'react-intl-universal';
import CSV from 'comma-separated-values'

export default class LabelImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      loading: true,
      importError: null,
    };
  }

  handleCsvSubmit = async () => {
    if (!this.state.file) return;
    const { onImport } = this.props

    const reader = new FileReader()

    reader.onload = function (e) {
      const csvText = e.target.result

      const parsedCsv = new CSV(csvText, { cast: ['String', 'String', 'String', 'String'] }).parse()
      
      const r = parseCsv(parsedCsv)

      // remove header line
      r.shift()
      onImport(r)
    }
    reader.readAsText(this.state.file[0])

  }

  render() {
    const { importError } = this.props;
    const importMessage = importError && importError.length > 0 ? (
      <Message negative>{importError.map((e, i) => <li key={ i }>{ e }</li>)}</Message>
    ) : null;

    return (
      <div style={{ cursor: 'pointer' }}>
        <Modal
          open={this.props.showImportDialog}
          centered
        >
          <Modal.Header>
            {intl.get('_project.label.ImportTitle')}
          </Modal.Header>
          <Modal.Content>
            {importMessage}
            <Form>
              <Form.Input
                type="file"
                id="csv"
                name="csv"
                accept=".csv"
                onChange={e => { this.setState({ file: e.target.files }) }}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.props.onShowImportDialog(false)}>{intl.get('_project.label.Cancel')}</Button>
            <Button positive onClick={() => this.handleCsvSubmit()}>{intl.get('_project.label.Import')}</Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

function parseCsv(csv) {
  const formParts = []
  csv.forEach(row => {
    const [c1, c2, c3, c4] = row
    switch (c2) {
      case 'bbox':
      case 'polygon':
        const bbox = { id: genId(), name: c1, type: c2 }
        formParts.push(bbox)
        break
      case 'text':
        const text = { id: genId(), name: c1, type: c2, allowedList: c3, prompt: c4 }
        formParts.push(text)
        break
      case 'select-one':
      case 'select':
        const options = c3 ? c3.split(',') : []
        const select = { id: genId(), name: c1, type: c2, options }
        formParts.push(select)
        break
      default:
        const def = { id: genId(), name: c1, type: c2 }
        formParts.push(def)
    }
  })
  return formParts
}

function genId() {
  return Math.random()
    .toString(36)
    .substring(2, 9);
}
