import React, { Component } from 'react';
import { debounce, difference, isEmpty } from 'lodash';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/monokai.css';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/mode/javascript/javascript';
// import 'codemirror/addon/hint/show-hint.css';
// import 'codemirror/addon/hint/show-hint.js';
// import 'codemirror/addon/hint/javascript-hint.js';
import 'codemirror/keymap/sublime';
import {
  Button,
  Form,
  Message,
  Icon,
  Divider,
  Header,
} from 'semantic-ui-react';
import api from '../../api';
import { GEN_TEST_PARAMS, INIT_CODE } from './DefaultData';

export default class MappingFunctionEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      codeVal: INIT_CODE,
      project: {},
      metadata: {},
      rstVal: {},
      verifyWarnings: [],
      isTesting: false,
      testParams: {},
    };

    this.initAll = this.initAll.bind(this);
    this.getProject = this.getProject.bind(this);
    this.getMetaData = this.getMetaData.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleTest = this.handleTest.bind(this);
    this.verifyParsingResult = this.verifyParsingResult.bind(this);
    this.handleMapFunc = this.handleMapFunc.bind(this);
    this.initTestParams = this.initTestParams.bind(this);
  }

  async componentDidMount() {
    await this.initAll();
  }

  async initAll() {
    const project = await this.getProject()
    await this.getMetaData();
    await this.initTestParams(project);
  }

  async getMetaData() {
    const { projectId } = this.props;
    const { metadata } = (
      await api.get(`/dppapi/admin/project/prediction/query?projectId=${projectId}`)
    ).data;

    this.setState({
      metadata,
    });

    return metadata;
  }

  async getProject() {
    const { projectId } = this.props;
    const project = (await api.get('/dppapi/projects/' + projectId)).data;
    

    this.setState({
      project,
      codeVal: project.parsingFunction || INIT_CODE,
    });

    return project;
  }

  verifyParsingResult(rstVal) {
    const { metadata } = this.state;
    const warnings = [];

    try {
      // 1. rstVal should include scrumMaps field
      if (!rstVal['scrumMaps']) {
        warnings.push('Parsing result should include scrumMaps field.');
        return warnings;
      }

      const scrumMaps = rstVal['scrumMaps'];
      for (let m of metadata) {
        // 2. rstVal should include every page field at metadata defined
        if (!Object.keys(scrumMaps).includes(m.name)) {
          warnings.push(`Parsing result should include page [${m.name}].`);
          return warnings;
        }

        // 3. rstVal should include every columns field at metadata defined
        const rowColumns = Object.keys(scrumMaps[m.name][0] || {});
        const columns = m['columns'].map((c) => c.name);
        const difColumns = difference(columns, rowColumns);
        if (!isEmpty(difColumns)) {
          warnings.push(
            `Parsing result should include columes [${difColumns}] at page [${m.name}].`
          );
          return warnings;
        }

        // 4. colume type validation
        const columnsIsSelectBbox = [];
        for (let column of m['columns']) {
          if (column['type'] === 'selectBbox') {
            let tempColumn = scrumMaps[m.name][0][column['name']];
            if (
              !tempColumn.hasOwnProperty('id') ||
              !tempColumn.hasOwnProperty('text')
            ) {
              columnsIsSelectBbox.push(column['name']);
            }
          }
        }
        if (!isEmpty(columnsIsSelectBbox)) {
          warnings.push(
            `The following columns [${columnsIsSelectBbox}] at page [${m.name}] is the 'selectBbox' type which should include 'id' and 'text' properties.`
          );
          return warnings;
        }
      }
    } catch (e) {
      console.error(e);
      return ['parsing result is invalid!'];
    }
  }

  handleSave() {
    const { projectId } = this.props;
    const { project, codeVal } = this.state;
    const p = Object.assign({}, project, {
      parsingFunction: codeVal,
    });
    api.patch('/dppapi/projects/' + projectId, { project: p });
  }

  async initTestParams(project) {
    const dbTestParams = JSON.parse(!isEmpty(project.testParams) ? project.testParams : '{}')

    const testParams = {
      getCurrentImageId: GEN_TEST_PARAMS['genGetCurrentImageId'](dbTestParams['currentImageId']),
      getPdfImages: GEN_TEST_PARAMS['genGetPdfImages'](dbTestParams['pdfImages']),
      getImageBBoxes: GEN_TEST_PARAMS['genGetImageBBoxes'](dbTestParams['imageBBoxes']),
      getPrediction: GEN_TEST_PARAMS['genGetPrediction'](dbTestParams['prediction']),
      getMetadataByCategory: GEN_TEST_PARAMS['genGetMetadataByCategory'](dbTestParams['metadata']),
    }

    this.setState({
      project,
      testParams
    });
  }

  async handleTest() {
    const { testParams } = this.state;
    this.setState({
      isTesting: true,
    });
    const rstVal = await this.handleMapFunc(testParams);
    const warning = this.verifyParsingResult(rstVal);
    this.setState({
      verifyWarnings: warning,
      rstVal: rstVal,
      isTesting: false,
    });
  }

  async handleMapFunc(argus) {
    let rstVal = {};
    try {
      // eslint-disable-next-line 
      const mappingFunction = new Function('return ' + this.state.codeVal)();
      rstVal = await mappingFunction(argus);
    } catch {
      rstVal = 'Unvalid code!';
    }
    return rstVal;
  }

  render() {
    const {
      rstVal,
      project,
      isTesting,
      verifyWarnings,
    } = this.state;
    const { parsingFunction } = { ...project };

    return (
      <React.Fragment>
        {!isEmpty(verifyWarnings) && <Message warning list={verifyWarnings} />}
        <Form>
          <Form.Field>
            <Button.Group>
              <Button onClick={this.handleTest} basic={true} color="orange">
                <Icon name="bug" loading={isTesting} />
                Test
              </Button>
              <Button onClick={this.handleSave} basic={true} color="black">
                <Icon name="save" />
                Save
              </Button>
            </Button.Group>
          </Form.Field>
          <Divider />
          <Form.Field>
            <label>Parsing Function</label>
            <CodeMirror
              value={parsingFunction || INIT_CODE}
              options={{
                mode: { name: 'javascript', json: true },
                theme: 'monokai',
                // extraKeys: { Ctrl: 'autocomplete' },
                lineNumbers: true,
                // matchBrackets: true,
                tabSize: 2,
                keyMap: 'sublime',
              }}
              editorDidMount={(editor) => {
                editor.setSize('100%', '500px');
              }}
              onChange={debounce((editor, data, value) => {
                this.setState({
                  codeVal: value,
                  verifyWarnings: [],
                });
              }, 300)}
            />
            <Divider horizontal>
              <Header as="h4">
                <Icon name="caret square right outline" />
                Output
              </Header>
            </Divider>
            <CodeMirror
              value={JSON.stringify(rstVal)}
              options={{
                mode: { name: 'javascript', json: true },
                theme: 'monokai',
                // extraKeys: { Ctrl: 'autocomplete' },
                lineNumbers: true,
                // matchBrackets: true,
                tabSize: 2,
                // keyMap: 'sublime',
                readOnly: true,
                lineWrapping: true,
              }}
              editorDidMount={(editor) => {
                editor.setSize('100%', '300px');
              }}
            />
          </Form.Field>
        </Form>
      </React.Fragment>
    );
  }
}
