import React, { useContext } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import MyContext from '../context-manager'
import ExtraInfoFactory from '../project/result-function/ExtraInfoFactory'

export default function Dialog() {
  // const [open, setOpen] = React.useState(false)
  const { commonDialog, handleOperateDialog } = useContext(MyContext)
  const { open, onlyCancel, title, type, msgs, onOk, onCancel, extraInfo, pdfId, projectId, callback, pdfInfo, actions } = commonDialog
  return (
    <Modal
      // onClose={() => handleOperateDialog({ open: false })}
      // onOpen={() => setOpen(true)}
      open={open}
      size={'small'}
    >
      <Modal.Header>{title}</Modal.Header>
      {msgs && msgs.length > 0 ?
        <Modal.Content>
          <Modal.Description>
            {msgs.map((msg, idx) => <p key={idx}>{msg}</p>)}
          </Modal.Description>
        </Modal.Content> : null}

      {extraInfo && extraInfo.length > 0 ?
        <Modal.Content scrolling>
          <Modal.Description>
            {ExtraInfoFactory(extraInfo, handleOperateDialog, pdfId, projectId, callback, type, pdfInfo, actions)}
          </Modal.Description>
        </Modal.Content> : null}
      {onOk && onOk ? <Modal.Actions>
        <Button className='primary-btn' onClick={async () => {
          if (typeof (onCancel) === 'function') { 
            await onCancel()
          }
          await handleOperateDialog({ open: false })
        }}> 閉じる</Button>
        {!onlyCancel ?
          <Button onClick={async () => {
            await onOk()
            await handleOperateDialog({ open: false })
          }} positive>
            確定
          </Button> : null
        }
      </Modal.Actions> : null}
    </Modal>
  )
}

