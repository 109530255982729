import React, { Component } from 'react';
import { Segment, Menu } from 'semantic-ui-react';
import MappingFunctionEditor from './MappingFunctionEditor';
import ParamEditor from './ParamsEditor'

export default class MappingFunction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: 'Function'
    }

    this.activeMenu = this.activeMenu.bind(this)
  }

  activeMenu(item) {
    this.setState({
      active: item
    })
  }

  render() {
    const { active } = this.state
    return (
      <Segment>
        <Menu
          vertical
          tabular
          style={{ position: 'absolute', left: '-100px', width: '100px' }}
        >
          <Menu.Item
            name="Function"
            active={active === 'Function'}
            onClick={() => {this.activeMenu('Function')}}
          />
          <Menu.Item
            name="Parameters"
            active={active === 'Parameters'}
            onClick={() => {this.activeMenu('Parameters')}}
          />
        </Menu>
        {active === 'Function' && <MappingFunctionEditor projectId={this.props.projectId} />}
        {active === 'Parameters' && <ParamEditor projectId={this.props.projectId} />}
      </Segment>
    );
  }
}
