import React from 'react';
import { Button, Input, Dropdown } from 'semantic-ui-react';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import intl from 'react-intl-universal';
import { CalenarIcon, CancelNoCircleIcon } from '../../assets/icon';
// import api from '../../api';
import { AnalyticsServiceContext } from '../../common/Analytics/AnalyticsServiceProvider';
import { EventName, EventType, PageName } from '../../common/Analytics/analyticsMetrics';
// import { getOptions } from '../Constant';

export default class Conditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: '',
      startDate: moment().add(-90, 'd').toDate(),
      endDate: moment().toDate(),
      creator: '',
      operator: '',
      status: ''
    };
    this.searchCondition = this.searchCondition.bind(this);
    this.handleClickSearchButton = this.handleClickSearchButton.bind(this)
  }
  static contextType = AnalyticsServiceContext;

  async componentDidMount() {
    this.props.getProjectMessage(this.state)
  }

  onClear = () => {
    this.setState({
      fileName: '',
      startDate: moment().add(-90, 'd').toDate(),
      endDate: moment().toDate(),
      creator: '',
      operator: '',
      status: ''
    })
  }

  onChange = (type, value) => {
    this.setState({ [type]: value },() => {this.props.getProjectMessage(this.state)});
  };

  getOptionsArr = () => {
    let searchStatusArr = [];
    const {options, res} = this.state;
    if (res && res.data && res.data.success === true && res.data.value && res.data.value.length > 0) {
      const statusArr = res.data.value.split(',');

      // remove  2: 'AI処理中'  state because after useing queue to reslove pdf, there was no longer a state === 2 situation 
      statusArr.forEach((v, i) => {
        if (i === 0 ){ 
          searchStatusArr.push({key: `${i + 1}`, value: `${i + 1}`, text: `${v}&${statusArr[i+1]}`})

        } else if(i !== 1)  { 
          searchStatusArr.push({key: `${i + 1}`, value: `${i + 1}`, text: v})

        }
      });
    } else {
      searchStatusArr = options
    }
    return searchStatusArr;
  }

  searchCondition = () => {
    const { fileName, startDate, endDate, creator, operator, status } = this.state;
    this.props.onSearch({
      fileName,
      startDate,
      endDate,
      creator,
      operator,
      status
    });
  }

  handleClickSearchButton = () => {
    const {  startDate, endDate, creator, operator, status} = this.state;
    this.searchCondition();
    const selectedStatus = this.props.statusOptions.find((option) => option.key === status.toString())
    let statusText = "";
    if(selectedStatus !== undefined){
      statusText = selectedStatus.text
    }
    this.context.sendMetrics({
      page_name: PageName.PredictPage,
      event_type: EventType.Action,
      event_name: EventName.SearchFile,
      custom_parameter: {
        start_of_upload_date : startDate,
        end_of_upload_date : endDate,
        status : statusText,
        uploader_id : creator,
        operator_id : operator,
      },
    })
  }

  render() {
    const { users, managers, projectId, editLoading, statusOptions } = this.props;
    const { fileName, startDate, endDate, creator, operator, status } = this.state;
    return (
      <>
        <div className="predict-admin-search-conditions-panel">
          <div className="predict-admin-title">決算書検索一覧</div>
          <div className="predict-admin-search-conditions-list">
            <div className="predict-admin-search-condition-first-floor">
              <div className="predict-admin-search-condition-input-fileName">
                <div className="predict-admin-input-label">{intl.get('_predict.admin.PdfSearch.Filename')}</div>
                <Input
                  fluid
                  className="predict-admin-input-content"
                  placeholder={intl.get('_predict.admin.PdfSearch.Pleaseentername')}
                  onChange={e => this.onChange('fileName', e.target.value)}
                  value={fileName}
                />
              </div>
              <div className="predict-admin-search-condition-input-upload-date">
                <div className="predict-admin-input-label">{intl.get('_predict.admin.PdfSearch.Uploadtime')}</div>
                <div className="predict-admin-search-datepicker-warp">
                  <DatePicker
                    className="predict-admin-search-datepicker"
                    onChange={date => this.onChange('startDate', date)}
                    value={startDate}
                    format="y/MM/dd"
                    calendarIcon={<CalenarIcon />}
                    clearIcon={<CancelNoCircleIcon />}
                  />
                  <div className="predict-admin-search-condition-label">
                    -
                  </div>
                  <DatePicker
                    className="predict-admin-search-datepicker"
                    onChange={date => this.onChange('endDate', date)}
                    value={endDate}
                    format="y/MM/dd"
                    calendarIcon={<CalenarIcon />}
                    clearIcon={<CancelNoCircleIcon />}
                  />
                </div>
              </div>
              <div className="predict-admin-search-condition-input-status">
                <div className="predict-admin-input-label">{intl.get('_predict.admin.PdfSearch.Processingstate')}</div>
                <Dropdown
                  fluid
                  clearable
                  options={statusOptions||[]}
                  selection
                  className="predict-admin-input-content select-pagetype"
                  onChange={(e, data) => this.onChange('status', data.value)}
                  noResultsMessage = {intl.get('_predict.admin.PdfSearch.Noresults')}
                  value={status} />
              </div>
            </div>
            <div className="predict-admin-search-condition">
              <div className="predict-admin-search-condition-input">
                <div className="predict-admin-input-label">{intl.get('_predict.admin.PdfSearch.Author')}</div>
                <Dropdown
                  fluid
                  clearable
                  options={users}
                  selection
                  search
                  className="predict-admin-input-content select-pagetype"
                  onChange={(e, data) => this.onChange('creator', data.value)}
                  noResultsMessage = {intl.get('_predict.admin.PdfSearch.Noresults')}
                  value={creator} />
              </div>
              <div className="predict-admin-search-condition-input">
                <div className="predict-admin-input-label">{intl.get('_predict.admin.PdfSearch.Personincharge')}</div>
                <Dropdown
                  fluid
                  clearable
                  options={managers}
                  selection
                  search
                  className="predict-admin-input-content select-pagetype"
                  onChange={(e, data) => this.onChange('operator', data.value)}
                  noResultsMessage = {intl.get('_predict.admin.PdfSearch.Noresults')}
                  value={operator} />
              </div>
              <div className="predict-admin-search-condition-btngroup">
                <Button
                  disabled={ projectId.length === 0 || editLoading }
                  className={projectId.length === 0 ? "primary-btn-disabled confirmAlert-button-right" : "primary-btn confirmAlert-button-right"}
                  onClick={() => this.handleClickSearchButton()}>
                  {intl.get('_predict.admin.PdfSearch.Search')}
                </Button>
                <Button id="ga-predict-list-condition-clear" className="predict-admin-botton-text confirmAlert-button-left" onClick={() => this.onClear()}>
                  {"条件クリア"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
