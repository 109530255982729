import React, { Component } from 'react';
import './index.css';

export default class ZoomPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };

    this.handleShow = this.handleShow.bind(this);
  }

  handleShow(flag) {
    this.setState({ show: flag });
  }

  scrollToBottom = () => {
    this.popupEnd.scrollIntoView({ block: 'center' });
  };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    // this.scrollToBottom();
  }

  render() {
    const {
      content,
      trigger,
      offset = [30, 0],
      onOpen = () => {},
      width = 250,
      cursor = 'pointer',
      styles = {},
      classNames = '',
      onClose = true,
    } = this.props;
    const { show } = this.state;

    const triggerChildren = (
      <div className="zoom-popup-wrapper">
        {trigger.props.children}
        <div
          className={`zoom-popup-container ${classNames}`}
          style={{
            position: 'absolute',
            left: offset[1],
            top: offset[0],
            display: show ? 'block' : 'none',
            width: width,
            ...styles,
          }}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {onClose && (
            <div className="leaflet-container">
              <a className="leaflet-popup-close-button" href="#close"
                onClick={e => {
                  e.stopPropagation();
                  this.handleShow(false);
                }}
              >×</a>
            </div>
          )}
          {content}
          <div
            style={{ float: 'right', clear: 'both' }}
            ref={el => {
              this.popupEnd = el;
            }}
          />
        </div>
      </div>
    );

    const triggerBrother = React.cloneElement(trigger, {
      children: triggerChildren,
      style: { ...trigger.props.style, cursor: cursor },
      onClick: e => {
        if (!show) {
          e.stopPropagation();
          onOpen();
        }

        this.handleShow(!show && !this.props.isLink);
        trigger.props.onClick && trigger.props.onClick();
      },
    });

    return <React.Fragment>{triggerBrother}</React.Fragment>;
  }
}
