import React, { Component } from 'react';
import {
  withRouter,
} from 'react-router-dom';
import api from './api';

export const ROOT_URI = '/dppui'

class HistoryListenerClass extends Component {

  async componentDidMount() {

    const info = this.props.location.pathname !== '/login' ? (await api.get(`/dppapi/info`)).data : null;

    if(window.location.href.indexOf('login') < 0 && !info)
    {
      window.location = `${ROOT_URI}/login?redirectTo=${window.location.pathname}`
    }
  }

  render() {
    return (<React.Fragment>
        {this.props.children}
    </React.Fragment>)
  }

}

export const HistoryListener = withRouter(HistoryListenerClass);