import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import './PredictApp.css';
import Menubar from '../common/Menubar';

import { PdfPortal, PredictPortal } from './';
import { PdfPortal as OldPdfPortal } from '../predict';
import MappingRequestPortal from './mapping-request/MappingRequestPortal';

class PredictApp extends Component {
  constructor(props) {
    super(props);
    const localNewExperience = localStorage.getItem('newExperience')
    const newExperience = typeof(localNewExperience) === 'string' ? Number(localNewExperience): 1

    this.state = {
      isEditing: false,
      isNotOwner: false,
      lastEditorInfo: [],
      newExperience: newExperience,
      isShowComment: true,
      amountImageDisplay: true,
      scrumMapingButtonDisplay: true,
      isRePredict: false,
    };

    localStorage.setItem('newExperience', newExperience);
  }

  handleCheckRadioButton = (radio, checked) => {
    this.setState({ [radio]: checked });
  };

  handleEdit = status => {
    this.setState({ isEditing: status });
  };

  handleChangeOwnerStatus = (status) => {
    this.setState({ isNotOwner: status });
  };

  handleChangeLastEditorInfo = info => {
    this.setState({ lastEditorInfo: info });
  };

  handleChangeRePredict = status => {
    this.setState({ isRePredict: status });
  };

  handleExperienceChange = () => {

    // 1: is new experience 0: old
    const changedValue = this.state.newExperience === 1 ? 0 : 1

    this.setState({newExperience: changedValue})
    localStorage.setItem('newExperience', changedValue);
  }

  handleCommentChange = () => {
    const changedValue = this.state.isShowComment ? false : true

    this.setState({isShowComment: changedValue})
  }

  render() {
    const { newExperience, isShowComment, amountImageDisplay, scrumMapingButtonDisplay } = this.state
    return (
      <Menubar
        active={'predict'}
        location={this.props.location}
        isEditing={this.state.isEditing}
        isNotOwner={this.state.isNotOwner}
        onExperienceChange={this.handleExperienceChange}
        onCommentChange={this.handleCommentChange}
        isShowComment={isShowComment}
        isNewExperience={this.state.newExperience}
        isRePredict={this.state.isRePredict}
        user={this.props.user}
        lastEditorInfo={this.state.lastEditorInfo}
      >
        <Switch>
          <Route exact path="/predict/" component={PredictPortal} />
          <Route
            exact
            path="/predict/detail/:projectId/:pdfFileId"
            render={props => (newExperience ?
              <PdfPortal
                {...props}
                user={this.props.user}
                onGetUser={this.props.onGetUser}
                isEditing={this.state.isEditing}
                isShowComment={isShowComment}
                onCommentChange={this.handleCommentChange}
                handleEdit={this.handleEdit}
                onChangeOwnerStatus={this.handleChangeOwnerStatus}
                onChangeLastEditorInfo={this.handleChangeLastEditorInfo}
                amountImageDisplay={amountImageDisplay}
                scrumMapingButtonDisplay={scrumMapingButtonDisplay}
                onCheckRadioButton={this.handleCheckRadioButton}
                handleChangeRePredict={this.handleChangeRePredict}
                isNotOwner={this.state.isNotOwner}
              /> :
              <OldPdfPortal {...props} />
            )}
          />
          <Route
            exact
            path="/predict/mapping-request/:projectId/:pdfFileId"
            render={props => (
              <MappingRequestPortal
                {...props}
                user={this.props.user}
                onGetUser={this.props.onGetUser}
                isEditing={this.state.isEditing}
                isShowComment={isShowComment}
                onCommentChange={this.handleCommentChange}
                handleEdit={this.handleEdit}
                onChangeOwnerStatus={this.handleChangeOwnerStatus}
                onChangeLastEditorInfo={this.handleChangeLastEditorInfo}
                amountImageDisplay={amountImageDisplay}
                scrumMapingButtonDisplay={scrumMapingButtonDisplay}
                onCheckRadioButton={this.handleCheckRadioButton}
                handleChangeRePredict={this.handleChangeRePredict}
                isNotOwner={this.state.isNotOwner}
              />
            )}
          />
        </Switch>
      </Menubar>
    );
  }
}

export default PredictApp;
