import React, { Component } from 'react';
import { MDBDataTable } from 'mdbreact';
import { Segment, Header, Button, Message, Loader } from 'semantic-ui-react';
import { confirmAlert } from 'react-confirm-alert';
import api from '../../../api';
import SettingSearch from './search'
import intl from 'react-intl-universal';
import SettingEdit from './edit';
import ImportPreferences from './importPreferences';
import { getCsrfToken } from '../../../common/CookieUtil';

export default class TenantSettings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      init: true,
      isLoading: false,
      isShowEdit: false,
      isShowImport: false,
      editTenant: {},
      searchParam: null,
      data: {},
      checkTenant:{},
      errors: {},
      isError: false,
    }

    this.onSearch = this.onSearch.bind(this)
    this.refetch = this.refetch.bind(this)
    this.handleDel = this.handleDel.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
    this.toggleEdit = this.toggleEdit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleExport = this.handleExport.bind(this);
    this.handleImport = this.handleImport.bind(this);
    this.toggleImport = this.toggleImport.bind(this);
    this.checkForms = this.checkForms.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.clearErrors = this.clearErrors.bind(this)
  }

  handleImport(checkTenant) {
    if(this.state.tenantId){
      this.toggleImport({
        isShowImport: true
      })
    }else{
      this.checkForms(checkTenant)
    }
  }

  toggleImport({ isShowImport }) {
    this.setState({
      isShowImport: isShowImport
    });
  }

  async onSearch(tenantId) {

    this.setState({
      isLoading: true
    })
    await this.refetch(tenantId);
  }

  async refetch(tenantId) {
    const searchId = tenantId || this.state.tenantId;
      const tenants = (await api.get(
        `/dppapi/system/tenant-manage/preferences`+
        `?tenantId=${searchId}`
      )).data;

    const columns = [
      {
        label: intl.get('_system.tenants.setting.Index.ID'),
        field: 'id',
        sort: 'asc',
      },
      {
        label: intl.get('_system.tenants.setting.Index.Category'),
        field: 'category',
        sort: 'asc',
      },
      {
        label: intl.get('_system.tenants.setting.Index.SubCategory'),
        field: 'subCategory',
        sort: 'asc',
      },
      {
        label: intl.get('_system.tenants.setting.Index.Name'),
        field: 'name',
        sort: 'asc',
      },
      {
        label: intl.get('_system.tenants.setting.Index.SubName'),
        field: 'subName',
        sort: 'asc',
      },
      {
        label: intl.get('_system.tenants.setting.Index.Version'),
        field: 'version',
        sort: 'asc',
      },
      {
        label: intl.get('_system.tenants.setting.Index.Value'),
        field: 'value',
        sort: 'asc',
      },
      {
        label: intl.get('_system.tenants.setting.Index.Operation'),
        field: 'operation',
        sort: 'asc',
      }
    ]
    let rows = []
    if (tenants) {
      rows = tenants.map((data) => {
        return {
          ...data,
          value: (
            <div style={{ width: '100px',wordBreak: 'break-all' }}>
                {data.value}
            </div>
          ),
          valueText: data.value,
          operation: (
          <div>
            <Button 
              icon="pencil" 
              label={intl.get('_system.tenants.setting.Index.Edit')}
              size="tiny" 
              onClick={() => this.handleEdit(data)}/>
            <Button
              icon="trash"
              label={intl.get('_system.tenants.setting.Index.Delete')}
              size="tiny"
              onClick={() => this.handleDel(data.id)}
            />
          </div>
          )
        }
      })
    }else{
      this.checkForms(this.state.checkTenant)
    }
    
    this.setState({
      data: { rows, columns },
      init: false,
      isLoading: false
    })
  }

  checkForms(tenant) {
    const errors = {}
    if(!tenant.name){
      errors['tenantsName'] = {content: 'Please select a tenants name.', pointing: 'below', flag:true}
    }
    this.setState({
      errors: errors,
      isError: true
    })
    return Object.keys(errors).length !== 0
  }

  handleDel(id) {
    const { tenantId } = this.state;
    confirmAlert({
      title: intl.get('_system.tenants.setting.Index.Delete'),
      message: intl.get('_system.tenants.setting.Index.Are you sure to do this?'),
      buttons: [
        {
          label: intl.get('_system.tenants.setting.Index.Confirm'),
          onClick: async () => {
            await api.delete(`/dppapi/system/tenant-manage/preferences/${tenantId}/${id}`)
            this.refetch();
          },
        },
        {
          label: intl.get('_system.tenants.setting.Index.Cancel'),
        },
      ],
    });
  }

  handleEdit(tenant) {
    if(this.state.tenantId){
      this.setState({
        isShowEdit: true,
        editTenant: tenant,
        checkTenant: tenant
      })
    }else{
      this.checkForms(this.state.checkTenant)
    }
  }

  handleFocus() {
    this.clearErrors()
  }

  clearErrors() {
    this.setState({
      errors: {},
      isError: false
    })
  }

  handleAdd() {
    if(this.state.tenantId){
      this.toggleEdit(true)
    }else{
      this.checkForms(this.state.checkTenant)
    }
  }

  toggleEdit(isShowEdit) {
    this.setState({
      isShowEdit: isShowEdit,
      editTenant: {},
      checkTenant:{}
    })
  }

  onChange = (value) => {
    this.setState({ tenantId: value });
  };

  async handleExport() {
    const { tenantId } = this.state;
    if(tenantId){
      confirmAlert({
        message: intl.get('_system.tenants.setting.Index.Do you want to get tenantSetting?'),
        buttons: [
          {
            label: intl.get('_system.tenants.setting.Index.Cancel'),
          },
          {
            label: intl.get('_system.tenants.setting.Index.Download'),
            onClick: async () => {
              let a = document.createElement('a');
              a.href = `/dppapi/system/tenant-manage/export/${tenantId}?token=${getCsrfToken()}`;
              a.click();
              a.remove();
            },
          },
        ],
      })
    }else{
      this.checkForms(this.state.checkTenant)
    }
  }

  render() {

    const { data, isLoading, init } = this.state;
    var result = null;
    if (isLoading) {
      result = (<Segment><Loader active inline="centered" /></Segment>)
    } else if (data.rows&&data.rows.length > 0) {
      result = (<Segment>
        <MDBDataTable
          btn
          striped
          bordered
          hover
          data={data}
        />
      </Segment>)
    } else {
      result = (<Message
        info
        header='No Data'
        content="please change conditions"
      />)
    }
    return (
      <React.Fragment>
        <div style={{ display: 'flex' }}>
          <div className="ui" style={{ flex: 1 }}>
            <div id="search-pdf" style={{ padding: '2em 0' }}>
              <Header disabled>{intl.get('_system.tenants.setting.Index.Filtering')}</Header>
              <SettingSearch 
              onSearch={this.onSearch}
              handleAdd={this.handleAdd}
              onChange={this.onChange}
              tenantId={this.state.tenantId}
              handleExport = {this.handleExport}
              handleImport = {this.handleImport}
              handleEdit={this.handleEdit}
              isError = {this.state.isError}
              handleFocus = {this.handleFocus}
              errors = {this.state.errors}
              />
            </div>
            <div id="page" style={{ paddingBottom: 200, flex: 1 }}>
              {!init ? result : null}
            </div>
            <SettingEdit 
              isShowEdit={this.state.isShowEdit}
              preferences={this.state.editTenant}
              tenantId={this.state.tenantId}
              toggleEdit={this.toggleEdit}
              getPreferences={this.refetch}/>
            <ImportPreferences
              tenantId={this.state.tenantId}
              isShowImport={this.state.isShowImport}
              toggleImport={this.toggleImport}
              getPreferences={this.refetch}/>
          </div>
        </div>
      </React.Fragment>
    );
  }
}