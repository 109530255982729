import React, { Component } from 'react';
import api from '../../api';
import {
  Segment,
  Form,
  Divider,
  Button,
  Icon,
  Loader,
} from 'semantic-ui-react';
import { downloadCsv } from '../../predict/Util';

class ActionBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  async componentDidMount() {}

  handleInput = (event) => {
    const { name, value } = event.target;
    const form = {};
    form[name] = value;
    this.props.setForm(form);
  };

  handleExport = async () => {
    try {
      this.setState({ loading: true });
      const { data } = await api.get(`/dppapi/tenant-setting/convert-subtitle-export`);
      const items = data.list;
      if (items && items.length > 0) {
        downloadCsv(
          items.map((item) => [item.subtitleName, item.convertSubtitle, item.category]),
          { header: false },
          `読替表設定_${new Date().getTime()}.csv`
        );
      }
    } catch (e) {
      console.error(e);
    } finally { 
      this.setState({ loading: false });
    } 
  };

  render() {
    const { loading } = this.state;
    return (
      <Segment>
        {loading ? (
          <Loader active inline="centered"/>
        ) : (
          <Form>
            <Form.Field>
              <Button.Group size="small">
                <Button
                  onClick={() => this.props.toggleEdit(true)}
                  color="black"
                >
                  <Icon name="add" />
                  Add
                </Button>
                <Button
                  onClick={() => this.props.toggleImport(true)}
                  basic={true}
                  color="black"
                >
                  <Icon name="sign-in" />
                  Import
                </Button>
                <Button
                  onClick={() => {
                    this.handleExport();
                  }}
                  basic={true}
                  color="black"
                >
                  <Icon name="sign-out" />
                  Export
                </Button>
              </Button.Group>
            </Form.Field>
            <Divider />
            <Form.Field>
              <label>Subtitle Name</label>
              <Form.Input
                name="subtitleName"
                placeholder="Subtitle Name"
                onChange={(event) => this.handleInput(event)}
              />
            </Form.Field>
            <Form.Field>
              <label>Convert Subtitle</label>
              <Form.Input
                name="convertSubtitle"
                placeholder="Convert Subtitle"
                onChange={(event) => this.handleInput(event)}
              />
            </Form.Field>
            <Form.Field>
              <label>Category</label>
              <Form.Input
                name="category"
                placeholder="Category"
                onChange={(event) => this.handleInput(event)}
              />
            </Form.Field>
            <Button size="small" onClick={() => this.props.handleSearch()}>
              <Icon name="search" />
              Search
            </Button>
          </Form>
        )}
      </Segment>
    );
  }
}

export default ActionBar;
