import React, { Component, Fragment } from 'react';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import './BBoxIcon.css';

export default class BBoxIcon extends Component {
  render() {
    // -------- Define displayed icons --------
    const icons = {
      // red
      formatWarning: {
        show: false,
        html: L.divIcon({
          html: `<div style='position: relative; width: 14px; height: 14px; padding: 1px; cursor: grab'><i aria-hidden="true" style="color:#d75b5c;font-size: 10px; position: absolute; top: 0; left: 3px;margin: 0; height: unset; width: unset;" class="exclamation icon"></i></div>`,
        }),
      },
      // yellow
      lowAccWarning: {
        show: false,
        html: L.divIcon({
          html: `<div style='position: relative; width: 14px; height: 14px; padding: 1px; cursor: grab'><i aria-hidden="true" style="color:#e8ba00;font-size: 10px; position: absolute; top: 0; left: 3px;margin: 0; height: unset; width: unset;" class="question icon"></i></div>`,
        }),
      },
    };
    // -------- Define displayed icons --------

    const { figure } = this.props;
    const { points, stateFlag, accuracy, accThreshold } = figure;

    // -------- Add icon's flag --------
    // const detailData = this.props.textMappingRef.current.state.detailData;
    // let isLinked = false

    // check is warning
    icons.formatWarning.show = stateFlag === 5;

    // check low accuray
    if (
      accuracy &&
      accThreshold &&
      accuracy.length > 0 &&
      figure.det_class === 'value'
    ) {
      const lowAcc = Math.min(
        ...(Array.isArray(accuracy) ? accuracy : []).map(a => parseFloat(a))
      );
      icons.lowAccWarning.show = lowAcc < parseFloat(accThreshold);
    }

    return (
      <Fragment key={figure.id}>
        {Object.keys(icons)
          .filter(key => icons[key].show)
          .map((key, index) => (
            <Marker
              key={index}
              position={[
                points[0]['lat'] + 15,
                points[0]['lng'] - (15 + index * 20),
              ]}
              icon={icons[key].html}
            />
          ))}
      </Fragment>
    );
  }
}
