import React, { Component } from 'react';
import Canvas from './Canvas';
import { colors } from '../../../label/label-app/utils';
import { withHistory } from '../../detail/pdf/LabelingAppHistoryHOC';
import { withLoadImageData } from '../../detail/pdf/LoadImageDataHOC';
import 'semantic-ui-css/semantic.min.css';
import './PdfLoader.css';
import { PREDICT_DEFAULT_LABEL_ID } from '../../Constant';
import { crop, getDataUrlFromArr } from '../../../common/ImageUtil';

class PdfContainer extends Component {
  constructor(props) {
    super(props);
    const { labels } = props;

    const toggles = {};
    labels.map(label => (toggles[label.id] = true));

    this.state = {
      selected: null,
      toggles,

      selectedFigureId: null,

      // UI
      reassigning: { status: false, type: null },

      // Parsing relavant
      isShowParsingResult: false,
      parsingResult: {},
    };

    this.handleSelected = this.handleSelected.bind(this);
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.handlePredict = this.handlePredict.bind(this);
    this.handlePredictFsScrumMap = this.handlePredictFsScrumMap.bind(this);
  }

  handleSelected(selected) {
    if (selected === this.state.selected) return;
    const { pushState } = this.props;

    if (!selected) {
      pushState(
        state => ({
          unfinishedFigure: null,
        }),
        () => this.setState({ selected })
      );
      return;
    }

    const { labels } = this.props;

    const labelIdx = 0;
    const type = labels[labelIdx]
      ? labels[labelIdx].type
      : PREDICT_DEFAULT_LABEL_ID;
    const color = colors[labelIdx] ? colors[labelIdx] : 'red';
    const detClass = labels[labelIdx] ? labels[labelIdx].name : '';

    pushState(
      state => ({
        unfinishedFigure: {
          id: null,
          color,
          type,
          points: [],
          det_text: '',
          det_class: detClass,
        },
      }),
      () => this.setState({ selected })
    );
  }

  handleSelectionChange(figureId) {
    if (this.props.isSelectTargetBbox) {
      // select target bbox for RePredict
      var bbox = {}
      if (this.props.figures['bbox']) {
        const figure = this.props.figures['bbox'].find(f => f.id === figureId)
        const { cropedImageData, width, height } = crop(this.props.imageData, figure.points, this.props.width, this.props.height)
        const dataUrl = getDataUrlFromArr(cropedImageData, width, height)
        bbox = {
          ...figure,
          dataUrl
        }
      }
      this.props.handleRePredictSelectBbox(false, bbox)
    } else {
      if (figureId) {
        this.setState({ selectedFigureId: figureId });
      } else {
        this.setState({
          reassigning: { status: false, type: null },
          selectedFigureId: null,
        });
      }
    }
  }

  async handlePredictFsScrumMap() {
    const { onPredictFsScrumMap, projectInfo, textMappingRef } = this.props;
    const { projectId, pdfFileId, pdfImageId } = projectInfo;
    const resPredictFsScrumMap = await onPredictFsScrumMap(
      projectId,
      pdfFileId,
      pdfImageId
    );
    if (resPredictFsScrumMap.success) {
      textMappingRef &&
        textMappingRef.current &&
        textMappingRef.current.handleReload(true);
    }
    this.setState({
      isShowParsingResult: true,
      parsingResult: resPredictFsScrumMap,
    });
  }

  async handlePredict() {
    const { onPredict, projectInfo, textMappingRef } = this.props;
    const { projectId, pdfFileId, pdfImageId } = projectInfo;
    const resPredict = await onPredict(projectId, pdfFileId, pdfImageId);
    if (resPredict.success) {
      this.props.onLoad();
      textMappingRef &&
        textMappingRef.current &&
        textMappingRef.current.handleReload(true);
    }
  }

  render() {
    const {
      labels,
      imageUrl,
      loaderImageType,
      figures,
      unfinishedFigure,
      height,
      width,
      selectedCell,
      linking,
      manualInput,
      textMappingRef,
      onShortKeyPanelVisible,
      onSelectBox,
      onSelectedBBoxChange,
      selectedBBox,
      isEditing,
      ocrFlg,
      scrumRecommendationFlg,
      onLoad,
      projectInfo,
      thumbnailRef,
      imageData,
      canvasRef,
      muiPopupRef,
      selectFlag,
      handleChangeSelectFlag,
      effectiveHotkeyPage,
    } = this.props;

    const allFigures = [];

    const colorSet = {};
    labels.forEach((label, i) => {
      colorSet[label.name] = i;
    });

    const labelTypes = {};
    labels.forEach((label, i) => {
      if (label.type === 'bbox') {
        labelTypes[label.name] = label.name;
      }
    });

    figures[PREDICT_DEFAULT_LABEL_ID].forEach(figure => {
      if (figure.type === 'bbox' || figure.type === 'polygon') {
        const detClass = figure.det_class;
        const i = colorSet[detClass] ? colorSet[detClass] : 0;
        allFigures.push({
          color: colors[i],
          points: figure.points,
          id: figure.id,
          checkFlag: figure.checkFlag,
          stateFlag: figure.stateFlag,
          accuracy: figure.accuracy,
          accThreshold: figure.accThreshold,
          type: figure.type,
          tracingOptions: figure.tracingOptions,
          det_text: figure.det_text,
          det_class: figure.det_class,
        });
      }
    });
    figures.__temp.forEach(figure => {
      allFigures.push({
        color: 'red',
        ...figure,
      });
    });

    const selectedCellIds = selectedCell.cellIds;
    return (
      <Canvas
        imageData={imageData}
        url={imageUrl}
        loaderImageType={loaderImageType}
        height={height}
        width={width}
        figures={allFigures}
        unfinishedFigure={unfinishedFigure}
        onPredict={this.handlePredict}
        onPredictFsScrumMap={this.handlePredictFsScrumMap}
        onReassignment={type =>
          this.setState({ reassigning: { status: true, type } })
        }
        onLoad={onLoad}
        onSelectionChange={this.handleSelectionChange}
        ref={canvasRef}
        muiPopupRef={muiPopupRef}
        style={{ flex: 1 }}
        selectedCellIds={selectedCellIds}
        selectedCell={selectedCell}
        onSelect={this.handleSelected}
        linking={linking}
        manualInput={manualInput}
        textMappingRef={textMappingRef}
        onShortKeyPanelVisible={onShortKeyPanelVisible}
        onSelectBox={onSelectBox}
        popupRef={this.props.popupRef}
        onSelectedBBoxChange={onSelectedBBoxChange}
        selectedBBox={selectedBBox}
        isEditing={isEditing}
        scrumRecommendationFlg={scrumRecommendationFlg}
        ocrFlg={ocrFlg}
        labelTypes={labelTypes}
        leftWidth={this.props.leftWidth}
        projectId={projectInfo.projectId}
        thumbnailRef={thumbnailRef}
        pdfId={projectInfo.pdfFileId}
        isRpaOpen={this.props.isRpaOpen}
        handelShowPredictLoading={this.props.handelShowPredictLoading}
        toggleRePredict={this.props.toggleRePredict}
        selectTargetBbox={this.props.selectTargetBbox}
        selectFlag={selectFlag}
        handleChangeSelectFlag={handleChangeSelectFlag}
        effectiveHotkeyPage={effectiveHotkeyPage}
        handleUnuseImage={this.props.handleUnuseImage}
        isNotOwner={this.props.isNotOwner}
        onShowExclusionControl={this.props.onShowExclusionControl}
        onSetHeaderInfo={this.props.onSetHeaderInfo}
        currentPage={projectInfo.pdfImageId}
        pdfInfo={this.props.pdfInfo}
      >
      </Canvas>
    );
  }
}

export default withLoadImageData(withHistory(PdfContainer));
