import React, { Component } from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact';
import {
  // Segment,
  Form,
  // Divider,
  Button,
  Icon,
  Message,
} from 'semantic-ui-react';
import api from '../../api';

export default class Edit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editItem: {
        id: null,
        subtitleName: '',
        convertSubtitle: '',
        category: '',
      },
      errors: {},
    };
  }

  // shouldComponentUpdate(nextProps) {
  //   if (
  //     nextProps.fsItemId === this.props.fsItemId &&
  //     typeof this.props.fsItemId !== 'undefined' &&
  //     nextProps.fsItemId !== null
  //   ) {
  //     return false;
  //   }
  //   return true;
  // }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.editItem && nextProps.editItem.id && prevState.editItem.id !== nextProps.editItem.id) {
      return {...prevState, editItem: nextProps.editItem};
    }
    return null;
  }

  handleInput = (event) => {
    const { name, value } = event.target;
    const { editItem } = this.state;
    const form = {};
    form[name] = value;
    this.setState({
      editItem: { ...editItem, ...form },
    });
  };

  checkForm() {
    const { editItem } = this.state;
    const errors = {};

    if (!editItem.subtitleName) {
      errors['subtitleName'] = {
        content: 'Please enter a Subtitle Name.',
        pointing: 'below',
        flag: true,
      };
    }

    if (!editItem.convertSubtitle) {
      errors['convertSubtitle'] = {
        content: 'Please enter a ConvertSubtitle.',
        pointing: 'below',
        flag: true,
      };
    }

    if (!editItem.category) {
      errors['category'] = {
        content: 'Please enter an Category.',
        pointing: 'below',
        flag: true,
      };
    }

   

    this.setState({
      errors: errors,
    });
    return Object.keys(errors).length === 0;
  }

  handleCancel = () => {
    this.clearForm();
    this.props.toggleEdit(false);
  };

  handleSave = async () => {
    if (!this.checkForm()) {
      return;
    }

    const { editItem } = this.state;

    try {
      if (editItem.id) {
        const { data } = await api.put(`/dppapi/tenant-setting/convert-subtitle/${editItem.id}`, editItem);
        if (!data.success) {
          this.setState({
            errors: [{ content: data.errmsg }],
          });
        } else {
          this.props.handleSearch();
          this.props.toggleEdit(false);
          this.clearForm();
        }
      } else {
        const { data } = await api.post(`/dppapi/tenant-setting/convert-subtitle`, editItem);
        if (!data.success) {
          this.setState({
            errors: [{ content: data.errmsg }],
          });
        } else {
          this.props.handleSearch();
          this.props.toggleEdit(false);
          this.clearForm();
        }
      }
      
    } catch (e) {
      // handle error
      const { msg } = e.data || {};
      this.setState({
        errors: [{ content: msg }],
      });
    }
  };

  clearForm = () => {
    this.setState({
      editItem: {
        id: null,
        subtitleName: '',
        convertSubtitle: '',
        category: '',
      },
      errors: {},
    });
  };

  render() {
    const { errors, editItem } = this.state;
    return (
      <MDBModal isOpen={this.props.isShowEdit} toggle={this.props.toggleEdit}>
        <MDBModalHeader>{editItem.id ? "Edit" : "Add"} Convert Subtitle</MDBModalHeader>
        <MDBModalBody>
          {Object.keys(errors).length > 0 && (
            <Message
              error
              list={Object.keys(errors).map((i) => {
                return errors[i].content;
              })}
            />
          )}
          <Form>
            <Form.Field>
              <label>SubtitleName</label>
              <Form.Input
                name="subtitleName"
                placeholder="Subtitle Name"
                value={editItem.subtitleName}
                onChange={(event) => this.handleInput(event)}
              />
            </Form.Field>
            <Form.Field>
              <label>ConvertSubtitle</label>
              <Form.Input
                name="convertSubtitle"
                placeholder="Convert Subtitle"
                value={editItem.convertSubtitle}
                onChange={(event) => this.handleInput(event)}
              />
            </Form.Field>
            <Form.Field>
              <label>Category</label>
              <Form.Input
                name="category"
                placeholder="Category"
                value={editItem.category}
                onChange={(event) => this.handleInput(event)}
              />
            </Form.Field>
            <Button
              size="small"
              onClick={() => this.handleSave()}
              floated="right"
            >
              <Icon name="save" />
              Save
            </Button>
            <Button
              size="small"
              onClick={() => this.handleCancel()}
              floated="right"
            >
              <Icon name="cancel" />
              Cancel
            </Button>
          </Form>
        </MDBModalBody>
      </MDBModal>
    );
  }
}