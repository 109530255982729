import React, { Component } from 'react';
import { Dropdown, Divider } from 'semantic-ui-react'
import api from '../../api';
import intl from 'react-intl-universal';
import { createCookie, getCookie } from '../../common/CookieUtil';

export default class PredictProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: []
    };
  }

  async componentDidMount() {
    const { data } = await api.request({
      url: '/dppapi/predict/projects',
      method: 'GET'
    })

    const projects = []
    data.forEach(item => {
      projects.push({ key: item.id, value: item.id, text: item.name })
    })
    if (projects.length > 0) {
      var projectId = getCookie('projectId');
      if (projectId) {
        var projectIdIndex = projects.find( v => v.value === Number(projectId));
        if (projectIdIndex) {
          this.props.onProjectIdChange(Number(projectId));
        } else {
          this.props.onProjectIdChange(projects[0].value);
        }
      } else {
        this.props.onProjectIdChange(projects[0].value);
      }
    }

    this.setState({ projects });
  }

  onProjectIdChangeSet(value) {
    this.props.onProjectIdChange(value);
    createCookie("projectId",value,365);
  };

  render() {
    const { projects } = this.state;
    const { projectId } = this.props;

    return (
      <div className="predict-admin-management-project">
        <div className="right-left-margin">
          <div className="predict-admin-title">プロジェクト選択</div>
        </div>

        <div className="predict-admin-management-project-dropdown right-left-margin">
          <Dropdown
            placeholder='Select Project'
            fluid
            search
            selection
            options={projects}
            value={projectId}
            onChange={(e, data) => this.onProjectIdChangeSet(data.value)}
            className="predict-admin-dropdown-content select-pagetype"
            noResultsMessage={intl.get('_predict.admin.PdfSearch.Noresults')}
          />
        </div>
        <Divider />
      </div>
    );
  }
}
