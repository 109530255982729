import React, { useContext } from 'react';
import { Dropdown } from 'semantic-ui-react';
import intl from 'react-intl-universal';
import { AnalyticsServiceContext } from '../../../common/Analytics/AnalyticsServiceProvider';
import { EventName, EventType } from '../../../common/Analytics/analyticsMetrics';
import _ from 'lodash'

export default ({
  id,
  options,
  value,
  onMappingItemChange,
  disabled,
  category,
  type,
  clearable,
  showPlaceholder = true,
}) => {
  const { predictDetailViewMode, sendPredictBeautifyDetailMetrics } = useContext(AnalyticsServiceContext)
  const filterOptions = _.filter(options, (t) => {
    return t.text.indexOf("(自動集計)") === -1
  })
  return (
    <Dropdown
      className='fs-text-table-table-wrap-table-dropdown'
      placeholder={showPlaceholder ? intl.get('_predict.detail.mapping.Select') : ""}
      fluid
      search={type === 'inputText'}
      selection
      lazyLoad
      value={value}
      disabled={disabled}
      options={filterOptions}
      noResultsMessage='該当データなし'
      closeOnBlur
      onChange={(e, { value }) => {
        if (e.type === 'click') {
          onMappingItemChange(id, value, category);
        }
        if (predictDetailViewMode !== "") {
          sendPredictBeautifyDetailMetrics({
            event_type: EventType.Action,
            event_name: EventName.SelectSubjectsDropdown,
            custom_parameter: {
              view_mode: predictDetailViewMode,
              selected_subject: value
            },
          })
        }
      }}
      clearable={clearable}
    />
  );
};
