import React, { Component } from 'react';
import { CRS } from 'leaflet';
import { Map, ImageOverlay, ZoomControl } from 'react-leaflet';
import { Link } from 'react-router-dom';
import Control from 'react-leaflet-control';
import Hotkeys from 'react-hot-keys';
import update from 'immutability-helper';
import { confirmAlert } from 'react-confirm-alert';
import 'leaflet-path-drag';
import 'leaflet/dist/leaflet.css';

import { Icon, Loader } from 'semantic-ui-react';
import { BBoxFigure, PolygonFigure } from './Figure';
import { convertPoint, lighten, colorMapping } from '../../../label/label-app/utils';
import { withBounds, maxZoom } from './CalcBoundsHOC';
import { PREDICT_DEFAULT_LABEL_NAME } from '../../Constant';
import intl from 'react-intl-universal';
import api from '../../../api'
const rdom = require('react-dom'); 

class Canvas extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      zoom: -1,
      selectedFigureId: null,
      cursorPos: { lat: 0, lng: 0 },
      loading: false,
      showToolBar: false,
      clickFlag: false,
      scrollWheelZoomFlag: false,
      presetMasterSearchFlg: false,
    };
    this.prevSelectedFigure = null;
    this.skipNextClickEvent = false;

    this.mapRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.rotateDialog = this.rotateDialog.bind(this);
    this.predictFsScrumMapDialog = this.predictFsScrumMapDialog.bind(this);
    this.predictDialog = this.predictDialog.bind(this);
    this.delAllBBoxDialog = this.delAllBBoxDialog.bind(this);
    this.handlePredict = this.handlePredict.bind(this);
    this.handleViewPortChange = this.handleViewPortChange.bind(this);
    
  }

  componentDidUpdate(prevProps) {
    const { onSelectionChange } = this.props;
    const { selectedFigureId } = this.state;

    if (this.prevSelectedFigureId !== selectedFigureId && onSelectionChange) {
      this.prevSelectedFigureId = selectedFigureId;
      onSelectionChange(selectedFigureId);
    }
  }

  async componentDidMount() {
    const per = localStorage.getItem("permission") || '';
    let perFlag = true;
    per.split(',').forEach((item)=>{
      if (item === '2') {
        perFlag = false;
      }
    })
    this.setState({clickFlag: perFlag});
    
    var agent = navigator.userAgent.toLowerCase();
    var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0 || agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
      this.setState({scrollWheelZoomFlag: false});
      document.getElementById('pdfImage').addEventListener('wheel', this.handleScroll, true);
      }
    if(isMac){
      this.setState({scrollWheelZoomFlag: true});
    }
    
    await this.handleGetTenantPreference();
  }

  handleGetTenantPreference = async() => {
    const res = await api.request({
      url: `/dppapi/predict/tenant/preference/query`,
      method: 'POST',
      data: {category: "configure", subCategory: "nodejs", name: "predict_page", subName: "preset_master_search"}
    })
    if (res.data.success){
      this.setState({presetMasterSearchFlg: res.data.value})
    }
  }

  getSelectedFigure() {
    const { selectedFigureId } = this.state;
    const { figures } = this.props;
    return figures.find(f => f.id === selectedFigureId);
  }

  handleScroll = (e) => {
    e.preventDefault()
    const ele = rdom.findDOMNode(this);
    const map = this.mapRef.current.leafletElement;
    if (e.deltaY <= 0) {
      /* scrolling up */
      if(ele.scrollTop <= 0) {
        map.panBy([0, -80]);
      }
    } else {
      /* scrolling down */
      if(ele.scrollTop + ele.clientHeight >= ele.scrollHeight) {
        map.panBy([0, 80]);
      }
    }
  }

  changeWinActionListener = (flag) => {
    var agent = navigator.userAgent.toLowerCase();
    if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0 || agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
      this.setState({scrollWheelZoomFlag: false});
      if(!flag){
        document.getElementById('pdfImage').addEventListener('wheel', this.handleScroll, true);
      }else{
        document.getElementById('pdfImage').removeEventListener('wheel', this.handleScroll, true);
      }
    }
  }
  
  handleSetOppositeValue = (id, det_text) => {
    const {figures} = this.props;
    const figure = figures.find(f => f.id === id);
    if (!figure) {
      return;
    }
    this.handleChange('detTextChange', {
      figure,
      det_text: det_text,
    });
  } 

  handleChange(eventType, { point, pos, figure, points, det_text, linkTo, det_class, checkFlag, accuracy }) {
    const { onChange, unfinishedFigure } = this.props;
    const drawing = !!unfinishedFigure;

    switch (eventType) {
      case 'add':
        if (drawing) {
          let newState = unfinishedFigure.points;
          newState = update(newState, { $push: [point] });

          onChange(
            'unfinished',
            update(unfinishedFigure, {
              points: {
                $set: newState,
              },
            })
          );
        } else {
          onChange(
            'replace',
            update(figure, { points: { $splice: [[pos, 0, point]] } })
          );
        }
        break;

      case 'end':
        const f = unfinishedFigure;
        onChange('new', f);
        break;

      case 'move':
        onChange(
          'replace',
          update(figure, { points: { $splice: [[pos, 1, point]] } })
        );
        break;

      case 'replace':
        onChange('replace', update(figure, { points: { $set: points } }));
        break;

      case 'remove':
        onChange(
          'replace',
          update(figure, { points: { $splice: [[pos, 1]] } })
        );
        break;
      case 'detTextChange':
        onChange('replace', update(figure, { det_text: { $set: det_text }, accuracy: { $set: accuracy } }));
        break;
      case 'detTextClassChange':
        onChange('replace', update(figure, { det_class: { $set: det_class } }));
        break;
      case 'linkIdChange':
        onChange('replace', update(figure, { linkTo: { $set: linkTo } }));
        break;
      case 'checkFlagChange':
        onChange('replace', update(figure, { checkFlag: { $set: checkFlag } }));
        break;

      default:
        throw new Error('unknown event type ' + eventType);
    }
  }

  handleClick(e) {
    const { unfinishedFigure } = this.props;
    const drawing = !!unfinishedFigure;

    if (this.skipNextClickEvent) {
      // a hack, for whatever reason it is really hard to stop event propagation in leaflet
      this.skipNextClickEvent = false;
      return;
    }

    if (drawing) {
      this.handleChange('add', { point: convertPoint(e.latlng) });
      return;
    }

    if (!drawing) {
      this.setState({ selectedFigureId: null });
      return;
    }
  }

  renderFigure(figure, options) {
    const Comp = figure.type === 'bbox' ? BBoxFigure : PolygonFigure;

    return (
      <Comp
        key={figure.id}
        figure={figure}
        options={options}
        skipNextClick={() => (this.skipNextClickEvent = true)}
        handleOcr={this.props.handleOcr}
        bounds={this.props.bounds}
        selectedBox={this.props.selectedBox}
        linking={this.props.linking}
        textMappingRef={this.props.textMappingRef}
        onSelectBox={this.props.onSelectBox}
        popupRef={this.props.popupRef}
        onSelectedBBoxChange={this.props.onSelectedBBoxChange}
        selectedBBox={this.props.selectedBBox}
        isEditing={this.props.isEditing}
        ocrFlg={this.props.ocrFlg}
        scrumRecommendationFlg={this.props.scrumRecommendationFlg}
        labelTypes={this.props.labelTypes}
        onLoad={this.props.onLoad}
        projectId={this.props.projectId}
        loaderImageType={this.props.loaderImageType}
        presetMasterSearchFlg={this.state.presetMasterSearchFlg}
        changeWinActionListener={this.changeWinActionListener}
      />
    );
  }

  rotateDialog = () => {
    confirmAlert({
      title: intl.get('_predict.detail.pdf.Canvas.Rotation'),
      message: intl.get('_predict.detail.pdf.Canvas.Do you want to rotate this page 90 degrees?'),
      buttons: [
        {
          label: intl.get('_predict.detail.pdf.Canvas.OK'),
          onClick: () => this.props.rotate(),
        },
        {
          label: intl.get('_predict.detail.pdf.Canvas.Cancel'),
        },
      ],
      zIndex: 500,
    });
  };

  predictFsScrumMapDialog = () => {
    confirmAlert({
      title: intl.get('_predict.detail.pdf.Canvas.Results analysis'),
      message: intl.get('_predict.detail.pdf.Canvas.Do you want re-analyze the results?'),
      buttons: [
        {
          label: intl.get('_predict.detail.pdf.Canvas.OK'),
          onClick: () => this.props.onPredictFsScrumMap(),
        },
        {
          label: intl.get('_predict.detail.pdf.Canvas.Cancel'),
        },
      ],
      zIndex: 500,
    });
  };

  predictDialog = () => {
    const { loading } = this.state;
    if (loading) {
      return;
    }
    confirmAlert({
      title: intl.get('_predict.detail.pdf.Canvas.AI Analysis'),
      message: intl.get('_predict.detail.pdf.Canvas.Do you want to re-analyze this page with AI?'),
      buttons: [
        {
          label: intl.get('_predict.detail.pdf.Canvas.OK'),
          onClick: () => this.handlePredict(),
        },
        {
          label: intl.get('_predict.detail.pdf.Canvas.Cancel'),
        },
      ],
      zIndex: 500,
    });
  };

  delAllBBoxDialog = () => {
    confirmAlert({
      title: intl.get('_predict.detail.pdf.Canvas.Delete'),
      message: intl.get('_predict.detail.pdf.Canvas.Do you want to remove all bounding boxes?'),
      buttons: [
        {
          label: intl.get('_predict.detail.pdf.Canvas.OK'),
          onClick: () => this.props.onAllBBoxDelete(),
        },
        {
          label: intl.get('_predict.detail.pdf.Canvas.Cancel'),
        },
      ],
      zIndex: 500,
    });
  };

  addPdfToImageDialog = () => {
    confirmAlert({
      title: intl.get('_predict.detail.pdf.Canvas.Movelabeling'),
      message: intl.get('_predict.detail.pdf.Canvas.Do you want to move it to labeling data?'),
      buttons: [
        {
          label: intl.get('_predict.detail.pdf.Canvas.OK'),
          onClick: () => this.props.handleAddPdfToImage(),
        },
        {
          label: intl.get('_predict.detail.pdf.Canvas.Cancel'),
        },
      ],
      zIndex: 500,
    });
  }

  async handlePredict() {
    this.setState({ loading: true });
    await this.props.onPredict();
    this.setState({ loading: false });
  }

  handleViewPortChange(e) {
    localStorage.setItem('zoom', JSON.stringify(e))
  }

  render() {
    const {
      url,
      bounds,
      height,
      width,
      figures,
      unfinishedFigure,
      onChange,
      onReassignment,
      style,
      selectedCellIds,
      onSelect,
      // onShortKeyPanelVisible,
      isEditing,
      imageData
    } = this.props;
    const { zoom, selectedFigureId, cursorPos, loading, showToolBar, clickFlag, scrollWheelZoomFlag } = this.state;
    const { zoom:zoomDefault, center:centerDefault} = JSON.parse(localStorage.getItem('zoom')||'{}');

    const drawing = !!unfinishedFigure;

    const calcDistance = (p1, p2) => {
      const map = this.mapRef.current.leafletElement;
      return map.latLngToLayerPoint(p1).distanceTo(map.latLngToLayerPoint(p2));
    };

    const unfinishedDrawingDOM = drawing
      ? this.renderFigure(unfinishedFigure, {
        finished: false,
        editing: false,
        interactive: false,
        color: colorMapping[unfinishedFigure.color],
        onChange: this.handleChange,
        calcDistance,
        newPoint: cursorPos,
        inSelectedCellIds: false
      })
      : null;

    const getColor = f =>
      f.tracingOptions && f.tracingOptions.enabled
        ? lighten(colorMapping[f.color], 80)
        : colorMapping[f.color];
    const figuresDOM = figures.map((f, i) =>
      this.renderFigure(f, {
        editing: selectedFigureId === f.id && !drawing,
        finished: true,
        interactive: !drawing,
        sketch: f.tracingOptions && f.tracingOptions.enabled,
        color: getColor(f),
        vertexColor: colorMapping[f.color],
        onSelect: () => this.setState({ selectedFigureId: f.id }),
        onChange: this.handleChange,
        calcDistance,
        inSelectedCellIds: selectedCellIds.indexOf(f.id) !== -1,
        imageData: imageData,
        imageWidth: width,
        imageHeight: height
      })
    );

    const hotkeysDOM = (
      <Hotkeys
        keyName="backspace,c,f,-,=,left,right,up,down,1,esc"
        onKeyDown={key => {
          const tagName = document.activeElement
            ? document.activeElement.tagName.toLowerCase()
            : null;
          if (['input', 'textarea'].includes(tagName)) {
            return false;
          }
          if (key === '1') {
            // new bbox, default type is word
            onSelect(PREDICT_DEFAULT_LABEL_NAME)
          }
          if (key === 'esc') {
            onSelect(null)
          }
          if (drawing) {
            if (key === 'f') {
              const { type, points } = unfinishedFigure;
              if (type === 'polygon' && points.length >= 3) {
                this.handleChange('end', {});
              }
            }
          } else {
            if (key === 'c') {
              if (selectedFigureId && this.getSelectedFigure()) {
                onReassignment(this.getSelectedFigure().type);
              }
            } else if (key === 'backspace') {
              if (!isEditing && selectedFigureId && this.getSelectedFigure()) {
                onChange('delete', this.getSelectedFigure());
              }
            }
          }

          const map = this.mapRef.current.leafletElement;
          if (key === 'left') {
            map.panBy([80, 0]);
          }
          if (key === 'right') {
            map.panBy([-80, 0]);
          }
          if (key === 'up') {
            map.panBy([0, 80]);
          }
          if (key === 'down') {
            map.panBy([0, -80]);
          }
          if (key === '=') {
            map.setZoom(map.getZoom() + 1);
          }
          if (key === '-') {
            map.setZoom(map.getZoom() - 1);
          }
        }}
      />
    );

    let renderedTrace = null;
    const selectedFigure = this.getSelectedFigure();
    if (selectedFigure && selectedFigure.type === 'polygon') {
      const trace = selectedFigure.tracingOptions
        ? selectedFigure.tracingOptions.trace || []
        : [];
      const figure = {
        id: 'trace',
        type: 'line',
        points: trace,
      };
      const traceOptions = {
        editing: false,
        finished: true,
        color: colorMapping[selectedFigure.color],
      };
      renderedTrace = <PolygonFigure figure={figure} options={traceOptions} />;
    }

    let renderedLoader = null;
    if (loading) {
      renderedLoader = <Loader active size="large" />;
    }


    return (
      <div
        style={{
          cursor: drawing ? 'crosshair' : 'grab',
          height: '100%',
          ...style,
        }}
        id='pdfImage'
      >
        <Map
          crs={CRS.Simple}
          zoom={(zoomDefault === undefined)? zoom: zoomDefault}
          minZoom={-50}
          maxZoom={maxZoom}
          center={(centerDefault === undefined)? [height / 2, width / 2] : centerDefault}
          zoomAnimation={false}
          zoomSnap={0.1}
          zoomControl={false}
          keyboard={false}
          attributionControl={false}
          onClick={this.handleClick}
          onZoom={e => { this.setState({ zoom: e.target.getZoom() })}}
          onMousemove={e => {this.setState({ cursorPos: e.latlng })}}
          onViewportChanged={this.handleViewPortChange}
          scrollWheelZoom={scrollWheelZoomFlag}
          ref={this.mapRef}
          key={this.props.leftWidth}
        >
           
          <ZoomControl position="bottomright"
            zoomInTitle={intl.get('_predict.detail.pdf.Canvas.Zoom in')} 
            zoomOutTitle={intl.get('_predict.detail.pdf.Canvas.Zoom out')}/>
          <Control className="leaflet-bar" position="bottomright">
            <Link
              title={intl.get('_predict.detail.pdf.Canvas.Zoom reset')}
              to="#"
              onClick={() => {
                const map = this.mapRef.current.leafletElement;
                map.setView([height / 2, width / 2], -1);
                localStorage.removeItem('zoom')
              }}
            >
              <Icon name="expand" fitted style={{ fontSize: '1.2em' }} />
            </Link>
          </Control>
          <Control className="leaflet-bar" position="bottomright">
            <Link
              title={intl.get('_predict.detail.pdf.Canvas.More')}
              to="#"
              onClick={() => {
                this.setState({showToolBar: !showToolBar})
              }}
            >
              <Icon name={showToolBar ? 'triangle down' : 'ellipsis horizontal'} fitted style={{ fontSize: '1.2em' }} />
            </Link>
          </Control>
          { showToolBar && <React.Fragment>
          {/* <Control className="leaflet-bar" position="bottomright">
            <Link
              title={intl.get('_predict.detail.pdf.Canvas.New bbox')}
              to="#"
              onClick={() => {
                onSelect(PREDICT_DEFAULT_LABEL_NAME)// new bbox, default type is word

              }}
            >
              <Icon name="pencil alternate" fitted style={{ fontSize: '1.2em' }} />
            </Link>
          </Control> */}
          <Control className="leaflet-bar" position="bottomright">
            <Link
              title={intl.get('_predict.detail.pdf.Canvas.rotate')}
              to="#"
              onClick={() => {
                if (isEditing) return;

                this.rotateDialog();
              }}
            >
              <Icon name="redo" fitted style={{ fontSize: '1.2em' }} disabled={isEditing} />
            </Link>
          </Control>
          {/* <Control className="leaflet-bar" position="bottomright" >
            <Link
              title={intl.get('_predict.detail.pdf.Canvas.Delete all bbox')}
              to="#"
              onClick={() => {
                if (isEditing) return
                this.delAllBBoxDialog();
              }}
            >
              <Icon
                name="times rectangle"
                fitted
                style={{ fontSize: '1.2em' }}
                disabled={isEditing}
              />
            </Link>
          </Control> */}
          <Control className="leaflet-bar" position="bottomright">
            <Link
              title={intl.get('_predict.detail.pdf.Canvas.Predict')}
              to="#"
              onClick={() => {
                if (isEditing) return

                this.predictDialog();
              }}
            >
              <Icon
                name="eye"
                fitted
                disabled={loading || isEditing}
                style={{ fontSize: '1.2em' }}
              />
            </Link>
          </Control>
          {/* <Control className="leaflet-bar" position="bottomright">
            <Link
              title={intl.get('_predict.detail.pdf.Canvas.Mapping')}
              to="#"
              onClick={() => {
                if (isEditing) return
                this.predictFsScrumMapDialog();
              }}
            >
              <Icon
                name="linkify"
                fitted
                style={{ fontSize: '1.2em' }}
                disabled={isEditing}

              />
            </Link>
          </Control> */}
          {/* <Control className="leaflet-bar" position="bottomright">
            <Link
              title={intl.get('_predict.detail.pdf.Canvas.short key')}
              to="#"
              onClick={() => onShortKeyPanelVisible()}
            >
              <Icon name="keyboard" fitted style={{ fontSize: '1.4em' }} />
            </Link>
          </Control> */}
          {!clickFlag && <Control className="leaflet-bar" position="bottomright">
            <Link
              title={intl.get('_predict.detail.pdf.Canvas.movelabeling')}
              to="#" 
              onClick={() => {
                  this.addPdfToImageDialog();
              }}
            >
              <Icon name="file pdf" 
                    fitted 
                    style={{ fontSize: '1.4em' }} 
                    />
            </Link>
          </Control>}
          </React.Fragment>}
          <ImageOverlay url={url} bounds={bounds} />
          {unfinishedDrawingDOM}
          {renderedTrace}
          {figuresDOM}
          {hotkeysDOM}
          {renderedLoader}
        </Map>
        {this.props.children}
      </div>
    );
  }
}

export default withBounds(Canvas);
