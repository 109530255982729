import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Menubar from '../common/Menubar';
import ProjectTable from './ProjectTable';
import TrainTable from './TrainTable';
import TrainMenu from './TrainMenu';

export default class TrainApp extends Component {
  render() {
    return (
      <Menubar active="train">
        <Switch>
          <Route
            exact
            path="/train/"
            component={ProjectTable}
          />
          <Route exact path="/train/:projectId" component={TrainTable} />
          <Route exact path="/train/:projectId/:trainId" component={TrainMenu} />
        </Switch>
      </Menubar>
    );
  }
}
