import React from 'react'
import Menubar from '../common/Menubar'
import { Container, Menu, Header } from 'semantic-ui-react'
import Preferences from './tenant-setting/TenantSetting'
import FsItemMapping from './fs-item-mapping'
import ConvertSubtitle from './convert-subtitle'
import PresetDiffMapping from './preset-diff-mapping'
// import TenantEdit from './tenant/TenantEdit'
import intl from 'react-intl-universal';
import { Switch, Route } from 'react-router-dom';
import api from '../api';
import { filterTab } from '../common/AuthUtil'

export default class System extends React.Component {
  state = {
    tabs: [
      {
        key: 'tenant_owner/tenant_setting',
        label: intl.get('_setting.Setting.TenantSetting'),
        url: '/setting/preferences'
      },
      {
        key: 'tenant_owner/fs_item_mapping',
        label: intl.get('_setting.Setting.FsItemMapping'),
        url: '/setting/fsItemMapping'
      },
      {
        key: 'tenant_owner/subtitle_conversion',
        label: intl.get('_setting.Setting.ConvertSubtitle'),
        url: '/setting/convertSubtitle'
      },
      // {
      //   label: intl.get('_setting.Setting.Tenant'),
      //   url: '/setting/tenant'
      // },
      {
        key: 'tenant_owner/preset_diff',
        label: intl.get('_setting.Setting.PresetDIFF'),
        url: '/setting/presetDiffMapping'
      },
    ],
    user: {}
  }

  async componentDidMount() {
    const info = (await api.get(`/dppapi/info`)).data;
    const policy = info.policy
    const allowTabs = filterTab(this.state.tabs, policy)
    const activeTab = allowTabs.length > 0 ? allowTabs[0]['url'] : ''
    this.setState({user: info ? info : '', tabs: allowTabs, activeTab}, () => { 
      if (activeTab) this.props.history.push(activeTab)
    });
  }

  render() {
    const { tabs, user } = this.state
    const { history, location } = this.props
    
    return (
      <Menubar active="setting">
        { (user && user.tenantName)? <Header as="h1">{user.tenantName}</Header>: null}
        <Container>
          <Menu pointing secondary>
            { 
              tabs.map(tab => {
                return <Menu.Item
                  name = {tab.label}
                  active={ location.pathname.indexOf(tab.url) > -1}
                  onClick={() => history.push(tab.url)}
                  key={tab.label}
                />
              })
            }
            </Menu>
            <Switch>
              <Route path="/setting/preferences" component={Preferences} />
              <Route path="/setting/fsItemMapping" component={FsItemMapping} />
              <Route path="/setting/convertSubtitle" component={ConvertSubtitle} />
              {/* <Route path="/setting/tenant" component={TenantEdit} /> */}
              <Route path="/setting/presetDiffMapping" component={PresetDiffMapping} />
              {/* <Redirect to={'/setting/preferences'} /> */}
            </Switch>
        </Container>
      </Menubar>
    )
  }
}
