import React, { useContext } from 'react'
import ExtraInfoFactory from '../../../project/result-function/ExtraInfoFactory'
import MyContext from '../../../context-manager'
export default function ResultFunctionContainer(props) {

  const { handleOperateDialog } = useContext(MyContext)

  const { resultFunctionParam } = props

  const { title, type, extraInfo, pdfId, projectId, callback, pdfInfo, actions, setResultFunctionParam } = resultFunctionParam
  return <div className="rf-newui-container">
    <div className="rf-newui-title">{title}</div>
    {extraInfo && extraInfo.length > 0 ?
      <>
        {ExtraInfoFactory(extraInfo, handleOperateDialog, pdfId, projectId, callback, type, pdfInfo, actions, setResultFunctionParam)}
      </> : null}
  </div>
}