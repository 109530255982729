import React, { Component } from 'react';
import { Form, Input, Button, Segment, Message, Loader, Label } from 'semantic-ui-react';
import './ItemSearchPopup.css';
import api from '../../../api'
import intl from 'react-intl-universal';
import { MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact';
import { MDBDataTable } from 'mdbreact';
import shallowEqualObjects from 'shallow-equal/objects';
import { CloseIcon } from '../../../assets/icon';
import { AnalyticsServiceContext } from '../../../common/Analytics/AnalyticsServiceProvider';
import { EventName, EventType, PageName } from '../../../common/Analytics/analyticsMetrics';
export default class ItemSearchPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      fsItemRslt: null,
      selectItem: null,
      maxFlag: false,
      loading: false,
    };
  }
  static contextType = AnalyticsServiceContext;

  shouldComponentUpdate(nextProps, nextState) {
    if (
      shallowEqualObjects(this.state, nextState)
    ) {
      return false;
    }
    return true;
  }

  componentDidUpdate() {
    const list = document.getElementsByClassName('form-control form-control-sm')
    for( var i=0;i<list.length;i++){
      if(list[i].nodeName === 'INPUT'){
        list[i].placeholder = 'さらに絞り込む'
      }
    }
  }

  handleToggle = (flag) => {
    this.props.toggleSearch(flag);
    this.setState({
      form: {},
    });
  }

  handleInput = (event) => {
    const { name, value } = event.target;
    const { form } = this.state;
    const fsItemForm = {};
    fsItemForm[name] = value;
    this.setState({
      form: { ...form, ...fsItemForm },
    });
  };

  handleSearch = async () => {
    const { subTitle = '', item = '' } = this.state.form;
    const { category } = this.props;
    this.setState({ loading: true });
    const { data } = await api.get(
      `/dppapi/predict/search-sfm?category=${category}&subTitle=${subTitle}&item=${item}&limit=50`
    );
    this.setState({ fsItemRslt: data.list, maxFlag: data.page && data.page.total > 50, loading: false });
  };

  handleLinkTo = ( value ) => {
    const { handleSelectSearchItem } = this.props;
    if (handleSelectSearchItem) {
      handleSelectSearchItem(value)
    }
    this.handleToggle(false)
  }

  handleClickSearchButton = async () => {
    await this.handleSearch();
    this.context.sendPredictBeautifyDetailMetrics({
      page_name: PageName.PredictDetailPage,
      event_type: EventType.Action,
      event_name: EventName.SearchItem,
    })
  }

  reanderTable = () => {
    const { fsItemRslt, maxFlag } = this.state;
    if (!fsItemRslt || fsItemRslt.length === 0) {
      return (<Message info header="該当データがありません。" content="条件を変更の上、再度検索してください。" />);
    }

    const columns = [
      {
        label: '区分',
        field: 'subTitle',
        sort: 'asc',
      },
      {
        label: '科目名',
        field: 'item',
        sort: 'asc',
      },
      {
        label: '連携先',
        field: 'fsItem',
        sort: 'asc',
        color: 'blue',
      },
      {
        label: '',
        field: 'operation',
        sort: 'disabled'
      },
    ];
    const rows = fsItemRslt.map(d => {
      return {
        subTitle: d.subTitle,
        item: d.item,
        fsItem: d.fsItem,
        operation: (
          <Button size="small" className="ui-item-search-popup-btn" onClick={() => this.handleLinkTo(d.fsItem)}>
            移動
          </Button>
        )
      }
    })
    const data = {
      columns: columns,
      rows: rows,
    };

    return (
      <div>
        <Segment className="ui-item-search-popup-seg">
          <MDBDataTable btn bordered hover data={data} onPageChange={() => console.log('page change')} 
            theadColor="ui-item-search-popup-table-header"
            tbodyColor="ui-item-search-popup-table-body"
            entriesLabel='表示件数' 
            entriesOptions={[10,20,50]}
            searchLabel=' '
            infoLabel={["", "-", "/", "件"]} 
            paginationLabel={["前へ", "次へ"]}
            noRecordsFoundLabel="該当データがありません。条件を変更の上、再度検索してください。"
          />
        {maxFlag ? (<label className="ui-item-search-popup-label">検索結果は最大50件まで表示できます</label>) : null}
        </Segment>
      </div>
    )
  }

  render() {
    const { fsItemRslt, loading } = this.state;
    const table = this.reanderTable();
    return (
      <div className='ui-item-search-popup-container'>
        <MDBModal isOpen={this.props.isShowEdit} toggle={this.handleToggle} className="ui-item-search-dialog">
          <div className="ui-item-search-popup-close-div">
            <CloseIcon className="ui-item-search-popup-close-icon" onClick={() => this.handleToggle(false)}/>
          </div>
          <MDBModalHeader className="ui-modal-dialog-header" >勘定科目辞書<Label basic className="ui-item-search-popup-header-label">[{this.props.category}]</Label></MDBModalHeader>
          <MDBModalBody className="ui-modal-dialog-body">
            {loading ? (<Loader active inline="centered" />)
              : (
                <div className="ui-modal-dialog-body-div">
                  <Segment className="ui-modal-dialog-segment">
                    <Form>
                      <Form.Group inline>
                        <Form.Field>
                          <label className="ui-item-search-popup-condition-label">区分</label>
                          <Input
                            name="subTitle"
                            placeholder="例）流動資産"
                            defaultValue={this.state.form.subTitle}
                            onChange={(event) => this.handleInput(event)}
                            className="ui-item-search-popup-input"
                          />
                        </Form.Field>
                        <Form.Field>
                          <label className="ui-item-search-popup-condition-label">科目名</label>
                          <Input
                            name="item"
                            placeholder="例）現金預金"
                            value={this.state.form.item}
                            onChange={(event) => this.handleInput(event)}
                            className="ui-item-search-popup-input"
                          />
                        </Form.Field>
                        <Form.Field className="ui-item-search-popup-field">
                          <Button size="small" className="ui-item-search-popup-inline-btn" onClick={this.handleClickSearchButton}> 
                            {intl.get('_predict.detail.pdf.MuiPopup.ItemSearch.Search')}
                          </Button>
                        </Form.Field>
                      </Form.Group>
                    </Form>
                  </Segment>
                  {fsItemRslt ? table : null}
                </div>
              )}
          </MDBModalBody>
        </MDBModal>
      </div>
    );
  }
}
