import React, { Component } from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact';
import {
  // Segment,
  Form,
  // Divider,
  Button,
  Icon,
  // Message,
  Loader,
  // Dimmer,
} from 'semantic-ui-react';
import CSV from 'comma-separated-values';
import { chunk } from 'lodash';
import api from '../../api';

export default class Import extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loadingText: '',
    };
  }

  handleImport = async () => {
    const fileEle = document.getElementById('ConvertSubtitleCsv');
    if (fileEle.files && fileEle.files[0]) {
      this.setState({ loading: true });

      const csvRslt = await new Promise((resolve) => {
        const file = fileEle.files[0];
        const fileReader = new FileReader();
        fileReader.onload = () => {
          resolve(CSV.parse(fileReader.result, { cast: ['String', 'String', 'String'] }));
        };
        fileReader.readAsText(file);
      });

      const csvChunks = chunk(csvRslt, 200);
      for (let [index, itemChunk] of csvChunks.entries()) {
        const items = itemChunk.map((arr) => {
          return {
            subtitleName: arr[0],
            convertSubtitle: arr[1],
            category: arr[2],
          };
        });
        await api.put(`/dppapi/tenant-setting/convert-subtitle-batch`, {
          items: items,
          mode: index === 0 ? 'init' : 'append'
        });
        this.setState({
          loadingText: Math.floor(((index + 1) / csvChunks.length) * 100) + '%',
        });
      }

      this.clearForm();
      this.props.toggleImport(false);
      this.props.handleSearch();
    }
  };

  clearForm = () => {
    this.setState({
      loading: false,
      loadingText: '',
    });
  };

  handleCancel = () => {
    this.clearForm();
    this.props.toggleImport(false);
  };

  render() {
    const { loading, loadingText } = this.state;

    return (
      <MDBModal
        isOpen={this.props.isShowImport}
        toggle={this.props.toggleImport}
      >
        <MDBModalHeader>Import Convert Subtitle</MDBModalHeader>
        <MDBModalBody>
          {loading ? (
            <Loader active inline="centered">
              {loadingText}
            </Loader>
          ) : (
            <Form>
              <Form.Field>
                <Form.Input
                  label={'CSV File'}
                  multiple
                  type="file"
                  id="ConvertSubtitleCsv"
                  name="ConvertSubtitleCsv"
                  accept=".csv"
                />
              </Form.Field>
              <Button
                size="small"
                onClick={() => this.handleImport()}
                floated="right"
              >
                <Icon name="save" />
                Import
              </Button>
              <Button
                size="small"
                onClick={() => this.handleCancel()}
                floated="right"
              >
                <Icon name="cancel" />
                Cancel
              </Button>
            </Form>
          )}
        </MDBModalBody>
      </MDBModal>
    );
  }
}
