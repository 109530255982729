import React, { Component } from 'react'
import { Table, Checkbox } from 'semantic-ui-react'

export const policyList = {
  project: [
    { "obj": "project", "sub_obj": "edit", "act": "read", "eft": "allow" },
    { "obj": "project", "sub_obj": "edit", "act": "write", "eft": "allow" },
    { "obj": "project", "sub_obj": "label", "act": "read", "eft": "allow" },
    { "obj": "project", "sub_obj": "label", "act": "write", "eft": "allow" },
    { "obj": "project", "sub_obj": "result_table", "act": "read", "eft": "allow" },
    { "obj": "project", "sub_obj": "result_table", "act": "write", "eft": "allow" },
    { "obj": "project", "sub_obj": "result_function", "act": "read", "eft": "allow" },
    { "obj": "project", "sub_obj": "result_function", "act": "write", "eft": "allow" },
    { "obj": "project", "sub_obj": "user", "act": "read", "eft": "allow" },
    { "obj": "project", "sub_obj": "user", "act": "write", "eft": "allow" },
    { "obj": "project", "sub_obj": "metadata", "act": "read", "eft": "allow" },
    { "obj": "project", "sub_obj": "metadata", "act": "write", "eft": "allow" },
    { "obj": "project", "sub_obj": "parsing_function", "act": "read", "eft": "allow" },
    { "obj": "project", "sub_obj": "parsing_function", "act": "write", "eft": "allow" },
    { "obj": "project", "sub_obj": "ai_process", "act": "read", "eft": "allow" },
    { "obj": "project", "sub_obj": "ai_process", "act": "write", "eft": "allow" },
    { "obj": "project", "sub_obj": "working_status", "act": "read", "eft": "allow" },
    { "obj": "project", "sub_obj": "working_status", "act": "write", "eft": "allow" },
    { "obj": "project", "sub_obj": "character_replacement", "act": "read", "eft": "allow" },
    { "obj": "project", "sub_obj": "character_replacement", "act": "write", "eft": "allow" },
    { "obj": "project", "sub_obj": "api_function", "act": "read", "eft": "allow" },
    { "obj": "project", "sub_obj": "api_function", "act": "write", "eft": "allow" }
  ],
  user: [
    { "obj": "user", "sub_obj": "user", "act": "read", "eft": "allow" },
    { "obj": "user", "sub_obj": "user", "act": "write", "eft": "allow" },
    { "obj": "user", "sub_obj": "role", "act": "read", "eft": "allow" },
    { "obj": "user", "sub_obj": "role", "act": "write", "eft": "allow" },
  ],
  label: [
    { "obj": "label", "sub_obj": "search_images", "act": "read", "eft": "allow" },
    { "obj": "label", "sub_obj": "search_images", "act": "write", "eft": "allow" },
    { "obj": "label", "sub_obj": "distribution", "act": "read", "eft": "allow" },
    { "obj": "label", "sub_obj": "distribution", "act": "write", "eft": "allow" },
    { "obj": "label", "sub_obj": "upload_images", "act": "read", "eft": "allow" },
    { "obj": "label", "sub_obj": "upload_images", "act": "write", "eft": "allow" },
    { "obj": "label", "sub_obj": "upload_video", "act": "read", "eft": "allow" },
    { "obj": "label", "sub_obj": "upload_video", "act": "write", "eft": "allow" },
    { "obj": "label", "sub_obj": "training_data", "act": "read", "eft": "allow" },
    { "obj": "label", "sub_obj": "training_data", "act": "write", "eft": "allow" },
    { "obj": "label", "sub_obj": "reference", "act": "read", "eft": "allow" },
    { "obj": "label", "sub_obj": "reference", "act": "write", "eft": "allow" },
    { "obj": "label", "sub_obj": "ml_assist", "act": "read", "eft": "allow" },
    { "obj": "label", "sub_obj": "ml_assist", "act": "write", "eft": "allow" },
    { "obj": "label", "sub_obj": "all_images", "act": "read", "eft": "allow" },
    { "obj": "label", "sub_obj": "all_images", "act": "write", "eft": "allow" }
  ],
  system: [
    { "obj": "system", "sub_obj": "preferences", "act": "read", "eft": "allow" },
    { "obj": "system", "sub_obj": "preferences", "act": "write", "eft": "allow" },
    { "obj": "system", "sub_obj": "online_users", "act": "read", "eft": "allow" },
    { "obj": "system", "sub_obj": "online_users", "act": "write", "eft": "allow" },
    { "obj": "system", "sub_obj": "tenants", "act": "read", "eft": "allow" },
    { "obj": "system", "sub_obj": "tenants", "act": "write", "eft": "allow" },
    { "obj": "system", "sub_obj": "license_key", "act": "read", "eft": "allow" },
    { "obj": "system", "sub_obj": "license_key", "act": "write", "eft": "allow" },
    { "obj": "system", "sub_obj": "machines", "act": "read", "eft": "allow" },
    { "obj": "system", "sub_obj": "machines", "act": "write", "eft": "allow" },
    { "obj": "system", "sub_obj": "black_ip", "act": "read", "eft": "allow" },
    { "obj": "system", "sub_obj": "black_ip", "act": "write", "eft": "allow" },
    { "obj": "system", "sub_obj": "fs_item_mapping", "act": "read", "eft": "allow" },
    { "obj": "system", "sub_obj": "fs_item_mapping", "act": "write", "eft": "allow" },
    { "obj": "system", "sub_obj": "feedback", "act": "read", "eft": "allow" },
    { "obj": "system", "sub_obj": "feedback", "act": "write", "eft": "allow" },
    { "obj": "system", "sub_obj": "pdf_search", "act": "read", "eft": "allow" },
    { "obj": "system", "sub_obj": "pdf_search", "act": "write", "eft": "allow" },
    { "obj": "system", "sub_obj": "rabbitmq", "act": "read", "eft": "allow" },
    { "obj": "system", "sub_obj": "rabbitmq", "act": "write", "eft": "allow" }],
  tenant_owner: [
    { "obj": "tenant_owner", "sub_obj": "tenant_setting", "act": "read", "eft": "allow" },
    { "obj": "tenant_owner", "sub_obj": "tenant_setting", "act": "write", "eft": "allow" },
    { "obj": "tenant_owner", "sub_obj": "fs_item_mapping", "act": "read", "eft": "allow" },
    { "obj": "tenant_owner", "sub_obj": "fs_item_mapping", "act": "write", "eft": "allow" },
    { "obj": "tenant_owner", "sub_obj": "subtitle_conversion", "act": "read", "eft": "allow" },
    { "obj": "tenant_owner", "sub_obj": "subtitle_conversion", "act": "write", "eft": "allow" },
    { "obj": "tenant_owner", "sub_obj": "preset_diff", "act": "read", "eft": "allow" },
    { "obj": "tenant_owner", "sub_obj": "preset_diff", "act": "write", "eft": "allow" }]
}
export default class Policy extends Component {

  renderBody = () => {
    const { policy, handlePolicyEffectChange } = this.props
    const rows = []
    Object.keys(policy).forEach(key => {
      const obj = policy[key]
      obj.forEach((row, idx) => {
        rows.push(<Table.Row key={`${key}_${idx}`}>
          {idx === 0 ? <Table.Cell rowSpan={obj.length} textAlign="center" verticalAlign="middle" >{row.obj}</Table.Cell> : <></>}
          {idx % 2 === 0 ? <Table.Cell rowSpan={2} textAlign="center" verticalAlign="middle" >{row.sub_obj}</Table.Cell> : <></>}
          {/* <Table.Cell >{row.sub_obj}</Table.Cell> */}
          <Table.Cell >{row.act}</Table.Cell>
          <Table.Cell ><Checkbox checked={row.eft === 'deny'} onChange={(e, data) => handlePolicyEffectChange(row.obj, row.sub_obj, row.act, data.checked)} /></Table.Cell>
        </Table.Row>)
      })
    })

    return rows
  }

  render() {
    return (<div className="policy-body">
      <Table celled structured >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>function</Table.HeaderCell>
            <Table.HeaderCell>sub function</Table.HeaderCell>
            <Table.HeaderCell>action</Table.HeaderCell>
            <Table.HeaderCell>deny</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body >
          {this.renderBody()}
        </Table.Body>
      </Table>
    </div>)
  }
}