import React, { Component } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

import { Input, Popup } from 'semantic-ui-react';
import { CancelIcon, ChevronRight } from '../../../assets/icon';
import _ from 'lodash';

import EditBox from './EditBox';
import { ManualInputStatus } from './FsConstant';
import BoardPopupContent from './BoardPopupContent';
import { formatMoney, handleDeleteCommaFromMoney, ignoreSpecialCharForCalc } from '../../Util';
import { EventName, EventType, FixAmountSource, PredictDetailPageLocation, ViewMode } from '../../../common/Analytics/analyticsMetrics';
import { AnalyticsServiceContext } from '../../../common/Analytics/AnalyticsServiceProvider';

let isComposition = false;
const isChrome = navigator.userAgent.indexOf('Chrome') > -1;
export default class BoardItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keepOpen: false,
      keepHover: false,
      popItemName: '',
      // isOpen: false,
      editing: false,
      numerical: [],
      isChanging: false,
      lastKeyPressed : "",
      calcInputError: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleComposition = this.handleComposition.bind(this);
    this.contextRef = React.createRef();
  }
  static contextType = AnalyticsServiceContext;

  componentDidUpdate(prevProps) {
    const { row, selectedCell } = this.props;

    if ((this.state.keepOpen || this.state.keepHover) &&
      selectedCell && selectedCell.id && selectedCell.id !== ''
      && this.state.componentName !== 'boardPopup') {
      if (!_.isEmpty(selectedCell.cellIds) && !_.find(row.values, { id: selectedCell.cellIds[1] })) {
        this.setState({ keepOpen: false, keepHover: false });
      }
    }
  }

  onHoverChange = (state, name) => {
    this.setState({ keepHover: state, popItemName: name ? name : 'EditBox' });
  }

  onKeepOpen = (state, componentName) => this.setState({ keepOpen: state, componentName: componentName });

  onSetTableNotHoverable = () => {
    const { selectedCell, handleCellOperation, detail, row, onOpenModeChange } = this.props;
    if (selectedCell.id === '') { // when this row had been selected by single click
      handleCellOperation(row, detail);
    }
    onOpenModeChange(false);
  }

  handleOpenInputMode = () => {
    const { onInputModeChange, onEdit, row, inputCalc } = this.props;
    this.setState({ editing: true }, () => {
      this.input.focus();
      this.input.select();
    });
    onInputModeChange(row.scrumItem, ManualInputStatus.INPUT);
    onEdit(true);
    this.onSetTableNotHoverable();

    if(inputCalc){
      this.setState({
        numerical: [row.scrumItem, inputCalc, false]
      })
    }
  }

  handleSaveData = (initValue, r, e) => {
    
    let convertText = initValue;
    if (initValue && initValue.startsWith('=')) {
      convertText = convertText.substring(1);
      if(!convertText){
        this.setState({ numerical: [r.scrumItem, '', true], editing: false,isChanging: false, });
      }else{
        try {
          let editValue = initValue.replace(/,/g, '');
          editValue = ignoreSpecialCharForCalc(editValue);
          convertText = new Intl.NumberFormat('ja-JP', { style: 'decimal', currency: 'CAD', }).format(Math.round(eval(editValue.substring(1))));
          this.setState({ numerical: [r.scrumItem, convertText, false], editing: false,isChanging: false, });
        } catch (error) {
          convertText = intl.get('_predict.detail.mapping.Formula input error');
          this.setState({ calcInputError: true});
          this.props.changeCalcInputError(true)
          return false
        }
      }
    }else{
      this.setState({editing: false,isChanging: false,});
    }
    if (convertText && convertText.replace('-', '').length > 18) {
      convertText = intl.get('_predict.detail.mapping.More than 14 digits');
      this.setState({ numerical: [r.scrumItem, '', true] });
    }
    this.props.handleInputChange(
      r.scrumItem,
      !this.state.numerical[1] || initValue.startsWith('=') ? convertText : this.state.numerical[1],
      initValue && initValue.startsWith('=')&&convertText? initValue: ''
    );
    if (convertText === this.props.onGetSum(r.values)) {
      this.setState({ editing: false })
      this.props.onInputModeChange(r.scrumItem, ManualInputStatus.LINK);
      this.props.onEdit(true);
    }
    this.setState({ calcInputError: false});
    this.props.changeCalcInputError(false)
    return true;
  }

  handleComposition(e, r) {
    if (e.type === 'compositionend') {
      isComposition = false;
      let data = e.target.value.replace(/[^0-9,-]/g, '');
      if (!isComposition && isChrome) {
        this.handleChange(e, data, r);
      }
    } else {
      isComposition = true;
    }
  }
  /* eslint no-eval: 0 */
  handleChange = (e, money, r) => {
    //It can be triggered only when the input method is not used or after using the input method
    if (!isComposition) {
      let target = e.target;

      const startPosition = target.selectionStart;

      const prevMoney = this.state.numerical[1] ? String(this.state.numerical[1]) : this.props.onGetSum(r.values);

      const [currentMoney , diffPosition] = handleDeleteCommaFromMoney(prevMoney, money, startPosition, this.state.lastKeyPressed)

      const [convertText, finalPosition, finalSetFlag, headMinusFlag] = formatMoney(target.selectionStart, currentMoney);
      if (headMinusFlag) {
        if (convertText.length > 19 && !convertText.startsWith('=')) return;
      } else {
        if (convertText.length > 18 && !convertText.startsWith('=')) return;
      }
      if (finalSetFlag) {
        this.setState({ numerical: [r.scrumItem, convertText, false] });
      }
      this.setState({}, () => {
        target.selectionStart = finalPosition + diffPosition;
        target.selectionEnd = finalPosition + diffPosition;
      })
    } else {
      this.setState({ numerical: [r.scrumItem, money, false] });
    }
    this.setState({lastKeyPressed : ""});
    this.setState({ isChanging: true });
    this.props.changeCalcInputError(false)
    this.props.changeCalcInputHint(money.startsWith('='))
  }

  // setIsOpen = val => {
  //   this.setState({ isOpen: val });
  // };

  componentWillReceiveProps(newProps) {
    this.setState({
      location: newProps.location,
    });
  }

  onOpenInput = (state) =>
    this.setState({ editing: state }, () => {
      const { handleCellOperation, detail, row, onSelected, selectedCell } = this.props;
      if (state) {
        onSelected && onSelected(true);
        if (!selectedCell || selectedCell.id !== row.id) {
          handleCellOperation(row, detail);
        }
        this.handleOpenInputMode();
        this.props.handleCellAction('penIcon');
      }
    });

  renderTrigger = (isDisabled) => {
    const {
      row,
      pdfInfo,
      selectedCell,
      openable,
      detailItemChanging,
      isDisabledItem,
      isPortalOpen,
      handleChangeValue,
      handleSearchReferItem,
      handleUnLinkScrumItem,
      handleSetOppositeValue,
      handleCellOperation,
      onGetSum,
      onEdit,
      onOpenModeChange,
      onUpdateBBox,
      detail,
      isTotal,
      isNotOwner,
      onShowExclusionControl,
    } = this.props;

    const {
      editing,
      // isOpen,
      keepHover,
      keepOpen,
      popItemName
    } = this.state;

    let popupStyle = {};
    const bodyHeight = document.body.scrollHeight;
    const currentRowElement = row.scrumItem && document.getElementById(`${row.scrumItem}-list-board`) && document.getElementById(`${row.scrumItem}-list-board`).getBoundingClientRect();
    if (currentRowElement && detail) {
      const currentRowBottom = currentRowElement.bottom;
      const popupHeight = bodyHeight - currentRowBottom - 8;
      popupStyle = detail.length * 74 > bodyHeight - currentRowBottom ? { height: `${popupHeight}px` } : {};
    }

    const isManual = row.manualInput === undefined;
    return (
      <span
        className={`${editing || isDisabledItem || (row.manualInput !== undefined && !row.isRefer) ? 'board-item-wrap-bottom-icon-disabled' : 'board-item-wrap-bottom-icon'
          }`}
        style={{ marginLeft: '2px' }}
      >

        {row.isRefer ? (
          isDisabled ? <ChevronRight disabled /> : 
          <Popup
            trigger={
              <span className="close-btn-icon-link"
                onClick={(e) => {
                  e.preventDefault();
                  if (!isPortalOpen && handleSearchReferItem) {
                    handleSearchReferItem(row.originalItem, row.originalPageType);
                  }
                }}>
                <ChevronRight />
              </span>
            }
            position="right center"
            content={intl.get('_predict.detail.mapping.Go to reference')}
            on="hover"
            hideOnScroll
            inverted
          />
        )
        :
        (!editing && row.manualInput === undefined ?
          (
            // isOpen ?
            // <Icon
            //   name={'chevron left'}
            //   disabled={isDisabled}
            // /> :
            isDisabled ? <ChevronRight disabled /> :
            <>
                <Popup
                  trigger={
                    <span
                      key={isTotal ? row.scrumItem + '-close-btn-total' : row.scrumItem + '-close-btn'}
                      className="close-btn-icon-link"
                      ref={this.contextRef}
                      onClick={() => {
                        this.setState({ keepOpen: !keepOpen });
                        this.context.sendPredictBeautifyDetailMetrics({
                          event_type: EventType.Action,
                          event_name: EventName.OpenDetails,
                          custom_parameter: {
                            view_mode: ViewMode.Card,
                          },
                        })
                      }}>
                      <ChevronRight disabled={isDisabled} />
                    </span>
                  }
                  position="right center"
                  content={intl.get('_predict.detail.mapping.Open details')}
                  on="hover"
                  disabled={keepOpen}
                  hideOnScroll
                  inverted
                />
                {detail ?
                  <Popup
                    key={isTotal ? row.scrumItem + '-total' : row.scrumItem}
                    id={isTotal ? row.scrumItem + '-right-total' : row.scrumItem + '-right'}
                    className="value-table-pop-item"
                    style={popupStyle}
                    // disabled={isDisabled && (!selectedBBox || selectedBBox.id === '')}
                    trigger={""}
                    context={this.contextRef}
                    content={
                      <BoardPopupContent
                        detail={detail}
                        row={row}
                        onGetSum={onGetSum}
                        handleUnLinkScrumItem={handleUnLinkScrumItem}
                        onEdit={onEdit}
                        handleSetOppositeValue={handleSetOppositeValue}
                        handleCellOperation={handleCellOperation}
                        selectedCell={selectedCell}
                        manualInput={row.manualInput}
                        onHoverChange={this.onHoverChange}
                        keepHover={keepHover}
                        popItemName={popItemName}
                        onKeepOpen={this.onKeepOpen}
                        showEditBtn={true}
                        pdfInfo={pdfInfo}
                        handleChangeValue={handleChangeValue}
                        openable={openable}
                        onOpenModeChange={onOpenModeChange}
                        onSetTableNotHoverable={this.onSetTableNotHoverable}
                        onUpdateBBox={onUpdateBBox}
                        detailItemChanging={detailItemChanging}
                        isNotOwner={isNotOwner}
                        onShowExclusionControl={onShowExclusionControl}
                      />
                    }
                    hideOnScroll
                    flowing
                    basic
                    open={isManual && keepOpen}
                    offset={[-11, 2]}
                    position="right center"
                    onOpen={() => {
                      if (selectedCell && selectedCell.id !== row.id) {
                        handleCellOperation(row, detail);
                      }
                    }}
                    onClose={() => {
                      this.setState({ isSelected: false });
                      let isPopup = false;
                      detail.forEach((item) => {
                        if (item.item.text === popItemName || item.item.text === '') {
                          isPopup = true;
                        }
                      });
                      if (isPopup && keepHover) {
                        this.setState({ keepOpen: true })
                      } else {
                        this.setState({ keepOpen: false })
                        // this.itemRef &&
                        //   this.itemRef.current &&
                        //   this.itemRef.current.setIsOpen(false);
                        if (!isPortalOpen) handleCellOperation(row, detail, 'close');
                      }
                    }}
                  /> 
                    : null}
                </>
              )
          :
          this.renderInputTrigger(row)
          )
        }
      </span>
    );
  };

  renderInputTrigger = val => {
    const { onInputModeChange, onEdit, onOpenModeChange, changeCalcInputError, changeCalcInputHint } = this.props;

    return (
      <Popup
        trigger={
          <span
            onMouseDown={e => {
              e.stopPropagation();
              this.setState({ numerical: [] })
              this.setState({ editing: false });
              onInputModeChange(val.scrumItem, ManualInputStatus.LINK);
              onEdit(true);
              onOpenModeChange(true);
              changeCalcInputError(false);
              changeCalcInputHint(false);
            }}
          >
            <CancelIcon color='#909090' size='24px' className='edit-box-icon-cancel' />
          </span>
        }
        position="right center"
        content={intl.get(
          '_predict.detail.mapping.Cancellation of manual input'
        )}
        on="hover"
        hideOnScroll
        inverted
      />
    );
  };

  render() {
    const {
      row,
      isPortalOpen,
      onGetSum,
      detailData,
      currentPageType,
      popups,
      // selectedCell,
      isCancel,
      inputCalc,
      onOpenModeChange,
      filterItems,
      isTotal,
      isNotOwner,
      onShowExclusionControl,
      calcInputError,
    } = this.props;
    const { props } = this;
    const {
      // isOpen,
      editing,
      numerical,
      isChanging,
      keepHover
    } = this.state;

    const isDisabled = !row.values || row.values.length <= 0 || isPortalOpen;
    const isManualInput = row.manualInput === undefined;
    // const clsOneMore = row.values.length > 0 ? 'one-or-more' : '';
    // const clsInput = isManualInput ? 'clsOneMore' : 'is-manual-input';
    const divHover = keepHover ? 'edit-box-div-border' : '';
    const currentBoard = document.getElementById(`${row.scrumItem}-list-board`);
    if (currentBoard) {
      if (keepHover) {
        currentBoard.classList.add("board-tr-backGround");
      } else {
        currentBoard.classList.remove("board-tr-backGround");
      }
    }
    const isFiltering = filterItems && row.scrumItem === filterItems.at(-1);
    const textColor = isFiltering ? ' text-selected-color' : '';
    const backGroundColor = isFiltering ? ' text-selected-backGround' : '';
    let initValue;
    if (!numerical[2] && (numerical[1] === undefined || (isCancel && !isChanging))) {
     if (row.manualInput !== undefined) {
        initValue = row.manualInput;
        if(inputCalc){
          initValue = inputCalc
        }
      } else if (row.values.length > 0) {
        initValue = onGetSum(row.values);
      }
    } else {
      initValue = numerical[1];
    }

    let isError;
    if (detailData.manual_input && currentPageType in detailData.manual_input) {
      const manualValue = detailData.manual_input[currentPageType][row.scrumItem];
      isError =
        manualValue === intl.get('_predict.detail.mapping.Formula input error') ||
        manualValue === intl.get('_predict.detail.mapping.More than 14 digits');
    }

    var renderEditBox = (parentElement) => {
      return <EditBox
        rowScrumItem={row.scrumItem}
        rowValues={row.values}
        manualInput={row.manualInput}
        onOpenInput={this.onOpenInput}
        onHoverChange={this.onHoverChange}
        isDisabledCard={row.values.length <= 0}
        parentElement={parentElement}
        popups={popups}
        showEditBtn={true}
        isNotOwner={isNotOwner}
        onShowExclusionControl={onShowExclusionControl}
        {...props}
      />;
    }

    var referEditBox = (parentElement) => {
      return <EditBox
        rowScrumItem={row.scrumItem}
        rowValues={row.values}
        manualInput={row.manualInput}
        onOpenInput={this.onOpenInput}
        onHoverChange={this.onHoverChange}
        isDisabledCard={row.values.length <= 0}
        parentElement={parentElement}
        popups={popups}
        showEditBtn={false}
        isBSLine={true}
        isNotOwner={isNotOwner}
        onShowExclusionControl={onShowExclusionControl}
        {...props}
      />;
    }

    const inputCalcHint = initValue && initValue.startsWith('=') && !calcInputError;
    return (
      <React.Fragment key={isTotal ? row.scrumItem + '-total' : row.scrumItem}>
        <div className={`board-item-wrap-top${textColor}`}>
          <span className="board-item-wrap-top-text">{row.scrumItem}</span>
        </div>
        <div className={`board-item-wrap-bottom${textColor} ${row.isRefer ?'refer-aggregation-value' : ''}`} 
          style={{ height: inputCalcHint?'50px':(calcInputError ? '72px' : '30px'), width: '220px' }}>
          {!editing && isManualInput ? (
            row.isRefer ?(
              referEditBox(
                <div className={`ui simple item edit-box-normal-div-list ${backGroundColor}`}
                  id={'item' + row.id}
                  style={{ cursor: 'auto' }}
                >
                  {onGetSum(row.values) || '-'}
                </div>)
            ) :(
              renderEditBox(
                <div className={`ui simple item edit-box-item ${divHover} ${backGroundColor}`}
                  id={'item' + row.id}
                  // onMouseEnter={() => {
                  //   this.onHoverChange(true, row.scrumItem);
                  // }}
                  onClick={(e) => {
                    if(e.detail === 2 && this.context.predictDetailViewMode !== "" ){
                      this.context.sendPredictBeautifyDetailMetrics({
                        event_type: EventType.Action,
                        event_name: EventName.FixAmount,
                        custom_parameter: {
                          location : PredictDetailPageLocation.ScrumItem,
                          source : FixAmountSource.TextField,
                          view_mode : ViewMode.Card,
                        },
                      })
                    }
                  }}
                >
                  {onGetSum(row.values) || '-'}
                </div>
              )
            )
            
          ) : !editing && !isManualInput ? (
            row.isRefer ? (
              referEditBox(
                <div className={`${backGroundColor} edit-box-normal-div-list`} style={{ cursor: 'auto' }}>
                  {detailData.manual_input[row.originalPageType][row.originalItem] || ''}
                </div>)
            ) : (
              renderEditBox(
                <div className={`${divHover} ${backGroundColor} ${isError ? 'edit-box-span-err' : 'edit-box-span'}`}>
                  {detailData.manual_input[currentPageType][row.scrumItem] || ''}
                </div>
              )
            )
            
          ) : (
            <div className="board-item-wrap-bottom-input">
              <Input
                className={`${initValue && initValue.startsWith('=') ? 'td-selectable-input-calculate' : 'td-selectable-input'} ${calcInputError?'input-mode-calc-error-border-input':''}`}
                value={initValue || ''}
                placeholder='半角数字入力'
                ref={(input) => this.input = input}
                onChange={(e, data) => {
                  this.handleChange(e, data.value, row);
                }}
                onClick={(e)=>{
                  e.stopPropagation();
                }}
                onCompositionStart={(e) => { this.handleComposition(e, row) }}
                onCompositionEnd={(e) => { this.handleComposition(e, row) }}
                onKeyDown={key => {
                  if(key.keyCode === 8){
                    this.setState({ lastKeyPressed : "Backspace"})
                  }else if ( key.keyCode === 46){
                    this.setState({ lastKeyPressed : "Delete"})
                  }
                  if (key.keyCode === 13) {
                    key.stopPropagation();
                    key.preventDefault();
                    this.handleSaveData(initValue, row, key);
                    onOpenModeChange(true);
                  }
                }}
                onBlur={(e) => {
                  const res = this.handleSaveData(initValue, row, e);
                  const rootElement = document.getElementById('root');
                  rootElement.addEventListener('click', (e) => {
                    if(!res){
                      e.preventDefault();
                      e.stopPropagation();
                      this.input.focus();
                      this.input.select();
                    }
                    onOpenModeChange(true);
                  }, { once: true });
                }}
              />
              {initValue && initValue.startsWith('=') && !calcInputError ? (<label className="input-mode-calc-hint-label">四則演算記号: +-*/</label>): null}
              {calcInputError ? (<label className="input-mode-calc-hint-error-label" style={{width:'160px'}}>数式を正しく入力してください</label>): null}
            </div>
          )}
          <div className="icon-divider icon-divider-list" />
          {this.renderTrigger(isDisabled)}
        </div>
      </React.Fragment>
    );
  }
}

BoardItem.propTypes = {
  row: PropTypes.object.isRequired,
  isPortalOpen: PropTypes.bool.isRequired,
  detailData: PropTypes.object.isRequired,
  currentPageType: PropTypes.string.isRequired,
  isDisabledItem: PropTypes.bool.isRequired,
  onInputModeChange: PropTypes.func,
  onEdit: PropTypes.func,
  onGetSum: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleTextChange: PropTypes.func,
};

BoardItem.defaultProps = {
  onInputModeChange: () => {},
  onEdit: () => {},
  onGetSum: () => {},
  handleInputChange: () => {},
  handleTextChange: () => {},
};
