import React, { Component } from 'react';
import { Menu, Segment } from 'semantic-ui-react';
import FsItemMappingGlobal from './FsItemMappingGlobal';
import FsItemTenantMappingImport from './FsItemTenantMappingImport';
import BulkExport from './BulkExport';
import BulkImport from './BulkImport';
import GlobalMasterDiffExport from './GlobalMasterDiffExport';
import intl from 'react-intl-universal';

class FsItemMapping extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '/system/FsItemMapping',
    };
  }

  async componentDidMount() {}

  handleSwitchTab = label => {
    this.setState({activeTab: label});
  }

  render() {
    const { activeTab } = this.state
    const tabs = [
      {
        label: intl.get('_system.FsItemMapping.Tab.Global'),
        url: '/system/FsItemMapping'
      },
      {
        label: intl.get('_system.FsItemMapping.Tab.Tenant'),
        url: '/system/FsItemMapping/tenantImport'
      },
      {
        label: intl.get('_system.FsItemMapping.Tab.TenantPreset BulkExport'),
        url: '/system/FsItemMapping/bulkExport/tenantPreset'
      },
      {
        label: intl.get('_system.FsItemMapping.Tab.TenantPreset BulkImport'),
        url: '/system/FsItemMapping/bulkImport/tenantPreset'
      },
      {
        label: intl.get('_system.FsItemMapping.Tab.DiffExport'),
        url: '/system/FsItemMapping/diffExport'
      },
      {
        label: intl.get('_system.FsItemMapping.Tab.TenantConvertSubtitle BulkExport'),
        url: '/system/FsItemMapping/bulkExport/tenantConvertSubtitle'
      },
      {
        label: intl.get('_system.FsItemMapping.Tab.TenantConvertSubtitle BulkImport'),
        url: '/system/FsItemMapping/bulkImport/tenantConvertSubtitle'
      },
    ];

    return (
      <Segment style={{minHeight: '350px'}}>
        <Menu
          vertical
          tabular
          style={{ position: 'absolute', left: '-100px', width: '100px' }}
        >
          { 
            tabs.map(tab => {
              return <Menu.Item
                  name={tab.label}
                  component={tab.url}
                  active={activeTab === tab.url}
                  onClick={() => this.handleSwitchTab(tab.url)}
                  key={tab.label}
              />
            })
          }
        </Menu>
        {
          activeTab === '/system/FsItemMapping' && <FsItemMappingGlobal />
        }
        {
          activeTab === '/system/FsItemMapping/tenantImport' && <FsItemTenantMappingImport />
        }
        {
          activeTab === '/system/FsItemMapping/bulkExport/tenantPreset' && <BulkExport exportFlag={"preset"}/>
        }
        {
          activeTab === '/system/FsItemMapping/bulkImport/tenantPreset' && <BulkImport importFlag={"preset"}/>
        }
        {
          activeTab === '/system/FsItemMapping/diffExport' && <GlobalMasterDiffExport />
        }
        {
          activeTab === '/system/FsItemMapping/bulkExport/tenantConvertSubtitle' && <BulkExport exportFlag={"convertSubtitle"}/>
        }
        {
          activeTab === '/system/FsItemMapping/bulkImport/tenantConvertSubtitle' && <BulkImport importFlag={"convertSubtitle"}/>
        }
      </Segment>
    );
  }
}

export default FsItemMapping;
