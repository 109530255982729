import React, { Component } from 'react'
import { Button, Form, Dropdown } from 'semantic-ui-react'
import api from '../../api'
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { TYPES, track2DBStorage } from '../../common/PDFTrackUtil'

export function getFinancialStatementsExtraInfo() {
  const extraInfoSetting =
    [{ label: "BSの単位", require: true, type: "dropdown", key: "bsUnit" },
    { label: "PLの単位", require: true, type: "dropdown", key: "plUnit" },
    { label: "SSの単位", require: true, type: "dropdown", key: "sUnit" },
    { label: "取引先コード", require: true, type: "code", key: "customerCode" },
    { label: "取引先名", require: true, type: "string", key: "customerName" },
    { label: "決算期", require: true, type: "yearmonth", key: "settlementPeriod"},
    { label: "決算月数", require: true, type: "month", key: "settlementMonthNum" },
    { label: "登録完了時通知先（To）", require: true, type: "user", key: "mailTo" },
    { label: "登録完了時通知先（Cc）", require: false, type: "user", key: "mailCc" },]
  return extraInfoSetting
}
export default class ExtraInfoNewUI extends Component {
  constructor(props) {
    super(props)
    const { items } = props
    const data = {}
    const message = {}
    items.forEach(item => {
      if (item.type === 'user') {
        data[item.key] = []
      } else { 
        data[item.key] = ""
      }
      message[item.key] = ""
    });
    data['settlementMonthNum'] = '12' // set default value for 決算月
    this.state = {
      loading: false,
      data: data,
      message: message,
      completeFlag: false,
      users: [],
      confirmBtnLoading: false
    }
    this.handleOperateSearch = this.handleOperateSearch.bind(this);
  }

  async componentDidMount() {
    // var leftChild = document.getElementById('leftChild')
    // leftChild.style.width = null
    // var rightChild = document.getElementById('rightChild')
    // rightChild.style.width = null

    const data =JSON.parse(localStorage.getItem('resultFunction'))
    if(data){
      if(data['settlementPeriod']){
        data['settlementPeriod'] = new Date(data['settlementPeriod'])
        const value = new Date(data['settlementPeriod'])
        if( value !== null){
          const originDiv = document.querySelector('.react-date-picker__inputGroup');
          const newInputDiv = document.createElement('Input');
  
          const year = value.getFullYear().toString();
          const month = (value.getMonth() + 1).toString();
          let displayDate = value.getMonth() >= 9 ? `${year}年${month}月` : `${year}年0${month}月`;
  
          newInputDiv.setAttribute('class', 'react-date-picker__inputGroup__input react-date-picker_newInput')
          newInputDiv.setAttribute('autocomplete','off')
          newInputDiv.setAttribute('value',displayDate)
          newInputDiv.setAttribute('id','newInputDiv');
  
          originDiv&&originDiv.appendChild(newInputDiv);
        }
      }
      this.setState({data})
      localStorage.removeItem('resultFunction')
    }
    this.getUsers();
  }

  getUsers = async () => {
    const { projectId } = this.props;
    const { data: users } = await api.get(`/dppapi/predict/projectusers/${projectId}`);
    this.setState({
      users: users.map(user => {
        return {
          key: user.id,
          value: user.email,
          text: user.email
        }
      })
    })

    // const { pdfId } = this.props;
    // const { data: pdf } = await api.get(`/dppapi/predict/${projectId}/pdfs/${pdfId}`);
    
    // const user = users.find(user => { 
    //   if (pdf.userInCharge) {
    //     return user.id === pdf.userInCharge
    //   } else { 
    //     return user.id === pdf.createdById
    //   }
    // })
    // if (user) { 
    //   const { data } = this.state
    //   if(data['mailTo'] && !data['mailTo'].find(d=>d===user.email)){
    //     data['mailTo'].push(user.email)
    //   }
    //   this.setState({data})
    // }
  }
  handleValueChange = (key, value) => {
    const { data, message } = this.state
    message[key] = "";
    data[key] = value;
    if(key ==='customerCode'){
      if(data[key].length !== 9 && data[key].length !== 0){
        message[key] = '「取引先コード」は9桁です';
      }
    }
    if(key === 'settlementPeriod'){
      const e = document.querySelector('#newInputDiv');
      if (e != null) e.parentElement.removeChild(e);
      if( value !== null){
        const originDiv = document.querySelector('.react-date-picker__inputGroup');
        const newInputDiv = document.createElement('Input');

        const year = value.getFullYear().toString();
        const month = (value.getMonth() + 1).toString();
        let displayDate = value.getMonth() >= 9 ? `${year}年${month}月` : `${year}年0${month}月`;

        newInputDiv.setAttribute('class', 'react-date-picker__inputGroup__input react-date-picker_newInput')
        newInputDiv.setAttribute('autocomplete','off')
        newInputDiv.setAttribute('value',displayDate)
        newInputDiv.setAttribute('id','newInputDiv');

        originDiv.appendChild(newInputDiv);
      }
    }

    localStorage.setItem('resultFunction', JSON.stringify(data))
    this.setState({
      data: data,
      message: message
    })
  }

  getItemType = (item) => {
    const { key } = item
    const { users } = this.state
    const value = this.state.data[key]
    const units = [
      { key: '0', text: '円', value: '0' },
      { key: '1', text: '百円', value: '1' },
      { key: '2', text: '千円', value: '2' },
      { key: '3', text: '万円', value: '3' },
      { key: '4', text: '百万円', value: '4' },
      { key: '5', text: '億円', value: '5' }
    ]
    const months = [
      { key: '0', text: '01', value: '01' },
      { key: '1', text: '02', value: '02' },
      { key: '2', text: '03', value: '03' },
      { key: '3', text: '04', value: '04' },
      { key: '4', text: '05', value: '05' },
      { key: '5', text: '06', value: '06' },
      { key: '6', text: '07', value: '07' },
      { key: '7', text: '08', value: '08' },
      { key: '8', text: '09', value: '09' },
      { key: '9', text: '10', value: '10' },
      { key: '10', text: '11', value: '11' },
      { key: '11', text: '12', value: '12' }
    ]

    switch (item.type) {
      case "code":
        return (
          <div>
            <input
              value={value}
              style={{ width: '70%' }}
              onChange={e => this.handleValueChange(key, e.target.value)}
              placeholder={item.placeholder} />
            <Button
              style={{ float: 'right' }}
              loading={this.state.loading}
              disabled={this.state.loading || !this.state.data['customerCode']}
              className='ui button rpa-newui-search-btn'
              onClick={() => this.handleOperateSearch(this.state.data)}
            >検索</Button>
          </div>
        )
      case "string":
        return <input value={value} onChange={e => this.handleValueChange(key, e.target.value)} placeholder={item.placeholder} />
      case "dropdown":
        return <Dropdown
          selection
          options={units}
          value={value}
          fluid
          onChange={(e, unit) => this.handleValueChange(key, unit.value)}
        />
      case "yearmonth":
        return <DatePicker
          className="rpa-newui-datepicker"
          onChange={date => this.handleValueChange(key, date)}
          value={value}
          format="yyyyMM"
          maxDetail="year"
        />
      case "month":
        return <Dropdown
          selection
          value={value}
          options={months}
          fluid
          onChange={(e, month) => this.handleValueChange(key, month.value)}
        />
      case "user":
        return <Dropdown
         value={value}
          selection
          options={users}
          multiple
          search
          fluid
          onChange={(e, user) => this.handleValueChange(key, user.value)}
        />
      default:
        return <input value={value} onChange={e => this.handleValueChange(key, e.target.value)} />
    }
  }

  renderOptions = () => {
    const { items } = this.props
    const { completeFlag } = this.state

    const dom = !completeFlag ? items.map((item, idx) => {
      const { label, key } = item
      const { message } = this.state
      return (< Form.Field key={idx} className="rf-newui-form-item">
        <div className="rf-newui-form-item-input">
          <div className="rf-newui-form-item-label"><label>{label}</label></div>
          <div className="rf-newui-form-item-content">
            {this.getItemType(item)}
          </div>
        </div>
        <div className="rf-newui-form-item-msg">{message[key]}</div>
      </Form.Field >)
    }) : '財務データ入力システムに登録データの連携を開始しました。登録処理完了メールが届くまでお待ちください。';
    return <Form className="rf-newui-form">{dom}</Form>
  }

  async handleOperateSearch(operaData) {
    this.setState({ loading: true });
    try {
      const result = (await api.post('/dppapi/predict/rpa/opera/' + this.props.projectId, {
        customerCode: operaData.customerCode
      })).data;
      if (result.success) {
        const data = Object.assign({}, this.state.data, result.operaData)
        this.setState({
          loading: false,
          data,
        });
      }
    } catch (error) {
      var message = this.state.message;
      message['customerCode'] = error.data.errmsg;
      this.setState({
        message,
        loading: false,
      });
    }
  }

  render() {
    const { handleOperateDialog, pdfId, projectId, setResultFunctionParam } = this.props
    const { items, callback } = this.props
    const { data, message, completeFlag } = this.state
    return (
      <div>
        {this.renderOptions()}
        {!completeFlag ?
          <div className="rf-newui-extra-btn-group">
            <div style={{ color: 'red', width: '50%', float: 'left' }}>{message['errMsg']}</div>
            <Button
              className='primary-btn confirmAlert-button-right'
              loading={this.state.confirmBtnLoading}
              onClick={async () => {
                this.setState({confirmBtnLoading: true})
                var body = {}
                let returnFlag = false;
                if (data['customerCode'].length !== 9) {
                  returnFlag = true;
                }
                items.forEach(item => {
                  body[item.key] = data[item.key];
                  if (item.require && (!data[item.key] || data[item.key] === "" || data[item.key].length === 0)) {
                    message[item.key] = item.label + 'を入力してください';
                    this.setState({ message, confirmBtnLoading: false });
                    returnFlag = true;
                  }
                });
                if (returnFlag) return;
                body.mailTo = body.mailTo.join(';')
                body.mailCc = (body.mailCc || []).join(';')
                body.settlementPeriod = moment(body.settlementPeriod).format('YYYYMM');
                api.post(`/dppapi/predict/evaluate-preset/${projectId}/${pdfId}`);
                var res = await (await api.post('/dppapi/predict/rpa/mail/' + projectId + '/' + pdfId, body)).data;

                if (!res.success) {
                  message['errMsg'] = res.errmsg;
                  this.setState({ message, confirmBtnLoading:false });
                } else {
                  localStorage.removeItem('resultFunction');
                  handleOperateDialog({
                    title: "送信完了",
                    open: true,
                    onlyCancel: true,
                    msgs: ['財務データ入力システムに登録データの連携を開始しました。登録処理完了メールが届くまでお待ちください。'],
                    onOk: () => { setResultFunctionParam({showRF: false, showFSRV: true}) },
                    onCancel: () => { setResultFunctionParam({showRF: false, showFSRV: true})}
                  });
                }
              }}
              positive>
              RPA実行
            </Button>
            <Button className='predict-admin-botton-text confirmAlert-button-left' style={{marginRight: '10px'}} onClick={() =>  setResultFunctionParam({showRF: false})}>編集画面に戻る</Button>
          </div> : <div className="rf-extra-btn-group">
            <Button
              onClick={async () => {
                if (callback && typeof (callback) === 'function') {
                  callback()
                }
                await handleOperateDialog({ open: false })

                // window.location.reload(true)
                // Track the operation of rpa excution
                try {
                  track2DBStorage(projectId, pdfId, TYPES.EXEC_RPA)
                } catch (e) {
                  console.error('Track errors of the operation of rpa excution!')
                }
              }}>
              OK
            </Button>
          </div>}
      </div>
    )
  }
}
