import React from 'react';

import { MDBModal, MDBModalBody } from 'mdbreact';
import {
  Button,
  Form
} from 'semantic-ui-react';
import intl from 'react-intl-universal';
import ReactMarkdown from 'react-markdown'

export default class ProjectDescription extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      project: {},
    };
  }

  render() {
    const { project, isShowEdit } = this.props;

    return (
      <MDBModal
        isOpen={isShowEdit}
        toggle={(flag) => {
          this.props.toggleEdit({ isShowEdit: flag });
        }}
      >
        <MDBModalBody>
          <Form>
          <ReactMarkdown>{project.description}</ReactMarkdown>
            <Button
              floated="right"
              onClick={() => {
                this.props.toggleEdit({ isShowEdit: false });
              }}
            >
              {intl.get('_label.description.index.Cancel')}
            </Button>
          </Form> 

        </MDBModalBody>
      </MDBModal>
    );
  }
}
