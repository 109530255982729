import React, { Component } from 'react'
import { Button, Form, Dropdown, Input, Message } from 'semantic-ui-react'
import DatePicker from 'react-date-picker';
import api from '../../api';
export default class DialogBodyGenerator extends Component {
  constructor(props) {
    super(props)
    const { items, actions } = this.props
    
    const data = {}
    items.forEach(item => {
      data[item.key] = item.defaultValue
    });

    const btns = {}
    actions.forEach(ac => { 
      btns[ac.name] = ac.loading
    })

    this.state = { data, userOptions: [], errorMsgs: [], btns }
  }

  async componentDidMount() {

    // var leftChild = document.getElementById('leftChild')
    // leftChild.style.width = null
    // var rightChild = document.getElementById('rightChild')
    // rightChild.style.width = null

    const { pdfInfo } = this.props;
    const { projectsId, outputOptions } = pdfInfo
    const data = JSON.parse(localStorage.getItem('resultFunction')) || JSON.parse(outputOptions);
    if(data){
      //Date transfer
      // if(data['settlementPeriod']){
      //   data['settlementPeriod'] = new Date(data['settlementPeriod'])
      // }
      this.props.items.filter(item=> item.type ==='date-selection').forEach(d=>{
        if(data[d.key]){
          data[d.key] = new Date(data[d.key])
        }
      })
      localStorage.removeItem('resultFunction')
      this.setState({data})
    }
    const { data: users } = await api.get(`/dppapi/predict/projectusers/${projectsId}`);
    this.setState({
      userOptions: users.map(user => {
        return {
          key: user.id,
          value: user.email,
          text: user.email
        }
      })
    })
  }

  handleValueChange = (key, value) => {
    const { data } = this.state
    data[key] = value;
    localStorage.setItem('resultFunction', JSON.stringify(data))
    this.setState({
      data: data,
    })
  }

  getItem = (item) => {
    const { type, key, options, datePickerProps, placeholder, readonly } = item
    const value = this.state.data[key]
    const { userOptions } = this.state
    switch (type) {
      case "selection":
        return <Dropdown
          key={key}
          value={value}
          selection
          options={options}
          fluid
          onChange={(e, data) => this.handleValueChange(key, data.value)}
          disabled={readonly}
        />

      case "date-selection":
        return <DatePicker
          className="rf-newui-datepicker"
          onChange={date => this.handleValueChange(key, date)}
          value={value}
          {...datePickerProps}
        />
      case "input-text":
        return <Input
          key={key}
          value={value}
          fluid
          placeholder={placeholder}
          onChange={(e, data) => this.handleValueChange(key, data.value)}
        />
      case "user-selection":
        return <Dropdown
          selection
          value={value}
          options={userOptions}
          multiple
          search
          fluid
          onChange={(e, user) => this.handleValueChange(key, user.value)}
        />

      default:
        return null
    }
  }

  renderItems = () => {
    const dom = this.props.items.map((item, idx) => {
      const { label } = item
      return (< Form.Field key={idx} className='rf-newui-item'>
        <div style={{ marginTop: 20, width: '30%' }}><label>{label}</label></div>
        <div style={{ marginTop: 10, width: '70%' }}>
          {this.getItem(item)}
        </div>
      </Form.Field >)
    })
    return <Form>{dom}</Form>
  }

  renderActions = () => {
    const { actions } = this.props
    const { btns } = this.state
    return <div className="rf-common-extra-btn-group" >
      {actions.map((action, idx) => {
        const { name, func, color } = action
        return <Button key={idx} className="rf-common-extra-btn-item" onClick={() => func(this.state, this)} color={color} loading={btns[name]}>
          {name}
        </Button>
      })}
    </div>
  }

  renderError = () => {
    return this.state.errorMsgs.length > 0 &&
      <div className='rf-message'>
        <Message negative>
          {this.state.errorMsgs.map((msg, idx) => <p key={idx}>{msg}</p>)}
        </Message>
      </div>
  }

  render() {
    return (
      <div className='rf-body'>
        <div className='rf-newui-items-group'>
          {this.renderItems()}
          {this.renderError()}
        </div>
        {this.renderActions()}
      </div>
    )
  }
}