import React from 'react';

import { Button, Message, Tab, Icon, Divider } from 'semantic-ui-react';
import { confirmAlert } from 'react-confirm-alert';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/monokai.css';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/mode/javascript/javascript';
// import 'codemirror/addon/hint/show-hint.css';
// import 'codemirror/addon/hint/show-hint.js';
// import 'codemirror/addon/hint/javascript-hint.js';
import 'codemirror/keymap/sublime';
import api from '../../api';
import { DEFAULT_TEST_PARAMS } from './DefaultData';
import { debounce, isEmpty } from 'lodash';
import 'codemirror/addon/fold/foldcode.js';
import 'codemirror/addon/fold/foldgutter.js';
import 'codemirror/addon/fold/brace-fold.js';
import 'codemirror/addon/fold/comment-fold.js';
import 'codemirror/addon/fold/foldgutter.css';
import intl from 'react-intl-universal';

const CODE_MIRRON_OPTION = {
  mode: { name: 'javascript', json: true },
  theme: 'monokai',
  lineNumbers: true,
  tabSize: 2,
  keyMap: 'sublime',
  lineWrapping: true,
  foldGutter: true,
  gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
};

export default class ParamsEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      testParams: {},
      panes: [],
      info: {},
      project: {},
      activeIndex: 1,
    };

    this.handleReset = this.handleReset.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.clearAll = this.clearAll.bind(this);
    this.initEditor = this.initEditor.bind(this);
    this.clearInfo = this.clearInfo.bind(this);
    this.showInfo = this.showInfo.bind(this);
    this.isValidJSON = this.isValidJSON.bind(this);
    this.getProject = this.getProject.bind(this);
    this.initPanel = this.initPanel.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  async getProject() {
    const { projectId } = this.props;
    const project = (await api.get('/dppapi/projects/' + projectId)).data;

    this.setState({
      project,
    });
    return project;
  }

  async initPanel() {
    let { testParams: dbTestParams } = await this.getProject();
    dbTestParams = JSON.parse(!isEmpty(dbTestParams) ? dbTestParams : '{}');

    this.setState({
      panes: [
        {
          menuItem: 'getCurrentImageId',
          render: () => {
            return (
              <Tab.Pane>
                <CodeMirror
                  value={JSON.stringify(
                      dbTestParams['currentImageId'] ||
                      DEFAULT_TEST_PARAMS['currentImageId']
                  )}
                  options={CODE_MIRRON_OPTION}
                  editorDidMount={(editor) => {
                    editor.setSize('100%', '500px');
                  }}
                  onChange={debounce((editor, data, value) => {
                    if (!this.isValidJSON(value)) {
                      return;
                    }

                    const testParams = Object.assign(
                      {},
                      this.state.testParams,
                      {
                        currentImageId: JSON.parse(value),
                      }
                    );
                    this.setState({
                      testParams,
                    });
                  }, 300)}
                />
              </Tab.Pane>
            );
          },
        },
        {
          menuItem: 'getPdfImages',
          render: () => {
            return (
              <Tab.Pane>
                <CodeMirror
                  value={JSON.stringify(
                      dbTestParams['pdfImages'] ||
                      DEFAULT_TEST_PARAMS['pdfImages']
                  )}
                  options={CODE_MIRRON_OPTION}
                  editorDidMount={(editor) => {
                    this.initEditor(editor);
                  }}
                  onChange={debounce((editor, data, value) => {
                    if (!this.isValidJSON(value)) {
                      return;
                    }

                    const testParams = Object.assign(
                      {},
                      this.state.testParams,
                      {
                        pdfImages: JSON.parse(value),
                      }
                    );
                    this.setState({
                      testParams,
                    });
                  }, 300)}
                />
              </Tab.Pane>
            );
          },
        },
        {
          menuItem: 'getImageBBoxes',
          render: () => {
            return (
              <Tab.Pane>
                <CodeMirror
                  value={JSON.stringify(
                      dbTestParams['imageBBoxes'] ||
                      DEFAULT_TEST_PARAMS['imageBBoxes']
                  )}
                  options={CODE_MIRRON_OPTION}
                  editorDidMount={(editor) => {
                    this.initEditor(editor);
                  }}
                  onChange={debounce((editor, data, value) => {
                    if (!this.isValidJSON(value)) {
                      return;
                    }

                    const testParams = Object.assign(
                      {},
                      this.state.testParams,
                      {
                        imageBBoxes: JSON.parse(value),
                      }
                    );
                    this.setState({
                      testParams,
                    });
                  }, 300)}
                />
              </Tab.Pane>
            );
          },
        },
        {
          menuItem: 'getPrediction',
          render: () => {
            return (
              <Tab.Pane>
                <CodeMirror
                  value={JSON.stringify(
                      dbTestParams['prediction'] ||
                      DEFAULT_TEST_PARAMS['prediction']
                  )}
                  options={CODE_MIRRON_OPTION}
                  editorDidMount={(editor) => {
                    this.initEditor(editor);
                  }}
                  onChange={debounce((editor, data, value) => {
                    if (!this.isValidJSON(value)) {
                      return;
                    }

                    const testParams = Object.assign(
                      {},
                      this.state.testParams,
                      {
                        prediction: JSON.parse(value),
                      }
                    );
                    this.setState({
                      testParams,
                    });
                  }, 300)}
                />
              </Tab.Pane>
            );
          },
        },
        {
          menuItem: 'getMetadataByCategory',
          render: () => {
            return (
              <Tab.Pane>
                <CodeMirror
                  value={JSON.stringify(
                      dbTestParams['metadata'] ||
                      DEFAULT_TEST_PARAMS['metadata']
                  )}
                  options={CODE_MIRRON_OPTION}
                  editorDidMount={(editor) => {
                    this.initEditor(editor);
                  }}
                  onChange={debounce((editor, data, value) => {
                    if (!this.isValidJSON(value)) {
                      return;
                    }

                    const testParams = Object.assign(
                      {},
                      this.state.testParams,
                      {
                        metadata: JSON.parse(value),
                      }
                    );
                    this.setState({
                      testParams,
                    });
                  }, 300)}
                />
              </Tab.Pane>
            );
          },
        },
      ],
      testParams: dbTestParams
    });
  }

  async componentDidMount() {
    await this.initPanel();
  }

  initEditor(editor) {
    editor.setSize('100%', '500px');
    this.clearInfo();
  }

  clearInfo() {
    this.setState({
      info: {},
    });
  }

  clearAll() {
    this.setState({
      testParams: {},
      info: {},
    });
  }

  showInfo(content, type) {
    this.setState({
      info: {
        type: type,
        content: content,
      },
    });

    setTimeout(() => {
      this.clearInfo();
    }, 2000);
  }

  isValidJSON(jsonStr) {
    try {
      JSON.parse(jsonStr);
      return true;
    } catch (e) {
      return false;
    }
  }

  async handleSave() {
    let { testParams: dbTestParams, id } = this.state.project;
    dbTestParams = JSON.parse(!isEmpty(dbTestParams) ? dbTestParams : '{}');

    confirmAlert({
      title: intl.get('_project.mapping-function.ParamsEditor.Confirm'),
      message: intl.get('_project.mapping-function.ParamsEditor.Do you really want to change the test params?'),
      buttons: [
        {
          label: intl.get('_project.mapping-function.ParamsEditor.OK'),
          onClick: async () => {
            const testParams = this.state.testParams;

            const project = Object.assign({}, this.state.project, {
              testParams: JSON.stringify(
                Object.assign({}, DEFAULT_TEST_PARAMS, dbTestParams, testParams)
              ),
            });
            await api.patch('/dppapi/projects/' + id, {
              project: project,
            });

            // show success info
            this.showInfo('Save successfully!', 'success');

            this.initPanel();
          },
        },
        {
          label: intl.get('_project.mapping-function.ParamsEditor.Cancel'),
        },
      ],
    });
  }

  async handleReset() {
    let { id } = this.state.project;

    confirmAlert({
      title: intl.get('_project.mapping-function.ParamsEditor.Confirm'),
      message: intl.get('_project.mapping-function.ParamsEditor.Do you really want to reset the test params to default?'),
      buttons: [
        {
          label: intl.get('_project.mapping-function.ParamsEditor.OK'),
          onClick: async () => {

            const project = Object.assign({}, this.state.project, {
              testParams: JSON.stringify(
                Object.assign({}, DEFAULT_TEST_PARAMS)
              ),
            });
            await api.patch('/dppapi/projects/' + id, {
              project: project,
            });

            // show success info
            this.showInfo('Reset successfully!', 'success');
            this.initPanel();
          },
        },
        {
          label: intl.get('_project.mapping-function.ParamsEditor.Cancel'),
        },
      ],
    });
  }

  handleTabChange(e, { activeIndex }) {
    this.setState({ activeIndex });
  }

  render() {
    const { panes, info, activeIndex } = this.state;

    return (
      <React.Fragment>
        {info['content'] && (
          <Message
            success={info['type'] === 'success'}
            warning={info['type'] === 'warn'}
            content={info['content']}
          />
        )}
        <Button.Group>
          <Button onClick={this.handleSave} basic={true} color="black">
            <Icon name="save" />
            {intl.get('_project.mapping-function.ParamsEditor.Save')}
          </Button>
          <Button onClick={this.handleReset} basic={true} color="red">
            <Icon name="redo" />
            {intl.get('_project.mapping-function.ParamsEditor.Reset')}
          </Button>
        </Button.Group>
        <Divider />
        <Tab menu={{ fluid: true, vertical: true, pointing: true }} menuPosition='left' panes={panes} activeIndex={activeIndex} onTabChange={this.handleTabChange}/>
      </React.Fragment>
    );
  }
}
