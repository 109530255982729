import React, { Component } from 'react'
import api from '../../api'
import { Button, Icon, Table, Message, Grid, Header, Segment, List } from 'semantic-ui-react'
import { FMT, unixMSToDatetime } from '../../common/TimeUtil';
export default class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queues: [],
      messages: [],
      detail: null,
      errmsg: ""
    };

  }

  async componentDidMount() {
    this.onSearch()
  }

  onSearch = async () => {

    const res = (await api.get(
      `/dppapi/system/rabbitmq/list-queues`
    )).data;

    if (res.success) {
      this.setState({
        queues: res.data.queues,
        errmsg: ""
      });
    } else {
      this.setState({ queues: [], messages: [], detail: null, errmsg: "could not connect to rabbitmq server" })
    }

    const msgRes = (await api.get(
      `/dppapi/system/rabbitmq/fs-inference-messages`
    )).data;

    if (msgRes.success) {
      const detail = msgRes.data.messages[0] ? msgRes.data.messages[0] : null


      this.setState({
        messages: msgRes.data.messages,
        detail,
        errmsg: ""
      });
    } else {
      this.setState({ queues: [], messages: [], detail: null, errmsg: "could not connect to rabbitmq server" })
    }

  }

  renderBody = () => {
    const body = this.state.queues.map((q, i) => {
      return <Table.Row key={i}>
        <Table.Cell>{q.name}</Table.Cell>
        <Table.Cell>{q.state}</Table.Cell>
        <Table.Cell>{q.messages_ready}</Table.Cell>
        <Table.Cell>{q.messages_unacknowledged}</Table.Cell>
        <Table.Cell>{q.messages}</Table.Cell>
        <Table.Cell>{q.consumers}</Table.Cell>
        <Table.Cell>{JSON.stringify(q.arguments)}</Table.Cell>
      </Table.Row>
    })

    return <Table.Body>{body}</Table.Body>
  }

  renderMessages = () => {
    const v =
        <Segment>
          <Grid>
            <Grid.Column width={6}>
              <List selection divided style={{ height: "380px", overflowY: 'auto' }}>
                <Header>{"fs_inference"}</Header>

                {this.state.messages.map((m, i) => {
                  return (
                    <List.Item key={i} onClick={() => this.handleSetDetail(m)}>
                      <List.Content>
                        <List.Header>
                          <pre>
                            {`tenantId: ${m.tenantId},\nid: ${m.pdfFile.id},\nprojectId: ${m.pdfFile.projectId},\noriginName: ${m.pdfFile.originName},\nuploadTime:${unixMSToDatetime(m.pdfFile.uploadTime, FMT.TIME_STANDARD)}`}
                          </pre>
                        </List.Header>
                        {/* <Button size="tiny" onClick={ ()=>this.handleSetDetail(q, m)}>Detail</Button> */}
                      </List.Content>
                    </List.Item>
                  )
                })}
              </List>
            </Grid.Column>
            <Grid.Column width={10}>
              <Header>Detail</Header>
              <pre style={{ height: "350px", overflowX: 'auto', backgroundColor:"#FAFAFA"}}>
                { this.state.detail && JSON.stringify(this.state.detail, null, 2)}
              </pre>
            </Grid.Column>
          </Grid>
        </Segment>
    return v
  }

  handleSetDetail = (detail) => {
    this.setState({ detail })
  }

  render() {

    return (
      <React.Fragment>
        {this.state.errmsg ? <Message negative><p>{this.state.errmsg}</p></Message> : null}
        <Table compact celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell rowSpan='2'>
                Name
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan='2'>
                State
              </Table.HeaderCell>
              <Table.HeaderCell colSpan='3'>
                Messages
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan='2'>
                Consumers
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan='2'>
                Arguments
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>
                Ready
              </Table.HeaderCell>
              <Table.HeaderCell>
                Unacked
              </Table.HeaderCell>
              <Table.HeaderCell>
                Total
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {this.renderBody()}
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='11'>
                <Button
                  floated="right"
                  icon
                  labelPosition='left'
                  primary
                  size="small"
                  onClick={() => this.onSearch()}
                >
                  <Icon name="sync" /> Reload
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        {this.renderMessages()}
      </React.Fragment>
    )
  }
}