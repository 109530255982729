import React, { Component } from 'react';
import LabelingApp from './label-app/LabelingApp';
import { Loader } from 'semantic-ui-react';
import DocumentMeta from 'react-document-meta';
import { confirmAlert } from 'react-confirm-alert';
import intl from 'react-intl-universal';
import api from '../api'

import { demoMocks } from './demo';
import { getCsrfToken } from '../common/CookieUtil';

export default class LabelingLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: null,
      image: null,
      isLoaded: false,
      isLocked: false,
      error: null,
    };

    this.isLock = this.isLock.bind(this);
  }

  async fetch(...args) {
    const { projectId } = this.props.match.params;

    if (projectId === 'demo') {
      const path = typeof args[0] === 'string' ? args[0] : args[0].pathname;
      return demoMocks[path](...args);
    }

    const { method, body } = args[1] || {}
    const resp = await api.request({
      url: args[0],
      method: method || 'GET',
      data: typeof body === 'string' ? JSON.parse(body) : body
    });
    
    return resp
  }

  componentDidMount() {
    this.refetch();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.imageId !== this.props.match.params.imageId) {
      this.refetch();
    }
  }

  async refetch() {
    this.setState({
      isLoaded: false,
      error: null,
      project: null,
      image: null,
    });

    const { match, history } = this.props;
    let { projectId, imageId, labeled } = match.params;
    var isChrome = /Chrome/.test(window.navigator.userAgent) && /Google Inc/.test(window.navigator.vendor);
    if (!isChrome) {
      history.replace('/message', {
        msg: 'Sorry, this access requires Chrome support.',
        warning: true
      })
      return;
    }

    try {
      const a = document.createElement('a');
      a.setAttribute('href', `/dppapi/labeling/${projectId}/getLabelingInfo`);
      const url = new URL(a.href);

      url.searchParams.append('projectId', projectId);
      if (imageId) {
        url.searchParams.append('imageId', imageId);
      }
      if (labeled) {
        url.searchParams.append('labeled', labeled);
      }
      
      const { project, image } = (await this.fetch(url)).data;

      history.replace(`/label/${project.id}/${image.id}`);

      this.setState({
        isLoaded: true,
        project,
        image,
      });

      this.isLock();
    } catch (error) {
      history.replace(`/label/${projectId}/over`);
    }
  }

  async isLock() {
    const { imageId, projectId } = this.props.match.params;
    const response = await this.fetch(`/dppapi/labeling/${projectId}/images/${imageId}/islock`, {
      method: 'GET'
    });

    if (response.data.success) {
      const isLocked = response.data.islock;
      this.setState({ isLocked });
    }
  }

  async pushUpdate(labelData) {
    let theImage = this.state.image;
    theImage.labelData = labelData;
    this.setState({ image: theImage });

    const { imageId, projectId } = this.props.match.params;
    await this.fetch(`/dppapi/labeling/${projectId}/images/${imageId}`, {
      method: 'PATCH',
      body: JSON.stringify({ labelData }),
    });
  }

  async markComplete() {
    const { history } = this.props;
    const { imageId, projectId } = this.props.match.params;

    confirmAlert({
      title: intl.get('_label.label-app.Submitting.DoSubmit'),
      message: intl.get('_label.label-app.Submitting.Sure'),
      buttons: [
        {
          label: intl.get('_label.label-app.Submitting.DoSubmit'),
          onClick: async () => {
            await this.fetch(`/dppapi/labeling/${projectId}/images/${imageId}`, {
              method: 'PATCH',
              body: JSON.stringify({ labeled: true }),
            });

            history.push(`/label/${projectId}/`);
          },
        },
        {
          label: intl.get('_label.label-app.Labeling.Cancel'),
        },
      ],
    });
  }

  async takeSnapshot() {
    const { imageId, projectId } = this.props.match.params;
    const { image } = this.state;

    confirmAlert({
      title: intl.get('_label.label-app.Labeling.TakeSnapshot'),
      message: intl.get('_label.label-app.Labeling.Sure'),
      buttons: [
        {
          label: intl.get('_label.label-app.Labeling.TakeSnapshot'),
          onClick: async () => {
            const res = await this.fetch(`/dppapi/labeling/${projectId}/images/${imageId}/snapshot`, {
              method: 'POST',
              body: JSON.stringify({labelData: image.labelData}),
            });
            if (res.status === 200) {
              setTimeout(() => {  window.location.reload() }, 1000);
            } else {
              console.log("snapshot error")
            }
          },
        },
        {
          label: intl.get('_label.label-app.Labeling.Cancel'),
        },
      ],
    });
  }

  async getSnapshots() {
    const { imageId, projectId } = this.props.match.params;
    // const { image } = this.state;
    // console.log(image);

    const response = await this.fetch(`/dppapi/labeling/${projectId}/images/${imageId}/snapshot`, {
      method: 'GET'
    });

    var snapshots = [];
    if (response.data.success && response.data.snapshots.length > 0) {
      snapshots = response.data.snapshots.map(x => { return {"id": x.id, "time": x.lastEdited, "annotation": x.annotation} });
      snapshots.sort((a,b) => { return b.time - a.time });
    }

    // var rslt = response.data;
    return snapshots
  }

  async changeLabeled() {
    const { imageId, projectId } = this.props.match.params;
    const { image } = this.state;
    await api.patch(`/dppapi/labeling/${projectId}/images/${imageId}`, { labeled: !image.labeled});
    image.labeled = !image.labeled;
    this.setState({ image });
  }

  async getOCRText(project, coordinate) {
    const { imageId } = this.props.match.params;
    var url =
      `/dppapi/labeling/${project.id}/predict/ocr?imageId=`+ imageId;
    if (typeof coordinate === 'object') {
      url += '&coordinate=' + JSON.stringify(coordinate);
    }
    const response = await this.fetch(url);
    var rslt = response.data;
    return rslt;
  }

  render() {
    const { history } = this.props;
    const { project, image, isLoaded, isLocked, error } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <Loader active inline="centered" />;
    }

    const title = `Image ${image.id} for project ${project.name} -- Label Tool`;

    const props = {
      onSnapshot: () => {
        this.takeSnapshot();
      },
      onBack: () => {
        history.goBack();
      },
      onSkip: () => {
        history.push(`/label/${project.id}/`);
      },
      onNextLabeled: () => {
        history.push(`/label/${project.id}/labeled/true`);
      },
      onChangeLabeled: () => {
        this.changeLabeled();
      },
      onSubmit: () => {
        this.markComplete();
      },
      onOcr: (update, coordinate) => {
        this.getOCRText(project, coordinate).then(v => {
          update(v);
        });
      },
      snapshots: this.getSnapshots(),
      onLabelChange: this.pushUpdate.bind(this),
    };

    const { referenceLink, referenceText } = project;

    return (
      <fieldset disabled={isLocked}>
        <DocumentMeta title={title}>
          <LabelingApp
            labels={project.form.formParts}
            reference={{ referenceLink, referenceText }}
            labelData={image.labelData.labels || {}}
            imageUrl={`/dppapi${image.link}?token=${getCsrfToken()}`}
            fetch={this.fetch.bind(this)}
            projectId={project.id}
            imageId={image.id}
            labeled={image.labeled}
            demo={project.id === 'demo'}
            isLocked={isLocked}
            {...props}
          />
        </DocumentMeta>
      </fieldset>
    );
  }
}
