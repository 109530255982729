import React, { Component } from 'react';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import moment from "moment";
import { Segment, Message, Loader, Button } from 'semantic-ui-react';
import { confirmAlert } from 'react-confirm-alert';
import {toUnixMS, FMT, unixMSToDatetime} from '../../common/TimeUtil';
import api from '../../api';
import { getCsrfToken } from '../../common/CookieUtil';
import FeedBackSearch from './search';
import intl from 'react-intl-universal';

export default class Feedback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      init: true,
      isLoading: false,
      isShowEdit: false,
      editAudits: {},
      searchParam: null,
      type: null,
      easyocr: null,
    }

    this.onSearch = this.onSearch.bind(this)
    this.refetch = this.refetch.bind(this)
    this.toggleEdit = this.toggleEdit.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.onGetEasyocr = this.onGetEasyocr.bind(this)
  }

  async onGetEasyocr() {
    const reports = (await api.get(`/dppapi/system/easyocr-endpoints`)).data;
    this.setState({
      easyocr: reports['endpoints']
    })
  }

  async onSearch(data) {
    const { tenantId, type, startDate, endDate } = data;
    const searchParam = {
      tenantId,
      type,
      startDate: startDate ? toUnixMS(moment(startDate).format(FMT.DATE)) : '',
      endDate: endDate
        ? toUnixMS(
            moment(endDate)
              .add(1, 'd')
              .format(FMT.DATE)
          )
        : '',
    };
    this.setState({
      isLoading: true,
      searchParam,
    });
    await this.refetch(searchParam);
  }

  async refetch(searchParam) {
    const types = intl.get('_system.feedback.parsingTypes');
    searchParam = searchParam || this.state.searchParam;
    const reports = (await api.get(
      `/dppapi/system/search/feedback` +
        `?tenantId=${searchParam.tenantId}` +
        `&type=${searchParam.type}` +
        `&startDate=${searchParam.startDate}` +
        `&endDate=${searchParam.endDate}`
    )).data;
    this.setState({
      rows: Object.keys(reports).length > 0 ? reports.map((data, index) => {
        const { id, fileName } = data;
        return {
          ...data,
          fileName: (
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={`/system/feedBack/${id}`}
          >
          <span><u>{fileName.substr(0,15) + "..."}</u></span> 
        </Link>
        ),
          fileNameText: fileName,
          type: (Number(data.type) > 0 &&  Number(data.type) < 5) ? Object.values(types[data.type - 1]) : null,
          time: unixMSToDatetime(data.createdTimestamp, FMT.DATETIME_LOCAL),
          comment:(<div style={{ width: '120px', wordBreak: 'break-all'}}>{data.comment}</div>),
          memo:(<div style={{ width: '120px', wordBreak: 'break-all'}}>{data.memo}</div>),
          commentText:data.comment,
          memoText:data.memo,
          operation: (
            <div>
              <Button
                icon="trash"
                label={intl.get('_system.feedback.index.Delete')}
                size="tiny"
                onClick={() => this.handleDel(data)}
              />
              <Button
                icon="download"
                label={intl.get('_system.feedback.index.Download')}
                size="tiny"
                onClick={() => this.handleCsvDownload(data)}
              />
            </div>
          )
        }
      }).sort((t1, t2) => {
        return t2.time - t1.time
      }) : [],
      init: false,
    });
    this.setState({
      isLoading: false,
    });
  }

  toggleEdit(isShowEdit) {
    this.setState({
      editAudits: {},
      isShowEdit: isShowEdit,
    });
  }

  handleEdit(audits) {
    this.setState({
      isShowEdit: true,
      editAudits: audits,
    });
  }

  handleDel(data) {
    confirmAlert({
      title: intl.get('_system.feedback.index.Delete'),
      message: intl.get('_system.feedback.index.Are you sure delete feedback'),
      buttons: [
        {
          label: intl.get('_system.feedback.index.Delete'),
          onClick: async () => {
            await api.delete(`/dppapi/system/feedback/${data.id}`);
            await this.refetch(this.state.searchParam);
          },
        },
        {
          label: intl.get('_system.feedback.index.Cancel'),
        },
      ],
    });
  }

  handleCsvDownload(data) {
    let a = document.createElement('a');
    a.href = `/dppapi/system/feedback/csv/${data.id}?token=${getCsrfToken()}`;
    a.click();
    a.remove();
  }

  render() {
    const columns = [
      {
        label: intl.get('_system.feedback.index.No'),
        field: 'id',
      },
      {
        label: intl.get('_system.feedback.index.FileName'),
        field: 'fileName',
      },
      {
        label: intl.get('_system.feedback.index.LicenseInfo'),
        field: 'description',
      },
      {
        label: intl.get('_system.feedback.index.UserName'),
        field: 'userName',
      },
      {
        label: intl.get('_system.feedback.index.MacAddress'),
        field: 'macAddress',
      },
      {
        label: intl.get('_system.feedback.index.IpAddress'),
        field: 'ipAddress',
      },
      // {
      //   label: intl.get('_system.feedback.index.Rating'),
      //   field: 'rating',
      // },
      {
        label: intl.get('_system.feedback.index.Type'),
        field: 'type',
      },
      {
        label: intl.get('_system.feedback.index.Comment'),
        field: 'comment',
      },
      {
        label: intl.get('_system.feedback.index.Memo'),
        field: 'memo',
      },
      {
        label: intl.get('_system.feedback.index.ReportTime'),
        field: 'time',
      },
      {
        label: intl.get('_system.feedback.index.Operation'),
        field: 'operation',
      },
    ];

    const { rows, isLoading, init, easyocr } = this.state;
    const data = {
      columns: columns,
      rows: rows,
    };
    var result = null;
    if (isLoading) {
      result = (
        <Segment>
          <Loader active inline="centered" />
        </Segment>
      );
    } else if (rows.length > 0) {
      result = (
        <Segment>
          <MDBDataTable btn striped bordered hover data={data} />
        </Segment>
      );
    } else {
      result = (
        <Message info header="No Data" content="please change conditions" />
      );
    }
    return (
      <React.Fragment>
        <div style={{ display: 'flex' }}>
          <div className="ui" style={{ flex: 1 }}>
            <FeedBackSearch 
              onSearch={this.onSearch}
              onGetEasyocr={this.onGetEasyocr}
              easyocr={easyocr}
            />
            <div id="page" style={{ paddingBottom: 200, flex: 1 }}>
              {!init ? result : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
