import React, { Component } from 'react';
import { Segment, Header, Button, Loader, List, Card } from 'semantic-ui-react';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import update from 'immutability-helper';
import arrayMove from 'array-move';
import PageItem from './PageItem';
import api from '../../api'
import { ERR_MSG } from './Constant';
import _ from 'lodash'
import { Link } from 'react-router-dom';

export default class PageEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      pages: null,
      metadatas: null,
      error: false

    };

    this.onSortEnd = this.onSortEnd.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleNewPage = this.handleNewPage.bind(this);
  }

  async componentDidMount() {
    const { projectId } = this.props;
    const { metadata } = (await api.get(`/dppapi/admin/project/prediction/query?projectId=${projectId}`)).data;
    const { metadatas } = (await api.get(`/dppapi/admin/project/metadata/query?projectId=${projectId}&groupBy=true`)).data;
    this.setState({
      isLoaded: true,
      pages: metadata,
      metadatas,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { pages, error } = this.state;
    if (!pages) return;
    const { projectId } = this.props;

    if (!error && prevState.pages !== pages) {
      api.post(`/dppapi/admin/project/prediction/update?projectId=${projectId}`, { metadata: pages });
    }

  }

  onSortEnd({ oldIndex, newIndex }) {
    this.setState(({ pages }) => ({
      pages: update(pages, {
        $set: arrayMove(pages, oldIndex, newIndex),
      }),
    }));
  }

  handlePageChange(oldValue, newValue, type) {

    const { pages } = this.state;
    if (type === 'page-name' || type === 'page-del') {
      this.handleValidate(oldValue, newValue, pages)
    }
    const edit = newValue ? [1, newValue] : [1];
    this.setState({
      pages: update(pages, {
        $splice: [
          [
            pages.findIndex(x => x.id === oldValue.id),
            ...edit,
          ],
        ],
      }),
    });
  }

  handleValidate = (oldValue, newValue, target) => {

    // newValue(edit) oldValue(delete)
    const cur = newValue ? newValue : oldValue
    let err = false
    let errIdx = []
    if (newValue) {// edit validate
      target.forEach((page, idx) => {
        if (page.name === cur.name) {
          err = true
          page['error'] = { content: ERR_MSG.DUPLICATED }
          errIdx.push(idx)
        } else {
          delete page.error
        }
      });
    } else {// delete validate
      let errIdx = []
      target.forEach((page, idx) => {
        if (page.name === cur.name && page.id !== cur.id) {
          err = true
          page['error'] = { content: ERR_MSG.DUPLICATED }
          errIdx.push(idx)
        }

      });
      if (errIdx.length === 1) {
        err = false
        delete target[errIdx[0]].error
      }
    }

    if (err) {
      cur['error'] = { content: ERR_MSG.DUPLICATED }
    } else {
      delete cur.error
    }
    this.handleError(err)
  }

  handleNewPage() {
    const { pages } = this.state;

    const page = newFormPart()
    this.handleValidate(null, page, pages)

    this.setState({
      pages: update(pages, {
        $push: [page],
      }),
    });
  }

  handleNewColumn = (pageId) => {
    const { pages } = this.state;
    const idx = pages.findIndex(p => p.id === pageId)
    const page = pages[idx]
    const col = newColumn()
    this.handleColumnValidate(null, col, page.columns)
    this.setState({
      pages: update(pages, {
        [idx]: {
          columns: {
            $push: [col],
          }
        }
      }),
    });
  }

  handleColumnValidate = (oldValue, newValue, target) => {

    // newValue(edit) oldValue(delete)
    const cur = newValue ? newValue : oldValue
    let err = false
    let errIdx = []

    // edit validate
    if (newValue) {
      target.forEach((col, idx) => {
        if (col.name === cur.name) {
          err = true
          col['error'] = { content: ERR_MSG.DUPLICATED }
          errIdx.push(idx)
        } else {
          delete col.error
        }
      });
    } else { // delete validate
      let errIdx = []
      target.forEach((col, idx) => {
        if (col.name === cur.name && col.id !== cur.id) {
          err = true
          col['error'] = { content: ERR_MSG.DUPLICATED }
          errIdx.push(idx)
        }

      });
      if (errIdx.length === 1) {
        err = false
        delete target[errIdx[0]].error
      }
    }

    if (err) {
      cur['error'] = { content: ERR_MSG.DUPLICATED }
    } else {
      delete cur.error
    }
    this.handleError(err)
  }

  handleColumnChange = (pageId, oldValue, newValue, type) => {

    const { pages } = this.state;
    const idx = pages.findIndex(p => p.id === pageId)
    const page = pages[idx]

    // only column name  and column deleting need validate
    if (type === 'col-name' || type === 'col-del') {
      this.handleColumnValidate(oldValue, newValue, page.columns)
    }

    const edit = newValue ? [1, newValue] : [1];
    this.setState({
      pages: update(pages, {
        [idx]: {
          columns: {
            $splice: [
              [
                page.columns.findIndex(x => x.id === oldValue.id),
                ...edit,
              ],
            ],
          },
        }
      }),
    });
  }

  handleError = err => {
    this.setState({ error: err })
  }

  handlePageCopy = page => {
    const { pages } = this.state;

    const pageCopy = _.cloneDeep(page)

    pageCopy.id = genID()
    pageCopy.name = `${pageCopy.name} Copy`
    pageCopy.columns.forEach(p => {
      p.id = genID()
    })

    this.handleValidate(null, pageCopy, pages)

    this.setState({
      pages: update(pages, {
        $push: [pageCopy],
      }),
    });

  }

  scrollToItem = (renderedItems, key) => {
    if (!key) return;
    for (let i = 0; i < renderedItems.length; i++) {
      if (renderedItems[i].key === key) {
        if (!(document.getElementById('child'))) return;
        let item = document.getElementById('child').children[i];
        item.scrollIntoView({ block: 'start', behavior: 'smooth' });
        window.event.returnValue = false;
      }
    }
  }

  render() {
    const { isLoaded, pages, metadatas, error } = this.state;

    if (!isLoaded) {
      return <Loader active inline="centered" />;
    }

    const renderedItems = pages.length ? (
      pages.map((page, index) => (
        <SortableItem
          key={page.id}
          index={index}
          projectId={this.props.projectId}
          page={page}
          metadatas={metadatas}
          onPageChange={this.handlePageChange}
          onNewColumn={this.handleNewColumn}
          onColSortEnd={this.handleColSortEnd}
          onColumnChange={this.handleColumnChange}
          onSwitchTab={this.props.onSwitchTab}
          error={error}
          onPageCopy={this.handlePageCopy}
        />
      ))
    ) : (
        <Header className="centered" as="h5">
          No pages, add pages using the plus button below
        </Header>
      );
    let links = null;
    if (pages.length) {
      links = renderedItems.map(renderedPage => (
        <List.Item key={renderedPage.key}>
          <List.Icon name="circle" style={{ opacity: 0.0 }} />
          <List.Content>
            <Link to="#" onClick={() => this.scrollToItem(renderedItems, renderedPage.key)}>
              {renderedPage.props.page.name}
            </Link>
          </List.Content>
        </List.Item>
      ));
    }

    return (
      <div>
        <div style={{ float: 'left', width: '80%', height: 'auto' }} >
          <Segment style={{ display: 'flex' }}>
            <div style={{ width: '100%' }} >
              <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
                {renderedItems}
              </SortableContainer>
              <Button
                circular
                icon="plus"
                size="massive"
                style={{ float: 'right', marginTop: '2em' }}
                onClick={this.handleNewPage}
                disabled={error}
              />
            </div>
          </Segment>
        </div>
        <div style={{ position: 'sticky', top: 0, width: '18%', float: 'right' }} >
          <Card>
            <Card.Content>
              <Header as='h3'>Tables</Header>
              <List style={{ maxWidth: 200 }}>
                <List.Item>
                  <List.Header>{links}</List.Header>
                </List.Item>
              </List>
            </Card.Content>
          </Card>
        </div>
        <div style={{ clear: 'both' }}></div>
      </div>
    );
  }
}

const SortableItem = sortableElement(PageItem);

const SortableContainer = sortableContainer(({ children }) => {
  return <div id='child'>{children}</div>;
});

const genID = () => {
  return Math.random()
    .toString(36)
    .substr(2, 9)
}
const newFormPart = () => {
  const id = genID();
  return {
    id,
    name: 'New PageName',
    type: 'change',
    columns: []
  };
};
const newColumn = () => {
  const id = genID();
  return {
    id,
    name: 'New Column',
    type: 'selectBbox'
  };
};
