import React from 'react';

import { MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact';
import {
  Button,
  Form,
  Icon,
  Message
} from 'semantic-ui-react';
import './ModalBackdrop.css';

export default class Angle extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      angle: null,
      errors: {},
    }

    this.handleChange = this.handleChange.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
    this.clearAngle = this.clearAngle.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleRotate = this.handleRotate.bind(this);
    this.checkAngle = this.checkAngle.bind(this);
  }

  handleChange(event) {
    const {value} = event.target
    this.setState({
      angle: value
    })
  }

  handleFocus() {
    this.clearErrors();
  }

  clearErrors() {
    this.setState({
      errors: {}
    })
  }

  clearAngle() {
    this.setState({
      angle: null
    })
  }

  handleRotate() {
    const { url, angle } = this.props;
    //const { angle } = this.state;
    // if (!this.checkAngle(angle)) {
    //   return;
    // }
    this.props.rotate(url, angle);
    this.props.toggleEdit(false);
    this.clearErrors();
    this.clearAngle();
  }

  checkAngle(angle) {
    const errors = {};
    const angleReg = /^-?([0-3](\.0*)?|[0-2]\.\d*|0\.\d*[1-9]\d*|0?\.0+|0)$/;
    if (angle && !angleReg.test(angle)) {
      errors['angle'] = {content: 'Please input range (-3 ~ 3) or empty', pointing: 'below', flag: true};
    }
    this.setState({
      errors: errors
    })
    return Object.keys(errors).length === 0;
  }

  render() {
    const { errors, angle } = this.state;
    return (
      <div style={{cursor:'default'}}>
        <MDBModal 
          isOpen={this.props.isShowEdit} 
          toggle={this.props.toggleEdit} 
          centered={true}
          size={'sm'}
          overflowScroll={false}
          >
          <MDBModalHeader>
            Set rotation angle
          </MDBModalHeader>
          <MDBModalBody>
          {
            Object.keys(errors).length > 0 &&
            <Message
              error
              list={ Object.keys(errors).map(i => {
                return errors[i].content
              })}
            />
          }
            <Form>
              <Button onClick={()=>this.props.handleSetAngle(this.props.angle + (angle?Number(angle):0.5))}>
                <Icon name="redo" fitted style={{ fontSize: '1.2em' }} />
              </Button>
              <Button onClick={()=>this.props.handleSetAngle(this.props.angle - (angle?Number(angle):0.5))}>
                <Icon name="undo" fitted style={{ fontSize: '1.2em' }} />
              </Button>
              <Form.Field error={errors['angle'] && errors['angle']['flag']}>
                <Form.Input 
                  label="Please input range or empty(0.5) for each step"
                  defaultValue={angle}
                  onChange={this.handleChange} 
                  onFocus={this.handleFocus}/>
              </Form.Field>
              <Button type='submit' onClick={this.handleRotate} disabled={Number(this.props.angle)===0}>OK</Button>
              <Button onClick={() => {
                this.props.toggleEdit(false); 
                this.clearErrors(); 
                this.clearAngle();}}
                >Cancel</Button>
            </Form>
          </MDBModalBody>
        </MDBModal>
      </div>
    );
  }
}
