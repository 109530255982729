import React, { Component } from 'react';
import OnlineUsersTable from './OnlineUsersTable'

class MonitorOnlineUsers extends Component {
  render() {
    return (
      <div className="ui">
        <OnlineUsersTable />
      </div>
    );
  }
}

export default MonitorOnlineUsers;