import React, { Component } from 'react'
import { Table, Button, Input } from 'semantic-ui-react'
import {
  ManualInputStatus,
  PageType,
  SubjectName,
} from './FsConstant'
import throttle from 'lodash.throttle'
import { SCRUM_ITEM_SUFFIX } from '../../Constant'
import ShareholdersEquityCommonTable from './ShareholdersEquityCommonTable'
import ShareholdersEquityTable from './ShareholdersEquityTable'
import ZoomPopup from '../../../common/ZoomPopup/index'

export default class FsKeyValueTable extends Component {

  constructor(props) {
    super(props)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleInputChangeDebounced = throttle(this.manualInputChange, 100)
    this.state = {
      anchorId: null,
    }

    this.popups = {}
    this.TableRef = React.createRef()
  }
  componentWillUnmount() {
    this.handleInputChangeDebounced.cancel()
  }
  componentDidMount() {
    let scrollElement = document.getElementById('fs-key-value-table')
    if (scrollElement) {
      scrollElement.scrollTo({ top: this.props.fsKeyValueTableScrollTop, behavior: "auto" })
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.currentPageType && this.props.currentPageType !== prevProps.currentPageType) {
      let scrollElement = document.getElementById('fs-key-value-table')
      if (scrollElement) {
        scrollElement.scrollTo({ top: 0, behavior: "auto" })
      }
    } else if (prevProps.searchText.length !== 0) {
      let scrollElement = document.getElementById(prevProps.searchText)
      if (scrollElement) {
        scrollElement.scrollIntoView()
      }
    }
  }



  // currentBoxIdForCss is boxID selected in pdf panel
  getClass = (type, { rowId, cellIds, err, scrumItem }, selectedCell, currentBoxIdForCss = "", column) => {
    let className = ''
    const isAggreation = scrumItem.indexOf(SCRUM_ITEM_SUFFIX.AGGREGATION) !== -1 ? true : false
    switch (type) {
      case 'cell':
        if (rowId === selectedCell.id && column === selectedCell.column) {
          className = 'old-selected'
        }
        break
      case 'row':
        if (currentBoxIdForCss && cellIds.indexOf(currentBoxIdForCss) !== -1) {
          className += " old-high-light-row"
        }

        if (err) {
          className += " old-err-row"
        } else if (isAggreation) {
          className += " old-aggregation-row"
        }
        break
      default:
        className = ''
    }
    return className
  }
  getCellIds = (values) => {
    return values.map(v => v.id)
  }

  handleCellOperation = (row) => {
    const { id, scrumItem, values } = row
    const { selectedBBox, onRowSelect, onLinkBbox2Cell, currentPage } = this.props
    if (selectedBBox.id === "" && selectedBBox.text === "") {
      onRowSelect(id, currentPage, this.getCellIds(values), "value")
    } else {
      onLinkBbox2Cell(scrumItem)
      onRowSelect(id, currentPage, this.getCellIds(values), "value")
    }
  }

  handleSetOppositeValue = (values) => {
    values.forEach(value => {
      if (value.invert) {
        value.invert = false;
      } else {
        value.invert = true;
      }
    })
    this.props.setInvertFlag(values);
  }

  renderHeader = () => {
    return (<Table.Row >
      <Table.Cell style={{ width: "30%", background: '#f9fafb' }} >科目名</Table.Cell>
      <Table.Cell style={{ width: "", background: '#f9fafb' }} >金額</Table.Cell>
      <Table.Cell style={{ width: "165px", background: '#f9fafb' }} >操作</Table.Cell>
    </Table.Row>)
  }
  handleShowDetail = (searchText) => {
    this.props.onTabChange(1, searchText)
  }
  handleInputChange(scrumItem, value) {
    this.handleInputChangeDebounced(scrumItem, value)
  }
  manualInputChange(scrumItem, value) {

    this.props.onManualInputTextChange(scrumItem, value)
  }

  // 手動入力:1 , リンクで入力へ:2
  getManualInputStatus = (scrumItem) => {
    const { detailData, currentPageType } = this.props
    let status = ""
    if (detailData["manual_input"] && detailData["manual_input"][currentPageType]) {
      const idx = Object.keys(detailData["manual_input"][currentPageType]).indexOf(scrumItem)
      status = idx === -1 ? ManualInputStatus.LINK : ManualInputStatus.INPUT

    } else {
      status = ManualInputStatus.LINK
    }

    return status
  }

  handleCheck = (key) => {
    const { errorList } = this.props

    let err = false
    let item = null

    const isAggreation = key.indexOf(SCRUM_ITEM_SUFFIX.AGGREGATION) === -1 ? false : true

    errorList.forEach(errItem => {
      if (errItem.name === key || errItem.name + SCRUM_ITEM_SUFFIX.AGGREGATION === key) {
        err = true
        item = errItem
      }
    });

    return { err, errItem: item, isAggreation }
  }

  onPopupOpen = () => {
    Object.values(this.popups).forEach(popup => {
      popup && popup.state.show && popup.handleShow(false)
    })
  }

  renderAction = (scrumItem, manualInput, values) => {
    const { onEdit, onInputModeChange } = this.props
    let actionDom = ""
    if (manualInput === undefined) {
      actionDom = (<div style={{ display: "flex", justifyContent: "space-between" }}><Button
        primary
        size="mini"
        disabled={values.length === 0}
        onClick={() => this.handleShowDetail(scrumItem)}
        style={{ "paddingLeft": "5px", "paddingRight": "5px" }}
      >{"詳細"}
      </Button>
        <Button
          color="teal"
          size="mini"
          onClick={() => {
            onInputModeChange(scrumItem, ManualInputStatus.INPUT)
            onEdit(true)
          }}
          style={{ "paddingLeft": "5px", "paddingRight": "5px" }}
        >{"手動入力"}
        </Button><Button
          primary
          size="mini"
          disabled={values.length === 0}
          onClick={() => {
            this.handleSetOppositeValue(values)
            onEdit(true)
          }
          }
          style={{ "paddingLeft": "5px", "paddingRight": "5px" }}
        >{"符号反転"}
        </Button></div>)
    } else {
      actionDom = (<div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          color="teal"
          size="mini"
          onClick={() => {
            onInputModeChange(scrumItem, ManualInputStatus.LINK)
            onEdit(true)
          }}
        >{"リンクで入力へ"}
        </Button></div>)
    }
    return actionDom
  }
  renderCell = (r, checkResult) => {
    const { selectedCell, currentBoxIdForCss, onGetSum, detailData, currentPageType } = this.props

    const manualInput = r.manualInput
    let cellDom = ""
    const { err, errItem, isAggreation } = checkResult
    if (isAggreation) {
      cellDom = (err ?
        // eslint-disable-next-line
        <ZoomPopup
          ref={(ref) => { this.popups[r.scrumItem] = ref; }}
          onOpen={this.onPopupOpen}
          style={{ zoom: '75%' }}
          content={errItem.formula.replace(/#|\{|\}/g, '')}
          trigger={<Table.Cell>{onGetSum(r.values)}</Table.Cell>}
        />
        : <Table.Cell>{onGetSum(r.values)}</Table.Cell>
      )
    } else if (manualInput === undefined) {
      cellDom = (<Table.Cell
        className={`old-td-selectable ${this.getClass('cell', { rowId: r.id, cellIds: this.getCellIds(r.values), err: null, scrumItem: r.scrumItem }, selectedCell, currentBoxIdForCss, "value")}`}
        onClick={() => this.handleCellOperation(r)}>{onGetSum(r.values)}</Table.Cell>
      )
    } else {
      cellDom = <Table.Cell className={`old-td-selectable `}>
        <Input
          value={detailData['manual_input'][currentPageType][r.scrumItem]}
          fluid
          onChange={(e, data) => this.handleInputChange(r.scrumItem, data.value)} />
      </Table.Cell>
    }

    return cellDom
  }
  renderSeparation = (scrumItem) => {
    const { metadata, currentPageType } = this.props
    if (!metadata || !metadata["separation"]) {
      return null
    }
    const separationList = metadata["separation"]
    const formula = metadata["fs_formula"]
    let spDom, res = null

    const idx = scrumItem.indexOf(SCRUM_ITEM_SUFFIX.AGGREGATION)
    if (idx !== -1 ){
      const aggString  = scrumItem.slice(0, idx)
      res = separationList && separationList.find(sp => {
        return sp.subCategory === currentPageType && sp.item === aggString
      })
    } else {
      const isAgg = formula.find(f=>f.subCategory === currentPageType && f.item === scrumItem)
      if  (!isAgg) {
        res = separationList && separationList.find(sp => {
          return sp.subCategory === currentPageType && sp.item === scrumItem
        })
      }
    }
    if (res) {
      spDom = <Table.Row>
        <Table.Cell colSpan='3' style={{ fontSize: 'medium', background: '#cccccc' }}>
          {res.value}
        </Table.Cell>
      </Table.Row >
    }
    return spDom
  }

  renderRow = () => {

    const { rows, selectedCell, currentBoxIdForCss } = this.props

    const dom = rows.map((r, idx) => {
      const checkResult = this.handleCheck(r.scrumItem)
      const { err, isAggreation } = checkResult
      const row = <Table.Row id={r.scrumItem} key={idx} className={`${this.getClass('row', { rowId: r.id, cellIds: this.getCellIds(r.values), err: err, scrumItem: r.scrumItem }, selectedCell, currentBoxIdForCss, "value")}`}>
        <Table.Cell style={{ background: '#f9fafb' }}>{r.scrumItem}</Table.Cell>
        {this.renderCell(r, checkResult)}

        <Table.Cell className="old-td-action">
          {isAggreation ? null : this.renderAction(r.scrumItem, r.manualInput, r.values)}
        </Table.Cell>
      </Table.Row >
      return <React.Fragment key={idx} >
        {this.renderSeparation(r.scrumItem)}
        {row}
      </React.Fragment>
    })
    return dom
  }

  formatSS = (ssMetadatas) => {

    const { tenantPreference } = this.props;
    const ssTitleSplit = tenantPreference['SS_title_split'] ? tenantPreference['SS_title_split'] : '/';
    const isSSTopLeftInvert = tenantPreference['SS_top_left_invert'] ? Number(tenantPreference['SS_top_left_invert']) : 0;

    let list = [];
    let nextLeftElement = "";
    let prevRightElement = "";
    let newElementList = [];
    ssMetadatas.forEach((d) => {
      const leftElement = isSSTopLeftInvert ?
        d.value.substring(0, d.value.indexOf(ssTitleSplit)) :
        d.value.substring(d.value.indexOf(ssTitleSplit) + 1);
      const rightElement = isSSTopLeftInvert ?
        d.value.substring(d.value.indexOf(ssTitleSplit) + 1) :
        d.value.substring(0, d.value.indexOf(ssTitleSplit));
      if (list.indexOf(leftElement) === -1) {
        if (list.length === 0) {
          list.push(leftElement);
        } else {
          if (prevRightElement !== '' && prevRightElement !== rightElement) {
            list.push(...newElementList);
            list.splice(0, 0, leftElement);
            newElementList = []; // clear array
          } else {
            newElementList.push(leftElement);
          }
          prevRightElement = rightElement;
        }
      } else {
        nextLeftElement = leftElement;
        if (newElementList.length > 0){
          if(list.length === 1){
            list.splice(1, 0, ...newElementList);
          } else if(prevRightElement !== rightElement){
            list.push(...newElementList);
          } else {
            list.splice(list.indexOf(nextLeftElement) - newElementList.length + 1, 0, ...newElementList);
          }
          newElementList = []; // clear array
          prevRightElement = "";
        }
      }
    })
    newElementList.length > 0 && list.push(...newElementList); // when newElementList have data , push to the end of list

    const rowHeader = ssMetadatas.map(d=>
      isSSTopLeftInvert ? d.value.substring(d.value.indexOf(ssTitleSplit)+1): d.value.substring(0,d.value.indexOf(ssTitleSplit))
    )
    const rows = Array.from(new Set(rowHeader))

    const { metadata } = this.props
    const ss_formula = metadata.fs_formula.filter(d=> d.subCategory === PageType['SS'])
    const left_aggre = []
    const right_aggre = []
    // calc aggre column or row
    ss_formula.forEach(row=>{
      const splitIndex = row.item.indexOf(ssTitleSplit)
      const leftHeader = isSSTopLeftInvert ? row.item.substring(splitIndex+1) : row.item.substring(0,splitIndex)
      const rightHeader = isSSTopLeftInvert ? row.item.substring(0,splitIndex) : row.item.substring(splitIndex+1)
      // name not exist in fs_formula value is the aggre name.
      const itemList = row.value.split('+')
      for (let i = 0; i < itemList.length; i++) {
        // column like "#{資本金/当期純利益}" => "資本金/当期純利益"
        const columnName = itemList[i].substring(2,itemList[i].length-1)
        const columnSplitIndex = columnName.indexOf(ssTitleSplit)
        const leftColumn = isSSTopLeftInvert ? columnName.substring(columnSplitIndex+1) : columnName.substring(0,columnSplitIndex)
        const rightColumn = isSSTopLeftInvert ? columnName.substring(0,columnSplitIndex) : columnName.substring(columnSplitIndex+1)
        if(leftHeader === leftColumn){
          // leftHeader is normal item, rightHeader is aggre item
          if(!right_aggre.includes(rightHeader)){
            right_aggre.push(rightHeader)
          }
          break;
        }else if(rightHeader === rightColumn){
          // rightHeader is normal item, leftHeader is aggre item
          if(!left_aggre.includes(leftHeader)){
            left_aggre.push(leftHeader)
          }
          break;
        }
      }
    })

    const ssEquityHeader = []
    rows.forEach(d=>{
      ssEquityHeader.push(d)
      if (left_aggre.indexOf(d)>-1){
        const aggre = d.substring(0,d.indexOf('('))+SCRUM_ITEM_SUFFIX.AGGREGATION
        ssEquityHeader.push(aggre)
      }
    })
    const ssLeftHeader = []
    list.forEach(d=>{
      if(right_aggre.indexOf(d)>-1){
        const aggre = d+SCRUM_ITEM_SUFFIX.AGGREGATION
        ssLeftHeader.push(aggre)
      }
      ssLeftHeader.push(d)
    })
    const row = this.converRow(ssLeftHeader, ssEquityHeader)
    return {
      'Header': ssEquityHeader,
      'data': row,
      'leftAgg': left_aggre,
      'topAgg': right_aggre
    }

  }

  converRow = (ssLeftHeader, ssEquityHeader) =>{
    return ssLeftHeader.map(d=>{
      if( d.indexOf(SCRUM_ITEM_SUFFIX.AGGREGATION)>0){
        return this.convertItem(d,ssEquityHeader,true)
      }else{
        return this.convertItem(d,ssEquityHeader)
      }
    })
  }
  convertItem = (item, ssEquityHeader, isAggre=false) =>{
    const obj = {}
    obj[SubjectName] = item
    ssEquityHeader.forEach(d=>{
      if( d.indexOf(SCRUM_ITEM_SUFFIX.AGGREGATION)>0 || isAggre){
        if(d.indexOf(SCRUM_ITEM_SUFFIX.AGGREGATION)>0 && isAggre){
          obj[d] = {id:'', manualInput: undefined, values:[], disabled: 1}
        }else{
          obj[d] = {id:'', manualInput: undefined, values:[], isAggreation: 1}
        }
      }else{
        obj[d] = {id:'', manualInput: undefined, values:[]}
      }
    })
    return obj
  }

  render() {
    const { currentPageType, rows, onGetSum, selectedCell, currentBoxIdForCss, detailData, onTabChange, setInvertFlag, onEdit, onInputModeChange, selectedBBox, tenantPreference } = this.props;
    const ss_2_dimension = tenantPreference["template_SS"] === "SMFL" && currentPageType === PageType.SS;
    const ss_common = tenantPreference["template_SS"] === "COMMON_TABLE" && currentPageType === PageType.SS;

    const ssTitleSplit = tenantPreference['SS_title_split']?tenantPreference['SS_title_split']:'/';
    const isSSTopLeftInvert = tenantPreference['SS_top_left_invert']?Number(tenantPreference['SS_top_left_invert']):0;

    let ssMetadatas = []
    if(this.props.metadatas){
       ssMetadatas = this.props.metadatas.filter(d=> d.item === PageType['SS'])
    }
    return (
      <div
        style={{ zoom: '75%', height: '95%', overflowY: "auto", marginTop: "12px", overflowX: 'auto', width: '100%' }}
        id='fs-key-value-table' ref={this.TableRef}
        onScroll={() => this.props.onSetScrollTop('fsKeyValueTableScrollTop', this.TableRef.current.scrollTop)
        }>
        {ss_common ?
          <ShareholdersEquityCommonTable
            rows={rows}
            isLink={selectedBBox.id !== ''}
            onGetSum={onGetSum}
            handleCellOperation={this.handleCellOperation}
            handleCheck={this.handleCheck}
            selectedCell={selectedCell}
            currentBoxIdForCss={currentBoxIdForCss}
            getCellIds={this.getCellIds}
            detailData={detailData}
            currentPageType={currentPageType}
            onTabChange={onTabChange}
            setInvertFlag={setInvertFlag}
            onEdit={onEdit}
            onInputModeChange={onInputModeChange}
            onInputChange={this.handleInputChange}
            ssData={this.formatSS(ssMetadatas)}
            ssTitleSplit={ssTitleSplit}
            isSSTopLeftInvert={isSSTopLeftInvert}
          /> : 
          ss_2_dimension ?
          <ShareholdersEquityTable
            rows={rows}
            isLink={selectedBBox.id !== ''}
            onGetSum={onGetSum}
            handleCellOperation={this.handleCellOperation}
            handleCheck={this.handleCheck}
            selectedCell={selectedCell}
            currentBoxIdForCss={currentBoxIdForCss}
            getCellIds={this.getCellIds}
            detailData={detailData}
            currentPageType={currentPageType}
            onTabChange={onTabChange}
            setInvertFlag={setInvertFlag}
            onEdit={onEdit}
            onInputModeChange={onInputModeChange}
            onInputChange={this.handleInputChange}
          /> :
          <Table celled
            selectable
            style={{ width: '100%' }}
          >
            <Table.Body style={{ height: '100%' }}>
              {this.renderHeader()}
              {this.renderRow()}
            </Table.Body>
          </Table>}
      </div>

    )
  }
}
