import React, { Component } from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact';
import {
  Form,
  Button,
  Icon,
  Message,
} from 'semantic-ui-react';
import api from '../../api';

class FsItemEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editFsItem: {
        id: null,
        category: '',
        subTitle: '',
        item: '',
        globalResultItem: '',
      },
      errors: {},
    };
  }

  // shouldComponentUpdate(nextProps) {
  //   if (
  //     nextProps.fsItemId === this.props.fsItemId &&
  //     typeof this.props.fsItemId !== 'undefined' &&
  //     nextProps.fsItemId !== null
  //   ) {
  //     return false;
  //   }
  //   return true;
  // }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.editFsItem && nextProps.editFsItem.id && prevState.editFsItem.id !== nextProps.editFsItem.id) {
      return {...prevState, editFsItem: nextProps.editFsItem};
    }
    return null;
  }

  handleInput = (event) => {
    const { name, value } = event.target;
    const { editFsItem } = this.state;
    const fsItemForm = {};
    fsItemForm[name] = value;
    this.setState({
      editFsItem: { ...editFsItem, ...fsItemForm },
      errors: {}
    });
  };

  checkForm() {
    const { editFsItem } = this.state;
    const errors = {};

    // if (!editFsItem.subTitle) {
    //   errors['subTitle'] = {
    //     content: 'Please enter a subTitle.',
    //     pointing: 'below',
    //     flag: true,
    //   };
    // }

    // if (!editFsItem.item) {
    //   errors['item'] = {
    //     content: 'Please enter an item.',
    //     pointing: 'below',
    //     flag: true,
    //   };
    // }
    if (!editFsItem.category) {
      errors['category'] = {
        content: 'Please enter a category.',
        pointing: 'below',
        flag: true,
      };
    }

    if(!editFsItem.subTitle && !editFsItem.item) {
        errors['subTitleOrItem'] = {
        content: 'Please enter a subTitle or an item.',
        pointing: 'below',
        flag: true,
      };
    }

    if (!editFsItem.globalResultItem) {
      errors['globalResultItem'] = {
        content: 'Please enter a globalResultItem.',
        pointing: 'below',
        flag: true,
      };
    }

    this.setState({
      errors: errors,
    });
    return Object.keys(errors).length === 0;
  }

  handleCancel = () => {
    this.clearForm();
    this.props.toggleEdit(false);
  };

  handleSave = async () => {
    if (!this.checkForm()) {
      return;
    }

    const { editFsItem } = this.state;

    try {
      if (editFsItem.id) {
        const { data } = await api.put(`/dppapi/system/global-preset/${editFsItem.id}`, editFsItem);
        if(!data.success) {
          this.setState({
            errors: { api: {content:data.errmsg} },
          });
          return;
        }
      } else {
        await api.post(`/dppapi/system/global-preset`, editFsItem);
      }
      this.props.handleSearch();
      this.props.toggleEdit(false);
      this.clearForm();
    } catch (e) {
      // handle error
      const { msg } = e.data || {};
      this.setState({
        errors: { msg },
      });
    }
  };

  clearForm = () => {
    this.setState({
      editFsItem: {
        id: null,
        subTitle: '',
        item: '',
        globalResultItem: '',
      },
      errors: {},
    });
  };

  handleToggle = (flag) => {
    this.props.toggleEdit(flag);
    this.setState({
      errors: {},
    });
  }

  render() {
    const { errors, editFsItem } = this.state;

    return (
      <MDBModal isOpen={this.props.isShowEdit} toggle={this.handleToggle}>
        <MDBModalHeader>{editFsItem.id ? "Edit" : "Add"} Global Result Item</MDBModalHeader>
        <MDBModalBody>
          {Object.keys(errors).length > 0 && (
            <Message
              error
              list={Object.keys(errors).map((i) => {
                return errors[i].content;
              })}
            />
          )}
          <Form>
            <Form.Field error={Boolean(errors['subTitleOrItem'])}>
              <label>Sub Title</label>
              <Form.Input
                name="subTitle"
                placeholder="Sub Title"
                value={editFsItem.subTitle}
                onChange={(event) => this.handleInput(event)}
              />
            </Form.Field>
            <Form.Field error={Boolean(errors['subTitleOrItem'])}>
              <label>Item</label>
              <Form.Input
                name="item"
                placeholder="Item"
                value={editFsItem.item}
                onChange={(event) => this.handleInput(event)}
              />
            </Form.Field>
            <Form.Field error={Boolean(errors['category'])}>
              <label>Category</label>
              <Form.Input
                name="category"
                placeholder="category"
                value={editFsItem.category}
                onChange={(event) => this.handleInput(event)}
              />
            </Form.Field>
            <Form.Field error={Boolean(errors['globalResultItem'])}>
              <label>Global Result Item</label>
              <Form.Input
                name="globalResultItem"
                placeholder="Global Result Item"
                value={editFsItem.globalResultItem}
                onChange={(event) => this.handleInput(event)}
              />
            </Form.Field>
            <Button
              size="small"
              onClick={() => this.handleSave()}
              floated="right"
            >
              <Icon name="save" />
              Save
            </Button>
            <Button
              size="small"
              onClick={() => this.handleCancel()}
              floated="right"
            >
              <Icon name="cancel" />
              Cancel
            </Button>
          </Form>
        </MDBModalBody>
      </MDBModal>
    );
  }
}

export default FsItemEdit;
