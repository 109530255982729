import React, { Component } from 'react';
import { Icon, Table, Popup, Input } from 'semantic-ui-react';
import { CancelIcon } from '../../../assets/icon';
import { ManualInputStatus, BScurrentEndBanaceList } from './FsConstant';
import EditBox from './EditBox';
import _ from 'lodash';
import intl from 'react-intl-universal';
import { formatMoney, handleDeleteCommaFromMoney, ignoreSpecialCharForCalc } from '../../Util';
import {
  CurrentEndBanaceAgg,
  CurrentFluctuationAmountAgg,
  CurrentFluctuationAmountInput,
  NetAssetsTotalAgg,
  NetAssetsTotalInput,
  ShareholdersEquityHeader,
  ShareholdersEquityRow,
  ShareholdersEquityTotalAgg,
  ShareholdersEquityTotalInput,
  SubjectName,
} from './FsConstant';
import { AnalyticsServiceContext } from '../../../common/Analytics/AnalyticsServiceProvider';
import BoardPopupContent from './BoardPopupContent';
import { getClass_SS as getClass } from '../../StyleUtil';

let isComposition = false;
const isChrome = navigator.userAgent.indexOf('Chrome') > -1;
export default class ShareholdersEquityTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      row: null,
      header: null,
      selected: [],
      hover: [],
      keepHover: false,
      editing: false,
      numerical: [],
      currentCell: null,
      lastKeyPressed : "",
      showDetailPop: false,
      keepOpen: true,
      popItemName: "",
      calcInputError: false,
    };
    this.multiAggValue1 = [];
    this.multiAggValue2 = [];
    this.multiAggValue3 = [];
    this.multiAggValue4 = [];
    this.popups = {};
    this.onPopupOpen = this.onPopupOpen.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleComposition = this.handleComposition.bind(this);
  }
  static contextType = AnalyticsServiceContext;

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(prevProps.currentBoxIdForCss, this.props.currentBoxIdForCss) && this.props.currentBoxIdForCss
    ) {
      this.findCurrentBBox();
    }
    if (this.input) this.input.focus();
  }

  onOpenInput = (state, scrumItem) =>
  this.setState({ editing: state }, () => {
    if (state) {
      this.handleOpenInputMode(scrumItem);
    }
  });

  onHoverChange = (state, name) => {
    this.props.openable && this.setState({ keepHover: state, popItemName: name ? name : 'EditBox' });
  }

  onKeepOpen = (state) => this.setState({ keepOpen: state });

  onShowDetailPop = (state) => this.setState({ showDetailPop: state });

  onSetCurrentCell = (cell) => this.setState({ currentCell: cell });

  onClearNumerical = () => this.setState({ numerical: [] });

  findCurrentBBox = () => {
    const { rows, currentBoxIdForCss } = this.props;
    const shareholdersEquityRow = _.cloneDeep(ShareholdersEquityRow);
    let tmp = [];
    shareholdersEquityRow.forEach(sr => {
      var tmpRow = sr;
      rows.forEach(r => {
        const scrumItem = r.scrumItem; // 株主資本計(入力)/当期変動額合計(入力)(自動集計)
        const scrumItem_x = scrumItem.substring(0, scrumItem.indexOf('/')); // 株主資本計(入力)
        const scrumItem_y = scrumItem.substring(scrumItem.indexOf('/') + 1); // 当期変動額合計(入力)(自動集計)
        // if (scrumItem_y === sr[SubjectName]) {
        if (scrumItem_y.startsWith(sr[SubjectName])) {
          if (scrumItem_y.endsWith('(自動集計)')) {
            var temp_x = scrumItem_x.replace(/\(入力\)/gi, '(自動集計)');
            if (r.id) tmpRow[temp_x].id = r.id;
            if (r.values) tmpRow[temp_x].values = r.values;
          } else {
            if (r.id) tmpRow[scrumItem_x].id = r.id;
            if (r.values) tmpRow[scrumItem_x].values = r.values;
          }
        }
      });
      tmp.push(tmpRow);
    });

    let newSelected = [];
    tmp.forEach((r, r_idx) => {
      ShareholdersEquityHeader.forEach((h, h_idx) => {
        const data = r[h].values.find(d => d.id === currentBoxIdForCss);
        const oldSelected = this.state.selected;
        if (data && oldSelected && (r_idx !== oldSelected[0] || h_idx !== oldSelected[1] || r[h].id !== oldSelected[2])) {
          newSelected = [r_idx, h_idx, r[h].id];
        }
      })
    });
    if (currentBoxIdForCss) {
      this.setState({ selected: newSelected });
    }
  }

  checkBSLine = (scrumItem) => {
    let checkResult = false;
    BScurrentEndBanaceList.forEach(bs => {
      if (scrumItem && scrumItem.split('(自動集計)')[0] === bs) {
        checkResult = true;
      }
    })
    return checkResult;
  }

  renderShareholdersEquityHeader = () => {
    const dom = ShareholdersEquityHeader.map((header, idx) => {
      const cls = idx === this.state.selected[1] ? 'selected-bd' : '';
      const hoverCls = idx === this.state.hover[1] ? 'title-hover-bd' : '';
      return (
        <Table.Cell
          className={`text-align-center-td ${cls} ${hoverCls}`}
          style={{
            minWidth: '100px',
            width: 'auto',
            background: '#F5F5F5',
            position: 'sticky',
            top: '0',
            zIndex: 100
          }}
          key={idx}
        >
          {header}
        </Table.Cell>
      );
    });
    return (
      <Table.Row>
        <Table.Cell
          className="text-align-left-td border-right-92"
          style={{
            width: '10%',
            background: '#F5F5F5',
            position: 'sticky',
            left: '0',
            zIndex: 110
          }}
        >
          <span className="text-align-left-td-SubjectName">{SubjectName}</span>
        </Table.Cell>
        {dom}
      </Table.Row>
    );
  };

  renderShareholdersEquityRow = () => {
    const { rows } = this.props;
    const shareholdersEquityRow = _.cloneDeep(ShareholdersEquityRow);
    let tmp = [];
    shareholdersEquityRow.forEach(sr => {
      var tmpRow = sr;
      rows.forEach(r => {
        const scrumItem = r.scrumItem; // 株主資本計(入力)/当期変動額合計(入力)(自動集計)
        const scrumItem_x = scrumItem.substring(0, scrumItem.indexOf('/')); // 株主資本計(入力)
        const scrumItem_y = scrumItem.substring(scrumItem.indexOf('/') + 1); // 当期変動額合計(入力)(自動集計)
        // if (scrumItem_y === sr[SubjectName]) {
        if (scrumItem_y.startsWith(sr[SubjectName])) {
          if (scrumItem_y.endsWith('(自動集計)')) {
            var temp_x = scrumItem_x.replace(/\(入力\)/gi, '(自動集計)');
            if (r.id) tmpRow[temp_x].id = r.id;
            if (r.values) tmpRow[temp_x].values = r.values;
            if (r.scrumItem) tmpRow[temp_x].scrumItem = r.scrumItem;
            if (r.manualInput) tmpRow[temp_x].manualInput = r.manualInput;
          } else {
            if (r.id) tmpRow[scrumItem_x].id = r.id;
            if (r.values) tmpRow[scrumItem_x].values = r.values;
            if (r.scrumItem) tmpRow[scrumItem_x].scrumItem = r.scrumItem;
            if (r.manualInput) tmpRow[scrumItem_x].manualInput = r.manualInput;
          }
          if (
            scrumItem ===
            ShareholdersEquityTotalInput + '/' + CurrentFluctuationAmountAgg
          ) {
            this.multiAggValue1 = r.values;
          }
          if (
            scrumItem ===
            NetAssetsTotalInput + '/' + CurrentFluctuationAmountAgg
          ) {
            this.multiAggValue2 = r.values;
          }
          if (
            scrumItem ===
            ShareholdersEquityTotalInput + '/' + CurrentEndBanaceAgg
          ) {
            this.multiAggValue3 = r.values;
          }
          if (scrumItem === NetAssetsTotalInput + '/' + CurrentEndBanaceAgg) {
            this.multiAggValue4 = r.values;
          }
          if (r.manualInput || r.manualInput === '') {
            tmpRow[scrumItem_x].manualInput = r.manualInput;
          }
        }
      });
      tmp.push(tmpRow);
    });

    let cellDom = '';
    return tmp.map((r, r_idx) => {
      const cls = r_idx === this.state.selected[0] ? 'selected-bd' : '';
      const hoverCls = r_idx === this.state.hover[0] ? 'title-hover-bd' : '';
      return (
        <Table.Row id={r[SubjectName]} key={r_idx} className='edit-box-table-tr-ss'>
          <Table.Cell
            className={`text-align-left-td border-right-92 ${cls} ${hoverCls}`}
            style={{
              background: '#F5F5F5',
              position: 'sticky',
              left: '0',
              zIndex: '110',
              minWidth: '130px',
              font: '12px Noto Sans JP',
            }}
          >
            {r[SubjectName].replace(
              /当期末残高\(B\/S入力\)\(自動集計\)/gi,
              '当期末残高(自動集計)'
            )}
          </Table.Cell>
          {ShareholdersEquityHeader.map((h, h_idx) => {
            cellDom = this.handleDistinguishCell(cellDom, r, r_idx, h, h_idx);
            return cellDom;
          })}
        </Table.Row>
      );
    });
  };

  onPopupOpen() {
    Object.values(this.popups).forEach(popup => {
      popup && popup.current && popup.state.show && popup.handleShow(false);
    });
  }

  onMouseOver(hoverList) {
    const isEditing = this.state.editing;
    if (!isEditing) {
      this.setState({ hover: hoverList })
    }
  }

  handleDistinguishCell = (cellDom, r, r_idx, h, h_idx) => {
    const {
      onGetSum,
      handleCheck,
      selectedCell,
      currentBoxIdForCss,
      getCellIds,
      detailData,
      currentPageType,
      onEdit,
      onInputModeChange,
      inputCalc,
      mappingDetail,
      handleUnLinkScrumItem,
      handleSetOppositeValue,
      handleCellOperation,
      pdfInfo,
      handleChangeValue,
      onOpenModeChange,
      openable,
      onUpdateBBox,
      detailItemChanging,
      isNotOwner,
      onShowExclusionControl,
    } = this.props;
    const {
      selected,
      editing,
      currentCell,
      keepHover,
      showDetailPop,
      keepOpen,
      calcInputError
    } = this.state;
    const selectedRowId = selected && selected.length > 0 ? selected[2] : null;
    const isSelectRowbd = (r[h].id === selectedCell.id && 'value' === selectedCell.column) || r[h].id === selectedRowId;
    const scrumItem = h + '/' + r[SubjectName];
    const checkResult = handleCheck(scrumItem);
    let { err, tableItem, diff } = checkResult;
    let { manualInput, values, isAggreation } = r[h];
    let props = this.props;
    const detail = mappingDetail(scrumItem);
    let isError;
    if (detailData.manual_input && currentPageType in detailData.manual_input) {
      const manualValue = detailData.manual_input[currentPageType][scrumItem];
      isError =
        manualValue === intl.get('_predict.detail.mapping.Formula input error') ||
        manualValue === intl.get('_predict.detail.mapping.More than 14 digits');
    }

    let initValue;
    if (!isError && this.state.numerical[1] === undefined) {
      if (manualInput !== undefined) {
        initValue = manualInput;
        if(inputCalc[currentPageType]){
          if(inputCalc[currentPageType][r.scrumItem]){
            initValue = inputCalc[currentPageType][r.scrumItem]
          }
        }
      } else if (values.length > 0) {
        initValue = onGetSum(values);
      }
    } else {
      initValue = this.state.numerical[1];
    }

    let specialStyles;
    const scrumItem_x = scrumItem.substring(0, scrumItem.indexOf('/')); // 株主資本計(入力)
    const scrumItem_y = scrumItem.substring(scrumItem.indexOf('/') + 1);
    const currentCellPositionX = document.getElementById(scrumItem) && document.getElementById(scrumItem).getBoundingClientRect().x;
    const currentCellPositionY = document.getElementById(scrumItem) && document.getElementById(scrumItem).getBoundingClientRect().y;
    if (scrumItem_x.indexOf('(自動集計)') >= 0) {
      specialStyles = { left: '-250px', top: 'auto', marginTop: '-30px' };
    } else if (scrumItem_y.indexOf('(自動集計)') >= 0) {
      specialStyles = { left: currentCellPositionX - 250 + 'px', top: currentCellPositionY - 42 + 'px', position: 'fixed' };
    } else {
      specialStyles = {};
    }
    var renderEditBox = (parentElement) => {
      return <EditBox
        pageType
        rowScrumItem={scrumItem}
        rowValues={values}
        manualInput={manualInput}
        onInputModeChange={this.onInputModeChange}
        onHoverChange={this.onHoverChange}
        onSetCurrentCell={this.onSetCurrentCell}
        onClearNumerical={this.onClearNumerical}
        onPopupOpen={this.onPopupOpen}
        isAggreation={isAggreation === 1 ? true : false}
        isEditing={editing}
        parentElement={parentElement}
        popupItem={tableItem}
        row={r[h]}
        popups={this.popups}
        specialClass={'edit-box-share-holders-div'}
        specialStyles={specialStyles}
        isBSLine={isBSLine}
        onOpenInput={this.onOpenInput}
        openable={true}
        detail={detail}
        onShowDetailPop={this.onShowDetailPop}
        onKeepOpen={this.onKeepOpen}
        showDetailPop={showDetailPop}
        showEditBtn={isAggreation === 1 || isBSLine ? false : true}
        {...props}
      />;
    }

    var renderCorner = (err, diff) => {
      return err ? <Popup
        className={'board-popup-exclamation-triangle'}
        trigger={
          <a className={'err-row-corner'} href="/#" onClick={(e) => e.preventDefault()} tabIndex='-1'>
            <i aria-hidden="true"></i>
          </a>  
        }
        offset={[1, -3]}
        content={`${err && diff}`}
        on='hover'
      /> :
        (<a className={'right-row-corner'} href="/#" onClick={(e) => e.preventDefault()} tabIndex='-1'>
          <i aria-hidden="true"></i>
        </a>);
    }

    if (
      (r[SubjectName] === CurrentFluctuationAmountAgg &&
        h === ShareholdersEquityTotalInput) ||
      (r[SubjectName] === CurrentFluctuationAmountInput &&
        h === ShareholdersEquityTotalAgg)
    ) {
      values = this.multiAggValue1;
    } else if (
      (r[SubjectName] === CurrentFluctuationAmountAgg &&
        h === NetAssetsTotalInput) ||
      (r[SubjectName] === CurrentFluctuationAmountInput &&
        h === NetAssetsTotalAgg)
    ) {
      values = this.multiAggValue2;
    } else if (
      r[SubjectName] === CurrentEndBanaceAgg &&
      h === ShareholdersEquityTotalInput
    ) {
      values = this.multiAggValue3;
    } else if (
      r[SubjectName] === CurrentEndBanaceAgg &&
      h === NetAssetsTotalInput
    ) {
      values = this.multiAggValue4;
    }

    const isBSLine = this.checkBSLine(scrumItem);
    const key = r_idx + ',' + h_idx;
    const isInput = r[h].isInput;
    var temp_x = h.replace(/\(自動集計\)/gi, '(入力)');
    const checkResult_input = handleCheck(temp_x + '/' + r[SubjectName]);
    const isColumnErr = checkResult_input.err;
    const diffColumnErr = checkResult_input.diff;
    const isKeepHover = keepHover && currentCell === scrumItem;
    const isShowDetailPop = showDetailPop && currentCell === scrumItem && isKeepHover;

    var scrumExist = false;
    if (r[h].id) scrumExist = true;
    
    if (r[h].isAggreation) {
      cellDom = err ? (
        // eslint-disable-next-line
        !r[h].noPop ? (
          <Table.Cell
            id={scrumItem}
            className={`table-corner-position err-row-aggreation ${isKeepHover ? 'shareholders-equity-cell-backGround' : ''}`}
            key={key}
          >
            {renderCorner(err, diff)}
            <span className="err-row-aggreation-value">{onGetSum(values)}</span>
            {renderEditBox(
              <div
                className={`edit-box-ellipsis-div ${isKeepHover ? 'edit-box-ellipsis-div-keephover' : ''}`}
              >
                <Icon name='ellipsis horizontal' className={`edit-box-ellipsis ${isKeepHover ? 'edit-box-ellipsis-keepHover' : ''}`} />
              </div>
            )}
          </Table.Cell>
        ) : (
            <Table.Cell id={scrumItem} className={`table-corner-position err-row-aggreation ${isKeepHover ? 'shareholders-equity-cell-backGround' : ''}`} key={key}>
            {renderCorner(err, diff)}
            <span className="err-row-aggreation-value">{onGetSum(values)}</span>
            {renderEditBox(
              <div
                className={`edit-box-ellipsis-div ${isKeepHover ? 'edit-box-ellipsis-div-keephover' : ''}`}
              >
                <Icon name='ellipsis horizontal' className={`edit-box-ellipsis ${isKeepHover ? 'edit-box-ellipsis-keepHover' : ''}`} />
              </div>
            )}
          </Table.Cell>
        )
      ) : (
          <Table.Cell id={scrumItem} key={key} className={`table-corner-position aggregation-row ${isKeepHover ? 'shareholders-equity-cell-backGround' : ''}`}>
          {renderCorner(isColumnErr, diffColumnErr)}
          <span className="err-row-aggreation-value">{(values && onGetSum(values))}</span>
          {renderEditBox(
            <div className={`edit-box-ellipsis-div ${isKeepHover ? 'edit-box-ellipsis-div-keephover' : ''}`}>
              <Icon name='ellipsis horizontal' className={`edit-box-ellipsis ${isKeepHover ? 'edit-box-ellipsis-keepHover' : ''}`} />
            </div>
          )}
        </Table.Cell>
      );
    } else {
      if (editing && currentCell === scrumItem) {
        const showHint = initValue && initValue.startsWith('=') && !calcInputError;
        cellDom = (
          <Table.Cell
            id={scrumItem}
            key={key}
            value={this.state.numerical[1] || '0'}
            className={`td-selectable selected-bd-input`}
            verticalAlign="middle" >
            <div className={`${initValue && initValue.startsWith('=')? 'ss-edit-box-noheight-icon': ''} 'edit-box-input-div-noheight'`}>
              <Popup
                trigger={
                  <span className='board-item-wrap-cancel-icon-ss-always edit-box-icon-cancel'>
                    <CancelIcon
                      color={'#909090'}
                      size='24px'
                      onClick={() => {
                        this.handleSubmit(initValue, scrumItem, values, true);
                        onInputModeChange(scrumItem, ManualInputStatus.LINK);
                        onEdit(true);
                      }}
                    />
                  </span>
                }
                content={intl.get(
                  '_predict.detail.mapping.Cancellation of manual input'
                )}
                on="hover"
                hideOnScroll
                inverted
                size="mini"
              />
            </div>
            <div className={`ss-edit-box-input-div ${showHint ? 'ss-edit-box-input-hint-div' : (calcInputError ? 'ss-edit-box-input-error-div' : '')}`}>
              <Input
                className={`${initValue && initValue.startsWith('=') ? 'edit-box-input-ss-calculate max-length-input' : 'edit-box-input-ss max-length-input'} ${calcInputError ? 'input-mode-calc-error-border-input':''}`}
                value={initValue || ''}
                fluid
                ref={(input) => this.input = input}
                onChange={(e, data) => {
                  this.handleChange(e, data.value, r , initValue);
                }}
                onCompositionStart={(e) => { this.handleComposition(e, r) }}
                onCompositionEnd={(e) => { this.handleComposition(e, r) }}
                onKeyDown={key => {
                  if(key.keyCode === 8){
                    this.setState({ lastKeyPressed : "Backspace"})
                  }else if ( key.keyCode === 46){
                    this.setState({ lastKeyPressed : "Delete"})
                  }else if (key.keyCode === 13) {
                    this.handleSubmit(initValue, scrumItem, values);
                  }
                }}
                onBlur={() => {
                  const rootElement = document.getElementById('root');
                  rootElement.addEventListener('click', (e) => {
                    const noActionItem = e.target.classList[1]==='svg-cancel' || (e.target.classList[3]&&e.target.classList[3]==='edit-box-input-ss-calculate')
                    const noActionParentItem = e.target.parentElement.classList[1]==='svg-cancel' || (e.target.parentElement.classList[3]&&e.target.parentElement.classList[3]==='edit-box-input-ss-calculate')
                    if (!noActionItem && !noActionParentItem) {
                      const res = this.handleSubmit(initValue, scrumItem, values);
                      if(!res){
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    } else {
                      if (noActionItem) {
                        e.target.dispatchEvent(new Event('click'));
                      } else {
                        e.target.parentElement.dispatchEvent(new Event('click'));
                      }
                    }
                  }, { once: true });
                }}
                onClick={() => { this.handleSelectCell(r, h, scrumItem, values, selectedCell, r_idx, h_idx, detail); }}
              />
              {showHint ? (<label className="input-mode-calc-hint-label ss-edit-box-input-hint-label">四則演算記号: +-*/</label>): null}
              {calcInputError ? (<label className="input-mode-calc-hint-error-label ss-edit-box-input-hint-label">数式を正しく入力してください</label>): null}
            </div>
          </Table.Cell>
        );
      } else if (manualInput === undefined) {
        let popupStyle = {};
        let offSetTop = 0;
        if (showDetailPop && keepOpen && isKeepHover) {
          // popupHeight = showDetailPop && document.getElementsByClassName("value-table-pop-item")[0] && document.getElementsByClassName("value-table-pop-item")[0].getBoundingClientRect().height;
          const currentRowElement = scrumItem && document.getElementById(scrumItem) && document.getElementById(scrumItem).getBoundingClientRect();
          if(currentRowElement){
            const currentRowBottom = currentRowElement.bottom;
            const currentRowTop = currentRowElement.top;
            const tableElement = document.getElementsByClassName("fs-key-value-table-ss-2-dimension")[0].getBoundingClientRect();
            const currentHeightBottom = tableElement.bottom - currentRowTop - 8;
            const currentHeightTop = currentRowBottom - tableElement.top;
            const popupHeight = detail.length * 76 + (detail.length - 1) * 2;
            if(currentHeightBottom > popupHeight){
              popupStyle = {}
              offSetTop = popupHeight / 2 - currentRowElement.height / 2;
            }else if(currentHeightTop > popupHeight){
              popupStyle = {}
              offSetTop = -(popupHeight / 2 - currentRowElement.height / 2);
            }else if(currentHeightBottom > currentHeightTop){
              offSetTop = currentHeightBottom / 2 - currentRowElement.height / 2;
              popupStyle = { height: `${currentHeightBottom}px` }
            }else{
              offSetTop = -(currentHeightTop / 2 - currentRowElement.height / 2);
              popupStyle = { height: `${currentHeightTop}px` }
            }
          }
        }
        
        if (scrumExist && !r[h].disabled) {
          if (r[h].noPop) {
            return cellDom = (
              <Table.Cell id={scrumItem}
                className={`table-corner-position ${err ? 'input-err-row' : 'input-aggregation-row'}`}
                style={!isBSLine ? { cursor: 'pointer' } : { cursor: 'text' }}
                key={key}
              >
                <span className="err-row-aggreation-value">{onGetSum(values)}</span>
                {renderEditBox(
                  <div
                    className={`edit-box-ellipsis-div ${isKeepHover ? 'edit-box-ellipsis-div-keephover' : ''}`}>
                    <Icon name='ellipsis horizontal' className={`edit-box-ellipsis ${isKeepHover ? 'edit-box-ellipsis-keepHover' : ''}`} />
                  </div>
                )}
              </Table.Cell>
            )
          }
          this.popups[scrumItem] = React.createRef();
          cellDom = (
            <Table.Cell
              id={scrumItem}
              key={key}
              onMouseEnter={() => {
                this.onMouseOver([r_idx, h_idx, r[h].id])
              }}
              onMouseLeave={() => {
                this.onMouseOver([])
              }}
              style={{ cursor: 'pointer' }}
              className={`table-corner-position ${getClass(
                { rowId: r[h].id, cellIds: getCellIds(values) },
                selectedCell,
                currentBoxIdForCss,
                'value',
                err,
                manualInput,
                isInput,
                selected,
                scrumItem
              )} ${isKeepHover ? 'shareholders-equity-cell-backGround' : ''}`}
              onClick={(e) => {
                this.handleSelectCell(r, h, scrumItem, values, selectedCell, r_idx, h_idx, detail);
                this.handleDoubleClick(e, scrumItem, r[h]);
              }}
            >
              <span className={`${isSelectRowbd ? 'selected-row-bd' : 'err-row-aggreation-value'}`} data-placeholder="Make a new tag"
                onClick={e => {
                  this.handleSelectCell(r, h, scrumItem, values, selectedCell, r_idx, h_idx, detail);
                  this.handleDoubleClick(e, scrumItem, r[h]);
                }}
              >{onGetSum(values)}
              </span>
              {!isShowDetailPop ? (
                renderEditBox(
                  <div
                    className={
                      `edit-box-ellipsis-div test2 
                      ${isKeepHover ? 'edit-box-ellipsis-div-keephover' : ''}
                      `
                    }
                    onMouseEnter={() => {
                      this.onShowDetailPop(false)
                    }}
                    >
                    <Icon name='ellipsis horizontal'
                      className={`edit-box-ellipsis ${isKeepHover ? 'edit-box-ellipsis-keepHover' : ''}`}
                    />
                  </div>
                )
              ) : (
                <Popup
                className="value-table-pop-item"
                offset={[offSetTop, -10]}
                style={popupStyle}
                trigger={
                  <div
                    className={
                      `edit-box-ellipsis-div 
                      ${isKeepHover ? 'edit-box-ellipsis-div-keephover' : ''}
                      `
                    }>
                    <Icon name='ellipsis horizontal'
                      className={`edit-box-ellipsis ${isKeepHover ? 'edit-box-ellipsis-keepHover' : ''}`}
                    />
                  </div>
                }
                position="right center"
                context={document.getElementById(scrumItem)}
                content={
                  <BoardPopupContent
                    detail={detail}
                    row={r}
                    onGetSum={onGetSum}
                    handleUnLinkScrumItem={handleUnLinkScrumItem}
                    onEdit={onEdit}
                    handleSetOppositeValue={handleSetOppositeValue}
                    handleCellOperation={handleCellOperation}
                    selectedCell={selectedCell}
                    manualInput={manualInput}
                    onHoverChange={this.onHoverChange}
                    keepHover={keepHover}
                    popItemName={this.state.popItemName}
                    onKeepOpen={this.onKeepOpen}
                    showDetailPop={showDetailPop}
                    onShowDetailPop={this.onShowDetailPop}
                    pdfInfo={pdfInfo}
                    showEditBtn={true}
                    handleChangeValue={handleChangeValue}
                    onOpenModeChange={onOpenModeChange}
                    openable={openable}
                    onUpdateBBox={onUpdateBBox}
                    detailItemChanging={detailItemChanging}
                    isSSPage={true}
                    isNotOwner={isNotOwner}
                    onShowExclusionControl={onShowExclusionControl}
                  />
                }
                open={keepOpen}
                onMount={()=>{
                  if(r[h].id !== selectedCell.id){
                    this.handleSelectCell(r, h, scrumItem, values, selectedCell, r_idx, h_idx, detail);
                  }
                }}
                onClose={() => {
                  if (keepHover) {
                    this.setState({
                      keepOpen: true,
                      showDetailPop: true,
                    })
                  } else {
                    this.setState({
                      keepOpen: false,
                      showDetailPop: false,
                    })
                  }
                }}
                on="click"
                hideOnScroll
                basic
                flowing
              />
              )
              }
            </Table.Cell>
          );
        } else {
          cellDom = (
            <Table.Cell id={scrumItem} key={key} className={`table-corner-position scrumNotExist-td ${isKeepHover ? 'shareholders-equity-cell-backGround' : ''}`}
              onClick={e => {
                this.handleSelectCell(r, h, scrumItem, values, selectedCell, r_idx, h_idx, detail);
                // this.handleDoubleClick(e, scrumItem, r[h])
              }
              } />
          );
        }
      } else {
        cellDom = (
          <Table.Cell
            id={scrumItem}
            key={key}
            onMouseEnter={() => {
              this.onMouseOver([r_idx, h_idx, r[h].id]);
              if (!editing) this.setEllipsisNotDisplay(true, scrumItem);
            }}
            onMouseLeave={() => {
              this.onMouseOver([]);
              if (!editing) this.setEllipsisNotDisplay(false, scrumItem);
            }}
            onClick={e => {
              this.handleSelectCell(r, h, scrumItem, values, selectedCell, r_idx, h_idx, detail);
              this.handleDoubleClick(e, scrumItem, r[h]);
              if (!editing) this.setEllipsisNotDisplay(false, scrumItem);
            }}
            style={{ cursor: 'pointer' }}
            className={`table-corner-position table-cell-not-middle ${getClass(
              { rowId: r[h].id, cellIds: getCellIds(values) },
              selectedCell,
              currentBoxIdForCss,
              'value',
              err,
              manualInput,
              isInput,
              selected,
              scrumItem
            )} ${isKeepHover ? 'shareholders-equity-cell-backGround' : ''}`}
          >
            <div className={`${isError ? 'edit-box-input-div-err' : 'edit-box-input-div'}`}>
              <Popup
                trigger={
                  <span className={`board-item-wrap-cancel-icon-ss edit-box-icon-cancel`}>
                    <CancelIcon
                      color={'#909090'}
                      size='24px'
                      onClick={() => {
                        onInputModeChange(scrumItem, ManualInputStatus.LINK);
                        onEdit(true);
                      }}
                    />
                  </span>
                }
                content={intl.get(
                  '_predict.detail.mapping.Cancellation of manual input'
                )}
                on="hover"
                hideOnScroll
                inverted
                size="mini"
              />
              {detailData.manual_input[currentPageType][scrumItem] || '0'}
            </div>
            {renderEditBox(
              <div
                className={`edit-box-ellipsis-div ${isKeepHover ? 'edit-box-ellipsis-div-keephover' : ''}`}>
                <Icon name='ellipsis horizontal' className={`edit-box-ellipsis ${isKeepHover ? 'edit-box-ellipsis-keepHover' : ''}`} />
              </div>
            )}
          </Table.Cell>
        );
      }
    }

    return cellDom;
  };

  handleComposition(e,r) {
    if (e.type === 'compositionend') {
      isComposition = false;
      let data = e.target.value.replace(/[^0-9,-]/g, '');
      if (!isComposition && isChrome) {
        this.handleChange(e, data, r);
      }
    } else {
      isComposition = true;
    }
  }

  setEllipsisNotDisplay = (state, scrumItem) => {
    var ellipsisElements = document.getElementsByClassName('edit-box-ellipsis-div');
    for (var i = 0; i < ellipsisElements.length; i++) {
      if (!scrumItem || ellipsisElements[i].parentElement.id !== scrumItem) {
        if (state) {
          ellipsisElements[i].style.display = 'none';
          ellipsisElements[i].parentElement.classList.add('tr-noHover');
          ellipsisElements[i].parentElement.style.cursor = 'default';
        } else {
          ellipsisElements[i].style.display = 'block';
          ellipsisElements[i].parentElement.classList.remove('tr-noHover');
          ellipsisElements[i].parentElement.style.cursor = 'pointer';
        }
      }
    }
  }

  handleDoubleClick = (e, scrumItem) => {
    if (e.detail === 2) {
      e.stopPropagation();
      // if (this.props.isNotOwner) return;

      this.onClearNumerical();
      this.onSetCurrentCell(scrumItem);
      this.onOpenInput(true, scrumItem);
      this.props.onInputModeChange(scrumItem, ManualInputStatus.INPUT);
      this.props.onEdit(true);
      this.setEllipsisNotDisplay(true);
    }
  }
  /* eslint no-eval: 0 */
  handleChange = (e, money, r , initValue) => {
    //It can be triggered only when the input method is not used or after using the input method
    if (!isComposition) {
      let target = e.target;

      const startPosition = target.selectionStart;

      const initialState = initValue ? initValue : "";
      const prevMoney = this.state.numerical[1] ? String(this.state.numerical[1]) : initialState;
      
      const [currentMoney , diffPosition] = handleDeleteCommaFromMoney(prevMoney, money, startPosition, this.state.lastKeyPressed)

      const [convertText, finalPosition, finalSetFlag, headMinusFlag] = formatMoney(target.selectionStart, currentMoney);
      if (headMinusFlag) {
        if (convertText.length > 19 && !convertText.startsWith('=')) return;
      } else {
        if (convertText.length > 18 && !convertText.startsWith('=')) return;
      }
      if (finalSetFlag) {
        this.setState({ numerical: [r.scrumItem, convertText] });
      } 
      this.setState({}, () => {
        target.selectionStart = finalPosition + diffPosition;
        target.selectionEnd = finalPosition + diffPosition;
      })
    } else {
      this.setState({ numerical: [r.scrumItem, money] });
    }
    this.setState({lastKeyPressed : "", calcInputError: false})
  }

  handleSelectCell = (r, h, scrumItem, values, selectedCell, r_idx, h_idx, detail) => {
    this.props.onSetFilterItems([]);
    this.props.handleCellOperation({
      id: r[h].id,
      scrumItem: scrumItem,
      values: values,
      manualInput: r[h].manualInput,
    }, detail);
    if (
      !selectedCell.id ||
      selectedCell.id === '' ||
      selectedCell.id !== r[h].id
    ) {
      this.setState({ selected: [r_idx, h_idx, r[h].id, scrumItem] });
    } else {
      this.setState({ selected: [] });
    }
  }

  handleOpenInputMode = (scrumItem) => {
    const { onInputModeChange, onEdit, inputCalc, currentPageType } = this.props;
    this.setState({ editing: true }, () => {
      this.input.focus();
      this.input.select();
    });
    onInputModeChange(scrumItem, ManualInputStatus.INPUT);
    onEdit(true);
    this.setEllipsisNotDisplay(true, scrumItem);

    if(inputCalc[currentPageType]){
      if(inputCalc[currentPageType][scrumItem]){
        const initValue = inputCalc[currentPageType][scrumItem]
        this.setState({
          numerical: [scrumItem, initValue, false]
        })
      }
    }
  }

  handleSubmit = (initValue, scrumItem, values, alertFlag=false) => {
    let convertText = initValue;
    if (initValue && initValue.startsWith('=')) {
      convertText = convertText.substring(1);
      if (!convertText) {
        this.setState({ numerical: [scrumItem, ''] });
      } else {
        try {
          let editValue = initValue.replace(/,/g, '');
          editValue = ignoreSpecialCharForCalc(editValue);
          convertText = new Intl.NumberFormat('ja-JP', { style: 'decimal', currency: 'CAD', }).format(Math.round(eval(editValue.substring(1))));
          this.setState({ numerical: [scrumItem, convertText] });
        } catch (error) {
          convertText = intl.get('_predict.detail.mapping.Formula input error');
          this.setState({ numerical: [scrumItem, initValue, true] });
          if(!alertFlag){
            this.setState({calcInputError: true})
            return false;
          }
        }
      }
    }
    if (convertText && convertText.replace('-', '').length > 18) {
      convertText = intl.get('_predict.detail.mapping.More than 14 digits');
    }
    this.setState({ editing: false})
    this.setEllipsisNotDisplay(false);
    if (this.state.numerical[1] === undefined && !initValue) {
      this.setState({ numerical: [scrumItem, ''] });
      this.props.onInputChange(scrumItem, undefined, '');
    } else {
      this.props.onInputChange(
        scrumItem,
        !this.state.numerical[1] || (initValue && initValue.startsWith('=')) ? convertText : this.state.numerical[1],
        initValue && initValue.startsWith('=') && convertText ? initValue : ''
      );
    }
    if (convertText === this.props.onGetSum(values)) {
      this.props.onInputModeChange(scrumItem, ManualInputStatus.LINK);
      this.props.onEdit(true);
    }
    this.setState({calcInputError: false})
    return true;
  }

  render() {
    return (
      <Table celled className="shareholders-equity-table-wrap"
        onClick={() => {
          if (this.state.showDetailPop) {
            this.setState({
              keepOpen: false,
              keepHover: false,
              showDetailPop: false,
            })
          }
        }}
      >
        <Table.Body>
          {this.renderShareholdersEquityHeader()}
          {this.renderShareholdersEquityRow()}
        </Table.Body>
      </Table>
    );
  }
}
