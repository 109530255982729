const axios = require('axios')
const { history } = require('./history')
const { ROOT_URI } = require('../HistoryListener')
const { cryptoEncrypt, cryptoDecrypt } =  require('../common/EncryptUtil')

const api = axios.create({
  timeout: 500000
})

// add csrf-token for every api request
api.interceptors.request.use(req => {
  const csrfToken = cryptoDecrypt(localStorage.getItem('csrf'))
  req.headers.common['csrf-token'] = csrfToken
  return req
})

api.interceptors.response.use(resp => {

  const csrf = cryptoEncrypt(resp.headers['csrf-token'])
  localStorage.setItem('csrf', csrf ? csrf : '')

  return resp
}, err => {

  // auth interceptor
  const { response = {} } = err

  if (response.status === 401) {
    localStorage.clear()

    if (window.location.pathname === `${ROOT_URI}/login/two-stage-authentication`) {
      window.location = `${ROOT_URI}/login`
    } else if (
      window.location.pathname.indexOf(`${ROOT_URI}/login`) === -1 &&
      window.location.pathname.indexOf(`${ROOT_URI}/help`) === -1
    ) {
      window.location = `${ROOT_URI}/login?redirectTo=${window.location.pathname}`
    }
  }

  if (response.status === 402) {
    window.location = `${ROOT_URI}/login/two-stage-authentication?redirectTo=${window.location.pathname}`
  }

  if (response.status === 403) {
    history.replace(`${ROOT_URI}/message`, {
      msg: response.msg || 'Sorry, you have no access to visit this page.',
      warning: true
    })
    window.location = `${ROOT_URI}/message`
  }

  // http statue code 303, force redirect to /password/reset when person need reset password, 
  if (response.status === 303) {
    window.location = `${ROOT_URI}/password/reset`
  }

  return Promise.reject(response)
})

export default api