import React from 'react';

import { MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact';
import {
  Button,
  Form,
  Message
} from 'semantic-ui-react';
import api from '../../api';

export default class PermissionEdit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      permission: {},
      errors: {},
    }

    this.handleInput = this.handleInput.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.checkForm = this.checkForm.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.clearErrors = this.clearErrors.bind(this)
    this.clearPermission = this.clearPermission.bind(this)
  }

  handleInput(event) {
    const {name, value} = event.target
    const permission = {...this.state.permission}
    permission[name] = value
    this.setState({
      permission: permission
    })
  }

  handleFocus() {
    this.clearErrors()
  }

  clearErrors() {
    this.setState({
      errors: {}
    })
  }

  clearPermission() {
    this.setState({
      permission: {}
    })
  }

  async handleSave() {
    const permission = Object.assign({}, this.props.permission, this.state.permission) 

    if(!this.checkForm(permission)) {
      return
    }
    

    const { success } = await (await api.request({
      url: `/dppapi/permission${this.props.permission.id ? '/' + this.props.permission.id : ''}`,
      method: this.props.permission.id ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(permission),
    })).data

    if(success) {
      this.props.getPermissions()
    }

    this.props.toggleEdit(false)
    this.clearErrors()
    this.clearPermission()
  }

  checkForm(permission) {
    const errors = {}
    if (!permission.name) {
      errors['name'] = {content: 'Please enter a permission name.', pointing: 'below', flag: true}
    }
    
    this.setState({
      errors: errors
    })
    return Object.keys(errors).length === 0
  }

  render() {
    const { permission = {}, isShowEdit, toggleEdit } = this.props
    const { errors } = this.state

    return (
      <MDBModal isOpen={isShowEdit} toggle={toggleEdit}>
        <MDBModalHeader>
          {permission && permission.id ? 'Edit Permission' : 'Add Permission'}
        </MDBModalHeader>
        <MDBModalBody>
        {
          Object.keys(errors).length > 0 &&
          <Message
            error
            list={ Object.keys(errors).map(i => {
              return errors[i].content
            })}
          />
        }
          <Form>
            <Form.Field error={errors['name'] && errors['name']['flag']}>
              <label>Name</label>
              <Form.Input placeholder="Name" defaultValue={permission.name} name="name" onChange={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            <Button floated='right' onClick={this.handleSave}>submit</Button>
            <Button floated='right' onClick={() => {this.props.toggleEdit(false); this.clearErrors();this.clearPermission();}}>cancel</Button>
          </Form>
        </MDBModalBody>
      </MDBModal>
    );
  }
}
