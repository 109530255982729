import React, { Component } from 'react';
import { Form, Input, Button, Segment, Message, Loader } from 'semantic-ui-react';
import './ItemSearchPopup.css';
import api from '../../../api'
import intl from 'react-intl-universal';
import { MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact';
import { MDBDataTable } from 'mdbreact';
import shallowEqualObjects from 'shallow-equal/objects';
export default class ItemSearchPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      fsItemRslt: null,
      selectItem: null,
      maxFlag: false,
      loading: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      shallowEqualObjects(this.state, nextState)
    ) {
      return false;
    }
    return true;
  }
  handleToggle = (flag) => {
    this.props.toggleSearch(flag);
    this.setState({
      form: {},
    });
  }

  handleInput = (event) => {
    const { name, value } = event.target;
    const { form } = this.state;
    const fsItemForm = {};
    fsItemForm[name] = value;
    this.setState({
      form: { ...form, ...fsItemForm },
    });
  };

  handleSearch = async () => {
    const { subTitle = '', item = '' } = this.state.form;
    const { category } = this.props;
    this.setState({ loading: true });
    const { data } = await api.get(
      `/dppapi/predict/search-sfm?category=${category}&subTitle=${subTitle}&item=${item}&limit=50`
    );
    this.setState({ fsItemRslt: data.list, maxFlag: data.page && data.page.total > 50, loading: false });
  };

  handleLinkTo = ( value ) => {
    const { textMappingRef, bboxId } = this.props;
    if (textMappingRef) {
      textMappingRef.current.handleScrumItemChange(bboxId, value, true)
    }
    this.handleToggle(false)
  }

  reanderTable = () => {
    const { fsItemRslt, maxFlag } = this.state;
    if (!fsItemRslt || fsItemRslt.length === 0) {
      return (<Message info header="No Data" content="Please change conditions" />);
    }

    const columns = [
      {
        label: 'Sub Title',
        field: 'subTitle',
        sort: 'asc',
      },
      {
        label: 'Item',
        field: 'item',
        sort: 'asc',
      },
      {
        label: 'Category',
        field: 'category',
        sort: 'asc',
      },
      {
        label: '結果',
        field: 'globalResultItem',
        sort: 'asc',
      },
      {
        label: 'Operation',
        field: 'operation',
        sort: 'asc',
      },
    ];
    const rows = fsItemRslt.map(d => {
      return {
        subTitle: d.subTitle,
        item: d.item,
        category: d.category,
        globalResultItem: d.globalResultItem,
        operation: (
          <Button size="small" className="item-search-popup-btn" onClick={() => this.handleLinkTo(d.globalResultItem)}>
            {intl.get('_predict.detail.pdf.MuiPopup.ItemSearch.Set')}
          </Button>
        )
      }
    })
    const data = {
      columns: columns,
      rows: rows,
    };

    return (
      <div>
        {maxFlag ? (<label className="item-search-popup-label">検索結果が最大五十件表示できます。</label>) : null}
        <Segment className="item-search-popup-seg">
          <MDBDataTable btn bordered hover data={data} onPageChange={() => console.log('page change')}/>
        </Segment>
      </div>
    )
  }

  render() {
    const { fsItemRslt, loading } = this.state;
    const table = this.reanderTable();
    return (
      <div className='item-search-popup-container'>
        <MDBModal isOpen={this.props.isShowEdit} toggle={this.handleToggle}>
          <MDBModalHeader style={{ justifyContent: 'center' }}>マッピング検索</MDBModalHeader>
          <MDBModalBody>
            {loading ? (<Loader active inline="centered" />)
              : (
                <div>
                  <Segment>
                    <Form>
                      <Form.Group inline>
                        <Form.Field>
                          <label>Sub Title</label>
                          <Input
                            name="subTitle"
                            placeholder="Sub Title"
                            defaultValue={this.state.form.subTitle}
                            onChange={(event) => this.handleInput(event)}
                          />
                        </Form.Field>
                        <Form.Field>
                          <label>Item</label>
                          <Input
                            name="item"
                            placeholder="Item"
                            value={this.state.form.item}
                            onChange={(event) => this.handleInput(event)}
                          />
                        </Form.Field>
                        <Form.Field>
                          <label>Category</label>
                          <Input
                            name="category"
                            placeholder="category"
                            value={this.props.category}
                            disabled
                          />
                        </Form.Field>
                      </Form.Group>
                      <div className="item-search-popup-div">
                        <Button size="small" className="item-search-popup-btn" onClick={() => this.handleToggle(false)}>
                          {intl.get('_predict.detail.pdf.MuiPopup.ItemSearch.Cancel')}
                        </Button>
                        <Button size="small" className="item-search-popup-btn" onClick={() => this.handleSearch()}>
                          {intl.get('_predict.detail.pdf.MuiPopup.ItemSearch.Search')}
                        </Button>
                      </div>
                    </Form>
                  </Segment>
                  {fsItemRslt ? table : null}
                </div>
              )}
          </MDBModalBody>
        </MDBModal>
      </div>
    );
  }
}
