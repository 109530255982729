import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as BadgeInuse } from './svg/badge_inuse.svg';
import './icon.css';

export class BadgeInuseIcon extends Component {

  render() {
    const { color, className, disabled, width, height, onClick } = this.props;
    const clsDisable = disabled ? ' svg-icon-disable' : '';

    return (
      <BadgeInuse
        className={`svg-icon-wrapper ${className}${clsDisable}`}
        style={{ fill: color, width: width, height: height }}
        onClick={disabled ? () => {} : onClick}
      />
    );
  }
}

BadgeInuseIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

BadgeInuseIcon.defaultProps = {
  color: '#000000',
  className: '',
  width: '50px',
  height: '21.921px',
  disabled: false,
  onClick: () => {},
};
