import React, { Component } from 'react';
import { Menu, Segment } from 'semantic-ui-react';
import Maintain from './maintain';
import Audits from './audits';
import TenantSettings from './setting';
import ProjectsSettings from './projects';
import intl from 'react-intl-universal';
import Users from './user/users'
export default class Tenants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '/system/tenants',
    };
  }

  handleSwitchTab = label => {
    this.setState({activeTab: label});
  }

  render() {
    const { activeTab } = this.state
    const tabs = [
      {
        label: intl.get('_system.tenants.Index.Maintain'),
        url: '/system/tenants'
      },
      {
        label: intl.get('_system.tenants.Index.Audits'),
        url: '/system/tenants/audits'
      },
      {
        label: intl.get('_system.tenants.Index.TenantSettings'),
        url: '/system/tenants/preferences'
      },
      {
        label: intl.get('_system.tenants.Index.ProjectSettings'),
        url: '/system/tenants/projects'
      },
      // {
      //   label: intl.get('_system.tenants.Index.User'),
      //   url: '/system/tenants/User'
      // },
    ];
  
    return (
      <Segment>
        <Menu
          vertical
          tabular
          style={{ position: 'absolute', left: '-100px', width: '100px' }}
        >
          { 
            tabs.map(tab => {
              return <Menu.Item
                  name={tab.label}
                  component={tab.url}
                  active={activeTab === tab.url}
                  onClick={() => this.handleSwitchTab(tab.url)}
                  key={tab.label}
              />
            })
          }
        </Menu>
        {
          activeTab === '/system/tenants' && <Maintain />
        }
        {
          activeTab === '/system/tenants/audits' && <Audits />
        }
        {
          activeTab === '/system/tenants/preferences' && <TenantSettings />
        }
        {
          activeTab === '/system/tenants/User' && <Users />
        }
        {
          activeTab === '/system/tenants/projects' && <ProjectsSettings />
        }
      </Segment>
    );
  }
}