import React, { Component } from 'react';
import api from '../../../api'

export function withLoadImageData(Comp) {
  return class LoadImageLayer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        height: null,
        width: null,
        imageData: null,
      };
      this.rotate = this.rotate.bind(this);

      this.componentDidUpdate({}, this.state);
    }

    componentDidUpdate(prevProps, prevState) {
      const { imageUrl } = this.props;

      if (imageUrl !== prevProps.imageUrl) {
        const img = new Image();
        const setState = this.setState.bind(this);
        img.onload = async function () {
          const { height, width } = this;
          setState({ height, width });

          const resetImage = () => {
            const canvas = document.getElementById('test-canvas');
            const ctx = canvas.getContext('2d');

            //const scale = demo ? 0.5 : Math.min(800, height) / height;
            //const sHeight = Math.floor(scale * height);
            //const sWidth = Math.floor(scale * width);
            const limit_h = 3500;
            const scale = height <= limit_h ? 1.0 : limit_h / height;
            const sHeight = height;
            const sWidth = width;
            canvas.height = sHeight;
            canvas.width = sWidth;
            ctx.imageSmoothingQuality = 'high';
            ctx.imageSmoothingEnabled = true;
            ctx.drawImage(img, 0, 0, sWidth, sHeight);
            const imgB64 = canvas
              .toDataURL()
              .substring('data:image/png;base64,'.length);

            canvas.height = height;
            canvas.width = width;
            ctx.drawImage(img, 0, 0, width, height);
            const data = ctx.getImageData(0, 0, width, height).data;

            setState({ imageData: data, imgB64, b64Scaling: scale });
          };

          if (document.readyState !== 'loading') {
            resetImage();
          } else {
            document.addEventListener('DOMContentLoaded', resetImage);
          }
        };
        img.src = imageUrl;
      }
    }

    rotate = async (url, projectId, pdfFileId, pdfImageId) => {
      const { height, width } = this.state;
      const canvas = document.getElementById('test-canvas');

      const ctx = canvas.getContext('2d');
      canvas.height = width;
      canvas.width = height;
      ctx.translate(height, 0);
      // if (!angle) {
      //   canvas.height = width;
      //   canvas.width = height;
      //   ctx.translate(height, 0);
      //   angle = 90;
      // } else {
      //   canvas.height = height;
      //   canvas.width = width;
      //   ctx.translate(0, 0);
      // }

      ctx.fillStyle = "#fff";
      ctx.fillRect(0 ,0 , canvas.width, canvas.height);
      ctx.rotate((90 * Math.PI) / 180);

      const img = new Image()
      img.src = this.props.imageUrl
      ctx.drawImage(img, 0, 0);
      const imgB64 = canvas.toDataURL()

      return (await api.post(`/dppapi/predict/${projectId}/${pdfFileId}/${pdfImageId}/rotate`, { imgB64, url })).data;
    }

    render() {
      const { rotate, props, state } = this;
      const { height, width, imageData, imgB64, b64Scaling } = state;

      if (!height) return null;

      return (
        <Comp
          height={height}
          width={width}
          imageData={imageData}
          imgB64={imgB64}
          b64Scaling={b64Scaling}
          rotate={rotate}
          {...props}
        />
      );
    }
  };
}
