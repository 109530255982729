import { assign } from 'lodash'

// const CALC_DIFF = {
//   // 一般法人＿新基準：《貸借対照表（Ｂ／Ｓ）》
//   "流動資産計(入力)": 5,
//   "有形固定資産計(入力)": 5,
//   "無形固定資産計(入力)": 2,
//   "投資等計(入力)": 5,
//   "繰延資産合計(入力)": 0,
//   "資産合計(入力)": 2,
//   "流動負債計(入力)": 5,
//   "固定負債計(入力)": 2,
//   "特定引当金合計(入力)": 0,
//   "資本剰余金(入力)": 1,
//   "利益剰余金(入力)": 2,
//   "株主資本(入力)": 2,
//   "評価･換算差額等(入力)": 1,
//   "純資産計(入力)": 2,
//   "負債純資産合計(入力)": 5,
//   // 一般法人＿新基準：《損益計算書（Ｐ／Ｌ）単体》
//   "売上総利益(入力)": 2, // special !!
//   "営業利益(入力)": 1, // special !!
//   "営業外収益計(入力)": 2,
//   "営業外費用計(入力)": 2,
//   "経常利益(入力)": 1, // special !!
//   "特別利益計(入力)": 2,
//   "特別損失計(入力)": 2,
//   "税前利益(入力)": 1, // special !!
//   "当期利益(入力)": 1, // special !!
//   // 一般法人＿新基準：《株主資本等変動計算書（Ｓ／Ｓ）》
//   "資本金/当期変動額合計(入力)": 2,
//   "新株申込拠出金/当期変動額合計(入力)": 2,
//   "資本剰余金/当期変動額合計(入力)": 2,
//   "利益剰余金/当期変動額合計(入力)": 2,
//   "自己株式/当期変動額合計(入力)": 2,
//   "株主資本計(入力)/☆前期末残高": 2,
//   "株主資本計(入力)/新株の発行": 1,
//   "株主資本計(入力)/剰余金の配当": 1,
//   "株主資本計(入力)/当期純利益": 1,
//   "株主資本計(入力)/自己株式の取得･処分": 1,
//   "株主資本計(入力)/自己株式の消却": 1,
//   "株主資本計(入力)/資本金･準備金/剰余金間の振替": 1,
//   "株主資本計(入力)/その他株主資本金の変動": 1,
//   "株主資本計(入力)/当期変動額合計(入力)": 2,
//   "評価･換算差額等/当期変動額合計(入力)": 2,
//   "新株予約権/当期変動額合計(入力)": 2,
//   "純資産合計(入力)/☆前期末残高": 2,
//   "純資産合計(入力)/新株の発行": 0,
//   "純資産合計(入力)/剰余金の配当": 0,
//   "純資産合計(入力)/当期純利益": 0,
//   "純資産合計(入力)/自己株式の取得･処分": 0,
//   "純資産合計(入力)/自己株式の消却": 0,
//   "純資産合計(入力)/資本金･準備金/剰余金間の振替": 0,
//   "純資産合計(入力)/その他株主資本金の変動": 2,
//   "純資産合計(入力)/当期変動額合計(入力)": 2,
//   "資本金/当期末残高(B/S入力)": 2,
//   "新株申込拠出金/当期末残高(B/S入力)": 2,
//   "資本剰余金/当期末残高(B/S入力)": 2,
//   "利益剰余金/当期末残高(B/S入力)": 2,
//   "自己株式/当期末残高(B/S入力)": 2,
//   "株主資本計(入力)/当期末残高(B/S入力)": 2,
//   "評価･換算差額等/当期末残高(B/S入力)": 2,
//   "新株予約権/当期末残高(B/S入力)": 2,
//   "純資産合計(入力)/当期末残高(B/S入力)": 2,
// }

// const CALC_FORMULA = {
//   // ------------------ + Test Data + -------------------------
//   // "長期前払費用": "#{その他投融資等}+#{その他無形固定資産}",
//   // ----------------------------------------------------------
//   "流動資産計(入力)": "#{現金預金}+#{受取手形}+#{売掛金}+#{完成工事未収入金}+#{有価証券}+#{製品･商品}+#{販売用不動産}+#{半製品･仕掛品}+#{未成工事支出金}+#{原材料}+#{貯蔵品}+#{その他棚卸資産}+#{前払金}+#{前渡金}+#{前払費用}+#{未収金}+#{未収入金}+#{未収収益}+#{短期貸付金}+#{関係会社短期貸付金}+#{仮払金}+#{仮払税金}+#{立替金}+#{繰延税金資産(短期)}+#{その他流動資産}+#{貸倒引当金(短期)}",
//   "有形固定資産計(入力)": "#{建物}+#{建物附属設備}+#{構築物}+#{機械･装置･設備}+#{営業用固定資産･ﾘｰｽ資産}+#{車輌運搬具}+#{工具･器具･備品}+#{その他償却資産}+#{土地}+#{建設仮勘定}",
//   "無形固定資産計(入力)": "#{のれん}+#{ソフトウエア}+#{電話加入権}+#{その他無形固定資産}",
//   "投資等計(入力)": "#{投資有価証券}+#{出資金}+#{関係会社株式}+#{長期貸付金}+#{関係会社長期貸付金}+#{保証金}+#{差入保証金}+#{長期前払費用}+#{繰延税金資産(長期)}+#{保険積立金}+#{破産･更生債権}+#{その他投融資等}+#{貸倒引当金(長期)}",
//   "繰延資産合計(入力)": "#{繰延資産}",
//   "資産合計(入力)": "#{流動資産計(入力)}+#{有形固定資産計(入力)}+#{無形固定資産計(入力)}+#{投資等計(入力)}+#{繰延資産合計(入力)}",
//   "流動負債計(入力)": "#{支払手形}+#{買掛金}+#{工事未払金}+#{短期借入金}+#{関係会社短期借入金}+#{長期中１年以内返済分}+#{１年以内償還社債}+#{輸入引受手形}+#{未払金}+#{未払消費税}+#{未払費用}+#{前受金}+#{未成工事受入金}+#{預り金}+#{従業員預り金}+#{前受収益}+#{仮受金}+#{割賦未実現利益}+#{賞与引当金}+#{未払事業税}+#{未払法人税}+#{その他短期の引当金}+#{設備支手･未払金}+#{繰延税金負債(短期)}+#{その他流動負債}+#{貸倒引当金(流動負債)}",
//   "固定負債計(入力)": "#{長期借入金}+#{関係会社長期借入金}+#{社債}+#{退職給付引当金}+#{その他長期の引当金}+#{繰延税金負債(長期)}+#{貸倒引当金(固定負債)}+#{その他固定負債}",
//   "特定引当金合計(入力)": "#{特定引当金}",
//   "資本剰余金(入力)": "#{資本準備金}+#{その他資本剰余金}",
//   "利益剰余金(入力)": "#{利益準備金}+#{任意積立金}+#{別途積立金}+#{繰越利益剰余金}",
//   "株主資本(入力)": "#{資本金}+#{資本剰余金(入力)}+#{利益剰余金(入力)}+#{自己株式}+#{新株式申込証拠金}",
//   "評価･換算差額等(入力)": "#{その他有価証券評価差額金}+#{繰延ヘッジ損益}+#{土地再評価差額金}",
//   "純資産計(入力)": "#{株主資本(入力)}+#{評価･換算差額等(入力)}",
//   "負債純資産合計(入力)": "#{流動負債計(入力)}+#{固定負債計(入力)}+#{特定引当金合計(入力)}+#{純資産計(入力)}",
//   "売上総利益(入力)": "#{売上高}-(#{売上原価})", // special !!
//   "営業利益(入力)": "#{売上総利益(入力)}-(#{販管費})", // special !!
//   "営業外収益計(入力)": "#{受取利息配当金}+#{資産処分益評価益}+#{為替差益}+#{雑収入}+#{雑益}+#{その他営業外収益}",
//   "営業外費用計(入力)": "#{支払利息割引料}+#{資産処分損評価損}+#{為替差損}+#{租税公課}+#{雑支出}+#{雑損}+#{その他営業外費用}",
//   "経常利益(入力)": "#{営業利益(入力)}+#{営業外収益計(入力)}-(#{営業外費用計(入力)})", // special !!
//   "特別利益計(入力)": "#{引当金取崩}+#{資産処分益評価益_特別}+#{為替差益_特別}+#{その他特別利益}",
//   "特別損失計(入力)": "#{引当金繰入額}+#{資産処分損評価損_特別}+#{減損損失}+#{為替差損_特別}+#{その他特別損失}",
//   "税前利益(入力)": "#{経常利益(入力)}+#{特別利益計(入力)}-(#{特別損失計(入力)})", // special !!
//   "当期利益(入力)": "#{税前利益(入力)}-(#{法人税住民税})-(#{法人税住民税事業税})-(#{法人税等調整額})", // special !!
//   "資本金/当期変動額合計(入力)": "#{資本金/新株の発行}+#{資本金/剰余金の配当}+#{資本金/当期純利益}+#{資本金/自己株式の取得･処分}+#{資本金/自己株式の消却}+#{資本金/資本金･準備金/剰余金間の振替}+#{資本金/その他株主資本金の変動}",
//   "新株申込拠出金/当期変動額合計(入力)": "#{新株申込拠出金/新株の発行}+#{新株申込拠出金/剰余金の配当}+#{新株申込拠出金/当期純利益}+#{新株申込拠出金/自己株式の取得･処分}+#{新株申込拠出金/自己株式の消却}+#{新株申込拠出金/資本金･準備金/剰余金間の振替}+#{新株申込拠出金/その他株主資本金の変動}",
//   "資本剰余金/当期変動額合計(入力)": "#{資本剰余金/新株の発行}+#{資本剰余金/剰余金の配当}+#{資本剰余金/当期純利益}+#{資本剰余金/自己株式の取得･処分}+#{資本剰余金/自己株式の消却}+#{資本剰余金/資本金･準備金/剰余金間の振替}+#{資本剰余金/その他株主資本金の変動}",
//   "利益剰余金/当期変動額合計(入力)": "#{利益剰余金/剰余金の配当}+#{利益剰余金/当期純利益}+#{利益剰余金/自己株式の取得･処分}+#{利益剰余金/自己株式の消却}+#{利益剰余金/資本金･準備金/剰余金間の振替}+#{利益剰余金/その他株主資本金の変動}",
//   "自己株式/当期変動額合計(入力)": "#{自己株式/自己株式の取得･処分}+#{自己株式/自己株式の消却}+#{自己株式/その他株主資本金の変動}",
//   "株主資本計(入力)/☆前期末残高": "#{資本金/☆前期末残高}+#{新株申込拠出金/☆前期末残高}+#{資本剰余金/☆前期末残高}+#{利益剰余金/☆前期末残高}+#{自己株式/☆前期末残高}",
//   "株主資本計(入力)/新株の発行": "#{資本金/新株の発行}+#{新株申込拠出金/新株の発行}+#{資本剰余金/新株の発行}",
//   "株主資本計(入力)/剰余金の配当": "#{資本金/剰余金の配当}+#{新株申込拠出金/剰余金の配当}+#{資本剰余金/剰余金の配当}+#{利益剰余金/剰余金の配当}",
//   "株主資本計(入力)/当期純利益": "#{資本金/当期純利益}+#{新株申込拠出金/当期純利益}+#{資本剰余金/当期純利益}+#{利益剰余金/当期純利益}",
//   "株主資本計(入力)/自己株式の取得･処分": "#{資本金/自己株式の取得･処分}+#{新株申込拠出金/自己株式の取得･処分}+#{資本剰余金/自己株式の取得･処分}+#{利益剰余金/自己株式の取得･処分}+#{自己株式/自己株式の取得･処分}",
//   "株主資本計(入力)/自己株式の消却": "#{資本金/自己株式の消却}+#{新株申込拠出金/自己株式の消却}+#{資本剰余金/自己株式の消却}+#{利益剰余金/自己株式の消却}+#{自己株式/自己株式の消却}",
//   "株主資本計(入力)/資本金･準備金/剰余金間の振替": "#{資本金/資本金･準備金/剰余金間の振替}+#{新株申込拠出金/資本金･準備金/剰余金間の振替}+#{資本剰余金/資本金･準備金/剰余金間の振替}+#{利益剰余金/資本金･準備金/剰余金間の振替}",
//   "株主資本計(入力)/その他株主資本金の変動": "#{資本金/その他株主資本金の変動}+#{新株申込拠出金/その他株主資本金の変動}+#{資本剰余金/その他株主資本金の変動}+#{利益剰余金/その他株主資本金の変動}+#{自己株式/その他株主資本金の変動}",
//   "株主資本計(入力)/当期変動額合計(入力)": "#{株主資本計(入力)/新株の発行}+#{株主資本計(入力)/剰余金の配当}+#{株主資本計(入力)/当期純利益}+#{株主資本計(入力)/自己株式の取得･処分}+#{株主資本計(入力)/自己株式の消却}+#{株主資本計(入力)/資本金･準備金/剰余金間の振替}+#{株主資本計(入力)/その他株主資本金の変動}",
//   "評価･換算差額等/当期変動額合計(入力)": "#{評価･換算差額等/その他株主資本金の変動}",
//   "新株予約権/当期変動額合計(入力)": "#{新株予約権/その他株主資本金の変動}",
//   "純資産合計(入力)/☆前期末残高": "#{株主資本計(入力)/☆前期末残高}+#{評価･換算差額等/☆前期末残高}+#{新株予約権/☆前期末残高}",
//   "純資産合計(入力)/新株の発行": "#{株主資本計(入力)/新株の発行}",
//   "純資産合計(入力)/剰余金の配当": "#{株主資本計(入力)/剰余金の配当}",
//   "純資産合計(入力)/当期純利益": "#{株主資本計(入力)/当期純利益}",
//   "純資産合計(入力)/自己株式の取得･処分": "#{株主資本計(入力)/自己株式の取得･処分}",
//   "純資産合計(入力)/自己株式の消却": "#{株主資本計(入力)/自己株式の消却}",
//   "純資産合計(入力)/資本金･準備金/剰余金間の振替": "#{株主資本計(入力)/資本金･準備金/剰余金間の振替}",
//   "純資産合計(入力)/その他株主資本金の変動": "#{株主資本計(入力)/その他株主資本金の変動}+#{評価･換算差額等/その他株主資本金の変動}+#{新株予約権/その他株主資本金の変動}",
//   "純資産合計(入力)/当期変動額合計(入力)": "#{純資産合計(入力)/新株の発行}+#{純資産合計(入力)/剰余金の配当}+#{純資産合計(入力)/当期純利益}+#{純資産合計(入力)/自己株式の取得･処分}+#{純資産合計(入力)/自己株式の消却}+#{純資産合計(入力)/資本金･準備金/剰余金間の振替}+#{純資産合計(入力)/その他株主資本金の変動}",
//   "資本金/当期末残高(B/S入力)": "#{資本金/☆前期末残高}+#{資本金/新株の発行}+#{資本金/剰余金の配当}+#{資本金/当期純利益}+#{資本金/自己株式の取得･処分}+#{資本金/自己株式の消却}+#{資本金/資本金･準備金/剰余金間の振替}+#{資本金/その他株主資本金の変動}",
//   "新株申込拠出金/当期末残高(B/S入力)": "#{新株申込拠出金/☆前期末残高}+#{新株申込拠出金/新株の発行}+#{新株申込拠出金/剰余金の配当}+#{新株申込拠出金/当期純利益}+#{新株申込拠出金/自己株式の取得･処分}+#{新株申込拠出金/自己株式の消却}+#{新株申込拠出金/資本金･準備金/剰余金間の振替}+#{新株申込拠出金/その他株主資本金の変動}",
//   "資本剰余金/当期末残高(B/S入力)": "#{資本剰余金/☆前期末残高}+#{資本剰余金/新株の発行}+#{資本剰余金/剰余金の配当}+#{資本剰余金/当期純利益}+#{資本剰余金/自己株式の取得･処分}+#{資本剰余金/自己株式の消却}+#{資本剰余金/資本金･準備金/剰余金間の振替}+#{資本剰余金/その他株主資本金の変動}",
//   "利益剰余金/当期末残高(B/S入力)": "#{利益剰余金/☆前期末残高}+#{利益剰余金/剰余金の配当}+#{利益剰余金/当期純利益}+#{利益剰余金/自己株式の取得･処分}+#{利益剰余金/自己株式の消却}+#{利益剰余金/資本金･準備金/剰余金間の振替}+#{利益剰余金/その他株主資本金の変動}",
//   "自己株式/当期末残高(B/S入力)": "#{自己株式/☆前期末残高}+#{自己株式/自己株式の取得･処分}+#{自己株式/自己株式の消却}+#{自己株式/その他株主資本金の変動}",
//   "株主資本計(入力)/当期末残高(B/S入力)": "#{株主資本計(入力)/☆前期末残高}+#{株主資本計(入力)/新株の発行}+#{株主資本計(入力)/剰余金の配当}+#{株主資本計(入力)/当期純利益}+#{株主資本計(入力)/自己株式の取得･処分}+#{株主資本計(入力)/自己株式の消却}+#{株主資本計(入力)/資本金･準備金/剰余金間の振替}+#{株主資本計(入力)/その他株主資本金の変動}",
//   "評価･換算差額等/当期末残高(B/S入力)": "#{評価･換算差額等/☆前期末残高}+#{評価･換算差額等/その他株主資本金の変動}",
//   "新株予約権/当期末残高(B/S入力)": "#{新株予約権/☆前期末残高}+#{新株予約権/その他株主資本金の変動}",
//   "純資産合計(入力)/当期末残高(B/S入力)": "#{純資産合計(入力)/☆前期末残高}+#{純資産合計(入力)/新株の発行}+#{純資産合計(入力)/剰余金の配当}+#{純資産合計(入力)/当期純利益}+#{純資産合計(入力)/自己株式の取得･処分}+#{純資産合計(入力)/自己株式の消却}+#{純資産合計(入力)/資本金･準備金/剰余金間の振替}+#{純資産合計(入力)/その他株主資本金の変動}",
// }

/**
 * convert text to value
 * e.g. '△5,000).50' -> -5000.5
 * 
 * @param {string} text 
 */
export function convertTextToValue(text) {
  try {
    const num = text ? Number(text.replace("△", "-").replace(/[^\d-+]/g, "")) : 0
    return num ? num : 0
  } catch (e) {
    return 0
  }
}

/**
 * sum raw value by scrum item
 * @param {Map} rawData 
 */
function sumAllScrumItem(rawData, metaMap, CALC_FORMULA) {
  const scrumItemMap = {}

  Object.keys(CALC_FORMULA).forEach(scrumText => {
    scrumItemMap[scrumText] = 0
  })

  for (let key in rawData) {
    for (let i in rawData[key]) {
      const scrumItemText = rawData[key][i]['scrumItem']
      if (scrumItemText) {
        if (metaMap[scrumItemText] === key) {
          const val = ( rawData[key][i]['value']['invert'] === true ? -1 : 1 ) * convertTextToValue(rawData[key][i]['value']['text'])
          if (scrumItemMap[scrumItemText]) {
            scrumItemMap[scrumItemText] += val
          } else {
            scrumItemMap[scrumItemText] = val
          }
        }
      }
    }
  }

  return scrumItemMap
}

function replaceScrumItemWithManualValue(scrumItemMap, rawData) {
  const manualItemMap = {}

  const manualInput = rawData['manual_input']
  for (let pageKey in manualInput) {
    for (let itemKey in manualInput[pageKey]) {
      manualItemMap[itemKey] = convertTextToValue(manualInput[pageKey][itemKey])
    }
  }
  return assign({}, scrumItemMap, manualItemMap)
}

/**
 * calculate the scrum item by defined formula
 * 
 * @param {Map} scrumItemMap 
 */
function calcScrumItemByFormula(scrumItemMap, CALC_FORMULA) {
  const calcItemMap = {}
  for (let itemKey in CALC_FORMULA) {
    let currentFormula = CALC_FORMULA[itemKey];
    for (let scrumItemKey in scrumItemMap) {
      currentFormula = currentFormula.replace("#{" + scrumItemKey + "}", scrumItemMap[scrumItemKey])
    }
    currentFormula = currentFormula.replace(/#\{.+?\}/g, 0)
    currentFormula = currentFormula.replaceAll("--", "+");
    currentFormula = currentFormula.replaceAll("+-", "-");
    calcItemMap[itemKey] = {
      // eslint-disable-next-line
      val: eval(currentFormula),
      strVal: currentFormula
    }
  }

  return calcItemMap
}

/**
 * compare caculated item with raw item
 * 
 * @param {Map} calcItemMap 
 * @param {Map} rawItemMap 
 */
function compareScrumItemWithAll(calcItemMap, rawItemMap, CALC_FORMULA, CALC_DIFF, CALC_DIFF_P, CALC_DIFF_N) {
  const allItemList = []

  for (let calcItemKey in calcItemMap) {
    for (let rawItemKey in rawItemMap) {
      if (calcItemKey === rawItemKey ) {
        const formula = CALC_FORMULA[calcItemKey]
        let item = {
          name: calcItemKey,
          children: formula.match(/#\{\S+?\}/g).map(f => {
            return f.replace(/(#{)|}/g, "")
          }),
          formula: formula,
          calcStrVal: calcItemMap[calcItemKey]['strVal'],
          calcVal: calcItemMap[calcItemKey]['val'],
          rawVal: rawItemMap[rawItemKey],
          correctDiff: CALC_DIFF[rawItemKey],
          correctDiff_P: CALC_DIFF_P[rawItemKey],
          correctDiff_N: CALC_DIFF_N[rawItemKey],
        }

        const amount_diff = calcItemMap[calcItemKey]['val'] - rawItemMap[rawItemKey]; // 自動集計値 - 入力値
        if (CALC_DIFF[rawItemKey] || CALC_DIFF[rawItemKey] === 0) {
          if (Math.abs(amount_diff) > CALC_DIFF[rawItemKey]) {
            item.isError = true
          } else {
            item.isError = false
          }
        } else {
          if ((amount_diff > 0 && amount_diff > CALC_DIFF_P[rawItemKey]) ||
            (amount_diff < 0 && Math.abs(amount_diff) > CALC_DIFF_N[rawItemKey])) {
            item.isError = true
          } else {
            item.isError = false
          }
        }

        allItemList.push(item)
      }
    }
  }

  return allItemList
}

/**
 * check scrum item
 * 
 * @param {Map} rawData 
 * @return invalid array [{ name: 流動資産計(入力), children: [現金預金, 受取手形, ...], formula: "#{現金預金}+#{受取手形}+#{売掛金}+..." }, {...}, {...}]
 */
export function checkScrumItemWithAll(rawData, metaMap, metadata) {
  const { CALC_FORMULA, CALC_DIFF, CALC_DIFF_P, CALC_DIFF_N } = convertFormulaAndBias(metadata)
  let scrumItemMap = sumAllScrumItem(rawData, metaMap, CALC_FORMULA)
  scrumItemMap = replaceScrumItemWithManualValue(scrumItemMap, rawData)
  const calcItemMap = calcScrumItemByFormula(scrumItemMap, CALC_FORMULA)
  const allItemList = compareScrumItemWithAll(calcItemMap, scrumItemMap, CALC_FORMULA, CALC_DIFF, CALC_DIFF_P, CALC_DIFF_N)

  return allItemList
}
function convertFormulaAndBias(metaData){
  const formula = metaData["fs_formula"]
  const bias = metaData["fs_bias"]
  const bias_positive = metaData["fs_bias_positive"]
  const bias_negative = metaData["fs_bias_negative"]

  const CALC_FORMULA = {}
  const CALC_DIFF = {}
  const CALC_DIFF_P = {}
  const CALC_DIFF_N = {}
  formula.forEach(f=>{
    CALC_FORMULA[f.item] = f.value
  })
  bias.forEach(b=>{
    CALC_DIFF[b.item] = Number(b.value)
  })
  bias_positive.forEach(bp => {
    CALC_DIFF_P[bp.item] = Number(bp.value)
  })
  bias_negative.forEach(bn => {
    CALC_DIFF_N[bn.item] = Number(bn.value)
  })
  return { CALC_FORMULA, CALC_DIFF, CALC_DIFF_P, CALC_DIFF_N }
}

export function metaDataToMap(metaData) {
  const metaMap = {}

  for(let i in metaData) {
    metaMap[metaData[i]['value']] = metaData[i]['item']
  }

  return metaMap
}