import React, { Component } from 'react';
import {
  Segment,
  Button,
  Table,
  Message,
  Pagination,
} from 'semantic-ui-react';
import api from '../../api';
import { confirmAlert } from 'react-confirm-alert';
import intl from 'react-intl-universal';

const TABLE_COLS = [
  {
    label: 'ID',
    key: 'id',
  },
  {
    label: 'Sub Title',
    key: 'subTitle',
  },
  {
    label: 'Item',
    key: 'item',
  },
  {
    label: 'Category',
    key: 'category',
  },
  {
    label: 'Global Result Item',
    key: 'globalResultItem',
  },
  {
    label: 'Operation',
    key: 'operation',
  },
];

class FsItemTable extends Component {
  handlePageChange = (event, data) => {
    this.props.handleSearch(data.activePage);
  };

  handleEdit = async (fsItem) => {
    this.props.toggleEdit(true, fsItem);
  };

  handleDelete = async (id) => {
    confirmAlert({
      title: intl.get('_common.Delete'),
      message: intl.get(
        '_system.preferences.Preferences.Are you sure to do this?'
      ),
      buttons: [
        {
          label: intl.get('_common.Delete'),
          onClick: async () => {
            const {data} = await api.delete(`/dppapi/system/global-preset/${id}`);
            if(data.success){
              this.props.handleSearch();
            } else {
              confirmAlert({
                title: intl.get('_common.Error'),
                message: data.errmsg,
                buttons: [
                  {
                    label: intl.get('_system.preferences.Preferences.Cancel'),
                  },
                ],
              });
            }
          },
        },
        {
          label: intl.get('_system.preferences.Preferences.Cancel'),
        },
      ],
    });
  };

  renderHeaderCell = () => {
    return TABLE_COLS.map((col, index) => (
      <Table.HeaderCell key={index}>{col.label}</Table.HeaderCell>
    ));
  };

  renderCell = (fsItemRslt) => {
    return fsItemRslt.map((fsItem, index) => (
      <Table.Row key={index}>
        {TABLE_COLS.map((col) => (
          <Table.Cell key={index + col.key}>
            {col.key === 'operation' ? (
              <React.Fragment>
                <Button
                  icon="edit"
                  label="Edit"
                  size="mini"
                  onClick={() => this.handleEdit(fsItem)}
                />
                <Button
                  icon="trash"
                  label="Delete"
                  size="mini"
                  onClick={() => this.handleDelete(fsItem['id'])}
                />
              </React.Fragment>
            ) : (
              fsItem[col.key]
            )}
          </Table.Cell>
        ))}
      </Table.Row>
    ));
  };

  render() {
    const { fsItemRslt, page } = this.props;

    return fsItemRslt.length > 0 ? (
      <Segment>
        <Table celled padded>
          <Table.Header>
            <Table.Row>{this.renderHeaderCell()}</Table.Row>
          </Table.Header>

          <Table.Body>{this.renderCell(fsItemRslt)}</Table.Body>
        </Table>
        <Pagination
          size="mini"
          secondary
          activePage={page.pageNo}
          totalPages={
            typeof page.pageCount === 'undefined' ? 1 : page.pageCount
          }
          onPageChange={this.handlePageChange}
        />
      </Segment>
    ) : (
      <Message info header="No Data" content="Please change conditions" />
    );
  }
}

export default FsItemTable;
