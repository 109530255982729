import React, { Component } from 'react';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import { Button, Icon, Segment, Loader, Header } from 'semantic-ui-react';
import { confirmAlert } from 'react-confirm-alert';
import api from '../api';
import intl from 'react-intl-universal';

export default class ApiTable extends Component {
  state = {
    rows: [],
    isLoading: false,
    tenantName: ''
  };

  async componentDidMount() {
    await this.getApiInfos();
  }

  handleDel = (apiInfo) => {
    confirmAlert({
      title: intl.get('_easy-ocr.ApiTable.Delete'),
      message: intl.get('_easy-ocr.ApiTable.Do you want to delete it?'),
      buttons: [
        {
          label: intl.get('_easy-ocr.ApiTable.Delete'),
          onClick: async () => {
            this.setState({
              isLoading: true
            })
            await api.delete(`/dppapi/easyOcr/${apiInfo.id}`);
            this.getApiInfos();
            this.setState({
              isLoading: false
            })
          },
        },
        {
          label: intl.get('_easy-ocr.ApiTable.Cancel'),
        },
      ],
    });
  }

  getApiInfos = async () => {
    const apiInfos = (await api.get(`/dppapi/easyOcr`)).data;
    const { history } = this.props;
    const columns = [
      {
        label: intl.get('_easy-ocr.ApiTable.ID'),
        field: 'id',
        sort: 'asc',
      },
      {
        label: intl.get('_easy-ocr.ApiTable.Name'),
        field: 'name',
        sort: 'asc',
      },
      {
        label: intl.get('_easy-ocr.ApiTable.Descripe'),
        field: 'descripe',
        sort: 'asc',
      },
      {
        label: intl.get('_easy-ocr.ApiTable.Actions'),
        field: 'actions',
        sort: 'asc',
      },
    ];
    let rows = [];
    if (apiInfos) {
      rows = apiInfos.map((p) => {
        const apiInfo = p;
        return {
          id: apiInfo.id,
          name: (
            <Link key={apiInfo.id} to={`/easyocr/edit/${apiInfo.id}`}>
              <div
                style={{
                  width: 200,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <u>{apiInfo.name}</u>
              </div>
            </Link>
          ),
          descripe: apiInfo.descripe,
          actions: (
            <div>
              {/* <Button
                icon="info"
                label="概要"
                size="tiny"
                onClick={() => history.push(`/easyocr/edit/${apiInfo.id}`)}
              /> */}
              <Button
                icon="edit"
                label={intl.get('_easy-ocr.ApiTable.Edit')}
                size="tiny"
                onClick={() => history.push(`/easyocr/edit/${apiInfo.id}`)}
              />
              <Button
                icon="trash"
                label={intl.get('_easy-ocr.ApiTable.Delete')}
                size="tiny"
                onClick={() => this.handleDel(apiInfo)}
              />
            </div>
          ),
        };
      })
    }
    const info = (await api.get(`/dppapi/info`)).data;
    let tenantName = info ? info.tenantName : '';
    this.setState({
      data: {
        columns: columns,
        rows: rows
      },
      tenantName
    })
  }

  render() {
    const { isLoading, tenantName } = this.state;
    const { history } = this.props;

    if (isLoading) {
      return (<Segment><Loader active inline="centered" /></Segment>)
    }

    return (
      <div>
        { (tenantName)? <Header as="h1">{tenantName}</Header>: null}
        <Segment>
          <Button.Group floated="right">
            <Button icon onClick={() => history.push(`/easyocr/add`)}>
              <Icon name="add" />
            </Button>
          </Button.Group>
          <MDBDataTable theadColor="common-search-table-header" btn striped bordered hover data={this.state.data} />
        </Segment>
      </div>
    );
  }
}