import React, { Component } from 'react';
import { Popup } from 'react-leaflet';
import shallowEqualObjects from 'shallow-equal/objects';
import './SystemFigure.css';

export default class SystemDetContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      figureId: props.figure.id,
      editing: false,
      det_text: props.figure.det_text,
    };
    this.popup = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.det_text === this.state.det_text &&
      nextState.editing === this.state.editing &&
      nextProps.linkedKey === this.props.linkedKey &&
      shallowEqualObjects(nextProps.figure, this.props.figure)
    ) {
      return false;
    }
    return true;
  }

  render() {
    const { det_text } = this.props.figure;
    return (
      <Popup
        ref={this.popup}
        closeButton={false}
        className={'mui-leaflet-popup-content-wrapper'}
      >
        <div
          style={{
            height: '110px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ height: '80px', width: '250px', overflowY: 'auto' }}>
            <div className="mui-det-content"> {det_text}</div>
          </div>
        </div>
      </Popup>
    );
  }
}
