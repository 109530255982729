import React, { Component } from 'react';

import { Form, Button, Message } from 'semantic-ui-react';
import { MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact';
import api from '../../../api';
import intl from 'react-intl-universal';


export default class ImportUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      loading: true,
      importError: null,
      errors: {}
      
    };
    this.handleCsvSubmit = this.handleCsvSubmit.bind(this);
    this.clearAll = this.clearAll.bind(this);
  }

  async componentDidMount() {
    const tenants = (await api.get(`/dppapi/system/tenant`)).data;
    this.setState({
      tenants: tenants.map(data => {
        return { text: data.name, key: data.id, value: data.id }
      })
    })
  }

  async handleCsvSubmit(e) {
    if(!this.state.file) return;

    e.preventDefault();
    
    const form = e.target;
    const formData = new FormData(form);
    this.setState({ loading: false });
    try {
      await api.post(`/dppapi/system/user/import/${this.props.tenantId}`, formData);
      this.setState({
        file: null,
        loading: true
      });
      form.reset();
      this.props.getUsers();
      this.props.toggleImport(false);
    } catch (error) {
      this.setState({
        file: null,
        loading: true,
        importError: error.data.errmsg
      });
    }
  }

  clearAll() {
    this.setState({ 
      file: null,
      loading: true,
      importError: null
    });
    this.props.toggleImport(false);
  }

  render() {
    const { loading, importError } = this.state;
    const importMessage = importError ? (<Message negative>{importError}</Message>) : null;
    return (
      <div style={{cursor:'default'}}>
        <MDBModal 
        isOpen={this.props.isShowImport} 
        toggle={(flag) => {this.setState({ importError: null ,isOpen: false});this.props.toggleImport({isShowEdit: flag})}}
        centered
        >
        <MDBModalHeader>
          {intl.get('_system.tenants.user.ImportUser.Import user')}
        </MDBModalHeader>
        <MDBModalBody>
          {importMessage}
          <Form
            method="post"
            encType="multipart/form-data"
            onSubmit={this.handleCsvSubmit}
          >
            <Form.Input
              multiple
              label={intl.get('_system.tenants.user.ImportUser.Select CSV files')}
              type="file"
              id="csv"
              name="csv"
              accept=".csv"
              onChange={e => {this.setState({ file: e.target.value, importError: null })}}
            />
            <Button floated='right' type="submit" loading={!loading} disabled={!loading} >{intl.get('_system.tenants.user.ImportUser.Import')}</Button>
            <Button floated='right' onClick={this.clearAll}>{intl.get('_common.Cancel')}</Button>
          </Form>
        </MDBModalBody>
      </MDBModal> 
      </div>
    );
  }
}
