export const serviceName = 'DPP_UI';

export const PageName = {
    PredictPage: 'predict_page',
    PredictDetailPage: 'predict_detail_page',
}

export const EventType = {
    Click: 'click',
    PageView: 'page_view',
    Action: 'action',
}

export const EventName = {
    PageTransition: "page_transition",
    FileOpen: "file_open",
    FileUpload: "file_upload",
    ChangeTableRowNum : "change_table_row_num",
    SearchFile: "search_file",
    ClickSetOperatorButton : "click_set_operator_button",
    SetFileOperator : "set_file_operator",
    SelectedFileOperatorForOneself : "selected_file_operator_for_oneself",
    ClickDeleteFileButton : "click_delete_file_button",
    DeleteFile : "delete_file",
    ResetZoom : "reset_zoom",
    SetRangeBySlider : "set_range_by_slider",
    SetMinRange : "set_min_range",
    SetMaxRange : "set_max_range",
    ChangeViewMode : "change_view_mode",
    CsvDownload : "csv_download",
    ReportReadingAccuracyIssues : "report_reading_accuracy_issues",
    RestoreToThePreviousSavedState : "restore_to_the_previous_saved_state",
    SelectDocumentTypeDropdown : "select_document_type_dropdown",
    Save : "save",
    SearchItem : "search_item",
    FixAmount : "fix_amount",
    UnlinkScrumItem : "unlink_scrum_item",
    ClickLinkScrumItemButton : "click_link_scrum_item_button",
    LinkScrumItem : "link_scrum_item",
    FixToAnalyzedContent : "fix_to_analyzed_content",
    CopyAmount : "copy_amount",
    InvertSign : "invert_sign",
    ShowAmountBreakdown : "show_amount_breakdown",
    OpenDetails : "open_details",
    ClickScrumTableHeader : "click_scrum_table_header",
    ChangeColumnWidth : "change_column_width",
    ShowDocumentList : "show_document_list",
    SearchDocumentText : "search_document_text",
    SelectSubjectsDropdown : "select_subjects_dropdown",
    Reread: 'reread',
    RereadCancel: 'reread_cancel',
    RereadNext: 'reread_next',	
    RereadLineselectCancel: 'reread_lineselect_cancel',
    RereadLineselect: 'reread_lineselect',
    RereadConfirmBack: 'reread_confirm_back',
    RereadStart: 'reread_start',
    RecommendItemToggle: "recommend_item_toggle",
    AmountToggle: "amount_toggle",
    AddRecord: "add_record",
    SetSelfAsAssignee: "set_self_as_assignee",
    ApplySettings: "apply_settings"
}

export const FileOpenSource = {
    Button: 'button',
    DragNDrop: 'drag_n_drop',
}

export const SelectedFileNum = {
    One : "one",
    Multiple : "multiple",
}

export const SetFileOperatorProcess = {
    Skip : "skip",
    Overwrite : "overwrite",
}

export const ViewMode = {
    List : "list",
    Card : "card",
    Table : "table",
    Detail : 'detail'
}

export const PredictDetailPageLocation = {
    PopUp : "pop_up",
    ScrumItem : "scrum_item",
    ScrumDetailItem : "scrum_detail_item"
}

export const DocumentListDisplay = {
    Show : "show",
    Hide : "hide"
}

export const FixAmountSource = {
    Icon : "icon",
    TextField : "text_field",
}