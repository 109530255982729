import React, { Component } from 'react';
import { Header, Form, Segment, Button, Dropdown, Input, Image, Grid } from 'semantic-ui-react';
import api from '../api'
import intl from 'react-intl-universal';
import { confirmAlert } from 'react-confirm-alert';
import {
  withRouter
} from 'react-router-dom';
import { ROOT_URI } from '../HistoryListener';
import { publicEncrypt } from '../common/EncryptUtil';
import { createCookie, getCookie } from '../common/CookieUtil';
// import { cryptoEncrypt } from '../common/EncryptUtil';
class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenantId: '',
      isTenant: false,
      buttonShow: false,
      email: '',
      password: '',
      error: null,
      loading: false,
      tenantName: intl.get('_login.LoginPage.History'),
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.handleLoginSuccess = this.handleLoginSuccess.bind(this);
    this.handleTenantInputChange = this.handleTenantInputChange.bind(this);
  }

  async componentWillMount() {
    await this.getTenantId();
    await this.getButtonShow();
  }

  getTenantId = async () => {
    const { tenantId } = (await api.get('/dppapi/tenant-id')).data;
    if (tenantId && tenantId > 0) {
      this.handleTenantInputChange(tenantId);
      return;
    }
    this.setState({ isTenant: true});

    const query = new URLSearchParams(this.props.location.search)
    const q_tenantId = query.get('tenantId');
    if (q_tenantId) {
      this.handleTenantInputChange(q_tenantId);
      return;
    }
    
    var c_tenantId = getCookie('tenant_id');
    if (c_tenantId) {
      this.handleTenantInputChange(c_tenantId);
      return;
    }
  }
  
  getButtonShow = async () => {
    const { buttonShow } = (await api.get('/dppapi/tenant-button-show')).data;
    this.setState({ buttonShow: buttonShow});
  }

  handleTenantInputChange(tenantId) {
    var tenantList = getCookie('tenantList');
    tenantList = tenantList ? JSON.parse(tenantList) : [];
    var tenantName = intl.get('_login.LoginPage.History');
    tenantList.forEach(data => {
      var cookieTID = data.id.toString();
      if (data.id === tenantId || cookieTID === tenantId) {
        tenantName = data.name;
      }
    })
    this.setState({ tenantId: tenantId, tenantName: tenantName});
  }

  async handleLogin(e) {
    const { tenantId, email, password } = this.state;

    try {
      this.setState({ loading: true })
      const reqBody = {
        tenantId: tenantId,
        email: email,
        password: publicEncrypt(password, window.$config.$pubkey)
      }
      let { permission, success, url, user, oktaEmail } = (await api.post('/dppapi/login', reqBody)).data

      this.setState({ error: null });
      localStorage.setItem("permission", permission);
      localStorage.setItem("url", url)

      if (url === '/login/two-stage-authentication') {
        localStorage.removeItem("permission")
        localStorage.setItem("passTwoStageAuthentication", false)
      }

      createCookie('tenant_id', user.tenantId, 365);
      var tenantList = getCookie('tenantList');
      tenantList = tenantList ? JSON.parse(tenantList) : [];
      var sameList = tenantList.filter(data => {
        if(data.id === user.tenantId) {
          return true;
        }
        return false;
      })
      if (sameList.length === 0) {
        var tenantData = { id: user.tenantId, name: user.tenantName };
        tenantList.push(tenantData)
        createCookie('tenantList', JSON.stringify(tenantList), 365);
      }
      
      if (oktaEmail) {
        confirmAlert({
          title: intl.get('_login.LoginPage.Confirm'),
          message: intl.get('_login.LoginPage.AlertMessage'),
          buttons: [
            {
              label: intl.get('_login.LoginPage.Confirm'),
              onClick: async () => {
                try {
                  await api.put(`/dppapi/user/okta/${user.id}`, {
                    email: oktaEmail
                  })
                } catch(error) {
                  const { msg } = error.data
                  this.setState({ error: msg.email.content, loading: false });
                }
              },
            },
            {
              label: intl.get('_login.LoginPage.Cancel'),
              onClick: async () => {
                if ( success && url ) {
                  this.handleLoginSuccess();
                }
              },
            },
          ],
        });
      } else {
        if ( success && url ) {
          this.handleLoginSuccess();
        }
      }

    } catch(error) {
      const { message } = error.data
      this.setState({ error: message, loading: false });
    }
  }

  handleLoginSuccess() {
    const redirectTo = (new URL(window.location.href)).searchParams.get('redirectTo')
    const ignoreList = ['/dppui/login/two-stage-authentication', '/dppui/login', '/dppui/help'];
    const notIgnore = (redirectUrl) => {
      var notInIgnore = true;
      ignoreList.forEach(element => {
        if (redirectUrl.startsWith(element)) notInIgnore = false;
      });
      return notInIgnore;
    }

    let url = localStorage.getItem("url")
    if (url !== '/login/two-stage-authentication' && redirectTo && notIgnore(redirectTo)) {
      url = redirectTo
    }

    if (url === '/login/two-stage-authentication' && redirectTo && notIgnore(redirectTo)) {
      url = `${url}?redirectTo=${redirectTo}`
    }
    
    this.setState({ loading: false });
    this.props.history.push(url.replace(ROOT_URI, ''));
  }

  render() {
    const { tenantId, isTenant, tenantName, buttonShow } = this.state;
    var tenantList = getCookie('tenantList');
    tenantList = tenantList ? JSON.parse(tenantList) : [];
    var options = [];
    tenantList.forEach(k => options.push({ key: k.id, text: k.name, value: k.id }));
    return (
    <>
      <Segment>
        <Form>
          <Header>{intl.get('_login.LoginPage.UserLogin')}</Header>
          { isTenant ? 
          <div><Form.Field style={{ width: '40%', display: 'inline-block'}}>
            <label>{intl.get('_login.LoginPage.Tenant')}</label>
            <Input
              type="text"
              disabled={!isTenant}
              value={tenantId}
              onChange = {(e, { value }) => this.handleTenantInputChange(value)}
            />
          </Form.Field>
          <Form.Field style={{ width: '60%', display: 'inline-block', paddingLeft: '2%'}}>
            <Dropdown
              button
              className='icon'
              floating
              labeled
              icon='list'
              options={options}
              style={{ width: '100%' }}
              search
              text={tenantName} 
              onChange={(e, { value }) => this.handleTenantInputChange(value)}
            />
          </Form.Field></div> : 
          <Form.Input
            type="text"
            disabled
            value={tenantId}
            label={intl.get('_login.LoginPage.Tenant')}
          />}
          <Form.Input
            onChange={(e, { value }) => this.setState({ email: value })}
            type="text"
            label={intl.get('_login.LoginPage.User')}
          />
          <Form.Input
            onChange={(e, { value }) => this.setState({ password: value })}
            type="password"
            label={intl.get('_login.LoginPage.Password')}
          />
          
          <Button primary
            loading={this.state.loading}
            disabled={this.state.loading}
            secondary={this.state.loading}
            onClick={this.handleLogin}>{intl.get('_login.LoginPage.Signin')}</Button>
          {/* <Button
            color='green'
            floated='right'
            onClick={() =>{ window.location = `/dppui/login/retrieve`}}>{intl.get('_login.LoginPage.ResetPassword')}</Button> */}
          { isTenant && buttonShow === 'true' ? 
            <Button.Group floated='right'>
              <Button
                color='blue'
                onClick={() =>{ window.location = `/dppui/login/tenantLoginPage`}}>{intl.get('_login.LoginPage.RootLogin')}</Button>

              <Button
                color='orange'
                onClick={() =>{ window.location = `/dppui/login/register`}}>{intl.get('_login.LoginPage.Register')}</Button>

              <Button
                color='green'
                onClick={() => { window.location = `/dppui/login/tenantIdConfirm` }}
            >{intl.get('_login.LoginPage.TenantIdConfirm')}</Button>
            <Button
                primary
                onClick={() => { window.location = `/dppui/login/userRetrieve` }}
            >{intl.get('_login.LoginPage.ResetPassword')}</Button>
            </Button.Group>
          : null }
          { isTenant && buttonShow !== 'true' ? 
            <Button.Group floated='right'>
              <Button
                color='green'
                onClick={() => { window.location = `/dppui/login/tenantIdConfirm` }}
            >{intl.get('_login.LoginPage.TenantIdConfirm')}</Button>
            <Button
                primary
                onClick={() => { window.location = `/dppui/login/userRetrieve` }}
            >{intl.get('_login.LoginPage.ResetPassword')}</Button>
            </Button.Group>
          : null }
          <p style={{ color: 'red' }}>{this.state.error}</p>
        </Form>
      </Segment>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{position: 'fixed', bottom: '20px', width:'50%' }}>
        <Grid columns={2} centered verticalAlign='middle'>
          <Grid.Row>
            <Grid.Column width={3} >
              <Image src='/assets/img/smfl-logo.png' size='small'/>
            </Grid.Column>
            <Grid.Column width={10}>
              <div>
                <a style={{margin: '10px', fontSize: '8px', }} href='https://www.smfl.co.jp/privacy/'>■プライバシーポリシーのリンク</a>{' '}
                <a style={{fontSize: '8px'}} href='https://www.smfl.co.jp/cookie-policy/'>■クッキーポリシーのリンク</a>
              </div>
              <p style={{margin: '10px', fontSize: '8px'}}>Copyright© Sumitomo Mitsui Finance and Leasing Co., Ltd All Rights Reserved.</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        </div>
      </div>
    </>
    );
  }
}

export default withRouter(LoginPage);
