import React from 'react';
import { Tooltip } from 'react-leaflet';
import './Figure.css';

const ToolTip = ({ id, det_text }) => {
  return (
    <Tooltip
      key={`tooltip-${id}`}
      className="zc_tooltip"
      direction="bottom"
      opacity={1}
      permanent
    >
      {det_text}
    </Tooltip>
  );
};
export default ToolTip;
