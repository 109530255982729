import React, { Component } from 'react';
import intl from 'react-intl-universal';
import Hotkeys from 'react-hot-keys';

import _ from 'lodash';
import throttle from 'lodash.throttle';
import { Table, Checkbox, Loader, Icon, Input } from 'semantic-ui-react';

import DetailTableItem from './DetailTableItem';
import { AllDetailTableHeader } from './FsConstant';
import CurrentPageErrorList from './CurrentPageErrorList';
import { AnalyticsServiceContext } from '../../../common/Analytics/AnalyticsServiceProvider';
import { EventName, EventType } from '../../../common/Analytics/analyticsMetrics';

function columnStyles(idx, type) {
  const cursor = type === 0 ? 'default' : 'pointer';
  if (idx === 0) {
    return { width: 'fit-content', paddingRight: '5px', cursor: cursor };
  } else if (idx === 2) {
    return { width: 'fit-content', padding: '2.2px', cursor: cursor };
  } else {
    const width = `fit-content`;
    return { width: width, padding: '5px', cursor: cursor };
  }
}

let clickHoldTimer = null;
export default class FsDetailTable extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeDebounced = throttle(this.manualInputChange, 100);
    this.handleFilterItems = this.handleFilterItems.bind(this);
    this.state = {
      isLoading: false,
      ids: [],
      keepHover: false,
      popItemName: '',
      searchValue: '',
      sectionDisplay: true,
      operationMethod: '',
      openable: true,
      filterItems: [],
      prevPageType: '',
      dragIntoIndex: -1,
      filterRows: [],
    };
    this.TableRef = React.createRef();
    this.throttleChangeDragIntoIndex = throttle(this.changeDragIntoIndex, 300)
  }

  static contextType = AnalyticsServiceContext;

  componentDidUpdate(prevProps) {
    const {
      scrollToElement,
    } = this.props;
    if (scrollToElement && scrollToElement !== prevProps.scrollToElement) {
      let scrollElement = document.getElementById("fs-detail-table-body").children[scrollToElement];
      if (scrollElement) {
        setTimeout(() => {
          scrollElement.scrollIntoView({
            // behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        }, 0);
      }
    }
    if (
      this.props.currentPageType &&
      this.props.currentPageType !== prevProps.currentPageType
    ) {
      let scrollElement = document.getElementById('fs-detail-table');
      if (scrollElement) {
        scrollElement.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }

    const {filterRows} = this.handleFilterItems();

    if (!_.isEqual(this.state.filterRows, filterRows)) {
      this.setState({ filterRows: filterRows });
    }
  }

  componentDidMount() {
    let scrollElement = document.getElementById("fs-detail-table");
    if (scrollElement) {
      setTimeout(() => {
        scrollElement.scrollTo({
          top: this.props.fsDetailTableScrollTop,
          behavior: 'smooth',
        });
      })
    }
    // Real-time keyboard input monitoring to prevent default browser actions
    document.addEventListener("keydown", (event) => {
      if (["ArrowDown", "ArrowUp", "Tab"].includes(event.code)) {
        event.preventDefault();
      }
      if (event.repeat) {
        return; // Avoid processing repearted key presses
      }
    })
  }

  changeDragIntoIndex = (idx) => {
    const { dragIntoIndex } = this.state;
    if (idx === -1) {
      this.setState({ dragIntoIndex: -1 })
    } else if (idx !== dragIntoIndex) {
      this.setState({ dragIntoIndex: idx })
    }
  }

  handleInputChange(scrumItem, value, calc) {
    this.handleInputChangeDebounced(scrumItem, value, calc);
  }

  manualInputChange(scrumItem, value, calc) {
    this.props.onManualInputTextChange(scrumItem, value, calc);
  }

  onKeyUp(keyName, e) {
    this.setState({ longKeyPressed: false });
    clearTimeout(clickHoldTimer);
  }

  onKeyDown(keyName, e) {
    e.preventDefault();
    clickHoldTimer = setTimeout(() => {
      // Action to be performed after holding down mouse
      this.setState({ longKeyPressed: true });
    }, 100);

    if (keyName !== 'down' && keyName !== 'up') {
      this.handleKeyDownSingleOperation(keyName, e);
    } else {
      setTimeout(() => {
        if (this.state.longKeyPressed) {
          this.handleKeyDownLongPressedOperation(this.state.filterRows, e, keyName);
        } else {
          this.handleKeyDownSingleOperation(keyName, e);
        }
      }, 200)
    }
  }

  handleKeyDownSingleOperation = (keyName, e) => {
    e.preventDefault();
    const jumpToNextLine = this.state.operationMethod === 'next_line' ? true : false;
    const jumpToPrevElement = this.state.operationMethod === 'prev_element' ? true : false;
    const { selectedCell } = this.props;
    this.handleOperationMethodChange(keyName);
    const currentTagName = document.activeElement.tagName.toLowerCase();
    const parentElement = document.getElementsByClassName("high-light-row"); // current selected row
    let fourthChild;
    let divElement;
    let inputElement;
    let buttonElement;
    if (parentElement[0]) {
      fourthChild = parentElement && parentElement[0].childNodes[3];
      divElement = fourthChild && fourthChild.querySelectorAll(".fs-detail-connection-subject-container");
      inputElement = fourthChild && fourthChild.querySelectorAll("input");
      buttonElement = fourthChild && fourthChild.querySelectorAll("button");
    }

    switch (keyName) {
      case 'down':
        currentTagName !== "input" && this.handleChangeRow(selectedCell, "NEXT");
        break;
      case 'up':
        currentTagName !== "input" && this.handleChangeRow(selectedCell, "PREVIOUS");
        break;
      case 'tab':
        if (selectedCell.id !== '') {
          if (currentTagName === "tr" || currentTagName === "body" || currentTagName === "button") {
            if (buttonElement.length !== 0 && (currentTagName === "tr" || currentTagName === "body") && !jumpToNextLine) {
              buttonElement[0].focus();
            } else if ((divElement.length !== 0 || inputElement.length !== 0) && (currentTagName === "tr" || currentTagName === "body" || currentTagName === "button")) {
              if (jumpToNextLine) {
                this.handleChangeRow(selectedCell, "NEXT");
              } else {
                divElement.length > 0 ? divElement[0].focus() : inputElement[0].focus();
              }
            }
          } else if (currentTagName === "input") {
            var amountElement = parentElement && parentElement[0].childNodes[4];
            amountElement.focus();
          }
        }
        break;
      case 'shift+tab':
        if (selectedCell.id !== '') {
          if (currentTagName === "body") {
            divElement.length > 0 ? divElement[0].focus() : inputElement[0].focus();
          }
          if (currentTagName === 'input' && !jumpToPrevElement) {
            if (buttonElement.length !== 0) {
              buttonElement[0].focus();
            } else {
              this.handleOperationMethodChange("auto_open");
              this.handleChangeRow(selectedCell, "PREVIOUS");
            }
          }
          if (currentTagName === 'button' || currentTagName === 'div') {
            this.handleOperationMethodChange("auto_open");
            this.handleChangeRow(selectedCell, "PREVIOUS");
          }
        }
        break;
      default:
        // do nothing

        return;
    }

  }

  handleKeyDownLongPressedOperation = async (array, e, keyName) => {
    const { selectedCell } = this.props;
    const extractedArray = _.map(array, item => item[0]);
    const targetIndex = _.findIndex(extractedArray, { id: selectedCell.id });
    this.handleOperationMethodChange(keyName + '_long_press');
    switch (keyName) {
      case 'down':
        const currentArray_down = _.slice(extractedArray, targetIndex);
        this.cycleColors(e, currentArray_down);
        break;
      case 'up':
        const currentArray_up = _.reverse(_.slice(extractedArray, 0, targetIndex + 1));
        this.cycleColors(e, currentArray_up);
        break;
      default:
      // do nothing
    }
  }

  cycleColors = async (e, currentArray) => {
    for (const element of currentArray) {
      e.preventDefault();
      if (!this.state.longKeyPressed) {
        break;
      }
      await this.changeRowColor(element, e, currentArray.indexOf(element) === currentArray.length - 1);
    }
  }

  changeRowColor = async (element, e, isLastElement) => {
    e.preventDefault();
    const currentLineIndex = _.findIndex(this.state.filterRows, (row) => {
      return row[0].id === element.id
    });
    const domElement = document.getElementById("fs-detail-table-body").children[currentLineIndex];
    const tableDivElement = document.getElementById("fs-detail-table");
    return new Promise(resolve => {
      setTimeout(() => {
        domElement.classList.add("high-light-row", "selected");
        if (domElement.getBoundingClientRect().y > window.innerHeight
          || tableDivElement.getBoundingClientRect().y > domElement.getBoundingClientRect().y) {
          domElement.scrollIntoView();
        }
      }, 100)
      setTimeout(() => {
        domElement.classList.remove("high-light-row", "selected");
        if (!this.state.longKeyPressed || isLastElement) {
          this.handleChangeRow(element, "CURRENT", false);
        }
        resolve();
      }, 200)
    })
  }

  onHoverChange = (state, name) => this.setState({ keepHover: state, popItemName: name ? name : 'EditBox' });

  onSetFilterItems = (filterItems) => this.setState({ filterItems: filterItems, prevPageType: this.props.currentPageType });

  handleOpenModeChange = (state) => this.setState({ openable: state });

  handleOperationMethodChange = (state) => this.setState({ operationMethod: state });

  changeFontColor = (text, accuracy, accThreshold) => {
    const colorfulFont = [];
    const { amountImageDisplay } = this.props;
    let haslowReliabilityChar = false;
    Array.from(text || '').forEach((char, index) => {
      const arrPercent = parseFloat(
        (Array.isArray(accuracy) ? accuracy : [])[index]
      );
      if (arrPercent < parseFloat(accThreshold) && amountImageDisplay) {
        haslowReliabilityChar = true;
        colorfulFont.push(
          // <Popup
          //   position="top center"
          //   key={index}
          //   content={
          //     JSON.stringify(Math.floor(arrPercent * 10000) / 100) + '%'
          //   }
          //   trigger={<span className="lowacc-warning-characters">{char}</span>}
          // />
          <span key={index} className="lowacc-warning-characters">{char}</span>
        );
      } else {
        colorfulFont.push(<span key={index}>{char}</span>);
      }
    });
    return [colorfulFont, haslowReliabilityChar];
  }

  handleRowOperation = (row, ids) => {
    const { id, pdfImageId } = row;
    const { onRowSelect } = this.props;
    onRowSelect(id, pdfImageId, ids, 'value', row.manualInput);
  };

  handleSetOppositeValue = (values) => {
    values.forEach(value => {
      value.invert = !value.invert;
    });
    this.props.handleChangeValue(values);
  };

  handleSearchChange = (e, data) => {
    this.setState({ searchValue: data.value })
  }

  handleSearchValueClear = (e, data) => {
    this.setState({ searchValue: '' })
  }

  handleChangeRow = (selectedCell, changeType) => {
    const rows = _.map(this.state.filterRows, item => item[0]);
    const selectedCellIndex = _.findIndex(rows, { id: selectedCell.id })
    let row = rows[selectedCellIndex];
    switch (changeType) {
      case 'PREVIOUS':
        if (selectedCellIndex > 0) {
          row = rows[selectedCellIndex - 1];
        } else {
          return;
        }
        break;
      case 'NEXT':
        if (selectedCellIndex < rows.length - 1) {
          row = rows[selectedCellIndex + 1];
        } else {
          return;
        }
        break;
      case "CURRENT":
        row = rows[selectedCellIndex];
        break;
      default:
      // Do nothing
    }
    const cellIds = [];
    Object.keys(row).forEach(key => {
      if (row[key].id !== undefined) {
        cellIds.push(row[key].id);
      }
    });
    this.handleRowOperation(row, cellIds);
    const currentIndex = _.findIndex(rows, (row) => {
      return cellIds.includes(row.item.id) && cellIds.includes(row.subTitle.id) && cellIds.includes(row.value.id)
    })
    let currentElement = document.getElementById("fs-detail-table-body").children[currentIndex];
    currentElement.focus();
    this.setState({ ids: cellIds });
  }

  renderHeader = () => {
    return (
      <Table.Header className="value-table-header" style={{ minWidth: '420px' }}>
        <Table.Row style={{ background: '#f9fafb' }} textAlign="left">
          <Table.HeaderCell style={columnStyles(0, 0)} className='fs-text-table-table-wrap-table-header'>
            <span className="value-table-header-span">頁</span>
          </Table.HeaderCell>
          {AllDetailTableHeader.map((col, idx) => {
            return (
              <Table.HeaderCell
                key={idx}
                style={columnStyles(1, 0)}
                className={col.header === "AmountOfMoneyAll" ?
                  'fs-text-table-header-amount-of-money-all'
                  : 'fs-text-table-table-wrap-table-header'}
              >
                {intl.get(`_predict.detail.mapping.${col.header}`)}
              </Table.HeaderCell>
            );
          })}
          <Table.HeaderCell style={columnStyles(2, 0)} className='fs-text-table-table-wrap-table-header'>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    );
  };

  handleFilterItems = () => {
    const {
      originalRows,
      detailItems,
      errorList,
      currentPageType,
    } = this.props;

    let filterRows = [];
    let filterDetailItems = [];
    const {
      searchValue,
      filterItems,
      prevPageType,
    } = this.state;
    let rows;
    let isFiltering;
    if (filterItems && filterItems.length > 0) {
      const haveError = _.filter(errorList, { name: filterItems.at(-1) }).length > 0;
      isFiltering = prevPageType === currentPageType && haveError;
    }

    const currentRowIndexs = []
    if (isFiltering) {
      rows = _.filter(originalRows, (row, index) => {
        if (filterItems.includes(row.scrumItem)) {
          currentRowIndexs.push(index)
          return true;
        }
      })
    } else {
      rows = originalRows;
    }

    for (let i = 0; i < rows.length; i++) {
      if (
        !rows[i].scrumItem ||
        (rows[i].scrumItem &&
          rows[i].scrumItem.indexOf('当期末残高(B/S入力)') === -1)
      ) {
        if (searchValue !== '') {
          //絞り込み機能、金額のカンマを無視して
          const convertSearchValue = searchValue.replace(/,/g, '');
          var convertMatch = false;
          if(convertSearchValue && rows[i].value){
            const convertValue = rows[i].value.text.replace(/,/g, '');
            if(convertValue.includes(convertSearchValue)){
              convertMatch = true;
            }
          }
          if ((rows[i].item.text && rows[i].item.text.includes(searchValue))
            || (rows[i].subTitle && rows[i].subTitle.text.includes(searchValue))
            || (rows[i].scrumItem && rows[i].scrumItem.includes(searchValue))
            || (rows[i].value && rows[i].value.text.includes(searchValue)) || convertMatch) {
            if (isFiltering) {
              const currentIndex = currentRowIndexs[i];
              filterRows.push([rows[i], currentIndex]);
              filterDetailItems.push(detailItems[currentIndex]);
            } else {
              filterRows.push([rows[i], i]);
              filterDetailItems.push(detailItems[i]);
            }
          }
        } else {
          if (isFiltering) {
            const currentIndex = currentRowIndexs[i];
            filterRows.push([rows[i], currentIndex]);
            filterDetailItems.push(detailItems[currentIndex]);
          } else {
            filterRows.push([rows[i], i]);
            filterDetailItems.push(detailItems[i]);
          }
        }
      }
    }
    return {filterRows, filterDetailItems, isFiltering}
  }
 
  renderBody = () => {
    const {
      onMappingItemChange,
      onCheck,
      checkedList,
      onRowSelect,
      onAddRow,
      onDeleteRow,
      onDropRow,
      onScrumItemChange,
      selectedCell,
      onNormalized,
      onInputModeChange,
      currentBoxIdForCss,
      category,
      handleUnLinkScrumItem,
      onEdit,
      onGetSum,
      errorList,
      allList,
      currentPageType,
      projectId,
      pdfFileId,
      inputCalc,
      onUpdateBBox,
      detailItemChanging,
      amountImageDisplay,
      scrumMapingButtonDisplay,
      handleChangeValue,
      effectiveHotkeyPage,
      isNotOwner,
      onShowExclusionControl,
    } = this.props;
    const {
      prevPageType,
      ids,
      keepHover,
      popItemName,
      openable,
      operationMethod,
      dragIntoIndex
    } = this.state;
    const {filterDetailItems, filterRows, isFiltering} = this.handleFilterItems();
    const headerOrder = AllDetailTableHeader.map(c => {
      return { key: c.name, autoComplete: c.autoComplete, link: c.link };
    });
    const hotKeyDom = effectiveHotkeyPage === "textmapping" ? (
      <Hotkeys
        keyName="up, down, tab, shift+tab"
        onKeyDown={this.onKeyDown.bind(this)}
        onKeyUp={this.onKeyUp.bind(this)}
      />) : null;
    const rowDom = (
      filterRows.length > 0 &&
      filterRows.map((row, idx) => {
        let detailIds = [];
        if (!_.isEmpty(row[0]) && row[0].id) {
          Object.keys(row[0]).forEach(key => {
            if (_.has(row[0][key], 'id') && row[0][key].id !== undefined) {
              detailIds.push(row[0][key].id);
            }
          });
        }

        return <DetailTableItem
          key={idx}
          index={row[1]}
          dragIntoIndex={dragIntoIndex}
          changeDragIntoIndex={this.throttleChangeDragIntoIndex}
          row={row[0]}
          detailItem={filterDetailItems[idx]}
          onMappingItemChange={onMappingItemChange}
          onCheck={onCheck}
          checkedList={checkedList}
          onRowSelect={onRowSelect}
          onScrumItemChange={onScrumItemChange}
          selectedCell={selectedCell}
          onNormalized={onNormalized}
          onInputModeChange={onInputModeChange}
          currentBoxIdForCss={currentBoxIdForCss}
          category={category}
          headerOrder={headerOrder}
          handleUnLinkScrumItem={handleUnLinkScrumItem}
          ids={ids}
          setIds={val => this.setState(val)}
          handleSetOppositeValue={this.handleSetOppositeValue}
          onEdit={onEdit}
          onHoverChange={this.onHoverChange}
          keepHover={keepHover}
          popItemName={popItemName}
          onGetSum={onGetSum}
          errorList={errorList}
          allList={allList}
          amountImageDisplay={amountImageDisplay}
          scrumMapingButtonDisplay={scrumMapingButtonDisplay}
          changeFontColor={this.changeFontColor}
          columnStyles={columnStyles}
          currentPageType={currentPageType}
          projectId={projectId}
          pdfFileId={pdfFileId}
          inputCalc={inputCalc}
          handleRowOperation={this.handleRowOperation}
          detailIds={detailIds}
          handleInputChange={this.handleInputChange}
          onUpdateBBox={onUpdateBBox}
          openable={openable}
          onOpenModeChange={this.handleOpenModeChange}
          tabIndex={selectedCell.id !== '' ? 1 : -1}
          onAddRow={onAddRow}
          onDeleteRow={onDeleteRow}
          onDropRow={onDropRow}
          operationMethod={operationMethod}
          onOperationMethodChange={this.handleOperationMethodChange}
          detailItemChanging={detailItemChanging}
          handleChangeValue={handleChangeValue}
          isFiltering={isFiltering}
          prevPageType={prevPageType}
          isNotOwner={isNotOwner}
          onShowExclusionControl={onShowExclusionControl}
        />
      }))
    return (
      <Table.Body id='fs-detail-table-body'>
        {hotKeyDom}
        {rowDom}
      </Table.Body>
    );
  };

  render() {
    const {
      errorList,
      selectedCell,
      originalRows,
      currentPageType,
      onCheckRadioButton,
      amountImageDisplay,
      scrumMapingButtonDisplay,
      pdfFileId,
      projectId,
    } = this.props;
    const {
      filterItems,
      searchValue,
    } = this.state;
    return (
      <div className="fs-text-table-table-wrap fs-detail-table-wrap unForceRight">
        <CurrentPageErrorList
          errorList={errorList}
          selectedCell={selectedCell}
          rows={originalRows}
          currentPageType={currentPageType}
          filterItems={filterItems}
          onSetFilterItems={this.onSetFilterItems}
        />
        <div className={'btn-group-detail fs-detail-btn-group'} style={{ paddingRight: '10px' }}>
          {this.props.fsItemsCompletion ?
            <>
              <span className='fs-detail-btn-group-label'>{intl.get('_predict.detail.mapping.Candidate subjects')}</span>
              <div className='fs-detail-checkbox-wrap'>
                <Checkbox toggle
                  checked={scrumMapingButtonDisplay}
                  onChange={(e, data) => {
                    onCheckRadioButton(
                      'scrumMapingButtonDisplay',
                      data.checked
                    )
                    this.context.sendPredictBeautifyDetailMetrics({
                      event_type: EventType.Click,
                      event_name: EventName.RecommendItemToggle,
                      custom_parameter: {
                        pdf_id: pdfFileId,
                        project_id: projectId,
                        document_type: currentPageType,
                        selected: scrumMapingButtonDisplay ? "off" : "on"
                      },
                    })
                  }}
                />
              </div>
            </> :
            <>
              <span className='fs-detail-btn-group-label'>{intl.get('_predict.detail.mapping.Preparing candidate subjects')}</span>
              <Loader active inline size='small' style={{ marginLeft: '4px', marginRight: '16px' }} />
            </>
          }
          <span className='fs-detail-btn-group-label'>{intl.get('_predict.detail.mapping.Amount check')}</span>
          <div className='fs-detail-checkbox-wrap'>
            <Checkbox toggle
              checked={amountImageDisplay}
              onChange={(e, data) => {
                onCheckRadioButton(
                  'amountImageDisplay',
                  data.checked
                )
                this.context.sendPredictBeautifyDetailMetrics({
                  event_type: EventType.Click,
                  event_name: EventName.AmountToggle,
                  custom_parameter: {
                    pdf_id: pdfFileId,
                    project_id: projectId,
                    document_type: currentPageType,
                    selected: amountImageDisplay ? "off" : "on"
                  },
                })
              }}
            />
          </div>
          <Input
            icon
            placeholder="検索"
            fluid
            size="mini"
            className="fs-detail-search-input"
            value={searchValue}
            onChange={this.handleSearchChange}
          >
            <input />
            {searchValue.length === 0 ? (
              <Icon name="search" size="large" />
            ) : (
              <Icon name="remove" link size="large"
                onClick={() => this.handleSearchValueClear()}
              />
            )}
          </Input>
        </div>
        <div
          id='fs-detail-table'
          className='fs-detail-table'
          ref={this.TableRef}
          onScroll={() =>
            this.props.onSetScrollTop(
              'fsDetailTableScrollTop',
              this.TableRef.current.scrollTop
            )
          }
        >
          <Table style={{ borderCollapse: 'collapse' }} unstackable>
            {this.renderHeader()}
            {this.renderBody()}
          </Table>
        </div>
      </div>
    );
  }
}
