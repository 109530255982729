import React, { Component } from 'react';
import moment from "moment";
import { Segment, Header, Message, Loader, Popup, Button, Input, Label, Icon } from 'semantic-ui-react';
import { MDBDataTable } from 'mdbreact';
import PdfSearch from './SearchConditions'
import { toUnixMS, FMT, unixMSToDatetime } from '../tenants/TimeUtil';
import { getStateFlag } from './Constant';
import intl from 'react-intl-universal';
import './PdfSearch.css';
import { getCsrfToken } from '../../common/CookieUtil';
import api from '../../api'
import { confirmAlert } from 'react-confirm-alert';

export default class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conditions: {
        stateFlag: '',
        uploadTimeStart: moment().toDate(),
        uploadTimeEnd: moment().toDate(),
        lastEditedStart: '',
        lastEditedEnd: '',
        deleteFlag: '0',
        tenantId: ''
      },
      init: true,
      rows: [],
      editingServerIp: '',
      editingPdfFileId: '',
      isLoading: false,
      pdfFiles: [],
      inQueueMessages: [],
      errmsg: ""
    };
    this.tableRef = React.createRef()

  }

  getSearchInfo = () => {
    const { tenantId, stateFlag, uploadTimeStart, uploadTimeEnd, lastEditedStart, lastEditedEnd, deleteFlag } = this.state.conditions;

    return {
      tenantId,
      stateFlag,
      uploadTimeStart: uploadTimeStart ? toUnixMS(moment(uploadTimeStart).format(FMT.DATE)) : '',
      uploadTimeEnd: uploadTimeEnd ? toUnixMS(moment(uploadTimeEnd).add(1, 'd').format(FMT.DATE)) : '',
      lastEditedStart: lastEditedStart ? toUnixMS(moment(lastEditedStart).format(FMT.DATE)) : '',
      lastEditedEnd: lastEditedEnd ? toUnixMS(moment(lastEditedEnd).add(1, 'd').format(FMT.DATE)) : '',
      deleteFlag
    };
  }

  onConditionsChange = (type, value) => {
    const { conditions } = this.state
    this.setState({ conditions: { ...conditions, [type]: value } });
  }

  onIpChange = (pdfId, ip) => {
    const pdfFiles = this.state.pdfFiles
    pdfFiles.forEach(p => {
      if (p.id === pdfId) {
        p.serverIp = ip
      }
    })
    this.setState({
      editingServerIp: ip,
      pdfFiles
    }, () => {
      const activePage = this.tableRef.current.state.activePage
      this.tableRef.current.changeActivePage(activePage)
    });
  }

  onSearch = async () => {
    const req = this.getSearchInfo();

    const pdfFiles = (await api.get(
      `/dppapi/system/pdfs?tenantId=${req.tenantId}&stateFlag=${req.stateFlag}&deleteFlag=${req.deleteFlag}&uploadTimeStart=${req.uploadTimeStart}&uploadTimeEnd=${req.uploadTimeEnd}&lastEditedStart=${req.lastEditedStart}&lastEditedEnd=${req.lastEditedEnd}`
    )).data;

    let rows = [];
    if (pdfFiles) {
      rows = pdfFiles.map((pdfFile) => {
        return this.pdfFilesToRows(pdfFile);
      })
    }

    const fsInferenceMessages = (await api.get(
      `/dppapi/system/rabbitmq/fs-inference-messages`
    )).data;

    if (fsInferenceMessages.success) {
      this.setState({
        inQueueMessages: fsInferenceMessages.data.messages,
        errmsg: ""
      });
    } else {
      this.setState({ inQueueMessages: [], errmsg: "could not connect to rabbitmq server" })
    }

    this.setState({
      init: false,
      rows: rows,
      pdfFiles: pdfFiles,
      editingServerIp: '',
      editingPdfFileId: ''
    });
  }

  pdfFilesToRows = pdf => {
    return Object.assign({}, pdf, {
      id: pdf.id,
      serverIp: (
        <div className="system-search-pdf-edit-icon">
          <Input key={pdf.id} className="system-search-pdf-ipinput" value={pdf.serverIp} disabled={pdf.id !== this.state.editingPdfFileId} onChange={(e, data) => this.onIpChange(pdf.id, data.value)} />
          <div className="system-search-pdf-edit-icon">
            <Button icon="edit" size="tiny" onClick={() => this.handleEdit(pdf.id)} />
            <Button icon="check" size="tiny" onClick={() => this.handleSave(pdf.id)} />
          </div>
        </div>
      ),
      createdById: pdf.createdById,
      createdByName: pdf.createdByName,
      uploadTime: unixMSToDatetime(pdf.uploadTime, FMT.DATETIME_LOCAL),
      lastEdited: unixMSToDatetime(pdf.lastEdited, FMT.DATETIME_LOCAL),
      projectsId: pdf.projectsId,
      stateFlag: this.renderState(pdf),
      deleteFlag: pdf.deleteFlag,
      tenantId: pdf.tenantId,
      operation: <div className="system-search-pdf-edit-icon">
        <Button size="mini" primary onClick={() => this.handleRequeue(pdf.id)} disabled={(![1, 2, 4].includes(pdf.stateFlag)) || pdf.deleteFlag === 1}>Requeue</Button>
        <Button size="mini" disabled={pdf.stateFlag !== 1 || (pdf.stateFlag === 1 && this.isProcessing(pdf))} primary
          onClick={() => this.renderDetail(pdf.id)}>Detail</Button>
      </div>
    })
  }

  renderDetail = (id) => {
    const detail = this.state.inQueueMessages.find(m => m.pdfFile.id === id)
    confirmAlert(
      {
        customUI: ({ onClose }) => {
          return (<Segment>
            <h2>message detail</h2>
            <pre style={{ height: "500px", width: "800px", overflow: 'auto', backgroundColor: "#FAFAFA" }}>
              {detail && JSON.stringify(detail, null, 2)}
            </pre>
            <div style={{display: "flex", flexDirection: "row-reverse"}}><Button onClick={ ()=>onClose()}>close</Button></div>
          </Segment>)
        }
      }
    )
  }

  isProcessing = (pdf) => {
    const { inQueueMessages } = this.state
    let processing = true
    for (let i = 0; i < inQueueMessages.length; i++) {
      if (inQueueMessages[i].pdfFile.id === pdf.id) {
        processing = false
        break
      }
    }

    return processing
  }

  renderState = (pdf) => {
    let dom = null
    if (pdf.stateFlag === 4) {
      dom = <Popup content={pdf.errmsg} trigger={<div style={{ paddingBottom: "10px" }} >{getStateFlag(pdf.stateFlag)}</div>} />
    } else if (pdf.stateFlag === 1 || pdf.stateFlag === 2) {

      const processing = this.isProcessing(pdf)

      dom = <div>{getStateFlag(pdf.stateFlag)} <Label size="mini" color={processing ? "teal" : "yellow"}>{processing ? "Processing" : "In Queue"}</Label></div>
    } else {
      dom = getStateFlag(pdf.stateFlag)
    }
    return dom
  }

  handleRequeue = async pdfFileId => {
    const res = (await api.post(`/dppapi/system/pdfs/requeue/${pdfFileId}`)).data

    confirmAlert({
      title: res.succes ? 'success' : 'error',
      message: res.msg,
      buttons: [
        {
          label: 'OK',
          onClick: () => {
            this.onSearch()
          }
        },
      ],
    })
  }

  handleEdit = async pdfFileId => {
    this.setState({
      editingPdfFileId: pdfFileId
    }, () => {
      const activePage = this.tableRef.current.state.activePage
      this.tableRef.current.changeActivePage(activePage)
    })
  }

  handleSave = async pdfFileId => {
    await api.post(`/dppapi/system/pdfs/${pdfFileId}`, { serverIp: this.state.editingServerIp })
    this.setState({
      editingPdfFileId: ''
    }, () => {
      const activePage = this.tableRef.current.state.activePage
      this.tableRef.current.changeActivePage(activePage)
    })
  }

  handleExport = async => {
    const req = this.getSearchInfo();

    confirmAlert({
      message: intl.get('_system.PdfSearch.Index.Do you want to get pdf search results?'),
      buttons: [
        {
          label: intl.get('_system.PdfSearch.Index.Cancel'),
        },
        {
          label: intl.get('_system.PdfSearch.Index.Download'),
          onClick: async () => {
            let a = document.createElement('a');
            a.href = `/dppapi/system/pdfs/export?token=${getCsrfToken()}&tenantId=${req.tenantId}&stateFlag=${req.stateFlag}&deleteFlag=${req.deleteFlag}&uploadTimeStart=${req.uploadTimeStart}&uploadTimeEnd=${req.uploadTimeEnd}&lastEditedStart=${req.lastEditedStart}&lastEditedEnd=${req.lastEditedEnd}`;
            a.click();
            a.remove();
          },
        },
      ],
    })
  }

  getRows = () => {
    const rows = this.state.pdfFiles.map((pdfFile) => {
      return this.pdfFilesToRows(pdfFile);
    })
    return rows
  }

  render() {
    const { rows, init, isLoading, errmsg } = this.state;
    const columns = [
      {
        label: intl.get('_system.PdfSearch.Index.ID'),
        field: 'id',
        sort: 'asc',
        width: 150,
      },
      {
        label: intl.get('_system.PdfSearch.Index.ServerIp'),
        field: 'serverIp',
        sort: 'asc',
        width: 150,
      },
      {
        label: intl.get('_system.PdfSearch.Index.CreatedById'),
        field: 'createdById',
        sort: 'asc',
        width: 150,
      },
      {
        label: intl.get('_system.PdfSearch.Index.CreatedByName'),
        field: 'createdByName',
        sort: 'asc',
        width: 150,
      },
      {
        label: intl.get('_system.PdfSearch.Index.UploadTime'),
        field: 'uploadTime',
        sort: 'asc',
        width: 150,
      },
      {
        label: intl.get('_system.PdfSearch.Index.LastEdited'),
        field: 'lastEdited',
        sort: 'asc',
        width: 150,
      },
      {
        label: intl.get('_system.PdfSearch.Index.ProjectsId'),
        field: 'projectsId',
        sort: 'asc',
        width: 150,
      },
      {
        label: intl.get('_system.PdfSearch.Index.StateFlag'),
        field: 'stateFlag',
        sort: 'asc',
        width: 150,
      },
      {
        label: intl.get('_system.PdfSearch.Index.DeleteFlag'),
        field: 'deleteFlag',
        sort: 'asc',
        width: 150,
      },
      {
        label: intl.get('_system.PdfSearch.Index.TenantId'),
        field: 'tenantId',
        sort: 'asc',
        width: 150,
      },
      {
        label: intl.get('_system.PdfSearch.Index.Operation'),
        field: 'operation',
        width: 150,
      }
    ];
    const data = {
      columns: columns,
      rows: this.getRows()
    }
    var result = null;
    if (isLoading) {
      result = (<Segment><Loader active inline="centered" /></Segment>)
    } else if (rows.length > 0) {
      result = (<Segment>
        <Button.Group floated="right" style={{ marginLeft: '2px' }}>
          <Button icon onClick={() => this.handleExport()}>
            <Icon name="download" />
          </Button>
        </Button.Group>
        <MDBDataTable
          ref={this.tableRef}
          theadColor="common-search-table-header"
          striped
          bordered
          small
          data={data}
        />
      </Segment>)
    } else {
      result = (<Message
        info
        header='No Data'
        content="please change conditions"
      />)
    }
    return (
      <React.Fragment>
        <div style={{ display: 'flex' }}>
          <div className="ui" style={{ flex: 1 }}>
            <div id="search-pdf" style={{ padding: '2em 0' }}>
              <Header disabled>{intl.get('_system.PdfSearch.Index.Filtering')}</Header>
              <PdfSearch onSearch={this.onSearch} onConditionsChange={this.onConditionsChange} conditions={this.state.conditions} />
              <div>{ errmsg ? <Message error header='error'content={errmsg}/> : null}</div>
            </div>
            <div id="page" style={{ paddingBottom: 200, flex: 1 }}>
              {!init ? result : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}