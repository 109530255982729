export const TableMode = { SCRUM: 0, DETAIL: 1, TOTALDETAIL: 2 };
export const MappingType = { LIST_AND_TABLE: 0, BOARD: 1 };

export const TextChangeType = {
  LINK: 'link',
  CHANGE: 'change',
  DELETE: 'delete',
};

export const DetailTableHeader = [
  {
    id: '3oesffczd',
    name: 'subTitle',
    header: 'ScanClassification',
    type: 'selectBbox',
  },
  {
    id: 'r7412d7o4',
    name: 'item',
    header: 'ScanSubject',
    type: 'selectBbox',
  },
  {
    id: 'fvaxtpet0',
    name: 'value',
    header: 'AmountOfMoney',
    type: 'selectBbox',
  },
  {
    id: 'dzebktsbf',
    name: 'scrumItem',
    header: 'ChangeOfPartnerSubject',
    type: 'inputText',
    autoComplete: 'scrumItem',
    link: '/dppapi/predict/get_sfm',
  },
];

export const AllDetailTableHeader = [
  {
    id: '3oesffczd',
    name: 'subTitle',
    header: 'ScanClassificationAll',
    type: 'selectBbox',
  },
  {
    id: 'r7412d7o4',
    name: 'item',
    header: 'ScanSubjectAll',
    type: 'selectBbox',
  },
  {
    id: 'dzebktsbf',
    name: 'scrumItem',
    header: 'ChangeOfPartnerSubjectAll',
    type: 'inputText',
    autoComplete: 'scrumItem',
    link: '/dppapi/predict/get_sfm',
  },
  {
    id: 'fvaxtpet0',
    name: 'value',
    header: 'AmountOfMoneyAll',
    type: 'selectBbox',
  },
];

export const AllFsItemReviewTableHeader = [
  {
    id: '1',
    name: '頁',
  },
  {
    id: '2',
    name: '分類',
  },
  {
    id: '3',
    name: '読取区分',
  },
  {
    id: '4',
    name: '読取科目',
  },
  {
    id: '5',
    name: '自動連携先',
  }
];

export const PageTypeList = [
  '貸借対照表',
  '損益計算書',
  '株主資本等変動計算書',
];
export const PageType = {
  BS: '貸借対照表',
  PL: '損益計算書',
  SS: '株主資本等変動計算書',
};
export const ManualInputStatus = {
  INPUT: 1,
  LINK: 2,
};

export const BSheaderList = [
  '資本金',
  '資本剰余金(入力)',
  '利益剰余金(入力)',
  '自己株式',
  '株主資本(入力)',
  '評価･換算差額等(入力)',
  '新株予約権',
  '純資産計(入力)',
  '新株申込拠出金(入力)',
];

export const BScurrentEndBanaceList = [
  '資本金/当期末残高(B/S入力)',
  '資本剰余金/当期末残高(B/S入力)',
  '利益剰余金/当期末残高(B/S入力)',
  '自己株式/当期末残高(B/S入力)',
  '株主資本計(入力)/当期末残高(B/S入力)',
  '評価･換算差額等/当期末残高(B/S入力)',
  '新株予約権/当期末残高(B/S入力)',
  '純資産合計(入力)/当期末残高(B/S入力)',
  '新株申込拠出金/当期末残高(B/S入力)',
];

export const SubjectName = '科目名';

export const ShareholdersEquityTotalAgg = '株主資本計(自動集計)';

export const ShareholdersEquityTotalInput = '株主資本計(入力)';

export const CurrentFluctuationAmountAgg = '当期変動額合計(入力)(自動集計)';

export const CurrentFluctuationAmountInput = '当期変動額合計(入力)';

export const NetAssetsTotalAgg = '純資産合計(自動集計)';

export const NetAssetsTotalInput = '純資産合計(入力)';

export const CurrentEndBanaceAgg = '当期末残高(B/S入力)(自動集計)';

export const ShareholdersEquityHeader = [
  '資本金',
  '新株申込拠出金',
  '資本剰余金',
  '利益剰余金',
  '自己株式',
  '株主資本計(入力)',
  '株主資本計(自動集計)',
  '評価･換算差額等',
  '新株予約権',
  '純資産合計(入力)',
  '純資産合計(自動集計)',
];

export const ShareholdersEquityRow = [
  {
    科目名: '☆前期末残高',
    資本金: { id: '', manualInput: undefined, values: [] },
    新株申込拠出金: { id: '', manualInput: undefined, values: [] },
    資本剰余金: { id: '', manualInput: undefined, values: [] },
    利益剰余金: { id: '', manualInput: undefined, values: [] },
    自己株式: { id: '', manualInput: undefined, values: [] },
    '株主資本計(入力)': {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
    },
    '株主資本計(自動集計)': {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
    '評価･換算差額等': { id: '', manualInput: undefined, values: [] },
    新株予約権: { id: '', manualInput: undefined, values: [] },
    '純資産合計(入力)': {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
    },
    '純資産合計(自動集計)': {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
  },
  {
    科目名: '新株の発行',
    資本金: { id: '', manualInput: undefined, values: [] },
    新株申込拠出金: { id: '', manualInput: undefined, values: [] },
    資本剰余金: { id: '', manualInput: undefined, values: [] },
    利益剰余金: { id: '', manualInput: undefined, values: [] },
    自己株式: { id: '', manualInput: undefined, values: [] },
    '株主資本計(入力)': {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
    },
    '株主資本計(自動集計)': {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
    '評価･換算差額等': { id: '', manualInput: undefined, values: [] },
    新株予約権: { id: '', manualInput: undefined, values: [] },
    '純資産合計(入力)': {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
    },
    '純資産合計(自動集計)': {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
  },
  {
    科目名: '剰余金の配当',
    資本金: { id: '', manualInput: undefined, values: [] },
    新株申込拠出金: { id: '', manualInput: undefined, values: [] },
    資本剰余金: { id: '', manualInput: undefined, values: [] },
    利益剰余金: { id: '', manualInput: undefined, values: [] },
    自己株式: { id: '', manualInput: undefined, values: [] },
    '株主資本計(入力)': {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
    },
    '株主資本計(自動集計)': {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
    '評価･換算差額等': { id: '', manualInput: undefined, values: [] },
    新株予約権: { id: '', manualInput: undefined, values: [] },
    '純資産合計(入力)': {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
    },
    '純資産合計(自動集計)': {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
  },
  {
    科目名: '当期純利益',
    資本金: { id: '', manualInput: undefined, values: [] },
    新株申込拠出金: { id: '', manualInput: undefined, values: [] },
    資本剰余金: { id: '', manualInput: undefined, values: [] },
    利益剰余金: { id: '', manualInput: undefined, values: [] },
    自己株式: { id: '', manualInput: undefined, values: [] },
    '株主資本計(入力)': {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
    },
    '株主資本計(自動集計)': {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
    '評価･換算差額等': { id: '', manualInput: undefined, values: [] },
    新株予約権: { id: '', manualInput: undefined, values: [] },
    '純資産合計(入力)': {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
    },
    '純資産合計(自動集計)': {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
  },
  {
    科目名: '自己株式の取得･処分',
    資本金: { id: '', manualInput: undefined, values: [] },
    新株申込拠出金: { id: '', manualInput: undefined, values: [] },
    資本剰余金: { id: '', manualInput: undefined, values: [] },
    利益剰余金: { id: '', manualInput: undefined, values: [] },
    自己株式: { id: '', manualInput: undefined, values: [] },
    '株主資本計(入力)': {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
    },
    '株主資本計(自動集計)': {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
    '評価･換算差額等': { id: '', manualInput: undefined, values: [] },
    新株予約権: { id: '', manualInput: undefined, values: [] },
    '純資産合計(入力)': {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
    },
    '純資産合計(自動集計)': {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
  },
  {
    科目名: '自己株式の消却',
    資本金: { id: '', manualInput: undefined, values: [] },
    新株申込拠出金: { id: '', manualInput: undefined, values: [] },
    資本剰余金: { id: '', manualInput: undefined, values: [] },
    利益剰余金: { id: '', manualInput: undefined, values: [] },
    自己株式: { id: '', manualInput: undefined, values: [] },
    '株主資本計(入力)': {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
    },
    '株主資本計(自動集計)': {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
    '評価･換算差額等': { id: '', manualInput: undefined, values: [] },
    新株予約権: { id: '', manualInput: undefined, values: [] },
    '純資産合計(入力)': {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
    },
    '純資産合計(自動集計)': {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
  },
  {
    科目名: '資本金･準備金/剰余金間の振替',
    資本金: { id: '', manualInput: undefined, values: [] },
    新株申込拠出金: { id: '', manualInput: undefined, values: [] },
    資本剰余金: { id: '', manualInput: undefined, values: [] },
    利益剰余金: { id: '', manualInput: undefined, values: [] },
    自己株式: { id: '', manualInput: undefined, values: [] },
    '株主資本計(入力)': {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
    },
    '株主資本計(自動集計)': {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
    '評価･換算差額等': { id: '', manualInput: undefined, values: [] },
    新株予約権: { id: '', manualInput: undefined, values: [] },
    '純資産合計(入力)': {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
    },
    '純資産合計(自動集計)': {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
  },
  {
    科目名: 'その他株主資本金の変動',
    資本金: { id: '', manualInput: undefined, values: [] },
    新株申込拠出金: { id: '', manualInput: undefined, values: [] },
    資本剰余金: { id: '', manualInput: undefined, values: [] },
    利益剰余金: { id: '', manualInput: undefined, values: [] },
    自己株式: { id: '', manualInput: undefined, values: [] },
    '株主資本計(入力)': {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
    },
    '株主資本計(自動集計)': {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
    '評価･換算差額等': { id: '', manualInput: undefined, values: [] },
    新株予約権: { id: '', manualInput: undefined, values: [] },
    '純資産合計(入力)': {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
    },
    '純資産合計(自動集計)': {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
  },
  {
    科目名: '当期変動額合計(入力)(自動集計)',
    資本金: { id: '', manualInput: undefined, values: [], isAggreation: 1 },
    新株申込拠出金: {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
    資本剰余金: { id: '', manualInput: undefined, values: [], isAggreation: 1 },
    利益剰余金: { id: '', manualInput: undefined, values: [], isAggreation: 1 },
    自己株式: { id: '', manualInput: undefined, values: [], isAggreation: 1 },
    '株主資本計(入力)': {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
    '株主資本計(自動集計)': {
      id: '',
      manualInput: undefined,
      values: [],
      disabled: 1,
    },
    '評価･換算差額等': {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
    新株予約権: { id: '', manualInput: undefined, values: [], isAggreation: 1 },
    '純資産合計(入力)': {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
    '純資産合計(自動集計)': {
      id: '',
      manualInput: undefined,
      values: [],
      disabled: 1,
    },
  },
  {
    科目名: '当期変動額合計(入力)',
    資本金: { id: '', manualInput: undefined, values: [], isInput: 1 },
    新株申込拠出金: { id: '', manualInput: undefined, values: [], isInput: 1 },
    資本剰余金: { id: '', manualInput: undefined, values: [], isInput: 1 },
    利益剰余金: { id: '', manualInput: undefined, values: [], isInput: 1 },
    自己株式: { id: '', manualInput: undefined, values: [], isInput: 1 },
    '株主資本計(入力)': {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
    },
    '株主資本計(自動集計)': {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
    '評価･換算差額等': {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
    },
    新株予約権: { id: '', manualInput: undefined, values: [], isInput: 1 },
    '純資産合計(入力)': {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
    },
    '純資産合計(自動集計)': {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
  },
  {
    科目名: '当期末残高(B/S入力)(自動集計)',
    資本金: { id: '', manualInput: undefined, values: [], isAggreation: 1 },
    新株申込拠出金: {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
    資本剰余金: { id: '', manualInput: undefined, values: [], isAggreation: 1 },
    利益剰余金: { id: '', manualInput: undefined, values: [], isAggreation: 1 },
    自己株式: { id: '', manualInput: undefined, values: [], isAggreation: 1 },
    '株主資本計(入力)': {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
    '株主資本計(自動集計)': {
      id: '',
      manualInput: undefined,
      values: [],
      disabled: 1,
    },
    '評価･換算差額等': {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
    新株予約権: { id: '', manualInput: undefined, values: [], isAggreation: 1 },
    '純資産合計(入力)': {
      id: '',
      manualInput: undefined,
      values: [],
      isAggreation: 1,
    },
    '純資産合計(自動集計)': {
      id: '',
      manualInput: undefined,
      values: [],
      disabled: 1,
    },
  },
  {
    科目名: '当期末残高(B/S入力)',
    資本金: {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
      noPop: 1,
    },
    新株申込拠出金: {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
      noPop: 1,
    },
    資本剰余金: {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
      noPop: 1,
    },
    利益剰余金: {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
      noPop: 1,
    },
    自己株式: {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
      noPop: 1,
    },
    '株主資本計(入力)': {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
      noPop: 1,
    },
    '株主資本計(自動集計)': {
      id: '',
      manualInput: undefined,
      values: [],
      disabled: 1,
    },
    '評価･換算差額等': {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
      noPop: 1,
    },
    新株予約権: {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
      noPop: 1,
    },
    '純資産合計(入力)': {
      id: '',
      manualInput: undefined,
      values: [],
      isInput: 1,
      noPop: 1,
    },
    '純資産合計(自動集計)': {
      id: '',
      manualInput: undefined,
      values: [],
      disabled: 1,
    },
  },
  {
    科目名: '(翌期支払)',
    資本金: { id: '', manualInput: undefined, values: [] },
    新株申込拠出金: { id: '', manualInput: undefined, values: [] },
    資本剰余金: { id: '', manualInput: undefined, values: [] },
    利益剰余金: { id: '', manualInput: undefined, values: [] },
    自己株式: { id: '', manualInput: undefined, values: [] },
    '株主資本計(入力)': { id: '', manualInput: undefined, values: [] },
    '株主資本計(自動集計)': { id: '', manualInput: undefined, values: [] },
    '評価･換算差額等': { id: '', manualInput: undefined, values: [] },
    新株予約権: { id: '', manualInput: undefined, values: [] },
    '純資産合計(入力)': { id: '', manualInput: undefined, values: [] },
    '純資産合計(自動集計)': { id: '', manualInput: undefined, values: [] },
  },
];
