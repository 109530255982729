export default {
    '_common': {
        'Download': 'ダウンロード',
        'Delete': '削除',
        'Confirm': '確認',
        'Save': '保存',
        'Cancel': 'キャンセル',
        'Error': 'エラー',
        'Menubar': {
            'Project': 'プロジェクト管理',
            'Label': 'ラベリング',
            'Demo': 'デモ',
            'Predict': 'ワークスペース',
            'User': 'ユーザー管理',
            'Train': 'モデル構築',
            'System': 'システム管理',
            'EasyOcr': 'EasyOCR',
            'TenantSetting':'テナント設定',
            'Help': 'ヘルプ',
            'Language': '言語',
            'Japanese': '日本語',
            'English': 'English',
            'Resetpassword': 'パスワード再設定',
            'ResetAccessKey': 'アクセスキーをリセット',
            'Logout': 'ログアウト', 
            'LastUpdate': '前回更新',
            'TimeLocal': 'YYYY年MM月DD日 HH:mm',
        },
    },
    '_demonstration':{
        'DemoHome':{
            'Image to Predict': '予測画像',
            'Start Predict': '予測開始',
            'Image to bindingbox and ocr': 'Bindingboxとocrへの画像',
            'Pdf predict result': 'PDF予測結果',
            'Prediction result': '予測結果',
            'Image Classification': '画像分類',
            'Image Direction': '画像方向',
            'Image Preview': '画像プレビュー',
            'Predicted Image': '予測画像',
            'EasyOcrDownloadTitle': 'Easy OCRの画像をダウンロード',
            'EasyOcrDownloadStart': 'から',
            'EasyOcrDownloadEnd': 'まで',
            'EasyOcrDownloadSubmit': 'ダウンロード',
            'ExportCSV': 'FS_Preset 漏れエクスポート',
            'ExportCSVStart': 'から',
            'ExportCSVEnd': 'まで',
            'AnalysisStatus': 'PDF解析状況集計',
            'EasyocrHistory': 'Easy OCR使用履歴をエクスポート',
            'licensekeyPredictionHistory': 'License EasyOCR履歴をエクスポート',
            'LoginHistory': '登录履歴をエクスポート',
            'Download': 'ダウンロード',
            'PreDictionPdfTrackTitle': '予測PDFのトラック情報をダウンロードする',
            'PreDictionPdfStartTime': 'から',
            'PreDictionPdfTrackDownloadBtn': 'ダウンロード',
            'mailHistory': 'メール履歴',
            'mailStart': 'から',
            'mailEnd': 'まで',
            'WorkingStatus': '作業状況',
            'WorkingStatusStart': 'から',
            'WorkingStatusEnd': 'まで',
            'WorkingPeriod': '作業期間',
            'WorkingStatusOverview': '概要',
            'WorkingStatusDetails': '詳細',
            'ProjectId': 'プロジェクト ID',
            'WorkingStatusOverviewTitle': '作業状況(概要)',
            'WorkingStatusDetailsTitle': '作業状況(詳細)',
            'TenantId': 'テナント ID',
            'PageClassfication': 'ページ分類',
            'CharReplacement': 'Character Replacement'
        },
    },
    '_easy-ocr': {
        'ApiEdit': {
            'Save': '保存',
            'Do you want to save?': '保存しますか',
            'Cancel': 'キャンセル',
        },
        'ApiTable': {
            'Delete': '削除',
            'Do you want to delete it?': '削除しますか',
            'Cancel': 'キャンセル',
            'ID': 'ID',
            'Name': '名前',
            'Descripe': '説明',
            'Actions': '操作',
            'Edit': '編集',       
        },
        'EasyOcrTab': {
            'EasyOcrList': 'EasyOcrリスト',
        },
    },
    '_label': {
        'LabelManagementTable': {
            'Overview': '概要',
            'Edit': '編集',
            'ID': 'ID',
            'Project': 'プロジェクト',
            'Label': 'ラベル',
            'Operation': '操作',            
        },
        'LabelMenu': {
            'Images': '画像',
            'Distribution': 'データ分布',
            'UploadImages': '画像をアップロード',
            'UploadVideo': '動画をアップロード',
            'Trainingdata': 'トレーニングデータ',
            'Reference': '参照',
            'MLAssist': 'MLアシスト',   
            'AllImages': '画像全一覧',
            'SearchImages': '画像検索',          
        },
        'description': {
            'index': {
                'Cancel': 'キャンセル',
            },
        },
        'distribution': {
            'index': {
                'Refetch': '再読み込み',
                'Message': 'この操作には待ち時間が必要です',
                'labelName': 'ラベル名',
                'labelType': 'ラベルタイプ',
                'labeled': 'ラベリング済み集計',
                'all': '全体集計',
            },
        },
        'image': {
            'Images': {
                'Changeperson': '担当者変更',
                'Areyousure': 'レコードを担当しますか',
                'OK': 'はい',
                'Cancel': 'キャンセル',
                'Delete': '削除',
                'Are you sure to do this?': 'これを実行してもよろしいですか',
                'ID': 'ID',
                'ImageLink': '画像リンク',
                'LabelStatus': 'ラベルのステータス',
                'LockedStatus': 'ロック',
                'Person': '担当',
                'Actions': '操作', 
                'Uploadtime': 'アップロード日',
                'Modifytime': '最終更新日',
                'Review': 'レビュー' ,
                'Search': '検索',
                'Pleaseenterimagelink': '画像リンクを入力してください...',
                'ChangeReviewer': 'レビュー者変更',
                'reviewConfirm': 'レコードをレビューしますか',  
                'TextInLabelData': 'ラベルデータのテキスト',  
                'Pleaseentertext': 'テキストを入力してください...',   
                'Edit': '編集'      
            },
            'UploadImages': {
                'Uploadfilesfromdisk': 'ファイルをアップロード',
                'Upload': 'アップロード',
                'Uploadpdfsfromdisk': 'PDFをアップロード',
                'ImagesURLs': '画像のURL',
                'SourcefromURLs': 'URLからのソース',
                'Serverfilesystempath': 'サーバーのファイルシステムパス',
                'Sourcefromserver': 'サーバーのファイルシステムからのソース',
            },
            'ZipUpload': {
                'UploadZipFile': 'ZIPをアップロード',
                'Upload': 'アップロード',
                'SizeLimit': '制限サイズ200MB',
                'Loading': 'ファイルの読み込み',
                'Unzipping': 'ファイルの解凍',
                'Uploading': 'ファイルのアップロード',
                'Completed': '完了',
            },
            'FolderUpload': {
                'Label': 'フォルダの画像をアップロード',
                'Upload': 'アップロード',
                'Abort': '中止',
            },
            'UploadVideo': {
              'Title': '動画のアップロード',
              'SelectVideo': 'ビデオを選択',
              'SplitToImages': '画像への分割',
              'FPS': 'フレーム/秒',
              'StartPosition': '開始位置',
              'MaxCount': '最大カウント',
              'CutVideo': 'カット動画',
              'SaveImages': '画像を保存',
              'Image': '画像',
              'ImageName': '画像名',
              'Duplicate': '重複した画像'
            },
        },
        'label-app': {
            'AssigneesHOC': {
                'Changeperson': '担当者変更',
                'Areyousure': 'レコードを担当しますか',
                'ChangeReviewer': 'レビュー者変更',
                'reviewConfirm': 'レコードをレビューしますか',  
                'OK': 'はい',
                'Cancel': 'キャンセル',                
            },
            'Canvas': {
                'Are you sure to do this?': 'これを実行してもよろしいですか',
                'Delete': '削除',
                'Cancel': 'キャンセル',                
            },
            'Labeling': {
                'TakeSnapshot': 'Take a Snapshot',
                'Sure': 'これを実行してもよろしいですか',
                'Cancel': 'キャンセル'
            },
            'Submitting': {
                'DoSubmit': '申し出る',
                'Sure': 'これを実行してもよろしいですか'
            }
        },
        'model': {
            'MLAssist': {
                'Availableendpoints': '利用可能なエンドポイント',
                'Delete': '削除',
                'Addanewendpoint': '新しいエンドポイントを追加',
                'ExpectedAPIformat': '予期されるAPI形式',                
            }
        },
        'reference': {
            'UploadReference': {
                'Uploadimagefromdisk': 'ディスクから画像をアップロード',
            },
        },
        'training-data': {
            'ImportData': {
                'ExportMsg': 'ラベリング済みのデータを取得しますが',
                'Completion only': '完成のみ',
                'Get all': '全体取得',
                'UploadMsg': '以下のダウンロードボタンで生成された単一のJSONファイルと対応する単一の画像をアップロードします。',
                'UploadJsonMsg': '以下のダウンロードボタンで生成された単一のJSONファイルをアップロードします。',
                'UploadLabel': '画像とJSONファイルを選択',
                'UploadJson': 'JSONファイルを選択',
                'Upload': 'アップロード',
                'DownloadLabel': 'JSONエンコードされたラベルを含むzipファイルをダウンロードする',
            },
        },
    },
    '_login': {
        'LoginPage': {
            'UserLogin': 'ユーザーログイン',
            'Tenant': 'テナントID',
            'TenantName': 'テナント名',
            'History': 'ログイン履歴',
            'User': 'ユーザー',
            'Password': 'パスワード',
            'Signin': 'サインイン',
            'Register': 'テナント登録', 
            'Confirm': '確認',
            'AlertMessage': 'Oktaメールはユーザーメールとは異なりますが、同期しますか',
            'Cancel': '取消',
            'TenantIdConfirm': 'テナントIDを確認する',
            'ResetPassword': 'パスワードリセット',
            'RootLogin': 'ルートユーザー',
            'PasswordReset': 'パスワード設定',
            'NewPassword': '新しいパスワード',
            'RetypeNewPassword': '新しいパスワードを再入力',
            'Submit': '再設定',
            'User ID': 'ユーザーID',
            'Tenant ID Confirm': 'テナントIDがわからない方',
            'Email authentication': 'パスワードがわからない方',
            'TenantId success msg': 'テナントID確認メールを送信しました',
            'User retrieve success msg': 'パスワード設定メールを送信しました',
            'Change success msg': 'パスワードを設定しました',
            'TenantId error msg': ' テナントIDを入力してください',
            'UserId erroe msg': ' ユーザーIDを入力してください',
            'Password error msg': ' パスワードを入力してください',
            'Login': 'ログイン',
            'Input newPwd': '新しいパスワードを入力してください',
            'Input reNewPwd': '新しいパスワードを再入力してください',
            'ReNewPwd error': '新しいパスワードと再入力したパスワードが一致しません',
            'NewPwd error': 'パスワードポリシーの要件を満たしていません',
            'Go to login': 'ログイン画面へ',
            'Retriere Again': 'パスワード設定URLを再送信',
        },
        'TenantLoginPage': {
            'UserLogin': 'ユーザーログイン',
            'TenantUser': 'テナントユーザー',
            'Tenant': 'テナント',
            'User': 'ユーザー',
            'Password': 'パスワード',
            'Signin': 'サインイン',
            'Confirm': '確認',
            'AlertMessage': 'Oktaメールはユーザーメールとは異なりますが、同期しますか',
            'Cancel': '取消',
        },
        'RegisterPage': {
            'Register': '登録',
            'TenantsMail': 'テナントメール',
            'Code': 'コード',
            'Send': '送信',
            'Next': '次',
            'TenantsName': 'テナント名',
            'Search': '検索',
            'Address': '住所',
            'LastName': '姓',
            'FirstName': '名',
            'LastKanaName': '姓（ふりがな）',
            'FirstKanaName': '名（ふりがな）',
            'Phone': '電話番号',
            'UserMail': '担当者メール',
            'Pwd': 'パスワード',
            'ConfirmPwd': '確認パスワード',
            'ContractPlan': '契約プラン',
            'TermsService': 'ラベルツールの利用規約',
            'Submit': '申し出る',
            'Return': '戻る',
            'EmailSuccessed': 'メール送信に成功しました！',
            'AgreeService': '利用規約に同意します',
            'Plan 1': 'プラン 1',
            'Plan 2': 'プラン 2',
            'Plan 3': 'プラン 3',
            'Rule': '利用規約　XXXXXX',
        },
        'TenantIdConfirmPage': {
            'TenantIdConfirm': 'テナントIDの確認',
            'TenantsMail': 'メールアドレス',
            'Send': 'テナントID確認メールを送信',
            'Tips': '登録済のメールアドレスにテナントIDが記載されたメールを送信します。',
            'Tenants mail error': 'メールアドレスを正しく入力してください',
            'Error': 'ユーザー情報が見つかりません。メールアドレスに誤りがないかご確認ください',
            'Go to login': 'ログイン画面へ'
        },
        'RetrievePage': {
            'SendMail': 'メール認証',
            'Mail': 'ユーザーメール',
            'Send': '送信',
        },
        'UserRetrievePage':{
            'TenantId':'テナントID',
            'Email':'メールアドレス',
            'UserName':'ユーザー名（氏名等）',
            'Send': 'パスワード設定メールを送信',
            'SendMail': 'パスワードの再設定',
            'Mail': 'ユーザーメール',
            'History': 'ログイン履歴',
            'Please enter tenantId': 'テナントIDを入力してください',
            'Please enter email correctly': 'メールアドレスを正しく入力してください',
            'Please enter userName': 'ユーザー名を入力してください',
            'No tenants to select': '選択できるテナントがありません',
            'TenantName info': 'テナント名（入力不要）',
            'Go to login': 'ログイン画面へ',
            'Tips': '登録済のメールアドレスにパスワード設定用URLが記載されたメールを送信します。',
            'Tips info': '（送信から10分以内に新しいパスワードを設定してください）'
        },
        'ResetPage': {
            'ResetPW': 'パスワードを再設定する',
            'OK': '確認',
        },
        'TwoStageAuthentication': {
            'InfoHeader': '２段階認証コードを入力',
            'InfoBody': '認証コードを送信しました。５分以内にメールに記載のコード（６桁）を入力し、認証を行ってください。',
            'Continue': '認証してログイン',
            'Resend': '認証コードを再送信',
            'VerificationCode': '６桁のコードを入力',
            'Account is locked': 'アカウントがロックされています。システム管理者にご確認ください',
            'Code is incorrect': '認証コード（６桁）が正しくありません。あとX回入力を間違うとアカウントがロックされます',
            'Code has been resend': '認証コードを再送信しました',
            'Authentication code': '認証コード',
            'Go to login': 'ログイン画面へ'
        
        },
    },
    '_monitor': {
        'online-users': {
            'index':{
                'Online Users': 'オンラインユーザー',
            },
            'OnlineUsersTable': {
                'Kick off': 'キックオフ',
                'Do you really want to kick off this user': '本当にこのユーザをキックオフしますか',
                'OK': 'はい',
                'Cancel': 'キャンセル',
                'User ID': 'ユーザーID',
                'User Name': 'ユーザー名',
                'Ip Address': 'IPアドレス',
                'User Agent': 'ユーザーエージェント',
                'Online Time': 'オンライン時間',
                'Operation': '操作',                
            }
        },
    },
    '_predict': {
        'admin': {
            'PdfSearch': {
                'Filename': 'ファイル名',
                'Pleaseentername': 'ファイル名を入力してください...',
                'Uploadtime': 'アップロード日',
                'Author': 'アップロード者',
                'Personincharge': '担当者',
                'Processingstate': 'ステータス',
                'Search': '検索',
                'Noresults' : '該当データがありません', 
                'NoDataToSelect' : '選択できるデータがありません',           
            },
            'PdfUpload': {
                'Upload': 'アップロード',
                'Connecting': '通信中',
                'Analysistitle': '解析対象',
                'Analysis_1': '貸借対照表',
                'Analysis_2': '損益計算書',
                'Analysis_3': '株主資本等変動計算書',
                'Analysis_4': '製造原価明細表',
                'Analysis_5': '販売費及び一般管理費',
            },
            'PredictAdmin': {
                'Person': '担当',
                'Edit': '編集',
                'Delete': '決算書を削除',
                'Analysis': '補正情報ダウンロード',
                'Changeperson': '担当者を変更',
                'Areyousure': '担当者を自身に変更しますか?',
                'OK': 'はい',
                'Cancel': 'キャンセル',
                'Areyousuredelete': 'この決算書データを削除してよろしいですか?(この操作は取り消せません)',
                'Filename': 'ファイル名',
                'Processingstate': 'ステータス',
                'Uploadtime': 'アップロード日',
                'Author': 'アップロード者',
                'Personincharge': '担当者',
                'Accuracy': '正確さ',
                'Operation': '操作',
                'Fileupload': 'ファイルアップロード',
                'Filtering': '検索',
                'Renew':'更新',                
            },
            'PredictProjects': {
                'AIAnalysis': 'ワークスペース一覧',
            },
        },
        'detail': {
            'pdf': {
                'Canvas': {
                    'Rotation': 'ページの回転',
                    'Do you want to rotate this page 90 degrees?': 'このページを右に90度回転させますか？',
                    'Results analysis': '結果解析',
                    'Do you want re-analyze the results?': '結果を再解析しましょうか',
                    'AI Analysis': 'AI解析',
                    'Do you want to re-analyze this page with AI?': 'このページをAIで再解析しますか',
                    'OK': 'はい',
                    'Cancel': 'キャンセル',
                    'Delete': '削除',
                    'Do you want to remove all bounding boxes?': 'すべての境界ボックスを削除しますか',
                    'Zoom in': '拡大',
                    'Zoom out': '縮小',
                    'Zoom reset': 'リセット',
                    'More': 'もっと',
                    'New bbox': 'bbox追加',
                    'rotate': '回転',
                    'Delete all bbox': '全bbox削除',
                    'Predict': '再解析',
                    'Mapping': '再マッピング',
                    'short key': 'ショートカットキー',
                    'movelabeling': 'ラベリング移行',
                    'Movelabeling': 'ラベリング移行',
                    'Do you want to move it to labeling data?': '学習データに遷移ましょうか ',
		            'Re-do AI scan': 'ページの再読取',
		            'Would you like to re-analyze this document with AI?':
		              'このページをOCRで再度読み取ります。実行すると、このページの編集および連携内容が全て破棄されます。実行しますか？',
		            'Reanalyze': 'はい',
		            'Close': '閉じる',
		            'Kinds': '分類',
		            'Please re-analyze the document': '書類の再読取をしてください。',
		            'Reanalysis': '再読取',
                    'No Reanalysis':'「保存」後に再読取できます',
                    'No rotate':'「保存」後に回転できます',
                    'Prohibit Reanalysis':'この機能は以下の分類ページで利用できます: 貸借対照表、損益計算書、製造原価明細表、販売費及び一般管理費、株主資本当変動計算書'
                },
                'MuiPopup': {
                    'Ocr': '自動読取',
                    'Edit': '値修正',
                    'LinkTo': 'マッピング',
                    'Choose Cell': 'セルを選択',
                    'ProhibitEditingWarning': '「値修正」する場合は、保存ボタンを押下してください ',
                    'unMapping': '連携を解除',
                    'ItemSearch': {
                        'Search': '検索',
                        'Set': '選択',
                        'Cancel': 'キャンセル',
                    },
                    'Maybe': '候補',
                    'Alignment': '連携',
                    'Scan subject': 'スキャン科目',
                    'Scan amount': 'スキャン金額',
                    'Cooperation': '連携济',
                    'Not linked': '未連携',
                    'Collaborative subjects': '連携先科目',
                    'Correct the amount': '金額を修正',
                    'EditingWarning': '「保存」後に金額を修正できます',
                    'Save amount': '金額を修正する場合は先に「保存」してください',
                    'Select collaborative subject': '科目を連携する',
                    'Cancel': 'キャンセル',
                    'Disabled': '詳細ビューでは使用できません'
                },
                Thumbnail: {
                	Search: '検索',
                	'I searched and did not find any hit results':
                	  '検索してヒットしてる結果が見つかりませんでした。',
        		},
            },
            'mapping': {
		        'ProhibitEditingWarning':
		          '適当なタイミングで「保存」ボタンを押下してください。データを保存するまで、一部ボタンを押せないよう制御しています。',
		        'Cancellation of manual input': '手入力を解除',
		        'Manual input': '手入力',
		        'Subject': '科目',
                'Manual input the amount by double-clicking':'ダブルクリックで金額を手入力',
		        'Manual entry of amount': '金額を手入力',
                'Open details': '連携内容を表示',
                'Go to reference': '参照元に移動',
                'Copy the amount': '金額をコピー',
                'Copied': 'コピーしました',
                'Show the breakdown of the amount': '集計の内訳を表示',
		        'Invert the sign': '符号を反転',
                'Breakdown': '内訳',
		        'Cancel cooperation': '連携を解除',
		        'Please select the subject you want to collaborate with':
		          '↓   科目を選択してください',
		        'Detail': '詳細',
		        'ManualInput': '手動入力',
		        'Sign inversion': '符号反転',
		        'Enter with a link': 'リンクで入力へ',
		        'Select': '選択する',
		        'Report to administrator': '読取精度の問題を報告',
		        'Report reading accuracy issues': '読取精度の問題を報告',
		        'If you think the accuracy needs to be improved, report it to the administrator':
		          '読取精度に問題がある場合は、報告ボタンをクリックしてください。精度向上の参考にさせていただきます。',
		        'Send': '報告',
		        'Cancel': 'キャンセル',
		        'Select a type': 'タイプを選択してください',
		        'Keep': '保存',
		        'CSV download': 'この表をCSV形式で保存',
		        'Restore to the previous saved state': '直前の保存状態に復帰',
		        'Caution': '注意',
		        'Some items do not match the values of (automatic aggregation) and (input)':
		          '下記科目の自動集計と入力の値が一致していません',
                'Have Input Error': '入力エラーがあります',
		        'please confirm': '確認してください。',
		        'Close': 'キャンセル',
                'As it is': 'このまま',
		        'Proceed to': 'に進む',
		        'ScanClassification': '区分',
                'ScanClassificationAll': '読取区分',
		        'ScanSubject': '読取科目',
                'ScanSubjectAll': '読取科目',
		        'AmountOfMoney': '金額',
                'AmountOfMoneyAll': '読取金額',
		        'ChangeOfPartnerSubject': '連携科目変更',
                'ChangeOfPartnerSubjectAll': '連携科目',
		        'Lift': '解除',
		        'ConnectionSubject': '連携科目',
		        'Operation': '操作',
		        'DifferenceFromInput': '入力と相違',
                'Thank you for your report':'ご報告ありがとうございました',
                'Email sending failed':'管理者の送信に失敗しました',
                'View in card view':'カードビュー',
                'View in list view':'リストビュー',
                'View in table view':'表形式ビュー',
                'View in detail view':'詳細ビュー',
                'Formula input error': '数式入力エラー',
                'More than 14 digits': '14桁を超える',
                'Aggregation': '集計',
                'TotalDetail': '詳細',
                'Account title dictionary': '勘定科目辞書',
                'Add Row Below': '下に行を追加',
                'Delete Row': '追加行を削除',
                'Candidate': '候補',
                'Candidate subjects':'候補科目',
                'Preparing candidate subjects':'候補科目',
                'Amount check':'金額チェック',
                'Low confidence characters':'低信頼度の文字',
                'Beware of low confidence characters': '低信頼度の数字に注意',
                'Beware of the digits': 'ケタに注意',
                'Narrow down data':'表示を絞り込む',
                'Release filter':'絞り込み解除',
                'Narrowing down': '絞り込み中',
                'Display the relevant sections': '該当箇所を表示',
		    },
            'dialog': {
                'TimeLocal': 'YYYY年MM月DD日 HH:mm',
                'The person in charge has been set': '担当者を設定しました',
                'The screen has been updated': '画面を更新しました',
            },
            'review': {
                'Close': '閉じる',
                'Submit update request': '件 の更新依頼を送信',
                'Title': '自動連携先の更新依頼',
                'File label': 'ファイルID',
                'Now': '現在',
                'New': '変更',
                'Update': '更新',
                'Tab1': '未依頼',
                'Tab2': '依頼済/更新済',
                'Cancel': 'キャンセル'
            }
        },
    },
    '_project': {
        'ProjectTab': {
          'ProjectList': 'プロジェクトリスト',
          'ProjectUser': 'ユーザ＆プロジェクトアクセス権一括設定'
        },
        'ProjectAdd': {
            'Project Name': 'プロジェクト名',
            'Project Description': 'プロジェクトの説明',
            'Save': '保存',
            'Cancel': 'キャンセル',
        },
        'ProjectDescription': {
            'Save': '保存',
            'Cancel': 'キャンセル',            
        },
        'ProjectManagementTable': {
            'Are you sure you want to delete the project?': 'プロジェクトを削除しますか',
            'Delete': '削除',
            'Create a new Project by Copy?': 'Create a new Project by Copy?',
            'Copy': '複製',
            'Cancel': 'キャンセル',
            'Overview': '概要',
            'Edit': '編集',
            'ID': 'ID',
            'Project': 'プロジェクト',
            'Summary': '概要',            
        },
        'ProjectMenu': {
            'Edit': '編集',
            'Labels': 'ラベル',
            'ResultTables': '結果表',
            'ResultFunctions': '結果関数',
            'User': 'ユーザー',
            'Metadata': 'メタデータ',
            'Parsing Function': '解析機能',
            'AI Process': 'AIプロセス',    
            'Working Status': '作業状況',
            'CharReplacement': 'Character Replacement',
            'APIFunction': 'API関数',   
        },
        'mapping-function': {
            'ParamsEditor': {
                'Confirm': '確認',
                'Do you really want to change the test params?': 'テストパラメータを本当に変更しますか',
                'OK': 'はい',
                'Do you really want to reset the test params to default?': 'テストパラメータをデフォルトにリセットしますか',
                'Cancel': 'キャンセル',
                'Save': '保存',
                'Reset': 'リセット',                
            },
        },
        'metadata': {
            'ImportMetadata': {
                'Import project metadata': 'プロジェクトのメタデータをインポート',
                'Select CSV files': 'CSVファイルを選択してください',
                'Import': 'インポート',
                'Cancel': 'キャンセル',                
            },
            'ProjectMetadataEdit': {
                'Category': 'カテゴリー',
                'SubCategory': 'サブカテゴリー',
                'Item': '項目',
                'Value': '値',
                'Save': '保存',
                'Cancel': 'キャンセル',
                'Edit Metadata': 'メタデータを編集',
                'Add Metadata': 'メタデータを追加',
            },
            'ProjectMetadataTable': {
                'Edit': '編集',
                'Delete': '削除',
                'Do you want to delete it?': '削除しますか',
                'Cancel': 'キャンセル',
                'Do you want to get metadata?': 'メタデータを取得しますか',
                'Download': 'ダウンロード',
                'Num': '数',
                'Category': 'カテゴリー',
                'SubCategory': 'サブカテゴリー',
                'Item': '項目',
                'Value': '値',
                'Operation': '操作',
            },
        },
        'process': {
            'Process': {
                'OK': 'はい',
                'Cancel': 'キャンセル',
                'Save': '保存',                
                'Confirm': '確認',                
                'Do you want to save the record?': 'レコードを保存しますか？'
            },
        },
        'project': {
            'ProjectEdit': {
                'Are you sure to do this?': 'これを実行してもよろしいですか',
                'Cancel': 'キャンセル',
                'DeleteTitle': 'プロジェクトを削除',
                'DeleteContext': ['このプロジェクトを削除します。', 'ラベル、画像、プロジェクトに関するすべてのメタデータも同時に削除されます。'],
                'DeleteButtonName':'削除',
                'DeleteProjectText': 'ラベル、画像、プロジェクトに関するすべてのメタデータを削除します',
                'DeleteProjectButton': 'プロジェクトを削除',
                'CopyTitle': 'プロジェクトを複製',
                'CopyContext': ['このプロジェクトを複製します。'],
                'CopyButtonName':'複製',
                'CopyProjectText': 'このプロジェクトを複製します',
                'CopyProjectButton': 'プロジェクトを複製',           
            },
        },
        'user': {
            'ProjectUserEdit': {
                'Save': '保存',
                'Cancel': 'キャンセル',
                'Edit User': 'ユーザーを編集',
            },
            'ProjectUserTable': {
                'Are you sure delete user': 'このユーザーを削除してもよろしいですか',
                'Delete': '削除',
                'Cancel': 'キャンセル',
                'Num': '数',
                'User Name': 'ユーザー名',
                'User Email': 'ユーザーのメール',
                'Operation': '操作',                
            },
        },
        'label': {
            'ImportTitle': 'ラベルのメタデータをインポート',
            'Cancel': 'キャンセル',  
            'Import': 'インポート',  
        },
    },
    '_system': {
        'System': {
            'Preferences': '環境設定',   
            'Tenants': 'テナント',
            'LicenseKey': 'ライセンスキー',
            'Machines': 'コンピューター',
            'Feedback': 'フィードバック',
            'Black Ip': 'Black IP',
            'FsItemMapping': 'Fs Item Mapping',
            'Pdf Search': 'PDF検索',
            'RabbitMQ': 'RabbitMQ',
            'Replace': '置換',
        },
        'preferences': {
            'Preferences': {
                'ID': 'ID',
                'Category': 'カテゴリー',
                'SubCategory': 'サブカテゴリー',
                'Name': '名前',
                'SubName': 'サブ名前',
                'Version': 'バージョン',
                'SubVersion': 'サブバージョン',
                'Value': '値',
                'Priority': '優先',
                'Operation': '操作',
                'Edit': '編集',
                'Delete this record': 'このレコードを削除',
                'Are you sure to do this?': 'これを実行してもよろしいですか',
                'Delete': '削除',
                'Cancel': 'キャンセル',
                'Edit Preferences': '環境設定を編集',
                'Add Preferences': '環境設定を追加',
            },
        },
        'feedback': {
            'index': {
                'No':'No.',
                'TenantId':'テナントID',
                'FileName':'ファイル名',
                'UserName':'ユーザー名',
                'LicenseInfo':'ライセンス情報',
                'MacAddress':'Macアドレス',
                'IpAddress':'IPアドレス',
                'Rating':'評価',
                'Type':'状況',
                'Comment':'フィードバック',
                'ReportTime':'報告時間',
                'Memo':'メモ',
                'Info': '情報',
                'Are you sure delete feedback': 'このフィードバックを削除してもよろしいですか',
                'Delete': '削除',
                'Download': 'ダウンロード',
                'Cancel': 'キャンセル',
                'Operation': '操作', 
                'EasyOCR Endpoints': 'EasyOCR エンドポイント', 
            },
            'search':{
                'UserName':'ユーザー名',
                'UserNameText':'ユーザー名を入力して下さい',
                'ReportTime':'報告時間',
                'Search':'検索',
                'TanentSearch': 'テナント',
            },
            'ApiEdit': {
                'Save': '保存',
                'Do you want to save?': '保存しますか',
                'Reload': '表示',
                'Update contents': '表示内容更新しますか',
                'RePredict': '再読取',
                'Do you want to re-predict?': 'どのサーバーで再読取しますか？',
                'Inference': '最新 Inference server',
                'Flask': 'Flask',
                'OK': 'はい',
                'Cancel': 'キャンセル',
            },    
            'parseInfo': {
                'Comment': '内容',
                'Memo': 'メモ',
                'Type': '状況',
                'Data': 'データ',
                'History':'歴史',
                'MetaData': 'メタデータ',
            },
            'parsingTypes': [
                {'new created': '新規'},
                {'in process': '対応中'},
                {'back log': 'バックログ'},
                {'completed': '対応済'},                
            ],
        }, 
        'tenants': {
            'Index': {
                'Maintain': 'メンテナンス',
                'Audits': '監査',
                'TenantSettings': 'テナント設定',
                'User': 'ユーザー',
                'ProjectSettings': 'プロジェクト設定'
            },
            'maintain': {
                'Edit': {
                    'Confirm': '確認',
                    'Cancel': 'キャンセル',
                    'TenantAdd': 'テナント 追加',
                    'TenantEdit': 'テナント 編集',
                    'TenantsName': 'テナント名',
                    'TenantsMail': '連絡先メールアドレス',
                    'Address': '住所',
                    'LastName': 'テナント管理者（姓）',
                    'FirstName': 'テナント管理者（名）',
                    'LastKanaName': '姓（ふりがな）',
                    'FirstKanaName': '名（ふりがな）',
                    'Phone': '電話番号',
                    'Count': '読取可能数',
                    'UserMail': 'テナント管理者メールアドレス',
                    'ContractPlan': '契約プラン',
                    'Save': '保存',
                    'limitAmountUser': '利用可能ユーザ数',
                    'limitAmountProject': '利用可能プロジェクト数',
                    'easyocrContract': 'EasyOCR 利用可能期間',
                    'fsContract': '決算書 利用可能期間',
                    'limitAmountPdf': '利用可能ファイル数',
                    'updateExpiredTimestamp': 'ライセンスキーを同時に更新',
                    'pdfAutoRenew': '決算書契約更新',
                    'easyocrAutoRenew': 'EasyOCR契約更新',
                },
                'Index': {
                    'Edit': '編集',
                    'Delete': '削除',
                    'Do you want to completely delete the tenant data': 'テナントデータを完全に削除しますか?',
                    'Do you want to delete this tenant?': '削除されたことを確認するには、テキスト入力フィールドに「完全に削除」と入力します。',
                    'Confirm': '確認',
                    'Cancel': 'キャンセル',
                    'ID': 'ID',
                    'TenantsName': 'テナント名',
                    'TenantsMail': 'テナントメール',
                    'Address': '住所',
                    'Phone': '電話番号',
                    'Count': '読取可能数',
                    'Actions': '操作',
                    'Filtering': '検索',
                },
                'Search': {
                    'TenantsName': 'テナント名',
                    'Please enter a tenant name': 'テナント名を入力してください',
                    'TenantsMail': 'テナントメール',
                    'Please enter a tenant mail': 'テナントメールを入力してください',
                    'UpdateTime': '更新時間',
                    'Search': '検索',
                    'Add': '追加',
                }
            },
            'audits': {
                'Edit': {
                    'Confirm': '確認',
                    'Do you want to update this audits?': 'この監査を更新しますか?',
                    'Cancel': 'キャンセル',
                    'AuditsEdit': '編集',
                    'TenantsName': 'テナント名',
                    'TenantsMail': 'テナントメール',
                    'Address': '住所',
                    'LastName': '姓',
                    'FirstName': '名',
                    'LastKanaName': '姓（ふりがな）',
                    'FirstKanaName': '名（ふりがな）',
                    'Phone': '電話番号',
                    'UserMail': '担当者メール',
                    'ContractPlan': '契約プラン',
                    'Comment': 'コメント',
                    'Agree': '同意',
                    'Refuse': '拒否',
                },
                'Index': {
                    'Edit': '編集',
                    'Delete': '削除',
                    'Do you want to delete this audits?': 'この監査を削除しますか?',
                    'Confirm': '確認',
                    'Cancel': 'キャンセル',
                    'ID': 'ID',
                    'TenantsName': 'テナント名',
                    'Status': 'ステータス',
                    'Personincharge': '担当者',
                    'UpdateTime': '更新時間',
                    'Actions': '操作',
                    'Filtering': '検索',
                },
                'Search': {
                    'TenantsName': 'テナント名',
                    'Please enter a tenant name': 'テナント名を入力してください',
                    'UpdateTime': '更新時間',
                    'Status': 'ステータス',
                    'Search': '検索',
                }
            },
            'setting': {
                'Edit': {
                    'Category': 'カテゴリー',
                    'SubCategory': 'サブカテゴリー',
                    'Name': '名前',
                    'SubName': 'サブ名前',
                    'Version': 'バージョン',
                    'SubVersion': 'サブバージョン',
                    'Value': '値',
                    'Priority': '優先',
                    'Edit TenantSetting': 'テナント設定を編集',
                    'Add TenantSetting': 'テナント設定を追加',
                },
                'Index': {
                    'Edit': '編集',
                    'Delete': '削除',
                    'Are you sure to do this?': 'これを実行してもよろしいですか?',
                    'Confirm': '確認',
                    'Cancel': 'キャンセル',
                    'ID': 'ID',
                    'Category': 'カテゴリー',
                    'SubCategory': 'サブカテゴリー',
                    'Name': '名前',
                    'SubName': 'サブ名前',
                    'Version': 'バージョン',
                    'Value': '値',
                    'Operation': '操作',
                    'Filtering': '検索',
                    'Do you want to get tenantSetting?': 'テナント設定を取得しますか',
                    'Download': 'ダウンロード',
                },
                'ImportTenantSetting': {
                    'Import tenantSetting': 'テナント設定をインポート',
                    'Select CSV files': 'CSVファイルを選択',
                    'Import': 'インポート',
                },
                'Search': {
                    'TenantsName': 'テナント名',
                    'Search': '検索',
                    'Add': '追加',
                },
            },
            'user':{
                'User':{
                    'Edit': '編集',
                    'Reset password': 'パスワードを再設定',
                    'Reset access key': 'アクセスキーをリセット',
                    'Delete': '削除',
                    'Are you sure to do this?': 'これを実行してもよろしいですか',
                    'Cancel': 'キャンセル',
                    'Num': '数',
                    'Name': '名前',
                    'E-mail': 'Eメール',
                    'Status': 'じょうきょう',
                    'Password': 'パスワード',
                    'Access Key': 'アクセスキー',
                    'Role': '役割',
                    'Operation': '操作',
                    'Reset': '再設定',
                    'Reset success': '再設定成功しました',
                    'EmailSuccessed': 'メール送信に成功しました！',
                    'Do you want to get user': 'ユーザーを取得しますか',
                    'Account': 'Oktaユーザー名',
                    'Edit User': 'ユーザーを編集',
                    'Add User': 'ユーザーを追加',
                    'Search': '検索',
                    'TenantsName': 'テナント名',
                    'Please enter tenantname to perform the operation':'操作を実行するには、テナント名を入力してください'
                },
                'ImportUser': {
                    'Import user': 'ユーザーをインポート',
                    'Select CSV files': 'CSVファイルを選択',
                    'Import': 'インポート',
                },
            },
            'project': {
                'ImportTenantSetting': {
                    'Import ResultFunction': '結果関数をインポート',
                    'Import ApiFunction': 'API関数をインポート',
                    'Import MetaData': 'メタデータをインポート',
                    'Select CSV files': 'CSVファイルを選択してください',
                    'Select TXT files': 'TXTファイルを選択してください',
                }
            }
        },
        'FsItemMapping': {
            'Tab': {
                'Global': 'システム',  
                'Tenant': 'テナントPresetMapping作成',
                'TenantPreset BulkImport': 'Tenant Preset一括導入',
                'TenantPreset BulkExport': 'Tenant Preset一括抽出',
                'DiffExport': 'DIFFエクスポート',
                'TenantConvertSubtitle BulkImport': 'Tenant 読替表一括導入',
                'TenantConvertSubtitle BulkExport':'Tenant 読替表一括抽出'
            },
        },
        'licenseKey': {
            'LicenseKey': {
                'ID': 'ID',
                'TenantID': 'テナントID',
                'PageCount': 'ページ数',
                'MachineCount': 'コンピューター数',
                'ContractID': '契約ID',
                'LicenseKey': 'ライセンスキー',
                'IPFilter': 'IPフィルタリング',
                'CreatedAt': '作成時間',
                'ValidFrom': '発効日',
                'ExpiredTime': '有効期限',
                'Operation': '操作',
                'Delete': '削除',
                'Copy': '複製',
                'Edit': '編集',
                'easyocrEp': 'EndPoint',
                'Description': '説明',
                'Cancel': 'キャンセル',
                'Do you want to get license key': 'ライセンスキーの検索結果をCSV形式でダウンロードしますか？',
                'Download': 'ダウンロード',
            },
            'Search': {
                'ID': 'ID',
                'Please enter a ID': 'IDを入力してください',
                'TenantID': 'テナント',
                'ContractID': '契約ID',
                'Please enter a Contract ID': '契約IDを入力してください',
                'LicenseKey': 'ライセンスキー',
                'Please enter a License Key': 'ライセンスキーを入力してください',
                'CreatedAt': '作成時間',
                'ValidFrom': '発効日',
                'ExpiredTime': '有効期限',
                'endpoint': 'EndPoint',
                'Please enter a EndPoint': 'EndPointを入力してください',
                'Search': '検索',
                'Reset': '条件クリア',
            }
        },
        'machines': {
            'Machines': {
                'ID': 'ID',
                'LicenseKeyID': 'ライセンスキーID',
                'OSInfo': 'OS情報',
                'EasyOCRVersion': 'EasyOCRバージョン',
                'MACAddress': 'MACアドレス',
                'IPAddress': 'IPアドレス',
                'CreatedAt': '作成時間',
                'Operation': '操作',
                'Delete': '削除',
                'Copy': '複製',
            },
        },
        'blackip':{
            'Index':{
                'IpFile': '認証失敗一覧',
                'GroupFile': 'Ip 一覧',
            },
            'IpFile':{
                'Index':{
                    'ID': 'ID',
                    'IpAddress': 'IpAddress',
                    'FailedTime': 'FailedTime',
                    'Actions': 'Actions',
                    'Delete': '削除',
                    'OK': '削除',
                    'Confirm': '確認',
                    'Cancel': 'キャンセル',
                    'Do you want to delete this blacklist?': "レコードを削除してもよろしいでしょうか?"
                },
                'Search':{
                    'FailedTime': '日時指定検索',
                    'Search': '検索',
                }
            },
            'GroupFile':{
                'Index':{
                    'ID': 'ID',
                    'IpAddress': 'IpAddress',
                    'Count': 'Count',
                    'Actions': 'Actions',
                    'Delete': '削除',
                    'OK': '削除',
                    'Confirm': '確認',
                    'Cancel': 'キャンセル',
                    'Do you want to delete this blacklist?': "レコードを削除してもよろしいでしょうか?"
                },
                'Search':{
                    'FailedTime': '日時指定検索',
                    'Search': '検索',
                },
            },
        },
        'PdfSearch':{
            'Index':{
                'Cancel': 'キャンセル',
                'ID': 'PDF ID',
                'ServerIp': 'サーバーIP',
                'CreatedById': '作成者ID',
                'CreatedByName': '作成者名',
                'UploadTime': 'アップロード日',
                'LastEdited': '最終編集日',
                'ProjectsId': 'プロジェクトID',
                'StateFlag': 'ステータス',
                'DeleteFlag': '削除フラグ',
                'TenantId': 'テナントID',
                'Filtering': '検索',
                'Operation': '操作',
                'Do you want to get pdf search results?': 'PDF検索結果を取得しますか',
                'Download': 'ダウンロード',
            },
            'Search':{
                'StateFlag': 'ステータス',
                'UploadTime': 'アップロード日',
                'LastEdited': '最終編集日',
                'DeleteFlag': '削除フラグ',
                'Search': '検索',
                'Tenant': 'テナント',
            }
        },
        'replacement': {
            'Edit': {
                'OcrText': 'OCR テキスト',
                'Replacement': '置換 テキスト',
                'Edit Replacement': '置換を編集',
                'Add Replacement': '置換を追加',
            },
            'Index': {
                'Edit': '編集',
                'Delete': '削除',
                'Are you sure to do this?': 'これを実行してもよろしいですか?',
                'Confirm': '確認',
                'Cancel': 'キャンセル',
                'ID': 'ID',
                'OcrText': 'OCR テキスト',
                'Replacement': '置換 テキスト',
                'Scope': '利用範囲',
                'Easyocr': 'easyocr',
                'FinancialStatement': '決算書',
                'Operation': '操作',
                'Filtering': '検索',
                'Do you want to get replacement?': '置換リストを取得しますか',
                'Download': 'ダウンロード',
            },
            'ImportReplacement': {
                'Import replacement': '置換リストをインポート',
                'Select CSV files': 'CSVファイルを選択',
                'Import': 'インポート',
            },
            'Search': {
                'TenantsName': 'テナント名',
                'Search': '検索',
                'Add': '追加',
            }
        },
    },
    '_train': {
        'ProjectTable': {
            'Overview': '概要',
            'Edit': '編集',
            'ID': 'ID',
            'Project': 'プロジェクト',
            'Operation': '操作',            
        },
        'TrainAdd': {
            'Save': '保存',
            'Cancel': 'キャンセル',            
        },
        'TrainTable': {
            'Train': 'トレーン',
            'Edit': '編集',
            'Person': '担当',
            'Delete': '削除',
            'Detail': '詳細',
            'OK': 'はい',
            'Cancel': 'キャンセル',
            'ID': 'ID',
            'Name': '名前',
            'Operator': 'オペレーター',
            'Module': 'モジュール',
            'Status': 'ステータス',
            'Label': 'ラベル',
            'Auto': '自動',
            'Operation': '操作',
            'Description': '説明'
        },
        'description': {
            'index': {
                'Cancel': 'キャンセル',
            },
        },
        'detail': {
            'Prepareing': {
                'OK': 'はい',
                'Cancel': 'キャンセル',                
            },
            'TrainAndEvaluate': {
                'OK': 'はい',
                'Cancel': 'キャンセル',                
            },
        },
    },
    '_user': {
        'UserApp': {
            'User': 'ユーザー',
            'Role': '役割',            
        },
        'permission': {
            'Permission': {
                'Are you sure to do this?': 'これを実行してもよろしいですか',
                'Delete': '削除',
                'Cancel': 'キャンセル',                
            },
        },
        'role': {
            'Role': {
                'Edit': '編集',
                'Delete': '削除',
                'Are you sure to do this?': 'この役割を削除してもよろしいですか？',
                'Cancel': 'キャンセル',
                'Num': 'No.',
                'Name': '役割名',
                'Permission': '権限',
                'Operation': '操作',
                'Delete this role': '役割を削除',
                'Edit Role': '役割を編集',
                'Add Role': '役割を追加',
                'Policy': 'Policy',
            },
        },
        'user': {
            'ImportUser': {
                'Import user': 'ユーザーを一括追加（CSVインポート）',
                'Select CSV files': 'CSVファイルを選択',
                'Import': 'インポートして登録する',
            },
            'User': {
                'Edit': '編集',
                'Reset password': 'パスワード再設定',
                'Reset access key': 'アクセスキーをリセット',
                'Delete': '削除',
                'Are you sure to do this?': 'これを実行してもよろしいですか',
                'Are you sure to reset password?': 'このユーザーにパスワード再設定用のURLが記載されたメールを送信します。URLの有効期限は送信から10分間です。',
                'Cancel': 'キャンセル',
                'Num': 'No.',
                'Name': 'ユーザー名',
                'E-mail': 'メールアドレス',
                'Status': 'ステータス',
                'Password': 'パスワード',
                'Access Key': 'アクセスキー',
                'Role': '役割',
                'Operation': '操作',
                'Reset': '送信',
                'Reset success': '再設定成功しました',
                'EmailSuccessed': 'ユーザーにパスワード再設定用のメールを送信しました。',
                'Do you want to get user': '登録済の全ユーザーのリスト（CSVファイル）をダンロードしますか？',
                'Account': 'Oktaユーザー名',
                'Edit User': 'ユーザーを編集',
                'Add User': 'ユーザーを追加',
                'Search': '検索',
                'PasswordReset': 'パスワード再設定',
                'NewPassword': '新しいパスワード',
                'RetypeNewPassword': '新しいパスワードを再入力',
                'Submit': '設定する',
                'NowPassword': '現在のパスワード',
                'Close': '閉じる',
                'Input password': 'パスワードを入力してください',
                'Input newPwd': '新しいパスワードを入力してください',
                'Input reNewPwd': '新しいパスワードを再入力してください',
                'ReNewPwd error': '新しいパスワードと再入力したパスワードが一致しません',
                'NewPwd error': 'パスワードポリシーの要件を満たしていません',
            },
        },
    },
    '_setting': {
        'Setting': {
            'TenantSetting': 'テナント設定',
            'Tenant': 'テナント', 
            'FsItemMapping': 'スクラムマッピング 設定',
            'ConvertSubtitle': '読替表設定',
            'PresetDIFF': 'PresetDIFF設定'
        },
        'tenantSetting': {
            'TenantSetting': {
                'ID': 'ID',
                'Category': 'カテゴリー',
                'SubCategory': 'サブカテゴリー',
                'Name': '名前',
                'SubName': 'サブ名前',
                'Version': 'バージョン',
                'SubVersion': 'サブバージョン',
                'Value': '値',
                'Priority': '優先',
                'Operation': '操作',
                'Edit': '編集',
                'Delete this record': 'このレコードを削除',
                'Are you sure to do this?': 'これを実行してもよろしいですか',
                'Delete': '削除',
                'Cancel': 'キャンセル',
                'Edit TenantSetting': 'テナント設定を編集',
                'Add TenantSetting': 'テナント設定を追加',
                'Do you want to get tenantSetting?': 'テナント設定を取得しますか',
                'Download': 'ダウンロード',
            },
            'ImportTenantSetting': {
                'Import tenantSetting': 'テナント設定をインポート',
                'Select CSV files': 'CSVファイルを選択',
                'Import': 'インポート',
            },
        },
        'tenant': {
            'Edit': {
                'Confirm': '確認',
                'Cancel': 'キャンセル',
                'TenantEdit': 'テナント 編集',
                'TenantsName': 'テナント名',
                'TenantsMail': 'テナントメール',
                'Address': '住所',
                'Phone': '電話番号',
                'Save': '保存',
                'Do you want to save?': '保存しますか',
                'OK': 'はい'
            },
        },
        'presetDIFF': {
            'Table': {
                'Placeholder': 'Fs Itemを入力してください',
                'LinkTo': 'マッピング',
                'Are you sure to do this?': 'これを実行してもよろしいですか',
                'OK': 'はい',
                'Cancel': 'キャンセル',
            },
        },
    },
}