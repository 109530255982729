import React, { Component } from 'react'
import { Table, Checkbox } from 'semantic-ui-react'
import FsMappingItem from './FsMappingItem'
import { AutoSizer, List } from 'react-virtualized'
import { COLUMN_TYPE } from '../../Constant'
import { genId } from '../../../label/label-app/utils'
import { DetailTableHeader } from './FsConstant'

function columnStyles(idx, length) {
  if (idx === 0) {
    return { width: '10%', wordBreak: "break-word" }
  } else {
    const width = 90 / length
    return { width: `${width}%`, wordBreak: "break-word" }
  }
}
export default class FsTextTable extends Component {
  constructor(props) {
    super(props)
    this.state = { isLoading: false, }
  }

  // currentBoxIdForCss is boxID selected in pdf panel 
  getClass = (type, { rowId, cellId }, selectedCell, currentBoxIdForCss = "", column) => {
    let className = ''
    switch (type) {
      case 'cell':
        if (rowId === selectedCell.id && selectedCell.cellIds.indexOf(cellId) !== -1 && column === selectedCell.column) {
          className = 'old-selected'
        }
        if (currentBoxIdForCss && cellId === currentBoxIdForCss) {
          className += " old-high-light"
        }
        break
      default:
        className = ''
    }
    return className
  }

  renderHeader = () => {
    const {
      rows,
      onAllCheck,
      allCheck,
    } = this.props
    const hd = < Table.Header >
      <Table.Row style={{ display: 'flex', background: '#f9fafb' }}>
        <Table.HeaderCell style={columnStyles(0, DetailTableHeader.length)}>
          <Checkbox disabled={rows.length === 0} checked={allCheck} onChange={(e, data) => onAllCheck(data.checked)} />
        </Table.HeaderCell>
        <Table.HeaderCell style={columnStyles(0, DetailTableHeader.length)} >
          ページ</Table.HeaderCell>
        {DetailTableHeader.map((col, idx) => {
          return (<Table.HeaderCell key={idx} style={columnStyles(1, DetailTableHeader.length)}>
            {col.header}</Table.HeaderCell>)
        })}
        {/* extra header cell to even out the width with a fake scrollbar */}
        <Table.HeaderCell
          style={{
            flex: '0 0 auto',
            opacity: 0,
            overflowY: 'scroll',
            padding: 0,
            border: 0,
          }}
        />
      </Table.Row>
    </Table.Header >
    return hd
  }

  renderBody = () => {
    const {
      rows,
      onMappingItemChange,
      onCheck,
      checkedList,
      onRowSelect,
      selectedCell,
      onNormalized,
      currentBoxIdForCss,
      category,
      onLinkBbox2Cell,
      selectedBBox
    } = this.props
    let filterRows = [];
    for (let i = 0; i < rows.length; i++) {
      if (!rows[i].scrumItem || (rows[i].scrumItem && rows[i].scrumItem.indexOf('当期末残高(B/S入力)') === -1)) {
        filterRows.push(rows[i])
      }
    }
    const headerOrder = DetailTableHeader.map(c => {
      return { key: c.name, autoComplete: c.autoComplete, link: c.link }
    });
    return (<Table.Body style={{ height: '100%', flex: 1, outline: 0 }}>
      <AutoSizedList
        rowHeight={65}
        rowCount={filterRows.length}
        style={{ overflowY: 'scroll', outline: 'none' }}
        rowRenderer={({ index, style, key }) => (
          <Row
            key={key}
            style={style}
            row={filterRows[index]}
            onMappingItemChange={onMappingItemChange}
            onCheck={onCheck}
            checkedList={checkedList}
            onRowSelect={onRowSelect}
            selectedCell={selectedCell}
            onNormalized={onNormalized}
            currentBoxIdForCss={currentBoxIdForCss}
            getClass={this.getClass}
            category={category}
            headerOrder={headerOrder}
            onLinkBbox2Cell={onLinkBbox2Cell}
            selectedBBox={selectedBBox}
          />
        )}
        overscanRowCount={10}
      />
    </Table.Body>)
  }

  render() {

    return (
      <Table
        celled
        selectable
        style={{ display: 'flex', flexDirection: 'column', height: '85%' }}
      >
        {this.renderHeader()}
        {this.renderBody()}
      </Table>

    );
  }
}

const AutoSizedList = props => (
  <AutoSizer>
    {({ height, width }) => (<List height={height} width={width} {...props} />)}
  </AutoSizer >
);

function renderCell(key, cell, category, id, pdfImageId, onMappingItemChange,
  selectedCell, currentBoxIdForCss, getClass, onRowSelect, headerSetting, idx, row, onLinkBbox2Cell, selectedBBox) {
  const { autoComplete, link } = headerSetting

  const mismatchedCell = (<Table.Cell key={`${id}_${genId()}`} style={columnStyles(1, DetailTableHeader.length)}> </Table.Cell>)

  const column = DetailTableHeader.find(col => col.name === key)
  const type = column ? column.type : undefined
  switch (type) {
    case COLUMN_TYPE.SELECTBBOX:
      // selectBBox type column must be {id:xxx, text:xxx}
      if (cell.id === undefined || cell.text === undefined) {
        return mismatchedCell
      }
      var cellText = cell.text;
      if (key === 'value') {
        if (cell.invert) {
          if (!cellText || cellText[0] === '0') {
             // vString = vString;
           } else if (cellText[0] !== '-') {
            cellText = '-' + cellText;
           } else {
            cellText = cellText.substring(1, cellText.length);
           }
       }
      }
      return (
        <Table.Cell key={`${id}_${cell.id}_${idx}`}
          className={`old-td-selectable ${getClass('cell', { rowId: id, cellId: cell.id }, selectedCell, currentBoxIdForCss, key)}`}
          style={columnStyles(1, DetailTableHeader.length)}
          onClick={() => {
            if (selectedBBox.id === "" && selectedBBox.text === "") {
              onRowSelect(id, pdfImageId, [cell.id], key)
            } else {
              onLinkBbox2Cell(id, cell.id, key)
              onRowSelect(id, pdfImageId, [selectedBBox.id], key)
            }
          }}
        >
          {cellText}
        </Table.Cell>
      )
    case COLUMN_TYPE.INPUTTEXT:

      return (<Table.Cell key={`${id}_${key}`} style={columnStyles(1, DetailTableHeader.length)}>
        <FsMappingItem
          id={id}
          options={category}
          value={cell}
          link={link}
          onMappingItemChange={onMappingItemChange}
          category={key}
          type={type}
          row={row} />
      </Table.Cell>)
    case COLUMN_TYPE.SELECTION:
      // sel type column must be string
      if (typeof (cell) !== 'string') {
        return mismatchedCell
      }
      return (<Table.Cell style={columnStyles(1, DetailTableHeader.length)}>
        <FsMappingItem
          id={id}
          options={category[autoComplete] ? category[autoComplete] : []}
          value={cell}
          link={link}
          onMappingItemChange={onMappingItemChange}
          category={key}
          type={type} />
      </Table.Cell>)
    default:
      return (
        <Table.Cell
          style={columnStyles(1, DetailTableHeader.length)}
        >
        </Table.Cell>
      )
  }
}

const Row = ({ row, style, onMappingItemChange, onCheck, checkedList, onRowSelect, selectedCell, onNormalized,
  currentBoxIdForCss, getClass, category, headerOrder, onLinkBbox2Cell, selectedBBox }) => {
  const { id, pdfImageId } = row

  return (< Table.Row key={id} style={{ ...style, display: 'flex' }}>
    <Table.Cell style={columnStyles(0, DetailTableHeader.length)}>
      <Checkbox checked={checkedList.indexOf(id) !== -1} onChange={(e, data) => onCheck(id, data.checked)} />
    </Table.Cell>
    <Table.Cell
      style={columnStyles(0, DetailTableHeader.length)}
      className="old-page-num"
      onClick={() => {
        const cellIds = []
        Object.keys(row).forEach(key => {
          if (row[key].id !== undefined) {
            cellIds.push(row[key].id)
          }
        })
        onRowSelect(id, pdfImageId, cellIds, null)
      }}>{onNormalized(pdfImageId)}
    </Table.Cell>
    {headerOrder.map((header, idx) => {
      return renderCell(header.key, row[header.key], category, id, pdfImageId,
        onMappingItemChange, selectedCell, currentBoxIdForCss, getClass, onRowSelect, header, idx, row, onLinkBbox2Cell, selectedBBox)
    })}
  </Table.Row >)

};
