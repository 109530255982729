import React, { Component } from 'react';
import SystemFeedApp from './feedback/SystemFeedApp';
import { Loader, Message } from 'semantic-ui-react';
import DocumentMeta from 'react-document-meta';
import { Link } from 'react-router-dom';
import api from '../api';
import intl from 'react-intl-universal';
import { getCsrfToken } from '../common/CookieUtil';
import { FMT, unixMSToDatetime } from '../common/TimeUtil';

export default class SystemFeedLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: null,
      isLoaded: false,
      loading: false,
      error: null,
      imaUrl: null,
      message: null,
      systemInfoData: null,
      systemPredictResult: null,
      predictionMetaData: null,
      historyOptions: [],
      filterTypes: [],
      types: intl.get('_system.feedback.parsingTypes'),
    };

    this.rePredict = this.rePredict.bind(this)
    this.historyReload = this.historyReload.bind(this)
    this.handleCsvDownload = this.handleCsvDownload.bind(this)
    this.refetch = this.refetch.bind(this)
  }

  componentDidMount() {
    this.refetch();
    const { types } = this.state;
    this.setState({
      filterTypes: types.map((data, idx) => {
        return {
          text: Object.values(data)[0],
          key: `${idx}${Object.values(data)}`,
          value: idx + 1,
        };
      }),
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.imageId !== this.props.match.params.imageId) {
      this.refetch();
    }
  }

  async refetch() {
    const { match } = this.props;
    const { id } = match.params;
    try {
      let feedbackTableData;
      const data = await api.get(`/dppapi/system/get-report/${id}`);
      if (!data.data.predictResult) {
        this.setState({
          message: 'Sorry, get report fault',
          isLoaded: true,
        });
      } else {
        feedbackTableData = await api.get(
          `/dppapi/system/get-pdf-report/data/${id}`
        );
        const systemInfo = await api.get(
          `/dppapi/system/get-system-comment/${id}`
        );
        if (systemInfo) {
          this.setState({
            systemInfoData: systemInfo,
          });
        }
        const historyList = await api.get(
          `/dppapi/system/easyocr-history/list/${id}`
        );
        if (historyList.data.success) {
          this.setState({
            historyOptions: historyList.data.data.map((data) => {
              let meta = data.metaData && JSON.parse(data.metaData);
              return { 
                text: meta.created_at ? 'Model built at ' + meta.created_at.slice(0,10) : 'textpdf-parsing-result', 
                label: 'API Called at ' + unixMSToDatetime(data.createdAt,FMT.DATE), 
                key: data.id, 
                value: data.id }
            }),
          })
        }
      }
      const predictResult = JSON.parse(data.data.predictResult);
      var predictionMetaData = {};
      try{
        predictionMetaData = JSON.parse(data.data.metaData);
      }catch{};
      const { tenantId, fileName } = data.data;
      const imagePath = `${tenantId}/${fileName}`;
      const tempData = predictResult[0].bboxes.map(b => {
        return {
          id: b.id,
          type: 'bbox',
          points: [
            { lat: b.coordinate[1], lng: b.coordinate[0] },
            { lat: b.coordinate[3], lng: b.coordinate[2] },
          ],
          tracingOptions: { trace: [] },
          det_text: b.ocr,
        };
      });
      const newLableData = { lableId: tempData };
      const project = {
        form: {
          formParts: [{ id: 'lableId', name: 'Word', type: 'bbox' }],
        },
      };
      this.setState({
        isLoaded: true,
        project,
        imaUrl: imagePath,
        newLableData: newLableData,
        systemPredictResult: feedbackTableData,
        predictionMetaData: predictionMetaData,
      });
    } catch (error) {
      this.setState({
        message: 'Sorry, get report fault',
        isLoaded: true,
      });
    }
  }

  async rePredict(serverFlg) {
    const { match } = this.props;
    const { id } = match.params;
    this.setState({
      loading: true,
    });
    try {
      const data = await api.patch(
        `/dppapi/system/recall-easyocr/${id}`, { serverFlg: serverFlg}
      );
      if (!data.data.success) {
        this.setState({
          message: 'Sorry, get report fault',
          loading: false,
        });
      }
      const historyList = await api.get(
        `/dppapi/system/easyocr-history/list/${id}`
      );
      if (historyList.data.success) {
        this.setState({
          historyOptions: historyList.data.data.map((data) => {
            let meta = data.metaData && JSON.parse(data.metaData);
            return { 
              text: meta.created_at ? 'Model built at ' + meta.created_at.slice(0,10) : 'textpdf-parsing-result', 
              label: 'API Called at ' + unixMSToDatetime(data.createdAt,FMT.DATE), 
              key: data.id, 
              value: data.id }
          }),
        })
      }
      const predictResult = JSON.parse(data.data.predictResult);
      var predictionMetaData = {};
      try{
        predictionMetaData = JSON.parse(data.data.metaData);
      }catch{};
      const tempData = predictResult[0].bboxes.map(b=>{
        return {
          id: b.id,
          type: 'bbox',
          points: [{lat: b.coordinate[1], lng: b.coordinate[0]}, {lat:b.coordinate[3], lng: b.coordinate[2]}],
          tracingOptions: { trace: []},
          det_text: b.ocr,
        }
      });
      const newLableData = {'lableId': tempData};
      const project = {
        "form": {
            "formParts": [{ "id": "lableId", "name": "Word", "type": "bbox" }]
        },
      };
      this.setState({
        loading: false,
        project,
        newLableData: newLableData,
        systemPredictResult: data,
        predictionMetaData: predictionMetaData,
      });
    } catch (error) {
      this.setState({
        message: 'Sorry, get report fault',
        loading: false,
      });
    }
  }

  async historyReload(id) {
    this.setState({
      loading: true,
    });
    try {
      const data = await api.get(
        `/dppapi/system/easyocr-history/detail/${id}`
      );
      if (!data.data.success) {
        this.setState({
          message: 'Sorry, get history report fault',
          loading: false,
        });
      }
      const predictResult = JSON.parse(data.data.predictResult);
      var predictionMetaData = {};
      try{
        predictionMetaData = JSON.parse(data.data.metaData);
      }catch{};
      const tempData = predictResult[0].bboxes.map(b=>{
        return {
          id: b.id,
          type: 'bbox',
          points: [{lat: b.coordinate[1], lng: b.coordinate[0]}, {lat:b.coordinate[3], lng: b.coordinate[2]}],
          tracingOptions: { trace: []},
          det_text: b.ocr,
        }
      });
      const newLableData = {'lableId': tempData};
      const project = {
        "form": {
            "formParts": [{ "id": "lableId", "name": "Word", "type": "bbox" }]
        },
      };
      this.setState({
        loading: false,
        project,
        newLableData: newLableData,
        systemPredictResult: data,
        predictionMetaData: predictionMetaData,
      });
    } catch (error) {
      this.setState({
        message: 'Sorry, get report fault',
        loading: false,
      });
    }
  }

  handleCsvDownload() {
    const { match } = this.props;
    const { id } = match.params;
    let a = document.createElement('a');
    a.href = `/dppapi/system/feedback/csv/${id}?token=${getCsrfToken()}`;
    a.click();
    a.remove();
  }

  render() {
    const { 
      project, 
      isLoaded, 
      error, 
      imaUrl, 
      newLableData, 
      message, 
      systemInfoData, 
      systemPredictResult, 
      filterTypes, 
      historyOptions,
      predictionMetaData,
      loading 
    } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <Loader active inline="centered" />;
    }

    return (<div>
      { !message && <DocumentMeta>
        <SystemFeedApp
          labels={project.form.formParts}
          labelData={newLableData || {}}
          imageUrl={`/dppapi/system/feedback-image/${imaUrl}?token=${getCsrfToken()}`}
          systemInfoData={systemInfoData}
          systemPredictResult={systemPredictResult}
          filterTypes={filterTypes}
          historyOptions={historyOptions}
          predictionMetaData={predictionMetaData}
          onRePredict={this.rePredict}
          onHistoryReload={this.historyReload}
          onCsvDownload={this.handleCsvDownload}
          onRefetch={this.refetch}
          loading={loading}
        />
      </DocumentMeta>}
        { message && <Message warning style={{ maxWidth: 600, margin: '100px auto' }}>
          <Message.Header>{message}</Message.Header>
          <p>
            Redirect to your <Link to="/system">list</Link>.page.
          </p>
        </Message>}</div>
    );
  }
}
