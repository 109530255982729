export const colorMapping = {
  red: '#FF0000',
  orange: '#FFA500',
  cyan: '#00FFFF',
  lime: '#00FF00',
  magenta: '#FF00FF',
  indigo: '#4B0082',
  blue: '#0000FF',
  olive: '#808000',
  green: '#008000',
  violet: '#EE82EE',
  purple: '#800080',
  pink: '#FFC0CB',
  brown: '#A52A2A',
  grey: '#808080',
  teal: '#008080',
  black: '#000000',
  skyBlue: '#87CEEB',
  gold: '#FFD700',
  plum: '#DDA0DD',
  tan: '#D2B48C',
  pastelGreen: '#63D980',
  roman: '#d75b5c',
};

export function convertPoint(p) {
  return {
    lat: p.lat,
    lng: p.lng,
  };
}

export function lighten(col, amt) {
  let usePound = false;
  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }
  const num = parseInt(col, 16);
  let r = (num >> 16) + amt;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;
  let b = ((num >> 8) & 0x00ff) + amt;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;
  let g = (num & 0x0000ff) + amt;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;
  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

export function genId() {
  return (
    Math.random()
      .toString(36)
      .substring(2, 15) +
    Math.random()
      .toString(36)
      .substring(2, 15)
  );
}
export function genShortId() {
  return Math.random()
    .toString(36)
    .substr(2, 9)
}
export function genNegativeId() {
  const id = `${Math.floor(Math.random() * 10000)}${Math.floor(Math.random() * 10000)}`
  return -Number(id)
}

export const shortcuts = '1234567890qwertyuiop';
export const colors = [
  'red',
  'orange',
  'cyan',
  'lime',
  'magenta',
  'indigo',
  'blue',
  'olive',
  'green',
  'violet',
  'purple',
  'pink',
  'brown',
  'grey',
  'teal',
  'black',
  'skyBlue',
  'gold',
  'plum',
  'tan',
];
