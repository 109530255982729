import React, { Component } from 'react'
import { Table } from 'semantic-ui-react'
import OperatePopup from './OperatePopup'
import ZoomPopup from '../../../common/ZoomPopup/index'
import _ from 'lodash';
import { 
  SubjectName
 } from './FsConstant'
 import { SCRUM_ITEM_SUFFIX } from '../../Constant'
export default class ShareholdersEquityCommonTable extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      row: null,
      header: null,
    }
    this.multiAggValue1 = [];
    this.multiAggValue2=[];
    this.multiAggValue3=[];
    this.multiAggValue4=[];
    this.multiAggVal = {

    }
    this.popups = {};
    this.onPopupOpen = this.onPopupOpen.bind(this);
  }

  renderShareholdersEquityHeader = () => {
    const dom = this.props.ssData.Header.map((header, idx) => {
      return  <Table.Cell style={{ minWidth:'60px', width: 'auto',background: '#f9fafb' }} key ={idx}>{header}</Table.Cell>
    })
    return (<Table.Row>
      <Table.Cell style={{ width: "10%", background: '#f9fafb', position: 'sticky', left: '0' }} >{SubjectName}</Table.Cell>
      {dom}
    </Table.Row>)
  }


  renderShareholdersEquityRow = () => {
    const {rows, ssData, ssTitleSplit, isSSTopLeftInvert} = this.props;
    const { leftAgg, topAgg } = ssData;
    const shareholdersEquityRow =  _.cloneDeep(ssData.data);
    let tmp = [];
    shareholdersEquityRow.forEach((sr) => {
      var tmpRow = sr;
      rows.forEach((r) => {
        const scrumItem = r.scrumItem;  // 株主資本計(入力)/当期変動額合計(入力)(自動集計)
        const index = scrumItem.indexOf(ssTitleSplit)
        var scrumItem_x = isSSTopLeftInvert ? scrumItem.substring(index + 1) : scrumItem.substring(0, index); // 株主資本計(入力)
        var scrumItem_y = isSSTopLeftInvert ? scrumItem.substring(0, index) : scrumItem.substring(index + 1); // 当期変動額合計(入力)(自動集計)
        // when invert , 純資産合計(入力)/当期純利益(自動集計) => scrumItem_x:当期純利益 scrumItem_y:純資産合計(入力)(自動集計)
        if(isSSTopLeftInvert && topAgg.includes(scrumItem_y) && scrumItem_x.endsWith(SCRUM_ITEM_SUFFIX.AGGREGATION)){
          scrumItem_x = scrumItem_x.replace(SCRUM_ITEM_SUFFIX.AGGREGATION,'')
          scrumItem_y = scrumItem_y + SCRUM_ITEM_SUFFIX.AGGREGATION
        }
        if (scrumItem_y.startsWith(sr[SubjectName])) {
          if(isSSTopLeftInvert){
            if (scrumItem_x.endsWith(SCRUM_ITEM_SUFFIX.AGGREGATION)&&!tmpRow[scrumItem_x]) {
              // 純資産合計(入力)(自動集計)=> 純資産合計(自動集計)
              var temp_x = scrumItem_x.replace(scrumItem_x.substring(scrumItem_x.indexOf('('),scrumItem_x.length-SCRUM_ITEM_SUFFIX.AGGREGATION.length),'')
              if (!tmpRow[temp_x]) temp_x = temp_x.replace(SCRUM_ITEM_SUFFIX.AGGREGATION,'')
              if (r.id) tmpRow[temp_x].id = r.id;
              if (r.values) tmpRow[temp_x].values = r.values;
              if (r.scrumItem) tmpRow[temp_x].scrumItem = r.scrumItem;
              if (r.manualInput) tmpRow[temp_x].manualInput = r.manualInput;
            }else{
              if (r.id) tmpRow[scrumItem_x].id = r.id;
              if (r.values) tmpRow[scrumItem_x].values = r.values;
              if (r.scrumItem) tmpRow[scrumItem_x].scrumItem = r.scrumItem;
              if (r.manualInput) tmpRow[scrumItem_x].manualInput = r.manualInput;
            }

          }else{
            if (scrumItem_y.endsWith(SCRUM_ITEM_SUFFIX.AGGREGATION)) {
              var tmp_x = scrumItem_x.replace(/\(入力\)/gi, SCRUM_ITEM_SUFFIX.AGGREGATION);
              if (r.id) tmpRow[tmp_x].id = r.id;
              if (r.values) tmpRow[tmp_x].values = r.values;
            } else {
              if (r.id) tmpRow[scrumItem_x].id = r.id;
              if (r.values) tmpRow[scrumItem_x].values = r.values;
            }
          }
          if(leftAgg.indexOf(scrumItem_x)> -1 && scrumItem_y.endsWith(SCRUM_ITEM_SUFFIX.AGGREGATION) && topAgg.indexOf(scrumItem_y.substring(0,scrumItem_y.indexOf(SCRUM_ITEM_SUFFIX.AGGREGATION))) > -1){
            this.multiAggVal[scrumItem] = r.values
            const sub = scrumItem_y.substring(0,scrumItem_y.indexOf(SCRUM_ITEM_SUFFIX.AGGREGATION))
            var var_temp_x = scrumItem_x.replace(/\(入力\)/gi, SCRUM_ITEM_SUFFIX.AGGREGATION);
            this.multiAggVal[isSSTopLeftInvert ? sub+ssTitleSplit+var_temp_x : var_temp_x+ssTitleSplit+sub] = r.values
          }
          if (r.manualInput || r.manualInput === "") tmpRow[scrumItem_x].manualInput = r.manualInput;
        }
      })
      tmp.push(tmpRow)
    })

    let cellDom = ""
    const dom = tmp.map((r, r_idx) => {
      return <Table.Row id={r[SubjectName]} key={r_idx}>
       {/* <Table.Cell style={{ background: '#f9fafb', position: 'sticky', left: '0', zIndex: '1' }}>{r[SubjectName].replace(/当期末残高\(B\/S入力\)\(自動集計\)/gi, "当期末残高(自動集計)")}</Table.Cell> */}
       <Table.Cell style={{ background: '#f9fafb', position: 'sticky', left: '0', zIndex: '1' }}>{r[SubjectName]}</Table.Cell>
        {this.props.ssData.Header.map((h, h_idx) => {
          cellDom = this.handleDistinguishCell(cellDom, r, r_idx, h, h_idx);
          return cellDom;
         })}
      </Table.Row >
    })
    return dom;
  }

  onPopupOpen() {
    Object.values(this.popups).forEach(popup => {
      popup && popup.state.show && popup.handleShow(false)
    })
  }

  handleDistinguishCell = (cellDom, r, r_idx, h, h_idx) => {
    const { onGetSum, handleCellOperation, handleCheck, selectedCell, currentBoxIdForCss, getCellIds, detailData, currentPageType, isLink, ssTitleSplit,
      isSSTopLeftInvert, } = this.props;
      const scrumItem = isSSTopLeftInvert ? r[SubjectName] + ssTitleSplit + h : h + ssTitleSplit + r[SubjectName];
    const checkResult = handleCheck(scrumItem);
    let { err, errItem } = checkResult;
    let { manualInput, values } = r[h];
    let itemName = isSSTopLeftInvert ? r[SubjectName] + ssTitleSplit + h : h + ssTitleSplit + r[SubjectName];
    if(this.multiAggVal[itemName]){
      values = this.multiAggVal[itemName]
    }

    const key = r_idx+','+h_idx;
    var scrumExist = false;
    if (r[h].id) scrumExist = true;
    if (r[h].isAggreation) {
      const originAggreScrum = this.props.ssData.Header[h_idx-1];
      const leftAgg = this.props.ssData.leftAgg
      if(originAggreScrum && leftAgg.indexOf(originAggreScrum)>-1 && r[originAggreScrum] && (!r[originAggreScrum].id || r[originAggreScrum].disabled)){
        cellDom = (
          <Table.Cell id={scrumItem} key={key} className={`old-scrumNotExist-td`}></Table.Cell>
        )
      } else{
        cellDom = err ? (
          // eslint-disable-next-line
          !r[h].noPop ? 
          <ZoomPopup
            key={scrumItem}
            ref={(ref) => {
              this.popups[scrumItem] = ref;
            }}
            isLink={isLink}
            onOpen={this.onPopupOpen}
            style={{ zoom: '75%' }}
            content={errItem.formula.replace(/#|\{|\}/g, '')}
            trigger={
              <Table.Cell id={scrumItem} className={`old-err-row`} key={key}>
                <span>{onGetSum(values)}</span>
              </Table.Cell>
            }
          /> : 
          <Table.Cell id={scrumItem} className={`old-err-row`} key={key}>
          <span>{onGetSum(values)}</span>
        </Table.Cell>
        ) : (
          <Table.Cell id={scrumItem} key={key} className={`old-aggregation-row`}>
            {values && onGetSum(values)}
          </Table.Cell>
        );
      }
    } else if (manualInput === undefined) {
      if (scrumExist && !r[h].disabled) {
        this.popups[scrumItem] = React.createRef();

        cellDom = (
          <ZoomPopup
            key={scrumItem}
            ref={(ref) => {
              this.popups[scrumItem] = ref;
            }}
            isLink={isLink}
            onOpen={this.onPopupOpen}
            content={
              <OperatePopup
                row={r}
                header={h}
                rows={this.props.rows}
                onTabChange={this.props.onTabChange}
                setInvertFlag={this.props.setInvertFlag}
                onEdit={this.props.onEdit}
                onInputModeChange={this.props.onInputModeChange}
                onInputChange={this.props.onInputChange}
                onGetSum={this.props.onGetSum}
                ssTitleSplit={ssTitleSplit}
                isSSTopLeftInvert={isSSTopLeftInvert}
              />
            }
            trigger={
              <Table.Cell
                id={scrumItem}
                key={key}
                className={`${this.getClass(
                  { rowId: r[h].id, cellIds: getCellIds(values) },
                  selectedCell,
                  currentBoxIdForCss,
                  'value',
                  err,
                  manualInput
                )}`}
                onClick={() =>
                  handleCellOperation({
                    id: r[h].id,
                    scrumItem: scrumItem,
                    values: values,
                  })
                }
              >
                {onGetSum(values)}
              </Table.Cell>
            }
          ></ZoomPopup>
        );
      } else {
        cellDom = (
          <Table.Cell id={scrumItem} key={key} className={`old-scrumNotExist-td`}></Table.Cell>
        )
      }
    } else {
      cellDom = (
        <ZoomPopup
          key={scrumItem}
          ref={(ref) => {
            this.popups[scrumItem] = ref;
          }}
          isLink={isLink}
          onOpen={this.onPopupOpen}
          content={
            <OperatePopup
              row={r}
              header={h}
              rows={this.props.rows}
              onTabChange={this.props.onTabChange}
              setInvertFlag={this.props.setInvertFlag}
              onEdit={this.props.onEdit}
              onInputModeChange={this.props.onInputModeChange}
              onInputChange={this.props.onInputChange}
              onGetSum={this.props.onGetSum}
              ssTitleSplit={ssTitleSplit}
              isSSTopLeftInvert={isSSTopLeftInvert}
            />
          }
          trigger={
            <Table.Cell id={scrumItem} key={key} className={`${this.getClass(
              { rowId: r[h].id, cellIds: getCellIds(values) },
              selectedCell,
              currentBoxIdForCss,
              'value',
              err,
              manualInput
            )}`}
            >
              <div style={{border:'solid 1px #88bafb', paddingLeft: '2px'}}>{detailData['manual_input'][currentPageType][scrumItem] || '0'}</div>
            </Table.Cell>
          }
        ></ZoomPopup>
      );
    }
    return cellDom;
  }

  getClass = ({ rowId, cellIds }, selectedCell, currentBoxIdForCss, column, err, manualInput) => {
    let className = ''
    if (rowId === selectedCell.id && column === selectedCell.column) {
      className = 'old-selected'
    }
    if (currentBoxIdForCss && cellIds.indexOf(currentBoxIdForCss) !== -1 && manualInput === undefined) {
      className = 'old-high-light'
    }
    if (err) {
      className += ' old-err-row'
    }
    return className
  }

  render() {
    return (
      <Table celled>
        <Table.Body>
          { this.renderShareholdersEquityHeader() }
          { this.renderShareholdersEquityRow() }
        </Table.Body>
      </Table>
  )
  }
}