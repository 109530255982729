import React, { Component } from 'react';
import {
  Header,
  Form,
  Input,
  Button,
} from 'semantic-ui-react';
import api from '../api'
import intl from 'react-intl-universal';
import {
  withRouter
} from 'react-router-dom';
import { ErrorIcon} from '../assets/icon';
import Hotkeys from 'react-hot-keys';

class TenantIdConfirmPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      errors: {},
      email: '',
      tenantCheck: false,
    };
    this.handleSendEmail = this.handleSendEmail.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
  }


  async componentDidMount() {
    this.setState({
      formData:{tenants_mail:''}
    })
  }

  async handleSendEmail(e) {
    const { formData } = this.state;
    if(this.checkMail(formData)) return;
    let result = (await api.post('/dppapi/tenant-confirm', {
      email: formData.tenants_mail,
    })).data;
    if(result.success){
      window.location = `/dppui/login?confirmFlag=1`;
    }else{
      this.setState({ tenantCheck: true });
      return;
    }
    
  }


  checkMail(form){
    const errors = {};
    //eslint-disable-next-line 
    const emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (!emailReg.test(form.tenants_mail)) {
      errors['tenants_mail'] = {content: intl.get('_login.TenantIdConfirmPage.Tenants mail error'), pointing: 'below', flag: true};
    }
    this.setState({
      errors: errors,
      tenantCheck: false
    });
    return Object.keys(errors).length !== 0;
  }


  handleFocus(event) {
    const { errors } = this.state;
    const {name} = event.target;
    delete errors[name];
    this.clearErrors(errors);
  }

  clearErrors(errors) {
    this.setState({
      errors
    });
  }

  handleInput(e) {
    const {name, value} = e.target;
    const formData = {...this.state.formData};
    formData[name] = value;
    this.setState({
      formData
    });
  }
  onKeyDown(keyName, e) {
    if(keyName === 'tab'){
      e.preventDefault();
      const currentTagName = document.activeElement.tagName.toLowerCase();
      const parentElement = document.getElementsByClassName("login-form-confirm");
      let inputElement = parentElement && parentElement[0].querySelectorAll("input");
      let buttonElement = parentElement && parentElement[0].querySelectorAll("button");
      let loginElement = parentElement && parentElement[0].querySelectorAll("p");
      if(currentTagName === "body"){
        inputElement[0].focus()
      }else if(currentTagName === "input"){
        buttonElement[0].focus()
      }else if(currentTagName === "button"){
        loginElement[2].focus()
      }else if(currentTagName === "p"){
        inputElement[0].focus()
      }
    }
  }

  render() {
    const { 
      formData,
      errors,
      tenantCheck,
    } = this.state;
      if(document.getElementsByClassName('login-container-confirm').length > 0){
        document.getElementsByClassName('login-container-confirm')[0].parentNode.classList.remove('container')
      }
      var confirmErrorClass = errors['tenants_mail'] ? "input-error" : ""
      return (
        <div className="login-container-confirm">
          <Hotkeys
            keyName="tab"
            onKeyDown={this.onKeyDown.bind(this)}
          />
          <Form className="login-form-confirm" 
            // onKeyDown={key => 
            //   this.onKeyDown(key)
            // }
            >
            <Header className="confirm-from-header">
            {intl.get('_login.TenantIdConfirmPage.TenantIdConfirm')}
            </Header>
            <p className="confirm-from-text">
            {intl.get('_login.TenantIdConfirmPage.Tips')}
            </p>
            <div className="confirm-form-input-div">
              <label className="confirm-form-input-label">{intl.get('_login.TenantIdConfirmPage.TenantsMail')}</label>
              <Form.Field style={{ width: '100%', display: 'inline-block', margin: 'unset' }}>
                <Input
                  type="text"
                  onChange={this.handleInput}
                  onFocus={this.handleFocus}
                  defaultValue={formData.tenants_mail}
                  name="tenants_mail"
                  tabIndex={0}
                  className={`${confirmErrorClass}`}
                />
              </Form.Field>
              <p className="confirm-form-single-error">{errors['tenants_mail'] ? errors['tenants_mail'].content:null}</p>
            </div>
            <div>
              <div className="confirm-form-button">
                <Button fluid
                  tabIndex={0}
                  className="login-btn primary-btn confirm-form-button-margin"
                  onClick={this.handleSendEmail}>{intl.get('_login.TenantIdConfirmPage.Send')}</Button>
              </div>
              {tenantCheck && <div className="confirm_icon_div">
                <ErrorIcon size="24px" color="#F26868" className="repredict-icon-cursor"/>
                <label htmlFor='error' className="reset-password-error-lable">
                    <span className='error-text'>{intl.get('_login.TenantIdConfirmPage.Error')}</span>
                </label>
              </div>}
            </div>
            <div className="login-footer-form-link">
              <p className="footer-form-text" 
                tabIndex={0}
                onKeyDown={(e) => {
                  if(e.keyCode === 13){
                    window.location = `/dppui/login`
                  }}}
                onClick={() => { window.location = `/dppui/login` }}>
                {intl.get('_login.TenantIdConfirmPage.Go to login')}
              </p>
            </div>
          </Form>
        </div>
    );
  }
}

export default withRouter(TenantIdConfirmPage);