import React, { Component } from 'react';
import { Context } from './Context';
import intl from 'react-intl-universal';
import { Icon, Select, Popup } from 'semantic-ui-react';
import { maxRangeZoom, minRangeZoom } from './CalcBoundsHOC';
import './MapHeader.css';
// import Toasts from '../../../common/Toasts';
import { confirmAlert } from 'react-confirm-alert';
import { RotateIcon, ScanIcon } from '../../../assets/icon';
import PropTypes from 'prop-types';
import { AnalyticsServiceContext } from '../../../common/Analytics/AnalyticsServiceProvider';
import { EventName, EventType, PageName } from '../../../common/Analytics/analyticsMetrics';

class MapHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      selected: { value: null },
      loading: false,
      valueOption: {},
      toastOpen: false,
      disabledPagetype: ["その他","決算報告書表紙","利益処分書","個別注記表","２種混合型"],
      usuallyPagetype: ["貸借対照表", "損益計算書", "製造原価明細表", "販売費及び一般管理費","株主資本等変動計算書","連結貸借対照表","連結損益計算書","連結株主資本等変動計算書"],
      disabledFlag: false,
    };
  }
  static contextType = AnalyticsServiceContext;

  componentDidMount() {
    this.getOptions();
    this.getValue();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { thumbnailRef } = this.props;

    const valueSelected = this.state.selected
      ? this.state.selected.value
      : null;
    const preValueSelected = prevState.selected
      ? prevState.selected.value
      : null;

      if (valueSelected !== preValueSelected) {
      const disabledFlag = this.state.usuallyPagetype.find( v =>v === valueSelected)
      this.setState({
        valueOption: {
          ...this.state.valueOption,
          pageType: valueSelected,
        },
        disabledFlag: disabledFlag?false:true
      });
    }

    if (thumbnailRef && thumbnailRef.current) {
      const currentOptions = thumbnailRef.current.state.options;
      const currentValue = thumbnailRef.current.state.pageClassification;

      const preRef = prevProps.thumbnailRef ? prevProps.thumbnailRef : null;
      const preRefCurrent = preRef ? preRef.current : null;

      const preOptions = preRefCurrent ? preRefCurrent.state.options : null;
      const optionsDef = currentOptions !== preOptions;

      const preValue = preRefCurrent
        ? preRefCurrent.state.pageClassification
        : null;
      const valueDef = currentValue !== preValue;

      const valSelected = preValueSelected !== valueSelected;

      if (!preRef || !preRefCurrent) {
        this.getOptions();
      } else {
        if (!preOptions || optionsDef) {
          this.getOptions();
        }
        if ((!preValue && !valSelected) || (valueDef && valSelected)) {
          this.getValue();
        }
      }

      if(!this.state.valueOption.pageType && currentValue && currentValue.pageType){
        this.getValue();
      }
    }
  }

  getOptions = () => {
    const { thumbnailRef } = this.props;
    if (thumbnailRef && thumbnailRef.current) {
      const options = thumbnailRef.current.state.options.filter(opt =>
        ["貸借対照表", "損益計算書", "製造原価明細表", "販売費及び一般管理費","株主資本等変動計算書","その他"].indexOf(opt.value) !== -1
      )
      this.setState({ options: options });
    }
  };

  getValue = () => {
    const { thumbnailRef } = this.props;
    if (thumbnailRef && thumbnailRef.current) {
      const disabledFlag = this.state.usuallyPagetype.find( v =>v === thumbnailRef.current.state.pageClassification.pageType)
      this.setState({
        valueOption: thumbnailRef.current.state.pageClassification,
        disabledFlag: disabledFlag?false:true
      });
    }
  };

  changeSelectedValue = (pageType) => {
    this.setState({
      selected: {
        value: pageType
      },
    });
  }

  handleChangeSelect = async () => {
    const {
      textMappingRef,
      thumbnailRef,
      handleChangeTitleLoading,
    } = this.props;
    const { selected } = this.state;

    if (textMappingRef.current && selected && thumbnailRef.current) {
      handleChangeTitleLoading(true);
      await thumbnailRef.current.handleChange(
        this.state.valueOption.pageId,
        selected.value
      );
      await textMappingRef.current.handlePageTypeChange(selected.value, true);
      this.setState({ toastOpen: false });
      handleChangeTitleLoading(false);
    }
  };

  confirmLoad = () => {
    confirmAlert({
      title: intl.get('_predict.detail.pdf.Canvas.Re-do AI scan'),
      message: intl.get(
        '_predict.detail.pdf.Canvas.Would you like to re-analyze this document with AI?'
      ),
      buttons: [
        {
          label: intl.get('_predict.detail.pdf.Canvas.Reanalyze'),
          onClick: () => this.handleChangeSelect(),
        },
        {
          label: intl.get('_predict.detail.pdf.Canvas.Cancel'),
        },
      ],
      zIndex: 500,
    });
  };

  // zoomIn = (e, map) => {
  //   e.preventDefault();
  //   map.setZoom(map.getZoom() + 1);
  // };
  //
  // zoomOut = (e, map) => {
  //   e.preventDefault();
  //   map.setZoom(map.getZoom() - 1);
  // };

  // handleClickMinRangeZoomButton = (map) => {
  //   map.setZoom(minRangeZoom);
  //   this.context.sendPredictBeautifyDetailMetrics({
  //     event_type: EventType.Action,
  //     event_name: EventName.SetMinRange,
  //   })
  // }

  handleClickMaxRangeZoomButton = (map) => {
    map.setZoom(maxRangeZoom);
    this.context.sendPredictBeautifyDetailMetrics({
      event_type: EventType.Action,
      event_name: EventName.SetMaxRange,
    })
  }

  handleHeaderPageTypeChange = (e, change) => {
    const { thumbnailRef, textMappingRef, handelShowPredictLoading } = this.props;
    const { usuallyPagetype } = this.state
    var existFlag = false;
    const { detailData } = textMappingRef.current.state;
    const imageId = thumbnailRef.current.props.currentPage;
    for (let i = 0; i < Object.keys(detailData).length; i++) {
      const scrumSubTitle = Object.keys(detailData)[i]
      if (scrumSubTitle !== 'manual_input') {
        const existPredict = detailData[scrumSubTitle].find(d=>d.pdfImageId&&d.pdfImageId.toString() === imageId.toString() && d.scrumItem)
        if(existPredict){
          existFlag = true;
        }
      }
    }
      if(existFlag && usuallyPagetype.find(v=> v===thumbnailRef.current.state.pageClassification.pageType) && change.value === "その他"){
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className='pageType-change-remind-popup'>
                <div><span className='pageType-change-remind-title'>分類を「その他」に変更</span></div>
                <div className='pageType-change-remind-body'>
                  <span>実行するとこのページの連携がすべて解除されます。</span>
                  <span>よろしいですか？</span>
                </div>
                <div className='custom-ui-confirm-alert-button-group'style={{ width : '100%' }}>
                  <button
                    className='primary-btn confirmAlert-button-right'
                    onClick={ async() => {
                      this.setState({ selected: change });
                      this.setState({ toastOpen: true });
                      await this.props.handleUnuseImage();
                      if(textMappingRef.current && thumbnailRef.current){
                        onClose();
                        handelShowPredictLoading("分類を変更しています","しばらくお待ちください");
                        const deleteImg = [{pageId:thumbnailRef.current.props.currentPage}]
                        await textMappingRef.current.handleDeleteImagePredict(deleteImg,change.value)
                        handelShowPredictLoading();
                      }
                    }}>
                    実行
                  </button>
                  <button
                    className='predict-admin-botton-text confirmAlert-button-left'
                    onClick={onClose}>
                    {intl.get('_predict.detail.pdf.Canvas.Cancel')}
                  </button>
                </div>
              </div>
            );
          }
        });
      }else if(usuallyPagetype.find(v=>v===change.value)){
        this.props.toggleRePredict(true, change.value);
      }else{
      this.setState({ selected: change });
      this.setState({ toastOpen: true });

      thumbnailRef &&
      thumbnailRef.current &&
      thumbnailRef.current.handleChange(thumbnailRef.current.props.currentPage, change.value)
      this.props.textMappingRef &&
      this.props.textMappingRef.current &&
      this.props.textMappingRef.current.handlePageTypeChange(change.value, true)
    }
  }

  render() {
    const {
      mapRef,
      isEditing,
      rotateDialog,
      height,
      width,
      // addPdfToImageDialog,
      // clickFlag,
      zoom,
      zoomDefault,
      isRpaOpen,
      pdfId,
      projectId,
      isNotOwner,
      onShowExclusionControl,
    } = this.props;
    const { options, valueOption, disabledFlag } = this.state;
    // const thumbValue =
    //   thumbnailRef &&
    //   thumbnailRef.current &&
    //   thumbnailRef.current.state &&
    //   thumbnailRef.current.state.pageClassification
    //     ? thumbnailRef.current.state.pageClassification.pageType
    //     : null;
    // const isDisable = selected.value === thumbValue;
    const currentZoom = zoomDefault ? zoomDefault : zoom;
    return (
      <Context.Consumer>
        {({ map }) => (
          <div className="map-header-wrapper">
            {!isRpaOpen?(
              <div className="select-map-page" onClick={(e) => {
                if (isNotOwner && e.target.tagName.toLowerCase() !== 'span') {
                  onShowExclusionControl(true);
                }
              }}>
                <span>{intl.get('_predict.detail.pdf.Canvas.Kinds')}</span>
                <Select
                  disabled={isNotOwner}
                  selectOnBlur={false}
                  placeholder={valueOption.pageType}
                  options={options}
                  value={valueOption.pageType}
                  fluid
                  selection
                  className="pdf-thumbnail-root-img-select select-pagetype"
                  onChange={this.handleHeaderPageTypeChange}
                />
                {(disabledFlag || isEditing || isNotOwner) ? (
                  <Popup
                  disabled={isNotOwner}
                  trigger={
                    <div
                  className={`map-header-icon-btn map-header-icon-text text-disable-color`}
                >
                  <ScanIcon disabled />
                  {intl.get('_predict.detail.pdf.Canvas.Reanalysis')}
                </div>
                  }
                  position="bottom center"
                  content={disabledFlag?"この機能は次の分類ページで利用できます: 貸借対照表、損益計算書、製造原価明細表、販売費及び一般管理費、株主資本等変動計算書":"「保存」後に再読取できます"}
                  on="hover"
                  hideOnScroll
                  inverted
                />
                ):(
                  <div
                  className={`map-header-icon-btn map-header-icon-text`}
                  onClick={() => {
                    this.props.toggleRePredict(true)

                    // user action
                    this.context.sendMetrics({
                      page_name: PageName.PredictDetailPage,
                      event_type: EventType.Click,
                      event_name: EventName.Reread,
                      custom_parameter: {
                        pdf_id : pdfId,
                        project_id : projectId,
                      },
                    })

                  }}
                >
                  <ScanIcon disabled={disabledFlag} />
                  {intl.get('_predict.detail.pdf.Canvas.Reanalysis')}
                </div>
                )}
              </div>
            ):(<div className="select-map-page"></div>)}
            {/* <div className="select-map-page">
              <span>{intl.get('_predict.detail.pdf.Canvas.Kinds')}</span>
              <Select
                options={options}
                value={valueOption.pageType}
                fluid
                search
                selection
                className="pdf-thumbnail-root-img-select select-pagetype"
                onChange={(e, change) => {
                  this.setState({ selected: change });
                  this.setState({ toastOpen: true });

                  thumbnailRef &&
                  thumbnailRef.current &&
                  thumbnailRef.current.handleChange(thumbnailRef.current.props.currentPage, change.value)
                  this.props.textMappingRef &&
                  this.props.textMappingRef.current &&
                  this.props.textMappingRef.current.handlePageTypeChange(change.value, true)
                }}
              />
               {isEditing?(
                 <Popup
                 trigger={
                  <div
                 className={`map-header-icon-btn map-header-icon-text text-disable-color`}
               >
                 <ScanIcon disabled={isEditing} />
                 {intl.get('_predict.detail.pdf.Canvas.Reanalysis')}
               </div>
                 }
                 position="bottom center"
                 content={intl.get('_predict.detail.pdf.Canvas.No Reanalysis')}
                 on="hover"
                 hideOnScroll
                 inverted
               />
               ):(
                <div
                className={`map-header-icon-btn map-header-icon-text`}
                onClick={() => {
                  this.props.predictDialog(this.state.valueOption.pageType)
                }}
              >
                <ScanIcon disabled={isEditing} />
                {intl.get('_predict.detail.pdf.Canvas.Reanalysis')}
              </div>
               )}
            </div> */}
            <div className="action-button-map">
              {isEditing || isNotOwner ? (
                <Popup
                  disabled={isNotOwner}
                  trigger={
                    <div
                      onClick={() => {
                        if (isNotOwner) {
                          onShowExclusionControl(true);
                        }
                      }}
                      id='ga-predict-detail-image-rotate'
                      className={`map-header-icon-btn map-header-icon-text text-disable-color`}
                    >
                      <RotateIcon
                        className="rotate-icon-header"
                        disabled
                        color="#545454"
                      />
                      {intl.get('_predict.detail.pdf.Canvas.rotate')}
                    </div>
                  }
                position="bottom center"
                content={intl.get('_predict.detail.pdf.Canvas.No rotate')}
                on="hover"
                hideOnScroll
                inverted
              />
              ):(
                <div
                className={`map-header-icon-btn map-header-icon-text`}
                onClick={() => {
                  if (isNotOwner) {
                    onShowExclusionControl(true);
                    return;
                  }
                  rotateDialog();
                }}
              >
                <RotateIcon
                  className="rotate-icon-header"
                  color="#545454"
                />
                {intl.get('_predict.detail.pdf.Canvas.rotate')}
              </div>
              )}
              
              <div
                id="ga-predict-detail-image-reset"
                className="map-header-icon-btn map-header-icon-text"
                onClick={() => {
                  this.context.sendPredictBeautifyDetailMetrics({
                    event_type: EventType.Action,
                    event_name: EventName.ResetZoom,
                  })
                  const map = mapRef.current.leafletElement;
                  map.setView([height / 2, width / 2], -1);
                  localStorage.removeItem('zoom'+this.props.pdfId);
                }}
              >
                <Icon
                  name="expand arrows alternate"
                  fitted
                  style={{ fontSize: '1.2em', marginTop: '15px' }}
                />
                {intl.get('_predict.detail.pdf.Canvas.Zoom reset')}
              </div>
              {/*<div*/}
              {/*  className='map-header-icon-btn map-header-icon-text'*/}
              {/*  onClick={() => {*/}
              {/*    if (isEditing || loading) return;*/}
              {/*    predictDialog();*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <Icon*/}
              {/*    name="eye"*/}
              {/*    fitted*/}
              {/*    style={{ fontSize: '1.2em', marginTop: '15px' }}*/}
              {/*    disabled={loading || isEditing}*/}
              {/*  />*/}
              {/*  {intl.get('_predict.detail.pdf.Canvas.Predict')}*/}
              {/*</div>*/}
              {/* {!clickFlag && (
                <div
                  className="map-header-icon-btn map-header-icon-text"
                  onClick={() => {
                    addPdfToImageDialog();
                  }}
                >
                  <Icon
                    name="file pdf"
                    fitted
                    style={{ fontSize: '1.2em', marginTop: '15px' }}
                  />
                  {intl.get('_predict.detail.pdf.Canvas.movelabeling')}
                </div>
              )} */}
              {/*<div*/}
              {/*  className="map-header-icon-btn"*/}
              {/*  onClick={e => this.zoomIn(e, map)}*/}
              {/*>*/}
              {/*  <Icon name="zoom-in" fitted style={{ fontSize: '1.2em' }} />*/}
              {/*</div>*/}
              {/*<div*/}
              {/*  className="map-header-icon-btn"*/}
              {/*  onClick={e => this.zoomOut(e, map)}*/}
              {/*>*/}
              {/*  <Icon name="zoom-out" fitted style={{ fontSize: '1.2em' }} />*/}
              {/*</div>*/}
              {/*<div className="map-header-icon-btn map-header-zoom">*/}
              {/*  {Math.round((zoom + 2) * 100)}%*/}
              {/*</div>*/}
              <div className="map-header-icon-btn map-header-zoom">
                <div id="ga-predict-detail-image-zoom" className="slider-range-input">
                  <Popup
                    trigger={
                      <input
                        type="range"
                        style={{ height: "100%", cursor: "pointer" }}
                        min={ minRangeZoom }
                        max={ maxRangeZoom }
                        step="any"
                        value={currentZoom}
                        onChange={e => 
                          {
                            map = mapRef.current.leafletElement;
                            map.setZoom(e.target.value)
                          }
                        }
                        onMouseUp={e => {
                          this.context.sendPredictBeautifyDetailMetrics({
                            event_type: EventType.Action,
                            event_name: EventName.SetRangeBySlider,
                            custom_parameter: {
                              zoom : `${Math.round((currentZoom + 2) * 100)}%`
                            },
                          })
                        }}
                      />
                    }
                    inverted
                    content={`${Math.round((currentZoom + 2) * 100)}%`}
                    position="right center"
                    style={{
                      borderRadius: 0,
                      opacity: 0.7,
                    }}
                  />
                </div>
                <div className="slider-range-label">
                  <span>縮小</span>
                  <span>拡大</span>
                </div>
              </div>
            </div>
            {/* <Toasts
              classNames="pdf-header-toast-custom"
              content={intl.get(
                '_predict.detail.pdf.Canvas.Please re-analyze the document'
              )}
              isOpen={toastOpen}
              handlePortalClose={() => {
                this.setState({ toastOpen: false });
              }}
              closeText={intl.get('_predict.detail.pdf.Canvas.Close')}
            /> */}
          </div>
        )}
      </Context.Consumer>
    );
  }
}

export default MapHeader;

MapHeader.propTypes = {
  thumbnailRef: PropTypes.object,
  textMappingRef: PropTypes.object,
  mapRef: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  clickFlag: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  zoom: PropTypes.number,
  handleChangeTitleLoading: PropTypes.func,
  addPdfToImageDialog: PropTypes.func,
  rotateDialog: PropTypes.func,
};

MapHeader.defaultProps = {
  handleChangeTitleLoading: () => {},
  addPdfToImageDialog: () => {},
  rotateDialog: () => {},
};
