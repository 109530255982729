export const INIT_CODE = `async function ({getCurrentImageId, getPdfImages, getImageBBoxes, getPrediction, getMetadataByCategory}) {
  /*
  =====================================
  -         API Information           -
  =====================================
  getCurrentImageId:
  @description: get the image id of being clicked
  @input: []
  @output: 6
  -------------------------------------
  getPdfImages:
  @description: get the pdf information and its images.
  @input: []
  @output: {id: 1, ...,images: [...]}
  -------------------------------------
  getImageBBoxes:
  @description: get the image's bboxes.
  @input: [imageId]
  @output: {id: 1, ...,labelData: [...]}
  -------------------------------------
  getPrediction:
  @description: get the processing data from the parsing function.
  @input: []
  @output: {}
  -------------------------------------
  getMetadataByCategory:
  @description: get the project metadata by category
  @input: [category]
  @output: {}
  -------------------------------------
  */ 
 
 // TO DO
  
  return {
    imageId: getCurrentImageId(),
    pdfImages: await getPdfImages(),
    imageBBoxes: await getImageBBoxes(1),
    prediction: await getPrediction(),
    metadata: await getMetadataByCategory('1')
  }
}`;

export const GEN_TEST_PARAMS = {
  genGetCurrentImageId: (rtnVal) => {
    return () => {
      return rtnVal || DEFAULT_TEST_PARAMS.currentImageId
    }
  },
  genGetPdfImages: (rtnVal) => {
   return () => { return new Promise((resolve) => {
          setTimeout(() => {
              resolve(rtnVal || DEFAULT_TEST_PARAMS.pdfImages);
          }, 1000);
        })
    }
  },
  genGetImageBBoxes: (rtnVal) => {
    return () => { return new Promise((resolve) => {
        setTimeout(() => {
            resolve(rtnVal || DEFAULT_TEST_PARAMS.imageBBoxes);
          }, 1000);
        })
    }
  },
  genGetPrediction: (rtnVal) => {
    return () => { return new Promise((resolve) => {
        setTimeout(() => {
            resolve(rtnVal || DEFAULT_TEST_PARAMS.prediction);
        }, 1000);
      })
    }
  },
  genGetMetadataByCategory: (rtnVal) => {
    return () => { return new Promise((resolve) => {
        setTimeout(() => {
            resolve(rtnVal || DEFAULT_TEST_PARAMS.metadata);
        }, 1000);
      })
    }
  },
}

export const DEFAULT_TEST_PARAMS = {
  currentImageId: 6,
  pdfImages: {
    "id": 5,
    "originalName": "001429_タマチ工業株式会社_201205決算書.pdf",
    "link": "/uploads/prediction/2/1592883899837_001429_タマチ工業株式会社_201205決算書.pdf",
    "stateFlag": 3,
    "pages": 0,
    "uploadTime": 1592883899837,
    "lastEdited": 1592884018863,
    "lastEditedId": null,
    "lastEditedName": null,
    "userInCharge": null,
    "userInChargeName": null,
    "errmsg": "7",
    "projectsId": 2,
    "images": [
        {
            "id": 1,
            "projectsId": 2,
            "pdfFileId": 5,
            "pageAngle": 0,
            "pageClassification": "個別注記表",
            "image_order": 1,
            "originalImageClassification": 7,
            "imageClassification": 7,
            "link": "generated/prediction/2/1592883899837_001429_タマチ工業株式会社_201205決算書/001_ori.jpg",
            "state_flag": 3,
            "errmsg": "{}",
            "lastEdited": 1592884018076,
            "lastEditedId": null,
            "lastEditedName": null
        },
        {
            "id": 2,
            "projectsId": 2,
            "pdfFileId": 5,
            "pageAngle": 0,
            "pageClassification": "貸借対照表",
            "image_order": 1,
            "originalImageClassification": 1,
            "imageClassification": 1,
            "link": "generated/prediction/2/1592883899837_001429_タマチ工業株式会社_201205決算書/002_ori.jpg",
            "state_flag": 3,
            "errmsg": "{}",
            "lastEdited": 1592884018119,
            "lastEditedId": null,
            "lastEditedName": null
        },
        {
            "id": 3,
            "projectsId": 2,
            "pdfFileId": 5,
            "pageAngle": 0,
            "pageClassification": "損益計算書",
            "image_order": 1,
            "originalImageClassification": 2,
            "imageClassification": 2,
            "link": "generated/prediction/2/1592883899837_001429_タマチ工業株式会社_201205決算書/003_ori.jpg",
            "state_flag": 3,
            "errmsg": "{}",
            "lastEdited": 1592884018331,
            "lastEditedId": null,
            "lastEditedName": null
        },
        {
            "id": 4,
            "projectsId": 2,
            "pdfFileId": 5,
            "pageAngle": 0,
            "pageClassification": "販売費及び一般管理費",
            "image_order": 1,
            "originalImageClassification": 4,
            "imageClassification": 4,
            "link": "generated/prediction/2/1592883899837_001429_タマチ工業株式会社_201205決算書/004_ori.jpg",
            "state_flag": 3,
            "errmsg": "{}",
            "lastEdited": 1592884018461,
            "lastEditedId": null,
            "lastEditedName": null
        },
        {
            "id": 5,
            "projectsId": 2,
            "pdfFileId": 5,
            "pageAngle": 0,
            "pageClassification": "製造原価明細表",
            "image_order": 1,
            "originalImageClassification": 3,
            "imageClassification": 3,
            "link": "generated/prediction/2/1592883899837_001429_タマチ工業株式会社_201205決算書/005_ori.jpg",
            "state_flag": 3,
            "errmsg": "{}",
            "lastEdited": 1592884018613,
            "lastEditedId": null,
            "lastEditedName": null
        },
        {
            "id": 6,
            "projectsId": 2,
            "pdfFileId": 5,
            "pageAngle": 0,
            "pageClassification": "個別注記表",
            "image_order": 1,
            "originalImageClassification": 7,
            "imageClassification": 7,
            "link": "generated/prediction/2/1592883899837_001429_タマチ工業株式会社_201205決算書/006_ori.jpg",
            "state_flag": 3,
            "errmsg": "{}",
            "lastEdited": 1592884018723,
            "lastEditedId": null,
            "lastEditedName": null
        },
        {
            "id": 7,
            "projectsId": 2,
            "pdfFileId": 5,
            "pageAngle": 0,
            "pageClassification": "個別注記表",
            "image_order": 1,
            "originalImageClassification": 7,
            "imageClassification": 7,
            "link": "generated/prediction/2/1592883899837_001429_タマチ工業株式会社_201205決算書/007_ori.jpg",
            "state_flag": 3,
            "errmsg": "{}",
            "lastEdited": 1592884018793,
            "lastEditedId": null,
            "lastEditedName": null
        }
    ]
  },
  imageBBoxes: {
    "id": 2,
    "projectsId": 2,
    "pdfFileId": 5,
    "pageAngle": 0,
    "pageClassification": "貸借対照表",
    "imageOrder": 1,
    "originalImageClassification": 1,
    "imageClassification": 1,
    "imageName": "002_ori.jpg",
    "link": "/dppapi/predict/pdf/image/2/1592883899837_001429_タマチ工業株式会社_201205決算書/002_ori.jpg/?uuid=1592898640028",
    "stateFlag": 3,
    "errmsg": {},
    "lastEdited": 1592884018119,
    "lastEditedId": null,
    "lastEditedName": null,
    "success": true,
    "labelData": {
        "labels": {
            "bbox": [
                {
                    "id": 6,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 3367,
                            "lng": 2324
                        },
                        {
                            "lat": 3408,
                            "lng": 2341
                        }
                    ],
                    "det_text": ")"
                },
                {
                    "id": 7,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 3134,
                            "lng": 2016
                        },
                        {
                            "lat": 3230,
                            "lng": 2237
                        }
                    ],
                    "det_text": ""
                },
                {
                    "id": 8,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 3140,
                            "lng": 1820
                        },
                        {
                            "lat": 3204,
                            "lng": 2035
                        }
                    ],
                    "det_text": "I,75"
                },
                {
                    "id": 9,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 3140,
                            "lng": 1231
                        },
                        {
                            "lat": 3204,
                            "lng": 1772
                        }
                    ],
                    "det_text": "負債･純資産合計"
                },
                {
                    "id": 10,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 3140,
                            "lng": 1130
                        },
                        {
                            "lat": 3204,
                            "lng": 1198
                        }
                    ],
                    "det_text": "9.4"
                },
                {
                    "id": 11,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 3148,
                            "lng": 1029
                        },
                        {
                            "lat": 3165,
                            "lng": 1070
                        }
                    ],
                    "det_text": "書※綾"
                },
                {
                    "id": 12,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 3132,
                            "lng": 200
                        },
                        {
                            "lat": 3197,
                            "lng": 756
                        }
                    ],
                    "det_text": "資産合計"
                },
                {
                    "id": 13,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 3063,
                            "lng": 2016
                        },
                        {
                            "lat": 3159,
                            "lng": 2237
                        }
                    ],
                    "det_text": ""
                },
                {
                    "id": 14,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 3065,
                            "lng": 1847
                        },
                        {
                            "lat": 3146,
                            "lng": 2039
                        }
                    ],
                    "det_text": "願理"
                },
                {
                    "id": 15,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 3069,
                            "lng": 1231
                        },
                        {
                            "lat": 3134,
                            "lng": 1772
                        }
                    ],
                    "det_text": "純資産合計"
                },
                {
                    "id": 16,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2977,
                            "lng": 977
                        },
                        {
                            "lat": 3026,
                            "lng": 1194
                        }
                    ],
                    "det_text": "2,486,450"
                },
                {
                    "id": 17,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2967,
                            "lng": 207
                        },
                        {
                            "lat": 3032,
                            "lng": 593
                        }
                    ],
                    "det_text": "(24.【繰延資産】"
                },
                {
                    "id": 18,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2891,
                            "lng": 1023
                        },
                        {
                            "lat": 2940,
                            "lng": 1194
                        }
                    ],
                    "det_text": "386,000"
                },
                {
                    "id": 19,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2883,
                            "lng": 302
                        },
                        {
                            "lat": 2932,
                            "lng": 752
                        }
                    ],
                    "det_text": "(23)特定退職金掛金"
                },
                {
                    "id": 20,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2795,
                            "lng": 936
                        },
                        {
                            "lat": 2860,
                            "lng": 1198
                        }
                    ],
                    "det_text": "177,186,350"
                },
                {
                    "id": 21,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2787,
                            "lng": 300
                        },
                        {
                            "lat": 2852,
                            "lng": 764
                        }
                    ],
                    "det_text": "(22)保険積立金"
                },
                {
                    "id": 22,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2711,
                            "lng": 977
                        },
                        {
                            "lat": 2760,
                            "lng": 1194
                        }
                    ],
                    "det_text": "5,085,012"
                },
                {
                    "id": 23,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2701,
                            "lng": 300
                        },
                        {
                            "lat": 2766,
                            "lng": 764
                        }
                    ],
                    "det_text": "(21)長期前払費用"
                },
                {
                    "id": 24,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2625,
                            "lng": 977
                        },
                        {
                            "lat": 2674,
                            "lng": 1194
                        }
                    ],
                    "det_text": "7,281,990"
                },
                {
                    "id": 25,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2615,
                            "lng": 300
                        },
                        {
                            "lat": 2680,
                            "lng": 764
                        }
                    ],
                    "det_text": "(20)保証金･敷金"
                },
                {
                    "id": 26,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2538,
                            "lng": 977
                        },
                        {
                            "lat": 2587,
                            "lng": 1194
                        }
                    ],
                    "det_text": "3,668,400"
                },
                {
                    "id": 27,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2521,
                            "lng": 300
                        },
                        {
                            "lat": 2585,
                            "lng": 764
                        }
                    ],
                    "det_text": "(19)投資有価証券"
                },
                {
                    "id": 28,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2444,
                            "lng": 977
                        },
                        {
                            "lat": 2493,
                            "lng": 1194
                        }
                    ],
                    "det_text": "4,610,000"
                },
                {
                    "id": 29,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2435,
                            "lng": 300
                        },
                        {
                            "lat": 2499,
                            "lng": 764
                        }
                    ],
                    "det_text": "(18)出資金"
                },
                {
                    "id": 30,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2358,
                            "lng": 938
                        },
                        {
                            "lat": 2407,
                            "lng": 1209
                        }
                    ],
                    "det_text": "198,217,752)"
                },
                {
                    "id": 31,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2337,
                            "lng": 227
                        },
                        {
                            "lat": 2417,
                            "lng": 419
                        }
                    ],
                    "det_text": "(投資等)"
                },
                {
                    "id": 32,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2280,
                            "lng": 1962
                        },
                        {
                            "lat": 2329,
                            "lng": 2217
                        }
                    ],
                    "det_text": "119,700,333"
                },
                {
                    "id": 33,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2272,
                            "lng": 1023
                        },
                        {
                            "lat": 2321,
                            "lng": 1194
                        }
                    ],
                    "det_text": "611,023"
                },
                {
                    "id": 34,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2262,
                            "lng": 1363
                        },
                        {
                            "lat": 2327,
                            "lng": 1779
                        }
                    ],
                    "det_text": "繰越利益剰余金"
                },
                {
                    "id": 35,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2264,
                            "lng": 302
                        },
                        {
                            "lat": 2313,
                            "lng": 760
                        }
                    ],
                    "det_text": "(17)電話加入権"
                },
                {
                    "id": 36,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2184,
                            "lng": 1975
                        },
                        {
                            "lat": 2249,
                            "lng": 2214
                        }
                    ],
                    "det_text": "25,000,000"
                },
                {
                    "id": 37,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2176,
                            "lng": 1363
                        },
                        {
                            "lat": 2241,
                            "lng": 1787
                        }
                    ],
                    "det_text": "別途積立金"
                },
                {
                    "id": 38,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2176,
                            "lng": 983
                        },
                        {
                            "lat": 2241,
                            "lng": 1198
                        }
                    ],
                    "det_text": "1,446,145"
                },
                {
                    "id": 39,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2168,
                            "lng": 300
                        },
                        {
                            "lat": 2233,
                            "lng": 764
                        }
                    ],
                    "det_text": "(16)ンフト購入費"
                },
                {
                    "id": 40,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2100,
                            "lng": 1977
                        },
                        {
                            "lat": 2149,
                            "lng": 2217
                        }
                    ],
                    "det_text": "25,000,000"
                },
                {
                    "id": 41,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2090,
                            "lng": 1363
                        },
                        {
                            "lat": 2155,
                            "lng": 1787
                        }
                    ],
                    "det_text": "退職積立金"
                },
                {
                    "id": 42,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2092,
                            "lng": 985
                        },
                        {
                            "lat": 2141,
                            "lng": 1209
                        }
                    ],
                    "det_text": "2,057,168)"
                },
                {
                    "id": 43,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2082,
                            "lng": 231
                        },
                        {
                            "lat": 2147,
                            "lng": 562
                        }
                    ],
                    "det_text": "(無形固定資産)"
                },
                {
                    "id": 44,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2004,
                            "lng": 1324
                        },
                        {
                            "lat": 2068,
                            "lng": 1787
                        }
                    ],
                    "det_text": "その他利益剰余金"
                },
                {
                    "id": 45,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 2006,
                            "lng": 938
                        },
                        {
                            "lat": 2055,
                            "lng": 1194
                        }
                    ],
                    "det_text": "255,451,123"
                },
                {
                    "id": 46,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1996,
                            "lng": 300
                        },
                        {
                            "lat": 2061,
                            "lng": 764
                        }
                    ],
                    "det_text": "(15)土地"
                },
                {
                    "id": 47,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1927,
                            "lng": 2000
                        },
                        {
                            "lat": 1976,
                            "lng": 2217
                        }
                    ],
                    "det_text": "5,000,000"
                },
                {
                    "id": 48,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1920,
                            "lng": 1047
                        },
                        {
                            "lat": 1968,
                            "lng": 1194
                        }
                    ],
                    "det_text": "62,859"
                },
                {
                    "id": 49,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1910,
                            "lng": 1316
                        },
                        {
                            "lat": 1974,
                            "lng": 1787
                        }
                    ],
                    "det_text": "利益準備金"
                },
                {
                    "id": 50,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1902,
                            "lng": 300
                        },
                        {
                            "lat": 1967,
                            "lng": 764
                        }
                    ],
                    "det_text": "(14)ー括償却資産"
                },
                {
                    "id": 51,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1831,
                            "lng": 1960
                        },
                        {
                            "lat": 1896,
                            "lng": 2237
                        }
                    ],
                    "det_text": "174,700,33】"
                },
                {
                    "id": 52,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1824,
                            "lng": 1262
                        },
                        {
                            "lat": 1888,
                            "lng": 1624
                        }
                    ],
                    "det_text": "(利益.剰余金)"
                },
                {
                    "id": 53,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1824,
                            "lng": 983
                        },
                        {
                            "lat": 1888,
                            "lng": 1206
                        }
                    ],
                    "det_text": "3,880,790"
                },
                {
                    "id": 54,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1816,
                            "lng": 308
                        },
                        {
                            "lat": 1880,
                            "lng": 764
                        }
                    ],
                    "det_text": "(13)工具器具備品"
                },
                {
                    "id": 55,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1747,
                            "lng": 1977
                        },
                        {
                            "lat": 1796,
                            "lng": 2233
                        }
                    ],
                    "det_text": "20,000,000)"
                },
                {
                    "id": 56,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1737,
                            "lng": 1262
                        },
                        {
                            "lat": 1802,
                            "lng": 1710
                        }
                    ],
                    "det_text": "(資本金)"
                },
                {
                    "id": 57,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1737,
                            "lng": 983
                        },
                        {
                            "lat": 1802,
                            "lng": 1206
                        }
                    ],
                    "det_text": "1,070,072"
                },
                {
                    "id": 58,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1730,
                            "lng": 308
                        },
                        {
                            "lat": 1794,
                            "lng": 764
                        }
                    ],
                    "det_text": "(12)車両運搬具"
                },
                {
                    "id": 59,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1651,
                            "lng": 1805
                        },
                        {
                            "lat": 1716,
                            "lng": 2237
                        }
                    ],
                    "det_text": "90.7038"
                },
                {
                    "id": 60,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1651,
                            "lng": 1262
                        },
                        {
                            "lat": 1716,
                            "lng": 1539
                        }
                    ],
                    "det_text": "【株主資本】"
                },
                {
                    "id": 61,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1653,
                            "lng": 938
                        },
                        {
                            "lat": 1702,
                            "lng": 1202
                        }
                    ],
                    "det_text": "301,680,402"
                },
                {
                    "id": 62,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1643,
                            "lng": 300
                        },
                        {
                            "lat": 1708,
                            "lng": 771
                        }
                    ],
                    "det_text": ".(11)機械装置"
                },
                {
                    "id": 63,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1573,
                            "lng": 1510
                        },
                        {
                            "lat": 1637,
                            "lng": 2128
                        }
                    ],
                    "det_text": "純資産の部"
                },
                {
                    "id": 64,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1567,
                            "lng": 985
                        },
                        {
                            "lat": 1616,
                            "lng": 1202
                        }
                    ],
                    "det_text": "2,910,065"
                },
                {
                    "id": 65,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1557,
                            "lng": 308
                        },
                        {
                            "lat": 1622,
                            "lng": 771
                        }
                    ],
                    "det_text": "(10)構築物"
                },
                {
                    "id": 66,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1522,
                            "lng": 1816
                        },
                        {
                            "lat": 1555,
                            "lng": 1855
                        }
                    ],
                    "det_text": "第"
                },
                {
                    "id": 67,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1504,
                            "lng": 1240
                        },
                        {
                            "lat": 1553,
                            "lng": 1775
                        }
                    ],
                    "det_text": "負債合計"
                },
                {
                    "id": 68,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1489,
                            "lng": 1977
                        },
                        {
                            "lat": 1585,
                            "lng": 2245
                        }
                    ],
                    "det_text": "麺目"
                },
                {
                    "id": 69,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1471,
                            "lng": 936
                        },
                        {
                            "lat": 1536,
                            "lng": 1206
                        }
                    ],
                    "det_text": "236,619,374"
                },
                {
                    "id": 70,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1463,
                            "lng": 308
                        },
                        {
                            "lat": 1528,
                            "lng": 771
                        }
                    ],
                    "det_text": "(9)建物"
                },
                {
                    "id": 71,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1395,
                            "lng": 1985
                        },
                        {
                            "lat": 1444,
                            "lng": 2217
                        }
                    ],
                    "det_text": "50,651,605"
                },
                {
                    "id": 72,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1385,
                            "lng": 1332
                        },
                        {
                            "lat": 1449,
                            "lng": 1787
                        }
                    ],
                    "det_text": "(32)長期未払金"
                },
                {
                    "id": 73,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1387,
                            "lng": 938
                        },
                        {
                            "lat": 1436,
                            "lng": 1209
                        }
                    ],
                    "det_text": "801,674,685)"
                },
                {
                    "id": 74,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1377,
                            "lng": 238
                        },
                        {
                            "lat": 1442,
                            "lng": 562
                        }
                    ],
                    "det_text": "(有形固定資産)"
                },
                {
                    "id": 75,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1308,
                            "lng": 1962
                        },
                        {
                            "lat": 1357,
                            "lng": 2217
                        }
                    ],
                    "det_text": "685,877,245"
                },
                {
                    "id": 76,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1299,
                            "lng": 1332
                        },
                        {
                            "lat": 1363,
                            "lng": 1787
                        }
                    ],
                    "det_text": "(27)長期借入金"
                },
                {
                    "id": 77,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1291,
                            "lng": 804
                        },
                        {
                            "lat": 1355,
                            "lng": 1221
                        }
                    ],
                    "det_text": "1,00,929,503"
                },
                {
                    "id": 78,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1293,
                            "lng": 240
                        },
                        {
                            "lat": 1342,
                            "lng": 512
                        }
                    ],
                    "det_text": "【固定資産】"
                },
                {
                    "id": 79,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1277,
                            "lng": 2264
                        },
                        {
                            "lat": 1381,
                            "lng": 2310
                        }
                    ],
                    "det_text": "固H"
                },
                {
                    "id": 80,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1230,
                            "lng": 2264
                        },
                        {
                            "lat": 1279,
                            "lng": 2310
                        }
                    ],
                    "det_text": "r"
                },
                {
                    "id": 81,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1212,
                            "lng": 1836
                        },
                        {
                            "lat": 1277,
                            "lng": 2229
                        }
                    ],
                    "det_text": "733823858"
                },
                {
                    "id": 82,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1205,
                            "lng": 1308
                        },
                        {
                            "lat": 1269,
                            "lng": 1539
                        }
                    ],
                    "det_text": "固定負債】"
                },
                {
                    "id": 83,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1205,
                            "lng": 944
                        },
                        {
                            "lat": 1269,
                            "lng": 1206
                        }
                    ],
                    "det_text": "△3,500,000"
                },
                {
                    "id": 84,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1207,
                            "lng": 310
                        },
                        {
                            "lat": 1256,
                            "lng": 768
                        }
                    ],
                    "det_text": "(8)貸倒引当金"
                },
                {
                    "id": 85,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1183,
                            "lng": 2264
                        },
                        {
                            "lat": 1240,
                            "lng": 2310
                        }
                    ],
                    "det_text": "き"
                },
                {
                    "id": 86,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1128,
                            "lng": 2248
                        },
                        {
                            "lat": 1177,
                            "lng": 2310
                        }
                    ],
                    "det_text": "番"
                },
                {
                    "id": 87,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1128,
                            "lng": 2008
                        },
                        {
                            "lat": 1177,
                            "lng": 2225
                        }
                    ],
                    "det_text": "2,790,200"
                },
                {
                    "id": 88,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1118,
                            "lng": 1339
                        },
                        {
                            "lat": 1183,
                            "lng": 1787
                        }
                    ],
                    "det_text": "(31)未払法人税等"
                },
                {
                    "id": 89,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1118,
                            "lng": 983
                        },
                        {
                            "lat": 1183,
                            "lng": 1206
                        }
                    ],
                    "det_text": "1,491,690"
                },
                {
                    "id": 90,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1111,
                            "lng": 308
                        },
                        {
                            "lat": 1175,
                            "lng": 771
                        }
                    ],
                    "det_text": "(7)前払費用"
                },
                {
                    "id": 91,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1083,
                            "lng": 2266
                        },
                        {
                            "lat": 1116,
                            "lng": 2305
                        }
                    ],
                    "det_text": "の"
                },
                {
                    "id": 92,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1042,
                            "lng": 2008
                        },
                        {
                            "lat": 1091,
                            "lng": 2225
                        }
                    ],
                    "det_text": "2,527,610"
                },
                {
                    "id": 93,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1032,
                            "lng": 1339
                        },
                        {
                            "lat": 1097,
                            "lng": 1787
                        }
                    ],
                    "det_text": "(30)預り金"
                },
                {
                    "id": 94,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1034,
                            "lng": 961
                        },
                        {
                            "lat": 1083,
                            "lng": 1202
                        }
                    ],
                    "det_text": "22,256,417"
                },
                {
                    "id": 95,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1026,
                            "lng": 2264
                        },
                        {
                            "lat": 1083,
                            "lng": 2310
                        }
                    ],
                    "det_text": "理"
                },
                {
                    "id": 96,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 1024,
                            "lng": 308
                        },
                        {
                            "lat": 1089,
                            "lng": 771
                        }
                    ],
                    "det_text": "(6)短期貸付金"
                },
                {
                    "id": 97,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 956,
                            "lng": 2264
                        },
                        {
                            "lat": 1020,
                            "lng": 2310
                        }
                    ],
                    "det_text": "ィ装"
                },
                {
                    "id": 98,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 956,
                            "lng": 1985
                        },
                        {
                            "lat": 1005,
                            "lng": 2217
                        }
                    ],
                    "det_text": "75,577,753"
                },
                {
                    "id": 99,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 946,
                            "lng": 1339
                        },
                        {
                            "lat": 1011,
                            "lng": 1787
                        }
                    ],
                    "det_text": "(29)未払費用"
                },
                {
                    "id": 100,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 948,
                            "lng": 969
                        },
                        {
                            "lat": 997,
                            "lng": 1202
                        }
                    ],
                    "det_text": "31,600,000"
                },
                {
                    "id": 101,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 940,
                            "lng": 310
                        },
                        {
                            "lat": 989,
                            "lng": 768
                        }
                    ],
                    "det_text": "(5)預り資産等"
                },
                {
                    "id": 102,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 917,
                            "lng": 2264
                        },
                        {
                            "lat": 966,
                            "lng": 2310
                        }
                    ],
                    "det_text": "器"
                },
                {
                    "id": 103,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 862,
                            "lng": 1985
                        },
                        {
                            "lat": 911,
                            "lng": 2217
                        }
                    ],
                    "det_text": "42,727,840"
                },
                {
                    "id": 104,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 852,
                            "lng": 1339
                        },
                        {
                            "lat": 917,
                            "lng": 1795
                        }
                    ],
                    "det_text": "(28)未払金"
                },
                {
                    "id": 105,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 852,
                            "lng": 967
                        },
                        {
                            "lat": 917,
                            "lng": 1213
                        }
                    ],
                    "det_text": "18,839,640"
                },
                {
                    "id": 106,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 844,
                            "lng": 308
                        },
                        {
                            "lat": 909,
                            "lng": 771
                        }
                    ],
                    "det_text": "(4)仕掛品等"
                },
                {
                    "id": 107,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 838,
                            "lng": 2264
                        },
                        {
                            "lat": 903,
                            "lng": 2310
                        }
                    ],
                    "det_text": "危"
                },
                {
                    "id": 108,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 776,
                            "lng": 2264
                        },
                        {
                            "lat": 832,
                            "lng": 2310
                        }
                    ],
                    "det_text": "舶"
                },
                {
                    "id": 109,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 776,
                            "lng": 1962
                        },
                        {
                            "lat": 825,
                            "lng": 2217
                        }
                    ],
                    "det_text": "346,154,000"
                },
                {
                    "id": 110,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 766,
                            "lng": 1339
                        },
                        {
                            "lat": 830,
                            "lng": 1795
                        }
                    ],
                    "det_text": "(27)短期借入金"
                },
                {
                    "id": 111,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 768,
                            "lng": 946
                        },
                        {
                            "lat": 817,
                            "lng": 1209
                        }
                    ],
                    "det_text": "142,950,314"
                },
                {
                    "id": 112,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 758,
                            "lng": 308
                        },
                        {
                            "lat": 823,
                            "lng": 771
                        }
                    ],
                    "det_text": "(3)売掛金"
                },
                {
                    "id": 113,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 736,
                            "lng": 2264
                        },
                        {
                            "lat": 785,
                            "lng": 2310
                        }
                    ],
                    "det_text": "継"
                },
                {
                    "id": 114,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 682,
                            "lng": 2264
                        },
                        {
                            "lat": 738,
                            "lng": 2310
                        }
                    ],
                    "det_text": "Q"
                },
                {
                    "id": 115,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 680,
                            "lng": 1983
                        },
                        {
                            "lat": 744,
                            "lng": 2229
                        }
                    ],
                    "det_text": "22,094,608"
                },
                {
                    "id": 116,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 668,
                            "lng": 1335
                        },
                        {
                            "lat": 748,
                            "lng": 1799
                        }
                    ],
                    "det_text": "(26)買掛金"
                },
                {
                    "id": 117,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 672,
                            "lng": 990
                        },
                        {
                            "lat": 736,
                            "lng": 1213
                        }
                    ],
                    "det_text": "1,057,350"
                },
                {
                    "id": 118,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 660,
                            "lng": 312
                        },
                        {
                            "lat": 740,
                            "lng": 775
                        }
                    ],
                    "det_text": "(2)受取手形"
                },
                {
                    "id": 119,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 635,
                            "lng": 2264
                        },
                        {
                            "lat": 684,
                            "lng": 2310
                        }
                    ],
                    "det_text": "賠"
                },
                {
                    "id": 120,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 595,
                            "lng": 1985
                        },
                        {
                            "lat": 644,
                            "lng": 2225
                        }
                    ],
                    "det_text": "12,000,000"
                },
                {
                    "id": 121,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 588,
                            "lng": 2264
                        },
                        {
                            "lat": 637,
                            "lng": 2310
                        }
                    ],
                    "det_text": "盟"
                },
                {
                    "id": 122,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 586,
                            "lng": 1339
                        },
                        {
                            "lat": 650,
                            "lng": 1795
                        }
                    ],
                    "det_text": "(25)支払手形"
                },
                {
                    "id": 123,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 588,
                            "lng": 946
                        },
                        {
                            "lat": 637,
                            "lng": 1202
                        }
                    ],
                    "det_text": "215,969,728"
                },
                {
                    "id": 124,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 578,
                            "lng": 316
                        },
                        {
                            "lat": 642,
                            "lng": 771
                        }
                    ],
                    "det_text": "(1)現金預金"
                },
                {
                    "id": 125,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 535,
                            "lng": 2274
                        },
                        {
                            "lat": 576,
                            "lng": 2305
                        }
                    ],
                    "det_text": "m"
                },
                {
                    "id": 126,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 488,
                            "lng": 1824
                        },
                        {
                            "lat": 568,
                            "lng": 2233
                        }
                    ],
                    "det_text": "泰臓⑥終源臓聴"
                },
                {
                    "id": 127,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 492,
                            "lng": 1270
                        },
                        {
                            "lat": 556,
                            "lng": 1523
                        }
                    ],
                    "det_text": "【流動負債"
                },
                {
                    "id": 128,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 486,
                            "lng": 2272
                        },
                        {
                            "lat": 543,
                            "lng": 2318
                        }
                    ],
                    "det_text": "空I"
                },
                {
                    "id": 129,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 480,
                            "lng": 816
                        },
                        {
                            "lat": 560,
                            "lng": 1225
                        }
                    ],
                    "det_text": "磯願"
                },
                {
                    "id": 130,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 484,
                            "lng": 246
                        },
                        {
                            "lat": 548,
                            "lng": 523
                        }
                    ],
                    "det_text": "【流動資産】"
                },
                {
                    "id": 131,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 423,
                            "lng": 2264
                        },
                        {
                            "lat": 472,
                            "lng": 2318
                        }
                    ],
                    "det_text": "※"
                },
                {
                    "id": 132,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 413,
                            "lng": 1510
                        },
                        {
                            "lat": 478,
                            "lng": 1973
                        }
                    ],
                    "det_text": "負債の部"
                },
                {
                    "id": 133,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 405,
                            "lng": 494
                        },
                        {
                            "lat": 470,
                            "lng": 958
                        }
                    ],
                    "det_text": "資産の部"
                },
                {
                    "id": 134,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 343,
                            "lng": 1975
                        },
                        {
                            "lat": 407,
                            "lng": 2237
                        }
                    ],
                    "det_text": "(単位円)"
                },
                {
                    "id": 135,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 343,
                            "lng": 897
                        },
                        {
                            "lat": 407,
                            "lng": 1547
                        }
                    ],
                    "det_text": "平成24年5月31日現在"
                },
                {
                    "id": 136,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 337,
                            "lng": 225
                        },
                        {
                            "lat": 386,
                            "lng": 357
                        }
                    ],
                    "det_text": "(0251)"
                },
                {
                    "id": 137,
                    "type": "bbox",
                    "points": [
                        {
                            "lat": 229,
                            "lng": 901
                        },
                        {
                            "lat": 309,
                            "lng": 1551
                        }
                    ],
                    "det_text": "貸借交点表"
                }
            ]
        }
    }
  },
  prediction: {},
  metadata: [
    {
        "id": 11,
        "projectId": 4,
        "category": "1",
        "item": "1",
        "value": "1"
    }
  ]
}
