import React, { Component } from 'react';
import { Popup, Grid } from 'semantic-ui-react'
import { PenIcon, CopyIcon, OppositeIcon, ChevronRight, BreakDownIcon } from '../../../assets/icon';
import intl from 'react-intl-universal';
import ZoomPopup from '../../../common/ZoomPopup';
import { AnalyticsServiceContext } from '../../../common/Analytics/AnalyticsServiceProvider';
import { EventName, EventType, FixAmountSource, PredictDetailPageLocation } from '../../../common/Analytics/analyticsMetrics';
export default class EditBox extends Component {
  constructor(props) {
    super(props);
    this.state = { isCopied: false }
  }
  static contextType = AnalyticsServiceContext;

  onCopiedTimeOut = (state) => {
    this.setState({ isCopied: state });
    setTimeout(function () {
      this.setState({ isCopied: false });
    }.bind(this), 2000);
  }

  render() {
    const {
      row,
      rowScrumItem,
      rowValues,
      manualInput,
      isDisabled,
      isDisabledCard,
      isAggreation,
      isEditing,
      isDetail,
      isTotalDetail,
      isBSLine,
      onOpenInput,
      onEdit,
      onGetSum,
      onClearNumerical,
      onHoverChange,
      onSetCurrentCell,
      // onTabChange,
      handleSetOppositeValue,
      parentElement,
      popups,
      onPopupOpen,
      popupItem,
      specialClass,
      specialStyles,
      boxOffSet,
      openable,
      onShowDetailPop,
      onKeepOpen,
      showDetailPop,
      showEditBtn,
      rowValueId,
      isNotOwner,
      onShowExclusionControl
    } = this.props;
    const { isCopied } = this.state;
    const rootElement = document.getElementById('root');
    const zoomElement = row && row.id && document.getElementById('zoomPopup' + row.id);
    const ssPage = specialClass && specialClass.indexOf('edit-box-share-holders-div') > -1 ? true : false;
    const popBoxPosition = ssPage ? 'bottom center' : 'top right';
    const popIconPosition = ssPage ? 'bottom center' : 'top center';
    let OppositeDisabled = isDisabled === undefined ? isDisabledCard : isDisabled;
    if (rowValues.length <= 0 || manualInput !== undefined) {
      OppositeDisabled = true;
    }

    let trigger;
    let spanId;
    if (isAggreation && popupItem !== null) {
      spanId = row.id ? row.id : encodeURIComponent(rowScrumItem).replace(new RegExp(['%'], 'gi'), '');
      trigger =
        <div className={specialClass ? specialClass : 'edit-box-normal-div'} id={'zoomPopup' + row.id}>
          {parentElement}
          <ZoomPopup
            classNames={'zoom-popup-value-table'}
            ref={ref => {
              popups[rowScrumItem] = ref;
            }}
            onOpen={onPopupOpen}
            styles={{
              textAlign: 'left',
              padding: '20px',
              font: 'normal normal normal 14px Noto Sans JP',
              lineHeight: '1.7em',
              ...specialStyles
            }}
            content={popupItem.formula.replace(/#|\{|\}/g, '')}
            trigger={
              <div><span id={'hideSpan' + spanId} /></div>
            }
            cursor='default'
          />
        </div>;
    } else {
      trigger = parentElement;
    }
    return (
      <Popup className={isAggreation && ssPage ? 'edit-box-menu-aggreation' : 'edit-box-menu'} trigger={trigger}
        flowing
        hoverable
        basic
        hideOnScroll
        position={popBoxPosition}
        disabled={!openable}
        offset={boxOffSet ? boxOffSet : [0, -5]}
        onClose={() => {
          const zoomPopupElement = Array.from(document.getElementsByClassName('zoom-popup-value-table'));
          let isAllPopupClosed = true;
          zoomPopupElement.forEach((element) => {
            if (element.style.display === 'block') {
              isAllPopupClosed = false;
            }
          })
          if (ssPage && !isEditing) {
            if (isAllPopupClosed) {
              onHoverChange(showDetailPop ? true : false);
              onSetCurrentCell('');
            } else {
              onSetCurrentCell(rowScrumItem);
            }
          } else {
            onHoverChange(showDetailPop ? true : false);
          }
        }}
        onOpen={() => {
          if (isDetail) {
            onHoverChange(true, rowScrumItem);
          } else {
            onHoverChange(true);
          }
          if (ssPage && !isEditing) {
            onSetCurrentCell(rowScrumItem);
          }
        }}
      >
        <Grid centered columns={ssPage && row.values.length !== 0 ? 4 : 3}
          onMouseEnter={() => {
            if (isDetail) {
              onHoverChange(true, rowScrumItem);
            } else {
              onHoverChange(true);
            }
            if (ssPage && !isEditing) {
              onSetCurrentCell(rowScrumItem);
            }
          }}
        >
          {showEditBtn ? (<Popup
            disabled={isNotOwner}
            trigger={
              <Grid.Column textAlign='center' className='edit-box-grid'
                onClick={(e) => {
                  if (isNotOwner) {
                    onShowExclusionControl(true);
                    return;
                  }
                  if(this.context.predictDetailViewMode !== "" ){
                    this.context.sendPredictBeautifyDetailMetrics({
                      event_type: EventType.Action,
                      event_name: EventName.FixAmount,
                      custom_parameter: {
                        location : PredictDetailPageLocation.ScrumItem,
                        source : FixAmountSource.Icon,
                        view_mode : this.context.predictDetailViewMode,
                      },
                    })
                  }
                  e.stopPropagation();
                  this.props.onCellOperation && this.props.onCellOperation()
                  if (ssPage) {
                    onClearNumerical();
                    onSetCurrentCell(rowScrumItem);
                    onOpenInput(true, rowScrumItem);
                  } else {
                    if(isDetail){
                      onOpenInput(true, rowValueId);
                    } else {
                      onOpenInput(true);
                    }
                  }
                }}
              >
                <PenIcon className="edit-box-svg" size={'16px'} color={'#909090'} disabled={isNotOwner} />
              </Grid.Column>}
            content={isDetail || isTotalDetail ?
              intl.get('_predict.detail.pdf.MuiPopup.Correct the amount') :
              intl.get('_predict.detail.mapping.Manual entry of amount')
            }
            position={popIconPosition}
            size='tiny'
            inverted
          />) : ''}
          {isBSLine || isDetail || (manualInput !== undefined || rowValues.length !== 0) ? (<Popup
            trigger={
              <Grid.Column textAlign='center' className='edit-box-grid'>
                <CopyIcon className="edit-box-svg" color='#909090'
                  onClick={(e) => {
                    e.stopPropagation();
                    let copyText;
                    if (manualInput !== undefined) {
                      copyText = manualInput;
                    } else {
                      if (isDetail) {
                        const cellText = rowValues[0].text;
                        if (rowValues[0].invert) {
                          if (cellText.indexOf('-') >= 0) {
                            copyText = cellText.substring(1, cellText.length);
                          } else {
                            copyText = '-' + cellText;
                          }
                        } else {
                          copyText = cellText;
                        }
                      } else {
                        copyText = onGetSum(rowValues) || '-'
                      }
                    }
                    navigator.clipboard.writeText(copyText);
                    this.onCopiedTimeOut(true);
                    if(this.context.predictDetailViewMode !== "" ){
                      this.context.sendPredictBeautifyDetailMetrics({
                        event_type: EventType.Action,
                        event_name: EventName.CopyAmount,
                        custom_parameter: {
                          location : isDetail ? PredictDetailPageLocation.ScrumDetailItem : PredictDetailPageLocation.ScrumItem,
                          view_mode : this.context.predictDetailViewMode,
                        },
                      })
                    }
                  }} />
              </Grid.Column>}
            content={isCopied ? intl.get('_predict.detail.mapping.Copied') : intl.get('_predict.detail.mapping.Copy the amount')}
            className={isCopied ? 'edit-box-copied' : 'edit_box_copy'}
            position={popIconPosition}
            size='tiny'
            inverted
          />) : ''}
          {!isBSLine && (isDetail || (!isAggreation && !OppositeDisabled)) ? (<Popup
            disabled={isNotOwner}
            trigger={
              <Grid.Column textAlign='center' className='edit-box-grid'
                onClick={(e) => {
                  if (isNotOwner) {
                    onShowExclusionControl(true);
                    return;
                  }
                  e.stopPropagation();
                  if (!isDetail) onOpenInput(false);
                  if (handleSetOppositeValue !== undefined) {
                    handleSetOppositeValue(rowValues)
                    onEdit(true)
                  };
                  if (!isTotalDetail) onEdit(true);
                  if(this.context.predictDetailViewMode !== "" ){
                    this.context.sendPredictBeautifyDetailMetrics({
                      event_type: EventType.Action,
                      event_name: EventName.InvertSign,
                      custom_parameter: {
                        location : isDetail ? PredictDetailPageLocation.ScrumDetailItem : PredictDetailPageLocation.ScrumItem,
                        view_mode : this.context.predictDetailViewMode,
                      },
                    })
                  }
                }}
              >
                <OppositeIcon className="edit-box-svg" color='#909090' disabled={isNotOwner} />
              </Grid.Column>}
            content={intl.get('_predict.detail.mapping.Invert the sign')}
            position={popIconPosition}
            size='tiny'
            inverted
          />) : ''}
          {!isDetail && isAggreation ? (<Popup
            trigger={
              <Grid.Column textAlign='center' className='edit-box-grid'
                onClick={(e) => {
                  e.stopPropagation();
                  if (zoomElement !== null) {
                    const spanElement = document.getElementById('hideSpan' + spanId);
                    if (spanElement) {
                      spanElement.click();
                    } else {
                      return;
                    }
                  }
                  rootElement.addEventListener('click', (e) => {
                    if (e.target.className !== "zoom-popup-container zoom-popup-value-table") {
                      if (popups[rowScrumItem]) {
                        popups[rowScrumItem].handleShow(false);
                      }
                      onHoverChange(false);
                    }
                  }, { once: true });
                  onHoverChange(true);
                  if(this.context.predictDetailViewMode !== "" ){
                    this.context.sendPredictBeautifyDetailMetrics({
                      event_type: EventType.Action,
                      event_name: EventName.ShowAmountBreakdown,
                      custom_parameter: {
                        view_mode : this.context.predictDetailViewMode,
                      },
                    })
                  }
                }}
              >
                <BreakDownIcon color='#909090' />
              </Grid.Column>}
            content={intl.get('_predict.detail.mapping.Show the breakdown of the amount')}
            position={popIconPosition}
            size='tiny'
            inverted
          />
          ) : ''}
          {!isBSLine && !isAggreation && ssPage && row.values.length > 0 && row.manualInput === undefined ? (
            <Popup
              trigger={
                <Grid.Column textAlign='center' className='edit-box-grid'>
                  <span className="board-item-wrap-bottom-icon close-btn-icon-link">
                    <ChevronRight className="edit-box-svg" color='#909090'
                      onClick={(e) => {
                        e.stopPropagation();
                        onShowDetailPop(true);
                        onKeepOpen(true);
                      }} />
                  </span>
                </Grid.Column>}
              content={intl.get('_predict.detail.mapping.Open details')}
              position={popIconPosition}
              size='tiny'
              inverted
            />
          ) : ''}
        </Grid>
      </Popup>
    )
  }
}
