import React from 'react';

import { MDBModal, MDBModalBody } from 'mdbreact';
import {
  Button,
  Form,
  Message
} from 'semantic-ui-react';

import api from '../api'
import intl from 'react-intl-universal';

export default class TrainAdd extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      train: {},
      errors: {}
    };

    this.handleFocus = this.handleFocus.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
    this.clearProject = this.clearProject.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.clearAll = this.clearAll.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleFocus() {
    this.clearErrors();
  }

  clearErrors() {
    this.setState({
      errors: {}
    });
  }

  clearProject() {
    this.setState({
      train: {}
    });
  }

  clearAll() {
    this.setState({
      errors: {},
      train: {}
    });
    this.props.toggleEdit({isShowEdit: false});
  }

  handleInput(event) {
    const {name, value} = event.target;
    const train = {...this.state.train};
    train[name] = value;
    this.setState({
      train
    });
  }

  handleChange(event, {name, value}) {
    const train = {...this.state.train};
    train[name] = value;
    this.setState({
      train
    });
  }

  async handleSave() {
    const { projectId } = this.props;
    const train = Object.assign({}, this.props.train, this.state.train);
    if(!this.checkForm(train)) {
      return;
    }
    await api.post(`/dppapi/train${train.id ? '/' + train.projectId + '/' + train.id : '/' + projectId}`,
      Object.assign({}, train, {
        labelForms: train.labelForms.join(',')
      })
    );
    this.clearAll();
    this.props.successCallback();
  }

  checkForm(train) {
    const errors = {};
    if (!train.name) {
      errors['name'] = {content: 'Please enter a train name.', pointing: 'below', flag: true};
    }

    if (!train.moduleId) {
      errors['moduleId'] = {content: 'Please select a module at least.', pointing: 'below', flag: true};
    }

    if (!train.labelForms) {
      errors['labelForms'] = {content: 'Please select a label at least.', pointing: 'below', flag: true};
    }
    
    this.setState({
      errors: errors
    });
    return Object.keys(errors).length === 0;
  }

  render() {
    const { train, isShowEdit, labelOptions, moduleOptions } = this.props;
    const { errors } = this.state;

    return (
      <MDBModal isOpen={isShowEdit} toggle={(flag) => {this.props.toggleEdit({isShowEdit: flag})}}>
        <MDBModalBody>
        {
          Object.keys(errors).length > 0 &&
          <Message
            error
            list={ Object.keys(errors).map(i => {
              return errors[i].content
            })}
          />
        }
          <Form>
            <Form.Input
              error={errors['name'] && errors['name']['flag']}
              label={intl.get('_train.TrainTable.Name')}
              placeholder="New Task"
              defaultValue={train.name}
              name="name"
              onChange={this.handleInput}
              onFocus={this.handleFocus}
            />
            {!train.id ? (
              <Form.Select
                error={errors['moduleId'] && errors['moduleId']['flag']}
                label={intl.get('_train.TrainTable.Module')}
                options={moduleOptions}
                defaultValue={train.moduleId}
                name="moduleId"
                onChange={this.handleChange}
                onFocus={this.handleFocus}
              />
            ) : null}
            <Form.Dropdown
              error={errors['labelForms'] && errors['labelForms']['flag']}
              label={intl.get('_train.TrainTable.Label')}
              placeholder='Select labels'
              options={labelOptions} 
              fluid
              multiple
              search
              selection
              defaultValue={train.labelForms}
              name='labelForms'
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              />
            <Form.TextArea
              label={intl.get('_train.TrainTable.Description')}
              placeholder="Brief Description"
              defaultValue={train.description}
              name="description"
              onChange={this.handleInput}
              onFocus={this.handleFocus}
              rows="10"/>
            <Button floated='right' onClick={this.handleSave}>{intl.get('_common.Save')}</Button>
            <Button floated='right' onClick={this.clearAll}>{intl.get('_common.Cancel')}</Button>
          </Form>
        </MDBModalBody>
      </MDBModal>
    )
  }
}