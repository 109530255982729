import CSV from 'comma-separated-values'
import { defautPdfStatus, defaultStatusText, defaultStatusColor } from './Constant';
const multipart = require('parse-multipart');

export async function parser(res) {
  const boundary = getBoundary(res.headers)
  const buff = new Buffer(await res.data);
  var text = { img: [] };
  multipart.Parse(buff, boundary).forEach(function (element) {
    if (element.type === 'text/plain') {
      text[element.filename] = element.data.toString();
    } else if (element.type === 'image/jpeg') {
      const x = atob(new TextDecoder('utf-8').decode(element.data));
      const y = new Array(x.length);
      for (let i = 0; i < x.length; i++) {
        y[i] = x.charCodeAt(i);
      }
      const url = URL.createObjectURL(
        new Blob([new Uint8Array(y)], { type: 'image/jpeg' })
      );
      text.img.push({ pageId: element.filename, url })
    }
  });
  return text
}

function getBoundary(headers) {
  var boundary = '';
  Object.keys(headers).forEach(key => {

    if (key === 'x-content-type') {
      boundary = headers[key].split('=')[1];
    }
    return boundary

  })
  return boundary
}
export const deepClone = (obj) => {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }
  let newObj = {};
  if (Array.isArray(obj)) {
    newObj = obj.map(item => deepClone(item));
  } else {
    Object.keys(obj).forEach((key) => {
      return newObj[key] = deepClone(obj[key]);
    })
  }
  return newObj;
}

// 12345.678 => 12,345.678 
export function formatCurrency(val) {

  const reg = /\d{1,3}(?=(\d{3})+$)/g
  return val.replace(/^(-?)(\d+)((\.\d+)?)$/, function (s, s1, s2, s3) {
    return s1 + s2.replace(reg, '$&,') + s3;
  })

}

export function getBboxCoordinate(figure, height) {

  const y1 = figure.points[0].lat;
  const x1 = figure.points[0].lng;
  const y2 = figure.points[1].lat;
  const x2 = figure.points[1].lng;

  return [
    [Math.round(x1), Math.round(height - y1)],
    [Math.round(x2), Math.round(height - y2)],
  ];
}

function genCsvUrl(data, options) {
  const encoded = new CSV(data, options).encode()
  const dataBlob = new Blob([`\ufeff${encoded}`], {
    type: 'text/plain;charset=utf-8',
  })
  return window.URL.createObjectURL(dataBlob)
}

export async function downloadCsv(data, options, fileName) {
  const url = genCsvUrl(data, options)
  const a = document.createElement('a')
  a.href = url
  a.download = fileName
  a.click()
  window.URL.revokeObjectURL(url)
}

export function getPdfStatus(dbStatus){
  if(!(dbStatus&&dbStatus.data&&dbStatus.data.value)){
    return defautPdfStatus;
  }

  try {
    const status = JSON.parse(dbStatus.data.value);
    // add default text and color
    Object.keys(status).forEach(key=>{
      if(!status[key]["text"]){
        status[key]["text"] = defautPdfStatus[key]?defautPdfStatus[key].text:defaultStatusText
      }
      if(!status[key]["color"]){
        status[key]["color"] = defautPdfStatus[key]?defautPdfStatus[key].color:defaultStatusColor
      }
    })
    // make sure status 1~5 exist
    Object.keys(defautPdfStatus).forEach(key=>{
      if(!status[key]){
        status[key] = defautPdfStatus[key]
      }
    })
    return status;
  } catch (error) {
    console.log(error)
    return defautPdfStatus;
  }
}

export function getPdfStatusOptions(status) {
  const options = []
  Object.keys(status).forEach(k => {
    k = Number(k)
    options.push({ key: k, value: k, text: `${status[k].text}`, color: status[k].color })
  })
  return options
}