import React from 'react';
import { Segment, Button, Icon } from 'semantic-ui-react';
import { MDBDataTable } from 'mdbreact';
import api from '../../api';
import { confirmAlert } from 'react-confirm-alert';
import ProjectUserEdit from './ProjectUserEdit'
import intl from 'react-intl-universal';

export default class ProjectUserTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      isShowEdit: false,
      editProject: {
        id: '',
        users: [],
      },
    };

    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleDel = this.handleDel.bind(this);
    this.getUsers = this.getUsers.bind(this);
  }

  async componentDidMount() {
    await this.getUsers();
  }

  async getUsers() {
    const { projectId } = this.props
    const users = (await api.get(`/dppapi/admin/projects/users?projectId=${projectId}`)).data

    this.setState({
      rows: users.map((u, index) => {
        return {
          num: index + 1,
          id: u.id,
          name: u.name,
          email: u.email,
          operation: (
            <div>
              <Button
                icon="trash"
                label={intl.get('_project.user.ProjectUserTable.Delete')}
                size="tiny"
                onClick={() => this.handleDel(u)}
              />
            </div>
          )
        }
      })
    })
  }

  handleAdd() {
    const { projectId } = this.props
    const { rows } = this.state
    this.toggleEdit({
      isShowEdit: true,
      editProject: {
        id: projectId,
        users: rows.map(row => row.id)
      }
    })
  }

  handleDel(user) {
    confirmAlert({
      title: intl.get('_project.user.ProjectUserTable.Delete'),
      message: intl.get('_project.user.ProjectUserTable.Are you sure delete user'),
      buttons: [
        {
          label: intl.get('_project.user.ProjectUserTable.Delete'),
          onClick: async () => {
            await api.delete(`/dppapi/admin/project/users`, {
              data: {
                projectId: this.props.projectId,
                userIds: [user.id]
              }
            })
            this.getUsers()
          },
        },
        {
          label: intl.get('_project.user.ProjectUserTable.Cancel'),
        },
      ],
    });
  }

  toggleEdit({ isShowEdit, editProject }) {
    this.setState({
      isShowEdit: isShowEdit,
      editProject: editProject || {},
    });
  }

  render() {
    const rows = this.state.rows;
    const columns = [
      {
        label: intl.get('_project.user.ProjectUserTable.Num'),
        field: 'num',
        sort: 'asc',
      },
      {
        label: intl.get('_project.user.ProjectUserTable.User Name'),
        field: 'name',
        sort: 'asc',
      },
      {
        label: intl.get('_project.user.ProjectUserTable.User Email'),
        field: 'email',
        sort: 'asc',
      },
      {
        label: intl.get('_project.user.ProjectUserTable.Operation'),
        field: 'operation',
        sort: 'asc',
      },
    ];
    const data = {
      columns: columns,
      rows: rows,
    };

    return (
      <Segment>
        <Button.Group floated="right">
          <Button icon onClick={this.handleAdd}>
            <Icon name="edit" />
          </Button>
        </Button.Group>
        <MDBDataTable theadColor="common-search-table-header" btn striped bordered hover data={data} />
        <ProjectUserEdit
          isShowEdit={this.state.isShowEdit}
          project={this.state.editProject}
          toggleEdit={this.toggleEdit}
          successCallback={this.getUsers}
        />
      </Segment>
    );
  }
}
