import React from 'react'
import { MDBDataTable } from 'mdbreact'
import { Loader, Button, Segment, Checkbox, Icon } from 'semantic-ui-react'
import { confirmAlert } from 'react-confirm-alert';
import shallowEqualObjects from 'shallow-equal/objects';
import api from '../../api'
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import { getCsrfToken } from '../../common/CookieUtil';
import { downloadCsv } from '../../predict/Util'

export default class ImagesAllList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      isLoaded: false,
      rows: [],
      changeId: null,
      isAdmin: false
    };
    this.handleDel = this.handleDel.bind(this);
    this.handleCheckout = this.handleCheckout.bind(this);
    this.handleLock = this.handleLock.bind(this);
    this.markAllNotLabeled = this.markAllNotLabeled.bind(this);
    this.handleLabeled = this.handleLabeled.bind(this);
    this.imagesToRows = this.imagesToRows.bind(this);
    this.refetch = this.refetch.bind(this);
    this.load = this.load.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { projectId } = this.props;
    if (
      projectId === nextProps.projectId &&
      shallowEqualObjects(this.state, nextState)
    ) {
      return false;
    }
    return true;
  }

  async componentDidMount() {
    const info = (await api.get(`/dppapi/info`)).data;
    const per = localStorage.getItem("permission") || '';
    this.setState({
      isAdmin: per.includes('1'),
      user: info ? info : ''
    })

    await this.load();
  }

  async load() {
    this.setState({ isLoaded: false });
    const { projectId } = this.props;
    try {
      const images = (await api.get(
        `/dppapi/labeling/${projectId}/images`
      )).data;
      let rows = [];
      if (images) {
        rows = images.map((image) => {
          return this.imagesToRows(projectId, image);
        })
      }
      this.setState({
        isLoaded: true,
        rows: rows,
      });
    } catch (error) {
      this.setState({
        isLoaded: true,
        error,
      });
    }
  }

  async refetch() {
    let { rows, changeId } = this.state;
    const { projectId } = this.props;
    try {
      const image = (await api.get(`/dppapi/labeling/${projectId}/images/${changeId}`)).data;
      if (image.id) {
        rows = rows.map(row => {
          if (image.id === row.id) {
            return this.imagesToRows(projectId, image);
          } else {
            return row;
          }
        })
      } else {
        rows = rows.filter(row => {
          return changeId !== row.id;
        })
      }
      this.setState({
        isLoaded: true,
        rows: rows,
        changeId: null,
      });
    } catch (error) {
      this.setState({
        isLoaded: true,
        error,
        changeId: null,
      });
    }
  }

  imagesToRows(projectId, image) {
    const isInCharge = this.state.user.name === image.userInChargeName;

    return Object.assign({}, image, {
      id: image.id,
      link: (
        <div style={{ width: '200px' }}>
          <a 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{ wordBreak: 'break-all' }} 
            href={`/dppapi${image.link}?token=${getCsrfToken()}`}
          >
            {image.originalName}
          </a>
        </div>
      ),
      labeled: (
        <Checkbox
          checked={!!image.labeled}
          label={image.labeled ? "YES" : "NO"}
          onChange={(e, { checked }) => this.handleLabeled(image.id, Number(checked))}
        />
      ),
      locked: (
        <Checkbox
          checked={!!image.locked}
          label={image.locked ? "YES" : "NO"}
          onChange={(e, { checked }) => this.handleLock(image.id, Number(checked))}
          disabled={!this.state.isAdmin && !isInCharge}
        />
      ),
      labeled_text: image.labeled ? "YES" : "NO",
      labelData: JSON.stringify(image.labelData),
      chargeUser: image.userInChargeName,
      reviewer: image.reviewByName,
      actions: (
        <div>
          <Button
            icon="user"
            label="担当"
            size="tiny"
            onClick={() => this.handleCheckout(image.id, 'manage')}
            disabled={image.locked}
          />
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={`/label/${projectId}/${image.id}`}
          >
            <Button icon="pencil" label="編集" size="tiny" />
          </Link>
          <Button
            icon="trash"
            label="削除"
            size="tiny"
            onClick={() => this.handleDel(image.id)}
            disabled={image.locked}
          />
          <Button
            icon="user"
            label="レビュー"
            size="tiny"
            onClick={() => this.handleCheckout(image.id, 'review')}
            disabled={image.locked}
          />
          <Button
            icon="download"
            label="ダウンロード"
            size="tiny"
            onClick={() => this.handleCsvDownload(image.id)}
          />
        </div>
      )
    })
  }

  async handleLabeled(imageId, labeled) {
    const { projectId } = this.props;
    await api.patch(`/dppapi/labeling/${projectId}/images/${imageId}`, { labeled: !!labeled});
    this.setState({
      changeId: imageId,
    });
    this.refetch();
  }

  async handleLock(imageId, locked) {
    const { projectId } = this.props;
    await api.patch(`/dppapi/labeling/${projectId}/images/${imageId}`, { locked: !!locked});
    this.setState({
      changeId: imageId
    });
    this.refetch();
  }

  async handleCheckout(imageId, opration) {
    const { projectId } = this.props;
    confirmAlert({
      title: opration === 'manage' ? intl.get('_label.image.Images.Changeperson') : intl.get('_label.image.Images.ChangeReviewer'),
      message: opration === 'manage' ? intl.get('_label.image.Images.Areyousure') : intl.get('_label.image.Images.reviewConfirm'),
      buttons: [
        {
          label: intl.get('_label.image.Images.OK'),
          onClick: async () => {
            await api.post(`/dppapi/labeling/${projectId}/images/checkout/${imageId}`, {opration: opration});
            this.setState({
              changeId: imageId,
            });
            this.refetch();
          },
        },
        {
          label: intl.get('_label.image.Images.Cancel'),
        },
      ],
    })
  }

  async handleDel(imageId) {
    const { projectId } = this.props;
    confirmAlert({
      title: intl.get('_label.image.Images.Delete'),
      message: intl.get('_label.image.Images.Are you sure to do this?'),
      buttons: [
        {
          label: intl.get('_label.image.Images.Delete'),
          onClick: async () => {
            await api.delete(`/dppapi/labeling/${projectId}/images/${imageId}`);
            this.setState({
              changeId: imageId,
            });
            this.refetch();
          },
        },
        {
          label: intl.get('_label.image.Images.Cancel'),
        },
      ],
    })
  }

  async handleCsvDownload(imageId) {
    const rslt = await api.get(`/dppapi/labeling/csv/${imageId}`);
    downloadCsv(
      rslt.data,
      { header: false },
      `labeling_image_${imageId}.csv`
    );
  }

  async markAllNotLabeled() {
    this.setState({ isLoaded: false });
    const { projectId } = this.props;
    await api.post(`/dppapi/labeling/${projectId}/images/all`, {
      labeled: false, 
      projectId: projectId,
    });
    this.load();
  }

  render() {
    const  { rows, error, isLoaded } = this.state
    const columns = [
      {
        label: intl.get('_label.image.Images.ID'),
        field: 'id',
        sort: 'asc',
        width: 50
      },
      {
        label: intl.get('_label.image.Images.ImageLink'),
        field: 'link',
        sort: 'disabled',
        width: 200
      },
      {
        label: intl.get('_label.image.Images.LabelStatus'),
        field: 'labeled',
        sort: 'disabled',
        width: 80
      },
      {
        label: intl.get('_label.image.Images.LockedStatus'),
        field: 'locked',
        sort: 'disabled',
        width: 80
      },
      {
        label: intl.get('_label.image.Images.Person'),
        field: 'chargeUser',
        sort: 'disabled',
        width: 80
      },
      {
        label: intl.get('_label.image.Images.Review'),
        field: 'reviewer',
        sort: 'disabled',
        width: 80
      },
      {
        label: intl.get('_label.image.Images.Actions'),
        field: 'actions',
        sort: 'disabled',
        width: 160
      }
    ]
    const data = {
      columns: columns,
      rows: rows
    }

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <Loader active inline="centered" />;
    }

    return (
      <div className="project-images">
        <Segment>
          <Button.Group floated='right'>
            <Button icon onClick={this.load}>
              <Icon name='refresh' />
            </Button>
          </Button.Group>
          <MDBDataTable
            theadColor="common-search-table-header"
            btn
            striped
            bordered
            hover
            entries={20}
            data={data}
            disableRetreatAfterSorting={true}
          />
        </Segment>
        {/* <Button.Group floated='right'>
          <Button icon="cancel"
            label="Mark all images as 'Unlabeled'"
            onClick={this.markAllNotLabeled}/>
        </Button.Group> */}
      </div>
    )
  }
}

