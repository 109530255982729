import React, { Component } from 'react';
import { MDBDataTable } from 'mdbreact';
import { Button, Icon, Segment } from 'semantic-ui-react';
import { confirmAlert } from 'react-confirm-alert';
import api from '../../api'

const columns = [
  {
    label: 'Tenant ID',
    field: 'tenantId',
    sort: 'asc'
  },
  {
    label: 'User ID',
    field: 'userId',
    sort: 'asc'
  },
  {
    label: 'User Name',
    field: 'userName',
    sort: 'asc'
  },
  {
    label: 'Ip Address',
    field: 'ip',
    sort: 'asc',
  },
  {
    label: 'User Agent',
    field: 'userAgent',
    sort: 'asc',
  },
  {
    label: 'Online Time',
    field: 'onlineTime',
    sort: 'asc',
  },
  {
    label: 'Operation',
    field: 'operation',
    sort: 'asc',
  },
];

class OnlineUsersTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      rows: []
    }

    this.getUsers = this.getUsers.bind(this)
    this.handleKickOff = this.handleKickOff.bind(this)
  }

  async componentDidMount() {
    await this.getUsers()
  }

  async getUsers() {
    const onlineUsers = (await api.get('/dppapi/system/monitor/users')).data
    this.setState({
      rows: onlineUsers.map((user, index)=> {
        return {
          operation: (
            <div style={{width: 160}}>
              <Button
                icon="user close"
                label="Kick off"
                size="tiny"
                onClick={() => this.handleKickOff(user.sessionId)}
              />
            </div>
          ),
          ...user
        }
      }).sort((u1, u2) => {
        return u1.userId - u2.userId
      })
    })
  }

  handleKickOff(sessionId) {
    confirmAlert({
      title: 'Kick Off',
      message: 'Do you really want to kick off this user?',
      buttons: [
        {
          label: 'confirm',
          onClick: async () => {
            await api.post(`/dppapi/system/monitor/user/kickoff/${sessionId}`)
            this.getUsers()
          },
        },
        {
          label: 'cancel',
        },
      ],
    });
  }

  render() {
    const { rows } = this.state;
    const data = {
      columns: columns,
      rows: rows,
    };

    return (
      <Segment>
        <Button.Group floated="right">
          <Button icon onClick={this.getUsers}>
            <Icon name="refresh" />
          </Button>
        </Button.Group>
        <MDBDataTable theadColor="common-search-table-header" btn striped bordered hover data={data} />
      </Segment>
    );
  }
}

export default OnlineUsersTable;