import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import intl from 'react-intl-universal';
import api from '../../api'

export function withAssignees(Comp) {
  return class AssigneesLayer extends Component {

    constructor(props) {
      super(props);
      this.state = {
        userName: '',
        reviewName: '',
      };
      this.handleCheckout = this.handleCheckout.bind(this);
    }

    async componentDidMount() {
      const { projectId, imageId } = this.props;
      const image = (await api.get(`/dppapi/labeling/${projectId}/images/${imageId}`)).data;
      this.setState({ userName: image.userInChargeName, reviewName: image.reviewByName });
    }

    async handleCheckout(opration) {
      const { projectId, imageId } = this.props;
      confirmAlert({
        title: opration === 'manage' ? intl.get('_label.label-app.AssigneesHOC.Changeperson') : intl.get('_label.label-app.AssigneesHOC.ChangeReviewer'),
        message: opration === 'manage' ? intl.get('_label.label-app.AssigneesHOC.Areyousure') : intl.get('_label.label-app.AssigneesHOC.reviewConfirm'),
        buttons: [
          {
            label: intl.get('_label.label-app.AssigneesHOC.OK'),
            onClick: async () => {
              const image = (await api.post(`/dppapi/labeling/${projectId}/images/checkout/${imageId}`, {opration: opration})).data;
              this.setState({ userName: image.userInChargeName, reviewName: image.reviewByName });
            },
          },
          {
            label: intl.get('_label.label-app.AssigneesHOC.Cancel'),
          },
        ],
      })
    }
    render() {
      const { props, state, handleCheckout } = this;
      const { userName, reviewName } = state;
      return (
        <Comp 
          userName={userName}
          reviewName={reviewName}
          onCheckout={handleCheckout}
          {...props} 
        />
      );
    }
  }
}
