import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ReactBookDefault } from './svg/book_small_default.svg';
import { ReactComponent as ReactBookBlack } from './svg/book_small_black.svg';
import './icon.css';

export class SmallBookIcon extends Component {

  render() {
    const { color, className, disabled, size, isHover, onClick } = this.props;
    const clsDisable = disabled ? ' svg-icon-disable' : '';

    return isHover ? (
      <ReactBookBlack
        className={`svg-icon-wrapper ${className}${clsDisable}`}
        style={{ fill: color, width: size, height: size }}
        onClick={disabled ? () => {} : onClick}
      />
    ) :
    (
      <ReactBookDefault
        className={`svg-icon-wrapper ${className}${clsDisable}`}
        style={{ fill: color, width: size, height: size }}
        onClick={disabled ? () => {} : onClick}
      />
    )
  }
}

SmallBookIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

SmallBookIcon.defaultProps = {
  color: '#000000',
  className: '',
  size: '20px',
  disabled: false,
  onClick: () => {},
};
