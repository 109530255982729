import React, { Component } from 'react';
import { CRS } from 'leaflet';
import { Map, ImageOverlay, ZoomControl } from 'react-leaflet';
import { Link } from 'react-router-dom';
import Control from 'react-leaflet-control';
import Hotkeys from 'react-hot-keys';
import update from 'immutability-helper';
import Angle from './Angle';
import 'leaflet-path-drag';

import 'leaflet/dist/leaflet.css';

import { Icon, Grid } from 'semantic-ui-react';

import { BBoxFigure, PolygonFigure } from './Figure';

import { convertPoint, lighten, colorMapping } from './utils';
import { withBounds, maxZoom } from './CalcBoundsHOC';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './ReactConfirmAlert.css';
import intl from 'react-intl-universal';

class Canvas extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      zoom: -1,
      selectedFigureId: null,
      cursorPos: { lat: 0, lng: 0 },
      showTooltip: false,
      showGrid: false,
      isShowEdit: false,
      rotateFlag: false,
      rotateAngle: 0,
    };
    this.prevSelectedFigure = null;
    this.skipNextClickEvent = false;

    this.mapRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleShowTooltip = this.handleShowTooltip.bind(this);
    this.handleRrawGrid = this.handleRrawGrid.bind(this);
    this.getKeyByFigureId = this.getKeyByFigureId.bind(this);
    this.clearLinkId = this.clearLinkId.bind(this);
    this.handleRotate = this.handleRotate.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.imageOverlayRef = React.createRef();
    this.handleSetAngle = this.handleSetAngle.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { onSelectionChange } = this.props;
    const { selectedFigureId } = this.state;

    if (this.prevSelectedFigureId !== selectedFigureId && onSelectionChange) {
      this.prevSelectedFigureId = selectedFigureId;
      onSelectionChange(selectedFigureId);
    }
    if(this.state.rotateFlag){
      this.rotateImage();
    }
  }

  getSelectedFigure() {
    const { selectedFigureId } = this.state;
    const { figures } = this.props;
    return figures.find(f => f.id === selectedFigureId);
  }

  handleChange(eventType, { point, pos, figure, points, det_text, linkTo }) {
    const { onChange, unfinishedFigure } = this.props;
    const drawing = !!unfinishedFigure;

    switch (eventType) {
      case 'add':
        if (drawing) {
          let newState = unfinishedFigure.points;
          newState = update(newState, { $push: [point] });

          onChange(
            'unfinished',
            update(unfinishedFigure, {
              points: {
                $set: newState,
              },
            })
          );
        } else {
          onChange(
            'replace',
            update(figure, { points: { $splice: [[pos, 0, point]] } })
          );
        }
        break;

      case 'end':
        const f = unfinishedFigure;
        onChange('new', f);
        break;

      case 'move':
        onChange(
          'replace',
          update(figure, { points: { $splice: [[pos, 1, point]] } })
        );
        break;

      case 'replace':
        onChange('replace', update(figure, { points: { $set: points } }));
        break;

      case 'remove':
        onChange(
          'replace',
          update(figure, { points: { $splice: [[pos, 1]] } })
        );
        break;
      case 'detTextChange':
        onChange('replace', update(figure, { det_text: { $set: det_text } }));
        break;
      case 'linkIdChange':
        onChange('replace', update(figure, { linkTo: { $set: linkTo } }));
        break;

      default:
        throw new Error('unknown event type ' + eventType);
    }
  }

  handleClick(e) {
    const { unfinishedFigure } = this.props;
    const drawing = !!unfinishedFigure;

    if (this.skipNextClickEvent) {
      // a hack, for whatever reason it is really hard to stop event propagation in leaflet
      this.skipNextClickEvent = false;
      return;
    }

    if (drawing) {
      this.handleChange('add', { point: convertPoint(e.latlng) });
      return;
    }

    if (!drawing) {
      this.setState({ selectedFigureId: null });
      return;
    }
  }

  renderFigure(figure, options) {
    const Comp = figure.type === 'bbox' ? BBoxFigure : PolygonFigure;

    return (
      <Comp
        key={figure.id}
        figure={figure}
        options={options}
        skipNextClick={() => (this.skipNextClickEvent = true)}
        showTooltip={this.state.showTooltip}
        onLinking={this.props.handleLink}
        handleOcr={this.props.handleOcr}
        bounds={this.props.bounds}
        getKeyByFigureId={this.getKeyByFigureId}
        linkingFigure={this.props.linkingFigure}
        clearLinkId={this.clearLinkId}
      />
    );
  }
  handleShowTooltip() {
    this.setState({ showTooltip: !this.state.showTooltip });
  }

  clearLinkId(currentFigure) {
    this.handleChange('linkIdChange', { figure: currentFigure, linkTo: '' });
  }

  getKeyByFigureId(id) {
    const { figures } = this.props;
    let value = '';
    const figure = figures.find(f => {
      return f.linkTo && f.linkTo.indexOf(id) !== -1
    });
    if (figure) {
      value = figure.det_text;
    }
    return value;
  }

  toggleEdit(isShowEdit) {
    this.setState({
      isShowEdit: isShowEdit,
      rotateAngle: 0,
      rotateFlag: false,
    });
  }

  handleRotate() {
    this.setState({
      isShowEdit: true,
    });
  }

  delAllFiguresDialog = () => {
    confirmAlert({
      title: intl.get('_label.label-app.Canvas.Delete'),
      message: intl.get('_label.label-app.Canvas.Are you sure to do this?'),
      buttons: [
        {
          label: intl.get('_label.label-app.Canvas.Delete'),
          onClick: () => this.props.onDelAllFigures(),
        },
        {
          label: intl.get('_label.label-app.Canvas.Cancel'),
        },
      ],
      zIndex: 500,
    });
  };

  handleRrawGrid() {
    this.setState({
      showGrid: !this.state.showGrid,
    });
  }

  renderRrawGrid() {
    const rows = [2,2,2,2,2,2]
    return (
      <Grid celled style={{ zIndex: 401, position: 'absolute' }}>
        { 
        rows.map(d=>(
        <Grid.Row>
          <Grid.Column width={d} />
          <Grid.Column width={d} />
          <Grid.Column width={d} />
          <Grid.Column width={d} />
          <Grid.Column width={d} />
          <Grid.Column width={d} />
          <Grid.Column width={d} />
          <Grid.Column width={d} />
        </Grid.Row>))
        }
      </Grid>
    );
  }

  rotateImage() {
    const imageOverlayRef = this.imageOverlayRef.current.leafletElement;
    const image = imageOverlayRef.getElement();
    
    if (!this.originalTransformValue) {
      this.originalTransformValue = image.style.transform;
    }
    var tran = image.style.transform;
    tran = tran.indexOf("rotate")>-1?tran.substr(0,tran.indexOf("rotate")):tran;
    image.style.transform =  `${tran} rotate(${this.state.rotateAngle}deg)`;
    
  }

  handleSetAngle(angle){
    this.setState({rotateAngle: angle,rotateFlag: angle===0?false:true})
  }

  render() {
    const {
      url,
      bounds,
      height,
      width,
      figures,
      unfinishedFigure,
      onChange,
      onReassignment,
      style,
      selectedBoxIds,
      isLocked,
    } = this.props;
    const { zoom, selectedFigureId, cursorPos, showGrid } = this.state;

    const drawing = !!unfinishedFigure;

    const calcDistance = (p1, p2) => {
      const map = this.mapRef.current.leafletElement;
      return map.latLngToLayerPoint(p1).distanceTo(map.latLngToLayerPoint(p2));
    };
    const gridDOM = showGrid ? this.renderRrawGrid() : null;

    const unfinishedDrawingDOM = drawing
      ? this.renderFigure(unfinishedFigure, {
        finished: false,
        editing: false,
        interactive: false,
        color: colorMapping[unfinishedFigure.color],
        onChange: this.handleChange,
        calcDistance,
        newPoint: cursorPos,
        inSelectedBoxIds: false,
      })
      : null;

    const getColor = f =>
      f.tracingOptions && f.tracingOptions.enabled
        ? lighten(colorMapping[f.color], 80)
        : colorMapping[f.color];
    const figuresDOM = figures.map((f, i) =>
      this.renderFigure(f, {
        editing: selectedFigureId === f.id && !drawing,
        finished: true,
        interactive: !drawing,
        sketch: f.tracingOptions && f.tracingOptions.enabled,
        color: getColor(f),
        vertexColor: colorMapping[f.color],
        onSelect: () => this.setState({ selectedFigureId: f.id }),
        onChange: this.handleChange,
        calcDistance,
        inSelectedBoxIds: selectedBoxIds.indexOf(f.id) !== -1,
      })
    );

    const hotkeysDOM = (
      <Hotkeys
        keyName="backspace,del,c,f,-,=,left,right,up,down"
        onKeyDown={key => {
          const tagName = document.activeElement
            ? document.activeElement.tagName.toLowerCase()
            : null;
          if (['input', 'textarea'].includes(tagName)) {
            return false;
          }
          if (drawing) {
            if (key === 'f') {
              const { type, points } = unfinishedFigure;
              if (type === 'polygon' && points.length >= 3) {
                this.handleChange('end', {});
              }
            }
          } else {
            if (key === 'c') {
              if (selectedFigureId && this.getSelectedFigure()) {
                onReassignment(this.getSelectedFigure().type);
              }
            } else if (key === 'backspace' || key === 'del') {
              if (selectedFigureId && this.getSelectedFigure()) {
                //check linkTo before delete
                const figure = this.props.figures.find(
                  f => f.linkTo === selectedFigureId
                );
                if (figure) {
                  this.handleChange('linkIdChange', {
                    figure: figure,
                    linkTo: '',
                  });
                }

                onChange('delete', this.getSelectedFigure());
              }
            }
          }

          const map = this.mapRef.current.leafletElement;
          if (key === 'left') {
            map.panBy([80, 0]);
          }
          if (key === 'right') {
            map.panBy([-80, 0]);
          }
          if (key === 'up') {
            map.panBy([0, 80]);
          }
          if (key === 'down') {
            map.panBy([0, -80]);
          }
          if (key === '=') {
            map.setZoom(map.getZoom() + 1);
          }
          if (key === '-') {
            map.setZoom(map.getZoom() - 1);
          }
        }}
      />
    );

    let renderedTrace = null;
    const selectedFigure = this.getSelectedFigure();
    if (selectedFigure && selectedFigure.type === 'polygon') {
      const trace = selectedFigure.tracingOptions
        ? selectedFigure.tracingOptions.trace || []
        : [];
      const figure = {
        id: 'trace',
        type: 'line',
        points: trace,
      };
      const traceOptions = {
        editing: false,
        finished: true,
        color: colorMapping[selectedFigure.color],
      };
      renderedTrace = (
        <PolygonFigure
          figure={figure}
          options={traceOptions}
          skipNextClick={() => (this.skipNextClickEvent = true)}
          showTooltip={this.state.showTooltip}
          onLinking={this.props.handleLink}
          getKeyByFigureId={this.getKeyByFigureId}
          linkingFigure={this.props.linkingFigure}
          clearLinkId={this.clearLinkId} />
      );
    }

    return (
      <div
        style={{
          cursor: drawing ? 'crosshair' : 'grab',
          height: '100%',
          ...style,
        }}
      >
        <Map
          crs={CRS.Simple}
          zoom={zoom}
          minZoom={-50}
          maxZoom={maxZoom}
          center={[height / 2, width / 2]}
          zoomAnimation={false}
          zoomSnap={0.1}
          zoomDelta={0.1}
          zoomControl={false}
          keyboard={false}
          attributionControl={false}
          onClick={this.handleClick}
          onZoom={e => this.setState({ zoom: e.target.getZoom() })}
          onMousemove={e => this.setState({ cursorPos: e.latlng })}
          ref={this.mapRef}
        >
          {isLocked ? (
            <div>
              <ZoomControl position="bottomright" />
              <Control className="leaflet-bar" position="bottomright">
                <Link
                  title="Zoom reset"
                  to="#"
                  onClick={() => {
                    const map = this.mapRef.current.leafletElement;
                    map.setView(map.options.center, map.options.zoom);
                  }}
                >
                  <Icon name="expand" fitted style={{ fontSize: '1.2em' }} />
                </Link>
              </Control>
              <ImageOverlay url={url} bounds={bounds} ref={this.imageOverlayRef}/>
            </div>
          ) : (
            <div>
              <ZoomControl position="bottomright" />
              <Control className="leaflet-bar" position="bottomright">
                <Link
                  title="Zoom reset"
                  to="#"
                  onClick={() => {
                    const map = this.mapRef.current.leafletElement;
                    map.setView(map.options.center, map.options.zoom);
                  }}
                >
                  <Icon name="expand" fitted style={{ fontSize: '1.2em' }} />
                </Link>
              </Control>
              <Control className="leaflet-bar" position="bottomright">
                <Link
                  title="show tooltip"
                  to="#"
                  onClick={() => {
                    this.handleShowTooltip();
                  }}
                >
                  <Icon
                    name="lightbulb outline"
                    fitted
                    style={{ fontSize: '1.2em' }}
                  />
                </Link>
              </Control>
              <Control className="leaflet-bar" position="bottomright">
                <Link
                  title="Delete all bbox"
                  to="#"
                  onClick={() => {
                    this.delAllFiguresDialog();
                  }}
                >
                  <Icon
                    name="times rectangle"
                    fitted
                    style={{ fontSize: '1.2em' }}
                  />
                </Link>
              </Control>
              <Control className="leaflet-bar" position="bottomright">
                <Link
                  title="rotate"
                  to="#"
                  onClick={() => {
                    this.handleRotate();
                  }}
                >
                  <Icon name="redo" fitted style={{ fontSize: '1.2em' }} />
                </Link>
              </Control>
              <Control className="leaflet-bar" position="bottomright">
                <Link
                  title="showGrid"
                  to="#"
                  onClick={() => {
                    this.handleRrawGrid();
                  }}
                >
                  <Icon name="grid layout" fitted style={{ fontSize: '1.2em' }} />
                </Link>
              </Control>
              <ImageOverlay url={url} bounds={bounds} ref={this.imageOverlayRef}/>
              {unfinishedDrawingDOM}
              {renderedTrace}
              {figuresDOM}
              {hotkeysDOM}
              {gridDOM}
            </div>
          )}
        </Map>
        <Angle
          isShowEdit={this.state.isShowEdit}
          toggleEdit={this.toggleEdit}
          rotate={this.props.rotate}
          url={this.props.url}
          handleSetAngle={this.handleSetAngle}
          angle={this.state.rotateAngle}
        />
      </div>
    );
  }
}

export default withBounds(Canvas);
