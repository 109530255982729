import React, { Component } from 'react';
import { Segment, Button, Loader, Input, TextArea, Dropdown } from 'semantic-ui-react';
import { confirmAlert } from 'react-confirm-alert';
import update from 'immutability-helper';
import api from '../api'
import intl from 'react-intl-universal';

export default class ApiEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      apiInfo: [],
      users: [],
      message: null,
    };
  }

  async componentDidMount() {
    const { apiInfoId } = this.props.match.params;
    this.getUsers();
    let apiInfo = { name: "", descripe: "", aiProcesses: { bbox: "General", ocr: "General" }, users: [] };
    if (apiInfoId) {
      apiInfo = (await api.get('/dppapi/easyOcr/' + apiInfoId)).data;
    }
    this.setState({
      isLoaded: true,
      apiInfo,
    });
  }

  getUsers = async () => {
    const { data: users } = await api.get('/dppapi/easyOcr/search/users')
    this.setState({
      users: users.map(user => {
        return {
          key: user.id,
          value: user.id,
          text: user.email
        }
      })
    })
  }

  handleTextChange = (e, type) => {
    const { value } = e.target;
    const { apiInfo } = this.state;
    if (type === 'name') {
      this.setState({
        apiInfo: update(apiInfo, {
          name: {
            $set: value,
          },
        }),
      });
    } else {
      this.setState({
        apiInfo: update(apiInfo, {
          descripe: {
            $set: value,
          },
        }),
      });
    }
  }

  handleProcessChange = (e, { value }, type) => {
    const { apiInfo } = this.state;
    let { aiProcesses } = apiInfo;
    if (type === 'bbox') {
      aiProcesses.bbox = value;
    } else {
      aiProcesses.ocr = value;
    }
    this.setState({
      apiInfo: update(apiInfo, {
        aiProcesses: {
          $set: aiProcesses,
        },
      }),
    });
  }

  handleUserChange = (e, { value }) => {
    const { apiInfo } = this.state;
    this.setState({
      apiInfo: update(apiInfo, {
        users: {
          $set: value,
        },
      }),
    })
  }

  handleSave = () => {
    const { apiInfo } = this.state;
    const { apiInfoId } = this.props.match.params;
    const { history } = this.props;
    if (!apiInfo.name) {
      this.setState({
        message: 'please enter the name'
      })
      return;
    }
    this.setState({ message: null });
    confirmAlert({
      title: intl.get('_easy-ocr.ApiEdit.Save'),
      message: intl.get('_easy-ocr.ApiEdit.Do you want to save?'),
      buttons: [
        {
          label: intl.get('_easy-ocr.ApiEdit.Save'),
          onClick: async () => {
            if (apiInfoId) {
              await api.patch('/dppapi/easyOcr/' + apiInfoId, { apiInfo: apiInfo });
            } else {
              await api.post('/dppapi/easyOcr/', { apiInfo: apiInfo });
            }
            history.push(`/easyocr/`);
          },
        },
        {
          label: intl.get('_easy-ocr.ApiEdit.Cancel'),
        },
      ],
    });
  }

  render() {
    const { history } = this.props;
    const { isLoaded, apiInfo } = this.state;
    const { users } = this.state;
    const { message } = this.state;
    if (!isLoaded) {
      return <Loader active inline="centered" />;
    }
    const stateOptions = [
      { key: '0', text: 'General', value: 'General' },
      { key: '1', text: '決算書', value: '決算書' }
    ]

    const nbsp = " "
    return (
      <Segment>
        <div style={{ width: '100%', height: 'auto' }}>
          <div style={{ width: '12%', float: 'left' }}><font style={{ fontSize: 'x-large', color: 'black', fontWeight: '400' }}>Name </font></div>
          <div style={{ width: '88%', float: 'left' }}>
            <Input
              placeholder="Name"
              control="input"
              defaultValue={apiInfo.name}
              style={{ fontSize: 15, width: '40%' }}
              onChange={(e) => this.handleTextChange(e, 'name')}
            />
          </div>
          <div style={{color: 'red', width: '100%', marginTop: 10, float: 'right', paddingLeft: 130}}>{message}</div>
          <div style={{ clear: 'both' }}></div>
        </div>
        <div style={{ width: '12%', float: 'left', marginTop: 10 }}><font style={{ fontSize: 'x-large', color: 'black', fontWeight: '400' }}>Description</font></div>
        <div className="ui form" style={{ marginTop: 10 }}>
          <TextArea
            style={{ fontSize: 16, padding: 22, width: '88%' }}
            placeholder="Description"
            defaultValue={apiInfo.descripe}
            name="description" rows="9"
            onChange={(e) => this.handleTextChange(e, 'description')}
            maxLength="256" />
        </div>
        <div style={{ width: '12%', float: 'left', height: '40px', marginTop: 10 }}>
          <font style={{ fontSize: 'x-large', color: 'black', fontWeight: '400' }}>Processes</font>
        </div>
        <div className="ui form" style={{ marginTop: 20 }}>
          <font style={{ fontSize: 'medium', color: 'black', fontWeight: '300' }}>文言枠識別: </font>
          <Dropdown
            selection options={stateOptions}
            value={apiInfo.aiProcesses.bbox}
            onChange={(e, value) => this.handleProcessChange(e, value, 'bbox')} />
        </div>
        <div style={{ width: '12%', float: 'left', height: '40px' }}><font style={{ fontSize: 'x-large', color: 'black', fontWeight: '400' }}>{nbsp}</font></div>
        <div className="ui form" style={{ marginTop: 10 }}>
          <font style={{ fontSize: 'medium', color: 'black', fontWeight: '300', marginRight: '48px' }}>OCR: </font>
          <Dropdown
            selection options={stateOptions}
            value={apiInfo.aiProcesses.ocr}
            onChange={(e, value) => this.handleProcessChange(e, value, 'ocr')} />
        </div>
        <div style={{ width: '12%', float: 'left', height: '40px', marginTop: 20 }}><font style={{ fontSize: 'x-large', color: 'black', fontWeight: '400' }}>Users</font></div>
        <div className="ui form" style={{ marginTop: 20 }}>
          <Dropdown
            placeholder='Users'
            selection
            multiple
            options={users}
            defaultValue={apiInfo.users}
            onChange={this.handleUserChange} />
        </div>
        <div className="ui form" style={{ marginTop: 20 }}>
          <Button floated='right' onClick={() => history.push(`/easyocr/`)}>{intl.get('_easy-ocr.ApiEdit.Cancel')}</Button>
          <Button floated='right' onClick={this.handleSave}>{intl.get('_easy-ocr.ApiEdit.Save')}</Button>
          <div style={{ clear: 'both' }}></div>
        </div>
      </Segment>
    );
  }
}
