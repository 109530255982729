import React from 'react'
import ExtraInfoNewUI from './ExtraInfoNewUI'
import CsvExtraInfo from './CsvExtraInfo'
// import DialogBodyGenerator from './DialogBodyGenerator'
import DialogBodyGeneratorNewUI from './DialogBodyGeneratorNewUI'

export default function ExtraInfoFactory(extraInfo, handleOperateDialog, pdfId, projectId, callback, type, pdfInfo, actions, setResultFunctionParam = null) {
  switch (type) {
    // case "common":
    //   return <DialogBodyGenerator items={extraInfo} handleOperateDialog={handleOperateDialog} pdfInfo={pdfInfo} actions={actions} />
    // case "rpa":
    //   return <ExtraInfo items={extraInfo} handleOperateDialog={handleOperateDialog} pdfId={pdfId} projectId={projectId} callback={callback} />
    case "rpa":
      return <ExtraInfoNewUI items={extraInfo} handleOperateDialog={handleOperateDialog} pdfId={pdfId} projectId={projectId} callback={callback} setResultFunctionParam={ setResultFunctionParam}/>
    case "csv":
      return <CsvExtraInfo items={extraInfo} handleOperateDialog={handleOperateDialog} pdfId={pdfId} projectId={projectId} callback={callback} />   
    case "common":
        return <DialogBodyGeneratorNewUI items={extraInfo} handleOperateDialog={handleOperateDialog} pdfInfo={pdfInfo} actions={actions} />
    default:
      return <ExtraInfoNewUI items={extraInfo} handleOperateDialog={handleOperateDialog} pdfId={pdfId} projectId={projectId} callback={callback} setResultFunctionParam={ setResultFunctionParam}/>
  }
}