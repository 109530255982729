import React, { Component } from 'react';
import { Container, Menu, Header } from 'semantic-ui-react';
import ApiTable from './ApiTable';
import intl from 'react-intl-universal';
import { cryptoDecrypt } from '../common/EncryptUtil';


export default class EasyOcrTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'list',
    };
  }

  handleSwitchTab = label => {
    this.setState({ activeTab: label });
  };

  render() {
    const { activeTab } = this.state;
    const { history } = this.props;
    const user = JSON.parse(cryptoDecrypt(localStorage.getItem("user")));
    const tabs = [
      {
        label: intl.get('_easy-ocr.EasyOcrTab.EasyOcrList'),
        component: 'list',
      },
    ];
    return (
      <div>
        {user && user.tenantName ? (
          <Header as="h1">{user.tenantName}</Header>
        ) : null}
        <Container>
          <Menu pointing secondary>
            {tabs.map(tab => {
              return (
                <Menu.Item
                  name={tab.label}
                  component={tab.component}
                  active={activeTab === tab.component}
                  onClick={() => this.handleSwitchTab(tab.component)}
                  key={tab.label}
                />
              );
            })}
          </Menu>
          {activeTab === 'list' && <ApiTable history={history} />}
        </Container>
      </div>
    );
  }
}
