import React from 'react';

import { MDBModal, MDBModalBody } from 'mdbreact';
import {
  Button,
  Checkbox
} from 'semantic-ui-react';
import intl from 'react-intl-universal';
import api from '../../api';
import { getStateFlag } from '../Constant';
import { ErrorIcon } from '../../assets/icon';
import _ from 'lodash';
import { AnalyticsServiceContext } from '../../common/Analytics/AnalyticsServiceProvider';
import { EventName, EventType, PageName } from '../../common/Analytics/analyticsMetrics';

export default class FileListDelete extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      confirmFlg: false
    }

    this.handleDelete = this.handleDelete.bind(this);
  }
  static contextType = AnalyticsServiceContext;

  async componentDidMount(){
    const data = await this.getCurrentUser();
    this.setState({ currentUser: data })
  }

  async handleDelete(fileInfoList) {
    this.props.onLoading(true);
    this.props.toggleEdit(false);
    let pdfFileIds = fileInfoList.map(d=>{
      return d.id;
    })
    await api.post(`/dppapi/predict/delete/${this.props.projectId}`, {pdfFileIds: pdfFileIds});
    this.context.sendMetrics({
      page_name: PageName.PredictPage,
      event_type: EventType.Action,
      event_name: EventName.DeleteFile,
      custom_parameter: {
        file_list : pdfFileIds
      },
    })
    this.setState({
      confirmFlg: false
    });
    await this.props.refetch();
    this.props.onLoading(false);
    this.props.onCompleted('1', 1)
  }

  getStateText = (state) => {
    let pdfSettingInfo = {};
    if (this.props.statusTexts && this.props.statusTexts.data && this.props.statusTexts.data.success === true) {
      pdfSettingInfo = this.props.statusTexts.data.value;
      pdfSettingInfo = pdfSettingInfo && JSON.parse(this.props.statusTexts.data.value);
    }
    return pdfSettingInfo ? pdfSettingInfo[state].text : getStateFlag(state);
  }

  getCurrentUser = async () => {
    // get user info
    const info = await (await api.get(`/dppapi/info`)).data;
    return info;
  }

  render() {
    const { confirmFlg, currentUser } = this.state;
    let fileInfoList = []
    let filesWithOtherUserAsInCharge = false;
    Object.keys(this.props.selectList).forEach(id=>{
      let fileInfo = this.props.selectList[id];
      fileInfoList.push({
        id: id,
        name: fileInfo[0],
        state: fileInfo[1],
        time: fileInfo[2]
      })

      if (fileInfo[4] && fileInfo[4] !== currentUser.id) filesWithOtherUserAsInCharge = true;
    })
    let selectTextList = [];
    let editFlg = false;
    fileInfoList.sort((a, b) => {
      if (Number(a.state) === Number(b.state)) {
        return a.time < b.time ? 1 : -1;
      }
      if (Number(a.state) === 4 || Number(b.state) === 4) {
        return Number(a.state) === 4 ? 1 : -1;
      } else {
        return Number(a.state) > Number(b.state) ? 1 : -1;
      }
    })
    const maxCount = _.maxBy(fileInfoList, (item) => {
      if(this.getStateText(item.state)){
        return parseInt(this.getStateText(item.state).length, 10);
      } else {
        return parseInt(0, 10);
      }
    });
    const stateText = maxCount && this.getStateText(maxCount.state);
    const autoWidth = (maxCount ? stateText.length <= 10 ? 92 - (stateText ? stateText.length - 1 : 0) * 3 : 65 : 92) + '%';
    fileInfoList.forEach(d => {
      if (Number(d.state) < 4) editFlg = true;
      selectTextList.push(
        <div key={d.id} className='file-inner-body special-width'>
          <div className='file-inner-left' style={{ width: autoWidth }}>{d.name} </div>
          <div className='file-inner-right'>{this.getStateText(d.state)}</div>
        </div>);
    });
    return (
      <div style={{cursor:'default'}}>
        <MDBModal
          isOpen={this.props.isShowEdit}
          toggle={this.props.toggleEdit}
          centered={true}
          size={'sm'}
          fade={false}
          overflowScroll={false}
          wrapClassName={ "cus"}
          className={"model-delete-center"}
          >
          <MDBModalBody className="file-delete-body">
              <div className='file-delete-body-title'><h1>{'ファイルを削除'}</h1></div>
              <div className='file-delete-scroll'>
                <div style={{marginLeft:'18px'} } className='fileInfoList'><span >{selectTextList.length}</span> 件 のファイルとその編集内容を削除します。</div>
                <div className='file-delete-body-inner'>
                  {selectTextList}
                </div>
              <div className="file-delete-body-icon">
                <ErrorIcon color='#E8BA00' size='24px'/>削除するとファイルは復元できません
              </div>
                {filesWithOtherUserAsInCharge && <div className="file-delete-body-icon">
                  <ErrorIcon color='#E8BA00' size='24px' />他のユーザーが担当者として設定されたファイルが含まれています
                </div>}
                {editFlg && <div className="file-delete-body-icon">
                <ErrorIcon color='#E8BA00' size='24px'/>編集が完了していないファイルが含まれています
                </div>}
                <div className="file-delete-body-check">
                  <><Checkbox key="confirm" id="confirm" checked={confirmFlg} onChange={(e, value) => this.setState({confirmFlg: value.checked })}/><label htmlFor='confirm'>
                    <span className='file-check-text'>上記の注意事項を確認しました</span></label></>
                </div>
              </div>
              <div className='custom-ui-confirm-alert-button-group'>
                <Button
                  className='primary-btn confirmAlert-button-right'
                  disabled={!confirmFlg}
                  onClick={() => {this.handleDelete(fileInfoList)}}
                >{'削除'}
                </Button>
                <button
                  className='predict-admin-botton-text confirmAlert-button-left'
                  onClick={() => {
                    this.setState({
                      confirmFlg: false
                    });
                    this.props.toggleEdit(false);
                  }}>
                  {intl.get('_predict.detail.mapping.Close')}
                </button>
              </div>
          </MDBModalBody>
        </MDBModal>
      </div>
    );
  }
}
