import React, { Component } from 'react'
import {
  Button,
  Form,
  Message
} from 'semantic-ui-react';
import intl from 'react-intl-universal';
import api from '../../api'
import SparkMD5 from 'spark-md5'
import Progress from './Progress'

export default class ZipUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      chunkSize: 5 * 1024 * 1024,
      fileSize: 0,
      file: null,
      errmsg: null,
      fileIndex: 0,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    const [file] = e.target.files;
    if (!file) {
      return
    }
    if(file.size > 200 * 1024 * 1024){
      this.setState({
        file: null,
        fileIndex: 0,
        errmsg: intl.get('_label.image.ZipUpload.SizeLimit')
      });
      return
    }
    this.setState({ 
      file, 
      fileSize: file.size,
      fileIndex: 0,
      errmsg: null
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    if (!this.state.file) {
      return
    }
    this.setState({
      errmsg: null
    });
    const form = e.target;
    this.setState({ 
      fileIndex: 5
    });
    // split
    let fileMD5 = await this.fileSplit();
    this.setState({ 
      fileIndex: 10
    });
    // Chunk list
    let resp = await this.findChunk(fileMD5);
    this.setState({ 
      fileIndex: 20
    });
    if (!resp.file) {
      // upload
      await this.uploadChunk(fileMD5, resp.chunkList);
      // merge
      await this.merge(fileMD5);
    }
    this.setState({ 
      fileIndex: 80
    });
    // unzip
    const resUncompress = await this.uncompress();
    if (!resUncompress.success) {
      this.setState({ 
        errmsg: resUncompress.errmsg
      });
      return;
    }
    
    this.setState({ 
      fileIndex: 90
    });
    // upload labeling
    const resUpload = await this.uploadByZip(resUncompress);
    if (!resUpload.success) {
      this.setState({ 
        errmsg: resUpload.errmsg
      });
    }
    this.setState({
      fileIndex: 100
    });
    form.reset();
  }

  fileSplit() {
    const {file, fileSize, chunkSize} = this.state
    return new Promise((resolve) => {
      var blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice,
          chunks = Math.ceil(fileSize / chunkSize),
          curChunk = 0,
          spark = new SparkMD5.ArrayBuffer(),
          fileReader = new FileReader();

      
      fileReader.onload = function(e) {
        spark.append(e.target.result);
        curChunk += 1;

        if (curChunk > chunks) {
          resolve(spark.end());
        } else {
          loadNext();
        }
      }

      function loadNext() {
        var start = curChunk * chunkSize,
            end = ((start + chunkSize) > fileSize) ? fileSize : start + chunkSize;
        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
      }

      loadNext();
    })
  }

  async findChunk(fileMD5) {
    return await(await api.get(`/dppapi/labeling/${this.props.projectId}/uploads/zip/check?fileName=${this.state.file.name}&fileMD5=${fileMD5}`)).data;
  }

  async uploadChunk(fileMD5, chunkList) {
    const { fileSize, chunkSize } = this.state
    let chunks = Math.ceil(fileSize / chunkSize);
    let block = Math.ceil(50 / chunks);
    for(let i = 0; i < chunks; i++) {
      let exit = chunkList.indexOf(i + '') > -1;
      if (!exit) {
        await this.upload(i, fileMD5);
      }
      this.setState({ 
        fileIndex: 20 + block * (i + 1)
      });
    }
  }

  async upload(i, fileMD5) {
    const {file, fileSize, chunkSize} = this.state
    let end = (i + 1) * chunkSize >= fileSize ? fileSize : (i + 1) * chunkSize;
      let form = new FormData();
      let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
      form.append('chunk', blobSlice.call(file, i * chunkSize, end));
      form.append('fileMD5', fileMD5);
      form.append('folder', i);
      return await api.post(`/dppapi/labeling/${this.props.projectId}/uploads/zip/chunk`, form);
  }

  async merge(fileMD5) {
    return await(await api.get(`/dppapi/labeling/${this.props.projectId}/uploads/zip/merge?fileName=${this.state.file.name}&fileMD5=${fileMD5}`)).data;
  }

  async uncompress() {
    return await(await api.get(`/dppapi/labeling/${this.props.projectId}/uploads/zip/unzip?fileName=${this.state.file.name}`)).data;
  }

  async uploadByZip({ folderPath, imagePaths }) {
    return await(await api.post(`/dppapi/labeling/${this.props.projectId}/uploads/zip/upload`, {
      folderPath,
      imagePaths,
    })).data;
  }

  render() {
    const { fileIndex, errmsg } = this.state;
    const message = errmsg ? (
      <Message negative>{errmsg}</Message>
    ) : null;
    return (
      <div>
        <Form 
          method="post"
          encType="multipart/form-data"
          onSubmit={this.handleSubmit} >
          <Form.Input
            label={intl.get('_label.image.ZipUpload.UploadZipFile')}
            type="file"
            id="file"
            name="file"
            accept=".zip, .tar"
            onChange={this.onChange}
          />
          <Button type="submit" >{intl.get('_label.image.ZipUpload.Upload')}</Button>
        </Form>
        {fileIndex > 0 && 
        <div>
          {fileIndex < 80 && intl.get('_label.image.ZipUpload.Loading')}
          {fileIndex === 80 && intl.get('_label.image.ZipUpload.Unzipping')}
          {fileIndex === 90 && intl.get('_label.image.ZipUpload.Uploading')}
          {fileIndex === 100 && intl.get('_label.image.ZipUpload.Completed')}
          <Progress nums={100} index={fileIndex} progressColor='#0077FF'/>
        </div>}
        {message}
      </div>
    )
  }
}

