import React, { Component } from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact';
import {
  Form,
  Button,
  Icon,
  Loader,
  Message,
} from 'semantic-ui-react';
import CSV from 'comma-separated-values';
import { chunk } from 'lodash';
import api from '../../api';

class FsItemImport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loadingText: '',
      error: '',
    };
  }

  handleImport = async () => {
    const fileEle = document.getElementById('fsItemsCsv');
    if (fileEle.files && fileEle.files[0]) {
      this.setState({ loading: true });

      const csvRslt = await new Promise((resolve) => {
        const file = fileEle.files[0];
        const fileReader = new FileReader();
        fileReader.onload = () => {
          resolve(CSV.parse(fileReader.result, { cast: ['Number', 'String', 'String', 'String', 'String'] }));
        };
        fileReader.readAsText(file);
      });
      const checkSizeOne = csvRslt.findIndex( d =>  d.length !== 5);
      if(checkSizeOne >= 0) {
        this.setState({ error: 'Line ' + (checkSizeOne + 1) +' has ' + csvRslt[checkSizeOne].length + ' items. 5 items expected for each line.', loading:false})
        return;
      }
      const checkSizeTwo = csvRslt.findIndex( d =>  d.includes('undefined') > 0);
      if(checkSizeTwo >= 0) {
        this.setState({ error: 'Line ' + (checkSizeTwo + 1) +' has ' + csvRslt[checkSizeTwo].findIndex( d => d === 'undefined') + ' items. 5 items expected for each line.', loading:false})
        return;
      }
      const checkId = csvRslt.findIndex( d =>  isNaN(d[0]));
      if(checkId >= 0) {
        this.setState({ error: 'Column' + (checkId + 1) +' : ID must be a Number , may be is not a global setting file', loading:false})
        return;
      }
      const checkSubTitle = csvRslt.findIndex( d =>  d[1]!=='' && !isNaN(Number(d[1])));
      if(checkSubTitle >= 0) {
        this.setState({ error: 'Column' + (checkSubTitle + 1) +' : Sub Title must be a String , may be is not a global setting file', loading:false})
        return;
      }
      const checkItem = csvRslt.findIndex( d =>  d[2]!=='' && !isNaN(Number(d[2])));
      if(checkItem >= 0) {
        this.setState({ error: 'Column' + (checkItem + 1) +' : Item must be a String , may be is not a global setting file', loading:false})
        return;
      }
      const checkGlobalResultItem = csvRslt.findIndex( d =>  d[4]!=='' && !isNaN(Number(d[4])));
      if(checkGlobalResultItem >= 0) {
        this.setState({ error: 'Column' + (checkGlobalResultItem + 1) +' : Global Result Item must be a String , may be is not a global setting file', loading:false})
        return;
      }
      const emptyTitle = csvRslt.find( d => d[1]==='' && d[2]==='');
      if(emptyTitle) {
        this.setState({ error: 'Please enter subTitle or item in each row', loading:false})
        return;
      }
      const emptyFs = csvRslt.find( d => d[4]==='');
      if(emptyFs) {
        this.setState({ error: 'Please enter globalResultItem in each row', loading:false})
        return;
      }
      const PageTypeList = [
        "貸借対照表",
        "損益計算書",
        "株主資本等変動計算書",
        "製造原価明細表",
        "販売費及び一般管理費"
      ]
      const emptyType = csvRslt.findIndex( d =>  !PageTypeList.includes(d[3]));
      if(emptyType >= 0) {
        this.setState({ error: 'Column' + (emptyType + 1) +' : Category is not correct, may be is not a global setting file ', loading:false})
        return;
      }
      const csvChunks = chunk(csvRslt, 200);
      try {
        for (let [index, fsItemChunk] of csvChunks.entries()) {
          const fsItems = fsItemChunk.map((fsItemArray) => {
            return {
              id: fsItemArray[0],
              subTitle: fsItemArray[1],
              item: fsItemArray[2],
              category: fsItemArray[3],
              globalResultItem: fsItemArray[4],
            };
          });
          await api.put(`/dppapi/system/global-preset`, {
            globalResultItems: fsItems
          });
          this.setState({
            loadingText: Math.floor(((index + 1) / csvChunks.length) * 100) + '%',
          });
        }
      } catch (error) {
        this.setState({ error: error.data.errmsg, loading:false})
        return;
      }

      this.clearForm();
      this.props.toggleImport(false);
      this.props.handleSearch();
    }
  };

  clearForm = () => {
    this.setState({
      loading: false,
      loadingText: '',
      error: '',
    });
  };

  handleCancel = () => {
    this.clearForm();
    this.props.toggleImport(false);
  };

  handleToggle = (toggle) => {
    const { loading } = this.state;
    if(loading){
      return;
    }
    this.props.toggleImport(toggle);
    this.setState({
      error: '',
    });
  }

  render() {
    const { loading, loadingText, error } = this.state;

    return (
      <MDBModal
        isOpen={this.props.isShowImport}
        toggle={this.handleToggle}
      >
        <MDBModalHeader>Import Global Result Items</MDBModalHeader>
        <MDBModalBody>
          {loading ? (
            <Loader active inline="centered">
              {loadingText}
            </Loader>
          ) : (
            <div>
              {error && error !== '' && (
                <Message error >
                  {error}
                </Message>
              )}
              <Form>
                <Form.Field>
                  <Form.Input
                    label={'CSV File'}
                    multiple
                    type="file"
                    id="fsItemsCsv"
                    name="fsItemsCsv"
                    accept=".csv"
                    onClick={() => this.setState({error:''})}
                  />
                </Form.Field>
                <Button
                  size="small"
                  onClick={() => this.handleImport()}
                  floated="right"
                >
                  <Icon name="save" />
                  Import
                </Button>
                <Button
                  size="small"
                  onClick={() => this.handleCancel()}
                  floated="right"
                >
                  <Icon name="cancel" />
                  Cancel
                </Button>
              </Form>
            </div>
          )}
        </MDBModalBody>
      </MDBModal>
    );
  }
}

export default FsItemImport;
