/**
 * Css Style
 */
import { SCRUM_ITEM_SUFFIX } from './Constant';

// set style for list and detail
// currentBoxIdForCss is boxID selected in pdf panel
export function getClass(
    type,
    { rowId = '', cellIds = [], err = '', scrumItem = '', isManual = false, isInput = false },
    selectedCell,
    currentBoxIdForCss = '',
    column,
    popupSelected
) {
    let className = '';
    const isAggreation = scrumItem && scrumItem.indexOf(SCRUM_ITEM_SUFFIX.AGGREGATION) !== -1;
    switch (type) {
        case 'cell':
            if (rowId === selectedCell.id && column === selectedCell.column) {
                className = ' selectedValue';
            }
            break;
        case 'row':
            if (
                (currentBoxIdForCss && cellIds.indexOf(currentBoxIdForCss) !== -1)
            ) {
                className += ' high-light-row selected';
            }
            if (err && !isAggreation) {
                className += ' err-row';
            } else if (isAggreation) {
                className += ' aggregation-row';
            } else if (!err && isInput) {
                if (isManual) {
                    className += ' table-manual-row';
                } else {
                    className += ' table-success-row';
                }
            }
            if (!isAggreation && ((rowId === selectedCell.id && column === selectedCell.column) || popupSelected)) {
                className += ' high-light-row selected';
            }
            break;
        case 'new':
            className = ' detail-new-row-tr';
            if(rowId === selectedCell.id && column === selectedCell.column){
                className += ' high-light-row selected';
            }
            break;
        default:
            className = '';
    }
    return className;
};

// set style for table
export function getClass_SS(
    { rowId, cellIds },
    selectedCell,
    currentBoxIdForCss,
    column,
    err,
    manualInput,
    isInput,
    selected,
    scrumItem
) {
    const selectedRowId = selected && selected.length > 0 ? selected[2] : null;
    const selectedName = selected[3];
    let className = '';
    if (isInput) {
        className += ' input-row';
    }
    if (
        (rowId === selectedCell.id && column === selectedCell.column) ||
        rowId === selectedRowId ||
        selectedName === scrumItem
    ) {
        className += ' selected-bd-cell';
    }
    if (
        currentBoxIdForCss &&
        cellIds.indexOf(currentBoxIdForCss) !== -1 &&
        manualInput === undefined
    ) {
        className = 'high-light';
    }
    if (err) {
        className += ' err-row';
    }
    return className;
};
