import React, { Component } from 'react';
import { Segment, Button, Grid, Header } from 'semantic-ui-react';
import intl from 'react-intl-universal';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import * as d3 from 'd3';
import api from '../../api';

export default class WorkingStatusCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartWidth: 200,
      workingStatusStartDate: moment().add(-90, 'd').toDate(),
      workingStatusEndDate: moment().toDate()
    };

    this.divOverview = React.createRef();
    this.measure = this.measure.bind(this);
    this.renderChart = this.renderChart.bind(this);
  }

  componentWillMount () {
    window.addEventListener('resize', this.measure, false);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.measure, false);
  }

  measure () {
    let rect = this.divOverview.getBoundingClientRect();
    // console.log(`RECT: ${rect.width}`);

    if (this.state.chartWidth !== rect.width){
       this.setState({
        chartWidth: rect.width
      });
    }
  }

  componentDidMount () {
    this.measure();
  }
  
  componentDidUpdate () {
    this.measure();
  }
  onChangeDate = (type, date) => { 
    this.setState({[type]: date})
  }
  
  renderChart(arr, isOverview, parentWidth, theHeight=360) {
    const getDataFromArr = function(arr, key, callback) {
      const getAllNames = function(xs) {
        return xs.reduce(function(rv, x) {
          if (x["name"] == null) {
            return rv;
          }
      
          if (!rv[x["name"]]) {
            rv[x["name"]] = 0;
          }
  
          return rv;
        }, {});
      };
  
      const allNames = getAllNames(arr);
      const data = callback(allNames, arr, key);

      return data;
    }

    const doRendering = function(data, id) {
      var BrowserText = (function () {
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');

        function getWidth(text, fontSize, fontFace) {
          context.font = fontSize + 'px ' + fontFace;
          return context.measureText(text).width;
        }
    
        return {
          getWidth: getWidth
        };
      })();

      var margin = { top: 20, right: 20, bottom: 60, left: 40 },
        width = parentWidth - margin.left - margin.right,
        height = theHeight - margin.top - margin.bottom;

      d3.select(`div#${id} > *`).remove();
      var svg = d3.select(`#${id}`)
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
              
      var x0 = d3.scaleBand().rangeRound([0, width]).padding(0.2)
    
      var x1 = d3.scaleBand();

      var y = d3.scaleLinear().range([height, 0]);

      var color = d3.scaleOrdinal()
          .range(["#4472c4", "#ed7d31", "#a5a5a5", "#ffc000", "#a05d56", "#d0743c", "#ff8c00"]);

      var xAxis = d3.axisBottom(x0);

      var yAxis = d3.axisLeft(y);
      
      var keys = Object.keys(data[0]).filter(x => x !== "ymd");
      
      x0.domain(data.map(function (d) { return d.ymd; }));
      x1.domain(keys).rangeRound([0, x0.bandwidth()]);
      y.domain([0, d3.max(data, function (d) { return d3.max(keys, function (key) { return d[key]; }); })]).nice();

      svg.append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis);

      svg.append("g")
        .attr("class", "y axis")
        .call(yAxis)
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 6)
        .attr("dy", ".71em")
        .style("text-anchor", "end")
        .text("Population");

      var state = svg.selectAll(".groups")
        .data(data)
        .enter().append("g")
        .attr("class", "groups")
        .attr("transform", function(d) { 
          return "translate(" + x0(d.ymd) + ",0)";
        });

      state.selectAll("rect")
        .data(function (d) {
          return keys.map(function (key) {
            return { name: key, value: d[key] };
          });
        })
        .enter().append("rect")
        .attr("width", x1.bandwidth())
        .attr("x", function(d) { return x1(d.name); })
        .attr("y", function(d) { return y(d.value); })
        .attr("height", function(d) { return height - y(d.value); })
        .style("fill", function(d) { return color(d.name); })
        .on("mouseover", function(event, d) {
          const defaultFontFamily = 'sans-serif';
          const defaultFontSize = 10;
          const tooltip = `${d.name}: ${d.value}`;
          const textWidth = BrowserText.getWidth(tooltip, defaultFontSize, defaultFontFamily)

          d3.select(this.parentNode)
            .append("text")
            .attr("x", x1(d.name) + (x1.bandwidth()/2) - (textWidth/2))
            .attr("y", y(d.value) - 10)
            .attr("class", "mylabel")
            .text(tooltip);

          d3.select(this)
            .style("opacity", "0.7")
            .style("stroke", "Black")
            .style("stroke-width", "1.8px")
            .style("stroke-opacity", "1");
        })
        .on("mouseout", function(d) {
          d3.selectAll(".mylabel").remove();

          d3.select(this).transition().duration(250)
            .attr("fill", color(d.name))
            .style("opacity", "1")
            .style("stroke-opacity", "0");
        });

      var legend = svg.selectAll(".legend")
        .data(keys.slice().reverse())
        .enter().append("g")
        .attr("class", "legend")
        .attr("transform", function(d, i) { return "translate(0," + i * 20 + ")"; });

      legend.append("rect")
        .attr("x", width - 18)
        .attr("width", 18)
        .attr("height", 18)
        .style("fill", color);

      legend.append("text")
        .attr("x", width - 24)
        .attr("y", 9)
        .attr("dy", ".35em")
        .style("text-anchor", "end")
        .text(function(d) { return d; });
    }

    const getDataByName = function(allNames, arr, key) {
      var data = [];
      for (var i=0; i<arr.length; i++) {
        if (arr[i].ymd === 'NULL' || arr[i][key] === 'NULL') {
          continue;
        }
  
        var deepCopy = JSON.parse(JSON.stringify(allNames));
        // eslint-disable-next-line 
        var idx = data.findIndex(x => x.ymd === arr[i].ymd);
        if (idx === -1) {
          deepCopy[arr[i].name] = arr[i][key];
          deepCopy["ymd"] = arr[i].ymd;
          data.push(deepCopy);
        } else {
          data[idx][arr[i].name] = arr[i][key];
        }
      }

      return data;
    }

    if (isOverview) {
      doRendering(getDataFromArr(arr, "labelNum", getDataByName), "barOverviewLabel");
      doRendering(getDataFromArr(arr, "reviewNum", getDataByName), "barOverviewReview");
    } else {
      const categories = [...new Set(arr.map(x => x.val))].filter(x => x !== 'NULL');

      for (const category of categories) {
        d3.select(`#labeltypes`).append("div").text(`${category} label count`)
        d3.select(`#labeltypes`).append("div").attr("id", `barDetails_${category}`)
        const tmp = arr.filter(x => x.val === category || x.val === 'NULL');
        doRendering(getDataFromArr(tmp, "num", getDataByName), `barDetails_${category}`);
      }
    }
  }

  async downloadworkingStatusOverview(workingStatusStartDate, workingStatusEndDate, projectId, chartWidth) {
    // console.log(workingStatusStartDate, workingStatusEndDate, projectId);

    const response = await api.get(`/dppapi/projects/export-working-status/${projectId}?starttime=${workingStatusStartDate}&endtime=${workingStatusEndDate}&type=overview&proj=${projectId}`);

    const body = await response.data;
    
    this.renderChart(body.data, true, chartWidth);
  }

  async downloadworkingStatusDetails(workingStatusStartDate, workingStatusEndDate, projectId, chartWidth) {

    // console.log(workingStatusStartDate, workingStatusEndDate, projectId);
    const response = await api.get(`/dppapi/projects/export-working-status/${projectId}?starttime=${workingStatusStartDate}&endtime=${workingStatusEndDate}&type=details&proj=${projectId}`);
    const body = await response.data;

    this.renderChart(body.data, false, chartWidth);
  }

  render() {
    const { workingStatusStartDate, workingStatusEndDate, chartWidth } = this.state;
    const { projectId } = this.props;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header>
              <Header.Content>{intl.get('_demonstration.DemoHome.WorkingStatus')}</Header.Content>
            </Header>
            <Segment>
              <div>
                <p style={{marginBottom: '5px'}}>{intl.get('_demonstration.DemoHome.WorkingPeriod')}</p>
                <DatePicker
                  className="search-pdf-item"
                  onChange={date => this.onChangeDate('workingStatusStartDate', date)}
                  value={workingStatusStartDate}
                  format="y/MM/dd"
                />{' ~ '}
                <DatePicker
                  className="search-pdf-item"
                  onChange={date => this.onChangeDate('workingStatusEndDate', date)}
                  value={workingStatusEndDate}
                  format="y/MM/dd"
                />
              </div>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header>
              <Header.Content>{intl.get('_demonstration.DemoHome.WorkingStatusOverviewTitle')}</Header.Content>
            </Header>
            <Segment>
              <div>Label Count</div>
              <div id="barOverviewLabel"></div>
              <div>Review Count</div>
              <div id="barOverviewReview"></div>
              <div ref={ref => this.divOverview = ref}>
                <Button type="submit"
                  onClick={() => this.downloadworkingStatusOverview(workingStatusStartDate, workingStatusEndDate, projectId, chartWidth)}>
                  {intl.get('_demonstration.DemoHome.WorkingStatusOverview')}
                </Button>
              </div>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header>
              <Header.Content>{intl.get('_demonstration.DemoHome.WorkingStatusDetailsTitle')}</Header.Content>
            </Header>
            <Segment>
              <div id="labeltypes">
              </div>
              <div>
                <Button type="submit"
                  onClick={() => this.downloadworkingStatusDetails(workingStatusStartDate, workingStatusEndDate, projectId, chartWidth)}>
                  {intl.get('_demonstration.DemoHome.WorkingStatusDetails')}
                </Button>
              </div>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}


