import React, { Component } from 'react';
import { Container, Menu, Dropdown } from 'semantic-ui-react';
import ProjectEdit from './project/ProjectEdit';
import LabelEdit from './label/LabelEdit';
import ProjectUserTable from './user/ProjectUserTable';
import ProjectMetadataTable from './metadata/ProjectMetadataTable';
import PageEdit from './page/PageEdit';
import MappingFunction from './mapping-function';
import WorkingStatusCharts from './working-status-charts';
import CharReplacement from './char-replacement';
import Process from './process/Process';
import ResultFunction from './result-function/ResultFunction';
import APIFunction from './api-function';
import intl from 'react-intl-universal';
import api from '../api';
import { filterTab } from '../common/AuthUtil'
export default class ProjectMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '',
      tabs: this.getInitialState(),
    }
    this.handleSwitchTab = this.handleSwitchTab.bind(this)
  }

  getInitialState() {
    const { isSystemManager } = this.props;
    if (isSystemManager) {
      return [
        {
          key: 'project/result_function',
          label: intl.get('_project.ProjectMenu.ResultFunctions'),
          component: 'ResultFunction'
        },
        {
          key: 'project/metadata',
          label: intl.get('_project.ProjectMenu.Metadata'),
          component: 'ProjectMetadataTable'
        },
        {
          key: 'project/api_function',
          label: intl.get('_project.ProjectMenu.APIFunction'),
          component: 'APIFunction'
        },
      ];
    } else {
      return [
        {
          key: 'project/edit',
          label: intl.get('_project.ProjectMenu.Edit'),
          component: 'ProjectEdit'
        },
        {
          key: 'project/label',
          label: intl.get('_project.ProjectMenu.Labels'),
          component: 'LabelEdit'
        },
        {
          key: 'project/result_table',
          label: intl.get('_project.ProjectMenu.ResultTables'),
          component: 'ResultTable'
        },
        {
          key: 'project/result_function',
          label: intl.get('_project.ProjectMenu.ResultFunctions'),
          component: 'ResultFunction'
        },
        {
          key: 'project/user',
          label: intl.get('_project.ProjectMenu.User'),
          component: 'ProjectUserTable'
        },
        {
          key: 'project/metadata',
          label: intl.get('_project.ProjectMenu.Metadata'),
          component: 'ProjectMetadataTable'
        },
        {
          key: 'project/parsing_function',
          label: intl.get('_project.ProjectMenu.Parsing Function'),
          component: 'MappingFunction'
        },
        {
          key: 'project/ai_process',
          label: intl.get('_project.ProjectMenu.AI Process'),
          component: 'AIProcess'
        },
        {
          key: 'project/working_status',
          label: intl.get('_project.ProjectMenu.Working Status'),
          component: 'WorkingStatus'
        },
        {
          key: 'project/character_replacement',
          label: intl.get('_project.ProjectMenu.CharReplacement'),
          component: 'CharReplacement'
        },
        {
          key: 'project/api_function',
          label: intl.get('_project.ProjectMenu.APIFunction'),
          component: 'APIFunction'
        },
      ]
    }
  }

  handleSwitchTab(e, { name, component }) {
    this.setState({
      activeTab: component
    })
  }

  async componentDidMount() {
    const info = (await api.get(`/dppapi/info`)).data;
    const policy = info.policy

    const allowTabs = filterTab(this.state.tabs, policy)
    const activeTab = allowTabs.length > 0 ? allowTabs[0]['component']: ''
    this.setState({ tabs: allowTabs, activeTab});
  }

  render() {
    const { match, history, isSystemManager } = this.props;
    const { projectId, tenantId } = match.params;
    const { activeTab, tabs } = this.state
    return (
      <Container>
        <Menu pointing secondary>
          {
            tabs.map((tab, index) => {
              return index < 9 && (<Menu.Item
                name={tab.label}
                component={tab.component}
                active={activeTab === tab.component}
                onClick={this.handleSwitchTab}
                key={tab.label}
              />
            )})
          }
          {
            tabs.length > 9 ? (
              <Dropdown item icon='angle double right'>
                <Dropdown.Menu>
                  {tabs.map((tab, index) => {
                    return index >= 9 && (
                      <Menu.Item
                        name={tab.label}
                        component={tab.component}
                        active={activeTab === tab.component}
                        onClick={this.handleSwitchTab}
                        key={tab.label}
                      />)
                  })}
                </Dropdown.Menu>
              </Dropdown>
            ) : null
          }
        </Menu>
        {
          activeTab === '' && <></> // it's a way to reslove initial render <ProjectEdit/> and call api
        }
        {
          activeTab === 'ProjectEdit' && <ProjectEdit history={history} projectId={projectId} />
        }
        {
          activeTab === 'LabelEdit' && <LabelEdit projectId={projectId} />
        }
        {
          activeTab === 'ProjectUserTable' && <ProjectUserTable projectId={projectId} />
        }
        {
          activeTab === 'ProjectMetadataTable' && <ProjectMetadataTable projectId={projectId} tenantId={tenantId} isSystemManager={isSystemManager} />
        }
        {
          activeTab === 'ResultTable' && <PageEdit onSwitchTab={this.handleSwitchTab} projectId={projectId} />
        }
        {
          activeTab === 'ResultFunction' && <ResultFunction projectId={projectId} tenantId={tenantId} isSystemManager={isSystemManager} />
        }
        {
          activeTab === 'MappingFunction' && <MappingFunction projectId={projectId} />
        }
        {
          activeTab === 'AIProcess' && <Process projectId={projectId} />
        }
        {
          activeTab === 'WorkingStatus' && <WorkingStatusCharts projectId={projectId} />
        }
        {
          activeTab === 'CharReplacement' && <CharReplacement projectId={projectId} />
        }
        {
          activeTab === 'APIFunction' && <APIFunction projectId={projectId} tenantId={tenantId} isSystemManager={isSystemManager} />
        }
      </Container>
    );
  }
}
