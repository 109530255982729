import React, { useEffect, useState } from 'react';
import { Button, Modal, Icon, Image, Divider } from 'semantic-ui-react';
import { find, findIndex } from 'lodash';

export default function ImagePreview({
  openPreview,
  imgList,
  previewImageName,
  setOpenPreview,
  setPreviewImageName,
  handleDelete,
}) {
  const [previewUrl, setPreviewUrl] = useState('');

  useEffect(() => {
    let previewImg = find(imgList, { name: previewImageName });
    if (previewImg) {
      setPreviewUrl(previewImg['link']);
    }
  }, [previewImageName]);

  const handleSwitch = (direction) => {
    let index = findIndex(imgList, { name: previewImageName });

    if (index < 0) {
      setPreviewUrl('');
      setPreviewImageName('');
      return;
    }

    switch (direction) {
      case '<-':
        if (index === 0) {
          index = imgList.length - 1;
        } else {
          index--;
        }
        break;
      case '->':
        if (index === imgList.length - 1) {
          index = 0;
        } else {
          index++;
        }
        break;
      default:
    }
    setPreviewUrl(imgList[index]['link']);
    setPreviewImageName(imgList[index]['name']);
  };

  const handleScroll = (e) => {
    if (e.nativeEvent.deltaY <= 0) {
      handleSwitch('<-');
    } else {
      handleSwitch('->');
    }
  };

  const handleImgDelete = () => {
    const name = previewImageName;
    handleSwitch('->');
    handleDelete(name);
  }

  return (
    <Modal
      basic
      closeIcon
      onClose={() => setOpenPreview(false)}
      onOpen={() => setOpenPreview(true)}
      open={openPreview}
      size="large"
    >
      <Modal.Header>
        <Icon name='image' />
        {previewImageName}
      </Modal.Header>
      <Modal.Content>
        <Button.Group>
          <Button
            icon="left chevron"
            onClick={() => {
              handleSwitch('<-');
            }}
          />
          <Button
            icon="trash"
            onClick={() => {
              handleImgDelete();
            }}
          />
          <Button
            icon="right chevron"
            onClick={() => {
              handleSwitch('->');
            }}
          />
        </Button.Group>
        <Divider />
        <Image
          src={previewUrl ? previewUrl : '/assets/img/empty.png'}
          size="massive"
          onMouseDown={(e) => {
            if (e.button === 1) {
              handleImgDelete();
            }
          }}
          onWheel={(e) => handleScroll(e)}
        />
      </Modal.Content>
    </Modal>
  );
}
