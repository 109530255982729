import React, { Component } from 'react';
import api from '../../api';
import {
  Button,
  Form,
  Icon,
  Loader,
  Message,
  Segment,
} from 'semantic-ui-react';
import CSV from 'comma-separated-values';
import { downloadCsv } from '../../predict/Util';

class GlobalMasterDiffExport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      loading: false,
    };
  }

  handleDiffExport = async () => {
    const fileEle = document.getElementById('destCsv');
    if (fileEle.files && fileEle.files[0]) {
      this.setState({ loading: true });

      try {
        const csvRslt = await new Promise((resolve) => {
          const file = fileEle.files[0];
          const fileReader = new FileReader();
          fileReader.onload = () => {
            resolve(CSV.parse(fileReader.result, { cast: ['String', 'String', 'String', 'String', 'String'] }));
          }
          fileReader.readAsText(file);
        });

        const { data } = await api.put('/dppapi/system/global-master-diff-export', { fileData: csvRslt, });
        if (data) {
          let respList = [];
          if (data.oriOnlyList && data.oriOnlyList.length > 0) {
            respList = respList.concat(data.oriOnlyList.map((item) => item.split(',')));
          }
          if (data.destOnlyList && data.destOnlyList.length > 0) {
            respList = respList.concat(data.destOnlyList.map((item) => item.split(',')));
          }
          if (data.bothList && data.bothList.length > 0) {
            respList = respList.concat(data.bothList.map((item) => item.split(',')));
          }
          if (respList.length > 0) {
            downloadCsv(respList, { header: false }, `Global_Preset_Master_Diff_${new Date().getTime()}.csv`);
          }
        }
      } catch (error) {
        this.setState({ error: error.data.errmsg, loading: false, })
        return;
      }
      this.setState({ error: '', loading: false, });
    }
  };

  render() {
    const { loading, error, } = this.state;
    return (
      <Segment style={{ width: '300px' }}>
        {loading ? (
          <Loader active inline="centered" />
        ) : (
          <div>
            <Form>
              <Form.Field>
                <Form.Input
                  label={'CSV File'}
                  multiple
                  type="file"
                  id="destCsv"
                  name="destCsv"
                  accept=".csv"
                  onClick={() => this.setState({ error: '' })}
                />
              </Form.Field>
              <Button
                size="small"
                onClick={() => this.handleDiffExport()}
              >
                <Icon name="balance scale" />
                DIFF
              </Button>
            </Form>
          </div>
        )}
        {error && error !== '' && (
          <Message error >
            {error}
          </Message>
        )}
      </Segment>
    );
  }
}

export default GlobalMasterDiffExport;
