import React, { useState } from 'react';
import { Checkbox, Table, Popup } from 'semantic-ui-react';
import { AllFsItemReviewTableHeader } from '../../detail/mapping/FsConstant';
import intl from 'react-intl-universal';
import { ReactComponent as ReactOkLogo } from '../../../assets/icon/svg/mdi-check_circle.svg';
import { ReactComponent as ReactSendLogo } from '../../../assets/icon/svg/send.svg';

export default function FsMappingReviewTable({
  dataList,
  selectId,
  setSelectedId,
  selectedIds,
  setSelectedIds,
  checkedAll,
  setCheckedAll,
  onRowSelect,
  tab
}) {
  const [currentRow, setCurrentRow] = useState();

  //すべての操作を選択
  const handleSelectedAll = (checked) => {
    setCheckedAll(checked);
    if (checked) {
      const ids = dataList.map(item => item.id);
      setSelectedIds(ids);
    } else {
      setSelectedIds([]);
    }
  }

  //無線選択操作
  const handleSelected = (checked, id) => {
    let ids = [];
    if (checked) {
      ids = [...selectedIds, id];
      setSelectedIds(ids);
    } else {
      ids = selectedIds.filter(item => item !== id);
      setSelectedIds(ids);
    }
    setCheckedAll(ids.length === dataList.length);
  }

  //クリック連携
  const handleRowOperation = (row) => {
    setCurrentRow(row);
    if (!row.id) {
      return;
    }
    if (row.id === selectId && row.scrumItem === currentRow.scrumItem) {
      setSelectedId();
    } else {
      setSelectedId(row.id);
    }
    const ids = [row.value.id, row.item.id, row.subTitle.id].sort((a, b) => a - b);
    const { id, pdfImageId } = row;
    if (row.id === selectId && row.scrumItem !== currentRow.scrumItem) {
      return;
    } else {
      onRowSelect(id, pdfImageId, ids, 'value', row.manualInput);
    }
  };

  const handleActive = (row) => {
    if(!currentRow) {
      return false;
    }
    return selectId && (selectId === row.id) && (currentRow.scrumItem === row.scrumItem);
  }

  const renderHeader = (
    <Table.Header className="mapping-request-table-header" style={{ minWidth: '420px' }}>
      <Table.Row style={{ background: '#f9fafb' }} textAlign="left">
        <Table.HeaderCell>
          <Checkbox
            disabled={tab === 1}
            checked={checkedAll}
            onChange={(e, { checked }) => handleSelectedAll(checked)}
          />
        </Table.HeaderCell>
        {AllFsItemReviewTableHeader.map((col) => {
          return (
            <Table.HeaderCell
              key={col.id}
              className={'fs-text-table-table-wrap-table-header'}
            >
              {col.name}
            </Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
  );

  const renderBody = () => {
    if (dataList.length === 0) {
      return (
        <Table.Body className="mapping-request-table-body-empty">
          <Table.Row style={{ height: '49px' }}>
            <Table.Cell>該当アイテムがありません</Table.Cell>
          </Table.Row>
        </Table.Body>
      )
    }
    return (
      <Table.Body className="mapping-request-table-body">
        {dataList.map((row, index) => (
          <Table.Row
            key={index}
            style={{ cursor: row.id ? "pointer" : "not-allowed" }}
            className={`${selectedIds.includes(row.id) ? "mapping-request-table-tr-selected" : ""} ${(row.status === 1 && row.scrumItem === row.originalItem) ? "mapping-request-table-tr-sendOk" : ""}`}
            onClick={(e) => {
              if (e.detail === 1) {
                handleRowOperation(row)
              }
            }}>
            <Table.Cell onClick={e => e.stopPropagation()}>
              {row.status === 0 ? (
                <Checkbox
                  checked={selectedIds.includes(row.id)}
                  onChange={(e, { checked }) => handleSelected(checked, row.id)}
                />
              ) : (
                <div>
                  {row.scrumItem === row.originalItem && (
                    <Popup
                      className="mapping-request-table-body-popup"
                      trigger={<ReactOkLogo />}
                      content="更新済"
                      position="top center"
                      inverted
                    />
                  )}
                  {row.scrumItem !== row.originalItem && (
                    <Popup
                      className="mapping-request-table-body-popup"
                      trigger={<ReactSendLogo />}
                      content="更新依頼送信済"
                      position="top center"
                      inverted
                    />
                  )}
                </div>
              )}
            </Table.Cell>
            <Table.Cell verticalAlign="middle">
              {row.pageNum}
            </Table.Cell>
            <Table.Cell verticalAlign="middle" className={`${handleActive(row) ? "mapping-request-table-tr-active" : ""}`}>
              {row.pageName}
            </Table.Cell>
            <Table.Cell verticalAlign="middle" className={`${handleActive(row) ? "mapping-request-table-tr-active" : ""}`}>
              {row.subTitle.text}
            </Table.Cell>
            <Table.Cell verticalAlign="middle" className={`${handleActive(row) ? "mapping-request-table-tr-active" : ""}`}>
              {row.item.text}
            </Table.Cell>
            <Table.Cell verticalAlign="middle">
              <div className="-now">
                <div>{intl.get('_predict.detail.review.Now')}</div>
                <div style={{ color: row.originalItem ? '#212121' : "#AAAAAA" }}>{row.originalItem ? row.originalItem : '(未連携)'}</div>
              </div>
              <div className="-new">
                <div>{intl.get('_predict.detail.review.Update')}</div>
                <div>{row.scrumItem ? row.scrumItem : '(未連携)'}</div>
              </div>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    )
  };

  return (
    <Table style={{ borderCollapse: 'collapse', margin: '2px 0' }} unstackable>
      {renderHeader}
      {renderBody()}
    </Table>
  )
}

