import React, { Component } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Redirect,
  Switch,
} from 'react-router-dom';

import LabelHome from './label/LabelHome';
import LabelingLoader from './label/LabelingLoader';
import ProjectApp from './project/ProjectApp';
import DemoApp from './demonstration/DemoApp';
import PredictApp from './predict-beautify/PredictApp';
import User from './user/UserApp';
import ResetPassword from './user/reset-password';
import ResetAccessKey from './user/reset-access-key';
import LoginApp from './login/LoginApp';
import Message from './common/MessagePage';
import MonitorApp from './monitor/MonitorApp';
import TrainApp from './train/TrainApp';
import System from './system/System';
import EasyOcrApp from './easy-ocr/EasyOcrApp';
import Setting from './setting/Setting';
import MyContext from './context-manager';
import 'semantic-ui-css/semantic.min.css';
import { ROOT_URI,HistoryListener } from './HistoryListener'
import api from './api'
import { AnalyticsServiceProvider } from './common/Analytics/AnalyticsServiceProvider';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      commonDialog: {
        open: false,
        onlyCancel: false,
        title: "",
        msgs: [],
        extraInfo: [],
        onOk: () => { },
        projectId: "",
        pdfId: "",
        callback: () => { }
      },
      user: null
    }
  }

  componentDidMount() {
    this.getPubKey();
    this.getUser()
  }

  getPubKey = async () => { 
    const {pubkey} = (await api.get('/dppapi/pub-key')).data
    window.$config = {
      ...window.$config,
      $pubkey: pubkey
    }
  }

  getUser = async () => { 
    const pathName = window.location.pathname;
    if(pathName !== '/dppui/login'){
      const user = (await api.get(`/dppapi/info`)).data;
      this.setState({user})
    }
  }
  
  handleOperateDialog = (action) => {
    const { open } = action
    if (open) {
      this.setState({ commonDialog: { ...action } })
    }
    else {
      this.setState({
        commonDialog: {
          open: false,
          onlyCancel: false,
          title: "",
          msgs: [],
          extraInfo: [],
          onOk: () => { },
          projectId: "",
          pdfId: "",
          callback: () => { }
        }
      })
    }
  }

  render() {
    if (process.env.REACT_APP_DEMO) {
      const props = {
        match: {
          params: {
            projectId: 'demo',
            imageId: 1,
          },
        },
        history: {
          replace: () => { },
          push: () => { },
          goBack: () => { },
        },
      };
      return <LabelingLoader {...props} />;
    }

    const user = this.state.user
    const url = localStorage.getItem('url') || '/login';

    if (!user) {
      let saveUrl = (new URL(window.location.href)).searchParams.get('saveUrl');
      if (saveUrl != null) {
        localStorage.setItem("saveUrl", saveUrl)
      } else if (window.location.pathname.indexOf("login") === -1) {
        localStorage.setItem("saveUrl", window.location.pathname)
      }
    }
    var helpLink = user && user['helpLink'];
    helpLink = helpLink ? helpLink : 'https://digitallab.smfl.co.jp/kessansho-ai';

    return (
      <MyContext.Provider value={{ commonDialog: this.state.commonDialog, handleOperateDialog: this.handleOperateDialog }}>
        <AnalyticsServiceProvider>
        <Router basename={ROOT_URI}>
          <HistoryListener>
          {/* {!user ? (
            <Switch>
              <Route path="/login" component={LoginApp} />
              <Route path="/help" component={Help} />
              <Redirect to="/login" />
            </Switch>
          ) : ( */}
              <Switch>
                <Route path="/label" component={LabelHome} />
                <Route path="/login" component={LoginApp} />
                <Route path="/project" component={ProjectApp} />
                {/* <Route path="/predict" component={PredictApp} /> */}
                <Route path="/predict" render={(props) =>
                  <PredictApp {...props} user={this.state.user} onGetUser={this.getUser} />
                } />
                <Route path="/demo" component={DemoApp} />
                {/* <Route path="/help" component={Help} /> */}
                <Route path="/help" component={() => {window.location.href = helpLink; return null;}} />
                <Route path="/user" component={User} />
                <Route path="/monitor" component={MonitorApp} />
                <Route path="/train" component={TrainApp} />
                <Route path="/system" component={System} />
                <Route path="/password/reset" component={ResetPassword} />
                <Route path="/access-key/reset" component={ResetAccessKey} />
                <Route exact path="/message" component={Message} />
                <Route path="/easyocr" component={EasyOcrApp} />
                <Route path="/setting" component={Setting} />
                <Redirect to={url} />
              </Switch>
            {/* )}*/}
            </HistoryListener>
        </Router>
        </AnalyticsServiceProvider>
      </MyContext.Provider>
    );
  }
}

export default App;
