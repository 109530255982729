import React, { Component } from 'react';
import {
  Segment,
  Loader,
  Form,
  Button,
  Icon,
} from 'semantic-ui-react';
import api from '../../api';
import Table from './Table';

class PresetDiffMapping extends Component {

  constructor(props) {
    super(props);

    this.state = {
      list: null,
      isLoading: false,
      form: {},
    }
  }

  handleInput = (event) => {
    const { name, value } = event.target;
    const { form } = this.state;
    form[name] = value;
    this.setState({ form: form });
  };

  handleSearch = async (pageNo = 1, limit = 10) => {
    const { subTitle = '', item = '', category = '', globalResultItem = '' } = this.state.form;
    this.setState({ isLoading: true });
    const { data } = await api.get(`/dppapi/tenant-setting/diff-mapping?subTitle=${subTitle}&item=${item}&category=${category}&globalResultItem=${globalResultItem}&pageNo=${pageNo}&limit=${limit}`);
    this.setState({ list: data.list, isLoading: false, page: { ...data.page } });
  }

  render() {
    const { isLoading, list, page, form } = this.state;
    return (
      <div>
        <Segment>
          {isLoading ? (<Loader active inline="centered" />)
            : (
              <Form>
                <Form.Field>
                  <label>Sub Title</label>
                  <Form.Input
                    name="subTitle"
                    placeholder="Sub Title"
                    value={form.subTitle ? form.subTitle : ''}
                    onChange={(event) => this.handleInput(event)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Item</label>
                  <Form.Input
                    name="item"
                    placeholder="Item"
                    value={form.item ? form.item : ''}
                    onChange={(event) => this.handleInput(event)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Category</label>
                  <Form.Input
                    name="category"
                    placeholder="Category"
                    value={form.category ? form.category : ''}
                    onChange={(event) => this.handleInput(event)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Global Result Item</label>
                  <Form.Input
                    name="globalResultItem"
                    placeholder="Global Result Item"
                    value={form.globalResultItem ? form.globalResultItem : ''}
                    onChange={(event) => this.handleInput(event)}
                  />
                </Form.Field>
                <Button size="small" onClick={() => this.handleSearch()}>
                  <Icon name="search" />
                  Search
                </Button>
              </Form>
            )
          }
        </Segment>
        <Table list={list} page={page} isLoading={isLoading} search={this.handleSearch} />
      </div>
    );
  }
}

export default PresetDiffMapping;