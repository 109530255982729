import React, { Component } from 'react';
import {
  Form,
  Button,
  Icon,
  Popup as SPopup,
  Input,
} from 'semantic-ui-react';
import { Popup } from 'react-leaflet';
import _ from 'lodash';
import './MuiPopup.css';
import api from '../../../api';
import intl from 'react-intl-universal';
import Hotkeys from 'react-hot-keys';
import {
  LinkOffIcon,
  PenIcon,
  // SaveAmountWarnIcon,
  PopupbarCoordinated,
  PopupbarUncoordinatedNormal,
  PopupbarNotlinked,
  // SmallBookIcon
} from '../../../assets/icon';
import { crop, getDataUrlFromArr } from '../../../common/ImageUtil';
import { AnalyticsServiceContext } from '../../../common/Analytics/AnalyticsServiceProvider';
import { EventName, EventType, PredictDetailPageLocation } from '../../../common/Analytics/analyticsMetrics';

import { formatMoney } from '../../Util';
export default class MuiPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataUrl: null,
      selectedBBox: { id: '', text: '' },
      figureId: props.figure.id,
      editing: false,
      det_text: _.cloneDeep(
        props.allFigure
          ? props.allFigure.value
            ? props.allFigure.value.det_text
            : props.figure.det_text
          : props.figure.det_text
      ),
      tmp_det_text: _.cloneDeep(
        props.allFigure
          ? props.allFigure.value
            ? props.allFigure.value.det_text
            : props.figure.det_text
          : props.figure.det_text
      ),
      title_text: _.cloneDeep(
        props.allFigure
          ? props.allFigure.item
            ? props.allFigure.item.det_text
            : ''
          : props.figure.det_text
      ),
      tmp_title_text: _.cloneDeep(
        props.allFigure
          ? props.allFigure.item
            ? props.allFigure.item.det_text
            : ''
          : props.figure.det_text
      ),
      scrumItem: {},
      lastKeyPressed : "",
    };
  }
  static contextType = AnalyticsServiceContext;
  componentDidUpdate(prevProps, prevState, snapshot) {
    const valueText = props =>
      props.allFigure
        ? props.allFigure.value
          ? props.allFigure.value.det_text
          : props.figure.det_text
        : props.figure.det_text;
    const itemText = props =>
      props.allFigure
        ? props.allFigure.item
          ? props.allFigure.item.det_text
          : ''
        : props.figure.det_text;
    if (
      valueText(this.props) !== valueText(prevProps) ||
      itemText(this.props) !== itemText(prevProps) ||
      this.props.selectedBBox !== prevProps.selectedBBox
    ) {
      this.setState({
        selectedBBox: this.props.selectedBBox,
        det_text: _.cloneDeep(valueText(this.props)),
        tmp_det_text: _.cloneDeep(valueText(this.props)),
        title_text: _.cloneDeep(itemText(this.props)),
        tmp_title_text: _.cloneDeep(itemText(this.props)),
      });
    }
  }

  handleEscDown = () => {
    this.props.onSelectedBBoxChange('', '');
    this.setState({ selectedBBox: { id: '', text: '' } });
  };

  resetContent() {
    // reset content
    this.setState({ det_text: this.props.allFigure.value.det_text });
  }

  onEdit = editing => {
    if (editing) {
      this.setState({ editing: editing });
    } else {
      this.setState({
        editing: editing,
        det_text: _.cloneDeep(this.state.tmp_det_text),
        title_text: _.cloneDeep(this.state.tmp_title_text),
      });
    }
  };
  onDetTextChange = e => {
    let text = String(e.target.value)
    let target = e.target;
    const prevValue = String(this.state.det_text);
    const startPosition = e.target.selectionStart;
    if(text && text.startsWith('=')){
      this.setState({}, () => {
        target.selectionStart = 0;
        target.selectionEnd = 0;
      })
      return
    }

    // タイピングされた文字のみを取得
    const diffText = _.difference(text.split(''), prevValue.split('')).join('');
    // console.log("diffText", diffText);
    // 削除時は判定を実施しない
    if(!(this.state.lastKeyPressed === "Backspace" || this.state.lastKeyPressed === "Delete")){
      // タイピングされた文字が、0~9,-以外の場合は、入力を中止する
      if(diffText.length > 0 &&!text.match(/^[-]?[0-9,]*$/)){
        this.setState({}, () => {
          target.selectionStart = startPosition -1;
          target.selectionEnd = startPosition -1;
        })
        this.setState({lastKeyPressed : ""})
        return
      }
    }
       
    if(text.match(/^[-]?[0-9,]*$/)){
      const diffLength = prevValue.length - text.length
      let diffPosition = 0;

      if(diffLength < 2){
        if(this.state.lastKeyPressed === "Backspace"){
          if (String(prevValue)[startPosition] === ',') {
            text = String(text).slice(0,startPosition-1,) + String(text).slice(startPosition);
            diffPosition = - 1
          }
        }else if (this.state.lastKeyPressed === "Delete"){
          if (String(prevValue)[startPosition] === ',') {
            text = String(text).slice(0,startPosition,) + String(text).slice(startPosition +1);
          }
        }
      }
      const [convertText, finalPosition ] = formatMoney(startPosition, text);
      let fixedText = convertText
      let fixedPosition = finalPosition + diffPosition

      this.setState({ det_text: fixedText });
      this.setState({}, () => {
        target.selectionStart = fixedPosition;
        target.selectionEnd = fixedPosition;
      })
    }else if(this.state.lastKeyPressed === "Backspace" || this.state.lastKeyPressed === "Delete" ){
      // 削除時はテキストを編集する
      this.setState({ det_text: text });
    }
    this.setState({lastKeyPressed : ""})
  };

  onTitleTextChange = text => {
    this.setState({ title_text: text });
  };

  onDetTextSave = async () => {
    const { onChange, allFigure, figure, textMappingRef } = this.props;
    const figValue = allFigure
      ? allFigure.value
        ? allFigure.value
        : figure
      : figure;
    // const figItem = allFigure
    //   ? allFigure.item
    //     ? allFigure.item
    //     : figure
    //   : figure;

    if (figValue) {
      const change_result = await onChange('detTextChange', {
        figure: figValue,
        det_text: this.state.det_text,
      });

      if (change_result !== undefined && !change_result) {
        this.onEdit(false);
      } else {
        this.setState({ editing: false });

        (await textMappingRef) &&
          textMappingRef.current &&
          textMappingRef.current.handleTextChange(
            figValue.id,
            this.state.det_text,
            'change'
          );
      }

    }
    // if (figItem) {
    //   onChange('detTextChange', {
    //     figure: figItem,
    //     det_text: this.state.title_text,
    //   });
    //   this.setState({ editing: false });

    //   (await textMappingRef) &&
    //     textMappingRef.current &&
    //     textMappingRef.current.handleTextChange(
    //       figItem.id,
    //       this.state.title_text,
    //       'change'
    //     );
    // }

    //this.props.onLoad()
  };

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (nextState.det_text === this.state.det_text &&
  //     nextState.editing === this.state.editing &&
  //     shallowEqualObjects(nextProps.figure, this.props.figure)) {
  //     return false;
  //   }
  //   return true;
  // }

  // closePopusOnClick() {
  //   this.popup.current.leafletElement.options.leaflet.map.closePopup();
  // }
  // onDetTextOcr = result => {
  //   const { textMappingRef, figure, onChange } = this.props;
  //   if (result.success) {
  //     this.setState({ det_text: result.ocr });
  //     onChange('detTextChange', {
  //       figure: figure,
  //       det_text: result.ocr,
  //       accuracy: JSON.parse(result.accuracy),
  //     });
  //     textMappingRef &&
  //       textMappingRef.current &&
  //       textMappingRef.current.handleTextChange(
  //         figure.id,
  //         result.ocr,
  //         'change'
  //       );
  //   } else {
  //     this.setState({ det_text: '', err_msg: result.errmsg });
  //   }
  // };

  // getBboxCoordinate = () => {
  //   const { figure, bounds } = this.props;
  //   const height = bounds._northEast.lat;
  //
  //   const y1 = figure.points[0].lat;
  //   const x1 = figure.points[0].lng;
  //   const y2 = figure.points[1].lat;
  //   const x2 = figure.points[1].lng;
  //
  //   return [
  //     [Math.round(x1), Math.round(height - y1)],
  //     [Math.round(x2), Math.round(height - y2)],
  //   ];
  // };

  onPopupOpen = () => {
    this.props.scrumRecommendationFlg && this.onLoadAddToScrumItemButton();
    this.onCrop()
  };

  onCrop = () =>{
    const { imageData, imageWidth, imageHeight, figure } = this.props

    const { cropedImageData, width, height } = crop(imageData, figure.points, imageWidth, imageHeight)

    const dataUrl = getDataUrlFromArr(cropedImageData, width, height)
    this.setState({dataUrl})
  }

  onLoadAddToScrumItemButton = async () => {
    // loaderImageType is the type of Image which is showing in PDFLoader 
    const { textMappingRef, figure, allFigure, projectId, loaderImageType } = this.props;
    const { detailData } = textMappingRef.current.state;

    let scrumItem = {};
    const fig = allFigure && allFigure.value ? allFigure.value : figure;

    for (let tableKey of Object.keys(detailData)) {
      const itemIndex = _.findIndex(detailData[tableKey], item => {
        return item.value && item.value.id === fig.id;
      });
      if (itemIndex >= 0) {
        scrumItem = detailData[tableKey][itemIndex];

        // infer to scrum item
        const { data } = await api.post('/dppapi/predict/get-sfm', [
          {
            key1: scrumItem.subTitle.text,
            key2: scrumItem.item.text,
            projectId: projectId,
            category : loaderImageType
          },
        ]);

        const { scrum_field_mappings, success } = data;

        if (
          success &&
          scrum_field_mappings &&
          scrum_field_mappings.length > 0
        ) {
          scrumItem['scrum_field_name'] =
            scrum_field_mappings[0]['scrum_field_name'];
        } else {
          scrumItem['scrum_field_name'] = null;
        }

        break;
      }
    }
    this.setState({ scrumItem: scrumItem });
  };

  onAddToScrumItem = () => {
    const { scrumItem } = this.state;
    const { textMappingRef } = this.props;

    textMappingRef.current.handleScrumItemChange(
      scrumItem.value.id,
      scrumItem['scrum_field_name'],
      true
    );
  };

  setSelectBBox = (id, det_text) => {
    this.props.onSelectedBBoxChange(id, det_text);
    this.setState({ selectedBBox: { id: id, text: det_text } });
  };

  clearSelectBBox = () => {
    this.props.onSelectedBBoxChange('', '');
    this.setState({ selectedBBox: { id: '', text: '' } });
  }

  handleClickTextSaveIcon = () => {
    this.onDetTextSave()
    this.context.sendPredictBeautifyDetailMetrics({
      event_type: EventType.Action,
      event_name: EventName.FixAmount,
      custom_parameter: {
        location : PredictDetailPageLocation.PopUp,
      },
    })
  }

  handleClickUnLinkIcon = (textMappingRef,value,id) => {
    textMappingRef &&
    textMappingRef.current &&
    textMappingRef.current.handleUnLinkScrumItem(
      value ? value.id : id
    ); 
    // popupRef.current.leafletElement.options.leaflet.map.closePopup();

    this.context.sendPredictBeautifyDetailMetrics({
      event_type: EventType.Action,
      event_name: EventName.UnlinkScrumItem,
      custom_parameter: {
        location : PredictDetailPageLocation.PopUp,
      },
    })
  }

  handleClickSelectCollaborativeSubjectButton = (det_text,figure ,id) => {
    const text = det_text ? det_text : figure.det_text;
    this.setSelectBBox(id, text);
    this.context.sendPredictBeautifyDetailMetrics({
      event_type: EventType.Click,
      event_name: EventName.ClickLinkScrumItemButton,
    })
    this.context.handleSetIsClickLinkScrumItemButton(true)
  }

  handleClickMaybeButton = () => {
    this.onAddToScrumItem()
    this.context.sendPredictBeautifyDetailMetrics({
      event_type: EventType.Action,
      event_name: EventName.FixToAnalyzedContent,
    })
  }

  render() {
    const {
      det_text,
      title_text,
      editing,
      scrumItem,
      selectedBBox,
    } = this.state;
    const {
      figure,
      allFigure: { value, linkName, isLinkedValue },
      // isEditing,
      textMappingRef,
      popupRef,
      popupPosition,
      isNotOwner,
      onShowExclusionControl,
    } = this.props;
    const { id, accuracy = [], accThreshold } = figure;
    // const detTextClaessOptions = [];
    // Object.values(labelTypes).forEach(detClass => {
    //   detTextClaessOptions.push({ value: detClass, text: detClass });
    // });

    let position = {};
    if (popupPosition) {
      position = { position: [popupPosition[0] + 10, popupPosition[1]] };
    }

    // predict scrum item
    const scrumMapingButton = scrumItem.scrum_field_name && (
      <div className="mui-scrum-mapping-btn">
        <span>{intl.get('_predict.detail.pdf.MuiPopup.Maybe')}</span>
        <div
          onClick={() => {
            if (isNotOwner) {
              onShowExclusionControl(true);
            } else {
              this.handleClickMaybeButton();
            }
          }}
        >
          <Button
            disabled={isNotOwner}
            id="ga-predict-detail-bbox-popup-item-recommend"
            content={scrumItem.scrum_field_name}
            basic
            className="mabey_btn"
          />
        </div>
      </div>
    );
    const hotkeysDOM = (
      <Hotkeys
        keyName="esc"
        onKeyDown={key => {
          if (key === 'esc') {
            this.handleEscDown();
          }
        }}
      />
    );

    // const minAcc = Math.min(...(Array.isArray(accuracy) ? accuracy : []));
    // const accPercent = Math.floor(minAcc * 100);
    // const accColor =
    //   parseFloat(minAcc) < parseFloat(accThreshold) ? 'yellow' : 'green';
    // console.log(det_text, title_text);
    const text_chars = text =>
      Array.from(text || '').map((char, index) => {
        const arrPercent = parseFloat(
          (Array.isArray(accuracy) ? accuracy : [])[index]
        );
        if (arrPercent < parseFloat(accThreshold)) {
          return (
            <SPopup
              position="top center"
              key={index}
              content={
                JSON.stringify(Math.floor(arrPercent * 10000) / 100) + '%'
              }
              trigger={<span style={{ color: 'red' }}>{char}</span>}
            />
          );
        } else {
          return <span key={index}>{char}</span>;
        }
      });

    const isTitle = text_chars(title_text) && text_chars(title_text).length > 0;
    const isDet = text_chars(det_text) && text_chars(det_text).length > 0;
    const textNotTitleDet = type => {
      const text = text_chars(figure.det_text);
      const textLeft = figure.det_class !== 'value' ? text : '-';
      const textRight = figure.det_class === 'value' ? text : '-';
      const textPosition = type === 'left' ? textLeft : textRight;
      return !isTitle && !isDet && !linkName ? textPosition : '-';
    };

    // const isShowSearchFlg = textMappingRef && textMappingRef.current && 
    //   textMappingRef.current.state.tenantPreference['predict_page_preset_master_search'];

    return (
      <Popup
        ref={popupRef}
        closeButton={true}
        className={'mui-leaflet-popup-content-wrapper'}
        onOpen={this.onPopupOpen}
        onClose={() => {this.context.handleSetIsClickLinkScrumItemButton(false)}}
        {...position}
      >
        <div key={`popup-${id}`} className={'mui-popup-container'}>
          <div className={'mui-popup-body-wrap'}>
            {
              isLinkedValue
                ? <PopupbarCoordinated className="mui-popup-header"/>
                : selectedBBox.id === '' && selectedBBox.text === ''
              ? <PopupbarUncoordinatedNormal className="mui-popup-header"/>
              : <PopupbarNotlinked className="mui-popup-header"/>
            }
          </div>
          <div className={'mui-popup-content-wrap'}>
            <div className="mui-popup-content-wrap-left">
              <div className="left-header-content">
                  {isTitle
                    ? title_text
                    : textNotTitleDet('left')}
              </div>
              <div className="mui-popup-det-content-wrapper">
                {!editing ? (
                  <div className="mui-det-content-new">
                    {isDet
                      ? text_chars(det_text)
                      : textNotTitleDet('right')}
                  </div>
                ) : (
                  <Form>
                    <Input
                      value={det_text}
                      disabled={!editing}
                      onChange={e => {this.onDetTextChange(e)}}
                      onKeyDown={key => {
                        if(key.keyCode === 8){
                          this.setState({ lastKeyPressed : "Backspace"})
                        }else if ( key.keyCode === 46){
                          this.setState({ lastKeyPressed : "Delete"})
                        }
                        
                        if (key.keyCode === 13) {
                          this.onDetTextSave();
                        }
                      }}
                    />
                  </Form>
                )}
                {editing ? (
                  <div className="icon-edit-group">
                    <span className="mui-popup-svg-icon">
                      <Icon
                        name="cancel"
                        onClick={() => this.onEdit(false)}
                      />
                    </span>
                    <span className="mui-popup-svg-icon">
                      <Icon
                        name="checkmark"
                        onClick={this.handleClickTextSaveIcon}
                      />
                    </span>
                  </div>
                ) : (
                  <SPopup
                    position="top center"
                    on="hover"
                    disabled={isNotOwner}
                    trigger={
                      <span className="mui-popup-svg-icon"
                        onClick={() => {
                          if (isNotOwner) {
                            onShowExclusionControl(true);
                            return;
                          }
                          this.onEdit(true)
                        }}
                      >
                        <PenIcon
                          disabled={isNotOwner}
                          size={'16px'}
                        />
                      </span>
                    }
                    className="popUp-text"
                    content={ intl.get(
                      '_predict.detail.pdf.MuiPopup.Correct the amount'
                    )}
                    inverted
                  />
                )}
              </div>
              <div className='mui-popup-value-img-wapper'>
                <img className='mui-popup-value-img' src={this.state.dataUrl} alt=''/>
              </div>   
            </div>
            <div className="mui-popup-content-wrap-divider"/>
            <div className="mui-popup-content-wrap-right">
              <div className={'mui-popup-content-wrap-right-selected'}>
                {isLinkedValue ? (
                  <>
                    <div className="mui-action-left-content">
                      {linkName ? linkName : '-'}
                    </div>
                    <div className="linked-icon-style-btn">
                      <SPopup
                        id="ga-predict-detail-bbox-popup-item-unlink"
                        position="top center"
                        on="hover"
                        trigger={
                          <span className="mui-popup-svg-icon"
                            onClick={() => {
                              if (isNotOwner) {
                                onShowExclusionControl(true);
                                return;
                              }
                              this.handleClickUnLinkIcon(textMappingRef, value, id)
                            }}
                          >
                            <LinkOffIcon disabled={isNotOwner} />
                          </span>
                        }
                        className="popUp-text"
                        content={intl.get(
                          '_predict.detail.pdf.MuiPopup.unMapping'
                        )}
                        inverted
                      />
                    </div>
                  </>
                ) : 
                  selectedBBox.id === '' &&
                    selectedBBox.text === '' ?
                    <SPopup
                      content={intl.get('_predict.detail.pdf.MuiPopup.Disabled')}
                      position="right center"
                      disabled={
                        !((selectedBBox.id !== '' &&
                          selectedBBox.text !== '') ||
                        (textMappingRef &&
                          textMappingRef.current &&
                          textMappingRef.current.state.mode === 2))
                      }
                      on="hover"
                      trigger={
                        <div
                          onClick={() => {
                            if ((selectedBBox.id !== '' &&
                              selectedBBox.text !== '') ||
                              (textMappingRef &&
                                textMappingRef.current &&
                                textMappingRef.current.state.mode === 2)
                              || isNotOwner) {
                              onShowExclusionControl(true);
                            } else {
                              const text = det_text
                                ? det_text
                                : figure.det_text;
                              this.handleClickSelectCollaborativeSubjectButton(det_text, figure, id)
                              this.setSelectBBox(id, text);
                            }
                          }}
                        >
                          <button
                            id="ga-predict-detail-bbox-popup-item-mapping"
                            className="unlink-icon-style-btn"
                            disabled={
                              (selectedBBox.id !== '' &&
                                selectedBBox.text !== '') ||
                              (textMappingRef &&
                                textMappingRef.current &&
                                textMappingRef.current.state.mode === 2)
                              || isNotOwner
                            }
                          >
                            {intl.get('_predict.detail.pdf.MuiPopup.Select collaborative subject')}
                          </button>
                        </div>
                      }
                      inverted
                      />
                  : 
                  <button
                    className="unlink-icon-style-clearBtn"
                    onClick={() => {
                      const text = det_text
                        ? det_text
                        : figure.det_text;
                      this.clearSelectBBox(id, text);
                      this.context.handleSetIsClickLinkScrumItemButton(false);
                    }}
                  >
                    {intl.get('_predict.detail.pdf.MuiPopup.Cancel')}
                  </button>
                }
                {/* { !isLinkedValue && isShowSearchFlg === 'true'? (
                  <SPopup
                    trigger= {
                      <Button
                        positive
                        icon
                        disabled={!(selectedBBox.id === '' && selectedBBox.text === '')}
                        onClick={() => textMappingRef &&
                          textMappingRef.current &&
                          textMappingRef.current.toggleSearch(true)}
                        className="mui-popup-book-search-btn"
                        onMouseEnter={()=>this.setState({enterBook:true})}
                        onMouseLeave={()=>this.setState({enterBook:false})}
                      >
                        <SmallBookIcon size="18px" isHover={this.state.enterBook}/>
                      </Button> 
                    }
                    position="top center"
                    content="勘定科目辞書"
                    on="hover"
                    hideOnScroll
                    inverted
                  />
                ) : null}  */}
                {hotkeysDOM}
              </div>
              <div className="mui-popup-passed">
                {!editing && (
                  <React.Fragment>
                    {scrumMapingButton}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          {/* {isEditing ? <div className="mui-popup-save-amount-warn">
            <SaveAmountWarnIcon className="mui-popup-save-amount-icon" />
            <p className="mui-popup-save-amount-warn-message">{intl.get('_predict.detail.pdf.MuiPopup.Save amount')}</p>
          </div> : null} */}
        </div>
      </Popup>
    );
  }
}
