import React from 'react'
import { Loader, Grid, Segment, Image, Checkbox, Button, Message } from 'semantic-ui-react'
import { confirmAlert } from 'react-confirm-alert'
import api from '../../api'
import intl from 'react-intl-universal'
import { Link } from 'react-router-dom';
import { getCsrfToken } from '../../common/CookieUtil'

export default class ImagesAllGrid extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      isLoaded: false,
      images: [],
      selectedList: [],
      pagination: null,
      searchData: {},
      countImages: 0,
    }
  }

  async componentDidMount() {
    await this.getPageCount();
  }

  async load() {
    this.setState({ isLoaded: false });
    const { projectId } = this.props;
    const { searchData } = this.state;
    const { alivePage , entries } = searchData;
    try {
      const images = (await api.get(
        `/dppapi/labeling/${projectId}?alivePage=${alivePage}&entries=${entries}`
      )).data;

      this.setState({
        images,
        isLoaded: true,
      });
    } catch (error) {
      this.setState({
        isLoaded: true,
        error,
      });
    }
  }

  async handleDel() {
    const { projectId } = this.props;
    const { selectedList, searchData } = this.state;
    confirmAlert({
      title: intl.get('_label.image.Images.Delete'),
      message: intl.get('_label.image.Images.Are you sure to do this?'),
      buttons: [
        {
          label: intl.get('_label.image.Images.Delete'),
          onClick: async () => {
            await api.delete(`/dppapi/labeling/${projectId}/images`, {
              data: {
                imageIds: selectedList
              }
            })
            this.setState({
              selectedList: [],
            });
            this.getPageCount(searchData);
          },
        },
        {
          label: intl.get('_label.image.Images.Cancel'),
        },
      ],
    })
  }
  handleChecked = (imageId) => {
    const {selectedList} = this.state
    
    const idx = selectedList.indexOf(imageId)
    if (idx !== -1 ){
      selectedList.splice(idx, 1)
    } else {
      selectedList.push(imageId)
    }
    this.setState({ selectedList })
  }
  
  getPageCount = async(searchData={}) => {
    const { projectId } = this.props;

    try {
      const count = (await api.get(
        `/dppapi/labeling/count/${projectId}`
      )).data;
      var countImages = 0;
      if (count) {
        countImages = count.countImages
      }
      if(!searchData.alivePage){
        searchData.alivePage = 1;
        searchData.entries = 20;
      } else {
        const {alivePage,entries} = searchData;
        if(count && alivePage > 1 && (alivePage -1)*entries > count.countImages-1){
          searchData.alivePage = alivePage -1;
        }
      }
      this.setState({
        searchData: searchData,
        countImages: countImages
      });
      this.generatePagination();
    } catch (error) {
      console.log(error)
    }
  }
  
  generatePagination = () => {
    const { countImages, searchData } = this.state;
    const { entries, alivePage } = searchData;
    const pagesCount = Math.ceil(countImages/entries);
    const pagesAmount = 5;
    var pagination = null;
    var pagNum = [];
    if (pagesCount > 0) {
      for (let i = 1; i <= pagesCount; i++) {
        if (i === alivePage) {
          if (pagesCount < pagesAmount) {
            for (let p = 1; p <= pagesCount; p++) {
              pagNum.push(p);
            }
          } else {
            if (i <= 3) {
              pagNum = [1, 2, 3, 4, 5];
            } else if (pagesCount - i < 3) {
              pagNum = [pagesCount - 4, pagesCount - 3, pagesCount - 2, pagesCount - 1, pagesCount];
            } else {
              pagNum = [i - 2, i - 1, i, i + 1, i + 2];
            }
          }
        }
      }
    }
    pagination = this.getShowPages(pagNum, alivePage);
    this.load();
    this.setState({pagination: pagination});
  }
  
  
  getShowPages = (pagNum, alivePage) => {
    return pagNum.map(p => {
      if (p === alivePage) {
        return (<li className="active page-item" key={p} onClick={() => this.changeAlivePage(p)}> 
          <Link className="page-link page-link" to={'#'}>{p}
            <span className="sr-only"></span>
          </Link>
        </li>)
      } else {
        return (<li className="page-item" key={p} onClick={() => this.changeAlivePage(p)}>
          <Link className="page-link page-link" to={'#'}>{p}
          </Link>
        </li>)
      }
    })
  }
  
  changeEntries = (e) => {
    let {searchData} = this.state;
    searchData.entries = e.target.value;
    searchData.alivePage = 1;
    this.setState({ searchData: searchData })
    this.generatePagination();
  }
  
  changeAlivePage = (alivePage) => {
    let {searchData} = this.state;
    searchData.alivePage = alivePage;
    this.setState({ searchData: searchData, selectedList: [] });
    this.generatePagination();
  }

  render() {
    const  { images, error, isLoaded, selectedList,countImages, searchData, pagination } = this.state
    const { alivePage, entries } = searchData;
    const { projectId } = this.props;

    const pagesCount = Math.ceil(countImages/entries);
    var previousClass = '';
    var nextClass = '';
    if (pagesCount > 0) {
      if (alivePage === 1) {
        if (pagesCount !== 1) {
          previousClass = 'disabled page-item';
          nextClass = 'page-item';
        } else {
          previousClass = 'disabled page-item';
          nextClass = 'disabled page-item';
        }
      } else if (alivePage === pagesCount) {
        previousClass = 'page-item';
        nextClass = 'disabled page-item';
      } else {
        previousClass = 'page-item';
        nextClass = 'page-item';
      }
    }
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <Loader active inline="centered" />;
    }
    const columns = images.map( (image, i) => (
      <Grid.Column key={i}>
        <Segment className="all-images-grid-img-container" >
          <div style={{height:"330px"}}>
          <Image src={`/dppapi${image.link}?token=${getCsrfToken()}`} href={`/dppapi${image.link}?token=${getCsrfToken()}`} target='_blank'/>

          </div>
          <div className="all-images-grid-toolboox" >
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={`/label/${projectId}/${image.id}`}
          >
            <Button size="mini" className="all-images-grid-toolboox-btn">{intl.get('_label.image.Images.Edit')}</Button>
          </Link>
            <Checkbox checked={selectedList.indexOf(image.id) !== -1} onChange={() => this.handleChecked(image.id)} />
          </div>
        </Segment>

      </Grid.Column>
    ))
    var result = null;
    if(images.length > 0){
      result = (
        <Segment>
          <div className="all-images-grid-top-bar">
          <Button color="red" style={{minWidth:"70px",marginRight:"20px"}} onClick={() => this.handleDel()} disabled={!(selectedList&&selectedList.length>0)}>{intl.get('_label.image.Images.Delete')}</Button>
          <div className="all-images-grid-pagination">
            <div>
            Show entries
            <select className="custom-select custom-select-sm" style={{marginLeft: '0.5rem', width: '55px'}} onChange={(e)=>this.changeEntries(e)}  key={entries} defaultValue={entries}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            </div>
            <div className="dataTables_paginate">
              <ul className="pagination">
                <li className={previousClass} onClick={() => this.changeAlivePage(alivePage - 1)}>
                  <Link className="page-link page-link" to={"#"}>
                    <span>Previous</span>
                  </Link>
                </li>
                {pagination}
                <li className={nextClass}  onClick={() => this.changeAlivePage(alivePage + 1)}>
                  <Link className="page-link page-link" to={"#"}>
                    <span>Next</span>
                  </Link>
                </li>
              </ul> 
            </div>
          </div>
          </div>
          
          <div>
          <Grid columns={4}>{columns}</Grid>
          </div>
        </Segment>
      ) 
    } else {
      result = (<Segment><Message
        info
        header='No Data'
        content="No matching records found"
      /></Segment>)
    }
    return (
      <div className="project-images">
        {result}
      </div>
    )
  }
}
