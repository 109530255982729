import React, { Component, Fragment } from 'react';
import { Polyline, Polygon, CircleMarker } from 'react-leaflet';
import './Figure.css';
import _ from 'lodash';

class Figure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dragging: false,
      draggedPoint: null,
      draggedLine: null,
      popupPosition: null,
    };
    this.polyCompRef = null;
  }

  componentDidMount() {
    this.makeLine();
    this.changeSelectFlag();
  }

  componentDidUpdate(prevProps, prevState) {
    const { options } = this.props;
    const preOptions = prevProps.options
    if (!_.isEqual(options, preOptions)) {
      this.makeLine();
      this.changeSelectFlag();
    }
  }

  // abstract
  calculateGuides() {
    return [];
  }

  // abstract
  onPointClick(i) { }

  // abstract
  onPointMoved(point, i) { }

  // abstract
  makeExtraElements() {
    return null;
  }

  // abstract
  leafletComponent() {
    return Polygon;
  }

  // abstract
  getRenderPoints(points) {
    return points;
  }

  // abstract
  getPopup() {
    return null;
  }

  makeGuides() {
    const guides = this.calculateGuides();
    const { color } = this.props.options;
    return guides.map((pos, i) => (
      <Polyline
        key={i}
        positions={pos}
        color={color}
        opacity={0.5}
        dashArray="5"
      />
    ));
  }

  hasFill() {
    return true;
  }

  makeLine() {
    const fig = this.getFigures();
    if (fig && fig.value && fig.item) {
      const listLine = [fig.item, fig.value];
      const polyline = [
        [
          (listLine[0].points[0].lat + listLine[0].points[1].lat) / 2,
          listLine[0].points[1].lng + 10,
        ],
        [
          (listLine[1].points[0].lat + listLine[1].points[1].lat) / 2,
          listLine[1].points[0].lng - 10,
        ],
      ];
      const popupPosition1 = (polyline[0][0] + polyline[1][0]) / 2;
      const popupPosition2 = (polyline[0][1] + polyline[1][1]) / 2;
      this.setState({
        draggedLine: polyline,
        popupPosition: [popupPosition1, popupPosition2],
      });
    }
  }

  changeSelectFlag() {
    const { selectFlag, figure } = this.props;
    const { inSelectedCellIds } = this.props.options;
    if (inSelectedCellIds && selectFlag && figure.det_class === "value") {
      this.props.handleChangeSelectFlag(false);
    }
  }

  render() {
    const {
      mapRef,
      figure,
      options,
      manualInput,
      selectFlag,
    } = this.props;
    const { id, points } = figure;

    const {
      editing,
      finished,
      sketch,
      color,
      inSelectedCellIds,
      borderColor,
      clickFigureIds,
      clickedPoints,
      notMatchedList,
    } = options;
    const { draggedPoint } = this.state;

    const renderPoints = this.getRenderPoints(points);

    // when chose item in key-value bar,box color changed
    const selectedColor = '#3584E6';
    // const lineColor = editing && sketch ? color : inSelectedCellIds ? selectedColor : vertexColor || color;
    const fillColor =
      editing && sketch
        ? 'rgba(0,0,0,0)'
        : inSelectedCellIds
          ? selectedColor
          : color;
    const weight = editing && sketch ? 1 : 2;

    const classes = ['vertex'];
    if (editing) classes.push('editing');
    if (finished) classes.push('finished');
    const vertices = renderPoints.map((pos, i) => (
      <CircleMarker
        className={`${classes.concat(!i ? ['first'] : []).join(' ')} bbox-circleMarker-cursor`}
        key={id + '-' + i}
        center={pos}
        fillOpacity={0.0}
        opacity={0.0}
        radius={11}
        onClick={() => this.onPointClick(i)}
        draggable={false}
        onDrag={e => {
          this.setState({
            draggedPoint: { point: e.target.getLatLng(), index: i },
          });
        }}
        onDragstart={e => this.setState({ dragging: true })}
        onDragend={e => {
          this.onPointMoved(e.target.getLatLng(), i);
          this.setState({ dragging: false, draggedPoint: null });
        }}
      >
        <CircleMarker
          weight={0}
          color={selectedColor}
          opacity={0.2}
          fill={true}
          fillColor={selectedColor}
          fillRule="nonzero"
          fillOpacity={0.2}
          radius={0}
          center={
            draggedPoint && draggedPoint.index === i ? draggedPoint.point : pos
          }
        />
      </CircleMarker>
    ));

    const guideLines = this.makeGuides();
    const PolyComp = this.leafletComponent();

    // check to be linked
    const detailData = this.props.textMappingRef.current ? this.props.textMappingRef.current.state.detailData : {};

    let isLinked = false;
    let hasManualVal = false;
    for (let pt in detailData) {
      if (pt !== 'manual_input') {
        for (let row of detailData[pt]) {
          if (row.value.id === figure.id && row.scrumItem) {
            isLinked = true;
            if (detailData['manual_input'] && detailData['manual_input'][pt] && detailData['manual_input'][pt][row.scrumItem]) {
              hasManualVal = true
            }
            break;
          }
        }
      }
    }

    let isManual = false;
    if (this.polyCompRef) {
      const pClzList = this.polyCompRef.leafletElement._path.classList;
      if (inSelectedCellIds) {
        if (['value', 'word', 'subtitle'].includes(figure.det_class)) {
          if (notMatchedList && notMatchedList.includes(figure.id)) {
            pClzList.remove('blingbling');
            pClzList.add('blingbling_notMatched');
          } else {
            pClzList.remove('blingbling_notMatched');
            pClzList.add('blingbling');
          }
        } else {
          pClzList.remove('blingbling_notMatched');
          pClzList.add('blingbling');
        }
        isManual = manualInput === undefined ? false : true;
      } else {
        pClzList.remove('blingbling');
        pClzList.remove('blingbling_notMatched');
      }
    }
    const fig = this.getFigures();
    let colorPoly = '#fff';
    let opacityPoly = '0';
    if (
      (!isLinked && borderColor) ||
      (isLinked && borderColor) ||
      ['value', 'word'].includes(figure.det_class)
    ) {
      if (fig.isLinkedValue) {
        colorPoly = '#63d980';
        opacityPoly = '0.6';
      }
      if (renderPoints && renderPoints.length > 0) {
        renderPoints[0].lng -= 5;
        renderPoints[1].lng += 5;
        renderPoints[2].lng += 5;
        renderPoints[3].lng -= 5;
        if (fig.isLinkedValue) {
          renderPoints[2].lat += 1;
          renderPoints[3].lat += 1;
        }
      }
    }
    if (inSelectedCellIds && selectFlag && figure.det_class === "value") {
      const map = mapRef.current.leafletElement;
      if (!map.getBounds().contains(renderPoints[0])) {
        setTimeout(() => {
          map.setView([renderPoints[1].lat, (renderPoints[0].lng + renderPoints[1].lng) / 2])
        }, 100);
      }
    }

    let isClickHighlight = false;
    if (clickFigureIds && clickFigureIds.length > 0) {
      clickFigureIds.forEach(id => {
        if (id === figure.id) {
          isClickHighlight = true;
        }
      });
    }
    if (_.isEqual(clickedPoints, renderPoints)) {
      isClickHighlight = true;
    }

    if (this.props.options.selectedCellIds && this.props.options.selectedCellIds.length > 0) {
      isClickHighlight = false;
    } 
    return (
      <Fragment key={id}>
        <PolyComp
          ref={ref => (this.polyCompRef = ref)}
          className="polygon_path_style"
          positions={renderPoints}
          color={inSelectedCellIds || isClickHighlight ? selectedColor : colorPoly}
          opacity={inSelectedCellIds || isClickHighlight ? '0.6' : opacityPoly}
          weight={inSelectedCellIds || isClickHighlight ? '3' : weight}
          stroke={true}
          dashArray={isManual || hasManualVal ? '4' : '0'}
          fill={this.hasFill()}
          fillOpacity={fig.isLinkedValue ? (selectedColor === fillColor ? '0.3' : '0') : '0.22'}
          interactive={id !== 'trace'} // always set interactive to true, to avoid bugs in leaflet-react
        >
        </PolyComp>
        {guideLines}
        {this.makeExtraElements()}
        {!finished || editing ? vertices : null}
      </Fragment>
    );
  }
}

export class PolygonFigure extends Figure {
  constructor(props) {
    super(props);

    this.onPointClick = this.onPointClick.bind(this);
  }

  leafletComponent() {
    const {
      options: { finished },
    } = this.props;
    return finished ? Polygon : Polyline;
  }

  calculateGuides() {
    const { figure, options } = this.props;
    const { points } = figure;
    const { newPoint, finished } = options;
    const { draggedPoint } = this.state;

    const guides = [];
    if (draggedPoint) {
      const { point, index } = draggedPoint;
      const { length } = points;
      guides.push(
        [point, points[(index + 1) % length]],
        [point, points[(index - 1 + length) % length]]
      );
    }

    const additionalGuides =
      !finished && points.length > 0
        ? [[points[points.length - 1], newPoint]]
        : [];

    return guides.concat(additionalGuides);
  }

  makeExtraElements() {
    const { figure, options } = this.props;
    const { id, points } = figure;
    const { editing, finished, calcDistance } = options;

    const { dragging } = this.state;

    if (!finished || !editing || dragging) {
      return [];
    }

    const midPoints = points
      .map((pos, i) => [pos, points[(i + 1) % points.length], i])
      .filter(([a, b]) => calcDistance(a, b) > 40)
      .map(([a, b, i]) => (
        <CircleMarker
          key={id + '-' + i + '-mid'}
          className="midpoint"
          center={midPoint(a, b)}
          radius={8}
          opacity={0.0}
          fillOpacity={0.0}
        >
          <CircleMarker
            radius={3}
            color="white"
            fill={true}
            fillOpacity={0.5}
            opacity={0.5}
            center={midPoint(a, b)}
          />
        </CircleMarker>
      ));

    return midPoints;
  }
}

export class BBoxFigure extends Figure {
  calculateGuides() {
    const { figure, options } = this.props;
    const { points } = figure;
    const { newPoint, finished } = options;
    const { draggedPoint } = this.state;

    if (draggedPoint) {
      const renderPoints = this.getRenderPoints(points);
      const { point, index } = draggedPoint;
      const oppPoint = renderPoints[(index + 2) % renderPoints.length];
      const sidePoint1 = { lat: oppPoint.lat, lng: point.lng };
      const sidePoint2 = { lat: point.lat, lng: oppPoint.lng };
      return [
        [point, sidePoint1],
        [sidePoint1, oppPoint],
        [point, sidePoint2],
        [sidePoint2, oppPoint],
      ];
    }

    if (!finished && points.length > 0) {
      const renderPoints = this.getRenderPoints([points[0], newPoint]);
      return [
        [renderPoints[0], renderPoints[1]],
        [renderPoints[1], renderPoints[2]],
        [renderPoints[2], renderPoints[3]],
        [renderPoints[3], renderPoints[0]],
      ];
    }

    return [];
  }

  getFigures() {
    const {
      options: { infoClick, figures },
    } = this.props;
    let itemFigure;
    let valueFigure;
    let subTitleFigure;

    if (infoClick) {
      itemFigure = figures.filter(f => f.id === infoClick.item.id);
      valueFigure = figures.filter(f => f.id === infoClick.value.id);
      subTitleFigure = figures.filter(f => f.id === infoClick.subTitle.id);
    }

    const allFigure = {};
    let isLinkedValue = false;
    let linkName = null;

    if (itemFigure && itemFigure.length > 0) {
      allFigure.item = itemFigure[0];
    }
    if (itemFigure && valueFigure.length > 0) {
      allFigure.value = valueFigure[0];
      // check to be linked
      const detailData = this.props.textMappingRef.current ? this.props.textMappingRef.current.state.detailData : {};
      for (let pt in detailData) {
        if (pt !== 'manual_input') {
          for (let row of detailData[pt]) {
            if (row.value.id === valueFigure[0].id && row.scrumItem) {
              linkName = row.scrumItem;
              isLinkedValue = true;
              if (row.manualInput) {
                allFigure.value.det_text = row.manualInput;
              }
              break;
            }
          }
        }
      }
    }
    if (itemFigure && subTitleFigure.length > 0) {
      allFigure.subTitle = subTitleFigure[0];
    }

    return { ...allFigure, isLinkedValue, linkName };
  }

  getRenderPoints(points) {
    const { figure, options: { clickFigureIds, selectedCellIds } } = this.props;
    let clickFigurePoints = [];
    if (clickFigureIds && clickFigureIds.length > 0 && figure.id === clickFigureIds[1]) {
      if (selectedCellIds && selectedCellIds.length > 0) {
        if (selectedCellIds && selectedCellIds.find(d => d === figure.id)) {
          clickFigurePoints = figure.points;
        } else {
          clickFigurePoints = [];
        }
      } else {
        clickFigurePoints = figure.points
      }
    }
    if (selectedCellIds && selectedCellIds.find(d => d === figure.id)) {
      clickFigurePoints = figure.points;
    }
    const [p1, p2] = figure.det_class === "value" ? points : clickFigurePoints;
    if (!p1) {
      return [];
    }
    if (!p2) {
      return [p1];
    }

    return [
      { lat: p1.lat, lng: p1.lng },
      { lat: p1.lat, lng: p2.lng },
      { lat: p2.lat, lng: p2.lng },
      { lat: p2.lat, lng: p1.lng },
    ];
  }
}

function midPoint(p1, p2) {
  return {
    lat: (p1.lat + p2.lat) / 2,
    lng: (p1.lng + p2.lng) / 2,
  };
}
