import React, { Component } from 'react';
import { MDBDataTable } from 'mdbreact';
import moment from "moment";
import { Segment, Header, Button, Message, Loader } from 'semantic-ui-react';
import { confirmAlert } from 'react-confirm-alert';
import { FMT, toUnixMS } from '../../tenants/TimeUtil';
import api from '../../../api';
import IpFileSearch from './search';
import intl from 'react-intl-universal';
export default class Ipfile extends Component {

  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      init: true,
      isLoading: false,
      searchParam: null
    }

    this.onSearch = this.onSearch.bind(this)
    this.refetch = this.refetch.bind(this)
    this.handleDel = this.handleDel.bind(this)
  }

  async onSearch(data) {
    const { 
      id,
      address,
      startDate,
      endDate } = data;

    const searchParam = {
      id,
      address,
      startDate: startDate ? toUnixMS(moment(startDate).format(FMT.DATE)) : '',
      endDate: endDate ? toUnixMS(moment(endDate).add(1, 'd').format(FMT.DATE)) : '',
    };
    this.setState({
      isLoading: true,
      searchParam
    })
    await this.refetch(searchParam);
  }

  async refetch(searchParam) {
    searchParam = searchParam || this.state.searchParam;
    if(!searchParam.endDate){
      searchParam.endDate = toUnixMS(Date.now())
    }
    if(!searchParam.startDate){
      searchParam.startDate = 0
    }
    const blackList = (await api.get(
        `/dppapi/system/black-list-ip?startTime=${searchParam.startDate}&endTime=${searchParam.endDate}`
      )).data;
    this.setState({
      rows: blackList.ipBlacklist.map((black, index)=> {
        return {
          actions: (
            <div>
              <Button
                icon="trash"
                label={intl.get('_system.blackip.IpFile.Index.Delete')}
                size="tiny"
                onClick={() => this.handleDel(black.id)}
              />
            </div>
          ),
          id:black.id,
          address:black.ipAddress,
          faildeTime:moment(black.failedTime).format(FMT.DATE_LOCAL),
          
        }
      }).sort((t1, t2) => {
        return t1.id - t2.id
      }),
      init: false,
    })
    this.setState({
      isLoading: false
    })
  }

  handleDel(id) {
    confirmAlert({
      title: intl.get('_system.blackip.IpFile.Index.Confirm'),
      message: intl.get('_system.blackip.IpFile.Index.Do you want to delete this blacklist?'),
      buttons: [
        {
          label: intl.get('_system.blackip.IpFile.Index.OK'),
          onClick: async () => {
            await api.delete(`/dppapi/system/black-list-single/${id}`)
            this.refetch();
          },
        },
        {
          label: intl.get('_system.blackip.IpFile.Index.Cancel'),
        },
      ],
    });
  }

  render() {
    const columns = [
      {
        label: intl.get('_system.blackip.IpFile.Index.ID'),
        field: 'id',
        sort: 'asc'
      },
      {
        label: intl.get('_system.blackip.IpFile.Index.IpAddress'),
        field: 'address',
        sort: 'asc'
      },
      {
        label: intl.get('_system.blackip.IpFile.Index.FailedTime'),
        field: 'faildeTime',
        sort: 'asc',
      },
      {
        label: intl.get('_system.blackip.IpFile.Index.Actions'),
        field: 'actions',
        sort: 'asc',
      }
    ];

    const { rows, isLoading, init} = this.state;
    const data = {
      columns: columns,
      rows: rows,
    };
    var result = null;
    if (isLoading) {
      result = (<Segment><Loader active inline="centered" /></Segment>)
    } else if (rows.length > 0) {
      result = (<Segment>
        <MDBDataTable
          btn
          striped
          bordered
          hover
          data={data}
        />
      </Segment>)
    } else {
      result = (<Message
        info
        header='No Data'
        content="please change conditions"
      />)
    }
    return (
      <React.Fragment>
        <div style={{ display: 'flex' }}>
          <div className="ui" style={{ flex: 1 }}>
            <div id="search-pdf" style={{ padding: '2em 0' }}>
              <Header disabled>{intl.get('_system.blackip.IpFile.Search.FailedTime')}</Header>
              <IpFileSearch 
              onSearch={this.onSearch}
              />
            </div>
            <div id="page" style={{ paddingBottom: 200, flex: 1 }}>
              {!init ? result : null}
            </div>
          </div>
          
        </div>
      </React.Fragment>
    );
  }
}