import React, { Component } from 'react'
import { UnControlled as CodeMirror } from 'react-codemirror2'
import { debounce, isNumber } from 'lodash'
import {
  Button,
  Form,
  Icon,
  Divider,
  Header,
  Segment,
} from 'semantic-ui-react'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import 'codemirror/theme/neat.css'
import './ResultFunction.css'
// import('codemirror/mode/xml/xml.js')
import 'codemirror/addon/edit/closebrackets.js'
import api from '../../api'
import { genShortId } from '../../label/label-app/utils'
import update from 'immutability-helper'
import MyContext from '../../context-manager'
import { downloadCsv } from '../../predict/Util'

const INIT_CODE = `async function ({getTableInfo, getProjectId, getPdfId, handleOperateDialog, getFinancialStatementsExtraInfo, callback, downloadCsv, fsScrumData}) {
  /*
  =====================================
  -         API Information           -
  =====================================
  getTableInfo:
  @description: get tabel data info.
  @input: null 
  @output {
    損益計算書: {
      header: ["subTitle", "item", "value", "scrumItem"],
      body: [
        ["【売上原価】", "外注費", "214,461,280", "売上原価"],
        ["【売上原価】", "仕入高", "87,278,953", "売上原価"]
      ]
    }
  }
  -------------------------------------
  getProjectId:
  @description: get project id
  @input: null 
  @output: 1
  -------------------------------------
  @input: getPdfId
  @description: get pdf id
  @input: null
  @output: 1
  -------------------------------------
  handleOperateDialog
  @description: open common dialog
  @input: {
    open: true,
    title: "warning",
    msgs: ["please enter number", "please enter unit"],
    onOk: () => {console.log('onOK') }
  }

  @output: dialog open
  -------------------------------------
  getFinancialStatementsExtraInfo
  @description: get financial statements extrainfo
  @input: null
  @output [{ label: "BSの単位", require: true, type: "dropdown", key: "bsUnit" },
  { label: "PLの単位", require: true, type: "dropdown", key: "plUnit" },
  { label: "SSの単位", require: true, type: "dropdown", key: "sUnit" },
  { label: "取引先コード", require: true, type: "code", key: "customerCode" },
  { label: "取引先名", require: true, type: "string", key: "customerName" },
  { label: "決算期", require: true, type: "string", key: "settlementPeriod" },
  { label: "決算月数", require: true, type: "month", key: "settlementMonthNum" },
  { label: "登録完了時通知先（To）", require: true, type: "user", key: "mailTo" },
  { label: "登録完了時通知先（Cc）", require: true, type: "user", key: "mailCc" },]
  -------------------------------------
  callback
  @description: callback for rpa scuess
  -------------------------------------
  fsScrumData
  @description: the convert data of tableinfo just for fs
  @input: null

  @output: {
    貸借対照表: [
    {id: "0dyuilc9d5xn60vbqion5vo", scrumItem: "現金預金", values: [{id: 201, text: "3,187,893,186"}], manualInput: undefined},
    {id: "n7a9gx547kkjp79gbz62", scrumItem: "受取手形", values: [{id: 200, text: "288,986,962"}], manualInput: undefined}
  ], 
    損益計算書:[
      {id: "npf8jtw4d8aswr31l9cpm9", scrumItem: "売上高", values: [{id: 351, text: "13,681,028,784"}], manualInput: undefined},
        {id: "5g4pqr3dxjhlikownn3oh", scrumItem: "売上原価", values: [{id: 334, text: "10,274,580,578"}], manualInput: "12"}
    ] , 
    株主資本等変動計算書: [
      {id: "j2aqhkdl7ahmrwfqb0sdua", scrumItem: "資本金/☆前期末残高", values: [{id: 590, text: "49,500,000"}], manualInput: undefined},
      {id: "lzylks9mws9vuwku5uow6a", scrumItem: "資本金/新株の発行", values: [], manualInput: undefined}
    ]}
  -------------------------------------
  */

 //TODO
  const err = true
  const extraInfo = getFinancialStatementsExtraInfo()
  if (err) {
    handleOperateDialog({
      open: true,
      title: "RPA連携情報",
      type: "rpa",
      msgs: [],
      extraInfo: extraInfo,
      projectId: getProjectId(),
      pdfId: getPdfId(),
      callback: callback
    })
  }

  return {
    projectId: getProjectId(),
    pdfId: getPdfId(),
    tableInfo: getTableInfo(),
  } 
}`
const TEST_PARAMS = {
  sendTenantRpa: sendTenantRpa,
  getPdfInfo: () => { 
    return { id:1, projectsId: 1}
  },
  getTableInfo: () => {
    return {
      損益計算書: {
        header: ["subTitle", "item", "value", "scrumItem"],
        body: [
          ["【売上原価】", "外注費", "214,461,280", "売上原価"],
          ["【売上原価】", "仕入高", "87,278,953", "売上原価"]
        ]
      },
      貸借対照表: {
        header: ["subTitle", "item", "value", "scrumItem"],
        body: [
          ["【流動資産】", "(1)現金預金", "215,969,728", "営業利益"],
          ["【【流動資産】", "(2)受取手形", "87,278,953", "営業利益"]
        ]
      }
    }
  },
  getProjectId: () => 1,
  getPdfId: () => 1,
  getFinancialStatementsExtraInfo: () => {
    return [{ label: "BSの単位", require: true, type: "dropdown", key: "bsUnit" },
    { label: "PLの単位", require: true, type: "dropdown", key: "plUnit" },
    { label: "SSの単位", require: true, type: "dropdown", key: "sUnit" },
    { label: "取引先コード", require: true, type: "code", key: "customerCode" },
    { label: "取引先名", require: true, type: "string", key: "customerName" },
    { label: "決算期", require: true, type: "string", key: "settlementPeriod" },
    { label: "決算月数", require: true, type: "month", key: "settlementMonthNum" },
    { label: "登録完了時通知先（To）", require: true, type: "user", key: "mailTo" },
    { label: "登録完了時通知先（Cc）", require: false, type: "user", key: "mailCc" },]
  },
  fsScrumData: {
    貸借対照表: [
    {id: "0dyuilc9d5xn60vbqion5vo", scrumItem: "現金預金", values: [{id: 201, text: "3,187,893,186"}], manualInput: undefined},
    {id: "n7a9gx547kkjp79gbz62", scrumItem: "受取手形", values: [{id: 200, text: "288,986,962"}], manualInput: undefined}
  ], 
    損益計算書:[
      {id: "npf8jtw4d8aswr31l9cpm9", scrumItem: "売上高", values: [{id: 351, text: "13,681,028,784"}], manualInput: undefined},
        {id: "5g4pqr3dxjhlikownn3oh", scrumItem: "売上原価", values: [{id: 334, text: "10,274,580,578"}], manualInput: "12"}
    ] , 株主資本等変動計算書: [
      {id: "j2aqhkdl7ahmrwfqb0sdua", scrumItem: "資本金/☆前期末残高", values: [{id: 590, text: "49,500,000"}], manualInput: undefined},
      {id: "lzylks9mws9vuwku5uow6a", scrumItem: "資本金/新株の発行", values: [], manualInput: undefined}
    ]
  }
}
export default class ResultFunction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resultFunctions: [],
    }
  }
  async componentDidMount() {
    this.reload()
  }

  reload = async () => {
    const { projectId, tenantId, isSystemManager } = this.props;
    try {
      let baseUrl;
      if (isSystemManager) {
        baseUrl = `/dppapi/system/tenant-manage/project/projects-result-function?projectId=${projectId}&tenantId=${tenantId}`;
      } else {
        baseUrl = `/dppapi/projects-result-function?projectId=${projectId}`;
      }
      const { data } = await api.get(baseUrl);

      if (data.success) {
        if (data.data.length === 0) {
          this.handleAddResultFunction()
        } else {
          this.setState({
            resultFunctions: data.data
          })
        }
      }
    } catch (e) {
      console.log(e)
    }
  }
  handleButonNameChange = (id, buttonName) => {
    this.setState({ buttonName })
  }
  handleValueChange = (id, value, type) => {
    const { resultFunctions } = this.state
    const idx = resultFunctions.findIndex(rf => rf.id === id)
    this.setState({
      resultFunctions: update(resultFunctions, {
        [idx]: {
          [type]: {
            $set: value,
          },
        }
      }),
    });

  }
  handleSubmit = async (id) => {
    const { projectId, tenantId, isSystemManager } = this.props;
    const { resultFunctions } = this.state
    const idx = resultFunctions.findIndex(rf => rf.id === id)
    const rf = resultFunctions[idx]

    const isEdit = isNumber(rf.id) ? true : false

    if (isEdit) {
      try {
        let baseUrl;
        if (isSystemManager) {
          baseUrl = `/dppapi/system/tenant-manage/project/projects-result-function?projectId=${projectId}&resultFunctionId=${rf.id}&tenantId=${tenantId}`;
        } else {
          baseUrl = `/dppapi/projects-result-function?projectId=${projectId}&resultFunctionId=${rf.id}`;
        }
        const { data } = await api.put(baseUrl, { buttonName: rf.buttonName, resultFunction: btoa(encodeURIComponent(rf.resultFunction)) });
        if (data.success) {
          this.setState({
            resultFunctions: update(resultFunctions, {
              [idx]: {
                $set: data.data,
              }
            }),
          })
        }
      } catch (e) {
        console.log(e)
      }
    } else {
      try {
        let baseUrl;
        if (isSystemManager) {
          baseUrl = `/dppapi/system/tenant-manage/project/projects-result-function?projectId=${projectId}&tenantId=${tenantId}`;
        } else {
          baseUrl = `/dppapi/projects-result-function?projectId=${projectId}`;
        }
        const { data } = await api.post(baseUrl, { buttonName: rf.buttonName, resultFunction: btoa(encodeURIComponent(rf.resultFunction)) });
        if (data.success) {
          this.setState({
            resultFunctions: update(resultFunctions, {
              [idx]: {
                $set: data.data,
              }
            }),
          })
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
  handleAddResultFunction = () => {
    const { resultFunctions } = this.state
    const { projectId } = this.props
    this.setState({
      resultFunctions: update(resultFunctions, {
        $push: [{ id: genShortId(), projectId: Number(projectId), buttonName: "TestButton", resultFunction: INIT_CODE }]
      })
    })
  }

  handleDeleteResultFunction = async (projectId, id) => {
    const { resultFunctions } = this.state;
    const { tenantId, isSystemManager } = this.props;
    const idx = resultFunctions.findIndex(rf => rf.id === id);
    const isNew = isNumber(id) ? false : true;

    if (isNew) {
      this.setState({
        resultFunctions: update(resultFunctions, {
          $splice: [[idx, 1]]
        })
      })
    } else {
      try {
        let baseUrl;
        if (isSystemManager) {
          baseUrl = `/dppapi/system/tenant-manage/project/projects-result-function?projectId=${projectId}&resultFunctionId=${id}&tenantId=${tenantId}`;
        } else {
          baseUrl = `/dppapi/projects-result-function?projectId=${projectId}&resultFunctionId=${id}`;
        }
        const { data } = await api.delete(baseUrl);
        if (data.success) {
          this.setState({
            resultFunctions: update(resultFunctions, {
              $splice: [[idx, 1]]
            })
          })
        }
      } catch (e) {
        console.log(e)
      }
    }

  }
  handleTest = async (id, handleOperateDialog) => {
    const { resultFunctions } = this.state
    const idx = resultFunctions.findIndex(rf => rf.id === id)
    const resFunc = resultFunctions[idx].resultFunction

    if (resFunc.length === 0) {
      return
    }
    
    const { metadatas, success} = (await api.get(`/dppapi/admin/project/metadata/query?projectId=${this.props.projectId}&category=result_function`)).data;
    let needUnitConvert = false

    if (success) {
      const res = metadatas.find(mt=>mt.subCategory === "csv_download" && mt.item === "unit_conversion")
      needUnitConvert = res && res.value !== "0"
    }
    
    const testRes = await this.handleExecFunc(resFunc, { ...TEST_PARAMS, handleOperateDialog, downloadCsv, getProjectId: ()=>this.props.projectId, needUnitConvert,confirmBtnName:"download" });

    this.setState({
      resultFunctions: update(resultFunctions, {
        [idx]: {
          testRes: {
            $set: testRes
          }
        }
      })
    })
  }

  async handleExecFunc(func, argus) {
    let rstVal = {};
    try {
      // eslint-disable-next-line 
      const executor = new Function('return ' + func)();
      rstVal = await executor(argus);
    } catch (e) {
      console.log(e)
      rstVal = String(e);
    }
    return rstVal;
  }
  genCodeMirror = ({ projectId, id, buttonName, resultFunction, testRes }) => {
    return (
      <Segment key={`cm-${id}`}>
        <Form >
          <Form.Field>
            <Button.Group >
              <MyContext.Consumer>
                {({ handleOperateDialog }) => <Button disabled={ true } onClick={() => this.handleTest(id, handleOperateDialog)} basic={true} color="orange">
                  <Icon name="bug" />
              Test
            </Button>}
              </MyContext.Consumer>

              <Button onClick={() => this.handleSubmit(id)} basic={true} color="black">
                <Icon name="save" />
            Save
          </Button>
              <Button onClick={() => this.handleDeleteResultFunction(projectId, id)} basic={true} color="red">
                <Icon name="trash" />
            Delete
          </Button>
            </Button.Group>
          </Form.Field>

          <Form.Field>
            <label>Button Name</label>
            <input placeholder='Button Name' value={buttonName} onChange={e => this.handleValueChange(id, e.target.value, 'buttonName')} />
          </Form.Field>
          <Form.Field>
            <label>Result Function</label>
            <CodeMirror
              autoCursor={false}
              value={resultFunction}
              options={{
                mode: { name: 'javascript', json: true },
                theme: 'monokai',
                lineNumbers: true,
                tabSize: 2,
                // autofocus: true,
                autoCloseBrackets: true
              }}
              editorDidMount={(editor) => {
                editor.setSize('100%', '500px');
              }}
              onChange={debounce((editor, data, value) => {
                this.handleValueChange(id, value, 'resultFunction')
              }, 300)}
            />
          </Form.Field>
          <Divider horizontal>
            <Header as="h4">
              <Icon name="caret square right outline" />
                Output
              </Header>
          </Divider>
          <Form.Field>

            <CodeMirror
              value={testRes ? JSON.stringify(testRes) : ""}
              options={{
                mode: { name: 'javascript', json: true },
                theme: 'monokai',
                // extraKeys: { Ctrl: 'autocomplete' },
                lineNumbers: true,
                // matchBrackets: true,
                tabSize: 2,
                // keyMap: 'sublime',
                readOnly: true,
                lineWrapping: true,
              }}
              editorDidMount={(editor) => {
                editor.setSize('100%', '300px');
              }}
            />
          </Form.Field>
        </Form>
      </Segment >)
  }

  render() {
    const { resultFunctions } = this.state
    return (<Segment>
      {resultFunctions.map(rf => this.genCodeMirror(rf))}
      <Button
        circular
        icon="plus"
        size="massive"
        style={{ float: 'right', marginTop: '2em' }}
        onClick={this.handleAddResultFunction}
      />
    </Segment>)
  }
}

export async function sendTenantRpa(projectId, pdfId, mailConfig) { 
  var res = await (await api.post('/dppapi/predict/tenant/rpa/' + projectId + '/' + pdfId, mailConfig)).data;
  if (res.success) {
    localStorage.removeItem('resultFunction')
    
    const evaluateRes = await api.post(`/dppapi/predict/evaluate-preset/${projectId}/${pdfId}`);
    if(evaluateRes.data && !evaluateRes.data.success){
      return evaluateRes.data
    }
  }
  return res
}