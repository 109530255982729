import React, { Component } from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact';
import {
  // Segment,
  Form,
  // Divider,
  Button,
  Icon,
  Message,
} from 'semantic-ui-react';
import api from '../../api';

class FsItemEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editFsItem: {
        id: null,
        subTitle: '',
        item: '',
        category: '',
        fsItem: '',
      },
      errors: {},
    };
  }

  // shouldComponentUpdate(nextProps) {
  //   if (
  //     nextProps.fsItemId === this.props.fsItemId &&
  //     typeof this.props.fsItemId !== 'undefined' &&
  //     nextProps.fsItemId !== null
  //   ) {
  //     return false;
  //   }
  //   return true;
  // }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.editFsItem && nextProps.editFsItem.id && prevState.editFsItem.id !== nextProps.editFsItem.id) {
      return {...prevState, editFsItem: nextProps.editFsItem};
    }
    return null;
  }

  handleInput = (event) => {
    const { name, value } = event.target;
    const { editFsItem } = this.state;
    const fsItemForm = {};
    fsItemForm[name] = value;
    this.setState({
      editFsItem: { ...editFsItem, ...fsItemForm },
    });
  };

  checkForm() {
    const { editFsItem } = this.state;
    const errors = {};

    // if (!editFsItem.subTitle) {
    //   errors['subTitle'] = {
    //     content: 'Please enter a subTitle.',
    //     pointing: 'below',
    //     flag: true,
    //   };
    // }

    // if (!editFsItem.item) {
    //   errors['item'] = {
    //     content: 'Please enter an item.',
    //     pointing: 'below',
    //     flag: true,
    //   };
    // }

    if (!editFsItem.category) {
      errors['category'] = {
        content: 'Please enter a category.',
        pointing: 'below',
        flag: true,
      };
    }

    if (!editFsItem.fsItem) {
      errors['fsItem'] = {
        content: 'Please enter a fsItem.',
        pointing: 'below',
        flag: true,
      };
    }

    this.setState({
      errors: errors,
    });
    return Object.keys(errors).length === 0;
  }

  handleCancel = () => {
    this.clearForm();
    this.props.toggleEdit(false);
  };

  handleSave = async () => {
    if (!this.checkForm()) {
      return;
    }

    const { editFsItem } = this.state;

    try {
      if (editFsItem.id) {
        await api.put(`/dppapi/tenant-setting/fs-scrum-item/${editFsItem.id}`, editFsItem);
      } else {
        await api.post(`/dppapi/tenant-setting/fs-scrum-item`, editFsItem);
      }
      this.props.handleSearch();
      this.props.toggleEdit(false);
      this.clearForm();
    } catch (e) {
      // handle error
      const { errmsg } = e.data || {};
      this.setState({
        errors: {
          error: {
            content: errmsg,
            pointing: 'below',
            flag: true,
          }
        },
      });
    }
  };

  clearForm = () => {
    this.setState({
      editFsItem: {
        id: null,
        subTitle: '',
        item: '',
        category: '',
        fsItem: '',
      },
      errors: {},
    });
  };

  handleToggle = (toggle) => {
    this.props.toggleEdit(toggle);
    this.clearForm();
  };

  render() {
    const { errors, editFsItem } = this.state;

    return (
      <MDBModal isOpen={this.props.isShowEdit} toggle={this.handleToggle}>
        <MDBModalHeader>{editFsItem.id ? "Edit" : "Add"} Fs Item</MDBModalHeader>
        <MDBModalBody>
          {Object.keys(errors).length > 0 && (
            <Message
              error
              list={Object.keys(errors).map((i) => {
                return errors[i].content;
              })}
            />
          )}
          <Form>
            <Form.Field>
              <label>Sub Title</label>
              <Form.Input
                name="subTitle"
                placeholder="Sub Title"
                value={editFsItem.subTitle}
                onChange={(event) => this.handleInput(event)}
              />
            </Form.Field>
            <Form.Field>
              <label>Item</label>
              <Form.Input
                name="item"
                placeholder="Item"
                value={editFsItem.item}
                onChange={(event) => this.handleInput(event)}
              />
            </Form.Field>
            <Form.Field>
              <label>Category</label>
              <Form.Input
                name="category"
                placeholder="category"
                value={editFsItem.category}
                onChange={(event) => this.handleInput(event)}
              />
            </Form.Field>
            <Form.Field>
              <label>Fs Item</label>
              <Form.Input
                name="fsItem"
                placeholder="Fs Item"
                value={editFsItem.fsItem}
                onChange={(event) => this.handleInput(event)}
              />
            </Form.Field>
            <Button
              size="small"
              onClick={() => this.handleSave()}
              floated="right"
            >
              <Icon name="save" />
              Save
            </Button>
            <Button
              size="small"
              onClick={() => this.handleCancel()}
              floated="right"
            >
              <Icon name="cancel" />
              Cancel
            </Button>
          </Form>
        </MDBModalBody>
      </MDBModal>
    );
  }
}

export default FsItemEdit;
