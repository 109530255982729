import React from 'react';
import { Button, Input, Segment, Dropdown } from 'semantic-ui-react';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { getOptions } from '../Constant';
import api from '../../api'
import intl from 'react-intl-universal';
const options = getOptions();

export default class PdfSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: '',
      startDate: moment().add(-90, 'd').toDate(),
      endDate: moment().toDate(),
      users: [],
      creator: '',
      operator: '',
      status: ''
    };
    this.getUsers = this.getUsers.bind(this);
  }

  async componentDidMount() {
    await this.getUsers();
  }

  async getUsers() {
    const { projectId } = this.props;
    const { data: users } = await api.get(`/dppapi/predict/projectusers/${projectId}`);

    this.setState({
      users: users.map(user => {
        return {
          key: user.id,
          value: user.id,
          text: user.email + '（' + user.name + '）'
        }
      })
    })
  }

  onChange = (type, value) => {
    this.setState({ [type]: value });
  };

  render() {
    const { onSearch } = this.props;
    const { fileName, startDate, endDate, users, creator, operator, status } = this.state;
    return (
      <Segment>
        <div className="search-pdf-condition">
          <p className="search-pdf-label">{intl.get('_predict.admin.PdfSearch.Filename')}</p>
          <Input
            className="search-pdf-item"
            fluid
            placeholder={intl.get('_predict.admin.PdfSearch.Pleaseentername')}
            onChange={e => this.onChange('fileName', e.target.value)}
            value={fileName}
          />
        </div>
        <div className="search-pdf-condition">
          <p className="search-pdf-label">{intl.get('_predict.admin.PdfSearch.Uploadtime')}</p>
          <DatePicker
            className="search-pdf-item"
            onChange={date => this.onChange('startDate', date)}
            value={startDate}
            format="y/MM/dd"
          />{' '}
          ~
          <DatePicker
            className="search-pdf-item"
            onChange={date => this.onChange('endDate', date)}
            value={endDate}
            format="y/MM/dd"
          />
        </div>
        <div className="search-pdf-condition">
          <p className="search-pdf-label">{intl.get('_predict.admin.PdfSearch.Author')}</p>
          <Dropdown
            clearable
            options={users}
            selection
            search
            onChange={(e, data) => this.onChange('creator', data.value)}
            value={creator} />
        </div>
        <div className="search-pdf-condition">
          <p className="search-pdf-label">{intl.get('_predict.admin.PdfSearch.Personincharge')}</p>
          <Dropdown
            clearable
            options={users}
            selection
            search
            onChange={(e, data) => this.onChange('operator', data.value)}
            value={operator} />
        </div>
        <div className="search-pdf-condition">
          <p className="search-pdf-label">{intl.get('_predict.admin.PdfSearch.Processingstate')}</p>
          <Dropdown
            clearable
            options={this.props.statusOptions?this.props.statusOptions:options}
            selection
            search
            onChange={(e, data) => this.onChange('status', data.value)}
            value={status} />
        </div>

        <div className="search-pdf-condition">
          <Button type="submit"
            onClick={() => onSearch({
              fileName,
              startDate,
              endDate,
              creator,
              operator,
              status
            })}>
            {intl.get('_predict.admin.PdfSearch.Search')}
          </Button>
        </div>
      </Segment>
    );
  }
}
