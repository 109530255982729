import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ReactLogo } from './svg/img_drug-file.svg';
import './icon.css';

export class ImgDrugFileIcon extends Component {

  render() {
    const { color, className, size } = this.props;

    return (
      <ReactLogo
        className={className}
        style={{ fill: color, width: size, height: size }}
        />
    );
  }
}

ImgDrugFileIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
};

ImgDrugFileIcon.defaultProps = {
  color: '#AAAAAA',
  className: '',
  size: '24px',
};
