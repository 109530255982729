import React, { Component } from 'react';
import { parser } from '../../Util';
import { Input, Popup, Icon, Dimmer } from 'semantic-ui-react';
import api from '../../../api';
import _ from 'lodash';
import update from 'immutability-helper';
import intl from 'react-intl-universal';
import { MappingType,PageTypeList } from '../mapping/FsConstant';
import { PictureAsPdfIcon, BadgeInuseIcon} from '../../../assets/icon';
import { AnalyticsServiceContext } from '../../../common/Analytics/AnalyticsServiceProvider';
import { EventName, EventType } from '../../../common/Analytics/analyticsMetrics';
import { getCsrfToken } from '../../../common/CookieUtil';
import Hotkeys from 'react-hot-keys';

export default class PdfThumbnail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageList: [],
      searchText: '',
      searchResult: [],
      options: [{ key: '0', text: '', value: '0' }],
      isOpen: false,
      pageClassification: {},
      visble:false,
      pdfInfo: null,
    };
    // this.serachRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.findTargetImageByPriority = this.findTargetImageByPriority.bind(this);
  }
  static contextType = AnalyticsServiceContext;

  async componentDidMount() {
    this.onLoad()
  }

  onLoad = async (keepPage = false) =>{
    const { projectId, pdfFileId, onPageChange, getMinPdfImageId, onChangeOwner, textMappingRef, setPdfInfo, currentPage, tenantPreference } = this.props;

    const pdfInfo = await api.request({
      url: `/dppapi/predict/${projectId}/pdfs/${pdfFileId}`,
      method: 'GET',
    });
    this.setState({pdfInfo: pdfInfo.data});
    setPdfInfo(pdfInfo.data);

    if (tenantPreference && tenantPreference.exclusion_control_exclusion_control_status === "true" && !pdfInfo.data.userInCharge) {
      onChangeOwner(true);
    }

    const projectInfo = await api.request({
      url: `/dppapi/predict/project/${projectId}`,
      method: 'GET',
    });
    const options = [];
    projectInfo.data.classOptions.forEach((e, index) => {
      options.push({ key: index, text: e, value: e });
    });
    this.setState({ options: options });

    const data = await api.request({
      url: `/dppapi/predict/pdf/image/preview/${projectId}?pdfFileId=${pdfFileId}`,
      method: 'GET',
    });
    const result = await parser(data);
    let minPdfImageId = 0;
    var imageList = [];
    let ps = [];
    result.img.forEach(r => {
      ps.push(
        new Promise(resolve => {
          if (minPdfImageId === 0 || Number(r.pageId) < minPdfImageId) {
            minPdfImageId = Number(r.pageId);
          }
          const pdfImageData = pdfInfo.data.images.find((element) => parseInt(element.id) === parseInt(r.pageId))
          r.pageClassification = pdfImageData.pageClassification;
          r.bboxesCount = pdfImageData.bboxesCount
          r['imageStatus'] = pdfImageData.imageStatus;
          imageList.push(r);
          resolve(data);
        })
      );
    });

    Promise.all(ps).then(rst => {
      getMinPdfImageId(Number(minPdfImageId));

      imageList.sort(function(a, b) {
        return Number(a.pageId) < Number(b.pageId) ? -1 : 1;
      });
      //page番号表示
      imageList.forEach((img, idx) => {
        img['pageNo'] = idx + 1;
      })

      if (keepPage && currentPage){
        onPageChange(Number(currentPage))
      } else {
        onPageChange(Number(this.findTargetImageByPriority(imageList).pageId));
      }
      this.setState({
        imageList,
        minPdfImageId,
        pageClassification: keepPage ? this.state.pageClassification : {
          pageType: this.findTargetImageByPriority(imageList)
            .pageClassification,
          pageId: Number(this.findTargetImageByPriority(imageList).pageId),
        },
      });
      this.state.imageList.forEach(img=>{
        document.getElementById(`mini_${img.pageId}`).addEventListener('contextmenu', this._handleContextMenu);
        document.addEventListener('click', this.handleClick)
      })
        textMappingRef&&textMappingRef.current&&textMappingRef.current.initialComponent(keepPage);
    });
  }

  componentWillUnmount() {
    this.state.imageList.forEach(img=>{
      document.getElementById(`mini_${img.pageId}`).removeEventListener('contextmenu', this._handleContextMenu);
      document.removeEventListener('click', this.handleClick)
    })
  }
  handleClick = () => {
    const { visible } = this.state
    if (visible) {
      this.setState({ visible: false })
    }
  };
  _handleContextMenu = (event) => {
    event.preventDefault();

    this.setState({ visible: true });

    var idx = '';
    var id = event.srcElement.id.substring(5)
    this.state.imageList.forEach((e, index) => {
      if(id=== e.pageId){
        idx = index;
        this.setState({imageListidx:idx})
      }
    });
    const clickY = event.clientY;
    let pdfStyle= document.getElementById("pdf-id")
    const rootW = document.getElementById("img-group").offsetWidth;
    pdfStyle.style.top=`${clickY-60}px`
    pdfStyle.style.left=`${rootW+40}px`
  }

  findTargetImageByPriority(imageList) {
    const PRIORITY_MAP = {
      貸借対照表: 0,
      連結貸借対照表: 1,
      損益計算書: 2,
      製造原価明細表: 3,
      販売費及び一般管理費: 4,
      連結損益計算書: 5,
      株主資本等変動計算書: 6,
      連結株主資本等変動計算書: 7,
    };
    if (imageList && imageList.length > 0) {
      const filteredImages = imageList
        .filter(image => image.imageStatus && image.imageStatus.inUse !== false)
        .map(image => ({
          ...image,
          priority:
            typeof PRIORITY_MAP[image.pageClassification] === 'undefined'
              ? 9
              : PRIORITY_MAP[image.pageClassification],
        }));

      if (filteredImages.length === 0){
        return imageList[0];
      }  

      const sortedImages = _.sortBy(filteredImages, image => image.priority);
      return sortedImages[0];
    } else {
      return {
        pageId: '1',
        pageClassification: '',
      };
    }
  }

  async handleChange(pageId, value) {
    const { projectId, pdfFileId, pdfInfo, onSetHeaderInfo, onShowUpdateErrorDialog } = this.props;
    let data = {};
    try {
      data = await (await api.post(`/dppapi/predict/pdf/image/pageClass/${projectId}`, {
        id: pageId,
        pageClassification: value,
        pdfFileId: pdfFileId,
        prevLastEdited: pdfInfo.lastEdited
      })).data;
      if (data.success) {
        onSetHeaderInfo(data.lastEditInfo);
      }
      const { imageList } = this.state;
      const idx = imageList.findIndex(
        img => Number(img.pageId) === Number(pageId)
      );

      this.setState({
        imageList: update(imageList, {
          [idx]: {
            $set: {
              ...imageList[idx],
              pageClassification: value,
            },
          },
        }),
        pageClassification: { pageId, pageType: value },
      });
    } catch (error) {
      data.success = false;
      onShowUpdateErrorDialog(error);
    }
    return data;
  }

  clickImg = img => {
    const { onPageChange, textMappingRef, tenantPreference } = this.props;
    onPageChange(img.pageId);
    //change page type on textMappingTable component
    const idx = this.state.imageList.findIndex(
      i => Number(i.pageId) === Number(img.pageId)
    );
    if (idx !== -1) {
      const pageType = this.state.imageList[idx].pageClassification;
      let mappingType = (pageType === '株主資本等変動計算書' || pageType === '連結株主資本等変動計算書') ? MappingType.LIST_AND_TABLE : textMappingRef.current.state.cacheMappingType;
      let template_SS = tenantPreference["template_SS"];
      let listViewFlg = 1;
      if (template_SS === 'SMFL' 
        || (template_SS === 'COMMON_TABLE' && textMappingRef.current.state.ssCommon)) {
          if (pageType === '株主資本等変動計算書' || pageType === '連結株主資本等変動計算書') {
            listViewFlg = 3;
          }else if(textMappingRef.current.state.cacheMappingType){
            listViewFlg = 2;
          }
      } else {
        if (pageType === '株主資本等変動計算書' || pageType === '連結株主資本等変動計算書') {
          listViewFlg = 1;
        }else if(textMappingRef.current.state.cacheMappingType){
          listViewFlg = 2;
        }
      }
      if (textMappingRef.current.state.mode === 2) {
        listViewFlg = 4;
      }
      this.props.onListViewChange(listViewFlg);
      if(PageTypeList.indexOf(pageType) === -1&&pageType !== '販売費及び一般管理費' && pageType !== '製造原価明細表' 
        && pageType !== '連結損益計算書' && pageType !== '連結貸借対照表' && pageType !== '連結株主資本等変動計算書'){
        mappingType = textMappingRef.current.state.mappingType;
      }
      textMappingRef &&
        textMappingRef.current &&
        textMappingRef.current.handleMappingTypeChange(
          mappingType,
          textMappingRef.current.state.cacheMappingType
        );
      textMappingRef &&
        textMappingRef.current &&
        textMappingRef.current.handlePageTypeChange(
          pageType === '販売費及び一般管理費' || pageType === '製造原価明細表' || pageType === '連結損益計算書'? '損益計算書' 
          : pageType === '連結貸借対照表'? '貸借対照表' : pageType === '連結株主資本等変動計算書' ? '株主資本等変動計算書' : pageType,
          true
        );
      this.setState({
        pageClassification: { pageType, pageId: img.pageId },
      });
    }
  }

  imgPanel = img => {
    const { currentPage, pdfInfo } = this.props;
    const inUse = pdfInfo&&pdfInfo.images.find(i=>i.imageStatus&&i.imageStatus.inUse&&img.pageId.toString()===i.id.toString())

    return (
      <div
        key={`mini_${img.pageId}`}
        id={`mini_${img.pageId}`}
        className={`pdf-thumbnail-root-img-panel`}
        ref={ref => {
          const currentRef = document.getElementById(`mini_${img.pageId}`);
          const searchBoxHeight = document.getElementsByClassName('pdf-thumbnail-root-search')[0].clientHeight
          if (Number(currentPage) === Number(img.pageId) && ref) {
            const { bottom } = ref.getBoundingClientRect()
            const { height } = document.getElementsByClassName("pdf-thumbnail-root")[0].getBoundingClientRect()
            if( bottom > height || bottom < 0){
              setTimeout(() => {
                // testRef.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
                currentRef.parentNode.scrollTop = currentRef.offsetTop - currentRef.parentNode.offsetTop - searchBoxHeight - 45;
              }, 0);
            }
          }
        }}
        onClick={() => {
          this.clickImg(img)
        }}
      >
        <div
          id="pdf-thumbnail-root-img"
          className={` pdf-thumbnail-root-img-border ${
            Number(currentPage) === Number(img.pageId)
              ? 'pdf-thumbnail-root-img-border-current'
              : null
          }`}
        >
          <div id= "img-group" className="pdf-thumbnail-root-img-group" name={img.pageId}>
            <div style={{'width': '63px','height': '80px', position: 'relative'}}>
              <img id={`mini_${img.pageId}`} alt="pdfThumbnail" src={img.url} width="62px" height="80px" />
              {inUse?(
                <span className='pdf-thumbnail-inuse-div'>
                  <BadgeInuseIcon/>
                </span>
              ):null}
            </div>
            <div className="pdf-thumbnail-root-img-tip">
              {img.pageNo ? img.pageNo : img.pageId}
            </div>
            <div className={`${  Number(currentPage) === Number(img.pageId)
              ? 'pdf-thumbnail-root-img-pcf-current'
              : 'pdf-thumbnail-root-img-pcf'
          }`}>{img.pageClassification}</div>
          </div>
        </div>
      </div>
    );
  };
  handleEnter = async e => {
    if (e.keyCode === 13) {
      const { projectId, pdfFileId } = this.props;
      const { searchText } = this.state;
      try {
        if (searchText === '') {
          setTimeout(() => {
            this.setState({ isOpen: false });
          }, 2000);
          this.setState({ isOpen: true, searchResult: [] });
          return;
        }
        const { data } = await api.request({
          url: `/dppapi/predict/pdf/search/brief/${projectId}/${pdfFileId}?keyword=${searchText}`,
          method: 'GET',
        });
        if (data.success) {
          setTimeout(() => {
            this.setState({ isOpen: false });
          }, 2000);
          data.result.length === 0 && this.setState({ isOpen: true });
          this.setState({ searchResult: data.result });
        }
      } catch (e) {
        console.log('api error');
      }
      this.context.sendPredictBeautifyDetailMetrics({
        event_type: EventType.Action,
        event_name: EventName.SearchDocumentText,
      })
    }
  };

  handleSearchTextChange = text => {
    text === '' && this.setState({ searchResult: [] });
    this.setState({ searchText: text });
    this.setState({ isOpen: false });
  };
  handleClear = () => {
    this.setState({ searchText: '', searchResult: [] });
    this.setState({ isOpen: false });
  };

  // this method is for page-type-change on textMapping component
  handlePageTypeChange = pageType => {
    const { imageList } = this.state;
    const idx = imageList.findIndex(img => img.pageClassification === pageType);

    if (idx !== -1) {
      const pageId = imageList[idx].pageId;
      this.props.onPageChange(Number(pageId));
    }
  };

  handleRowClick = (url,pageId) => {
    var windowFeatures = `width=800,height=1200`;
    // const win = window.open(url, '_blank', windowFeatures);
    const win = window.open(url + `?token=${getCsrfToken()}` + '#page=' + pageId, '_blank', windowFeatures);
    win.focus()
  }

  onKeyDown(keyName, e) {
    e.preventDefault();
    const { imageList } = this.state;
    const { currentPage, onPageChange, textMappingRef, tenantPreference } = this.props

    const idx = imageList.findIndex( i => Number(i.pageId) === Number(currentPage) );
    let nextIdx = idx
    switch (keyName) {
      case 'down':
        nextIdx = idx + 1;
        break;
      case 'up':
        nextIdx = idx - 1;
        break;
      default:
        // do nothing
    }

    if (nextIdx > imageList.length - 1 || nextIdx < 0){
      return;
    }

    onPageChange(imageList[nextIdx].pageId)

    const pageType = imageList[nextIdx].pageClassification;
    let mappingType = (pageType === '株主資本等変動計算書' || pageType === '連結株主資本等変動計算書') ? MappingType.LIST_AND_TABLE : textMappingRef.current.state.cacheMappingType;

    let template_SS = tenantPreference["template_SS"];
    let listViewFlg = 1;
    if (template_SS === 'SMFL' 
      || (template_SS === 'COMMON_TABLE' && textMappingRef.current.state.ssCommon)) {
        if (pageType === '株主資本等変動計算書' || pageType === '連結株主資本等変動計算書') {
          listViewFlg = 3;
        }else if(textMappingRef.current.state.cacheMappingType){
          listViewFlg = 2;
        }
    } else {
      if (pageType === '株主資本等変動計算書' || pageType === '連結株主資本等変動計算書') {
        listViewFlg = 1;
      }else if(textMappingRef.current.state.cacheMappingType){
        listViewFlg = 2;
      }
    }
    this.props.onListViewChange(listViewFlg);

    if(PageTypeList.indexOf(pageType) === -1 && pageType !== '販売費及び一般管理費' && pageType !== '製造原価明細表' 
      && pageType !== '連結損益計算書' && pageType !== '連結貸借対照表' && pageType !== '連結株主資本等変動計算書'){
      mappingType = textMappingRef.current.state.mappingType;
    }
    textMappingRef &&
      textMappingRef.current &&
      textMappingRef.current.handleMappingTypeChange(
        mappingType,
        textMappingRef.current.state.cacheMappingType
      );
    textMappingRef &&
      textMappingRef.current &&
      textMappingRef.current.handlePageTypeChange(
        pageType === '販売費及び一般管理費' || pageType === '製造原価明細表' || pageType === '連結損益計算書' ? '損益計算書' 
        : pageType === '連結貸借対照表'? '貸借対照表' :  pageType === '連結株主資本等変動計算書' ? '株主資本等変動計算書' : pageType,
        true
      );

      this.setState({ pageClassification: { pageType, pageId: imageList[nextIdx].pageId }});
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevProps.currentPage && prevProps.currentPage !== this.props.currentPage){
      const currentPageId = this.props.currentPage
      const currentImage = currentPageId&&this.state.imageList.find(img=> img.pageId === currentPageId.toString())
      if(currentImage){
        this.setState({
          pageClassification: { pageType: currentImage.pageClassification, pageId: currentImage.pageId }
        })
      }

    }
  }

  render() {
    const { searchText, imageList, searchResult, visible, imageListidx} = this.state;
    const { showThumbnail, projectId, pdfFileId, pageRereadLoading, effectiveHotkeyPage} = this.props;
    let fliteredImageList = _.cloneDeep(imageList);
    if (searchResult.length > 0) {
      fliteredImageList = imageList.filter(
        i => searchResult.indexOf(Number(i.pageId)) !== -1
      );
    }

    const hotKeyDom = effectiveHotkeyPage === "thumbnail" ? (
      <Hotkeys
        keyName="up, down"
        onKeyDown={this.onKeyDown.bind(this)}
      />) : null;

    return (
      <div
        className={`pdf-thumbnail-root ${
          showThumbnail ? '' : 'pdf-thumbnail-root-hidden'
        }`}
      >
        {pageRereadLoading && (
          <Dimmer active inverted style={{width:'100px'}}>
          </Dimmer>
        )}
        {hotKeyDom}
        <Popup
          trigger={
            <Input
              icon
              placeholder={intl.get('_predict.detail.pdf.Thumbnail.Search')}
              fluid
              size="mini"
              className="pdf-thumbnail-root-search"
              value={searchText}
              onChange={(e, data) => this.handleSearchTextChange(data.value)}
              onKeyDown={e => this.handleEnter(e)}
            >
              <input />
              {searchText.length === 0 ? (
                <Icon name="search" />
              ) : (
                <Icon name="remove" link onClick={() => this.handleClear()} />
              )}
            </Input>
          }
          content={intl.get(
            '_predict.detail.pdf.Thumbnail.I searched and did not find any hit results'
          )}
          open={this.state.isOpen}
        />
        {fliteredImageList.map(img => this.imgPanel(img))}
        {visible && <div id ="pdf-id" className='pdf-open'  onClick={() => this.handleRowClick(`/dppapi/uploads/prediction/${projectId}/${pdfFileId}.pdf`,imageListidx+1)}> <div><PictureAsPdfIcon color={'#545454'} size='24px'/>PDFを別ウインドウで開く</div></div>}
      </div>
    );
  }
}
