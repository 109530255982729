import React, { Component } from 'react';
import update from 'immutability-helper';
import api from '../../../api'

export function withHistory(Comp) {
  return class HistoryLayer extends Component {
    constructor(props) {
      super(props);

      const { labelData, labels } = props;
      let figures = {};

      labels.map(label => (figures[label.id] = []));
      figures.__temp = [];
      Object.keys(labelData).forEach(key => {
        figures[key] = (figures[key] || []).concat(labelData[key]);
      });
      figures = this.flipY(figures);
      this.state = {
        figures, // mapping from label name to a list of Figure structures
        unfinishedFigure: null,
        figuresHistory: [],
        unfinishedFigureHistory: [],
      };

      this.pushState = this.pushState.bind(this);
      this.popState = this.popState.bind(this);

      this.getPdfImages = this.getPdfImages.bind(this);
      this.getImageBBoxes = this.getImageBBoxes.bind(this);
      this.getPrediction = this.getPrediction.bind(this);
      this.getParsingFunction = this.getParsingFunction.bind(this);
    }

    flipY(figures) {
      // flip the y-coordinate
      const f = {};
      Object.keys(figures).forEach(label => {
        f[label] = figures[label].map(figure => {
          if (figure.type !== 'polygon' && figure.type !== 'bbox')
            return figure;

          let tracingOptions;
          if (figure.tracingOptions && figure.tracingOptions.enabled) {
            tracingOptions = {
              ...figure.tracingOptions,
              trace: this.transformPoints(figure.tracingOptions.trace),
            };
          } else {
            tracingOptions = figure.tracingOptions;
          }

          return {
            ...figure,
            points: this.transformPoints(figure.points),
            tracingOptions,
          };
        });
      });
      return f;
    }

    transformPoints(points) {
      const { height } = this.props;
      if (points.length === 2) {// figure type is bbox
        points = this.orderPoint(points);
      }
      return points.map(({ lat, lng }) => ({
        lat: height - lat,
        lng,
      }));
    }

    orderPoint(points) {
      const [p1, p2] = points;
      var minlat = p1.lat > p2.lat ? p1.lat : p2.lat;
      var maxlat = p1.lat < p2.lat ? p1.lat : p2.lat;
      var minlng = p1.lng < p2.lng ? p1.lng : p2.lng;
      var maxlng = p1.lng > p2.lng ? p1.lng : p2.lng;
  
      return [
        { lat: minlat, lng: minlng },
        { lat: maxlat, lng: maxlng },
      ];
    }

    // componentDidUpdate(prevProps, prevState) {
    //   const { onLabelChange, height, width } = this.props;
    //   const { figures } = this.state;

    //   if (figures !== prevState.figures) {
    //     onLabelChange({
    //       labels: this.flipY(figures),
    //       height,
    //       width,
    //     });
    //   }
    // }

    pushState(stateChange, cb) {
      this.setState(
        state => ({
          figuresHistory: update(state.figuresHistory, {
            $push: [state.figures],
          }),
          unfinishedFigureHistory: update(state.unfinishedFigureHistory, {
            $push: [state.unfinishedFigure],
          }),
          ...stateChange(state),
        }),
        cb
      );
    }

    popState() {
      this.setState(state => {
        let { figuresHistory, unfinishedFigureHistory } = state;
        if (!figuresHistory.length) {
          return {};
        }

        figuresHistory = figuresHistory.slice();
        unfinishedFigureHistory = unfinishedFigureHistory.slice();
        const figures = figuresHistory.pop();
        let unfinishedFigure = unfinishedFigureHistory.pop();

        if (unfinishedFigure && !unfinishedFigure.points.length) {
          unfinishedFigure = null;
        }

        return {
          figures,
          unfinishedFigure,
          figuresHistory,
          unfinishedFigureHistory,
        };
      });
    }

    async getPdfImages(projectId, pdfId) {
      const { pdfImages } = (await api.get(`/dppapi/predict/pdf-images/${projectId}/${pdfId}`)).data;
      return pdfImages
    }

    async getImageBBoxes(projectId, pdfId, imageId) {
      const { image } = (await api.get(`/dppapi/predict/pdf/page/bboxes/${projectId}/${imageId}`)).data
      return image
    }

    async getPrediction(projectId, pdfId) {
      const { prediction } = (await api.get(`/dppapi/predict/prediction/${projectId}/${pdfId}/query`)).data
      return prediction
    }

    async getParsingFunction(projectId) {
      const { parsingFunction } = (await api.get('/dppapi/predict/re-analyze/' + projectId)).data;
      return parsingFunction
    }

    async getMetadataByCategory(projectId, category) {
      const { metadatas } = (await api.get(`/dppapi/predict/prediction/metadata/query?projectId=${projectId}&category=${category}`)).data;
      return metadatas
    }

    async saveParsingResult(projectId, pdfId, parsingResult) {
      await api.post(`/dppapi/predict/prediction/${projectId}/${pdfId}/update`, parsingResult)
    }

    handlePredictFsScrumMap = async (projectId, pdfFileId, pdfImageId) => {
      try {
        // eslint-disable-next-line 
        const parsingFunction = new Function('return ' + (await this.getParsingFunction(projectId, pdfFileId)))()
        const parsingResult = await parsingFunction({
          getCurrentImageId: () => {
            return pdfImageId
          },
          getPdfImages: async () => {
            return await this.getPdfImages(projectId, pdfFileId)
          },
          getImageBBoxes: async (pdfImageId) => {
            return await this.getImageBBoxes(projectId, pdfFileId, pdfImageId)
          },
          getPrediction: async () => {
            return await this.getPrediction(projectId, pdfFileId)
          },
          getMetadataByCategory: async ( category ) => {
            return await this.getMetadataByCategory(projectId, category)
          },
        })
        await this.saveParsingResult(projectId, pdfFileId, parsingResult)
	      return {projectId: projectId, pdfFileId: pdfFileId, scrumMaps: parsingResult, success: true};
      } catch(e) {
        return {}
      }
      //return (await api.get(`/dppapi/predict/prediction/${projectId}/${pdfFileId}/${pdfImageId}/generate`)).data;
    }

    handlePredict = async (projectId, pdfFileId, pdfImageId) => {
      return (await api.get(`/dppapi/predict/${projectId}/${pdfFileId}/${pdfImageId}/predict`)).data;
    }

    handleAllBBoxDelete = async (projectId, pdfFileId, pdfImageId) => {
      return (await api.get(`/dppapi/predict/pdf/image/bbox/${projectId}/${pdfFileId}/${pdfImageId}/delete`)).data;
    }

    handleAddPdfToImage = async (projectId, pdfFileId, pdfImageId) => {
      return (await api.get(`/dppapi/labeling/${projectId}/${pdfFileId}/${pdfImageId}/addPdfToImage`)).data;
    }
  
    handleBBoxDelete = async (projectId, pdfFileId, pdfImageId, bboxId) => {
      return await (await api.get(`/dppapi/predict/bbox/delete/${projectId}/${pdfFileId}/${bboxId}`)).data
    }

    handleBBoxGenerate = async (projectId, pdfFileId, pdfImageId, coordinate, content, contentType) => {

      let url = `/dppapi/predict/pdf/bbox/generateId/${projectId}?pdfFileId=${pdfFileId}&pdfImageId=${pdfImageId}`

      if (typeof coordinate === 'object') {
        url += '&coordinate=' + JSON.stringify(coordinate);
      }
      if (content) {
        url += '&content=' + content;
      }
      if (contentType) {
        url += '&contentType=' + contentType;
      }

      return await (await api.get(url)).data
    }

    handleBBoxUpdate = async ({ projectId, pdfFileId, pdfImageId, bboxId, coordinate, bboxOrder, stateFlag, errmsg, content, contentType, checkFlag, accuracy }) => {

      const url = `/dppapi/predict/pdf/bbox/update/${projectId}/${pdfFileId}/${pdfImageId}/${bboxId}`;
      const body = {
        coordinate,
        content
      }

      if (bboxOrder !== undefined && bboxOrder !== null) {
        body["bboxOrder"] = bboxOrder
      }
      if (stateFlag !== undefined && stateFlag !== null) {
        body["stateFlag"] = stateFlag
      }
      if (errmsg !== undefined && errmsg !== null) {
        body["errmsg"] = errmsg
      }
      if (contentType !== undefined && contentType !== null) {
        body["contentType"] = contentType
      }
      if (checkFlag !== undefined && checkFlag !== null) {
        body["checkFlag"] = checkFlag
      }
      if (accuracy !== undefined && accuracy !== null) {
        body["accuracy"] = accuracy
      }

      const headers = {'Content-Type': 'application/json'}
      return await (await api.post(url, body, {headers: headers})).data
    }

    render() {
      const { 
        props, 
        state, 
        pushState, 
        popState, 
        handlePredictFsScrumMap, 
        handlePredict, 
        handleAllBBoxDelete, 
        handleAddPdfToImage,
        handleBBoxDelete, 
        handleBBoxGenerate, 
        handleBBoxUpdate ,
      } = this;
      const { figures, unfinishedFigure } = state;
      const passedProps = {
        pushState,
        popState,
        figures,
        unfinishedFigure,
        onPredictFsScrumMap: handlePredictFsScrumMap,
        onPredict: handlePredict,
        onAllBBoxDelete: handleAllBBoxDelete,
        onAddPdfToImage: handleAddPdfToImage,
        onBBoxDelete: handleBBoxDelete,
        onBBoxGenerate: handleBBoxGenerate,
        onBBoxUpdate: handleBBoxUpdate
      };
      return <Comp {...passedProps} {...props} />;
    }
  };
}
