import React, { Component } from 'react';
import { Segment, Button, Message, Form, Icon } from 'semantic-ui-react';
import api from '../../api';
import { getCsrfToken } from '../../common/CookieUtil';
import './TensorflowServing.css';

export default class TensorflowServing extends Component {
  constructor(props) {
    super(props);
    this.state = { submitLoading: false, downloadPbLoading: false, downloadH5Loading: false,  message: "" };
    this.fileInput = React.createRef();
  }


  handleFilesSubmit = async (e) => {
    const train = this.props.train;
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);

    if (this.fileInput.current.files.length === 0) {
      this.setState({
        message: "please choose file",
        submitLoading: false,
      });
      return
    }

    this.setState({ message: null, submitLoading: true });

    //const { data } = await api.post(`/dppapi/predict/pdf_uploads/${this.props.projectId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    //const { errmsg } = data;
    //TOTO: replace :projectId and :trainId to their real ID
    const response = await api.post(`/dppapi/train/upload/model/${train.projectId}/${train.id}`, { body: formData, });

    const rslt = await response.data;
    this.setState({
      submitLoading: false
    })
    if (rslt.success) {
      this.setState({
        message: "Upload succeed."
      })
    }else{
      this.setState({
        message: rslt.errmsg
      })
    }
    form.reset();
  }

  handleDownloadPb = async () => {
    const train = this.props.train;

    const response = await api.get(`/dppapi/train/export/pb/${train.projectId}/${train.id}` );
    this.setState({
      downloadPbLoading: true,
    })

    const body = await response.data;
    const rslt = JSON.parse(body);
    if (rslt.success) {
      let a = document.createElement('a');
      a.href = `${rslt.link}?token=${getCsrfToken()}`;
      a.click();
      a.remove();
      this.setState({
        downloadPbLoading: false,
      })
    } else {
      this.setState({
        downloadPbLoading: false,
        message: rslt.errmsg
      })
    }
  }

  handleDownloadH5 = async () => {
    const train = this.props.train;
    const response = await api.get(`/dppapi/train/export/h5/${train.projectId}/${train.id}` );
    this.setState({
      downloadH5Loading: true,
    })

    const body = await response.data;
    const rslt = JSON.parse(body);
    if (rslt.success) {
      let a = document.createElement('a');
      a.href = `${rslt.link}?token=${getCsrfToken()}`;
      a.click();
      a.remove();
      this.setState({
        downloadH5Loading: false,
      })
    } else {
      this.setState({
        downloadH5Loading: false,
        message: rslt.errmsg
      })
    }
  }


  render() {
    const { message, submitLoading, downloadPbLoading, downloadH5Loading } = this.state;

    return (
      <Segment className="tensor-container">
        <Form
          method="post"
          encType="multipart/form-data"
          onSubmit={this.handleFilesSubmit}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Button
            type="submit"
            style={{ width: '20%' }}
          >
            {submitLoading ? <Icon name='circle notched' submitLoading /> : "Submit"}
          </Button>
          <input
            type="file"
            id="model"
            name="model"
            accept=".zip"
            style={{ width: '80%' }}
            className='tensor-input'
            ref={this.fileInput}
            disabled={submitLoading}
          />
        </Form>
        <Button
            style={{ width: '20%' }}
            onClick={() => {this.handleDownloadPb()}}
          >
            {downloadPbLoading ? <Icon name='circle notched' downloadPbLoading /> : "Download Weight (pb)"}
        </Button>
        <Button
            style={{ width: '20%' }}
            onClick={() => {this.handleDownloadH5()}}
          >
            {downloadH5Loading ? <Icon name='circle notched' downloadH5Loading /> : "Download Weight (H5)"}
        </Button>
        {message ? <Message negative>{message}</Message> : null}

      </Segment>
    );
  }
}
