import React, { Component } from 'react';
import Hotkeys from 'react-hot-keys';
import intl from 'react-intl-universal';

import _ from 'lodash';
import throttle from 'lodash.throttle';
import { Table, Menu, Ref, Popup } from 'semantic-ui-react';

import { PageType, SubjectName } from './FsConstant';
import { SCRUM_ITEM_SUFFIX } from '../../Constant';
import { scrollToElementByPosition } from '../../Util';
import { ExclamationTriangleIcon, CheckCircleIcon } from '../../../assets/icon';
import { AnalyticsServiceContext } from '../../../common/Analytics/AnalyticsServiceProvider';
import { EventName, EventType, ViewMode } from '../../../common/Analytics/analyticsMetrics';

import ValueTableItem from './ValueTableItem';
import ShareholdersEquityTable from './ShareholdersEquityTable';
import ShareholdersEquityCommonTable from './ShareholdersEquityCommonTable';
import AccountTitleDictionary from './AccountTitleDictionary';
import CurrentPageErrorList from './CurrentPageErrorList';

let clickHoldTimer = null;
export default class FsKeyValueTable extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleReload = this.handleReload.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleFilterItems = this.handleFilterItems.bind(this);
    this.handleInputChangeDebounced = throttle(this.manualInputChange, 100);
    this.state = {
      anchorId: null,
      listSeparation: [],
      firstRow: {},
      nextRow: {},
      visibleSection: null,
      operationMethod: '',
      windowSize: { width: window.innerWidth, height: window.innerHeight },
      openable: true,
      filterItems: [],
      prevPageType: '',
      hasVerticalScrollbar: false,
      hasHorizontalScrollbar: false,
      longKeyPressed: false,
      filterRows: null,
    };
    this.tableItems = {};
    this.popups = {};
    this.TableRef = React.createRef();
    this.TableEquityRef = React.createRef();
    this.ValueTableItemRef = React.createRef();
    this.scrollContentsRef = React.createRef();
    this.resizeObserver = null;
  }
  static contextType = AnalyticsServiceContext;

  checkScrollbars = () => {
    const element = this.TableRef.current;
    if(element){
      const hasVerticalScrollbar = element.scrollHeight > element.clientHeight;
      const hasHorizontalScrollbar = element.scrollWidth > element.clientWidth;
      this.setState({ 
        hasVerticalScrollbar,
        hasHorizontalScrollbar 
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    this.handleInputChangeDebounced.cancel();
    // this.handleScroll();
    // this.TableRef.current.removeEventListener('scroll', this.handleScroll);
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    let scrollElement = document.getElementById('fs-key-value-table');
    if (scrollElement) {
      scrollElement.scrollTo({
        top: this.props.fsKeyValueTableScrollTop,
        behavior: 'smooth',
      });
    }
    this.setListSeparation(this.props.rows);
    this.checkScrollbars();
    this.resizeObserver = new ResizeObserver(() => {
        this.checkScrollbars();
    });
    // Real-time keyboard input monitoring to prevent default browser actions
    document.addEventListener("keydown", (event) => {
      if (["ArrowDown", "ArrowUp", "Tab"].includes(event.code)) {
        event.preventDefault();
      }
      if (event.repeat) {
        return; // Avoid processing repearted key presses
      }
    })
  }

  handleResize = () => {
    this.setState({
      windowSize: {
        width: window.innerWidth,
        height: window.innerHeight
      }
    })
  }

  onSetFilterItems = (filterItems) => this.setState({ filterItems: filterItems, prevPageType: this.props.currentPageType });

  componentDidUpdate(prevProps, prevState) {
    const {
      scrollToElement,
      tenantPreference,
      currentPageType,
      ssCommon,
      rows,
      selectedCell,
    } = this.props;

    const { filterItems } = this.state;
    const ss_2_dimension = tenantPreference["template_SS"] === 'SMFL' && currentPageType === PageType.SS;
    const ss_common = tenantPreference["template_SS"] === "COMMON_TABLE" && currentPageType === PageType.SS && ssCommon;
    if (scrollToElement && scrollToElement !== prevProps.scrollToElement) {
      const scrollElement = document.getElementById(scrollToElement.scrumItem);
      if (scrollElement) {
        if (!ss_2_dimension && !ss_common) {
          scrollToElementByPosition(scrollElement, 'center');
        } else {
          setTimeout(() => {
            scrollElement.scrollIntoView({
              // behavior: 'smooth',
              block: 'center',
              inline: 'nearest',
            });
          }, 0);
        }
      }
    }
    if (
      this.props.currentPageType &&
      this.props.currentPageType !== prevProps.currentPageType
    ) {
      let scrollElement = document.getElementById('fs-key-value-table');
      if (scrollElement) {
        scrollElement.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } else if (prevProps.searchText.length !== 0) {
      let scrollElement = document.getElementById(prevProps.searchText);
      if (scrollElement) {
        scrollElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
    if (this.props.rows !== prevProps.rows) {
      this.setListSeparation(this.props.rows);
    }
    if (prevState.windowSize.width !== this.state.windowSize.width ||
      prevState.windowSize.height !== this.state.windowSize.height) {
      this.handleReload();
    }
    // this.handleScroll();
    // this.TableRef.current.addEventListener('scroll', this.handleScroll);

    if(this.TableRef.current){
      this.resizeObserver.observe(this.TableRef.current);
    }

    if (prevProps.currentPageType !== currentPageType) {
      this.onSetFilterItems([]);
    }

    if (filterItems && filterItems.length > 0 && (ss_common || ss_2_dimension)) {
      const scrollToErrorElement = _.find(rows, (row) => {
        return filterItems.at(-1) === row.scrumItem
      });
      if (scrollToErrorElement &&
        (selectedCell.id === '' ||
          (selectedCell.id !== '' && scrollToErrorElement.id !== selectedCell.id))) {
        let scrollElement = document.getElementById(scrollToErrorElement.scrumItem);
        scrollToElementByPosition(scrollElement, 'center');
        // scrollElement.scrollIntoView({
        //   behavior: 'smooth',
        //   block: 'center',
        // });
        scrollElement.click();
      }
    }

    const filterRows = this.handleFilterItems();
    if (!_.isEqual(this.state.filterRows, filterRows)) {
      this.setState({ filterRows: filterRows })
    }
  }

  handleFilterItems = () => {
    const {
      currentPageType,
      rows,
    } = this.props;

    const { filterItems, prevPageType } = this.state;

    let filterRows;
    let isFiltering = false;
    if (filterItems && filterItems.length > 0) {
      isFiltering = prevPageType === currentPageType;
    }

    if (isFiltering) {
      filterRows = _.filter(rows, (row) => {
        if (filterItems.includes(row.scrumItem) ||
          filterItems.at(-1) === row.scrumItem.replace('(自動集計)', '')) {
          return true;
        }
      })
    } else {
      filterRows = rows;
    }
   
    return filterRows;
  }

  macOsKeyOperation = (event) => {
    if (event.metaKey && event.keyCode === 40) {
      this.onKeyDown("⌘+down", event)
    }
    if (event.metaKey && event.keyCode === 38) {
      this.onKeyDown("⌘+up", event)
    }
  }

  handleReload() {
    this.setState({ operationMethod: this.state.operationMethod });
  }

  handleChangeRow = (selectedCell, changeType, useShift) => {
    const { detailData, currentPageType } = this.props;
    const { filterRows: rows } = this.state;
    const selectedCellIndex = _.findIndex(rows, { id: selectedCell.id });

    let row;
    switch (changeType) {
      case 'PREVIOUS':
        if (selectedCellIndex !== -1 && selectedCellIndex > 0) {
          if (useShift) {
            const copyRows = _.clone(rows);
            const currentRows = copyRows.splice(0, selectedCellIndex);
            const haveValueIndex = _.findLastIndex(currentRows, (r) => {
              return r.values.length > 0 && !_.includes(r.scrumItem, '(自動集計)');
            });
            row = rows[haveValueIndex];
          } else {
            row = !_.includes(rows[selectedCellIndex - 1].scrumItem, '(自動集計)') ? rows[selectedCellIndex - 1] : rows[selectedCellIndex - 2];
          }
          break;
        } else {
          return;
        }
      case 'NEXT':
        if (selectedCellIndex !== -1 && selectedCellIndex < rows.length - 1) {
          if (useShift) {
            const haveValueIndex = _.findIndex(rows, (row, i) => {
              return i >= selectedCellIndex + 1 && row.values.length > 0 && !_.includes(row.scrumItem, '(自動集計)');
            });
            row = rows[haveValueIndex];
          } else {
            row = !_.includes(rows[selectedCellIndex + 1].scrumItem, '(自動集計)') ? rows[selectedCellIndex + 1] : rows[selectedCellIndex + 2];
          }
          break;
        } else {
          return;
        }
      case "CURRENT":
        row = _.find(rows, { id: selectedCell.id });
        break;
      default :
       // Do nothing
    }
    if (row && row.id !== undefined) {
      const detailIds = [];
      const pdfImageIds = [];
      if (detailData && detailData[currentPageType]) {
        const detail = detailData[currentPageType].filter(i => i.scrumItem === row.scrumItem);
        if (detail && detail.length > 0) {
          if (detail[0].item) {
            detailIds.push(detail[0].item.id);
          }
          if (detail[0].value) {
            detailIds.push(detail[0].value.id);
          }
          if (detail[0].pdfImageId && !pdfImageIds.includes(detail[0].pdfImageId)) {
            pdfImageIds.push(detail[0].pdfImageId);
          }
        }
      }
      this.handleRowOperation(row, detailIds, pdfImageIds);
      document.getElementById(row.scrumItem).focus();
    }
  }

  handleScroll = () => {
    const { visibleSection } = this.state;
    const { height: contentHeight } = this.getDimensions(this.TableRef.current);
    let selected = null;
    Object.keys(this.tableItems).forEach(key => {
      const ele = this.tableItems[key].current;
      if (ele) {
        const { y } = this.getDimensions(ele);
        if (y <= contentHeight) {
          selected = key;
        }
      }
    });
    if (selected && selected !== visibleSection) {
      this.setState({ visibleSection: selected });
    }
  };

  handleOpenModeChange = (state) => this.setState({ openable: state });

  handleOperationMethodChange = (state) => this.setState({ operationMethod: state });

  getDimensions = ele => {
    const { height, y } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;
    return { height, offsetTop, offsetBottom, y };
  };

  setListSeparation = rows => {
    let list = [];
    rows.forEach(r => {
      const res = this.checkSeparation(r.scrumItem);
      if (res) {
        list.push(res);
      }
    });
    let firstRow = {};
    let nextRow = {};
    if (rows && rows.length > 0) {
      firstRow = {
        ...rows[0],
        value: `${rows[0].id}${rows[0].scrumItem}`,
      };
      const idxNext = rows.findIndex(r => r.scrumItem === '流動負債計(入力)');
      if (idxNext !== -1) {
        nextRow = {
          ...rows[idxNext],
          value: `${rows[idxNext].id}${rows[idxNext].scrumItem}`,
        };
      }
    }
    this.setState({
      listSeparation: list,
      firstRow,
      nextRow,
    });
  };

  getCellIds = values => {
    return values.map(v => v.id);
  };

  getDetailIds = details => {
    let detailIds = [];
    if (details && details.length > 0) {
      details.forEach(dt => {
        if (dt.item) detailIds.push(dt.item.id);
        if (dt.value) detailIds.push(dt.value.id);
      });
    }
    return detailIds;
  };
  
  handleCellOperation = (row, detail) => {
    const { id, scrumItem, values } = row;
    const {
      selectedBBox,
      onRowSelect,
      onLinkBbox2Cell,
      currentPage,
    } = this.props;
    const pdfImageId = detail && detail.length > 0 && detail[0].pdfImageId;
    let cellIds;
    if (detail) {
      cellIds = this.getDetailIds(detail);
    } else {
      cellIds = this.getCellIds(values);
    }
    if (selectedBBox.id === '' && selectedBBox.text === '') {
      if (pdfImageId) {
        onRowSelect(id, pdfImageId, cellIds, 'value', row.manualInput);
      } else {
        onRowSelect(id, currentPage, cellIds, 'value', row.manualInput);
      }
    } else {
      onLinkBbox2Cell(scrumItem);
      onRowSelect(id, currentPage, cellIds, 'value', row.manualInput);
    }
  };

  handleRowOperation = (row, ids, pdfImageIds) => {
    const { id, scrumItem } = row;
    const {
      selectedBBox,
      onRowSelect,
      onLinkBbox2Cell,
      currentPage,
    } = this.props;
    if (selectedBBox.id === '' && selectedBBox.text === '') {
      if (pdfImageIds && pdfImageIds.length !== 0) {
        let pdfImageId = pdfImageIds.sort((a, b) => { return a - b });
        onRowSelect(id, pdfImageId[0], ids, 'value', row.manualInput);
      } else {
        onRowSelect(id, currentPage, ids, 'value', row.manualInput);
      }
    } else {
      onLinkBbox2Cell(scrumItem);
      // onRowSelect(id, currentPage, ids, 'value');
    }
  };

  handleSetOppositeValue = values => {
    values.forEach(value => {
      value.invert = !value.invert;
    });
    this.props.handleChangeValue(values);
  };

  renderHeader = () => {
    return (
      <Table.Row>
        <Table.HeaderCell>
          {intl.get('_predict.detail.mapping.ConnectionSubject')}
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="right" style={{ width: '150px' }}>
          {intl.get('_predict.detail.mapping.AmountOfMoney')}
        </Table.HeaderCell>
        <Table.HeaderCell
          textAlign="center"
          style={{ width: '40px', paddingLeft: 0, paddingRight: 0 }}
        >
          {/* {intl.get('_predict.detail.mapping.Operation')} */}
        </Table.HeaderCell>
        {/* <Table.HeaderCell style={{ width: '40px' }} /> */}
        {/* <Table.HeaderCell
          textAlign="center"
          style={{ width: '40px', paddingLeft: 0, paddingRight: 0 }}
        >
          {intl.get('_predict.detail.mapping.Detail')}
        </Table.HeaderCell> */}
      </Table.Row>
    );
  };

  handleShowDetail = searchText => {
    this.props.onTabChange(1, searchText);
  };

  handleInputChange(scrumItem, value, calc) {
    this.handleInputChangeDebounced(scrumItem, value,calc);
  }

  manualInputChange(scrumItem, value, calc) {
    this.props.onManualInputTextChange(scrumItem, value, calc);
  }

  // 手動入力:1 , リンクで入力へ:2
  // getManualInputStatus = scrumItem => {
  //   const { detailData, currentPageType } = this.props;
  //   let status = '';
  //   if (detailData.manual_input && detailData.manual_input[currentPageType]) {
  //     const idx = Object.keys(detailData.manual_input[currentPageType]).indexOf(
  //       scrumItem
  //     );
  //     status = idx === -1 ? ManualInputStatus.LINK : ManualInputStatus.INPUT;
  //   } else {
  //     status = ManualInputStatus.LINK;
  //   }
  //
  //   return status;
  // };

  handleCheck = (key, values) => {
    const { allList, tenantPreference } = this.props;
    const ssTitleSplit = tenantPreference['SS_title_split']?tenantPreference['SS_title_split']:'/';
    const isSSTopLeftInvert = tenantPreference['SS_top_left_invert']?Number(tenantPreference['SS_top_left_invert']):0;

    let err = false;
    let tableItem = null;
    let diff = 0;

    const isAggreation = key.indexOf(SCRUM_ITEM_SUFFIX.AGGREGATION) !== -1;
    const isInput = key.indexOf(SCRUM_ITEM_SUFFIX.INPUT) !== -1;

    allList.forEach(item => {
      const splitIndex = item.name.indexOf(ssTitleSplit);
      const rowName = (splitIndex < 0 ? item.name.split(ssTitleSplit)[0] : isSSTopLeftInvert ? item.name.substring(splitIndex + 1).replace(/\(入力\)/gi, SCRUM_ITEM_SUFFIX.AGGREGATION) : item.name.substring(0, splitIndex).replace(/\(入力\)/gi, SCRUM_ITEM_SUFFIX.AGGREGATION));
      const columnName = splitIndex < 0 ? '' : (isSSTopLeftInvert ? item.name.substring(0, splitIndex) : item.name.substring(splitIndex + 1));
      const nameAgg = item.name + SCRUM_ITEM_SUFFIX.AGGREGATION === key || (isSSTopLeftInvert? (columnName + ssTitleSplit + rowName):(rowName + ssTitleSplit + columnName)) === key;
      if (item.name === key || nameAgg) {
        if (item.isError === true) {
          err = true;
          diff = new Intl.NumberFormat('ja-JP', { style: 'decimal', currency: 'CAD', }).format(item.calcVal - item.rawVal);
          diff = item.calcVal - item.rawVal > 0 ? '+' + diff : diff;
        }
        tableItem = item;
      }
    });

    let isManual = false;
    if (values && values.length > 0 && !err) {
      if (values[0].text === '0' || values[0].text === '0') {
        isManual = true;
      }
    }

    return { err, tableItem: tableItem, isAggreation, isManual, isInput, diff };
  };

  onPopupOpen = () => {
    Object.values(this.popups).forEach( popup => {
      popup && popup.state.show && popup.handleShow(false);
    });
  };

  checkSeparation = scrumItem => {
    const { metadata, currentPageType } = this.props;
    const separationList = metadata.separation;
    const formula = metadata.fs_formula;
    let res = null;
    const idx = scrumItem.indexOf(SCRUM_ITEM_SUFFIX.AGGREGATION);
    if (idx !== -1) {
      const aggString = scrumItem.slice(0, idx);
      res =
        separationList &&
        separationList.find(sp => {
          return sp.subCategory === currentPageType && sp.item === aggString;
        });
    } else {
      const isAgg = formula.find(
        f => f.subCategory === currentPageType && f.item === scrumItem
      );
      if (!isAgg) {
        res =
          separationList &&
          separationList.find(sp => {
            return sp.subCategory === currentPageType && sp.item === scrumItem;
          });
      }
    }
    return res;
  };

  renderSeparation = scrumItem => {
    const { metadata } = this.props;
    if (!metadata || !metadata.separation) {
      return null;
    }
    let spDom;
    const res = this.checkSeparation(scrumItem);
    if (res) {
      spDom = (
        <Ref innerRef={this.tableItems[res.value]}>
          <Table.Row id={`separation-${res.id}`}>
            <Table.Cell
              verticalAlign="middle"
              colSpan="5"
              style={{ fontSize: 'medium', background: '#cccccc' }}
            >
              {res.value}
            </Table.Cell>
          </Table.Row>
        </Ref>
      );
    }
    return spDom;
  };

  renderAggreationStatus(err, isManual, diff) {
    const errStatus = (
      <Table.Cell verticalAlign="middle" className="table-err-status">
        <Popup
          className="board-popup-exclamation-triangle"
          trigger={
            <span className="board-item-wrap-bottom-icon close-btn-icon-link board-icon-exclamation-triangle">
              <ExclamationTriangleIcon size='18px' />
            </span>
          }
          offset={[9, -3]}
          content={diff}
          on='hover'
        />
      </Table.Cell>
    );
    const successStatus = (
      <Table.Cell verticalAlign="middle" className="table-success-status td-action">
        <span className="board-item-wrap-bottom-icon close-btn-icon-link board-icon-check-circle" >
          <CheckCircleIcon size='18px' />
        </span>
      </Table.Cell>
    );
    return err ? errStatus : successStatus;
  }

  mappingDetail = searchText => {
    const { detailData, currentPageType } = this.props;
    const isUndefined = detailData && detailData[currentPageType] === undefined;
    const isDetail = !detailData || isUndefined;
    let filteredRows = isDetail ? [] : detailData[currentPageType];
    const filteredRow = filteredRows.filter(v => {return v.scrumItem === searchText});

    return filteredRow;
  };

  onKeyUp(keyName, e) {
    this.setState({ longKeyPressed: false });
    clearTimeout(clickHoldTimer);
  }

  onKeyDown(keyName, e) {
    e.preventDefault();

    clickHoldTimer = setTimeout(() => {
      // Action to be performed after holding down mouse
      this.setState({ longKeyPressed: true });
    }, 100);

    if (keyName !== 'down' && keyName !== 'up') {
      this.handleKeyDownSingleOperation(keyName, e);
    } else {
      setTimeout(() => {
        if (this.state.longKeyPressed) {
          this.handleKeyDownLongPressedOperation(this.state.filterRows, e, keyName);
        } else {
          this.handleKeyDownSingleOperation(keyName, e);
        }
      }, 200)
    }
  }

  handleKeyDownSingleOperation = (keyName, e) => {
    const { rows, selectedCell } = this.props;
    const isMac = navigator.userAgent.indexOf('Mac OS X') !== -1;

    const currentTagName = document.activeElement.tagName.toLowerCase();
    const haveValueIndex = _.findIndex(rows, (r) => {
      return r.id === selectedCell.id;
    })
    const currentRow = rows[haveValueIndex];
    const parentElement = currentRow && document.getElementById(currentRow.scrumItem);
    const thirdChild = parentElement && parentElement.childNodes[2];
    const spansWithTabIndex_third = thirdChild && thirdChild.querySelectorAll("span[tabIndex]");
    const isCancelButton = thirdChild && thirdChild.querySelectorAll(".edit-box-icon-cancel").length > 0;
    this.handleOperationMethodChange(keyName);
    switch (keyName) {
      case 'down':
        this.handleChangeRow(selectedCell, "NEXT", false);
        break;
      case 'ctrl+down':
        if (!isMac) {
          this.handleChangeRow(selectedCell, "NEXT", true);
        }
        break;
      case '⌘+down':
        if (isMac) {
          this.handleChangeRow(selectedCell, "NEXT", true);
        }
        break;
      case 'up':
        this.handleChangeRow(selectedCell, "PREVIOUS", false);
        break;
      case 'ctrl+up':
        if (!isMac) {
          this.handleChangeRow(selectedCell, "PREVIOUS", true);
        }
        break;
      case '⌘+up':
        if (isMac) {
          this.handleChangeRow(selectedCell, "PREVIOUS", true);
        }
        break;
      case 'tab':
        // when activeElement leave to body , force focus to next element.
        if (selectedCell.id !== '') {
          if (currentTagName === "body" || currentTagName === "input") {
            if (spansWithTabIndex_third && spansWithTabIndex_third.length !== 0 && currentTagName === "body") {
              if (spansWithTabIndex_third[0].dataset.tabType === 'skip' || isCancelButton) {
                this.handleChangeRow(selectedCell, "NEXT", false);
              } else {
                spansWithTabIndex_third[0].focus();
              }
            }
            // when tab to next line
          } else if (currentTagName === "span") {
            if (currentRow) {
              this.handleChangeRow(selectedCell, "NEXT", false);
            }
          }
        }
        break;
      case 'shift+tab':
        // when activeElement leave to body , force focus to next element.
        if (selectedCell.id !== '') {
          if (currentTagName === "tr") {
            if (spansWithTabIndex_third && spansWithTabIndex_third.length !== 0 && currentTagName === "tr") {
              if (!spansWithTabIndex_third[0].dataset.tabType && !isCancelButton) {
                spansWithTabIndex_third[0].focus();
              }
            }
            // when tab to next line
          } else if (currentTagName === "body") {
            if (currentRow) {
              this.handleChangeRow(selectedCell, "PREVIOUS", false);
            }
          }
        }
        break;
      default:
        // do nothing
    }
  }

  handleKeyDownLongPressedOperation = async (array, e, keyName) => {
    const { selectedCell } = this.props;
    const targetIndex = _.findIndex(array, { id: selectedCell.id });
    this.handleOperationMethodChange(keyName + '_long_press');
    switch (keyName) {
      case 'down':
        const currentArray_down = _.slice(array, targetIndex);
        this.cycleColors(e, currentArray_down);
        break;
      case 'up':
        const currentArray_up = _.reverse(_.slice(array, 0, targetIndex + 1));
        this.cycleColors(e, currentArray_up);
        break;
      default:
      // do nothing
    }
  }

  cycleColors = async (e, currentArray) => {
    for (const element of currentArray) {
      e.preventDefault();
      if (!this.state.longKeyPressed) {
        break;
      }
      if (!_.includes(element.scrumItem, '(自動集計)')) {
        await this.changeRowColor(element, e, currentArray.indexOf(element) === currentArray.length - 1);
      }
    }
  }

  changeRowColor = async (element, e, isLastElement) => {
    e.preventDefault();
    const domElement = document.getElementById(element.scrumItem);
    const tableDivElement = document.getElementById("fs-key-value-table");
    return new Promise(resolve => {
      if (element) {
        setTimeout(() => {
          domElement.classList.add("high-light-row", "selected");
          if (domElement.getBoundingClientRect().y > window.innerHeight
            || tableDivElement.getBoundingClientRect().y > domElement.getBoundingClientRect().y) {
            document.getElementById(element.scrumItem).scrollIntoView();
          }
        }, 100)
        setTimeout(() => {
          domElement.classList.remove("high-light-row", "selected");
          if (!this.state.longKeyPressed || isLastElement) {
            this.handleChangeRow(element, "CURRENT", false);
          }
          resolve();
        }, 200)
      } else {
        resolve();
      }
    })
  }

  renderRow = () => {
    const {
      selectedCell,
      currentBoxIdForCss,
      detailData,
      currentPageType,
      // rows,
      selectedBBox,
      onGetSum,
      onEdit,
      onInputModeChange,
      isEditing,
      isCancel,
      inputCalc,
      handleUnLinkScrumItem,
      handleSearchReferItem,
      effectiveHotkeyPage,
      pdfInfo,
      handleChangeValue,
      onUpdateBBox,
      detailItemChanging,
      isNotOwner,
      onShowExclusionControl,
    } = this.props;

    const { openable, operationMethod } = this.state; 
    const hotKeyDom = effectiveHotkeyPage === "textmapping" ? (
      <Hotkeys
        keyName="up, down, ctrl+up, ctrl+down, tab, shift+tab"
        onKeyDown={this.onKeyDown.bind(this)}
        onKeyUp={this.onKeyUp.bind(this)}
      />): null;

    const filterRows = this.handleFilterItems();
      
    const rowDom = (
      filterRows.map((r, idx) => {
        const checkResult = this.handleCheck(r.scrumItem, r.values);
        const { err, isAggreation, isManual, isInput, diff } = checkResult;
        const normalInput = idx > 0 && filterRows[idx - 1].scrumItem.indexOf(SCRUM_ITEM_SUFFIX.AGGREGATION) !== -1;
        const isNotDetail = !detailData && detailData[currentPageType] === undefined;
        const details = isNotDetail ? [] : detailData[currentPageType];
        let detailIds = [];
        let pdfImageIds = [];
        const isDisabled = r.values.length <= 0;

        if (r.values && r.values.length > 0) {
          r.values.forEach(item => {
            if (item.id) {
              const detail = (details) ? details.filter(i => i.value.id === item.id) : [];
              if (detail && detail.length > 0) {
                if (detail[0].item) {
                  detailIds.push(detail[0].item.id);
                }
                if (detail[0].value) {
                  detailIds.push(detail[0].value.id);
                }
                if (detail[0].pdfImageId && !pdfImageIds.includes(detail[0].pdfImageId)) {
                  pdfImageIds.push(detail[0].pdfImageId);
                }
              }
            }
          });
        }

        return (
          <ValueTableItem
            ref={this.ValueTableItemRef}
            key={idx}
            renderSeparation={this.renderSeparation}
            row={r}
            tableItems
            getCellIds={this.getCellIds}
            err={err}
            isManual={isManual}
            isInput={isInput || normalInput}
            diff={diff}
            selectedCell={selectedCell}
            currentBoxIdForCss={currentBoxIdForCss}
            isDisabled={isDisabled}
            checkResult={checkResult}
            detailIds={detailIds}
            isAggreation={isAggreation}
            onGetSum={onGetSum}
            detailData={detailData}
            currentPageType={currentPageType}
            isLink={selectedBBox && selectedBBox.id !== ''}
            onPopupOpen={this.onPopupOpen}
            popups={this.popups}
            handleRowOperation={this.handleRowOperation}
            handleInputChange={this.handleInputChange}
            renderAggreationStatus={this.renderAggreationStatus}
            onEdit={onEdit}
            onInputModeChange={onInputModeChange}
            handleSetOppositeValue={this.handleSetOppositeValue}
            handleShowDetail={this.handleShowDetail}
            isTableEditing={isEditing}
            openable={openable}
            isCancel={isCancel}
            inputCalc={inputCalc}
            handleSearchReferItem={handleSearchReferItem}
            tabIndex={selectedCell.id !== '' ? 1 : -1}
            operationMethod={operationMethod}
            onOpenModeChange={this.handleOpenModeChange}
            onOperationMethodChange={this.handleOperationMethodChange}
            pdfImageIds={pdfImageIds}
            macOsKeyOperation={this.macOsKeyOperation}
            mappingDetail={this.mappingDetail}
            handleUnLinkScrumItem={handleUnLinkScrumItem}
            handleCellOperation={this.handleCellOperation}
            pdfInfo={pdfInfo}
            handleChangeValue={handleChangeValue}
            onUpdateBBox={onUpdateBBox}
            detailItemChanging={detailItemChanging}
            isNotOwner={isNotOwner}
            onShowExclusionControl={onShowExclusionControl}
          />
        );
      })
    )
    return (
      <React.Fragment>
        {hotKeyDom}
        {rowDom}
      </React.Fragment>
    )
  };

  formatSS = (ssMetadatas) => {

    const { tenantPreference } = this.props;
    const ssTitleSplit = tenantPreference['SS_title_split']?tenantPreference['SS_title_split']:'/';
    const isSSTopLeftInvert = tenantPreference['SS_top_left_invert']?Number(tenantPreference['SS_top_left_invert']):0;
    const keepSplit = tenantPreference['SS_keep_split'] ? JSON.parse(tenantPreference['SS_keep_split']) : {};
    let list = [];
    let prevRightElement = "";
    let nextLeftElement = "";
    let newElementList = [];
    ssMetadatas.forEach((d) => {
      const leftElement = isSSTopLeftInvert ?
        d.value.substring(0, d.value.indexOf(ssTitleSplit)) :
        d.value.substring(d.value.indexOf(ssTitleSplit) + 1);
      const rightElement = isSSTopLeftInvert ?
        d.value.substring(d.value.indexOf(ssTitleSplit) + 1) :
        d.value.substring(0, d.value.indexOf(ssTitleSplit));
      if (list.indexOf(leftElement) === -1) {
        if (list.length === 0) {
          list.push(leftElement);
        } else {
          if (prevRightElement !== '' && prevRightElement !== rightElement) {
            list.push(...newElementList);
            list.splice(0, 0, leftElement);
            newElementList = []; // clear array
          } else {
            newElementList.push(leftElement);
          }
          prevRightElement = rightElement;
        }
      } else {
        nextLeftElement = leftElement;
        if (newElementList.length > 0){
          if(list.length === 1){
            list.splice(1, 0, ...newElementList);
          } else if(prevRightElement !== rightElement){
            list.push(...newElementList);
          } else {
            list.splice(list.indexOf(nextLeftElement) - newElementList.length + 1, 0, ...newElementList);
          }
          newElementList = []; // clear array
          prevRightElement = "";
        }
      }
    })
    newElementList.length > 0 && list.push(...newElementList); // when newElementList have data , push to the end of list

    const rowHeader = ssMetadatas.map(d=>
      isSSTopLeftInvert ? d.value.substring(d.value.indexOf(ssTitleSplit)+1): d.value.substring(0,d.value.indexOf(ssTitleSplit))
    )
    const rows = Array.from(new Set(rowHeader))

    const { metadata } = this.props
    const ss_formula = metadata.fs_formula.filter(d=> d.subCategory === PageType['SS'])
    const left_aggre = []
    const right_aggre = []
    // calc aggre column or row
    ss_formula.forEach(row=>{
      const splitIndex = row.item.indexOf(ssTitleSplit)
      const leftHeader = isSSTopLeftInvert ? row.item.substring(splitIndex+1) : row.item.substring(0,splitIndex)
      const rightHeader = isSSTopLeftInvert ? row.item.substring(0,splitIndex) : row.item.substring(splitIndex+1)
      // name not exist in fs_formula value is the aggre name.
      const itemList = row.value.split('+')
      for (let i = 0; i < itemList.length; i++) {
        // column like "#{資本金/当期純利益}" => "資本金/当期純利益"
        const columnName = itemList[i].substring(2,itemList[i].length-1)
        const columnSplitIndex = columnName.indexOf(ssTitleSplit)
        const leftColumn = isSSTopLeftInvert ? columnName.substring(columnSplitIndex+1) : columnName.substring(0,columnSplitIndex)
        const rightColumn = isSSTopLeftInvert ? columnName.substring(0,columnSplitIndex) : columnName.substring(columnSplitIndex+1)
        if(leftHeader === leftColumn){
          // leftHeader is normal item, rightHeader is aggre item
          if(!right_aggre.includes(rightHeader)){
            right_aggre.push(rightHeader)
          }
          break;
        }else if(rightHeader === rightColumn){
          // rightHeader is normal item, leftHeader is aggre item
          if(!left_aggre.includes(leftHeader)){
            left_aggre.push(leftHeader)
          }
          break;
        }
      }
    })

    const ssEquityHeader = []
    rows.forEach(d=>{
      ssEquityHeader.push(d)
      if (left_aggre.indexOf(d)>-1){
        const aggre = d.substring(0,d.indexOf('('))+SCRUM_ITEM_SUFFIX.AGGREGATION
        ssEquityHeader.push(aggre)
      }
    })
    const ssLeftHeader = []
    list.forEach(d=>{
      if(right_aggre.indexOf(d)>-1){
        const aggre = d+SCRUM_ITEM_SUFFIX.AGGREGATION
        ssLeftHeader.push(aggre)
      }
      ssLeftHeader.push(d)
    })

    let ssEquityHeaderAddChar = ssEquityHeader;
    let ssLeftHeaderAddChar = ssLeftHeader;
    if (!_.isEmpty(keepSplit)) {
      const { keepOn, stayOn } = keepSplit;
      if (keepOn === 'topHeader') {
        ssEquityHeaderAddChar = ssEquityHeader.map((a) => stayOn === 'right' ? a + ssTitleSplit : ssTitleSplit + a);
      }

      if (keepOn === 'leftHeader') {
        ssLeftHeaderAddChar = ssLeftHeader.map((a) => stayOn === 'right' ? a + ssTitleSplit : ssTitleSplit + a)
      }
    }

    const row = this.converRow(ssLeftHeaderAddChar, ssEquityHeaderAddChar, right_aggre, left_aggre)

    return {
      'Header': ssEquityHeaderAddChar,
      'data': row,
      'leftAgg': left_aggre,
      'topAgg': right_aggre
    }

  }

  converRow = (ssLeftHeader, ssEquityHeader,leftAgg, rightAgg) =>{
    return ssLeftHeader.map(d=>{
      if( d.indexOf(SCRUM_ITEM_SUFFIX.AGGREGATION)>0){
        return this.convertItem(d,ssEquityHeader,rightAgg,true)
      }else if(leftAgg.indexOf(d) > -1 ){
        return this.convertItem(d,ssEquityHeader,rightAgg,false,true)
      }else{
        return this.convertItem(d,ssEquityHeader,rightAgg)
      }
    })
  }
  convertItem = (item, ssEquityHeader, rightAgg, isAggre=false, isAggreItem=false) =>{
    const obj = {}
    obj[SubjectName] = item
    ssEquityHeader.forEach(d=>{
      if( d.indexOf(SCRUM_ITEM_SUFFIX.AGGREGATION)>0 || isAggre){
        if(d.indexOf(SCRUM_ITEM_SUFFIX.AGGREGATION)>0 && isAggre){
          obj[d] = {id:'', manualInput: undefined, values:[], disabled: 1}
        }else{
          obj[d] = {id:'', manualInput: undefined, values:[], isAggreation: 1}
        }
      }else if(rightAgg.indexOf(d) > -1 || isAggreItem){
        obj[d] = {id:'', manualInput: undefined, values:[], isInput: 1}
      }else{
        obj[d] = {id:'', manualInput: undefined, values:[]}
      }
    })
    return obj
  }

  render() {
    const {
      currentPageType,
      onGetSum,
      selectedCell,
      currentBoxIdForCss,
      detailData,
      onTabChange,
      handleChangeValue,
      onEdit,
      onInputModeChange,
      selectedBBox,
      tenantPreference,
      rows,
      tabData,
      isShowSearchFlg,
      inputCalc,
      ssCommon,
      // mappingType,
      // onMappingTypeChange,
      toggleSearch,
      // onListViewChange,
      // handleSSTypeChange,
      errorList,
      handleUnLinkScrumItem,
      pdfInfo,
      onUpdateBBox,
      detailItemChanging,
      mappingType,
      isNotOwner,
      onShowExclusionControl,
    } = this.props;
    const { filterItems } = this.state;
    const currentTab = tabData && tabData.length > 0 && tabData.filter(t => {
      return t.item === currentPageType
    });

    if (currentTab && currentPageType) {
      this.tableItems = [...currentTab].reduce(
        (acc, value) => {
          let node = document.getElementById(`${value.value}`);
          acc[value.value] = {current: node};
          return acc;
        },
        {}
      );
    }

    const handleClick = list => {
      setTimeout(() => {
        this.tableItems[list.value] &&
          this.tableItems[list.value].current &&
          scrollToElementByPosition(this.tableItems[list.value].current, 'start');
      }, 1);
      this.setState({ visibleSection: list.value })
      this.context.sendPredictBeautifyDetailMetrics({
        event_type: EventType.Click,
        event_name: EventName.ClickScrumTableHeader,
        custom_parameter: {
          tab_name: list.subCategory,
          view_mode: ViewMode.List
        },
      })
    };

    const ss_2_dimension = tenantPreference["template_SS"] === 'SMFL' && currentPageType === PageType.SS;
    const ss_common = tenantPreference["template_SS"] === "COMMON_TABLE" && currentPageType === PageType.SS && ssCommon;
    
    const ssTitleSplit = tenantPreference['SS_title_split']?tenantPreference['SS_title_split']:'/';
    const isSSTopLeftInvert = tenantPreference['SS_top_left_invert']?Number(tenantPreference['SS_top_left_invert']):0;
    const keepSplit = tenantPreference['SS_keep_split'] ? JSON.parse(tenantPreference['SS_keep_split']) : {};

    let ssMetadatas = []
    if(this.props.metadatas){
        ssMetadatas = this.props.metadatas.filter(d=> d.item === PageType['SS'])
    }

    const errorArea = document.querySelectorAll(".fs-detail-discrepancy");
    const successArea = document.querySelectorAll(".fs-detail-agreement");
    const errorAreaHeigth =
      successArea.length > 0 ? successArea[0].getBoundingClientRect().height :
        errorArea.length > 0 ? errorArea[0].getBoundingClientRect().height : 0;

    // the vertical column grid do not show when seted tenant of value is 'true'
    // const isShowVerticalColGrid = tenantPreference['review_page_rich_ui_version'];
    const menuContent = () => (
      <div
        style={{
          height: ss_2_dimension ? `calc(100% - ${errorAreaHeigth -12}px)` : ss_common ? `calc(100% - ${errorAreaHeigth - 12}px)` : 'calc(100% - 45px)',
        }}
        id="fs-key-value-table"
        className={
          ss_2_dimension
            ? 'fs-key-value-table-ss-2-dimension'
            : 'fs-key-value-table'
        }
        ref={this.TableRef}
        onScroll={() =>
          this.props.onSetScrollTop(
            'fsKeyValueTableScrollTop',
            this.TableRef.current.scrollTop
          )
        }
      >
        {ss_common ?
        (
          <ShareholdersEquityCommonTable
            ref={this.TableEquityRef}
            selectedBBox={selectedBBox}
            rows={rows}
            isLink={selectedBBox.id !== ''}
            onGetSum={onGetSum}
            handleCellOperation={this.handleCellOperation}
            handleCheck={this.handleCheck}
            selectedCell={selectedCell}
            currentBoxIdForCss={currentBoxIdForCss}
            getCellIds={this.getCellIds}
            detailData={detailData}
            currentPageType={currentPageType}
            onTabChange={onTabChange}
            handleChangeValue={handleChangeValue}
            onEdit={onEdit}
            onInputModeChange={onInputModeChange}
            onInputChange={this.handleInputChange}
            handleSetOppositeValue={this.handleSetOppositeValue}
            handleUnLinkScrumItem={handleUnLinkScrumItem}
            inputCalc={inputCalc}
            ssData={this.formatSS(ssMetadatas)}
            ssTitleSplit={ssTitleSplit}
            isSSTopLeftInvert={isSSTopLeftInvert}
            keepSplit={keepSplit}
            mappingDetail={this.mappingDetail}
            pdfInfo={pdfInfo}
            onOpenModeChange={this.handleOpenModeChange}
            openable={this.state.openable}
            onUpdateBBox={onUpdateBBox}
            detailItemChanging={detailItemChanging}
            onSetFilterItems={this.onSetFilterItems}
            isNotOwner={isNotOwner}
            onShowExclusionControl={onShowExclusionControl}
          />
        )
         : ss_2_dimension ? (
          <ShareholdersEquityTable
            ref={this.TableEquityRef}
            selectedBBox={selectedBBox}
            rows={rows}
            isLink={selectedBBox.id !== ''}
            onGetSum={onGetSum}
            handleCellOperation={this.handleCellOperation}
            handleCheck={this.handleCheck}
            selectedCell={selectedCell}
            currentBoxIdForCss={currentBoxIdForCss}
            getCellIds={this.getCellIds}
            detailData={detailData}
            currentPageType={currentPageType}
            onTabChange={onTabChange}
            handleChangeValue={handleChangeValue}
            onEdit={onEdit}
            onInputModeChange={onInputModeChange}
            onInputChange={this.handleInputChange}
            handleSetOppositeValue={this.handleSetOppositeValue}
            handleUnLinkScrumItem={handleUnLinkScrumItem}
            inputCalc={inputCalc}
            mappingDetail={this.mappingDetail}
            pdfInfo={pdfInfo}
            onOpenModeChange={this.handleOpenModeChange}
            openable={this.state.openable}
            onUpdateBBox={onUpdateBBox}
            detailItemChanging={detailItemChanging}
            onSetFilterItems={this.onSetFilterItems}
            isNotOwner={isNotOwner}
            onShowExclusionControl={onShowExclusionControl}
          />
        ) : (
          <Table className="value-table-wrapper" unstackable>
            <Table.Header className="value-table-header">
              {this.renderHeader()}
            </Table.Header>
            <Table.Body style={{ height: '100%' }}>
              {this.renderRow()}
            </Table.Body>
          </Table>
        )}
      </div>
    );

    const errorContainer = document.getElementById("errorContainer");
    const rightContainer = document.getElementById("rightContainer");
    const containerHeight = errorContainer ? errorContainer.clientHeight - 10 + 'px' : rightContainer ? rightContainer.clientHeight - 10 + 'px' : '0px';

    const createStyleMarginRight = () => {
      if(this.state.hasVerticalScrollbar){
        if(!ss_common && !ss_2_dimension){
          return "0"
        }
        return "-8px"
      }
      return "0"
    }

    const createStylePaddingRight = () => {
      if(this.state.hasVerticalScrollbar){
        if(!ss_common && !ss_2_dimension){
          return "0"
        }
        return "8px"
      }
      return "0"
    }

    return (
      <div
        style={{ position: 'relative', height: '100%', background: '#F5F5F5', padding: '10px 0 0 10px',
          paddingRight: createStylePaddingRight(), 
          paddingBottom: this.state.hasHorizontalScrollbar? "8px" : "0",
         }}
      >
        {/* {!ss_common && !ss_2_dimension ? */}
        <div style={{ marginRight : createStyleMarginRight()}}>
          <CurrentPageErrorList
            errorList={errorList}
            selectedCell={selectedCell}
            rows={rows}
            currentPageType={currentPageType}
            filterItems={filterItems}
            reload={this.handleReload}
            onSetFilterItems={this.onSetFilterItems}
            mappingType={mappingType}
            ssCommon={ssCommon}
          /> 
          </div>
          {/* : null} */}
        {ss_common || ss_2_dimension ? (
          menuContent()
        ) : (
          <div className="menu-scroll-table" style={{ height: `calc(100% - ${containerHeight}` }}>
            <div className="menu-scroll-header-menu">
              <Menu pointing secondary className="menu-scroll-header-menu-group">
                {/* {firstRow && (
                  <Menu.Item
                    active={
                      `${firstRow.id}${firstRow.scrumItem}` === visibleSection
                    }
                    onClick={() =>
                      handleClick(`${firstRow.id}${firstRow.scrumItem}`)
                    }
                  >
                    {listSeparation.length > 0 ? '損益計算' : '資産合計'}
                  </Menu.Item>
                )}
                {nextRow && listSeparation.length <= 0 && (
                  <Menu.Item
                    active={
                      `${nextRow.id}${nextRow.scrumItem}` === visibleSection
                    }
                    onClick={() =>
                      handleClick(`${nextRow.id}${nextRow.scrumItem}`)
                    }
                  >
                    {'負債純資産合計'}
                  </Menu.Item>
                )} */}
                {/* {listSeparation &&
                  listSeparation.map((list, idx) => (
                    <Menu.Item
                      active={list.value === visibleSection}
                      onClick={() => handleClick(list.value)}
                      key={idx}
                    >
                      {list.value}
                    </Menu.Item>
                  ))} */}
                {tabData &&
                  tabData.map((list, idx) => (
                    list.item === currentPageType &&
                      <Menu.Item
                        disabled={filterItems && filterItems.length > 0}
                        onClick={() => handleClick(list)}
                        key={idx}
                      >
                        {list.subCategory}
                      </Menu.Item>
                  ))}
                  <AccountTitleDictionary
                    tenantPreference={tenantPreference}
                    currentPageType={currentPageType}
                    isShowSearchFlg={isShowSearchFlg}
                    toggleSearch={toggleSearch}
                    ssCommon={ssCommon}
                    filterItems={filterItems}
                  />
              </Menu>
            </div>
            {menuContent()}
          </div>
        )}
      </div>
    );
  }
}
