import React, { Component } from 'react';
import { MDBDataTable } from 'mdbreact';
import moment from "moment";
import { Segment, Header, Button, Message, Loader, Input } from 'semantic-ui-react';
import { confirmAlert } from 'react-confirm-alert';
import { toUnixMS, FMT } from '../TimeUtil';
import api from '../../../api';
import MaintainSearch from './search'
import intl from 'react-intl-universal';
import MaintainEdit from './edit';

export default class Maintain extends Component {

  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      init: true,
      isLoading: false,
      isShowEdit: false,
      editTenant: {},
      editFlag: true,
      searchParam: {
        name: '',
        mail: '',
        address: '',
        phone: '',
        startDate: '',
        endDate: '',
      }
    }

    this.onSearch = this.onSearch.bind(this)
    this.refetch = this.refetch.bind(this)
    this.handleDel = this.handleDel.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
    this.toggleEdit = this.toggleEdit.bind(this)

    this.confirmButtonRef = React.createRef();
  }

  async onSearch(data) {
    const { name,
      mail,
      address,
      phone,
      startDate,
      endDate } = data;

    const searchParam = {
      name,
      mail,
      address,
      phone,
      startDate: startDate ? toUnixMS(moment(startDate).format(FMT.DATE)) : '',
      endDate: endDate ? toUnixMS(moment(endDate).add(1, 'd').format(FMT.DATE)) : '',
    };
    this.setState({
      isLoading: true,
      searchParam
    })
    await this.refetch(searchParam);
  }

  async refetch(searchParam) {
    searchParam = searchParam || this.state.searchParam;

    const tenants = (await api.get(
        `/dppapi/system/tenant`+
        `?name=${searchParam.name}`+
        `&mail=${searchParam.mail}`+
        `&address=${searchParam.address}`+
        `&phone=${searchParam.phone}`+
        `&startDate=${searchParam.startDate}`+
        `&endDate=${searchParam.endDate}`
      )).data;
    this.setState({
      rows: tenants.map((tenant, index)=> {
        return {
          actions: (
            <div>
               <Button 
                icon="pencil" 
                label={intl.get('_system.tenants.maintain.Index.Edit')}
                size="tiny" 
                onClick={() => this.handleEdit(tenant)}/>
              <Button
                icon="trash"
                label={intl.get('_system.tenants.maintain.Index.Delete')}
                size="tiny"
                onClick={() => this.handleDel(tenant.id)}
              />
            </div>
          ),
          ...tenant
        }
      }).sort((t1, t2) => {
        return t1.id - t2.id
      }),
      init: false,
    })
    this.setState({
      isLoading: false
    })
  }

  handleDel(id) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='project-edit-popup'>
            <div><span className='pageType-change-remind-title'>{intl.get('_system.tenants.maintain.Index.Do you want to completely delete the tenant data')}</span></div>
            <div className='pageType-change-remind-body project-edit-display'>
              {
                intl.get('_system.tenants.maintain.Index.Do you want to delete this tenant?')
              }
              <Input
                placeholder='完全に削除'
                style={{ marginTop: '16px' }}
                onChange={
                  (e) => {
                    const confirmButtonRef = this.confirmButtonRef.current;
                    if (e.target.value === "完全に削除") {
                      confirmButtonRef.disabled = false;
                      if (confirmButtonRef.className.includes("ui")) {
                        confirmButtonRef.classList.remove('ui');
                        confirmButtonRef.classList.remove('button');
                        confirmButtonRef.className = confirmButtonRef.className.replace(/\bprimary-btn-disabled\b/g, 'primary-btn');
                        confirmButtonRef.onclick = async () => {
                          this.setState({ isLoading: true });
                          onClose();
                          const result = await api.delete(`/dppapi/system/tenant/${id}`);
                          this.refetch();
                          if (result.success) {
                            this.setState({ isLoading: false });
                          }
                        }
                      }
                    } else {
                      confirmButtonRef.disabled = true;
                      if (!confirmButtonRef.className.includes("ui")) {
                        confirmButtonRef.className += ' ui button';
                        confirmButtonRef.className = confirmButtonRef.className.replace(/\bprimary-btn\b/g, 'primary-btn-disabled');
                      }
                    }
                  }
                } />
            </div>
            <div className='custom-ui-confirm-alert-button-group' style={{ width: '100%' }}>
              <button
                ref={this.confirmButtonRef}
                disabled
                className={'ui button primary-btn-disabled confirmAlert-button-right'}
              >
                {intl.get('_system.tenants.maintain.Index.Confirm')}
              </button>
              <button
                className='predict-admin-botton-text confirmAlert-button-left'
                onClick={onClose}>
                {intl.get('_system.tenants.maintain.Index.Cancel')}
              </button>
            </div>
          </div>
        );
      }
    });
  }

  handleEdit(tenant) {
    this.setState({
      isShowEdit: true,
      editTenant: tenant,
      editFlag: true
    })
  }

  handleAdd() {
    this.setState({
      editFlag: false,
    })
    this.toggleEdit(true)
  }

  toggleEdit(isShowEdit) {
    this.setState({
      isShowEdit: isShowEdit,
      editTenant: {},
    })
  }

  handleTimeChange = (date, timeType) => {
    const formatDate = date ? moment(date).format(FMT.DATE) : ""
    this.setState({
      editTenant: {...this.state.editTenant, [timeType]: formatDate}
    })
  }

  render() {
    const columns = [
      {
        label: intl.get('_system.tenants.maintain.Index.ID'),
        field: 'id',
        sort: 'asc'
      },
      {
        label: intl.get('_system.tenants.maintain.Index.TenantsName'),
        field: 'name',
        sort: 'asc'
      },
      {
        label: intl.get('_system.tenants.maintain.Index.TenantsMail'),
        field: 'mail',
        sort: 'asc',
      },
      // {
      //   label: intl.get('_system.tenants.maintain.Index.Address'),
      //   field: 'address',
      //   sort: 'asc',
      // },
      // {
      //   label: intl.get('_system.tenants.maintain.Index.Phone'),
      //   field: 'phoneNum',
      //   sort: 'asc',
      // },
      {
        label: intl.get('_system.tenants.maintain.Index.Count'),
        field: 'useCount',
        sort: 'asc',
      },
      {
        label: intl.get('_system.tenants.maintain.Index.Actions'),
        field: 'actions',
        sort: 'asc',
      }
    ];

    const { rows, isLoading, init } = this.state;
    const data = {
      columns: columns,
      rows: rows,
    };
    var result = null;
    if (isLoading) {
      result = (<Segment><Loader active inline="centered" /></Segment>)
    } else if (rows.length > 0) {
      result = (<Segment>
        <MDBDataTable
          theadColor="common-search-table-header"
          btn
          striped
          bordered
          hover
          data={data}
        />
      </Segment>)
    } else {
      result = (<Message
        info
        header='No Data'
        content="please change conditions"
      />)
    }
    return (
      <React.Fragment>
        <div style={{ display: 'flex' }}>
          <div className="ui" style={{ flex: 1 }}>
            <div id="search-pdf" style={{ padding: '2em 0' }}>
              <Header disabled>{intl.get('_system.tenants.maintain.Index.Filtering')}</Header>
              <MaintainSearch 
              onSearch={this.onSearch}
              handleAdd={this.handleAdd}
              />
            </div>
            <div id="page" style={{ paddingBottom: 200, flex: 1 }}>
              {!init ? result : null}
            </div>
            <MaintainEdit 
              isShowEdit={this.state.isShowEdit}
              tenant={this.state.editTenant}
              editFlag={this.state.editFlag}
              toggleEdit={this.toggleEdit}
              onTimeChange={this.handleTimeChange}
              refetch={this.refetch}/>
          </div>
        </div>
      </React.Fragment>
    );
  }
}