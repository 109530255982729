import React, { Component } from 'react';
import { MDBDataTable } from 'mdbreact';
import moment from "moment";
import { Segment, Header, Button, Message, Loader } from 'semantic-ui-react';
import { confirmAlert } from 'react-confirm-alert';
import { toUnixMS, FMT, unixMSToDatetime } from '../TimeUtil';
import { getStateFlag } from '../Const';
import api from '../../../api';
import AuditsSearch from './search';
import AuditsEdit from './edit';
import intl from 'react-intl-universal';

export default class Audits extends Component {

  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      init: true,
      isLoading: false,
      isShowEdit: false,
      editAudits: {},
      searchParam: null
    }

    this.onSearch = this.onSearch.bind(this)
    this.handleDel = this.handleDel.bind(this)
    this.refetch = this.refetch.bind(this)
    this.toggleEdit = this.toggleEdit.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
  }

  async onSearch(data) {
    const { name,
      startDate,
      endDate,
      status } = data;
    const searchParam = {
      name,
      startDate: startDate ? toUnixMS(moment(startDate).format(FMT.DATE)) : '',
      endDate: endDate ? toUnixMS(moment(endDate).add(1, 'd').format(FMT.DATE)): '',
      status
    };
    this.setState({
      isLoading: true,
      searchParam
    })
    await this.refetch(searchParam);
  }

  async refetch(searchParam) {
    searchParam = searchParam || this.state.searchParam;
    const audits = (await api.get(
      `/dppapi/system/audits`+
      `?name=${searchParam.name}`+
      `&startDate=${searchParam.startDate}`+
      `&endDate=${searchParam.endDate}`+
      `&status=${searchParam.status}`
    )).data;
    this.setState({
      rows: audits.map((data, index)=> {
        return {
          ...data,
          name: data.form.tenants_name,
          status: getStateFlag(data.status),
          time: unixMSToDatetime(data.lastModifiedTime, FMT.DATETIME_LOCAL),
          personInCharge: data.auditByName,
          actions: (
            <div>
              <Button 
                icon="pencil" 
                label={intl.get('_system.tenants.audits.Index.Edit')}
                size="tiny" 
                onClick={() => this.handleEdit(data)}/>
              {/* <Button
                icon="trash"
                label="delete"
                size="tiny"
                onClick={() => this.handleDel(data.id)}
              /> */}
            </div>
          )
        }
      }).sort((t1, t2) => {
        return t2.lastModifiedTime - t1.lastModifiedTime
      }),
      init: false,
    })
    this.setState({
      isLoading: false
    })
  }

  toggleEdit(isShowEdit) {
    this.setState({
      editAudits: {},
      isShowEdit: isShowEdit
    })
  }

  handleEdit(audits) {
    this.setState({
      isShowEdit: true,
      editAudits: audits
    })
  }

  handleDel(id) {
    confirmAlert({
      title: intl.get('_system.tenants.audits.Index.Delete'),
      message: intl.get('_system.tenants.audits.Index.Do you want to delete this audits?'),
      buttons: [
        {
          label: intl.get('_system.tenants.audits.Index.Confirm'),
          onClick: async () => {
            await api.delete(`/dppapi/system/audits/${id}`)
            this.refetch();
          },
        },
        {
          label: intl.get('_system.tenants.audits.Index.Cancel'),
        },
      ],
    });
  }

  render() {
    const columns = [
      {
        label: intl.get('_system.tenants.audits.Index.ID'),
        field: 'id',
      },
      {
        label: intl.get('_system.tenants.audits.Index.TenantsName'),
        field: 'name',
      },
      {
        label: intl.get('_system.tenants.audits.Index.Status'),
        field: 'status',
      },
      {
        label: intl.get('_system.tenants.audits.Index.Personincharge'),
        field: 'personInCharge',
      },
      {
        label: intl.get('_system.tenants.audits.Index.UpdateTime'),
        field: 'time',
      },
      {
        label: intl.get('_system.tenants.audits.Index.Actions'),
        field: 'actions',
      }
    ];

    const { rows, isLoading, init } = this.state;
    const data = {
      columns: columns,
      rows: rows,
    };
    var result = null;
    if (isLoading) {
      result = (<Segment><Loader active inline="centered" /></Segment>)
    } else if (rows.length > 0) {
      result = (<Segment>
        <MDBDataTable
          theadColor="common-search-table-header"
          btn
          striped
          bordered
          hover
          data={data}
        />
      </Segment>)
    } else {
      result = (<Message
        info
        header='No Data'
        content="please change conditions"
      />)
    }
    return (
      <React.Fragment>
        <div style={{ display: 'flex' }}>
          <div className="ui" style={{ flex: 1 }}>
            <div id="search-pdf" style={{ padding: '2em 0' }}>
              <Header disabled>{intl.get('_system.tenants.audits.Index.Filtering')}</Header>
              <AuditsSearch 
              onSearch={this.onSearch}
              />
            </div>
            <div id="page" style={{ paddingBottom: 200, flex: 1 }}>
              {!init ? result : null}
            </div>
            <AuditsEdit 
              isShowEdit={this.state.isShowEdit} 
              audits={this.state.editAudits} 
              toggleEdit={this.toggleEdit} 
              refetch={this.refetch}/>
          </div>
        </div>
      </React.Fragment>
    );
  }
}