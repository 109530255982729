export function imageToB64str(imgUrl, callback) {
    const img = new Image();
    img.onload = async function () {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/png");
      callback(dataURL);
    }
    img.src = imgUrl;
};