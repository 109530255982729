import React, { Component } from 'react';
import { Divider } from 'semantic-ui-react'
import './KeyValueBar.css'

export default class KeyValueBar extends Component {
  constructor(props) {
    super(props)
    this.state = { dataMap: {}, selectedId: null };
    this.keyValueBarRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    const figures = nextProps.figures;
    let dataMap = {};
    figures.forEach(f => {
      if (f.linkTo) {
        dataMap[f.id] = { keyId: f.id, keyText: f.det_text, values: [] };
        f.linkTo.forEach(l => {
          const valueFigure = figures.find(v => v.id === l)
          if (dataMap[f.id].keyId && valueFigure) {
            dataMap[f.id].values.push({ valueId: valueFigure.id, valueText: valueFigure.det_text });
          }
        })
      }
    })
    return { dataMap, selectedId: nextProps.selectedKeyValueItemId }
  }

  componentDidMount() {
    this.props.onGetKeyValueBarRef(this.keyValueBarRef.current)
  }

  handleOnSelect = id => {
    const target = this.state.dataMap[id];
    this.props.onSelectKeyValueItem(target);
  }
  getLabelDom = type => {
    return <span className="key-value-label">
      {type === 'key' ? 'key' : 'value'}
    </span>
  }

  getListDom = ({ keyId, keyText, values }, idx) => {
    let classes = '';
    if (this.state.selectedId === keyId) {
      classes = 'key-value-item-selected'
    }
    return (
      <React.Fragment key={keyId}>
        {idx !== 0 ? <Divider className='key-value-divider' /> : null}
        <div key={keyId} className={`key-value-item ${classes}`} onClick={() => this.handleOnSelect(keyId)}>
          <div className='key-value-item-k'>{this.getLabelDom('key')}{keyText}</div>
          {values.map(v => <div className='key-value-item-v' key={v.valueId}>{this.getLabelDom('value')}{v.valueText}</div>)}
        </div>
      </React.Fragment>

    );
  }

  render() {
    const { dataMap } = this.state;
    return (<div className="key-value-bar" ref={this.keyValueBarRef}>
      {Object.keys(dataMap).reverse().map((k, idx) => this.getListDom(dataMap[k], idx))}
    </div>);
  }
}