import React, { Component } from 'react';
import { Context } from '../../detail/pdf/Context';
import intl from 'react-intl-universal';
import { Icon, Popup } from 'semantic-ui-react';
import { maxRangeZoom, minRangeZoom } from '../../detail/pdf/CalcBoundsHOC';
import './MapHeader.css';
import PropTypes from 'prop-types';
import { AnalyticsServiceContext } from '../../../common/Analytics/AnalyticsServiceProvider';
import { EventName, EventType } from '../../../common/Analytics/analyticsMetrics';
class MapHeader extends Component {
  static contextType = AnalyticsServiceContext;

  render() {
    const {
      mapRef,
      height,
      width,
      zoom,
      zoomDefault,
      pdfInfo,
      currentPage
    } = this.props;
   
    const imageInfo = pdfInfo.images.find(item => item.id === currentPage);
    const currentZoom = zoomDefault ? zoomDefault : zoom;
    
    return (
      <Context.Consumer>
        {({ map }) => (
          <div className="map-header-wrapper">
            <div className="image-info-wrapper">
              {imageInfo.image_order}<span>|</span>{imageInfo.pageClassification}
            </div>
            
            <div className="action-button-map">
              <div
                id="ga-predict-detail-image-reset"
                className="map-header-icon-btn map-header-icon-text"
                onClick={() => {
                  this.context.sendPredictBeautifyDetailMetrics({
                    event_type: EventType.Action,
                    event_name: EventName.ResetZoom,
                  })
                  const map = mapRef.current.leafletElement;
                  map.setView([height / 2, width / 2], -1);
                  localStorage.removeItem('zoom' + this.props.pdfId);
                }}
              >
                <Icon
                  name="expand arrows alternate"
                  fitted
                  style={{ fontSize: '1.2em', marginTop: '15px' }}
                />
                {intl.get('_predict.detail.pdf.Canvas.Zoom reset')}
              </div>
              <div className="map-header-icon-btn map-header-zoom">
                <div id="ga-predict-detail-image-zoom" className="slider-range-input">
                  <Popup
                    trigger={
                      <input
                        type="range"
                        style={{ height: "100%", cursor: "pointer" }}
                        min={minRangeZoom}
                        max={maxRangeZoom}
                        step="any"
                        value={currentZoom}
                        onChange={e => {
                          map = mapRef.current.leafletElement;
                          map.setZoom(e.target.value)
                        }
                        }
                        onMouseUp={e => {
                          this.context.sendPredictBeautifyDetailMetrics({
                            event_type: EventType.Action,
                            event_name: EventName.SetRangeBySlider,
                            custom_parameter: {
                              zoom: `${Math.round((currentZoom + 2) * 100)}%`
                            },
                          })
                        }}
                      />
                    }
                    inverted
                    content={`${Math.round((currentZoom + 2) * 100)}%`}
                    position="right center"
                    style={{
                      borderRadius: 0,
                      opacity: 0.7,
                    }}
                  />
                </div>
                <div className="slider-range-label">
                  <span>縮小</span>
                  <span>拡大</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </Context.Consumer>
    );
  }
}

export default MapHeader;

MapHeader.propTypes = {
  thumbnailRef: PropTypes.object,
  textMappingRef: PropTypes.object,
  mapRef: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  clickFlag: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  zoom: PropTypes.number,
  handleChangeTitleLoading: PropTypes.func,
  addPdfToImageDialog: PropTypes.func,
  rotateDialog: PropTypes.func,
};

MapHeader.defaultProps = {
  handleChangeTitleLoading: () => { },
  addPdfToImageDialog: () => { },
  rotateDialog: () => { },
};
