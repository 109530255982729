import React, { Component } from 'react';
import FsPdfThumbnail from './thumbnail/FsPdfThumbnail';
import FsPdfTextMapping from './mapping/FsPdfTextMapping';
import PdfLoader from './pdf/PdfLoader';
import shallowEqualObjects from 'shallow-equal/objects';
import HotKeyPanel from './HotKeyPanel';
import '../PredictApp.css';
export default class PdfPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: null,
      selectedCell: { id: "", pdfImageId: "", cellIds: [] },
      selectedBBox: { id: "", text: "" },
      linking: false,
      minPdfImageId: 0,
      shortKeyPanelVisible: false,
      currentBoxId: "",
      currentBoxIdForCss: "",
      isEditing: false,
      showThumbnail: true,
      leftWidth: "",
    }
    this.TextMappingRef = React.createRef()
    this.ThumbnailRef = React.createRef()
    this.PopupRef = React.createRef();

  }

  handleEdit = (status) => {
    this.setState({ isEditing: status })
  }

  handlePageChange = page => {
    if (page !== this.state.currentPage) {
      this.setState({ currentPage: page });
    }
  }
  handleRowSelect = (id, pdfImageId, cellIds, column) => {
    const { selectedCell } = this.state
    const newSelectedCell = { id, pdfImageId, cellIds, column }
    const empty = { id: "", pdfImageId: "", cellIds: [] }
    if ((shallowEqualObjects(selectedCell.cellIds, newSelectedCell.cellIds)
      && column === selectedCell.column && id === selectedCell.id)
      || shallowEqualObjects(empty.cellIds, newSelectedCell.cellIds)) {
      this.setState({ selectedCell: empty, linking: false })
    } else {
      const newSelectdBox = { ...selectedCell, ...newSelectedCell }
      this.setState({ selectedCell: newSelectdBox, linking: false, currentPage: pdfImageId })
    }
  }
  handleLinkTo = () => {
    this.setState({ linking: !this.state.linking })
    this.PopupRef && this.PopupRef.current && this.PopupRef.current.leafletElement.options.leaflet.map.closePopup()
  }
  getMinPdfImageId = (id) => {
    this.setState({ minPdfImageId: id })
  }
  handleShortKeyPanelVisible = () => {
    this.setState(prevState => {
      return { shortKeyPanelVisible: !prevState.shortKeyPanelVisible }
    })
  }
  //once ckick bbox, tabel cell css changed
  handleSelectBox = id => {
    const currentBoxIdForCss = this.state.currentBoxIdForCss === id ? "" : id
    this.setState({ currentBoxId: id, currentBoxIdForCss })
  }

  // this func is for link bbox to table cell
  handleSelectedBBoxChange = (id, text) => {
    this.setState({ selectedBBox: { id, text } })
  }
  
  onMouseDown = (e) => {
    var leftChild = document.getElementById('leftChild');
    var rightChild = document.getElementById('rightChild');
    var iEvent = e;
    var dx = iEvent.clientX;     
    var leftWidth = leftChild.offsetWidth;
    var rightWidth = rightChild.offsetWidth;
    document.onmousemove = (e) => {
      var iEvent = e;
      var diff = iEvent.clientX - dx;
      if ((leftWidth + diff) > 0 && (rightWidth - diff) > 350) {
        leftChild.style.width = (leftWidth + diff) / (leftWidth + rightWidth) * 100 + '%';
        rightChild.style.width = `calc(`+(rightWidth - diff) / (leftWidth + rightWidth) * 100 + `% - 5px)`;
      }
    };
    document.onmouseup = () => {
      this.setState({leftWidth:Date.now()});

      document.onmousemove = null;
      document.onmouseup = null;
    };
    return false;
  }

  handleShowThumbnail = () => {
    this.setState(prevState => ({ showThumbnail: !prevState.showThumbnail }))
  }

  render() {
    const {
      currentPage,
      selectedCell,
      linking,
      textType,
      minPdfImageId,
      shortKeyPanelVisible,
      currentBoxId,
      currentBoxIdForCss,
      isSelecting,
      selectedBBox,
      isEditing,
      showThumbnail,
      leftWidth,
    } = this.state;

    const { projectId, pdfFileId } = this.props.match.params
    return (
      <HotKeyPanel shortKeyPanelVisible={shortKeyPanelVisible} >
        <div className="old-pdf-portal-root">
          <FsPdfThumbnail
            onPageChange={this.handlePageChange}
            currentPage={currentPage}
            projectId={projectId}
            pdfFileId={pdfFileId}
            getMinPdfImageId={this.getMinPdfImageId}
            textMappingRef={this.TextMappingRef}
            ref={this.ThumbnailRef}
            showThumbnail={showThumbnail}
            onShowThumbnail={this.handleShowThumbnail}
          />
          <div id='father' className="old-pdf-portal-father">
            <div id='leftChild' className="old-pdf-portal-left-child">
              <PdfLoader
                currentPage={currentPage}
                selectedCell={selectedCell}
                linking={linking}
                textMappingRef={this.TextMappingRef}
                projectId={projectId}
                pdfFileId={pdfFileId}
                onShortKeyPanelVisible={this.handleShortKeyPanelVisible}
                onSelectBox={this.handleSelectBox}
                popupRef={this.PopupRef}
                selectedBBox={selectedBBox}
                onSelectedBBoxChange={this.handleSelectedBBoxChange}
                leftWidth={leftWidth}
                isEditing={isEditing}
                showThumbnail={showThumbnail}
                onShowThumbnail={this.handleShowThumbnail}
              />
            </div>
            <div id="line" className="old-pdf-portal-line"  onMouseDown={this.onMouseDown} />
            <div id="rightChild" className="old-pdf-portal-right-child">
              <FsPdfTextMapping
                onPageChange={this.handlePageChange}
                thumbnailRef={this.ThumbnailRef}
                onRowSelect={this.handleRowSelect}
                selectedCell={selectedCell}
                linking={linking}
                onLinkTo={this.handleLinkTo}
                textType={textType}
                ref={this.TextMappingRef}
                currentPage={currentPage}
                projectId={projectId}
                pdfFileId={pdfFileId}
                minPdfImageId={minPdfImageId}
                currentBoxId={currentBoxId}
                currentBoxIdForCss={currentBoxIdForCss}
                isSelecting={isSelecting}
                selectedBBox={selectedBBox}
                onSelectedBBoxChange={this.handleSelectedBBoxChange}
                onEdit={this.handleEdit}
                isEditing={isEditing}
              />
            </div>
          </div>
        </div>
      </HotKeyPanel >
    );
  }
}
