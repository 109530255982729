import React from 'react';

import { MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import {
  Button,
  Form,
  List,
  Radio
} from 'semantic-ui-react';
import intl from 'react-intl-universal';
import { unixMSToDatetime, FMT } from '../../predict/TimeUtil';

export default class TimeLine extends React.Component {
  constructor(props) {
    super(props);

    this.handleRollback = this.handleRollback.bind(this);
    this.getList = this.getList.bind(this);

    this.state = {
      selectedSnapshot: null,
      snapshots: []
    };
  }

  async handleRollback(onLabelChange) {
    const { selectedSnapshot } = this.state;
    // const { projectId } = this.props;
    // await api.delete(`/dppapi/labeling/${projectId}/images/${imageId}`);      
    // this.refetch();

    console.log(selectedSnapshot)
    console.log(this.state.snapshots)

    const annotation2Rollback = this.state.snapshots.filter(x => x.id === selectedSnapshot);
    if (annotation2Rollback.length > 0) {
      await onLabelChange(JSON.parse(annotation2Rollback[0].annotation));
    }

    window.location.reload();
  }

  componentDidMount() {
    const { snapshots } = this.props;

    snapshots.then((res) => {
      let snapshots = res;
      this.setState({ snapshots });
    });
  }

  getList() {
    const { snapshots } = this.state;
    const items = snapshots.map(option => (
      <List.Item key={option.id}>
        <Radio 
          label={unixMSToDatetime(option.time, FMT.DATETIME_LOCAL)} 
          value={option.id} 
          name='snapshotsGroup' 
          checked={ this.state.selectedSnapshot === option.id}
          onChange={(e, { value }) => this.setState({ selectedSnapshot: value })} />
      </List.Item>
    ));
    return <List>{items}</List>;
  }

  render() {
    const { isShowEdit, onLabelChange } = this.props;

    return (
      <MDBModal
        isOpen={isShowEdit}
        centered
        toggle={(flag) => {
          this.props.toggleEdit({ isShowEdit: flag });
        }}
      >
        <MDBModalHeader>Timeline</MDBModalHeader>
        <MDBModalBody>
          <Form>
            { this.getList() }
            <Button
              primary
              floated="right"
              onClick={() => {
                this.handleRollback(onLabelChange);
              }}
              disabled={this.state.selectedSnapshot === null}
            >
              Rollback to Snapshot
            </Button>
            <Button
              primary
              floated="right"
              onClick={() => {
                this.props.toggleEdit({ isShowEdit: false });
              }}
            >
              {intl.get('_label.description.index.Cancel')}
            </Button>
          </Form> 
        </MDBModalBody>
      </MDBModal>
    );
  }
}
