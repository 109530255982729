import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ReactLogo } from './svg/arrow_drop_down.svg';
import './icon.css';

export class ArrowDropdownIcon extends Component {

  render() {
    const { color, className, disabled, size, onClick } = this.props;
    const clsDisable = disabled ? ' svg-icon-disable' : '';

    return (
      <ReactLogo
        className={`svg-icon-wrapper ${className}${clsDisable}`}
        style={{ fill: color, width: size, height: size }}
        onClick={disabled ? () => {} : onClick}
      />
    );
  }
}

ArrowDropdownIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

ArrowDropdownIcon.defaultProps = {
  color: '#000000',
  className: '',
  size: '20px',
  disabled: false,
  onClick: () => {},
};
