import React, { Component } from 'react';

import {
  Header,
  Divider,
  Form,
  Message,
  Segment,
  Button,
  Loader,
} from 'semantic-ui-react';
import update from 'immutability-helper';
import api from '../../api'
import intl from 'react-intl-universal';
import { getCsrfToken } from '../../common/CookieUtil';

export default class UploadReference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadError: null,
      project: null,
      isLoaded: false,
    };

    this.handleFileSubmit = this.handleFileSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.load = this.load.bind(this);
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    const { projectId } = this.props;
    const project = (await api.get(`/dppapi/labeling/${projectId}/project`)).data;
    this.setState({
      isLoaded: true,
      project,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { project } = this.state;
    if (!project) return;
    const { projectId } = this.props;

    if (prevState.project !== project) {
      api.patch(`/dppapi/labeling/${projectId}/project`, { project: project });
    }
  }

  async handleFileSubmit(e) {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);

    const resp = await api.post(
      `/dppapi/labeling/${this.props.projectId}/uploads/reference`,
      formData
    );
    const res = await resp.data;
    if (!res.success) {
      this.setState({
        uploadError: res.message,
      });
      return;
    }

    this.setState({
      uploadError: null,
    });
    form.reset();
    this.load();
  }

  onChange({ referenceLink, referenceText }) {
    const { project } = this.state;
    this.setState({
      project: update(project, {
        referenceText: {
          $set: referenceText,
        },
        referenceLink: {
          $set: referenceLink,
        },
      }),
    });
  }

  render() {
    const { uploadError, project, isLoaded } = this.state;

    if (!isLoaded) {
      return <Loader active inline="centered" />;
    }

    const { referenceText, referenceLink } = project;

    const uploadErrorMessage = uploadError ? (
      <Message negative>{uploadError}</Message>
    ) : null;

    let preview = null;
    if (referenceText || referenceLink) {
      const img = referenceLink ? (
        <div style={{ position: 'relative' }}>
          <img
            alt="Reference"
            style={{ width: 'auto', maxWidth: '100%' }}
            src={`/dppapi${referenceLink}?token=${getCsrfToken()}`}
          />
          <Button
            icon="trash"
            size="tiny"
            label="Delete"
            style={{ position: 'absolute', top: 0, right: -125 }}
            onClick={() => this.onChange({ referenceText, referenceLink: null })}
          />
        </div>
      ) : null;
      preview = (
        <div>
          <Header>Preview</Header>
          <p>
            This is what the reference image and text would look like on the top
            of the labeling page:
          </p>
          <Segment>
            {img}
            {referenceText}
          </Segment>
          <Divider />
        </div>
      );
    }

    return (
      <Segment>
        {preview}
        <Form
          method="post"
          encType="multipart/form-data"
          onSubmit={this.handleFileSubmit}
        >
          <Form.Input
            label={intl.get('_label.reference.UploadReference.Uploadimagefromdisk')}
            type="file"
            id="referenceImage"
            name="referenceImage"
            accept=".jpg, .jpeg, .png"
            action="Upload"
          />
        </Form>
        {uploadErrorMessage}
        <Form style={{ marginTop: '1em' }}>
          <Form.TextArea
            autoHeight
            label="Reference Text"
            placeholder="Instructions, descriptions, etc"
            defaultValue={referenceText}
            onChange={(e, { value }) =>
              this.onChange({ referenceText: value, referenceLink })
            }
          />
        </Form>
        <Divider />
        <p>
          The reference information (image and/or text) will be shown on top of
          the labeling interface to give the labelers more instruction on how to
          label or something to compare to (image of the key colors, reference
          images, etc).
        </p>
      </Segment>
    );
  }
}
