import React from 'react'
import { Form, Header, Input, Button } from 'semantic-ui-react'
import api from '../api'
import { ROOT_URI } from '../HistoryListener';
import intl from 'react-intl-universal';
import { ErrorIcon} from '../assets/icon';
import Hotkeys from 'react-hot-keys';
export default class TwoStageAuthentication extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      verificationCode: null,
      mfaId: null,
      success: true,
      msg: "",
      error: "",
      reload: false,
    }
  }

  async componentDidMount() {
    this.setState({reload: true});
    const passTwoStageAuthentication = localStorage.getItem("passTwoStageAuthentication")

    const info = (await api.get(`/dppapi/info`)).data;
    if (!info || passTwoStageAuthentication === null) {
      this.props.history.push('/login')
    }
  }

  handleCodeChange = (code) => {
    this.setState({ verificationCode: code })
  }

  handleCodeConfirm = async () => {
    const { success, url, permission, locked, lastTimes } = (await api.post('/dppapi/two-stage-authentication/confirm', { code: this.state.verificationCode })).data;

    if (success) {
      localStorage.setItem("url", url)
      localStorage.setItem("permission", permission);
      localStorage.setItem("passTwoStageAuthentication", true)

      const redirectTo = (new URL(window.location.href)).searchParams.get('redirectTo')
      let redirectUrl = localStorage.getItem("url")
      if (redirectTo && redirectTo !== '/dppui/login') {
        redirectUrl = redirectTo
      }
      this.setState({ loading: false });
      this.props.history.push(redirectUrl.replace(ROOT_URI, ''));
    } else {
      if (locked) {
        setTimeout(function () { 
          localStorage.clear()
          window.location = ROOT_URI
        }, 3000)
        this.setState({ msg: intl.get('_login.TwoStageAuthentication.Account is locked'), success: false, error: null })
      } else { 
        this.setState({ error: intl.get('_login.TwoStageAuthentication.Code is incorrect').replace('X',lastTimes), success: false, msg: null })
      }
    }
  }

  sendVerifyMail = async () => {
    this.setState({ msg: "" })
    const { success } = (await api.post('/dppapi/two-stage-authentication/send')).data;
    if (success) { 
      this.setState({ msg: intl.get('_login.TwoStageAuthentication.Code has been resend'), success, error: null })
    }
  }

  clearErrors = () =>  {
    this.setState({error: ""});
  }

  onKeyDown(keyName, e) {
    if(keyName === 'tab'){
      e.preventDefault();
      const currentTagName = document.activeElement.tagName.toLowerCase();
      const currentElementName = document.activeElement.getAttribute("name");
      const parentElement = document.getElementsByClassName("login-form-confirm");
      let inputElement = parentElement && parentElement[0].querySelectorAll("input");
      let buttonElement = parentElement && parentElement[0].querySelectorAll("button");
      let loginElement = document.getElementsByClassName("footer-form-text");
      if(currentTagName === "body"){
        inputElement[0].focus()
      }else if(currentTagName === "input"){
        buttonElement[0].focus()
      }else if(currentTagName === "button"){
        loginElement[0].focus()
      }else if(currentTagName === "p"){
        if(currentElementName === "sendAgain"){
          loginElement[1].focus()
        }else if(currentElementName === "goLogin"){
          inputElement[0].focus()
        }
      }
    }
  }

  render() {
    const { success, msg, error } = this.state
    if(document.getElementsByClassName('login-container-confirm').length > 0){
      document.getElementsByClassName('login-container-confirm')[0].parentNode.classList.remove('container')
    }
    var errorClass = error ? "input-error" : ""
    return (
      <div className="login-container-confirm">
        <Hotkeys
          keyName="tab"
          onKeyDown={this.onKeyDown.bind(this)}
        />
        <Form className="login-form-confirm">
          <Header className="confirm-from-header">
            {intl.get('_login.TwoStageAuthentication.InfoHeader')}
          </Header>
          <p className="confirm-from-text">
            {intl.getHTML('_login.TwoStageAuthentication.InfoBody')}
          </p>
          <div className="confirm-form-input-div">
            <label className="confirm-form-input-label">{intl.getHTML('_login.TwoStageAuthentication.Authentication code')}</label>
            <Form.Field style={{ width: '100%', display: 'inline-block', margin: 'unset' }}>
              <Input
                type="text"
                name="verificationCode"
                focus
                placeholder={intl.get('_login.TwoStageAuthentication.VerificationCode')}
                fluid 
                onFocus={this.clearErrors}
                onChange={(e, data) => this.handleCodeChange(data.value)}
                className={`${errorClass}`}
              />
            </Form.Field>
            <p className="confirm-form-single-error">{error ? error:null}</p>
          </div>
          <div>
            <div className="confirm-form-button">
              <Button fluid
                className="login-btn primary-btn confirm-form-button-margin"
                onClick={() => this.handleCodeConfirm()}>{intl.get('_login.TwoStageAuthentication.Continue')}</Button>
            </div>
            {msg &&(success ? 
            <div className="confirm_icon_div">
              <label htmlFor='success' className="reset-password-error-lable">
                  <span className='two-Stage-send-again'>{msg}</span>
              </label>
            </div>:
            <div className="confirm_icon_div">
              <ErrorIcon size="24px" color="#F26868" className="repredict-icon-cursor"/>
              <label htmlFor='error' className="reset-password-error-lable">
                  <span className='error-text'>{msg}</span>
              </label>
            </div>)}
          </div>
          <div className="login-footer-form-link">
            <p className="footer-form-text" tabIndex={0} name="sendAgain"
              onKeyDown={(e) => {
                if(e.keyCode === 13){
                  this.sendVerifyMail()
                }}}
              onClick={() => this.sendVerifyMail()}>
              {intl.get('_login.TwoStageAuthentication.Resend')}
            </p>
            <p className="footer-form-text" tabIndex={0} name="goLogin"
              onKeyDown={(e) => {
                if(e.keyCode === 13){
                  window.location = `/dppui/login`
                }}}
              onClick={() => { window.location = `/dppui/login` }}>
              {intl.get('_login.TwoStageAuthentication.Go to login')}
            </p>
          </div>
        </Form>
      </div>
  )}
}