import React from 'react'

import {
  Button,
  Form,
  Message,
  Segment,
  Header
} from 'semantic-ui-react';
import intl from 'react-intl-universal';
import { confirmAlert } from 'react-confirm-alert';
import {
  withRouter
} from 'react-router-dom';
import api from '../../api';
class ResetKeyForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      form: {
        accessKey: Math.random().toString().slice(-6),
      },
      errors: {},
      msgs: {}
    }

    this.handleInput = this.handleInput.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.clearErrors = this.clearErrors.bind(this)
    this.clearMsgs = this.clearMsgs.bind(this)
    this.checkForm = this.checkForm.bind(this)
  }

  async handleSave() {
    const form = this.state.form

    if(!this.checkForm(form)) {
      return
    }

    confirmAlert({
      title: intl.get('_user.user.User.Reset access key'),
      message: intl.get('_user.user.User.Are you sure to do this?'),
      buttons: [
        {
          label: intl.get('_common.Confirm'),
          onClick: async () => {
            const r = await api.put(`/dppapi/access-key/user/reset`, 
              JSON.stringify({
                accessKey: form.accessKey,
              }),
              {headers: {
                'Content-Type': 'application/json',
              }},
              
            )
        
            if (r.status !== 200) {
              const { msg } = await r.data
              this.setState({errors: {
                accessKey: {
                 content: msg
                }
               }})
              return
            }
            
            const { url } = await r.data
        
            confirmAlert({
              title: intl.get('_user.user.User.Reset success'),
              message: (<div>Your new access key is <span style={{fontWeight: 'bold'}}>{form.accessKey}</span>.</div>),
              buttons: [
                {
                  label: intl.get('_common.Confirm'),
                  onClick: async () => {
                    localStorage.setItem("url", url)
                    this.props.history.push(url);
                  },
                }
              ],
            })
          },
        },
        {
          label: intl.get('_user.user.User.Cancel'),
        },
      ],
    })
  }

  checkForm(form) {
    const errors = {}
    if (!form.accessKey) {
      errors['accessKey'] = {content: 'Please enter the access key.', pointing: 'below', flag: true}
    }
    
    this.setState({
      errors: errors
    })
    return Object.keys(errors).length === 0
  }

  handleFocus() {
    this.clearErrors()
    this.clearMsgs()
  }

  clearErrors() {
    this.setState({
      errors: {}
    })
  }

  clearMsgs() {
    this.setState({
      msgs: {}
    })
  }

  handleInput(event) {
    const {name, value} = event.target
    const form = {...this.state.form}
    form[name] = value
    this.setState({
      form: form
    })
  }

  render() {
    const { errors, msgs } = this.state
    return (<Segment>
      <Header size="large">Reset access key</Header>
      {
        Object.keys(errors).length > 0 &&
        <Message
          error
          list={ Object.keys(errors).map(i => {
            return errors[i].content && errors[i].content
          })}
        />
      }
      {
        Object.keys(msgs).length > 0 &&
        <Message
          positive
          list={ Object.keys(msgs).map(i => {
            return msgs[i].content && msgs[i].content
          })}
        />
      }
      <Form>
        <Form.Field error={errors['accessKey'] && errors['accessKey']['flag']}>
          <label>New access Key</label>
          <Form.Input placeholder="new access key" name="accessKey" value={this.state.form.accessKey} onChange={this.handleInput} onFocus={this.handleFocus}/>
        </Form.Field>
        <Button onClick={this.handleSave}>Update</Button>
      </Form>
    </Segment>)
  }
}


export default withRouter(ResetKeyForm)