import React from 'react'

import { MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact'
import {
  Button,
  Form,
  Message
} from 'semantic-ui-react'
import api from '../../api'
import intl from 'react-intl-universal';

export default class PreferencesEdit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      preferences: {},
      errors: {}
    }

    this.handleInput = this.handleInput.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.checkForm = this.checkForm.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.clearErrors = this.clearErrors.bind(this)
    this.clearPreferences = this.clearPreferences.bind(this)
  }

  handleInput(event) {
    const {name, value} = event.target
    const preferences = {...this.state.preferences}
    preferences[name] = value
    this.setState({
      preferences
    })
  }

  handleFocus(event) {
    const { errors } = this.state
    const {name} = event.target
    delete errors[name]
    this.clearErrors(errors)
  }

  clearErrors(errors) {
    this.setState({
      errors
    })
  }

  clearPreferences() {
    this.setState({
      preferences: {}
    })
  }

  async handleSave() {
    const preferences = Object.assign({}, this.props.preferences, this.state.preferences)

    if(!this.checkForm(preferences)) {
      return
    }
    
    await api.request({
      url: `/dppapi/tenant-setting/preferences${this.props.preferences.id ? '/' + this.props.preferences.id : ''}`,
      method: this.props.preferences.id ? 'PUT' : 'POST',
      data: preferences
    })

    this.props.getPreferences()
    this.props.toggleEdit(false)
    this.clearErrors({})
    this.clearPreferences()
  }

  checkForm(preferences) {
    const errors = {}
    if (!preferences.category) {
      errors['category'] = {content: 'Please enter a preferences category.', pointing: 'below', flag: true}
    }

    // if (!preferences.subCategory) {
    //   errors['subCategory'] = {content: 'Please enter a preferences subCategory.', pointing: 'below', flag: true}
    // }

    if (!preferences.name) {
      errors['name'] = {content: 'Please enter a preferences name.', pointing: 'below', flag: true}
    }

    // if (!preferences.subName) {
    //   errors['subName'] = {content: 'Please enter a preferences subName.', pointing: 'below', flag: true}
    // }

    // if (!preferences.version) {
    //   errors['version'] = {content: 'Please enter a preferences version.', pointing: 'below', flag: true}
    // }

    // if (!preferences.subVersion) {
    //   errors['subVersion'] = {content: 'Please enter a preferences subVersion.', pointing: 'below', flag: true}
    // }

    // if (!preferences.value) {
    //   errors['value'] = {content: 'Please enter a preferences value.', pointing: 'below', flag: true}
    // }

    // if (!preferences.priority) {
    //   errors['priority'] = {content: 'Please enter a preferences priority.', pointing: 'below', flag: true}
    // }
    
    this.setState({
      errors: errors
    })
    return Object.keys(errors).length === 0
  }

  render() {
    const { preferences } = this.props
    const { errors } = this.state

    return (
      <MDBModal isOpen={this.props.isShowEdit} toggle={this.props.toggleEdit}>
        <MDBModalHeader>
          {
            this.props.preferences && this.props.preferences.id 
            ? intl.get('_setting.tenantSetting.TenantSetting.Edit TenantSetting') 
            : intl.get('_setting.tenantSetting.TenantSetting.Add TenantSetting')
          }
        </MDBModalHeader>
        <MDBModalBody>
        {
          Object.keys(errors).length > 0 &&
          <Message
            error
            list={ Object.keys(errors).map(i => {
              return errors[i].content
            })}
          />
        }
          <Form>
            <Form.Field error={errors['category'] && errors['category']['flag']}>
              <label>{intl.get('_setting.tenantSetting.TenantSetting.Category')}</label>
              <Form.Input placeholder="category" defaultValue={preferences.category} name="category" onChange={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            <Form.Field error={errors['subCategory'] && errors['subCategory']['flag']}>
              <label>{intl.get('_setting.tenantSetting.TenantSetting.SubCategory')}</label>
              <Form.Input placeholder="subCategory" defaultValue={preferences.subCategory} name="subCategory" onChange={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            <Form.Field error={errors['name'] && errors['name']['flag']}>
              <label>{intl.get('_setting.tenantSetting.TenantSetting.Name')}</label>
              <Form.Input placeholder="name" defaultValue={preferences.name} name="name" onChange={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            <Form.Field error={errors['subName'] && errors['subName']['flag']}>
              <label>{intl.get('_setting.tenantSetting.TenantSetting.SubName')}</label>
              <Form.Input placeholder="subName" defaultValue={preferences.subName} name="subName" onChange={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            <Form.Field error={errors['version'] && errors['version']['flag']}>
              <label>{intl.get('_setting.tenantSetting.TenantSetting.Version')}</label>
              <Form.Input placeholder="version" defaultValue={preferences.version} name="version" onChange={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            <Form.Field error={errors['subVersion'] && errors['subVersion']['flag']}>
              <label>{intl.get('_setting.tenantSetting.TenantSetting.SubVersion')}</label>
              <Form.Input placeholder="subVersion" defaultValue={preferences.subVersion} name="subVersion" onChange={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            <Form.Field error={errors['value'] && errors['value']['flag']}>
              <label>{intl.get('_setting.tenantSetting.TenantSetting.Value')}</label>
              <Form.TextArea placeholder="value" defaultValue={preferences.value} name="value" onChange={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            <Form.Field error={errors['priority'] && errors['priority']['flag']}>
              <label>{intl.get('_setting.tenantSetting.TenantSetting.Priority')}</label>
              <Form.Input placeholder="priority" defaultValue={preferences.priority} name="priority" onChange={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            <Button 
              floated='right' 
              onClick={this.handleSave}
              >{intl.get('_common.Save')}</Button>
            <Button 
              floated='right' 
              onClick={() => {
                this.props.toggleEdit(false)
                this.clearErrors({})
                this.clearPreferences()
              }}
              >{intl.get('_common.Cancel')}</Button>
          </Form>
        </MDBModalBody>
      </MDBModal>
    )
  }
}
