import React from 'react'
import { MDBDataTable } from 'mdbreact'
import { Loader, Button, Segment, Checkbox, Icon, Message, Header } from 'semantic-ui-react'
import { confirmAlert } from 'react-confirm-alert';
import shallowEqualObjects from 'shallow-equal/objects';
import api from '../../api'
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import { toUnixMS, FMT } from '../../predict/TimeUtil';
import ImageSearch from './ImageSearch';
import moment from "moment";
import { getCsrfToken } from '../../common/CookieUtil';
import { downloadCsv } from '../../predict/Util'

export default class ImagesConditions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      init: true,
      error: null,
      isLoaded: false,
      rows: [],
      changeId: null,
      searchData: {},
      countImages: 0,
      pagination: null,
    };
    this.handleDel = this.handleDel.bind(this);
    this.handleCheckout = this.handleCheckout.bind(this);
    this.markAllNotLabeled = this.markAllNotLabeled.bind(this);
    this.handleLabeled = this.handleLabeled.bind(this);
    this.imagesToRows = this.imagesToRows.bind(this);
    this.refetch = this.refetch.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { projectId } = this.props;
    if (
      projectId === nextProps.projectId &&
      shallowEqualObjects(this.state, nextState)
    ) {
      return false;
    }
    return true;
  }

  async componentDidMount() {
  }

  onSearch = async data => {
    const { projectId } = this.props;
    const { imageLink, uploadStartDate, uploadEndDate, modifyStartDate, modifyEndDate, manager, reviewer, status, textInLabelData, alivePage, entries } = data;
    const req = {
      projectId,
      imageLink,
      uploadStartDate: (uploadStartDate)? toUnixMS(moment(uploadStartDate).format(FMT.DATE)) : '',
      uploadEndDate:  (uploadEndDate)? toUnixMS(moment(uploadEndDate).add(1, 'd').format(FMT.DATE)) : '',
      modifyStartDate:  (modifyStartDate)? toUnixMS(moment(modifyStartDate).format(FMT.DATE)) : '',
      modifyEndDate:  (modifyEndDate)? toUnixMS(moment(modifyEndDate).add(1, 'd').format(FMT.DATE)) : '',
      manager,
      reviewer,
      status,
      textInLabelData,
      alivePage,
      entries
    };
    try {
      const images = (await api.get(
        `/dppapi/labeling/${req.projectId}?imageLink=${req.imageLink}&manager=${req.manager}&reviewer=${req.reviewer}&status=${req.status}&uploadStartTime=${req.uploadStartDate}&uploadEndTime=${req.uploadEndDate}&modifyStartDate=${req.modifyStartDate}&modifyEndDate=${req.modifyEndDate}&textInLabelData=${req.textInLabelData}&alivePage=${req.alivePage}&entries=${req.entries}`
      )).data;
      let rows = [];
      if (images) {
        rows = images.map((image) => {
          return this.imagesToRows(projectId, image);
        })
      }
      this.setState({
        isLoaded: true,
        rows: rows,
        init: false,
      });
    } catch (error) {
      this.setState({
        isLoaded: true,
        error,
      });
    }
  }

  getPageCount = async(searchData) => {
    const { projectId } = this.props;
    const { imageLink, uploadStartDate, uploadEndDate, modifyStartDate, modifyEndDate, manager, reviewer, status, textInLabelData } = searchData;
    const req = {
      projectId,
      imageLink,
      uploadStartDate: toUnixMS(moment(uploadStartDate).format(FMT.DATE)),
      uploadEndDate: toUnixMS(moment(uploadEndDate).add(1, 'd').format(FMT.DATE)),
      modifyStartDate: toUnixMS(moment(modifyStartDate).format(FMT.DATE)),
      modifyEndDate: toUnixMS(moment(modifyEndDate).add(1, 'd').format(FMT.DATE)),
      manager,
      reviewer,
      status,
      textInLabelData
    };
    try {
      const count = (await api.get(
        `/dppapi/labeling/count/${req.projectId}?imageLink=${req.imageLink}&manager=${req.manager}&reviewer=${req.reviewer}&status=${req.status}&uploadStartTime=${req.uploadStartDate}&uploadEndTime=${req.uploadEndDate}&modifyStartDate=${req.modifyStartDate}&modifyEndDate=${req.modifyEndDate}&textInLabelData=${req.textInLabelData}`
      )).data;
      var countImages = 0;
      if (count) {
        countImages = count.countImages
      }
      searchData.alivePage = 1;
      searchData.entries = 10;
      this.setState({
        searchData: searchData,
        countImages: countImages
      });
      this.generatePagination();
    } catch (error) {
      this.setState({
        isLoaded: true,
        error,
      });
    }
  }

  generatePagination = () => {
    const { countImages, searchData } = this.state;
    const { entries, alivePage } = searchData;
    const pagesCount = Math.ceil(countImages/entries);
    const pagesAmount = 5;
    var pagination = null;
    var pagNum = [];
    if (pagesCount > 0) {
      for (let i = 1; i <= pagesCount; i++) {
        if (i === alivePage) {
          if (pagesCount < pagesAmount) {
            for (let p = 1; p <= pagesCount; p++) {
              pagNum.push(p);
            }
          } else {
            if (i <= 3) {
              pagNum = [1, 2, 3, 4, 5];
            } else if (pagesCount - i < 3) {
              pagNum = [pagesCount - 4, pagesCount - 3, pagesCount - 2, pagesCount - 1, pagesCount];
            } else {
              pagNum = [i - 2, i - 1, i, i + 1, i + 2];
            }
          }
        }
      }
    }
    pagination = this.getShowPages(pagNum, alivePage);
    this.onSearch(searchData);
    this.setState({pagination: pagination});
  }

  getShowPages = (pagNum, alivePage) => {
    return pagNum.map(p => {
      if (p === alivePage) {
        return (<li className="active page-item" key={p} onClick={() => this.changeAlivePage(p)}> 
          <Link className="page-link page-link" to={'#'}>{p}
            <span className="sr-only"></span>
          </Link>
        </li>)
      } else {
        return (<li className="page-item" key={p} onClick={() => this.changeAlivePage(p)}>
          <Link className="page-link page-link" to={'#'}>{p}
          </Link>
        </li>)
      }
    })
  }
  
  changeAlivePage = (alivePage) => {
    let {searchData} = this.state;
    searchData.alivePage = alivePage;
    this.setState({ searchData: searchData });
    this.generatePagination();
  }

  changeEntries = (e) => {
    let {searchData} = this.state;
    searchData.entries = e.target.value;
    searchData.alivePage = 1;
    this.setState({ searchData: searchData })
    this.generatePagination();
  }

  async refetch() {
    let { rows, changeId } = this.state;
    const { projectId } = this.props;
    try {
      const image = (await api.get(`/dppapi/labeling/${projectId}/images/${changeId}`)).data;
      if (image.id) {
        rows = rows.map(row => {
          if (image.id === row.id) {
            return this.imagesToRows(projectId, image);
          } else {
            return row;
          }
        })
      } else {
        rows = rows.filter(row => {
          return changeId !== row.id;
        })
      }
      this.setState({
        isLoaded: true,
        init: false,
        rows: rows,
        changeId: null,
      });
    } catch (error) {
      this.setState({
        isLoaded: true,
        error,
        changeId: null,
      });
    }
  }

  imagesToRows(projectId, image) {
    return Object.assign({}, image, {
      id: image.id,
      link: (
        <div style={{ width: '200px' }}>
          <a 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{ wordBreak: 'break-all' }} 
            href={`/dppapi${image.link}?token=${getCsrfToken()}`}
          >
            {image.originalName}
          </a>
        </div>
      ),
      labeled: (
        <Checkbox
          checked={!!image.labeled}
          label={image.labeled ? "YES" : "NO"}
          onChange={(e, { checked }) => this.handleLabeled(image.id, Number(checked))}
        />
      ),
      labeled_text: image.labeled ? "YES" : "NO",
      labelData: JSON.stringify(image.labelData),
      chargeUser: image.userInChargeName,
      reviewer: image.reviewByName,
      actions: (
        <div>
          <Button
            icon="user"
            label="担当"
            size="tiny"
            onClick={() => this.handleCheckout(image.id, 'manage')}
          />
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={`/label/${projectId}/${image.id}`}
          >
            <Button icon="pencil" label="編集" size="tiny" />
          </Link>
          <Button
            icon="trash"
            label="削除"
            size="tiny"
            onClick={() => this.handleDel(image.id)}
          />
          <Button
            icon="user"
            label="レビュー"
            size="tiny"
            onClick={() => this.handleCheckout(image.id, 'review')}
          />
          <Button
            icon="download"
            label="ダウンロード"
            size="tiny"
            onClick={() => this.handleCsvDownload(image.id)}
          />
        </div>
      )
    })
  }

  async handleLabeled(imageId, labeled) {
    const { projectId } = this.props;
    await api.patch(`/dppapi/labeling/${projectId}/images/${imageId}`, { labeled: !!labeled});
    this.setState({
      changeId: imageId,
    });
    this.refetch();
  }

  async handleCheckout(imageId, opration) {
    const { projectId } = this.props;
    confirmAlert({
      title: opration === 'manage' ? intl.get('_label.image.Images.Changeperson') : intl.get('_label.image.Images.ChangeReviewer'),
      message: opration === 'manage' ? intl.get('_label.image.Images.Areyousure') : intl.get('_label.image.Images.reviewConfirm'),
      buttons: [
        {
          label: intl.get('_label.image.Images.OK'),
          onClick: async () => {
            await api.post(`/dppapi/labeling/${projectId}/images/checkout/${imageId}`, {opration: opration});
            this.setState({
              changeId: imageId,
            });
            this.refetch();
          },
        },
        {
          label: intl.get('_label.image.Images.Cancel'),
        },
      ],
    })
  }

  async handleDel(imageId) {
    const { projectId } = this.props;
    const { searchData } = this.state;
    confirmAlert({
      title: intl.get('_label.image.Images.Delete'),
      message: intl.get('_label.image.Images.Are you sure to do this?'),
      buttons: [
        {
          label: intl.get('_label.image.Images.Delete'),
          onClick: async () => {
            await api.delete(`/dppapi/labeling/${projectId}/images/${imageId}`);
            this.setState({
              changeId: imageId,
            });
            this.getPageCount(searchData);
          },
        },
        {
          label: intl.get('_label.image.Images.Cancel'),
        },
      ],
    })
  }

  async handleCsvDownload(imageId) {
    const rslt = await api.get(`/dppapi/labeling/csv/${imageId}`);
    downloadCsv(
      rslt.data,
      { header: false },
      `labeling_image_${imageId}.csv`
    );
  }

  async markAllNotLabeled() {
    this.setState({ isLoaded: false });
    const { projectId } = this.props;
    await api.post(`/dppapi/labeling/${projectId}/images/all`, {
      labeled: false, 
      projectId: projectId,
    });
    this.onSearch(this.state.searchData);
  }

  render() {
    const  { rows, error, isLoaded, init, searchData, countImages, pagination } = this.state;
    const { projectId } = this.props;
    const columns = [
      {
        label: intl.get('_label.image.Images.ID'),
        field: 'id',
        sort: 'asc',
        width: 50
      },
      {
        label: intl.get('_label.image.Images.ImageLink'),
        field: 'link',
        sort: 'disabled',
        width: 200
      },
      {
        label: intl.get('_label.image.Images.LabelStatus'),
        field: 'labeled',
        sort: 'disabled',
        width: 80
      },
      {
        label: intl.get('_label.image.Images.Person'),
        field: 'chargeUser',
        sort: 'disabled',
        width: 80
      },
      {
        label: intl.get('_label.image.Images.Review'),
        field: 'reviewer',
        sort: 'disabled',
        width: 80
      },
      {
        label: intl.get('_label.image.Images.Actions'),
        field: 'actions',
        sort: 'disabled',
        width: 160
      }
    ]
    const data = {
      columns: columns,
      rows: rows
    }
    const { alivePage, entries } = searchData;
    const pagesCount = Math.ceil(countImages/entries);
    var previousClass = '';
    var nextClass = '';
    if (pagesCount > 0) {
      if (alivePage === 1) {
        if (pagesCount !== 1) {
          previousClass = 'disabled page-item';
          nextClass = 'page-item';
        } else {
          previousClass = 'disabled page-item';
          nextClass = 'disabled page-item';
        }
      } else if (alivePage === pagesCount) {
        previousClass = 'page-item';
        nextClass = 'disabled page-item';
      } else {
        previousClass = 'page-item';
        nextClass = 'page-item';
      }
    }

    if (error) {
      return <div>Error: {error.message}</div>;
    }

    var result = null;
    if (!isLoaded) {
      result = (<Segment><Loader active inline="centered" /></Segment>)
    } else if (rows.length > 0) {
      result = (
      <div className="project-images">
        <Segment>
          <div style={{ marginBottom: '20px' }}>
            Show entries
            <select className="custom-select custom-select-sm" style={{marginLeft: '0.5rem', width: '6%'}} onChange={(e)=>this.changeEntries(e)}  key={entries} defaultValue={entries}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <div style={{ float: 'right'}}>
              
              <Button.Group floated='right'>
                <Button icon onClick={this.load}>
                  <Icon name='refresh' />
                </Button>
              </Button.Group>
            </div>
          </div>
          <MDBDataTable
            theadColor="common-search-table-header"
            btn
            striped
            bordered
            hover
            entries={20}
            data={data}
            paging={false}
            searching={false}
          />
          <div style={{ height: '30px'}}>
            <div className="dataTables_paginate" style={{ float: 'right'}}>
              <ul className="pagination">
                <li className={previousClass} onClick={() => this.changeAlivePage(alivePage - 1)}>
                  <Link className="page-link page-link" to={"#"}>
                    <span>Previous</span>
                  </Link>
                </li>
                {pagination}
                <li className={nextClass}  onClick={() => this.changeAlivePage(alivePage + 1)}>
                  <Link className="page-link page-link" to={"#"}>
                    <span>Next</span>
                  </Link>
                </li>
              </ul> 
            </div>
          </div>
        </Segment>
        {/* <Button.Group floated='right'>
          <Button icon="cancel"
            label="Mark all images as 'Unlabeled'"
            onClick={this.markAllNotLabeled}/>
        </Button.Group> */}
      </div>)
    } else {
      result = (<Message
        info
        header='No Data'
        content="please change conditions"
      />)
    }

    return (
      <React.Fragment>
        <div id="search-pdf" style={{ padding: '2em 0' }}>
          <Header disabled>{intl.get('_predict.admin.PredictAdmin.Filtering')}</Header>
          <ImageSearch 
          getPageCount={this.getPageCount}
          projectId={projectId} 
          />
        </div>
        <div style={{ paddingBottom: 200, flex: 1 }}>
          {!init ? result : null}
        </div>
    </React.Fragment>
    )
  }
}
