import React, { PureComponent } from 'react';
import { Button } from 'semantic-ui-react';
import { Message } from 'semantic-ui-react';

export default class MessageBox extends PureComponent {

  render() {
    const {
      options: { messageHeader, messageList, type, onSave, onCancel, figure },
      hidden,
    } = this.props;
    return (
      <Message
        hidden={hidden}
        success={type === 'success'}
        info={type === 'info'}
        warning={type === 'warning'}
      >
        <Message.Header>{messageHeader}</Message.Header>

        <Message.List items={messageList} />
        <div style={{ display: "flex", margin: "20px 0" }}>
          {onSave ? <Button color='blue' onClick={() => onSave(figure, 'end')}>保存</Button> : ''}
          {onCancel ? <Button color='grey' onClick={() => onCancel(figure, 'cancel')}>キャンセル</Button> : ''}
        </div>
      </Message>
    );
  }
}
