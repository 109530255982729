import React, { Component } from 'react';
import api from '../../api';
import {
  Segment,
  Form,
  Divider,
  Button,
  Icon,
  Loader,
} from 'semantic-ui-react';
import { downloadCsv } from '../../predict/Util';

class ActionBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // fsItemForm: {},
      loading: false,
    };
  }

  async componentDidMount() {}

  handleInput = (event) => {
    const { name, value } = event.target;
    const fsItemForm = {};
    fsItemForm[name] = value;
    this.props.setFsItemForm(fsItemForm);
  };

  handleExport = async () => {
    try {
      this.setState({ loading: true });
      const { data } = await api.get(`/dppapi/system/global-preset-export`);
      const fsItems = data.list;
      if (fsItems && fsItems.length > 0) {
        downloadCsv(
          fsItems.map((item) => [item.id, item.subTitle, item.item, item.category, item.globalResultItem]),
          { header: false },
          `Global_Result_Items_${new Date().getTime()}.csv`
        );
      }
    } catch (e) {
      console.error(e);
    } finally { 
      this.setState({ loading: false });
      this.props.setFsItemForm({subTitle:'',item:'',globalResultItem:''});
    } 
  };

  render() {
    const { loading } = this.state;
    return (
      <Segment>
        {loading ? (
          <Loader active inline="centered"/>
        ) : (
          <Form>
            <Form.Field>
              <Button.Group size="small">
                <Button
                  onClick={() => this.props.toggleEdit(true)}
                  color="black"
                >
                  <Icon name="add" />
                  Add
                </Button>
                <Button
                  onClick={() => this.props.toggleImport(true)}
                  basic={true}
                  color="black"
                >
                  <Icon name="sign-in" />
                  Import
                </Button>
                <Button
                  onClick={() => {
                    this.handleExport();
                  }}
                  basic={true}
                  color="black"
                >
                  <Icon name="sign-out" />
                  Export
                </Button>
              </Button.Group>
            </Form.Field>
            <Divider />
            <Form.Field>
              <label>Sub Title</label>
              <Form.Input
                name="subTitle"
                placeholder="Sub Title"
                onChange={(event) => this.handleInput(event)}
              />
            </Form.Field>
            <Form.Field>
              <label>Item</label>
              <Form.Input
                name="item"
                placeholder="Item"
                onChange={(event) => this.handleInput(event)}
              />
            </Form.Field>
            <Form.Field>
              <label>Category</label>
              <Form.Input
                name="category"
                placeholder="category"
                onChange={(event) => this.handleInput(event)}
              />
            </Form.Field>
            <Form.Field>
              <label>Global Result Item</label>
              <Form.Input
                name="globalResultItem"
                placeholder="Global Result Item"
                onChange={(event) => this.handleInput(event)}
              />
            </Form.Field>
            <Button size="small" onClick={() => this.props.handleSearch()}>
              <Icon name="search" />
              Search
            </Button>
          </Form>
        )}
      </Segment>
    );
  }
}

export default ActionBar;
