import React, { useState } from 'react';
import {
  Segment,
  Form,
  Divider,
  Button,
  Icon,
  Loader,
} from 'semantic-ui-react';
import api from '../../api';
import { downloadCsv } from '../../predict/Util';
import ProjectUserImportModal from './ProjectUserImportModal';
import ProjectUserWholeTable from './ProjectUserWholeTable'

export default function ProjectUserIndex() {
  const [loading, setLoading] = useState(false);
  const [isShowImport, setShowImport] = useState(false);
  const [searchform, setSearchForm] = useState({});
  const [prjUsersRslt, setPrjUsersRslt] = useState([]);
  const [page, setPage] = useState({
    pageNo: 1,
    limit: 10,
    pageCount: 0,
    total: 0,
    offset: 0
  });

  const handleExport = async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`/dppapi/admin/export/project-users`);
      const projectUsers = data.list;
      if (projectUsers && projectUsers.length > 0) {
        downloadCsv(
          projectUsers.map((item) => [
            item.projectId,
            item.userId,
            item.projectName,
            item.userName,
            item.userEmail,
          ]),
          { header: false },
          `Projects_Users_${new Date().getTime()}.csv`
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const toggleImport = (isShow) => {
    setShowImport(isShow);
  };

  const handleInput = (event) => {
    const { name, value } = event.target;
    searchform[name] = value;
    setSearchForm(searchform);
  }

  const handleSearch = async (activePageNo) => {
    const { projectName = '', userName = '', userEmail = '' } = searchform;
    const { pageNo, limit} = page
    const selectPageNo = activePageNo ? activePageNo : pageNo;
    const { data } = await api.get(
      `/dppapi/admin/project-users-by-page?projectName=${projectName}&userName=${userName}&userEmail=${userEmail}&pageNo=${selectPageNo}&limit=${limit}`
    );
    
    if (data.list && data.page) {
      setPage(data.page);
      setPrjUsersRslt(data.list);
    }
  };

  return (
    <React.Fragment>
      <Segment>
        {loading ? (
          <Loader active inline="centered" />
        ) : (
          <Form>
            <Form.Field>
              <Button.Group size="small">
                <Button
                  onClick={() => toggleImport(true)}
                  // basic={true}
                  color="black"
                >
                  <Icon name="sign-in" />
                  Import
                </Button>
                <Button
                  onClick={() => {
                    handleExport();
                  }}
                  basic={true}
                  color="black"
                >
                  <Icon name="sign-out" />
                  Export
                </Button>
              </Button.Group>
            </Form.Field>
            <Divider />
            <Form.Field>
              <label>Project Name</label>
              <Form.Input
                name="projectName"
                placeholder="Project Name"
                onChange={(event) => handleInput(event)}
              />
            </Form.Field>
            <Form.Field>
              <label>User Name</label>
              <Form.Input
                name="userName"
                placeholder="User Name"
                onChange={(event) => handleInput(event)}
              />
            </Form.Field>
            <Form.Field>
              <label>User Email</label>
              <Form.Input
                name="userEmail"
                placeholder="User Email"
                onChange={(event) => handleInput(event)}
              />
            </Form.Field>
            <Button size="small" onClick={() => handleSearch()}>
              <Icon name="search" />
              Search
            </Button>
          </Form>
        )}
      </Segment>
      <ProjectUserImportModal
        isShowImport={isShowImport}
        toggleImport={(isShow) => {
          toggleImport(isShow);
        }}
        handleSearch={() => handleSearch()}
      />
      <ProjectUserWholeTable
        prjUsersRslt={prjUsersRslt}
        page={page}
        handleSearch={handleSearch}
      />
    </React.Fragment>
  );
}
