import React from 'react'

import { MDBDataTable } from 'mdbreact'

import { Button, Icon, Segment, Message, } from 'semantic-ui-react'

import { confirmAlert } from 'react-confirm-alert';

import UsersEdit from './usersEdit'

import api from '../../../api'
import intl from 'react-intl-universal';

import ImportUsers from './importUsers';
import UserSearchForm from './userSeachForm';
import { getCsrfToken } from '../../../common/CookieUtil';
const STATUS_ICON_MAP = {
  0: 'check circle',
  1: 'lock'
}

export default class Users extends React.Component {
  state = {
    isLoaded: true,
    rows: [],
    isShowEdit: false,
    editUser: {},
    roles: [],
    isShowImport: false,
    searchForm: {},
    isShowAdd:false,
    errors: {},
    checkUser:{},
    isError: false
  }

  constructor(props) {
    super(props)
    this.handleDel = this.handleDel.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.toggleEdit = this.toggleEdit.bind(this)
    this.getUsers = this.getUsers.bind(this)
    this.handleResetPassword = this.handleResetPassword.bind(this)
    
    this.handleExport = this.handleExport.bind(this);
    this.handleImport = this.handleImport.bind(this);
    this.toggleImport = this.toggleImport.bind(this);
    this.onSearch = this.onSearch.bind(this)
    this.onChange = this.onChange.bind(this)
    this.checkForms = this.checkForms.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.clearErrors = this.clearErrors.bind(this)
  }

  async componentDidMount() {
  }

  async onSearch(tenantId) {
    const { data: roles } = await api.get(`/dppapi/system/role/${tenantId}`);
    this.setState({
      isLoading: true,
      roles: roles,
      searchForm: {},
    })
  }

  toggleEdit(isShowEdit) {
    this.setState({
      isShowEdit: isShowEdit,
      editUser:{},
      checkUser:{}
    })
  }

  onChange = (value) => {
    this.setState({ tenantId: value });
  };

  async getUsers(condition) {
    if(!condition){this.setForm({ oktaAccount: '', name: '', email: '', roleId: ''})}
    this.setState({isLoaded: false});
    const { oktaAccount = '', name = '', email = '', roleId = '' } = condition ? this.state.searchForm : {};
    const tenantId = this.state.tenantId
    let rows = []
    if(tenantId){
      const { data: users } = await api.get(`/dppapi/system/user?oktaAccount=${oktaAccount}&name=${name}&email=${email}&roleId=${roleId}&tenantId=${tenantId}`)
      const { roles } =this.state;
      users.forEach(use=>{
        if(use.tenantId === tenantId){
          if (users) {
            rows = users.map( (user, index) => {
              return {
                ...user,
                num: index + 1,
                status_icon: (<Icon name={STATUS_ICON_MAP[user.status]} size='large' />),
                role_name: (roles.find(role => {
                  return user.roleId === role.id
                }) || {} )['name'],
                operation: (
                <div>
                  <Button icon="pencil" label={intl.get('_system.tenants.user.User.Edit')} size="tiny" onClick={() => this.handleEdit(user)}/>
                  <Button icon="pencil" label={intl.get('_system.tenants.user.User.Reset password')} size="tiny" onClick={() => this.handleResetPassword(user)}/>
                  <Button
                    icon="trash"
                    label={intl.get('_system.tenants.user.User.Delete')}
                    size="tiny"
                    onClick={() => this.handleDel(user)}
                  />
                </div>
                )
              }
            })
          }
        }
      })
    }else{
      this.checkForms(this.state.checkUser)
    }
    this.setState({
      rows: rows,
    })
    this.setState({isLoaded: true});
  }

  handleFocus() {
    this.clearErrors()
  }

  clearErrors() {
    this.setState({
      errors: {},
      isError: false
    })
  }

  checkForms(user) {
      const errors = {}
      if(!user.name){
        errors['tenantsName'] = {content: 'Please select a tenants name.', pointing: 'below', flag:true}
      }
      this.setState({
        errors: errors,
        isError: true
      })
      return Object.keys(errors).length !== 0
  }
 
  handleEdit(user) {
    if(this.state.tenantId){
      this.setState({
        isShowEdit: true,
        editUser: user,
        checkUser: user
      })
    }else{
      this.checkForms(this.state.checkUser)
    }
  }

  async handleDel(user) {
    confirmAlert({
      title: intl.get('_common.Delete'),
      message: intl.get('_system.tenants.user.User.Are you sure to do this?'),
      buttons: [
        {
          label: intl.get('_common.Delete'),
          onClick: async () => {
            await api.delete(`/dppapi/system/user/${user.id}/${this.state.tenantId}`)
            this.getUsers()
          },
        },
        {
          label: intl.get('_system.tenants.user.User.Cancel'),
        },
      ],
    })
  }

  async handleResetPassword(user) {
    confirmAlert({
      title: intl.get('_system.tenants.user.User.Reset password'),
      message: intl.get('_system.tenants.user.User.Are you sure to do this?'),
      buttons: [
        {
          label: intl.get('_system.tenants.user.User.Reset'),
          onClick: async () => {
            try{
              const rslt = await api.put(`/dppapi/system/password/admin/reset/${user.id}/${this.state.tenantId}`);
              if (rslt.status === 200) {
                confirmAlert({
                  title: '',
                  message: intl.get('_system.tenants.user.User.EmailSuccessed'),
                  buttons: [
                    {
                      label: intl.get('_common.Confirm')
                    }
                  ],
                })
              }
            }catch(e){
              confirmAlert({
                title: '',
                message: e.data.message,
                buttons: [
                  {
                    label: intl.get('_common.Confirm')
                  }
                ],
              })
            }
          },
        },
        {
          label: intl.get('_common.Cancel'),
        },
      ],
    })
  }

  handleImport(checkUser) {
    if(this.state.tenantId){
      this.toggleImport({
        isShowImport :true
      })
    }else{
      this.checkForms(checkUser)
    }
  }
  toggleImport({ isShowImport }) {
    this.setState({
      isShowImport: isShowImport
    });
  }

  async handleExport() {
    if(this.state.tenantId){
      const tenantId = this.state.tenantId
      confirmAlert({
        message: intl.get('_system.tenants.user.User.Do you want to get user'),
        buttons: [
          {
            label: intl.get('_common.Download'),
            onClick: async () => {
              let a = document.createElement('a');
              a.href = `/dppapi/system/user/export/${tenantId}?token=${getCsrfToken()}`;
              a.click();
              a.remove();
              this.getUsers()
            },
          },
          {
            label: intl.get('_common.Cancel'),
          },
        ],
      })
    }else{
      this.checkForms(this.state.checkUser)
    }
  }
    
  setForm = (searchForm) => {
    this.setState({
      searchForm: { ...this.state.searchForm, ...searchForm },
    });
  };

  render() {
    const rows = this.state.rows;
    const columns = [
      {
        label: intl.get('_system.tenants.user.User.Num'),
        field: 'num',
        sort: 'asc',
        width: 100
      },
      {
        label: intl.get('_system.tenants.user.User.Status'),
        field: 'status_icon',
        sort: 'asc',
        width: 200
      },
      {
        label: intl.get('_system.tenants.user.User.Account'),
        field: 'oktaAccount',
        sort: 'asc',
        width: 100
      },
      {
        label: intl.get('_system.tenants.user.User.Name'),
        field: 'name',
        sort: 'asc',
        width: 100
      },
      {
        label: intl.get('_system.tenants.user.User.E-mail'),
        field: 'email',
        sort: 'asc',
        width: 200
      },
      {
        label: intl.get('_system.tenants.user.User.Role'),
        field: 'role_name',
        sort: 'asc',
        width: 200
      },
      {
        label: intl.get('_system.tenants.user.User.Operation'),
        field: 'operation',
        sort: 'asc',
        width: 400
      }
    ]
    const data = {
      columns: columns,
      rows: rows
    }

    return (
      <div>
      <UserSearchForm 
      loading={!this.state.isLoaded}
      form={this.state.searchForm}
      roles={this.state.roles}
      setForm={this.setForm} 
      handleSearch={this.getUsers}
      handleExport={this.handleExport}
      handleImport={this.handleImport}
      handleEdit={this.handleEdit}
      onSearch={this.onSearch}
      onChange={this.onChange}
      tenantId={this.state.tenantId}
      isError = {this.state.isError}
      handleFocus = {this.handleFocus}
      errors = {this.state.errors}
      />

      {rows&&rows.length>0 
        ? (
          <Segment>
            <MDBDataTable
              theadColor="common-search-table-header"
              btn
              striped
              bordered
              hover
              searching={false}
              data={data}
            />
          </Segment>
        ) 
        : (<Message info header="No Data" content="Please change conditions" />)}
      
      <UsersEdit 
        isShowEdit={this.state.isShowEdit} 
        roles={this.state.roles} 
        user={this.state.editUser} 
        toggleEdit={this.toggleEdit} 
        getUsers={this.getUsers} 
        tenantId = {this.state.tenantId}/>
      <ImportUsers
          isShowImport={this.state.isShowImport}
          toggleImport={this.toggleImport}
          getUsers={this.getUsers}
          tenantId={this.state.tenantId}
        />
    </div>)
  }
}