import React from 'react';

import { MDBModal, MDBModalBody } from 'mdbreact';
import {
  Button,
  Form,
  Dropdown
} from 'semantic-ui-react';

import api from '../../api'
import intl from 'react-intl-universal';

export default class ProjectUserEdit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      users: [],
      project: {}
    }

    this.getUsers = this.getUsers.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.clearErrors = this.clearErrors.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.clearProject = this.clearProject.bind(this)
    this.clearAll = this.clearAll.bind(this)
  }

  async componentWillReceiveProps() {
    await this.getUsers()
  }

  async getUsers() {
    const { data: users } = await api.get('/dppapi/admin/project/allusers')

    this.setState({
      users: users.map(user => {
        return {
          key: user.id,
          value: user.id,
          text: user.email
        }
      })
    })
  }

  handleChange(event, {name, value}) {
    const project = {...this.state.project}
    project[name] = value
    this.setState({
      project: project
    })
  }

  handleFocus() {
    this.clearErrors()
  }

  clearAll() {
    this.clearErrors()
    this.clearProject()
    this.props.toggleEdit({isShowEdit: false})
  }

  clearErrors() {
    this.setState({
      errors: {}
    })
  }

  clearProject() {
    this.setState({
      project: {}
    })
  }

  async handleSave() {
    const project = Object.assign({}, this.props.project, this.state.project)

    await api.put('/dppapi/admin/project/users', project)

    this.props.successCallback()
    this.clearAll()
  }

  render() {
    const { project } = this.props

    return (
      <MDBModal isOpen={this.props.isShowEdit} toggle={(flag) => {this.props.toggleEdit({isShowEdit: flag})}} centered>
        <MDBModalBody>
          <Form>
            <Form.Field>
              <label>{intl.get('_project.user.ProjectUserEdit.Edit User')}</label>
              <Dropdown 
                placeholder='Select users'
                options={this.state.users} 
                fluid
                multiple
                search
                selection
                pointing='left'
                defaultValue={project.users}
                name='users'
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                />
            </Form.Field>
            <Button floated='right' onClick={this.handleSave}>{intl.get('_project.user.ProjectUserEdit.Save')}</Button>
            <Button floated='right' onClick={this.clearAll}>{intl.get('_project.user.ProjectUserEdit.Cancel')}</Button>
          </Form>
        </MDBModalBody>
      </MDBModal>
    );
  }
}
