/* eslint-disable no-alert, no-console */
import React, { Component } from 'react';
import api from '../../../api';
import { Icon, Button, Popup } from 'semantic-ui-react';
import MyContext from '../../../context-manager';
import { getFinancialStatementsExtraInfo } from '../../../project/result-function/ExtraInfoNewUI';
import { getCsvExtraInfo } from '../../../project/result-function/CsvExtraInfo';
import { sendTenantRpa } from '../../../project/result-function/ResultFunction'

import { confirmAlert } from 'react-confirm-alert';
import intl from 'react-intl-universal';
import { downloadCsv } from '../../Util';
import moment from 'moment';
import CSV from 'comma-separated-values';
import { PageType } from './FsConstant';
import _ from 'lodash';

export default class ResultFunctionToolBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resultFunctions: [],
      rpa: false,
      nextFlag: true,
      needUnitConvert: false,
    };
  }
  async componentDidMount() {
    try {
      const { data } = await api.get(
        '/dppapi/predict/resultFunction/' + this.props.projectId
      );

      if (data.success) {
        this.setState({
          resultFunctions: data.data,
        });
      }
      const { metadatas, success } = (await api.get(
        `/dppapi/predict/prediction/metadata/query?projectId=${
          this.props.projectId
        }&category=result_function`
      )).data;

      if (success) {
        const res = metadatas.find(
          mt =>
            mt.subCategory === 'csv_download' && mt.item === 'unit_conversion'
        );

        const needUnitConvert = res && res.value !== '0';
        this.setState({ needUnitConvert });
      }
    } catch (e) {
      console.log(e);
    }
  }

  componentWillUnmount() {
    this.setState = () => false;
  }

  handelExecResultFunc = async (
    resFunc,
    handleOperateDialog,
    confirmBtnName
  ) => {
    const { errorList } = this.props;
    if (errorList.length > 0) {
      let nameMap = new Map();
      let errorTextList = [];
      const errorPage = {
        [PageType.BS]: [],
        [PageType.PL]: [],
        [PageType.SS]: []
      }
      let i = 0;

      errorList.forEach(err => {
        if(!nameMap.has(err.subCategory)){
          nameMap.set(err.subCategory, err.name);
          err.subCategory&&errorPage[err.subCategory].push(<p className="custom-ui-confirm-text-subTitle" key={i} > {err.subCategory} </p>);
          i = i + 1;
        } else {
          nameMap.set(err.subCategory, nameMap.get(err.subCategory)+ ";"+ err.name);
        }
        err.subCategory&&errorPage[err.subCategory].push(<p className="custom-ui-confirm-text-content" key={i} > ・{err.name} </p>);
        i = i + 1;
      });
      errorTextList = errorPage[PageType.BS].concat(errorPage[PageType.PL]).concat(errorPage[PageType.SS]);
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui-confirm-alert-body-RPA'>
              <h1>
                <Icon name="exclamation triangle" className="total-right-icon-error" />
                {intl.get('_predict.detail.mapping.Some items do not match the values of (automatic aggregation) and (input)')}
              </h1>
              <div className='custom-ui-confirm-alert-body-inner'>
                {errorTextList}
              </div>
              <div className='custom-ui-confirm-alert-button-group'>
                <button
                  className='primary-btn confirmAlert-button-right'
                  onClick={() => {
                    this.handleRpaPop(resFunc, handleOperateDialog, confirmBtnName);
                    document.body.style.setProperty("min-width", '1000px', 'important');
                    document.body.style.setProperty("overflow", 'auto', 'important');
                    onClose();
                  }}>
                  {intl.get('_predict.detail.mapping.As it is') + confirmBtnName + intl.get('_predict.detail.mapping.Proceed to')}
                </button>
                <button
                  className='predict-admin-botton-text confirmAlert-button-left'
                  onClick={onClose}>
                  {intl.get('_predict.detail.mapping.Close')}
                </button>
              </div>
            </div>
          );
        }
      });
      const currentDiv = document.getElementsByClassName("custom-ui-confirm-alert-body-inner")[0];
      const currentHeight = currentDiv.scrollHeight;
      const windowHeight = window.innerHeight;
      const per_height = currentHeight / windowHeight;
      if( per_height > 0.4) {
        currentDiv.style.height = '40vh';
      } else {
        const setHeight = currentHeight + 3;
        currentDiv.style.height = setHeight + 'px';
      }
    } else {
      this.handleRpaPop(resFunc, handleOperateDialog, confirmBtnName);
    }
  };

  handleRpaPop = async (resFunc, handleOperateDialog, confirmBtnName) => {
    
    const getProjectId = () => this.props.projectId;
    const getPdfId = () => this.props.pdfFileId;
    const getPdfInfo = () => this.props.pdfInfo
    const disRpa = () => {
      this.handleDisRpa();
    };
    const updatePdfStatus = async (status = 5) => {
      const result = await api.put(`/dppapi/predict/pdf-status/${this.props.projectId}/${this.props.pdfFileId}`, {
        state: status
      });
      if (result.data.success) {
        this.props.onSetHeaderInfo(result.data.lastEditInfo);
      }
    }
    const saveOptions = async (data) => {
      const result = await api.put(`/dppapi/predict/save-options/${this.props.projectId}/${this.props.pdfFileId}`, {
        output_options: data
      })
      if (result.data.success) {
        this.props.onSetHeaderInfo(result.data.lastEditInfo);
      }
    }
    try {
      const superCsvDownload = async (data, options, fileName, needBom, projectId = this.props.projectId, pdfFileId = this.props.pdfFileId) => {
        await api.post(`/dppapi/predict/evaluate-preset/${projectId}/${pdfFileId}`)
        downloadCsv(data, options, fileName, needBom);
      }
      const scrumDataMap = _.cloneDeep(this.props.scrumData);
      Object.keys(scrumDataMap).forEach(key=>{
        scrumDataMap[key].forEach(e=>{
          if(e.values.length > 0){
            e.values.forEach(v=>{
              v['originalText'] = _.cloneDeep(v.text);
              const val = this.props.onGetSum([v]);
              const parseText = val.replace(/,/g, '')
              v.text = parseText;
              // change [invert] to [invertFlag] aviod multi invert.
              if(v.invert){
                v['invertFlag'] = v.invert
                delete v.invert;
              }
            })
          }
        })
      })
      // eslint-disable-next-line
      const executor = new Function('return ' + resFunc)();
      /*** get table info response 
      *   {
      *     "損益計算書": [
      *       {
      *             "item": {
      *                 "id": 2732468,
      *                 "text": "1.給料手当"
      *             },
      *             "subTitle": {
      *                 "id": 2732469,
      *                 "text": "Ⅲ労務費"
      *             },
      *             "value": {
      *                 "id": 2732467,
      *                 "text": "469,755,740"
      *             },
      *             "scrumItem": "労務費･福利厚生費(製造原価)",
      *             "originalItem": "労務費･福利厚生費(製造原価)",
      *             "pdfImageId": 44398,
      *             "id": "v6shtbvz7fx4untyhipii"
      *         },
      *     ],
      *     "貸借対照表": [
      *     ],
      *     "株主資本等変動計算書": [
      *     ]
      * }
      */
      await executor({
        getTableInfo: () => { 
          return this.props.detailData
        },
        getProjectId,
        getPdfId,
        // hack: due to ui change, we don't want to modify result function script,
        // so we pass onResultFunctionParamSet instead origin handleOperateDialog functiuon
        // and add another function handleDialog = handleOperateDialog  for future use 
        handleOperateDialog: this.props.onResultFunctionParamSet, 
        handleDialog: handleOperateDialog,
        getFinancialStatementsExtraInfo,
        callback: disRpa,
        downloadCsv: superCsvDownload,
        fsScrumData: scrumDataMap,
        getCsvExtraInfo,
        needUnitConvert: this.state.needUnitConvert,
        confirmBtnName,
        getPdfInfo,
        sendTenantRpa,
        moment, 
        CSV,
        updatePdfStatus,
        saveOptions,
      });
      this.setState({ nextFlag: true });
    } catch (e) {
      console.log(e);
    }
  };
  handleDisRpa = () => {
    this.setState({ rpa: true });
  };
  render() {
    const { resultFunctions } = this.state;
    // const { type } = this.props;
    return (
      <>
        {this.props.isEditing?(
          resultFunctions.map(rf => {
            return ( <MyContext.Consumer key={rf.id}>
                {({ handleOperateDialog, commonDialog }) => {
                  return (
                    <Popup
                    trigger = {
                      <Button
                      primary
                      size="medium"
                      className={"pdf-textmapping-rsFunc-btn-disabled"}
                    >
                      {rf.buttonName}
                    </Button>
                    }
                    position="bottom center"
                    content={"「保存」後に" + rf.buttonName + "できます"}
                    on="hover"
                    hideOnScroll
                    inverted
                     />
                  );
                }}
              </MyContext.Consumer>
            );
        })
        ):(
          resultFunctions.map(rf => {
            return (
              <MyContext.Consumer key={rf.id}>
                {({ handleOperateDialog, commonDialog }) => {
                  return (
                    <Button
                      key={rf.id}
                      disabled={this.props.isEditing}
                      primary
                      size="medium"
                      className={"primary-btn pdf-textmapping-primary-btn"}
                      onClick={() => {
                        this.handelExecResultFunc(
                          rf.resultFunction,
                          handleOperateDialog,
                          rf.buttonName
                        )
                      }}
                    >
                      {rf.buttonName}
                    </Button>
                  );
                }}
              </MyContext.Consumer>
            );
        })
        )}
        </>
    );
  }
}
