import React from 'react';
import {
  Form,
  Input,
  Icon,
  Button,
  Checkbox,
  Radio,
  Header,
  TextArea
} from 'semantic-ui-react';

import { sortableHandle } from 'react-sortable-hoc';

import update from 'immutability-helper';

function renderExtraConfig({ value, onChange, error }) {
  if (value.type === 'text') {
    const allowedList = value.allowedList || "";
    return (
      <div>
        <Input
          value={value.prompt}
          label="Input box prompt"
          className="visible"
          placeholder="Ex.: 'car brand and model'"
          disabled={error}
          onChange={(e, data) =>
            onChange(value, { ...value, prompt: data.value })
          }
        />
        <Header as="h5">Allowed characters:</Header>
        <TextArea 
          className="visible"
          value={allowedList}
          onChange={(e, data) =>
            onChange(value, { ...value, allowedList: [...new Set(data.value)].join('') })
          }
        />
      </div>
    );
  }

  if (value.type === 'select' || value.type === 'select-one') {
    const options = value.options || [];
    const Comp = value.type === 'select' ? Checkbox : Radio;

    const renderedOptions = options.map((optionText, index) => (
      <div key={index} className="form-checkbox">
        <Comp checked={false} disabled={error} tabIndex="-1" />
        <Input
          style={{ top: '-3px' }}
          value={optionText}
          size="small"
          disabled={error}
          onChange={(e, data) =>
            onChange(value, {
              ...value,
              options: update(options, { $splice: [[index, 1, data.value]] }),
            })
          }
        />
        <Button
          type="button"
          icon="trash"
          style={{ background: 'transparent' }}
          disabled={error}
          onClick={() =>
            onChange(value, {
              ...value,
              options: update(options, { $splice: [[index, 1]] }),
            })
          }
        />
      </div>
    ));

    return (
      <div>
        <Header as="h5">Options:</Header>
        {renderedOptions}
        <Button
          type="button"
          size="mini"
          circular
          disabled={error}
          icon="plus"
          onClick={() =>
            onChange(value, {
              ...value,
              options: update(options, { $push: ['Option'] }),
            })
          }
        />
      </div>
    );
  }
  return null;
}

export default function LabelFormItem({ value, onChange, error }) {
  const options = [
    { key: 'bbox', text: 'Draw a bounding box', value: 'bbox' },
    { key: 'polygon', text: 'Draw a polygon figure', value: 'polygon' },
    { key: 'text', text: 'Enter a text label', value: 'text' },
    { key: 'select', text: 'Select all tags that apply', value: 'select' },
    {
      key: 'select-one',
      text: 'Select one tag that applies',
      value: 'select-one',
    },
  ];
  const errContent = value.error ? value.error.content : ""
  const muiMsgTip = errContent.length > 0 ? (
    < div
      className="ui pointing below prompt label mui-msg-tip">
      {errContent}
    </div >
  ) : null;

  const extraConfig = renderExtraConfig({ value, onChange, error });

  return (
    <div
      style={{
        marginTop: '0.7em',
        padding: '1em',
        border: 'solid 1px #efefef',
        background: 'white',
        shadow: 'rgb(204, 204, 204) 0px 1px 2px',
      }}
    >
      <Form className="form-card" style={{ display: 'flex' }}>
        <DragHandle style={{ flex: 0, marginTop: 9 }} />
        <div style={{ flex: 1, padding: '0 0.5em' }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItemsL: "center" }}>
            <div style={{ width: "90%" }}>
              {muiMsgTip}
              <Form.Field
                placeholder="Label name"
                control="input"
                defaultValue={value.name}
                style={{ padding: 3, fontSize: 24 }}
                error={errContent.length > 0}
                disabled={errContent.length === 0 && error}
                onChange={e => onChange(value, { ...value, name: e.target.value }, 'label-name')}
              />
            </div>
            <div style={{ width: "10%", display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="button"
                style={{ background: 'transparent', padding: 0 }}
                onClick={() => onChange(value, null, 'label-del')}
                disabled={errContent.length === 0 && error}
              >
                <Icon name="trash" />
              </Button>
            </div>
          </div>
          <Form.Select
            label="Label type"
            options={options}
            disabled={error}
            defaultValue={value.type}
            onChange={(e, change) =>
              onChange(value, { ...value, type: change.value }, 'label-type')
            }
            style={{ maxWidth: 400 }}
          />
          {extraConfig}
        </div>
      </Form>
    </div>
  );
}

const dragHandleStyle = {
  background:
    'linear-gradient(180deg,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000)',
  width: 25,
  minWidth: 25,
  height: 20,
  opacity: 0.25,
  cursor: 'move',
};
const DragHandle = sortableHandle(({ style }) => (
  <div style={{ ...dragHandleStyle, ...style }} />
));
