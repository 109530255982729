import React, { Component } from 'react';

export default class Progress extends Component {
  renderProgress () {
    const progressItemStyle = {
      width: `${100/this.props.nums}%`,
      height: '100%'
    };
    var ele = [];
    for (var i = 0; i < this.props.nums; i++) {
      if (i <= this.props.index) {
        ele.push(
          <div style={Object.assign(progressItemStyle, {backgroundColor: this.props.progressColor})} key={i}></div>
        )
      }
    }
    return ele;
  }

  render() {

    const progressStyle = {
      display: '-webkit-flex',
      color: this.props.progressColor,
      margin: '0 25px'
    };
    
    const progressArticleStyle = {
      height: 16,
      border: '1px solid #B5D3FF',
      width: '100%',
      display: '-webkit-flex',
      borderRadius: 5,
      overflow: 'hidden',
      marginTop: 2,
      backgroundColor:'#B5D3FF',
      marginLeft:-25
    };

    return (
      <div style={progressStyle}>
        <div style={progressArticleStyle}>
          {this.renderProgress()}
        </div>
        <div style={{marginLeft:'10px',color:'#000000',width: '15%'}}>
          {this.props.index}%
        </div>
      </div>
    )
  }
}