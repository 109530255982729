import React, { Component } from 'react';
import { Header, Form, Button, Dropdown, Input, Image } from 'semantic-ui-react';
import api from '../api'
import intl from 'react-intl-universal';
import { confirmAlert } from 'react-confirm-alert';
import {
  withRouter
} from 'react-router-dom';
import { ROOT_URI } from '../HistoryListener';
import { publicEncrypt } from '../common/EncryptUtil';
import { createCookie, getCookie } from '../common/CookieUtil';
import { LinkAltIcon, EyeIcon, EyeOffIcon } from '../assets/icon';
import { cryptoEncrypt } from '../common/EncryptUtil';
import Hotkeys from 'react-hot-keys';
import Slide from '@material-ui/core/Slide';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    const searchValue = new URLSearchParams(props.location.search)
    const confirmFlag = searchValue.get('confirmFlag');
    var rereadSuccess = false
    var rereadSuccessMsg = ""
    if(confirmFlag){
      if(confirmFlag === "1"){
        rereadSuccess = true
        rereadSuccessMsg = intl.get('_login.LoginPage.TenantId success msg')
      }else if(confirmFlag === "2"){
        rereadSuccess = true
        rereadSuccessMsg = intl.get('_login.LoginPage.User retrieve success msg')
      }else if(confirmFlag === "3"){
        rereadSuccess = true
        rereadSuccessMsg = intl.get('_login.LoginPage.Change success msg')
      }
      setTimeout(() =>{
        this.setState({rereadSuccess : false, rereadSuccessMsg : ""})
        this.props.history.replace({...this.props.location, search: null})
      },2500)
    }
    this.state = {
      tenantId: '',
      isTenant: false,
      email: '',
      password: '',
      error: null,
      loading: false,
      tenantName: "",
      isRevealPassword: false,
      tenantErrorMsg: null,
      emailErrorMsg: null,
      passwordErrorMsg: null,
      rereadSuccess,
      rereadSuccessMsg,
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.handleLoginSuccess = this.handleLoginSuccess.bind(this);
    this.handleTenantInputChange = this.handleTenantInputChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  async componentWillMount() {
    await this.getTenantId();
    await this.getButtonShow();
  }

  getTenantId = async () => {
    const { tenantId } = (await api.get('/dppapi/tenant-id')).data;
    if (tenantId && tenantId > 0) {
      this.handleTenantInputChange(tenantId, "tenantId");
      return;
    }
    this.setState({ isTenant: true });

    const query = new URLSearchParams(this.props.location.search)
    const q_tenantId = query.get('tenantId');
    if (q_tenantId) {
      this.handleTenantInputChange(q_tenantId, "tenantId");
      return;
    }

    var c_tenantId = getCookie('tenant_id');
    if (c_tenantId) {
      this.handleTenantInputChange(c_tenantId, "tenantId");
      return;
    }
  }

  getButtonShow = async () => {
    const { buttonShow } = (await api.get('/dppapi/tenant-button-show')).data;
    this.setState({ buttonShow: buttonShow });
  }

  handleTenantInputChange(tenantValue,name) {
    var tenantList = getCookie('tenantList');
    tenantList = tenantList ? JSON.parse(tenantList) : [];
    var tenantName = "";
    var tenantId = name === "tenantId"? tenantValue : "";
    tenantList.forEach(data => {
      if(name === "tenantId"){
        var cookieTID = data.id.toString();
        if (data.id === tenantValue || cookieTID === tenantValue) {
          tenantName = data.name;
          tenantId = data.id;
        }
      }else if(name === "tenantName"){
        if (data.name === tenantValue) {
          tenantName = data.name;
          tenantId = data.id;
        }
      }
    })
    this.setState({ tenantId: tenantId, tenantName: tenantName });
  }

  togglePassword = () => {
    const { isRevealPassword } = this.state
    if (isRevealPassword) {
      this.setState({ isRevealPassword: false })
    } else {
      this.setState({ isRevealPassword: true })
    }
  }
  handleFocus(event) {
    const {name} = event.target; 
    if(name === 'tenantId'){
      this.setState({tenantErrorMsg: null })
    }
    if(name === 'email'){
      this.setState({emailErrorMsg: null })
    }
    if(name === 'password'){
      this.setState({passwordErrorMsg: null})
    }
  }

  async handleLogin(e) {
    const { tenantId, email, password } = this.state;

    let tenantErrorMsg = ""
    let emailErrorMsg = ""
    let passwordErrorMsg = ""

    if (tenantId === ""){
      tenantErrorMsg = intl.get('_login.LoginPage.TenantId error msg')
    } 
    if (email === ""){
      emailErrorMsg =  intl.get('_login.LoginPage.UserId erroe msg')
    }
    if (password === ""){
      passwordErrorMsg =  intl.get('_login.LoginPage.Password error msg')
    }

    if (tenantErrorMsg || emailErrorMsg || passwordErrorMsg){
      this.setState({error: null, tenantErrorMsg, emailErrorMsg, passwordErrorMsg})
      return
    }

    try {
      this.setState({ loading: true })
      const reqBody = {
        tenantId: tenantId,
        email: email,
        password: publicEncrypt(password, window.$config.$pubkey)
      }
      let { permission, success, url, user, oktaEmail } = (await api.post('/dppapi/login', reqBody)).data

      this.setState({ error: null, tenantErrorMsg: null, emailErrorMsg: null, passwordErrorMsg: null });
      localStorage.setItem("permission", permission);
      localStorage.setItem("user", cryptoEncrypt(JSON.stringify(user)))
      localStorage.setItem("url", url)

      if (url === '/login/two-stage-authentication') {
        localStorage.removeItem("permission")
        localStorage.setItem("passTwoStageAuthentication", false)
      }

      createCookie('tenant_id', user.tenantId, 365);
      var tenantList = getCookie('tenantList');
      tenantList = tenantList ? JSON.parse(tenantList) : [];
      var nameList = tenantList.filter(data => {
        if (data.id === user.tenantId) {
          return true;
        }
        return false;
      })
      if (nameList.length === 0) {
        var tenantData = { id: user.tenantId, name: user.tenantName };
        tenantList.push(tenantData)
        createCookie('tenantList', JSON.stringify(tenantList), 365);
      }

      if (oktaEmail) {
        confirmAlert({
          title: intl.get('_login.LoginPage.Confirm'),
          message: intl.get('_login.LoginPage.AlertMessage'),
          buttons: [
            {
              label: intl.get('_login.LoginPage.Confirm'),
              onClick: async () => {
                try {
                  await api.put(`/dppapi/user/okta/${user.id}`, {
                    email: oktaEmail
                  })
                } catch (error) {
                  const { msg } = error.data
                  this.setState({ error: msg.email.content, loading: false });
                }
              },
            },
            {
              label: intl.get('_login.LoginPage.Cancel'),
              onClick: async () => {
                if (success && url) {
                  this.handleLoginSuccess();
                }
              },
            },
          ],
        });
      } else {
        if (success && url) {
          this.handleLoginSuccess();
        }
      }

    } catch (error) {
      const { message } = error.data
      this.setState({ error: message, loading: false });
    }
  }

  handleLoginSuccess() {
    const redirectTo = (new URL(window.location.href)).searchParams.get('redirectTo')
    const ignoreList = ['/dppui/login/two-stage-authentication', '/dppui/login', '/dppui/help'];
    const notIgnore = (redirectUrl) => {
      var notInIgnore = true;
      ignoreList.forEach(element => {
        if (redirectUrl.startsWith(element)) notInIgnore = false;
      });
      return notInIgnore;
    }

    let url = localStorage.getItem("url")
    if (url !== '/login/two-stage-authentication' && redirectTo && notIgnore(redirectTo)) {
      url = redirectTo
    }

    if (url === '/login/two-stage-authentication' && redirectTo && notIgnore(redirectTo)) {
      url = `${url}?redirectTo=${redirectTo}`
    }
    
    this.setState({ loading: false });
    this.props.history.push(url.replace(ROOT_URI, ''));
  }

  onKeyDown(keyName, e) {
    if(keyName === 'tab'){
      e.preventDefault();
      const currentTagName = document.activeElement.tagName.toLowerCase();
      const currentElementName = document.activeElement.getAttribute("name");
      const parentElement = document.getElementsByClassName("login-form-panel");
      let inputElement = parentElement && parentElement[0].querySelectorAll("input");
      let buttonElement = parentElement && parentElement[0].querySelectorAll("button");
      let loginElement = document.getElementsByClassName("footer-form-text");
      if(currentTagName === "body"){
        inputElement.length === 4 ? inputElement[0].focus() : inputElement[1].focus()
      }else if(currentTagName === "input"){
        if(currentElementName === "tenantId"){
          inputElement.length === 4 ? inputElement[2].focus() : inputElement[1].focus()
        }else if(!currentElementName || currentElementName === ""){
          inputElement[2].focus()
        }else if(currentElementName === "email"){
          inputElement.length === 4 ? inputElement[3].focus() : inputElement[2].focus()
        }else if(currentElementName === "password"){
          buttonElement[0].focus()
        }
      }else if(currentTagName === "button"){
        if(currentElementName === "login"){
          loginElement[0].focus()
        }
      }else if(currentTagName === "p"){
        if(currentElementName === "tenantIdConfirm"){
          loginElement[1].focus()
        }else if(currentElementName === "userRetrieve"){
          inputElement.length === 4 ? inputElement[0].focus() : inputElement[1].focus()
        }
      }
    }
  }

  render() {
    const { tenantId, isTenant, tenantName, buttonShow, isRevealPassword, tenantErrorMsg, emailErrorMsg, passwordErrorMsg, rereadSuccess, rereadSuccessMsg } = this.state;
    const passwdIcon = isRevealPassword ? (<EyeOffIcon onClick={this.togglePassword} size={'20px'} className='login-form-input-passwd-icon' />)
      : (<EyeIcon onClick={this.togglePassword} size={'20px'} className='login-form-input-passwd-icon' />);
    var tenantList = getCookie('tenantList');
    tenantList = tenantList ? JSON.parse(tenantList) : [];
    var options = [];
    tenantList.forEach(k => options.push({ key: k.id, text: k.name, value: k.name }));
    var tenantErrorClass = tenantErrorMsg ? "input-error" : ""
    var emailErrorClass = emailErrorMsg ? "input-error" : ""
    var passwordErrorClass = passwordErrorMsg ? "input-error" : ""
    return (
      <div className="login-container">
        <Hotkeys
          keyName="tab"
          onKeyDown={this.onKeyDown.bind(this)}
        />
        <div style={{ position: 'fixed', top: '-120px' }}>
          <img style={{ width: '100%', height: '100%', backgroundColor: '#053E81' }} src={require('./top_bg@2x.png')} alt="login" />
        </div>
        <Slide direction="up" in={rereadSuccess}>
          <div
            className="canvas-success-slide login-snackbar"
            style={{
              display: rereadSuccess ? 'block' : 'none',
            }}
          >
            {rereadSuccessMsg}
          </div>
        </Slide>
        <div className="login-form-panel">
          <Form className="login-form-re">
            <Header as='h1' className="login-form-head">
              <Image src='/assets/img/logo_blue.svg' className="login-form-head-logo"/>
            </Header>
            {/* <Header className="login-form-second-head">{intl.get('_login.LoginPage.UserLogin')}</Header> */}
            {isTenant ?
              <>
                <div className="login-form-inline">
                  <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                    <label className="login-form-input-label">{intl.get('_login.LoginPage.Tenant')}</label>
                    <Form.Field style={{ width: '100%', display: 'inline-block', paddingRight: '2%' }}>
                      <Input
                        type="number"
                        disabled={!isTenant}
                        value={tenantId}
                        name="tenantId"
                        onFocus={this.handleFocus}
                        onChange={(e, { value }) => this.handleTenantInputChange(value, "tenantId")}
                        className={`login-form-tenant-id select-pagetype tenant-id-hidden-arrow ${tenantErrorClass}`}
                      />
                    </Form.Field>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <label className="login-form-input-label" style={{ height: '12px' }}></label>
                    <Form.Field style={{ width: '100%', display: 'inline-block'}}>
                      <Dropdown
                        className='icon login-form-tenant-name select-pagetype dropdown-place-color'
                        options={options}
                        style={{ width: '100%' }}
                        search
                        selection
                        value={tenantName}
                        name="tenantName"
                        noResultsMessage = {intl.get('_login.UserRetrievePage.No tenants to select')}
                        placeholder={intl.get('_login.UserRetrievePage.TenantName info')}
                        onChange={(e, { value }) => this.handleTenantInputChange(value,"tenantName")}
                        onKeyDown={(e) => {if(e.keyCode === 13){e.preventDefault()}}}
                      />
                    </Form.Field>
                  </div>
                </div>
                <p className="login-form-single-error">{tenantErrorMsg}</p>
                </> :
              <Form.Input
                type="text"
                disabled
                value={tenantId}
                label={intl.get('_login.LoginPage.Tenant')}
              />}
            <div>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                  <label className="login-form-input-label">{intl.get('_login.LoginPage.User ID')}</label>
                  <Form.Field style={{ width: '100%', display: 'inline-block' }}>
                    <Input
                      type="text"
                      name="email"
                      onFocus={this.handleFocus}
                      onChange={(e, { value }) => this.setState({ email: value })}
                      className={`${emailErrorClass}`}
                    />
                  </Form.Field>
                </div>
                <p className="login-form-single-error">{emailErrorMsg}</p>
            </div>
            <div>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                  <label className="login-form-input-label">{intl.get('_login.LoginPage.Password')}</label>
                  <Form.Field style={{ width: '100%', display: 'inline-block' }}>
                    <Input
                      type={isRevealPassword ? 'text' : 'password'}
                      icon={passwdIcon}
                      name="password"
                      onFocus={this.handleFocus}
                      onChange={(e, { value }) => this.setState({ password: value })}
                      className={`${passwordErrorClass}`}
                    />
                  </Form.Field>
                </div>
                <p className="login-form-single-error">{passwordErrorMsg}</p>
            </div>
            <Button fluid
              loading={this.state.loading}
              disabled={this.state.loading}
              secondary={this.state.loading}
              className="login-btn primary-btn login-form-button-margin"
              name="login"
              onClick={this.handleLogin}>{intl.get('_login.LoginPage.Login')}</Button>
            {/* <Button
                color='green'
                floated='right'
                onClick={() =>{ window.location = `/dppui/login/retrieve`}}>{intl.get('_login.LoginPage.ResetPassword')}</Button> */}
            <div className="login-footer-form-link">
              <p className="footer-form-text" 
                name="tenantIdConfirm"
                tabIndex={0}
                onKeyDown={(e) => {
                  if(e.keyCode === 13){
                    createCookie('user_email', this.state.email, 365)
                    window.location = `/dppui/login/tenantIdConfirm`
                  }}}
                onClick={
                  () => { createCookie('user_email', this.state.email, 365); window.location = `/dppui/login/tenantIdConfirm` }
              }>
                {intl.get('_login.LoginPage.Tenant ID Confirm')}
              </p>
              <p className="footer-form-text" name="userRetrieve" tabIndex={0}
                onKeyDown={(e) => {
                  if(e.keyCode === 13){
                    window.location = `/dppui/login/userRetrieve`
                  }}}
                onClick={() => { window.location = `/dppui/login/userRetrieve` }}>
                {intl.get('_login.LoginPage.Email authentication')}
              </p>
            </div>
            {isTenant && buttonShow === 'true' ?
              <Button.Group style={{ width: '100%' }} >
                <Button
                  content={intl.get('_login.LoginPage.RootLogin')}
                  className="login-form-group-botton"
                  onClick={() => { window.location = `/dppui/login/tenantLoginPage` }} />

                <Button
                  content={intl.get('_login.LoginPage.Register')}
                  className="login-form-group-botton"
                  onClick={() => { window.location = `/dppui/login/register` }} />

                {/* <Button
                  content={intl.get('_login.LoginPage.TenantIdConfirm')}
                  className="login-form-group-botton"
                  onClick={() => { createCookie('user_email', this.state.email, 365); window.location = `/dppui/login/tenantIdConfirm` }}
                /> */}
              </Button.Group>
              : null}
            {/* {isTenant && buttonShow !== 'true' ?
              <Button.Group style={{ width: '100%' }}>
                <Button
                  content={intl.get('_login.LoginPage.TenantIdConfirm')}
                  onClick={() => { createCookie('user_email', this.state.email, 365); window.location = `/dppui/login/tenantIdConfirm` }}
                />
              </Button.Group>
              : null} */}
            <p className="login-form-group-error">{this.state.error}</p>
          </Form>
        </div>
        <div className="login-footer-link">
          <div className="login-footer-link-icon">
            <span style={{ marginRight: '8px', hover: 'cursor', cursor: 'pointer' }} onClick={() => { window.open('https://www.smfl.co.jp/privacy/') }}>
              <span className="footer-text">プライバシーポリシー</span>
              <LinkAltIcon />
            </span>
            <span style={{ color: '#fff', marginRight: '8px' }}>|</span>
            <span style={{ marginRight: '8px', hover: 'cursor', cursor: 'pointer' }} onClick={() => { window.open('https://www.smfl.co.jp/cookie-policy/') }}>
              <span className="footer-text">クッキー（Cookie）ポリシー</span>
              <LinkAltIcon />
            </span>
            <span style={{ color: '#fff', marginRight: '8px' }}>|</span>
            <span style={{ hover: 'cursor', cursor: 'pointer' }} onClick={() => { window.open('https://www.smfl.co.jp/external-transmission/') }}>
              <span className="footer-text">利用者情報の外部送信について</span>
              <LinkAltIcon />
            </span>
          </div>
          <p className="login-footer-text">© Sumitomo Mitsui Finance and Leasing Co., Ltd. All Rights Reserved.</p>
        </div>
      </div>
    );
  }
}

export default withRouter(LoginPage);
