import React, { Component } from 'react';
import intl from 'react-intl-universal';

import { ExpandMoreOutlined, ExpandLessOutlined } from '@material-ui/icons';
import { Segment, Popup } from 'semantic-ui-react';
import _ from 'lodash';

import { PageType } from './FsConstant';
import { CheckCircleIcon, ExclamationTriangleIcon } from '../../../assets/icon';

class AccordionExpandIconButton extends React.Component {

  render() {
    const { isExpanded } = this.props;
    return (
      <>
        {isExpanded ?
          <ExpandLessOutlined className="fs-detail-accordion-expand-icon" /> :
          <ExpandMoreOutlined className="fs-detail-accordion-expand-icon" />}
      </>
    );
  }
}

export default class CurrentPageErrorList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sectionDisplay: true,
    };
  }

  render() {
    const {
      errorList,
      selectedCell,
      rows,
      currentPageType,
      reload,
      filterItems,
      onSetFilterItems,
      mappingType,
      ssCommon,
    } = this.props;
    const { sectionDisplay } = this.state;
    const noChangeOnClick = (currentPageType === PageType['SS'] && ssCommon) || mappingType === 1;
    let currentPageErrorList = errorList ?
      _.filter(errorList, (item) => !item.name.includes('当期末残高(B/S入力)') && item.subCategory === currentPageType) : [];
    const correntedAmount = _.find(errorList, (item) => {
      return filterItems && filterItems.at(-1) === item.name
    });
    if (filterItems && filterItems.length > 0 && !correntedAmount) {
      currentPageErrorList.push(
        {
          "name": filterItems.at(-1),
          "isCorrentedAmount": true
        }
      )
    }
    if (!_.isEmpty(currentPageErrorList) && currentPageErrorList.length > 0) {
      // Handle error list
    } else {
      console.log("errorList:", errorList, currentPageType);
    }

    return (
      <div style={{ padding: '0 10px 0 0', font: '11px Noto Sans JP' }}>
        {!_.isEmpty(currentPageErrorList) && currentPageErrorList.length > 0
          ? (
            <Segment className="fs-detail-discrepancy" id='errorContainer'>
              <div className='fs-detail-space-between'>
                <div className='fs-detail-error-message-container'>
                  <ExclamationTriangleIcon size="16px" color="#F26868" className="detail-container-icon-error" />
                  <span className='fs-detail-error-message'>自動集計と入力値が不一致<br /></span>
                </div>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ sectionDisplay: !sectionDisplay });
                    reload && reload();
                  }}
                >
                  <AccordionExpandIconButton
                    isExpanded={sectionDisplay} />
                </div>
              </div>
              {sectionDisplay &&
                <div id='errorItems'>
                  <div className='fs-detail-error-items'>
                    {
                      currentPageErrorList.map((errorItem) => {
                        const selectedRow = _.find(rows, { id: selectedCell.id });
                        const isSelectedItem = selectedRow && (errorItem.name === selectedRow.scrumItem || _.includes(errorItem.children, selectedRow.scrumItem));
                        const modifiedName = errorItem.name.replace(new RegExp('\\(入力\\)', 'g'), '');
                        const discrepancy = errorItem.isCorrentedAmount ? 0 : errorItem.calcVal - errorItem.rawVal;
                        /* eslint no-eval: 0 */
                        const convertText = new Intl.NumberFormat('ja-JP', { style: 'decimal', currency: 'CAD', }).format(Math.round(eval(discrepancy)));
                        const isSelected = filterItems && (errorItem.isCorrentedAmount || _.isEqual(errorItem.children.concat(errorItem.name), filterItems));
                        return (
                          <Popup
                            key={errorItem.name}
                            trigger={
                              <div>
                                <div
                                  className={`fs-detail-error-item 
                                  ${isSelected && !noChangeOnClick ? ' fs-detail-error-item-selected' : ''}
                                  ${' fs-detail-error-item-clickable'}
                                  `}
                                  onClick={() => {
                                    if ((filterItems && filterItems.length === 0) || !isSelected) {
                                      onSetFilterItems([...errorItem.children, errorItem.name]);
                                    } else {
                                      if (!noChangeOnClick) onSetFilterItems([]);
                                    }
                                  }}
                                >
                                  <span
                                    className={`${isSelectedItem ? 'fs-detail-discrepancy-selectedItem' : ''}`}
                                    style={{ marginRight: '6px' }}>
                                    {modifiedName}
                                  </span>
                                  <span className={`${errorItem.isCorrentedAmount ? 'fs-detail-agreement-item-amount' : 'fs-detail-error-item-amount'}`}>
                                    {discrepancy > 0 ? '+' + convertText : convertText}
                                  </span>
                                </div>
                                {isSelected && !noChangeOnClick ?
                                  <h1 className='fs-detail-narrowing-down'>
                                    {intl.get('_predict.detail.mapping.Narrowing down')}
                                  </h1> : null
                                }
                              </div>
                            }
                            position="top center"
                            content={
                              noChangeOnClick ?
                                intl.get('_predict.detail.mapping.Display the relevant sections') :
                                isSelected ?
                                  intl.get('_predict.detail.mapping.Release filter') :
                                  intl.get('_predict.detail.mapping.Narrow down data')
                            }
                            // disabled={noChangeOnClick}
                            on="hover"
                            hideOnScroll
                            inverted
                          />
                        );
                      })
                    }
                  </div>
                </div>
              }

            </Segment>
          ) :
          <Segment className="fs-detail-agreement" id='rightContainer'>
            <div className='fs-detail-space-between'>
              <div className='fs-detail-agreement-message-container'>
                <CheckCircleIcon color="#63D980" className="detail-container-icon-success" size="16px" />
                <span className='fs-detail-agreement-message'>すべての自動集計と入力値が一致<br /></span>
              </div>
            </div>
          </Segment>
        }
      </div>
    )
  }
}