import React, { Component } from 'react';
import api from '../../api';
import ActionBar from './ActionBar';
import Table from './Table';
import Edit from './Edit';
import Import from './Import';

class ConvertSubtitle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editItem: null,
      form: {},
      items: [],
      page: {
        pageNo: 1,
        pageCount: 1,
      },
      isShowEdit: false,
      isShowImport: false,
    };
  }

  componentDidMount(){
    this.handleSearch()
  }

  handleSearch = async (pageNo = 1, limit = 10) => {
    const { subtitleName = '', convertSubtitle = '', category = '' } = this.state.form;
    const { data } = await api.get(
      `/dppapi/tenant-setting/convert-subtitle?subtitleName=${subtitleName}&convertSubtitle=${convertSubtitle}&category=${category}&pageNo=${pageNo}&limit=${limit}`
    );
    this.setItems(data.list);
    this.setPage(data.page);
  };

  setForm = (form) => {
    this.setState({
      form: { ...this.state.form, ...form },
    }, ()=>console.log(this.state.form));
  };

  setItems = (items) => {
    this.setState({
      items: items,
    });
  };

  setPage = (page) => {
    this.setState({
      page: page,
    });
  };

  toggleEdit = (isShowEdit, item) => {
    this.setState({
      isShowEdit: isShowEdit,
      editItem: item || null
    });
  };

  toggleImport = (isShowImport) => {
    this.setState({
      isShowImport: isShowImport,
    });
  };

  render() {
    const { items, page, isShowEdit, isShowImport, editItem } = this.state;
    return (
      <div className="ui">
        <ActionBar
          setItems={this.setItems}
          setPage={this.setPage}
          setForm={this.setForm}
          handleSearch={this.handleSearch}
          toggleEdit={this.toggleEdit}
          toggleImport={this.toggleImport}
        />
        <Table
          items={items}
          page={page}
          setItems={this.setItems}
          handleSearch={this.handleSearch}
          toggleEdit={this.toggleEdit}
        />
        <Edit
          editItem={editItem}
          isShowEdit={isShowEdit}
          toggleEdit={this.toggleEdit}
          handleSearch={this.handleSearch}
        />
        <Import
          isShowImport={isShowImport}
          toggleImport={this.toggleImport}
          handleSearch={this.handleSearch}
        />
      </div>
    );
  }
}

export default ConvertSubtitle;
