/* 1, AI処理待ち, 2, AI処理中, 3, ユーザ確認待ち, 4, エラー, 5, ユーザ確認完了 */
const stateFlag = {
  1: 'AI読取待ち',
  2: 'AI読取中',
  3: '編集可能',
  4: 'エラー',
  5: 'ダウンロード済み'
}
export const PdfStatus = {
  WAIT_AI_PROCESS: 1,
  AI_PROCESSING: 2,
  WAIT_PROCESS: 3,
  AI_FROCESS_FAILURE: 4,
  PROCESS_COMPLETE: 5
}

export const STATE_3 = 3;
export const STATE_5 = 5;

export function getOptions() {
  const options = [];

  // remove  2: 'AI処理中'  state because after useing queue to reslove pdf, there was no longer a state === 2 situation 
  // console.log(Object.keys(stateFlag))
  Object.keys(stateFlag).filter(k => k !== '2').forEach(k => { 
    if (k === '1') {
      options.push({ key: k, value: k, text: `${stateFlag[k] }&${stateFlag[Number(k)+ 1] }`})

    } else { 
      options.push({ key: k, value: k, text: stateFlag[k] })
    }
  })
  return options
}

export function getStateFlag(k) {
  return stateFlag[k]
}

export const PREDICT_DEFAULT_LABEL_ID = "bbox"
export const PREDICT_DEFAULT_LABEL_NAME = "word"

export const PDF_PAGE_CLASSIFICATION = {
  '決算報告書表紙': [],
  '貸借対照表': [],
  '損益計算書': [],
  '製造原価明細表': [],
  '販売費及び一般管理費': [],
  '利益処分書': [],
  '株主資本等変動計算書': [],
  '個別注記表': [],
  'その他': [],
  '二種混合型': []
}

export const PAGE_CLASSIFICATION = {
  BS: "貸借対照表",
  PL: "損益計算書",
  MC: "製造原価明細表",
  MF: "販売費及び一般管理費"
 }

export const PDF_PAGE_CLASSIFICATION_MAPPING = {
  '0': '決算報告書表紙',
  '1': '貸借対照表',
  '2': '損益計算書',
  '3': '製造原価明細表',
  '4': '販売費及び一般管理費',
  '5': '利益処分書',
  '6': '株主資本等変動計算書',
  '7': '個別注記表',
  '8': 'その他',
  '9': '二種混合型'
}

export const COLUMN_TYPE = {
  SELECTBBOX: 'selectBbox',
  INPUTTEXT: 'inputText',
  SELECTION: 'selection'
}

export const PAGE_TYPE = {
  FIXED: 'fixed',
  CHANGE: 'change'
}

export const DET_TEXT_CLASSES = {
  ADDR: 'addr',
  COMMENT: 'comment',
  DATE: 'date',
  NAME: 'name',
  SEAL: 'seal',
  TEL: 'tel',
  VALUE: 'value',
  WORD: 'word'
}

export function getRecommendKey(type) {
  switch (type) {
    case 'scrumItem':
      return ['subTitle', 'item']
    default:
      return ['subTitle', 'item']
  }
}

export const SCRUM_ITEM_SUFFIX = {
  INPUT: "入力",
  AGGREGATION: "(自動集計)"
}
