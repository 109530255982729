import React, { Component } from 'react';
import moment from "moment";
import { Link } from 'react-router-dom';
import { Message, Loader, Dropdown, Divider, Checkbox, Button, Popup, Pagination } from 'semantic-ui-react';
import { MDBDataTable } from 'mdbreact';
import Conditions from './Conditions';
import { toUnixMS, FMT, unixMSToDatetime } from '../TimeUtil';
import { downloadCsv, getPdfStatusOptions } from '../Util';
import { PdfStatus, STATE_3, STATE_5} from '../Constant';
import intl from 'react-intl-universal';
import { cloneDeep, isEqual } from 'lodash';
import FileListDelete from './FileListDelete';
import FileListUserSet from './FileListUserSet';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import api from '../../api';
import { getCsrfToken } from '../../common/CookieUtil';
import Slide from '@material-ui/core/Slide';
import { PersonIcon, DownloadIcon, PictureAsPdfIcon, DeleteIcon, MoreVertIcon, MdiChevronLeftIcon, MdiChevronRightIcon,
      KeyboardDoubleArrowLeftIcon, KeyboardDoubleArrowRightIcon } from '../../assets/icon';
import _ from 'lodash';
import { defaultStatusText, defaultStatusColor,statusColor } from '../../predict/Constant';
import { getPdfStatus } from '../../predict/Util';
//import ReactDOM from 'react-dom';
import { AnalyticsServiceContext } from '../../common/Analytics/AnalyticsServiceProvider';
import { EventName, EventType, PageName, SelectedFileNum } from '../../common/Analytics/analyticsMetrics';
import { ReactComponent as ReactSendLogo } from '../../assets/icon/svg/send.svg';
import { confirmAlert } from 'react-confirm-alert';
import { ReactComponent as ReactMdiErrorLogo } from '../../assets/icon/svg/mdi-error.svg';

const option = {
  header: ['pdf id', 'page name', 'subtitle', 'item', 'value', 'scrum item', 'original item'],
}

export default class PredictSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      init: true,
      rows: [],
      columns:[],
      isLoading: false,
      permissionFlag: false,
      users: [],
      userList: [],
      projectMessage:[],
      selectList: {},
      selectSingle: {},
      isFileDeleteShow: false,
      isFileUserSetShow: false,
      deleteMsgShow: false,
      userSetMsgShow: false,
      statusTexts: null,
      editLoading: false,
      toolbarFlg: false,
      singleFlg: false,
      windowSize: { width: window.innerWidth, height: window.innerHeight },
      initStyle: { height: '68px', paddingBottom: '16px', font: 'normal normal bold 12px/18px Noto Sans JP' },
      entries : 10,
      rowsPageList: [],
      activePage : 1,
      menuBoxBottomHeight : 20,
      rowsLength : 0,
      isAllowJumpMappingReview: true
    };
    this.tableRef = React.createRef();
    this.conditionRef = React.createRef();

    this.handleCheckout = this.handleCheckout.bind(this);
    this.pdfFilesToRows = this.pdfFilesToRows.bind(this);
    this.toggleDelete = this.toggleDelete.bind(this);
    this.toggleUserSet = this.toggleUserSet.bind(this);
    this.handleLoading = this.handleLoading.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleListRefetch = this.handleListRefetch.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.sendMetricsClickEvent = this.sendMetricsClickEvent.bind(this);
    this.setActivePage = this.setActivePage.bind(this);
    this.tableSearch = this.tableSearch.bind(this);
    this.checkIsShow = this.checkIsShow.bind(this);
  }
  static contextType = AnalyticsServiceContext;

  // getProjectMessage = (projectMessage) =>{this.setState({projectMessage:projectMessage})}
  getProjectMessage = (projectMessage) => {}
  
  componentDidMount = async () => {
    window.addEventListener('resize', this.handleResize);

    const per = localStorage.getItem("permission") || '';
    let permissionFlag = false;
    per.split(',').forEach((item) => {
      if (item === '2') {
        permissionFlag = true;
      }
    })

    this.setState({ permissionFlag });

    // get allow_file_download flag
    const conditions = {
      category: 'configure',
      subCategory: 'nodejs',
      name: 'predict',
      subName: 'allow_file_download',
    }
    const res = (await api.post(
      '/dppapi/predict/system/preference/query', { conditions }
    )).data;

    let isAllowFileDownload = 0
    if (res.success) {
      isAllowFileDownload = Number(res.value)
    }

    this.setState({isAllowFileDownload});

    await this.checkIsShow();
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({
      windowSize: {
        width: window.innerWidth,
        height: window.innerHeight
      }
    })
  }

  
  handleSelectChange = (e) => {

    this.context.sendMetrics({
      page_name: PageName.PredictPage,
      event_type: EventType.Action,
      event_name: EventName.ChangeTableRowNum,
      custom_parameter: {
        selected_table_row_num: e.target.value,
      },
    })

    this.setState({entries: e.target.value},()=>{  
      this.handleTurnPage(this.state.rows)
      setTimeout(() => {
        this.addRowSelectedClass()
      }, 10)
      const { columns, selectList, rowsPageList, activePage } = this.state;
      let selected = cloneDeep(selectList);
      let activePageState =  activePage
      const pages = rowsPageList;
      if(pages.length < activePageState){
        activePageState = 1
        this.setState({activePage: activePageState})
      }
      let indeterFlg = false
      const selectKeys = Object.keys(selected);
      let newPageIds = pages[activePageState - 1].map(d=>{return d.id})
      selectKeys.forEach(d=>{
        if(newPageIds.indexOf(Number(d)) === -1){
          delete selected[d]
        }
      })
      const activeSelectKeys = Object.keys(selected);
      activeSelectKeys.length > 0 && pages[activePageState - 1].forEach(data=>{
        if (activeSelectKeys.indexOf(String(data.id)) === -1) {
          indeterFlg = true
        }
      })
      columns[0].label = (
        <>
        <Popup
          trigger={
            <Checkbox id="all" checked={activeSelectKeys.length > 0} indeterminate={indeterFlg} onChange={(e, {checked}) => this.handleCheckBoxAll(checked)}/>
          }
          position="top center"
          content={'表示中のファイルを一括選択/選択解除'}
          on="hover"
          wide='very'
          hideOnScroll
          inverted
        />
        </>);
      this.setState({columns});
    });
  }

  addRowSelectedClass = (curPageNum) => {
    const curSelectList = this.state.selectList
    const tbody = document.querySelector('tbody')
    const elms = tbody.getElementsByTagName('tr')
    const activePage = curPageNum !== undefined ? curPageNum : this.state.activePage
    const pages = this.state.rowsPageList

    const curPage = pages[activePage-1]
    const curSelectIds = Object.keys(curSelectList)
    for (let i = 0; i < curPage.length; i++) {
      const row = curPage[i]
      if (curSelectIds.indexOf(row.id.toString()) !== -1) {
        elms[i] && elms[i].classList.add('file-row-selected')
      } else {
        elms[i] && elms[i].classList.remove('file-row-selected')
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    this.removeDropDownStyle();
    const curSelectList = this.state.selectList
    const preSelectList = prevState.selectList

    if (this.tableRef.current && !isEqual(preSelectList, curSelectList)) {
      this.addRowSelectedClass()
    }
    const { selectList } = this.state;
    if (this.props.projectId !== '' && prevProps.projectId !== this.props.projectId) {
      this.defaultUser();
      this.getUsers();

    }
    const selectedCount = Object.keys(selectList).length;
    const serachDom = document.querySelector('.dataTables_filter input')
    const serachLabelDom = document.querySelector('.dataTables_filter label')
    if (serachDom) {
      serachDom.placeholder = "キーワード"
      if (selectedCount > 0) {
        serachDom.disabled = true
        serachLabelDom.classList.add('serach-label')
      } else {
        serachDom.disabled = false
        serachLabelDom.classList.remove('serach-label')
      }
    }

    if (prevState.windowSize.width !== this.state.windowSize.width ||
      prevState.windowSize.height !== this.state.windowSize.height) {
      if(selectedCount > 0) {
        this.setBottomSize(true);
      } else {
        this.setBottomSize(false);
      }
    }
  }

  removeDropDownStyle = () => {
    const { rowsPageList, activePage } = this.state;
    rowsPageList.length > 0 && rowsPageList[activePage - 1].forEach(row => {
      // setTimeout(() => {
        const dropDownDom = document.getElementById(`predict-drop-down-menu-${row.id}`);
        if (dropDownDom) {
          const computedStyle = window.getComputedStyle(dropDownDom);
          if (computedStyle.getPropertyValue('display') === 'none') {
            dropDownDom.style.top = null;
            dropDownDom.style.bottom = null;
            dropDownDom.style.boxShadow = null;
            dropDownDom.style.borderRadius = null;
          }
        }
      // }, 50)
    })
  }

  addDropDownStyle = () => {
    const { rowsPageList, activePage } = this.state;

    rowsPageList.length > 0 && rowsPageList[activePage - 1].forEach(row => {
      setTimeout(() => {
        const currentRow = document.getElementById(`predict-drop-down-${row.id}`);
        const pagination = document.getElementsByClassName("datatable-info-pagination");
        const currentRowTop = currentRow && currentRow.getBoundingClientRect().top;
        const paginationTop = pagination.length > 0 && pagination[0].getBoundingClientRect().top;
        const currentBottom = paginationTop && currentRowTop && paginationTop - currentRowTop;
        if (currentBottom && currentBottom < 220) {
          const dropDownDom = document.getElementById(`predict-drop-down-menu-${row.id}`);
          dropDownDom.style.top = "auto";
          dropDownDom.style.bottom = "100%";
          dropDownDom.style.boxShadow = "0 0 3px 0 rgba(0, 0, 0, .08)";
          dropDownDom.style.borderRadius = ".28571429rem .28571429rem 0 0";
        }
      }, 10)
    })
  }

  setBottomSize = (isPopup) => {
    let newStyle = _.cloneDeep(this.state.initStyle);
    let menuBoxBottomHeight = 20;
    let height = 48;
    if (isPopup && document.getElementById('list-action-menu') && Object.keys(this.state.selectList).length > 0) {
      const menuBoxBottom = document.getElementById('list-action-menu').offsetHeight;
      menuBoxBottomHeight = menuBoxBottom + 24
      height = height + menuBoxBottomHeight
      newStyle.paddingBottom = (menuBoxBottomHeight) + 'px'
      newStyle.height = (height) + 'px'
    } else {
      height = height + menuBoxBottomHeight
      newStyle.paddingBottom = 16 + 'px'
      newStyle.height = (height) + 'px'
    }
    this.setState({ initStyle: newStyle, menuBoxBottomHeight })
  }

  defaultUser = async () => {
    const param = {
      category: 'configure',
      subCategory: 'nodejs',
      name: 'work-space',
      subName: 'init-search-login-user',
    }
    // get user info
    const info = (await api.get(`/dppapi/info`)).data;
    // get default flag
    const res = await api.request({
      url: `/dppapi/predict/tenant/preference/query`,
      method: 'POST',
      data: param,
    });
    let defaultValue = false
    if (res && res.data && res.data.success === true) {
      defaultValue = res.data.value
    }
    this.onSearch({
      fileName: '',
      startDate: moment().add(-90, 'd').toDate(),
      endDate: moment().toDate(),
      creator: defaultValue === 'true' ? info.id : '',
      operator: '',
      status: ''
    })
    // set default value
    this.conditionRef.current.state.creator = defaultValue === 'true' ? info.id : ''
  }

  getUsers = async () => {
    const { projectId } = this.props;
    const { data: users } = await api.get(`/dppapi/predict/projectusers/${projectId}`);

    this.setState({
      users: users.map(user => {
        return {
          key: user.id,
          value: user.id,
          text: user.name
        }
      })
    })

    this.setState({
      userList: users.map(user => {
        return {
          key: user.id,
          value: user.id + ',' + user.name,
          text: user.name
        }
      })
    })
  }

  isProcessing = (rabbitmqMessages, pdf) => {
    let processing = true
    for (let i = 0; i < rabbitmqMessages.length; i++) {
      if (rabbitmqMessages[i].pdfFile.id === pdf.id) {
        processing = false
        break
      }
    }
    return processing
  }

  onSearch = async data => {
    const { projectId } = this.props;
    const { fileName, startDate, endDate, creator, operator, status } = data;

    const req = {
      projectId,
      fileName,
      startDate: startDate ? toUnixMS(moment(startDate).format(FMT.DATE)) : '',
      endDate: endDate ? toUnixMS(moment(endDate).add(1, 'd').format(FMT.DATE)): toUnixMS(moment(moment().toDate()).add(1, 'd').format(FMT.DATE)),
      creator,
      operator,
      status
    };

    this.setState({ editLoading: true }); // show loading
    const pdfFiles = (await api.get(
      `/dppapi/predict/pdfs/${req.projectId}?fileName=${req.fileName}&creatorId=${req.creator}&operatorId=${req.operator}&state=${req.status}&uploadStartTime=${req.startDate}&uploadEndTime=${req.endDate}`
    )).data;

    // get rabbitmq message info
    const messageRes = (await api.get('/dppapi/predict/rabbitmq/fs-inference-messages')).data;

    let rabbitmqMessages = []
    let useRabbitmq = false

    if (messageRes.success) {
      rabbitmqMessages = messageRes.data.messages
      useRabbitmq = messageRes.data.useRabbitmq
    }

    const param = {
      category: 'configure',
      subCategory: 'nodejs',
      name: 'pdf-status',
      subName: 'status-info',
    }

    const tenantPreferenceRes = await api.request({
      url: `/dppapi/predict/tenant/preference/query`,
      method: 'POST',
      data: param,
    });

    const statusInfo = getPdfStatus(tenantPreferenceRes)
    const statusOptions = getPdfStatusOptions(statusInfo);

    let rows = [];
    this.setState({
      selectList: {}
    });
    if (pdfFiles) {
      rows = pdfFiles.map((pdfFile) => {
        if (useRabbitmq && pdfFile.stateFlag === PdfStatus.AI_PROCESSING && !this.isProcessing(rabbitmqMessages, pdfFile)) {
          pdfFile.stateFlag = PdfStatus.WAIT_AI_PROCESS
        }
        return this.pdfFilesToRows(projectId, pdfFile, statusInfo);
      })
    }

    const columns = this.getColumns();
    this.handleTurnPage(rows);

    this.setState({
      init: false,
      sortColumn: '',
      sortDirection: '',
      columns,
      rows: rows,
      statusTexts: tenantPreferenceRes,
      editLoading: false,
      statusOptions
    });
  };

  async handleListRefetch() {
    let { rows, selectList, selectSingle } = this.state;
    let changeIdList = Object.keys(selectSingle).concat(Object.keys(selectList))
    const { projectId } = this.props;
    const files = changeIdList.length > 0 ? (await api.post(`/dppapi/predict/${projectId}/change`,{ pdfFileIds: changeIdList })).data : [];
    const param = {
      category: 'configure',
      subCategory: 'nodejs',
      name: 'pdf-status',
      subName: 'status-info',
    }
    const tenantPreferenceRes = await api.request({
      url: `/dppapi/predict/tenant/preference/query`,
      method: 'POST',
      data: param,
    });
    this.setState({
      selectList: {},
      selectSingle: {}
    });
    
    const statusInfo = getPdfStatus(tenantPreferenceRes)
    const statusOptions = getPdfStatusOptions(statusInfo);

    files && files.forEach(pdf=>{
      if (pdf.id && !pdf.deleteFlag) {
        rows = rows.map(row => {
          if (pdf.id === row.id) {
            return this.pdfFilesToRows(projectId, pdf, statusInfo);
          } else {
            return row;
          }
        })
      } else {
        rows = rows.filter(row => {
          return pdf.id !== row.id;
        })
      }
    })

    const columns = this.getColumns();
    this.handleTurnPage(rows)
    this.setState({
      init: false,
      rows: rows,
      sortColumn: '',
      sortDirection: '',
      columns,
      statusOptions,
    });
  }

  gerStatusFlag = (k, pdfStatusArr) => {
    return pdfStatusArr[k];
  }

  getPdfStatusArr = (pdfStatusValue) => {
    return pdfStatusValue.split(',');
  }

  renderStatus = (pdf, res) => {
    const text = res[pdf.stateFlag]&&res[pdf.stateFlag].text?res[pdf.stateFlag].text:defaultStatusText;
    const color = res[pdf.stateFlag]&&res[pdf.stateFlag].color
      &&(statusColor.indexOf(res[pdf.stateFlag].color)>-1) // limit color to make sure the color is valid
      ?res[pdf.stateFlag].color:defaultStatusColor;

    let dom = ''
    dom = <div className="predict-admin-search-table-status" style={{color: color}} searchvalue={text}><div className="status-circle status-width" style={{backgroundColor:color}}/>{text}</div>;
    return dom
  }

  handleRowClick = (url) => {
    const win = window.open(url);
    win.focus()
    this.context.sendMetrics({
      page_name: PageName.PredictPage,
      event_type: EventType.PageView,
      event_name: EventName.PageTransition,
      custom_parameter: {
        from :  PageName.PredictPage,
        to : PageName.PredictDetailPage,
      },
    })
  }

  pdfFilesToRows(projectId, pdf, res) {
    const { permissionFlag, isAllowFileDownload, isAllowJumpMappingReview } = this.state;
    return Object.assign({}, pdf, {
      checkbox: (<Checkbox key={pdf.id} id={pdf.id} checked={false} onChange={(e, value) => this.handleCheckBox(e, value)}/>),
      id: pdf.id,
      originalName: pdf.originalName,
      pdfName: pdf.originalName,
      createTime: pdf.uploadTime,
      uploadTime: unixMSToDatetime(pdf.uploadTime, FMT.DATETIME_LOCAL),
      state: this.renderStatus(pdf, res),
      stateFlag: pdf.stateFlag,
      creator: pdf.createdByName,
      operator: pdf.userInChargeName,
      clickEvent:  (e)=>{
        if(e.target.type !== 'checkbox' && !(res[pdf.stateFlag]&&res[pdf.stateFlag].noDetail)){
          this.handleRowClick(`/dppui/predict/detail/${projectId}/${pdf.id}`) 
        }
      } ,
      action: (
        <Dropdown item direction='left'
          id={`predict-drop-down-${pdf.id}`}
          icon={<MoreVertIcon size='24px' color='#545454' />}
          className='predict-dropDown predict-dropDown-search-size'
        >
          <Dropdown.Menu id={`predict-drop-down-menu-${pdf.id}`}>
            <Dropdown.Item onClick={() => this.handleCheckout(pdf)}>
              <PersonIcon color={'#545454'} size='24px'/>
              <span className='text'>&nbsp;担当者を設定</span>
            </Dropdown.Item>
            {isAllowJumpMappingReview && (
              <Dropdown.Item onClick={() => this.checkScrumMapsValue(projectId, pdf.id)}>
                <ReactSendLogo style={{height: "24px"}}/>
                <span className='text'>&nbsp;自動連携先の更新依頼</span>
              </Dropdown.Item>
            )}
            {isAllowFileDownload ? <Dropdown.Item>
              <PictureAsPdfIcon color={'#545454'} size='24px'/>
              <span className='text'>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ wordBreak: 'break-all' }}
                  href={`/dppapi${pdf.link}?token=${getCsrfToken()}`}
                >
                  &nbsp;PDFを別タブで開く
                </a>
              </span>
            </Dropdown.Item> : null}
            {permissionFlag &&
              <Dropdown.Item onClick={() => this.handleExportPdf(projectId, pdf.id)}>
                <DownloadIcon color={'#545454'} size='24px'/>
                <span className='text'>&nbsp;補正情報ダウンロード</span>
              </Dropdown.Item>
            }
            <Divider />
            <Dropdown.Item onClick={() => this.handleDel(pdf)}>
              <DeleteIcon color={'#545454'} size='24px'/>
              <span className='text'>&nbsp;削除</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )
    })
  }

  handleCheckBox = (e, target) => {
    const { rows, columns, selectList, activePage, rowsPageList } = this.state;
    let selected = cloneDeep(selectList);
    const pages = rowsPageList;
    if(selected && selected[target.id]){
      rows.filter(d=> d.id === target.id).forEach(d=>{
        delete selected[d.id]
        d.checkbox = (<Checkbox key={d.id} id={d.id} checked={false} onChange={(e, value) => this.handleCheckBox(e, value)}/>)
      })
    }else{
      rows.filter(d=> d.id === target.id).forEach(d=>{
        selected = { ...selected, [d.id]: [d.originalName, d.stateFlag, d.createTime, d.operator, d.userInCharge] }
        d.checkbox = (<Checkbox key={d.id} id={d.id} checked={true} onChange={(e, value) => this.handleCheckBox(e, value)}/>)
      })
    }
    let indeterFlg = false
    const selectKeys = Object.keys(selected);
    selectKeys.length > 0 && pages[activePage-1].forEach(data=>{
      if (selectKeys.indexOf(String(data.id)) === -1) {
        indeterFlg = true
      }
    })
    columns[0].label = (
      <>
      <Popup
        trigger={
          <Checkbox id="all" checked={false} indeterminate={indeterFlg} onChange={(e, {checked}) => this.handleCheckBoxAll(checked)}/>
        }
        position="top center"
        content={'表示中のファイルを一括選択/選択解除'}
        on="hover"
        wide='very'
        hideOnScroll
        inverted
      />
      </>);
    this.handleTurnPage(rows)
    this.setState({selectList: selected, rows, columns, activePage},()=>{
      this.setBottomSize(true);
    })
  }

  handleCheckBoxAll = (checked, flg=0) => {
    const { rows, columns, selectList, activePage,rowsPageList } = this.state;

    let selected = cloneDeep(selectList);
    let activeRows = rowsPageList[activePage-1]
    if(flg === 0){
      rows.forEach(d=>{
        activeRows.forEach(a=>{
          if(d.id === a.id) {
            if(checked){
              selected[d.id] = [d.originalName, d.stateFlag, d.createTime, d.operator, d.userInCharge];
            }else{
              delete selected[d.id]
            }
            d.checkbox = (<Checkbox key={d.id} id={d.id} checked={checked} onChange={(e, value) => this.handleCheckBox(e, value)}/>)
          }
        })
      })
      columns[0].label = (
        <>
        <Popup
          trigger={
            <Checkbox id="all" checked={checked} onChange={(e, { checked }) => this.handleCheckBoxAll(checked)}/>
          }
          position="top center"
          content={'表示中のファイルを一括選択/選択解除'}
          on="hover"
          wide='very'
          hideOnScroll
          inverted
        />
        </>);
      this.handleTurnPage(rows);
      this.setState({selectList: selected, rows, columns, activePage},()=>{
        this.setBottomSize(true);
      })
    }else{
      setTimeout(() => {
        this.addRowSelectedClass(activePage)
      }, 10)
      let select = {}
      rows.forEach(d=>{
        if (flg === 1) select[d.id] = [d.originalName, d.stateFlag, d.createTime, d.operator, d.userInCharge];
        d.checkbox = (<Checkbox key={d.id} id={d.id} checked={flg === 1} onChange={(e, value) => this.handleCheckBox(e, value)}/>)
      })
      columns[0].label = (
        <>
        <Popup
          trigger={
            <Checkbox id="all" checked={flg === 1} indeterminate={false} onChange={(e, { checked }) => this.handleCheckBoxAll(checked)}/>
          }
          position="top center"
          content={'表示中のファイルを一括選択/選択解除'}
          on="hover"
          wide='very'
          hideOnScroll
          inverted
        />
        </>);
      this.handleTurnPage(rows);
      this.setState({selectList: select, rows, columns},()=>{
        this.setBottomSize(true);
      })
    }
  }
  handlePageChange = (event, data) => {
    setTimeout(() => {
      this.addRowSelectedClass(data.activePage)
    }, 10)

    const { columns, selectList,rowsPageList } = this.state;
    let selected = cloneDeep(selectList);
    const pages = rowsPageList;
    let indeterFlg = false
    const selectKeys = Object.keys(selected);
    let newPageIds = pages[data.activePage-1].map(d=>{return d.id})
    selectKeys.forEach(d=>{
      if(newPageIds.indexOf(Number(d)) === -1){
        delete selected[d]
      }
    })
    const activeSelectKeys = Object.keys(selected);
    activeSelectKeys.length > 0 && pages[data.activePage-1].forEach(data=>{
      if (activeSelectKeys.indexOf(String(data.id)) === -1) {
        indeterFlg = true
      }
    })
    columns[0].label = (<>
      <Popup
        trigger={
          <Checkbox id="all" checked={activeSelectKeys.length > 0 && !indeterFlg} indeterminate={indeterFlg} onChange={(e, { checked }) => this.handleCheckBoxAll(checked)}/>
        }
        position="top center"
        content={'表示中のファイルを一括選択/選択解除'}
        on="hover"
        wide='very'
        hideOnScroll
        inverted
      />
      </>);
    this.setState({columns, activePage: data.activePage })
  }

  filterRow = (rows) => {
    const filterRows = [];
    const filterItems = ["id", "originalName", "uploadTime", "creator", "operator", "state", "stateFlag", "action", "clickEvent"]; // only these items can be searched
    rows.forEach((row) => {
      const filterRow = {};
      filterItems.forEach((filterItem) => {
        filterRow[filterItem] = row[filterItem];
        if (row.state) {
          filterRow.stateText = row.state.props.searchvalue;
        }
      })
      filterRow.checkbox = row.checkbox
      filterRows.push(filterRow);
    })
    return filterRows;
  }

  sendMetricsClickEvent = (eventName,customParameter) => {
    this.context.sendMetrics({
      page_name: PageName.PredictPage,
      event_type: EventType.Click,
      event_name: eventName,
      custom_parameter: customParameter,
    })
  }

  async handleCheckout(pdf) {
    let selectSingle = {}
    selectSingle[pdf.id] = [pdf.originalName, pdf.stateFlag, pdf.uploadTime, pdf.userInChargeName, pdf.userInCharge];
    this.setState({selectSingle: selectSingle, singleFlg: true})
    this.toggleUserSet(!this.state.isFileUserSetShow)
    this.sendMetricsClickEvent(EventName.ClickSetOperatorButton,{
      selected_file_num : SelectedFileNum.One
    })
  }

  async handleDel(pdf) {
    let selectSingle = {}
    selectSingle[pdf.id] = [pdf.originalName, pdf.stateFlag, pdf.uploadTime, pdf.userInChargeName, pdf.userInCharge];
    this.setState({selectSingle: selectSingle, singleFlg: true})
    this.toggleDelete(!this.state.isFileDeleteShow)
    this.sendMetricsClickEvent(EventName.ClickDeleteFileButton,{
      selected_file_num : SelectedFileNum.One
    })
  }

  handleExportPdf = async (projectId, pdfId) => {
    let body = []
    const { scrumMaps } = await (await api.get(`/dppapi/predict/pdf/scrum/mapping/${projectId}/${pdfId}`)).data;
    if (scrumMaps) {
      this.scrumMapsToCsvData(body, scrumMaps, pdfId);
    }
    const sufix = moment().format(FMT.TIME_STRING)
    downloadCsv(body, option, `analysis_${pdfId}_${sufix}.csv`)
  }

  handleExportPdfAll = async (projectId, rows) => {
    let body = []
    let exportList = [];
    rows.forEach(row => {
      const pdfFileId = row.id;
      if (row.stateFlag === STATE_3 || row.stateFlag === STATE_5) {
        exportList.push(new Promise((resolve => {
          api.request({
            url: `/dppapi/predict/pdf/scrum/mapping/${projectId}/${pdfFileId}`,
            method: 'GET'
          }).then(({ data }) => {
            resolve(data)
          })
        })))
      }
    })
    Promise.all(exportList).then(rst => {
      rst.forEach(tmp => {
        const { scrumMaps } = tmp;
        if (scrumMaps) {
          this.scrumMapsToCsvData(body, scrumMaps, tmp.pdfFileId);
        }
      })
      const sufix = moment().format(FMT.TIME_STRING)
      downloadCsv(body, option, `analysis_search_${sufix}.csv`)
    })
  }

  handleLoading(editLoading) {
    this.setState({
      editLoading: editLoading
    })
    editLoading && this.setState({
      toolbarFlg: editLoading
    })
  }

  scrumMapsToCsvData = (body, scrumMaps, pdfId) => {
    var csvData = [];
    Object.keys(scrumMaps).forEach(tableName => {
      if (tableName === 'manual_input') return;
      var newTableList = scrumMaps[tableName].filter(table => {
        if ((table.scrumItem || (table.value && table.value.text))
          && table.scrumItem !== table.originalItem) {
          return true;
        } else {
          return false;
        }
      });
      newTableList.forEach(table => {
        csvData.push({
          "pdfId": pdfId,
          "pageName": tableName,
          "subtitle": table.subTitle.text,
          "item": table.item.text,
          "value": table.value.text,
          "scrumItem": table.scrumItem,
          "originalItem": table.originalItem || '',
        });
      })
    })
    
    const manualInput = scrumMaps['manual_input'];
    var newCsvData = csvData;
    manualInput && Object.keys(manualInput).forEach(pageName => {
      Object.keys(manualInput[pageName]).forEach(item => {
        if (!manualInput[pageName][item]) return;
        newCsvData = newCsvData.filter(data => {
          if (data.scrumItem !== item) {
            return true;
          } else {
            return false;
          }
        });
        newCsvData.push({
          "pdfId": pdfId,
          "pageName": pageName,
          "subtitle": '',
          "item": '',
          "value": manualInput[pageName][item],
          "scrumItem": item,
          "originalItem": '',
        })
      })
    })

    newCsvData.forEach(csvData => {
      if (csvData.scrumItem.indexOf('B/S') !== -1) return;
      var row = [];
      row.push(
        csvData.pdfId,
        csvData.pageName,
        csvData.subtitle,
        csvData.item,
        csvData.value,
        csvData.scrumItem,
        csvData.originalItem,
      );
      body.push(row);
    })
    return body;
  }

  sortRows = (value) => {
    let {rows, sortColumn, sortDirection} = this.state;
    let {column} = value;

    const dir = column === sortColumn ? sortDirection==='asc'? 'desc' : 'asc' : 'desc';
    if (column === 'operator') {
      if (dir === 'asc') {
        rows.sort(function(a, b) { return !a.operator && a.operator!==0 && b.operator ? 1 : a.operator && !b.operator && b.operator!==0 ? -1 : a.operator < b.operator ? 1 : -1;} );
      } else {
        rows.sort(function(a, b) { return !a.operator && a.operator!==0 && b.operator ? -1 : a.operator && !b.operator && b.operator!==0 ? 1 : a.operator > b.operator ? 1 : -1;} );
      }
    }else if(column === 'state'){
      if (dir === 'asc') {
        rows.sort(function(a, b) { return a.state.props.searchvalue < b.state.props.searchvalue ? 1 : -1;} );
      } else {
        rows.sort(function(a, b) { return a.state.props.searchvalue > b.state.props.searchvalue ? 1 : -1;} );
      }
    }else if(column){
      if (dir === 'asc') {
        rows.sort(function(a, b) { return a[column] < b[column] ? 1 : -1;} );
      } else {
        rows.sort(function(a, b) { return a[column] > b[column] ? 1 : -1;} );
      }
    }else{
      rows.sort(function(a, b) { return a.id< b.id ? 1 : -1;} );
    }
    const columns = this.getColumns(column,dir);
    if(column){
      this.handleTurnPage(rows);
      this.setState({
        rows: rows,
        sortColumn: column,
        sortDirection: dir,
        columns
      })
    }else{
      this.setState({
        columns
      })
    }
  }

  getColumns = (column='', dir='') =>{
    const columns = [
      {
        label: (<>
          <Popup
            trigger={
              <Checkbox id="all" checked={false} indeterminate={false} onChange={(e, { checked }) => this.handleCheckBoxAll(checked)} />
            }
            position="top center"
            content={'表示中のファイルを一括選択/選択解除'}
            on="hover"
            wide='very'
            hideOnScroll
            inverted
          />
          </>),
        field: 'checkbox',
        sort: 'disabled',
        width: 30,
      },
      {
        label: 'ID',
        field: 'id',
        sort: this.getSortDirection('id',column, dir ),
        width: 100,
      },
      {
        label: intl.get('_predict.admin.PredictAdmin.Filename'),
        field: 'originalName',
        sort: this.getSortDirection('originalName',column, dir ),
        width: 200,
      },
      {
        label: intl.get('_predict.admin.PredictAdmin.Uploadtime'),
        field: 'uploadTime',
        sort: this.getSortDirection('uploadTime',column, dir ),
        width: 150,
      },
      {
        label: intl.get('_predict.admin.PredictAdmin.Author'),
        field: 'creator',
        sort: this.getSortDirection('creator',column, dir),
        width: 150,
      },
      {
        label: intl.get('_predict.admin.PredictAdmin.Personincharge'),
        field: 'operator',
        sort: this.getSortDirection('operator',column, dir),
        width: 150,
      },
      {
        label: intl.get('_predict.admin.PredictAdmin.Processingstate'),
        field: 'state',
        sort: this.getSortDirection('state',column, dir),
        width: 150,
      },
      {
        label: intl.get('_predict.admin.PredictAdmin.Operation'),
        field: 'action',
        sort: 'disabled',
        width: 150,
      },
    ];
    return columns;
  }

  getSortDirection = (column, sortColumn, sortDirection) => {
    const { rows } = this.state;
    if(!rows){
      return 'asc';
    }
    return !sortColumn? 'asc' : (sortColumn===column ? sortDirection : '');
  }

  handleComplete(flg, activePage) {
    flg === '1' && this.setState({
      deleteMsgShow: true,
    });
    flg === '2' && this.setState({
      userSetMsgShow: true,
    });
    this.setState({
      toolbarFlg: false,
      activePage
    },);
    setTimeout(()=>{
      this.setState({
        deleteMsgShow: false,
        userSetMsgShow: false,
        activePage
      });
    },4000)
  }

  toggleDelete(isFileDeleteShow) {
    const activePage = this.state.activePage;
    this.setState({
      isFileDeleteShow: isFileDeleteShow,
      isFileUserSetShow: false,
      activePage
    }, ()=>{
      this.setBottomSize(true)
    });
  }

  toggleUserSet(isFileUserSetShow) {
    const activePage = this.state.activePage;
    this.setState({
      isFileUserSetShow: isFileUserSetShow,
      isFileDeleteShow: false,
      activePage
    }, ()=>{
      this.setBottomSize(true)
    });
  }

  handleTurnPage(rows){
    const { entries, activePage } = this.state;
    var rowsPageList = []
    var rowsPage = []
    var rowsLength = rows.length
    var activePageState = activePage
    if(rows.length > entries){
      for(var row of rows){
        if(rowsPage.length < entries){
          rowsPage.push(row)
        }else{
          rowsPageList.push(rowsPage)
          rowsPage = []
          rowsPage.push(row)
        }
      }
      rowsPageList.push(rowsPage)
    }else{
      rowsPageList.push(rows)
    }
    if(rowsPageList.length < activePageState){
      activePageState = 1
      this.setState({})
    }
    this.setState({
      rowsPageList,
      rowsLength,
      activePage: activePageState
    });
  }
  setActivePage(activePage){
    this.setState({activePage});
  }

  tableSearch(e){
    const { rows } = this.state;
    e.preventDefault();
    let value = e.target.value
    const searchList = rows.filter(row =>{
      row.id.toString()
      return row.id.toString().indexOf(value) !== -1 ||
      (row.originalName && row.originalName.indexOf(value) !== -1) ||
      (row.uploadTime && row.uploadTime.indexOf(value) !== -1) ||
      (row.creator && row.creator.indexOf(value) !== -1) ||
      (row.operator && row.operator.indexOf(value) !== -1) ||
      (row.state && row.state.props.searchvalue.indexOf(value) !== -1)
    })
    this.setState({activePage : 1 }, ()=>{this.handleTurnPage(searchList)})
  }

  handleFilterScrumData = (scrumMaps, pdfId, pdfInfo) => {
    var csvData = [];
    Object.keys(scrumMaps).forEach(tableName => {
      if (tableName === 'manual_input') return;
      var newTableList = scrumMaps[tableName].filter(table => {
        if ((table.scrumItem || (table.value && table.value.text)) &&
          table.scrumItem !== table.originalItem &&
          table.scrumItem.indexOf('B/S') === -1) {
          return true;
        } else {
          return false;
        }
      });

      newTableList.forEach(table => {
        const category = pdfInfo.images.find(item => item.id === table.pdfImageId).pageClassification;
        //scrum json 変更したら、レコード非表示
        if ((category === tableName) ||
          (["販売費及び一般管理費", "製造原価明細表"].includes(category) && tableName === "損益計算書")) {
          csvData.push({
            ...table,
            "pdfId": pdfId,
            "pageName": category
          });
        }
      })
    })

    //subtitleあり、itemあり（全帳票）｜ subtitleなし、itemあり（CR・SGAのみ）
    return csvData.filter(data => {
      if (!data.originalItem && !data.scrumItem) {
        return false;
      }
      if (!data.subTitle.text) {
        if (!data.item.text || !["販売費及び一般管理費", "製造原価明細表"].includes(data.pageName)) {
          return false;
        }
        return true;
      }
      return true;
    });
  }

  checkScrumMapsValue = async (projectId, pdfId) => {
    let body = [];
    const { scrumMaps } = await (await api.get(`/dppapi/predict/pdf/scrum/mapping/${projectId}/${pdfId}`)).data;
    const pdfInfo = await (await api.get(`/dppapi/predict/${projectId}/pdfs/${pdfId}`)).data;
    if (scrumMaps && pdfInfo) {
      body = this.handleFilterScrumData(scrumMaps, pdfId, pdfInfo);
    }
    
    if(body.length > 0) {
        const baseUrl = window.location.origin;
        window.open(`${baseUrl}/dppui/predict/mapping-request/${projectId}/${pdfId}`);
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="confirm-alert-wrapper">
              <h2><ReactMdiErrorLogo style={{height: "32px", verticalAlign: "-7px", marginRight: "8px"}}/>更新無し</h2>
              <p>マッピング結果が更新されたことがありません。</p>
              <div><Button onClick={() => onClose()}>OK</Button></div>
            </div>
          )
        }
      })
    }
  }

  checkIsShow = async () => {
    const conditions = {
      category: 'configure',
      subCategory: 'nodejs',
      name: 'mapping_review_page',
      subName: 'mapping_review_page',
    }
    const res = (await api.post(
      '/dppapi/predict/system/preference/query', { conditions }
    )).data;

    let isAllowJump = 0;
    if (res.success) {
      isAllowJump = Number(res.value);
    }

    this.setState(({isAllowJumpMappingReview: isAllowJump === 1 ? true : false}))
  }

  render() {

    const { rows, init, isLoading, users, userList, columns, selectList, selectSingle, singleFlg,
      statusTexts, deleteMsgShow, userSetMsgShow, editLoading, toolbarFlg, initStyle, statusOptions, 
      rowsPageList, activePage, menuBoxBottomHeight, rowsLength, entries } = this.state;

    const { projectId } = this.props;
    const data = {
      columns: columns,
      rows: this.filterRow(rowsPageList[activePage-1]?rowsPageList[activePage-1]:rowsPageList)
    }
    const searchHeight = document.body.clientHeight - 45 - 40 - 16 - menuBoxBottomHeight;
    const selectedCount = Object.keys(selectList).length;
    const rowsPageStart = rows.length > 0 ? entries *  (activePage - 1) + 1 : 0;
    const rowsPageEnd = rows.length > 0 ? entries *  (activePage - 1) + rowsPageList[activePage-1].length : 0;
    var result = null;
    this.addDropDownStyle(data.rows);
    if (isLoading) {
      result = (<Loader active inline="centered" />)
    } else if (rows.length > 0) {
      result = (<div id='predict-search-table' className="predict-admin-search-table-panel predict-table">
        {editLoading && <div className="table-loader-center"><Loader active inline="centered" /></div>}
        <div className='row dataTables_wrapper'>
          <div data-test="datatable-search" className="col-sm-12 col-md-6">
            <div data-test="datatable-input" className="dataTables_filter">
              <label>絞り込み
                <input type="search" className="form-control form-control-sm" placeholder="キーワード" 
                onChange={this.tableSearch}/>
              </label>
            </div>
          </div>
          <div data-test="datatable-entries" className="col-sm-12 col-md-6">
            <div className="dataTables_length bs-select">
              <label>表示件数  
                <select className="custom-select custom-select-sm form-control form-control-sm" style={{marginLeft:'8px'}} onChange={(e)=>this.handleSelectChange(e)}  key={entries} defaultValue={entries}>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </label>
            </div>
          </div>
        </div>
        {columns ? <MDBDataTable
          ref={this.tableRef}
          theadColor="predict-admin-search-table-header"
          tbodyColor="predict-admin-search-table-body"
          borderless
          small
          data={data}
          searching={false}
          paging={false}
          className="table-col-sm-12"
          noRecordsFoundLabel="該当データがありません。条件を変更の上、再度検索してください。"
          onSort={value => this.sortRows(value)}
        /> : null}
      </div>)
    } else {
      result = (
        <div id='predict-search-table' className="predict-admin-search-table-panel">
          {editLoading && <div className="table-loader-center"><Loader active inline="centered" /></div>}
          <Message
            className="noDataMessage"
            info
            header='該当データがありません。 '
            content="条件を変更の上、再度検索してください。"
          />
        </div>
      )
    }
    return (
      <React.Fragment>
        <div className="predict-search-div-height" style={{ maxHeight: searchHeight + 'px' }}>
        <Conditions
          users={users}
          managers={_.concat([{ key: 0, value: 0, text: '担当者なし' }], users)}
          onSearch={this.onSearch}
          projectId={projectId}
          ref={this.conditionRef}
          getProjectMessage = {this.getProjectMessage}
          editLoading={editLoading}
          statusOptions={statusOptions}
        />
        <div id="page" style={{flex: 1, font: 'normal normal bold 12px/18px Noto Sans JP'}}>
          {!init ? result :
            <div id='predict-search-table' className="predict-admin-search-table-panel">
              {editLoading && <div className="table-loader-center"><Loader active inline="centered" /></div>}
            </div>
          }
          <Slide direction="up" in={userSetMsgShow}>
            <div className="file-setting-success">担当者の設定が適用されました</div>
          </Slide>
          <Slide direction="up" in={deleteMsgShow}>
            <div className="file-delete-success">ファイルの削除が完了しました</div>
          </Slide>
          {/* <div style={{ position: 'absolute', width: '400px', border: 'solid red', height: '5px', right: '0', marginTop: '4px' }}></div> */}
        </div>
        </div>
        {!isLoading && rows.length > 0 && <div className="datatable-info-pagination" style={initStyle}>
          <div className="datatable-info-style">
            {rowsLength === 0 ? <div role="status" aria-live="polite"> {rowsLength} 件</div>:
            <div role="status" aria-live="polite"> {rowsPageStart} - {rowsPageEnd} / {rowsLength} 件</div>
            }
          </div>
          <Pagination
            //size="mini"
            className="datatable-pagination-style"
            secondary
            activePage={activePage}
            totalPages={typeof rowsPageList.length === 'undefined' ? 1 : rowsPageList.length}
            onPageChange={this.handlePageChange}
            boundaryRange={0}
            siblingRange={4}
            ellipsisItem={null}
            firstItem={{ content: <KeyboardDoubleArrowLeftIcon color={'#545454'} size='24px' />, icon: true }}
            lastItem={{ content: <KeyboardDoubleArrowRightIcon color={'#545454'} size='24px' />, icon: true }}
            prevItem={{ content: <MdiChevronLeftIcon color={'#545454'} size='24px' />, icon: true }}
            nextItem={{ content: <MdiChevronRightIcon color={'#545454'} size='24px'/>, icon: true }}
          />
          { !toolbarFlg &&
            !this.state.isFileUserSetShow &&
            !this.state.isFileDeleteShow &&
            <Slide direction="up" in={selectedCount > 0}>
              <div id="list-action-menu" className="predict-admin-list-action-menu">
              <div className="predict-admin-list-action-menu-label">{selectedCount} 件
              <span className="predict-admin-list-action-menu-label-detail">選択中</span>
                <Button
                  id="ga-predict-list-auther-setting"
                  size="medium"
                  onClick={() => {
                    this.setState({singleFlg: false});
                    this.toggleUserSet(!this.state.isFileUserSetShow);
                    this.sendMetricsClickEvent(EventName.ClickSetOperatorButton,{
                      selected_file_num : SelectedFileNum.Multiple
                    })
                }}
                  className="predict-admin-botton-text predict-admin-userOption">
                    <div className="btn-content-wrap">
                      <PersonIcon color={'#545454'} size='24px'/>
                      <div className="btn-text">担当者を設定</div>
                    </div>
                </Button>
                <Button
                  id="ga-predict-list-delete"
                  size="medium"
                  onClick={() => {
                    this.setState({singleFlg: false});
                    this.toggleDelete(!this.state.isFileDeleteShow);
                    this.sendMetricsClickEvent(EventName.ClickDeleteFileButton,{
                      selected_file_num : SelectedFileNum.Multiple
                    })
                  }}
                  className="predict-admin-botton-text predict-admin-userOption">
                    <div className="btn-content-wrap">
                      <DeleteIcon color={'#545454'} size='24px'/>
                      <div className="btn-text">削除</div>
                    </div>
                </Button>
              </div>
              <div className="predict-admin-list-action-menu-link">
                <Link to="#"
                  onClick={(e) => {
                    this.handleCheckBoxAll(true, 1)}
                  }
                  >
                  <u className="predict-admin-list-action-menu-link-u">{rows.length} 件 すべて選択</u>
                </Link>
                <Link to="#"
                  onClick={(e) => {
                    this.handleCheckBoxAll(true, 2)}
                  }
                  >
                  <u className="predict-admin-list-action-menu-link-u">選択解除</u>
                </Link>
              </div>
            </div>
          </Slide>}
        </div>}
        
        <FileListDelete
          isShowEdit={this.state.isFileDeleteShow}
          toggleEdit={this.toggleDelete}
          selectList={singleFlg ? selectSingle : selectList}
          refetch={this.handleListRefetch}
          onCompleted={this.handleComplete}
          onLoading={this.handleLoading}
          projectId={projectId}
          statusTexts={statusTexts}
        />
        <FileListUserSet
          isShowEdit={this.state.isFileUserSetShow}
          toggleEdit={this.toggleUserSet}
          selectList={singleFlg ? selectSingle : selectList}
          refetch={this.handleListRefetch}
          onCompleted={this.handleComplete}
          onLoading={this.handleLoading}
          projectId={projectId}
          users={userList}
          activePage={activePage}
          setActivePage={this.setActivePage}
        />
      </React.Fragment>
    );
  }
}
