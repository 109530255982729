/* 1, AI処理待ち, 2, AI処理中, 3, ユーザ確認待ち, 4, エラー, 5, ユーザ確認完了 */
const stateFlag = {
  1: 'AI読取待ち',
  2: 'AI読取中',
  3: '編集可能',
  4: 'エラー',
  5: 'ダウンロード済み'
}

  const deleteFlag = {
    1: '1',
    0: '0'
  }
  
  export function getOptions() {
    const options = [];
    Object.keys(stateFlag).forEach(k => options.push({ key: k, value: k, text: stateFlag[k] }))
    return options
  }

  export function getDeleteOptions() {
    const options = [];
    Object.keys(deleteFlag).forEach(k => options.push({ key: k, value: k, text: deleteFlag[k] }))
    return options
  }
  
  export function getStateFlag(k) {
    return stateFlag[k]
  }


