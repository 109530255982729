import React from 'react'
import { Button, Form, Segment, Icon, Dropdown, Loader, Popup } from 'semantic-ui-react'
import intl from 'react-intl-universal';
import { generatePassword } from './Util'

export default class SearchForm extends React.Component {

  handleInput = (event) => {
    const { name, value } = event.target;
    const form = {};
    form[name] = value;
    this.props.setForm(form);
  }

  render() {

    const options =this.props.roles.map(role => {return {key: role.id, text: role.name, value: role.id}});
    const {loading, form } = this.props;
    return (
      <Segment>
        {loading 
          ? ( <Loader active inline="centered"/>) 
          : (<Form>
              <Form.Field>
                <Popup
                  trigger={ <Button.Group floated="right" style={{ marginLeft: '2px' }}>
                    <Button icon onClick={this.props.handleExport}>
                      <Icon name="download" />
                    </Button>
                  </Button.Group>}
                  content={'ユーザーリストをダウンロード'}
                  position="top center"
                  mouseEnterDelay={500}
                  hoverable 
                  inverted
                  >
                </Popup>
                <Popup
                  trigger={ <Button.Group floated="right" style={{ marginLeft: '2px' }}>
                    <Button icon onClick={this.props.handleImport}>
                      <Icon name="file" />
                    </Button>
                  </Button.Group>}
                  content={'ユーザーを一括追加（CSVインポート）'}
                  position="top center"
                  mouseEnterDelay={500}
                  hoverable 
                  inverted
                  >
                </Popup>
                <Popup
                  trigger={ <Button.Group floated='right' style={{ marginLeft: '2px' }}>
                    <Button icon onClick={() => this.props.handleEdit({
                      accessKey: Math.random().toString().slice(-6),
                      pwd: generatePassword(6)
                    })}>
                      <Icon name='add' />
                    </Button>
                  </Button.Group>}
                  content={'ユーザーを追加'}
                  position="top center"
                  mouseEnterDelay={500}
                  hoverable 
                  inverted
                  >
                </Popup>
              </Form.Field>
              <Form.Field>
                <label>{intl.get('_user.user.User.Name')}</label>
                <Form.Input
                  name="name"
                  defaultValue={form.name}
                  placeholder={intl.get('_user.user.User.Name')}
                  onChange={(event) => this.handleInput(event)}
                />
              </Form.Field>
              <Form.Field>
                <label>{intl.get('_user.user.User.E-mail')}</label>
                <Form.Input
                  name="email"
                  defaultValue={form.email}
                  placeholder={intl.get('_user.user.User.E-mail')}
                  onChange={(event) => this.handleInput(event)}
                />
              </Form.Field>
              <Form.Field>
                <label>{intl.get('_user.user.User.Role')}</label>
                <Dropdown
                  className='dropdown-place-color'
                  clearable
                  options={options}
                  defaultValue={form.roleId}
                  placeholder={intl.get('_user.user.User.Role')}
                  selection
                  onChange={(e, change) => this.props.setForm({roleId: change.value})}
                />
              </Form.Field>
              <Button size="small" onClick={() => this.props.handleSearch(true)}>
                <Icon name="search" />
                {intl.get('_user.user.User.Search')}
              </Button>
            </Form>)}
      </Segment>
    );
  }
}