const stateFlag = {
  0: 'init',
  1: 'submitted',
  2: 'training',
  3: 'succeed',
  4: 'failed',
  5: 'stopping',
  6: 'stopped',
  7: 'deploying',
  8: 'deployed',
}

export function getOptions() {
  const options = [];
  Object.keys(stateFlag).forEach(k => options.push({ key: k, value: k, text: stateFlag[k] }))
  return options
}

export function getStateFlag(k) {
  return stateFlag[k]
}
