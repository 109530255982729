import React from 'react';
import { withRouter } from 'react-router-dom';
import api from '../../api';
import intl from 'react-intl-universal';

import {
  Button,
  Form,
  Input,
  Header,
} from 'semantic-ui-react';
import { ErrorIcon} from '../../assets/icon';
import Hotkeys from 'react-hot-keys';
class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: {
        pwd: '',
        newPwd: '',
        reNewPwd: '',
      },
      errors: {},
      msgs: {},
      tenantPreference: { password_rule: null },
      passRuleMatch: false,
    };

    this.handleInput = this.handleInput.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
    this.clearMsgs = this.clearMsgs.bind(this);
    this.checkForm = this.checkForm.bind(this);
  }
  async componentDidMount() {
    await this.handleGetTenantPreference({
      category: 'configure',
      subCategory: 'nodejs',
      name: 'password',
      subName: 'rule',
    });
    await this.handleGetTenantPreference({
      category: 'configure',
      subCategory: 'nodejs',
      name: 'password',
      subName: 'rule_tip',
    });
  }

  handleGetTenantPreference = async param => {
    const res = await api.request({
      url: `/dppapi/retrieve/tenant-preferences`,
      method: 'POST',
      data: param,
    });
    if (res.data.success) {
      this.setState({
        tenantPreference: {
          ...this.state.tenantPreference,
          [`${param.name}_${param.subName}`]: res.data.value,
        },
      });
    }
  };

  async handleSave() {
    const password = this.state.password;

    if (!this.checkForm(password)) {
      return;
    }

    api.put(
      `/dppapi/password/user/reset`,
      JSON.stringify({
        pwd: password.pwd,
        newPwd: password.newPwd,
      }),
      {headers:{
        'Content-Type': 'application/json',
      }},

    ).then((r) => {
      const { url } = r.data
      localStorage.setItem("url", url+"?confirmFlag=1")
      // window.location = url
      this.props.history.push(url+"?confirmFlag=1")

    }).catch((r) => {
      const { msg } = r.data
      this.setState({
        msgs: {
          pwd: {
            content: msg
          }
        }
      })
    })
  }

  checkForm(password) {
    const errors = {};
    if (!password.pwd) {
      errors['pwd'] = {
        content: intl.get('_user.user.User.Input password'),
        pointing: 'below',
        flag: true,
      };
    }

    if (!password.newPwd) {
      errors['newPwd'] = {
        content: intl.get('_user.user.User.Input newPwd'),
        pointing: 'below',
        flag: true,
      };
    }

    if (!password.reNewPwd) {
      errors['reNewPwd'] = {
        content: intl.get('_user.user.User.Input reNewPwd'),
        pointing: 'below',
        flag: true,
      };
    }

    if (password.reNewPwd !== password.newPwd) {
      errors['reNewPwd'] = {
        content: intl.get('_user.user.User.ReNewPwd error'),
        pointing: 'below',
        flag: true,
      };
    }
    if (this.state.tenantPreference['password_rule']) {
      if (!this.passCheck(password.newPwd)) {
        errors['newPwd'] = {
          content: intl.get('_user.user.User.NewPwd error'),
          pointing: 'below',
          flag: true,
        };
      }
    }

    this.setState({
      errors: errors,
      msgs: {}
    });
    return Object.keys(errors).length === 0;
  }

  passCheck = pass => {
    let passReg;
    try {
      passReg = new RegExp(this.state.tenantPreference['password_rule']);
    } catch (error) {
      console.log(error);
      return false;
    }
    return passReg.test(pass)
  }

  handleFocus() {
    this.clearErrors();
    this.clearMsgs();
  }

  clearErrors() {
    this.setState({
      errors: {},
    });
  }

  clearMsgs() {
    this.setState({
      msgs: {},
    });
  }

  handleInput(event) {
    const { name, value } = event.target;
    const password = { ...this.state.password };
    password[name] = value;

    if (name === 'newPwd' && this.state.tenantPreference['password_rule']) {
      const match = this.passCheck(value);
      this.setState({ passRuleMatch: match });
    }
    this.setState({
      password: password,
    });
  }
  onKeyDown(keyName, e) {
    if(keyName === 'tab'){
      e.preventDefault();
      const currentTagName = document.activeElement.tagName.toLowerCase();
      const currentElementName = document.activeElement.getAttribute("name");
      const parentElement = document.getElementsByClassName("login-form-confirm");
      let inputElement = parentElement && parentElement[0].querySelectorAll("input");
      let buttonElement = parentElement && parentElement[0].querySelectorAll("button");
      if(currentTagName === "body"){
        inputElement[0].focus()
      }else if(currentTagName === "input"){
        if(currentElementName === "pwd"){
          inputElement[1].focus()
        }else if(currentElementName === "newPwd"){
          inputElement[2].focus()
        }else if(currentElementName === "reNewPwd"){
          buttonElement[0].focus()
        }
      }else if(currentTagName === "button"){
        inputElement[0].focus()
      }
    }
  }

  render() {
    const { errors, msgs } = this.state;
    if(document.getElementsByClassName('login-container-confirm').length > 0){
      document.getElementsByClassName('login-container-confirm')[0].parentNode.classList.remove('container')
    }
    var pwdErrorClass = errors['pwd'] && errors['pwd']['flag'] ? "input-error" : ""
    var newPwdErrorClass = errors['newPwd'] && errors['newPwd']['flag'] ? "input-error" : ""
    var reNewPwdErrorClass = errors['reNewPwd'] && errors['reNewPwd']['flag'] ? "input-error" : ""
    return (
      <div className="login-container-confirm">
        <Hotkeys
          keyName="tab"
          onKeyDown={this.onKeyDown.bind(this)}
        />
        <Form className="login-form-confirm">
          <Header className="confirm-from-header">
            {intl.get('_user.user.User.PasswordReset')}
          </Header>
          <div className="confirm-form-input-div">
            <label className="confirm-form-input-label">{intl.get('_user.user.User.NowPassword')}</label>
            <Form.Field style={{ width: '100%', display: 'inline-block', margin: 'unset' }}>
              <Input
                type="password"
                onChange={this.handleInput}
                onFocus={this.handleFocus}
                name="pwd"
                className={`${pwdErrorClass}`}
              />
            </Form.Field>
            <p className="confirm-form-single-error">{errors['pwd'] ? errors['pwd'].content:null}</p>
          </div>
          <div className="confirm-form-input-div">
            <label className="confirm-form-input-label">{intl.get('_login.LoginPage.NewPassword')}</label>
            <Form.Field style={{ width: '100%', display: 'inline-block', margin: 'unset' }}>
              <Input
                type="password"
                onChange={this.handleInput}
                onFocus={this.handleFocus}
                name="newPwd"
                className={`${newPwdErrorClass}`}
              />
            </Form.Field>
            <p className="confirm-form-single-error">{errors['newPwd'] ? errors['newPwd'].content:null}</p>
            {this.state.tenantPreference['password_rule'] &&
              this.state.tenantPreference['password_rule_tip'] &&
              <p className="confirm-from-text">
                {this.state.tenantPreference['password_rule_tip']}{' '}
              </p>}
          </div>
          <div className="confirm-form-input-div">
            <label className="confirm-form-input-label">{intl.get('_login.LoginPage.RetypeNewPassword')}</label>
            <Form.Field style={{ width: '100%', display: 'inline-block', margin: 'unset' }}>
              <Input
                type="password"
                onChange={this.handleInput}
                onFocus={this.handleFocus}
                name="reNewPwd"
                className={`${reNewPwdErrorClass}`}
              />
            </Form.Field>
            <p className="confirm-form-single-error">{errors['reNewPwd'] ? errors['reNewPwd'].content:null}</p>
          </div>
          <div>
            <div className="confirm-form-button">
              <Button fluid
                className="login-btn primary-btn confirm-form-button-margin"
                onClick={this.handleSave}>{intl.get('_login.LoginPage.Submit')}</Button>
            </div>
            {Object.keys(msgs).length > 0  && <div className="confirm_icon_div">
              <ErrorIcon size="24px" color="#F26868" className="repredict-icon-cursor"/>
              <label htmlFor='error' className="reset-password-error-lable">
                  <span className='error-text'>{msgs['pwd'].content}</span>
              </label>
            </div>}
          </div>
        </Form>
      </div>
    );
  }
}

export default withRouter(ResetPasswordForm);
