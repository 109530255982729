import React from 'react';

import { MDBModal, MDBModalBody } from 'mdbreact';
import {
  Button,
  Form,
  Message
} from 'semantic-ui-react';

import api from '../api'
import intl from 'react-intl-universal';

export default class ProjectAdd extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      project: {},
      errors: {}
    }

    this.handleFocus = this.handleFocus.bind(this)
    this.clearErrors = this.clearErrors.bind(this)
    this.clearProject = this.clearProject.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.clearAll = this.clearAll.bind(this)
    this.handleInput = this.handleInput.bind(this)
  }

  handleFocus() {
    this.clearErrors()
  }

  clearErrors() {
    this.setState({
      errors: {}
    })
  }

  clearProject() {
    this.setState({
      project: {}
    })
  }

  clearAll() {
    this.setState({
      errors: {},
      project: {}
    })
    this.props.toggleEdit({isShowEdit: false})
  }

  handleInput(event) {
    const {name, value} = event.target
    const project = {...this.state.project}
    project[name] = value
    this.setState({
      project: project
    })
  }

  async handleSave() {
    const project = Object.assign({}, this.props.project, this.state.project)

    try {
      await api.post('/dppapi/projects', project);
      this.clearAll()
      this.props.successCallback()
    } catch (error) {
      const { msg } = error.data || {};
      this.setState({
        errors: msg
      });
    }

  }

  render() {
    const { isShowEdit } = this.props
    const { errors } = this.state
    return (
      <MDBModal isOpen={isShowEdit} toggle={(flag) => {this.clearErrors(); this.props.toggleEdit({isShowEdit: flag})}}>
        <MDBModalBody>
        {
          Object.keys(errors).length > 0 &&
          <Message
            error
            list={ Object.keys(errors).map(i => {
              return errors[i].content
            })}
          />
        }
          <Form>
            <Form.Field>
              <label>{intl.get('_project.ProjectAdd.Project Name')}</label>
              <Form.Input placeholder="New Project" name="name" onChange={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            <Form.Field>
              <label>{intl.get('_project.ProjectAdd.Project Description')}</label>
              <Form.TextArea placeholder="Brief Description" name="description" onChange={this.handleInput} onFocus={this.handleFocus} rows="10"/>
            </Form.Field>
            <Button floated='right' onClick={this.handleSave}>{intl.get('_project.ProjectAdd.Save')}</Button>
            <Button floated='right' onClick={this.clearAll}>{intl.get('_project.ProjectAdd.Cancel')}</Button>
          </Form>
        </MDBModalBody>
      </MDBModal>
    )
  }
}