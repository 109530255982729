import React, { Component } from 'react';
import { Header } from 'semantic-ui-react';
import SettingSearch from './search'
import intl from 'react-intl-universal';
import ImportPreferences from './importPreferences';
import ProjectManagementTable from '../../../project/ProjectManagementTable';

export default class ProjectsSettings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      init: true,
      isLoading: false,
      isShowEdit: false,
      isShowImport: [false, ''],
      editTenant: {},
      searchParam: null,
      checkTenant:{},
      errors: {},
      isError: false,
    }

    this.onSearch = this.onSearch.bind(this)
    this.refetch = this.refetch.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleImport = this.handleImport.bind(this);
    this.toggleImport = this.toggleImport.bind(this);
    this.checkForms = this.checkForms.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.clearErrors = this.clearErrors.bind(this)

    this.selectedRowsRef = React.createRef();
    this.selectedRowsRef.current = [];
  }

  handleImport(checkTenant, type) {
    if (this.state.tenantId && this.selectedRowsRef.current.length > 0) {
      this.toggleImport({
        isShowImport: [true, type]
      })
    } else {
      this.checkForms(checkTenant)
    }
  }

  toggleImport({ isShowImport }) {
    this.setState({
      isShowImport: isShowImport
    });
  }

  async onSearch(tenantId) {

    this.setState({
      isLoading: true
    })
    await this.refetch(tenantId);
  }

  async refetch(tenantId) {
    this.setState({
      init: false,
      isLoading: false
    })
  }

  checkForms(tenant) {
    const errors = {}
    if (!tenant.name) {
      if (!this.state.tenantId) {
        errors['tenantsName'] = { content: 'Please select a tenants name.', pointing: 'below', flag: true }
        this.setState({
          errors: errors,
          isError: true
        })
      } else if (this.selectedRowsRef.current.length === 0) {
        errors['tenantsName'] = { content: 'Please select at least one project.', pointing: 'below', flag: true }
        this.setState({
          errors: errors,
          isError: false
        })
      }
    }

    return Object.keys(errors).length !== 0
  }


  handleFocus() {
    this.clearErrors()
  }

  clearErrors() {
    this.setState({
      errors: {},
      isError: false
    })
  }

  onChange = (value) => {
    this.setState({ tenantId: value });
  };

  render() {
    return (
      <React.Fragment>
        <div style={{ display: 'flex' }}>
          <div className="ui" style={{ flex: 1 }}>
            <div id="search-pdf" style={{ padding: '2em 0' }}>
              <Header disabled>{intl.get('_system.tenants.setting.Index.Filtering')}</Header>
              <SettingSearch
                onSearch={this.onSearch}
                onChange={this.onChange}
                tenantId={this.state.tenantId}
                handleImport={this.handleImport}
                isError={this.state.isError}
                handleFocus={this.handleFocus}
                errors={this.state.errors}
              />
            </div>
            <ProjectManagementTable
              selectedRowsRef={this.selectedRowsRef}
              tenantId={this.state.tenantId}
              isSystemManager={true}
              handleFocus={this.handleFocus}
            />
            <ImportPreferences
              selectedRowsRef={this.selectedRowsRef}
              tenantId={this.state.tenantId}
              isShowImport={this.state.isShowImport}
              toggleImport={this.toggleImport}
              getPreferences={this.refetch} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}