export default {
    '_common': {
        'Download': 'Download',
        'Delete': 'Delete',
        'Confirm': 'Confirm',
        'Save': 'Save',
        'Cancel': 'Cancel',
        'Error': 'Error',
        'Menubar': {
            'Project': 'Project',
            'Label': 'Label',
            'Demo': 'Demo',
            'Predict': 'Predict',
            'User': 'User',
            'Train': 'Train',
            'System': 'System',
            'EasyOcr': 'EasyOcr',
            'TenantSetting':'TenantSetting',
            'Help': 'Help',
            'Language': 'Language',
            'Japanese': '日本語',
            'English': 'English',
            'Resetpassword': 'Reset password',
            'ResetAccessKey': 'Reset access key',
            'Logout': 'Logout',   
            'LastUpdate': 'Last update',
            'TimeLocal': 'YYYY/MM/DD HH:mm',
        },
    },
    '_demonstration':{
        'DemoHome':{
            'Image to Predict': 'Image to Predict',
            'Start Predict': 'Start Predict',
            'Image to bindingbox and ocr': 'Image to bindingbox and ocr',
            'Pdf predict result': 'Pdf predict result',
            'Prediction result': 'Prediction result',
            'Image Classification': 'Image Classification',
            'Image Direction': 'Image Direction',
            'Image Preview': 'Image Preview',
            'Predicted Image': 'Predicted Image',
            'EasyOcrDownloadTitle': 'Download Easy OCR Images',
            'EasyOcrDownloadStart': 'Start Time',
            'EasyOcrDownloadEnd': 'End Time',
            'EasyOcrDownloadSubmit': 'Download',
            'ExportCSV': 'FS Preset Fail Export',
            'AnalysisStatus': 'Pdf Analysis Status Collection',
            'EasyocrHistory': 'EasyOcr usage history export',
            'licensekeyPredictionHistory': 'License EasyOCR usage history export',
            'LoginHistory': 'Login history export',
            'Download': 'Download',
            'PreDictionPdfTrackTitle': 'Download Track Infos On Pdf',
            'PreDictionPdfStartTime': 'From Pdf Upload Time',
            'PreDictionPdfTrackDownloadBtn': 'Download',
            'mailHistory': 'Mail History',
            'mailStart': 'Start Time',
            'mailEnd': 'End Time',
            'WorkingStatus': 'Working Status',
            'WorkingStatusStart': 'Start Time',
            'WorkingStatusEnd': 'End Time',
            'WorkingPeriod': 'Working Period',
            'WorkingStatusOverview': 'Overview',
            'WorkingStatusDetails': 'Details',
            'ProjectId': 'Project ID',
            'WorkingStatusOverviewTitle': 'Working Status (Overview)',
            'WorkingStatusDetailsTitle': 'Working Status (Details)',
            'TenantId': 'Tenant ID',
            'PageClassfication': 'Not matched page classification',
        },
    },
    '_easy-ocr': {
        'ApiEdit': {
            'Save': 'Save',
            'Do you want to save?': 'Do you want to save?',
            'Cancel': 'Cancel',
        },
        'ApiTable': {
            'Delete': 'Delete',
            'Do you want to delete it?': 'Do you want to delete it?',
            'Cancel': 'Cancel',
            'ID': 'ID',
            'Name': 'Name',
            'Descripe': 'Descripe',
            'Actions': 'Actions',
            'Edit': 'Edit',     
        }, 
        'EasyOcrTab': {
            'EasyOcrList': 'EasyOcrList',
        },
    },
    '_label': {
        'LabelManagementTable': {
            'Overview': 'Overview',
            'Edit': 'Edit',
            'ID': 'ID',
            'Project': 'Project',
            'Label': 'Label',
            'Operation': 'Operation',            
        },
        'LabelMenu': {
            'Images': 'Images',
            'Distribution': 'Distribution',
            'UploadImages': 'Upload Images',
            'UploadVideo': 'Upload Video',
            'Trainingdata': 'Training data',
            'Reference': 'Reference',
            'MLAssist': 'MLAssist',
            'AllImages': 'All Images',
            'SearchImages': 'Search Images',            
        },
        'description': {
            'index': {
                'Cancel': 'Cancel',
            },
        },
        'distribution': {
            'index': {
                'Refetch': 'Refetch',
                'Message': 'This operation requires a waiting time',
                'labelName': 'LabelName',
                'labelType': 'LabelType',
                'labeled': 'Labeled',
                'all': 'All',
            },
        },
        'image': {
            'Images': {
                'Changeperson': 'Change person',
                'Areyousure': 'Are you sure?',
                'OK': 'OK',
                'Cancel': 'Cancel',
                'Delete': 'Delete',
                'Are you sure to do this?': 'Are you sure to do this?',
                'ID': 'ID',
                'ImageLink': 'Image Link',
                'LabelStatus': 'Label Status',
                'LockedStatus': 'Lock',
                'Person': 'Person',
                'Actions': 'Actions',
                'Uploadtime': 'Uploadtime',
                'Modifytime': 'Modifytime',
                'Review': 'Review',
                'Search': 'Search',
                'Pleaseenterimagelink': 'Please enter a image link',
                'ChangeReviewer': 'Change reviewer',
                'reviewConfirm': 'Are you sure?',
                'TextInLabelData': 'Text in label data',
                'Pleaseentertext': 'Please enter text',
                'Edit': 'Edit'      
            },
            'UploadImages': {
                'Uploadfilesfromdisk': 'Upload files from disk',
                'Upload': 'Upload',
                'Uploadpdfsfromdisk': 'Upload pdfs from disk',
                'ImagesURLs': 'Images URLs',
                'SourcefromURLs': 'Source from URLs',
                'Serverfilesystempath': 'Server\'s filesystem path',
                'Sourcefromserver': 'Source from server\'s filesystem',
            },
            'ZipUpload': {
                'UploadZipFile': 'Upload zip file',
                'Upload': 'Upload',
                'SizeLimit': 'Limit size 200MB',
                'Loading': 'File loading',
                'Unzipping': 'File unzipping',
                'Uploading': 'File uploading',
                'Completed': 'Completed',
            },
            'FolderUpload': {
                'Label': 'Upload image in folder',
                'Upload': 'Upload',
                'Abort': 'Abort',
            },
            'UploadVideo': {
              'Title': 'Upload Video',
              'SelectVideo': 'Select Video', 
              'SplitToImages': 'Split To Images',
              'FPS': 'FPS',
              'StartPosition': 'Start',
              'MaxCount': 'Max Count',
              'CutVideo': 'Cut Video',
              'SaveImages': 'Save Images',
              'Image': 'Image',
              'ImageName': 'Image Name',
              'Duplicate': 'Duplicate Images'
            },
        },
        'label-app': {
            'AssigneesHOC': {
                'Changeperson': 'Change person',
                'Areyousure': 'Are you sure?',
                'ChangeReviewer': 'Change reviewer',
                'reviewConfirm': 'Are you sure?',
                'OK': 'OK',
                'Cancel': 'Cancel',                
            },
            'Canvas': {
                'Are you sure to do this?': 'Are you sure to do this?',
                'Delete': 'Delete',
                'Cancel': 'Cancel',                
            },
            'Labeling': {
                'TakeSnapshot': 'Take a Snapshot',
                'Sure': 'Do you want to take a snapshot?',
                'Cancel': 'Cancel'
            },
            'Submitting': {
                'DoSubmit': 'Submit',
                'Sure': 'Do you want to submit?'
            }
        },
        'model': {
            'MLAssist': {
                'Availableendpoints': 'Available end-points',
                'Delete': 'Delete',
                'Addanewendpoint': 'Add a new end-point',
                'ExpectedAPIformat': 'Expected API format',                
            }
        },
        'reference': {
            'UploadReference': {
                'Uploadimagefromdisk': 'Upload image from disk',
            },
        },
        'training-data': {
            'ImportData': {
                'ExportMsg': 'Do you really want to get the labeled data?',
                'Completion only': 'Completion only',
                'Get all': 'Get all',
                'UploadMsg': 'Upload a single JSON file generated by the download button below and a single corresponding image.',
                'UploadJsonMsg': 'Upload a single JSON file generated by the download button.',
                'UploadLabel': 'Select image and JSON file',
                'UploadJson': 'Select JSON file',
                'Upload': 'Upload',
                'DownloadLabel': 'Download a zip-file with JSON-encoded labels',
            },
        },
    },
    '_login': {
        'LoginPage': {
            'UserLogin': 'User Login',
            'Tenant': 'Tenant',
            'TenantName': 'TenantName',
            'History': 'History',
            'User': 'User',
            'Password': 'Password',
            'Signin': 'Sign in', 
            'Register': 'Tenant Register', 
            'Confirm': 'Confirm',
            'AlertMessage': 'okta email and user email are different, Are you synchronize it?',
            'Cancel': 'Cancel',
            'TenantIdConfirm': 'Tenant Id Confirm',
            'ResetPassword': 'Reset Password',
            'RootLogin': 'Root Login',
            'PasswordReset': 'Reset password',
            'NewPassword': 'New password',
            'RetypeNewPassword': 'Retype new password',
            'Submit': 'submit',
            'User ID': 'User ID',
            'Tenant ID Confirm': 'Tenant ID Confirm',
            'Email authentication': 'Email authentication',
        },
        'TenantLoginPage': {
            'UserLogin': 'User Login',
            'TenantUser': 'Tenant User',
            'Tenant': 'Tenant',
            'User': 'User',
            'Password': 'Password',
            'Signin': 'Sign in', 
            'Confirm': 'Confirm',
            'AlertMessage': 'okta email and user email are different, Are you synchronize it?',
            'Cancel': 'Cancel',
        },
        'RegisterPage': {
            'Register': 'Register',
            'TenantsMail': 'Tenants Mail',
            'Code': 'Code',
            'Send': 'Send',
            'Next': 'Next',
            'TenantsName': 'Tenants Name',
            'Search': 'Search',
            'Address': 'Address',
            'FirstName': 'First Name',
            'LastName': 'Last Name',
            'FirstKanaName': 'First Kana Name',
            'LastKanaName': 'Last Kana Name',
            'Phone': 'Phone',
            'UserMail': 'User Mail',
            'Pwd': 'Password',
            'ConfirmPwd': 'Confirm Password',
            'ContractPlan': 'Contract Plan',
            'TermsService': 'Label Tool Terms of Service',
            'Submit': 'Submit',
            'Return': 'Return',
            'EmailSuccessed': 'email send successed !',
            'AgreeService': 'I agree with the Terms of Service',
            'Plan 1': 'Plan 1',
            'Plan 2': 'Plan 2',
            'Plan 3': 'Plan 3',
            'Rule': 'Terms of Service XXXXXX',
        },
        'TenantIdConfirmPage': {
            'TenantIdConfirm': 'TenantId Confirm',
            'TenantsMail': 'Tenants Mail',
            'Send': 'Send',
            'Tips': 'If you do not know your tenant ID, please send a confirmation email'
        },
        'RetrievePage': {
            'SendMail': 'Mail authentication',
            'Mail': 'User mail',
            'Send': 'Send',
        },
        'UserRetrievePage':{
            'SendMail': 'Mail authentication',
            'Mail': 'User mail',
            'Send': 'Send',
            'TenantId':'TenantId',
            'Email':'Email',
            'FirstName':'First Name',
            'SecondName':'Last Name',
            'History': 'History',
        },
        'ResetPage': {
            'ResetPW': 'Reset password',
            'OK': 'OK',
        },
        'TwoStageAuthentication': {
            'InfoHeader': 'Two-Stage Authentication',
            'InfoBody': 'We have sent a verification code to your email, please check your email.<br/>The verification code is valid within 5 minutes',
            'Continue': 'Continue',
            'Resend': 'Resend',
            'VerificationCode': 'Verification Code'
        },
    },
    '_monitor': {
        'online-users': {
            'index':{
                'Online Users': 'Online Users',
            },
            'OnlineUsersTable': {
                'Kick off': 'Kick off',
                'Do you really want to kick off this user': 'Do you really want to kick off this user?',
                'OK': 'OK',
                'Cancel': 'Cancel',
                'User ID': 'User ID',
                'User Name': 'User Name',
                'Ip Address': 'Ip Address',
                'User Agent': 'User Agent',
                'Online Time': 'Online Time',
                'Operation': 'Operation',                
            }
        },
    },
    '_predict': {
        'admin': {
            'PdfSearch': {
                'Filename': 'File name',
                'Pleaseentername': 'Please enter a file name...',
                'Uploadtime': 'Upload time',
                'Author': 'Author',
                'Personincharge': 'Person',
                'Processingstate': 'Processing state',
                'Search': 'Search',
                'Noresults' : 'No results found.',                
            },
            'PdfUpload': {
                'Upload': 'Upload',
                'Connecting': 'Connecting',
                'Analysistitle': 'Analysis Target',
                'Analysis_1': 'Balance Sheet',
                'Analysis_2': 'Profit and Loss Sheet',
                'Analysis_3': "Statements of Shareholders' Equity",
                'Analysis_4': 'Production costs',
                'Analysis_5': 'Selling and Administrative expenses',
            },
            'PredictAdmin': {
                'Person': 'Person',
                'Edit': 'Edit',
                'Delete': 'Delete',
                'Analysis': 'Analysis',
                'Changeperson': 'Change person',
                'Areyousure': 'Are you sure?',
                'OK': 'OK',
                'Cancel': 'Cancel',
                'Areyousuredelete': 'Delete pdf, are you sure?',
                'Filename': 'File name',
                'Processingstate': 'Processing state',
                'Uploadtime': 'Upload time',
                'Author': 'Author',
                'Personincharge': 'Person',
                'Accuracy': 'Accuracy',
                'Operation': 'Operation',
                'Fileupload': 'File upload',
                'Filtering': 'Filtering',
                'Renew':'Refresh',                
            },
            'PredictProjects': {
                'AIAnalysis': 'AI Analysis',
            },
        },
        'detail': {
            'pdf': {
                'Canvas': {
                    'Rotation': 'Rotation',
                    'Do you want to rotate this page 90 degrees?': 'Do you want to rotate this page 90 degrees?',
                    'Results analysis': 'Results analysis',
                    'Do you want re-analyze the results?': 'Do you want re-analyze the results?',
                    'AI Analysis': 'AI Analysis',
                    'Do you want to re-analyze this page with AI?': 'Do you want to re-analyze this page with AI?',
                    'OK': 'OK',
                    'Cancel': 'Cancel',
                    'Delete': 'Delete',
                    'Do you want to remove all bounding boxes?': 'Do you want to remove all bounding boxes?',
                    'Zoom in': 'Zoom in',
                    'Zoom out': 'Zoom out',
                    'Zoom reset': 'Zoom reset',
                    'More': 'More',
                    'New bbox': 'New bbox',
                    'rotate': 'rotate',
                    'Delete all bbox': 'Delete all bbox',
                    'Predict': 'Predict',
                    'Mapping': 'Mapping',
                    'short key': 'short key',
                    'Movelabeling': 'Move Labeling',
                    'movelabeling': 'move labeling',
                    'Do you want to move it to labeling data?': 'Do you want to move it to labeling data?',
                    'Re-do AI scan': 'Re-do AI scan',
                    'Would you like to re-analyze this document with AI?':
                      'Would you like to re-analyze this document with AI?',
                    'Reanalyze': 'Reanalyze',
                    'Close': 'Close',
                    'Kinds': 'Classification',
                    'Please re-analyze the document': 'Please re-analyze the document.',
                    'Reanalysis': 'Reanalysis',
                },
                'MuiPopup': {
                    'Ocr': 'OCR',
                    'Edit': 'Edit',
                    'LinkTo': 'LinkTo',
                    'Choose Cell': 'Choose Cell',
                    'ProhibitEditingWarning': 'If you want to modify the value, press the Save button',
                    'unMapping': 'unMapping',
                    'ItemSearch': {
                        'Search': 'Search',
                        'Set': 'Set',
                        'Cancel': 'Cancel',
                    },
                    'Maybe': 'Maybe',
                    'Alignment': 'Alignment',
                    'Scan subject': 'Scan subject',
                    'Scan amount': 'Scan amount',
                    'Cooperation': 'Cooperation',
                    'Not linked': 'Not linked',
                    'Collaborative subjects': 'Collaborative subjects',
                },
                'Thumbnail': {
                	'Search': 'Search',
                	'I searched and did not find any hit results':
                 	  'I searched and did not find any hit results.',
        		},
            },
            'mapping': {
		        'ProhibitEditingWarning':
		          'Press the "Save" button at the appropriate time. It is controlled so that some buttons cannot be pressed until the data is saved.',
		        'Cancellation of manual input': 'Cancellation of manual input',
		        'Manual input': 'Manual input',
		        'Subject': 'Subject',
		        'Manual entry of amount': 'Manual entry of amount',
                'Open details': 'Open details',
		        'Invert the sign': 'Invert the sign',
		        'Cancel cooperation': 'Cancel cooperation',
		        'Please select the subject you want to collaborate with':
		          '↓   Please select the subject you want to collaborate with.',
		        'Detail': 'Detail',
		        'ManualInput': 'Manual Input',
		        'Sign inversion': 'Sign inversion',
		        'Enter with a link': 'Enter with a link',
		        'Select': 'Select',
		        'Report to administrator': 'Report to administrator',
		        'If you think the accuracy needs to be improved, report it to the administrator':
		          'If you think the accuracy needs to be improved, report it to the administrator.',
		        'Send': 'Send',
		        'Cancel': 'Cancel',
		        'Select a type': 'Select a type',
		        'Keep': 'Keep',
		        'CSV download': 'CSV download',
		        'Restore to the previous saved state':
		          'Restore to the previous saved state',
		        'Caution': 'Caution',
		        'Some items do not match the values of (automatic aggregation) and (input)':
		          'Some items do not match the values of (automatic aggregation) and (input)',
		        'please confirm': 'please confirm.',
		        'Close': 'Close',
                'As it is': 'As it is',
		        'Proceed to': 'Proceed to',
		        'ScanClassification': 'Scan classification',
		        'ScanSubject': 'Scan subject',
		        'AmountOfMoney': 'Amount of money',
		        'ChangeOfPartnerSubject': 'Change of partner subject',
		        'Lift': 'Lift',
		        'ConnectionSubject': 'Connection subject',
		        'Operation': 'Operation',
		        'DifferenceFromInput': 'Difference from input',
                'Thank you for your report':'Thank you for your report',
                'Email sending failed':'Email sending failed',
                'View in card view':'View in card view',
                'View in list view':'View in list view',
		    },
            'review': {
                'Close': 'close',
                'Submit update request': 'Submit update request',
                'Title': 'There are courses whose collaboration destination has been changed',
                'File label': 'fileId',
                'Now': 'now',
                'New': 'new',
                'Update': 'update',
                'Tab1': 'unrequested',
                'Tab2': 'Requested/Updated',
                'Cancel': 'cancel'
            }
        },
    },
    '_project': {
        'ProjectTab': {
          'ProjectList': 'Project List',
          'ProjectUser': 'Project User'
        },
        'ProjectAdd': {
            'Project Name': 'Project Name',
            'Project Description': 'Project Description',
            'Save': 'Save',
            'Cancel': 'Cancel',            
        },
        'ProjectDescription': {
            'Save': 'Save',
            'Cancel': 'Cancel',            
        },
        'ProjectManagementTable': {
            'Are you sure you want to delete the project?': 'Are you sure you want to delete the project?',
            'Delete': 'Delete',
            'Create a new Project by Copy?': 'Create a new Project by Copy?',
            'Copy': 'Copy',
            'Cancel': 'Cancel',
            'Overview': 'Overview',
            'Edit': 'Edit',
            'ID': 'ID',
            'Project': 'Project',
            'Operation': 'Operation',            
        },
        'ProjectMenu': {
            'Edit': 'Edit',
            'Labels': 'Labels',
            'ResultTables': 'ResultTables',
            'ResultFunctions': 'ResultFunctions',
            'User': 'User',
            'Metadata': 'Metadata',
            'Parsing Function': 'Parsing Function',
            'AI Process': 'AI Process', 
            'Working Status': 'Working Status',
            'CharReplacement': 'Character Replacement',
            'APIFunction': 'APIFunction',  
        },
        'mapping-function': {
            'ParamsEditor': {
                'Confirm': 'Confirm',
                'Do you really want to change the test params?': 'Do you really want to change the test params?',
                'OK': 'OK',
                'Do you really want to reset the test params to default?': 'Do you really want to reset the test params to default?',
                'Cancel': 'Cancel',
                'Save': 'Save',
                'Reset': 'Reset',                
            },
        },
        'metadata': {
            'ImportMetadata': {
                'Import project metadata': 'Import project metadata',
                'Select CSV files': 'Select CSV files',
                'Import': 'Import',
                'Cancel': 'Cancel',               
            },
            'ProjectMetadataEdit': {
                'Category': 'Category',
                'SubCategory': 'SubCategory',
                'Item': 'Item',
                'Value': 'Value',
                'Save': 'Save',
                'Cancel': 'Cancel',
                'Edit Metadata': 'Edit Metadata',
                'Add Metadata': 'Add Metadata',
            },
            'ProjectMetadataTable': {
                'Edit': 'Edit',
                'Delete': 'Delete',
                'Do you want to delete it?': 'Do you want to delete it?',
                'Cancel': 'Cancel',
                'Do you want to get metadata?': 'Do you want to get metadata?',
                'Download': 'Download',
                'Num': 'Num',
                'Category': 'Category',
                'SubCategory': 'SubCategory',
                'Item': 'Item',
                'Value': 'Value',
                'Operation': 'Operation',
            },      
        },
        'process': {
            'Process': {
                'OK': 'OK',
                'Cancel': 'Cancel',
                'Save': 'Save',                
                'Confirm': 'Confirm',                
                'Do you want to save the record?': 'Do you want to save the record?',
            },
        },
        'project': {
            'ProjectEdit': {
                'Are you sure to do this?': 'Are you sure to do this?',
                'Cancel': 'Cancel',
                'DeleteTitle': 'DELETE PROJECT',
                'Deleteprojecttext': 'The button below will delete all metadata about labels, images and the project.',
                'Deleteprojectbutton': 'Delete project',                
            },
        },
        'user': {
            'ProjectUserEdit': {
                'Save': 'Save',
                'Cancel': 'Cancel',
                'Edit User': 'Edit User',
            },
            'ProjectUserTable': {
                'Are you sure delete user': 'Are you sure you want to delete this user?',
                'Delete': 'Delete',
                'Cancel': 'Cancel',
                'Num': 'Num',
                'User Name': 'User Name',
                'User Email': 'User Email',
                'Operation': 'Operation',                
            },
        },
        'label': {
            'ImportTitle': 'Import label data',
            'Cancel': 'Cancel',  
            'Import': 'Import',  
        },
    },
    '_system': {
        'System': {
            'Preferences': 'Preferences',  
            'Tenants': 'Tenants',
            'LicenseKey': 'License Key',
            'Machines': 'Machines',
            'Feedback': 'Feedback',
            'Black Ip': 'Black IP',
            'FsItemMapping': 'Fs Item Mapping',
            'Pdf Search': 'PDF Search',
            'RabbitMQ': 'RabbitMQ',
            'Replace': 'Replace',
        },
        'preferences': {
            'Preferences': {
                'ID': 'ID',
                'Category': 'Category',
                'SubCategory': 'SubCategory',
                'Name': 'Name',
                'SubName': 'SubName',
                'Version': 'Version',
                'SubVersion': 'SubVersion',
                'Value': 'Value',
                'Priority': 'Priority',
                'Operation': 'Operation',
                'Edit': 'Edit',
                'Delete this record': 'Delete this record',
                'Are you sure to do this?': 'Are you sure to do this?',
                'Delete': 'Delete',
                'Cancel': 'Cancel',
                'Edit Preferences': 'Edit Preferences',
                'Add Preferences': 'Add Preferences',
            },
        },
        'feedback': {
            'index': {
                'No':'No',
                'TenantId':'TenantId',
                'FileName':'FileName',
                'UserName':'UserName',
                'LicenseInfo':'LicenseInfo',
                'MacAddress':'MacAddress',
                'IpAddress':'IpAddress',
                'Rating':'Rating',
                'Type':'Type',
                'Comment':'Feedback',
                'ReportTime':'ReportTime',
                'Memo':'Memo',
                'Info': 'Info',
                'Are you sure delete feedback': 'Are you sure you want to delete this feedback?',
                'Delete': 'Delete',
                'Download': 'Download',
                'Cancel': 'Cancel',
                'Operation': 'Operation', 
                'EasyOCR Endpoints': 'EasyOCR Endpoints', 
            },
            'search':{
                'UserName':'UserName',
                'UserNameText':'UserNameText',
                'ReportTime':'ReportTime',
                'Search':'Search',
                'TanentSearch': 'TanentSearch',
            },
            'ApiEdit': {
                'Save': 'Save',
                'Do you want to save?': 'Do you want to save?',
                'Reload': 'Display',
                'Update contents': 'Do you want to update the display contents?',
                'RePredict': 'Re-Predict',
                'Do you want to re-predict?': 'Do you want to re-predict?',
                'Inference': 'Inference server',
                'Flask': 'Flask',
                'OK': 'OK',
                'Cancel': 'Cancel',
            },    
            'parseInfo': {
                'Comment': 'Comment',
                'Memo': 'Memo',
                'Type':'Type',
                'MetaData': 'Metadata',
                'History':'History',
                'Data': 'Data',
            },
            'parsingTypes': [
                {'new created': 'new created'},
                {'in process': 'in process'},
                {'back log': 'back log'},
                {'completed': 'completed'},                
            ],
        },
        'tenants': {
            'Index': {
                'Maintain': 'Maintain',
                'Audits': 'Audits',
                'TenantSettings': 'TenantSetting',
                'User': 'User',
            },
            'maintain': {
                'Edit': {
                    'Confirm': 'Confirm',
                    'Cancel': 'Cancel',
                    'TenantAdd': 'Tenants Add',
                    'TenantEdit': 'Tenants Edit',
                    'TenantsName': 'Tenants Name',
                    'TenantsMail': 'Tenants Mail',
                    'Address': 'Address',
                    'FirstName': 'First Name',
                    'LastName': 'Last Name',
                    'FirstKanaName': 'First Kana Name',
                    'LastKanaName': 'Last Kana Name',
                    'Phone': 'Phone',
                    'Count': 'Effective Number',
                    'UserMail': 'User Mail',
                    'ContractPlan': 'Contract Plan',
                    'Save': 'Save',
                    'limitAmountUser': 'Limit Amount User',
                    'limitAmountProject': 'Limit Amount Project',
                    'easyocrContract': 'EasyOcr contract',
                    'fsContract': 'FS Contract',
                    'limitAmountPdf': 'Limit Amount Pdf',
                    'updateExpiredTimestamp': 'update license key expired timestamp',
                    'pdfAutoRenew': 'Pdf Contract Auto Renew',
                    'easyocrAutoRenew': 'Easyocr Contract Auto Renew',
                },
                'Index': {
                    'Edit': 'Edit',
                    'Delete': 'Delete',
                    'Do you want to delete this tenant?': 'Do you want to delete this tenant?',
                    'Confirm': 'Confirm',
                    'Cancel': 'Cancel',
                    'ID': 'ID',
                    'TenantsName': 'Tenants Name',
                    'TenantsMail': 'Tenants Mail',
                    'Address': 'Address',
                    'Phone': 'Phone',
                    'Count': 'Effective Number',
                    'Actions': 'Actions',
                    'Filtering': 'Filtering',
                },
                'Search': {
                    'TenantsName': 'Tenants Name',
                    'Please enter a tenant name': 'Please enter a tenant name',
                    'TenantsMail': 'Tenants Mail',
                    'Please enter a tenant mail': 'Please enter a tenant mail',
                    'UpdateTime': 'Update Time',
                    'Search': 'Search',
                    'Add': 'Add',
                }
            },
            'audits': {
                'Edit': {
                    'Confirm': 'Confirm',
                    'Do you want to update this audits?': 'Do you want to update this audits?',
                    'Cancel': 'Cancel',
                    'AuditsEdit': 'Audits Edit',
                    'TenantsName': 'Tenants Name',
                    'TenantsMail': 'Tenants Mail',
                    'Address': 'Address',
                    'FirstName': 'First Name',
                    'LastName': 'Last Name',
                    'FirstKanaName': 'First Kana Name',
                    'LastKanaName': 'Last Kana Name',
                    'Phone': 'Phone',
                    'UserMail': 'User Mail',
                    'ContractPlan': 'Contract Plan',
                    'Comment': 'Comment',
                    'Agree': 'Agree',
                    'Refuse': 'Refuse',
                },
                'Index': {
                    'Edit': 'Edit',
                    'Delete': 'Delete',
                    'Do you want to delete this audits?': 'Do you want to delete this audits?',
                    'Confirm': 'Confirm',
                    'Cancel': 'Cancel',
                    'ID': 'ID',
                    'TenantsName': 'Tenants Name',
                    'Status': 'Status',
                    'Personincharge': 'Person',
                    'UpdateTime': 'Update Time',
                    'Actions': 'Actions',
                    'Filtering': 'Filtering',
                },
                'Search': {
                    'TenantsName': 'Tenants Name',
                    'Please enter a tenant name': 'Please enter a tenant name',
                    'UpdateTime': 'Update Time',
                    'Status': 'Status',
                    'Search': 'Search',
                }
            },
            'setting': {
                'Edit': {
                    'Category': 'Category',
                    'SubCategory': 'SubCategory',
                    'Name': 'Name',
                    'SubName': 'SubName',
                    'Version': 'Version',
                    'SubVersion': 'SubVersion',
                    'Value': 'Value',
                    'Priority': 'Priority',
                    'Edit TenantSetting': 'Edit TenantSetting',
                    'Add TenantSetting': 'Add TenantSetting',
                },
                'Index': {
                    'Edit': 'Edit',
                    'Delete': 'Delete',
                    'Are you sure to do this?': 'Are you sure to do this?',
                    'Confirm': 'Confirm',
                    'Cancel': 'Cancel',
                    'ID': 'ID',
                    'Category': 'Category',
                    'SubCategory': 'SubCategory',
                    'Name': 'Name',
                    'SubName': 'SubName',
                    'Version': 'Version',
                    'Value': 'Value',
                    'Operation': 'Operation',
                    'Filtering': 'Filtering',
                    'Do you want to get tenantSetting?': 'Do you want to get tenant setting?',
                    'Download': 'Download',
                },
                'ImportTenantSetting': {
                    'Import tenantSetting': 'Import TenantSetting',
                    'Select CSV files': 'Select CSV files',
                    'Import': 'Import',
                },
                'Search': {
                    'TenantsName': 'Tenants Name',
                    'Search': 'Search',
                    'Add': 'Add',
                }
            },
            'user':{
                'User':{
                    'Edit': 'Edit',
                    'Reset password': 'Reset password',
                    'Reset access key': 'Reset access key',
                    'Delete': 'Delete',
                    'Are you sure to do this?': 'Are you sure to do this?',
                    'Cancel': 'Cancel',
                    'Num': 'Num',
                    'Name': 'Name',
                    'E-mail': 'E-mail',
                    'Status': 'Status',
                    'Password': 'Password',
                    'Access Key': 'Access Key',
                    'Role': 'Role',
                    'Operation': 'Operation',
                    'Reset': 'Reset',
                    'Reset success': 'Reset success',
                    'EmailSuccessed': 'email send successed !',
                    'Do you want to get user': 'Do you want to get user',
                    'Account': 'Okta Account',
                    'Edit User': 'Edit User',
                    'Add User': 'Add User',
                    'TenantsName': 'Tenants Name',
                    'Please enter tenantname to perform the operation':'Please enter tenantname to perform the operation',
                    'Search':'Search'
                },
                'ImportUser': {
                    'Import user': 'Import user',
                    'Select CSV files': 'Select CSV files',
                    'Import': 'Import',
                },
            },
        },
        'FsItemMapping': {
            'Tab': {
                'Global': 'System',  
                'Tenant': 'TenantPresetMappingImport',
                'BulkImport': 'Tenant Preset Bulk import',
                'BulkExport': 'Tenant Preset Bulk export',
                'DiffExport': 'Diff Export',
            },
        },
        'licenseKey': {
            'LicenseKey': {
                'ID': 'ID',
                'TenantID': 'Tenant ID',
                'PageCount': 'Page Count',
                'MachineCount': 'Machine Count',
                'ContractID': 'Contract ID',
                'LicenseKey': 'License Key',
                'IPFilter': 'IP Filter',
                'CreatedAt': 'Created At',
                'ValidFrom': 'Valid From',
                'ExpiredTime': 'Expiration Date',
                'Operation': 'Operation',
                'Delete': 'Delete',
                'Copy': 'Copy',
                'Edit': 'Edit',
                'easyocrEp': 'EndPoint',
                'Description': 'Description',
                'Cancel': 'Cancel',
                'Do you want to get license key': 'Do you want to get all license keys',
                'Download': 'Download',
            },
            'Search': {
                'ID': 'ID',
                'Please enter a ID': 'Please enter a ID',
                'TenantID': 'Tenant',
                'ContractID': 'Contract ID',
                'Please enter a Contract ID': 'Please enter a Contract ID',
                'LicenseKey': 'License Key',
                'Please enter a License Key': 'Please enter a License Key',
                'CreatedAt': 'Created At',
                'ValidFrom': 'Valid From',
                'ExpiredTime': 'Expiration Date',
                'endpoint': 'EndPoint',
                'Please enter a EndPoint': 'Please enter a EndPoint',
                'Search': 'Search',
                'Reset': 'Reset',
            }
        },
        'machines': {
            'Machines': {
                'ID': 'ID',
                'LicenseKeyID': 'License Key ID',
                'OSInfo': 'OS Info',
                'EasyOCRVersion': 'EasyOCR Version',
                'MACAddress': 'MAC Address',
                'IPAddress': 'IP Address',
                'CreatedAt': 'Created At',
                'Operation': 'Operation',
                'Delete': 'Delete',
                'Copy': 'Copy',
            },
        },
        'blackip': { 
            'Index':{
                'IpFile': 'AuthFailedList',
                'GroupFile': 'Ip list',
            },
            'IpFile':{
                'Index':{
                    'ID': 'ID',
                    'IpAddress': 'IpAddress',
                    'FailedTime': 'FailedTime',
                    'Actions': 'Actions',
                    'Delete': 'Delete',
                    'OK': 'OK',
                    'Confirm': 'Confirm',
                    'Cancel': 'Cancel',
                    'Do you want to delete this blacklist?': "Are you sure you want to delete the record?"
                },
                'Search':{
                    'FailedTime': 'retrieval time scope',
                    'Search': 'Search',
                },
            },
            'GroupFile':{
                'Index':{
                    'ID': 'ID',
                    'IpAddress': 'IpAddress',
                    'Count': 'Count',
                    'Actions': 'Actions',
                    'Delete': 'Delete',
                    'OK': 'OK',
                    'Confirm': 'Confirm',
                    'Cancel': 'Cancel',
                    'Do you want to delete this blacklist?': "Are you sure you want to delete the record?"
                },
                'Search':{
                    'FailedTime': 'retrieval time scope',
                    'Search': 'Search',
                },
            },
        },
        'PdfSearch':{
          'Index':{
              'ID': 'PDFID',
              'ServerIp': 'ServerIp',
              'CreatedById': 'CreatorId',
              'CreatedByName': 'CreatorName',
              'UploadTime': 'UploadTime',
              'LastEdited': 'LastEdited',
              'ProjectsId': 'ProjectsId',
              'StateFlag': 'StateFlag',
              'DeleteFlag': 'DeleteFlag',
              'TenantId': 'TenantId',
              'Filtering': 'Filtering',
              'Operation': 'Operation',
          },
          'Search':{
              'StateFlag': 'StateFlag',
              'UploadTime': 'UploadTime',
              'LastEdited': 'LastEdited',
              'DeleteFlag': 'DeleteFlag',
              'Search': 'Search',
              'Tenant': 'Tenant',
          }
        },
        'replacement': {
            'Edit': {
                'OcrText': 'OCR Text',
                'Replacement': 'Replacement Text',
                'Edit Replacement': 'Edit Replacement',
                'Add Replacement': 'Add Replacement',
            },
            'Index': {
                'Edit': 'Edit',
                'Delete': 'Delete',
                'Are you sure to do this?': 'Are you sure to do this?',
                'Confirm': 'Confirm',
                'Cancel': 'Cancel',
                'ID': 'ID',
                'OcrText': 'OCR Text',
                'Replacement': 'Replacement Text',
                'Scope': 'Scope',
                'Easyocr': 'easyocr',
                'FinancialStatement': 'financialStatement',
                'Operation': 'Operation',
                'Filtering': 'Filtering',
                'Do you want to get replacement?': 'Do you want to get replacement?',
                'Download': 'Download',
            },
            'ImportReplacement': {
                'Import replacement': 'Import Replacement',
                'Select CSV files': 'Select CSV files',
                'Import': 'Import',
            },
            'Search': {
                'TenantsName': 'Tenants Name',
                'Search': 'Search',
                'Add': 'Add',
            }
        },
    },
    '_train': {
        'ProjectTable': {
            'Overview': 'Overview',
            'Edit': 'Edit',
            'ID': 'ID',
            'Project': 'Project',
            'Operation': 'Operation',            
        },
        'TrainAdd': {
            'Save': 'Save',
            'Cancel': 'Cancel',            
        },
        'TrainTable': {
            'Train': 'Train',
            'Edit': 'Edit',
            'Person': 'Person',
            'Delete': 'Delete',
            'Detail': 'Detail',
            'OK': 'OK',
            'Cancel': 'Cancel',
            'ID': 'ID',
            'Name': 'Name',
            'Operator': 'Operator',
            'Module': 'Module',
            'Status': 'Status',
            'Label': 'Label',
            'Auto': 'Auto',
            'Operation': 'Operation',
            'Description': 'Description', 
        },
        'description': {
            'index': {
                'Cancel': 'Cancel',
            },
        },
        'detail': {
            'Prepareing': {
                'OK': 'OK',
                'Cancel': 'Cancel',                
            },
            'TrainAndEvaluate': {
                'OK': 'OK',
                'Cancel': 'Cancel',                
            },
        },
    },
    '_user': {
        'UserApp': {
            'User': 'User',
            'Role': 'Role',            
        },
        'permission': {
            'Permission': {
                'Are you sure to do this?': 'Are you sure to do this?',
                'Delete': 'Delete',
                'Cancel': 'Cancel',                
            },
        },
        'role': {
            'Role': {
                'Edit': 'Edit',
                'Delete': 'Delete',
                'Are you sure to do this?': 'Are you sure to do this?',
                'Cancel': 'Cancel',
                'Num': 'Num',
                'Name': 'Name',
                'Permission': 'Permission',
                'Operation': 'Operation',
                'Delete this role': 'Delete this role',
                'Edit Role': 'Edit Role',
                'Add Role': 'Add Role',
                'Policy': 'Policy',
            },
        },
        'user': {
            'ImportUser': {
                'Import user': 'Import user',
                'Select CSV files': 'Select CSV files',
                'Import': 'Import',
            },
            'User': {
                'Edit': 'Edit',
                'Reset password': 'Reset password',
                'Reset access key': 'Reset access key',
                'Delete': 'Delete',
                'Are you sure to do this?': 'Are you sure to do this?',
                'Cancel': 'Cancel',
                'Num': 'Num',
                'Name': 'Name',
                'E-mail': 'E-mail',
                'Status': 'Status',
                'Password': 'Password',
                'Access Key': 'Access Key',
                'Role': 'Role',
                'Operation': 'Operation',
                'Reset': 'Reset',
                'Reset success': 'Reset success',
                'EmailSuccessed': 'email send successed !',
                'Do you want to get user': 'Do you want to get user',
                'Account': 'Okta Account',
                'Edit User': 'Edit User',
                'Add User': 'Add User',
                'Search': 'Search',
                'PasswordReset': 'Reset password',
                'NewPassword': 'New password',
                'RetypeNewPassword': 'Retype new password',
                'Submit': 'Submit',
                'NowPassword': 'NowPassword',
                'Close': 'Close',
            },
        },
    },
    '_setting': {
        'Setting': {
            'TenantSetting': 'Tenant Setting',
            'Tenant': 'Tenant',
            'FsItemMapping': 'Fs Item Mapping',
            'ConvertSubtitle': 'Subtitle Conversion',
            'PresetDIFF': 'PresetDIFF'
        },
        'tenantSetting': {
            'TenantSetting': {
                'ID': 'ID',
                'Category': 'Category',
                'SubCategory': 'SubCategory',
                'Name': 'Name',
                'SubName': 'SubName',
                'Version': 'Version',
                'SubVersion': 'SubVersion',
                'Value': 'Value',
                'Priority': 'Priority',
                'Operation': 'Operation',
                'Edit': 'Edit',
                'Delete this record': 'Delete this record',
                'Are you sure to do this?': 'Are you sure to do this?',
                'Delete': 'Delete',
                'Cancel': 'Cancel',
                'Edit TenantSetting': 'Edit TenantSetting',
                'Add TenantSetting': 'Add TenantSetting',
                'Do you want to get tenantSetting?': 'Do you want to get tenant setting?',
                'Download': 'Download',
            },
            'ImportTenantSetting': {
                'Import tenantSetting': 'Import TenantSetting',
                'Select CSV files': 'Select CSV files',
                'Import': 'Import',
            },
        },
        'tenant': {
            'Edit': {
                'Confirm': 'Confirm',
                'Cancel': 'Cancel',
                'TenantEdit': 'Tenants Edit',
                'TenantsName': 'Tenants Name',
                'TenantsMail': 'Tenants Mail',
                'Address': 'Address',
                'Phone': 'Phone',
                'Save': 'Save',
                'Do you want to save?': 'Do you want to save?',
                'OK': 'OK'
            },
        },
        'presetDIFF': {
            'Table': {
                'Placeholder': 'Please enter Fs Item',
                'LinkTo': 'LinkTo',
                'Are you sure to do this?': 'Are you sure to do this?',
                'OK': 'OK',
                'Cancel': 'Cancel',
            },
        },
    },
}