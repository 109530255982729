import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import Menubar from '../common/Menubar';
// eslint-disable-next-line
import LoginPage from './LoginPage';
import LoginPageBeautify from './LoginPageBeautify';
import RegisterPage from './RegisterPage';
import TenantIdConfirmPage from './TenantIdConfirmPage';
import TenantLoginPage from './TenantLoginPage';
import UserRetrievePage from './UserRetrievePage';
import RetrievePage from './RetrievePage';
import ChangePage from './ChangePage';
import TwoStageAuthentication from './TwoStageAuthentication'
import './login.css'
import './LoginPage.css';

class LoginApp extends Component {
  componentWillMount() {
    const saveUrl = localStorage.getItem('saveUrl');
    const passTwoStageAuthentication = localStorage.getItem('passTwoStageAuthentication');
    if (passTwoStageAuthentication === 'true' || passTwoStageAuthentication === null) {
      localStorage.clear()
    }
    if (saveUrl) {
      localStorage.setItem("saveUrl", saveUrl)
    }
  }

  render() {
    return (
      <Menubar active="project">
        <Route exact path="/login" component={LoginPageBeautify} />
        <Route exact path="/login/register" component={RegisterPage} />
        <Route exact path="/login/tenantIdConfirm" component={TenantIdConfirmPage} />
        <Route exact path="/login/tenantLoginPage" component={TenantLoginPage} />
        <Route exact path="/login/userRetrieve" component={UserRetrievePage} />
        <Route exact path="/login/retrieve" component={RetrievePage} />
        <Route exact path="/login/reset" component={ChangePage} />
        <Route exact path="/login/two-stage-authentication" component={TwoStageAuthentication} />
      </Menubar>
    );
  }
}

export default LoginApp;