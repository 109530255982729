import React, { Component } from 'react';
import PdfLoader from './pdf/PdfLoader';
import shallowEqualObjects from 'shallow-equal/objects';
import HotKeyPanel from '../detail/HotKeyPanel';
import { Icon, Loader, Dimmer } from 'semantic-ui-react';
import _ from 'lodash';
import { createCookie, getCookie } from '../../common/CookieUtil';
import { AnalyticsServiceContext } from '../../common/Analytics/AnalyticsServiceProvider';
import { EventName, EventType } from '../../common/Analytics/analyticsMetrics';
import api from '../../api';
import FsMapping from './mapping/FsMapping';

export default class MappingRequestPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: null,
      oldCurrentPage: null,
      selectedCell: { id: '', pdfImageId: '', cellIds: [] },
      selectedBBox: { id: '', text: '' },
      linking: false,
      minPdfImageId: 0,
      shortKeyPanelVisible: false,
      currentBoxId: '',
      currentBoxIdForCss: '',
      leftWidth: '',
      showType: 5,
      resultFunctionParam: { open: false, showFSRV: false, setResultFunctionParam: this.handelResultFunctionParamSet },
      pdfInfo: null,
      tenantId: null,
      isPredictLoading: false,
      isSelectTargetBbox: false,
      pdfStatus: 1,
      pageType: '',
      messagefirst: '',
      messageSecord: '',
      pageRereadLoading: false,
      tenantPreference: {},
      selectFlag: false,
      effectiveHotkeyPage: "canvas", // thumbnail|canvas|textmapping
      isExclusionControlShow: true,
      isFileUpdateErrorShow: [false, '', '', ''],
    };
    this.TextMappingRef = React.createRef();
    this.ThumbnailRef = React.createRef();
    this.PopupRef = React.createRef();
    this.CanvasRef = React.createRef();
    this.MuiPopupRef = React.createRef();
    this.PdfLoaderRef = React.createRef();
  }
  static contextType = AnalyticsServiceContext;

  async componentWillMount() {
    await this.handleGetPdfStatus();
  }
  async componentDidMount() {
    const { projectId, pdfFileId } = this.props.match.params;
    this.context.setProjectId(projectId);
    this.context.setPdfFileId(pdfFileId);
    let tenantId = getCookie('tenant_id');
    this.setState({ tenantId: tenantId });
    let viewType = JSON.parse(
      getCookie('viewType' + tenantId) || '{}'
    );
    for (let i = 1; i < 6; i++) {
      if (!viewType[i]) {
        viewType[i] = {
          leftWidth: i === 1 ? "65%" : i === 5 ? "60%" : "45%",
          rightWidth: i === 1 ? "calc(35% - 5px)" : i === 5 ? "calc(40% - 5px)" : "calc(55% - 5px)"
        }
      }
    }
    createCookie('viewType' + tenantId, JSON.stringify(viewType), 365);
    await this.handleGetTenantPreference();
    this.setState({ loadState: "loaded" });
  }

  handleSetPdfInfo = pdfInfo => {
    this.setState({ pdfInfo: pdfInfo });
  }

  handleSetHeaderInfo = lastEditInfo => {
    let newPdfInfo = this.state.pdfInfo;
    newPdfInfo.lastEdited = lastEditInfo[0];
    newPdfInfo.lastEditedId = lastEditInfo[1];
    newPdfInfo.lastEditedName = lastEditInfo[2];
    this.setState({ pdfInfo: newPdfInfo });
    this.props.onChangeLastEditorInfo([lastEditInfo[1], lastEditInfo[2], lastEditInfo[0]]);
  }

  handlePageChange = page => {
    if (page !== this.state.currentPage) {
      this.setState({ currentPage: page });
    }
  };

  handleRowSelect = (id, pdfImageId, cellIds, column, manualInput, operationMethod) => {
    const { selectedCell, selectedBBox, currentPage, oldCurrentPage } = this.state;
    const newSelectedCell = { id, pdfImageId, cellIds, column };
    const copySelectedCellIds = _.cloneDeep(selectedCell.cellIds);
    const copyCellIds = _.cloneDeep(cellIds);
    const empty = { id: '', pdfImageId: '', cellIds: [] };
    var oldCurrentPageNew = null;
    if (currentPage) {
      oldCurrentPageNew = currentPage
    } else {
      oldCurrentPageNew = oldCurrentPage
    }
    if (
      (shallowEqualObjects(
        copySelectedCellIds.sort(),
        copyCellIds.sort()
      ) &&
        column === selectedCell.column &&
        id === selectedCell.id)
    ) {
      this.setState({ selectedCell: empty, linking: false, currentBoxId: '', currentBoxIdForCss: '', manualInput: undefined, selectFlag: false });
    } else {
      const newSelectdBox = { ...selectedCell, ...newSelectedCell };
      this.setState({
        selectedCell: newSelectdBox,
        linking: false,
        currentPage: pdfImageId,
        currentBoxId: '',
        currentBoxIdForCss: '',
        manualInput: manualInput,
        selectFlag: true,
        oldCurrentPage: oldCurrentPageNew
      });
    }
    if (!selectedBBox || !selectedBBox.id) {
      this.PopupRef &&
        this.PopupRef.current &&
        this.PopupRef.current.leafletElement.options.leaflet.map.closePopup();
    }
    if (selectedCell && selectedCell.id !== '' && (operationMethod && operationMethod === 'keyboard')) {
      this.TextMappingRef &&
        this.TextMappingRef.current &&
        this.TextMappingRef.current.handleSearchFromBBoxClick();
    }
  };

  handleChangeSelectFlag = (selectFlag) => {
    this.setState({
      selectFlag: selectFlag
    });
  }

  handleShortKeyPanelVisible = () => {
    this.setState(prevState => {
      return { shortKeyPanelVisible: !prevState.shortKeyPanelVisible };
    });
  };
  //once ckick bbox, tabel cell css changed
  handleSelectBox = id => {
    const currentBoxIdForCss = this.state.currentBoxIdForCss === id ? '' : id;
    this.setState({ currentBoxId: id, currentBoxIdForCss, selectedCell: { id: '', pdfImageId: '', cellIds: [] }, linking: false });
  };

  // this func is for link bbox to table cell
  handleSelectedBBoxChange = (id, text) => {
    this.setState({ selectedBBox: { id, text } });
  };

  onMouseDown = e => {
    var viewType = JSON.parse(
      getCookie('viewType' + this.state.tenantId) || '{}'
    );
    var leftChild = document.getElementById('leftChild');
    var rightChild = document.getElementById('rightChild');
    var iEvent = e;
    var dx = iEvent.clientX;
    var leftWidth = leftChild.offsetWidth;
    var rightWidth = rightChild.offsetWidth;
    document.onmousemove = e => {
      var iEvent = e;
      var diff = iEvent.clientX - dx;
      if (
        leftWidth + diff > 450 &&
        rightWidth - diff > 0
      ) {
        leftChild.style.width =
          ((leftWidth + diff) / (leftWidth + rightWidth)) * 100 + '%';
        rightChild.style.width =
          `calc(` +
          ((rightWidth - diff) / (leftWidth + rightWidth)) * 100 +
          `% - 5px)`;

        createCookie('viewType' + this.state.tenantId, JSON.stringify({
          ...viewType,
          [this.state.showType]: {
            leftWidth: leftChild.style.width,
            rightWidth: rightChild.style.width
          }
        }), 365);
      }
    };
    document.onmouseup = () => {
      this.setState({ leftWidth: Date.now() });
      this.context.sendPredictBeautifyDetailMetrics({
        event_type: EventType.Action,
        event_name: EventName.ChangeColumnWidth,
        custom_parameter: {
          right_width: rightWidth,
          display_size: {
            height: window.screen.height,
            width: window.screen.width,
          },
          browser_size: {
            height: window.outerHeight,
            width: window.outerWidth,
          }
        },
      })
      document.onmousemove = null;
      document.onmouseup = null;
    };
    return false;
  };

  handelResultFunctionParamSet = (params) => {
    if (params && !params.open) {
      this.handelClearBodyStyle();
    }
    this.setState({ resultFunctionParam: { ...params, setResultFunctionParam: this.handelResultFunctionParamSet }, isRpaOpen: params.open })
  }

  handelClearBodyStyle = () => {
    document.body.style.removeProperty("min-width");
    document.body.style.removeProperty("overflow");
  };

  handelShowPredict = (messagefirst, messageSecord) => {
    if (!this.state.isPredictLoading) {
      this.setState({
        targetBbox: null
      })
    }
    this.setState({
      isPredictLoading: !this.state.isPredictLoading,
      messagefirst: messagefirst,
      messageSecord: messageSecord
    })
  }

  handleShowExclusionControl = (status) => {
    this.setState({ isExclusionControlShow: status });
  }

  handleShowFileUpdateError = (status) => {
    this.setState({ isFileUpdateErrorShow: status });
  }

  handleShowUpdateErrorDialog = async (error) => {
    const { pdfInfo } = this.state;
    const { onChangeOwnerStatus } = this.props;
    if (error.status === 463) {
      if (this.ThumbnailRef && this.ThumbnailRef.current) {
        await this.ThumbnailRef.current.onLoad(true);
      }
      onChangeOwnerStatus(true);
      this.handleShowExclusionControl(true);
    }

    if (error.status === 464) {
      if (pdfInfo.lastEdited !== error.data.lastEdited) {
        this.handleShowFileUpdateError([true, error.data.lastEditedId, error.data.lastEditedName, error.data.lastEdited]);
      }
    }
  }

  toggleRePredict = (isRePredictShow, pageType) => {
    this.setState({
      isRePredictShow: isRePredictShow,
      pageType: pageType
    });
    if (!isRePredictShow) {
      this.setState({
        isSelectTargetBbox: false,
        targetBbox: null,
        pageRereadLoading: false
      });
    }
  }

  handleRePredictSelectBbox = (isSelectTargetBbox, targetBbox = null) => {
    if (targetBbox) {
      this.PopupRef &&
        this.PopupRef.current &&
        this.PopupRef.current.leafletElement.options.leaflet.map.closePopup();

      this.context.sendPredictBeautifyDetailMetrics({
        event_type: EventType.Click,
        event_name: EventName.RereadLineselect,
      })
    }
    this.setState({
      isSelectTargetBbox: isSelectTargetBbox,
      targetBbox: targetBbox,
      pageRereadLoading: isSelectTargetBbox
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // if pdf in inference/parsing, show the loading page
    if (prevState.pdfStatus !== this.state.pdfStatus && (this.state.pdfStatus !== 1 && this.state.pdfStatus !== 2)) {
      window.clearInterval(this.state.interval)
    }
  }

  componentWillUnmount() {
    document.body.style.overflowY = '';
  }

  handleGetPdfStatus = async () => {
    const { projectId, pdfFileId } = this.props.match.params;
    const pdf = await api.request({
      url: `/dppapi/predict/${projectId}/pdfs/${pdfFileId}`,
      method: 'GET',
    });
    this.setState({ pdfStatus: pdf.data.stateFlag, pdfInfo: pdf.data, currentPage: pdf.data.images[0].id });
    // get pdf status every 5 seconds if pdf in inference/parsing
    if (pdf.data.stateFlag === 1 || pdf.data.stateFlag === 2) {
      const interval = window.setInterval(async () => {
        const { projectId, pdfFileId } = this.props.match.params;
        const { pdfStatus } = this.state;
        const pdf = await api.request({
          url: `/dppapi/predict/${projectId}/pdfs/${pdfFileId}`,
          method: 'GET',
        });
        if (pdfStatus !== pdf.data.stateFlag) {
          this.setState({ pdfStatus: pdf.data.stateFlag });
          // if pdf status turn to inference finished, reload pdf.
          if (pdf.data.stateFlag === 3 && this.state.isPredictLoading) {
            // set rePredict false to update menubar time
            this.props.handleChangeRePredict(false)
            this.handelShowPredict();
            await this.handleImgReload()
            this.handleSetHeaderInfo([pdf.data.lastEdited, pdf.data.lastEditedId, pdf.data.lastEditedName]);
            // show success after image reload, CanvasRef.current may not mount, use interval until CanvasRef.current mount.
            var sucessInterval = window.setInterval(() => {
              if (this.CanvasRef && this.CanvasRef.current) {
                this.CanvasRef.current.showRereadSuccess();
                clearInterval(sucessInterval);
              }
            }, 200)
          }
        }
      }, 5000)
      this.setState({
        interval: interval
      })
    }
  }

  handleUnuseImage = async () => {
    const { projectId, pdfFileId } = this.props.match.params;
    const { pdfInfo } = this.state;
    try {
      const unUseImages = pdfInfo.images.filter(img => this.ThumbnailRef.current.props.currentPage.toString() === img.id.toString() && img.imageStatus && img.imageStatus.inUse)
      if (unUseImages && unUseImages.length > 0) {
        const unuseImagesList = unUseImages.map(img => {
          img.imageStatus = {
            ...img.imageStatus,
            inUse: false
          }
          return {
            imageId: img.id,
            imageStatus: img.imageStatus
          }
        })
        const data = await (await api.request(
          {
            url: `/dppapi/predict/image/status/${projectId}/${pdfFileId}`,
            method: 'POST',
            data: {
              images: unuseImagesList,
              prevLastEdited: pdfInfo.lastEdited
            },
          },
        )).data;
        if (data.success) {
          this.handleSetHeaderInfo(data.lastEditInfo);
        }
      }
      this.setState({ pdfInfo: pdfInfo });
    } catch (error) {
      this.handleShowUpdateErrorDialog(error);
    }
  }

  handleImgReload = async () => {
    await this.ThumbnailRef.current.onLoad(true);
    await this.TextMappingRef.current.initialComponent();

    const imglist = this.ThumbnailRef.current.state.imageList;
    const pageClassification = this.ThumbnailRef.current.state.pageClassification;
    const selectedImg = imglist.find(d => d.pageId.toString() === pageClassification.pageId.toString());
    this.ThumbnailRef.current.clickImg({ pageId: null });
    this.ThumbnailRef.current.clickImg(selectedImg);
  }

  handleGetTenantPreference = async () => {
    const res = await api.post(`/dppapi/predict/tenant/preference/all`);
    let tenantPreference = {};
    if (res.data) {
      res.data.forEach((preference) => {
        tenantPreference = {
          ...tenantPreference,
          [`${preference.name}_${preference.subName}`]: preference.value,
        }
      })
      this.setState({ tenantPreference: tenantPreference });
    }
  };

  handleEffectiveHotkeyPageChange = (page) => {
    this.setState({ effectiveHotkeyPage: page })
  }

  render() {
    const {
      currentPage,
      oldCurrentPage,
      selectedCell,
      linking,
      shortKeyPanelVisible,
      selectedBBox,
      leftWidth,
      resultFunctionParam,
      isRpaOpen,
      manualInput,
      showType,
      tenantId,
      isPredictLoading,
      isSelectTargetBbox,
      pdfStatus,
      messagefirst,
      messageSecord,
      tenantPreference,
      selectFlag,
      effectiveHotkeyPage,
      pdfInfo,
    } = this.state;
    const { isEditing,
      user,
      isNotOwner,
      onChangeOwnerStatus,
      onGetUser,
    } = this.props;
    const { open } = resultFunctionParam
    const { projectId, pdfFileId } = this.props.match.params;
    document.body.style.overflowY = 'hidden';
    const viewType = JSON.parse(
      getCookie('viewType' + tenantId) || '{}'
    );
    var leftChild = document.getElementById('leftChild');
    var rightChild = document.getElementById('rightChild');
    if (viewType && viewType[showType] && leftChild) {
      leftChild.style.width = viewType[showType].leftWidth;
      rightChild.style.width = viewType[showType].rightWidth;
    }

    const pdfOwner = pdfInfo && pdfInfo.userInCharge;
    const isExclusionControl = !_.isEmpty(tenantPreference) && tenantPreference.exclusion_control_exclusion_control_status === "true";
    let currentUser = user && user.id;
    if (!currentUser) {
      onGetUser();
    }
    if (isExclusionControl && !isNotOwner && pdfOwner && currentUser && pdfOwner !== currentUser) {
      onChangeOwnerStatus(true);
    }

    return (
      <>
        {
          (pdfStatus === 1 || pdfStatus === 2) && !isPredictLoading ?
            <Dimmer active inverted>
              <Loader active inline="centered">AI処理中...</Loader>
            </Dimmer>
            :
            <HotKeyPanel shortKeyPanelVisible={shortKeyPanelVisible}>
              <div className="pdf-portal-root">
                {isPredictLoading && <Dimmer active inverted>
                  <Loader active inline="centered">{messagefirst}<br />{messageSecord}</Loader>
                </Dimmer>}
                <div id="father" className="pdf-portal-father">
                  <div id="leftChild" className={!open ? 'mapping-request-portal-left-child' : 'pdf-portal-left-child-rf'} onClick={() => this.handleEffectiveHotkeyPageChange('textmapping')}>
                    {!_.isEmpty(tenantPreference) &&
                      <FsMapping
                        onPageChange={this.handlePageChange}
                        onRowSelect={this.handleRowSelect}
                        ref={this.TextMappingRef}
                        currentPage={currentPage}
                        projectId={projectId}
                        pdfFileId={pdfFileId}
                        pdfInfo={this.state.pdfInfo}
                      />
                    }
                  </div>
                  <div
                    id="line"
                    className="pdf-portal-line"
                    onMouseDown={this.onMouseDown}
                  >
                    <div id="ga-predict-detail-panel-drag">
                      <Icon name="triangle left" />
                      <Icon name="triangle right" />
                    </div>
                  </div>
                  <div id="rightChild" className={`'mapping-request-portal-right-child' ${!open ? '' : 'pdf-portal-right-child-rf'}`} onClick={() => this.handleEffectiveHotkeyPageChange('canvas')}>
                    <PdfLoader
                      canvasRef={this.CanvasRef}
                      muiPopupRef={this.MuiPopupRef}
                      ref={this.PdfLoaderRef}
                      currentPage={currentPage}
                      oldCurrentPage={oldCurrentPage}
                      selectedCell={selectedCell}
                      linking={linking}
                      manualInput={manualInput}
                      textMappingRef={this.TextMappingRef}
                      projectId={projectId}
                      pdfFileId={pdfFileId}
                      onShortKeyPanelVisible={this.handleShortKeyPanelVisible}
                      onSelectBox={this.handleSelectBox}
                      popupRef={this.PopupRef}
                      selectedBBox={selectedBBox}
                      onSelectedBBoxChange={this.handleSelectedBBoxChange}
                      leftWidth={leftWidth}
                      isEditing={isEditing}
                      thumbnailRef={this.ThumbnailRef}
                      isRpaOpen={isRpaOpen}
                      handelShowPredictLoading={this.handelShowPredict}
                      toggleRePredict={this.toggleRePredict}
                      isSelectTargetBbox={isSelectTargetBbox}
                      handleRePredictSelectBbox={this.handleRePredictSelectBbox}
                      selectTargetBbox={this.state.targetBbox}
                      selectFlag={selectFlag}
                      handleChangeSelectFlag={this.handleChangeSelectFlag}
                      effectiveHotkeyPage={effectiveHotkeyPage}
                      handleUnuseImage={this.handleUnuseImage}
                      isNotOwner={isNotOwner}
                      onShowExclusionControl={this.handleShowExclusionControl}
                      onSetHeaderInfo={this.handleSetHeaderInfo}
                      onShowUpdateErrorDialog={this.handleShowUpdateErrorDialog}
                      pdfInfo={pdfInfo}
                    />
                  </div>
                </div>
              </div>
            </HotKeyPanel>
        }
      </>
    );
  }
}

