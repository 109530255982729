import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Container, Menu, Icon, Dropdown, Checkbox, Image, Button, Popup, PopupHeader, PopupContent } from 'semantic-ui-react';
import Dialog from './Dialog';
import intl from 'react-intl-universal';
import ja from '../locales/ja.js';
import en from '../locales/en.js';
import api from '../api';
import { unixMSToDatetime } from '../predict/TimeUtil';
import './Menubar.css';
import InsertCommentOutlinedIcon from '@material-ui/icons/Comment';
import { DoneSaveIcon, ErrorIcon } from '../assets/icon';
import { EventName, EventType, PageName } from './Analytics/analyticsMetrics';
import { AnalyticsServiceContext } from './Analytics/AnalyticsServiceProvider';

const MENU_MAX_COUNT = 7;

class Menubar extends Component {
  constructor(props) {
    super(props);
    const showHelpFlag = origin.endsWith('.i.smfl.co.jp');
    this.state = {
      loginVisible: false,
      permission: '',
      headerData: null,
      isShow: false,
      showHelpFlag
    };
    this.handleClickHome = this.handleClickHome.bind(this);
    this.logout = this.logout.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const pathname = this.props.location.pathname;
    const prevPathname = prevProps.location.pathname;
    if (pathname !== prevPathname || 
      (this.props.isEditing === false && this.props.isEditing !== prevProps.isEditing) ||
      (this.props.isRePredict === false && this.props.isRePredict !== prevProps.isRePredict)) {
      await setTimeout(async () => {
        await this.getProjectData();
      }, 100);
    }
  }

  async componentWillMount() {
    await this.getLanguageButtonShow();
    window.addEventListener('beforeunload', this.handleEditConfirm);
  }

  async componentDidMount() {
    await this.getProjectData();
    const passTwoStageAuthentication = localStorage.getItem("passTwoStageAuthentication")

    const info = this.props.location.pathname !== '/login' ? (await api.get(`/dppapi/info`)).data : null;

    // doesn't need two-stage auth: passTwoStageAuthentication === null
    // need two-stage auth but doesn't pass: passTwoStageAuthentication === false 
    // need two-stage auth and pass: passTwoStageAuthentication === true 
    if (info && passTwoStageAuthentication !== 'false' && this.props.location.pathname !== '/login') {
      const per = localStorage.getItem("permission") || '';
      this.setState({
        loginVisible: true,
        permission: per,
        user: info ? info : ''
      })
    } else {
      this.setState({
        loginVisible: false,
      });
    }
  }

  componentWillUnmount(){
    window.removeEventListener('beforeunload', this.handleEditConfirm);
  }

  getMetadata = async (projectId) => {
 
    const { metadatas, success } = (await api.get(
      `/dppapi/predict/prediction/metadata/query?projectId=${projectId}&category=fs_prediction`
    )).data;

    if (success) {
      const res = metadatas.find(
        mt => mt.subCategory === 'ui' && mt.item === 'show_experience_switch'
      );
      let showExperienceSwitch = 0

      if (res) {
        showExperienceSwitch = Number(res.value)
      }

      this.setState({showExperienceSwitch});

    }
  }

  async getProjectData() {
    const reg = new RegExp('/predict/detail/([0-9]*)/([0-9]*)');
    const pathname = this.props.location.pathname;
    const isDetailPredict = pathname.match(reg);

    if (isDetailPredict) {
      const projectId = isDetailPredict[1];
      const pdfFileId = isDetailPredict[2];
      const pdf = await api.get(
        `/dppapi/predict/${projectId}/pdfs/${pdfFileId}`
      );
      if (pdf && pdf.data) {
        this.setState({ headerData: pdf.data });
      }

      this.getMetadata(projectId)
    } else {
      this.setState({ headerData: null });
    }
  }

  async logout() {
    const r = await api.get('/dppapi/logout');
    if (r.status === 200) {
      localStorage.clear()
      this.state = {}
      this.props.history.push('/login')
    }
  }

  setLang = async (lang = 'ja') => {
    intl.init({
      currentLocale: lang,
      locales: {
        ja,
        en
      }
    })

    localStorage.setItem('locales', lang);
    window.location.reload();
  }

  handleClickHome() {
    const {user} = this.state;
    const url = localStorage.getItem('url');

    this.props.history.push(user ? url : '/login');
  }

  handleEditConfirm = (e)=> {
    if(this.props.isEditing){
      e.preventDefault();
    }
  }

  goBack(sendPredictBeautifyDetailMetrics) {

    const { isEditing } = this.props;
    const url = this.props.match.url;
    if(this.props.match.url === "/predict"){
      sendPredictBeautifyDetailMetrics({
        event_type: EventType.PageView,
        event_name: EventName.PageTransition,
        custom_parameter: {
          from : PageName.PredictDetailPage,
          to : PageName.PredictPage,
        },
      })
    }
    if(isEditing){
      window.location.href = url;
    }else{
      this.props.history.push(url);
    }

  }

  renderChildren = (children) => {
    const location = (this.props.location && this.props.location.pathname) || ""
    if (location.indexOf('/predict') !== -1 || location === '/login') {
      return children
    } else {
      return <Container>{children}</Container>
    }
  }

  getLanguageButtonShow = async () => {
    const { languageButtonShow } = (await api.get('/dppapi/language-button-show')).data;
    this.setState({ languageButtonShow: languageButtonShow});
  }

  render() {
    const { loginVisible, permission, user, headerData, languageButtonShow, showHelpFlag } = this.state;
    const { active, isEditing, isNewExperience, onExperienceChange, onCommentChange, isShowComment, isNotOwner, lastEditorInfo } = this.props;
    const isLoginPath = window.location.href.includes('/login');
    var menuList = [];

    permission.split(',').forEach((item)=>{
      item = parseInt(item)
      if(item === 1)
      {
        menuList.push({ linkTo: '/project/', active: 'project', iconName: 'pencil', title: intl.get('_common.Menubar.Project') })
      }
      else if(item === 2)
      {
        menuList.push({ linkTo: '/label/', active: 'label', iconName: 'tag', title: intl.get('_common.Menubar.Label') })
      }
      else if(item === 3)
      {
        menuList.push({ linkTo: '/demo/', active: 'demo', iconName: 'diamond', title: intl.get('_common.Menubar.Demo') })
      }
      else if(item === 4)
      {
        menuList.push({ linkTo: '/predict/', active: 'predict', iconName: 'cogs', title: intl.get('_common.Menubar.Predict') })
      }
      else if(item === 5)
      {
        menuList.push({ linkTo: '/user/', active: 'user', iconName: 'user', title: intl.get('_common.Menubar.User') })
      }
      // else if(item === 6)
      // {
      //   menuList.push({ linkTo: '/monitor/', active: 'monitor', iconName: 'chart area', title: 'Monitor' })
      // }
      else if(item === 7)
      {
        menuList.push({ linkTo: '/train/', active: 'train', iconName: 'cube', title: intl.get('_common.Menubar.Train') })
      }
      else if(item === 8)
      {
        menuList.push({ linkTo: '/system/', active: 'system', iconName: 'cog', title: intl.get('_common.Menubar.System') })
      }
      else if(item === 9)
      {
        menuList.push({ linkTo: '/easyocr/', active: 'easyocr', iconName: 'etsy', title: intl.get('_common.Menubar.EasyOcr') })
      }
      else if(item === 11)
      {
        menuList.push({ linkTo: '/setting/', active: 'setting', iconName: 'cog', title: intl.get('_common.Menubar.TenantSetting') })
      }
    })
    var helpLink = isLoginPath ? '/login' : '/help';
    if(showHelpFlag || isLoginPath){
      menuList.push({ linkTo: helpLink, active: 'help', iconName: 'help circle', title: intl.get('_common.Menubar.Help') })
    }
  
    const menuItemCls = localStorage.getItem('locales')==='en' ? 'menu-bar-item-en' : 'menu-bar-item-ja';
    const langDropdownCls = localStorage.getItem('locales')==='en' ? 'menu-bar-lang-dropdown-en' : 'menu-bar-lang-dropdown-ja';
    let isNotLastEditor = false;
    if (lastEditorInfo && lastEditorInfo.length > 0) {
      if (headerData && this.props.user && lastEditorInfo[0] !== this.props.user.id) {
        isNotLastEditor = true;
      }
    } else {
      if (headerData && this.props.user && headerData.lastEditedId !== this.props.user.id) {
        isNotLastEditor = true;
      }
    }
    
    return (
      <div style={{ background: '#f7f7f7', minHeight: '100vh' }}>
        <Menu inverted style={{ backgroundColor: '#053e81', height: '45px' }}>
          <Container style={{ width: '100%', padding: 0, marginBotom: '10px' }}>
            <Dialog {...this.state.commonDialog} />
            {headerData ? (
              <>
              <AnalyticsServiceContext.Consumer>
              { ({ sendPredictBeautifyDetailMetrics }) => (
                <Menu.Item
                  className="go-back-menu-item"
                  header
                  onClick={() => this.goBack(sendPredictBeautifyDetailMetrics)}
                >
                  <Icon name="chevron left" />
                </Menu.Item>
                )}
              </AnalyticsServiceContext.Consumer>
                <Menu.Item className="project-info">
                  <div className="project-info-title">
                    {headerData.originalName}
                  </div>
                  <div className={isNotOwner ? "status-not-owner" : isEditing ? "status-state-flag-editing" : "status-state-flag"}>
                    {/*{headerData.stateFlag === 4 ? (*/}
                    {/*  <Popup*/}
                    {/*    content={headerData.errmsg}*/}
                    {/*    trigger={*/}
                    {/*      <div style={{ paddingBottom: '10px' }}>*/}
                    {/*        {getStateFlag(headerData.stateFlag)}*/}
                    {/*      </div>*/}
                    {/*    }*/}
                    {/*  />*/}
                    {/*) : (*/}
                    {/*  getStateFlag(headerData.stateFlag)*/}
                    {/*)}*/}
                    {!isNotOwner && !isEditing ? <DoneSaveIcon size='12px' /> : null}
                    {isNotOwner ? '編集不可' : isEditing ? '未保存' : '保存済'}
                  </div>
                  <div className="datetime-update-pdf">
                    最終保存:
                    {unixMSToDatetime(
                      lastEditorInfo.length > 0 ? lastEditorInfo[2] : headerData.lastEdited,
                      intl.get('_common.Menubar.TimeLocal')
                    )}
                    ｜{lastEditorInfo.length > 0 ? lastEditorInfo[1] : headerData.lastEditedName}
                  </div>
                  {isNotLastEditor &&
                    <Popup
                      on='hover'
                      position='bottom center'
                      inverted
                      trigger={
                        <div><ErrorIcon color='#E8BA00' size='20px' /></div>
                      }
                    >
                      <PopupHeader className="error-icon-popup-header">他のユーザーが最後に保存しました</PopupHeader>
                      <PopupContent className="error-icon-popup-content">
                        最終保存者:&nbsp;&nbsp;&nbsp;{lastEditorInfo.length > 0 ? lastEditorInfo[1] : headerData.lastEditedName}
                      </PopupContent>
                    </Popup>
                  }
                </Menu.Item>
              </>
            ) : (
              <>
                {!isLoginPath ?
                  <Menu.Item header onClick={this.handleClickHome}>
                    <Image src='/assets/img/logo_white.svg'/>
                  </Menu.Item> : null
                }
                {menuList.map((item, index) => {
                  return (
                    index < MENU_MAX_COUNT && (
                      isLoginPath && item.title === intl.get('_common.Menubar.Help')?
                      <Link key={index} to={item.linkTo}>
                        <Menu.Item active={active === item.active} className={`${menuItemCls}`}>
                          <Image src='/assets/img/logo_white.svg'/>
                        </Menu.Item>
                      </Link>:
                      <Link key={index} to={item.linkTo} target={item.active === 'help' ? '_blank' : ''}>
                        <Menu.Item active={active === item.active} className={`${menuItemCls}`}>
                          <Icon
                            name={item.iconName}
                            style={{ marginRight: '5px' }}
                          />
                          {item.title}
                        </Menu.Item>
                      </Link>
                    )
                  );
                })}
                {menuList.length > MENU_MAX_COUNT ? (
                  <Dropdown item icon="angle double right">
                    <Dropdown.Menu style={{ background: '#053e81' }}>
                      {menuList.map((item, index) => {
                        return (
                          index >= MENU_MAX_COUNT && (
                            <Link
                              key={index}
                              to={item.linkTo}
                              target={item.active === 'help' ? '_blank' : ''}
                            >
                              <Dropdown.Item active={active === item.active} className={`${menuItemCls}`}>
                                <Icon
                                  name={item.iconName}
                                  style={{ marginRight: '5px' }}
                                />
                                {item.title}
                              </Dropdown.Item>
                            </Link>
                          )
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : null}
              </>
            )}
          </Container>
          { this.props.location.pathname.indexOf('/predict/detail') !== -1 && headerData && headerData.comment && <Button
              icon
              onClick={() => onCommentChange()}
              className={isShowComment ? "menu-comment-selected-btn":"menu-comment-show-btn"}
            >
              <InsertCommentOutlinedIcon/>
            </Button>}
          { this.props.location.pathname.indexOf('/predict/detail') !== -1 && this.state.showExperienceSwitch ? <div className="experience-change">
            <span>New Experience</span>
            <Checkbox id= "ga-predict-detail-ui-switch" toggle checked={!!isNewExperience} onChange={() => onExperienceChange()}/>
          </div> : null }
          {languageButtonShow && languageButtonShow === 'true' ? <Dropdown
            item
            text={intl.get('_common.Menubar.Language')}
            direction="left"
            className={`${langDropdownCls}`}
          >
            <Dropdown.Menu style={{ background: '#053e81' }}>
              <Link to="#" onClick={() => this.setLang('ja')}>
                <Dropdown.Item>
                  {intl.get('_common.Menubar.Japanese')}
                </Dropdown.Item>
              </Link>
              <Link to="#" onClick={() => this.setLang('en')}>
                <Dropdown.Item>
                  {intl.get('_common.Menubar.English')}
                </Dropdown.Item>
              </Link>
            </Dropdown.Menu>
          </Dropdown> : null}
          {loginVisible ? (
            <Dropdown item text={user.name} direction="left" className='menu-bar-lang-dropdown-ja'>
              <Dropdown.Menu style={{ background: '#053e81' }}>
                {/* <Link to="/access-key/reset">
                  <Dropdown.Item>
                    <Icon name="cog" />
                    {intl.get('_common.Menubar.ResetAccessKey')}
                  </Dropdown.Item>
                </Link> */}
                <Link to="/password/reset">
                  <Dropdown.Item>
                    <Icon name="key" />
                    {intl.get('_common.Menubar.Resetpassword')}
                  </Dropdown.Item>
                </Link>
                <Link to="" onClick={this.logout}>
                  <Dropdown.Item>
                    <Icon name="sign out alternate" />
                    {intl.get('_common.Menubar.Logout')}
                  </Dropdown.Item>
                </Link>
              </Dropdown.Menu>
            </Dropdown>
          ) : null}
        </Menu>
        {this.renderChildren(this.props.children)}
      </div>
    );
  }
}

export default withRouter(Menubar);
