import React, { useEffect, useState } from 'react';
import {
  Segment,
  Form,
  Button,
  Divider,
  Loader,
  Image,
  Popup,
  Icon,
} from 'semantic-ui-react';
import { MDBDataTable } from 'mdbreact';
import intl from 'react-intl-universal';
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import api from '../../api';
import './index.css';
import ImagePreview from './ImagePreview'
import { Link } from 'react-router-dom';
import { same, hash } from '../../common/ImageUtil'

const ffmpeg = createFFmpeg({
  log: true,
  corePath: '/ffmpeg-core/ffmpeg-core.js',
});
// import './index.css'

export default function UploadVideo({ projectId }) {
  const [ready, setReady] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [video, setVideo] = useState(null);
  const [startPosition, setStartPosition] = useState('00:00:00');
  const [fps, setFps] = useState(1);
  const [outputImgCount, setOutputImgCount] = useState(10);
  const [imgList, setImgList] = useState([]);
  const [openPreview, setOpenPreview] = useState(false);
  const [previewImageName, setPreviewImageName] = useState(false);

  const load = async () => {
    if (!ffmpeg.isLoaded()) {
      await ffmpeg.load();
    }

    setReady(true);
  };

  useEffect(() => {
    load();
  }, []);

  const genImgDomByImg = (img) => {
    return new Promise(function(resolve) {
      const dom = new window.Image();
      dom.src = img.link;
      dom.onload = () => {
        resolve(dom)
      }
    })
  }

  const handleDuplicateFilter = async () => {
    if (imgList && imgList.length > 0) {
      setReady(false);
      
      const tmpImgList = [];
      for (let [imgIndex, img] of imgList.entries()) {
        const dom = await genImgDomByImg(img)
        tmpImgList.push({
          ...img,
          fingerprint: hash(dom)
        })
        setLoadingText(Math.floor(((imgIndex)/imgList.length)*100) + '%');
      }

      const imgSet = [];
      for(let img of tmpImgList) {
        
        let index = -1;
        for(let [i, sImg] of imgSet.entries()) {
          // console.debug('========================')
          // console.debug('comparing images: ' ,img, sImg);
          if (same(img.fingerprint, sImg.fingerprint)) {
            index = i;
            break;
          }
        }

        if (index < 0) {
          imgSet.unshift(img)
        }
      }
      setImgList(imgSet.reverse());

      setLoadingText('');
      setReady(true);
    }
  }

  const handleVideoFileChange = (e) => {
    const file = e.target.files ? e.target.files[0] : null;
    setVideo(file);
  };

  const handleImageClick = (imgName) => {
    setOpenPreview(true);
    setPreviewImageName(imgName);
  }

  const handleProcessVideo = async () => {
    if (
      !video ||
      !fps ||
      fps === '' ||
      !outputImgCount ||
      outputImgCount === ''
    ) {
      return;
    }

    setReady(false);

    ffmpeg.setProgress(({ ratio }) => {
      setLoadingText(Math.floor((ratio >= 0 ? ratio : 0) * 100) + '%')
    });

    const name = video.name;
    const outName = name.substring(0, name.indexOf('.'));
    ffmpeg.FS('writeFile', name, await fetchFile(video));
    const command = `-i,${name},-ss,${startPosition},-vf,fps=${parseFloat(fps).toString()},${parseInt(outputImgCount) > 0 ? '-vframes,' + parseInt(outputImgCount).toString() : ''},${outName}_%d.jpg`
    await ffmpeg.run(...(command.split(',')));
    await ffmpeg.FS('unlink', name);

    const tmpImgList = [];
    let i = 1;
    while(true) {

      try {
        let imgName = `${outName}_${i}.jpg`;
        let data = await ffmpeg.FS('readFile', imgName);
        await ffmpeg.FS('unlink', imgName);
        let blob = new Blob([data.buffer], { type: 'image/jpg' });
        let imgSrc = URL.createObjectURL(blob);
        tmpImgList.push({
          index: i - 1,
          uploaded: false,
          name: imgName,
          blob: blob,
          link: imgSrc
        });
      } catch (e) {
        break;
      }
      
      i++;
    }
    setImgList(tmpImgList);

    setReady(true);
  };

  const handleDelete = (imgName) => {
    setImgList(imgList.filter((img) => img.name !== imgName));
  };

  const uploadImages = async () => {
    if (!imgList || imgList.length === 0) {
      return;
    }

    setReady(false);
    for (let [i, img] of imgList.entries()) {
      setLoadingText(Math.floor(((i + 1)/imgList.length)*100) + '%');
      const formData = new FormData();
      formData.append('images', img.blob, img.name);
      try {
        await api.post(`/dppapi/labeling/${projectId}/uploads`, formData);
        img.uploaded = true;
      } catch {
        continue;
      }
    }
    const tmpImgList = imgList.filter((img) => !img.uploaded);
    setImgList(tmpImgList);

    setLoadingText('')
    setReady(true);
  };

  const columns = [
    {
      label: intl.get('_label.image.UploadVideo.Image'),
      field: 'data',
      sort: 'asc',
    },
    {
      label: intl.get('_label.image.UploadVideo.ImageName'),
      field: 'imgName',
      sort: 'asc',
    },
    {
      label: intl.get('_user.user.User.Operation'),
      field: 'operation',
      sort: 'asc',
      width: 200,
    },
  ];

  const imgData = {
    columns: columns,
    rows: imgList.map(img => {
      return {
        ...img,
        imgName: <Link to={"#"} onClick={()=>{handleImageClick(img.name)}}>{img.name}</Link>,
        data: (
          <Popup
            trigger={
              <Image
                src={img.link}
                size="mini"
                alt={img.name}
                href={'#'}
                onClick={()=>{handleImageClick(img.name)}}
              />
            }
            mouseEnterDelay={500}
            position="top right"
            // inverted
          >
            <Popup.Content>
              <Image src={img.link} alt={img.name} size="medium" rounded />
            </Popup.Content>
          </Popup>
        ),
        operation: (
          <div>
            <Button
              icon="trash"
              label="Delete"
              size="tiny"
              onClick={() => {
                handleDelete(img.name);
              }}
            />
          </div>
        ),
      }
    }),
  };

  return ready ? (
    <Segment className="upload-video">
      <Form>
        <Form.Input
          label={intl.get('_label.image.UploadVideo.SelectVideo')}
          type="file"
          id="video"
          name="video"
          accept=".mp4, .wmv, .avi, .mov"
          onChange={handleVideoFileChange}
        />
        <Divider />
        <Form.Field>
          <label>{intl.get('_label.image.UploadVideo.SplitToImages')}</label>
        </Form.Field>
        <Form.Group inline>
          <Form.Input
            label={intl.get('_label.image.UploadVideo.StartPosition')}
            className="cut-video-input"
            placeholder="e.g. 00:00:01"
            value={startPosition}
            onChange={(e) => {
              const positionRe = /^\d{1,2}:\d{1,2}:\d{1,2}$/;
              if (positionRe.test(e.target.value || '')) {
                setStartPosition(e.target.value);
              }
            }}
          />
          <Form.Input
            label={intl.get('_label.image.UploadVideo.FPS')}
            className="cut-video-input"
            placeholder="e.g. 1"
            value={fps}
            onChange={(e) => {
              setFps((e.target.value || '').replace(/[^.\d]/g, ''));
            }}
          />
          <Form.Input
            label={intl.get('_label.image.UploadVideo.MaxCount')}
            className="cut-video-input"
            placeholder="e.g. 10"
            value={outputImgCount}
            onChange={(e) => {
              setOutputImgCount((e.target.value || '').replace(/[^\d]/g, ''));
            }}
          />
          <Form.Field>
            <Button.Group>
              <Button
                onClick={() => {
                  handleProcessVideo();
                }}
                basic={true}
                color="orange"
              >
                <Icon name="cut" />
                {intl.get('_label.image.UploadVideo.CutVideo')}
              </Button>
              <Button
                onClick={() => {
                  uploadImages();
                }}
                basic={true}
                color="black"
              >
                <Icon name="hdd" />
                {intl.get('_label.image.UploadVideo.SaveImages')}
              </Button>
            </Button.Group>
          </Form.Field>
        </Form.Group>
      </Form>
      <React.Fragment>
        <Button.Group floated='right' size="mini" style={{padding: "7px"}}>
          <Button
            // basic
            color='black'
            icon='filter'
            label={{ as: 'a', basic: true, icon: 'images', content: intl.get('_label.image.UploadVideo.Duplicate'), color: 'black' }}
            labelPosition='right'
            onClick={()=>{ handleDuplicateFilter(); }}
          />
        </Button.Group>
        <MDBDataTable btn striped bordered hover data={imgData} />
      </React.Fragment>
      <ImagePreview openPreview={openPreview} imgList={imgList} previewImageName={previewImageName} setOpenPreview={setOpenPreview} setPreviewImageName={setPreviewImageName} handleDelete={handleDelete}/>
    </Segment>
  ) : (
    <Loader active inline="centered">{loadingText}</Loader>
  );
}
