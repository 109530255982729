import React, { Component } from 'react';
import { MDBDataTable } from 'mdbreact';
import { Button, Segment, Loader, Icon, Popup, Header } from 'semantic-ui-react';
import { confirmAlert } from 'react-confirm-alert';
import { getStateFlag } from './Constant';
import TrainAdd from './TrainAdd';
import api from '../api';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom'

export default class TrainTable extends Component {
  state = {
    rows: [],
    editTrain: {},
    isShowEdit: false,
    isLoading: false,
    changeId: null,
    project: null,
    modules:[],
  };

  constructor(props) {
    super(props);

    this.getTrains = this.getTrains.bind(this);
    this.refetch = this.refetch.bind(this);
    this.trainDatasToRows = this.trainDatasToRows.bind(this);
    this.handleTraining = this.handleTraining.bind(this);
    this.handleCheckout = this.handleCheckout.bind(this);
    this.handleDel = this.handleDel.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
  }

  async componentDidMount() {
    const { projectId } = this.props.match.params;
    const project = (await api.get(`/dppapi/train/${projectId}/project`)).data;
    const modules = (await api.patch('/dppapi/train/modules')).data;
    this.setState({
      project,
      modules,
    });
    await this.getTrains();
  }

  async getTrains() {
    const { projectId } = this.props.match.params;
    const trainDatas = (await api.get(`/dppapi/train/${projectId}`)).data;

    let rows = [];
    if (trainDatas) {
      rows = trainDatas.map((train) => {
        return this.trainDatasToRows(projectId, train);
      });
    }

    this.setState({
      rows: rows,
    });
  }

  async refetch() {
    let { rows, changeId } = this.state;
    const { projectId } = this.props.match.params;
    const train = (await api.get(`/dppapi/train/${projectId}/${changeId}`)).data;
    if (train.id) {
      rows = rows.map(row => {
        if (train.id === row.id) {
          return this.trainDatasToRows(projectId, train);
        } else {
          return row;
        }
      })
    } else {
      rows = rows.filter(row => {
        return changeId !== row.id;
      })
    }
    this.setState({
      init: false,
      rows: rows,
      changeId: null,
    });
  }
  
  trainDatasToRows(projectId, train) {
    return Object.assign({}, train, {
      id: train.id,
      trainName: (
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {train.description ? (
            <Popup
              trigger={(
                <div>
                  <Link 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    style={{ wordBreak: 'break-all' }} 
                    to={`/train/${projectId}/${train.id}`}
                  >
                    <u>{train.name}</u>
                  </Link>
                </div>
              )}
              content={<p>{train.description}</p>}
              position="right center"
              mouseEnterDelay={500}
              hoverable
              onClick={(e) => e.stopPropagation()}
              inverted
            >
            </Popup>
          ) : (
            <div>
              <Link 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ wordBreak: 'break-all' }} 
                to={`/train/${projectId}/${train.id}`}
              >
                <u>{train.name}</u>
              </Link>
            </div>
          )}
        </div>
      ),
      name: train.name,
      operator: train.userInChargeName,
      module: train.moduleName,
      status: getStateFlag(train.status),
      labels: train.labelForms,
      auto: (
        <Button
          icon="cube"
          label={intl.get('_train.TrainTable.Train')}
          size="mini"
          onClick={() => this.handleTraining(train)}
        />
      ),
      operation: (
        <div>
          <Button 
            icon="edit"
            label={intl.get('_train.TrainTable.Edit')}
            size="mini"
            onClick={() => this.handleAdd({...train, labelForms: train.labelForms.split(',')})}/>
          <Button
            icon="user"
            label={intl.get('_train.TrainTable.Person')}
            size="mini"
            onClick={() => this.handleCheckout(train)}
          />
          <Button
            icon="trash"
            label={intl.get('_train.TrainTable.Delete')}
            size="mini"
            onClick={() => this.handleDel(train)}
          />
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={`/train/${projectId}/${train.id}`}
          >
            <Button 
              icon="server"
              label={intl.get('_train.TrainTable.Detail')}
              size="mini" />
          </Link>
        </div>
      ),
    });
  }

  handleAdd(train) {
    this.toggleEdit({
      isShowEdit: true,
      editTrain: train
    });
  }

  async handleTraining(train) {
    confirmAlert({
      title: 'トレーニング',
      message: 'トレーニングを開始しますか？',
      buttons: [
        {
          label: intl.get('_train.TrainTable.OK'),
          onClick: async () => {
            // TODO await api.post(`/dppapi/train/${projectId}/training/${trainId}`);
            train.status = 1;
            train.epochInfo = [];
            // train.trainMessage = "Distribution { 損益計算書：10}";
            await api.post(`/dppapi/train/submit/${train.projectId}/${train.id}`, train);
            this.setState({
              changeId: train.id,
            });
            this.refetch();
          },
        },
        {
          label: intl.get('_train.TrainTable.Cancel'),
        },
      ],
    })
  }

  async handleCheckout(train) {
    confirmAlert({
      title: '担当者変更',
      message: 'レコードを担当しますか？',
      buttons: [
        {
          label: intl.get('_train.TrainTable.OK'),
          onClick: async () => {
            await api.post(`/dppapi/train/${train.projectId}/checkout/${train.id}`);
            this.setState({
              changeId: train.id,
            });
            this.refetch();
          },
        },
        {
          label: intl.get('_train.TrainTable.Cancel'),
        },
      ],
    })
  }

  async handleDel(train) {
    confirmAlert({
      title: '削除',
      message: 'プロジェクトを削除しますか?',
      buttons: [
        {
          label: intl.get('_train.TrainTable.OK'),
          onClick: async () => {
            await api.delete(`/dppapi/train/${train.projectId}/${train.id}`);
            this.setState({
              changeId: train.id,
            });
            this.refetch();
          },
        },
        {
          label: intl.get('_train.TrainTable.Cancel'),
        },
      ],
    });
  }

  toggleEdit({ isShowEdit, editTrain }) {
    this.setState({
      isShowEdit: isShowEdit,
      editTrain: editTrain || {},
    });
  }

  render() {
    const { projectId } = this.props.match.params;

    const { 
      rows,
      isLoading,
      isShowEdit,
      editTrain,
      project,
      modules,
    } = this.state;

    const columns = [
      {
        label: intl.get('_train.TrainTable.ID'),
        field: 'id',
        sort: 'asc',
      },
      {
        label: intl.get('_train.TrainTable.Name'),
        field: 'trainName',
        sort: 'asc',
      },
      {
        label: intl.get('_train.TrainTable.Operator'),
        field: 'operator',
        sort: 'asc',
      },
      {
        label: intl.get('_train.TrainTable.Module'),
        field: 'module',
        sort: 'asc',
      },
      {
        label: intl.get('_train.TrainTable.Status'),
        field: 'status',
        sort: 'asc',
      },
      {
        label: intl.get('_train.TrainTable.Label'),
        field: 'labels',
        sort: 'asc',
      },
      {
        label: intl.get('_train.TrainTable.Auto'),
        field: 'auto',
        sort: 'asc',
      },
      {
        label: intl.get('_train.TrainTable.Operation'),
        field: 'operation',
        sort: 'asc',
      },
    ];

    const data = {
      columns: columns,
      rows: rows,
    };

    var labelOptions = [];
    project && project.form.formParts.forEach((data) => {
      labelOptions.push({
        key: data.name,
        value: data.name,
        text: data.name,
      });
    });

    var moduleOptions = [];
    modules.forEach((data) => {
      moduleOptions.push({
        key: data.id,
        value: data.id,
        text: data.name,
      });
    });
    
    if (isLoading) {
      return (<Segment><Loader active inline="centered" /></Segment>)
    }

    return (
      <div>
        <Header disabled>{project && project.name}</Header>
        <Segment>
          <Button.Group floated="right">
            <Button icon onClick={() => this.handleAdd({})}>
              <Icon name="add" />
            </Button>
          </Button.Group>
          <MDBDataTable theadColor="common-search-table-header" btn striped bordered hover data={data} />
          <TrainAdd
            isShowEdit={ isShowEdit }
            train={ editTrain }
            moduleOptions={ moduleOptions }
            labelOptions={ labelOptions }
            projectId={projectId}
            toggleEdit={this.toggleEdit}
            successCallback={this.getTrains}
          />
        </Segment>
      </div>
    );
  }
}
