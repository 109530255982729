import React, { Component } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import { Menu } from 'semantic-ui-react';

import { SCRUM_ITEM_SUFFIX } from '../../Constant';
import { CloseIcon } from '../../../assets/icon';
import { EventName, EventType, ViewMode } from '../../../common/Analytics/analyticsMetrics';
import { AnalyticsServiceContext } from '../../../common/Analytics/AnalyticsServiceProvider';

import BoardList from './BoardList';
import BoardTotalItem from './BoardTotalItem';
import CurrentPageErrorList from './CurrentPageErrorList';
import AccountTitleDictionary from './AccountTitleDictionary';

export default class FsKeyValueBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      popupOpenId: null,
      listSeparation: [],
      fistRow: {},
      nextRow: {},
      visibleSection: null,
      isLinked: [],
      actionName: '',
      windowSize: { width: window.innerWidth, height: window.innerHeight },
      openable: true,
      filterItems: [],
      prevPageType: '',
    };
    this.popups = {};
    this.boardItems = {};
    this.contentRef = React.createRef();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleReload = this.handleReload.bind(this);
    this.handleInputChangeDebounced = throttle(this.manualInputChange, 100);
  }
  static contextType = AnalyticsServiceContext;

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.setListAndRow(this.props.rows);
  }

  onSetFilterItems = (filterItems) => {
    this.setState({ filterItems: filterItems, 
      prevPageType: this.props.currentPageType })
    };

  componentDidUpdate(prevProps, prevState) {
    const { selectedCell, rows } = this.props;
    const { filterItems } = this.state;
    this.scrollToItem(prevProps, prevState);
    if (this.props.searchItem && this.props.searchItem !== prevProps.searchItem) {
      let scrollElement = document.getElementById(
        `${this.props.searchItem ? this.props.searchItem.scrumItem : ''}-list-board`
      );
      if (scrollElement) {
        setTimeout(() => {
          this.smoothScroll(scrollElement,{inline: 'start'}).then(()=>{
            scrollElement.click()
          })
        }, 0);
      }
    }
    if (this.props.rows !== prevProps.rows) {
      this.setListAndRow(this.props.rows);
    }
    if (prevState.windowSize.width !== this.state.windowSize.width ||
      prevState.windowSize.height !== this.state.windowSize.height) {
      this.handleReload();
    }
    // this.handleScroll();
    // this.contentRef.current.addEventListener('scroll', this.handleScroll);

    if (filterItems && filterItems.length > 0) {
      const scrollToErrorElement = _.find(rows, (row) => {
        return filterItems.at(-1) === row.scrumItem
      });
      if (scrollToErrorElement &&
        prevState.filterItems !== this.state.filterItems &&
        (selectedCell.id === '' ||
          (selectedCell.id !== '' && scrollToErrorElement.id !== selectedCell.id)
        )) {
        let scrollElement = document.getElementById(scrollToErrorElement.scrumItem + '-list-board-total');
        scrollElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        scrollElement.click();
      }
    }
  }

  handleResize = () => {
    this.setState({
      windowSize: {
        width: window.innerWidth,
        height: window.innerHeight
      }
    })
  }

  scrollToTop = () => {
    const rowGroup = this.convertRowGroup(this.props.rows);
    if (Object.keys(rowGroup).length !== 0) {
      const [topObj] = Object.values(rowGroup)[0];
      const topItem = topObj.scrumItem;
      let scrollElement = document.getElementById(
        `${topItem}-list-board`
      );
      if (scrollElement) {
        scrollElement.scrollIntoView({
          behavior: 'auto',
          inline: 'start',
        });
      }
    }
  }

  scrollToItem = (prevProps, prevState) => {
    const { scrollToElement, currentBoxIdForCss, rows } = this.props;
    if (scrollToElement && (scrollToElement !== prevProps.scrollToElement) && this.state.actionName !== '') {
      this.handleCellAction('');
    }

    if (scrollToElement && (scrollToElement !== prevProps.scrollToElement || currentBoxIdForCss)) {

      const itemRow = rows.find(d => d.values.find(val => val.id === currentBoxIdForCss));
      let scrollElement = document.getElementById(
        `${itemRow ? itemRow.scrumItem : ''}-list-board${this.state.actionName === 'total' ? '-total' : ''}`
      );
      if(!scrollElement) {
        scrollElement = document.getElementById(
          `${itemRow ? itemRow.scrumItem : ''}-list-board-total`
        );
      }
      if (scrollElement) {
        // scrollElement.classList.add('style-scroll-board-selected');
        // scrollElement.classList.add('board-item-backGround');
        if (this.state.isLinked.length === 0 || (itemRow && itemRow.scrumItem !== prevState.isLinked[0])) {
          this.setState({ isLinked: [itemRow.scrumItem, true] });
          //this.handleCellOperation(itemRow, this.mappingDetail(itemRow.scrumItem));
        }
        if(this.state.enterBook === prevState.enterBook){

          setTimeout(() => {
            scrollElement.scrollIntoView({
              behavior: 'smooth',
              inline: 'start',
            });
          }, 0);
        }
      }
    }
  }

  smoothScroll = ( ele, options) =>{
    return new Promise( (resolve) => {
      if( !( ele instanceof Element)){
        return
      }
      let same = 0;
      let lastPos = null;
      let lastLeftPos = null;
      const scrollOptions = Object.assign({ behavior: 'smooth'}, options);

      ele.scrollIntoView(scrollOptions);
      requestAnimationFrame( check );

      function check(){
        const newPos = ele.getBoundingClientRect().top;
        const newLeftPos = ele.getBoundingClientRect().left;

        if( newPos === lastPos && newLeftPos === lastLeftPos){
          if(same ++ > 2){
            return resolve();
          }
        }else{
          same = 0;
          lastPos = newPos;
          lastLeftPos = newLeftPos;
        }
        requestAnimationFrame(check);
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    // this.handleScroll();
    // this.contentRef.current.removeEventListener('scroll', this.handleScroll);
  }

  handleReload() {
    this.setState({ windowSize: this.state.windowSize });
    this.onPopupOpen();
  }

  handleScroll = () => {
    const { visibleSection } = this.state;
    const { width: contentWidth } = this.getDimensions(
      this.contentRef.current
    );
    let selected = null;
    Object.keys(this.boardItems).forEach(key => {
      const ele = this.boardItems[key].current;
      if (ele) {
        const { x } = this.getDimensions(ele);
        if (contentWidth * 2 >= x) {
          selected = key;
        }
      }
    });
    if (selected && selected !== visibleSection) {
      this.setState({ visibleSection: selected });
    }
  };

  getDimensions = ele => {
    const { width, x } = ele.getBoundingClientRect();
    const offsetLeft = ele.offsetLeft;
    const offsetRight = offsetLeft + width;
    return { width, offsetLeft, offsetRight, x };
  };

  setListAndRow = rows => {
    const { metadata } = this.props;
    let op = {};
    if (metadata && metadata.separation) {
      metadata.separation.forEach(s => {
        op[rows.findIndex(r => r.scrumItem === s.item)] = s.id;
      });
    }
    const list = this.checkSeparation(rows, op);
    const idxs = this.getAllIndexes(rows);
    let minIdx = -1;
    if (idxs.length > 0) {
      minIdx = Math.min(...idxs);
    }
    let row = {};
    if (minIdx !== -1) {
      row = rows[minIdx + 1];
    }
    let nextRow = {};
    const idxNext = rows.findIndex(r => r.scrumItem === '流動負債計(入力)');
    if (idxNext !== -1) {
      nextRow = rows[idxNext];
    }
    this.setState({
      listSeparation: list,
      fistRow: { row, value: row.scrumItem },
      nextRow: nextRow ? { nextRow, value: nextRow.scrumItem } : {},
    });
  };

  handleSetOppositeValue = values => {
    values.forEach(value => {
      value.invert = !value.invert;
    });
    this.props.handleChangeValue(values);
  };

  handleInputChange(scrumItem, value, calc) {
    this.handleInputChangeDebounced(scrumItem, value, calc);
  }

  manualInputChange(scrumItem, value, calc) {
    this.props.onManualInputTextChange(scrumItem, value, calc);
  }

  handleOpenModeChange = (state) => this.setState({ openable: state });

  getDetailIds = details => {
    let detailIds = [];
    if (details && details.length > 0) {
      details.forEach(dt => {
        if (dt.item) detailIds.push(dt.item.id);
        if (dt.value) detailIds.push(dt.value.id);
      });
    }
    return detailIds;
  };

  handleCellOperation = (r, detail, type) => {
    const { id, scrumItem } = r;
    const {
      selectedBBox,
      onRowSelect,
      onLinkBbox2Cell,
      currentPage,
    } = this.props;
    if (type && type === 'close') {
      onRowSelect(id, currentPage, [], 'value', r.manualInput);
    } else {
      if (selectedBBox.id === '' && selectedBBox.text === '') {
        const pageId = detail&&detail[0] ? detail[0].pdfImageId : currentPage
        onRowSelect(id, pageId, this.getDetailIds(detail), 'value', r.manualInput);
      } else {
        onLinkBbox2Cell(scrumItem);
        onRowSelect(_.find(this.props.rows, { scrumItem: r.scrumItem }).id, currentPage, this.getDetailIds(detail), 'value', r.manualInput);
      }
    }
  };

  handleCellAction = (actionName) => this.setState({ actionName: actionName });

  mappingDetail = searchText => {
    const { detailData, currentPageType } = this.props;
    const isUndefined = detailData && detailData[currentPageType] === undefined;
    const isDetail = !detailData || isUndefined;
    let filteredRows = isDetail ? [] : detailData[currentPageType];
    const filteredRow = filteredRows.filter(v => {return v.scrumItem === searchText});

    return filteredRow;
  };

  getAllIndexes = arr => {
    let indexes = [],
      i;
    for (i = 0; i < arr.length; i++) {
      if (arr[i].scrumItem.indexOf(SCRUM_ITEM_SUFFIX.AGGREGATION) !== -1) {
        indexes.push(i);
      }
    }
    return indexes;
  };

  splitStr = (list) => {
    const current_list = [];
    // get value in metadata of fs_formula then according to metadata input calculation formula divided into the following situations
    const operator_add_subtract = list.indexOf('+') > -1 && list.indexOf('-') > -1;
    const operator_add = list.indexOf('+') > -1 && list.indexOf('-') < 0;
    const operator_subtract = list.indexOf('-') > -1 && list.indexOf('+') < 0;
    const operator_null = list.indexOf('-') < 0 && list.indexOf('+') < 0;
    
    if (operator_add) {
      list.split("+").forEach(str => {
        current_list.push(str.trim().slice(2, -1));
      })
    } else if (operator_add_subtract || operator_subtract) {
      list.split("#{").forEach(str => {
        if (str) {
          if (str.indexOf(")}-(") > -1 || str.indexOf("}-(") > -1){
            current_list.push(str.slice(0, -3));
          } else if (str.indexOf("})-(") > -1) {
            current_list.push(str.slice(0, -4));
          } else if (str.indexOf("}")===(str.length-1)) {
            current_list.push(str.slice(0, -1));
          }  else {
            current_list.push(str.slice(0, -2));
          }
        }
      })
    } else if (operator_null) {
      list.split("#{").forEach(str => {
        if (str) {
          current_list.push(str.slice(0, -1));
        }
      })
    }
    return current_list;
  }

  convertRowGroup = (rows) => {
    const { metadata, currentPageType } = this.props;
    const { listSeparation } = this.state;
    const current_list_board = metadata.fs_formula.filter(v => v.subCategory === currentPageType);
    let content = {};
    current_list_board.forEach(item => {
      const current_list_str = JSON.parse(JSON.stringify(item.value));
      const currentList = this.splitStr(current_list_str);

      let contentList = [];
      currentList && currentList.forEach(c => {
        const findOp = rows.find(v => {
          return v.scrumItem === c
        });
        if(findOp){
          contentList.push(findOp);
        }
      })
      contentList.push(rows.find(r => r.scrumItem === `${item.item}${SCRUM_ITEM_SUFFIX.AGGREGATION}`));
      contentList.push(rows.find(r => r.scrumItem === item.item));
      content[item.item] = contentList
    });
    if (listSeparation && listSeparation.length > 0) {
      listSeparation.forEach(l => {
        content[l.value] = l.rows;
      });
    }
    return content;
  }

  checkSeparation = (rows, op) => {
    let newRowSeparation = {};
    const arrKey = Object.keys(op).sort((a, b) => a - b);
    const { metadata, currentPageType } = this.props;
    const separationList = metadata.separation;
    const formula = metadata.fs_formula;
    arrKey.map((idx, id) => {
      if (id + 1 < arrKey.length) {
        const isAgg = formula.find(
          f => f.subCategory === currentPageType && rows[arrKey[id + 1]] && f.item === rows[(arrKey[id + 1]) ].scrumItem
        );
        if(isAgg){
          return newRowSeparation[op[idx]] = rows.slice(arrKey[id], arrKey[id + 1]-1).filter(d=>d.scrumItem.indexOf(SCRUM_ITEM_SUFFIX.AGGREGATION)<0);
        }else{
          return newRowSeparation[op[idx]] = rows.slice(arrKey[id], arrKey[id + 1]).filter(d=>d.scrumItem.indexOf(SCRUM_ITEM_SUFFIX.AGGREGATION)<0);
        }
      } else {
        return newRowSeparation[op[idx]] = rows.slice(arrKey[id], rows.length).filter(d=>d.scrumItem.indexOf(SCRUM_ITEM_SUFFIX.AGGREGATION)<0);
      }
    });
    let list = [];
    rows.forEach(r => {
      let res = null;
      const idx = r.scrumItem.indexOf(SCRUM_ITEM_SUFFIX.AGGREGATION);
      if (idx !== -1) {
        const aggString = r.scrumItem.slice(0, idx);
        res =
          separationList &&
          separationList.find(sp => {
            return sp.subCategory === currentPageType && sp.item === aggString;
          });
      } else {
        const isAgg = formula.find(
          f => f.subCategory === currentPageType && f.item === r.scrumItem
        );
        if (!isAgg) {
          res =
            separationList &&
            separationList.find(sp => {
              return (
                sp.subCategory === currentPageType && sp.item === r.scrumItem
              );
            });
        }
      }
      if (res) {
        res['rows'] = newRowSeparation[res.id];
        list.push(res);
      }
    });
    return list;
  };

  itemTitle = scrumItem => {

    const idx = scrumItem.indexOf(SCRUM_ITEM_SUFFIX.AGGREGATION);
    let aggString = ""
    if (idx !== -1) {
      aggString = scrumItem.slice(0, idx);
    } else {
      aggString = scrumItem
    }

    let titleString;
    const idxIp = aggString.indexOf(`(${SCRUM_ITEM_SUFFIX.INPUT})`);
    if (idxIp !== -1) {
      titleString = aggString.slice(0, idxIp);
    } else {
      titleString = aggString;
    }

    const totalIdx = titleString.indexOf('計');
    if (totalIdx === -1) {
      return titleString;
    } else {
      return titleString.slice(0, totalIdx);
    }
  };

  totalGroupCheck = (arr, type) => {
    let result = null;
    for (let i = 0; i < arr.length; i++) {
      const aggIdx = arr[i].scrumItem.indexOf(SCRUM_ITEM_SUFFIX.AGGREGATION);
      if (type === 'aggregation' && aggIdx !== -1) {
        result = arr[i];
      }
      if (type === 'input' && aggIdx !== -1) {
        result = arr[arr.length - 1];
      }
    }
    return result;
  };

  renderList = () => {
    const {
      rows,
      onGetSum,
      onUpdateBBox,
      onEdit,
      handleUnLinkScrumItem,
      detailData,
      currentPageType,
      onInputModeChange,
      errorList,
      allList,
      metadata,
      selectedCell,
      handleTextChange,
      selectedBBox,
      scrollToElement,
      isCancel,
      inputCalc,
      pdfInfo,
      handleChangeValue,
      detailItemChanging,
      isNotOwner,
      onShowExclusionControl,
    } = this.props;
    const { isPortalOpen } = this.props;
    const rowGroup = this.convertRowGroup(rows);

    const currentSep = metadata.separation ? metadata.separation.filter(d=>d.subCategory === currentPageType) : [];
    const listBoard = Object.keys(rowGroup).map((groupName, idx) => {
      const sep = !currentSep || (currentSep && Object.keys(rowGroup).length > (idx + currentSep.length))
      const aggregation = sep && this.totalGroupCheck(rowGroup[groupName], 'aggregation');
      const input = sep && this.totalGroupCheck(rowGroup[groupName], 'input');
      let sliceIdx = rowGroup[groupName].length;
      if (aggregation) sliceIdx -= 1;
      if (input) sliceIdx -= 1;

      const pureList = input ? rowGroup[groupName].filter(item => {
        return item.scrumItem !== input.scrumItem && item.scrumItem !== `${input.scrumItem}${SCRUM_ITEM_SUFFIX.AGGREGATION}`
      }) : rowGroup[groupName];

      const list = pureList
        .slice(0, sliceIdx)
        .map((r, idx) => (
          <BoardList
            key={idx}
            row={r}
            metadata={metadata}
            isPortalOpen={isPortalOpen}
            handleCellOperation={this.handleCellOperation}
            onGetSum={onGetSum}
            onUpdateBBox={onUpdateBBox}
            detailData={detailData}
            currentPageType={currentPageType}
            onInputModeChange={onInputModeChange}
            onEdit={onEdit}
            handleUnLinkScrumItem={handleUnLinkScrumItem}
            handleSetOppositeValue={this.handleSetOppositeValue}
            mappingDetail={this.mappingDetail}
            handleInputChange={this.handleInputChange}
            selectedCell={selectedCell}
            handleTextChange={handleTextChange}
            selectedBBox={selectedBBox}
            popups={this.popups}
            searchItem={this.props.searchItem}
            onOpenModeChange={this.handleOpenModeChange}
            openable={this.state.openable}
            scrollToElement={scrollToElement}
            isCancel={isCancel}
            currentBoxIdForCss={this.props.currentBoxIdForCss}
            handleCellAction={this.handleCellAction}
            inputCalc={inputCalc&&inputCalc[currentPageType]}
            pdfInfo={pdfInfo}
            handleChangeValue={handleChangeValue}
            detailItemChanging={detailItemChanging}
            onSetFilterItems={this.onSetFilterItems}
            filterItems={this.state.filterItems}
            handleSearchReferItem={this.props.handleSearchReferItem}
            isNotOwner={isNotOwner}
            onShowExclusionControl={onShowExclusionControl}
          />
        ));
      
      const height = 60 + (aggregation ? 60 : 0) + (input ? 60 : 0);
      const listStyle = { height: `calc(100% - ${height}px)` };
      const totalProps = {
        isPortalOpen,
        mappingDetail: this.mappingDetail,
        handleCellOperation: this.handleCellOperation,
        onGetSum,
        detailData,
        currentPageType,
        onInputModeChange,
        onEdit,
        handleUnLinkScrumItem,
        handleSetOppositeValue: this.handleSetOppositeValue,
        errorList,
        allList,
        handleInputChange: this.handleInputChange,
        selectedCell,
        onPopupOpen: this.onPopupOpen,
        popups: this.popups,
        openable: this.state.openable,
        onOpenModeChange: this.handleOpenModeChange,
        isCancel: isCancel,
        isLinked: this.state.isLinked,
        handleCellAction: this.handleCellAction,
        handleSearchReferItem: this.props.handleSearchReferItem,
        pdfInfo, 
        handleChangeValue,
        onUpdateBBox,
        detailItemChanging,
        onSetFilterItems: this.onSetFilterItems,
        filterItems: this.state.filterItems,
        currentBoxIdForCss: this.props.currentBoxIdForCss,
        isNotOwner: this.props.isNotOwner,
        onShowExclusionControl: this.props.onShowExclusionControl
      };

      return (
        <React.Fragment key={idx}>
          <div
            ref={this.boardItems[groupName]}
            id={`${groupName}-board`}
            className={`board-list-group-wrap ml-10px-${idx}`}
          >
            <div className="board-list-groupname">
              {groupName}
            </div>
            <div className="board-list-wrap" style={listStyle}>
              {list}
            </div>
            {aggregation && (
              <BoardTotalItem
                row={aggregation}
                type="aggregation"
                {...totalProps}
              />
            )}
            {input && (
              <BoardTotalItem
                row={input}
                rowSecond={aggregation}
                type="input"
                {...totalProps}
              />
            )}
          </div>
        </React.Fragment>
      );
    });
    return <div className="board-wrapper">{listBoard}</div>;
  };

  onPopupOpen = () => {
    Object.values(this.popups).forEach( popup => {
      popup && popup.state.show && popup.handleShow(false);
    });
  };

  render() {
    const {
      tabData,
      currentPageType,
      isShowSearchFlg,
      tenantPreference,
      // mappingType,
      // onMappingTypeChange,
      toggleSearch,
      // onListViewChange,
      // handleSSTypeChange,
      ssCommon,
      textmappingOperatorHeight,
      errorList,
      selectedCell,
      rows,
      mappingType,
    } = this.props;
    const { fistRow, nextRow, listSeparation } = this.state;
    // show currentPageType tab
    const currentTab = tabData && tabData.length > 0 && tabData.filter(t => {
      return t.item === currentPageType
    });

    this.boardItems = [fistRow, nextRow, ...listSeparation].reduce(
      (acc, value) => {
        acc[value.value] = React.createRef();
        return acc;
      },
      {}
    );

    const handleScrollClick = (list) => {
      const item = list.value
      const el = document.getElementsByClassName('style-scroll-board-selected');
      if (el && el.length > 0) {
        for (let i = 0; i < el.length; i++) {
          el[i].classList.remove('style-scroll-board-selected');
        }
      }

      let scrollElement = document.getElementById(
        `${item}-list-board`
      );
      if (scrollElement) {
        setTimeout(() => {
          scrollElement.classList.add('style-scroll-board-selected');
          scrollElement.scrollIntoView({
            behavior: 'smooth',
            inline: 'start',
          });
        }, 0);
      } else {
        this.boardItems[item] &&
          this.boardItems[item].current &&
          this.boardItems[item].current.scrollIntoView({
            behavior: 'smooth',
            inline: 'start',
          });
        this.setState({visibleSection: item})
      }
      this.context.sendPredictBeautifyDetailMetrics({
        event_type: EventType.Click,
        event_name: EventName.ClickScrumTableHeader,
        custom_parameter: {
          tab_name : list.subCategory,
          view_mode : ViewMode.Card
        },
      })
    };

    const errorArea = document.querySelectorAll(".fs-detail-discrepancy");
    const successArea = document.querySelectorAll(".fs-detail-agreement");
    const errorAreaHeigth =
      successArea.length > 0 ? successArea[0].getBoundingClientRect().height :
        errorArea.length > 0 ? errorArea[0].getBoundingClientRect().height : 0;

    return (
      <div
        style={{ position: 'relative', height: '100%', background: '#F5F5F5', padding: '10px 0 10px 10px' }}
      >
        <CurrentPageErrorList
          errorList={errorList}
          selectedCell={selectedCell}
          rows={rows}
          currentPageType={currentPageType}
          reload={this.handleReload}
          onSetFilterItems={this.onSetFilterItems}
          filterItems={this.state.filterItems}
          mappingType={mappingType}
        />
        <div className="fs-text-table-wrap" style={{ height: `calc(100% - ${errorAreaHeigth}px)` }}>
          <div className="menu-scroll-header-menu" style={{ margin: '0 10px' }}>
            <Menu pointing secondary>
              {/* {fistRow && (
                <Menu.Item
                  active={fistRow.value === visibleSection}
                  onClick={() => handleClick(fistRow.value, true)}
                >
                  {listSeparation.length > 0 ? '損益計算' : '資産合計(入力)'}
                </Menu.Item>
              )}
              {nextRow && listSeparation.length <= 0 && (
                <Menu.Item
                  active={nextRow.value === visibleSection}
                  onClick={() => handleClick(nextRow.value, true)}
                >
                  {'負債純資産合計(入力)'}
                </Menu.Item>
              )} */}
              {currentTab &&
                currentTab.map((list, idx) => (
                  list.item === currentPageType &&
                  <Menu.Item
                    onClick={() => handleScrollClick(list)}
                    key={idx}
                  >
                    {list.subCategory}
                  </Menu.Item>
                ))}
              <AccountTitleDictionary
                tenantPreference={tenantPreference}
                currentPageType={currentPageType}
                isShowSearchFlg={isShowSearchFlg}
                toggleSearch={toggleSearch}
                ssCommon={ssCommon}
              />
              <div style={{ display: 'none' }}><CloseIcon /></div>
            </Menu>
            <div
              ref={this.contentRef}
              id="fs-text-table-wrap-id"
              className="table-tab-body-wrapper"
              style={{ height: `calc(100vh - ${91 + textmappingOperatorHeight + errorAreaHeigth}px)` }}
            >
              {this.renderList()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FsKeyValueBoard.propTypes = {
  selectedBBox: PropTypes.object.isRequired,
  currentPage: PropTypes.number.isRequired,
  detailData: PropTypes.object.isRequired,
  currentPageType: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
  errorList: PropTypes.array.isRequired,
  metadata: PropTypes.object.isRequired,
  selectedCell: PropTypes.object.isRequired,
  onGetSum: PropTypes.func,
  onEdit: PropTypes.func,
  handleUnLinkScrumItem: PropTypes.func,
  onInputModeChange: PropTypes.func,
  onLinkBbox2Cell: PropTypes.func,
  handleChangeValue: PropTypes.func,
  onManualInputTextChange: PropTypes.func,
  onRowSelect: PropTypes.func,
  handleTextChange: PropTypes.func,
};

FsKeyValueBoard.defaultProps = {
  handleChangeValue: () => { },
  onManualInputTextChange: () => { },
  onRowSelect: () => { },
  onLinkBbox2Cell: () => { },
  onGetSum: () => { },
  onEdit: () => { },
  handleUnLinkScrumItem: () => { },
  onInputModeChange: () => { },
  handleTextChange: () => { },
};
