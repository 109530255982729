import CSV from 'comma-separated-values';
import { chunk } from 'lodash';
import { MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import React, { useState } from 'react';
import { Button, Form, Icon, Loader } from 'semantic-ui-react';
import api from '../../api';

export default function ProjectUserImportModal(props) {
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(false);

  const clearForm = () => {
    setLoading(false);
    setLoadingText('');
  };

  const handleCancel = () => {
    clearForm();
    props.toggleImport(false);
  };

  const handleImport = async () => {
    const fileEle = document.getElementById('prjUsersCsv');
    if (fileEle.files && fileEle.files[0]) {
      setLoading(true);
      const csvRslt = await new Promise((resolve) => {
        const file = fileEle.files[0];
        const fileReader = new FileReader();
        fileReader.onload = () => {
          resolve(new CSV(fileReader.result).parse());
        };
        fileReader.readAsText(file);
      });

      const csvChunks = chunk(csvRslt, 200);
      for (let [index, prjUserChunk] of csvChunks.entries()) {
        const prjUsers = prjUserChunk.map((prjUserArray) => {
          return {
            projectId: prjUserArray[0],
            userId: prjUserArray[1],
          };
        });
        await api.put(`/dppapi/admin/project-users-batch`, {
          prjUsers: prjUsers,
          mode: index === 0 ? 'init' : 'append'
        });
        setLoadingText(
          Math.floor(((index + 1) / csvChunks.length) * 100) + '%'
        );
      }

      clearForm();
      props.toggleImport(false);
      props.handleSearch();
    }
  };

  return (
    <MDBModal isOpen={props.isShowImport} toggle={props.toggleImport}>
      <MDBModalHeader>Import Projects And Users</MDBModalHeader>
      <MDBModalBody>
        {loading ? (
          <Loader active inline="centered">
            {loadingText}
          </Loader>
        ) : (
          <Form>
            <Form.Field>
              <Form.Input
                label={'CSV File'}
                multiple
                type="file"
                id="prjUsersCsv"
                name="prjUsersCsv"
                accept=".csv"
              />
            </Form.Field>
            <Button size="small" onClick={() => handleImport()} floated="right">
              <Icon name="save" />
              Import
            </Button>
            <Button size="small" onClick={() => handleCancel()} floated="right">
              <Icon name="cancel" />
              Cancel
            </Button>
          </Form>
        )}
      </MDBModalBody>
    </MDBModal>
  );
}
