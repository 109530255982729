import React from 'react'

import { MDBDataTable } from 'mdbreact'

import { Button, Icon, Segment } from 'semantic-ui-react'

import { confirmAlert } from 'react-confirm-alert';

import PermissionEdit from './Edit'
import intl from 'react-intl-universal';
import api from '../../api';

const columns = [
  {
    label: 'Num',
    field: 'num',
    sort: 'asc',
    width: 100
  },
  {
    label: 'Name',
    field: 'name',
    sort: 'asc',
    width: 200
  },
  {
    label: 'Operation',
    field: 'operation',
    sort: 'asc',
    width: 400
  }
]

export default class Permission extends React.Component {
  state = {
    isLoaded: true,
    rows: [],
    isShowEdit: false,
    editPermission: {},
  }

  constructor(props) {
    super(props)
    this.handleDel = this.handleDel.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.toggleEdit = this.toggleEdit.bind(this)
    this.getPermissions = this.getPermissions.bind(this)
  }

  async componentDidMount() {
    await this.getPermissions()
  }

  async getPermissions() {
    
    const permissions = await (await api.get(`/dppapi/permission`)).data
    let rows = []

    if (permissions) {
      rows = permissions.map( (p, index) => {
        return Object.assign({}, p, {
          num: index + 1,
          operation: (
          <div>
            <Button icon="pencil" label="Edit" size="tiny" onClick={() => this.handleEdit(p)}/>
            <Button
              icon="trash"
              label="Delete"
              size="tiny"
              onClick={() => this.handleDel(p)}
            />
          </div>
          )
        })
      })
      this.setState({
        rows: rows
      })
    }
  }

  toggleEdit(isShowEdit) {
    this.setState({
      editPermission: {},
      isShowEdit: isShowEdit
    })
  }

  handleEdit(p) {
    this.setState({
      isShowEdit: true,
      editPermission: p
    })
  }

  async handleDel(p) {
    confirmAlert({
      title: intl.get('_user.permission.Permission.Delete'),
      message: intl.get('_user.permission.Permission.Are you sure to do this?'),
      buttons: [
        {
          label: intl.get('_user.permission.Permission.Delete'),
          onClick: async () => {
            await api.delete(`/dppapi/permission/${p.id}` )
            this.getPermissions()
          },
        },
        {
          label: intl.get('_user.permission.Permission.Cancel'),
        },
      ],
    })
  }

  render() {
    const  { rows, editPermission, isShowEdit } = this.state
    const data = {
      columns: columns,
      rows: rows
    }
    return (<Segment>
       <Button.Group floated='right'>
        <Button icon onClick={() => this.handleEdit({})}>
          <Icon name='add' />
        </Button>
        </Button.Group>
      <MDBDataTable
        theadColor="common-search-table-header"
        btn
        striped
        bordered
        hover
        data={data}
      />
      <PermissionEdit isShowEdit={isShowEdit} permission={ editPermission } toggleEdit={this.toggleEdit} getPermissions={this.getPermissions}/>
    </Segment>)
  }
}