import React, { Component } from 'react';
import { MDBDataTable } from 'mdbreact';
import { Segment, Header, Button, Message, Loader } from 'semantic-ui-react';
import { confirmAlert } from 'react-confirm-alert';
import api from '../../api';
import RelpaceSearch from './search'
import intl from 'react-intl-universal';
import RelpacementEdit from './edit';
import ImportRelpacement from './importRelpacement';
import { getCsrfToken } from '../../common/CookieUtil';

export default class Replacement extends Component {

  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      init: true,
      isLoading: false,
      isShowEdit: false,
      isShowImport: false,
      editReplacement: {},
      searchParam: null,
      data: {},
      checkTenant:{},
      errors: {},
      isError: false,
      tenantId: 0
    }

    this.onSearch = this.onSearch.bind(this)
    this.refetch = this.refetch.bind(this)
    this.handleDel = this.handleDel.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
    this.toggleEdit = this.toggleEdit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleExport = this.handleExport.bind(this);
    this.handleImport = this.handleImport.bind(this);
    this.toggleImport = this.toggleImport.bind(this);
    this.checkForms = this.checkForms.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.clearErrors = this.clearErrors.bind(this)
  }

  handleImport(checkTenant) {
    this.toggleImport({
      isShowImport: true
    })
  }

  toggleImport({ isShowImport }) {
    this.setState({
      isShowImport: isShowImport
    });
  }

  async onSearch(tenantId) {

    this.setState({
      isLoading: true
    })
    await this.refetch(tenantId);
  }

  async refetch(tenantId) {
    const searchId = tenantId || this.state.tenantId;
      const replacement = (await api.get(
        `/dppapi/system/replacement/list/${searchId}`
      )).data;

    const columns = [
      {
        label: intl.get('_system.replacement.Index.ID'),
        field: 'id',
        sort: 'asc',
      },
      {
        label: intl.get('_system.replacement.Index.OcrText'),
        field: 'ocrText',
        sort: 'asc',
      },
      {
        label: intl.get('_system.replacement.Index.Replacement'),
        field: 'replacement',
        sort: 'asc',
      },
      {
        label: intl.get('_system.replacement.Index.Scope'),
        field: 'scope',
        sort: 'asc',
      },
      {
        label: intl.get('_system.replacement.Index.Operation'),
        field: 'operation',
      }
    ]
    let rows = []

    if (replacement.map) {
      rows = replacement.map.map((data) => {
        var scope = ''
        if(data.financialStatement){
          scope = intl.get('_system.replacement.Index.FinancialStatement')
        }
        if(data.easyocr){
          scope = scope?scope+","+intl.get('_system.replacement.Index.Easyocr'):intl.get('_system.replacement.Index.Easyocr')
        }
        return {
          ...data,
          scope:scope,
          operation: (
          <div>
            <Button 
              icon="pencil" 
              label={intl.get('_system.replacement.Index.Edit')}
              size="tiny" 
              onClick={() => this.handleEdit(data)}/>
            <Button
              icon="trash"
              label={intl.get('_system.replacement.Index.Delete')}
              size="tiny"
              onClick={() => this.handleDel(data.id)}
            />
          </div>
          )
        }
      })
    }else{
      this.checkForms(this.state.checkReplacement)
    }
    
    this.setState({
      data: { rows, columns },
      init: false,
      isLoading: false
    })
  }

  checkForms(tenant) {
    const errors = {}
    if(!tenant.name){
      errors['tenantsName'] = {content: 'Please select a tenants name.', pointing: 'below', flag:true}
    }
    this.setState({
      errors: errors,
      isError: true
    })
    return Object.keys(errors).length !== 0
  }

  handleDel(id) {
    confirmAlert({
      title: intl.get('_system.replacement.Index.Delete'),
      message: intl.get('_system.replacement.Index.Are you sure to do this?'),
      buttons: [
        {
          label: intl.get('_system.replacement.Index.Confirm'),
          onClick: async () => {
            await api.delete(`/dppapi/system/replacement/delete/${id}`)
            this.refetch();
          },
        },
        {
          label: intl.get('_system.replacement.Index.Cancel'),
        },
      ],
    });
  }

  handleEdit(replacement) {
    this.setState({
      isShowEdit: true,
      editReplacement: replacement,
      checkReplacement: replacement
    })
  }

  handleFocus() {
    this.clearErrors()
  }

  clearErrors() {
    this.setState({
      errors: {},
      isError: false
    })
  }

  handleAdd() {
    this.toggleEdit(true)
  }

  toggleEdit(isShowEdit) {
    if(isShowEdit){
      this.setState({
        isShowEdit: isShowEdit,
        editReplacement: {
          easyocr: 1,
          financialStatement: 1,
        },
        checkReplacement:{}
      })
    }else{
      this.setState({
        isShowEdit: isShowEdit,
        editReplacement: {},
        checkReplacement:{}
      })
    }
  }

  onChange = (value) => {
    this.setState({ tenantId: 0 });
  };

  async handleExport() {
    const { tenantId } = this.state;
    confirmAlert({
      message: intl.get('_system.replacement.Index.Do you want to get replacement?'),
      buttons: [
        {
          label: intl.get('_system.replacement.Index.Cancel'),
        },
        {
          label: intl.get('_system.replacement.Index.Download'),
          onClick: async () => {
            let a = document.createElement('a');
            a.href = `/dppapi/system/replacement/export/${tenantId}?token=${getCsrfToken()}`;
            a.click();
            a.remove();
          },
        },
      ],
    })
  }

  render() {

    const { data, isLoading, init } = this.state;
    var result = null;
    if (isLoading) {
      result = (<Segment><Loader active inline="centered" /></Segment>)
    } else if (data.rows&&data.rows.length > 0) {
      result = (<Segment>
        <MDBDataTable
          btn
          striped
          bordered
          hover
          data={data}
        />
      </Segment>)
    } else {
      result = (<Message
        info
        header='No Data'
        content="please change conditions"
      />)
    }
    return (
      <React.Fragment>
        <div style={{ display: 'flex' }}>
          <div className="ui" style={{ flex: 1 }}>
            <div id="search-pdf" style={{ padding: '2em 0' }}>
              <Header disabled>{intl.get('_system.replacement.Index.Filtering')}</Header>
              <RelpaceSearch 
              onSearch={this.onSearch}
              handleAdd={this.handleAdd}
              onChange={this.onChange}
              tenantId={this.state.tenantId}
              handleExport = {this.handleExport}
              handleImport = {this.handleImport}
              handleEdit={this.handleEdit}
              isError = {this.state.isError}
              handleFocus = {this.handleFocus}
              errors = {this.state.errors}
              />
            </div>
            <div id="page" style={{ paddingBottom: 200, flex: 1 }}>
              {!init ? result : null}
            </div>
            <RelpacementEdit 
              isShowEdit={this.state.isShowEdit}
              replacement={this.state.editReplacement}
              tenantId={this.state.tenantId}
              toggleEdit={this.toggleEdit}
              getReplacement={this.refetch}/>
            <ImportRelpacement
              tenantId={this.state.tenantId}
              isShowImport={this.state.isShowImport}
              toggleImport={this.toggleImport}
              getReplacement={this.refetch}/>
          </div>
        </div>
      </React.Fragment>
    );
  }
}