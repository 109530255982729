import React from 'react';
import { Button, Dropdown, Segment, Form, Icon, Message} from 'semantic-ui-react';
import intl from 'react-intl-universal';
import '../Tenants.css';
import api from '../../../api';

export default class SettingSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tenantId: '',
      tenants: [],
      isError:this.props,
    };
  }

  async componentDidMount() {
    const tenants = (await api.get(`/dppapi/system/tenant`)).data;
    this.setState({
      tenants: tenants.map(data => {
        return { text: data.name, key: data.id, value: data.id }
      })
    })
  }

  render() {
    const { onSearch, handleAdd, tenantId } = this.props;
    const { tenants } = this.state;
    const { errors } = this.props
    return (
      <Segment>
        {
          Object.keys(errors).length > 0 &&
          <Message
            error
            list={ Object.keys(errors).map(i => {
              return errors[i].content
            })}
          />
        }
        <Form>
        <Form.Field>
          <Button.Group floated="right" style={{ marginLeft: '2px' }}>
            <Button icon onClick={this.props.handleExport}>
              <Icon name="download" />
            </Button>
          </Button.Group>
          <Button.Group floated="right" style={{ marginLeft: '2px' }}>
            <Button icon onClick={this.props.handleImport}>
              <Icon name="file" />
            </Button>
          </Button.Group>
          <Button.Group floated='right' style={{ marginLeft: '2px' }}>
          <Button icon onClick={handleAdd}>
              <Icon name='add' />
            </Button>
          </Button.Group>
        </Form.Field>
        <Form.Field error={this.props.isError}>
          <label>{intl.get('_system.tenants.setting.Search.TenantsName')}</label>
          <Dropdown
            placeholder='Select Tenant'
            options={tenants}
            value={tenantId}
            search
            selection
            onChange={(event, { value }) =>
              {
                this.props.onChange(value);
                onSearch(value);
              }
            }
            onFocus={this.props.handleFocus}
            style={{ width: 'auto' }}
          />
          </Form.Field>

          <Button type="submit" disabled={!tenantId}
            onClick={() => onSearch(tenantId)}>
           {intl.get('_system.tenants.setting.Search.Search')}
          </Button>
        </Form>
      </Segment>
    );
  }
}
