import React, { Component } from 'react'
import { Table } from 'semantic-ui-react'
import OperatePopup from './OperatePopup'
import ZoomPopup from '../../../common/ZoomPopup/index'
import _ from 'lodash';
import { 
  SubjectName,
  ShareholdersEquityHeader,
  ShareholdersEquityRow,
  ShareholdersEquityTotalAgg,
  ShareholdersEquityTotalInput,
  CurrentFluctuationAmountAgg,
  CurrentFluctuationAmountInput,
  NetAssetsTotalAgg,
  NetAssetsTotalInput,
  CurrentEndBanaceAgg
 } from './FsConstant'
export default class ShareholdersEquityTable extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      row: null,
      header: null,
    }
    this.multiAggValue1 = [];
    this.multiAggValue2=[];
    this.multiAggValue3=[];
    this.multiAggValue4=[];
    this.popups = {};
    this.onPopupOpen = this.onPopupOpen.bind(this);
  }

  renderShareholdersEquityHeader = () => {
    const dom = ShareholdersEquityHeader.map((header, idx) => {
      return  <Table.Cell style={{ minWidth:'60px', width: 'auto',background: '#f9fafb' }} key ={idx}>{header}</Table.Cell>
    })
    return (<Table.Row>
      <Table.Cell style={{ width: "10%", background: '#f9fafb', position: 'sticky', left: '0' }} >{SubjectName}</Table.Cell>
      {dom}
    </Table.Row>)
  }


  renderShareholdersEquityRow = () => {
    const {rows} = this.props;
    const shareholdersEquityRow =  _.cloneDeep(ShareholdersEquityRow);
    let tmp = [];
    shareholdersEquityRow.forEach((sr) => {
      var tmpRow = sr;
      rows.forEach((r) => {
        const scrumItem = r.scrumItem;  // 株主資本計(入力)/当期変動額合計(入力)(自動集計)
        const scrumItem_x = scrumItem.substring(0,scrumItem.indexOf('/')); // 株主資本計(入力)
        const scrumItem_y = scrumItem.substring(scrumItem.indexOf('/')+1); // 当期変動額合計(入力)(自動集計)
        // if (scrumItem_y === sr[SubjectName]) {
        if (scrumItem_y.startsWith(sr[SubjectName])) {
          if (scrumItem_y.endsWith('(自動集計)')) {
            var temp_x = scrumItem_x.replace(/\(入力\)/gi, "(自動集計)");
            if (r.id) tmpRow[temp_x].id = r.id;
            if (r.values) tmpRow[temp_x].values = r.values;
          } else {
            if (r.id) tmpRow[scrumItem_x].id = r.id;
            if (r.values) tmpRow[scrumItem_x].values = r.values;
          }
          if (scrumItem === ShareholdersEquityTotalInput + '/' + CurrentFluctuationAmountAgg) {
            this.multiAggValue1 = r.values
          }
          if (scrumItem === NetAssetsTotalInput + '/' + CurrentFluctuationAmountAgg) {
            this.multiAggValue2 = r.values
          }
          if (scrumItem === ShareholdersEquityTotalInput + '/' + CurrentEndBanaceAgg) {
            this.multiAggValue3 = r.values
          }
          if (scrumItem === NetAssetsTotalInput + '/' + CurrentEndBanaceAgg) {
            this.multiAggValue4 = r.values
          }
          if (r.manualInput || r.manualInput === "") tmpRow[scrumItem_x].manualInput = r.manualInput;
        }
      })
      tmp.push(tmpRow)
    })

    let cellDom = ""
    const dom = tmp.map((r, r_idx) => {
      return <Table.Row id={r[SubjectName]} key={r_idx}>
       <Table.Cell style={{ background: '#f9fafb', position: 'sticky', left: '0', zIndex: '1' }}>{r[SubjectName].replace(/当期末残高\(B\/S入力\)\(自動集計\)/gi, "当期末残高(自動集計)")}</Table.Cell>
        {ShareholdersEquityHeader.map((h, h_idx) => {
          cellDom = this.handleDistinguishCell(cellDom, r, r_idx, h, h_idx);
          return cellDom;
         })}
      </Table.Row >
    })
    return dom;
  }

  onPopupOpen() {
    Object.values(this.popups).forEach(popup => {
      popup && popup.state.show && popup.handleShow(false)
    })
  }

  handleDistinguishCell = (cellDom, r, r_idx, h, h_idx) => {
    const { onGetSum, handleCellOperation, handleCheck, selectedCell, currentBoxIdForCss, getCellIds, detailData, currentPageType, isLink } = this.props;
    const scrumItem = h+'/'+r[SubjectName];
    const checkResult = handleCheck(scrumItem);
    let { err, errItem } = checkResult;
    let { manualInput, values } = r[h];
    if ((r[SubjectName] === CurrentFluctuationAmountAgg && h === ShareholdersEquityTotalInput) || (r[SubjectName] === CurrentFluctuationAmountInput &&  h === ShareholdersEquityTotalAgg)) {
      values = this.multiAggValue1
    } else if ((r[SubjectName] === CurrentFluctuationAmountAgg && h === NetAssetsTotalInput) || (r[SubjectName] === CurrentFluctuationAmountInput &&  h === NetAssetsTotalAgg)) {
      values = this.multiAggValue2
    } else if (r[SubjectName] === CurrentEndBanaceAgg && h === ShareholdersEquityTotalInput) {
      values = this.multiAggValue3
    } else if (r[SubjectName] === CurrentEndBanaceAgg && h === NetAssetsTotalInput) {
      values = this.multiAggValue4
    }

    const key = r_idx+','+h_idx;
    var scrumExist = false;
    if (r[h].id) scrumExist = true;
    if (r[h].isAggreation) {
      cellDom = err ? (
        // eslint-disable-next-line
        !r[h].noPop ? 
        <ZoomPopup
          key={scrumItem}
          ref={(ref) => {
            this.popups[scrumItem] = ref;
          }}
          isLink={isLink}
          onOpen={this.onPopupOpen}
          style={{ zoom: '75%' }}
          content={errItem.formula.replace(/#|\{|\}/g, '')}
          trigger={
            <Table.Cell id={scrumItem} className={`old-err-row`} key={key}>
              <span>{onGetSum(values)}</span>
            </Table.Cell>
          }
        /> : 
        <Table.Cell id={scrumItem} className={`old-err-row`} key={key}>
        <span>{onGetSum(values)}</span>
      </Table.Cell>
      ) : (
        <Table.Cell id={scrumItem} key={key} className={`old-aggregation-row`}>
          {values && onGetSum(values)}
        </Table.Cell>
      );
    } else if (manualInput === undefined) {
      if (scrumExist && !r[h].disabled) {
        this.popups[scrumItem] = React.createRef();

        cellDom = (
          <ZoomPopup
            key={scrumItem}
            ref={(ref) => {
              this.popups[scrumItem] = ref;
            }}
            isLink={isLink}
            onOpen={this.onPopupOpen}
            content={
              <OperatePopup
                row={r}
                header={h}
                rows={this.props.rows}
                onTabChange={this.props.onTabChange}
                setInvertFlag={this.props.setInvertFlag}
                onEdit={this.props.onEdit}
                onInputModeChange={this.props.onInputModeChange}
                onInputChange={this.props.onInputChange}
                onGetSum={this.props.onGetSum}
              />
            }
            trigger={
              <Table.Cell
                id={scrumItem}
                key={key}
                className={`${this.getClass(
                  { rowId: r[h].id, cellIds: getCellIds(values) },
                  selectedCell,
                  currentBoxIdForCss,
                  'value',
                  err,
                  manualInput
                )}`}
                onClick={() =>
                  handleCellOperation({
                    id: r[h].id,
                    scrumItem: scrumItem,
                    values: values,
                  })
                }
              >
                {onGetSum(values)}
              </Table.Cell>
            }
          ></ZoomPopup>
        );
      } else {
        cellDom = (
          <Table.Cell id={scrumItem} key={key} className={`old-scrumNotExist-td`}></Table.Cell>
        )
      }
    } else {
      cellDom = (
        <ZoomPopup
          key={scrumItem}
          ref={(ref) => {
            this.popups[scrumItem] = ref;
          }}
          isLink={isLink}
          onOpen={this.onPopupOpen}
          content={
            <OperatePopup
              row={r}
              header={h}
              rows={this.props.rows}
              onTabChange={this.props.onTabChange}
              setInvertFlag={this.props.setInvertFlag}
              onEdit={this.props.onEdit}
              onInputModeChange={this.props.onInputModeChange}
              onInputChange={this.props.onInputChange}
              onGetSum={this.props.onGetSum}
            />
          }
          trigger={
            <Table.Cell id={scrumItem} key={key} className={`${this.getClass(
              { rowId: r[h].id, cellIds: getCellIds(values) },
              selectedCell,
              currentBoxIdForCss,
              'value',
              err,
              manualInput
            )}`}
            >
              <div style={{border:'solid 1px #88bafb', paddingLeft: '2px'}}>{detailData['manual_input'][currentPageType][scrumItem] || '0'}</div>
            </Table.Cell>
          }
        ></ZoomPopup>
      );
    }
    return cellDom;
  }

  getClass = ({ rowId, cellIds }, selectedCell, currentBoxIdForCss, column, err, manualInput) => {
    let className = ''
    if (rowId === selectedCell.id && column === selectedCell.column) {
      className = 'old-selected'
    }
    if (currentBoxIdForCss && cellIds.indexOf(currentBoxIdForCss) !== -1 && manualInput === undefined) {
      className = 'old-high-light'
    }
    if (err) {
      className += ' old-err-row'
    }
    return className
  }

  render() {
    return (
      <Table celled>
        <Table.Body>
          { this.renderShareholdersEquityHeader() }
          { this.renderShareholdersEquityRow() }
        </Table.Body>
      </Table>
  )
  }
}