import React, { Component } from 'react';
import { Segment, Image, Button, Loader, Input, Form, Icon } from 'semantic-ui-react';
import { confirmAlert } from 'react-confirm-alert';
import { MDBDataTable } from 'mdbreact';
import { getStateFlag } from '../Constant';
import { FMT, unixMSToDatetime } from './TimeUtil';
import api from '../../api';
import {imageToB64str} from '../../demonstration/demo-utils';
import './TrainAndEvaluate.css';
import intl from 'react-intl-universal';

export default class TrainAndEvaluate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      train: null,
      rows: [],
      columns: [
        {
          label: 'epoch',
          field: 'epoch',
        }
      ],
      resultRows: [],
      resultColumns: [
        {
          label: 'result',
          field: 'result',
        }
      ],
      filesrc: null,
      resultFilesrc: null,
    };

    this.handleTraining = this.handleTraining.bind(this);
    this.handleTrainCancel = this.handleTrainCancel.bind(this);
    this.refetch = this.refetch.bind(this);
    this.cleanEpochInfo = this.cleanEpochInfo.bind(this);
    this.handleEvaluate = this.handleEvaluate.bind(this);
  }

  async componentDidMount() {
    this.refetch(this.props.train);
  }

  async handleTraining(train) {
    confirmAlert({
      title: 'トレーニング',
      message: 'トレーニングを開始しますか？',
      buttons: [
        {
          label: intl.get('_train.detail.TrainAndEvaluate.OK'),
          onClick: async () => {
            train.status = 7;
            train.epochInfo = [];
            await api.post(`/dppapi/train/submit/${train.projectId}/${train.id}`, train);
            this.refetch(train);
          },
        },
        {
          label: intl.get('_train.detail.TrainAndEvaluate.Cancel'),
        },
      ],
    })
  }

  async handleTrainFinish(train) {
    confirmAlert({
      title: 'Finish',
      message: 'Stop this train task when the current epoch finished, and deploy the training result.',
      buttons: [
        {
          label: 'Finish',
          onClick: async () => {
            train.status = 1;
            train.epochInfo = [];
            await api.get(`/dppapi/train/stop/${train.projectId}/${train.id}?stopFlag=1`, train);
            this.refetch(train);
          },
        },
        {
          label: intl.get('_train.detail.TrainAndEvaluate.Cancel'),
        },
      ],
    })
  }

  async handleTrainStop(train) {
    confirmAlert({
      title: 'Stop',
      message: 'Stop this train task when the current epoch finished. Result of current task will be dropped.',
      buttons: [
        {
          label: 'Stop',
          onClick: async () => {
            train.status = 1;
            train.epochInfo = [];
            await api.get(`/dppapi/train/stop/${train.projectId}/${train.id}?stopFlag=2`, train);
            this.refetch(train);
          },
        },
        {
          label: intl.get('_train.detail.TrainAndEvaluate.Cancel'),
        },
      ],
    })
  }

  async handleTrainCancel(train) {
    confirmAlert({
      title: 'Cancel Task',
      message: 'Stop the train task immediately. Result of current task will be dropped.',
      buttons: [
        {
          label: 'Cancel this training',
          onClick: async () => {
            train.status = 1;
            train.epochInfo = [];
            await api.get(`/dppapi/train/stop/${train.projectId}/${train.id}?stopFlag=3`, train);
            this.refetch(train);
          },
        },
        {
          label: intl.get('_train.detail.TrainAndEvaluate.Cancel'),
        },
      ],
    })
  }

  async refetch(train) {
    var { columns } = this.state;
    var newTrain = (await api.get(`/dppapi/train/${train.projectId}/${train.id}`)).data;
    let rows = [];
    if (newTrain.epochInfo && newTrain.epochInfo.length > 0) {
      var columnArrays = Object.keys(newTrain.epochInfo[0]);
      columns = columnArrays.map((data) => {
        return {
          label: data,
          field: data,
        };
      })
      rows = newTrain.epochInfo.map((data) => {
        return Object.assign({}, data, {
          timestamp: unixMSToDatetime(data.timestamp, FMT.DATETIME_LOCAL)
        });
      })
    }
    this.setState({
      isLoaded: true,
      train: newTrain,
      rows,
      columns
    });
  }

  async cleanEpochInfo(train) {
    confirmAlert({
      title: 'クリーン',
      message: 'クリーンを開始しますか？',
      buttons: [
        {
          label: intl.get('_train.detail.TrainAndEvaluate.OK'),
          onClick: async () => {
            train.epochInfo = null;
            await api.post(`/dppapi/train/${train.projectId}/${train.id}`, train);
            this.refetch(train);
          },
        },
        {
          label: intl.get('_train.detail.TrainAndEvaluate.Cancel'),
        },
      ],
    })
  }

  handleChange(e) {
    const imgUrl = URL.createObjectURL(e.target.files[0]);
    this.setState({
      filesrc: imgUrl,
    });
    imageToB64str(imgUrl, dataUrl => {
      this.setState({
        fileB64: dataUrl,
      });
    });
  }

  async handleUpload(e){
    e.preventDefault();
    const train = this.props.train;
    var bodyParm = {
      instances: [
        {
          input_bytes: {
            b64: this.state.fileB64.substring('data:image/png;base64,'.length),
          },
        },
      ],
    };    

    const response = await api.post(`/dppapi/train/evaluate/${train.projectId}/${train.id}`,
      JSON.stringify(bodyParm),
      { 
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        }
      }
    );

    const body = response.data;
    var  resultColumns, resultRows;
    if(!body.success) {
      resultColumns = [
        {
          label: 'success',
          field: 'success'
        },
        {
        label: 'errmsg',
        field: 'errmsg'
      }]
      resultRows = [
        {success: String(body.success), errmsg: String(body.errmsg)}
      ]
    }else if (body.predictions.length === 0){
      resultRows = [
        {success: String(body.success), errmsg: 'None target found.'}
      ]
    }else{
      var columnArrays = Object.keys(body.predictions[0]);
      resultColumns = columnArrays
      .filter(data => {
        if(/_idx$/.test(data)){
          return false;
        }else{
          return true;
        }
      })
      .map((data) => {
        return {
          label: data,
          field: data,
        };
      })
      resultRows = body.predictions.map((data) => {
        return data;
      })
    }
    this.setState({
      resultRows: resultRows,
      resultColumns: resultColumns
    });
  }

  async handleEvaluate(e) {
    e.preventDefault();

    const form = e.target;

    confirmAlert({
      title: '評価する',
      message: '評価するを開始しますか？',
      buttons: [
        {
          label: intl.get('_train.detail.TrainAndEvaluate.OK'),
          onClick: async () => {
            await this.handleUpload(e);
            this.setState({
              resultFilesrc: this.state.filesrc,
            });
            form.reset();
          },
        },
        {
          label: intl.get('_train.detail.TrainAndEvaluate.Cancel'),
        },
      ],
    })
  }

  render() {
    const { 
      isLoaded,
      train,
      rows,
      columns,
      resultRows,
      resultColumns,
      filesrc,
      resultFilesrc
    } = this.state;

    const data = {
      columns: columns,
      rows: rows
    }

    const result = {
      columns: resultColumns,
      rows: resultRows
    }

    if (!isLoaded) {
      return <Loader active inline="centered" />;
    }

    return (
      <Segment>
        <div style={{marginBottom:'20px'}} >  
        {train.status === 2 ? (
            <Button
              style={{width:'20%'}}
              onClick={() => {this.handleTrainFinish(train)}}
            >
              Finish this training
            </Button>
          ) : (
            <Button
              style={{width:'20%'}}
              onClick={() => {this.handleTraining(train)}}
            >
              Train
            </Button>
        )}
        {train.status === 2 ? (
            <Button
              style={{width:'20%'}}
              onClick={() => {this.handleTrainStop(train)}}
            >
              Stop this training
            </Button>
          ) : (null
        )}
        {train.status === 2  || train.status === 5 ? (
            <Button
              style={{width:'20%'}}
              onClick={() => {this.handleTrainCancel(train)}}
            >
              Cancel this training
            </Button>
          ) : (null
        )}
        <Input
          disabled
          value={getStateFlag(train.status)}
          style={{width:'79%'}}/>
        </div>
        <div style={{marginBottom:'20px'}} >
          <Button.Group floated='right'>
            <Button icon onClick={() => {this.cleanEpochInfo(train)}}>
              <Icon name='trash' />
            </Button>
            <Button icon onClick={() => {this.refetch(train)}}>
              <Icon name='refresh' />
            </Button>
          </Button.Group>
          <MDBDataTable
            theadColor="common-search-table-header"
            btn
            striped
            bordered
            hover
            data={data}
          />
        </div>
        <Form
          method="post"
          encType="multipart/form-data"
          onSubmit={this.handleEvaluate}
          style={{display:'flex',alignItems: 'center'}}
        >
          <Button 
            type="submit"
            style={{width:'20%'}}
            disabled={!filesrc}
          >
            Evaluate
          </Button>
          <Input
            type="file"
            id="image"
            name="image"
            accept=".jpg, .jpeg, .png"
            onChange={e => this.handleChange(e)}
            style={{width:'79%'}}
            className='evaluate-input'
          />
        </Form>
        <div style={{marginBottom:'20px'}} >
          <MDBDataTable
            theadColor="common-search-table-header"
            btn
            striped
            bordered
            hover
            data={result}
          />
        </div>
        <Image style={{marginTop:'15px'}} src={resultFilesrc} />
      </Segment>
    );
  }
}
