import React, { Component } from 'react';

import {
  Button,
  Form,
  Grid,
  Message,
  Segment,
} from 'semantic-ui-react';
import api from '../../api'
import intl from 'react-intl-universal';
import ZipUpload from './ZipUpload';
import FolderUpload from './FolderUpload';

export default class UploadImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urlsText: '',
      localPath: '',
      urlsError: null,
      localPathError: null,
      filesLoaded: true,
      pdfsLoaded: true,
      urlsLoaded: true,
      pathLoaded: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePathSubmit = this.handlePathSubmit.bind(this);
    this.handleFilesSubmit = this.handleFilesSubmit.bind(this);
    this.handlePdfsSubmit = this.handlePdfsSubmit.bind(this);
  }

  handleChange(e, change) {
    const { name, value } = change;
    this.setState({
      [name]: value,
    });
  }

  async handleSubmit() {
    const { urlsText } = this.state;
    const { projectId } = this.props;

    if (!urlsText) return;
    
    const urls = urlsText
    .trim()
    .split('\n')
    .filter(line => line !== '');
    
    this.setState({ urlsLoaded: false });
    const resp = await api.post(`/dppapi/labeling/${projectId}/images/`, {
      projectId,
      urls,
    });
    this.setState({ urlsLoaded: true });

    if (!resp.ok) {
      this.setState({
        urlsError: (await resp.data).message,
      });
      return;
    }

    this.setState({
      urlsError: null,
    });
    this.setState({ urlsText: '' });
  }

  async handleFilesSubmit(e) {
    e.preventDefault();
    
    const form = e.target;
    const formData = new FormData(form);
    
    this.setState({ filesLoaded: false });
    await api.post(`/dppapi/labeling/${this.props.projectId}/uploads`, formData);
    this.setState({ filesLoaded: true });

    form.reset();
  }

  async handlePdfsSubmit(e) {
    e.preventDefault();
    
    const form = e.target;
    const formData = new FormData(form);
    this.setState({ pdfsLoaded: false });
    await api.post(`/dppapi/labeling/${this.props.projectId}/uploads/pdf`, formData);
    this.setState({ pdfsLoaded: true });

    form.reset();
  }

  async handlePathSubmit(e) {
    e.preventDefault();
    
    const form = e.target;
    const { localPath } = this.state;
    const { projectId } = this.props;

    if (!localPath) return;

    this.setState({ pathLoaded: false });
    const resp = await api.post(`/dppapi/labeling/${projectId}/images/`, {
      projectId,
      localPath,
    });
    this.setState({ pathLoaded: true });
    if (!resp.ok) {
      this.setState({
        localPathError: (await resp.data).message,
      });
      return;
    }

    this.setState({
      localPathError: null,
    });

    form.reset();
  }

  render() {
    const { urlsText, urlsError, localPath, localPathError } = this.state;
    const { filesLoaded, pdfsLoaded, urlsLoaded, pathLoaded } = this.state;

    const urlsMessage = urlsError ? (
      <Message negative>{urlsError}</Message>
    ) : null;
    const localPathMessage = localPathError ? (
      <Message negative>{localPathError}</Message>
    ) : null;

    return (
      <Segment>
        <Grid celled columns={2} relaxed="very" stackable>
          <Grid.Row>
            <Grid.Column>
              <Form
                method="post"
                encType="multipart/form-data"
                onSubmit={this.handleFilesSubmit}
              >
                <Form.Input
                  label={intl.get('_label.image.UploadImages.Uploadfilesfromdisk')}
                  multiple
                  type="file"
                  id="images"
                  name="images"
                  accept=".jpg, .jpeg, .png"
                />
                <Button 
                  type="submit" 
                  loading={!filesLoaded} 
                  disabled={!filesLoaded}>{intl.get('_label.image.UploadImages.Upload')}</Button>
              </Form>
            </Grid.Column>
            <Grid.Column>
              <Form
                method="post"
                encType="multipart/form-data"
                onSubmit={this.handlePdfsSubmit}
              >
                <Form.Input
                  label={intl.get('_label.image.UploadImages.Uploadpdfsfromdisk')}
                  multiple
                  type="file"
                  id="pdfs"
                  name="pdfs"
                  accept=".pdf"
                />
                <Button 
                  type="submit" 
                  loading={!pdfsLoaded} 
                  disabled={!pdfsLoaded}>{intl.get('_label.image.UploadImages.Upload')}</Button>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column verticalAlign="middle">
              <Form onSubmit={this.handleSubmit}>
                <Form.TextArea
                  name="urlsText"
                  label={intl.get('_label.image.UploadImages.ImagesURLs')}
                  placeholder="One URL per line i.e. https://images.com/cat.jpg"
                  rows="3"
                  value={urlsText}
                  onChange={this.handleChange}
                />
                <Button 
                  loading={!urlsLoaded} 
                  disabled={!urlsLoaded}>{intl.get('_label.image.UploadImages.SourcefromURLs')}</Button>
              </Form>
              {urlsMessage}
            </Grid.Column>
            <Grid.Column>
              <Form onSubmit={this.handlePathSubmit}>
                <Form.Input
                  name="localPath"
                  label={intl.get('_label.image.UploadImages.Serverfilesystempath')}
                  placeholder="i.e. /mnt/image-server/project-files/"
                  value={localPath}
                  onChange={this.handleChange}
                />
                <Button 
                  loading={!pathLoaded} 
                  disabled={!pathLoaded}>{intl.get('_label.image.UploadImages.Sourcefromserver')}</Button>
              </Form>
              {localPathMessage}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <ZipUpload projectId={this.props.projectId}/>
            </Grid.Column>
            <Grid.Column>
              <FolderUpload projectId={this.props.projectId}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
