import CSV from 'comma-separated-values'
const multipart = require('parse-multipart');

export async function parser(res) {
  const boundary = getBoundary(res.headers)
  const buff = new Buffer(await res.data);
  var text = { img: [] };
  multipart.Parse(buff, boundary).forEach(function (element) {
    if (element.type === 'text/plain') {
      text[element.filename] = element.data.toString();
    } else if (element.type === 'image/jpeg') {
      const x = atob(new TextDecoder('utf-8').decode(element.data));
      const y = new Array(x.length);
      for (let i = 0; i < x.length; i++) {
        y[i] = x.charCodeAt(i);
      }
      const url = URL.createObjectURL(
        new Blob([new Uint8Array(y)], { type: 'image/jpeg' })
      );
      text.img.push({ pageId: element.filename, url })
    }
  });
  return text
}

function getBoundary(headers) {
  var boundary = '';
  Object.keys(headers).forEach(key => {

    if (key === 'x-content-type') {
      boundary = headers[key].split('=')[1];
    }
    return boundary

  })
  return boundary
}
export const deepClone = (obj) => {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }
  let newObj = {};
  if (Array.isArray(obj)) {
    newObj = obj.map(item => deepClone(item));
  } else {
    Object.keys(obj).forEach((key) => {
      return newObj[key] = deepClone(obj[key]);
    })
  }
  return newObj;
}

// 12345.678 => 12,345.678 
export function formatCurrency(val) {

  const reg = /\d{1,3}(?=(\d{3})+$)/g
  return val.replace(/^(-?)(\d+)((\.\d+)?)$/, function (s, s1, s2, s3) {
    return s1 + s2.replace(reg, '$&,') + s3;
  })

}

export function getBboxCoordinate(figure, height) {

  const y1 = figure.points[0].lat;
  const x1 = figure.points[0].lng;
  const y2 = figure.points[1].lat;
  const x2 = figure.points[1].lng;

  return [
    [Math.round(x1), Math.round(height - y1)],
    [Math.round(x2), Math.round(height - y2)],
  ];
}

function genCsvUrl(data, options, needBom) {
  const encoded = new CSV(data, options).encode()

  const bom = needBom ? '\ufeff' : '';

  const dataBlob = new Blob([`${bom}${encoded}`], {
    type: 'text/plain;charset=utf-8',
  })
  return window.URL.createObjectURL(dataBlob)
}

export async function downloadCsv(data, options, fileName, needBom = true) {
  const url = genCsvUrl(data, options, needBom)
  const a = document.createElement('a')
  a.href = url
  a.download = fileName
  a.click()
  window.URL.revokeObjectURL(url)
}

export function formatMoney(position, originalMoney) {
  let finalPosition = position - 1;
  let finalSetFlag = false;
  const beforeCount = originalMoney.split(',').length;
  const headMinusFlag = originalMoney.startsWith('-');

  let convertText = originalMoney;
  let isNumber = false;
  if (convertText.startsWith('=')) {
    if (convertText === '=' || convertText === '=-') {
      isNumber = true;
    } else {
      const noSigns = convertText.replace(new RegExp(['\\+', '\\-', '\\*', '\\/'].join('|'), 'gi'), '');
      isNumber = /^[\d,]+(\d*)?$/.test(noSigns.substring(1));
      if(noSigns === '='){
        finalSetFlag = true;
        finalPosition = finalPosition + 1
      }
    }
  } else {
    convertText = originalMoney.replace(new RegExp([','].join('|'), 'gi'), '');
    if (convertText === '-') {
      isNumber = true;
    } else if (headMinusFlag) {
      isNumber = /^[\d,]+(\.\d*)?$/.test(convertText.substring(1));
    } else {
      isNumber = /^[\d,]+(\.\d*)?$/.test(convertText);
    }
  }
  if (isNumber || convertText === '') { // money format check
    finalSetFlag = true;
    if (convertText.length > 14 && !convertText.startsWith('=')) {
      convertText = convertText.substring(0, 14);
    }
    if (convertText !== '-' && !convertText.startsWith('=')) {
      if (headMinusFlag) {
        convertText = '-' +
          new Intl.NumberFormat('ja-JP', { style: 'decimal', currency: 'CAD', }).format(convertText.substring(1));
      } else {
        convertText = convertText ?
          new Intl.NumberFormat('ja-JP', { style: 'decimal', currency: 'CAD', }).format(convertText) : '';
      }
    }

    const afterCount = convertText.split(',').length;
    const addPosition = afterCount - beforeCount;

    finalPosition = position + addPosition;
  }
  return [convertText, finalPosition, finalSetFlag, headMinusFlag];
}

export function handleDeleteCommaFromMoney(prevMoney ,currentMoney ,startPosition,lastKeyPressed) {
  let diffPosition = 0;
  let money = currentMoney;

  const prevValue = prevMoney ? prevMoney : "";
  const diffLength = prevValue.length - money.length;
  if(diffLength < 2){
    if(lastKeyPressed === "Backspace"){
      if (String(prevValue)[startPosition] === ',') {
        money = String(money).slice(0,startPosition-1,) + String(money).slice(startPosition);
        diffPosition = - 1
      }
    }else if (lastKeyPressed === "Delete"){
      if (String(prevValue)[startPosition] === ',') {
        money = String(money).slice(0,startPosition,) + String(money).slice(startPosition +1);
      }
    }
  }
  return [ money ,diffPosition ]
}

export function getPdfStatusOptions(status) {
  const options = []
  Object.keys(status).forEach(k => {
    k = Number(k)
    // remove  2: 'AI処理中'  state because after useing queue to reslove pdf, there was no longer a state === 2 situation 
    if (1 === Number(k)) {
      options.push({ key: k, value: k, text: `${status[k].text}&${status[k + 1].text}`, color: status[k].color })
    } else if (2 !== Number(k)) {
      options.push({ key: k, value: k, text: `${status[k].text}`, color: status[k].color })
    }
  })
  return options
}

export function scrollToElementByPosition(scrollElement, block) {
  const tableElement = document.getElementById("fs-key-value-table");
  const theadElement = document.getElementsByClassName("value-table-header")[0];
  if (tableElement && theadElement) {
    const scrollElementPosition = scrollElement.getBoundingClientRect().bottom;
    const tableElementHeight = tableElement.getBoundingClientRect().top;
    const theadElementPosition = theadElement.getBoundingClientRect().bottom;
    const offsetPosition = block === 'start' ?
      scrollElementPosition - theadElementPosition + 10 :
      scrollElementPosition - theadElementPosition - tableElementHeight / 2 + 10;
    setTimeout(() => {
      tableElement.scrollTo({
        top: offsetPosition,
      });
    }, 0);
  } else {
    scrollElement.scrollIntoView({behavior: 'auto', block: 'start', inline: 'center'});
  }
}

export function ignoreSpecialCharForCalc(value){

  // ignore 0. example: 000+00100 => 0+100
  let regex = /(?<=[=\\+\-\\*\\/])0+(?=\d+)/g;
  let convertVal = value.replace(regex, '');
  charCheckForCalc(convertVal)
  // ignore +-*/ in the end. example: 1+2--- => 1+2
  let regexEnd = /(?<=.+)[\\+\-\\*\\/]+$/;
  convertVal = convertVal.replace(regexEnd, '')
  return convertVal;
}

export function charCheckForCalc(value){
  // check 0. example: 6/0 => error
  const zeroRegex = /\/0/g;
  if(zeroRegex.test(value)){
    throw new Error('formate error')
  }
const multiRegex = /[\\+\-\\*\\/]{2}/g;
if(multiRegex.test(value)){
  throw new Error('formate error')
}
}