export const INIT_CODE = `async function () {
  /*
  =====================================
  -         API Information           -
  =====================================
  */ 
 
 // TO DO
  
  return {}
}`;
