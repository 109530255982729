import React from 'react'
import { Icon } from 'semantic-ui-react'
import ImagesAllGrid from './ImagesAllGrid'
import ImagesAllList from './ImagesAllList'
export default class ImagesAll extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isGrid: false }
  }
  onTypeChange = () => {
    this.setState(prevState => {
      return { isGrid: !prevState.isGrid }
    })
  }

  render() {
    const { isGrid } = this.state
    const { projectId } = this.props
    return (<div>
      <div style={{"marginBottom": "12px"}}>
        <Icon
          link
          name="list"
          size="large"
          disabled={!isGrid}
          onClick={() => this.onTypeChange()}
        />
        <Icon
          link
          name="grid layout"
          size="large"
          disabled={isGrid}
          onClick={() => this.onTypeChange()}
        />
      </div>

      {isGrid ? <ImagesAllGrid projectId={projectId} /> : <ImagesAllList projectId={projectId} />}
    </div>)
  }
}
