import React, { Component } from 'react';
import { ImageOverlay, Map } from 'react-leaflet';
import { Context } from './Context';
import { CRS } from 'leaflet';
import { maxRangeZoom, minRangeZoom } from './CalcBoundsHOC';
import PropTypes from 'prop-types';

class MapContent extends Component {

  componentDidMount() {
    const { mapRef } = this.props;
    const map = mapRef.current.leafletElement;
    this.props.setMap(map);
  }

  render() {
    const {
      zoomDefault,
      centerDefault,
      scrollWheelZoomFlag,
      // leftWidth,
      handleViewPortChange,
      unfinishedDrawingDOM,
      renderedTrace,
      renderedPolyline,
      figuresDOM,
      hotkeysDOM,
      renderedLoader,
      zoom,
      height,
      width,
      handleClick,
      url,
      bounds,
      setMapState,
      mapRef,
      targetColumnPolygon,
      currentPage
    } = this.props;

    return (
      <Map
        className="map-content-wrapper"
        crs={CRS.Simple}
        zoom={zoomDefault === undefined ? zoom : zoomDefault}
        minZoom={width / 2 > 810 || height / 2 > 877 ? -4 : minRangeZoom}
        maxZoom={maxRangeZoom}
        center={
          centerDefault === undefined ? [height / 2, width / 2] : width / 2 > 810 || height / 2 > 877 ? centerDefault : [height / 2, width / 2]
        }
        zoomAnimation={false}
        zoomSnap={0.1}
        zoomControl={false}
        keyboard={false}
        attributionControl={false}
        onClick={handleClick}
        onZoom={e => {
          setMapState({ zoom: e.target.getZoom() });
        }}
        onMousemove={e => {
          setMapState({ cursorPos: e.latlng });
        }}
        onViewportChanged={handleViewPortChange}
        scrollWheelZoom={scrollWheelZoomFlag}
        ref={mapRef}
        key={currentPage}
      >
        <ImageOverlay url={url} bounds={bounds} />
        {unfinishedDrawingDOM}
        {renderedTrace}
        {renderedPolyline}
        {figuresDOM}
        {hotkeysDOM}
        {renderedLoader}
        {targetColumnPolygon}
      </Map>
    );
  }
}

export default props => (
  <Context.Consumer>
    {({ setMap }) => <MapContent {...props} setMap={setMap} />}
  </Context.Consumer>
);

MapContent.propTypes = {
  mapRef: PropTypes.object.isRequired,
  zoomDefault: PropTypes.number,
  zoom: PropTypes.number,
  centerDefault: PropTypes.array,
  height: PropTypes.number,
  witch: PropTypes.number,
  scrollWheelZoomFlag: PropTypes.bool,
  leftWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleViewPortChange: PropTypes.func,
  unfinishedDrawingDOM: PropTypes.any,
  renderedTrace: PropTypes.any,
  renderedPolyline: PropTypes.any,
  figuresDOM: PropTypes.any,
  hotkeysDOM: PropTypes.any,
  renderedLoader: PropTypes.any,
  handleClick: PropTypes.func,
  url: PropTypes.string,
  bounds: PropTypes.object,
  setMapState: PropTypes.func,
};

MapContent.defaultProps = {
  handleViewPortChange: () => {},
  handleClick: () => {},
  setMapState: () => {},
};
