import React, { Component } from 'react';
import { MDBDataTable } from 'mdbreact';
import { Loader, Segment, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import api from '../../api';
import intl from 'react-intl-universal';

export default class PredictProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      rows: [],
    };
  }

  async componentDidMount() {
    try {
      const { data } = await api.request({
        url: '/dppapi/predict/projects',
        method: 'GET'
      })
      const info = (await api.get(`/dppapi/info`)).data;
      this.setState({user: info ? info : ''});
      this.setState({
        isLoaded: true,
        rows: data.map((p) => {
          const projectId = p.id;
          const projectName = p.name;
          return {
            id: projectId,
            projectName: (
              <Link key={projectId} to={`/predict/admin/${projectId}`}>
                <div
                  style={{
                    width: 200,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <u>{projectName}</u>
                </div>
              </Link>
            ),
            name: projectName,
          };
        }),
      });
    } catch (error) {
      this.setState({
        isLoaded: true,
        error,
      });
    }
  }

  render() {
    const { error, isLoaded, rows, user } = this.state;

    const columns = [
      {
        label: intl.get('_project.ProjectManagementTable.ID'),
        field: 'id',
        sort: 'asc',
      },
      {
        label: intl.get('_project.ProjectManagementTable.Project'),
        field: 'projectName',
        sort: 'asc',
      }
    ];

    const data = {
      columns: columns,
      rows: rows,
    };

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <Loader active inline="centered" />;
    }

    return (
      <div>
        {/* <Header as="h1">{intl.get('_predict.admin.PredictProjects.AIAnalysis')}</Header> */}
        { (user && user.tenantName)? <Header as="h1">{user.tenantName}</Header>: null}
        <Segment>
          <MDBDataTable theadColor="common-search-table-header" btn striped bordered hover data={data} />
        </Segment>
      </div>
    );
  }
}
