/**
 * Parse, validate, manipulate, and display dates in javascript. http://momentjs.com
 */
import moment from 'moment';

export const FMT = {
  UNIX_MS: 'x', // unix milliseconds timestamp
  DATETIME_LOCAL: 'YYYY-MM-DD HH:mm',
  DATETIME_YMDHms: 'YYYY-MM-DD HH:mm:ss',
  DATETIME_YMDHmsS: 'YYYY-MM-DD HH:mm:ss:SSS',
  DATE: 'YYYY-MM-DD',
  TIME_STRING: 'YYYYMMDDHHmmss',
  TIME_STANDARD: 'YYYY/MM/DD HH:mm:ss'
}

export function toUnixMS(date) {
  return moment(date).format(FMT.UNIX_MS)
}

export function unixMSToDatetime(unixMS, fmt) {
  return moment.unix(unixMS / 1000).format(fmt)
}

