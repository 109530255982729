import React, { Component } from 'react';
import { Container, Menu, Header } from 'semantic-ui-react';
import UploadReference from './reference/UploadReference';
import ImagesConditions from './image/ImagesConditions';
import ImagesAll from './image/ImagesAll';
import UploadImages from './image/UploadImages';
import MLAssist from './model/MLAssist';
import ImportData from './training-data/ImportData';
import api from '../api'
import intl from 'react-intl-universal';
import UploadVideo from './video';
import Distribution from './distribution';
import { filterTab } from '../common/AuthUtil'

export default class LabelMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '',
      tabs: [
        {
          key: 'label/search_images',
          label: intl.get('_label.LabelMenu.SearchImages'),
          component: 'ImagesConditions'
        },
        {
          key: 'label/distribution',
          label: intl.get('_label.LabelMenu.Distribution'),
          component: 'Distribution'
        },
        {
          key: 'label/upload_images',
          label: intl.get('_label.LabelMenu.UploadImages'),
          component: 'UploadImages'
        },
        {
          key: 'label/upload_video',
          label: intl.get('_label.LabelMenu.UploadVideo'),
          component: 'UploadVideo'
        },
        {
          key: 'label/training_data',
          label: intl.get('_label.LabelMenu.Trainingdata'),
          component: 'TrainingData'
        },
        {
          key: 'label/reference',
          label: intl.get('_label.LabelMenu.Reference'),
          component: 'Reference'
        },
        {
          key: 'label/ml_assist',
          label: intl.get('_label.LabelMenu.MLAssist'),
          component: 'MLAssist'
        },
        {
          key: 'label/all_images',
          label: intl.get('_label.LabelMenu.AllImages'),
          component: 'ImagesAll'
        },
      ],
      project: null,
    }
    this.handleSwitchTab = this.handleSwitchTab.bind(this)
  }

  async componentDidMount() {
    const { match } = this.props;
    const { projectId } = match.params;
    const project = (await api.get(`/dppapi/labeling/${projectId}/project`)).data;
    const info = (await api.get(`/dppapi/info`)).data;
    const policy = info.policy
    const allowTabs = filterTab(this.state.tabs, policy)
    const activeTab = allowTabs.length > 0 ? allowTabs[0]['component']: ''
    this.setState({
      project,
      tabs: allowTabs, activeTab
    });
  }

  handleSwitchTab(e, { name, component }) {
    this.setState({
      activeTab: component
    })
  }

  render() {
    const { match } = this.props;
    const { projectId } = match.params;
    const { activeTab, tabs, project } = this.state;

    return (
      <div>
        <Header disabled>{project && project.name}</Header>
        <Container>
          <Menu pointing secondary>
            { 
              tabs.map(tab => {
                return <Menu.Item
                  name = {tab.label}
                  component = {tab.component}
                  active={activeTab === tab.component}
                  onClick={this.handleSwitchTab}
                  key={tab.label}
                />
              })
            }
          </Menu>
            {
              activeTab === '' && <></>
            }
            {
              activeTab === 'ImagesConditions' && <ImagesConditions projectId={projectId}/>
            }
            {
              activeTab === 'Distribution' && <Distribution projectId={projectId}/>
            }
            {
              activeTab === 'UploadImages' && <UploadImages projectId={projectId}/>
            }
            {
              activeTab === 'UploadVideo' && <UploadVideo projectId={projectId}/>
            }
            {
              activeTab === 'TrainingData' && <ImportData projectId={projectId}/>
            }
            {
              activeTab === 'Reference' && <UploadReference projectId={projectId}/>
            }
            {
              activeTab === 'MLAssist' && <MLAssist projectId={projectId}/>
            }
            {
              activeTab === 'ImagesAll' && <ImagesAll projectId={projectId}/>
            }
        </Container>
      </div>
    );
  }
}
