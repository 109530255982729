import React from 'react';
import {
  select,
  scaleLinear,
  scaleBand,
  max,
  axisLeft,
  axisBottom
} from 'd3';
import { Header } from 'semantic-ui-react';

export default class BarChart extends React.Component {

  componentDidMount = () => {
    this.renderChart();
  }

  renderChart = () => {
    const { data, className } = this.props;
    let width = data.length * 80;
    if (data.length < 3) width = data.length * 150
    const height = 400;
    let svg = select('.'+className)
    const xValue = d => d.type;
    const yValueAll = d => d.all;
    const yValueLabeled = d => d.labeled;
    const margin = { top: 20, bottom: 40, right: 20, left: 50 };
    //Inner Width and Height calculation to give this for range calculation
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const xScale = scaleBand()
      .domain(data.map(xValue))
      .range([0, innerWidth])
      .padding(0.1); //to make separation of bars

    const yScale = scaleLinear()
      .domain([0, max(data, yValueAll)])
      .range([innerHeight, 0]); //reverse the Domain to get correct Axis

    svg.attr('width', width)
      .attr('height', height)

    const g = svg.append('g')
      // translate for moving the chart from its original position
      .attr('transform', `translate(${margin.left},${margin.top})`
      );

    // const xTickFormat = number =>
    //   format('10000')(number)

    g.append('g').call(axisLeft(yScale).ticks(1));
    g.append('g').call(axisBottom(xScale))
      .attr('transform', `translate(0,${innerHeight})`);

    data.forEach(tmp => {
      g.append('g')
        .attr('id', tmp.type)
        .append('rect')
        .attr('style', 'fill:#19ddcb')
        .attr('id', 'all_' + tmp.type)
        .attr('value', tmp.all)
        .attr('x', xScale(tmp.type))
        .attr('y', yScale(tmp.all))//Try to understand this
        .attr('width', xScale.bandwidth() / 2)
        //Try to understand this also.
        .attr('height', innerHeight - yScale(yValueAll(tmp)));

      g.select('#' + tmp.type).append('text')
        .attr('x', xScale(tmp.type) + 8)
        .attr('y', yScale(tmp.all) - 3)
        .attr('opacity', 1)
        .text(tmp.all)

      g.select('#' + tmp.type).append('rect')
        .attr('style', 'fill:#19dd')
        .attr('id', 'label_' + tmp.type)
        .attr('value', tmp.labeled)
        .attr('x', xScale.bandwidth() / 2 + xScale(tmp.type))
        .attr('y', yScale(tmp.labeled))//Try to understand this
        .attr('width', xScale.bandwidth() / 2)
        //Try to understand this also.
        .attr('height', innerHeight - yScale(yValueLabeled(tmp)));

      g.select('#' + tmp.type).append('text')
        .attr('x', xScale.bandwidth() / 2 + xScale(tmp.type) + 8)
        .attr('y', yScale(tmp.labeled) - 3)
        .attr('opacity', 1)
        .text(tmp.labeled)
    });
  }


  render() {
    const { className } = this.props;
    return (
      <div
        style={{
          width: 'auto',
          height: '500px'
        }}
      >
        <div  style={{
          width: 'auto',
          height: '50px'
        }}>
          <Header as='h2'>{className}</Header>
        </div>
        <svg className={className}>
        </svg>
        <svg height='400' width='120'>
          <g transform="translate(50,20)">
            <g transform="translate(0, 0)" className='circle1'>
              <circle r="13" fill="#19ddcb"></circle>
              <text dy="0.32em" x="15">All</text>
            </g>
            <g transform="translate(0, 30)" className='circle2'>
              <circle r="13" fill="#19dd"></circle>
              <text dy="0.32em" x="15">Labeled</text>
            </g>
          </g>
        </svg>
      </div>
    )
  }
}
