import React from 'react';
import { Icon, Button, Segment, Dropdown, Input } from 'semantic-ui-react';
import api from '../../api';
import DatePicker from 'react-date-picker';
import intl from 'react-intl-universal';
import LicenseAdd from './Add'
import './LicenseKey.css';
import { CloseIcon } from '../../assets/icon';

export default class LicenseKeySearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tenants: [],
      isShowAdd: false,
      editPreferences: {},
      tenantId: '',
      searchParam: {
        searchId: '', 
        tenantId: '',
        contractId: '',
        licenseKey: '', 
        endpoint: ''
      }
    };

    this.handleAdd = this.handleAdd.bind(this)
    this.toggleAdd = this.toggleAdd.bind(this)
  }

  async componentDidMount() {
    
    const { tenantId } = (await api.get(`/dppapi/info`)).data;

    const tenants = (await api.get(`/dppapi/system/tenant`)).data;
    this.setState({
      searchParam: { ...this.state.searchParam, tenantId},
      tenantId: tenantId,
      tenants: tenants.map(data => {
        return { text: data.name, key: data.id, value: data.id }
      })
    })
  }

  onReset = () => {
    this.setState({ searchParam: { 
      searchId: '', 
      tenantId: this.state.tenantId,
      contractId: '',
      licenseKey: '', 
      endpoint: ''
    }})
  }
  onChange = (type, value) => {
    this.setState({ searchParam: { ...this.state.searchParam, [type]: value}})
  };

  handleAdd() {
    this.setState({
      editPreferences: {},
      isShowAdd: true
    });
  };

  toggleAdd(isShowAdd) {
    this.setState({
      editPreferences: {},
      isShowAdd: isShowAdd
    });
  };

  render() {
    
    const { onSearch } = this.props;
    const { tenants, searchParam } = this.state;
    const { createdStartDate, createdEndDate,validStartDate,validEndDate, expiredStartDate, expiredEndDate, searchId, tenantId,contractId, licenseKey, endpoint} = searchParam;

    const deleteIcon = (key) => (<CloseIcon size={'20px'} onClick={()=>this.onChange(key,'')} className="search-licensekey-condition-delete-icon" />);
    return (
      <Segment>
        <div className="ui stackable two column grid">
          <div className='wide row search-licensekey-row'>
            <div className="wide column search-licensekey-condition">
              <p className="search-licensekey-label">{intl.get('_system.licenseKey.Search.TenantID')}</p>
              <Dropdown
                placeholder='Select Tenant'
                options={tenants}
                value={tenantId}
                search
                selection
                clearable
                onChange={(event, { value }) =>
                  this.setState({
                    searchParam: {...searchParam, tenantId: value,},
                    error:''
                  })
                }
                style={{ width: 'auto' }}
              />
            </div>
            <div className="wide column search-licensekey-condition">
              <p className="search-licensekey-label">{intl.get('_system.licenseKey.Search.ContractID')}</p>
              <Input
                className="search-licensekey-item"
                fluid
                icon={()=>contractId?deleteIcon('contractId'):null}
                placeholder={intl.get('_system.licenseKey.Search.Please enter a Contract ID')}
                onChange={e => this.onChange('contractId', e.target.value)}
                value={contractId}
              />
            </div>
          </div>
          <div className='wide row search-licensekey-row'>
            <div className="wide column search-licensekey-condition">
              <p className="search-licensekey-label">{intl.get('_system.licenseKey.Search.ID')}</p>
              <Input
                className="search-licensekey-item"
                fluid
                icon={()=>searchId?deleteIcon('searchId'):null}
                placeholder={intl.get('_system.licenseKey.Search.Please enter a ID')}
                onChange={e => this.onChange('searchId', e.target.value)}
                value={searchId}
              />
            </div>
            <div className="wide column search-licensekey-condition">
              <p className="search-licensekey-label">{intl.get('_system.licenseKey.Search.LicenseKey')}</p>
              <Input
                className="search-licensekey-item"
                fluid
                icon={()=>licenseKey?deleteIcon('licenseKey'):null}
                placeholder={intl.get('_system.licenseKey.Search.Please enter a License Key')}
                onChange={e => this.onChange('licenseKey', e.target.value)}
                value={licenseKey}
              />
            </div>
          </div>
          <div className='wide row search-licensekey-row'>
            <div className="wide column search-licensekey-condition">
              <p className="search-licensekey-label">{intl.get('_system.licenseKey.Search.endpoint')}</p>
              <Input
                className="search-licensekey-item"
                fluid
                icon={()=>endpoint?deleteIcon('endpoint'):null}
                placeholder={intl.get('_system.licenseKey.Search.Please enter a EndPoint')}
                onChange={e => this.onChange('endpoint', e.target.value)}
                value={endpoint}
              />
            </div>
            <div className="wide column search-licensekey-condition">
              <p className="search-licensekey-label">{intl.get('_system.licenseKey.Search.ExpiredTime')}</p>
              <DatePicker
                className="search-licensekey-item"
                onChange={date => this.onChange('expiredStartDate', date)}
                value={expiredStartDate}
                format="y/MM/dd"
              />{' '}
              ~
              <DatePicker
                className="search-licensekey-item"
                onChange={date => this.onChange('expiredEndDate', date)}
                value={expiredEndDate}
                format="y/MM/dd"
              />
            </div>
          </div>
          <div className='wide row search-licensekey-row'>
            <div className="wide column search-licensekey-condition">
              <p className="search-licensekey-label">{intl.get('_system.licenseKey.Search.CreatedAt')}</p>
              <DatePicker
                className="search-licensekey-item"
                onChange={date => this.onChange('createdStartDate', date)}
                value={createdStartDate}
                format="y/MM/dd"
              />{' '}
              ~
              <DatePicker
                className="search-licensekey-item"
                onChange={date => this.onChange('createdEndDate', date)}
                value={createdEndDate}
                format="y/MM/dd"
              />
            </div>
            
            <div className="wide column search-licensekey-condition">
              <p className="search-licensekey-label">{intl.get('_system.licenseKey.Search.ValidFrom')}</p>
              <DatePicker
                className="search-licensekey-item"
                onChange={date => this.onChange('validStartDate', date)}
                value={validStartDate}
                format="y/MM/dd"
              />{' '}
              ~
              <DatePicker
                className="search-licensekey-item"
                onChange={date => this.onChange('validEndDate', date)}
                value={validEndDate}
                format="y/MM/dd"
              />
            </div>
          </div>
          {/* <div className='wide row search-licensekey-row'>
            <div className="wide column search-licensekey-condition">
              <p className="search-licensekey-label">{intl.get('_system.licenseKey.Search.ExpiredTime')}</p>
              <DatePicker
                className="search-licensekey-item"
                onChange={date => this.onChange('expiredStartDate', date)}
                value={expiredStartDate}
                format="y/MM/dd"
              />{' '}
              ~
              <DatePicker
                className="search-licensekey-item"
                onChange={date => this.onChange('expiredEndDate', date)}
                value={expiredEndDate}
                format="y/MM/dd"
              />
            </div>
          </div> */}
          <div className='wide row search-licensekey-row'>
            <div className="wide column search-licensekey-condition">
              <Button type="submit"
                onClick={() => onSearch(searchParam)}>
                {intl.get('_system.licenseKey.Search.Search')}
              </Button>
              <Button
                onClick={() => this.onReset()}>
                {intl.get('_system.licenseKey.Search.Reset')}
              </Button>

              <Button icon onClick={() => this.handleAdd()}>
                  <Icon name='add' />
              </Button>
            </div>
          </div>
          {/* <div className='wide column'>
            <div className="search-licensekey-condition">
              <p className="search-licensekey-label">{intl.get('_system.licenseKey.Search.ID')}</p>
              <Input
                className="search-licensekey-item"
                fluid
                placeholder={intl.get('_system.licenseKey.Search.Please enter a ID')}
                onChange={e => this.onChange('searchId', e.target.value)}
                value={searchId}
              />
            </div>
            <div style={{ paddingTop: '25px' }}>
              <p className="search-licensekey-label">{intl.get('_system.licenseKey.Search.TenantID')}</p>
              <Dropdown
                placeholder='Select Tenant'
                options={tenants}
                value={tenantId}
                search
                selection
                clearable
                onChange={(event, { value }) =>
                  this.setState({
                    searchParam: {...searchParam, tenantId: value,},
                    error:''
                  })
                }
                style={{ width: 'auto' }}
              />
            </div>
            <div className="search-licensekey-condition">
              <p className="search-licensekey-label">{intl.get('_system.licenseKey.Search.ContractID')}</p>
              <Input
                className="search-licensekey-item"
                fluid
                placeholder={intl.get('_system.licenseKey.Search.Please enter a Contract ID')}
                onChange={e => this.onChange('contractId', e.target.value)}
                value={contractId}
              />
            </div>
            <div className="search-licensekey-condition">
              <p className="search-licensekey-label">{intl.get('_system.licenseKey.Search.LicenseKey')}</p>
              <Input
                className="search-licensekey-item"
                fluid
                placeholder={intl.get('_system.licenseKey.Search.Please enter a License Key')}
                onChange={e => this.onChange('licenseKey', e.target.value)}
                value={licenseKey}
              />
            </div>
            <div className="search-licensekey-condition">
              <p className="search-licensekey-label">{intl.get('_system.licenseKey.Search.endpoint')}</p>
              <Input
                className="search-licensekey-item"
                fluid
                placeholder={intl.get('_system.licenseKey.Search.Please enter a EndPoint')}
                onChange={e => this.onChange('endpoint', e.target.value)}
                value={endpoint}
              />
            </div>
            <div className="search-licensekey-condition">
              <p className="search-licensekey-label">{intl.get('_system.licenseKey.Search.CreatedAt')}</p>
              <DatePicker
                className="search-licensekey-item"
                onChange={date => this.onChange('createdStartDate', date)}
                value={createdStartDate}
                format="y/MM/dd"
              />{' '}
              ~
              <DatePicker
                className="search-licensekey-item"
                onChange={date => this.onChange('createdEndDate', date)}
                value={createdEndDate}
                format="y/MM/dd"
              />
            </div>
            <div className="search-licensekey-condition">
              <p className="search-licensekey-label">{intl.get('_system.licenseKey.Search.ValidFrom')}</p>
              <DatePicker
                className="search-licensekey-item"
                onChange={date => this.onChange('validStartDate', date)}
                value={validStartDate}
                format="y/MM/dd"
              />{' '}
              ~
              <DatePicker
                className="search-licensekey-item"
                onChange={date => this.onChange('validEndDate', date)}
                value={validEndDate}
                format="y/MM/dd"
              />
            </div>
            <div className="search-licensekey-condition">
              <p className="search-licensekey-label">{intl.get('_system.licenseKey.Search.ExpiredTime')}</p>
              <DatePicker
                className="search-licensekey-item"
                onChange={date => this.onChange('expiredStartDate', date)}
                value={expiredStartDate}
                format="y/MM/dd"
              />{' '}
              ~
              <DatePicker
                className="search-licensekey-item"
                onChange={date => this.onChange('expiredEndDate', date)}
                value={expiredEndDate}
                format="y/MM/dd"
              />
            </div>
            <div className="search-licensekey-condition">
              <Button type="submit"
                onClick={() => onSearch(searchParam)}>
                {intl.get('_system.licenseKey.Search.Search')}
              </Button>
              <Button
                onClick={() => onSearch(searchParam)}>
                {intl.get('_system.licenseKey.Search.Search')}
              </Button>
            </div>
          </div> */}
        </div>
        <LicenseAdd 
          isShowAdd={this.state.isShowAdd}
          toggleAdd={this.toggleAdd}
          preferences={this.state.editPreferences}
          getLicenses={onSearch}/>
      </Segment>
    );
  }
}
