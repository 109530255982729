import React, { Component } from 'react';
import {
  Segment,
  Button,
  Table,
  Input,
  Message,
  Loader,
  Pagination,
} from 'semantic-ui-react';
import api from '../../api';
import { confirmAlert } from 'react-confirm-alert';
import intl from 'react-intl-universal';

const TABLE_COLS = [
  {
    label: 'ID',
    key: 'id',
  },
  {
    label: 'Sub Title',
    key: 'subTitle',
  },
  {
    label: 'Item',
    key: 'item',
  },
  {
    label: 'Category',
    key: 'category',
  },
  {
    label: 'Global Result Item',
    key: 'globalResultItem',
  },
  {
    label: 'Fs Item',
    key: 'fsItem',
  },
  {
    label: 'Operation',
    key: 'operation',
  },
];

export default class ItemTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fsItems: {},
      isLoaded: false,
    }
  }

  handleChange = (event, item) => {
    const { fsItems } = this.state;
    fsItems[item.id] = event.target.value;
    this.setState({ fsItems: fsItems });
  }

  handleLink = async (item, fsItem) => {
    if (!fsItem || fsItem.trim() === '') {
      confirmAlert({
        title: intl.get('_setting.presetDIFF.Table.LinkTo'),
        message: intl.get('_setting.presetDIFF.Table.Placeholder'),
        buttons: [
          {
            label: intl.get('_setting.presetDIFF.Table.OK'),
          },
        ],
      });
    } else {

      confirmAlert({
        title: intl.get('_setting.presetDIFF.Table.LinkTo'),
        message: intl.get('_setting.presetDIFF.Table.Are you sure to do this?'),
        buttons: [
          {
            label: intl.get('_setting.presetDIFF.Table.OK'),
            onClick: async () => {
              await api.post(`/dppapi/tenant-setting/link-mapping`, { itemInfo: { ...item, fsItem } });
              this.props.search();
              this.setState({ fsItems: {} })
            },
          },
          {
            label: intl.get('_setting.presetDIFF.Table.Cancel'),
          },
        ],
      });
    }
  }

  handlePageChange = (event, data) => {
    const pageNo = data.activePage;
    this.props.search(pageNo);
  };

  renderHeaderCell = () => {
    return TABLE_COLS.map((col, index) => (
      <Table.HeaderCell key={index}>{col.label}</Table.HeaderCell>
    ));
  };

  renderCell = (items) => {
    const { fsItems } = this.state;
    return items.map((item, index) => (
      <Table.Row key={index}>
        {TABLE_COLS.map((col) => (
          <Table.Cell key={index + col.key}>
            {col.key === 'operation' ? (
              <React.Fragment>
                <Button
                  icon="linkify"
                  label={intl.get('_setting.presetDIFF.Table.LinkTo')}
                  size="mini"
                  onClick={() => this.handleLink(item, fsItems[item.id])}
                />
              </React.Fragment>
            ) : col.key === 'fsItem' ?
              (<Input
                placeholder={intl.get('_setting.presetDIFF.Table.Placeholder')}
                value={Boolean(fsItems[item.id]) ? fsItems[item.id] : ''}
                onChange={(event) => this.handleChange(event, item)}
              />)
              : (
                item[col.key]
              )}
          </Table.Cell>
        ))}
      </Table.Row>
    ));
  };

  render() {
    const { list, page, isLoading } = this.props;

    if (isLoading) {
      return <Loader active inline="centered" />;
    }
    return list && list.length > 0
      ? (
        <Segment>
          <Table celled padded>
            <Table.Header>
              <Table.Row>{this.renderHeaderCell()}</Table.Row>
            </Table.Header>
            <Table.Body>{this.renderCell(list)}</Table.Body>
          </Table>
          <Pagination
            size="mini"
            secondary
            activePage={page.pageNo}
            totalPages={
              typeof page.pageCount === 'undefined' ? 1 : page.pageCount
            }
            onPageChange={this.handlePageChange}
          />
        </Segment>
      )
      : (<Message info header="No Data" content="Please change conditions" />);
  }
}
