import React, { Component } from 'react';


export default class MuiMsgTip extends Component {
  render() {
    const { props } = this
    const { show, content } = props
    if (!show) {
      return null
    }

    return (
      < div
        className="ui pointing below prompt label mui-msg-tip">
        {content}
      </div >
    )
  }
}