import React, { Component } from 'react';

import { Form, Button, Message } from 'semantic-ui-react';
import { MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact';
import api from '../../../api'
import intl from 'react-intl-universal';

export default class ImportPreferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      loading: true,
      importError: null,
    };
    this.handleCsvSubmit = this.handleCsvSubmit.bind(this);
    this.clearAll = this.clearAll.bind(this);
  }

  async handleCsvSubmit(e) {
    if (!this.state.file) return;

    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);
    const { isShowImport, selectedRowsRef } = this.props;

    this.setState({ loading: false });
    try {
      if (selectedRowsRef && selectedRowsRef.current) {
        if (isShowImport) {
          switch (isShowImport[1]) {
            case 'aiFunction':
              selectedRowsRef.current.forEach(async projectId => {
                await this.importAifunction(projectId);
              });
              break;
            case 'resultFunction':
              selectedRowsRef.current.forEach(async projectId => {
                await this.importResultFunction(projectId);
              });
              break;
            case 'metadata':
              await this.importMetaData(selectedRowsRef.current, formData);
              break;
            default:
            // Do nothing
          }
        }
      }
      this.setState({
        file: null,
        loading: true
      });
      form.reset();
      this.props.getPreferences();
      this.props.toggleImport({
        isShowImport: [false, this.props.isShowImport[1]]
      });
    } catch (error) {
      this.setState({
        file: null,
        loading: true,
        importError: error.data.errmsg
      });
    }
  }

  importAifunction = async (projectId) => {
    const fileInput = document.getElementById('txt');
    const file = fileInput.files[0];
    if (file) {
      const reader = new FileReader();
      try {
        reader.onload = async (event) => {
          const fileContent = event.target.result;
          await api.put(`/dppapi/system/tenant-manage/project/api-function?projectId=${projectId}&tenantId=${this.props.tenantId}`, { apiFunction: fileContent });
        }
        reader.readAsText(file);
      } catch (error) {
        console.log("error:", error)
      }
    }
  }

  importResultFunction = async (projectId) => {
    const fileInput = document.getElementById('txt');
    const file = fileInput.files[0];
    const buttonName = document.getElementById('result_function_name').value;
    if (file && buttonName) {
      const reader = new FileReader();
      try {
        reader.onload = async (event) => {
          const fileContent = event.target.result;
          await api.put(`/dppapi/system/tenant-manage/project/projects-result-function/bulkImport?projectId=${projectId}&tenantId=${this.props.tenantId}`,
            { buttonName: buttonName, resultFunction: btoa(encodeURIComponent(fileContent)) });
        }
        reader.readAsText(file);
      } catch (error) {
        console.log("error:", error)
      }
    }
  }

  importMetaData = async (projectIds, formData) => {
    formData.append("projectIds", JSON.stringify(projectIds));
    await api.post(`/dppapi/system/tenant-manage/project/metadata/import?tenantId=${this.props.tenantId}`, formData);
  }

  clearAll() {
    this.setState({
      file: null,
      loading: true,
      importError: null
    });
    this.props.toggleImport({
      isShowImport: [false, this.props.isShowImport[1]]
    });
  }

  render() {
    const { loading, importError } = this.state;
    const { isShowImport } = this.props;
    const importMessage = importError ? (<Message negative>{importError}</Message>) : null;
    let titleName;
    let fileType;
    let labelText;
    if (isShowImport) {
      switch (isShowImport[1]) {
        case 'aiFunction':
          titleName = intl.get('_system.tenants.project.ImportTenantSetting.Import ApiFunction');
          labelText = intl.get('_system.tenants.project.ImportTenantSetting.Select TXT files');
          fileType = "txt";
          break;
        case 'resultFunction':
          titleName = intl.get('_system.tenants.project.ImportTenantSetting.Import ResultFunction');
          labelText = intl.get('_system.tenants.project.ImportTenantSetting.Select TXT files');
          fileType = "txt";
          break;
        case 'metadata':
          titleName = intl.get('_system.tenants.project.ImportTenantSetting.Import MetaData');
          labelText = intl.get('_system.tenants.project.ImportTenantSetting.Select CSV files');
          fileType = "csv";
          break;
        default:
        // Do nothing
      }
    }
    return (
      <div style={{ cursor: 'default' }}>
        <MDBModal
          isOpen={isShowImport && isShowImport[0]}
          toggle={(flag) => { this.setState({ importError: null }); this.props.toggleImport({ isShowEdit: flag }) }}
          centered
        >
          <MDBModalHeader>
            {titleName}
          </MDBModalHeader>
          {isShowImport && isShowImport[0] && (
            <MDBModalBody>
              {importMessage}
              <Form
                method="post"
                encType="multipart/form-data"
                onSubmit={this.handleCsvSubmit}
              >
                <Form.Input
                  multiple
                  label={labelText}
                  type="file"
                  id={fileType}
                  name={fileType}
                  accept={`.${fileType}`}
                  onChange={e => { this.setState({ file: e.target.value, importError: null }) }}
                />
                {isShowImport[1] === "resultFunction" && (
                  <Form.Field>
                    <label>Button Name</label>
                    <input placeholder='Button Name' id="result_function_name" />
                  </Form.Field>
                )}
                <Button floated='right' type="submit" loading={!loading} disabled={!loading} >{intl.get('_system.tenants.setting.ImportTenantSetting.Import')}</Button>
                <Button floated='right' onClick={this.clearAll}>{intl.get('_common.Cancel')}</Button>
              </Form>
            </MDBModalBody>
          )}
        </MDBModal>
      </div>
    );
  }
}
