import React, { Component } from 'react';
import { Button, Form, Message } from 'semantic-ui-react';
import intl from 'react-intl-universal';
import api from '../../api';
import Progress from './Progress';

const FILETYPES = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/jp2',
  'image/tiff',
  'image/bmp',
  'application/eps',
  'image/gif',
  'image/icns',
  'image/webp',
  'image/mpo',
];

export default class FolderUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      folderLoaded: true,
      fileList: [],
      errFileList: [],
      isAbort: false,
      fileIndex: 0,
      fileName: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    this.setState({
      errFileList: [],
      fileList: e.target.files,
      isAbort: false,
      fileIndex: 0,
      fileName: null,
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    const form = e.target;
    const { fileList, errFileList } = this.state;
    if (fileList.length === 0) {
      return;
    }
    this.setState({ folderLoaded: false });
    let percent = 100 / fileList.length;
    for (let i = 0; i < fileList.length; i++) {
      if (this.state.isAbort) continue;
      let file = fileList[i];
      if (FILETYPES.indexOf(file.type) < 0) {
        errFileList.push(file.webkitRelativePath + '（ Non-image ）');
        this.setState({
          errFileList,
        });
        continue;
      }
      this.setState({
        fileName: file.webkitRelativePath,
        fileIndex:
          fileList.length > 100
            ? Math.floor(percent * i * 100) / 100
            : Math.floor(percent * i),
      });
      await this.uploadFile(file);
    }
    this.setState({ folderLoaded: true });
    !this.state.isAbort &&
      this.setState({
        fileIndex: 100,
      });
    form.reset();
  }

  async uploadFile(file) {
    var { errFileList } = this.state;
    var form = new FormData();
    let blobSlice =
      File.prototype.slice ||
      File.prototype.mozSlice ||
      File.prototype.webkitSlice;
    form.append('imageFile', blobSlice.call(file));
    form.append('fileName', file.name);
    const resp = await (await api.post(
      `/dppapi/labeling/${this.props.projectId}/uploads/folder`,
      form
    )).data;
    if (!resp.success) {
      errFileList.push(file.webkitRelativePath + '（ ' + resp.errmsg + ' ）');
      this.setState({
        errFileList,
      });
      return;
    }
  }

  render() {
    const { folderLoaded, errFileList, fileName, fileIndex } = this.state;
    const message =
      errFileList.length > 0 ? (
        <Message negative>{errFileList.join(';　')}</Message>
      ) : null;
    return (
      <div>
        <div>
          <Form
            method="post"
            encType="multipart/form-data"
            onSubmit={this.handleSubmit}
          >
            <div className="field">
              <label htmlFor="file">
                {intl.get('_label.image.FolderUpload.Label')}
              </label>
              <div className="ui input">
                <input
                  type="file"
                  id="folder"
                  name="folder"
                  webkitdirectory="true"
                  multiple
                  onChange={this.onChange}
                />
              </div>
            </div>
            <Button
              type="submit"
              loading={!folderLoaded}
              disabled={!folderLoaded}
            >
              {intl.get('_label.image.FolderUpload.Upload')}
            </Button>
            <Button
              secondary
              onClick={() => {
                this.setState({ isAbort: true });
              }}
            >
              {intl.get('_label.image.FolderUpload.Abort')}
            </Button>
          </Form>
          {fileIndex > 0 && (
            <div>
              {fileName}
              <Progress nums={100} index={fileIndex} progressColor="#0077FF" />
            </div>
          )}
          {message}
        </div>
      </div>
    );
  }
}
