import React, { Component } from 'react';
import { 
  Button,
  Input,
  Icon
} from 'semantic-ui-react';
import { 
  ManualInputStatus,
  SubjectName
} from './FsConstant';
import '../pdf/MuiPopup.css';

export default class OperatePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cell: {scrumItem: '', manualInput: '', values: []},
      text: null,
      inputVal: '',
      changeFlag: null,
      value: ''
    };
  }

  componentDidMount() {
    this.handleReload();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.handleReload();
    }
  }


  handleEnterKey = (e) => {
    const { cell, changeFlag } = this.state;
    const { scrumItem } = cell;
    if ( e.keyCode === 13 && changeFlag ) {
      this.handleSubmitInput(scrumItem);
    }
  }

  handleSubmitInput = (scrumItem) => {
    const inputValue = this.state.inputVal;
    const splitSum = inputValue.toString().split('.')
    let integer = splitSum[0]
    const decimal = splitSum[1] ? `.${splitSum[1]}` : ""
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",")// format 12345678.88888 to 12,345,678.88888
    this.props.onInputChange(scrumItem, `${integer}${decimal}`)
    this.setState({ changeFlag: false, inputVal: `${integer}${decimal}` })
  }

  handleReload = () => {
    const {rows, row, header, onGetSum, ssTitleSplit, isSSTopLeftInvert} = this.props;
    var cell = {scrumItem: '', manualInput: '', values: []};
    var text = '';
    rows.forEach((r)=>{
      const split = ssTitleSplit ? ssTitleSplit : '/'
      const name = isSSTopLeftInvert ? row[SubjectName]+split+header: header+split+row[SubjectName]
      if (r.scrumItem === name) {
        cell = {scrumItem: r.scrumItem, manualInput: r.manualInput, values: r.values};
      } 
      if (r.scrumItem === name) {
        text = onGetSum(r.values);
      }  
    })
    this.setState({cell, text, inputVal: cell.manualInput, value: cell.manualInput})
  }

  handleShowDetail = (searchText) => {
    this.props.onTabChange(1, searchText)
  }

  handleSetOppositeValue = (values) => {
    values.forEach(value=>{
      if (value.invert) {
        value.invert = false;
      } else {
        value.invert = true;
      }
    })
    this.props.setInvertFlag(values);
  }

  render() {
    var { cell, changeFlag, inputVal, value } = this.state;
    const { scrumItem, manualInput, values } = cell;
    const { onEdit, onInputModeChange } = this.props;

    return (
      <div key={`popup-${scrumItem}`} >
        {manualInput === undefined
        ? 
        <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: '8px'}}>
          <Button
            primary
            size="mini"
            disabled={values.length === 0}
            onClick={() => this.handleShowDetail(scrumItem)}
          >{"詳細"}
          </Button>
          <Button
            color="teal"
            size="mini"
            onClick={() => {
              onInputModeChange(scrumItem, ManualInputStatus.INPUT)
              onEdit(true)
              this.setState({changeFlag: true})
            }}
            style={{ "paddingLeft": "5px","paddingRight": "5px"}}
          >{"手動入力"}
          </Button>
          <Button
            primary
            size="mini"
            disabled={values.length === 0}
            onClick={() => {
              this.handleSetOppositeValue(values)
              onEdit(true)
            }}
            style={{ "paddingLeft": "5px","paddingRight": "5px"}}
          >{"符号反転"}
          </Button>
        </div> : 
        <div style={{marginTop: '8px', display: 'flex'}}>
          <Input
            value={inputVal.replace(/,/g,"")}
            fluid
            style={{width: '65%'}}
            onChange={(e, data) => {
              if (data.value.length > 14) return;
              if (data.value.toString() !== value.replace(/,/g,"")) {
                changeFlag = true;
              } else {
                changeFlag = false;
              }
              this.setState({inputVal: data.value, changeFlag: changeFlag})
            }}
            onKeyDown={(e) => {
              this.handleEnterKey(e)
            }}  />
          <Button.Group size="mini" style={{marginLeft: '5px'}}>
            <Button icon onClick={() => { 
              onInputModeChange(scrumItem, ManualInputStatus.LINK)
              onEdit(true) }}>
              <Icon name="cancel" />
            </Button>
            <Button icon positive
              disabled={!changeFlag}
              onClick={() => {
                this.handleSubmitInput(scrumItem)
              }}>
              <Icon name="checkmark" />
            </Button>
          </Button.Group>
        </div>
          }
      </div>
    );
  }
}
