/* 0, Pending, 1, Review, 2, Passed, 3, Failed. */

const stateFlag = {
  1: 'Pending',
  2: 'Review',
  3: 'Passed',
  4: 'Failed'
}

export function getOptions() {
  const options = [];
  Object.keys(stateFlag).forEach(k => options.push({ key: k, value: k, text: stateFlag[k] }))
  return options
}

export function getStateFlag(k) {
  return stateFlag[k]
}
