import React, { Component } from 'react'
import MachineTable from './MachineTable'

class Machine extends Component {
  render() {
    return (
      <div className="ui">
        <MachineTable />
      </div>
    );
  }
}

export default Machine
