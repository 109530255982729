import React, { Component } from 'react';
import api from '../../api';
import { MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact';
import {
  Button,
  Dropdown,
  Form,
  Icon,
  Loader,
  Message,
} from 'semantic-ui-react';
import CSV from 'comma-separated-values';
import { chunk } from 'lodash';

class BulkImport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tenants: [],
      selectedTenant: '',
      isShowImport: false,
      error: '',
    };
  }

  async componentDidMount() {
    const tenants = (await api.get(`/dppapi/system/tenant`)).data;
    this.setState({
      tenants: tenants.map(data => {
        return { text: data.name, key: data.id, value: data.id }
      })
    })
  }

  toggleImport = (isShowImport) => {
    const {loading} = this.state;
    if(!loading) {
      this.setState({
        isShowImport: isShowImport,
        error: '',
      });
    }
  };

  handleImportPreset = async () => {
    const fileEle = document.getElementById('tenantFsItemsCsv');
    const {selectedTenant} = this.state;
    if (fileEle.files && fileEle.files[0]) {
      this.setState({ loading: true });

      const csvRslt = await new Promise((resolve) => {
        const file = fileEle.files[0];
        const fileReader = new FileReader();
        fileReader.onload = () => {
          resolve(CSV.parse(fileReader.result, { cast: ['String', 'String', 'String', 'String', 'Number'] }));
        }
        fileReader.readAsText(file);
      });

      const emptyTitle = csvRslt.find( d => d[0]==='' && d[1]==='');
      if(emptyTitle) {
        this.setState({ error: 'Please enter subTitle or item in each row', loading:false})
        return;
      }
      const emptyFs = csvRslt.find( d => d[3]==='');
      if(emptyFs) {
        this.setState({ error: 'Please enter ScrumItem in each row', loading:false})
        return;
      }

      const csvChunks = chunk(csvRslt, 200);
      try {
        for (let [index, fsItemChunk] of csvChunks.entries()) {
          const fsItems = fsItemChunk.map(element => {
            return {
              subTitle: element[0],
              item: element[1],
              category: element[2],
              fsItem: element[3],
              classification: element[4],
            }
          });
  
          const res = await api.put('/dppapi/system/tenant-item-bulk-import-preset', { fsItems, tenantId: Number(selectedTenant), mode: index === 0 ? 'init' : 'append' });
          if(!res.data.success) {
            this.setState({ error: res.data.errmsg, loading:false, isShowImport: false})
            return;
          }
          this.setState({ loadingText: Math.floor(((index + 1) / csvChunks.length) * 100) + '%' });
        }
      } catch (error) {
        this.setState({ error: error.data.errmsg, loading:false, isShowImport: false})
        return;
      }

      this.setState({error:'', loading:false, loadingText:'', isShowImport: false});
    }
  };

  handleImportConvertSubtitle = async () => {
    const fileEle = document.getElementById('tenantFsItemsCsv');
    const { selectedTenant } = this.state;
    if (fileEle.files && fileEle.files[0]) {
      this.setState({ loading: true });

      const csvRslt = await new Promise((resolve) => {
        const file = fileEle.files[0];
        const fileReader = new FileReader();
        fileReader.onload = () => {
          resolve(CSV.parse(fileReader.result, { cast: ['String', 'String', 'String'] }));
        };
        fileReader.readAsText(file);
      });

      const csvChunks = chunk(csvRslt, 200);
      try {
        for (let [index, itemChunk] of csvChunks.entries()) {
          const items = itemChunk.map((arr) => {
            return {
              subtitleName: arr[0],
              convertSubtitle: arr[1],
              category: arr[2],
            };
          });
          await api.put(`/dppapi/system/tenant-item-bulk-import-convert-subtitle`, {
            items: items,
            tenantId: Number(selectedTenant),
            mode: index === 0 ? 'init' : 'append'
          });
          this.setState({
            loadingText: Math.floor(((index + 1) / csvChunks.length) * 100) + '%',
          });
        }
      } catch (error) {
        this.setState({ error: error.data.errmsg, loading: false, isShowImport: false })
        return;
      }

      this.setState({ error: '', loading: false, loadingText: '', isShowImport: false });
    }
  };

  render() {
    const { tenants, selectedTenant, loading, loadingText, isShowImport, error, } = this.state;
    const { importFlag } = this.props;
    const tenantName = tenants && tenants.find(d => d.value === selectedTenant) ? tenants.find(d => d.value === selectedTenant).text : '';
    return (
      <div className="ui" style={{minHeight: '300px'}}>
        <Form>
          <Form.Field style={{ paddingTop: '25px' }}>
            <Dropdown
              placeholder='Select Tenant'
              options={tenants}
              value={selectedTenant}
              search
              selection
              clearable
              onChange={(event, { value }) =>
                this.setState({
                  selectedTenant: value,
                  error:''
                })
              }
              style={{ width: 'auto' }}
            />
          </Form.Field>
          <Form.Field>
            <Button
              onClick={() => this.toggleImport(true)}
              basic={true}
              color="black"
              disabled={tenantName === ''}
            >
              <Icon name="sign-in" />
              Import
            </Button>
          </Form.Field>
        </Form>
        {error && error !== '' && (
          <Message error >
            {error}
          </Message>
        )}
        <MDBModal
          isOpen={isShowImport}
          toggle={this.toggleImport}
        >
          <MDBModalHeader>Import Item Mapping: {tenantName}</MDBModalHeader>
          <MDBModalBody>
            {loading ? (
              <Loader active inline="centered">
                {loadingText}
              </Loader>
            ) : (
              <div>
                {error && error !== '' && (
                  <Message error >
                    {error}
                  </Message>
                )}
                <Form>
                  <Form.Field>
                    <Form.Input
                      label={'CSV File'}
                      multiple
                      type="file"
                      id="tenantFsItemsCsv"
                      name="tenantFsItemsCsv"
                      accept=".csv"
                      onClick={() => this.setState({ error: '' })}
                    />
                  </Form.Field>
                  <Button
                    size="small"
                    onClick={() => {
                      if (importFlag === 'preset') {
                        this.handleImportPreset();
                      } else if (importFlag === 'convertSubtitle') {
                        this.handleImportConvertSubtitle();
                      }
                    }}
                    floated="right"
                  >
                    <Icon name="save" />
                    Import
                  </Button>
                  <Button
                    size="small"
                    onClick={() => this.setState({ isShowImport: false })}
                    floated="right"
                  >
                    <Icon name="cancel" />
                    Cancel
                  </Button>
                </Form>
              </div>
            )}
          </MDBModalBody>
        </MDBModal>
      </div>
    );
  }
}

export default BulkImport;
