import React, { Component } from 'react'
import PredictProjects from './PredictProjects'
import PdfUpload from './PdfUpload'
import PredictSearch from './PredictSearch'
import { createCookie, getCookie } from '../../common/CookieUtil';
import Dropzone from 'react-dropzone';
import "./PredictPortal.css"
import { ImgDropFileIcon } from '../../assets/icon';

export default class Portal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      projectId: "" ,
      files : [] ,
      isDragFiles : false,
      errorMessage : "",
      errFiles : "",
    }
    this.parsingTypeIdList = ["1", "2", "3", "4", "5"];
    this.PredictSearchRef = React.createRef();
    try{
      this.parsingTypeIdList = JSON.parse(getCookie("predict_analysis_targets"));
    }catch{}
  }

  handleProjectIdChange = (projectId) => {
    this.setState({ projectId })
  }

  setFiles = (state) => {
    this.setState({files : state})
  }

  setIsDragFiles = (state) => {
    this.setState({ isDragFiles : state})
  }

  setErrorMessage = (state) => {
    this.setState({errorMessage : state})
  }

  setErrFiles = (state) => {
    this.setState({errFiles :state})
  }

  componentDidUpdate(prevProps,prevState) {
    if (this.state.files !== prevState.files) {
      this.setIsDragFiles(false)
    }
  }

  onDrop = (propsFiles) => { 
    this.setFiles([...this.state.files,...propsFiles])
  };

  handleCheckboxChange = (e, object) => {
    let id = object.id.split('_')[0];
    if(object.checked){
      if(!this.parsingTypeIdList.includes(id)){
        this.parsingTypeIdList.push(id);
      }
    }else{
      const index = this.parsingTypeIdList.indexOf(id);
      if(index > -1){
        this.parsingTypeIdList.splice(index, 1);
      }
    }
    createCookie('predict_analysis_targets', JSON.stringify(this.parsingTypeIdList), 365);
  }

  onClearErrFiles = () => {
    this.setErrorMessage("");
    this.setErrFiles("");
  }

  handleDragEnter = (event) => {
    this.onClearErrFiles();
    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.setIsDragFiles(true);
    }
  }

  handleDragLeave = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.setIsDragFiles(false);
    }
  }

  render() {
    const { projectId ,files, isDragFiles ,errorMessage ,errFiles} = this.state
    const { history } = this.props

    return (
      <Dropzone onDrop={this.onDrop} noClick>
        {({getRootProps, getInputProps ,open}) => (
          <div {...getRootProps()} 
          onDragEnter={this.handleDragEnter}
          onDragLeave={this.handleDragLeave}
          >
            <input {...getInputProps()} />
            <div className={isDragFiles ? "file-drag-container" : ""}>
            {isDragFiles && (
                <div className='bg-color-file-upload-overlay'>
                  <div className='predict-admin-management-drop-container'>
                    <ImgDropFileIcon />
                    <p className='predict-admin-drop-message'>ファイルをドロップ</p>
                  </div>
                </div>
            )}
            <div className="predict-admin-root">
              <div className="predict-admin-management">
                <PredictProjects onProjectIdChange={this.handleProjectIdChange} projectId={projectId} />
                <PdfUpload
                  projectId={projectId}
                  parsingTypeIdList={this.parsingTypeIdList}
                  handleCheckboxChange={this.handleCheckboxChange}
                  predictSearchRef={this.PredictSearchRef}
                  open={open}
                  files={files ? files : []}
                  setFiles={this.setFiles}
                  onClearErrFiles={this.onClearErrFiles}
                  errorMessage={errorMessage}
                  setErrorMessage={this.setErrorMessage}
                  errFiles={errFiles}
                  setErrFiles={this.setErrFiles}
                />
              </div>
              <div className="predict-admin-search predict-admin-search-padding-right">
                  <PredictSearch projectId={projectId} history={history} ref={this.PredictSearchRef} />
              </div>
            </div>
            </div>
          </div>
        )}
      </Dropzone>
    )
  }
}