import React, { Component } from 'react';
import { MDBDataTable } from 'mdbreact';
import { Button, Icon, Segment, Message, Loader } from 'semantic-ui-react';
import { confirmAlert } from 'react-confirm-alert';
import LicenseAdd from './Add'
import LicenseEdit from './Edit'
import { getCsrfToken } from '../../common/CookieUtil';
import api from '../../api'
import intl from 'react-intl-universal';
import moment from "moment";
import { FMT } from '../tenants/TimeUtil';

class LicenseKeyTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isShowAdd: false,
      isShowEdit: false,
      editPreferences: {},
      data: {}
    }

    this.getLicenses = this.getLicenses.bind(this)
    this.handleDel = this.handleDel.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.toggleAdd = this.toggleAdd.bind(this)
    this.toggleEdit = this.toggleEdit.bind(this)
  }

  getLicenses() {
    const licenseDatas = this.props.licenseDatas;
    let rows = [];
    let columns = [];
    rows = licenseDatas.map((row, _)=> {
      return {
        operation: (
          <div style={{width: 90}}>
            <Button
              icon="trash"
              label={intl.get('_system.licenseKey.LicenseKey.Delete')}
              size="tiny"
              onClick={() => this.handleDel(row)}
            />
            <Button
              icon="copy"
              label={intl.get('_system.licenseKey.LicenseKey.Copy')}
              size="tiny"
              onClick={() => {navigator.clipboard.writeText(row.licenseKey)}}
            />
            <Button
              icon="edit"
              label={intl.get('_system.licenseKey.LicenseKey.Edit')}
              size="tiny"
              onClick={() => this.handleEdit(row)}
            />
          </div>
        ),
        keyId: row.keyId,
        contractId: row.contractId,
        createdAt: moment.utc(row.createdAt).local().format('YYYY-MM-DD HH:mm:ss'),
        validFrom: row.validFrom ? moment.utc(row.validFrom).local().format('YYYY-MM-DD HH:mm:ss') : '',
        expiredTime: moment.utc(row.expiredTime).local().format('YYYY-MM-DD HH:mm:ss'),
        ipFilter: (<div style={{ width: '100px',wordBreak: 'break-all' }}> {row.ipFilter}</div>),
        licenseKey: (<div style={{width:'100px',overflow:'hidden','textOverflow': 'ellipsis'}}>{row.licenseKey}</div>),
        machineCount: row.machineCount,
        pageCount: row.pageCount,
        tenantId: row.tenantId,
        description: row.description,
        easyocrEp: row.easyocrEp
      }
    }).sort((l1, l2) => {
      return l1.keyId - l2.keyId
    })
    columns = [
      {
        label: intl.get('_system.licenseKey.LicenseKey.ID'),
        field: 'keyId',
        sort: 'asc'
      },
      {
        label: intl.get('_system.licenseKey.LicenseKey.TenantID'),
        field: 'tenantId',
        sort: 'asc'
      },
      {
        label: intl.get('_system.licenseKey.LicenseKey.PageCount'),
        field: 'pageCount',
        sort: 'asc',
      },
      {
        label: intl.get('_system.licenseKey.LicenseKey.MachineCount'),
        field: 'machineCount',
        sort: 'asc',
      },
      {
        label: intl.get('_system.licenseKey.LicenseKey.ContractID'),
        field: 'contractId',
        sort: 'asc',
      },
      {
        label: intl.get('_system.licenseKey.LicenseKey.LicenseKey'),
        field: 'licenseKey',
        sort: 'asc',
      },
      {
        label: intl.get('_system.licenseKey.LicenseKey.IPFilter'),
        field: 'ipFilter',
        sort: 'asc',
      },
      {
        label: intl.get('_system.licenseKey.LicenseKey.CreatedAt'),
        field: 'createdAt',
        sort: 'asc',
      },
      {
        label: intl.get('_system.licenseKey.LicenseKey.ValidFrom'),
        field: 'validFrom',
        sort: 'asc',
      },
      {
        label: intl.get('_system.licenseKey.LicenseKey.ExpiredTime'),
        field: 'expiredTime',
        sort: 'asc',
      },
      {
        label: intl.get('_system.licenseKey.LicenseKey.Description'),
        field: 'description'
      },
      {
        label: intl.get('_system.licenseKey.LicenseKey.easyocrEp'),
        field: 'easyocrEp',
        sort: 'asc',
      },
      {
        label: intl.get('_system.licenseKey.LicenseKey.Operation'),
        field: 'operation',
        sort: 'asc',
      },
    ]
    return {
      rows: rows,
      columns: columns
    };
  }

  handleAdd() {
    this.setState({
      editPreferences: {},
      isShowAdd: true
    })
  }

  handleEdit(user) {
    this.setState({
      editPreferences: user,
      isShowEdit: true
    })
  }

  toggleAdd(isShowAdd) {
    this.setState({
      editPreferences: {},
      isShowAdd: isShowAdd
    })
  }

  toggleEdit(isShowEdit) {
    this.setState({
      editPreferences: {},
      isShowEdit: isShowEdit
    })
  }

  handleDel(user) {
    confirmAlert({
      title: intl.get('_system.preferences.Preferences.Delete this record'),
      message: intl.get('_system.preferences.Preferences.Are you sure to do this?'),
      buttons: [
        {
          label: intl.get('_system.preferences.Preferences.Delete'),
          onClick: async () => {
            await api.delete(`/dppapi/system/monitor/licensekeys/${user.keyId}`)
            this.props.reload()
          },
        },
        {
          label: intl.get('_system.preferences.Preferences.Cancel'),
        },
      ],
    })
  }

  handleTimeChange = (date, timeType) => {
    const formatDate = date ? moment(date).format(FMT.DATE) : ""
    this.setState({ editPreferences: {...this.state.editPreferences, [timeType]: formatDate}})
  }

  async handleExport() {
    confirmAlert({
      message: intl.get('_system.licenseKey.LicenseKey.Do you want to get license key'),
      buttons: [
        {
          label: intl.get('_system.licenseKey.LicenseKey.Cancel'),
        },
        {
          label: intl.get('_system.licenseKey.LicenseKey.Download'),
          onClick: async () => {
            let a = document.createElement('a');
            a.href = `/dppapi/system/monitor/licensekeys/export?token=${getCsrfToken()}&${this.props.searchParam}`;
            a.click();
            a.remove();
          },
        },
      ],
    })
  }

  render() {
    const { isLoading } = this.props
    const data = this.getLicenses();
    if(isLoading){
      return (<Segment><Loader active inline="centered" /></Segment>)
    }
    if(!data.rows || data.rows.length ===0 ){
      return (
        <Message
          info
          header='No Data'
          content="please change conditions"
        />
      )
    }
    return (
      <Segment>
        <Button floated="right" icon onClick={() => this.handleExport()}>
            <Icon name='download' />
        </Button>
        <Button floated="right" icon onClick={() => this.handleAdd()}>
          <Icon name='add' />
        </Button>
        <MDBDataTable theadColor="common-search-table-header" btn striped bordered hover data={data} /> 
        <LicenseAdd 
          isShowAdd={this.state.isShowAdd}
          toggleAdd={this.toggleAdd}
          preferences={this.state.editPreferences}
          getLicenses={this.props.reload}/>
        <LicenseEdit 
          isShowEdit={this.state.isShowEdit}
          toggleEdit={this.toggleEdit}
          preferences={this.state.editPreferences}
          getLicenses={this.props.reload}
          onTimeChange={this.handleTimeChange}
          />   
      </Segment>
    );
  }
}

export default LicenseKeyTable;
