import React, { Component } from 'react';
import moment from "moment";
import { Segment, Header, Button, Message, Loader, Popup, Icon } from 'semantic-ui-react';
import { confirmAlert } from 'react-confirm-alert';
import { MDBDataTable } from 'mdbreact';
import PdfUpload from './PdfUpload';
import PdfSearch from './PdfSearch';
import { toUnixMS, FMT, unixMSToDatetime } from '../TimeUtil';
import { downloadCsv, getPdfStatus, getPdfStatusOptions } from '../Util';
import { PdfStatus, STATE_3, STATE_5, defaultStatusText, defaultStatusColor } from '../Constant';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
// import '../PredictApp.css';
import api from '../../api';
import { getCsrfToken } from '../../common/CookieUtil';

const option = {
  header: ['pdf id', 'page name', 'subtitle', 'item', 'value', 'scrum item', 'original item'],
}

export default class PredictAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      init: true,
      rows: [],
      changeId: null,
      isLoading: false,
      permissionFlag: false
    };
    
    this.handleCheckout = this.handleCheckout.bind(this);
    this.pdfFilesToRows = this.pdfFilesToRows.bind(this);
    this.refetch = this.refetch.bind(this);
  }
  
  componentDidMount = async () => {
    const per = localStorage.getItem("permission") || '';
    let permissionFlag = false;
    per.split(',').forEach((item)=>{
      if (item === '2') {
        permissionFlag = true;
      }
    })
    this.setState({permissionFlag});

    const conditions = {
      category: 'configure',
      subCategory: 'nodejs',
      name: 'predict',
      subName: 'allow_file_download',
    }
    const res = (await api.post(
      '/dppapi/predict/system/preference/query', { conditions }
    )).data;
    
    let isAllowFileDownload = 0
    if (res.success) {
      isAllowFileDownload = Number(res.value)
    }

    const param = {
      category: 'configure',
      subCategory: 'nodejs',
      name: 'pdf-status',
      subName: 'status-info',
    }

    const statusRes = await api.request({
      url: `/dppapi/predict/tenant/preference/query`,
      method: 'POST',
      data: param,
    });
    const statusInfo = getPdfStatus(statusRes)
    const statusOptions = getPdfStatusOptions(statusInfo);

    this.setState({isAllowFileDownload,statusInfo,statusOptions});
  }

  isProcessing = (rabbitmqMessages, pdf) => {
    let processing = true
    for (let i = 0; i < rabbitmqMessages.length; i++) {
      if (rabbitmqMessages[i].pdfFile.id === pdf.id) {
        processing = false
        break
      }
    }
    return processing
  }
  
  onSearch = async data => {
    const { projectId } = this.props.match.params;
    const { fileName, startDate, endDate, creator, operator, status } = data;

    const req = {
      projectId,
      fileName,
      startDate: startDate ? toUnixMS(moment(startDate).format(FMT.DATE)) : '',
      endDate: endDate ? toUnixMS(moment(endDate).add(1, 'd').format(FMT.DATE)): toUnixMS(moment(moment().toDate()).add(1, 'd').format(FMT.DATE)),
      creator,
      operator,
      status
    };

    const pdfFiles = (await api.get(
      `/dppapi/predict/pdfs/${req.projectId}?fileName=${req.fileName}&creatorId=${req.creator}&operatorId=${req.operator}&state=${req.status}&uploadStartTime=${req.startDate}&uploadEndTime=${req.endDate}`
    )).data;

    // get rabbitmq message info
    const messageRes = (await api.get('/dppapi/predict/rabbitmq/fs-inference-messages')).data;

    let rabbitmqMessages = []
    let useRabbitmq = false

    if (messageRes.success) {
      rabbitmqMessages = messageRes.data.messages
      useRabbitmq = messageRes.data.useRabbitmq
    }

    let rows = [];
    if (pdfFiles) {
      rows = pdfFiles.map((pdfFile) => {
        if (useRabbitmq && pdfFile.stateFlag === PdfStatus.WAIT_AI_PROCESS && this.isProcessing(rabbitmqMessages, pdfFile)) { 
          pdfFile.stateFlag = PdfStatus.AI_PROCESSING
        }
        return this.pdfFilesToRows(projectId, pdfFile);
      })
    }

    this.setState({
      init: false,
      rows: rows
    });
  };
  
  async refetch() {
    let { rows, changeId } = this.state;
    const { projectId } = this.props.match.params;
    
    const pdf = (await api.get(`/dppapi/predict/${projectId}/pdfs/${changeId}`)).data;
    if (pdf.id && pdf.deleteFlag === 0) {
      rows = rows.map(row => {
        if (pdf.id === row.id) {
          return this.pdfFilesToRows(projectId, pdf);
        } else {
          return row;
        }
      })
    } else {
      rows = rows.filter(row => {
        return changeId !== row.id;
      })
    }
    this.setState({
      init: false,
      rows: rows,
      changeId: null,
    });
  }

  pdfFilesToRows (projectId, pdf) {
    const { permissionFlag, isAllowFileDownload, statusInfo } = this.state;
    const text = statusInfo[pdf.stateFlag]&&statusInfo[pdf.stateFlag].text?statusInfo[pdf.stateFlag].text:defaultStatusText;
    const color = statusInfo[pdf.stateFlag]&&statusInfo[pdf.stateFlag].color?statusInfo[pdf.stateFlag].color:defaultStatusColor;
    return Object.assign({}, pdf, {
      id: pdf.id,
      originalName: (
        <div style={{ width: '300px' }}>
          {isAllowFileDownload ? <a 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{ wordBreak: 'break-all' }} 
            href={`/dppapi${pdf.link}?token=${getCsrfToken()}`}
          >
            {pdf.originalName}
          </a> : <p>{pdf.originalName}</p> }
        </div>
      ),
      pdfName: pdf.originalName,
      uploadTime: unixMSToDatetime(pdf.uploadTime, FMT.DATETIME_LOCAL),
      state: pdf.stateFlag === 4 ? <Popup content={pdf.errmsg} trigger={<div style={{ paddingBottom: "10px",color:color }} >{text}</div>} /> : <div style={{ color:color }} >{text}</div>,
      stateFlag: pdf.stateFlag,
      creator: pdf.createdByName,
      operator: pdf.userInChargeName,
      action: (
        <div>
          <Button
            icon="user"
            size="mini"
            label={intl.get('_predict.admin.PredictAdmin.Person')}
            onClick={() => this.handleCheckout(projectId, pdf.id)}
          />
          {pdf.stateFlag === STATE_3 || pdf.stateFlag === STATE_5? 
            (<Link
              target="_blank"
              rel="noopener noreferrer"
              to={`/predict/detail/${projectId}/${pdf.id}`}
            >
              <Button 
                icon="pencil"
                label={intl.get('_predict.admin.PredictAdmin.Edit')}
                size="mini" />
            </Link>) : null}
          <Button
            icon="trash"
            label={intl.get('_predict.admin.PredictAdmin.Delete')}
            size="mini"
            onClick={() => this.handleDel(projectId, pdf.id)}
          />
          {permissionFlag && <Button
            icon="download"
            label={intl.get('_predict.admin.PredictAdmin.Analysis')}
            size="mini"
            onClick={() => this.handleExportPdf(projectId, pdf.id)}
          />}
        </div>
      )
    })
  }

  async handleCheckout(projectId, pdfId) {
    confirmAlert({
      title: intl.get('_predict.admin.PredictAdmin.Changeperson'),
      message: intl.get('_predict.admin.PredictAdmin.Areyousure'),
      buttons: [
        {
          label: intl.get('_predict.admin.PredictAdmin.OK'),
          onClick: async () => {
            await api.post(`/dppapi/predict/${projectId}/pdfs/checkout/${pdfId}`);
            this.setState({
              changeId: pdfId,
            });
            this.refetch();
          },
        },
        {
          label: intl.get('_predict.admin.PredictAdmin.Cancel'),
        },
      ],
    })
  }

  async handleDel(projectId, pdfId) {
    confirmAlert({
      title: intl.get('_predict.admin.PredictAdmin.Delete'),
      message: intl.get('_predict.admin.PredictAdmin.Areyousuredelete'),
      buttons: [
        {
          label: intl.get('_predict.admin.PredictAdmin.OK'),
          onClick: async () => {
            this.setState({
              isLoading: true
            })
            await api.delete(`/dppapi/predict/${projectId}/${pdfId}/delete`);
            this.setState({
              changeId: pdfId,
            });
            this.refetch();
            this.setState({
              isLoading: false
            })
          },
        },
        {
          label: intl.get('_predict.admin.PredictAdmin.Cancel'),
        },
      ],
    });
  }

  sortRows = (value) => {
    let {rows} = this.state;
    let {column} = value;
    if (column === 'originalName') {
      if (value.direction === 'asc') {
        rows.sort(function(a, b) { return a.pdfName > b.pdfName ? 1 : -1;} );
      } else {
        rows.sort(function(a, b) { return a.pdfName < b.pdfName ? 1 : -1;} );
      }
      this.setState({
        rows: rows,
      })
    }
  }

  handleExportPdf = async (projectId, pdfId) => {
    let body = []
    const { scrumMaps } = await (await api.get(`/dppapi/predict/pdf/scrum/mapping/${projectId}/${pdfId}`)).data;
    if (scrumMaps) {
      this.scrumMapsToCsvData(body, scrumMaps, pdfId);
    }
    const sufix = moment().format(FMT.TIME_STRING)
    downloadCsv(body, option, `analysis_${pdfId}_${sufix}.csv`)
  }

  handleExportPdfAll = async (projectId, rows) => {
    let body = []
    let exportList = [];
    rows.forEach(row => {
      const pdfFileId = row.id;
      if (row.stateFlag === STATE_3 || row.stateFlag === STATE_5) {
        exportList.push(new Promise((resolve => {
          api.request({
            url: `/dppapi/predict/pdf/scrum/mapping/${projectId}/${pdfFileId}`,
            method: 'GET'
          }).then(({ data }) => {
            resolve(data)
          })
        })))
     }
    })
    Promise.all(exportList).then(rst => {
      rst.forEach(tmp => {
        const { scrumMaps } = tmp;
        if (scrumMaps) {
          this.scrumMapsToCsvData(body, scrumMaps, tmp.pdfFileId);
        }
      })
      const sufix = moment().format(FMT.TIME_STRING)
      downloadCsv(body, option, `analysis_search_${sufix}.csv`)
    })
  }

  scrumMapsToCsvData = (body, scrumMaps, pdfId) => {
    var csvData = [];
    Object.keys(scrumMaps).forEach(tableName => {
      if (tableName === 'manual_input') return;
      var newTableList = scrumMaps[tableName].filter(table => {
        if ((table.scrumItem || (table.value && table.value.text)) 
          && table.scrumItem !== table.originalItem) {
          return true;
        } else {
          return false;
        }
      });
      newTableList.forEach(table => {
        csvData.push({
          "pdfId": pdfId,
          "pageName": tableName,
          "subtitle": table.subTitle.text,
          "item": table.item.text,
          "value": table.value.text,
          "scrumItem": table.scrumItem,
          "originalItem": table.originalItem || '',
        });
      })
    })

    const manualInput = scrumMaps['manual_input'];
    var newCsvData = csvData;
    manualInput && Object.keys(manualInput).forEach(pageName => {
      Object.keys(manualInput[pageName]).forEach(item => {
        if (!manualInput[pageName][item]) return;
        newCsvData = newCsvData.filter(data => {
          if (data.scrumItem !== item) {
            return true;
          } else {
            return false;
          }
        });
        newCsvData.push({
          "pdfId": pdfId,
          "pageName": pageName,
          "subtitle": '',
          "item": '',
          "value": manualInput[pageName][item],
          "scrumItem": item,
          "originalItem": '',
        })
      })
    })

    newCsvData.forEach(csvData => {
      if (csvData.scrumItem.indexOf('B/S') !== -1) return;
      var row = [];
      row.push(
        csvData.pdfId,
        csvData.pageName,
        csvData.subtitle,
        csvData.item,
        csvData.value,
        csvData.scrumItem,
        csvData.originalItem,
      );
      body.push(row);
    })
    return body;
  }

  render() {
    const { rows, init, isLoading, permissionFlag } = this.state;
    const { projectId } = this.props.match.params;
    const columns = [
      {
        label: 'ID',
        field: 'id',
        sort: 'asc',
        width: 150,
      },
      {
        label: intl.get('_predict.admin.PredictAdmin.Filename'),
        field: 'originalName',
        sort: 'asc',
        width: 200,
      },
      {
        label: intl.get('_predict.admin.PredictAdmin.Processingstate'),
        field: 'state',
        sort: 'asc',
        width: 150,
      },
      {
        label: intl.get('_predict.admin.PredictAdmin.Uploadtime'),
        field: 'uploadTime',
        sort: 'asc',
        width: 150,
      },
      {
        label: intl.get('_predict.admin.PredictAdmin.Author'),
        field: 'creator',
        sort: 'asc',
        width: 150,
      },
      {
        label: intl.get('_predict.admin.PredictAdmin.Personincharge'),
        field: 'operator',
        sort: 'asc',
        width: 150,
      },
      {
        label: intl.get('_predict.admin.PredictAdmin.Operation'),
        field: 'action',
        sort: 'disabled',
        width: 150,
      },
    ];
    let downloadFlag = false;
    rows.forEach(row => {
      if (row.stateFlag === STATE_3 || row.stateFlag === STATE_5) {
        downloadFlag = true;
      }
    })
    const data = {
      columns: columns,
      rows: rows
    }
    const button = (permissionFlag ?  <Button
      icon
      floated="right"
      onClick={()=>{this.handleExportPdfAll(projectId, rows)}}
      disabled={!downloadFlag}
     >
       <Icon name='download' />
     </Button> :
     '')
    var result = null;
    if (isLoading) {
      result = (<Segment><Loader active inline="centered" /></Segment>)
    } else if (rows.length > 0) {
      result = (<Segment>
        {button}
        <MDBDataTable
          theadColor="common-search-table-header"
          striped
          bordered
          small
          entriesLabel='表示件数'
          data={data}
          onSort={ value => this.sortRows(value) }
        />
      </Segment>)
    } else {
      result = (<Message
        info
        header='No Data'
        content="please change conditions"
      />)
    }
    return (
      <React.Fragment>
        <div style={{ display: 'flex' }}>
          <div className="ui" style={{ flex: 1 }}>
            <div id="upload-pdf" style={{ padding: '2em 0' }}>
              <Header disabled>{intl.get('_predict.admin.PredictAdmin.Fileupload')}</Header>
              <PdfUpload projectId={projectId} />
            </div>
            <div id="search-pdf" style={{ padding: '2em 0' }}>
              <Header disabled>{intl.get('_predict.admin.PredictAdmin.Filtering')}</Header>
              <PdfSearch 
              onSearch={this.onSearch}
              projectId={projectId} 
              statusOptions={this.state.statusOptions}
              />
            </div>
            <div id="page" style={{ paddingBottom: 200, flex: 1 }}>
              {!init ? result : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
