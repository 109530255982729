import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import _ from 'lodash';
import { Select, Button, Loader, Icon, Dropdown, Popup, Dimmer } from 'semantic-ui-react';
import FsTextTable from './FsTextTable';
import FsResultFunctionToolBox from './FsResultFunctionToolBox';
import api from '../../../api';
import 'semantic-ui-css/semantic.min.css';
import { genId, genNegativeId } from '../../../label/label-app/utils';
import { downloadCsv, formatCurrency, scrollToElementByPosition } from '../../Util';
import { halfToFull, fullToHalf, filterCharacters } from '../../CharacterUtil';
import moment from 'moment';
import { FMT } from '../../TimeUtil';
import FsKeyValueTable from './FsKeyValueTable';
import FsKeyValueBoard from './FsKeyValueBoard';
import FsDetailTable from './FsDetailTable';
import {
  TableMode,
  MappingType,
  PageTypeList,
  ManualInputStatus,
  BSheaderList,
  BScurrentEndBanaceList,
  PageType,
  TextChangeType,
} from './FsConstant';
import { confirmAlert } from 'react-confirm-alert';
import { metaDataToMap, checkScrumItemWithAll } from '../../ScrumItemUtil';
import { PAGE_CLASSIFICATION, SCRUM_ITEM_SUFFIX } from '../../Constant';
import { TYPES, track2DBStorage } from '../../../common/PDFTrackUtil';
import Toasts from '../../../common/Toasts';
import { findIndex } from 'lodash';
import intl from 'react-intl-universal';
import { CloseIcon, MoreVertIcon, ViewWeekIcon, GridOnIcon, TableRowsNarrowIcon } from '../../../assets/icon';
import ItemSearchPopup from './ItemSearchPopup';
import { AnalyticsServiceContext } from '../../../common/Analytics/AnalyticsServiceProvider';
import { EventName, EventType, ViewMode } from '../../../common/Analytics/analyticsMetrics';
import { ErrorForCommentIcon } from '../../../assets/icon/ErrorForCommentIcon';
import { crop, getDataUrlFromArr, createNewImage } from '../../../common/ImageUtil';
import { getCsrfToken } from '../../../common/CookieUtil';
import { FactCheckIcon } from '../../../assets/icon/FactCheckIcon';
import { FactCheckActiveIcon } from '../../../assets/icon/FactCheckActiveIcon';
import Hotkeys from 'react-hot-keys';

export default class FsPdfTextMapping extends Component {
  constructor(props) {
    super(props);
    this.boardRef = React.createRef();
    this.container = React.createRef();
    this.resizeObserver = null;
    this.state = {
      currentPageType: '',
      checkedList: [],
      allCheck: false,
      searchText: '',
      category: {},
      pageType: [],
      mode: props.tenantPreference['review_page_aggregation_detail'] === 'detail' ? TableMode.TOTALDETAIL : TableMode.SCRUM,
      mappingType: MappingType.LIST_AND_TABLE,
      detailData: {},
      isDownloading: false,
      scrumMappingList: {},
      errorList: [],
      pdfStatus: null,
      scrumData: {},
      fsKeyValueTableScrollTop: 0,
      fsDetailTableScrollTop: 0,
      metaMap: {},
      isLoading: true,
      allList: [],
      metadata: {},
      // tenantPreference: { template_SS: null },
      preference: {},
      scrollToElement: null,
      isPortalOpen: false,
      cacheMappingType: MappingType.LIST_AND_TABLE,
      isReportOpen:false,
      reportmessage:'',
      // pdfInfo: null, // get pdfInfo from props
      isCancel: false,
      ssCommon: true,
      manualCalc: {},
      newRowPosition: {},
      rowsAddDataUrl: [],
      fsItems: {},
      figuresArray: {},
      imageData: null,
      fsItemsCompletion: false,
      detailItemChanging: false,
      textmappingOperatorHeight: 0,
      moreBtnAlign: 'left',
    };
    this.valueList = [];
    this.inputList = [];
  }
  static contextType = AnalyticsServiceContext;

  async componentDidMount() {
    // localStorage.removeItem("figuresArray");
    // localStorage.removeItem("dataUrls");
    this.observeSize()
  }
  componentWillUnmount(){
    if(this.resizeObserver){
      this.resizeObserver.disconnect()
    }
  }

  observeSize = () => {
    this.resizeObserver = new ResizeObserver(() => {
      const changeViewModeButtonGroup = document.querySelector("#change-view-mode-btn-group")
      const pdfTextmappingPrimaryBtns = document.querySelectorAll(".pdf-textmapping-primary-btn")
      let lastPdfTextmappingPrimaryBtn = undefined;
      if(pdfTextmappingPrimaryBtns){
        // primary buttonの右端の要素を取得
        lastPdfTextmappingPrimaryBtn = pdfTextmappingPrimaryBtns[pdfTextmappingPrimaryBtns.length - 1]
      }
      const saveButton = document.querySelector("#save-button")
      // primary buttonが一つ以上ある場合
      if(changeViewModeButtonGroup && lastPdfTextmappingPrimaryBtn){
        // 二つの要素の高さが同じ場合、スタイルを追加
        if(lastPdfTextmappingPrimaryBtn.offsetTop === changeViewModeButtonGroup.offsetTop){
          changeViewModeButtonGroup.style.marginLeft = "auto"
        }else {
          changeViewModeButtonGroup.style.marginLeft = 0
        }
        // primary buttonがない場合
      }else if(changeViewModeButtonGroup  && saveButton){
        // 二つの要素の高さが同じ場合、スタイルを追加
        if(saveButton.offsetTop === changeViewModeButtonGroup.offsetTop){
          changeViewModeButtonGroup.style.marginLeft = "auto"
        }else {
          changeViewModeButtonGroup.style.marginLeft = 0
        }
      }
      // 高さを取得し、stateへ設定する
      if(this.state.textmappingOperatorHeight !== this.container.current.offsetHeight){
        this.setState({
          textmappingOperatorHeight : this.container.current.offsetHeight,
        })
      }
      if(changeViewModeButtonGroup){
        const moreBtnAlign = changeViewModeButtonGroup.offsetLeft>20 ? 'left' : 'right'
        if(moreBtnAlign !== this.state.moreBtnAlign)
        this.setState({
          moreBtnAlign: moreBtnAlign
        })
      }
    });
    if(this.container.current !== null){
      this.resizeObserver.observe(this.container.current);
    }
  }

  async initialComponent(keepPageType = false) {
    await this.handleGetMetaData(['separation', 'fs_formula', 'fs_bias', 'fs_bias_positive', 'fs_bias_negative', 'tab']);
    await this.handleGetPreference({
      category: "configure",
      subCategory: "nodejs",
      name: "prediction-post-processing",
      subName: "regular-filter-pattern"
    });
    await this.handleReload(keepPageType);
    if(!keepPageType){
      let defaultPageType = this.handelGetThumbnailCurrentPageType();
      this.handlePageTypeChange(defaultPageType)
      let viewFlg = 1;
      if (defaultPageType === '株主資本等変動計算書' 
        && this.props.tenantPreference['template_SS']
        && this.props.tenantPreference['template_SS'] !== 'COMMON_LIST') {
        viewFlg = 3;
      }
      if (this.state.mode === TableMode.TOTALDETAIL) {
        viewFlg = 4;
      }
      this.props.onListViewChange(viewFlg);
    }

    this.handleRefreshCanvas();
    // get all the figures
    await this.handleGetFiguresArray();
    // get all the fs_items
    this.handleGetFsItemsArray();
  }

  handleGetPreference = async conditions => {
    const res = (await api.post(
      '/dppapi/predict/system/preference/query', { conditions }
    )).data;
    if (res.success) {
      this.setState({
        preference: {
          ...this.state.preference,
          [`${conditions.name}_${conditions.subName}`]: res.value,
        },
      });
    }
  }

  handleGetMetaData = async categoryList => {
    const { projectId } = this.props;
    categoryList.forEach(async category => {
      const res = await api.request({
        url: `/dppapi/predict/pdf/${projectId}/metadata/query?category=${category}`,
        method: 'GET',
      });
      if (res.data.success) {
        const { metadata } = this.state;
        this.setState({
          metadata: { ...metadata, [category]: res.data.metadatas },
        });
      }
    });
  };

  handleSetScrollTop = (table, scrollTop) => {
    this.setState({ [table]: scrollTop });
  };

  handleCheck = detailData => {
    const { metaMap, metadata,referItemList } = this.state;
    const allList = checkScrumItemWithAll(detailData, metaMap, metadata, referItemList);
    const errorList = allList.filter(all => all.isError === true);
    this.setState({ allList: allList });
    console.log("handleCheck metaMap:", metaMap, errorList, referItemList);
    return errorList;
  };

  handleReload = async (keepPageType = false) => {
    const { projectId, pdfFileId } = this.props;
    this.setState({
      isLoading: true,
    });

    const { data } = await api.request({
      url: `/dppapi/predict/pdf/scrum/mapping/${projectId}/${pdfFileId}`,
      method: 'GET',
    });

    const res = await api.request({
      url: `/dppapi/predict/pdf/${projectId}/metadata/query?category=scrumItem`,
      method: 'GET',
    });
    console.log("res:", res);
    this.setState({ metaMap: metaDataToMap(res.data.metadatas), metadatas: res.data.metadatas });

    const referData = await api.request({
      url: `/dppapi/predict/pdf/${projectId}/metadata/query?category=item_reference`,
      method: 'GET'
    })
    const referMap = {}
    const referItemList = []
    if(referData.data.success){
      referData.data.metadatas.forEach(d=>{
        const referCategory = d.subCategory.split('=>');
        const val = d.value.split('=>');
        const referItem = {
          'category': val[0],
          'item': d.item,
          'originalItem': val[1],
          'position': referCategory[1],
          'positionCategory': referCategory[0],
        }
        if(referCategory[0]){
          if(referCategory[0] in referMap){
            referMap[referCategory[0]][[referCategory[1]]] = referItem
          }else{
            referMap[referCategory[0]] = {
              [referCategory[1]]: referItem
            }
          }
        }
        referItemList.push(referItem);
      })
    }
    this.setState({referItemList});
    const headerList = BSheaderList;
    if (data.success && res.data.success) {
      // get DetailData and copy
      const { scrumMaps } = data;
      let manualCalc = data.manualCalc ? data.manualCalc : {}
      Object.keys(scrumMaps).forEach(scrumSubTitle => {
        if (scrumSubTitle !== 'manual_input') {
          if (scrumSubTitle === PageTypeList[0]) {
            scrumMaps[scrumSubTitle].forEach(scrum => {
              headerList.forEach(header => {
                if (scrum.scrumItem === header) {
                  this.valueList.push(scrum);
                }
              });
            });
          }
          data.scrumMaps[scrumSubTitle].forEach(d => {
            d['id'] = genId();
          });
        } else {
          Object.keys(scrumMaps[scrumSubTitle]).forEach(page => {
            if (page === PageTypeList[0]) {
              headerList.forEach(header => {
                if (scrumMaps[scrumSubTitle][page][header]) {
                  let inputValue = {
                    scrumItem: header,
                    value: {
                      id: '-1',
                      text: scrumMaps[scrumSubTitle][page][header],
                      invert: false,
                    },
                  };
                  this.inputList.push(inputValue);
                }
              });
            }
          });
        }
      });
      const CurrentEndBanaceList = BScurrentEndBanaceList;
      if (!data.scrumMaps[PageTypeList[2]]) {
        data.scrumMaps[PageTypeList[2]] = [];
      }
      CurrentEndBanaceList.forEach(tmp => {
        const value = headerList[CurrentEndBanaceList.indexOf(tmp)];
        let scrumItemList = [];
        this.inputList.forEach(inputV => {
          if (inputV.scrumItem === value) {
            scrumMaps[PageTypeList[2]].push({
              id: genId(),
              scrumItem: tmp,
              value: {
                id: '-1',
                text: inputV.value.text,
                invert: inputV.value.invert,
              },
              item: {},
              subTitle: {},
            });
            scrumItemList.push(value);
          }
        });
        let inputFlag = false;
        this.valueList.forEach(v => {
          scrumItemList.forEach(inputScrum => {
            if (v.scrumItem === inputScrum) {
              inputFlag = true;
            }
          });
          if (v.scrumItem === value && !inputFlag) {
            scrumMaps[PageTypeList[2]].push({
              id: genId(),
              scrumItem: tmp,
              value: { id: '-1', text: v.value.text, invert: v.value.invert },
              item: {},
              subTitle: {},
            });
          }
        });
      });
      this.valueList = [];
      this.inputList = [];
      const tmpDetailData = _.cloneDeep(data.scrumMaps);
      this.setState({ detailData: data.scrumMaps, tmpDetailData });

      // convert catagory data to scrumMappingList
      let scrumMappingList = {};
      res.data.metadatas.forEach(md => {
        if (scrumMappingList[md.item]) {
          const formula = this.state.metadata.fs_formula;
          const aggreationItem = formula.find(
            f => f.subCategory === md.item && f.item === md.value
          );
          if (aggreationItem) {
            const autoAggregation = `${md.value}${
              SCRUM_ITEM_SUFFIX.AGGREGATION
            }`;
            scrumMappingList[md.item].push(autoAggregation);
          }
          scrumMappingList[md.item].push(md.value);
        } else {
          const formula = this.state.metadata.fs_formula;
          const aggreationItem = formula.find(
            f => f.subCategory === md.item && f.item === md.value
          );
          if (aggreationItem) {
            const autoAggregation = `${md.value}${
              SCRUM_ITEM_SUFFIX.AGGREGATION
            }`;
            scrumMappingList[md.item] = [autoAggregation];
            scrumMappingList[md.item].push(md.value);
          } else {
            scrumMappingList[md.item] = [md.value];
          }
        }
      });

      // get ScrumData and copy
      const scrumData = this.convertData(data.scrumMaps, scrumMappingList, referMap);
      const errorList = this.handleCheck(this.state.detailData);
      // add aggreation to scrumData
      const scrumDataWithAggreation = this.convertAggreation(
        _.cloneDeep(scrumData)
      );

      const tmpScrumData = _.cloneDeep(scrumDataWithAggreation);
      const tmpManualCalc = _.cloneDeep(manualCalc);
      this.setState({
        scrumData: scrumDataWithAggreation,
        tmpScrumData,
        scrumMappingList,
        errorList,
        manualCalc,
        tmpManualCalc,
        referMap
      });

      const currentPageType = keepPageType ? this.state.currentPageType : '';
      this.genTableInfo(currentPageType, scrumMappingList);
      this.getPdfStatus();
    } else {
      this.setState({
        detailData: [],
        tmpDetailData: [],
        scrumData: [],
        tmpScrumData: [],
      });
      // TODO
      console.log('api error');
    }

    this.setState({
      isLoading: false,
    });
  };

  handleChangeScrumMapData = (bboxId, bboxContent) => {

    const {detailData, tmpDetailData} = this.state
  
    for (const key in detailData) {
      if (key !== "manual_input") {
        const items = detailData[key];
        for (const item of items) {
          if (item.value.id === bboxId) {
            item.value.text = bboxContent;
            break;
          }
        }
      }
    }

    for (const key in tmpDetailData) {
      if (key !== "manual_input") {
        const items = tmpDetailData[key];
        for (const item of items) {
          if (item.value.id === bboxId) {
            item.value.text = bboxContent;
            break;
          }
        }
      }
    }
    this.updateScrumData(); // Canvas had updated , force update scrumData
    this.setState({detailData, tmpDetailData})
  }
    
  getPdfStatus = async () => {
    const { handleGetPdfStatus } = this.props;
    await handleGetPdfStatus();
    // const pdf = await api.request({
    //   url: `/dppapi/predict/${projectId}/pdfs/${pdfFileId}`,
    //   method: 'GET',
    // });
    // this.setState({ pdfInfo: pdf.data })
    // this.setState({ pdfStatus: pdf.data.stateFlag });
  };
  convertData = (detailData, scrumMappingList, referMap) => {
    const converScrumData = {};
    Object.keys(scrumMappingList).forEach(pt => {
      const tmp = [];
      scrumMappingList[pt].forEach(scrumItem => {
        const manualInput = this.getManualInputValue(detailData, pt, scrumItem);
        const row = {
          id: genId(),
          scrumItem: scrumItem,
          values: [],
          manualInput,
        };
        if (detailData[pt]) {
          detailData[pt].forEach(sm => {
            if (sm.scrumItem === scrumItem) {
              row.values.push(sm.value);
            }
          });
        }
        tmp.push(row);

        //refer item
        if(pt in referMap &&referMap[pt][scrumItem]){
          const referItem = referMap[pt][scrumItem]
          const referManualInput = this.getManualInputValue(detailData, referItem.category, referItem.originalItem);
          const referRow = {
            id: genId(),
            scrumItem: referItem.item,
            values: [],
            manualInput: referManualInput,
            isRefer: true,
            originalItem: referItem.originalItem,
            originalPageType: referItem.category
          };
          const referRowAgg = {
            id: genId(),
            scrumItem: referItem.item+SCRUM_ITEM_SUFFIX.AGGREGATION,
            values: [],
            isRefer: true,
            originalItem: referItem.originalItem,
          };
          if(detailData[referItem.category]){
            detailData[referItem.category].forEach(sm => {
              if (sm.scrumItem === referItem.originalItem) {
                referRow.values.push(sm.value);
              }
            });
          }
          tmp.push(referRowAgg);
          tmp.push(referRow);
        }
      });
      converScrumData[pt] = tmp;
    });
    return converScrumData;
  };

  getManualInputValue = (detailData, currentPageType, scrumItem) => {
    if (detailData.manual_input === undefined) {
      return undefined;
    }
    if (detailData.manual_input[currentPageType] === undefined) {
      return undefined;
    }
    if (detailData.manual_input[currentPageType][scrumItem] === undefined) {
      return undefined;
    }
    return detailData.manual_input[currentPageType][scrumItem];
  };

  genTableInfo = async (currentPageType, scrumMappingList) => {
    const pageType = [];
    // let currentPageType = ""
    PageTypeList.forEach((name, idx) => {
      if (currentPageType.length === 0 && idx === 0) {
        currentPageType = name;
      }
      pageType.push({ value: name, text: name });   
    });

    this.getAllCatagory(scrumMappingList);
    this.setState({ pageType, currentPageType });
  };

  getAllCatagory = async scrumMappingList => {
    const category = {};
    Object.keys(scrumMappingList).forEach(pageType => {
      const options = [];
      scrumMappingList[pageType].forEach((v, idx) => {
        options.push({ id: idx, text: v, value: v });
      });
      category[pageType] = options;
    });
    this.setState({ category: category });
  };

  handleGetFiguresArray = async () => {
    const fieldName = 'pdfImageId';
    const pageList = _.chain(this.state.detailData) // get all the page num
      .flatMap(array => Array.isArray(array) ? array.map(item => item[fieldName]) : [])
      .compact()
      .uniq()
      .value();

    let figuresArray = [];
    let preTargetValueLink;
    for (const pageNum of pageList) {
      let figuresList = {};
      let imageArray = {};
      const result = await api.request({
        url: `/dppapi/predict/pdf/page/bboxes/${this.props.projectId}/${pageNum}`,
        method: 'GET',
      });
      if (result.data.success) {
        const imagesInfo = result.data.image;
        if (!preTargetValueLink || preTargetValueLink !== imagesInfo.link) {
          const imageUrl = '/dppapi' + imagesInfo.link + '&token=' + getCsrfToken();
          imageArray = await this.createImage(imageUrl);
          preTargetValueLink = imagesInfo.link;
        }
        figuresList.bbox = imagesInfo.labelData.labels.bbox;
        figuresArray.push({ ...figuresList, ...imageArray });
      }
    }
    this.setState({ figuresArray: figuresArray });
  }

  getConvertPageType = (row) => {
    const thumnailRef = this.props.thumbnailRef.current;
    let convertPageType = this.state.currentPageType;
    if (row.pdfImageId) { // ss page cell(B/S入力) have no pdfImageId , use currentPageType
      convertPageType = _.find(thumnailRef.state.imageList, { pageId: row.pdfImageId.toString() }).pageClassification;
    }
    if (/^連結/.test(convertPageType)) {
      convertPageType = convertPageType.substring(2);
    }
    return convertPageType;
  }

  handleGetFsItemsArray = async () => {
    let needLocalStorageData = true;
    const storageFsItems = localStorage.getItem('fsItems') ? JSON.parse(localStorage.getItem('fsItems')) : {};
    var storageFsItemsKeys = [];
    if (!_.isEmpty(storageFsItems)) {
      const { data } = await api.get('/dppapi/predict/get-last-modified-time');
      if (data.success && data) {
        if (storageFsItems.last_modified_time !== '' && storageFsItems.last_modified_time > data.last_modified_time
        ) {
          needLocalStorageData = true;
          storageFsItemsKeys = Object.keys(storageFsItems.list)
        } else {
          needLocalStorageData = false;
        }
      }
    } else {
      needLocalStorageData = false;
    }
    // if (needLocalStorageData) {
    //   this.setState({ fsItemsCompletion: true });
    //   this.setState({ fsItems: storageFsItems.list }); 
    // }

    const { detailData } = this.state;
    const { preference } = this.state;
    const pt = preference['prediction-post-processing_regular-filter-pattern'];
    let allRows = !detailData ? [] : detailData;
    let itemList = [];
    Object.keys(allRows).forEach((category) => {
      Array.isArray(allRows[category]) && allRows[category].forEach((row) => {
        const subtitleText = row.subTitle ? row.subTitle.text : row.subTitle.length > 0 && row.subTitle[0].text;
        const itemText = row.item ? row.item.text : row.item.length > 0 && row.item[0].text;
        const scrumItem = row.scrumItem ? row.scrumItem : '';
        const isBlankGroup = subtitleText === '' && itemText === '';
        if (subtitleText !== undefined &&
          itemText !== undefined &&
          row.pdfImageId !== undefined &&
          !isBlankGroup &&
          scrumItem === '' ) { // *****/当期末残高(B/S入力)
          
          const filterSubtitleText = filterCharacters(subtitleText, pt);
          const filteritemText = filterCharacters(itemText, pt);
          const pageClassification = this.getConvertPageType(row);
          if (needLocalStorageData && storageFsItemsKeys.includes(`${pageClassification}||${filterSubtitleText}||${filteritemText}`)) {
            
          } else {
            const item = {
              key1: filterSubtitleText,
              key2: filteritemText,
              projectId: this.props.projectId,
              category: pageClassification,
            };
            itemList.push(item);
          }
        }
      })
    })
    
    const sfmInfo = await this.getSfmInfo(itemList);
    const newStorageFsItems = localStorage.getItem('fsItems') ? JSON.parse(localStorage.getItem('fsItems')) : {};
    const mergeSfmInfo = {
      "last_modified_time": sfmInfo.last_modified_time,
      "list": { ...newStorageFsItems.list, ...sfmInfo.list },
      "pdf": _.union(storageFsItems.pdf ? storageFsItems.pdf : [], sfmInfo.pdf)
    }
    this.setState({ fsItems: mergeSfmInfo.list, fsItemsCompletion: true });
    localStorage.setItem("fsItems", JSON.stringify(mergeSfmInfo));
  };

  getSfmInfo = async (itemList) => {
    let fsItemsList = {};
    const { pdfInfo } = this.props;
    fsItemsList.pdf = [pdfInfo.id];
    fsItemsList.last_modified_time = Date.now();
    for (const index in itemList) {
      const item = itemList[index];
      const { data } = await api.post('/dppapi/predict/get-sfm', [item]);
      const fsItemKey = item.category + '||' + item.key1 + '||' + item.key2;
      if (data.success && data.scrum_field_mappings && data.scrum_field_mappings.length > 0) {
        fsItemsList.list = { ...fsItemsList.list, [fsItemKey]: data.scrum_field_mappings[0].scrum_field_name };
      } else {
        fsItemsList.list = { ...fsItemsList.list, [fsItemKey]: '' };
      }
    }
    return fsItemsList;
  }

  handleGetDetailItems = () => {
    let detailItems = [];
    let dataUrls = [];
    const { pdfInfo, isUsingLocalStorage } = this.props;
    const { detailData, figuresArray, fsItems, currentPageType, preference } = this.state;
    const storageDataUrls = localStorage.getItem('dataUrls') && isUsingLocalStorage ? JSON.parse(localStorage.getItem('dataUrls')) : {};
    // eslint-disable-next-line@
    let rows =
      !detailData ||
        (detailData && detailData[currentPageType] === undefined)
        ? []
        : detailData[currentPageType];
    const pt = preference['prediction-post-processing_regular-filter-pattern'];
    Array.isArray(rows) && rows.forEach((row, index) => {
      const rowSubtitleId = row.subTitle ? row.subTitle.id : row.subTitle.length > 0 && row.subTitle[0].id;
      const rowItemId = row.item ? row.item.id : row.item.length > 0 && row.item[0].id;
      const rowValueId = row.value ? row.value.id : row.values.length > 0 && row.values[0].id;
      const targetSubtitle = _.find(figuresArray, (item) => _.find(item.bbox, { id: rowSubtitleId }));
      const targetItem = _.find(figuresArray, (item) => _.find(item.bbox, { id: rowItemId }));
      const targetValue = _.find(figuresArray, (item) => _.find(item.bbox, { id: rowValueId }));
      const currentPageIndex = _.findIndex(figuresArray, (item) => _.find(item.bbox, { id: rowValueId }));
      const pageClassification = this.getConvertPageType(row);
      let detailItem = {};
      detailItem.scrumFieldName = '';
      detailItem.subTitle = '';
      detailItem.item = '';
      detailItem.value = '';
      if (row.subTitle.text !== undefined
        && row.item.text !== undefined
        && (!row.scrumItem || row.scrumItem === '')
        && fsItems) { // *****/当期末残高(B/S入力))
        detailItem.scrumFieldName = fsItems[pageClassification + '||' + filterCharacters(row.subTitle.text, pt) + '||' + filterCharacters(row.item.text, pt)];
      }
      if (!_.isEmpty(targetValue)) {
        const imageHeight = figuresArray[currentPageIndex].height;
        const imageWidth = figuresArray[currentPageIndex].width;
        const points = _.find(targetValue.bbox, { id: rowValueId }).points;
        let dataUrl;
        if (!_.isEmpty(storageDataUrls) && storageDataUrls[pdfInfo.id] && storageDataUrls[pdfInfo.id][currentPageType]) {
          dataUrl = JSON.parse(localStorage.getItem("dataUrls"))[pdfInfo.id][currentPageType][index];
        } else {
          const { cropedImageData, width, height } =
            crop(targetValue.imageData, this.transformPoints(points, imageHeight), imageWidth, imageHeight);
          dataUrl = getDataUrlFromArr(cropedImageData, width, height);
          dataUrls.push(dataUrl);
        }
        detailItem.dataUrl = dataUrl;
        if (row.subTitle && targetSubtitle) {
          const subTitle = {};
          subTitle.accuracy = _.find(targetSubtitle.bbox, { id: rowSubtitleId }).accuracy;
          subTitle.accThreshold = _.find(targetSubtitle.bbox, { id: rowSubtitleId }).accThreshold;
          detailItem.subTitle = subTitle;
        }

        if (row.item && targetItem) {
          const item = {};
          item.accuracy = _.find(targetItem.bbox, { id: rowItemId }).accuracy;
          item.accThreshold = _.find(targetItem.bbox, { id: rowItemId }).accThreshold;
          detailItem.item = item;
        }

        if (row.value && targetValue) {
          const value = {};
          value.accuracy = _.find(targetValue.bbox, { id: rowValueId }).accuracy;
          value.accThreshold = _.find(targetValue.bbox, { id: rowValueId }).accThreshold;
          value.stateFlag = _.find(targetValue.bbox, { id: rowValueId }).stateFlag;
          detailItem.value = value;
        }
      }
      detailItems.push(detailItem);
    })

    const isNotCurrentPage =
      !_.isEmpty(storageDataUrls) && pdfInfo &&
      (!storageDataUrls[pdfInfo.id] || (storageDataUrls[pdfInfo.id] && !storageDataUrls[pdfInfo.id][currentPageType]));

    if ((_.isEmpty(storageDataUrls) || isNotCurrentPage) && dataUrls.length > 0) {
      const currentPageDataUrl = {};
      if(storageDataUrls[pdfInfo.id]){
        currentPageDataUrl[pdfInfo.id] = storageDataUrls[pdfInfo.id];
      } else {
        currentPageDataUrl[pdfInfo.id] = {};
      }
      currentPageDataUrl[pdfInfo.id][currentPageType] = dataUrls;
      localStorage.setItem("dataUrls", JSON.stringify(currentPageDataUrl));
    }
    return detailItems;
  }

  createImage = async (imageUrl) => {
    let data;
    const promiseArray = [];
    const imageArray = {};
    promiseArray.push(new Promise(resolve => {
      const img = new Image();

      img.onload = async function () {
        const { height, width } = this;
        data = createNewImage(height, width, img).data;
        imageArray.imageData = data;
        imageArray.height = height;
        imageArray.width = width;
        resolve();
      }
      img.src = imageUrl;
    }))

    await Promise.all(promiseArray);
    return imageArray;
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedBBox ,tenantPreference} = this.props;
    if (
      !_.isEqual(prevState.detailData, this.state.detailData) ||
      !_.isEqual(prevState.allList, this.state.allList)
    ) {
      this.updateScrumData();
    }
    if (selectedBBox.id !== prevProps.selectedBBox.id) {
      if (selectedBBox.id === '' && selectedBBox.text === '') {
        this.setState({ isPortalOpen: false });
      } else {
        this.setState({ isPortalOpen: true });
      }
    }
    if(this.state.currentPageType !== prevState.currentPageType && this.state.currentPageType === PageType.SS){
      this.context.handleSetPredictDetailViewMode("")
    }else if ((this.state.currentPageType !== prevState.currentPageType && this.state.currentPageType !== PageType.SS )|| (this.state.mappingType !== prevState.mappingType)){
      this.context.handleSetPredictDetailViewMode(this.state.mappingType === MappingType.LIST_AND_TABLE ? ViewMode.List : ViewMode.Card)
    }
    if (((tenantPreference["template_SS"]) ? tenantPreference["template_SS"] === "COMMON_LIST" : true) && tenantPreference['review_page_aggregation_detail'] !== 'detail' && this.state.ssCommon) {
      this.setState({ ssCommon: false });
    }
    
    if(this.resizeObserver){
      this.resizeObserver.disconnect()
    }
    this.observeSize()
  }

  updateScrumData = () => {
    const { selectedCell, onUpdateSelectedCell } = this.props;
    const { detailData, scrumMappingList, referMap, scrumData, currentPageType, mode } = this.state;
    const newScrumData = this.convertData(
      detailData,
      scrumMappingList,
      referMap
    );
    const isChanged = _.find(newScrumData[currentPageType], { id: selectedCell.id });
    if (mode === TableMode.SCRUM && selectedCell.id !== '' && !isChanged) {
      const selectedLineIndex = _.findIndex(scrumData[currentPageType], { id: selectedCell.id });
      if (selectedLineIndex !== -1) {
        onUpdateSelectedCell(newScrumData[currentPageType][selectedLineIndex].id);
      }
    }

    const errorList = this.handleCheck(this.state.detailData);
    const scrumDataWithAggreation = this.convertAggreation(
      _.cloneDeep(newScrumData)
    );
    this.setState({ scrumData: scrumDataWithAggreation, errorList });
  }

  convertAggreation = scrumData => {
    const { allList } = this.state;
    Object.keys(scrumData).forEach(pt => {
      scrumData[pt].forEach((row, idx) => {
        const allidx = allList.findIndex(all => all.name === row.scrumItem);
        if (allidx !== -1) {
          scrumData[pt][idx - 1].values = [
            { text: formatCurrency(allList[allidx].calcVal.toString()) },
          ];
        }
      });
    });
    return scrumData;
  };

  //   getCatagory = async (projectId, cg) => {

  //     const options = []
  //     PageTypeList.forEach(meta => {
  //       options.push({ id: meta.item, text: meta.value, value: meta.value })
  //     })
  //     const { category } = this.state
  //     this.setState({ category: { ...category, [cg]: options } })
  //   }
  // }

  transformPoints(points, height) {
    if (points.length === 2) {// figure type is bbox
      points = this.orderPoint(points);
    }
    return points.map(({ lat, lng }) => ({
      lat: height - lat,
      lng,
    }));
  }

  orderPoint(points) {
    const [p1, p2] = points;
    var minlat = p1.lat > p2.lat ? p1.lat : p2.lat;
    var maxlat = p1.lat < p2.lat ? p1.lat : p2.lat;
    var minlng = p1.lng < p2.lng ? p1.lng : p2.lng;
    var maxlng = p1.lng > p2.lng ? p1.lng : p2.lng;

    return [
      { lat: minlat, lng: minlng },
      { lat: maxlat, lng: maxlng },
    ];
  }

  handleMappingItemChange = (id, value, category) => {
    const { detailData } = this.state;
    const newDetailData = _.cloneDeep(detailData);
    const idx = detailData[this.state.currentPageType].findIndex(
      r => r.id === id
    );
    newDetailData[this.state.currentPageType][idx][category] = value;
    this.props.onEdit(true);
    this.setState({ detailData: newDetailData });
  };

  handleUnLinkScrumItem = async (bboxId) => {
    const { detailData } = this.state;
    const newDetailData = _.cloneDeep(detailData);
    const { preference } = this.state;
    const pt = preference['prediction-post-processing_regular-filter-pattern'];
    for (let pageIdx in detailData) {
      for (let rowIdx in detailData[pageIdx]) {
        if (
          detailData[pageIdx][rowIdx].value &&
          bboxId === detailData[pageIdx][rowIdx].value.id
        ) {
          newDetailData[pageIdx][rowIdx].scrumItem = '';
          this.setState({ detailData: newDetailData });
          const scrumData = detailData[pageIdx][rowIdx];
          const itemText = scrumData.item.text ? filterCharacters(scrumData.item.text, pt) : '';
          const subtitleText = scrumData.subTitle.text ? filterCharacters(scrumData.subTitle.text, pt) : '';
          const isNoMappingItem = subtitleText === '' && itemText === '';
          if (!isNoMappingItem) {
            const fsItemKey = subtitleText + '||' + itemText;
            let storageFsItems = localStorage.getItem('fsItems') ? JSON.parse(localStorage.getItem('fsItems')) : {};
            if (_.isEmpty(storageFsItems) || !Object.keys(storageFsItems.list).includes(fsItemKey)) {
              const pageClassification = this.getConvertPageType(detailData[pageIdx][rowIdx]);
              const item = {
                key1: subtitleText,
                key2: itemText,
                projectId: this.props.projectId,
                category: pageClassification,
              };
              const fsItem = await this.getSfmInfo([item]);
              const newList = { ...storageFsItems.list, ...fsItem.list };
              storageFsItems.list = newList;
              localStorage.setItem("fsItems", JSON.stringify(storageFsItems));
              this.setState({ fsItems: newList });
            }
          }
          // delay for muipopup get latest detailData from textMappingRef
          setTimeout(() => {
            this.props.onEdit(true);
          }, 100)
          return;
        }
      }
    }
  };

  handelGetThumbnailCurrentPageType = () => { 
    let defaultPageType = PAGE_CLASSIFICATION.BS
    if (this.props.thumbnailRef && this.props.thumbnailRef.current) { 
      const tnCurrentPageCls = this.props.thumbnailRef.current.state.pageClassification.pageType
      defaultPageType = tnCurrentPageCls === PAGE_CLASSIFICATION.MC || tnCurrentPageCls === PAGE_CLASSIFICATION.MF || tnCurrentPageCls === '連結損益計算書' ?
        PAGE_CLASSIFICATION.PL : tnCurrentPageCls === '連結貸借対照表' ? PAGE_CLASSIFICATION.BS : tnCurrentPageCls
    }
    return defaultPageType
  }

  handlePageTypeChange = (value, fromThumbnail = false) => {
    //const { isEditing } = this.props;
    if (fromThumbnail) {
      const idx = PageTypeList.indexOf(value);
      if (idx !== -1) {
        this.setState({ currentPageType: value, searchText: '' });
      }
    } else {
      this.setState({ currentPageType: value, searchText: '' });
      const thumnailRef = this.props.thumbnailRef.current;
      this.findTargetPageByPageType(
        thumnailRef.state.imageList,
        value,
        this.props.currentPage
      );
    }
    this.boardRef &&
      this.boardRef.current &&
      this.boardRef.current.scrollToTop();
  };

  findTargetPageByPageType = (imageList, pageType, currentPageId, scrumItem='') => {
    if (!currentPageId) {
      setTimeout(function() {
        this.findTargetPageByPageType(
          imageList,
          pageType,
          this.props.currentPage
        );
      }, 100)
    } else {
      if (
        findIndex(imageList, {
          pageId: currentPageId.toString(),
          pageClassification: pageType,
        }) > -1
      ) {
        return;
      }

      let img = this.findTargetImageByPageType(pageType, imageList, scrumItem)
      if(img){
        const thumnailRef = this.props.thumbnailRef.current;
        thumnailRef.state.pageClassification.pageType = img.pageClassification
        thumnailRef.state.pageClassification.pageId = img.pageId
      }
      this.props.onPageChange(
        Number(
          (
            img || {
              pageId: currentPageId.toString(),
            }
          ).pageId
        )
      );
    }
  }

  findTargetImageByPageType = (pageType, imageList, searchScrumItem='') =>{
    let img = null
    if(searchScrumItem){
      const { detailData } = this.state
      if(detailData[pageType]){
        const itemObj = detailData[pageType].find(item=> item.scrumItem && searchScrumItem === item.scrumItem)
        if(itemObj){
          img = imageList.find(image => itemObj.pdfImageId.toString() === image.pageId)
        }
      }
    }
    if(img) return img
    img = imageList.find(image => image.pageClassification === pageType && image.imageStatus && image.imageStatus.inUse === true);
    if(!img){
      if(pageType===PAGE_CLASSIFICATION.BS){
        img = imageList.find(image => image.pageClassification === "連結貸借対照表" && image.imageStatus && image.imageStatus.inUse === true);
        if(!img){
          img = imageList.find(image => image.pageClassification === pageType || image.pageClassification === "連結貸借対照表");
        }
      }else if(pageType===PAGE_CLASSIFICATION.PL){
        img = imageList.find(image => (image.pageClassification === PAGE_CLASSIFICATION.MC 
            || image.pageClassification === PAGE_CLASSIFICATION.MF 
            || image.pageClassification === "連結損益計算書") && image.imageStatus && image.imageStatus.inUse === true);
        if(!img){
          img = imageList.find(image => image.pageClassification === pageType 
            || (image.pageClassification === PAGE_CLASSIFICATION.MC 
            || image.pageClassification === PAGE_CLASSIFICATION.MF 
            || image.pageClassification === "連結損益計算書"));
        }
      }else if(pageType==="株主資本等変動計算書"){
        img = imageList.find(image => image.pageClassification === "連結株主資本等変動計算書" && image.imageStatus && image.imageStatus.inUse === true);
        if(!img){
          img = imageList.find(image => image.pageClassification === pageType || image.pageClassification === "連結株主資本等変動計算書");
        }
      }
    }
    return img;
  }

  handleScrumItemChange = (bboxId, scrumItem, allowScroll) => {
    const { detailData, scrumMappingList, scrumData, mode } = this.state;
    const cpDetailData = _.cloneDeep(detailData);

    // ・,（, ）=> ･, (, ),
    let praseScrumItem = scrumItem.replace(/・/g, '･');
    // praseScrumItem = praseScrumItem.replace(/）/g, ')')
    // praseScrumItem = praseScrumItem.replace(/（/g, '(')

    // get real table key according to scrumItem

    let realTabelKey = '';
    Object.keys(scrumMappingList).forEach(tk => {
      if (scrumMappingList[tk].indexOf(praseScrumItem) !== -1) {
        realTabelKey = tk;
      }
    });
    if(mode !== TableMode.TOTALDETAIL){
      const scrumIndex = _.findIndex(scrumData[realTabelKey], item => {
        return item.scrumItem && item.scrumItem === scrumItem;
      });
      const realTabelIndex = _.findIndex(cpDetailData[realTabelKey], item => {
        return item.value && item.value.id === bboxId;
      });
      let detailIds = []; 
      if(realTabelIndex !== -1){
        if (cpDetailData[realTabelKey][realTabelIndex].item) detailIds.push(cpDetailData[realTabelKey][realTabelIndex].item.id)
        if (cpDetailData[realTabelKey][realTabelIndex].item) detailIds.push(cpDetailData[realTabelKey][realTabelIndex].value.id)
      }
      if(detailIds.findIndex(etailId => etailId === bboxId) === -1){
        detailIds.push(bboxId);
      }
      this.props.onRowSelect(
        scrumData[realTabelKey][scrumIndex].id,
        cpDetailData[realTabelKey][realTabelIndex].pdfImageId,
        detailIds,'value'
      );
    }

    for (let tableKey of Object.keys(cpDetailData)) {
      const itemIndex = _.findIndex(cpDetailData[tableKey], item => {
        return item.value && item.value.id === bboxId;
      });
      if (itemIndex >= 0 && realTabelKey !== '') {
        const item = cpDetailData[tableKey][itemIndex];
        item.scrumItem = praseScrumItem;
        item.value.text = item.value.text.replace(/\./g, ',');

        if (realTabelKey !== tableKey) {
          cpDetailData[tableKey].splice(itemIndex, 1);
          cpDetailData[realTabelKey].push(item);
        }

        this.handlePageTypeChange(realTabelKey, true);
        this.setState(
          {
            detailData: cpDetailData
          },
          () => this.props.onEdit(true)
        );
        if (allowScroll) {
          let scrollElement = document.getElementById(praseScrumItem);
          if (scrollElement) {
            scrollToElementByPosition(scrollElement, 'start');
          } else {
            let scrollBoardElement = document.getElementById(`${praseScrumItem}-list-board`);
            if (scrollBoardElement) {
              scrollBoardElement.scrollIntoView({behavior: 'auto', block: 'start', inline: 'center'});
            }else {
              let scrollBoardTotalElement = document.getElementById(`${praseScrumItem}-list-board-total`);
              if (scrollBoardTotalElement) {
                scrollBoardTotalElement.scrollIntoView({behavior: 'auto', block: 'start', inline: 'center'});
              }
            }
          }
        }
        break;
      }
    }
  };

  handleCancel = () => {
    const { tmpScrumData, tmpDetailData, tmpManualCalc } = this.state;
    this.setState(
      {
        scrumData: _.cloneDeep(tmpScrumData),
        detailData: _.cloneDeep(tmpDetailData),
        manualCalc: _.cloneDeep(tmpManualCalc),
        allCheck: false,
        checkedList: [],
        isCancel: true,
      },
      () => {
        this.props.onRowSelect('', this.props.currentPage, []);
        this.props.onEdit(false);
      }
    );
  };
  handelCheck = (id, checked) => {
    const { detailData, checkedList } = this.state;
    let allDatalength = 0;
    Object.keys(detailData).forEach(scrumSubTitle => {
      if (scrumSubTitle === this.state.currentPageType) {
        allDatalength = detailData[scrumSubTitle].length;
        detailData[scrumSubTitle].forEach(d => {
          if (d.id === id) {
            d.checked = checked;
          }
        });
      }
    });

    const cIdx = checkedList.indexOf(id);
    if (cIdx !== -1) {
      checkedList.splice(cIdx, 1);
    } else {
      checkedList.push(id);
    }

    const newAllCheck = checkedList.length === allDatalength ? true : false;

    this.setState({ detailData, checkedList, allCheck: newAllCheck });
  };

  handleAllCheck = checked => {
    const checkedList = [];
    Object.keys(this.state.detailData).forEach(scrumSubTitle => {
      if (scrumSubTitle === this.state.currentPageType) {
        this.state.detailData[scrumSubTitle].forEach(r => {
          r.checked = checked;
          checkedList.push(r.id);
        });
      }
    });
    this.setState({
      allCheck: checked,
      checkedList: checked ? checkedList : [],
    });
    // this.setState(this.state)
  };

  handleAdd = () => {
    const row = {
      id: genId(),
      pdfImageId: Number(this.props.currentPage),
      subTitle: { id: genNegativeId(), text: '' },
      item: { id: genNegativeId(), text: '' },
      value: { id: genNegativeId(), text: '' },
      scrumItem: '',
    };

    const { detailData, currentPageType } = this.state;
    detailData[currentPageType]
      ? detailData[currentPageType].push(row)
      : (detailData[currentPageType] = [row]);
    this.setState({ detailData });
  };

  handleRemove = () => {
    const { checkedList, detailData, currentPageType } = this.state;
    const newData = _.cloneDeep(detailData);
    Object.keys(detailData).forEach(scrumSubTitle => {
      if (scrumSubTitle === currentPageType) {
        const tmp = [];
        detailData[scrumSubTitle].forEach(d => {
          if (checkedList.indexOf(d.id) === -1) {
            tmp.push(d);
          }
        });
        newData[scrumSubTitle] = tmp;
      }
    });

    this.setState({ detailData: newData, checkedList: [], allCheck: false });
  };

  handleAddRow = (id) => {
    const { detailData, currentPageType } = this.state;
    const newDetailData = _.cloneDeep(detailData);
    const currentIndex = _.findIndex(newDetailData[currentPageType], { id: id });
    const newRow = {
      id: genId(),
      item: { id: genNegativeId(), text: '' },
      originalItem: '',
      pdfImageId: Number(this.props.currentPage),
      scrumItem: '',
      subTitle: { id: genNegativeId(), text: '' },
      value: { id: genNegativeId(), text: '' },
      isNewRow: true,
    }
    newDetailData[currentPageType].splice(currentIndex + 1, 0, newRow);
    this.setState({ detailData: newDetailData });
  }

  handleDeleteRow = (index) => {
    const { detailData, currentPageType } = this.state;
    const newDetailData = _.cloneDeep(detailData);
    newDetailData[currentPageType].splice(index, 1);
    this.setState({ detailData: newDetailData });
  }

  handleDropRow = (before, after) => {
    const { detailData, currentPageType } = this.state;
    const newDetailData = _.cloneDeep(detailData);
    const newRow = newDetailData[currentPageType];
    const [movedElement] = _.pullAt(newRow, before);
    newRow.splice(after, 0, movedElement);
    this.setState({ detailData: newDetailData });
  }

  handleTextChange = (bboxId, text, type) => {
    const { detailData } = this.state;
    const newDetailData = _.cloneDeep(detailData);

    if (type === TextChangeType.LINK) {
      const { selectedCell, onRowSelect } = this.props;
      const { id, cellIds, column } = selectedCell;

      let newSelectedBox = { id: '', pdfImageId: '', cellIds: [] };
      Object.keys(newDetailData).forEach(scrumSubTitle => {
        if (scrumSubTitle === this.state.currentPageType) {
          newDetailData[scrumSubTitle].forEach(d => {
            if (d.id === id) {
              Object.keys(d).forEach(key => {
                if (
                  d[key].id &&
                  column === key &&
                  cellIds.indexOf(d[key].id) !== -1
                ) {
                  d[key].id = bboxId;
                  d[key].text = text;
                  newSelectedBox = { ...selectedCell, cellIds: [bboxId] };
                }
              });
            }
          });
        }
      });

      onRowSelect(
        newSelectedBox.id,
        newSelectedBox.pdfImageId,
        newSelectedBox.cellIds
      );
      this.setState({ detailData: newDetailData, linking: false });
    } else if (type === TextChangeType.CHANGE) {
      // for popup content edit
      Object.keys(newDetailData).forEach(scrumSubTitle => {
        if (scrumSubTitle !== 'manual_input') {
          newDetailData[scrumSubTitle].forEach(d => {
            Object.keys(d).forEach(key => {
              if (d[key] && d[key].id === bboxId) {
                d[key].text = text;
              }
            });
          });
        }
      });

      this.setState({ detailData: newDetailData });
    } else if (type === TextChangeType.DELETE) {
      Object.keys(newDetailData).forEach(scrumSubTitle => {
        if (scrumSubTitle !== 'manual_input') {
          newDetailData[scrumSubTitle].forEach(d => {
            Object.keys(d).forEach(key => {
              if (d[key] && d[key].id === bboxId) {
                d[key].text = '';
                d[key].id = genNegativeId();
              }
            });
          });
        }
      });
      this.setState({ detailData: newDetailData });
    }
  };

  // convert pdfImagedId to pageNum (1,2,3,4 ...)
  handleNormalized = pdfImageId => {
    if (pdfImageId) {
      return pdfImageId - this.props.minPdfImageId + 1;
    } else {
      return '';
    }
  };

  handleChangeValue = values => {

    let { detailData } = this.state;
    values.forEach(value => {
      Object.keys(detailData).forEach(scrumSubTitle => {
        if (scrumSubTitle !== 'manual_input') {
          detailData[scrumSubTitle].forEach(d => {
            if (d['value'].id === value.id) {
              d['value'] = {
                id: d['value'].id,
                text: value.text,
                invert: value.invert,
              };
            }
          });
        }
      });
    });
    const scrumData = this.convertData(detailData, this.state.scrumMappingList,this.state.referMap);
    const errorList = this.handleCheck(detailData);
    const scrumDataWithAggreation = this.convertAggreation(
      _.cloneDeep(scrumData)
    );
    this.setState({
      detailData: detailData,
      scrumData: scrumDataWithAggreation,
      errorList,
    });
  };

  handleSave = async () => {
    this.setState({ allCheck: false, checkedList: [] });
    const { projectId, pdfFileId, pdfInfo, onEdit, onShowUpdateErrorDialog, onSetHeaderInfo } = this.props;
    const body = {};
    const tmp = _.cloneDeep(this.state.detailData);
    let isCansave = true;
    let errorTextList = [];
    Object.keys(tmp).forEach(scrumSubTitle => {
      if (scrumSubTitle !== 'manual_input') {
        tmp[scrumSubTitle].forEach(d => {
          delete d.id;
        });
        if (scrumSubTitle === PageTypeList[2]) {
          BScurrentEndBanaceList.forEach(bs => {
            for (let i = 0; i < tmp[scrumSubTitle].length; i++) {
              if (tmp[scrumSubTitle][i].scrumItem === bs) {
                tmp[scrumSubTitle].splice(i, 1);
                if (tmp[scrumSubTitle].length === 0) {
                  break;
                } else {
                  i--;
                }
              }
            }
          });
        }
      } else {
        const totalManualItems = tmp[scrumSubTitle];
        let nameMap = new Map();
        Object.keys(totalManualItems).forEach(pageType => {
          const onePageManualItems = totalManualItems[pageType];
          Object.keys(onePageManualItems).forEach((itemName) => {
            if (onePageManualItems[itemName] === intl.get('_predict.detail.mapping.Formula input error') ||
              onePageManualItems[itemName] === intl.get('_predict.detail.mapping.More than 14 digits')) {
              if (!nameMap.has(pageType)) {
                nameMap.set(pageType, itemName);
                errorTextList.push(<p className="custom-ui-confirm-text-subTitle" key={pageType} > {pageType} </p>);
              }
              isCansave = false;
              errorTextList.push(<p className="custom-ui-confirm-text-content" key={itemName} > ・{itemName} </p>);
            }
          })
        })
      }
    });
    const { manualCalc } = this.state;
    if (isCansave) {
      body['scrumMaps'] = tmp;
      body['manualCalc'] = manualCalc
      body['prevLastEdited'] = pdfInfo.lastEdited;
      try {
        const data = await (await api.post(
          `/dppapi/predict/pdf/scrum/update/${projectId}/${pdfFileId}`,
          JSON.stringify(body),
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            }
          }
        )).data;

        // Track all save operations for the pdf
        try {
          track2DBStorage(projectId, pdfFileId, TYPES.SAVE_SCRUM_MAP);
        } catch (e) {
          console.error(e);
        }

        if (data.success) {
          onEdit(false);
          this.handleReload(true);
          this.setState({ isCancel: false });
          onSetHeaderInfo(data.lastEditInfo);
        }
      } catch (error) {
        onShowUpdateErrorDialog(error);
      }
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui-confirm-alert-body-RPA'>
              <h1>
                <Icon name="exclamation triangle" className="total-right-icon-error" />
                {intl.get('_predict.detail.mapping.Have Input Error')}
              </h1>
              <div className='custom-ui-confirm-alert-body-inner'>
                {errorTextList}
              </div>
              <div className='custom-ui-confirm-alert-button-group'>
                <button
                  className='predict-admin-botton-text confirmAlert-button-left'
                  onClick={onClose}>
                  {intl.get('_predict.detail.mapping.Close')}
                </button>
              </div>
            </div>
          );
        }
      });
    }
  };

  handleDeleteImagePredict = async (images, changePageType) =>{

    const { projectId, pdfFileId, onEdit, onShowUpdateErrorDialog, onSetHeaderInfo, thumbnailRef } = this.props;
    const body = {};
    const { detailData, manualCalc } = this.state;
    const tmp = _.cloneDeep(detailData);
    const tmpManualCalc = _.cloneDeep(manualCalc);
    let updateFlag = false;

    //this.setState({ isDownloading: true });
    for (let idx = 0; idx < images.length; idx++) {
    // images.forEach((image)=>{
      const image = images[idx];
      var existFlag = false;
      for (let i = 0; i < Object.keys(tmp).length; i++) {
        const scrumSubTitle = Object.keys(tmp)[i]
        if (scrumSubTitle !== 'manual_input') {
          const existPredict = tmp[scrumSubTitle].find(d=>d.pdfImageId&&d.pdfImageId.toString() === image.pageId.toString() && d.scrumItem)
          if(existPredict){
            existFlag = true;
          }
        }
      }

      const data = await thumbnailRef.current.handleChange(image.pageId, changePageType);
      if (!data.success) {
        return;
      }
      this.handlePageTypeChange(changePageType, true)
      if(existFlag){
        updateFlag = true;
        Object.keys(tmp).forEach(scrumSubTitle => {
          const subTitleList = []
          if (scrumSubTitle !== 'manual_input') {
            tmp[scrumSubTitle].forEach(d => {
              delete d.id;
              if(!d.pdfImageId || d.pdfImageId.toString() !== image.pageId.toString()){
                subTitleList.push(d)
              }
            });
            tmp[scrumSubTitle] = subTitleList
            if (scrumSubTitle === PageTypeList[2]) {
              BScurrentEndBanaceList.forEach(bs => {
                for (let i = 0; i < tmp[scrumSubTitle].length; i++) {
                  if (tmp[scrumSubTitle][i].scrumItem === bs) {
                    tmp[scrumSubTitle].splice(i, 1);
                    if (tmp[scrumSubTitle].length === 0) {
                      break;
                    } else {
                      i--;
                    }
                  }
                }
              });
            }
          }else{
            const totalManualItems = tmp[scrumSubTitle];
            Object.keys(totalManualItems).forEach(pageType => {
              const onePageManualItems = totalManualItems[pageType];
              Object.keys(onePageManualItems).forEach((itemName) => {
                if (onePageManualItems[itemName] === intl.get('_predict.detail.mapping.Formula input error') ||
                  onePageManualItems[itemName] === intl.get('_predict.detail.mapping.More than 14 digits')) {
                  // delete error data
                  delete onePageManualItems[itemName]
                  // delete clac
                  if(tmpManualCalc[pageType] && tmpManualCalc[pageType][itemName]){
                    delete tmpManualCalc[pageType][itemName]
                  }
                }
              })
            })
          }
        });
      }
    // });
    }

    if(updateFlag||this.props.isEditing){
      body['scrumMaps'] = tmp;
      body['manualCalc'] = tmpManualCalc;
      body['prevLastEdited'] = this.props.pdfInfo.lastEdited;
      let data = {};
      try {
        data = await (await api.post(
          `/dppapi/predict/pdf/scrum/update/${projectId}/${pdfFileId}`,
          JSON.stringify(body),
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            }
          }
        )).data;
      } catch (error) {
        onShowUpdateErrorDialog(error);
      }
  
      // Track all save operations for the pdf
      try {
        await track2DBStorage(projectId, pdfFileId, TYPES.SAVE_SCRUM_MAP);
      } catch (e) {
        console.error(e);
      }
  
      if (data.success) {
        onEdit(false);
        await this.handleReload(true);
        this.setState({ isCancel: false });
        const thumnailRef = this.props.thumbnailRef.current;
        thumnailRef.clickImg({pageId:thumnailRef.props.currentPage.toString()});
        onSetHeaderInfo(data.lastEditInfo);
      }
    }
  }

  handleSearch = text => {
    if (
      this.state.currentPageType === PageTypeList[2] &&
      this.state.mode === TableMode.SCRUM
    ) {
      return;
    }
    this.setState({ searchText: text });
  };

  handleSelectSearchItem = (scrumItem,imgId='') => {
    const { scrumData, currentPageType, mode, detailData, mappingType } = this.state;
    if (mode === TableMode.DETAIL) return;

    const rowScrum =
      scrumData[currentPageType] === undefined
        ? []
        : scrumData[currentPageType];
    const rowData =
      !detailData || (detailData && detailData[currentPageType] === undefined)
        ? []
        : detailData[currentPageType];
    let filteredRows = [...rowScrum, ...rowData];
    const idRow = rowScrum.find(d=>d.scrumItem === scrumItem)
    let manualInput = undefined
    let ids = []
    filteredRows.forEach(r => {
      if (r.scrumItem === scrumItem) {
        if(mappingType === MappingType.LIST_AND_TABLE){
          this.setState({ scrollToElement: r});
        } else{
          this.setState({ searchItem: r});
        }
        if (r.item){
          ids.push(r.item.id)
        }
        if (r.value){
          ids.push(r.value.id)
        }
        if (r.manualInput){
          manualInput = r.manualInput
        }
      }
    });
    if(mappingType === MappingType.LIST_AND_TABLE){
      this.props.onRowSelect(idRow?idRow.id:'', imgId?imgId:this.props.currentPage, ids, 'value', manualInput);
    }
  }
  handleSearchFromBBoxClick = () => {
    const { scrumData, currentPageType, mode, detailData } = this.state;
    if (mode === TableMode.DETAIL) return;

    const { currentBoxId } = this.props;

    const rowScrum =
      scrumData[currentPageType] === undefined
        ? []
        : scrumData[currentPageType];
    const rowData =
      !detailData || (detailData && detailData[currentPageType] === undefined)
        ? []
        : detailData[currentPageType];
    let filteredRows = [...rowScrum, ...rowData];
    if (mode !== TableMode.TOTALDETAIL) {
      filteredRows.forEach(r => {
        if (r.values && r.values.length > 0) {
          const idx = r.values.findIndex(v => v.id === currentBoxId);
          if (idx !== -1) {
            this.setState({ scrollToElement: r });
          }
        } else {
          if (
            (r.value && r.value.id === currentBoxId) ||
            (r.subTitle && r.subTitle.id === currentBoxId) ||
            (r.item && r.item.id === currentBoxId)
          ) {
            this.setState({ scrollToElement: r });
          }
        }
      });
    } else {
      const currentIndex = _.findIndex(rowData, (data) => {
        return data.value.id === currentBoxId
      });
      this.setState({ scrollToElement: currentIndex });
    }
  };

  handleDownload = async () => {
    this.setState({ isDownloading: true });
    const { currentPageType, scrumData, detailData, mode } = this.state;
    const sufix = moment().format(FMT.TIME_STRING);
    let option = {};
    let body = [];

    if (mode === TableMode.DETAIL) {
      option = {
        header: ['page num', 'subtitle', 'item', 'value', 'scrum item'],
      };
      let filteredRows =
        !detailData || (detailData && detailData[currentPageType] === undefined)
          ? []
          : detailData[currentPageType];

      filteredRows.forEach(r => {
        const row = [];
        row.push(
          this.handleNormalized(r.pdfImageId),
          r.subTitle.text,
          r.item.text,
          r.value.text,
          r.scrumItem
        );
        body.push(row);
      });
    } else {
      option = {
        header: ['scrum item', 'total value'],
      };
      let filteredRows =
        !scrumData || (scrumData && scrumData[currentPageType] === undefined)
          ? []
          : scrumData[currentPageType];
      filteredRows.forEach(r => {
        const row = [];
        const totalValue =
          r.manualInput === undefined
            ? this.handleGetSum(r.values)
            : r.manualInput;
        row.push(
          r.scrumItem.replace(
            /当期末残高\(B\/S自動集計\)/gi,
            '当期末残高(自動集計)'
          ),
          totalValue
        );
        body.push(row);
      });
    }
    await downloadCsv(body, option, `KV_${currentPageType}_${sufix}.csv`);
    this.setState({ isDownloading: false });
  };

  handleCellClear = () => {
    const { detailData } = this.state;
    const newDetailData = _.cloneDeep(detailData);

    const { id, cellIds, column } = this.props.selectedCell;
    if (cellIds.length !== 1) {
      return;
    }
    // clear selected cell
    this.props.onRowSelect('', '', [], '');

    // clear tabel cell
    const bboxId = cellIds[0];
    Object.keys(newDetailData).forEach(scrumSubTitle => {
      if (scrumSubTitle !== 'manual_input') {
        newDetailData[scrumSubTitle].forEach(d => {
          if (d.id === id) {
            // row id
            Object.keys(d).forEach(key => {
              if (key === column && d[key] && d[key].id === bboxId) {
                // item id
                d[key].text = '';
                d[key].id = genNegativeId();
              }
            });
          }
        });
      }
    });
    this.setState({ detailData: newDetailData });
  };
  handleGetSum = values => {
    if (values.length === 0) return '';
    let sum = 0;
    values.forEach(v => {
      // eslint-disable-next-line
      const m = v.text.match(/\u25B3|\-|\d+/gi); // just match "△", "-, "number", ".""
      let vString = '';
      if (m) {
        vString = m.join('');
        vString = vString.replace(/\u25B3/gi, '-'); // replace "△" with "-"
      }
      if (v.invert) {
        if (!vString || vString[0] === '0') {
          // vString = vString;
        } else if (vString[0] !== '-') {
          vString = '-' + vString;
        } else {
          vString = vString.substring(1, vString.length);
        }
      }
      if (!Number.isNaN(Number(vString))) {
        sum += Number(vString);
      }
    });
    // const splitSum = sum.toString().split(',');
    // let integer = splitSum[0];
    // const decimal = splitSum[1] ? `.${splitSum[1]}` : '';
    // integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // format 12345678.88888 to 12,345,678.88888
    //
    // return `${integer}${decimal}`;
    return new Intl.NumberFormat('ja-JP', {
      style: 'decimal',
      currency: 'CAD',
    }).format(sum);
  };
  handleLinkBbox2Cell4KVTable = scrumItem => {
    const { detailData, currentPageType } = this.state;

    const newDetailData = _.cloneDeep(detailData);

    const {
      selectedBBox: { id, text },
      onSelectedBBoxChange,
      onEdit,
    } = this.props;
    if (id !== '' && text !== '') {
      onSelectedBBoxChange('', '');

      // find in detail data
      let existIn = '',
        existIndex = -1;
      const parseText = text.replace(/\./g, ','); // . => ,
      Object.keys(newDetailData).forEach(scrumSubTitle => {
        if (scrumSubTitle !== 'manual_input') {
          const idx = newDetailData[scrumSubTitle].findIndex(
            d => d.value && d.value.id === id
          );
          if (idx !== -1) {
            existIn = scrumSubTitle;
            existIndex = idx;
          }
        }
      });

      // target exist and in current page
      if (existIndex !== -1 && existIn === currentPageType) {
        newDetailData[existIn][existIndex]['value'].text = parseText;
        newDetailData[existIn][existIndex].scrumItem = scrumItem;
      } else {
        // target not exist or in other page

        // if i
        let row = {
          id: genId(),
          pdfImageId: Number(this.props.currentPage),
          subTitle: { id: genNegativeId(), text: '' },
          item: { id: genNegativeId(), text: '' },
          value: { id, text: parseText },
          scrumItem: scrumItem,
        };
        //if it exists on other page, we should move it to the current page and change it's scrumitem
        if (existIndex !== -1 && existIn !== currentPageType) {
          const res = newDetailData[existIn].splice(existIndex, 1);
          row = res[0];
          row['scrumItem'] = scrumItem;
          row['value']['text'] = parseText;
        }

        if (newDetailData[currentPageType]) {
          newDetailData[currentPageType].push(row);
        } else {
          newDetailData[currentPageType] = [row];
        }
      }

      this.setState({ detailData: newDetailData }, () => {
        onEdit(true);
      });
    }
  };
  handleLinkBbox2Cell4TextTable = (rowId, cellId, column) => {
    const { detailData } = this.state;
    const newDetailData = _.cloneDeep(detailData);

    const {
      selectedBBox: { id, text },
      onSelectedBBoxChange,
      onEdit,
    } = this.props;
    if (id !== '' && text !== '') {
      onSelectedBBoxChange('', '');

      Object.keys(newDetailData).forEach(scrumSubTitle => {
        if (scrumSubTitle !== 'manual_input') {
          newDetailData[scrumSubTitle].forEach(d => {
            if (d.id === rowId) {
              // row id

              Object.keys(d).forEach(key => {
                if (key === column && d[key] && d[key].id === cellId) {
                  // item id
                  d[key].text = text;
                  d[key].id = id;
                }
              });
            }
          });
        }
      });
      this.setState({ detailData: newDetailData }, () => {
        onEdit(true);
      });
    }
  };

  handleTabChange = (idx, searchText = '') => {
    // const { mode } = this.state
    // const { isEditing } = this.props
    // if (mode !== idx && isEditing) {
    //   this.tabChangeDialog(idx, searchText)
    // } else {
    //   this.setState({ mode: idx, searchText })
    // }
    this.setState({ mode: idx, searchText });
  };

  handleMappingTypeChange = (type, cache) => {
    const { mode } = this.state;
    this.setState({ mode: mode === TableMode.TOTALDETAIL ? TableMode.TOTALDETAIL : 0, searchText: '' });
    this.setState({ mappingType: type, cacheMappingType: cache });
  };

  handleSSTypeChange = (type) => {
    const { mode } = this.state;
    this.setState({ mode: mode === TableMode.TOTALDETAIL ? TableMode.TOTALDETAIL : 0, searchText: '' });
    this.setState({ ssCommon: type });
    this.handleTabChange(0);
    const { pdfFileId, projectId } = this.props;
    this.context.sendPredictBeautifyDetailMetrics({
      event_type: EventType.Action,
      event_name: EventName.ChangeViewMode,
      custom_parameter: {
        view_mode: type ? ViewMode.Table : ViewMode.List,
        document_type: this.state.currentPageType,
        pdf_id: pdfFileId,
        project_id: projectId,
      },
    })
  };

  handleModeChange = (type) => {
    this.setState({
      mode: type,
      fsKeyValueTableScrollTop: 0,
      fsDetailTableScrollTop: 0,
      searchText: ''
    });
    this.props.onListViewChange(4);
    this.props.onRowSelect('', this.props.currentPage, []);
    if (type !== this.state.mode) {
      this.handleReloadPdfLoader();
    }

    const { pdfFileId, projectId } = this.props;
    this.context.sendPredictBeautifyDetailMetrics({
      event_type: EventType.Action,
      event_name: EventName.ChangeViewMode,
      custom_parameter: {
        view_mode: ViewMode.Detail,
        document_type: this.state.currentPageType,
        pdf_id: pdfFileId,
        project_id: projectId,
      },
    })
  };

  handleReloadPdfLoader = () => {
    // hack, for refresh pdfLoaderRef
    if (this.props.pdfLoaderRef && this.props.pdfLoaderRef.current) {
      this.props.pdfLoaderRef.current.refetch();
    }
  }

  handleRefreshCanvas = () => {
    // hack, for refresh canvas bbox after mapping detail page has loaded
    if (this.props.canvasRef && this.props.canvasRef.current) {
      this.props.canvasRef.current.handleClick();
    }

  }

  handleReSetMuiPopup = () => {
    // reset popup content
    if (this.props.muiPopupRef && this.props.muiPopupRef.current) {
      this.props.muiPopupRef.current.resetContent();
    }
  }

  tabChangeDialog = (idx, searchText) => {
    confirmAlert({
      title: 'warning',
      message: 'you must save current page data first, click ok to save',
      buttons: [
        {
          label: 'OK',
          onClick: () => {
            // TODO save api
            this.handleSave();
            this.setState({ mode: idx, searchText });
          },
        },
        {
          label: 'Cancel',
        },
      ],
      zIndex: 500,
    });
  };

  handleGetFilteredRows = () => {
    const {
      mode,
      mappingType,
      scrumData,
      detailData,
      currentPageType,
      searchText,
    } = this.state;
    if (mappingType === MappingType.BOARD && mode !== TableMode.TOTALDETAIL) {
      let filteredRows =
        !scrumData || (scrumData && scrumData[currentPageType] === undefined)
          ? []
          : scrumData[currentPageType];

      if (searchText.length > 0) {
        const halfReg = new RegExp(_.escapeRegExp(halfToFull(searchText)), 'i');
        const fullReg = new RegExp(_.escapeRegExp(fullToHalf(searchText)), 'i');

        const isMatch = result => {
          let match = [];
          match.push(halfReg.test(result.scrumItem));
          match.push(fullReg.test(result.scrumItem));
          const sum = this.handleGetSum(result.values);
          match.push(halfReg.test(sum));
          match.push(fullReg.test(sum));

          let res = false;
          for (let i = 0; i < match.length; i++) {
            if (match[i]) {
              res = true;
              break;
            }
          }
          return res;
        };
        filteredRows = _.filter(filteredRows, isMatch);
      }
      return filteredRows;
    } else {
      if (mode === TableMode.SCRUM) {
        // eslint-disable-next-line
        let filteredRows =
          !scrumData || (scrumData && scrumData[currentPageType] === undefined)
            ? []
            : scrumData[currentPageType];

        if (searchText.length > 0) {
          const halfReg = new RegExp(
            _.escapeRegExp(halfToFull(searchText)),
            'i'
          );
          const fullReg = new RegExp(
            _.escapeRegExp(fullToHalf(searchText)),
            'i'
          );

          const isMatch = result => {
            let match = [];
            match.push(halfReg.test(result.scrumItem));
            match.push(fullReg.test(result.scrumItem));
            const sum = this.handleGetSum(result.values);
            match.push(halfReg.test(sum));
            match.push(fullReg.test(sum));

            let res = false;
            for (let i = 0; i < match.length; i++) {
              if (match[i]) {
                res = true;
                break;
              }
            }
            return res;
          };
          filteredRows = _.filter(filteredRows, isMatch);
        }
        return filteredRows;
      } else if (mode === TableMode.DETAIL || mode === TableMode.TOTALDETAIL) {
        // eslint-disable-next-line
        let filteredRows = !detailData ||
          (detailData && detailData[currentPageType] === undefined)
          ? []
          : detailData[currentPageType];
        if (searchText.length > 0 && mode === TableMode.DETAIL) { // DETAIL Model had deleted
          const re = new RegExp(_.escapeRegExp(searchText), 'i');

          const isMatch = result => {
            let match = [];
            match.push(re.test(result.subTitle.text));
            match.push(re.test(result.item.text));
            match.push(re.test(result.value.text));
            match.push(re.test(result.scrumItem));

            let res = false;
            if (searchText === result.scrumItem) {
              res = true;
            }
            return res;
          };
          filteredRows = _.filter(filteredRows, isMatch);
        }
        return filteredRows;
      }
    }
  };

  handleInputModeChange = (scrumItem, mode) => {
    const { detailData, currentPageType, manualCalc } = this.state;
    const newDetailData = _.cloneDeep(detailData);
    const newInputCalc = _.cloneDeep(manualCalc);
    if (mode === ManualInputStatus.INPUT) {
      if (newDetailData.manual_input) {
        if (newDetailData.manual_input[currentPageType]) {
          // newDetailData.manual_input[currentPageType][scrumItem] = '';
        } else {
          newDetailData.manual_input[currentPageType] = { [scrumItem]: undefined };
        }
      } else {
        newDetailData.manual_input = {
          [currentPageType]: {},
        };
      }
    } else {
      delete newDetailData.manual_input[currentPageType][scrumItem];
      if(newInputCalc[currentPageType]){
        delete newInputCalc[currentPageType][scrumItem];
      }
    }
    this.setState({ detailData: newDetailData, manualCalc: newInputCalc });
  };

  handleManualInputTextChange = (scrumItem, text, calc) => {
    const { detailData, currentPageType, manualCalc } = this.state;
    const { onEdit } = this.props;
    const newDetailData = _.cloneDeep(detailData);
    newDetailData.manual_input[currentPageType][scrumItem] = text;

    let newInputCalc = _.cloneDeep(manualCalc);
    if(newInputCalc[currentPageType]){
      newInputCalc[currentPageType] = {
        ...newInputCalc[currentPageType],
        [scrumItem]: calc
      }
    }else{
      newInputCalc = {
        ...newInputCalc,
        [currentPageType]:{
          [scrumItem]: calc
        }
      }
    }

    onEdit(true);
    this.setState({ detailData: newDetailData, manualCalc: newInputCalc });
  };

  handleUpdateBBox = async (text, bboxId) => {
    this.setState({ detailItemChanging: true });
    const { figuresArray, detailData } = this.state;
    const { projectId, pdfFileId, currentPage, pdfInfo, onShowUpdateErrorDialog, onSetHeaderInfo } = this.props;
    let data = {};
    let body = {};

    try {
      if (!bboxId.toString().startsWith("-")) {
        const targetValue = _.find(figuresArray, (item) => _.find(item.bbox, { id: bboxId }));
        body.content = text;
        body.accuracy = targetValue ? _.find(targetValue.bbox, { id: bboxId }).accuracy : '';
        body.prevLastEdited = pdfInfo.lastEdited;
        data = await (await api.post(
          `/dppapi/predict/pdf/bbox/update/${projectId}/${pdfFileId}/${currentPage}/${bboxId}`,
          JSON.stringify(body),
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            }
          }
        )).data;
      }
      if (bboxId.toString().startsWith("-")) { // new row only update scrum
        body.scrumMaps = detailData;
        body.prevLastEdited = pdfInfo.lastEdited;
        data = await (await api.post(
          `/dppapi/predict/pdf/scrum/update/${projectId}/${pdfFileId}`,
          JSON.stringify(body),
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            }
          }
        )).data;
      }
    } catch (error) {
      onShowUpdateErrorDialog(error);
    }

    if (data.success) {
      this.handleChangeScrumMapData(bboxId, text);
      this.handleReloadPdfLoader();
      this.handleRefreshCanvas();
      this.handleReSetMuiPopup();
      onSetHeaderInfo(data.lastEditInfo);
    }
    this.setState({ detailItemChanging: false });
  }

  handleClickSendReportButton = (onClose) => {
    this.handleReport()
    onClose();
    this.context.sendPredictBeautifyDetailMetrics({
      event_type: EventType.Action,
      event_name: EventName.ReportReadingAccuracyIssues,
      custom_parameter: {
        document_type : this.state.currentPageType,
      },
    })
  }

  sendReport() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui-confirm-alert-body'>
            <h1>{intl.get('_predict.detail.mapping.Report reading accuracy issues')}</h1>
            <p>{
              intl.get(
                '_predict.detail.mapping.If you think the accuracy needs to be improved, report it to the administrator'
              )}</p>
            <div className='custom-ui-confirm-alert-button-group'>
              <button
                className='primary-btn confirmAlert-button-right'
                onClick={() => this.handleClickSendReportButton(onClose)}>
                {intl.get('_predict.detail.mapping.Send')}
              </button>
              <button
                className='predict-admin-botton-text confirmAlert-button-left'
                onClick={onClose}>
                {intl.get('_predict.detail.mapping.Cancel')}
              </button>
            </div>
          </div>
        );
      }
    });
  }

  handleReport = async () => {
    const { projectId, pdfFileId } = this.props;
     const  { data } =await api.get(`/dppapi/predict/pdf/issue/report/${projectId}/${pdfFileId}`);
    data.success ? this.setState({reportmessage:intl.get('_predict.detail.mapping.Thank you for your report')}):this.setState({reportmessage:intl.get('_predict.detail.mapping.Email sending failed')});
    this.setState({isReportOpen : true})
    setTimeout(() =>{
      this.setState({isReportOpen : false})
    },2500)
  };

  handlePortalClose = () => {
    this.props.onSelectedBBoxChange('', '');
  };

  toggleSearch = open => {
    if(open){
      this.setState({ searchPopOpen: open,  scrollToElement: null, searchItem: null});
      this.props.onRowSelect('', this.props.currentPage, []);
    }else{
      this.setState({ searchPopOpen: open});
    }
  };

  handleSearchReferItem = (scrumItem, pageType) =>{
    this.props.onRowSelect('', this.props.currentPage, []);
    if(this.state.currentPageType !== pageType){
      this.setState({scrollToElement: null, })
      if (pageType === PageType.SS) {
        this.handleMappingTypeChange(MappingType.LIST_AND_TABLE,this.state.mappingType);
      }else if(this.state.cacheMappingType){
        this.handleMappingTypeChange(this.state.cacheMappingType,this.state.cacheMappingType);
      }
      // change page
      const thumnailRef = this.props.thumbnailRef.current;
      this.findTargetPageByPageType(
        thumnailRef.state.imageList,
        pageType,
        this.props.currentPage,
        scrumItem
      );

      let img = this.findTargetImageByPageType(pageType, thumnailRef.state.imageList,scrumItem)
      // scroll into view
      this.setState({ currentPageType: pageType, searchText: '', searchItem:null},()=>{
        this.handleSelectSearchItem(scrumItem,img?img.pageId:'');
      });
    }else{
      
      // scroll into view
      this.setState({scrollToElement: null, searchItem:null},()=>{
        this.handleSelectSearchItem(scrumItem);
      })
    }
  }

  handleClickChangeBoardButton = () => {
    this.handleMappingTypeChange(MappingType.BOARD,MappingType.BOARD)
    this.props.onListViewChange(2)
    this.handleTabChange(0)
    const { pdfFileId, projectId } = this.props;
    this.context.sendPredictBeautifyDetailMetrics({
      event_type: EventType.Action,
      event_name: EventName.ChangeViewMode,
      custom_parameter: {
        view_mode: ViewMode.Card,
        document_type: this.state.currentPageType,
        pdf_id: pdfFileId,
        project_id: projectId,
      },
    })
  }

  handleClickChangeListButton = () => {
    this.handleMappingTypeChange(MappingType.LIST_AND_TABLE,MappingType.LIST_AND_TABLE)
    this.props.onListViewChange(1)
    this.handleTabChange(0)
    const { pdfFileId, projectId } = this.props;
    this.context.sendPredictBeautifyDetailMetrics({
      event_type: EventType.Action,
      event_name: EventName.ChangeViewMode,
      custom_parameter: {
        view_mode: ViewMode.List,
        document_type: this.state.currentPageType,
        pdf_id: pdfFileId,
        project_id: projectId,
      },
    })
  }

  handleClickCsvDownloadButton = async () => {
    await this.handleDownload();
    this.context.sendPredictBeautifyDetailMetrics({
      event_type: EventType.Action,
      event_name: EventName.CsvDownload,
      custom_parameter: {
        document_type : this.state.currentPageType, 
      },
    })
  }

  handleClickRestoreButton = () => {
    this.handleCancel()
    this.context.sendPredictBeautifyDetailMetrics({
      event_type: EventType.Action,
      event_name: EventName.RestoreToThePreviousSavedState,
      custom_parameter: {
        document_type : this.state.currentPageType, 
      },
    })
  }

  handleClickSaveButton = () => {
    this.handleSave()
    this.context.sendPredictBeautifyDetailMetrics({
      event_type: EventType.Action,
      event_name: EventName.Save,
    })
  }

  onKeyDown(keyName, e) {
    e.preventDefault();
    const isMac = navigator.userAgent.indexOf('Mac OS X') !== -1;
    if (isMac) {
      if(keyName === "⌘+s"){
        this.handleClickSaveButton()
      }
    }else{
      if(keyName === "ctrl+s"){
        this.handleClickSaveButton()
      }
    }
  }

  render() {
    const {
      pageType,
      currentPageType,
      allCheck,
      checkedList,
      searchText,
      category,
      mode,
      mappingType,
      detailData,
      isDownloading,
      errorList,
      allList,
      // pdfStatus,
      fsKeyValueTableScrollTop,
      fsDetailTableScrollTop,
      scrumData,
      metadata,
      scrollToElement,
      // pdfInfo,
      isCancel,
      ssCommon,
      detailItemChanging,
    } = this.state;
    const {
      onRowSelect,
      selectedCell,
      currentBoxIdForCss,
      projectId,
      pdfFileId,
      selectedBBox,
      isEditing,
      onEdit,
      currentPage,
      onSelectedBBoxChange,
      onResultFunctionParamSet,
      isShowComment,
      onCommentChange,
      pageRereadLoading,
      onListViewChange,
      onCheckRadioButton,
      amountImageDisplay,
      scrumMapingButtonDisplay,
      tenantPreference,
      pdfInfo,
      effectiveHotkeyPage,
      isNotOwner,
      onShowExclusionControl,
      onSetHeaderInfo,
    } = this.props;
    const pdfStatus = pdfInfo.stateFlag
    // const ss_2_dimension =
    //   tenantPreference['template_SS'] === 'SMFL' &&
    //   currentPageType === PageType.SS;
    const isShowAdminReportBut = tenantPreference['button_admin_report'];

    const isShowSearchFlg = tenantPreference['predict_page_preset_master_search'];

    const isShowVerticalColGrid = tenantPreference['review_page_rich_ui_version'];

    const ss_common_flag = tenantPreference["template_SS"] === "COMMON_TABLE" && currentPageType === PageType.SS;
    const ss_smfl_flag = tenantPreference["template_SS"] === "SMFL" && currentPageType === PageType.SS;
    const ss_common_list_flag = ((tenantPreference["template_SS"]) ? tenantPreference["template_SS"] === "COMMON_LIST" : true) && currentPageType === PageType.SS ;

    // get metadata of type is tab
    const tabMetadata = metadata['tab'];
  
    // search
    const filteredRows = this.handleGetFilteredRows();
    const detailItems = mode === TableMode.TOTALDETAIL && this.handleGetDetailItems();
    if (this.state.isLoading) {
      return (
        <Loader className="text-mapping-loading" active inline="centered" />
      );
    }

    return (
      <div id="pdf-textmapping-root" className="pdf-textmapping-root reread-loading-dimmer">
        {pageRereadLoading && (
          <Dimmer active inverted >
          </Dimmer>
        )}
        {isEditing ? <Hotkeys
          keyName="⌘+s, ctrl+s"
          onKeyDown={this.onKeyDown.bind(this)}
        /> : null}
        { pdfInfo && pdfInfo.comment && isShowComment && <div className="menu-scroll-show">
        <div className="menu-scroll-header">
          <div className="menu-scroll-header-menu-list">
            <div className="menu-scroll-header-content">
              <ErrorForCommentIcon color='#E8BA00' size='20px' className="total-left-icon" />コメントがあります
            </div>
            <CloseIcon className="button-icon" size='24px' onClick={() => onCommentChange()} />
          </div>
          <div className="pdfInfo-comment">
           <div className="pdf-comment">
           {pdfInfo.comment}
           </div>
          </div>
          </div>
        </div>}
        <div className="pdf-textmapping-operator menu-change-group-btn pdf-textmapping-operator-for-detail" ref={this.container}>
          <div className={'select-group'}>
            <Select
              options={pageType}
              value={currentPageType}
              placeholder={intl.get('_predict.detail.mapping.Select a type')}
              onChange={(e, { value }) => {
                //this.setState({cacheMappingType: value === PageType.SS ? this.state.mappingType : null});
                let listViewFlg = 1;
                if (tenantPreference["template_SS"] === 'SMFL' 
                  || (tenantPreference["template_SS"] === 'COMMON_TABLE' && ssCommon)) {
                    if (value === PageType.SS) {
                      this.handleMappingTypeChange(MappingType.LIST_AND_TABLE,this.state.mappingType);
                      listViewFlg = 3;
                    }else if(this.state.cacheMappingType){
                      this.handleMappingTypeChange(this.state.cacheMappingType,this.state.cacheMappingType);
                      listViewFlg = 2;
                    }
                } else {
                  if (value === PageType.SS) {
                    this.handleMappingTypeChange(MappingType.LIST_AND_TABLE,this.state.mappingType);
                    listViewFlg = 1;
                  }else if(this.state.cacheMappingType){
                    this.handleMappingTypeChange(this.state.cacheMappingType,this.state.cacheMappingType);
                    listViewFlg = 2;
                  }
                }
                
                if (mode === TableMode.TOTALDETAIL) {
                  listViewFlg = 4;
                }
                this.props.onListViewChange(listViewFlg);
                this.handlePageTypeChange(value);
                this.context.sendPredictBeautifyDetailMetrics({
                  event_type: EventType.Action,
                  event_name: EventName.SelectDocumentTypeDropdown,
                  custom_parameter: {
                    selected_document_type : value, 
                  },
                })
              }}
              className='select-pagetype select-pagetype-for-pdf-textmapping-operator'
            />
          </div>
          <Toasts
            isOpen={this.state.isPortalOpen}
            handlePortalClose={this.handlePortalClose}
            content={intl.get(
              '_predict.detail.mapping.Please select the subject you want to collaborate with'
            )}
          />
          {createPortal(
            <Toasts
              isOpen={this.state.isReportOpen}
              isClose={false}
              content={this.state.reportmessage}
              classNames="report-toast-snackbar"
            />, document.body
          )}
          {!isNotOwner ?
            <React.Fragment><Button
              id="save-button"
              className="predict-admin-botton-text pdf-textmapping-save-button"
              disabled={!isEditing}
              size="medium"
              onClick={this.handleClickSaveButton}
            >
              {intl.get('_predict.detail.mapping.Keep')}
            </Button>
              <FsResultFunctionToolBox
                detailData={detailData}
                scrumData={scrumData}
                projectId={projectId}
                pdfFileId={pdfFileId}
                isEditing={isEditing}
                pdfStatus={pdfStatus}
                cb={this.getPdfStatus}
                errorList={errorList}
                type={'RPA連携'}
                pdfInfo={pdfInfo}
                onResultFunctionParamSet={onResultFunctionParamSet}
                onSetHeaderInfo={onSetHeaderInfo}
                onGetSum={this.handleGetSum}
              /></React.Fragment> : null}
          <div className="btn-group menu-change-group-btn btn-group-for-detail" id="change-view-mode-btn-group">
            <Button.Group>
            {(isShowVerticalColGrid === 'true' && currentPageType !== PageType.SS) ?
            <Popup
            trigger= {
              <Button
              id="ga-predict-detail-mapping-card-view"
              active={mode !== TableMode.TOTALDETAIL && mappingType === MappingType.BOARD}
              // disabled={ss_2_dimension}
              positive
              icon
              onClick={this.handleClickChangeBoardButton}
              className="btn-toggle"
            >
              <ViewWeekIcon color={mode !== TableMode.TOTALDETAIL && mappingType === MappingType.BOARD?'#0072C6':'#737373'}/>
            </Button> 
            }
            position="bottom center"
            content={intl.get('_predict.detail.mapping.View in card view')}
            on="hover"
            hideOnScroll
            inverted
            />
            : (ss_common_flag || ss_smfl_flag? 
            <Popup
            trigger= {
              <Button
              id="ga-predict-detail-mapping-SS-table-view"
              active={mode !== TableMode.TOTALDETAIL && ssCommon}
              positive
              icon
              onClick={() => {
                this.handleSSTypeChange(true);
                this.props.onListViewChange(3);
              }}
              className="btn-toggle"
            >
              <GridOnIcon color={mode !== TableMode.TOTALDETAIL && ssCommon?'#0072C6':'#737373'}/>
            </Button> 
            }
            position="bottom center"
            content={intl.get('_predict.detail.mapping.View in table view')}
            on="hover"
            hideOnScroll
            inverted
            />
            :null)}
            {currentPageType !== PageType.SS ?
            <Popup
            trigger = {
              <Button
              id="ga-predict-detail-mapping-list-view"
              active={mode !== TableMode.TOTALDETAIL && mappingType === MappingType.LIST_AND_TABLE}
              // disabled={ss_2_dimension}
              positive
              icon
              onClick={this.handleClickChangeListButton}
              className="btn-toggle"
            >
              <TableRowsNarrowIcon color={mode !== TableMode.TOTALDETAIL && mappingType === MappingType.LIST_AND_TABLE?'#0072C6':'#737373'}/>
            </Button>
            }
            position="bottom center"
            content={intl.get('_predict.detail.mapping.View in list view')}
            on="hover"
            hideOnScroll
            inverted
            />
            : (ss_common_flag || ss_common_list_flag || (isShowVerticalColGrid !== 'true' && currentPageType !== PageType.SS) ? 
              <Popup
              trigger = {
                <Button
                id="ga-predict-detail-mapping-SS-list-view"
                active={mode !== TableMode.TOTALDETAIL && !ssCommon }
                positive
                icon
                onClick={() => {
                  this.handleSSTypeChange(false);
                  this.props.onListViewChange(1);
                }}
                className="btn-toggle"
              >
                <TableRowsNarrowIcon color={mode !== TableMode.TOTALDETAIL && !ssCommon?'#0072C6':'#737373'}/>
              </Button>
              }
              position="bottom center"
              content={intl.get('_predict.detail.mapping.View in list view')}
              on="hover"
              hideOnScroll
              inverted
              />
            : null)}
            <Popup
              trigger = {
                <Button
                  id="ga-predict-detail-mapping-detail-view"
                  active={mode === TableMode.TOTALDETAIL}
                  positive
                  icon
                  onClick={() => {this.handleModeChange(TableMode.TOTALDETAIL);}}
                  className="btn-toggle"
                >
                  {mode === TableMode.TOTALDETAIL?
                    <FactCheckActiveIcon /> : <FactCheckIcon />
                  }
                </Button>
              }
              position="bottom center"
              content={intl.get('_predict.detail.mapping.View in detail view')}
              on="hover"
              hideOnScroll
              inverted
            />
            </Button.Group>
            <Dropdown
              button
              item
              icon={<MoreVertIcon size='24px' color='#545454' />}
              floating
              direction={this.state.moreBtnAlign}
              className="btn-more-icon"
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  disabled={isEditing || isDownloading}
                  loading={isDownloading.toString()}
                  // icon="download"
                  text={intl.get('_predict.detail.mapping.CSV download')}
                  onClick={this.handleClickCsvDownloadButton}
                />
                {isShowAdminReportBut === '1' && <Dropdown.Item onClick={() => this.sendReport()}>
                  {intl.get('_predict.detail.mapping.Report to administrator')}
                </Dropdown.Item>}
                <Dropdown.Item
                  disabled={!isEditing}
                  onClick={this.handleClickRestoreButton}
                >
                  {intl.get(
                    '_predict.detail.mapping.Restore to the previous saved state'
                  )}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="pdf-textmapping-page-body">
          {mode === TableMode.TOTALDETAIL ? (
            <div className="fs-text-mapping-table-wrap">
              <FsDetailTable
                originalRows={filteredRows}
                detailItems={detailItems}
                fsItemsCompletion={this.state.fsItemsCompletion}
                onMappingItemChange={this.handleMappingItemChange}
                onCheck={this.handelCheck}
                onScrumItemChange={this.handleScrumItemChange}
                onInputModeChange={this.handleInputModeChange}
                checkedList={checkedList}
                onRowSelect={onRowSelect}
                onAddRow={this.handleAddRow}
                onDeleteRow={this.handleDeleteRow}
                onDropRow={this.handleDropRow}
                selectedCell={selectedCell}
                onNormalized={this.handleNormalized}
                currentBoxIdForCss={currentBoxIdForCss}
                currentPage={currentPage}
                currentPageType={currentPageType}
                category={category[currentPageType]}
                handleUnLinkScrumItem={this.handleUnLinkScrumItem}
                handleChangeValue={this.handleChangeValue}
                onEdit={onEdit}
                onGetSum={this.handleGetSum}
                errorList={errorList}
                allList={allList}
                onManualInputTextChange={this.handleManualInputTextChange}
                isCancel={isCancel}
                inputCalc={this.state.manualCalc}
                onUpdateBBox={this.handleUpdateBBox}
                scrollToElement={scrollToElement}
                detailItemChanging={detailItemChanging}
                amountImageDisplay={amountImageDisplay}
                scrumMapingButtonDisplay={scrumMapingButtonDisplay}
                onCheckRadioButton={onCheckRadioButton}
                fsDetailTableScrollTop={fsDetailTableScrollTop}
                onSetScrollTop={this.handleSetScrollTop}
                effectiveHotkeyPage={effectiveHotkeyPage}
                pdfFileId={pdfFileId}
                projectId={projectId}
                isNotOwner={isNotOwner}
                onShowExclusionControl={onShowExclusionControl}
              />
            </div>
          ) :
            mappingType === MappingType.LIST_AND_TABLE ? (
              <>
                {mode === TableMode.SCRUM ? (
                  <FsKeyValueTable
                    rows={filteredRows}
                    tabData={tabMetadata}
                    onRowSelect={onRowSelect}
                    selectedCell={selectedCell}
                    currentBoxIdForCss={currentBoxIdForCss}
                    selectedBBox={selectedBBox}
                    onGetSum={this.handleGetSum}
                    onTabChange={this.handleTabChange}
                    onLinkBbox2Cell={this.handleLinkBbox2Cell4KVTable}
                    currentPageType={currentPageType}
                    detailData={detailData}
                    onInputModeChange={this.handleInputModeChange}
                    onManualInputTextChange={this.handleManualInputTextChange}
                    onEdit={onEdit}
                    currentPage={currentPage}
                    errorList={errorList}
                    allList={allList}
                    fsKeyValueTableScrollTop={fsKeyValueTableScrollTop}
                    onSetScrollTop={this.handleSetScrollTop}
                    searchText={searchText}
                    isEditing={isEditing}
                    handleChangeValue={this.handleChangeValue}
                    metadata={metadata}
                    tenantPreference={tenantPreference}
                    onSelectedBBoxChange={onSelectedBBoxChange}
                    scrollToElement={scrollToElement}
                    toggleSearch={this.toggleSearch}
                    isShowSearchFlg={isShowSearchFlg}
                    metadatas={this.state.metadatas}
                    isCancel={isCancel}
                    inputCalc={this.state.manualCalc}
                    handleSearchReferItem={this.handleSearchReferItem}
                    ssCommon={ssCommon}
                    mappingType={mappingType}
                    onMappingTypeChange={this.handleMappingTypeChange}
                    onListViewChange={onListViewChange}
                    handleSSTypeChange={this.handleSSTypeChange}
                    handleUnLinkScrumItem={this.handleUnLinkScrumItem}
                    effectiveHotkeyPage={effectiveHotkeyPage}
                    pdfInfo={pdfInfo}
                    onUpdateBBox={this.handleUpdateBBox}
                    detailItemChanging={detailItemChanging}
                    isNotOwner={isNotOwner}
                    onShowExclusionControl={onShowExclusionControl}
                  />
                ) : (
                  <div className="fs-text-mapping-table-wrap">
                    <div className={'btn-group-detail'}>
                      <Icon
                        link
                        name="arrow left"
                        onClick={() => this.handleTabChange(0)}
                      />
                    </div>
                    <FsTextTable
                      rows={filteredRows}
                      onMappingItemChange={this.handleMappingItemChange}
                      onCheck={this.handelCheck}
                      onAllCheck={this.handleAllCheck}
                      allCheck={allCheck}
                      checkedList={checkedList}
                      onRowSelect={onRowSelect}
                      selectedCell={selectedCell}
                      onNormalized={this.handleNormalized}
                      currentBoxIdForCss={currentBoxIdForCss}
                      category={category[currentPageType]}
                      onLinkBbox2Cell={this.handleLinkBbox2Cell4TextTable}
                      selectedBBox={selectedBBox}
                      handleUnLinkScrumItem={this.handleUnLinkScrumItem}
                      handleChangeValue={this.handleChangeValue}
                      onEdit={onEdit}
                      onGetSum={this.handleGetSum}
                      isNotOwner={isNotOwner}
                      onShowExclusionControl={onShowExclusionControl}
                    />
                  </div>
                )}
              </>
            ) : (
              <FsKeyValueBoard
                ref={this.boardRef}
                rows={filteredRows}
                tabData={tabMetadata}
                onLinkBbox2Cell={this.handleLinkBbox2Cell4KVTable}
                errorList={errorList}
                allList={allList}
                currentPage={currentPage ? Number(currentPage) : null}
                detailData={detailData}
                currentPageType={currentPageType}
                selectedBBox={selectedBBox}
                isPortalOpen={this.state.isPortalOpen}
                onRowSelect={onRowSelect}
                onEdit={onEdit}
                handleChangeValue={this.handleChangeValue}
                onGetSum={this.handleGetSum}
                onUpdateBBox={this.handleUpdateBBox}
                metadata={metadata}
                category={category[currentPageType]}
                handleUnLinkScrumItem={this.handleUnLinkScrumItem}
                onSelectedBBoxChange={onSelectedBBoxChange}
                onInputModeChange={this.handleInputModeChange}
                onManualInputTextChange={this.handleManualInputTextChange}
                selectedCell={selectedCell}
                scrollToElement={scrollToElement}
                handleTextChange={this.handleTextChange}
                currentBoxIdForCss={currentBoxIdForCss}
                toggleSearch={this.toggleSearch}
                searchItem={this.state.searchItem}
                isShowSearchFlg={isShowSearchFlg}
                isCancel={isCancel}
                inputCalc={this.state.manualCalc}
                referItemList={this.state.referItemList}
                scrumData={scrumData}
                handleSearchReferItem={this.handleSearchReferItem}
                tenantPreference={tenantPreference}
                mappingType={mappingType}
                onMappingTypeChange={this.handleMappingTypeChange}
                ssCommon={ssCommon}
                onListViewChange={onListViewChange}
                handleSSTypeChange={this.handleSSTypeChange}
                textmappingOperatorHeight={this.state.textmappingOperatorHeight}
                pdfInfo={pdfInfo}
                detailItemChanging={detailItemChanging}
                isNotOwner={isNotOwner}
                onShowExclusionControl={onShowExclusionControl}
              />
            )}
        </div>
        {this.state.searchPopOpen ? createPortal(
          <ItemSearchPopup
            isShowEdit={this.state.searchPopOpen}
            toggleSearch={this.toggleSearch}
            handleSelectSearchItem={this.handleSelectSearchItem}
            category={this.props.thumbnailRef.current.state.pageClassification.pageType}
          />
        , document.body) : null}
      </div>
    );
  }
}
