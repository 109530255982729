import React, { Component } from 'react';
import { Message } from 'semantic-ui-react'
import {
  Link
} from 'react-router-dom';

export default class MessagePage extends Component {
  render() {
    const { state = {} } = this.props.location
    const url = localStorage.getItem('url')
    return (
      <Message style={{ maxWidth: 600, margin: '100px auto' }} {...state}>
        <Message.Header>{state.msg || 'An unknown error occurred, please contact your administor.'}</Message.Header>
        <p>
          Redirect to your <Link to={url}>home</Link>.page.
        </p>
      </Message>
    );
  }
}