import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import Menubar from '../common/Menubar';
import EasyOcrTab from './EasyOcrTab';
import ApiEdit from './ApiEdit';

class EasyOcrApp extends Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path="/easyocr/"
          render={props => (
            <Menubar active="easyocr">
              <EasyOcrTab {...props} />
            </Menubar>
          )}
        />
        <Route
          path="/easyocr/edit/:apiInfoId"
          render={props => (
            <Menubar active="easyocr">
              <ApiEdit {...props} />
            </Menubar>
          )}
        />
        <Route
          path="/easyocr/add"
          render={props => (
            <Menubar active="easyocr">
              <ApiEdit {...props} />
            </Menubar>
          )}
        />
      </Switch>
    );
  }
}

export default EasyOcrApp;
