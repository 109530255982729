import React from 'react';

import { MDBModal, MDBModalBody } from 'mdbreact';
import {
  Button,
  Form,
} from 'semantic-ui-react';
import intl from 'react-intl-universal';

export default class ProjectDescription extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      project: {},
    };
  }

  render() {
    const { project, isShowEdit } = this.props;

    return (
      <MDBModal
        isOpen={isShowEdit}
        toggle={(flag) => {
          this.props.toggleEdit({ isShowEdit: flag });
        }}
      >
        <MDBModalBody>
          <Form>
            <Form.Field>
              <Form.TextArea placeholder="Brief Description" defaultValue={project.description} name="description" rows="10" readOnly/>
            </Form.Field>
            <Button
              floated="right"
              onClick={() => {
                this.props.toggleEdit({ isShowEdit: false });
              }}
            >
              {intl.get('_train.description.index.Cancel')}
            </Button>
          </Form> 
        </MDBModalBody>
      </MDBModal>
    );
  }
}
