import React from 'react';
import { createPortal } from 'react-dom';
import { MDBModal, MDBModalBody } from 'mdbreact';
import intl from 'react-intl-universal';
import {Button, Checkbox, Table, Ref, Popup, Message} from 'semantic-ui-react';
import api from '../../../api';
import _ from 'lodash';
import Toasts from '../../../common/Toasts';
import colValueSelectGif from '../../../assets/icon/gif/col-value-select.gif';
import { VectorIcon, ExpandMoreUpIcon, ExpandMoreDownIcon, AddIcon, ErrorIcon, DisconnectIcon, FrameIcon, BadgeInuseIcon } from '../../../assets/icon';
import { confirmAlert } from 'react-confirm-alert';
import { EventName, EventType, PageName } from '../../../common/Analytics/analyticsMetrics';
import { AnalyticsServiceContext } from '../../../common/Analytics/AnalyticsServiceProvider';

export default class RePredict extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            pageClassification: '',
            selectedImg: [],
            imgOptions:[],
            clsOptions: ["貸借対照表","損益計算書","製造原価明細表","販売費及び一般管理費","連結貸借対照表","連結損益計算書"],
            imgOtherOptions:[],
            showVice: false,
            errorFlag: false,
            isHover: false,
            popupOpenFlag: true,
            disconnectShow: true,
            frameShow: true,
            imgFrameOptions: [],
            disconnectOptions: [],
            indeterFlg: false,
            checked: false
        }
    }

    static contextType = AnalyticsServiceContext;

    componentDidMount() {
        // if(this.props.images){
        if(this.props.thumbnailRef && this.props.thumbnailRef.current.state.imageList){
            const { imageList, pageClassification } = this.props.thumbnailRef.current.state
            const ops = []
            const otherOps = []
            let pageType = this.props.pageType ? this.props.pageType : pageClassification.pageType
            const linkPageTypeMap = {
                "連結貸借対照表": "貸借対照表",
                "連結損益計算書": "損益計算書",
                "連結株主資本等変動計算書": "株主資本等変動計算書"
            }
            if(linkPageTypeMap[pageType]){
                pageType = linkPageTypeMap[pageType]
            }
            imageList.forEach((element,idx) => {
                if(element.pageClassification === pageType){
                    ops.push({
                        key: element.pageId,
                        value: element.pageId,
                        pageId: element.pageId,
                        text: idx+1,
                        selected: false,
                        pageType: element.pageClassification,
                        bboxesCount: element.bboxesCount,
                    })
                }else{
                    otherOps.push({
                        key: element.pageId,
                        value: element.pageId,
                        pageId: element.pageId,
                        text: idx+1,
                        selected: false,
                        pageType: element.pageClassification,
                        rowHover: false,
                        bboxesCount: element.bboxesCount,
                    })
                    if(Number(element.pageId) === Number(pageClassification.pageId) && element.pageClassification === pageClassification.pageType){
                        ops.push({
                            key: element.pageId,
                            value: element.pageId,
                            pageId: element.pageId,
                            text: idx+1,
                            selected: false,
                            pageType: element.pageClassification,
                            bboxesCount: element.bboxesCount,
                        })
                    }
                }
            });

            this.findConsequentPage(ops,pageClassification.pageId)
            const hasBbox = this.isListHasBbox(ops)

            this.setState({
                imgOptions: ops,
                imgOtherOptions: otherOps,
                pageType: pageType,
                hasBbox
            })
        }
        this.changeCss()
        window.addEventListener('resize', this.changeCss)
    }

    componentDidUpdate(prevProps, prevState){

        if(!_.isEqual(prevState.imgOptions, this.state.imgOptions)){
            this.setState({hasBbox: this.isListHasBbox(this.state.imgOptions)})
        }
        this.changeCss()
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.changeCss)
      }

    changeCss = () => {
        const { confirmFlg, isMultiCol, isAIAuto, errorFlag } = this.state;
        if(confirmFlg){
            const { disconnectOptions, imgFrameOptions } = this.state;
            const selectSize = imgFrameOptions.length;
            const disSize = disconnectOptions ? disconnectOptions.length : 0;
            const preInnerHeight = (isMultiCol||isAIAuto) ? 364 + 49 : 364;
            var exceptConOptionHeight = preInnerHeight + 64;
            const maxInnerHeight = exceptConOptionHeight + selectSize * 34 + 1 + (disSize ? (disSize * 34 + 1) : 0)
            const discEle = document.getElementsByClassName("confirm-disconnect-table-div");
            const selectEle = document.getElementsByClassName("confirm-frame-table-div");
            if(!selectEle || selectEle.length === 0) return;
            if(window.innerHeight < maxInnerHeight ){
                if( window.innerHeight > exceptConOptionHeight){
                    const optionHeight = window.innerHeight - exceptConOptionHeight
                    const calcDisSize = disSize > 9 ? 9 : disSize
                    const calcSelSize = selectSize > 9 ? 9 : selectSize
                    if(disSize){
                        const selectMaxHeight = Math.ceil((optionHeight/(calcSelSize + calcDisSize))*calcSelSize)
                        selectEle[0].style.maxHeight = selectMaxHeight + 'px';
                        const minusHeight = exceptConOptionHeight + selectMaxHeight
                        const disMaxHeight = "calc(100vh - "+minusHeight+"px)"
                        discEle[0].style.maxHeight = disMaxHeight ;
                    }else{
                        const selectMaxHeight = "calc(100vh - "+((isMultiCol||isAIAuto) ? 376 + 49 : 376)+"px)"
                        selectEle[0].style.maxHeight = selectMaxHeight;
                    }
                }else{
                    selectEle[0].style.maxHeight = "34px" ;
                    if(disSize){
                        discEle[0].style.maxHeight = "34px" ;
                    }
                    const contentEle = document.getElementsByClassName("repredict-reread-content");
                    contentEle[0].style.marginBottom = 0
                    contentEle[0].style.marginTop = 0
                }
            }else{
                if(disSize){
                    discEle[0].style.maxHeight = "304px" ;
                }
                selectEle[0].style.maxHeight = "304px" ;
            }

        }else{
            const viceEle = document.getElementsByClassName("vice-table-div");
            const { showVice } = this.state
            const imgSize = this.state.imgOptions.length;
            const nextDisabled = this.getNextStatus();
            const showWarnning = !isMultiCol && !isAIAuto && nextDisabled
            var exceptOptionHeight = showWarnning ? 531 : errorFlag ? 471 : 431;
            const selectHeight = imgSize > 5 ? 176 : 32 * imgSize
            const maxInnerHeight = (showWarnning ? 984 : errorFlag ? 934 : 894) + selectHeight
            const minInnerHeight = exceptOptionHeight + selectHeight + (showVice ? 104 : 0)
            if(window.innerHeight < maxInnerHeight ){
                if(window.innerHeight > minInnerHeight){
                    const minus =  exceptOptionHeight + selectHeight + (showVice ? 24 : 0)
                    const height = "calc(100vh - "+minus+"px)"
                    if(showVice){
                        viceEle[0].style.maxHeight = height
                    }
                }else{
                    const contentEle = document.getElementsByClassName("repredict-reread-content");
                    if(contentEle){
                        contentEle[0].style.marginBottom = 0
                        contentEle[0].style.marginTop = 0
                    }
                    if(showVice){
                        viceEle[0].style.maxHeight = "80px"
                    }
                }
            }
        }
    }
    isListHasBbox = (imgOptions) => {
        const selectedImgOptions = imgOptions.filter(img => img.selected === true)
        let bboxs = 0;
        for(let i = 0; i < selectedImgOptions.length;i++){
            bboxs = bboxs + Number(selectedImgOptions[i].bboxesCount)
            if (bboxs > 0) break
        }
        if(bboxs === 0){
            this.setState({isMultiCol: false, isAIAuto: false})
        }

        return bboxs > 0
    }

    findConsequentPage = (ops, currentPageId) => {
        var imgIdxList = []
        for(var i=0;i<ops.length; i++){
            if(i===0){
                imgIdxList.push(ops[i].key)
            }
            if(i>0){
                if(Number(ops[i].key) === (Number(ops[i-1].key) + 1)){
                    imgIdxList.push(ops[i].key)
                }else{
                    if(imgIdxList.includes(String(currentPageId))){
                        break;
                    }else{
                        imgIdxList = []
                        imgIdxList.push(ops[i].key)
                    }
                }
            }
        }
        ops.forEach(e=>{
            if(imgIdxList.includes(e.key)){
                e.selected = true;
            }
        })
    }
    handleChange = (event, { name, key, text, value }) => {
        this.setState({
            selectedImg: value
        })
    }

    handleChangeCls = (event, { name, key, text, value }) => {
        this.setState({
            pageClassification: value
        })
    }

    handleCheckboxChange = (idx) => {
        const { imgOptions } = this.state;
        const cloneImgOptions = _.cloneDeep(imgOptions);
        var errorFlag = true;
        var num = 0;
        var indeterFlg = false;
        cloneImgOptions[idx].selected = !cloneImgOptions[idx].selected

        cloneImgOptions.forEach((cloneImgOption) => {
            if(cloneImgOption.selected){
                errorFlag = false;
                num++;
            }
        })
        if(num > 0 && num < cloneImgOptions.length){
            indeterFlg = true;
        }
        
        this.setState({
            imgOptions: cloneImgOptions,
            errorFlag: errorFlag,
            indeterFlg: indeterFlg
        })
    }

    handleCheckBoxAll = (checked) => {
        const { imgOptions } = this.state;
        const cloneImgOptions = _.cloneDeep(imgOptions);
        cloneImgOptions.forEach((cloneImgOption) => {
            cloneImgOption.selected = checked
        })

        this.setState({
            imgOptions: cloneImgOptions,
            errorFlag: !checked,
            indeterFlg: false
        })
    }

    handleOK = () => {
        const { handleRePredictSelectBbox, textMappingRef } = this.props;
        const { isMultiCol,imgOptions, pageType } = this.state;

        var frameShow = false
        const imgFrameOptions = imgOptions.filter(imgOption => imgOption.selected);
        if(imgFrameOptions.length > 0){
            frameShow = true
        }
        
        const disconnectOptions = [];
        var disconnectShow = false
        const imgDisconnectOptions = imgOptions.filter(imgOption => !imgOption.selected&&imgOption.pageType === pageType);
        if(imgDisconnectOptions && imgDisconnectOptions.length > 0){
            const { detailData } = textMappingRef.current.state;
            imgDisconnectOptions.forEach((imgDisconnectOption) =>{
                for (let i = 0; i < Object.keys(detailData).length; i++) {
                    const scrumSubTitle = Object.keys(detailData)[i]
                    if (scrumSubTitle !== 'manual_input') {
                        const existPredict = detailData[scrumSubTitle].find(d=>d.pdfImageId&&d.pdfImageId.toString() === imgDisconnectOption.key.toString() && d.scrumItem)
                        if(existPredict){
                            disconnectOptions.push(imgDisconnectOption);
                        }
                    }
                }

            })
        }
        if(disconnectOptions.length > 0){
            disconnectShow = true
        }

        this.setState({
            confirmFlg: true, 
            frameShow, 
            disconnectShow,
            imgFrameOptions,
            disconnectOptions
        })
        if(isMultiCol){

            const currentImage = this.state.imgOptions.find(img => Number(img.pageId) === Number(this.props.currentPage))

            if (currentImage && (!currentImage.selected || currentImage.bboxesCount === 0)){
                const targetImage = this.state.imgOptions.find(img => (Number(img.bboxesCount) > 0 && img.selected === true))
                if (targetImage){
                    // this.props.onPageChange(Number(targetImage.pageId))
                    const thumbnailRef = this.props.thumbnailRef.current
                    if (thumbnailRef){
                        thumbnailRef.clickImg({pageId: targetImage.pageId});

                    }
                }
            } 

            handleRePredictSelectBbox(true)
        }
    }
    handleRePredict = async() =>{
        const {
            projectId,
            pdfFileId,
            handelShowPredictLoading,
            targetBbox,
            textMappingRef,
            thumbnailRef,
            canvasRef,
            pdfInfo,
            onSetHeaderInfo,
            onShowUpdateErrorDialog,
            onLocalStorageUsageChange,
        } = this.props;

        const { isMultiCol, imgOptions, pageType, isAIAuto } = this.state;
        onLocalStorageUsageChange(false);
        canvasRef.current.headerRef.current.changeSelectedValue(pageType)
        this.props.toggleEdit(false); 
        this.props.handleChangeRePredict(true)
        handelShowPredictLoading("再読取中です","しばらくお待ちください");
        const selectedImg = imgOptions.filter(v=>v.selected).map(v=>v.value)
        const disconnectOptions = [];
        const imgDisconnectOptions = imgOptions.filter(imgOption => !imgOption.selected&&imgOption.pageType === pageType);
        // update images status inUse=false
        const unUseImages = pdfInfo.images.filter(img=> imgDisconnectOptions.find(dis=>dis.pageId === img.id.toString()) && img.imageStatus && img.imageStatus.inUse)
        try {
            if (unUseImages && unUseImages.length > 0) {
                const unuseImagesList = unUseImages.map(img => {
                    img.imageStatus = {
                        ...img.imageStatus,
                        inUse: false
                    }
                    return {
                        imageId: img.id,
                        imageStatus: img.imageStatus
                    }
                })
                const data_update_status = await (await api.request(
                    {
                        url: `/dppapi/predict/image/status/${projectId}/${pdfFileId}`,
                        method: 'POST',
                        data: {
                            images: unuseImagesList,
                            prevLastEdited: pdfInfo.lastEdited
                        },
                    },
                )).data;

                if (data_update_status.success) {
                    onSetHeaderInfo(data_update_status.lastEditInfo);
                }
            }
        } catch (error) {
            onShowUpdateErrorDialog(error);
            handelShowPredictLoading();
            return false;
        }


        if(imgDisconnectOptions && imgDisconnectOptions.length > 0){
            const { detailData } = textMappingRef.current.state;
            imgDisconnectOptions.forEach((imgDisconnectOption) =>{
                for (let i = 0; i < Object.keys(detailData).length; i++) {
                    const scrumSubTitle = Object.keys(detailData)[i]
                    if (scrumSubTitle !== 'manual_input') {
                        const existPredict = detailData[scrumSubTitle].find(d=>d.pdfImageId&&d.pdfImageId.toString() === imgDisconnectOption.key.toString() && d.scrumItem)
                        if(existPredict){
                            disconnectOptions.push(imgDisconnectOption);
                        }
                    }
                }
            })
        }
        const cleanupImagesList = disconnectOptions.map(v=>Number(v.value))
        const selectedCheckImg = imgOptions.filter(v=>v.selected&&v.pageType !== pageType)

        if(textMappingRef.current && thumbnailRef.current){
            await textMappingRef.current.handleDeleteImagePredict(selectedCheckImg, pageType)
        }
        try {
            const data = await (await api.request({
                url: `/dppapi/predict/${projectId}/${pdfFileId}/redo`,
                method: 'POST',
                data: {
                    cleanupImagesList: cleanupImagesList,
                    reParsingImagesList: selectedImg.map(item => Number(item)),
                    reParsingType: [pageType],
                    isMultiCol: isMultiCol || isAIAuto,
                    prevLastEdited: pdfInfo.lastEdited,
                    targetBbox
                },
            })).data;
            await this.props.handleGetPdfStatus();
            if (data.success) {
                canvasRef.current.headerRef.current.changeSelectedValue(pageType)
                if (data.isQueue) {
                    // logic for Queue 
                } else {
                    onSetHeaderInfo(data.lastEditInfo);
                    // set rePredict false to update menubar time
                    this.props.handleChangeRePredict(false)
                    handelShowPredictLoading();
                    await this.props.handleImgReload();
                    onLocalStorageUsageChange(true);
                    var sucessInterval = window.setInterval(() => {
                        if (canvasRef && canvasRef.current) {
                            canvasRef.current.showRereadSuccess();
                            clearInterval(sucessInterval);
                        }
                    }, 200)
                }
            }
        } catch (error) {
            onShowUpdateErrorDialog(error);
            handelShowPredictLoading();
        }
    }

    handleSelectBbox = ()=> {
        const { handleRePredictSelectBbox } = this.props;
        handleRePredictSelectBbox(true)
    }

    changeMultiCol = (e, value) =>{
        this.setState({isMultiCol: value.checked, isAIAuto: false})
    }

    changeAIAuto = (e, value) =>{
        this.setState({isAIAuto: value.checked, isMultiCol: false})
    }

    handlePortalClose = () => {
        this.props.handleRePredictSelectBbox(false);
        this.setState({confirmFlg: false })
    };
    handleImageClick = async(e,img) => {
        if(this.state.popupOpenFlag &&!e.target.className.includes("noJump") && e.target.type !== "checkbox"){
            const thumbnail = this.props.thumbnailRef.current
            thumbnail.clickImg(img);
        }
    }
    dragstart = (e) => {
        e.dataTransfer.effectAllowed = "move"
        e.dataTransfer.dropEffect = "move"
        document.getElementsByClassName("modal-content")[0].classList.add('repredict-dialog-drag-hiden')
        this.setState({
            pageX: e.pageX,
            pageY: e.pageY
        })
    }
    dragover = (e) => {
        e.preventDefault();
        e.dataTransfer.effectAllowed = "move"
        e.dataTransfer.dropEffect = "move"
    }
    dragenter = (e) => {
        e.preventDefault();
        e.dataTransfer.effectAllowed = "move"
        e.dataTransfer.dropEffect = "move"
    }
    dragend = (e) => {
        document.getElementsByClassName("modal-content")[0].classList.remove('repredict-dialog-drag-hiden')
        e.preventDefault();
    }
    drop = (e) => {
        e.preventDefault();
        
        var movex = e.pageX-this.state.pageX;
        var movey = e.pageY-this.state.pageY;
        if(this.state.moveX){
            movex = movex + this.state.moveX
        }
        if(this.state.moveY){
            movey = movey + this.state.moveY
        }
        document.getElementsByClassName("modal-content")[0].style.position = 'relative'
        document.getElementsByClassName("modal-content")[0].style.left = movex+'px'
        document.getElementsByClassName("modal-content")[0].style.top = movey+'px'
        this.setState({
            moveX: movex,
            moveY: movey
        })
    }

    handlePopupMove = (e,id) => {
        const minusX = e.clientX-(document.getElementById(id).getBoundingClientRect().right+document.getElementById(id).getBoundingClientRect().left)/2;
        const minusY = e.clientY-document.getElementById(id).getBoundingClientRect().top;
        if(!this.state.openImagePop){
            this.setState({openImagePop: id,popupOpenFlag: true,popupOffset: [minusX,-minusY+1]})
        }else{
            this.setState({popupOffset: [minusX,-minusY+1]});
        }
    }
    handlePopupEnter = (key) => {
        const timer = setTimeout(()=>{
            this.setState({openImagePop: key})
        }, 1000);
        const cloneTimeRefs = _.cloneDeep(this.state.timeRefs);
        const newTimes = {
            ...cloneTimeRefs,
            [key]: timer
        }
        this.setState({openImagePop: '-1', popupOpenFlag: true, timeRefs: newTimes})
    }

    handlePopupLeave = (key) => {
        this.setState({popupOffset: null,openImagePop: null, popupOpenFlag: false});
        if(this.state.timeRefs&&this.state.timeRefs[key]){
            clearTimeout(this.state.timeRefs[key])
        }
    }

    getNextStatus = () => {
        const { pageType, imgOptions } = this.state;
        const { pdfInfo } = this.props;
        var nextDisabled = false;
        const otherTypeOptions = imgOptions.filter(imgOption => imgOption.selected&&imgOption.pageType !== pageType);
        if(otherTypeOptions.length === 0){
            const inUseImages = pdfInfo.images.filter(i=> pageType === i.pageClassification && i.imageStatus && i.imageStatus.inUse )
            const selectedImages = imgOptions.filter(imgOption => imgOption.selected)
            const changeInuseImages = inUseImages.filter(i => !selectedImages.find(imgOption => imgOption.pageId === i.id.toString()))
            const otherTemplateImages = inUseImages.filter(i=> i.imageTemplate !== 1)
            const selectedNotUsedImages = selectedImages.filter(i => !inUseImages.find(imgOption =>imgOption.id.toString() === i.pageId.toString()))
            //all selected image is current type inuse image
            //all selected image is template 1
            if(changeInuseImages.length === 0 && otherTemplateImages.length === 0 && selectedNotUsedImages.length === 0){
                nextDisabled = true;
            }
        }
        return nextDisabled;
    }
    renderHeader = () => {
        const { indeterFlg, imgOptions } = this.state;
        var checkNum = 0
        var checked = false;
        imgOptions.forEach((cloneImgOption) => {
            if(cloneImgOption.selected){
                checkNum++
            }
        })
        if(checkNum === 0){
            checked = false
        }else if(checkNum === imgOptions.length){
            checked = true
        }
        return (
          <Table.Row className="mui-popup-table-header-row">
            <Table.HeaderCell className="mui-popup-table-header-select-cell mui-popup-table-header-cell-border" style={{width:'56px'}}>
                <Popup
                    trigger={
                        <div className="mui-popup-table-child1">
                            <Checkbox id="all" checked={checked} indeterminate={indeterFlg} onChange={(e, {checked}) => this.handleCheckBoxAll(checked)}/>
                        </div>
                    }
                    position="top center"
                    content={'一括選択/選択解除'}
                    on="hover"
                    wide='very'
                    hideOnScroll
                    inverted
                    style={{zIndex: 2100}}
                />
            </Table.HeaderCell>
            <Table.HeaderCell className="mui-popup-table-child2 mui-popup-table-header-cell-border">
              {'頁'}
            </Table.HeaderCell>
            <Table.HeaderCell
              className="mui-popup-table-child3 mui-popup-table-header-cell-border"
            >
              {'分類（再読取前）'}
            </Table.HeaderCell>
          </Table.Row>
        );
      };
      renderRow = () => {
        const { imgOptions, pageType, popupOpenFlag } = this.state;
        const { pdfInfo } = this.props;
    
        return imgOptions.map((img, idx) => {
          const currentImg = pdfInfo.images.find(i=>i.id.toString() === img.pageId.toString())
          const inUse = currentImg && currentImg.imageStatus && currentImg.imageStatus.inUse;
          const isLast = imgOptions.length === (idx+1)
          return (
            <Ref innerRef={[`${img.text}`]} key={idx}>
                <Popup
                    open={popupOpenFlag&&this.state.openImagePop&&this.state.openImagePop===img.text+'-body-'+img.key}
                    trigger={
                        <Table.Row
                        id={img.text+'-body-'+img.key}
                        className={img.selected ? `mui-popup-table-list-selected-row`:`mui-popup-table-list`}
                        onMouseMove={(e)=>{this.handlePopupMove(e,img.text+'-body-'+img.key)}}
                        onMouseEnter={() => this.handlePopupEnter(img.text+'-body-'+img.key)}
                        onMouseLeave={() => this.handlePopupLeave(img.text+'-body-'+img.key)}
                        onClick={(e)=>{this.handleImageClick(e,img)}}>
                            <Table.Cell className={`mui-popup-table-header-select-cell ${isLast?'repredict-table-td-bottom-left-border':''}`}>
                                <div className="mui-popup-table-child1 noJump" 
                                    onMouseEnter={() => this.setState({popupOpenFlag: false})}
                                    onMouseLeave={() => this.setState({popupOpenFlag: true})}
                                    onClick={()=>this.handleCheckboxChange(idx)}>
                                    <Checkbox className="noJump" id={idx} key={idx} checked={img.selected}/>
                                </div>
                            </Table.Cell>
                            <Table.Cell  className="mui-popup-table-child2">
                                <span className="repredict-table-cell-text">{img.text}</span>
                            </Table.Cell>
                            <Table.Cell className="mui-popup-table-child4">
                                <span className="repredict-table-cell-text">{img.pageType}</span>
                                {img.pageType !== pageType&& <span className="reread-pageType-stype"> [分類変更]</span>}
                            </Table.Cell>
                            <Table.Cell className={`mui-popup-table-child3 mui-popup-table-child3-align ${isLast?'repredict-table-td-bottom-right-border':''}`}>
                                {inUse?
                                    <BadgeInuseIcon/>
                                    :
                                    <div className="repredict-unuse-div">読取未済</div>
                                }
                            </Table.Cell>
                        </Table.Row>
                    }
                    position="top center"
                    content={'表示ページを切替'}
                    on="hover"
                    wide='very'
                    hideOnScroll
                    inverted
                    hoverable
                    mouseEnterDelay={1000}
                    offset={this.state.popupOffset?this.state.popupOffset:[0,0]}
                    style={{zIndex: 2100}}
                    
                />
            </Ref>
          );
        });
      };
      renderViceRow = () =>{
        const { imgOtherOptions, imgOptions, popupOpenFlag } = this.state;
    
        return imgOtherOptions.map((img, idx) => {
          const isSelected = imgOptions.find((imgOption)=> imgOption.key === img.key)
          return (
            <Ref innerRef={[`${img.key}`]} key={img.text}>
                <Popup
                    trigger={
                        <Table.Row
                            id={img.text+'-vice-'+img.key}
                            className={`file-reread-confirm-table-tr`}
                            onClick={(e)=>{this.handleImageClick(e,img)}}
                            onMouseMove={(e)=>{this.handlePopupMove(e,img.text+'-vice-'+img.key)}}
                            onMouseEnter={() => this.handlePopupEnter(img.text+'-vice-'+img.key)}
                            onMouseLeave={() => this.handlePopupLeave(img.text+'-vice-'+img.key)}
                            >
                                <Table.Cell verticalAlign="middle" className="reread-vice-table-td-padding">
                                    <div className="file-reread-confirm-table-td-one">
                                        <span className="file-reread-vice-table-td-one-font">{img.text}</span>
                                    </div> 
                                </Table.Cell>
                                <Table.Cell verticalAlign="middle" style={{width:'100%'}} className="reread-vice-table-td-padding">
                                    <span className="file-reread-confirm-table-td-two">{img.pageType}</span>
                                </Table.Cell>
                                <Table.Cell className="file-reread-vice-table-td-three">
                                    <span 
                                    className={isSelected?"mui-popup-svg-icon-disabled file-reread-confirm-table-td-add-span-disabled":"mui-popup-svg-icon"}
                                    onClick={() =>{
                                        if(!imgOptions.find((imgOption)=> imgOption.key === img.key)){
                                            const cloneOptions = _.cloneDeep(imgOptions);
                                            img.selected = true;
                                            cloneOptions.push(img);
                                            cloneOptions.sort(function (a, b) {
                                                return (Number(a.pageId) < Number(b.pageId)) ? -1 : 1;
                                            })
                                            var num = 0;
                                            var indeterFlg = false;
                                            cloneOptions.forEach((cloneImgOption) => {
                                                if(cloneImgOption.selected){
                                                    num++;
                                                }
                                            })
                                            if(num > 0 && num < cloneOptions.length){
                                                indeterFlg = true;
                                            }
                                            this.setState({imgOptions:cloneOptions, errorFlag:false,indeterFlg: indeterFlg})
                                        }
                                    }}
                                    onMouseOver={() => this.setState({popupOpenFlag: false})}
                                    onMouseLeave={() => this.setState({popupOpenFlag: true})}
                                    >
                                        <AddIcon size='24px' className={isSelected?"file-reread-confirm-table-td-add-icon-disabled":""}/>
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                    }
                    position="top center"
                    content={'表示ページを切替'}
                    on="hover"
                    open={popupOpenFlag&&this.state.openImagePop&&this.state.openImagePop===img.text+'-vice-'+img.key}
                    wide='very'
                    hideOnScroll
                    inverted
                    mouseEnterDelay={1000}
                    offset={this.state.popupOffset?this.state.popupOffset:[0,0]}
                    style={{zIndex: 2100}}
                />
            </Ref>
          );
        });
      }
      renderFrameRow = () =>{
        const { imgFrameOptions, pageType, popupOpenFlag } = this.state;
        return imgFrameOptions.map((img, idx) => {
          return (
            <Ref innerRef={[`${img.key}`]} key={img.text}>
                <Popup
                    open={popupOpenFlag&&this.state.openImagePop&&this.state.openImagePop===img.text+'-frame-'+img.key}
                    trigger={
                        <Table.Row
                            id={img.text+'-frame-'+img.key}
                            className={`file-reread-confirm-table-tr`}
                            onMouseMove={(e)=>{this.handlePopupMove(e,img.text+'-frame-'+img.key)}}
                            onMouseEnter={(e) => this.handlePopupEnter(img.text+'-frame-'+img.key)}
                            onMouseLeave={() => this.handlePopupLeave(img.text+'-frame-'+img.key)}
                            onClick={(e)=>{this.handleImageClick(e,img)}}
                            >
                            <Table.Cell verticalAlign="middle">
                                <div className="file-reread-confirm-table-td-one">
                                    <span className="file-reread-confirm-table-td-one-font">{img.text}</span>
                                </div> 
                            </Table.Cell>
                            <Table.Cell verticalAlign="middle" style={{width:'100%'}}>
                                <span className="file-reread-confirm-table-td-two">{img.pageType}</span>
                                {img.pageType !== pageType&& <span className="reread-pageType-stype"> [分類変更]</span>}
                            </Table.Cell>
                        </Table.Row>
                    }
                    position="top center"
                    content={'表示ページを切替'}
                    on="hover"
                    wide='very'
                    hideOnScroll
                    inverted
                    mouseEnterDelay={1000}
                    offset={this.state.popupOffset?this.state.popupOffset:[0,0]}
                    style={{zIndex: 2100}}
                />
            </Ref>
          );
        });
      }
      renderDisconnectRow = () =>{
        const { disconnectOptions, popupOpenFlag } = this.state;
        return disconnectOptions.map((img, idx) => {
          return (
            <Ref innerRef={[`${img.key}`]} key={img.text}>
                <Popup
                    open={popupOpenFlag&&this.state.openImagePop&&this.state.openImagePop===img.text+'-frame-'+img.key}
                    trigger={
                        <Table.Row
                            id={img.text+'-frame-'+img.key}
                            className={`file-reread-confirm-table-tr`}
                            onMouseMove={(e)=>{this.handlePopupMove(e,img.text+'-frame-'+img.key)}}
                            onMouseEnter={() => this.handlePopupEnter(img.text+'-frame-'+img.key)}
                            onMouseLeave={() => this.handlePopupLeave(img.text+'-frame-'+img.key)}
                            onClick={(e)=>{this.handleImageClick(e,img)}}
                            >
                            <Table.Cell verticalAlign="middle">
                                <div className="file-reread-confirm-table-td-one">
                                    <span className="file-reread-confirm-table-td-one-font">{img.text}</span>
                                </div> 
                            </Table.Cell>
                            <Table.Cell verticalAlign="middle" style={{width:'100%'}}>
                                <span className="file-reread-confirm-table-td-two">{img.pageType}</span>
                            </Table.Cell>
                        </Table.Row>
                    }
                    position="top center"
                    content={'表示ページを切替'}
                    on="hover"
                    wide='very'
                    hideOnScroll
                    inverted
                    mouseEnterDelay={1000}
                    offset={this.state.popupOffset?this.state.popupOffset:[0,0]}
                    style={{zIndex: 2100}}
                />
            </Ref>
          );
        });
      }
      rotateDialog = () => {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className='custom-ui-rotation-body'>
                <div>
                    <label className="custom-ui-rotation-content-text custom-ui-text-margin">処理をキャンセルします。<br/>よろしいですか？</label> 
                    <br/><br/>
                    <label className="custom-ui-rotation-content-text custom-ui-text-margin">【注意】</label>
                    <ul className="close-ui-rotation-ul custom-ui-text-margin">
                        <li>分類変更がある場合、キャンセルすると変更は適用されません</li>
                        <li>他のページも同時に再読取する場合は「さらにページ追加」より対象ページを追加してください</li>
                    </ul>
                </div>
                <div className='custom-ui-confirm-alert-button-group custom-ui-button-margin' style={{width:'100%'}}>
                  <button
                    className='custom-ui-rotation-button predict-admin-botton-text rotationAlert-button-left'
                    onClick={onClose}>
                    いいえ
                  </button>
                  <button
                    className='custom-ui-rotation-button primary-btn rotationAlert-button-right'
                    onClick={() => {
                        this.setState({
                            confirmFlg: false
                        })
                        this.props.toggleEdit(false);
                        onClose();
                    }}>
                    {intl.get('_predict.detail.pdf.Canvas.OK')}
                  </button>
                </div>
              </div>
            );
          }
        });
      }
    
    render() {
        const { isMultiCol, pageType, confirmFlg, 
            clsOptions, showVice, isAIAuto, errorFlag, isHover, 
            imgOtherOptions, disconnectShow, frameShow, imgOptions, hasBbox } = this.state;
        const { isSelectTargetBbox, pdfInfo, pdfFileId, projectId } = this.props;
        const isShowMultiCol = clsOptions.find(e=>e === pageType)
        const viceDateFlag = imgOtherOptions.length > 0;

        var nextDisabled = false;
        // const imgDisconnectOptions = imgOptions.filter(imgOption => !imgOption.selected&&imgOption.pageType === pageType);
        const otherTypeOptions = imgOptions.filter(imgOption => imgOption.selected&&imgOption.pageType !== pageType);
        if(otherTypeOptions.length === 0){
            const inUseImages = pdfInfo.images.filter(i=> pageType === i.pageClassification && i.imageStatus && i.imageStatus.inUse )
            const selectedImages = imgOptions.filter(imgOption => imgOption.selected)
            const changeInuseImages = inUseImages.filter(i => !selectedImages.find(imgOption => imgOption.pageId === i.id.toString()))
            const otherTemplateImages = inUseImages.filter(i=> i.imageTemplate !== 1)
            const selectedNotUsedImages = selectedImages.filter(i => !inUseImages.find(imgOption =>imgOption.id.toString() === i.pageId.toString()))
            //all selected image is current type inuse image
            //all selected image is template 1
            if(changeInuseImages.length === 0 && otherTemplateImages.length === 0 && selectedNotUsedImages.length === 0){
                nextDisabled = true;
            }
        }

        if(isSelectTargetBbox){
            return (
                <Toasts
                    isOpen={isSelectTargetBbox}
                    show={isSelectTargetBbox}
                    handlePortalClose={()=>{
                        this.handlePortalClose();
                        this.context.sendMetrics({
                            page_name: PageName.PredictDetailPage,
                            event_type: EventType.Click,
                            event_name: EventName.RereadLineselectCancel,
                            custom_parameter: {
                                pdf_id : pdfFileId,
                                project_id : projectId,
                            },
                        })
                    }}
                    content={'↓   読み取りたい列の金額を１つ選択してください'}
                    classNames="reread-snackbar"
                />
            )
        }
        return createPortal(
            <div style={{ cursor: 'default' }} className={"model-repredict-contain-div"} onDragOver={this.dragover}  onDrop={this.drop}  onDragEnd={this.dragend} >
                <MDBModal
                    isOpen={this.props.isRePredictShow}
                    toggle={this.rotateDialog}
                    centered={true}
                    size={'fluid'}
                    fade={false}
                    overflowScroll={false}
                    className={"model-repredict-set-center model-repredict-set-center-middle"}
                    contentClassName={"repredict-reread-content"}
                >
                    {confirmFlg && <MDBModalBody className="file-reread-body" onDragStart={this.dragstart} draggable={true}>
                        <div className="reread-dialog-top-bar"></div>
                        <div className="file-user-set-title">
                            <label className="mui_popup_title">{'再読取（内容の確認）'}</label>
                            <button className="mui_popup_pageType_background">
                                <label className="mui_popup_pageType">{pageType}</label>
                            </button>
                        </div>
                        <div className="file-reread-confirm-body">
                            <div><span className='file-reread-message'>以下の内容で <span className='file-reread-messagePageType'>[{pageType}]</span> の再読取を実行します。</span></div>
                            {frameShow&&<div className="file-reread-confirm-table">
                                <div className='text_icon_div'>
                                    <FrameIcon size='24px' className="repredict-icon-cursor" />
                                    <span className='confirm-title'>対象ページ</span>
                                </div>
                                <div className="confirm-frame-table-div">
                                    <Table className="file-reread-confirm-table-margin value-table-wrapper" unstackable>
                                        <Table.Body style={{ height: '100%' }}>
                                        {this.renderFrameRow()}
                                        </Table.Body>
                                    </Table>
                                </div>
                            </div>}
                            {disconnectShow&&<div className="file-reread-confirm-table">
                                <div className='text_icon_div'>
                                    <DisconnectIcon size='20px' className="repredict-icon-cursor" />
                                    <span className='confirm-title'>連携をすべて解除するページ</span>
                                </div>
                                <div className="confirm-disconnect-table-div">
                                    <Table className="file-reread-confirm-table-margin value-table-wrapper" unstackable>
                                        <Table.Body style={{ height: '100%' }}>
                                        {this.renderDisconnectRow()}
                                        </Table.Body>
                                    </Table>
                                </div>
                            </div>}
                        </div>
                        <div>
                            {(isMultiCol||isAIAuto)&&<div className="file-reread-body-check">
                                <label className="repredic-table-opt-label">列指定オプション</label>
                                {isMultiCol&&<div className="multiColLine">
                                    <label htmlFor='confirm'>
                                        <span className='file-check-confirm-text'>手動で１列指定（複数期記載の場合等）</span>
                                    </label>
                                </div>
                                }
                                {isAIAuto&&
                                <div className="multiColLine">
                                    <label htmlFor='confirmAI'>
                                        <span className='file-check-confirm-text'>AIが自動で１列指定（複数期記載の場合等）</span>
                                    </label>
                                </div>
                                }
                            </div>}
                        </div>
                        <div className='custom-ui-confirm-alert-button-group repredict-dialog-bottom-button-div'>
                            <Button
                            className='primary-btn confirmAlert-button-right'
                            style={{marginRight:'0px'}}
                            onClick={()=>{
                                this.handleRePredict();
                                const originPageType = []
                                imgOptions.forEach( e => {
                                    if (e.selected) {
                                        originPageType.push({
                                            page_num : e.text, 
                                            before_document_type :e.pageType 
                                        })
                                    }
                                })
                                this.context.sendMetrics({
                                    page_name: PageName.PredictDetailPage,
                                    event_type: EventType.Click,
                                    event_name: EventName.RereadStart,
                                    custom_parameter: {
                                        pdf_id : pdfFileId,
                                        project_id : projectId,
                                        document_type : pageType,
                                        origin_page_type : originPageType,
                                    },
                                })
                            }}
                            >{'再読取を実行'}
                            </Button>
                            <button
                                className='predict-admin-botton-text confirmAlert-button-left'
                                onClick={() => {
                                    this.setState({confirmFlg: false });
                                    this.props.handleRePredictSelectBbox(false);
                                    this.context.sendMetrics({
                                        page_name: PageName.PredictDetailPage,
                                        event_type: EventType.Click,
                                        event_name: EventName.RereadConfirmBack,
                                        custom_parameter: {
                                            pdf_id : pdfFileId,
                                            project_id : projectId,
                                        },
                                    })
                                }
                            }>
                                {'戻る'}
                            </button>
                        </div>
                    </MDBModalBody>
                    }
                    {!confirmFlg &&<MDBModalBody className="file-reread-body" onDragStart={this.dragstart} draggable={true}>
                        <div className="reread-dialog-top-bar"></div>
                        <div className="file-user-set-title">
                            <label className="mui_popup_title">{'再読取'}</label>
                            <button className="mui_popup_pageType_background">
                                <label className="mui_popup_pageType">{pageType}</label>
                            </button>
                            <Popup
                                trigger={
                                <span>
                                    <VectorIcon size='16.801px'  color="#AAAAAA" className="icon_hover"/>
                                </span>
                                }
                                position="right center"
                                className="reread_point_popup_background"
                                content={
                                    <div>
                                        <div><label className="reread_point_popup_title">{'再読取'}</label></div>
                                        <div className="reread_point_popup_body">
                                            <label style={{marginBottom:'0'}}>{'表示中のページおよび同じ分類のページを再読取し自動連携します。'}</label>
                                            <label>{'以下のようなケースで活用できます。'}</label>
                                            <ul className="reread_point_popup_content">
                                                <li>ページの分類や向きを補正したい時</li>
                                                <li>アップロード時に読取対象としなかったページを読取対象へ変更したい時</li>
                                                <li>「複数期分のページ」が自動連携されてしまった場合等、入力不要なページを連携解除したい時（対象ページのチェックを外す）</li>
                                                <li>１ページ内に「複数期分の情報」や「構成比」等の入力不要な列が混在する場合（列指定オプションにチェックをいれる）</li>
                                            </ul>
                                            <ul className="reread_point_popup_mark_ul">
                                                <li className="reread_point_popup_mark_li" >上記以外のケースでは、再読取を実行しても前回読取時と同じ結果となる可能性があります</li>
                                                <li className="reread_point_popup_mark_li" >現在は [株主資本等変動計算書] のみ列指定オプションを使用できません</li>
                                            </ul>
                                        </div>
                                    </div>
                                }
                                on="hover"
                                wide='very'
                                hideOnScroll
                                style={{zIndex: 2100}}
                            />
                        </div>

                        <div style={{gap:"16px"}}>
                            <div style={{gap:"8px"}}>
                                <div className='file-reread-body-inner' style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                                    <label className='reread-dialog-target-label'>対象ページを選択</label>
                                </div>
                                <div>
                                    <div>
                                        <Table className="value-table-wrapper reread-table-margin" unstackable>
                                            <Table.Header className="value-table-header">
                                            {this.renderHeader()}
                                            </Table.Header>
                                        </Table>
                                    </div>
                                    <div className="reread-table-div">
                                        <Table className="value-table-wrapper reread-table-margin" unstackable>
                                            <Table.Body style={{ height: '100%' }}>
                                            {this.renderRow()}
                                            </Table.Body>
                                        </Table>
                                    </div>
                                </div>
                                {!showVice&&<div className='repredict-add-image-title' style={{ textAlign: '-webkit-right'}}>
                                    <div style={{width: '150px'}} 
                                        onMouseOver={() =>{this.setState({isHover : true})}}
                                        onMouseLeave={() =>{this.setState({isHover : false})}}
                                        onClick={() =>{this.setState({showVice : true, isHover : false})}}
                                        >
                                        <span className='page-expand-or-hide' style={{ color: isHover?'#0072C6':'#212121' }}>さらにページを追加</span>
                                        <ExpandMoreDownIcon  width='24px' height='16px' color={isHover?'#0072C6':'#AAAAAA'}/>
                                    </div>
                                </div>}
                                {showVice&&<div className='repredict-add-image-title' style={{ textAlign: '-webkit-right'}}>
                                    <div style={{width: '150px'}} 
                                        onMouseOver={() =>{this.setState({isHover : true})}}
                                        onMouseLeave={() =>{this.setState({isHover : false})}}
                                        onClick={() =>{this.setState({showVice : false, isHover : false})}}
                                    >
                                        <span className='page-expand-or-hide' style={{ color: isHover?'#0072C6':'#212121' }}>追加リストを隠す</span>
                                        <ExpandMoreUpIcon  width='24px' height='16px' color={isHover?'#0072C6':'#AAAAAA'}/>
                                    </div>
                                </div>}
                                {showVice&&<div className="vice-table-div vice-table-div-middle">
                                    {viceDateFlag&&
                                        <Table className="value-table-wrapper reread-table-margin reread-table-border" unstackable>
                                            <Table.Body style={{ height: '100%' }}>
                                            {this.renderViceRow()}
                                            </Table.Body>
                                        </Table>
                                    }
                                    {!viceDateFlag&&
                                        <Message
                                            className="viceNoDataMessage"
                                            content="追加できるページがありません"
                                        />
                                    }
                                </div>}
                            </div>
                            {isShowMultiCol&&<div className="file-reread-body-check">
                                <label className="repredic-table-opt-label">列指定オプション</label>
                                {hasBbox&&<div className="multiColLine">
                                    <Checkbox key="confirm" id="confirm" checked={isMultiCol} onChange={this.changeMultiCol}/>
                                    <label htmlFor='confirm'>
                                        <span className='repredict-image-check-text'>手動で１列指定（複数期記載の場合等）</span>
                                    </label>
                                    <Popup
                                    trigger={
                                        <span>
                                            <VectorIcon size='16.801px' color="#AAAAAA" className="icon_hover"/>
                                        </span>
                                    }
                                    position="right center"
                                    className="reread_point_popup_background"
                                    content={
                                        <div>
                                            <figure style={{ textAlign: 'center'}}>
                                                <img src={colValueSelectGif} alt="value select" style={{width: '300px'}} className="reread_point_popup_title_img"/>
                                            </figure>
                                            <div><label className="reread_point_popup_title">{'手動で１列指定'}</label></div>
                                            <div className="reread_point_popup_body">
                                                <label>{'１ページ内に「複数期分の情報」や「構成比」等の入力不要な列が混在する場合にチェックをいれてください。次のステップで必要な列を指定することができます'}</label>
                                                <ul className="reread_point_popup_mark_ul" style={{alignItems:'inherit',paddingLeft: '14px'}}>
                                                    <li className="reread_point_popup_mark_li" >{'指定できるのは一列のみです'}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                    on="hover"
                                    wide='very'
                                    hideOnScroll
                                    style={{zIndex: 2100}}
                                    />
                                </div>
                                }
                                {!hasBbox&&
                                <div className="multiColLine">
                                    <Checkbox key="confirmAI" id="confirmAI" checked={isAIAuto} onChange={this.changeAIAuto}/>
                                    <label htmlFor='confirmAI'>
                                        <span className='repredict-image-check-text'>AIが自動で１列指定（複数期記載の場合等）</span>
                                    </label>
                                    <Popup
                                    trigger={
                                        <span>
                                            <VectorIcon size='16.801px' color="#AAAAAA" className="icon_hover"/>
                                        </span>
                                    }
                                    position="right center"
                                    className="reread_point_popup_background"
                                    content={
                                        <div>
                                            <div><label className="reread_point_popup_title" style={{lineHeight: "125%"}}>{'AIが自動で１列指定'}</label></div>
                                            <div className="reread_point_popup_body">
                                                <label>{'１ページ内に「複数期分の情報」や「構成比」等の入力不要な列が混在する場合にチェックをいれてください。AIが必要な列を判別して自動連携します。'}</label>
                                                <ul className="reread_point_popup_mark_ul" style={{alignItems:'inherit',paddingLeft: '14px'}}>
                                                    <li className="reread_point_popup_mark_li" >{'AIによって自動連携できるのは一列のみです'}</li>
                                                    <li className="reread_point_popup_mark_li" >{'対象ページに選択できる金額が含まれていないため手動での列指定は行えません'}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                    on="hover"
                                    wide='very'
                                    hideOnScroll
                                    style={{zIndex: 2100}}
                                    />
                                </div>
                                }
                            </div>
                            }
                        </div>
                        {errorFlag&&<div className="text_icon_div">
                            <ErrorIcon size="24px" color="#F26868" className="repredict-icon-cursor"/>
                            <label htmlFor='error'>
                                <span className='error-text'>対象ページを選択してください</span>
                            </label>
                        </div>}
                        {!isMultiCol && !isAIAuto && nextDisabled&&<div className="text_icon_div" style={{alignItems: 'flex-start'}}>
                            <ErrorIcon size="24px" color="#F26868" className="repredict-icon-cursor" />
                            <label htmlFor='error'>
                                <span className='error-text'>再読取を実行するには以下いずれかの操作が必要です</span>
                                <ul className="error-text" style={{paddingLeft: '20px', marginBottom: '0px'}}>
                                    <li>ページの分類または向きの変更</li>
                                    <li>[対象ページ]の変更</li>
                                    <li>[列指定オプション]の選択</li>
                                </ul>
                            </label>
                        </div>}
                        <div className='custom-ui-confirm-alert-button-group repredict-dialog-bottom-button-div'>
                            <Button
                                className='primary-btn confirmAlert-button-right'
                                style={{ marginRight: '0px' }}
                                onClick={()=>{
                                    this.handleOK()
                                    // user action
                                    this.context.sendMetrics({
                                        page_name: PageName.PredictDetailPage,
                                        event_type: EventType.Click,
                                        event_name: EventName.RereadNext,
                                        custom_parameter: {
                                            pdf_id : pdfFileId,
                                            project_id : projectId,
                                        },
                                    })
                                }}
                                disabled={errorFlag|| (!isMultiCol && !isAIAuto && nextDisabled)}
                            >{'次へ'}
                            </Button>
                            <button
                                className='predict-admin-botton-text confirmAlert-button-left'
                                onClick={() => {
                                   this.rotateDialog();
                                   // user action
                                   this.context.sendMetrics({
                                    page_name: PageName.PredictDetailPage,
                                    event_type: EventType.Click,
                                    event_name: EventName.RereadCancel,
                                    custom_parameter: {
                                        pdf_id : pdfFileId,
                                        project_id : projectId,
                                    },
                                })
                                }}>
                                {intl.get('_predict.detail.mapping.Close')}
                            </button>
                        </div>
                    </MDBModalBody>
                    }
                    
                </MDBModal>
            </div>
        ,document.body)
    }
}