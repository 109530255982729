import React from 'react';

import { Segment, Button, Icon, Loader } from 'semantic-ui-react';
import { MDBDataTable } from 'mdbreact';
import api from '../../api';
import { confirmAlert } from 'react-confirm-alert';
import ProjectMetadataEdit from './ProjectMetadataEdit';
import ImportMetadata from './ImportMetadata';
import intl from 'react-intl-universal';
import { getCsrfToken } from '../../common/CookieUtil';

export default class ProjectMetadataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      rows: [],
      isShowEdit: false,
      isShowImport: false,
      projectMetadata: {},
    };

    this.toggleEdit = this.toggleEdit.bind(this);
    this.toggleImport = this.toggleImport.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleImport = this.handleImport.bind(this);
    this.handleDel = this.handleDel.bind(this);
    this.getProjectMetadatas = this.getProjectMetadatas.bind(this);
    this.handleExport = this.handleExport.bind(this);
  }

  async componentDidMount() {
    await this.getProjectMetadatas();
  }

  async getProjectMetadatas() {
    this.setState({ isLoaded: false });
    const { projectId, tenantId, isSystemManager } = this.props;
    try {
      let baseUrl;
      if (isSystemManager) {
        baseUrl = `/dppapi/system/tenant-manage/project/metadata/query?projectId=${projectId}&tenantId=${tenantId}`;
      } else {
        baseUrl = `/dppapi/admin/project/metadata/query?projectId=${projectId}`;
      }
      const { metadatas } = (await api.get(baseUrl)).data;

      this.setState({
        rows: metadatas.map((metadata, index) => {
          return {
            ...metadata,
            num: index + 1,
            category: metadata.category,
            subCategory: metadata.subCategory,
            item: metadata.item,
            value: metadata.value,
            operation: (
              <div>
                <Button icon="edit" label={intl.get('_project.metadata.ProjectMetadataTable.Edit')} size="tiny" onClick={() => this.handleEdit(metadata)}/>
                <Button icon="trash" label={intl.get('_project.metadata.ProjectMetadataTable.Delete')} size="tiny" onClick={() => this.handleDel(metadata)}/>
              </div>
            )
          }
        }),
        isLoaded: true,
      });
    } catch (error) {
      this.setState({
        error,
        isLoaded: true,
      });
    }
  }

  handleEdit(metadata) {
    this.toggleEdit({
      isShowEdit: true,
      projectMetadata: metadata
    });
  }

  handleImport() {
    this.toggleImport({
      isShowImport: true
    });
  }

  handleDel(metadata) {
    const { projectId, tenantId, isSystemManager } = this.props;
    confirmAlert({
      title: intl.get('_project.metadata.ProjectMetadataTable.Delete'),
      message: intl.get('_project.metadata.ProjectMetadataTable.Do you want to delete it?'),
      buttons: [
        {
          label: intl.get('_project.metadata.ProjectMetadataTable.Delete'),
          onClick: async () => {
            try {
              let baseUrl;
              if (isSystemManager) {
                baseUrl = `/dppapi/system/tenant-manage/project/metadata/delete?projectId=${projectId}&tenantId=${tenantId}`;
              } else {
                baseUrl = `/dppapi/admin/project/metadata/delete?projectId=${projectId}`;
              }
              await api.post(baseUrl, { ids: [metadata.id] });
              this.getProjectMetadatas();
            } catch (error) {
              this.setState({
                error
              });
            }
          },
        },
        {
          label: intl.get('_project.metadata.ProjectMetadataTable.Cancel'),
        },
      ],
    });
  }

  async handleExport() {
    const { projectId, tenantId, isSystemManager } = this.props;
    confirmAlert({
      message: intl.get('_project.metadata.ProjectMetadataTable.Do you want to get metadata?'),
      buttons: [
        {
          label: intl.get('_project.metadata.ProjectMetadataTable.Download'),
          onClick: async () => {
            let a = document.createElement('a');
            let baseUrl;
            if (isSystemManager) {
              baseUrl = `/dppapi/system/tenant-manage/project/metadata/export?projectId=${projectId}&token=${getCsrfToken()}&tenantId=${tenantId}`;
            } else {
              baseUrl = `/dppapi/admin/project/metadata/export?projectId=${projectId}&token=${getCsrfToken()}`;
            }
            a.href = baseUrl;
            a.click();
            a.remove();
          },
        },
        {
          label: intl.get('_project.metadata.ProjectMetadataTable.Cancel'),
        },
      ],
    })
  }

  toggleEdit({ isShowEdit, projectMetadata }) {
    this.setState({
      isShowEdit: isShowEdit,
      projectMetadata: projectMetadata || {},
    });
  }

  toggleImport({ isShowImport }) {
    this.setState({
      isShowImport: isShowImport
    });
  }

  render() {
    const  { rows, error, isLoaded } = this.state;
    const columns = [
      {
        label: intl.get('_project.metadata.ProjectMetadataTable.Num'),
        field: 'num',
        sort: 'asc',
      },
      {
        label: intl.get('_project.metadata.ProjectMetadataTable.Category'),
        field: 'category',
        sort: 'asc',
      },
      {
        label: intl.get('_project.metadata.ProjectMetadataTable.SubCategory'),
        field: 'subCategory',
        sort: 'asc',
      },
      {
        label: intl.get('_project.metadata.ProjectMetadataTable.Item'),
        field: 'item',
        sort: 'asc',
      },
      {
        label: intl.get('_project.metadata.ProjectMetadataTable.Value'),
        field: 'value',
        sort: 'asc',
      },
      {
        label: intl.get('_project.metadata.ProjectMetadataTable.Operation'),
        field: 'operation',
        sort: 'asc',
      },
    ];
    const data = {
      columns: columns,
      rows: rows,
    };

    if (error) {
      return <div>Error: {error.data.errmsg}</div>;
    } else if (!isLoaded) {
      return <Loader active inline="centered" />;
    }

    return (
      <Segment>
        <Button.Group floated="right" style={{ marginLeft: '2px' }}>
          <Button icon onClick={this.handleExport}>
            <Icon name="download" />
          </Button>
        </Button.Group>
        <Button.Group floated="right" style={{ marginLeft: '2px' }}>
          <Button icon onClick={this.handleImport}>
            <Icon name="file" />
          </Button>
        </Button.Group>
        <Button.Group floated="right" style={{ marginLeft: '2px' }}>
          <Button icon onClick={() => this.handleEdit({})}>
            <Icon name="add" />
          </Button>
        </Button.Group>
        <MDBDataTable theadColor="common-search-table-header" btn striped bordered hover data={data} />
        <ProjectMetadataEdit
          isShowEdit={this.state.isShowEdit}
          projectId={this.props.projectId}
          tenantId={this.props.tenantId}
          projectMetadata={this.state.projectMetadata}
          toggleEdit={this.toggleEdit}
          getProjectMetadatas={this.getProjectMetadatas}
          isSystemManager={this.props.isSystemManager}
        />
        <ImportMetadata
          isShowImport={this.state.isShowImport}
          projectId={this.props.projectId}
          tenantId={this.props.tenantId}
          toggleImport={this.toggleImport}
          getProjectMetadatas={this.getProjectMetadatas}
        />
      </Segment>
    );
  }
}
