import React, { Component } from 'react';
import api from '../../api';
import ActionBar from './ActionBar';
import FsItemTable from './FsItemTable';
import FsItemEdit from './FsItemEdit';
import FsItemImport from './FsItemImport';

class FsItemMappingGlobal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editFsItem: null,
      fsItemForm: {},
      fsItemRslt: [],
      page: {
        pageNo: 1,
        pageCount: 1,
      },
      isShowEdit: false,
      isShowImport: false,
    };
  }

  async componentDidMount() {}

  handleSearch = async (pageNo = 1, limit = 10) => {
    const { category = '', subTitle = '', item = '', globalResultItem = '' } = this.state.fsItemForm;
    const { data } = await api.get(
      `/dppapi/system/global-preset?category=${category}&subTitle=${subTitle}&item=${item}&globalResultItem=${globalResultItem}&pageNo=${pageNo}&limit=${limit}`
    );
    this.setFsItemRslt(data.list);
    this.setPage(data.page);
  };

  setFsItemForm = (fsItemForm) => {
    this.setState({
      fsItemForm: { ...this.state.fsItemForm, ...fsItemForm },
    });
  };

  setFsItemRslt = (fsItemRslt) => {
    this.setState({
      fsItemRslt: fsItemRslt,
    });
  };

  setPage = (page) => {
    this.setState({
      page: page,
    });
  };

  toggleEdit = (isShowEdit, fsItem) => {
    this.setState({
      isShowEdit: isShowEdit,
      editFsItem: fsItem || null
    });
  };

  toggleImport = (isShowImport) => {
    this.setState({
      isShowImport: isShowImport,
    });
  };

  render() {
    const { fsItemRslt, page, isShowEdit, isShowImport, editFsItem } = this.state;
    return (
      <div className="ui">
        <ActionBar
          setFsItemRslt={this.setFsItemRslt}
          setPage={this.setPage}
          setFsItemForm={this.setFsItemForm}
          handleSearch={this.handleSearch}
          toggleEdit={this.toggleEdit}
          toggleImport={this.toggleImport}
        />
        <FsItemTable
          fsItemRslt={fsItemRslt}
          page={page}
          setFsItemRslt={this.setFsItemRslt}
          handleSearch={this.handleSearch}
          toggleEdit={this.toggleEdit}
        />
        <FsItemEdit
          editFsItem={editFsItem}
          isShowEdit={isShowEdit}
          toggleEdit={this.toggleEdit}
          handleSearch={this.handleSearch}
        />
        <FsItemImport
          isShowImport={isShowImport}
          toggleImport={this.toggleImport}
          handleSearch={this.handleSearch}
        />
      </div>
    );
  }
}

export default FsItemMappingGlobal;
