import React, { Component } from 'react';

import Menubar from '../common/Menubar';

import DemoHome from './DemoHome';

class DemoApp extends Component {
  render() {
    return (
      <Menubar active="demo">
        <DemoHome/>
      </Menubar>
    );
  }
}

export default DemoApp;
