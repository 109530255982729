import React from 'react';
import { withRouter } from 'react-router-dom';
import intl from 'react-intl-universal';
import UserRetrievePage from './UserRetrievePage';

import {
  Button,
  Form,
  Input,
  Header,
  Loader,
} from 'semantic-ui-react';
import api from '../api';
import { ErrorIcon} from '../assets/icon';
import Hotkeys from 'react-hot-keys';
class ChangePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: {
        newPwd: '',
        reNewPwd: '',
      },
      errors: {},
      error: null,
      tenantPreference: { password_rule: null},
      passRuleMatch: false,
      reload: false,
    };

    this.handleInput = this.handleInput.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
    this.checkForm = this.checkForm.bind(this);
    this.handleRetrieveAgain = this.handleRetrieveAgain.bind(this);
  }

  async componentDidMount() {
    await this.handleTokenCheck();
    this.setState({reload: true});
    await this.handleGetTenantPreference({
      category: 'configure',
      subCategory: 'nodejs',
      name: 'password',
      subName: 'rule',
    });
    await this.handleGetTenantPreference({
      category: 'configure',
      subCategory: 'nodejs',
      name: 'password',
      subName: 'rule_tip',
    });
  }

  handleTokenCheck = async () => {
    const tokenMatch = await api.post('/dppapi/retrieve/tokencheck', {
      auth: new URL(window.location.href).searchParams.get('auth'),
    });
    this.setState({
      redirect: (!tokenMatch || !tokenMatch.data || !tokenMatch.data.tokenMatch) ?'userRetrieve':'no'
    })
  }
  handleGetTenantPreference = async param => {
    const res = await api.request({
      url: `/dppapi/retrieve/non-login/tenant-preferences`,
      method: 'POST',
      data: {
        ...param,
        auth: new URL(window.location.href).searchParams.get('auth'),
      },
    });
    if (res.data.success) {
      this.setState(
        {
          tenantPreference: {
            ...this.state.tenantPreference,
            [`${param.name}_${param.subName}`]: res.data.value,
          },
        },
        () => console.log(this.state.tenantPreference)
      );
    }
  };
  async handleRetrieveAgain() {
    this.setState({ loading: true, error: null });
    try {
      const sendEmail = await api.post('/dppapi/retrieve/retrieveAgain', {
        auth: new URL(window.location.href).searchParams.get('auth'),
      });
      if(sendEmail.data.success === true){
        this.setState({ loading: false });
        window.location = `/dppui/login?confirmFlag=2`;
      }
    } catch (e) {
      this.setState({ loading: false, error: e.data.message });
    }
  }

  async handleChange() {
    const password = this.state.password;
    if (!this.checkForm(password)) {
      return;
    }
    this.setState({ loading: true });
    try {
      await api.post('/dppapi/retrieve/change', {
        password: password.newPwd,
        auth: new URL(window.location.href).searchParams.get('auth'),
      });
      this.setState({ loading: false });
      window.location = `/dppui/login?confirmFlag=3`;
    } catch (e) {
      if(e.data.message === '再設定の有効期限を超過しました'){
        this.setState({ loading: false, redirect: 'userRetrieve' });
      }else{
        this.setState({ loading: false, error: e.data.message });
      }
    }
  }

  checkForm(password) {
    const errors = {};

    if (!password.newPwd) {
      errors['newPwd'] = {
        content: intl.get('_login.LoginPage.Input newPwd'),
        pointing: 'below',
        flag: true,
      };
    }

    if (!password.reNewPwd) {
      errors['reNewPwd'] = {
        content: intl.get('_login.LoginPage.Input reNewPwd'),
        pointing: 'below',
        flag: true,
      };
    }

    if (password.reNewPwd !== password.newPwd) {
      errors['reNewPwd'] = {
        content: intl.get('_login.LoginPage.ReNewPwd error'),
        pointing: 'below',
        flag: true,
      };
    }

    if (this.state.tenantPreference['password_rule']) {
      if (!this.passCheck(password.newPwd)) {
        errors['newPwd'] = {
          content: intl.get('_login.LoginPage.NewPwd error'),
          pointing: 'below',
          flag: true,
        };
      }
    }

    this.setState({
      errors: errors,
      error: null
    });
    return Object.keys(errors).length === 0;
  }

  passCheck = pass => {
    let passReg;
    try {
      passReg = new RegExp(this.state.tenantPreference['password_rule']);
    } catch (error) {
      console.log(error);
      return false;
    }
    return passReg.test(pass);
  };

  handleFocus() {
    this.clearErrors();
  }

  clearErrors() {
    this.setState({
      errors: {},
    });
  }

  handleInput(event) {
    const { name, value } = event.target;
    const password = { ...this.state.password };
    password[name] = value;

    if (name === 'newPwd' && this.state.tenantPreference['password_rule']) {
      const match = this.passCheck(value);
      this.setState({ passRuleMatch: match });
    }

    this.setState({
      password: password,
    });
  }

  onKeyDown(keyName, e) {
    if(keyName === 'tab'){
      e.preventDefault();
      const currentTagName = document.activeElement.tagName.toLowerCase();
      const currentElementName = document.activeElement.getAttribute("name");
      const parentElement = document.getElementsByClassName("login-form-confirm");
      let inputElement = parentElement && parentElement[0].querySelectorAll("input");
      let buttonElement = parentElement && parentElement[0].querySelectorAll("button");
      let loginElement = document.getElementsByClassName("footer-form-text");
      if(currentTagName === "body"){
        inputElement[0].focus()
      }else if(currentTagName === "input"){
        if(currentElementName === "newPwd"){
          inputElement[1].focus()
        }else if(currentElementName === "reNewPwd"){
          buttonElement[0].focus()
        }
      }else if(currentTagName === "button"){
        loginElement[0].focus()
      }else if(currentTagName === "p"){
        if(currentElementName === "sendAgain"){
          loginElement[1].focus()
        }else if(currentElementName === "goLogin"){
          inputElement[0].focus()
        }
      }
    }
  }

  render() {
    const { loading, error, errors, redirect } = this.state;
    if(!redirect){
      return <Loader active inline="centered" />;
    }else if(redirect === 'userRetrieve'){
      return (<UserRetrievePage tokenExpired="true"></UserRetrievePage>)
    }
    if(document.getElementsByClassName('login-container-confirm').length > 0){
      document.getElementsByClassName('login-container-confirm')[0].parentNode.classList.remove('container')
    }
    var newPwdErrorClass = errors['newPwd'] && errors['newPwd']['flag'] ? "input-error" : ""
    var reNewPwdErrorClass = errors['reNewPwd'] && errors['reNewPwd']['flag'] ? "input-error" : ""
    return (
      <div className="login-container-confirm">
        <Hotkeys
          keyName="tab"
          onKeyDown={this.onKeyDown.bind(this)}
        />
        <Form className="login-form-confirm">
          <Header className="confirm-from-header">
            {intl.get('_login.LoginPage.PasswordReset')}
          </Header>
          <div className="confirm-form-input-div">
            <label className="confirm-form-input-label">{intl.get('_login.LoginPage.NewPassword')}</label>
            <Form.Field style={{ width: '100%', display: 'inline-block', margin: 'unset' }}>
              <Input
                type="password"
                onChange={this.handleInput}
                onFocus={this.handleFocus}
                name="newPwd"
                className={`${newPwdErrorClass}`}
              />
            </Form.Field>
            <p className="confirm-form-single-error">{errors['newPwd'] ? errors['newPwd'].content:null}</p>
            {this.state.tenantPreference['password_rule'] &&
              this.state.tenantPreference['password_rule_tip'] &&
              <p className="confirm-from-text">
                {this.state.tenantPreference['password_rule_tip']}{' '}
              </p>}
          </div>
          <div className="confirm-form-input-div">
            <label className="confirm-form-input-label">{intl.get('_login.LoginPage.RetypeNewPassword')}</label>
            <Form.Field style={{ width: '100%', display: 'inline-block', margin: 'unset' }}>
              <Input
                type="password"
                onChange={this.handleInput}
                onFocus={this.handleFocus}
                name="reNewPwd"
                className={`${reNewPwdErrorClass}`}
              />
            </Form.Field>
            <p className="confirm-form-single-error">{errors['reNewPwd'] ? errors['reNewPwd'].content:null}</p>
          </div>
          <div>
            <div className="confirm-form-button">
              <Button fluid
                className="login-btn primary-btn confirm-form-button-margin"
                loading={loading}
                disabled={loading}
                secondary={loading}
                onClick={this.handleChange}>{intl.get('_login.LoginPage.Submit')}</Button>
            </div>
            {error && <div className="confirm_icon_div">
              <ErrorIcon size="24px" color="#F26868" className="repredict-icon-cursor"/>
              <label htmlFor='error' className="reset-password-error-lable">
                  <span className='error-text'>{error}</span>
              </label>
            </div>}
          </div>
          <div className="login-footer-form-link">
            <p className="footer-form-text" tabIndex={0} name="sendAgain"
              onKeyDown={(e) => {
                if(e.keyCode === 13){
                  this.handleRetrieveAgain()
                }}}
              onClick={this.handleRetrieveAgain}>
              {intl.get('_login.LoginPage.Retriere Again')}
            </p>
            <p className="footer-form-text" tabIndex={0} name="goLogin"
              onKeyDown={(e) => {
                if(e.keyCode === 13){
                  window.location = `/dppui/login`
                }}}
              onClick={() => { window.location = `/dppui/login` }}>
              {intl.get('_login.LoginPage.Go to login')}
            </p>
          </div>
        </Form>
      </div>
    );
  }
}

export default withRouter(ChangePage);
