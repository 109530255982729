import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import Menubar from '../common/Menubar';
import LabelManagementTable from './LabelManagementTable'
// import LabelPage from './label-management/LabelPage';
import LabelMenu from './LabelMenu';
import LabelingLoader from './LabelingLoader';
import OverScreen from './OverScreen';

export default class LabelHome extends Component {
  render() {
    return (
        <Switch>
          <Route
            exact
            path="/label/"
            render={(props) => (
              <Menubar active="label">
                <LabelManagementTable {...props} />
              </Menubar>
            )}
          />
          <Route exact path="/label/:projectId" component={LabelingLoader} />
          <Route exact path="/label/:projectId/labeled/:labeled" component={LabelingLoader} />
          
          <Route 
            exact 
            path="/label/manage/:projectId" 
            render={(props) => (
              <Menubar active="label">
                <LabelMenu {...props} />
              </Menubar>
            )}
          />
          <Route
            exact
            path="/label/:projectId/:imageId"
            render={(props) =>
              props.match.params.imageId === 'over' ? (
                <OverScreen {...props} />
              ) : (
                <LabelingLoader {...props} />
              )
            }
          />
        </Switch>
    );
  }
}
