import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ReactLogo } from './svg/img_drop-file.svg';
import './icon.css';

export class ImgDropFileIcon extends Component {

  render() {
    const { color, className } = this.props;

    return (
      <ReactLogo
        className={className}
        style={{ fill: color, width: "76.964px", height: "140px" }}
      />
    );
  }
}

ImgDropFileIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

ImgDropFileIcon.defaultProps = {
  color: '#AAAAAA',
  className: '',
};
