import React, { useState, useEffect } from 'react'
import { Container, Menu, Header } from 'semantic-ui-react';
import ProjectManagementTable from './ProjectManagementTable'
import ProjectUserIndex from './user/ProjectUserIndex'
import intl from 'react-intl-universal';
import api from '../api';
import Slide from '@material-ui/core/Slide';


export default function ProjectTab (props) {

  const searchValue = new URLSearchParams(props.location.search)
  const confirmFlag = searchValue.get('confirmFlag');
  var rereadSuccessValue = false
  var rereadSuccessMsgValue =""
  if(confirmFlag){
    if(confirmFlag === "1"){
      rereadSuccessValue = true
      rereadSuccessMsgValue = '新しいパスワードに設定しました'
    }
    setTimeout(() =>{
      setRereadSuccess(false)
      setRereadSuccessMsg("")
      props.history.replace({...props.location, search: null})
    },2500)
  }
  const tabs = [
    {
      label: intl.get('_project.ProjectTab.ProjectList'),
      component: 'ProjectList'
    },
    {
      label: intl.get('_project.ProjectTab.ProjectUser'),
      component: 'ProjectUser'
    }
  ]
  const [rereadSuccess, setRereadSuccess] = useState(rereadSuccessValue)
  const [rereadSuccessMsg, setRereadSuccessMsg] = useState(rereadSuccessMsgValue)
  const [activeTab, setActiveTab] = useState('ProjectList')
  const [user, setUser] = useState('');

  const getInfo = async () => {
    const info = (await api.get(`/dppapi/info`)).data;
    setUser(info ? info : '');
  }

  useEffect(() => {
    getInfo();
  }, []);

  const handleSwitchTab = (label) => {
    setActiveTab(label)
  }
  return (<Container>
    <Slide direction="up" in={rereadSuccess}>
      <div
        className="canvas-success-slide"
        style={{
          display: rereadSuccess ? 'block' : 'none',
        }}
      >
        {rereadSuccessMsg}
      </div>
    </Slide>
    {(user && user.tenantName) ? <Header as="h1">{user.tenantName}</Header> : null}
    <Menu pointing secondary>
        {
          tabs.map(tab => {
            return <Menu.Item
              name={tab.label}
              component={tab.component}
              active={activeTab === tab.component}
              onClick={() => handleSwitchTab(tab.component)}
              key={tab.label}
            />
          })
        }
      </Menu>
      {
        activeTab === 'ProjectList' && <ProjectManagementTable />
      }
      {
        activeTab === 'ProjectUser' && <ProjectUserIndex />
      }
  </Container>)
}