import React from 'react'
import { Button, Form, Segment, Icon, Dropdown, Loader, Message} from 'semantic-ui-react'
import intl from 'react-intl-universal';
import { generatePassword } from '../../../user/user/Util'
import api from '../../../api';
export default class UserSearchForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          tenantId: '',
          tenants: [],
          isError:this.props,
        };
      }
      
  handleInput = (event) => {
    const { name, value } = event.target;
    const form = {};
    form[name] = value;
    this.props.setForm(form);
  }

  async componentDidMount() {
    const tenants = (await api.get(`/dppapi/system/tenant`)).data;
    this.setState({
      tenants: tenants.map(data => {
        return { text: data.name, key: data.id, value: data.id }
      })
    })
  }
  
  render() {
    const { onSearch, tenantId } = this.props;
    const { tenants } = this.state;
    const {errors} = this.props
    const options = this.props.roles.map(role => {return {key: role.id, text: role.name, value: role.id}});
    const {loading, form } = this.props;
    return (
      <Segment>
        {
          Object.keys(errors).length > 0 &&
          <Message
            error
            list={ Object.keys(errors).map(i => {
              return errors[i].content
            })}
          />
        }
        {loading 
          ? ( <Loader active inline="centered"/>) 
          : (
          <Form>
            <Form.Field>
              <Button.Group floated="right" style={{ marginLeft: '2px' }}>
                <Button icon onClick={this.props.handleExport}>
                  <Icon name="download" />
                </Button>
              </Button.Group>
              <Button.Group floated="right" style={{ marginLeft: '2px' }}>
                <Button icon onClick={this.props.handleImport}>
                  <Icon name="file" />
                </Button>
              </Button.Group>
              <Button.Group floated='right' style={{ marginLeft: '2px' }}>
                <Button icon onClick={() => this.props.handleEdit({
                  accessKey: Math.random().toString().slice(-6),
                  pwd: generatePassword(6),
                })}>
                  <Icon name='add' />
                </Button>
              </Button.Group>
            </Form.Field>
            <Form.Field error={this.props.isError}>
              <label>{intl.get('_system.tenants.setting.Search.TenantsName')}</label>
              <Dropdown
                placeholder={intl.get('_system.tenants.user.User.TenantsName')}
                options={tenants}
                value={tenantId}
                search
                selection
                onChange={(event, { value }) =>
                  {
                    this.props.onChange(value);
                    onSearch(value);
                  }
                }
                onFocus={this.props.handleFocus}
              />
            </Form.Field>
            <Form.Field >
              <label>{intl.get('_system.tenants.user.User.Name')}</label>
              <Form.Input
                name="name"
                defaultValue={form.name}
                placeholder={intl.get('_system.tenants.user.User.Name')}
                onChange={(event) => this.handleInput(event)}
              />
            </Form.Field>
            <Form.Field>
              <label>{intl.get('_system.tenants.user.User.E-mail')}</label>
              <Form.Input
                name="email"
                defaultValue={form.email}
                placeholder={intl.get('_system.tenants.user.User.E-mail')}
                onChange={(event) => this.handleInput(event)}
              />
            </Form.Field>
            <Form.Field>
              <label>{intl.get('_system.tenants.user.User.Role')}</label>
              <Dropdown
                clearable
                options={options}
                defaultValue={form.roleId}
                placeholder={intl.get('_system.tenants.user.User.Role')}
                selection
                onChange={(e, change) => this.props.setForm({roleId: change.value})}
              />
            </Form.Field>
            <Button size="small" onClick={() => this.props.handleSearch(true)}>
              <Icon name="search" />
              {intl.get('_system.tenants.user.User.Search')}
            </Button>
          </Form>)}   
      </Segment>
    );
  }
}