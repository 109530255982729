import React, { Component } from 'react';
import { Form, Header, Button, Dropdown, Input } from 'semantic-ui-react';
import api from '../api'
import intl from 'react-intl-universal';
import {
  withRouter
} from 'react-router-dom';
import {getCookie, createCookie } from '../common/CookieUtil';
import { ErrorIcon} from '../assets/icon';
import Hotkeys from 'react-hot-keys';

class UserRetrievePage extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      email: '',
      tenantId: '',
      userName: '',
      isTenant: false,
      error: null,
      threePartyMsg: null,
      loading: false,
      errors: {},
      tenantName: "",
    };
    this.handleEmailCheck = this.handleEmailCheck.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
  }
  
  async componentWillMount() {
    await this.getTenantId();
  }

  getTenantId = async () => {
    const { tenantId } = (await api.get('/dppapi/tenant-id')).data;
    if (tenantId && tenantId > 0) {
      this.handleTenantInputChange(tenantId, "tenantId");
      return;
    }
    this.setState({ isTenant: true});

    const query = new URLSearchParams(this.props.location.search)
    const q_tenantId = query.get('tenantId');
    if (q_tenantId) {
      this.handleTenantInputChange(q_tenantId, "tenantId");
      return;
    }
    
    var c_tenantId = getCookie('tenant_id');
    if (c_tenantId) {
      this.handleTenantInputChange(c_tenantId, "tenantId");
      return;
    }
  }
  
  handleTenantInputChange(tenantValue,name) {
    var tenantList = getCookie('tenantList');
    tenantList = tenantList ? JSON.parse(tenantList) : [];
    var tenantName = "";
    var tenantId = name === "tenantId"? tenantValue : "";
    tenantList.forEach(data => {
      if(name === "tenantId"){
        var cookieTID = data.id.toString();
        if (data.id === tenantValue || cookieTID === tenantValue) {
          tenantName = data.name;
          tenantId = data.id;
        }
      }else if(name === "tenantName"){
        if (data.name === tenantValue) {
          tenantName = data.name;
          tenantId = data.id;
        }
      }
    })
    this.setState({ tenantId: tenantId, tenantName: tenantName});
  }

  async handleEmailCheck(e) {
    const  { email,tenantId,userName} = this.state;
    if(this.checkEmail(email,tenantId,userName)) return;
    this.setState({ 
      loading: true,
      success: null });
    
    try {
      const checkUser = await api.post('/dppapi/retrieve/usercheck', {
        email: email, 
        tenantId: tenantId, 
        userName: userName
      });
      if(checkUser.data.success === true){
        this.setState({ loading: false });
        window.location = `/dppui/login?confirmFlag=2`;
      }
    } catch (e) {
      this.setState({ 
        loading: false, 
        error: e.data.message,
        threePartyMsg: e.data.threePartyMsg 
      });
    }
  }

  handleFocus(event) {
    const { errors } = this.state;
    const {name} = event.target; 
    if(name === 'userName'){
      delete errors['userName'];
    }
    if(name === ''){
      delete errors['tenantId'];
    }
    delete errors[name];
    this.clearErrors(errors);
  }

  clearErrors(errors) {
    this.setState({
      errors
    });
  }
  
  checkEmail(email,tenantId,userName){ 
    const errors = {};
    //eslint-disable-next-line 
    const emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    //eslint-disable-next-line 
    const TenantIdReg = /^([A-Za-z0-9_\-\.])/
    if (!TenantIdReg.test(tenantId)) {
      errors['tenantId'] = {content: intl.get('_login.UserRetrievePage.Please enter tenantId'), pointing: 'below', flag: true};
    }
    if (!emailReg.test(email)) {
      errors['email'] = {content: intl.get('_login.UserRetrievePage.Please enter email correctly'), pointing: 'below', flag: true};
    }
    if (!userName) {
      errors['userName'] = {content: intl.get('_login.UserRetrievePage.Please enter userName'), pointing: 'below', flag: true};
      
    }
    this.setState({
      errors: errors,
      error: null, 
      threePartyMsg: null, 
    });
    return Object.keys(errors).length !== 0;
  }
  onKeyDown(keyName, e) {
    if(keyName === 'tab'){
      e.preventDefault();
      const currentTagName = document.activeElement.tagName.toLowerCase();
      const currentElementName = document.activeElement.getAttribute("name");
      const parentElement = document.getElementsByClassName("login-form-confirm");
      let inputElement = parentElement && parentElement[0].querySelectorAll("input");
      let buttonElement = parentElement && parentElement[0].querySelectorAll("button");
      let loginElement = document.getElementsByClassName("footer-form-text");
      if(currentTagName === "body"){
        inputElement.length === 4 ? inputElement[0].focus() : inputElement[1].focus()
      }else if(currentTagName === "input"){
        if(currentElementName === "tenantId"){
          inputElement.length === 4 ? inputElement[2].focus() : inputElement[1].focus()
        }else if(currentElementName === ""){
          inputElement[2].focus()
        }else if(currentElementName === "email"){
          inputElement.length === 4 ? inputElement[3].focus() : inputElement[2].focus()
        }else if(currentElementName === "userName"){
          buttonElement[0].focus()
        }
      }else if(currentTagName === "button"){
        loginElement[0].focus()
      }else if(currentTagName === "p"){
        inputElement.length === 4 ? inputElement[0].focus() : inputElement[1].focus()
      }
    }
  }
  
  render() {
    const { email, userName, tenantId, loading, threePartyMsg, error, errors, isTenant, tenantName } = this.state;
    const { tokenExpired } = this.props;
    var tenantList = getCookie('tenantList');
    tenantList = tenantList ? JSON.parse(tenantList) : [];
    var options = [];
    tenantList.forEach(k => options.push({ key: k.id, text: k.name, value: k.name }));
    if(document.getElementsByClassName('login-container-confirm').length > 0){
      document.getElementsByClassName('login-container-confirm')[0].parentNode.classList.remove('container')
    }
    var tenantErrorClass = errors['tenantId'] ? "input-error" : ""
    var emailErrorClass = errors['email'] ? "input-error" : ""
    var userNameErrorClass = errors['userName'] ? "input-error" : ""
    var tokenExpiredPadding = tokenExpired ? "confirm-from-expired-header" : ""
    return (
      <div className="login-container-confirm">
        <Hotkeys
          keyName="tab"
          onKeyDown={this.onKeyDown.bind(this)}
        />
        <Form className="login-form-confirm" onSubmit={this.handleEmailCheck}>
          <Header className={`confirm-from-header ${tokenExpiredPadding}`}>
            {
              tokenExpired ? 
              (<ErrorIcon size="32px" color="#F26868" className="repredict-icon-cursor"/>)
              : null
            }
            {tokenExpired ? (<label style={{margin:'4px 0 4px 8px'}}>有効期限切れのURLです</label>) : intl.get('_login.UserRetrievePage.SendMail')}
          </Header>
          <p className="confirm-from-text">
            {tokenExpired ? 'パスワード設定URLの有効期限が切れています。以下フォームよりメールを再送信してください。' : intl.get('_login.UserRetrievePage.Tips')}<br/>
            {intl.get('_login.UserRetrievePage.Tips info')}
          </p>
          {isTenant ?
              <div>
                <div className="login-form-inline">
                  <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                    <label className="login-form-input-label">{intl.get('_login.UserRetrievePage.TenantId')}</label>
                    <Form.Field style={{ width: '100%', display: 'inline-block', paddingRight: '2%' }}>
                      <Input
                        type="number"
                        disabled={!isTenant}
                        value={tenantId}
                        name="tenantId"
                        onFocus={this.handleFocus}
                        onChange={(e, { value }) => this.handleTenantInputChange(value,"tenantId")}
                        className={`login-form-tenant-id select-pagetype tenant-id-hidden-arrow ${tenantErrorClass}`}
                      />
                    </Form.Field>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <label className="login-form-input-label" style={{ height: '12px' }}></label>
                    <Form.Field style={{ width: '100%', display: 'inline-block'}}>
                      <Dropdown
                        className='icon retrieve-form-tenant-name select-pagetype dropdown-place-color'
                        options={options}
                        style={{ width: '100%' }}
                        search
                        selection
                        labeled
                        value={tenantName}
                        name="tenantName"
                        noResultsMessage = {intl.get('_login.UserRetrievePage.No tenants to select')}
                        placeholder={intl.get('_login.UserRetrievePage.TenantName info')}
                        onChange={(e, { value }) => this.handleTenantInputChange(value,"tenantName")}
                        onKeyDown={(e) => {if(e.keyCode === 13){e.preventDefault()}}}
                      />
                    </Form.Field>
                  </div>
                </div>
                <p className="confirm-form-single-error">{errors['tenantId'] ? errors['tenantId'].content:null}</p>
                </div> :
              <Form.Input
                type="text"
                disabled
                value={tenantId}
                onFocus={this.handleFocus}
                label={intl.get('_login.UserRetrievePage.TenantId')}
              />}
            <div>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                  <label className="login-form-input-label">{intl.get('_login.UserRetrievePage.Email')}</label>
                  <Form.Field style={{ width: '100%', display: 'inline-block' }}>
                    <Input
                      type="text"
                      name="email"
                      onFocus={this.handleFocus}
                      defaultValue={email}
                      onChange={(e, { value }) => this.setState({ email: value })}
                      className={`${emailErrorClass}`}
                    />
                  </Form.Field>
                </div>
                <p className="confirm-form-single-error">{errors['email'] ? errors['email'].content:null}</p>
            </div>
            <div>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                  <label className="login-form-input-label">{intl.get('_login.UserRetrievePage.UserName')}</label>
                  <Form.Field style={{ width: '100%', display: 'inline-block' }}>
                    <Input
                      type="text"
                      name="userName"
                      onFocus={this.handleFocus}
                      onChange={(e, { value }) => this.setState({ userName: value })}
                      defaultValue={userName}
                      className={`${userNameErrorClass}`}
                    />
                  </Form.Field>
                </div>
                <p className="confirm-form-single-error">{errors['userName'] ? errors['userName'].content:null}</p>
            </div>
          <div>
            <div className="confirm-form-button">
              <Button fluid
                loading={loading} 
                disabled={loading} 
                secondary={loading}
                name="sendEmail"
                className="login-btn primary-btn confirm-form-button-margin">
                  {intl.get('_login.UserRetrievePage.Send')}</Button>
            </div>
            {error && <div className="confirm_icon_div">
              <ErrorIcon size="24px" color="#F26868" className="repredict-icon-cursor"/>
              <label htmlFor='error'>
                  <span className='error-text'>{error}</span>
              </label>
            </div>}
            {threePartyMsg && <div className="confirm_icon_div">
              <ErrorIcon size="24px" color="#F26868" className="repredict-icon-cursor"/>
              <label htmlFor='error' className="reset-password-error-lable">
                  <span className='error-text'>{threePartyMsg}</span>
              </label>
            </div>}
          </div>
          <div className="login-footer-form-link">
            <p className="footer-form-text" 
               name="tenantIdConfirm"
               tabIndex={0}
               onKeyDown={(e) => {
                if(e.keyCode === 13){
                  createCookie('user_email', this.state.email, 365)
                  window.location = `/dppui/login/tenantIdConfirm`
                }}}
               onClick={
                () => { createCookie('user_email', this.state.email, 365); window.location = `/dppui/login/tenantIdConfirm` }
               }>
                {intl.get('_login.LoginPage.Tenant ID Confirm')}
            </p>
            <p className="footer-form-text" name="goLogin" tabIndex={0}
              onKeyDown={(e) => {
                if(e.keyCode === 13){
                  window.location = `/dppui/login`
                }}}
              onClick={() => { window.location = `/dppui/login` }}>
              {intl.get('_login.UserRetrievePage.Go to login')}
            </p>
          </div>
        </Form>
      </div>
    );
  }
}

export default withRouter(UserRetrievePage);
