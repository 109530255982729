import React from 'react';
import { Button, Input, Segment } from 'semantic-ui-react';
import DatePicker from 'react-date-picker';
import moment from 'moment';
// import { getOptions } from '../../Const';
import intl from 'react-intl-universal';
import '../Tenants.css';
// const options = getOptions();

export default class MaintainSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      mail: '',
      address: '',
      phone: '',
      startDate: moment().add(-30, 'd').toDate(),
      endDate: moment().toDate(),
      status: ''
    };
  }

  onChange = (type, value) => {
    this.setState({ [type]: value });
  };

  render() {
    const { onSearch, handleAdd } = this.props;
    const { name, mail, address, phone, startDate, endDate, status } = this.state;
    return (
      <Segment>
        <div className="search-tenants-condition">
          <p className="search-tenants-label">{intl.get('_system.tenants.maintain.Search.TenantsName')}</p>
          <Input
            className="search-tenants-item"
            fluid
            placeholder={intl.get('_system.tenants.maintain.Search.Please enter a tenant name')}
            onChange={e => this.onChange('name', e.target.value)}
            value={name}
          />
        </div>
        <div className="search-tenants-condition">
          <p className="search-tenants-label">{intl.get('_system.tenants.maintain.Search.TenantsMail')}</p>
          <Input
            className="search-tenants-item"
            fluid
            placeholder={intl.get('_system.tenants.maintain.Search.Please enter a tenant mail')}
            onChange={e => this.onChange('mail', e.target.value)}
            value={mail}
          />
        </div>
        {/* <div className="search-tenants-condition">
          <p className="search-tenants-label">Address</p>
          <Input
            className="search-tenants-item"
            fluid
            placeholder="please enter a address"
            onChange={e => this.onChange('address', e.target.value)}
            value={address}
          />
        </div>
        <div className="search-tenants-condition">
          <p className="search-tenants-label">Phone</p>
          <Input
            className="search-tenants-item"
            fluid
            placeholder="please enter a phone"
            onChange={e => this.onChange('phone', e.target.value)}
            value={phone}
          />
        </div> */}
        <div className="search-tenants-condition">
          <p className="search-tenants-label">{intl.get('_system.tenants.maintain.Search.UpdateTime')}</p>
          <DatePicker
            className="search-tenants-item"
            onChange={date => this.onChange('startDate', date)}
            value={startDate}
            format="y/MM/dd"
          />{' '}
          ~
          <DatePicker
            className="search-tenants-item"
            onChange={date => this.onChange('endDate', date)}
            value={endDate}
            format="y/MM/dd"
          />
        </div>
        {/* <div className="search-tenants-condition">
          <p className="search-tenants-label">Status</p>
          <Dropdown
            clearable
            options={options}
            selection
            search
            onChange={(e, data) => this.onChange('status', data.value)}
            value={status} />
        </div> */}

        <div className="search-tenants-condition">
          <Button type="submit"
            onClick={() => onSearch({
              name,
              mail,
              address,
              phone,
              startDate,
              endDate,
              status
            })}>
           {intl.get('_system.tenants.maintain.Search.Search')}
          </Button>
          <Button icon onClick={handleAdd}>
            {intl.get('_system.tenants.maintain.Search.Add')}
          </Button>
        </div>
      </Segment>
    );
  }
}
