import React, { Component } from 'react'
import Menubar from '../common/Menubar'
import { Switch, Route } from 'react-router-dom';
import SystemFeedLoader from './SystemFeedLoader'
import SystemTab from './SystemTab'
import ProjectMenu from '../project/ProjectMenu'

class System extends Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path="/system/"
          render={(props) => (
            <Menubar active="system">
              <SystemTab {...props} />
            </Menubar>)}
        />
        <Route path="/system/feedback/:id" render={(props) => <SystemFeedLoader {...props} />} />
        <Route
          exact
          path="/system/tenant/project/:tenantId/:projectId"
          render={(props) => (
            <Menubar active="system">
              <ProjectMenu {...props} isSystemManager={true} />
            </Menubar>)}
        />
      </Switch>
    )
  }
}

export default System;