/**
 * Parse, validate, manipulate, and display dates in javascript. http://momentjs.com
 */
import moment from 'moment';

export const FMT = {
  UNIX_MS: 'x', // unix milliseconds timestamp
  DATETIME_LOCAL: 'YYYY-MM-DD HH:mm',
  DATE: 'YYYY-MM-DD',
  TIME_STRING: 'YYYYMMDDHHmmss',
  TIME_STANDARD: 'YYYY/MM/DD HH:mm:ss',
  DATETIME_LOCAL_TEXT: 'YYYY年MM月DD日 HH:mm',
};

export function toUnixMS(date) {
  return moment(date).format(FMT.UNIX_MS);
}

export function unixMSToDatetime(unixMS, fmt) {
  return moment.unix(unixMS / 1000).format(fmt);
}
