import React from 'react'
import { Container, Menu, Dropdown } from 'semantic-ui-react'
import intl from 'react-intl-universal';
import Preferences from './preferences/Preferences'
import OnlineUsers from './online-users'
import Tenants from './tenants'
import FsItemMapping from './fs-item-mapping'
import LicenseKey from './license-key';
import Machines from './machines';
import Feedback from './feedback';
import Blackip from './blackip';
import api from '../api';
import { filterTab } from '../common/AuthUtil'
import PdfSearch from './pdf-search';
import RabbitMQ from './rabbitmq';
import Replacement from './replace';

export default class SystemTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '',
      tabs : [
        {
          key: 'system/preferences',
          label: intl.get('_system.System.Preferences'),
          url: '/system/preferences'
        },
        {
          key: 'system/online_users',
          label: 'OnlineUsers',
          url: '/system/onlineUsers'
        },
        {
          key: 'system/tenants',
          label: intl.get('_system.System.Tenants'),
          url: '/system/tenants'
        },
        {
          key: 'system/fs_item_mapping',
          label: intl.get('_system.System.FsItemMapping'),
          url: '/system/FsItemMapping'
        },
        {
          key: 'system/license_key',
          label: intl.get('_system.System.LicenseKey'),
          url: '/system/LicenseKey'
        },
        {
          key: 'system/machines',
          label: intl.get('_system.System.Machines'),
          url: '/system/Machines'
        },
        {
          key: 'system/feedback',
          label: intl.get('_system.System.Feedback'),
          url: '/system/Feedback'
        },
        {
          key: 'system/black_ip',
          label: intl.get('_system.System.Black Ip'),
          url: '/system/BlackIp'
        },
        {
          key: 'system/pdf_search',
          label: intl.get('_system.System.Pdf Search'),
          url: '/system/PdfSearch'
        },
        {
          key: 'system/rabbitmq',
          label: intl.get('_system.System.RabbitMQ'),
          url: '/system/RabbitMQ'
        },
        {
          key: 'system/replace',
          label: intl.get('_system.System.Replace'),
          url: '/system/Replace'
        },
      ]
    };
  }

  handleSwitchTab = label => {
    this.setState({activeTab: label});
  }

  async componentDidMount() {
    const info = (await api.get(`/dppapi/info`)).data;
    const policy = info.policy
    const allowTabs = filterTab(this.state.tabs, policy)
    const activeTab = allowTabs.length > 0 ? allowTabs[0]['url'] : ''
    this.setState({user: info ? info : '', tabs: allowTabs, activeTab});
  }

  render(){
    const { activeTab,tabs } = this.state;
    const { history } = this.props;

    return (
        <Container>
          <Menu pointing secondary>
            { 
              tabs.map((tab, index) => {
                return index < 9 && (
                  <Menu.Item
                    name={tab.label}
                    component={tab.url}
                    active={activeTab === tab.url}
                    onClick={() => this.handleSwitchTab(tab.url)}
                    key={tab.label}
                  />
              )})
            }
            {
              tabs.length > 9 ? (
                <Dropdown item icon='angle double right'>
                  <Dropdown.Menu>
                    {tabs.map((tab, index) => {
                      return index >= 9 && (
                        <Menu.Item
                          name={tab.label}
                          component={tab.url}
                          active={activeTab === tab.url}
                          onClick={() => this.handleSwitchTab(tab.url)}
                          key={tab.label}
                        />)
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              ) : null
            }
          </Menu>
          {
            activeTab === '' && <></>
          }
          {
            activeTab === '/system/preferences' && <Preferences history={history}/>
          }
          {
            activeTab === '/system/onlineUsers' && <OnlineUsers history={history}/>
          }
          {
            activeTab === '/system/tenants' && <Tenants history={history}/>
          }
          {
            activeTab === '/system/FsItemMapping' && <FsItemMapping history={history}/>
          }
          {
            activeTab === '/system/LicenseKey' && <LicenseKey history={history}/>
          }
          {
            activeTab === '/system/Machines' && <Machines history={history}/>
          }
          {
            activeTab === '/system/Feedback' && <Feedback history={history}/>
          }
          {
            activeTab === '/system/BlackIp' && <Blackip history={history}/>
          }
          {
            activeTab === '/system/PdfSearch' && <PdfSearch history={history}/>
          }
          {
            activeTab === '/system/RabbitMQ' && <RabbitMQ history={history}/>
          }
          {
            activeTab === '/system/Replace' && <Replacement history={history}/>
          }
        </Container>
    )
  }
}
