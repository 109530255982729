import React, { Component } from 'react';
import update from 'immutability-helper';
import Canvas from './Canvas';
import { colors } from '../../../label/label-app/utils';
import { computeTrace } from '../../../label/label-app/tracing';
import { withHistory } from './LabelingAppHistoryHOC';
import { withLoadImageData } from './LoadImageDataHOC';
import { getBboxCoordinate } from '../../Util';
import 'semantic-ui-css/semantic.min.css';
import './LabelingApp.css';
// import ParsingResult from './ParsingResult'
import { PREDICT_DEFAULT_LABEL_ID } from '../../Constant';
/*
 type Figure = {
   type: 'bbox' | 'polygon';
   points: [{ lat: Number, lng: Number }];
   ?color: Color;
 };
*/

class PdfContainer extends Component {
  constructor(props) {
    super(props);
    const { labels } = props;

    const toggles = {};
    labels.map(label => (toggles[label.id] = true));

    this.state = {
      selected: null,
      toggles,

      selectedFigureId: null,

      // UI
      reassigning: { status: false, type: null },

      // Parsing relavant
      isShowParsingResult: false,
      parsingResult: {}
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelected = this.handleSelected.bind(this);
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.canvasRef = React.createRef();
    this.handleDelAllFigures = this.handleDelAllFigures.bind(this);
    this.handleDelAllBBox = this.handleDelAllBBox.bind(this);
    this.handlePredict = this.handlePredict.bind(this);
    this.handlePredictFsScrumMap = this.handlePredictFsScrumMap.bind(this);
    this.handleRotate = this.handleRotate.bind(this);
    this.toggleShowParsingResult = this.toggleShowParsingResult.bind(this);
  }

  toggleShowParsingResult({ isShowParsingResult }) {
    this.setState({
      isShowParsingResult: isShowParsingResult
    })
  }

  handleSelected(selected) {
    if (selected === this.state.selected) return;
    const { pushState } = this.props;

    if (!selected) {
      pushState(
        state => ({
          unfinishedFigure: null,
        }),
        () => this.setState({ selected })
      );
      return;
    }

    const { labels } = this.props;

    const labelIdx = 0;
    const type = labels[labelIdx] ? labels[labelIdx].type : PREDICT_DEFAULT_LABEL_ID;
    const color = colors[labelIdx] ? colors[labelIdx] : 'red';
    const detClass = labels[labelIdx] ? labels[labelIdx].name : '';

    pushState(
      state => ({
        unfinishedFigure: {
          id: null,
          color,
          type,
          points: [],
          det_text: "",
          det_class: detClass
        },
      }),
      () => this.setState({ selected })
    );
  }

  handleSelectionChange(figureId) {
    if (figureId) {
      this.setState({ selectedFigureId: figureId });
    } else {
      this.setState({
        reassigning: { status: false, type: null },
        selectedFigureId: null,
      });
    }
  }

  async handleRotate() {
    const { rotate, projectInfo, imageUrl } = this.props;
    const { projectId, pdfFileId, pdfImageId } = projectInfo;
    const resRotate = await rotate(imageUrl, projectId, pdfFileId, pdfImageId);
    if (resRotate.success) {
      this.props.onLoad();
    }
  }

  async handlePredictFsScrumMap() {
    const { onPredictFsScrumMap, projectInfo, textMappingRef } = this.props;
    const { projectId, pdfFileId, pdfImageId } = projectInfo;
    const resPredictFsScrumMap = await onPredictFsScrumMap(projectId, pdfFileId, pdfImageId);
    if (resPredictFsScrumMap.success) {
      textMappingRef && textMappingRef.current && textMappingRef.current.handleReload(true);
    }
    this.setState({
      isShowParsingResult: true,
      parsingResult: resPredictFsScrumMap
    })
  }

  async handlePredict() {
    const { onPredict, projectInfo, textMappingRef } = this.props;
    const { projectId, pdfFileId, pdfImageId } = projectInfo;
    const resPredict = await onPredict(projectId, pdfFileId, pdfImageId);
    if (resPredict.success) {
      this.props.onLoad();
      textMappingRef && textMappingRef.current && textMappingRef.current.handleReload(true);
    }
  }

  async handleDelAllBBox() {
    const { onAllBBoxDelete, projectInfo, textMappingRef } = this.props;
    const { projectId, pdfFileId, pdfImageId } = projectInfo;
    const resAllDelete = await onAllBBoxDelete(projectId, pdfFileId, pdfImageId);
    if (resAllDelete.success) {
      this.props.onLoad();
      textMappingRef && textMappingRef.current && textMappingRef.current.handleReload(true);
    }
  }

  handleAddPdfToImage = async() => {
    const { onAddPdfToImage, projectInfo } = this.props;
    const { projectId, pdfFileId, pdfImageId } = projectInfo;
    const res = await onAddPdfToImage(projectId, pdfFileId, pdfImageId);
    if (res.success) {
      window.open(res.imagePath)
    }
  }

  handleDelAllFigures() {
    const { pushState, figures } = this.props;
    let delOperation = {}
    Object.keys(figures).forEach(k => delOperation[k] = { $splice: [[0]] })
    pushState(state => ({
      figures: update(state.figures, delOperation),
    }));
  }

  async handleChange(eventType, figure, newLabelId) {
    if (!figure.color) return;
    const { figures,
      pushState,
      height,
      width,
      imageData,
      onBBoxDelete,
      onBBoxGenerate,
      onBBoxUpdate,
      projectInfo,
      textMappingRef } = this.props;
    const { projectId, pdfFileId, pdfImageId } = projectInfo;

    const idx = (figures[PREDICT_DEFAULT_LABEL_ID] || []).findIndex(f => f.id === figure.id);
    switch (eventType) {
      case 'new':
        const coordinate = getBboxCoordinate(figure, height)
        const resGenerate = await onBBoxGenerate(projectId, pdfFileId, pdfImageId, coordinate)
        if (resGenerate.success) {
          pushState(
            state => ({
              figures: update(state.figures, {
                [PREDICT_DEFAULT_LABEL_ID]: {
                  $push: [
                    {
                      id: figure.id || resGenerate.bboxId,
                      type: figure.type,
                      points: figure.points,
                      det_text: figure.det_text,
                      det_class: figure.det_class,
                      accuracy: figure.accuracy,
                      accThreshold: figure.accThreshold
                    },
                  ],
                },
              }),
              unfinishedFigure: null,
            }),
            () =>
              this.setState({
                selected: null,
              })
          );
        } else {
          console.log('api error')
        }

        break;

      case 'replace':

        const coordinateUpdate = getBboxCoordinate(figure, height)
        const arg = { projectId, pdfFileId, pdfImageId, bboxId: figure.id, coordinate: coordinateUpdate, content: figure.det_text, contentType: figure.det_class, checkFlag: figure.checkFlag, accuracy: figure.accuracy }
        const resUpdate = await onBBoxUpdate(arg)
        if (resUpdate.success) {
          pushState(state => {
            let { tracingOptions } = figure;
            if (tracingOptions && tracingOptions.enabled) {
              const imageInfo = {
                height,
                width,
                imageData,
              };
              tracingOptions = {
                ...tracingOptions,
                trace: computeTrace(figure.points, imageInfo, tracingOptions),
              };
            } else {
              tracingOptions = { ...tracingOptions, trace: [] };
            }

            return {
              figures: update(state.figures, {
                [PREDICT_DEFAULT_LABEL_ID]: {
                  $splice: [
                    [
                      idx,
                      1,
                      {
                        id: figure.id,
                        type: figure.type,
                        points: figure.points,
                        tracingOptions,
                        det_text: figure.det_text,
                        det_class: figure.det_class,
                        checkFlag: figure.checkFlag,
                        accuracy: figure.accuracy,
                        accThreshold: figure.accThreshold
                      },
                    ],
                  ],
                },
              }),
            };
          });
          textMappingRef && textMappingRef.current
            && !textMappingRef.current.state.edit && textMappingRef.current.handleReload(true)
        } else {
          //TODO
          console.log('api err')
        }

        break;

      case 'delete':
        const resDelete = await onBBoxDelete(projectId, pdfFileId, pdfImageId, figure.id)
        if (resDelete.success) {
          pushState(state => ({
            figures: update(state.figures, {
              [PREDICT_DEFAULT_LABEL_ID]: {
                $splice: [[idx, 1]],
              },
            }),
          }));
          textMappingRef && textMappingRef.current
            && !textMappingRef.current.state.edit && textMappingRef.current.handleReload(true)
          textMappingRef && textMappingRef.current
            && textMappingRef.current.state.edit && textMappingRef.current.handleTextChange(figure.id, "", "delete")
        } else {
          // TODO 
          console.log('delete err')
        }

        break;

      case 'unfinished':
        pushState(
          state => ({ unfinishedFigure: figure }),
          () => {
            const { unfinishedFigure } = this.props;
            const { type, points } = unfinishedFigure;
            if (type === 'bbox' && points.length >= 2) {
              this.handleChange('new', unfinishedFigure);
            }
          }
        );
        break;

      case 'recolor':
        if (PREDICT_DEFAULT_LABEL_ID === newLabelId) return;
        pushState(state => ({
          figures: update(state.figures, {
            [PREDICT_DEFAULT_LABEL_ID]: {
              $splice: [[idx, 1]],
            },
            [newLabelId]: {
              $push: [
                {
                  id: figure.id,
                  points: figure.points,
                  type: figure.type,
                  tracingOptions: figure.tracingOptions,
                  det_text: figure.det_text,
                  det_class: figure.det_class,
                },
              ],
            },
          }),
        }));
        break;

      default:
        throw new Error('unknown event type ' + eventType);
    }
  }

  render() {
    const {
      labels,
      imageUrl,
      loaderImageType,
      figures,
      unfinishedFigure,
      height,
      width,
      // rotate,
      onOcr,
      selectedCell,
      linking,
      textMappingRef,
      onShortKeyPanelVisible,
      onSelectBox,
      onSelectedBBoxChange,
      selectedBBox,
      isEditing,
      ocrFlg,
      scrumRecommendationFlg,
      onLoad,
      projectInfo,
      imageData
    } = this.props;
    // const {
    //   selectedFigureId,
    // toggles,
    // isShowParsingResult,
    // parsingResult
    // } = this.state;

    // let selectedFigure = null;
    const allFigures = [];

    const colorSet = {}
    labels.forEach((label, i) => {
      colorSet[label.name] = i
    });

    const labelTypes = {}
    labels.forEach((label, i) => {
      if (label.type === 'bbox') {
        labelTypes[label.name] = label.name;
      }
    });

    figures[PREDICT_DEFAULT_LABEL_ID].forEach(figure => {
      if (
        (figure.type === 'bbox' || figure.type === 'polygon')
      ) {
        const detClass = figure.det_class
        const i = colorSet[detClass] ? colorSet[detClass] : 0
        allFigures.push({
          color: colors[i],
          points: figure.points,
          id: figure.id,
          checkFlag: figure.checkFlag,
          stateFlag: figure.stateFlag,
          accuracy: figure.accuracy,
          accThreshold: figure.accThreshold,
          type: figure.type,
          tracingOptions: figure.tracingOptions,
          det_text: figure.det_text,
          det_class: figure.det_class,
        });

        // if (figure.id === selectedFigureId) {
        //   selectedFigure = { ...figure, color: colors[i] };
        // }
      }
    });
    figures.__temp.forEach(figure => {
      allFigures.push({
        color: 'red',
        ...figure,
      });
    });

    const selectedCellIds = selectedCell.cellIds;

    return (
      <Canvas
        imageData={imageData}
        url={imageUrl}
        loaderImageType={loaderImageType}
        height={height}
        width={width}
        figures={allFigures}
        unfinishedFigure={unfinishedFigure}
        onChange={this.handleChange}
        onDelAllFigures={this.handleDelAllFigures}
        onAllBBoxDelete={this.handleDelAllBBox}
        handleAddPdfToImage={this.handleAddPdfToImage}
        onPredict={this.handlePredict}
        onPredictFsScrumMap={this.handlePredictFsScrumMap}
        onReassignment={type =>
          this.setState({ reassigning: { status: true, type } })
        }
        onLoad={onLoad}
        onSelectionChange={this.handleSelectionChange}
        rotate={this.handleRotate}
        ref={this.canvasRef}
        style={{ flex: 1 }}
        selectedCellIds={selectedCellIds}
        onSelect={this.handleSelected}
        handleOcr={onOcr}
        linking={linking}
        textMappingRef={textMappingRef}
        onShortKeyPanelVisible={onShortKeyPanelVisible}
        onSelectBox={onSelectBox}
        popupRef={this.props.popupRef}
        onSelectedBBoxChange={onSelectedBBoxChange}
        selectedBBox={selectedBBox}
        isEditing={isEditing}
        scrumRecommendationFlg={scrumRecommendationFlg}
        ocrFlg={ocrFlg}
        labelTypes={labelTypes}
        leftWidth={this.props.leftWidth}
        projectId={projectInfo.projectId}
      >
        {/* <ParsingResult  projectId={projectInfo.projectId} pdfId={projectInfo.pdfFileId} parsingResult={parsingResult} isShowParsingResult={isShowParsingResult} toggleShowParsingResult={this.toggleShowParsingResult}/> */}
      </Canvas>
    );
  }
}

export default withLoadImageData(withHistory(PdfContainer));
