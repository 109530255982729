import React, { Component } from 'react';
import {
  Form,
  Icon,
  Button,
  Header,
} from 'semantic-ui-react';

import { sortableHandle, sortableContainer, sortableElement } from 'react-sortable-hoc';

import update from 'immutability-helper';
import arrayMove from 'array-move';
import ColumnItem from './ColumnItem';
import MuiMsgTip from './MuiMsgTip';
import api from '../../api'

export default class PageItem extends Component {

  renderColumns = () => {

    const { page, error } = this.props;

    if (page.type === 'fixed') {

      const columns = page.columns || [];

      const renderedColumns = columns.length ? (
        columns.map((column, index) => (
          <SortableItem
            key={column.id}
            index={index}
            column={column}
            pageId={page.id}
            metadatas={this.props.metadatas}
            onColumnChange={this.props.onColumnChange}
            onSwitchTab={this.props.onSwitchTab}
            error={error}
          />
        ))
      ) : (
          <Header className="centered" as="h6">
            No columns, add columns using the plus button below
          </Header>
        );

      return (
        <div>
          <Header as="h5">Columns:</Header>
          <SortableContainer onSortEnd={this.handleColSortEnd} useDragHandle>
            {renderedColumns}
          </SortableContainer>
          <Button style={{ float: 'right', display: 'none' }} />
          <Button
            circular
            icon="plus"
            size="mini"
            disabled={error}
            style={{ float: 'right', marginTop: '2em' }}
            onClick={() => this.props.onNewColumn(page.id)}
          />
        </div>
      );
    }

    if (page.type === 'reverse') {
      const catagories = this.props.metadatas.map(({ category }) => {
        return {
          key: category,
          text: category,
          value: category
        }
      });

      return (<Form.Select
        label="Catagory"
        options={catagories}
        defaultValue={page.catagory}
        disabled={error}
        onChange={async (e, change) => {
          const category = change.value
          const { metadatas } = (await api.get(`/dppapi/admin/project/metadata/query?projectId=${this.props.projectId}&category=${category}`)).data;
          this.props.onPageChange(page, { ...page, category: category, columns: metadatas.map(meta => {
            return {
              item: meta.item,
              value: meta.value
            }
          })}, 'catagory-type')
        }}
        style={{ maxWidth: 400 }}
      />)
    }

    return null;
  }

  handleColSortEnd = ({ oldIndex, newIndex }) => {

    const { page, onPageChange } = this.props
    const newPage =
      update(page, {
        columns: {
          $set: arrayMove(page.columns, oldIndex, newIndex),
        },
      })

    onPageChange(page, newPage, 'col-sort')
  }

  render() {

    const { page, error } = this.props;
    const options = [
      { key: 'fixed', text: 'Fixed', value: 'fixed' },
      { key: 'change', text: 'Change', value: 'change' },
      { key: 'reverse', text: 'Reverse', value: 'reverse' },
    ];

    const renderColumns = this.renderColumns();
    const errContent = page.error ? page.error.content : ""
    return (
      <div
        style={{
          marginTop: '0.7em',
          padding: '1em',
          border: 'solid 2px #efefef',
          background: 'white',
          shadow: 'rgb(204, 204, 204) 0px 1px 2px',
        }}
      >
        <Form className="form-card" style={{ display: 'flex' }}>
          <DragHandle style={{ flex: 0, marginTop: 9 }} />
          <div style={{ flex: 1, padding: '0 0.5em' }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div style={{ width: "90%" }}>
                <MuiMsgTip show={errContent.length > 0} content={errContent} />
                <Form.Field
                  className="form-item-name"
                  placeholder="page name"
                  control="input"
                  defaultValue={page.name}
                  style={{ padding: 3, fontSize: 24 }}
                  error={errContent.length > 0}
                  disabled={errContent.length === 0 && error}
                  onChange={(e) => {
                    this.props.onPageChange(page, { ...page, name: e.target.value }, 'page-name')
                  }}
                />
              </div>
              <div style={{ width: "10%", display: "flex", justifyContent: "flex-end" }}>
                <Button
                  size="huge"
                  type="button"
                  style={{ background: 'transparent', padding: 0 }}
                  disabled={error}
                  onClick={() => this.props.onPageCopy(page)}>
                  <Icon name="copy" />
                </Button>
                <Button
                  type="button"
                  size="huge"
                  style={{ background: 'transparent', padding: 0 }}
                  disabled={errContent.length === 0 && error}
                  onClick={() =>
                    this.props.onPageChange(page, null, 'page-del')
                  }
                >
                  <Icon name="trash" />
                </Button>
              </div>

            </div>

            <Form.Select
              label="Type"
              options={options}
              defaultValue={page.type}
              disabled={error}
              onChange={(e, change) =>
                this.props.onPageChange(page, { ...page, type: change.value }, 'page-type')
              }
              style={{ maxWidth: 400 }}
            />
            {renderColumns}
          </div>
        </Form>
      </div >
    );
  }
}

const dragHandleStyle = {
  background:
    'linear-gradient(180deg,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000)',
  width: 25,
  minWidth: 25,
  height: 20,
  opacity: 0.25,
  cursor: 'move',
};
const DragHandle = sortableHandle(({ style }) => (
  <div style={{ ...dragHandleStyle, ...style }} />
));



const SortableItem = sortableElement(ColumnItem);

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});
