import React, { Component } from 'react';
import { Form, TextArea, Button, Icon, Divider } from 'semantic-ui-react';
import { Popup } from 'react-leaflet';
import shallowEqualObjects from 'shallow-equal/objects';
import './Figure.css';

export default class DetContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      figureId: props.figure.id,
      editing: false,
      det_text: props.figure.det_text,
      err_msg: '',
    };
    this.popup = React.createRef();
  }

  onEdit = editing => {
    this.setState({ editing: editing });
  };
  onDetTextChange = text => {
    this.setState({ det_text: text });
  };

  onDetTextOcr = result => {
    if (result.success) {
      this.setState({ det_text: result.ocr });
      this.props.onChange('detTextChange', {
        figure: this.props.figure,
        det_text: this.state.det_text,
      });
    } else {
      this.setState({ det_text: '', err_msg: result.errmsg });
    }
  };

  onDetTextSave = () => {
    this.props.onChange('detTextChange', {
      figure: this.props.figure,
      det_text: this.state.det_text,
    });
    this.onEdit(false);
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.det_text === this.state.det_text &&
      nextState.editing === this.state.editing &&
      nextProps.linkedKey === this.props.linkedKey &&
      shallowEqualObjects(nextProps.figure, this.props.figure)
    ) {
      return false;
    }
    return true;
  }

  closePopusOnClick() {
    this.popup.current.leafletElement.options.leaflet.map.closePopup();
  }

  getBboxCoordinate() {
    const { figure, bounds } = this.props;
    const height = bounds._northEast.lat;

    const y1 = figure.points[0].lat;
    const x1 = figure.points[0].lng;
    const y2 = figure.points[1].lat;
    const x2 = figure.points[1].lng;

    return [
      [Math.round(x1), Math.round(height - y1)],
      [Math.round(x2), Math.round(height - y2)],
    ];
  }

  render() {
    const { det_text, editing } = this.state;
    const {
      figure: { id },
      handleOcr,
      onLinking,
      figure,
      clearLinkId,
      linkedKey,
      ocrFlag,
    } = this.props;

    return (
      <Popup
        ref={this.popup}
        closeButton={false}
        className={'old-mui-leaflet-popup-content-wrapper'}
      >
        <div
          key={`popup-${id}`}
          style={{
            height: '200px',
            width: '250px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              height: '110px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ height: '80px', width: '250px', overflowY: 'auto' }}>
              {!editing ? (
                <div className="old-mui-det-content"> {det_text}</div>
              ) : (
                  <Form>
                    <TextArea autoFocus
                      value={det_text}
                      disabled={!editing}
                      onChange={e => this.onDetTextChange(e.target.value)}
                    />
                  </Form>
                )}
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end ' }}>
              {editing ? (
                <Button.Group size="mini">
                  <Button icon onClick={() => this.onEdit(false)}>
                    <Icon name="cancel" />
                  </Button>
                  <Button icon positive onClick={() => this.onDetTextSave()}>
                    <Icon name="checkmark" />
                  </Button>
                </Button.Group>
              ) : (
                  <Button.Group size="mini">
                    {ocrFlag ? (
                      <Button
                        size="mini"
                        primary
                        onClick={() => {
                          handleOcr(this.onDetTextOcr, this.getBboxCoordinate());
                        }}
                      >
                        OCR
                      </Button>
                    ) : null}
                    <Button size="mini" positive onClick={() => this.onEdit(true)}>
                      編集
                  </Button>
                  </Button.Group>
                )}
            </div>
          </div>
          <Divider className="old-mui-det-divider" />
          <div style={{ height: '44px', width: '250px' }}>
            <div
              style={{
                height: '22px',
                width: '250px',
                overflowY: 'auto',
                wordBreak: 'break-all',
              }}
            >
              <span style={{ display: 'inline-block' }}>鍵：</span>
              <span>{linkedKey}</span>
            </div>
          </div>
          <div
            style={{
              height: '30px',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button.Group size="mini">
              <Button
                size="mini"
                positive
                onClick={() => {
                  onLinking(figure, 'start');
                  this.closePopusOnClick();
                }}
              >
                選択
              </Button>
              <Button size="mini" onClick={() => clearLinkId(figure)} disabled={!figure.linkTo}>
                (値)クリア
              </Button>
            </Button.Group>
          </div>
        </div>
      </Popup>
    );
  }
}
