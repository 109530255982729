import React from 'react';

import { MDBModal, MDBModalBody } from 'mdbreact';
import {
  Button,
  Dropdown,
  Radio
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import api from '../../api';
import { cryptoDecrypt } from '../../common/EncryptUtil';
import { cloneDeep } from 'lodash'
import { ErrorIcon } from '../../assets/icon';
import { AnalyticsServiceContext } from '../../common/Analytics/AnalyticsServiceProvider';
import { EventName, EventType, PageName, SetFileOperatorProcess } from '../../common/Analytics/analyticsMetrics';

export default class FileListUserSet extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      confirmFlg: false,
      userSetList: [],
      overwrite: '1',
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleRadio = this.handleRadio.bind(this)
    this.handleUserAdd = this.handleUserAdd.bind(this)
    this.handleUserSet = this.handleUserSet.bind(this)
  }
  static contextType = AnalyticsServiceContext;

  async handleUserSet(fileInfoList, userCountList) {
    const activePage = this.props.activePage;
    this.props.onLoading(true);
    this.props.toggleEdit(false);
    userCountList = userCountList.sort((a,b)=>{return b.count - a.count;});
    fileInfoList = fileInfoList.map((f,index)=>{
      let userInfo = ['x','x'];
      if(userCountList.length > 0) {
        let remainder = index % userCountList.length
        userInfo = userCountList[remainder].name.split(',')
      }
      if(this.state.overwrite === '1' && f.operator !== null) return f
      return {...f, operatorId: userInfo[0], operator: userInfo[1]}
    })
    var fileUpdateMap = {};
    fileInfoList.forEach((f)=>{
      if(fileUpdateMap.hasOwnProperty(f.operatorId + ',' + f.operator)){
        fileUpdateMap[f.operatorId + ',' + f.operator].push(f.id)
      }else{
        fileUpdateMap[f.operatorId + ',' + f.operator] = []
        fileUpdateMap[f.operatorId + ',' + f.operator].push(f.id)
      }
    })

    let fileUpdateList = []
    Object.keys(fileUpdateMap).forEach(f=>{
      let userInfo = f.split(',')
      if (userInfo[0] !== 'undefined'){
        fileUpdateList.push({
          ids: fileUpdateMap[f],
          operatorId: userInfo[0],
          operator: userInfo[1]
        })
      }
    })
    if (fileUpdateList.length > 0) {

      await api.post(`/dppapi/predict/${this.props.projectId}/pdfs/list`, {fileUpdateList: fileUpdateList, noOverwrite: this.state.overwrite==='1'});

      this.context.sendMetrics({
        page_name: PageName.PredictPage,
        event_type: EventType.Action,
        event_name: EventName.SetFileOperator,
        custom_parameter: {
          operator_id_list:
            (fileUpdateList[0].operatorId === "x") ? undefined : 
              fileUpdateList.map((file) => file.operatorId),
          process : this.state.overwrite === "1" ? SetFileOperatorProcess.Skip : SetFileOperatorProcess.Overwrite,
        },
      })
    }
    
    this.setState({
      overwrite: '1',
      userSetList: [],
      confirmFlg: false
    })
    await this.props.refetch();
    this.props.onLoading(false);
    this.props.onCompleted('2',activePage)
  }

  handleUserAdd() {
    let { userSetList } = this.state;
    let userList = cloneDeep(userSetList)
    const user = JSON.parse(cryptoDecrypt(localStorage.getItem("user")));
    !userList.includes(user.id+','+user.name) && userList.push(user.id+','+user.name)
    this.setState({
      userSetList: userList
    }, ()=>{this.props.setActivePage(this.props.activePage);})
    this.context.sendMetrics({
      page_name: PageName.PredictPage,
      event_type: EventType.Action,
      event_name: EventName.SelectedFileOperatorForOneself,
    })
  }

  handleChange(event, {name, key, text, value}) {
    value.length < 21 && this.setState({
      userSetList: value
    })
  }

  handleRadio(e, { value }) {
    this.setState({
      overwrite: value
    })
  }

  getUserName(id){
    return this.props.users.filter(u=>u.key===id)[0].text
  }

  render() {
    const { confirmFlg, userSetList, overwrite } = this.state;
    let fileInfoList = []
    Object.keys(this.props.selectList).forEach(id=>{
      let fileInfo = this.props.selectList[id];
      fileInfoList.push({
        id: id,
        name: fileInfo[0],
        state: fileInfo[1],
        time: fileInfo[2],
        operator: fileInfo[3]
      })
    })
    fileInfoList.sort((a, b) => {
      return Number(a.id) > Number(b.id) ? 1 : -1;
    })
    let userCountList = []
    if(userSetList.length <= fileInfoList.length){
      let counts = Math.floor(fileInfoList.length / userSetList.length)
      let remainder = fileInfoList.length % userSetList.length
      userSetList.forEach((u, index) => {
        if (index < remainder) {
          userCountList.push({ name: u, count: counts + 1 })
        } else {
          userCountList.push({ name: u, count: counts })
        }
      })
    }
    let userCountTextList = [];
    // const maxCount = _.maxBy(userCountList, (item) => parseInt(item.count, 10));
    // const autoWidth = (maxCount ? 88 - (maxCount.count.toString().length - 1) * 3 : 88) + '%';
    userCountList.forEach(d => {
      let userInfo = d.name && d.name.split(',');
      userCountTextList.push(
        <div key={userInfo[0]} className='file-inner-body'>
          <div className='file-inner-left user-set-flex'>{userInfo[1]}</div>
          <div className='file-setting-right'>
            <div>{d.count}</div>
            <div>&nbsp;件</div>
          </div>
        </div>);
    });
    return (
      <div style={{cursor:'default'}}>
        <MDBModal
          isOpen={this.props.isShowEdit}
          toggle={this.props.toggleEdit}
          centered={true}
          size={'fluid'}
          fade={false}
          overflowScroll={false}
          className={"model-user-set-center"}
          contentClassName={confirmFlg ?  "file-confirm-content" : "file-common-content"}
          >
          {confirmFlg && <MDBModalBody className="file-setting-content">
              <div className="file-user-set-title"><h1>{'設定内容の確認'}</h1></div>
              <div style={{marginLeft:'18px'}}><span className='fileInfoList'>以下の内容で担当者を設定します。</span></div>
              <div className='file-delete-body-inner'>
                {userCountTextList.length>0 ? userCountTextList : <div style={{padding:'16px',color:'#212121',fontSize: '13px',fontFamily: 'Noto Sans JP'}}>担当者なし</div>}
              </div>
              <div className='file-delete-body-icon'>担当者が既に設定されているファイルの処理：</div>
              <div className="file-setting-radio"><Radio
                id="pass"
                label={''}
                name="overwrite"
                value="1"
                checked={overwrite === "1"}
                onChange={this.handleRadio}
              /><label htmlFor='pass' className='label-set'><span className='radio-set'>上書きせずにスキップする</span></label>
              <Radio
                id="cover"
                label={''}
                name="overwrite"
                value="2"
                checked={overwrite === "2"}
                onChange={this.handleRadio}
              /><label htmlFor='cover' className='label-set'><span className='radio-set-other'>上書きする</span></label></div>

              <div className='custom-ui-confirm-alert-button-group'>
                <Button
                  className='primary-btn confirmAlert-button-right'
                  style={{marginRight:'0px'}}
                  onClick={() => {this.handleUserSet(fileInfoList, userCountList)}}
                >{'設定を適用'}
                </Button>
                <button
                  className='predict-admin-botton-text confirmAlert-button-left'
                  onClick={() => {this.setState({confirmFlg: false })}}>
                  {'戻る'}
                </button>
              </div>
          </MDBModalBody>}
          {!confirmFlg && <MDBModalBody className="file-delete-body">
              <div className="file-user-set-title"><h1>{'担当者を設定'}</h1></div>
              <div style={{marginLeft:'18px'} } className='fileInfoList'><span>{fileInfoList.length}</span> 件 のファイルの担当者を選択してください。</div>
              <div className='file-set-body-inner'>
              {userSetList.length > fileInfoList.length ?
                <Dropdown
                  placeholder='担当者なし'
                  options={this.props.users}
                  fluid
                  multiple
                  search
                  selection
                  value={userSetList}
                  name='users'
                  onChange={this.handleChange}
                  style={{borderColor:'#F26868',color:'#212121',fontSize:'14px',fontFamily:'Noto Sans JP' }}
                  noResultsMessage={intl.get('_predict.admin.PdfSearch.NoDataToSelect')}
                  /> : <Dropdown
                  placeholder='担当者なし'
                  options={this.props.users}
                  fluid
                  multiple
                  search
                  selection
                  value={userSetList}
                  name='users'
                  onChange={this.handleChange}
                  style={{color:'#212121',fontSize:'14px',fontFamily:'Noto Sans JP' }}
                  noResultsMessage={intl.get('_predict.admin.PdfSearch.NoDataToSelect')}
                  />}
              </div>
              <div className ="file-user-set-link">
                {userSetList.length === 20 ? <Link style={{'pointer-events': 'none'}} to="#"
                  >
                  <u style={{'color':'#ccc'}}>自分を選択する</u>
                </Link> : <Link to="#"
                  onClick={this.handleUserAdd}
                  >
                  <u>自分を選択する</u>
                </Link>}
              </div>
              <br/>
            <div className="file-user-set-link-label">{userSetList.length} 名
            <span className="predict-admin-list-action-menu-label-detail">選択中 </span>
            </div>
              {userSetList.length <= fileInfoList.length && userSetList.length === 20 && <div className="file-delete-body-icon">
                <ErrorIcon color='#E0A029' size='24px' />
                一度に選択できる担当者数の上限(20名)に達しています
              </div>}
              {userSetList.length > fileInfoList.length && <div className="file-set-body-icon">
                <ErrorIcon color='#F26868' size='24px' /> 担当者は対象ファイル数以下になるように選択してください
              </div>}
              <div className='custom-ui-confirm-alert-button-group'>
                <Button
                  className='primary-btn confirmAlert-button-right'
                  style={{marginRight:'0px'}}
                  onClick={() => {this.setState({confirmFlg: true })}}
                  disabled={userSetList.length > fileInfoList.length}
                >{'確認画面へ'}
                </Button>
                <button
                  className='predict-admin-botton-text confirmAlert-button-left'
                  onClick={() => {
                    this.setState({
                      overwrite: '1',
                      userSetList: [],
                      confirmFlg: false
                    })
                    this.props.toggleEdit(false);
                  }}>
                  {intl.get('_predict.detail.mapping.Close')}
                </button>
              </div>

          </MDBModalBody>}
        </MDBModal>
      </div>
    );
  }
}
