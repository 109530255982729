import React from 'react';
import { Button, Message, Pagination, Segment, Table } from 'semantic-ui-react';
import api from '../../api';
import intl from 'react-intl-universal';
import { confirmAlert } from 'react-confirm-alert';

const TABLE_COLS = [
  {
    label: 'ID',
    key: 'id',
  },
  {
    label: 'Project Name',
    key: 'projectName',
  },
  {
    label: 'User Name',
    key: 'userName',
  },
  {
    label: 'User Email',
    key: 'userEmail',
  },
  {
    label: 'Operation',
    key: 'operation',
  },
];

export default function ProjectUserWholeTable(props) {
  const renderHeaderCell = () => {
    return TABLE_COLS.map((col, index) => (
      <Table.HeaderCell key={index}>{col.label}</Table.HeaderCell>
    ));
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: intl.get('_common.Delete'),
      message: intl.get(
        '_project.project.ProjectEdit.Are you sure to do this?'
      ),
      buttons: [
        {
          label: intl.get('_common.Delete'),
          onClick: async () => {
            await api.delete(`/dppapi/admin/project-user/${id}`);
            props.handleSearch();
          },
        },
        {
          label: intl.get('_common.Cancel'),
        },
      ],
    });
  };

  const handlePageChange = (event, data) => {
    props.handleSearch(data.activePage);
  };

  const renderCell = (prjUsersRslt) => {
    return prjUsersRslt.map((prjUser, index) => (
      <Table.Row key={index}>
        {TABLE_COLS.map((col) => (
          <Table.Cell key={index + col.key}>
            {col.key === 'operation' ? (
              <React.Fragment>
                <Button
                  icon="trash"
                  label="Delete"
                  size="mini"
                  onClick={() => handleDelete(prjUser['id'])}
                />
              </React.Fragment>
            ) : (
              prjUser[col.key]
            )}
          </Table.Cell>
        ))}
      </Table.Row>
    ));
  };

  const { prjUsersRslt, page } = props;

  return prjUsersRslt.length > 0 ? (
    <Segment>
      <Table celled padded>
        <Table.Header>
          <Table.Row>{renderHeaderCell()}</Table.Row>
        </Table.Header>

        <Table.Body>{renderCell(prjUsersRslt)}</Table.Body>
      </Table>
      <Pagination
        size="mini"
        secondary
        activePage={page.pageNo}
        totalPages={typeof page.pageCount === 'undefined' ? 1 : page.pageCount}
        onPageChange={handlePageChange}
      />
    </Segment>
  ) : (
    <Message info header="No Data" content="Please change conditions" />
  );
}
