import React, { Component } from 'react';
import { Popup, Button } from 'semantic-ui-react'
import { PageType } from './FsConstant';
import intl from 'react-intl-universal';
import { BigBookIcon } from '../../../assets/icon';

export default class AccountTitleDictionary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enterBook: true,
        }
    }
    render() {
        const {
            tenantPreference,
            isShowSearchFlg,
            currentPageType,
            toggleSearch,
            ssCommon,
            filterItems,
        } = this.props;
        // the vertical column grid do not show when seted tenant of value is 'true'
        const ss_common_flag = tenantPreference["template_SS"] === "COMMON_TABLE" && currentPageType === PageType.SS;

        return (
            <Button.Group className='menu-button-group menu-change-group-view-btn'>
                {!(ss_common_flag && ssCommon) ? < Popup
                    trigger={
                        isShowSearchFlg === 'true' && <Button
                            positive
                            icon
                            onClick={() => toggleSearch(true)}
                            className="menu-scroll-header-menu-search-btn"
                            onMouseEnter={() => this.setState({ enterBook: true })}
                            onMouseLeave={() => this.setState({ enterBook: false })}
                            disabled={filterItems && filterItems.length > 0}
                        >
                            <BigBookIcon isHover={this.state.enterBook} />
                        </Button>
                    }
                    position="top left"
                    content={intl.get('_predict.detail.mapping.Account title dictionary')}
                    on="hover"
                    hideOnScroll
                    inverted
                /> : null}
            </Button.Group>
        )
    }
}