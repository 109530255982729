import React from 'react';
import { Dropdown } from 'semantic-ui-react';

export default ({ id, options, value, onMappingItemChange, disabled, category, type }) => {

  return <Dropdown
    placeholder='Select'
    fluid
    search={type === 'inputText'}
    selection
    lazyLoad
    value={value}
    disabled={disabled}
    options={options}
    onChange={(e, { value }) => onMappingItemChange(id, value, category)}
    clearable
  />
}