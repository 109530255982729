import React, { Component } from 'react';
import { Header, Form, Segment, Button } from 'semantic-ui-react';
import api from '../api'
import intl from 'react-intl-universal';
import { confirmAlert } from 'react-confirm-alert';
import {
  withRouter
} from 'react-router-dom';
import { ROOT_URI } from '../HistoryListener';
import { publicEncrypt } from '../common/EncryptUtil';
import { createCookie } from '../common/CookieUtil';
// import { cryptoEncrypt } from '../common/EncryptUtil';
class TenantLoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: null,
      loading: false,
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.handleLoginSuccess = this.handleLoginSuccess.bind(this);
  }

  async handleLogin(e) {
    const { email, password } = this.state;

    try {
      this.setState({ loading: true })
      
      const reqBody = {
        email: email,
        password: publicEncrypt(password, window.$config.$pubkey)
      }
      let { permission, success, url, user, oktaEmail } = (await api.post('/dppapi/login', reqBody)).data

      this.setState({ error: null });
      localStorage.setItem("permission", permission);
      localStorage.setItem("url", url)
      
      createCookie('tenant_id', user.tenantId, 365);
      
      if (oktaEmail) {
        confirmAlert({
          title: intl.get('_login.TenantLoginPage.Confirm'),
          message: intl.get('_login.TenantLoginPage.AlertMessage'),
          buttons: [
            {
              label: intl.get('_login.TenantLoginPage.Confirm'),
              onClick: async () => {
                try {
                  await api.put(`/dppapi/user/okta/${user.id}`, {
                    email: oktaEmail
                  })
                } catch(error) {
                  const { msg } = error.data
                  this.setState({ error: msg.email.content, loading: false });
                }
              },
            },
            {
              label: intl.get('_login.TenantLoginPage.Cancel'),
              onClick: async () => {
                if ( success && url ) {
                  this.handleLoginSuccess();
                }
              },
            },
          ],
        });
      } else {
        if ( success && url ) {
          this.handleLoginSuccess();
        }
      }

    } catch(error) {
      const { message } = error.data
      this.setState({ error: message, loading: false });
    }
  }

  handleLoginSuccess() {
    const redirectTo = (new URL(window.location.href)).searchParams.get('redirectTo')
    let url = localStorage.getItem("url")

    if (redirectTo) {
      url = redirectTo
    }
    this.setState({ loading: false });
    this.props.history.push(url.replace(ROOT_URI, ''));
  }

  render() {
    return (
      <Segment>
        <Form>
          <Header>{intl.get('_login.TenantLoginPage.UserLogin')}</Header>
          <Form.Input
            onChange={(e, { value }) => this.setState({ email: value })}
            type="text"
            label={intl.get('_login.TenantLoginPage.User')}
          />
          <Form.Input
            onChange={(e, { value }) => this.setState({ password: value })}
            type="password"
            label={intl.get('_login.TenantLoginPage.Password')}
          />
          
          <Button
            loading={this.state.loading}
            disabled={this.state.loading}
            secondary={this.state.loading}
            onClick={this.handleLogin}>{intl.get('_login.TenantLoginPage.Signin')}</Button>
          <Button primary
            floated='right'
            onClick={() => { window.location = `/dppui/login` }}>{intl.get('_login.TenantLoginPage.TenantUser')}</Button>
          <Button
            color='green'
            floated='right'
            onClick={() =>{ window.location = `/dppui/login/retrieve`}}>{intl.get('_login.LoginPage.ResetPassword')}</Button>
          <p style={{ color: 'red' }}>{this.state.error}</p>
        </Form>
      </Segment>
    );
  }
}

export default withRouter(TenantLoginPage);
