const SIMILARITY_THRESHOLD = 0.015;

const utils = {
  /**
   * Scale down the image to the specified width ad height
   *
   * @param {*} image
   * @param {*} width
   * @param {*} height
   */
  compress: function (image, width = 8, height = 8) {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    const context = canvas.getContext('2d');
    context.drawImage(image, 0, 0, width, height);
    return context.getImageData(0, 0, width, height).data;
  },
  /**
   * Convert to averaged colors (shades of gray)
   *
   * @param {*} data
   */
  img2gray: function (data) {
    const grays = new Array(data.length / 4);
    for (let i = 0; i < grays.length; i++) {
      let j = i * 4;
      grays[i] = Math.round((data[j] + data[j + 1] + data[j + 2]) / 3);
    }
    return grays;
  },
  /**
   * Get the average of an array of numbers
   *
   * @param {*} data
   */
  average: function (data) {
    var total = 0;
    for (var i = 0; i < data.length; i++) {
      total += data[i];
    }
    return Math.round(total / data.length);
  },
  /**
   * Each byte is converted in a positive bit (1) if the byte is greater or equal than the average
   * 
   * e.g.
   * data: [[231,165,99,...,86,216],...,[...]], average: 100
   * =>
   * [[1,1,0,...,0,1],...,[...]]
   * 
   * @param {*} data 
   * @param {*} average 
   */
  threshold: function (data, average) {
    return utils.mapToBits(data, function (byteData) {
      return byteData >= average;
    });
  },
  mapToBits: function (data, callback) {
    let result = 0, bit = 0;
    data.forEach(function (element) {
      result |= callback(element) << bit++;
    });
    return result;
  },
  /**
   * Count the diferent bit between 2 images
   * 
   * @param {*} bitsA 
   * @param {*} bitsB 
   */
  hammingDistance: function(bitsA, bitsB){
    const diffMask = (bitsA ^ bitsB).toString(2);
    return (diffMask.match(/1/g)||[]).length;
  }
};

export const hash = (image) => {
  const compressedImg = utils.compress(image, 8, 8),
    grayImg = utils.img2gray(compressedImg),
    average = utils.average(grayImg),
    fingerprint = utils.threshold(grayImg, average);

  return fingerprint;
};

export const compare = (first, second) => {

  first = (first instanceof HTMLImageElement) ? hash(first) : first;
  second = (second instanceof HTMLImageElement) ? hash(second) : second;

  var distance = utils.hammingDistance(first, second);

  return parseFloat((distance / 64).toFixed(3));
}

export function same(first, second){
  const distance = compare(first, second);
  // console.debug('distance: ', distance);
  // console.debug('threshold: ', SIMILARITY_THRESHOLD);
  // console.debug('========================')
  return distance <= SIMILARITY_THRESHOLD;
}

export function crop(imageData, coordinate, imageWidth, imageHight){

  const { lat: y1, lng: x1} = coordinate[0] // lower left
  const { lat: y2, lng: x2} = coordinate[1] // upper right

  const width  = Math.abs(x2 - x1) + 1
  const height = Math.abs(y2 - y1) + 1
  const cropedImageData = new Uint8ClampedArray(4 * width * height)
  for (let z = y2; z >= y1; z--) {
    const idx = y2 - z
    cropedImageData.set(imageData.slice( 4 * ((imageHight - z - 1) * imageWidth + x1  ), 4 * ((imageHight - z -1 )*imageWidth + x1 + width )), 4 * (idx * width ))
}

  return {cropedImageData, width, height}
}

export function getDataUrlFromArr(arr, w, h) {

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = w;
  canvas.height = h;

  const imgData = ctx.createImageData(w, h);
  imgData.data.set(arr);
  ctx.putImageData(imgData, 0, 0);

  return canvas.toDataURL();
}

export function getImgFromDataUrl(data) {
  const img = document.createElement('img');
  img.src = data;
  return img;
}

export function createNewImage(height, width, img) {
  const canvas = document.getElementById('test-canvas');
  const ctx = canvas.getContext('2d');

  const limit_h = 3500;
  const scale = height <= limit_h ? 1.0 : limit_h / height;
  const sHeight = height;
  const sWidth = width;
  canvas.height = sHeight;
  canvas.width = sWidth;
  ctx.imageSmoothingQuality = 'high';
  ctx.imageSmoothingEnabled = true;
  ctx.drawImage(img, 0, 0, sWidth, sHeight);
  const imgB64 = canvas
    .toDataURL()
    .substring('data:image/png;base64,'.length);

  canvas.height = height;
  canvas.width = width;
  ctx.drawImage(img, 0, 0, width, height);
  const data = ctx.getImageData(0, 0, width, height).data;
  return { data, imgB64, scale };
}
