import React, { Component } from 'react';
import intl from 'react-intl-universal';

import _ from 'lodash';
import { Table, Popup, Input, Button, Dropdown, Loader } from 'semantic-ui-react';
import { PlaylistAdd, DeleteOutlineOutlined, Reorder } from '@material-ui/icons';

import EditBox from './EditBox';
import { formatMoney, ignoreSpecialCharForCalc } from '../../Util';
import { getClass } from '../../StyleUtil';
import FsMappingItem from './FsMappingItem';
import { COLUMN_TYPE } from '../../Constant';
import { AllDetailTableHeader } from './FsConstant';
import { genId } from '../../../label/label-app/utils';
import { CancelIcon, LinkOffIcon, MoreVertIcon } from '../../../assets/icon';
import { AnalyticsServiceContext } from '../../../common/Analytics/AnalyticsServiceProvider';
import { EventName, EventType } from '../../../common/Analytics/analyticsMetrics';


let isComposition = false;
const isChrome = navigator.userAgent.indexOf('Chrome') > -1;

export default class DetailTableItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      numerical: [],
      keepHover: false,
      isChanging: false,
      selectableInputOffsetTop: 0,
      isScrumOption: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleComposition = this.handleComposition.bind(this);
    this.container = React.createRef();
  }

  static contextType = AnalyticsServiceContext;

  shouldComponentUpdate(nextProps) {
    if (this.state.isScrumOption && (!this.props.row.scrumItem || !nextProps.row.scrumItem)) {
      this.setState({ isScrumOption: false });
      if (this.props.row.scrumItem !== nextProps.row.scrumItem) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.row.scrumItem && this.props.row.scrumItem && prevProps.row.id === this.props.row.id) {
      this.container.current.focus();
    }

    // 編集中のテキストフィールドの位置を取得し、編集キャンセルボタンの位置を指定する
    let inputs = document.querySelectorAll('.fs-detail-td-selectable-input');
    let selectableInputOffsetTop = 0
    if (inputs && inputs[0]) {
      selectableInputOffsetTop = inputs[0].offsetTop - 5;
      if (selectableInputOffsetTop < 0) {
        selectableInputOffsetTop = 0
      }
    }

    if (selectableInputOffsetTop !== this.state.selectableInputOffsetTop) {
      if (inputs && inputs[0]) {
        this.setState({
          selectableInputOffsetTop: selectableInputOffsetTop,
        })
      }
    }
  }

  handleComposition(e, r) {
    if (e.type === 'compositionend') {
      isComposition = false;
      let data = e.target.value.replace(/[^0-9,-]/g, '');
      if (!isComposition && isChrome) {
        this.handleChange(e, data, r);
      }
    } else {
      isComposition = true;
    }
  }

  handleCompositionDropDown(e, r) {
    if (e.type === 'compositionend') {
      isComposition = false;
    } else {
      isComposition = true;
    }
  }

  onOpenInput = (state) => {
    const {
      handleRowOperation,
      detailIds,
      row,
      selectedCell,
    } = this.props;
    this.setState({ editing: state }, () => {
      if (_.isEqual(selectedCell.cellIds.sort(), detailIds.sort()) &&
        row.id === selectedCell.id) {
      } else {
        handleRowOperation(row, detailIds);
      }
      if (state) {
        this.handleOpenInputMode();
      }
    })
  };

  handleOpenInputMode = () => {
    this.setState({ editing: true }, () => {
      this.input.focus();
      this.input.select();
    });
    this.onSetTableNotHoverable();
  }

  onDragStart = (ev, i) => {
    ev.dataTransfer.setData("index", i);
  };

  onDragOver = (ev, index) => {
    ev.preventDefault();
    this.props.changeDragIntoIndex(index)
  };

  onDrop = (ev, a) => {
    // a => after idx,  b => brfore idx,
    // a > b drag to down
    // a < b drag to up
    let b = ev.dataTransfer.getData("index");

    if (parseInt(a) < parseInt(b)) {
      a = parseInt(a) + 1
    }
    this.props.onDropRow(b, a);
    this.props.onEdit(true);
    this.props.changeDragIntoIndex(-1)
  };

  handleSaveData = (initValue, r) => {
    const {
      onGetSum,
      onUpdateBBox,
      handleChangeValue
    } = this.props

    this.setState({
      keepHover: false,
      editing: false,
      isChanging: false,
    });
    let convertText = initValue;
    if (initValue && initValue.startsWith('=')) {
      convertText = convertText.substring(1);
      if (!convertText) {
        this.setState({ numerical: [r.scrumItem, '', true] });
      } else {
        try {
          let editValue = initValue.replace(/,/g, '');
          editValue = ignoreSpecialCharForCalc(editValue);
          convertText = new Intl.NumberFormat('ja-JP', { style: 'decimal', currency: 'CAD', }).format(Math.round(eval(editValue.substring(1))));
          this.setState({ numerical: [r.scrumItem, convertText, false] });
        } catch (error) {
          convertText = intl.get('_predict.detail.mapping.Formula input error');
          this.setState({ numerical: [r.scrumItem, '', true] });
        }
      }

    }
    if (convertText && convertText.replace('-', '').length > 18) {
      convertText = intl.get('_predict.detail.mapping.More than 14 digits');
      this.setState({ numerical: [r.scrumItem, '', true] });
    }
    const inputText = !this.state.numerical[1] || initValue.startsWith('=') ? convertText : this.state.numerical[1];
    if (convertText === onGetSum([r.value])) {
      this.setState({ editing: false })
    } else {
      if (r.value.invert) {
        // this.props.onEdit(true);
        const valueObj = r.value
        valueObj.invert = false
        handleChangeValue([valueObj])
      }
      onUpdateBBox(inputText, r.value.id);
    }
  }

  handleCancel = (e) => {
    e.stopPropagation()
    this.setState({
      numerical: [],
      editing: false,
    })
    this.props.onOpenModeChange(true);
  }

  /* eslint no-eval: 0 */
  handleChange = (e, money, r) => {
    //It can be triggered only when the input method is not used or after using the input method
    if(money && money.startsWith('=')){
      let target = e.target;
      this.setState({}, () => {
        target.selectionStart = 0;
        target.selectionEnd = 0;
      })
      return
    }
    if (!isComposition) {
      let target = e.target;
      const [convertText, finalPosition, finalSetFlag, headMinusFlag] = formatMoney(target.selectionStart, money);
      if (headMinusFlag) {
        if (convertText.length > 19 && !convertText.startsWith('=')) return;
      } else {
        if (convertText.length > 18 && !convertText.startsWith('=')) return;
      }
      if (finalSetFlag) {
        this.setState({ numerical: [r.scrumItem, convertText, false] });
      }
      this.setState({}, () => {
        target.selectionStart = finalPosition;
        target.selectionEnd = finalPosition;
      })
    } else {
      this.setState({ numerical: [r.scrumItem, money, false] });
    }
    this.setState({ isChanging: true });
  }

  onHoverChange = (state, name) => this.setState({ keepHover: state, popItemName: name ? name : 'EditBox' });

  onSetTableNotHoverable = () => {
    const { selectedCell, detailIds, row, handleRowOperation, onOpenModeChange } = this.props;
    onOpenModeChange(false);
    if (selectedCell.id === '') { // when this row had been selected by single click
      handleRowOperation(row, detailIds);
    }
  }

  onDetailMappingItemChange = (id, value, category) => {
    const { row, onMappingItemChange, handleRowOperation, selectedCell, detailIds } = this.props;
    let isSelected = false;
    if (
      selectedCell.cellIds &&
      selectedCell.cellIds.length > 0 &&
      detailIds &&
      detailIds.length > 0
    ) {
      isSelected =
        _.isEqual(selectedCell.cellIds.sort(), detailIds.sort()) &&
        id === selectedCell.id;
    }

    onMappingItemChange(id, value, category);
    !isSelected && handleRowOperation(row, detailIds);
  }

  renderCell = (
    key,
    cell,
    cellDetail,
    category,
    id,
    onScrumItemChange,
    headerSetting,
    idx,
    row,
    detailItem,
    isSelected,
    onHoverChange,
    handleSetOppositeValue,
    onEdit,
    onGetSum,
    handleUnLinkScrumItem,
    amountImageDisplay,
    scrumMapingButtonDisplay,
    changeFontColor,
    columnStyles,
    handleRowOperation,
    detailIds,
    openable,
    tabIndex,
    onOperationMethodChange,
    detailItemChanging,
    onDetailMappingItemChange,
    onOpenModeChange,
    isNotOwner,
    onShowExclusionControl,
  ) => {
    const { autoComplete, link } = headerSetting;
    const { editing, numerical, keepHover, isChanging } = this.state;
    const mismatchedCell = (
      <Table.Cell
        className={`select-row-detail-container ${isSelected ? 'select-row-detail' : ''}`}
        verticalAlign="middle"
        key={`${id}_${genId()}`}
        style={columnStyles(1, 1)}
        onClick={(e) => {
          if (e.detail === 1) {
            handleRowOperation(row, detailIds)
          }
        }}
      >
        {' '}
      </Table.Cell>
    );
    const column = AllDetailTableHeader.find(col => col.name === key);
    const type = column ? column.type : undefined;
    let renderEditBox;

    const scrumMapingButton = detailItem.scrumFieldName && (
      <div className="detail-scrum-mapping-btn">
        <span>{intl.get('_predict.detail.mapping.Candidate')}</span>
        <div
          onClick={() => {
            if (isNotOwner) {
              onShowExclusionControl(true);
              return;
            }
            this.setState({ isScrumOption: true });
            onScrumItemChange(
              row.value.id,
              detailItem.scrumFieldName,
              false
            )
          }}
        >
          <Button
            disabled={isNotOwner}
            id="ga-predict-detail-bbox-popup-item-recommend"
            content={detailItem.scrumFieldName}
            tabIndex={tabIndex}
            basic
            className="maybe_btn_detail"
            onKeyDown={event => {
              if (event.keyCode === 13) {
                this.setState({ isScrumOption: true });
                onScrumItemChange(
                  row.value.id,
                  detailItem.scrumFieldName,
                  false
                )
              }
            }}
          />
        </div>
      </div>
    );

    let initValue;
    if (!numerical[2] && (numerical[1] === undefined || !isChanging)) {
      initValue = onGetSum([row.value]);
    } else {
      initValue = numerical[1];
    }
    switch (type) {
      case COLUMN_TYPE.SELECTBBOX:
        // selectBBox type column must be {id:xxx, text:xxx}
        if (cell.id === undefined || cell.text === undefined) {
          return mismatchedCell;
        }
        var cellText = key === 'value' ? onGetSum([cell]) : cell.text;

        if (key === 'value') {
          renderEditBox = (parentElement) => {
            return <EditBox
              rowScrumItem={row.scrumItem}
              rowValues={[cell]}
              onHoverChange={onHoverChange}
              parentElement={parentElement}
              isDetail={false}
              isTotalDetail={true}
              openable={isSelected || openable}
              handleSetOppositeValue={handleSetOppositeValue}
              onEdit={onEdit}
              onOpenInput={this.onOpenInput}
              onGetSum={onGetSum}
              showEditBtn={true}
              isNotOwner={isNotOwner}
              onShowExclusionControl={onShowExclusionControl}
            />;
          }
        }
        const changeFontColorText = changeFontColor(cellText, cellDetail.accuracy, cellDetail.accThreshold, cellDetail.stateFlag);
        const isFormatWarning = cellDetail.stateFlag === 5 && amountImageDisplay;
        const isLowAccWarning = changeFontColorText[1] && key === 'value';
        const twoIconStyle = isFormatWarning ? { marginLeft: '5px' } : {};
        return (
          <Table.Cell
            verticalAlign="middle"
            key={`${id}_${cell.id}_${idx}`}
            className={
              `td-text-selectable 
            ${isSelected ? 'select-row-detail' : ''} 
            ${headerSetting.key === 'value' ? 'page-num edit-box-detail-td' : ''}
            ${isLowAccWarning && !isFormatWarning ? 'lowacc-warning-background' : ''}
            ${isFormatWarning ? 'format-warning-background' : ''}
            `}
            style={{ ...columnStyles(1, 1), textDecoration: "none" }}
            onMouseEnter={() => { key === 'value' && onHoverChange(true, row.item.text) }}
            onMouseLeave={() => { key === 'value' && onHoverChange(false, row.item.text) }}
            onClick={(e) => {
              if (e.detail === 1) {
                handleRowOperation(row, detailIds)
              }
            }}
          >

            {key === 'value' ?
              !editing ? (
                <>
                  {isFormatWarning ? (
                    <Popup
                      trigger={
                        <div className='format-warning-icon'>
                          <i aria-hidden="true" className="exclamation icon"
                            onMouseEnter={() => {
                              this.setState({ keepHover: false });
                            }}
                          />
                        </div>
                      }
                      position="top center"
                      content={intl.get('_predict.detail.mapping.Beware of the digits')}
                      on="hover"
                      hideOnScroll
                      inverted
                    />
                  ) : null}
                  {isLowAccWarning ? (
                    <>
                      <Popup
                        trigger={
                          <div className='lowacc-warning-icon'
                            style={twoIconStyle}
                          >
                            <i aria-hidden="true" className="question icon"
                              onMouseEnter={() => {
                                this.setState({ keepHover: false });
                              }}
                            />
                          </div>
                        }
                        position="top center"
                        content={intl.get('_predict.detail.mapping.Beware of low confidence characters')}
                        on="hover"
                        hideOnScroll
                        inverted
                      />
                    </>) : null}
                  {renderEditBox(
                    <div
                      className={`edit-box-item-for-detail ${keepHover ?
                        'edit-box-div-border edit-box-item-for-detail-grey-border' : ''} ${isSelected ? 'select-row-detail-boldFont'
                          : ''}`}
                    >
                      {detailItemChanging && isSelected ?
                        (<Loader active inline size='small' />)
                        :
                        changeFontColorText[0]
                      }
                    </div>
                  )}
                </>
              )
                : (<Input
                  size='mini'
                  className={'fs-detail-td-selectable-input'}
                  value={initValue || ''}
                  placeholder='半角数字入力'
                  fluid
                  ref={(input) => this.input = input}
                  onChange={(e, data) => {
                    this.handleChange(e, data.value, row);
                  }}
                  onCompositionStart={(e) => { this.handleComposition(e, row) }}
                  onCompositionEnd={(e) => { this.handleComposition(e, row) }}
                  onKeyDown={event => {
                    if (event.keyCode === 9 || event.keyCode === 13) {
                      event.stopPropagation();
                      event.preventDefault();
                      this.handleSaveData(initValue, row);
                      onOperationMethodChange("next_line");
                      onOpenModeChange(true);
                    }
                    if (event.keyCode === 27) { // esc key
                      this.setState({
                        numerical: [],
                        editing: false
                      });
                      onOpenModeChange(true);
                    }
                  }}
                  onBlur={() => {
                    this.handleSaveData(initValue, row);
                    onOpenModeChange(true);
                  }}
                />)
              : <span style={{ padding: "2px 0px" }}>{cellText}</span>
            }
            {key === 'value' && detailItem.dataUrl && amountImageDisplay ?
              <div style={{ padding: "2px 6px" }}>
                <img className='detail-popup-value-img' src={detailItem.dataUrl} alt='' />
              </div> : null}

          </Table.Cell>
        );
      case COLUMN_TYPE.INPUTTEXT:
        return (
          <Table.Cell
            className={`select-row-detail-container ${isSelected ? 'select-row-detail' : ''}`}
            verticalAlign="middle"
            key={`${id}_${key}`}
            style={columnStyles(1, 1)}
            onClick={(e) => {
              if (e.detail === 1) {
                handleRowOperation(row, detailIds)
              }
            }}
          >
            <React.Fragment>
              {cell !== '' && cell !== undefined ? (
                <div tabIndex={0} className='fs-detail-connection-subject-container' ref={this.container}>
                  <div className='fs-detail-connection-subject'>{cell}</div>
                  <Popup
                    disabled={isNotOwner}
                    trigger={
                      <span
                        id="ga-predict-detail-mapping-listview-item-unlink"
                        className="board-item-wrap-bottom-icon unlink-icon-style"
                        tabIndex={tabIndex}
                        onClick={() => {
                          if (isNotOwner) {
                            onShowExclusionControl(true);
                            return;
                          }
                          if (row.value) {
                            handleUnLinkScrumItem(row.value.id);
                          }
                        }}
                      >
                        <LinkOffIcon size={'20px'} disabled={isNotOwner} />
                      </span>
                    }
                    position="top center"
                    content={intl.get('_predict.detail.mapping.Cancel cooperation')}
                    on="hover"
                    hideOnScroll
                    inverted
                  />
                </div>
              ) : (
                <div className='fs-detail-td-mapping-item-container'
                  onClick={() => {
                    if (isNotOwner) {
                      onShowExclusionControl(true);
                    }
                  }}
                >
                  <FsMappingItem
                    id={id}
                    options={category}
                    value={cell === undefined ? '' : cell}
                    link={link}
                    onMappingItemChange={onDetailMappingItemChange}
                    category={key}
                    type={type}
                    row={row}
                    showPlaceholder={false}
                    disabled={isNotOwner}
                  />
                </div>
              )}
              {key === 'scrumItem' && scrumMapingButtonDisplay ?
                (<div className="detail-popup-passed">
                  {scrumMapingButton}
                </div>) : null}
            </React.Fragment>
          </Table.Cell>
        );
      case COLUMN_TYPE.SELECTION:
        // sel type column must be string
        if (typeof cell !== 'string') {
          return mismatchedCell;
        }
        return (
          <Table.Cell
            verticalAlign="middle"
            className={`select-row-detail-container ${isSelected ? 'select-row-detail' : ''}`}
            style={columnStyles(1, 1)}
            onClick={(e) => {
              if (e.detail === 1) {
                handleRowOperation(row, detailIds)
              }
            }}
          >
            <div style={{ padding: "2px 0" }}>
              <FsMappingItem
                id={id}
                options={category[autoComplete] ? category[autoComplete] : []}
                value={cell}
                link={link}
                onMappingItemChange={onDetailMappingItemChange}
                category={key}
                type={type}
              />
            </div>
          </Table.Cell>
        );
      default:
        return (
          <Table.Cell
            onClick={(e) => {
              if (e.detail === 1) {
                handleRowOperation(row, detailIds)
              }
            }}
            verticalAlign="middle"
            className={`select-row-detail-container ${isSelected ? 'select-row-detail' : ''}`}
            style={columnStyles(1, 1)}
          />
        );
    }
  }

  render() {
    const {
      index,
      row,
      detailItem,
      onScrumItemChange,
      selectedCell,
      onNormalized,
      currentBoxIdForCss,
      category,
      headerOrder,
      handleUnLinkScrumItem,
      handleSetOppositeValue,
      onEdit,
      onGetSum,
      handleRowOperation,
      allList,
      amountImageDisplay,
      scrumMapingButtonDisplay,
      changeFontColor,
      columnStyles,
      detailIds,
      onAddRow,
      onDeleteRow,
      openable,
      tabIndex,
      onOperationMethodChange,
      detailItemChanging,
      operationMethod,
      onOpenModeChange,
      currentPageType,
      projectId,
      pdfFileId,
      isNotOwner,
      onShowExclusionControl,
    } = this.props;
    const { editing } = this.state;
    const { id, pdfImageId } = row;
    let isSelected = false;
    if (
      selectedCell.cellIds &&
      selectedCell.cellIds.length > 0 &&
      detailIds &&
      detailIds.length > 0
    ) {
      isSelected =
        _.isEqual(selectedCell.cellIds.sort(), detailIds.sort()) &&
        id === selectedCell.id;
    }
    let statusBarStyle = 'fs-detail-normal-status-bar';
    if (row.scrumItem) {
      const indexes = [];
      allList.forEach((arr, index) => {
        if (arr.name === row.scrumItem || _.includes(arr.children, row.scrumItem)) {
          indexes.push(index);
        }
      })

      if (!indexes || indexes.length === 0) {
        statusBarStyle = 'fs-detail-not-relevant-status-bar';
      } else {
        statusBarStyle = 'fs-detail-success-status-bar';
        indexes.forEach((index) => {
          if (allList[index].isError) {
            statusBarStyle = 'fs-detail-error-status-bar';
          }
        });
      }
    }
    const renderRow = (
      <Table.Row
        tabIndex={-1}
        key={id}
        textAlign="left"
        draggable={row.isNewRow ? true : false}
        id={row.scrumItem}
        style={{ minWidth: '420px' }}
        className={`edit-box-table-tr
        ${this.props.dragIntoIndex === index ? 'dragInto' : ''}
        ${this.state.keepHover && openable ? 'tr-backGround' : ''}
        ${openable ? 'edit-box-able' : 'edit-box-unable unhoverRow'}
        ${getClass(
          row.isNewRow ? 'new' : 'row',
          {
            rowId: id,
            cellIds: detailIds,
            scrumItem: row.scrumItem
          },
          selectedCell,
          currentBoxIdForCss,
          'value'
        )}`}
        onClick={e => {
          if (e.detail === 2 && !detailItemChanging) {
            e.stopPropagation();
            // if (!this.props.isNotOwner) {
            this.handleOpenInputMode();
            // }
          }
        }}
        onCompositionStart={(e) => { this.handleCompositionDropDown(e, row) }}
        onCompositionEnd={(e) => { this.handleCompositionDropDown(e, row) }}
        onKeyDown={event => {
          const currentTagName = document.activeElement.tagName.toLowerCase();
          const currentTagClass = document.activeElement.className;
          const isLinked = currentTagName === "div" && currentTagClass === 'fs-detail-connection-subject-container';
          const isUnlinked = currentTagName === "input" && currentTagClass === 'search' && !row.scrumItem;
          if (event.key === 'Tab' && !event.shiftKey && !isComposition) {
            if (!isSelected) {
              if (isUnlinked) {
                event.preventDefault();
                handleRowOperation(row, detailIds);
                this.handleOpenInputMode();
              }
            } else {
              if (isLinked || isUnlinked) {
                event.preventDefault();
                this.handleOpenInputMode();
              }
            }
          }
          if (event.key === 'Tab' && event.shiftKey) {
            if (currentTagName === 'tr') {
              event.preventDefault();
              this.handleOpenInputMode();
              onOperationMethodChange("prev_element");
            }
          }
          if (currentTagName === 'input' && currentTagClass === 'search') {
            if (event.key === 'Enter' && !isComposition) {
              const selectedOption = document.activeElement.parentNode.childNodes[1].innerHTML;
              selectedOption && selectedOption !== '' && this.onDetailMappingItemChange(id, selectedOption, "scrumItem");
            }
            if (event.key === 'Tab') {
              document.activeElement.parentNode.childNodes[1].innerHTML = "";
            }
          }
        }}
        onFocus={event => {
          const focusedTagName = event.target.tagName.toLowerCase();
          if (focusedTagName === 'tr' && selectedCell.id !== '' && operationMethod === 'tab') {
            event.preventDefault();
            var fourthChild = event.target.childNodes[3];
            var divElement = fourthChild && fourthChild.querySelectorAll(".fs-detail-connection-subject-container");
            var inputElement = fourthChild && fourthChild.querySelectorAll("input");

            var buttonElement = fourthChild && fourthChild.querySelectorAll("button");
            if (buttonElement.length !== 0) {
              buttonElement[0].focus();
            } else {
              divElement.length > 0 ? divElement[0].focus() : inputElement[0].focus();
            }
          }
          if (operationMethod === 'auto_open') {
            event.preventDefault();
            this.handleOpenInputMode();
          }
        }}
        onMouseDown={() => {
          onOperationMethodChange("mouse");
        }}
        onDragStart={e => {
          if (!row.isNewRow) {
            e.preventDefault();
          }
          this.onDragStart(e, index)
        }}
        onDragOver={e => this.onDragOver(e, index)}
        onDrop={e => this.onDrop(e, index)}
      >
        {!row.isNewRow ?
          <Table.Cell
            verticalAlign="middle"
            style={columnStyles(0, 1)}
            className={`page-num page-num-for-detail ${isSelected ? 'select-row-detail-start' : ''}`}
            onClick={(e) => {
              if (e.detail === 1) {
                handleRowOperation(row, detailIds)
              }
            }}
          >
            {statusBarStyle !== "" && (
              <div className={statusBarStyle} />
            )}
            <span className='page-num-span'>{onNormalized(pdfImageId)}</span>
          </Table.Cell> :
          <Table.Cell
            verticalAlign="middle"
            style={{ position: "relative", ...columnStyles(0, 1) }}
            className={`page-num page-num-for-detail${isSelected ? 'select-row-detail-start' : ''}`}
            onClick={(e) => {
              if (e.detail === 1) {
                handleRowOperation(row, detailIds)
              }
            }}
          >
            {statusBarStyle !== "" && (
              <div className={statusBarStyle} />
            )}
            <div className='fs-detail-new-row-container'>
              <Popup
                inverted
                content="ドラッグで行を移動"
                trigger={<Reorder className='fs-detail-new-row-icon' />}
              />
              <span className='fs-detail-new-row-message'>追加行</span>
            </div>
          </Table.Cell>
        }
        {headerOrder.map((header, idx) => {
          return this.renderCell(
            header.key,
            row[header.key],
            detailItem[header.key],
            category,
            id,
            onScrumItemChange,
            header,
            idx,
            row,
            detailItem,
            isSelected,
            this.onHoverChange,
            handleSetOppositeValue,
            onEdit,
            onGetSum,
            handleUnLinkScrumItem,
            amountImageDisplay,
            scrumMapingButtonDisplay,
            changeFontColor,
            columnStyles,
            handleRowOperation,
            detailIds,
            openable,
            tabIndex,
            onOperationMethodChange,
            detailItemChanging,
            this.onDetailMappingItemChange,
            onOpenModeChange,
            isNotOwner,
            onShowExclusionControl,
          );
        })}
        {!editing ? <Table.Cell
          verticalAlign="middle"
          textAlign="center"
          className={`fs-detail-predict-dropDown-container ${isSelected ? 'select-row-detail-end' : ''}`}
          onClick={(e) => {
            if (e.detail === 1) {
              if (isNotOwner) {
                onShowExclusionControl(true);
                return;
              }
              handleRowOperation(row, detailIds)
            }
          }}
        >
          {/* <span className="board-item-wrap-bottom-icon"
              style={{ marginLeft: '-2.8px' }}
            > */}
          <Dropdown
            item
            direction='left'
            disabled={isNotOwner}
            icon={<MoreVertIcon size='24px' color='#545454' />}
            floating
            className='predict-dropDown'>
            <Dropdown.Menu style={{ background: '#fff' }}>
              <Dropdown.Item
                onClick={() => {
                  onAddRow(id);
                  onEdit(true);
                  this.context.sendPredictBeautifyDetailMetrics({
                    event_type: EventType.Action,
                    event_name: EventName.AddRecord,
                    custom_parameter: {
                      pdf_id: pdfFileId,
                      project_id: projectId,
                      document_type: currentPageType,
                    },
                  })
                }}>
                <PlaylistAdd style={{ marginTop: '3px' }} />
                <span className='text'>{intl.get('_predict.detail.mapping.Add Row Below')}</span>
              </Dropdown.Item>
              {row.isNewRow &&
                <Dropdown.Item onClick={() => {
                  onDeleteRow(index);
                  onEdit(true);
                }}>
                  <DeleteOutlineOutlined style={{ marginBottom: '3px' }} />
                  <span className='text'>{intl.get('_predict.detail.mapping.Delete Row')}</span>
                </Dropdown.Item>
              }
            </Dropdown.Menu>
          </Dropdown>
          {/* </span> */}
        </Table.Cell>
          : <Table.Cell className={`${isSelected ? 'select-row-detail-end' : ''}  fs-detail-row-cancel`} >
            <div className='fs-detail-row-cancel-btn-warp' onMouseDown={(e) => this.handleCancel(e)} style={{ paddingTop: this.state.selectableInputOffsetTop }}>
              <CancelIcon color='#909090' size={'24px'} className='edit-box-icon-cancel' />
            </div>
          </Table.Cell>}
      </Table.Row>
    );

    return (
      <React.Fragment>
        {renderRow}
      </React.Fragment>
    );
  }
}

