import React, { Component } from 'react';
import { Segment, Header, Button, Loader, Input, TextArea, Form, Grid, Image } from 'semantic-ui-react';
import { confirmAlert } from 'react-confirm-alert';
import { FMT, unixMSToDatetime } from './TimeUtil';
import chunk from "lodash/chunk";
import api from '../../api'
import intl from 'react-intl-universal';

export default class Prepareing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      train: null,
      datasetText: '',
      debugText: '',
      debugTextHeight: 2,
      debugImage: '',
      debugDetailInputValue: 1,
    };

    this.handleTrainChange = this.handleTrainChange.bind(this);
    this.handleDataSet = this.handleDataSet.bind(this);
    this.handleDebugSummary = this.handleDebugSummary.bind(this);
    this.handleDebugDetail = this.handleDebugDetail.bind(this);
    this.handleDebugSegmentation = this.handleDebugSegmentation.bind(this);
    this.resetSTatusDefault = this.resetSTatusDefault.bind(this);
    this.checkDebugDetailInput = this.checkDebugDetailInput.bind(this);
  }

  async componentDidMount() {
    const { train } = this.props;
    const newTrain = (await api.get(`/dppapi/train/${train.projectId}/${train.id}`)).data;
    this.setState({
      isLoaded: true,
      train: newTrain,
      datasetText: unixMSToDatetime(newTrain.datasetStamp, FMT.DATETIME_LOCAL),
      debugText: newTrain.trainMessage,
      debugTextHeight: newTrain.trainMessage != null ? newTrain.trainMessage.split('\n').length + 2 : 2
    });
  }

  async handleTrainChange(event) {
    const {name, value} = event.target;
    const train = {...this.state.train};
    train.hyperparameters[name] = value;
    this.setState({
      train
    },
    async () => 
      await api.post(`/dppapi/train/${train.projectId}/${train.id}`, this.state.train)
    );
  }

  async resetSTatusDefault(){
    this.setState({
      debugText: '',
      debugTextHeight: 2,
      debugImage: ''
    });
  }
  
  async handleDataSet(train) {
    confirmAlert({
      title: 'データセット',
      message: 'データセットを開始しますか？',
      buttons: [
        {
          label: intl.get('_train.detail.Prepareing.OK'),
          onClick: async () => {
            await this.resetSTatusDefault();
            const newTrain = (await api.get(`/dppapi/train/reload/${train.projectId}/${train.id}`)).data;
            if (newTrain.success){
              this.setState({
                datasetText: unixMSToDatetime(newTrain.datasetStamp, FMT.DATETIME_LOCAL),
                debugText: newTrain.message,
              });
            }else{
              this.setState({
                debugText: newTrain.errmsg
              })
            }
          },
        },
        {
          label: intl.get('_train.detail.Prepareing.Cancel'),
        },
      ],
    })
  }

  async checkDebugDetailInput(event){
    const { value } = event.target;
    this.setState({
      debugDetailInputValue: value
    })
  }

  async handleDebugSegmentation(trainMessage){
    var { debugTextHeight } = this.state;
    var debugText = `Image Name:    ${trainMessage.image_name}\n`
    debugText += `segmentations:\n`
    for (const index in trainMessage.annotations){
      const item = trainMessage.annotations[index];
      const coordinate = item[0]
      debugText += `    (${coordinate[0]}, ${coordinate[1]}) (${coordinate[2]}, ${coordinate[3]})       ${item[1]}\n`;
      debugTextHeight += 1;
    }

    const x = atob(trainMessage.image);
    const y = new Array(x.length);
    for (let i = 0; i < x.length; i++) {
      y[i] = x.charCodeAt(i);
    }
    const image = URL.createObjectURL(
      new Blob([new Uint8Array(y)], { type: 'image/jpeg' })
    );
    this.setState({
      debugText: debugText,
      debugTextHeight: debugTextHeight,
      debugImage: image
    });
  }
  
  async handleDebugSummary(train) {
    confirmAlert({
      title: 'デバッグ',
      message: 'デバッグを開始しますか？',
      buttons: [
        {
          label: intl.get('_train.detail.Prepareing.OK'),
          onClick: async () => {
            await this.resetSTatusDefault();
            const newTrain = (await api.get(`/dppapi/train/debug/${train.projectId}/${train.id}`)).data;
            if (!newTrain.success){
              this.setState({
                debugText: newTrain.errmsg,
              });
            }else{
              var { debugTextHeight } = this.state;
              var debugText = '';
              for (const key in newTrain.trainMessage){
                const value = newTrain.trainMessage[key];
                if (typeof value === 'object'){
                  debugText += `${key}\n`;
                  debugTextHeight += 1;
                  for (const innerKey in value){
                    const innerValue = value[innerKey];
                    debugText += `    ${innerKey}    ${innerValue}\n`;
                    debugTextHeight += 1;
                  }
                }else{
                  debugText += `${key}    ${value}\n`;
                  debugTextHeight += 1;
                }
              }
              this.setState({
                debugText: debugText,
                debugTextHeight: debugTextHeight
              });
            }
          },
        },
        {
          label: intl.get('_train.detail.Prepareing.Cancel'),
        },
      ],
    })
  }

  async handleDebugDetail(train) {
    confirmAlert({
      title: 'デバッグ',
      message: 'デバッグを開始しますか？',
      buttons: [
        {
          label: intl.get('_train.detail.Prepareing.OK'),
          onClick: async () => {
            await this.resetSTatusDefault();
            const newTrain = (await api.get(`/dppapi/train/debug/detail/${train.projectId}/${train.id}?debugDetailId=${this.state.debugDetailInputValue}`)).data;
            if (!newTrain.success){
              this.setState({
                debugText: newTrain.errmsg,
              });
              return;
            }
            if (newTrain.type === 'segmentation'){
              await this.handleDebugSegmentation(newTrain.trainMessage);
              return;
            }
          },
        },
        {
          label: intl.get('_train.detail.Prepareing.Cancel'),
        },
      ],
    })
  }

  render() {

    const { train, datasetText, debugText, debugTextHeight, isLoaded } = this.state;

    if (!isLoaded) {
      return <Loader active inline="centered" />;
    }

    const hyperparameters = train.hyperparameters;
    var titleArrays = Object.keys(hyperparameters);
    var groups = chunk(titleArrays, 5);

    return (
      <Segment>
        <Header disabled>Hyper Parameter</Header>
        <Form>
          {groups.map((arrays, index) => {
            return (
            <Form.Group key={index} unstackable widths={5}>
              {arrays.map((data, index) => {
                return (
                  <Form.Input
                    key={index}
                    label={data}
                    defaultValue={hyperparameters[data]}
                    name={data}
                    onChange={this.handleTrainChange}
                  />
                )
              })}
            </Form.Group>)
          })}
          <div>
          <Button
            style={{width:'20%'}}
            onClick={() => {this.handleDataSet(train)}}
          >
            Reload dataset
          </Button>
          <Input
            disabled
            value={datasetText}
            style={{width:'79%'}}/>
          </div>
          <div style={{marginTop:'15px'}}>
          <Button
            style={{width:'20%'}}
            onClick={() => {this.handleDebugSummary(train)}}>
            {train.moduleId !== 2 ? "Debug" : "Debug Summary"
            }
          </Button>
          <TextArea
            disabled
            value={debugText}
            style={{width:'79%'}}
            rows={debugTextHeight}/>
          </div>
          {train.moduleId !== 2 ? (
            null
          ) : (
            <div>
              <Button
              style={{width:'20%'}}
              onClick={() => {this.handleDebugDetail(train)}}>
              Debug Image Detail
              </Button>
              <Input
                key="debugDetailInput"
                defaultValue="1"
                name="debugDetailInput"
                onChange={this.checkDebugDetailInput}/>
            </div>
          )}
        </Form>
        <Grid celled="internally">
          <Grid.Row>
          <Grid.Column width={16}>
            <Header>
              <Header.Content>{intl.get('Related image')}</Header.Content>
            </Header>
            <Segment>
              <Image src={this.state.debugImage} />
            </Segment>
          </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
