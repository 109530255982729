import React, { Component } from 'react';
import { Loader } from 'semantic-ui-react';
import api from '../../../api';
import 'semantic-ui-css/semantic.min.css';
import { genId } from '../../../label/label-app/utils';
import {
  PageTypeList,
  BSheaderList,
  BScurrentEndBanaceList,
} from '../../detail/mapping/FsConstant';
import FsMappingReview from './FsMappingReview';
import "./FsMapping.css";

export default class FsMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fsItemData: [],
    };
    this.valueList = [];
    this.inputList = [];
  }

  async componentDidMount() {
    await this.handleReload()
  }

  handleReload = async (keepPageType = false) => {
    const { projectId, pdfFileId } = this.props;
    this.setState({
      isLoading: true,
    });

    const { data } = await api.request({
      url: `/dppapi/predict/pdf/scrum/mapping/${projectId}/${pdfFileId}`,
      method: 'GET',
    });

    const headerList = BSheaderList;
    if (data.success) {
      // get DetailData and copy
      const { scrumMaps } = data;
      Object.keys(scrumMaps).forEach(scrumSubTitle => {
        if (scrumSubTitle !== 'manual_input') {
          if (scrumSubTitle === PageTypeList[0]) {
            scrumMaps[scrumSubTitle].forEach(scrum => {
              headerList.forEach(header => {
                if (scrum.scrumItem === header) {
                  this.valueList.push(scrum);
                }
              });
            });
          }
          data.scrumMaps[scrumSubTitle].forEach(d => {
            d['id'] = genId();
          });
        } else {
          Object.keys(scrumMaps[scrumSubTitle]).forEach(page => {
            if (page === PageTypeList[0]) {
              headerList.forEach(header => {
                if (scrumMaps[scrumSubTitle][page][header]) {
                  let inputValue = {
                    scrumItem: header,
                    value: {
                      id: '-1',
                      text: scrumMaps[scrumSubTitle][page][header],
                      invert: false,
                    },
                  };
                  this.inputList.push(inputValue);
                }
              });
            }
          });
        }
      });
      const CurrentEndBanaceList = BScurrentEndBanaceList;
      if (!data.scrumMaps[PageTypeList[2]]) {
        data.scrumMaps[PageTypeList[2]] = [];
      }
      CurrentEndBanaceList.forEach(tmp => {
        const value = headerList[CurrentEndBanaceList.indexOf(tmp)];
        let scrumItemList = [];
        this.inputList.forEach(inputV => {
          if (inputV.scrumItem === value) {
            scrumMaps[PageTypeList[2]].push({
              id: genId(),
              scrumItem: tmp,
              value: {
                id: '-1',
                text: inputV.value.text,
                invert: inputV.value.invert,
              },
              item: {},
              subTitle: {},
            });
            scrumItemList.push(value);
          }
        });
        let inputFlag = false;
        this.valueList.forEach(v => {
          scrumItemList.forEach(inputScrum => {
            if (v.scrumItem === inputScrum) {
              inputFlag = true;
            }
          });
          if (v.scrumItem === value && !inputFlag) {
            scrumMaps[PageTypeList[2]].push({
              id: genId(),
              scrumItem: tmp,
              value: { id: '-1', text: v.value.text, invert: v.value.invert },
              item: {},
              subTitle: {},
            });
          }
        });
      });
      this.valueList = [];
      this.inputList = [];
      this.setState({ fsItemData: data.scrumMaps });
    } else {
      this.setState({
        fsItemData: []
      });
      console.log('api error');
    }

    this.setState({
      isLoading: false,
    });
  };

  //すべてのデータをフィルタリングしてスクラムをGETします
  handleFilterScrumData = (scrumMaps, pdfId) => {
    const { pdfInfo } = this.props;
    var csvData = [];
    Object.keys(scrumMaps).forEach(tableName => {
      if (tableName === 'manual_input') return;
      var newTableList = scrumMaps[tableName].filter(table => {
        if ((table.scrumItem || (table.value && table.value.text)) &&
          table.scrumItem !== table.originalItem &&
          table.scrumItem.indexOf('B/S') === -1) {
          return true;
        } else {
          return false;
        }
      });

      newTableList.forEach(table => {
        const category = pdfInfo.images.find(item => item.id === table.pdfImageId).pageClassification;
        //scrum json 変更したら、レコード非表示
        if ((category === tableName) ||
          (["販売費及び一般管理費", "製造原価明細表"].includes(category) && tableName === "損益計算書")) {
          csvData.push({
            ...table,
            "pdfId": pdfId,
            "pageName": category
          });
        }
      })
    })

    //subtitleあり、itemあり（全帳票）｜ subtitleなし、itemあり（CR・SGAのみ）
    return csvData.filter(data => {
      if (!data.scrumItem) {
        return false;
      }
      if (!data.subTitle.text) {
        if (!data.item.text || !["販売費及び一般管理費", "製造原価明細表"].includes(data.pageName)) {
          return false;
        }
        return true;
      }
      return true;
    });
  }

  render() {
    const {
      fsItemData,
    } = this.state;
    const {
      onRowSelect,
      projectId,
      pdfFileId,
      pdfInfo,
    } = this.props;
    if (this.state.isLoading || fsItemData.length === 0) {
      return (
        <Loader className="text-mapping-loading" active inline="centered" />
      );
    }

    return (
      <FsMappingReview
        projectId={projectId}
        pdfId={Number(pdfFileId)}
        detailData={fsItemData}
        onRowSelect={onRowSelect}
        pdfInfo={pdfInfo}
        handleFilterScrumData={this.handleFilterScrumData}
        onPageChange={this.props.onPageChange}
        currentPage={this.props.currentPage}
      />
    );
  }
}
