import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import intl from 'react-intl-universal';
import ja from './locales/ja.js';
import en from './locales/en.js';

let saveLocales = localStorage.getItem('locales');
if(saveLocales == null)
{
    saveLocales = 'ja';
    localStorage.setItem('locales', 'ja');
}

intl.init({
  currentLocale: saveLocales,
  locales: {
    ja,
    en
  }
})

ReactDOM.render(<App />, document.getElementById('root'));
