import React, { Component } from 'react';

import { Form, Segment, Button, Loader, Message } from 'semantic-ui-react';
import { confirmAlert } from 'react-confirm-alert';
import api from '../../api';
import intl from 'react-intl-universal';
import DatePicker from 'react-date-picker';
import { toUnixMS, FMT } from '../../predict/TimeUtil';
import moment from "moment";
import { getCsrfToken } from '../../common/CookieUtil';
export default class ImportData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      loading: false,
      modifyStartDate: '',
      jsonsUploadMessage: null,
    };
    // this.handleFilesSubmit = this.handleFilesSubmit.bind(this);
    this.handleFilesUpload = this.handleFilesUpload.bind(this);
    this.handleJsonsUpload = this.handleJsonsUpload.bind(this);
    this.handleExportRaw = this.handleExportRaw.bind(this);
  }

  // async handleFilesSubmit(e) {
  //   e.preventDefault();

  //   const form = e.target;
  //   const formData = new FormData(form);

  //   this.setState({ message: null });
  //   const res = await api.post(`/dppapi/labeling/${this.props.projectId}/import`, formData);
  //   const { message } = await res.data;
  //   this.setState({
  //     message,
  //     loading: false,
  //   });

  //   form.reset();
  // }

  async handleFilesUpload(e) {
    e.preventDefault();
    try {
      const form = e.target;
      const formData = new FormData(form);
      const {projectId} = this.props;
      this.setState({ 
        message: null,
        loading: true 
      });
      await api.post(`/dppapi/labeling/${projectId}/data/import`, formData);
      this.setState({
        loading: false,
      });
      form.reset();
    } catch (error) {
      this.setState({
        message: error.data.errmsg,
        loading: false,
      });
    }
  }

  async handleJsonsUpload(e) {
    e.preventDefault();
    try {
      const form = e.target;
      const formData = new FormData(form);
      const { projectId } = this.props;
      this.setState({
        jsonsUploadMessage: null,
        loading: true
      });
      await api.post(`/dppapi/labeling/${projectId}/data/import/json`, formData);
      this.setState({
        loading: false,
      });
      form.reset();
    } catch (error) {
      this.setState({
        jsonsUploadMessage: error.data.errmsg,
        loading: false,
      });
    }
  }

  async handleExportRaw() {
    let {modifyStartDate} = this.state;
    modifyStartDate = toUnixMS(moment(modifyStartDate).format(FMT.DATE));
    if (isNaN(Number(modifyStartDate))) {
      modifyStartDate = null;
    }
    confirmAlert({
      message: intl.get('_label.training-data.ImportData.ExportMsg'),
      buttons: [
        {
          label: intl.get('_label.training-data.ImportData.Completion only'),
          onClick: async () => {
            let a = document.createElement('a');
            a.href = `/dppapi/labeling/${this.props.projectId}/export/raw?exportNotLabeled=false&modifyStartDate=${modifyStartDate}&token=${getCsrfToken()}`;
            a.click();
            a.remove();
          },
        },
        {
          label: intl.get('_label.training-data.ImportData.Get all'),
          onClick: async () => {
            let a = document.createElement('a');
            a.href = `/dppapi/labeling/${this.props.projectId}/export/raw?exportNotLabeled=true&modifyStartDate=${modifyStartDate}&token=${getCsrfToken()}`;
            a.click();
            a.remove();
          },
        },
      ],
    })
  }

  render() {
    const { message, loading, modifyStartDate, jsonsUploadMessage } = this.state;
    const loader = loading ? <Loader /> : null;
    const messages = message ? (
      <Message negative>{message}</Message>
    ) : null;
    const jsonsUploadMessages = jsonsUploadMessage ? (
      <Message negative>{jsonsUploadMessage}</Message>
    ) : null;

    return (
      <div>
        {/* <Segment>
          <p>
            Import image labels exported from tools like{' '}
            <a href="https://labelbox.com">LabelBox</a>.
          </p>
          <p>
            The files must be formatted as a valid JSON, with corresponding image
            name in the "imagePath" field.
          </p>
          <p>
            Objects from the "shapes" field will be imported as polygons with
            corresponding labels created.
          </p>
          <Form
            method="post"
            encType="multipart/form-data"
            onSubmit={this.handleFilesSubmit}
          >
            <Form.Input
              label="Select JSON files"
              multiple
              type="file"
              id="files"
              name="files"
              accept=".json"
            />
            <Button type="submit">Import</Button>
          </Form>
          {loader}
        </Segment> */}
        <Segment>
          <p>
          {intl.get('_label.training-data.ImportData.UploadMsg')}
          </p>
          <Form
            method="post"
            encType="multipart/form-data"
            onSubmit={this.handleFilesUpload}
          >
            <Form.Input
              label={intl.get('_label.training-data.ImportData.UploadLabel')}
              multiple
              type="file"
              id="files"
              name="files"
              accept=".jpg, .jpeg, .png, .json"
            />
            <Button type="submit">{intl.get('_label.training-data.ImportData.Upload')}</Button>
          </Form>
          {messages}
          {loader}
        </Segment>
        <Segment>
          <p>
          {intl.get('_label.training-data.ImportData.UploadJsonMsg')}
          </p>
          <Form
            method="post"
            encType="multipart/form-data"
            onSubmit={this.handleJsonsUpload}
          >
            <Form.Input
              label={intl.get('_label.training-data.ImportData.UploadJson')}
              multiple
              type="file"
              id="jsons"
              name="jsons"
              accept=".json"
            />
            <Button type="submit">{intl.get('_label.training-data.ImportData.Upload')}</Button>
          </Form>
          {jsonsUploadMessages}
          {loader}
        </Segment>
        <Segment>
          <Button 
            icon="download"
            label={intl.get('_label.training-data.ImportData.DownloadLabel')}
            onClick={this.handleExportRaw}
          />
          <DatePicker
            onChange={date => this.setState({ modifyStartDate: date })}
            value={modifyStartDate}
            format="y/MM/dd"
          />{'時間以降'}
        </Segment>
      </div>
    );
  }
}
