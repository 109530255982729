import React from 'react';
import { Button, Input, Segment, Dropdown } from 'semantic-ui-react';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { getOptions } from '../Const';
import intl from 'react-intl-universal';
import '../Tenants.css';
const options = getOptions();

export default class AuditsSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      startDate: moment().add(-30, 'd').toDate(),
      endDate: moment().toDate(),
      status: '1'
    };
  }

  async componentDidMount() {
    const { name, startDate, endDate, status } = this.state;
    this.props.onSearch({
      name,
      startDate,
      endDate,
      status
    });
  }

  onChange = (type, value) => {
    this.setState({ [type]: value });
  };

  render() {
    const { onSearch } = this.props;
    const { name, startDate, endDate, status } = this.state;
    return (
      <Segment>
        <div className="search-tenants-condition">
          <p className="search-tenants-label">{intl.get('_system.tenants.audits.Search.TenantsName')}</p>
          <Input
            className="search-tenants-item"
            fluid
            placeholder={intl.get('_system.tenants.audits.Search.Please enter a tenant name')}
            onChange={e => this.onChange('name', e.target.value)}
            value={name}
          />
        </div>
        <div className="search-tenants-condition">
          <p className="search-tenants-label">{intl.get('_system.tenants.audits.Search.UpdateTime')}</p>
          <DatePicker
            className="search-tenants-item"
            onChange={date => this.onChange('startDate', date)}
            value={startDate}
            format="y/MM/dd"
          />{' '}
          ~
          <DatePicker
            className="search-tenants-item"
            onChange={date => this.onChange('endDate', date)}
            value={endDate}
            format="y/MM/dd"
          />
        </div>
        <div className="search-tenants-condition">
          <p className="search-tenants-label">{intl.get('_system.tenants.audits.Search.Status')}</p>
          <Dropdown
            clearable
            options={options}
            selection
            search
            onChange={(e, data) => this.onChange('status', data.value)}
            value={status} />
        </div>

        <div className="search-tenants-condition">
          <Button type="submit"
            onClick={() => onSearch({
              name,
              startDate,
              endDate,
              status
            })}>
            {intl.get('_system.tenants.audits.Search.Search')}
          </Button>
        </div>
      </Segment>
    );
  }
}
