import React, { Component } from 'react'
import { Button, Form, Dropdown } from 'semantic-ui-react'
import { downloadCsv } from '../../predict/Util'
import api from '../../api'

export function getCsvExtraInfo() {
  const extraInfoSetting =
    [{ label: "BSの単位", require: true, type: "inDropdown", key: "bsUnit", default: "0" },
    { label: "PLの単位", require: true, type: "inDropdown", key: "plUnit", default: "0" },
    { label: "SSの単位", require: true, type: "inDropdown", key: "ssUnit", default: "0" },
    { label: "小数点以下", require: true, type: "convertdropdown", key: "conversion", default: "3" },
    { label: "出力単位", require: true, type: "outDropdown", key: "outputUnit", default: "4" },
    ]
  return extraInfoSetting
}

export default class CsvInfo extends Component {
  constructor(props) {
    super(props)
    const items = this.props.items
    const data = {}
    items.forEach(item => {
      data[item.key] = item.default
    });
    this.state = { data }
  }

  async componentDidMount() {
    const data =JSON.parse(localStorage.getItem('resultFunction'))
    if(data){
      this.setState({data})
      localStorage.removeItem('resultFunction')
    }
  }
  handleValueChange = (key, value) => {
    const { data } = this.state
    data[key] = value;
    localStorage.setItem('resultFunction', JSON.stringify(data))
    this.setState({
      data: data,
    })
  }

  getItemType = (item) => {
    const { key } = item
    const value = this.state.data[key]
    const inputUnits = [
      { key: '0', text: '円', value: '0' },
      { key: '1', text: '百円', value: '1' },
      { key: '2', text: '千円', value: '2' },
      { key: '3', text: '万円', value: '3' },
      { key: '4', text: '百万円', value: '4' },
      { key: '5', text: '億円', value: '5' }
    ]
    const converts = [
      { key: '0', text: '切り捨て', value: '0' },
      { key: '1', text: '四捨五入', value: '1' },
      { key: '2', text: '切り上げ', value: '2' },
      { key: '3', text: 'そのまま', value: '3' }
    ]
    const outUnits = [
      { key: '0', text: '円', value: '0' },
      { key: '1', text: '百円', value: '1' },
      { key: '2', text: '千円', value: '2' },
      { key: '3', text: '万円', value: '3' },
      { key: '4', text: '百万円', value: '4' },
      { key: '5', text: '億円', value: '5' }
    ]
    switch (item.type) {
      case "inDropdown":
        return <Dropdown
          defaultValue={value}
          selection
          options={inputUnits}
          fluid
          onChange={(e, unit) => this.handleValueChange(key, unit.value)}
        />
      case "outDropdown":
        return <Dropdown
          defaultValue={value}
          selection
          options={outUnits}
          fluid
          onChange={(e, unit) => this.handleValueChange(key, unit.value)}
        />
      case "convertdropdown":
        return <Dropdown
          defaultValue={value}
          selection
          options={converts}
          fluid
          onChange={(e, convert) => this.handleValueChange(key, convert.value)}
        />

      default:
        return <input value={value} onChange={e => this.handleValueChange(key, e.target.value)} />
    }
  }

  renderOptions = () => {
    const dom = this.props.items.map((item, idx) => {
      const { label } = item
      return (< Form.Field key={idx}>
        <div style={{ marginTop: 20, width: '30%', float: 'left' }}><label>{label}</label></div>
        <div style={{ marginTop: 10, width: '70%', float: 'left' }}>
          {this.getItemType(item)}
        </div>
      </Form.Field >)
    })
    return <Form className="rf-form">{dom}</Form>
  }
  convert = (type, value) => {
    let convertValue = null
    const { conversion, outputUnit } = this.state.data

    // BSの単位/PLの単位/SSの単位/输出単位    円: 0, 百円: 1, 千円: 2, 万円: 3, 百万円: 4, 億円: 5
    const moneyMap = { "0": 1, "1": 100, "2": 1000, "3": 10000, "4": 1000000, "5": 100000000 }
    const ratio = moneyMap[this.state.data[type]] / moneyMap[outputUnit];
    const ratioE = Math.log10(ratio);
    
    if (typeof (value) === 'number') {
      switch (conversion) {
        case "0": //切り捨て
          convertValue = Math.floor(Number(value) * ratio)
          break
        case "1": //四捨五入
          convertValue = Math.round(Number(value) * ratio)
          break
        case "2": //切り上げ
          convertValue = Math.ceil(Number(value) * ratio)
          break
        case "3": //そのまま
          convertValue = (ratioE < 0)?Number(value + 'e' + ratioE):Number(value + 'e+' + ratioE)
          break
        default:
          return value
      }
      return convertValue
    } else {
      return value
    }

  }

  download = () => {
    const typeMap = { "貸借対照表": "bsUnit", "損益計算書": "plUnit", "株主資本等変動計算書": "ssUnit" }

    const { csvBody, csvOption, csvName, finalDownload } = this.props.callback
    const newCsvBody = []
    csvBody.forEach(raw => {
      newCsvBody.push([raw[0], raw[1], this.convert(typeMap[raw[0]], raw[2])])
    })

    const superCsvDownload = async (data, options, fileName, needBom, projectId = this.props.projectId, pdfFileId = this.props.pdfId) => {
        await api.post(`/dppapi/predict/evaluate-preset/${projectId}/${pdfFileId}`)
        downloadCsv(data, options, fileName, needBom);
    }
    finalDownload(newCsvBody, csvOption, csvName, superCsvDownload)
    this.props.handleOperateDialog({ open: false })
  }

  render() {
    const { handleOperateDialog, callback } = this.props
    return (
      <div className="rf-extra-csv-extra-contain">
        {this.renderOptions()}
        <div className="rf-extra-btn-group">
          <Button onClick={() => this.download()} positive className="rf-common-extra-btn-item">
            {callback.confirmBtnName}
          </Button>
          <Button onClick={() => handleOperateDialog({ open: false })}>編集画面に戻る</Button>
        </div>
      </div>
    )
  }
}