import React, { Component } from 'react';
import {
  Header,
  Form,
  Segment,
  Button,
  Checkbox,
  Radio,
  Input,
  Message,
  Icon,
  TextArea,
} from 'semantic-ui-react';
import api from '../api'
import intl from 'react-intl-universal';
import {
  withRouter
} from 'react-router-dom';
class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      error: null,
      errors: {},
      success: null,
      sendLoading: false,
      nextLoading: false,
      submitLoading: false,
      isForm: false,
    };
    this.handleEmailCheck = this.handleEmailCheck.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReturn = this.handleReturn.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
    this.handleRadio = this.handleRadio.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
  }

  async handleEmailCheck(e) {
    const { formData } = this.state;
    if(this.checkMail(formData)) return;
    this.setState({ 
      sendLoading: true,
      error: null,
      success: null,
    });
    let result = (await api.post('/dppapi/register/sendMail', {
      email: formData.tenants_mail,
    })).data;
    if (!result.success) {
      this.setState({ sendLoading: false, error: result.errmsg });
      return;
    }
    this.setState({ 
      sendLoading: false,
      success: intl.get('_login.RegisterPage.EmailSuccessed'),
    });
  }
  
  async handleNext() {
    const formData = {...this.state.formData};
    if(this.checkMail(formData)) return;
    this.setState({ 
      nextLoading: true,
      error: null,
      success: null,
    });
    let result = (await api.post('/dppapi/register/next', {
      email: formData.tenants_mail,
      code: formData.code
    })).data;
    if (!result.success) {
      this.setState({ nextLoading: false, error: result.errmsg });
      return;
    }
    formData.user_mail = formData.tenants_mail;
    this.setState({ 
      isForm: true,
      nextLoading: false,
      formData
    });
    this.clearErrors({});
  }
  
  async handleSubmit() {
    const { formData } = this.state;
    if(!this.checkForm(formData)) return;
    this.setState({ 
      submitLoading: true,
      error: null,
      success: null,
    });
    let result = (await api.post('/dppapi/register/submit', {
      form: formData,
    })).data;
    if (!result.success) {
      this.setState({ submitLoading: false, error: result.errmsg });
      return;
    }
    this.setState({ 
      submitLoading: false,
      success: intl.get('_login.RegisterPage.EmailSuccessed'),
    });
    this.clearErrors({});
    window.location = `/dppui/login`;
  }

  checkMail(form){
    const errors = {};
    //eslint-disable-next-line 
    const emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (!emailReg.test(form.tenants_mail)) {
      errors['tenants_mail'] = {content: 'Please enter a valid email.', pointing: 'below', flag: true};
    }
    this.setState({
      errors: errors
    });
    return Object.keys(errors).length !== 0;
  }

  checkForm(form) {
    const errors = {};
    if (!form.tenants_name) {
      errors['tenants_name'] = {content: 'Please enter a tenants name.', pointing: 'below', flag: true};
    }
    if (!form.address) {
      errors['address'] = {content: 'Please enter a address.', pointing: 'below', flag: true};
    }
    if (!form.first_name) {
      errors['first_name'] = {content: 'Please enter a first name.', pointing: 'below', flag: true};
    }
    if (!form.last_name) {
      errors['last_name'] = {content: 'Please enter a last name.', pointing: 'below', flag: true};
    }
    if (!form.first_kana_name) {
      errors['first_kana_name'] = {content: 'Please enter a first kana name.', pointing: 'below', flag: true};
    }
    if (!form.last_kana_name) {
      errors['last_kana_name'] = {content: 'Please enter a last kana name.', pointing: 'below', flag: true};
    }
    if (!form.phone_num) {
      errors['phone_num'] = {content: 'Please enter a phone num.', pointing: 'below', flag: true};
    }
    if (!form.pwd) {
      errors['pwd'] = {content: 'Please enter a password.', pointing: 'below', flag: true};
    }
    if (!form.confPwd) {
      errors['confPwd'] = {content: 'Please enter a confirm password.', pointing: 'below', flag: true};
    }
    if (!form.contract_plan) {
      errors['contract_plan'] = {content: 'Please pick a contract plan.', pointing: 'below', flag: true};
    }
    if (!form.agree_flag) {
      errors['agree_flag'] = {content: 'Please check agree flag.', pointing: 'below', flag: true};
    }
    if (form.pwd !== form.confPwd) {
      errors['confPwd'] = {content: 'The password entered is inconsistent, please enter again.', pointing: 'below', flag: true};
    }
    
    this.setState({
      errors: errors
    });
    return Object.keys(errors).length === 0;
  }

  handleCheckBox(e, { checked }) {
    const formData = {...this.state.formData};
    formData.agree_flag = checked;
    this.setState({
      formData
    });
  }

  handleRadio(e, { value }) {
    const formData = {...this.state.formData};
    formData.contract_plan = value;
    this.setState({
      formData
    });
  }

  handleFocus(event) {
    const { errors } = this.state;
    const {name} = event.target;
    delete errors[name];
    this.clearErrors(errors);
  }

  clearErrors(errors) {
    this.setState({
      errors
    });
  }

  handleInput(e) {
    const {name, value} = e.target;
    const formData = {...this.state.formData};
    formData[name] = value;
    this.setState({
      formData
    });
  }
  
  handleReturn() {
    this.setState({ 
      isForm: false,
    });
    this.clearErrors({});
  }

  render() {
    const { 
      sendLoading,
      nextLoading,
      submitLoading,
      error,
      success,
      formData,
      isForm, 
      errors,
    } = this.state;
    return (
      <Segment attached>
      <Header>
        {intl.get('_login.RegisterPage.Register')}
        <Icon link name="close" onClick={() =>{ window.location = `/dppui/login`}} />
        </Header>
        {
          Object.keys(errors).length > 0 &&
          <Message
            error
            list={ Object.keys(errors).map(i => {
              return errors[i].content;
            })}
          />
        }
        {!isForm && <Form>
          <Form.Field style={{width: '80%', display: 'inline-block'}} >
            <label>{intl.get('_login.RegisterPage.TenantsMail')}</label>
            <Input
              onChange={this.handleInput}
              onFocus={this.handleFocus}
              defaultValue={formData.tenants_mail}
              name="tenants_mail"
            />
          </Form.Field>
          <Form.Field style={{ width: '20%', display: 'inline-block', paddingLeft: '2%'}}>
            <Button primary
              loading={sendLoading}
              disabled={sendLoading}
              secondary={sendLoading}
              onClick={this.handleEmailCheck}>{intl.get('_login.RegisterPage.Send')}</Button>
          </Form.Field>
          <Form.Field style={{ width: '80%', display: 'inline-block'}}>
            <label>{intl.get('_login.RegisterPage.Code')}</label>
            <Input
              onChange={this.handleInput}
              onFocus={this.handleFocus}
              defaultValue={formData.code}
              name="code"
            />
          </Form.Field>
          <Form.Field style={{ width: '20%', display: 'inline-block', paddingLeft: '2%'}}>
            <Button primary
              loading={nextLoading}
              disabled={nextLoading}
              secondary={nextLoading}
              onClick={this.handleNext}>{intl.get('_login.RegisterPage.Next')}</Button>
          </Form.Field>
        </Form>}
        {isForm && <Form>
          <Form.Field error={errors['tenants_name'] && errors['tenants_name']['flag']}
            style={{width: '80%', display: 'inline-block'}} >
            <label>{intl.get('_login.RegisterPage.TenantsName')}</label>
            <Input
              onChange={this.handleInput}
              onFocus={this.handleFocus}
              defaultValue={formData.tenants_name}
              name="tenants_name"
            />
          </Form.Field>
          <Form.Field style={{ width: '20%', display: 'inline-block', paddingLeft: '2%'}}>
            <Button primary
              >{intl.get('_login.RegisterPage.Search')}</Button>
          </Form.Field>
          <Form.Field error={errors['address'] && errors['address']['flag']}>
            <label>{intl.get('_login.RegisterPage.Address')}</label>
            <Input
              onChange={this.handleInput}
              onFocus={this.handleFocus}
              defaultValue={formData.address}
              name="address"
            />
          </Form.Field>
          <Form.Field error={errors['last_name'] && errors['last_name']['flag']}
            style={{ width: '49%', display: 'inline-block'}}>
            <label>{intl.get('_login.RegisterPage.LastName')}</label>
            <Input
              onChange={this.handleInput}
              onFocus={this.handleFocus}
              defaultValue={formData.last_name}
              name="last_name"
            />
          </Form.Field>
          <Form.Field error={errors['first_name'] && errors['first_name']['flag']}
            style={{ width: '51%', display: 'inline-block', paddingLeft: '2%'}}>
            <label>{intl.get('_login.RegisterPage.FirstName')}</label>
            <Input
              onChange={this.handleInput}
              onFocus={this.handleFocus}
              defaultValue={formData.first_name}
              name="first_name"
            />
          </Form.Field>
          <Form.Field error={errors['last_kana_name'] && errors['last_kana_name']['flag']}
            style={{ width: '49%', display: 'inline-block'}}>
            <label>{intl.get('_login.RegisterPage.LastKanaName')}</label>
            <Input
              onChange={this.handleInput}
              onFocus={this.handleFocus}
              defaultValue={formData.last_kana_name}
              name="last_kana_name"
            />
          </Form.Field>
          <Form.Field error={errors['first_kana_name'] && errors['first_kana_name']['flag']}
            style={{ width: '51%', display: 'inline-block', paddingLeft: '2%'}}>
            <label>{intl.get('_login.RegisterPage.FirstKanaName')}</label>
            <Input
              onChange={this.handleInput}
              onFocus={this.handleFocus}
              defaultValue={formData.first_kana_name}
              name="first_kana_name"
            />
          </Form.Field>
          <Form.Field error={errors['phone_num'] && errors['phone_num']['flag']}>
            <label>{intl.get('_login.RegisterPage.Phone')}</label>
            <Input
              onChange={this.handleInput}
              onFocus={this.handleFocus}
              defaultValue={formData.phone_num}
              name="phone_num"
            />
          </Form.Field>
          <Form.Field>
            <label>{intl.get('_login.RegisterPage.UserMail')}</label>
            <Input
              readOnly
              defaultValue={formData.user_mail}
              name="user_mail"
            />
          </Form.Field>
          <Form.Field error={errors['pwd'] && errors['pwd']['flag']}>
            <label>{intl.get('_login.RegisterPage.Pwd')}</label>
            <Input
              type='password'
              onChange={this.handleInput}
              onFocus={this.handleFocus}
              defaultValue={formData.pwd}
              name="pwd"
            />
          </Form.Field>
          <Form.Field error={errors['confPwd'] && errors['confPwd']['flag']}>
            <label>{intl.get('_login.RegisterPage.ConfirmPwd')}</label>
            <Input
              type='password'
              onChange={this.handleInput}
              onFocus={this.handleFocus}
              defaultValue={formData.confPwd}
              name="confPwd"
            />
          </Form.Field>
          <Form.Field error={errors['contract_plan'] && errors['contract_plan']['flag']}>
            <label>{intl.get('_login.RegisterPage.ContractPlan')}</label>
            <Radio
              label={intl.get('_login.RegisterPage.Plan 1')}
              name="contract_plan"
              value="1"
              checked={formData.contract_plan === "1"}
              onChange={this.handleRadio}
              onFocus={this.handleFocus}
            />{' '}
            <Radio
              label={intl.get('_login.RegisterPage.Plan 2')}
              name="contract_plan"
              value="2"
              checked={formData.contract_plan === "2"}
              onChange={this.handleRadio}
              onFocus={this.handleFocus}
            />{' '}
            <Radio
              label={intl.get('_login.RegisterPage.Plan 3')}
              name="contract_plan"
              value="3"
              checked={formData.contract_plan === "3"}
              onChange={this.handleRadio}
              onFocus={this.handleFocus}
            />
          </Form.Field>
          <Form.Field error={errors['agree_flag'] && errors['agree_flag']['flag']}>
            <label>{intl.get('_login.RegisterPage.TermsService')}</label>
            <TextArea
              readOnly
              value={intl.get('_login.RegisterPage.Rule')}
              rows="8"/>
            <Checkbox
              checked={!!formData.agree_flag}
              label={intl.get('_login.RegisterPage.AgreeService')}
              name="agree_flag"
              onChange={this.handleCheckBox}
              onFocus={this.handleFocus}
            />
          </Form.Field>
          <Button color='green'
            loading={submitLoading}
            disabled={submitLoading}
            secondary={submitLoading}
            onClick={this.handleSubmit}>{intl.get('_login.RegisterPage.Submit')}</Button>
          <Button
            onClick={this.handleReturn}>{intl.get('_login.RegisterPage.Return')}</Button>
        </Form>
        }
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {success && <p style={{ color: 'blue' }}>{success}</p>}
      </Segment>
    );
  }
}

export default withRouter(RegisterPage);