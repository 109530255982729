import crypto from 'crypto'
import {getCookie} from './CookieUtil'

const ALGORITHM = 'aes192'
const ENCODING_HEX = 'hex'
const ENCODING_UTF8 = 'utf8'

export function publicEncrypt(data, publicKey) {
  try {
    const pubKey = window.forge.pki.publicKeyFromPem(publicKey);
    return window.btoa(pubKey.encrypt(data));
  } catch (e) {
    console.error(e);
    return "";
  }
}

export function cryptoEncrypt(content){
  if (!content){
    return null
  }
  const secret = getCookie('_modelsec')
  const cipher = crypto.createCipher(ALGORITHM,secret)
  const update = cipher.update(content,ENCODING_UTF8,ENCODING_HEX);
  const enctypt = update + cipher.final(ENCODING_HEX)
  return enctypt
}

export function cryptoDecrypt(content){
  const secret = getCookie('_modelsec')
  if(!content || !secret){
    return null
  }
  const decipher = crypto.createDecipher(ALGORITHM,secret)
  const update  = decipher.update(content,ENCODING_HEX,ENCODING_UTF8)
  const decrypt = update + decipher.final(ENCODING_UTF8)
  return decrypt
}