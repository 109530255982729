import React from 'react'
import DatePicker from 'react-date-picker';
import { MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact'
import {
  Button,
  Form,
  Message
} from 'semantic-ui-react'
import moment from 'moment';
import api from '../../api'
import intl from 'react-intl-universal';
import '../tenants/Tenants.css';
import { toUnixMS, FMT } from '../tenants/TimeUtil';

export default class LicenseAdd extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      preferences: {
        validFrom: moment().format(FMT.DATE),
        expiredTime: moment().add(3, 'months').format(FMT.DATE)
      },
      errors: {}
    }

    this.handleInput = this.handleInput.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.checkForm = this.checkForm.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.clearErrors = this.clearErrors.bind(this)
    this.clearPreferences = this.clearPreferences.bind(this)
  }

  handleInput(event) {
    const {name, value} = event.target
    const preferences = {...this.state.preferences}
    preferences[name] = value
    this.setState({
      preferences
    })
  }

  onChange = (name, value) => {
    const preferences = {...this.state.preferences}
    preferences[name] = value ? moment(value).format(FMT.DATE): ""
    this.setState({ 
      preferences 
    });
  };

  handleFocus(event) {
    const { errors } = this.state
    const {name} = event.target
    delete errors[name]
    this.clearErrors(errors)
  }

  clearErrors(errors) {
    this.setState({
      errors
    })
  }

  clearPreferences() {
    this.setState({
      preferences: {
        validFrom: moment().format(FMT.DATE),
        expiredTime: moment().add(3, 'months').format(FMT.DATE)
      }
    })
  }

  async handleSave() {
    const preferences = Object.assign({}, this.props.preferences, this.state.preferences)

    if (!this.checkForm(preferences)) {
      return
    }
    
    const res = await api.request({
      url: `/dppapi/system/monitor/licensekeys`,
      method: 'POST',
      data: preferences
    })

    if(res.data && !res.data.success && res.data.errmsg){
      const errors = {}
      if(res.data.errmsg === 'validFrom'){
        errors['validFrom'] = { content: '＜仮＞テナント設定のEasyOCR有効期間外に、ライセンスキーの有効期間が設定されています', pointing: 'below', flag: true }
      }
      if(res.data.errmsg === 'expiredTime'){
        errors['expiredTime'] = { content: '＜仮＞テナント設定のEasyOCR有効期間外に、ライセンスキーの有効期間が設定されています', pointing: 'below', flag: true }
      }
      if(res.data.errmsg === 'easyocrContract'){
        errors['easyocrContract'] = { content: 'Please enter a EasyOCR usage period  in the Tenant settings', pointing: 'below', flag: true }
      }
      this.setState({ errors })
      return
    }

    this.props.getLicenses()
    this.props.toggleAdd(false)
    this.clearErrors({})
    this.clearPreferences()
  }

  checkForm(preferences) {
    const errors = {}
    if (!preferences.tenantId) {
      errors['tenantId'] = {content: 'Please enter a preferences tenantId.', pointing: 'below', flag: true}
    }

    if (!preferences.pageCount) {
      errors['pageCount'] = {content: 'Please enter a preferences pageCount.', pointing: 'below', flag: true}
    }

    if (!preferences.machineCount) {
      errors['machineCount'] = {content: 'Please enter a preferences machineCount.', pointing: 'below', flag: true}
    }

    if (!preferences.contractId) {
      errors['contractId'] = {content: 'Please enter a preferences contractId.', pointing: 'below', flag: true}
    }

    if (!preferences.ipFilter) {
      errors['ipFilter'] = {content: 'Please enter a preferences ipFilter.', pointing: 'below', flag: true}
    }

    if (!preferences.validFrom) {
      errors['validFrom'] = {content: 'Please enter a preferences validFrom.', pointing: 'below', flag: true}
    } else {
      preferences.validFrom = toUnixMS(moment(preferences.validFrom));
    }

    if (!preferences.expiredTime) {
      errors['expiredTime'] = {content: 'Please enter a preferences expiredTime.', pointing: 'below', flag: true}
    } else {
      preferences.expiredTime = toUnixMS(moment(preferences.expiredTime).add({h: 23, m: 59, s: 59}));
    }
    
    this.setState({
      errors: errors
    })

    return Object.keys(errors).length === 0
  }

  clearDateMsg = (name) => {
    const { errors } = this.state;
    delete errors[name];
    this.clearErrors(errors);
  }

  render() {
    const { errors, preferences } = this.state

    return (
      <MDBModal isOpen={this.props.isShowAdd} toggle={flag => {this.setState({errors:{}});this.props.toggleAdd(flag)}}>
        <MDBModalHeader> { "Add a new License Key" }
        </MDBModalHeader>
        <MDBModalBody>
        {
          Object.keys(errors).length > 0 &&
          <Message
            error
            list={ Object.keys(errors).map(i => {
              return errors[i].content
            })}
          />
        }
          <Form>
            <Form.Field error={errors['tenantId'] && errors['tenantId']['flag']}>
              <label>{ "Tenant ID" }</label>
              <Form.Input placeholder="tenantId" name="tenantId" onChange={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            <Form.Field error={errors['pageCount'] && errors['pageCount']['flag']}>
              <label>{ "Page Count" }</label>
              <Form.Input placeholder="pageCount" name="pageCount" onChange={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            <Form.Field error={errors['machineCount'] && errors['machineCount']['flag']}>
              <label>{ "Machine Count" }</label>
              <Form.Input placeholder="machineCount" name="machineCount" onChange={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            <Form.Field error={errors['contractId'] && errors['contractId']['flag']}>
              <label>{ "Contract ID" }</label>
              <Form.Input placeholder="contractId" name="contractId" onChange={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            <Form.Field error={errors['ipFilter'] && errors['ipFilter']['flag']}>
              <label>{ "IP Filter" }</label>
              <Form.Input placeholder="ipFilter" name="ipFilter" onChange={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            <Form.Field>
              <label>{ "Valid Time" }</label>
            </Form.Field>
          </Form>
          <div className="search-tenants-condition">
            <DatePicker
              className={errors['validFrom'] ?"licensekey-date-error" :"search-tenants-item"}
              onChange={date => {this.clearDateMsg('validFrom');this.onChange('validFrom', date)}}
              value={preferences['validFrom'] ? moment(preferences['validFrom']).toDate() : ""}
              format="y/MM/dd"
            />
          </div>
          <Form>
            <Form.Field>
                <label>{ "Expired Time" }</label>
              </Form.Field>
            </Form>
          <div className="search-tenants-condition">
            <DatePicker
              className={errors['expiredTime'] ?"licensekey-date-error" :"search-tenants-item"}
              onChange={date => {this.clearDateMsg('expiredTime');this.onChange('expiredTime', date)}}
              value={preferences['expiredTime'] ? moment(preferences['expiredTime']).toDate() : ""}
              format="y/MM/dd"
            />
          </div>
          <Form>
            <Form.Field>
              <label> {"Description"}</label>
              <Form.TextArea placeholder="Brief Description" defaultValue="" name="description" onChange={this.handleInput} onFocus={this.handleFocus} rows="10"/>
            </Form.Field>
            <Button 
              floated='right' 
              onClick={this.handleSave}
              >{intl.get('_common.Save')}</Button>
            <Button 
              floated='right' 
              onClick={() => {
                this.props.toggleAdd(false)
                this.clearErrors({})
                this.clearPreferences()
              }}
              >{intl.get('_common.Cancel')}</Button>
          </Form>
        </MDBModalBody>
      </MDBModal>
    )
  }
}
