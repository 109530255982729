import api from '../api'

export const TYPES = {
  LOAD_PAGE_BS: 'LOAD_PAGE_BS',
  LOAD_PAGE_PL: 'LOAD_PAGE_PL',
  LOAD_PAGE_SS: 'LOAD_PAGE_SS',
  LOAD_PAGE: 'LOAD_PAGE',
  SAVE_SCRUM_MAP: 'SAVE_SCRUM_MAP',
  EXEC_RPA: 'EXEC_RPA'
}

const KEY_NAME = 'pdf_track_store'

export function track2LocalStorage(projectId, pdfId, type, remark) {
  const pdfTrackStore = JSON.parse((localStorage.getItem(KEY_NAME) || '{}'))
  const trackProject = pdfTrackStore[projectId] || {}
  const trackPdfList = trackProject[pdfId] || []
  const trackItem = {
    type: type,
    remark: remark,
    timestamp: new Date().getTime(),
  }
  trackPdfList.unshift(trackItem)
  trackProject[pdfId] = trackPdfList
  pdfTrackStore[projectId] = trackProject
  localStorage.setItem(KEY_NAME, JSON.stringify(pdfTrackStore))
}

export async function track2DBStorage(projectId, pdfId, type, remark) {

  const pdfTrackStore = JSON.parse((localStorage.getItem(KEY_NAME) || '{}'))
  const trackProject = pdfTrackStore[projectId] || {}
  const trackPdfList = trackProject[pdfId] || []
  const trackItem = {
    type: type,
    remark: remark,
    timestamp: new Date().getTime(),
  }
  trackPdfList.unshift(trackItem)
  trackProject[pdfId] = trackPdfList
  pdfTrackStore[projectId] = trackProject

  const  { data } = await api.post(`/dppapi/predict/pdf/track/${projectId}/${pdfId}`, {
    appendTrackInfo: trackPdfList
  })

  if (data.success) {
    pdfTrackStore[projectId][pdfId] = []
    localStorage.setItem(KEY_NAME, JSON.stringify(pdfTrackStore))
  } else {
    console.error('Track pdf error!')
  }
}