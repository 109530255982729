import React, { Component } from 'react';
import { CRS } from 'leaflet';
import { Map, ImageOverlay } from 'react-leaflet';
import { Link } from 'react-router-dom';
import Control from 'react-leaflet-control';
import 'leaflet-path-drag';
import 'leaflet/dist/leaflet.css';
import { confirmAlert } from 'react-confirm-alert';
import { Icon, Loader } from 'semantic-ui-react';
import { BBoxFigure, PolygonFigure } from './SystemFigure';
import { lighten, colorMapping } from '../label/label-app/utils';
import { withBounds, maxZoom } from './SystemCalcBoundsHOC';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './SystemReactConfirmAlert.css';
import intl from 'react-intl-universal';

class Canvas extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      zoom: -1,
      selectedFigureId: null,
      cursorPos: { lat: 0, lng: 0 },
      showTooltip: false,
      isShowEdit: false,
    };
    this.prevSelectedFigure = null;
    this.skipNextClickEvent = false;

    this.mapRef = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.handleShowTooltip = this.handleShowTooltip.bind(this);
    this.getKeyByFigureId = this.getKeyByFigureId.bind(this);
    this.imageOverlayRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { onSelectionChange } = this.props;
    const { selectedFigureId } = this.state;

    if (this.prevSelectedFigureId !== selectedFigureId && onSelectionChange) {
      this.prevSelectedFigureId = selectedFigureId;
      onSelectionChange(selectedFigureId);
    }
  }

  getSelectedFigure() {
    const { selectedFigureId } = this.state;
    const { figures } = this.props;
    return figures.find(f => f.id === selectedFigureId);
  }

  handleClick(e) {
    const { unfinishedFigure } = this.props;
    const drawing = !!unfinishedFigure;

    if (this.skipNextClickEvent) {
      // a hack, for whatever reason it is really hard to stop event propagation in leaflet
      this.skipNextClickEvent = false;
      return;
    }

    if (!drawing) {
      this.setState({ selectedFigureId: null });
      return;
    }
  }

  renderFigure(figure, options) {
    const Comp = figure.type === 'bbox' ? BBoxFigure : PolygonFigure;

    return (
      <Comp
        key={figure.id}
        figure={figure}
        options={options}
        skipNextClick={() => (this.skipNextClickEvent = true)}
        showTooltip={this.state.showTooltip}
        bounds={this.props.bounds}
        getKeyByFigureId={this.getKeyByFigureId} 
      />
    );
  }
  handleShowTooltip() {
    this.setState({ showTooltip: !this.state.showTooltip });
  }

  predictDialog = () => {
    confirmAlert({
      title: intl.get('_system.feedback.ApiEdit.RePredict'),
      message: intl.get('_system.feedback.ApiEdit.Do you want to re-predict?'),
      buttons: [
        {
          label: intl.get('_system.feedback.ApiEdit.Inference'),
          onClick: () => this.props.onRePredict(true),
        },
        {
          label: intl.get('_system.feedback.ApiEdit.Flask'),
          onClick: () => this.props.onRePredict(false),
        },
        {
          label: intl.get('_system.feedback.ApiEdit.Cancel'),
        },
      ],
      zIndex: 500,
    });
  };

  getKeyByFigureId(id) {
    const { figures } = this.props;
    let value = '';
    const figure = figures.find(f => {
      return f.linkTo && f.linkTo.indexOf(id) !== -1
    });
    if (figure) {
      value = figure.det_text;
    }
    return value;
  }

  render() {
    const {
      url,
      bounds,
      height,
      width,
      figures,
      unfinishedFigure,
      style,
      selectedBoxIds,
      onCsvDownload,
      loading
    } = this.props;
    const { zoom, selectedFigureId } = this.state;

    const drawing = !!unfinishedFigure;

    const calcDistance = (p1, p2) => {
      const map = this.mapRef.current.leafletElement;
      return map.latLngToLayerPoint(p1).distanceTo(map.latLngToLayerPoint(p2));
    };

    const getColor = f =>
      f.tracingOptions && f.tracingOptions.enabled
        ? lighten(colorMapping[f.color], 80)
        : colorMapping[f.color];
    const figuresDOM = figures.map((f, i) =>
      this.renderFigure(f, {
        editing: selectedFigureId === f.id && !drawing,
        finished: true,
        interactive: !drawing,
        sketch: f.tracingOptions && f.tracingOptions.enabled,
        color: getColor(f),
        vertexColor: colorMapping[f.color],
        onSelect: () => this.setState({ selectedFigureId: f.id }),
        calcDistance,
        inSelectedBoxIds: selectedBoxIds.indexOf(f.id) !== -1,
      })
    );

    let renderedTrace = null;
    const selectedFigure = this.getSelectedFigure();
    if (selectedFigure && selectedFigure.type === 'polygon') {
      const trace = selectedFigure.tracingOptions
        ? selectedFigure.tracingOptions.trace || []
        : [];
      const figure = {
        id: 'trace',
        type: 'line',
        points: trace,
      };
      const traceOptions = {
        editing: false,
        finished: true,
        color: colorMapping[selectedFigure.color],
      };
      renderedTrace = (
        <PolygonFigure
          figure={figure}
          options={traceOptions}
          skipNextClick={() => (this.skipNextClickEvent = true)}
          showTooltip={this.state.showTooltip}
          getKeyByFigureId={this.getKeyByFigureId} />
      );
    }

    let renderedLoader = null;
    if (loading) {
      renderedLoader = <Loader active size="large" />;
    }

    return (
      <div
        style={{
          cursor: drawing ? 'crosshair' : 'grab',
          height: '100%',
          ...style,
        }}
      >
        <Map
          crs={CRS.Simple}
          zoom={zoom}
          minZoom={-50}
          maxZoom={maxZoom}
          center={[height / 2, width / 2]}
          zoomAnimation={false}
          zoomControl={false}
          zoomSnap={0.1}
          keyboard={false}
          attributionControl={false}
          onClick={this.handleClick}
          onZoom={e => this.setState({ zoom: e.target.getZoom() })}
          onMousemove={e => this.setState({ cursorPos: e.latlng })}
          ref={this.mapRef}
        >
          <Control className="leaflet-bar" position="bottomright">
            <Link
              title="show tooltip"
              to="#"
              onClick={() => {
                this.handleShowTooltip();
              }}
            >
              <Icon
                name="lightbulb outline"
                fitted
                style={{ fontSize: '1.2em' }}
              />
            </Link>
          </Control><Control className="leaflet-bar" position="bottomright">
            <Link
              title="download data"
              to="#"
              onClick={() => {
                onCsvDownload();
              }}
            >
              <Icon
                name="download"
                fitted
                style={{ fontSize: '1.2em' }}
              />
            </Link>
          </Control>
          <Control className="leaflet-bar" position="bottomright">
            <Link
              title="re-predict"
              to="#"
              onClick={() => {
                if (loading) return
                this.predictDialog();
              }}
            >
              <Icon
                name="eye"
                fitted
                disabled={loading}
                style={{ fontSize: '1.2em' }}
              />
            </Link>
          </Control>
          
          <ImageOverlay url={url} bounds={bounds} ref={this.imageOverlayRef}/>
          {renderedTrace}
          {figuresDOM}
          {renderedLoader}
        </Map>
      </div>
    );
  }
}

export default withBounds(Canvas);
