import React from 'react'
import { Header, Menu, Segment, Sidebar, Table } from 'semantic-ui-react'

const Content = <div >
  <Header as="h2" attached="top">
    Hotkeys
</Header>
  <Segment attached>
    <Header as="h3"> Labels </Header>
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Action</Table.HeaderCell>
          <Table.HeaderCell>Key</Table.HeaderCell>

        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Word</Table.Cell>
          <Table.Cell>1</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
    <Header as="h3"> General </Header>
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Action</Table.HeaderCell>
          <Table.HeaderCell>Key</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        <Table.Row>
          <Table.Cell>Delete figure</Table.Cell>
          <Table.Cell>Delete</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Cancel selection</Table.Cell>
          <Table.Cell>Escape</Table.Cell>
        </Table.Row>

      </Table.Body>
    </Table>
    <Header as="h3"> Navigation </Header>
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Action</Table.HeaderCell>
          <Table.HeaderCell>Key</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        <Table.Row>
          <Table.Cell>Zoom In</Table.Cell>
          <Table.Cell>+/=</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Zoom Out</Table.Cell>
          <Table.Cell>-</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Move the Image</Table.Cell>
          <Table.Cell>←→↑↓</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  </Segment>
</div >
export default ({ children, shortKeyPanelVisible }) => {
  return (<Sidebar.Pushable as={Segment} className="custom-ui-segment">
    <Sidebar
      as={Menu}
      animation='overlay'
      icon='labeled'
      inverted
      vertical
      visible={shortKeyPanelVisible}
      className="hotkey-side-bar"
      width="thin"
      content={Content}
    >
    </Sidebar>
    <Sidebar.Pusher>
      {children}
    </Sidebar.Pusher>
  </Sidebar.Pushable>)
}
