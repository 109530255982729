import React, { Component } from 'react';
import Menubar from '../common/Menubar';
import OnlineUsers from './online-users'

class MonitorApp extends Component {
  render() {
    return (
      <Menubar active="monitor">
        <OnlineUsers />
      </Menubar>
    );
  }
}

export default MonitorApp;
