import React, { Component } from 'react';
import { Segment, Checkbox, Button, Loader, Select} from 'semantic-ui-react';
import { confirmAlert } from 'react-confirm-alert';
import api from '../../api'
import intl from 'react-intl-universal';

export default class Process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      project: null,
      aiProcessKeys: null,
      aiProcessValues: null,
      errors: {},
      trainOptions: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  async componentDidMount() {
    const { projectId } = this.props;
    var project = (await api.get('/dppapi/projects/' + projectId)).data;
    const trainDatas = (await api.get(`/dppapi/projects/train/${projectId}`)).data;
    var trainOptions = []
    trainDatas.forEach((data) => {
      trainOptions.push({
        key: data.id,
        value: data.id,
        text: data.name,
      });
    });
    var aiProcessKeys = {};
    project.aiProcess && Object.keys(project.aiProcess).forEach((key) => {
      aiProcessKeys[key] = 1;
    });
    this.setState({
      isLoaded: true,
      project,
      aiProcessKeys: aiProcessKeys || {},
      aiProcessValues: project.aiProcess || {},
      trainOptions
    });
  }

  handleChange(event, value) {
    const {name, checked} = value;
    const { aiProcessKeys } = this.state;
    aiProcessKeys[name] = Number(checked);
    this.setState({
      aiProcessKeys,
    });
  }

  handleSelectChange(event, {name, value}) {
    const { errors, aiProcessValues } = this.state;
    aiProcessValues[name] = value;
    delete errors[name];
    this.setState({
      aiProcessValues,
      errors
    });
  }

  async handleSave() {
    const { projectId } = this.props;
    var { project, aiProcessKeys, aiProcessValues } = this.state;
    confirmAlert({
      title: intl.get('_project.process.Process.Confirm'),
      message: intl.get('_project.process.Process.Do you want to save the record?'),
      buttons: [
        {
          label: intl.get('_project.process.Process.OK'),
          onClick: async () => {
            if (!this.checkForm()) {
              return;
            }
            var aiProcess = {};
            Object.keys(aiProcessKeys).forEach((key) => {
              if (!!aiProcessKeys[key])
              aiProcess[key] = aiProcessValues[key];
            })
            project.aiProcess = aiProcess;
            await api.patch('/dppapi/projects/' + projectId, {project: project});
          },
        },
        {
          label: intl.get('_project.process.Process.Cancel'),
        },
      ],
    })
  }

  checkForm() {
    const { aiProcessKeys, aiProcessValues } = this.state;
    const errors = {};
    if (!!aiProcessKeys.direction && aiProcessValues.direction === undefined) {
      errors['direction'] = {pointing: 'below', flag: true};
    }
    if (!!aiProcessKeys.pageClassification && aiProcessValues.pageClassification === undefined) {
      errors['pageClassification'] = {pointing: 'below', flag: true};
    }
    if (!!aiProcessKeys.split && aiProcessValues.split === undefined) {
      errors['split'] = {pointing: 'below', flag: true};
    }
    if (!!aiProcessKeys.bbox && aiProcessValues.bbox === undefined) {
      errors['bbox'] = {pointing: 'below', flag: true};
    }
    if (!!aiProcessKeys.ocr && aiProcessValues.ocr === undefined) {
      errors['ocr'] = {pointing: 'below', flag: true};
    }
    if (!!aiProcessKeys.cellClassification && aiProcessValues.cellClassification === undefined) {
      errors['cellClassification'] = {pointing: 'below', flag: true};
    }
    if (!!aiProcessKeys.pair && aiProcessValues.pair === undefined) {
      errors['pair'] = {pointing: 'below', flag: true};
    }
    this.setState({
      errors
    });
    return Object.keys(errors).length === 0;
  }

  render() {
    const { 
      trainOptions,
      errors,
      isLoaded,
      aiProcessKeys,
      aiProcessValues
    } = this.state;

    if (!isLoaded) {
      return <Loader active inline="centered" />;
    }

    var defOptions = [];
    defOptions.push({
      key: 0,
      value: 0,
      text: "Default model",
    });
    trainOptions.forEach((data) => {
      defOptions.push(data);
    });

    var pcOptions = [];
    pcOptions.push({
      key: 0,
      value: 0,
      text: "決算書分類",
    });
    trainOptions.forEach((data) => {
      pcOptions.push(data);
    });

    var checkList = [];
    Object.keys(aiProcessKeys).forEach((data) => {
      aiProcessKeys[data] === 1 && checkList.push(data);
    })

    return (
      <Segment>
        <div style={{ marginTop: '1em'}}>
          <Checkbox
            label="方向補正"
            name="direction"
            checked={!!aiProcessKeys.direction}
            onChange={this.handleChange}
            style={{width:"150px",marginRight:"20px"}}
          />
          <Select
            error={errors['direction'] && errors['direction']['flag']}
            options={defOptions}
            value={aiProcessValues.direction}
            name="direction"
            onChange={this.handleSelectChange}
          />
        </div>
        <div style={{ marginTop: '1em'}}>
          <Checkbox
            label="分類"
            name="pageClassification"
            checked={!!aiProcessKeys.pageClassification}
            onChange={this.handleChange}
            style={{width:"150px",marginRight:"20px"}}
          />
          <Select
            error={errors['pageClassification'] && errors['pageClassification']['flag']}
            options={defOptions}
            value={aiProcessValues.pageClassification}
            name="pageClassification"
            onChange={this.handleSelectChange}
          />
        </div>
        <div style={{ marginTop: '1em'}}>
          <Checkbox
            label="ページ分割"
            name="split"
            checked={!!aiProcessKeys.split}
            onChange={this.handleChange}
            style={{width:"150px",marginRight:"20px"}}
          />
          <Select
            error={errors['split'] && errors['split']['flag']}
            options={defOptions}
            value={aiProcessValues.split}
            name="split"
            onChange={this.handleSelectChange}
          />
        </div>
        <div style={{ marginTop: '1em'}}>
          <Checkbox
            label="文言枠識別"
            name="bbox"
            checked={!!aiProcessKeys.bbox}
            onChange={this.handleChange}
            style={{width:"150px",marginRight:"20px"}}
          />
          <Select
            error={errors['bbox'] && errors['bbox']['flag']}
            options={defOptions}
            value={aiProcessValues.bbox}
            name="bbox"
            onChange={this.handleSelectChange}
          />
        </div>
        <div style={{ marginTop: '1em'}}>
          <Checkbox
            label="OCR"
            name="ocr"
            checked={!!aiProcessKeys.ocr}
            onChange={this.handleChange}
            style={{width:"150px",marginRight:"20px"}}
          />
          <Select
            error={errors['ocr'] && errors['ocr']['flag']}
            options={defOptions}
            value={aiProcessValues.ocr}
            name="ocr"
            onChange={this.handleSelectChange}
          />
        </div>
        <div style={{ marginTop: '1em'}}>
          <Checkbox
            label="文言の種類"
            name="cellClassification"
            checked={!!aiProcessKeys.cellClassification}
            onChange={this.handleChange}
            style={{width:"150px",marginRight:"20px"}}
          />
          <Select
            error={errors['cellClassification'] && errors['cellClassification']['flag']}
            options={defOptions}
            value={aiProcessValues.cellClassification}
            name="cellClassification"
            onChange={this.handleSelectChange}
          />
        </div>
        <div style={{ marginTop: '1em'}}>
          <Checkbox
            label="key-value識別"
            name="pair"
            checked={!!aiProcessKeys.pair}
            onChange={this.handleChange}
            style={{width:"150px",marginRight:"20px"}}
          />
          <Select
            error={errors['pair'] && errors['pair']['flag']}
            options={defOptions}
            value={aiProcessValues.pair}
            name="pair"
            onChange={this.handleSelectChange}
          />
        </div>
        <div style={{ marginTop: '1em'}}>
          <Button 
            onClick={this.handleSave}
            disabled={checkList.length === 0}
          >{intl.get('_project.process.Process.Save')}</Button>
        </div>
      </Segment>
    );
  }
}
