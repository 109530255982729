import React, { Component } from 'react';
import { Menu, Segment } from 'semantic-ui-react';
import intl from 'react-intl-universal';
import Ipfile from './ipfile';
import Groupfile from './groupfile';
export default class Blackip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '/system/blackip',
    };
  }

  handleSwitchTab = label => {
    this.setState({activeTab: label});
  }

  render() {
    const { activeTab } = this.state
    const tabs = [
      {
        label: intl.get('_system.blackip.Index.IpFile'),
        url: '/system/blackip'
      },
      {
        label: intl.get('_system.blackip.Index.GroupFile'),
        url: '/system/blackip/groupFile'
      },
    ];

    return (
      <Segment>
        <Menu
          vertical
          tabular
          style={{ position: 'absolute', left: '-100px', width: '100px' }}
        >
          { 
            tabs.map(tab => {
              return <Menu.Item
                name = {tab.label}
                active={ activeTab === tab.url}
                onClick={() => this.handleSwitchTab(tab.url)}
                key={tab.label}
              />
            })
          }
        </Menu>
        {
        activeTab === '/system/blackip' && <Ipfile />
        }
        {
        activeTab === '/system/blackip/groupFile' && <Groupfile />
        }
      </Segment>
    );
  }
}
