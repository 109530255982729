import React, { Component } from 'react';

import { Form, Button, Message } from 'semantic-ui-react';
import { MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact';
import api from '../../api'
import intl from 'react-intl-universal';

export default class ImportMetadata extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      loading: true,
      importError: null,
    };
    this.handleCsvSubmit = this.handleCsvSubmit.bind(this);
    this.clearAll = this.clearAll.bind(this);
  }

  async handleCsvSubmit(e) {

    if(!this.state.file) return;

    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);

    this.setState({ loading: false });
    try {
      let baseUrl = `/dppapi/admin/project/metadata/import?projectId=${this.props.projectId}`;
      if (this.props.tenantId) {
        baseUrl += `&tenantId=${this.props.tenantId}`;
      }
      await api.post(baseUrl, formData);
      this.setState({ 
        file: null,
        loading: true,
        importError: null
      });
      form.reset();
      this.props.getProjectMetadatas();
      this.props.toggleImport(false);
    } catch (error) {
      this.setState({
        file: null,
        loading: true,
        importError: error.data.errmsg,
      });
    }
  }

  clearAll() {
    this.setState({ 
      file: null,
      loading: true,
      importError: null
    });
    this.props.toggleImport(false);
  }

  render() {
    const { loading, importError } = this.state;

    const importMessage = importError ? (
      <Message negative>{importError}</Message>
    ) : null;

    return (
      <div style={{cursor:'default'}}>
        <MDBModal 
          isOpen={this.props.isShowImport} 
          toggle={(flag) => {this.props.toggleImport({isShowEdit: flag})}}
          centered
          >
          <MDBModalHeader>
            {intl.get('_project.metadata.ImportMetadata.Import project metadata')}
          </MDBModalHeader>
          <MDBModalBody>
            {importMessage}
            <Form
              method="post"
              encType="multipart/form-data"
              onSubmit={this.handleCsvSubmit}
            >
              <Form.Input
                multiple
                label={intl.get('_project.metadata.ImportMetadata.Select CSV files')}
                type="file"
                id="csv"
                name="csv"
                accept=".csv"
                onChange={e => {this.setState({ file: e.target.value })}}
              />
              <Button floated='right' type="submit" loading={!loading} disabled={!loading} >{intl.get('_project.metadata.ImportMetadata.Import')}</Button>
              <Button floated='right' onClick={this.clearAll}>{intl.get('_project.metadata.ImportMetadata.Cancel')}</Button>
            </Form>
          </MDBModalBody>
        </MDBModal>
      </div>
    );
  }
}
