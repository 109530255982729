import React, { Component } from 'react';
import { Segment, Button, Loader, Input, TextArea, Menu, Container } from 'semantic-ui-react';
import { confirmAlert } from 'react-confirm-alert';
import update from 'immutability-helper';
import api from '../../api'
import intl from 'react-intl-universal';
import { ROOT_URI } from '../../HistoryListener'
import ReactMarkdown from 'react-markdown'

export default class ProjectEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      project: null,
      activeTab: 'MarkdownOverview',
      tabs: [
        {
          label: intl.get('_label.LabelManagementTable.Overview'),
          component: 'MarkdownOverview'
        },
        {
          label: intl.get('_label.LabelManagementTable.Edit'),
          component: 'MarkdownEdit'
        },
      ],
    };

    this.handleSwitchTab = this.handleSwitchTab.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
  }

  handleSwitchTab(e, { name, component }) {
    this.setState({
      activeTab: component
    })
  }

  async componentDidMount() {
    const { projectId } = this.props;
    const project = (await api.get('/dppapi/projects/' + projectId)).data;
    this.setState({
      isLoaded: true,
      project,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { project } = this.state;
    if (!project) return;
    const { projectId } = this.props;

    if (prevState.project !== project) {
      api.patch('/dppapi/projects/' + projectId, {project: project});
    }
  }

  commonDialog(title_text, content_text, button_name, click_event) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='project-edit-popup'>
            <div><span className='pageType-change-remind-title'>{title_text}</span></div>
            <div className='pageType-change-remind-body project-edit-display'>
              {
                content_text.map((text, index) => (<span key={index}>{text}</span>))
              }
            </div>
            <div className='custom-ui-confirm-alert-button-group' style={{ width: '100%' }}>
              <button
                className='primary-btn confirmAlert-button-right'
                onClick={()=>{
                  click_event();
                  onClose();
                }}>
                {button_name}
              </button>
              <button
                className='predict-admin-botton-text confirmAlert-button-left'
                onClick={onClose}>
                {intl.get('_predict.detail.pdf.Canvas.Cancel')}
              </button>
            </div>
          </div>
        );
      }
    });
  }

  async handleDelete() {
    const title_text = intl.get('_project.project.ProjectEdit.DeleteTitle');
    const content_text = intl.get('_project.project.ProjectEdit.DeleteContext');
    const button_name = intl.get('_project.project.ProjectEdit.DeleteButtonName');
    const click_event = async () => {
      const { projectId, history } = this.props;
      await api.delete('/dppapi/projects/' + projectId);
      history.push('/project/');
    }
    this.commonDialog(title_text, content_text, button_name, click_event);
  }

  handleNameChange(e) {
    const { value } = e.target;
    const { project } = this.state;
    this.setState({
      project: update(project, {
        name: {
          $set: value,
        },
      }),
    });
  }

  handleDescriptionChange(e) {
    const { value } = e.target;
    const { project } = this.state;
    this.setState({
      project: update(project, {
        description: {
          $set: value,
        },
      }),
    });
  }

  async handleCopy() {
    const title_text = intl.get('_project.project.ProjectEdit.CopyTitle');
    const content_text = intl.get('_project.project.ProjectEdit.CopyContext');
    const button_name = intl.get('_project.project.ProjectEdit.CopyButtonName');
    const click_event = async () => {
      const { project } = this.state;
      this.setState({
        isLoaded: false
      })
      try {
        const { history } = this.props;
        await api.put(`/dppapi/projects/${project.id}`);
        history.push('/project/');
      } catch (error) {
        this.setState({
          isLoaded: true
        })
        confirmAlert({
          message: 'Reached tenant limit amount of project',
          buttons: [
            {
              label: 'キャンセル',
            },
          ]
        });
      }
    }
    this.commonDialog(title_text, content_text, button_name, click_event);
  }

  render() {
    const { projectId } = this.props;

    const { isLoaded, project, activeTab, tabs } = this.state;

    if (!isLoaded) {
      return <Loader active inline="centered" />;
    }

    return (
      <Segment>
        <Input
          placeholder="Project name"
          control="input"
          defaultValue={project.name}
          style={{ fontSize: 24, width: '100%', marginTop: 10 }}
          onChange={this.handleNameChange}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            marginTop: 5,
          }}
        >
          <Input
            label="Labeling link"
            value={window.location.origin + ROOT_URI + '/label/manage/' + projectId}
            onClick={e => e.target.select()}
          />
        </div>
        <Container className="project-edit-container">
            <Menu pointing secondary>
            {
              tabs.map(tab => {
                return <Menu.Item
                  name={tab.label}
                  component={tab.component}
                  active={activeTab === tab.component}
                  onClick={this.handleSwitchTab}
                  key={tab.label}
                />
              })
            }
          </Menu>
          {
            activeTab === 'MarkdownOverview' && <ReactMarkdown>{project.description}</ReactMarkdown>
          }
          {
            activeTab === 'MarkdownEdit' && <div className="ui form" style={{ marginTop: 5 }}>
              <TextArea style={{ fontSize: 16, padding: 22 }} placeholder="Brief Description" defaultValue={project.description} name="description" onChange={this.handleDescriptionChange} rows="10" />
            </div>
          }
        </Container>
        <div className='project-edit-outer-div'>
          <div id="copy-project" className='project-edit-over-view-div'>
            <Button onClick={this.handleCopy}>
              {intl.get('_project.project.ProjectEdit.CopyProjectButton')}
            </Button>
            <p className='project-edit-over-view-p'>
              {intl.get('_project.project.ProjectEdit.CopyProjectText')}
            </p>
          </div>
          <div id="delete-project" className='project-edit-over-view-div'>
            <Button negative onClick={this.handleDelete}>
              {intl.get('_project.project.ProjectEdit.DeleteProjectButton')}
            </Button>
            <p className='project-edit-over-view-p'>
              {intl.get('_project.project.ProjectEdit.DeleteProjectText')}
            </p>
          </div>
        </div>
      </Segment>
    );
  }
}
