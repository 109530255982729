import React, { Component } from 'react';
import './index.css';
import PropTypes from 'prop-types';

export default class Toasts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };

    this.handleShow = this.handleShow.bind(this);
  }

  componentDidMount() {
    const { isOpen } = this.props;
    if (this.state.show !== isOpen) {
      this.setState({ show: isOpen });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isOpen } = this.props;
    if (this.state.show !== isOpen) {
      this.setState({ show: isOpen });
    }
  }

  handleShow = flag => {
    this.props.handlePortalClose();
    this.setState({ show: flag });
  };

  render() {
    const { show } = this.state;
    const { content, isClose, closeText, classNames } = this.props;

    const triggerChildren = (
      <div id="snackbar" className={show ? `show ${classNames}` : classNames}>
        {content}
        {isClose && (
          <div className="close-btn" onClick={() => this.handleShow(false)}>
            {closeText}
          </div>
        )}
      </div>
    );

    return <React.Fragment>{triggerChildren}</React.Fragment>;
  }
}

Toasts.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  content: PropTypes.any.isRequired,
  isClose: PropTypes.bool,
  closeText: PropTypes.string,
  classNames: PropTypes.string,
  handlePortalClose: PropTypes.func,
};

Toasts.defaultProps = {
  isClose: true,
  closeText: 'キャンセル',
  classNames: '',
  handlePortalClose: () => {},
};
