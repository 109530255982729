import React, { Component } from 'react';
import { Segment, Header, Button, Loader, Icon } from 'semantic-ui-react';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import update from 'immutability-helper';
import arrayMove from 'array-move';
import LabelFormItem from './LabelFormItem';
import LabelImport from './LabelImport'
import api from '../../api'
import { downloadCsv } from '../../predict/Util'
import { cloneDeep } from 'lodash'

const errMsg = "Name already exists";
export default class LabelEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      project: null,
      error: false,
      showImportDialog: false,
      importError: []
    };

    this.onSortEnd = this.onSortEnd.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNew = this.handleNew.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  async componentDidMount() {
    const { projectId } = this.props;
    const project = (await api.get('/dppapi/projects/' + projectId)).data;
    this.setState({
      isLoaded: true,
      project,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { project, error } = this.state;
    if (!project) return;
    const { projectId } = this.props;

    if (!error && prevState.project !== project) {
      api.patch('/dppapi/projects/' + projectId, {project: project});
    }
  }

  onSortEnd({ oldIndex, newIndex }) {
    this.setState(({ project }) => ({
      project: update(project, {
        form: {
          formParts: {
            $set: arrayMove(project.form.formParts, oldIndex, newIndex),
          },
        },
      }),
    }));
  }

  handleImport = (formParts) => { 

    // validate first
    const duplicatedMsg = []
    const invalidMsg = []
    const allowedType = ['bbox', 'polygon', 'select', 'select-one', 'text']
    formParts.forEach((f, i) => {
      const duplicatedIdx = formParts.findIndex(e => e.name === f.name)
    
      // label name duplicated check  
      if (duplicatedIdx !== -1 && duplicatedIdx !== i) {
        duplicatedMsg.push(`duplicated label name [ ${f.name} ] at line ${i + 1}`)
      }

      // label type valiad check
      if (allowedType.indexOf(f.type) === -1) { 
        invalidMsg.push(`invalid label type [ ${f.type} ] at line ${i + 1}`)
      }
    })

    const msg = duplicatedMsg.concat(invalidMsg)

    if (msg.length > 0) { 
      this.setState({importError : msg})
    } else { 
      const project = cloneDeep(this.state.project)
      project.form.formParts = formParts
      this.setState({project, showImportDialog: false})
    }
  }

  handleChange(oldValue, newValue, type) {
    const { project } = this.state;
    if (type === 'label-name' || type === 'label-del') {
      this.handleValidate(oldValue, newValue, project.form.formParts)
    }
    const edit = newValue ? [1, newValue] : [1];
    this.setState({
      project: update(project, {
        form: {
          formParts: {
            $splice: [
              [
                project.form.formParts.findIndex(x => x.id === oldValue.id),
                ...edit,
              ],
            ],
          },
        },
      }),
    });
  }
  
  handleValidate = (oldValue, newValue, target) => {

    // newValue(edit) oldValue(delete)
    const cur = newValue ? newValue : oldValue
    let err = false
    let errIdx = []
    if (newValue) {// edit validate
      target.forEach((label, idx) => {
        if (label.name === cur.name) {
          err = true
          label['error'] = { content: errMsg }
          errIdx.push(idx)
        } else {
          delete label.error
        }
      });
    } else {// delete validate
      let errIdx = []
      target.forEach((label, idx) => {
        if (label.name === cur.name && label.id !== cur.id) {
          err = true
          label['error'] = { content: errMsg }
          errIdx.push(idx)
        }

      });
      if (errIdx.length === 1) {
        err = false
        delete target[errIdx[0]].error
      }
    }

    if (err) {
      cur['error'] = { content: errMsg }
    } else {
      delete cur.error
    }
    this.handleError(err)
  }

  handleError = err => {
    this.setState({ error: err })
  }

  handleShowImportDialog = (open) => {
    this.setState({
      showImportDialog: open,
      importError:[]
    });
  }

  handleExport = () => { 

    const formParts = this.state.project.form.formParts

    const meta = [['name', "type", 'options', ""]]

    formParts.forEach(f => { 
      const labels = Object.values(f)
      labels.shift()
      meta.push(Object.values(labels))
    })

    const option = { } 
    const fileName = `label-data-${new Date().getTime()}.csv`
    downloadCsv(meta, option, fileName)
  }

  handleNew() {
    const { project } = this.state;
    const label = newFormPart();
    this.handleValidate(null, label, project.form.formParts);
    this.setState({
      project: update(project, {
        form: {
          formParts: {
            $push: [label],
          },
        },
      }),
    });
  }

  render() {
    const { isLoaded, project, error } = this.state;

    if (!isLoaded) {
      return <Loader active inline="centered" />;
    }

    const items = project.form.formParts;

    const renderedItems = items.length ? (
      items.map((value, index) => (
        <SortableItem
          key={value.id}
          index={index}
          value={value}
          error={error}
          onChange={this.handleChange}
        />
      ))
    ) : (
      <Header className="centered" as="h5">
        No labels, add labels using the plus button below
      </Header>
    );

    return (
      <Segment>
        <div style={{ display: 'flex', flexDirection: 'row-reverse'}}>
          <Button icon onClick={()=>this.handleExport()}>
            <Icon name="download" />
            </Button>
            <Button icon onClick={()=>this.handleShowImportDialog(true)}>
            <Icon name="file" />
          </Button>
        </div>
        <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
          {renderedItems}
        </SortableContainer>
        <Button
          circular
          icon="plus"
          size="massive"
          disabled={error}
          style={{ float: 'right', marginTop: '2em' }}
          onClick={this.handleNew}
        />
        
        { this.state.showImportDialog ? <LabelImport
          showImportDialog={this.state.showImportDialog}
          onImport={this.handleImport}
          onShowImportDialog={this.handleShowImportDialog}
          importError={ this.state.importError}
        /> : null}
      </Segment>
    );
  }
}

const SortableItem = sortableElement(LabelFormItem);

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

const newFormPart = () => {
  const id = Math.random()
    .toString(36)
    .substr(2, 9);
  return {
    id,
    name: 'New label',
    type: 'bbox',
  };
};
