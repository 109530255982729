import React from 'react';

import { MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact';
import {
  Button,
  Form,
  Message
} from 'semantic-ui-react';

import api from '../../api'
import intl from 'react-intl-universal';

export default class ProjectMetadataEdit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      projectMetadata: {},
      errors: {}
    }

    this.handleInput = this.handleInput.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.clearErrors = this.clearErrors.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.checkForm = this.checkForm.bind(this)
    this.clearProjectMetadata = this.clearProjectMetadata.bind(this)
    this.clearAll = this.clearAll.bind(this)
  }

  handleInput(event) {
    const {name, value} = event.target
    const projectMetadata = {...this.state.projectMetadata}
    projectMetadata[name] = value
    this.setState({
      projectMetadata: projectMetadata
    })
  }

  handleFocus() {
    this.clearErrors()
  }

  clearAll() {
    this.clearErrors()
    this.clearProjectMetadata()
    this.props.toggleEdit({isShowEdit: false})
  }

  clearErrors() {
    this.setState({
      errors: {}
    })
  }

  clearProjectMetadata() {
    this.setState({
      projectMetadata: {}
    })
  }

  async handleSave() {
    const projectMetadata = Object.assign({}, this.props.projectMetadata, this.state.projectMetadata)
    const { projectId, tenantId, isSystemManager } = this.props;
    if (!this.checkForm(projectMetadata)) {
      return
    }
    let baseUrl;
    if (isSystemManager) {
      baseUrl = `/dppapi/system/tenant-manage/project/metadata/${this.props.projectMetadata.id ? 'update' : 'add'}?projectId=${projectId}&tenantId=${tenantId}`;
    } else {
      baseUrl = `/dppapi/admin/project/metadata/${this.props.projectMetadata.id ? 'update' : 'add'}?projectId=${projectId}`;
    }
    await api.post(baseUrl, {
      metadatas: [projectMetadata]
    })

    this.clearAll()
    this.props.getProjectMetadatas()
  }

  checkForm(projectMetadata) {
    const errors = {}
    if (!projectMetadata.category) {
      errors['category'] = {content: 'Please enter a category.', pointing: 'below', flag: true}
    }

    if (!projectMetadata.item) {
      errors['item'] = {content: 'Please enter a item.', pointing: 'below', flag: true}
    }

    if (!projectMetadata.value) {
      errors['value'] = {content: 'Please enter a value.', pointing: 'below', flag: true}
    }
    
    this.setState({
      errors: errors
    })
    return Object.keys(errors).length === 0
  }

  render() {
    const { projectMetadata } = this.props
    const { errors } = this.state

    return (
      <MDBModal isOpen={this.props.isShowEdit} toggle={(flag) => {this.props.toggleEdit({isShowEdit: flag})}} centered>
        <MDBModalHeader>
          {
            projectMetadata && projectMetadata.id 
            ? intl.get('_project.metadata.ProjectMetadataEdit.Edit Metadata')
            : intl.get('_project.metadata.ProjectMetadataEdit.Add Metadata')
          }
        </MDBModalHeader>
        <MDBModalBody>
        {
          Object.keys(errors).length > 0 &&
          <Message
            error
            list={ Object.keys(errors).map(i => {
              return errors[i].content
            })}
          />
        }
          <Form>
            <Form.Field error={errors['category'] && errors['category']['flag']}>
              <label>{intl.get('_project.metadata.ProjectMetadataEdit.Category')}</label>
              <Form.Input placeholder="Category" defaultValue={projectMetadata.category} name="category" onChange={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            <Form.Field>
              <label>{intl.get('_project.metadata.ProjectMetadataEdit.SubCategory')}</label>
              <Form.Input placeholder="SubCategory" defaultValue={projectMetadata.subCategory} name="subCategory" onChange={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            <Form.Field error={errors['item'] && errors['item']['flag']}>
              <label>{intl.get('_project.metadata.ProjectMetadataEdit.Item')}</label>
              <Form.Input placeholder="Item" defaultValue={projectMetadata.item} name="item" onChange={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            <Form.Field error={errors['value'] && errors['value']['flag']}>
              <label>{intl.get('_project.metadata.ProjectMetadataEdit.Value')}</label>
              <Form.Input placeholder="Value" defaultValue={projectMetadata.value} name="value" onChange={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
              <Button floated='right' onClick={this.handleSave}>{intl.get('_project.metadata.ProjectMetadataEdit.Save')}</Button>
              <Button floated='right' onClick={this.clearAll}>{intl.get('_project.metadata.ProjectMetadataEdit.Cancel')}</Button>
          </Form>
        </MDBModalBody>
      </MDBModal>
    );
  }
}
