import React from 'react';

import { MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact';
import {
  Button,
  Form,
  Message,
  Checkbox,
} from 'semantic-ui-react';
import api from '../../api';
import intl from 'react-intl-universal';

export default class RelpacementEdit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      replacement: {},
      errors: {}
    }

    this.handleInput = this.handleInput.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.checkForm = this.checkForm.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.clearErrors = this.clearErrors.bind(this)
    this.clearReplacement = this.clearReplacement.bind(this)
  }

  handleInput(event) {
    const {name, value} = event.target
    const replacement = {...this.state.replacement}
    replacement[name] = value
    this.setState({
      replacement
    })
  }

  handleFocus(event) {
    const { errors } = this.state
    const {name} = event.target
    delete errors[name]
    this.clearErrors(errors)
  }

  clearErrors(errors) {
    this.setState({
      errors
    })
  }

  clearReplacement() {
    this.setState({
      replacement: {}
    })
  }

  async handleSave() {
    const replacement = Object.assign({}, this.props.replacement, this.state.replacement)

    if(!this.checkForm(replacement)) {
      return
    }
    await api.request({
      url: `/dppapi/system/replacement${this.props.replacement.id ? '/' + this.props.replacement.id : ''}`,
      method: this.props.replacement.id ? 'PUT' : 'POST',
      data: {...replacement, tenantId: this.props.tenantId}
    })

    this.props.getReplacement()
    this.props.toggleEdit(false)
    this.clearErrors({})
    this.clearReplacement()
  }

  checkForm(replacement) {
    const errors = {}
    if (!replacement.ocrText) {
      errors['ocrText'] = {content: 'Please enter a ocr text.', pointing: 'below', flag: true}
    }

    if (!replacement.replacement) {
      errors['replacement'] = {content: 'Please enter a replacement.', pointing: 'below', flag: true}
    }
    
    this.setState({
      errors: errors
    })
    return Object.keys(errors).length === 0
  }

  handleCheckBox = (e, data) =>{
    const replacement = {...this.state.replacement}
    replacement[data.name] = data.checked ? 1: 0
    this.setState({
      replacement
    })
  }

  render() {
    const { replacement } = this.props
    const { errors } = this.state

    return (
      <MDBModal isOpen={this.props.isShowEdit} toggle={this.props.toggleEdit}>
        <MDBModalHeader>
          {
            this.props.replacement && this.props.replacement.id 
            ? intl.get('_system.replacement.Edit.Edit Replacement') 
            : intl.get('_system.replacement.Edit.Add Replacement')
          }
        </MDBModalHeader>
        <MDBModalBody>
        {
          Object.keys(errors).length > 0 &&
          <Message
            error
            list={ Object.keys(errors).map(i => {
              return errors[i].content
            })}
          />
        }
          <Form>
            <Form.Field error={errors['ocrText'] && errors['ocrText']['flag']}>
              <label>{intl.get('_system.replacement.Edit.OcrText')}</label>
              <Form.Input placeholder="ocrText" defaultValue={replacement.ocrText} name="ocrText" onChange={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            <Form.Field error={errors['replacement'] && errors['replacement']['flag']}>
              <label>{intl.get('_system.replacement.Edit.Replacement')}</label>
              <Form.Input placeholder="replacement" defaultValue={replacement.replacement} name="replacement" onChange={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            <Form.Field >
              <label>{intl.get('_system.replacement.Index.Scope')}</label>
              <div style={{display: 'flex',justifyContent: 'space-around'}}>
                <Checkbox 
                    label={intl.get('_system.replacement.Index.Easyocr')}
                    name="easyocr"
                    onChange={this.handleCheckBox}
                    defaultChecked={replacement.easyocr===1}
                  />
                <Checkbox 
                    label={intl.get('_system.replacement.Index.FinancialStatement')}
                    name="financialStatement"
                    onChange={this.handleCheckBox}
                    defaultChecked={replacement.financialStatement===1}
                  />
              </div>
            </Form.Field>
            <Button 
              floated='right' 
              onClick={this.handleSave}
              >{intl.get('_common.Save')}</Button>
            <Button 
              floated='right' 
              onClick={() => {
                this.props.toggleEdit(false)
                this.clearErrors({})
                this.clearReplacement()
              }}
              >{intl.get('_common.Cancel')}</Button>
          </Form>
        </MDBModalBody>
      </MDBModal>
    )
  }
}
