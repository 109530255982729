import React, { PureComponent } from 'react';
import {
  Header,
  List,
  Icon,
  Button,
  Form,
  Checkbox,
  Radio,
  Select,
} from 'semantic-ui-react';
import { shortcuts, colors } from './utils';
import Hotkeys from 'react-hot-keys';
import TimeLine from '../timeline'

const headerIconStyle = { fontSize: '0.8em', float: 'right' };
export default class Sidebar extends PureComponent {
  state = {
    isShowEditDescription: false,
    snapshots: []
  };

  constructor(props) {
    super(props);
    this.toggleEditDescription = this.toggleEditDescription.bind(this);
  }

  // async componentDidMount() {
  //   await this.getProjects();
  // }

  toggleEditDescription({ isShowEditDescription, snapshots }) {
    this.setState({
      isShowEditDescription: isShowEditDescription,
      snapshots: snapshots || [],
    });
  }

  render() {
    const { isShowEditDescription  } = this.state;
    const {
      title,
      onSelect,
      labels,
      selected,
      toggles,
      onToggle,
      filter,
      style,
      openHotkeys,
      onBack,
      onSnapshot,
      onSkip,
      onNextLabeled,
      onChangeLabeled,
      labeled,
      onSubmit,
      labelData,
      onFormChange,
      models,
      makePrediction,
      userName,
      reviewName,
      onCheckout,
      snapshots,
      onLabelChange
    } = this.props;

    const hotkeysButton = openHotkeys ? (
      <Icon
        link
        name="keyboard"
        style={headerIconStyle}
        onClick={openHotkeys}
      />
    ) : null;
    const assigneesRender = (
      <Button
        icon="pencil alternate"
        label={userName || '担当'}
        title="担当者変更"
        onClick={() => onCheckout('manage')}
        color='vk'
        size='mini'
      />
    );
    const review = (
      <Button
        icon="search"
        label={reviewName || 'レビュー'}
        title="レビュー者変更"
        onClick={() => onCheckout('review')}
        color='vk'
        size='mini'
      />
    )
    const getSelectHandler = ({ type, id }) =>
      type === 'bbox' || type === 'polygon' ? () => onSelect(id) : null;
    return (
      <div>
        <TimeLine
          isShowEdit={ isShowEditDescription }
          snapshots={ snapshots }
          onLabelChange={ onLabelChange }
          toggleEdit={this.toggleEditDescription}
        />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1em 0.5em',
          borderRight: '1px solid #ccc',
          height: '100%',
          ...style,
        }}
      >
        <Header size="medium" style={{ flex: '0 0 auto', display: 'flex' }}>
          <Button secondary onClick={onNextLabeled}>
            Next
          </Button>
          <span style={{ flex: 1 }} />
          <Button color={labeled ? 'orange' : 'green'} onClick={onChangeLabeled}>
            {labeled ? 'Labeled' : 'Labeling'}
          </Button>
        </Header>
        <Header size="medium" style={{ flex: '0 0 auto', display: 'flex', margin: '0px' }}>
          {assigneesRender}
          <span style={{ flex: 1 }} />
          {review}
        </Header>
        <Header size="medium" style={{ flex: '0 0 auto' }}>
          {title}
          {hotkeysButton}
        </Header>
        <List divided selection style={{ flex: 1, overflowY: 'auto' }}>
          {labels.map((label, i) =>
            ListItem({
              shortcut: shortcuts[i],
              label,
              color: colors[i],
              onSelect: getSelectHandler(label),
              selected: selected === label.id,
              disabled: filter ? !filter(label) : false,
              onToggle: onToggle,
              isToggled: toggles && toggles[label.id],
              labelData: labelData[label.id],
              onFormChange,
              models,
              makePrediction,
            })
          )}
          <Hotkeys keyName="esc" onKeyDown={() => onSelect(null)} />
        </List>
        <div style={{ flex: '0 0 auto', display: 'flex' }}>
          <Button onClick={onBack}>Back</Button>
          <span style={{ flex: 1 }} />
          <Hotkeys keyName="command+k" onKeyDown={onSkip}>
            <Button primary onClick={onSkip}>
              Skip
            </Button>
          </Hotkeys>
          <Hotkeys keyName="command+u" onKeyDown={onSubmit}>
            <Button primary onClick={onSubmit}>
              Submit
            </Button>
          </Hotkeys>
        </div>
        <div style={{ flex: '0 0 auto', display: 'flex', marginTop: '8px' }}>
          <Button onClick={onSnapshot}>Take Snapshot</Button>
          <Button primary onClick={() => {
              this.toggleEditDescription({
                snapshots: snapshots,
                isShowEditDescription: true,
              });
            }}>Snapshot Timeline
          </Button>
        </div>
      </div>
      </div>
    );
  }
}

const iconMapping = {
  bbox: 'object ungroup outline',
  polygon: 'pencil alternate',
};

const typeHidable = {
  bbox: true,
  polygon: true,
  text: false,
  select: false,
  'select-one': false,
};
function ListItem({
  shortcut,
  label,
  onSelect,
  onToggle,
  color,
  selected = false,
  disabled = false,
  isToggled = false,
  labelData,
  onFormChange,
  models,
  makePrediction,
}) {
  const icons = [];

  if (onToggle && typeHidable[label.type]) {
    icons.push(
      <Button
        key="visibility-icon"
        icon={isToggled ? 'eye' : 'eye slash'}
        style={{ padding: 5 }}
        onClick={e => {
          onToggle(label);
          e.stopPropagation();
        }}
      />
    );
  }

  const iconType = iconMapping[label.type];
  const figureIcon = iconType ? (
    <Icon
      key="type-icon"
      name={iconType}
      style={{ opacity: 0.5, display: 'inline-block', marginLeft: 5 }}
    />
  ) : null;

  function genSublist(label) {
    const sublistStyle = { fontSize: '12px' };
    if (label.type === 'text') {
      const filteredModels = (models || []).filter(
        ({ type }) => type === 'object_classification'
      );
      const options = filteredModels.map(({ id, name }) => ({
        value: id,
        text: name,
      }));
      const fillInDOM =
        filteredModels.length > 0 ? (
          <div>
            Fill in using a model prediction:
            <Select
              options={options}
              placeholder="Select a model"
              onChange={async (e, { value }) => {
                const m = models.find(({ id }) => id === value);
                const text = (await makePrediction(m)).join(', ');
                onFormChange(label.id, [text]);
              }}
            />
          </div>
        ) : null;
      
      const isDisabled = (label.allowedList === undefined || label.allowedList === "") ? true : false; 
      var txt = labelData[0] || '';
      if (!isDisabled) {
        [...txt].forEach(c => {
          if (!label.allowedList.includes(c)) {
            txt = txt.replace(c, '');
          }
        });
      }

      return (
        <List style={sublistStyle}>
          <List.Item>
            <Form>
              <Form.Input
                label={label.prompt}
                style={{ width: '100%' }}
                value={txt}
                onChange={(e, { value }) => onFormChange(label.id, [value])}
              />
              {fillInDOM}
              <Button onClick={() => alert(label.allowedList)} disabled={isDisabled}>Allowed Characters</Button>
            </Form>
          </List.Item>
        </List>
      );
    }

    if (label.type === 'select') {
      const { options } = label;
      const handleChange = function(option) {
        return (e, { checked }) =>
          onFormChange(
            label.id,
            checked
              ? labelData.concat([option])
              : labelData.filter(x => x !== option)
          );
      };

      const items = options.map(option => (
        <List.Item key={option}>
          <Checkbox
            label={option}
            checked={labelData.indexOf(option) !== -1}
            onChange={handleChange(option)}
          />
        </List.Item>
      ));
      return <List style={sublistStyle}>{items}</List>;
    }

    if (label.type === 'select-one') {
      const { options } = label;
      const items = options.map(option => (
        <List.Item key={option}>
          <Radio
            label={option}
            checked={labelData.indexOf(option) !== -1}
            onChange={(e, { checked }) => onFormChange(label.id, [option])}
          />
        </List.Item>
      ));
      return <List style={sublistStyle}>{items}</List>;
    }

    return null;
  }

  return (
    <List.Item
      onClick={onSelect}
      disabled={disabled}
      active={selected}
      key={label.id}
      style={{ fontSize: '1.3em' }}
    >
      <Hotkeys
        keyName={shortcut}
        onKeyDown={() => !disabled && onSelect && onSelect()}
      >
        <span 
          style={{ backgroundColor: color, color: 'white'}} 
          className='ui label horizontal'>
          {shortcut}
        </span>
        {label.name}
        {figureIcon}
        <span style={{ float: 'right' }}>{icons}</span>
        {genSublist(label)}
      </Hotkeys>
    </List.Item>
  );
}
