import React from 'react';
import { Button, Loader, Segment, Checkbox } from 'semantic-ui-react';
import intl from 'react-intl-universal';
import api from '../../api';
import { createCookie, getCookie } from '../../common/CookieUtil';

export default class PdfUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      loading: false,
      loadFile: false,
      colorFlag: false,
    };
    this.fileInput = React.createRef();
    this.parsingTypes = {
      "1": "貸借対照表",
      "2": "損益計算書",
      "3": "株主資本等変動計算書",
      "4": "製造原価明細表",
      "5": "販売費及び一般管理費"
    };

    this.parsingTypeIdList = ["1", "2", "3", "4", "5"];
    try{
      this.parsingTypeIdList = JSON.parse(getCookie("predict_analysis_targets"));
    }catch{}
  }

  handleCheckboxChange(e, object) {
    if(object.checked){
      if(!this.parsingTypeIdList.includes(object.id)){
        this.parsingTypeIdList.push(object.id);
      }
    }else{
      const index = this.parsingTypeIdList.indexOf(object.id);
      if(index > -1){
        this.parsingTypeIdList.splice(index, 1);
      }
    }
    createCookie('predict_analysis_targets', JSON.stringify(this.parsingTypeIdList), 365);
  }

  handleFilesSubmit = async (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);

    if (this.fileInput.current.files.length === 0) {
      this.setState({
        message: "アップロードしたいファイルを選択してください。",
        loading: false,
        colorFlag: false
      });
      return
    }

    this.setState({ message: null });

    this.setState({ loadFile: true });
    const parsingType = this.parsingTypeIdList.map(item => {
      return this.parsingTypes[item];
    })
    const { data } = await api.post(`/dppapi/predict/pdf-uploads/${this.props.projectId}?parsingType=${JSON.stringify(parsingType)}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    const { message } = data;
    if (data.success) {
      this.setState({
        message: "成功しました。",
        loadFile: false,
        colorFlag: true
      })
    } else {
      this.setState({
        message: message,
        loading: false,
        colorFlag: false
      });
    }
    form.reset();
  }

  render() {
    const { message, loading, loadFile, colorFlag } = this.state;
    const loader = loading ? <Loader /> : null;
    return (
      <Segment>
        <Segment.Group>
          <Segment>{intl.get('_predict.admin.PdfUpload.Analysistitle')}</Segment>
          <Segment.Group horizontal>
            <Segment>
            <Checkbox label={intl.get('_predict.admin.PdfUpload.Analysis_1')} id='1' defaultChecked={this.parsingTypeIdList.includes("1")} onChange={(e, value) => this.handleCheckboxChange(e, value)}/>
            </Segment>
            <Segment>
              <Checkbox label={intl.get('_predict.admin.PdfUpload.Analysis_2')} id='2' defaultChecked={this.parsingTypeIdList.includes("2")} onChange={(e, value) => this.handleCheckboxChange(e, value)}/>
            </Segment>
            <Segment>
              <Checkbox label={intl.get('_predict.admin.PdfUpload.Analysis_3')} id='3' defaultChecked={this.parsingTypeIdList.includes("3")} onChange={(e, value) => this.handleCheckboxChange(e, value)}/>
            </Segment>
            <Segment>
              <Checkbox label={intl.get('_predict.admin.PdfUpload.Analysis_4')} id='4' defaultChecked={this.parsingTypeIdList.includes("4")} onChange={(e, value) => this.handleCheckboxChange(e, value)}/>
            </Segment>
            <Segment>
              <Checkbox label={intl.get('_predict.admin.PdfUpload.Analysis_5')} id='5' defaultChecked={this.parsingTypeIdList.includes("5")} onChange={(e, value) => this.handleCheckboxChange(e, value)}/>
            </Segment>
          </Segment.Group>
        </Segment.Group>
        <form style={{ width: "100%" }}
          label="Upload files from disk"
          method="post"
          encType="multipart/form-data"
          onSubmit={this.handleFilesSubmit}
        >
          <input className="upload" multiple type="file" id="pdf" name="pdf" accept=".pdf, .jpg, .jpeg, .png, .jp2, .tiff, .bmp, .eps, .gif, .icns, .webp, .mpo" ref={this.fileInput} />
          <Button type="submit" hidden={loadFile}>{intl.get('_predict.admin.PdfUpload.Upload')}</Button>
          <Button loading　disabled hidden={!loadFile}>{intl.get('_predict.admin.PdfUpload.Connecting')}</Button>
        </form>
        {loader}
        <div style={{ marginTop: '2em', color: colorFlag ? 'blue' : 'red' }}>{message}</div>
      </Segment>
    );
  }
}
