import React from 'react';
import { Button, Segment, Dropdown } from 'semantic-ui-react';
import api from '../../api';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import intl from 'react-intl-universal';
import './feedback.css';

export default class FeedBackSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment()
        .add(-30, 'd')
        .toDate(),
      endDate: moment().toDate(),
      tenants: [],
      type: '',
      types: intl.get('_system.feedback.parsingTypes'),
      filterTypes: [],
    };
  }

  async componentDidMount() {
    const { startDate, endDate, type, types } = this.state;
    
    const { tenantId } = (await api.get(`/dppapi/info`)).data;
    await this.props.onSearch({
      tenantId,
      type,
      startDate,
      endDate,
    });

    const tenants = (await api.get(`/dppapi/system/tenant`)).data;
    this.setState({
      tenantId,
      filterTypes: types.map((data, idx) => {
        return {
          text: Object.values(data)[0],
          key: `${idx}${Object.values(data)}`,
          value: idx + 1,
        };
      }),
      tenants: tenants.map(data => {
        return { text: data.name, key: data.id, value: data.id };
      }),
    });
  }

  onChange = (type, value) => {
    this.setState({ [type]: value });
  };

  renderEndpointHeader = () => {
    var header = <div key="header" className='table-header'>
        <div key="headercell0" className='endpoint-headerTitle_30'>{'version_name'}</div>
        <div key="headercell1" className='endpoint-headerTitle_40'>{'domain_port'}</div>
        <div key="headercell2" className='endpoint-headerTitle_30'>{'meta'}</div>
      </div>
    return header
  }

  renderEndpoints = (endpoints) => {
    const dom = endpoints.map((endpoint, index) => {
      var meta = JSON.stringify(endpoint['meta']);
      var row = <div key={index} className='endpoint-table-body'>
          <div key={index + "cell0"} className='endpoint-content-box-30'>{endpoint['version_name']}</div>
          <div key={index + "cell1"} className='endpoint-content-box-40'>{endpoint['meta']['domain_port']}</div>
          <div key={index + "cell2"} className='endpoint-content-box-30' title={meta}>{meta.substr(0,20) + "..."}</div>
        </div>
      return row
    })
    return dom
  }

  render() {
    const { onSearch, onGetEasyocr, easyocr } = this.props;
    const { startDate, endDate, tenants, tenantId, type, filterTypes } = this.state;
    return (
      <Segment>
        <div className="ui stackable two column grid">
          <div className='six wide column'>
            <div className="search-feedback-condition">
              <p className="search-feedback-label">{intl.get('_system.feedback.search.ReportTime')}</p>
              <DatePicker
                className="search-feedback-item"
                onChange={date => this.onChange('startDate', date)}
                value={startDate}
                format="y/MM/dd"
              />{' '}
              ~
              <DatePicker
                className="search-feedback-item"
                onChange={date => this.onChange('endDate', date)}
                value={endDate}
                format="y/MM/dd"
              />
            </div>
            <div style={{ paddingTop: '25px' }}>
              <p className="search-feedback-label">{intl.get('_system.feedback.search.TanentSearch')}</p>
              <Dropdown
                placeholder='Select Tenant'
                options={tenants}
                value={tenantId}
                search
                selection
                clearable
                onChange={(event, { value }) =>
                  this.setState({
                    tenantId: value,
                    error:''
                  })
                }
                style={{ width: 'auto' }}
              />
            </div>
            <div style={{ paddingTop: '25px' }}>
              <p className="search-feedback-label-type">{intl.get('_system.feedback.index.Type')}</p>
              <Dropdown
                placeholder='Select Type'
                options={filterTypes}
                value={type}
                search
                selection
                clearable
                onChange={(event, { value }) =>
                  this.setState({
                    type: value,
                    error:''
                  })
                }
                style={{ width: 'auto' }}
              />
            </div>
            <div className="search-feedback-condition">
              <Button type="submit"
                onClick={() => onSearch({
                  tenantId,
                  type,
                  startDate,
                  endDate
                })}>
                {intl.get('_system.feedback.search.Search')}
              </Button>
            </div>
          </div>
          <div className='ten wide column'>
            <div className='ui right floated buttons'>
              <Button onClick={() => onGetEasyocr()}>{intl.get('_system.feedback.index.EasyOCR Endpoints')}</Button>
            </div>
            <div style={{ paddingTop: '50px' }}>
              {easyocr && this.renderEndpointHeader()}
              {easyocr && this.renderEndpoints(easyocr)}
            </div>
          </div>
        </div>
      </Segment>
    );
  }
}
