import React, { Component } from 'react';
import { debounce } from 'lodash';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/monokai.css';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/keymap/sublime';
import {
  Button,
  Form,
  Icon,
  Divider,
} from 'semantic-ui-react';
import api from '../../api';
import { INIT_CODE } from './DefaultData';

export default class APIFunction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      codeVal: INIT_CODE,
      apiFunction: INIT_CODE,
    };

    this.getApiFunction = this.getApiFunction.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  async componentDidMount() {
    await this.getApiFunction()
  }

  async getApiFunction() {
    const { projectId, tenantId, isSystemManager } = this.props;
    let baseUrl;
    if (isSystemManager) {
      baseUrl = `/dppapi/system/tenant-manage/project/api-function?projectId=${projectId}&tenantId=${tenantId}`;
    } else {
      baseUrl = `/dppapi/admin/project/api-function?projectId=${projectId}`;
    }
    const project = (
      await api.get(baseUrl)
    ).data;
    this.setState({
      codeVal: project.data || INIT_CODE,
      apiFunction: project.data || INIT_CODE,
    });
  }

  handleSave() {
    const { projectId, tenantId, isSystemManager } = this.props;
    const { codeVal } = this.state;
    let baseUrl;
    if (isSystemManager) {
      baseUrl = `/dppapi/system/tenant-manage/project/api-function?projectId=${projectId}&tenantId=${tenantId}`;
    } else {
      baseUrl = `/dppapi/admin/project/api-function?projectId=${projectId}`;
    }
    api.put(baseUrl, { apiFunction: codeVal });
  }

  render() {
    const { apiFunction } = this.state;

    return (
      <React.Fragment>
        <Form>
          <Form.Field>
            <Button.Group>
              <Button onClick={this.handleSave} basic={true} color="black">
                <Icon name="save" />
                Save
              </Button>
            </Button.Group>
          </Form.Field>
          <Divider />
          <Form.Field>
            <label>API Function</label>
            <CodeMirror
              value={apiFunction || INIT_CODE}
              options={{
                mode: { name: 'javascript', json: true },
                theme: 'monokai',
                lineNumbers: true,
                tabSize: 2,
                keyMap: 'sublime',
              }}
              editorDidMount={(editor) => {
                editor.setSize('100%', '500px');
              }}
              onChange={debounce((editor, data, value) => {
                this.setState({
                  codeVal: value,
                });
              }, 300)}
            />
          </Form.Field>
        </Form>
      </React.Fragment>
    );
  }
}
