import React, { Component } from 'react';
import { Segment, Button, Input, Dropdown, Grid, Header } from 'semantic-ui-react';
import intl from 'react-intl-universal';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import api from '../../api';

export default class CharReplacement extends Component {
  constructor(props) {
    super(props);

    this.handleProjChange = this.handleProjChange.bind(this);
    this.handleDropDownSelect = this.handleDropDownSelect.bind(this);
    this.handleSpecificReplacement = this.handleSpecificReplacement.bind(this);

    this.projIdInput = React.createRef();
    this.beforeString = React.createRef();
    this.afterString = React.createRef();
    this.state = {
      dbRowCount: 0,
      matchedRowCount: 0,
      matchedLabelCount: 0,
      labelOptions: [],
      labelSelected: null,
      rows: []
    };
  }

  // componentDidMount() {
  //   this.handleProjChange()
  // }

  async doReplacement() {
    confirmAlert({
      title: "Replace all matches",
      message: "Are you sure, you want to do all replacement?",
      buttons: [
        {
          label: intl.get('_label.image.Images.OK'),
          onClick: async () => {
            await api.post(`/dppapi/projects/replace-annotations/${this.projIdInput.inputRef.current.value}`, {
              hashId: this.state.labelSelected,
              before: this.beforeString.inputRef.current.value,
              after: this.afterString.inputRef.current.value
            });
            
            this.doSearch();
          },
        },
        {
          label: intl.get('_label.image.Images.Cancel'),
        },
      ],
    })
  }

  async handleDropDownSelect(event, data) {
    this.setState({
      labelSelected: data.value, 
    });
  }

  async handleSpecificReplacement(imgId, hashcode, labelType) {
    await api.post(`/dppapi/projects/replace-specific-annotation/${this.projIdInput.inputRef.current.value}`, {
      imgId: imgId,
      labelHashId: this.state.labelSelected,
      hashCode: hashcode,
      labeType: labelType,
      before: this.beforeString.inputRef.current.value,
      after: this.afterString.inputRef.current.value
    });
  }

  async doSearch() {
    try {
      const response = await api.post(`/dppapi/projects/search-annotations/${this.projIdInput.inputRef.current.value}`, {
        hashId: this.state.labelSelected,
        before: this.beforeString.inputRef.current.value
      });
  
      let rows = [];
      const body = response.data;
      if (body) {
        let totalLen = 0;
        let totalMatchRow = 0;
        const dbRowCount = body["count"]["count"];
        const matches = body["matches"];
        for (var i=0; i<matches.length; ++i) {
          totalLen += matches[i].annotation.length;
          totalMatchRow += (matches[i].annotation.length > 0)? 1 : 0;
          for (var j=0; j<matches[i].annotation.length; ++j) {
            const hashcode = matches[i].annotation[j].hash;
            const labeltype = matches[i].annotation[j].type;
            const imgId = matches[i].id;
            rows.push({
              "id": imgId,
              "link": (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to={`/label/${this.projIdInput.inputRef.current.value}/${imgId}`}
                >
                  {matches[i].img}
                </Link>),
              "hashcode": hashcode,
              "type": labeltype,
              "text": matches[i].annotation[j].text,
              "action": (<Button
                icon="clone"
                label="replace"
                size="tiny"
                onClick={() => this.handleSpecificReplacement(imgId, hashcode, labeltype)}
              />)
            });
          }
        }

        this.setState({
          dbRowCount: dbRowCount,
          matchedRowCount: totalMatchRow,
          matchedLabelCount: totalLen,
          rows: rows,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async handleProjChange(event) {
    //TODO: chage it to /demo/new/api/...
    const response = await api.get(`/dppapi/projects/predict/project/${this.projIdInput.inputRef.current.value}`);

    try {
      const rslt = response.data;
      let options = [];
      rslt.form.formParts.forEach((e, index) => {
        if (e.type === "bbox" || e.type === "polygon" || e.type === "text") {
          options.push({ key: e.id, text: `${e.type} | ${e.name}`, value: e.id });
        }
      })
      
      if (options.length > 0) {
        this.setState({
          labelOptions: options,
          labelSelected: options[0].value, 
        });
      }
    } catch (error) {
      this.setState({
        labelOptions: [],
        labelSelected: null, 
      });
    }
  }

  render() {
    const  { rows, dbRowCount, matchedRowCount, matchedLabelCount } = this.state
    const columns = [
      {
        label: intl.get('_label.image.Images.ID'),
        field: 'id',
        sort: 'asc',
        width: 50
      },
      {
        label: intl.get('_label.image.Images.ImageLink'),
        field: 'link',
        sort: 'disabled',
        width: 200
      },
      {
        label: "hash code",
        field: 'hashcode',
        sort: 'disabled',
        width: 80
      },
      {
        label: "type",
        field: 'type',
        sort: 'disabled',
        width: 80
      },
      {
        label: "text",
        field: 'text',
        sort: 'disabled',
        width: 200
      },
      {
        label: "action",
        field: 'action',
        sort: 'disabled',
        width: 80
      },
    ]

    const data = {
      columns: columns,
      rows: rows
    }
    
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header>
              <Header.Content>{"Char Replacement"}</Header.Content>
            </Header>
              <Segment>
                <div>
                  <Header as="h5" image>
                    <Header.Content>{"Project Id"}</Header.Content>
                  </Header>
                  <Input 
                      defaultValue={1} ref={ref => this.projIdInput = ref} 
                      onChange={e => this.handleProjChange(e)}
                  />
                </div>
                <div>
                  <Header as="h5" image>
                    <Header.Content>{"Label"}</Header.Content>
                  </Header>
                  <Dropdown
                      placeholder='Select a label'
                      fluid
                      selection
                      onChange={this.handleDropDownSelect}
                      options={this.state.labelOptions}
                      // value={this.state.labelSelected}
                  />
                </div>
                <div>
                <Header as="h5" image>
                  <Header.Content>{"Before"}</Header.Content>
                </Header>
                <Input ref={ref => this.beforeString = ref} />
                </div>
                <div>
                <Header as="h5" image>
                  <Header.Content>{"After"}</Header.Content>
                </Header>
                <Input ref={ref => this.afterString = ref} />
                </div>
                <div>
                <Button icon="search" type="submit"
                  onClick={() => this.doSearch()}>
                  {"Search Annotations"}
                </Button>
                <Button icon="clone" type="submit"
                  onClick={() => this.doReplacement()}>
                  {"Replace All Annotations"}
                </Button>
              </div>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header>
              <Header.Content>{"Search Result"}</Header.Content>
            </Header>
            <Header as="h5" image>
              <Header.Content>{`Database Row(s): ${dbRowCount}, Matched Row(s): ${matchedRowCount}, Matched Label(s): ${matchedLabelCount}`}</Header.Content>
            </Header>
            <Segment>
              <MDBDataTable
                theadColor="common-search-table-header"
                btn
                striped
                bordered
                hover
                entries={20}
                data={data}
                disableRetreatAfterSorting={true}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}


