import React, { Component } from 'react';
import { Polyline, CircleMarker, Polygon } from 'react-leaflet';
import Hotkeys from 'react-hot-keys';
import update from 'immutability-helper';
import { confirmAlert } from 'react-confirm-alert';
import 'leaflet-path-drag';
import 'leaflet/dist/leaflet.css';
import Provider from './Provider';
import api from '../../../api';

import { Dimmer, Loader } from 'semantic-ui-react';
import { BBoxFigure, PolygonFigure } from './Figure';
import {
  convertPoint,
  lighten,
  colorMapping,
} from '../../../label/label-app/utils';
import { withBounds } from './CalcBoundsHOC';
import intl from 'react-intl-universal';
import { chunk } from 'lodash';
import MapHeader from './MapHeader';
import MapContent from './MapContent';
import shallowEqualObjects from 'shallow-equal/objects';
import Slide from '@material-ui/core/Slide';

const rdom = require('react-dom');

class Canvas extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      zoom: -1,
      selectedFigureId: null,
      cursorPos: { lat: 0, lng: 0 },
      loading: false,
      showToolBar: false,
      clickFlag: false,
      scrollWheelZoomFlag: false,
      changeTitleLoading: false,
      clickFigureIds: [],
      clickedPoints: [],
      tenantPreference: { scroll_wheel_status: null },
      rereadSuccess: false,
      changeOwnerSuccess: false,
      screenUpdatedSuccess: false,
      imageData: null,
      height: null,
      width: null,
      loaderImageType: null
    };
    this.prevSelectedFigure = null;
    this.skipNextClickEvent = false;

    this.mapRef = React.createRef();
    this.headerRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.rotateDialog = this.rotateDialog.bind(this);
    this.predictFsScrumMapDialog = this.predictFsScrumMapDialog.bind(this);
    this.predictDialog = this.predictDialog.bind(this);
    this.delAllBBoxDialog = this.delAllBBoxDialog.bind(this);
    this.handlePredict = this.handlePredict.bind(this);
    this.handleViewPortChange = this.handleViewPortChange.bind(this);
    this.clickHighlight = this.clickHighlight.bind(this);
    this.showRereadSuccess = this.showRereadSuccess.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { onSelectionChange } = this.props;
    const { selectedFigureId } = this.state;

    if (this.prevSelectedFigureId !== selectedFigureId && onSelectionChange) {
      this.clickHighlight({}, false);
      this.prevSelectedFigureId = selectedFigureId;
      if (selectedFigureId) onSelectionChange(selectedFigureId);
    }
    if ((!shallowEqualObjects(this.props.selectedCellIds, prevProps.selectedCellIds) && !this.props.textMappingRef.current.props.currentBoxId)) {
      this.setState({
        clickFigureIds: [],
        selectedFigureId: null,
      });
    }
    if(this.props.selectedCell && this.props.selectedCell.id&&!shallowEqualObjects(this.props.selectedCell, prevProps.selectedCell)){
      this.setState({
        clickFigureIds: [],
        selectedFigureId: null,
      });
    }
    
    if (prevProps.imageData !== this.props.imageData) {
      this.setState({ imageData: this.props.imageData })
      this.setState({ width: this.props.width })
      this.setState({ height: this.props.height })
      this.setState({ loaderImageType: this.props.loaderImageType })
    }
  }

  async componentDidMount() {
    await this.handleGetTenantPreference({
      category: 'configure',
      subCategory: 'review_page',
      name: 'scrollWheel',
      subName: 'scroll_wheel_status',
    });

    // set scrollWheelStatus at tenantSetting: 
    // *while do not have the set of scrollWheelStatus the scrollWheelZoomFlag is true*
    // 0: win system scroll mouse the canvas is Moving up and down.
    // 1: mac system scroll mouse the canvas is scrooling zoom.
    const scrollWheelStatus = this.state.tenantPreference['scroll_wheel_status'];
    const per = localStorage.getItem('permission') || '';
    let perFlag = true;
    per.split(',').forEach(item => {
      if (item === '2') {
        perFlag = false;
      }
    });
    this.setState({ clickFlag: perFlag });

    if(scrollWheelStatus === '1' || !scrollWheelStatus) {
      this.setState({ scrollWheelZoomFlag: true });
    } else {
      document && document.getElementById('pdfImage') && document.getElementById('pdfImage').addEventListener('wheel', this.handleScroll, true);
      this.setState({ scrollWheelZoomFlag: false });
    }

    // var agent = navigator.userAgent.toLowerCase();
    // var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    // if (
    //   agent.indexOf('win32') >= 0 ||
    //   agent.indexOf('wow32') >= 0 ||
    //   agent.indexOf('win64') >= 0 ||
    //   agent.indexOf('wow64') >= 0
    // ) {
    //   this.setState({ scrollWheelZoomFlag: false });
    //   document
    //     .getElementById('pdfImage')
    //     .addEventListener('wheel', this.handleScroll, true);
    // }
    // if (isMac) {
    //   this.setState({ scrollWheelZoomFlag: true });
    // }
  }

  handleGetTenantPreference = async param => {
    const res = await api.request({
      url: `/dppapi/predict/tenant/preference/query`,
      method: 'POST',
      data: param,
    });
    if (res.data.success) {
      this.setState({
        tenantPreference: {
          ...this.state.tenantPreference,
          [`${param.subName}`]: res.data.value,
        },
      });
    }
  };

  getSelectedFigure() {
    const { selectedFigureId } = this.state;
    const { figures } = this.props;
    return figures.find(f => f.id === selectedFigureId);
  }

  handleScroll = e => {
    e.preventDefault();
    const ele = rdom.findDOMNode(this);
    const map = this.mapRef.current.leafletElement;
    if (e.deltaY <= 0) {
      /* scrolling up */
      if (ele.scrollTop <= 0) {
        map.panBy([0, -80]);
      }
    } else {
      /* scrolling down */
      // if (ele.scrollTop + ele.clientHeight <= ele.scrollHeight) {
      map.panBy([0, 80]);
      // }
    }
  };

  handleSetOppositeValue = (id, det_text) => {
    const { figures } = this.props;
    const figure = figures.find(f => f.id === id);
    if (!figure) {
      return;
    }
    this.handleChange('detTextChange', {
      figure,
      det_text: det_text,
    });
  };

  async handleChange(
    eventType,
    {
      point,
      pos,
      figure,
      points,
      det_text,
      linkTo,
      det_class,
      checkFlag,
      accuracy,
    }
  ) {
    const { onChange, unfinishedFigure } = this.props;
    const drawing = !!unfinishedFigure;

    switch (eventType) {
      case 'add':
        if (drawing) {
          let newState = unfinishedFigure.points;
          newState = update(newState, { $push: [point] });

          onChange(
            'unfinished',
            update(unfinishedFigure, {
              points: {
                $set: newState,
              },
            })
          );
        } else {
          onChange(
            'replace',
            update(figure, { points: { $splice: [[pos, 0, point]] } })
          );
        }
        break;

      case 'end':
        const f = unfinishedFigure;
        onChange('new', f);
        break;

      case 'move':
        onChange(
          'replace',
          update(figure, { points: { $splice: [[pos, 1, point]] } })
        );
        break;

      case 'replace':
        onChange('replace', update(figure, { points: { $set: points } }));
        break;

      case 'remove':
        onChange(
          'replace',
          update(figure, { points: { $splice: [[pos, 1]] } })
        );
        break;
      case 'detTextChange':
        const change_result = await onChange(
          'replace',
          update(figure, {
            det_text: { $set: det_text },
            accuracy: { $set: accuracy },
          })
        );
        if (change_result !== undefined && !change_result) {
          return change_result;
        }
        break;
      case 'detTextClassChange':
        onChange('replace', update(figure, { det_class: { $set: det_class } }));
        break;
      case 'linkIdChange':
        onChange('replace', update(figure, { linkTo: { $set: linkTo } }));
        break;
      case 'checkFlagChange':
        onChange('replace', update(figure, { checkFlag: { $set: checkFlag } }));
        break;

      default:
        throw new Error('unknown event type ' + eventType);
    }
  }

  handleClick(e) {
    const { unfinishedFigure } = this.props;
    const drawing = !!unfinishedFigure;

    if (this.skipNextClickEvent) {
      // a hack, for whatever reason it is really hard to stop event propagation in leaflet
      this.skipNextClickEvent = false;
      return;
    }

    if (drawing) {
      this.handleChange('add', { point: convertPoint(e.latlng) });
      return;
    }

    if (!drawing) {
      this.setState({ selectedFigureId: null });
      return;
    }
  }

  renderFigure(figure, options) {
    const { selectFlag, handleChangeSelectFlag } = this.props;
    const Comp = figure.type === 'bbox' ? BBoxFigure : PolygonFigure;
    return (
      <Comp
        mapRef = {this.mapRef}
        key={figure.id}
        figure={figure}
        options={options}
        skipNextClick={() => (this.skipNextClickEvent = true)}
        handleOcr={this.props.handleOcr}
        bounds={this.props.bounds}
        selectedBox={this.props.selectedBox}
        linking={this.props.linking}
        manualInput={this.props.manualInput}
        textMappingRef={this.props.textMappingRef}
        muiPopupRef={this.props.muiPopupRef}
        onSelectBox={this.props.onSelectBox}
        popupRef={this.props.popupRef}
        onSelectedBBoxChange={this.props.onSelectedBBoxChange}
        selectedBBox={this.props.selectedBBox}
        isEditing={this.props.isEditing}
        ocrFlg={this.props.ocrFlg}
        scrumRecommendationFlg={this.props.scrumRecommendationFlg}
        labelTypes={this.props.labelTypes}
        onLoad={this.props.onLoad}
        projectId={this.props.projectId}
        loaderImageType={this.props.loaderImageType}
        selectFlag={selectFlag}
        handleChangeSelectFlag={handleChangeSelectFlag}
        isNotOwner={this.props.isNotOwner}
        onShowExclusionControl={this.props.onShowExclusionControl}
      />
    );
  }

  rotateDialog = () => {
    const { figures, thumbnailRef } = this.props;
    const usuallyPagetype = ["貸借対照表", "損益計算書", "製造原価明細表", "販売費及び一般管理費","株主資本等変動計算書"];
    const pageTypeFlag = usuallyPagetype.find(v => v===thumbnailRef.current.state.pageClassification.pageType)
    var hasBox = false;
    if(figures && figures.length > 0){
      hasBox = true;
    }
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui-rotation-body'>
            <label className="custom-ui-rotation-title">ページを右に90度回転</label>
            <div className="custom-ui-rotation-content">
              {hasBox? 
              <label className="custom-ui-rotation-content-text">実行するとこのページの読取情報がすべて破棄されます。<br/>よろしいですか？</label> :
              <label className="custom-ui-rotation-content-text">実行してよろしいですか？</label>
              }
            </div>
            <div className='custom-ui-confirm-alert-button-group' style={{width:'100%'}}>
              {pageTypeFlag&&<button
                className='custom-ui-rotation-button primary-btn rotationAlert-button-center'
                onClick={async() => {
                  onClose();
                  await this.props.handleUnuseImage()
                  await this.props.rotate()
                  await this.props.toggleRePredict(true);
                  }}>
                実行して再読取に進む
              </button>}
              <button
                className='custom-ui-rotation-button primary-btn rotationAlert-button-right'
                onClick={async() => {
                  onClose();
                  await this.props.handleUnuseImage()
                  await this.props.rotate()
                  }}>
                実行
              </button>
              <button
                className='custom-ui-rotation-button predict-admin-botton-text rotationAlert-button-left'
                onClick={onClose}>
                {intl.get('_predict.detail.pdf.Canvas.Cancel')}
              </button>
            </div>
          </div>
        );
      }
    });
  }

  predictFsScrumMapDialog = () => {
    confirmAlert({
      title: intl.get('_predict.detail.pdf.Canvas.Results analysis'),
      message: intl.get(
        '_predict.detail.pdf.Canvas.Do you want re-analyze the results?'
      ),
      buttons: [
        {
          label: intl.get('_predict.detail.pdf.Canvas.OK'),
          onClick: () => this.props.onPredictFsScrumMap(),
        },
        {
          label: intl.get('_predict.detail.pdf.Canvas.Cancel'),
        },
      ],
      zIndex: 500,
    });
  };

  predictDialog = () => {
    const { loading } = this.state;
    if (loading) {
      return;
    }
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui-confirm-alert-body'>
            <h1>{intl.get('_predict.detail.pdf.Canvas.Re-do AI scan')}</h1>
            <p>{
              intl.get(
                '_predict.detail.pdf.Canvas.Would you like to re-analyze this document with AI?'
              )}</p>
            <div className='custom-ui-confirm-alert-button-group'>
              <button
                className='primary-btn confirmAlert-button-right'
                onClick={() => {
                  this.handlePredict()
                  onClose();}}>
                {intl.get('_predict.detail.pdf.Canvas.OK')}
              </button>
              <button
                className='predict-admin-botton-text confirmAlert-button-left'
                onClick={onClose}>
                {intl.get('_predict.detail.pdf.Canvas.Cancel')}
              </button>
            </div>
          </div>
        );
      }
    });
  };

  delAllBBoxDialog = () => {
    confirmAlert({
      title: intl.get('_predict.detail.pdf.Canvas.Delete'),
      message: intl.get(
        '_predict.detail.pdf.Canvas.Do you want to remove all bounding boxes?'
      ),
      buttons: [
        {
          label: intl.get('_predict.detail.pdf.Canvas.OK'),
          onClick: () => this.props.onAllBBoxDelete(),
        },
        {
          label: intl.get('_predict.detail.pdf.Canvas.Cancel'),
        },
      ],
      zIndex: 500,
    });
  };

  addPdfToImageDialog = () => {
    confirmAlert({
      title: intl.get('_predict.detail.pdf.Canvas.Movelabeling'),
      message: intl.get(
        '_predict.detail.pdf.Canvas.Do you want to move it to labeling data?'
      ),
      buttons: [
        {
          label: intl.get('_predict.detail.pdf.Canvas.OK'),
          onClick: () => this.props.handleAddPdfToImage(),
        },
        {
          label: intl.get('_predict.detail.pdf.Canvas.Cancel'),
        },
      ],
      zIndex: 500,
    });
  };

  async handlePredict() {
    this.setState({ loading: true });
    await this.props.onPredict();
    this.setState({ loading: false });
  }

  handleViewPortChange(e) {
    sessionStorage.setItem('zoom'+this.props.pdfId, JSON.stringify(e));
  }

  handleCheck(key, errorList) {
    let err = false;
    let item = null;

    errorList.forEach(errItem => {
      const child = errItem.children.includes(key);
      if (errItem.name === key || child) {
        err = true;
        item = errItem;
      }
    });
    return { err, errItem: item };
  }

  infoItemFigures(id, filteredRows) {
    let result = null;
    filteredRows.forEach(f => {
      const { item, value, subTitle } = f;
      const isItem = item && item.id === id;
      const isValue = value && value.id === id;
      const isSubTitle = subTitle && subTitle.id === id;
      if (isItem || isValue || isSubTitle) {
        result = f;
      }
    });
    return result;
  }

  clickHighlight(fig, renderPoints, reset) {
    this.setState({
      clickFigureIds: [],
      clickedPoints: renderPoints,
    });
    // click the pdf fig have two cases: 1.fig includes value and item 2.it is only have value
    if (fig && fig.value && fig.item && !reset) {
      this.setState({
        clickFigureIds: [fig.value.id, fig.item.id],
      });
    } else if (fig && fig.value && !reset) {
      this.setState({
        clickFigureIds: [fig.value.id, null],
      });
    }
  }
  handleSetMapState = (val) => {
    if (val && val.hasOwnProperty('zoom')) {
      setTimeout(() => {
        this.setState(val);
      }, 50)
    }
  }

  calcTargetBboxColumn = () => {
    const { selectTargetBbox, height } = this.props;
    if(!selectTargetBbox){
      return null;
    }
    var startX = selectTargetBbox.points[0].lng;
    var endX = selectTargetBbox.points[1].lng
    const extendSize = 10;
    return [
      {lat: 0, lng: startX-extendSize},
      {lat: 0, lng: endX+extendSize},
      {lat: height, lng: endX+extendSize},
      {lat: height, lng: startX-extendSize},
    ];
  }

  showRereadSuccess= () => {
    this.setState({rereadSuccess : true})
    setTimeout(() =>{
      this.setState({rereadSuccess : false})
    },2500)
  }

  showDialogSuccess = (type) => {
    this.setState({ [type]: true })
    setTimeout(() => {
      this.setState({ [type]: false })
    }, 4000)
  }

  render() {
    const {
      url,
      bounds,
      height,
      width,
      figures,
      unfinishedFigure,
      style,
      selectedCellIds,
      onSelect,
      // onShortKeyPanelVisible,
      isEditing,
      textMappingRef,
      thumbnailRef,
      imageData,
      pdfId,
      handelShowPredictLoading,
      effectiveHotkeyPage,
      isNotOwner,
      onShowExclusionControl,
    } = this.props;
    const {
      zoom,
      selectedFigureId,
      cursorPos,
      loading,
      clickFlag,
      scrollWheelZoomFlag,
      changeTitleLoading,
      clickFigureIds,
      clickedPoints,
      rereadSuccess,
      changeOwnerSuccess,
      screenUpdatedSuccess,
    } = this.state;
    const { zoom: zoomDefault, center: centerDefault } = JSON.parse(
      sessionStorage.getItem('zoom'+pdfId) || '{}'
    );
    const immutable_page_type = ['貸借対照表', '損益計算書', '株主資本等変動計算書'];
    let detailData = {};
    let currentPageType = null;
    let errorList = [];
    let filteredRows = [];
    if (textMappingRef && textMappingRef.current) {
      detailData = textMappingRef.current.state.detailData;
      currentPageType = textMappingRef.current.state.currentPageType;
      errorList = textMappingRef.current.state.errorList;
    }
    if (detailData && currentPageType) {
      const notDetail = !immutable_page_type.includes(currentPageType);
      const isNotDetail = !detailData || notDetail;
      if(!isNotDetail){
        Object.values(detailData).forEach(valList => {
          if(valList.length){
            filteredRows = filteredRows.concat(valList);
          }
        })
      }
    }

    const drawing = !!unfinishedFigure;

    const calcDistance = (p1, p2) => {
      const map = this.mapRef.current.leafletElement;
      return map.latLngToLayerPoint(p1).distanceTo(map.latLngToLayerPoint(p2));
    };

    const unfinishedDrawingDOM = drawing
      ? this.renderFigure(unfinishedFigure, {
        finished: false,
        editing: false,
        interactive: false,
        color: colorMapping[unfinishedFigure.color],
        onChange: this.handleChange,
        calcDistance,
        newPoint: cursorPos,
        inSelectedCellIds: false,
      })
      : null;

    const getColor = (f, color) =>
      f.tracingOptions && f.tracingOptions.enabled
        ? lighten(colorMapping[color], 80)
        : colorMapping[color];
    let notMatchedList = [];
    filteredRows.forEach(filteredRow => {
      if(selectedCellIds.indexOf(filteredRow.value.id) !== -1 && !filteredRow.scrumItem){
        notMatchedList.push(filteredRow.value.id);
        notMatchedList.push(filteredRow.item.id);
        notMatchedList.push(filteredRow.subTitle.id);
      }
    }); 
    const figuresDOM = figures.map((f, i) => {
      const info = this.infoItemFigures(f.id, filteredRows, errorList);
      let check = null;
      if (info) {
        check = this.handleCheck(info.scrumItem, errorList);
      }
      let color = null;
      if (check) {
        color = check.err ? 'roman' : 'pastelGreen';
      }
      return this.renderFigure(f, {
        editing: selectedFigureId === f.id && !drawing,
        finished: true,
        interactive: !drawing,
        sketch: f.tracingOptions && f.tracingOptions.enabled,
        color: getColor(f, f.color),
        borderColor: color ? colorMapping[color] : null,
        vertexColor: colorMapping[f.color],
        onSelect: () => this.setState({ selectedFigureId: f.id }),
        onChange: this.handleChange,
        calcDistance,
        inSelectedCellIds: selectedCellIds.indexOf(f.id) !== -1,
        infoClick: info,
        figures, clickFigureIds,
        clickedPoints,
        clickHighlight: this.clickHighlight,
        imageData: imageData,
        imageWidth: width,
        imageHeight: height,
        selectedCellIds,
        notMatchedList
      });
    });

    const hotkeysDOM = effectiveHotkeyPage === "canvas" ?  (
      <Hotkeys
        keyName="backspace,c,f,-,=,left,right,up,down,1,esc"
        onKeyDown={key => {
          const tagName = document.activeElement
            ? document.activeElement.tagName.toLowerCase()
            : null;
          if (['input', 'textarea'].includes(tagName)) {
            return false;
          }
          // if (key === '1') {
          //   // new bbox, default type is word
          //   onSelect(PREDICT_DEFAULT_LABEL_NAME);
          // }
          if (key === 'esc') {
            onSelect(null);
          }
          // if (drawing) {
          //   if (key === 'f') {
          //     const { type, points } = unfinishedFigure;
          //     if (type === 'polygon' && points.length >= 3) {
          //       this.handleChange('end', {});
          //     }
          //   }
          // } else {
          //   if (key === 'c') {
          //     if (selectedFigureId && this.getSelectedFigure()) {
          //       onReassignment(this.getSelectedFigure().type);
          //     }
          //   } else if (key === 'backspace') {
          //     if (!isEditing && selectedFigureId && this.getSelectedFigure()) {
          //       onChange('delete', this.getSelectedFigure());
          //     }
          //   }
          // }

          const map = this.mapRef.current.leafletElement;
          if (key === 'left') {
            map.panBy([-80, 0]);
          }
          if (key === 'right') {
            map.panBy([80, 0]);
          }
          if (key === 'up') {
            map.panBy([0, -80]);
          }
          if (key === 'down') {
            map.panBy([0, 80]);
          }
          if (key === '=') {
            map.setZoom(map.getZoom() + 1);
          }
          if (key === '-') {
            map.setZoom(map.getZoom() - 1);
          }
        }}
      /> 
    ) : null ;

    let renderedTrace = null;
    const selectedFigure = this.getSelectedFigure();
    if (selectedFigure && selectedFigure.type === 'polygon') {
      const trace = selectedFigure.tracingOptions
        ? selectedFigure.tracingOptions.trace || []
        : [];
      const figure = {
        id: 'trace',
        type: 'line',
        points: trace,
      };
      const traceOptions = {
        editing: false,
        finished: true,
        color: colorMapping[selectedFigure.color],
      };
      renderedTrace = <PolygonFigure figure={figure} options={traceOptions} />;
    }

    let renderedPolyline = null;
    if (selectedCellIds.length >= 2) {
      let indexes = [],
        i;
      for (i = 0; i < selectedCellIds.length; i++) {
        // eslint-disable-next-line 
        const idx = figures.findIndex(fig => fig.id === selectedCellIds[i]);
        if (idx !== -1) {
          indexes.push(figures[idx]);
        } else if (selectedCellIds[i] === '-1') {
          indexes.push(null);
        }
      }
      let idxs = [];
      let idxSubtitle = [];
      indexes.forEach(i => {
        if (i && i.det_class === 'subtitle') {
          idxSubtitle.push(i);
        } else {
          idxs.push(i);
        }
      });
      const idxChunk2 = chunk(idxs, 2);
      const idxSubtitleChunk1 = chunk(idxSubtitle, 1);
      const listLine = [...idxChunk2, ...idxSubtitleChunk1];
      // const listLine = chunk(indexes, 2);
      // eslint-disable-next-line 
      renderedPolyline = listLine.map((list, idx) => {
        if (list.length >= 2 && list[0] && list[1]) {
          const multiPolyline = list[0].det_class === 'value' ? [
            [
              (list[0].points[0].lat + list[0].points[1].lat) / 2,
              list[0].points[0].lng - 10,
            ],
            [
              (list[1].points[0].lat + list[1].points[1].lat) / 2,
              list[1].points[1].lng + 10,
            ],
          ] : [
            [
              (list[0].points[0].lat + list[0].points[1].lat) / 2,
              list[0].points[0].lng + (list[0].points[1].lng - list[0].points[0].lng) + 10,
            ],
            [
              (list[1].points[0].lat + list[1].points[1].lat) / 2,
              list[1].points[1].lng - (list[1].points[1].lng - list[1].points[0].lng) - 10,
            ],
          ];
          return (
            <React.Fragment key={idx}>
              <CircleMarker
                center={multiPolyline[0]}
                color={'#3584E6'}
                opacity={'0.7'}
                radius={2}
              />
              <Polyline
                positions={multiPolyline}
                weight={1}
                color={'#3584E6'}
                opacity={'0.7'}
              />
              <CircleMarker
                center={multiPolyline[1]}
                color={'#3584E6'}
                opacity={'0.7'}
                radius={2}
              />
            </React.Fragment>
          );
        }
      });
    }

    let renderedLoader = null;
    if (loading) {
      renderedLoader = <Loader active size="large" />;
    }

    const renderPoints = this.calcTargetBboxColumn();
    const targetColumnPolygon = renderPoints && <Polygon
      className="repredict-targetBbox-blingbling"
      positions={renderPoints}
      color={'#fff'}
      opacity={'0.6'}
      fill={true}
      fillColor={'#056de7'}
    ></Polygon>

    return (
      <Provider>
        <div
          style={{
            cursor: drawing ? 'crosshair' : 'grab',
            height: 'calc(100% - 54px)',
            ...style,
          }}
          id="pdfImage"
        >
          <div
            style={{
              height: '54px',
              width: '100%',
              background: '#fff',
              borderBottom: '1px solid #D4D4D9',
              position: 'absolute',
              minWidth: '450px',
            }}
          >
            <MapHeader
              ref={this.headerRef}
              mapRef={this.mapRef}
              thumbnailRef={thumbnailRef}
              textMappingRef={textMappingRef}
              isEditing={isEditing}
              loading={loading}
              clickFlag={clickFlag}
              height={height}
              width={width}
              zoomDefault={zoomDefault}
              zoom={zoom}
              pdfId={pdfId}
              changeTitleLoading={changeTitleLoading}
              handleChangeTitleLoading={load => {
                this.setState({ changeTitleLoading: load });
              }}
              predictDialog={this.predictDialog}
              rotateDialog={this.rotateDialog}
              addPdfToImageDialog={this.addPdfToImageDialog}
              isRpaOpen={this.props.isRpaOpen}
              toggleRePredict={this.props.toggleRePredict}
              handelShowPredictLoading={handelShowPredictLoading}
              onAllBBoxDelete={this.props.onAllBBoxDelete}
              handleUnuseImage={this.props.handleUnuseImage}
              projectId={this.props.projectId}
              isNotOwner={isNotOwner}
              onShowExclusionControl={onShowExclusionControl}
            />
          </div>
          {changeTitleLoading && (
            <Dimmer active inverted>
              <Loader inverted size="large" />
            </Dimmer>
          )}
          <Slide direction="up" in={rereadSuccess}>
            <div
              className="canvas-success-slide"
              style={{
                display: rereadSuccess ? 'block' : 'none',
              }}
            >
              再読取が完了しました
            </div>
          </Slide>
          <Slide direction="up" in={changeOwnerSuccess}>
            <div
              className="canvas-success-slide"
              style={{
                display: changeOwnerSuccess ? 'block' : 'none',
              }}
            >
              {intl.get('_predict.detail.dialog.The person in charge has been set')}
            </div>
          </Slide>
          <Slide direction="up" in={screenUpdatedSuccess}>
            <div
              className="canvas-success-slide"
              style={{
                display: screenUpdatedSuccess ? 'block' : 'none',
              }}
            >
              {intl.get('_predict.detail.dialog.The screen has been updated')}
            </div>
          </Slide>
          <MapContent
            mapRef={this.mapRef}
            zoomDefault={zoomDefault}
            centerDefault={centerDefault}
            height={height}
            width={width}
            handleClick={this.handleClick}
            setMapState={this.handleSetMapState}
            handleViewPortChange={this.handleViewPortChange}
            scrollWheelZoomFlag={scrollWheelZoomFlag}
            leftWidth={this.props.leftWidth}
            zoom={zoom}
            unfinishedDrawingDOM={unfinishedDrawingDOM}
            renderedTrace={renderedTrace}
            renderedPolyline={renderedPolyline}
            figuresDOM={figuresDOM}
            hotkeysDOM={hotkeysDOM}
            renderedLoader={renderedLoader}
            url={url}
            bounds={bounds}
            targetColumnPolygon={targetColumnPolygon}
            currentPage={this.props.currentPage}
          />
          {this.props.children}
        </div>
      </Provider>
    );
  }
}

export default withBounds(Canvas);
