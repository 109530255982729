import React from 'react';
import { createPortal } from 'react-dom';
import intl from 'react-intl-universal';

import { MDBModal, MDBModalBody } from 'mdbreact';
import { Button, Table } from 'semantic-ui-react';

import { ErrorIcon } from '../../../assets/icon';
import { EventName, EventType, PageName } from '../../../common/Analytics/analyticsMetrics';
import { AnalyticsServiceContext } from '../../../common/Analytics/AnalyticsServiceProvider';
import { unixMSToDatetime } from '../../../predict/TimeUtil';

import './ExclusionControl.css';

export default class ExclusionControl extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            frameShow: true,
        }
    }

    static contextType = AnalyticsServiceContext;

    dragstart = (e) => {
        e.dataTransfer.effectAllowed = "move"
        e.dataTransfer.dropEffect = "move"
        document.getElementsByClassName("modal-content")[0].classList.add('repredict-dialog-drag-hiden')
        this.setState({
            pageX: e.pageX,
            pageY: e.pageY
        })
    }

    dragover = (e) => {
        e.preventDefault();
        e.dataTransfer.effectAllowed = "move"
        e.dataTransfer.dropEffect = "move"
    }

    dragenter = (e) => {
        e.preventDefault();
        e.dataTransfer.effectAllowed = "move"
        e.dataTransfer.dropEffect = "move"
    }

    dragend = (e) => {
        document.getElementsByClassName("modal-content")[0].classList.remove('repredict-dialog-drag-hiden')
        e.preventDefault();
    }

    drop = (e) => {
        e.preventDefault();

        var movex = e.pageX - this.state.pageX;
        var movey = e.pageY - this.state.pageY;
        if (this.state.moveX) {
            movex = movex + this.state.moveX
        }
        if (this.state.moveY) {
            movey = movey + this.state.moveY
        }
        document.getElementsByClassName("modal-content")[0].style.position = 'relative'
        document.getElementsByClassName("modal-content")[0].style.left = movex + 'px'
        document.getElementsByClassName("modal-content")[0].style.top = movey + 'px'
        this.setState({
            moveX: movex,
            moveY: movey
        })
    }

    reloadScreen = async () => {
        const {
            onShowFileUpdateError,
            onShowPredictLoading,
            onChangeOwnerStatus,
            onChangeLastEditorInfo,
            onEdit,
            isFileUpdateErrorShow,
        } = this.props;
        onShowFileUpdateError([false, '', '', '']);
        onShowPredictLoading("再読み込み中です", "しばらくお待ちください");
        onEdit(false);
        await this.props.onImgReload();
        onChangeOwnerStatus(false);
        var sucessInterval = window.setInterval(() => {
            if (this.props.canvasRef && this.props.canvasRef.current) {
                this.props.canvasRef.current.showDialogSuccess("screenUpdatedSuccess");
                clearInterval(sucessInterval);
            }
        }, 200)
        onShowPredictLoading();
        onChangeLastEditorInfo([isFileUpdateErrorShow[1], isFileUpdateErrorShow[2], isFileUpdateErrorShow[3]]);
    }

    setSelfAsAssignee = async () => {
        const {
            pdfFileId,
            projectId,
        } = this.props;

        this.setState({ confirmFlg: true });
        this.context.sendMetrics({
            page_name: PageName.PredictDetailPage,
            event_type: EventType.Click,
            event_name: EventName.SetSelfAsAssignee,
            custom_parameter: {
                pdf_id: pdfFileId,
                project_id: projectId,
            },
        })
    }

    applySettings = async () => {
        const {
            pdfFileId,
            projectId,
        } = this.props;
        this.props.onChangeOwner(false);
        this.props.onShowExclusionControl(false);
        // if (this.props.textMappingRef && this.props.textMappingRef.current) {
        //     this.props.textMappingRef.current.handleSave();
        // }
        this.context.sendMetrics({
            page_name: PageName.PredictDetailPage,
            event_type: EventType.Click,
            event_name: EventName.ApplySettings,
            custom_parameter: {
                pdf_id: pdfFileId,
                project_id: projectId,
            },
        })
    }

    renderFrameRow = () => {
        const { pdfInfo } = this.props;
        return (
            <Table.Row>
                <Table.Cell>
                    <div>
                        <div className='exclusion-control-content-top'>
                            <div className='exclusion-control-content-left'><span>現在の担当者</span></div>
                            <div className='exclusion-control-content-right'>{pdfInfo.userInChargeName}</div>
                        </div>
                        <div className='exclusion-control-content-bottom'>
                            <div className='exclusion-control-content-left'><span>最終保存日時</span></div>
                            <div className='exclusion-control-content-right'>{unixMSToDatetime(
                                pdfInfo.lastEdited,
                                intl.get('_predict.detail.dialog.TimeLocal')
                            )}</div>
                        </div>
                    </div>
                </Table.Cell>
            </Table.Row>
        );
    }

    renderFrameRowConfirm = () => {
        const { user } = this.props;
        return (
            <Table.Row>
                <Table.Cell>
                    <div>
                        <div className='exclusion-control-confirm-content-changed'>
                            <div className='exclusion-control-confirm-content-left'><span>担当者（変更後）</span></div>
                            <div className='exclusion-control-confirm-content-right'>{user.name}</div>
                        </div>
                    </div>
                </Table.Cell>
            </Table.Row>
        );
    }

    renderFrameRowUpdateError = () => {
        const { isFileUpdateErrorShow } = this.props;
        return (
            <Table.Row>
                <Table.Cell>
                    <div>
                        <div className='exclusion-control-content-top'>
                            <div className='exclusion-control-content-left'><span>最終保存者</span></div>
                            <div className='exclusion-control-content-right'>{isFileUpdateErrorShow[2]}</div>
                        </div>
                        <div className='exclusion-control-content-bottom'>
                            <div className='exclusion-control-content-left'><span>最終保存日時</span></div>
                            <div className='exclusion-control-content-right'>{unixMSToDatetime(
                                isFileUpdateErrorShow[3],
                                intl.get('_predict.detail.dialog.TimeLocal')
                            )}</div></div>
                    </div>
                </Table.Cell>
            </Table.Row>
        );
    }

    render() {
        const {
            frameShow,
            confirmFlg,
        } = this.state;
        const {
            onShowFileUpdateError,
            onShowExclusionControl,
            isFileUpdateErrorShow,
        } = this.props;
        return createPortal(
            <div style={{ cursor: 'default' }} className={"model-repredict-contain-div"} onDragOver={this.dragover} onDrop={this.drop} onDragEnd={this.dragend} >
                <MDBModal
                    isOpen={true}
                    centered={true}
                    size={'fluid'}
                    fade={false}
                    overflowScroll={false}
                    className={"model-repredict-set-center model-repredict-set-center-middle"}
                    contentClassName={"repredict-reread-content"}
                >
                    {
                        isFileUpdateErrorShow[0] ?
                            <MDBModalBody className="exclusion-control-body" onDragStart={this.dragstart} draggable={false}>
                                {/* <div className="reread-dialog-top-bar"></div> */}
                                <div className="exclusion-control-set-title">
                                    <ErrorIcon color='#F26868' size='32px' />
                                    <label className="mui_popup_title">{'ファイル更新エラー'}</label>
                                </div>
                                <div className="exclusion-control-confirm-body">
                                    <div><span className='exclusion-control-message'>編集中に下記ユーザーによってファイルが更新されたため、処理を実行できません。画面を再読み込みし、最終保存状態を表示してください。</span></div>
                                    {frameShow && <div className="exclusion-control-confirm-table">
                                        <div className="confirm-frame-table-div">
                                            <Table className="exclusion-control-confirm-table-margin" unstackable>
                                                <Table.Body style={{ height: '100%' }}>
                                                    {this.renderFrameRowUpdateError()}
                                                </Table.Body>
                                            </Table>
                                        </div>
                                    </div>}
                                </div>
                                <div className="text_icon_div" style={{ alignItems: 'flex-start' }}>
                                    <ErrorIcon size="24px" color="#E8BA00" className="repredict-icon-cursor" />
                                    <label className='exclusion-control-waring-label'>
                                        <span>他のユーザーがこのファイルを同時に編集していないかご確認ください</span>
                                    </label>
                                </div>
                                <div className='custom-ui-confirm-alert-button-group exclusion-control-bottom-button-div'>
                                    <Button
                                        className='primary-btn confirmAlert-button-right'
                                        style={{ marginRight: '0px' }}
                                        onClick={async () => this.reloadScreen()}
                                    >{'画面を再読み込み（編集内容を破棄）'}
                                    </Button>
                                    <button
                                        className='predict-admin-botton-text confirmAlert-button-left'
                                        onClick={() => onShowFileUpdateError([false, '', '', ''])}>
                                        {'戻る'}
                                    </button>
                                </div>
                            </MDBModalBody> :
                            !confirmFlg ?
                                <MDBModalBody className="exclusion-control-body" onDragStart={this.dragstart} draggable={false}>
                                    {/* <div className="reread-dialog-top-bar"></div> */}
                                    <div className="exclusion-control-set-title">
                                        <ErrorIcon color='#E8BA00' size='32px' />
                                        <label className="mui_popup_title">{'このファイルは編集できません'}</label>
                                    </div>
                                    <div className="exclusion-control-confirm-body">
                                        <div><span className='exclusion-control-message'>他のユーザーが担当者として設定されているため、このファイルを編集できません。</span></div>
                                        {frameShow && <div className="exclusion-control-confirm-table">
                                            <div className="confirm-frame-table-div">
                                                <Table className="exclusion-control-confirm-table-margin" unstackable>
                                                    <Table.Body style={{ height: '100%' }}>
                                                        {this.renderFrameRow()}
                                                    </Table.Body>
                                                </Table>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className='custom-ui-confirm-alert-button-group exclusion-control-bottom-button-div'>
                                        <Button
                                            className='primary-btn confirmAlert-button-right'
                                            style={{ marginRight: '0px' }}
                                            onClick={() => onShowExclusionControl(false)}
                                        >{'OK（閲覧のみ行う）'}
                                        </Button>
                                        <button
                                            className='predict-admin-botton-text confirmAlert-button-left'
                                            onClick={() => this.setSelfAsAssignee()}>
                                            {'担当者を自分に設定して編集'}
                                        </button>
                                    </div>
                                </MDBModalBody> :
                                <MDBModalBody className="exclusion-control-body" onDragStart={this.dragstart} draggable={false}>
                                    <div className="exclusion-control-set-title">
                                        <label className="mui_popup_title">{'担当者を自分に設定'}</label>
                                    </div>
                                    <div className="exclusion-control-confirm-body">
                                        <div><span className='exclusion-control-message'>以下の内容でこのファイルの担当者を設定します。</span></div>
                                        {frameShow && <div className="exclusion-control-confirm-table">
                                            <div className="confirm-frame-table-div">
                                                <Table className="exclusion-control-confirm-table-margin" unstackable>
                                                    <Table.Body style={{ height: '100%' }}>
                                                        {this.renderFrameRowConfirm()}
                                                    </Table.Body>
                                                </Table>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="exclusion-control-warning-text text_icon_div" style={{ alignItems: 'flex-start' }}>
                                        <ErrorIcon size="24px" color="#E8BA00" className="repredict-icon-cursor" />
                                        <label className='exclusion-control-waring-label'>
                                            <span>設定を適用するとデータが上書きされるため、他のユーザーの未保存の編集内容が失われます。他のユーザーが編集中でないことをご確認の上、適用してください</span>
                                        </label>
                                    </div>
                                    {this.renderFrameRow()}
                                    <div className='custom-ui-confirm-alert-button-group exclusion-control-bottom-button-div'>
                                        <Button
                                            className='primary-btn confirmAlert-button-right'
                                            style={{ marginRight: '0px' }}
                                            onClick={() => this.applySettings()}
                                        >{'設定を適用'}
                                        </Button>
                                        <button
                                            className='predict-admin-botton-text confirmAlert-button-left'
                                            onClick={() => this.setState({ confirmFlg: false })}>
                                            {'戻る'}
                                        </button>
                                    </div>
                                </MDBModalBody>
                    }
                </MDBModal>
            </div>
            ,document.body
        )
    }
}