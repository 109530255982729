import React from 'react'
import Menubar from '../../common/Menubar'

import Form from './Form'

export default class UserApp extends React.Component {
  render() {
    return (<Menubar>
      <Form></Form>
    </Menubar>)
  }
}