import React from 'react';
import { Button, Input, Segment, Dropdown } from 'semantic-ui-react';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import api from '../../api'
import intl from 'react-intl-universal';
/* 0, not dealing, 1, on dealing, 2, finished, 3, failed. */
const options = [{ key: 0, value: '1', text: 'Yes' }, { key: 1, value: '0', text: 'No' }];

export default class ImageSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadStartDate: moment().add(-90, 'd').toDate(),
      uploadEndDate: moment().toDate(),
      modifyStartDate: moment().add(-90, 'd').toDate(),
      modifyEndDate: moment().toDate(),
      users: [],
      status: '',
      imageLink: '',
      manager: '',
      reviewer: '',
      textInLabelData: '',
    };
    this.getUsers = this.getUsers.bind(this);
  }

  async componentDidMount() {
    await this.getUsers();
  }

  async getUsers() {
    const { projectId } = this.props;
    const { data: users } = await api.get(`/dppapi/labeling/projectusers/${projectId}`);

    this.setState({
      users: users.map(user => {
        return {
          key: user.id,
          value: user.name,
          text: user.name
        }
      })
    })
  }

  onChange = (type, value) => {
    this.setState({ [type]: value });
  };

  render() {
    const { getPageCount } = this.props;
    const { imageLink, uploadStartDate, uploadEndDate, modifyStartDate, modifyEndDate, users, manager, reviewer, status, textInLabelData } = this.state;
    return (
      <Segment>
        <div className="search-pdf-condition">
          <p className="search-pdf-label">{intl.get('_label.image.Images.ImageLink')}</p>
          <Input
            className="search-pdf-item"
            fluid
            placeholder={intl.get('_label.image.Images.Pleaseenterimagelink')}
            onChange={e => this.onChange('imageLink', e.target.value)}
            value={imageLink}
          />
        </div>
        <div className="search-pdf-condition">
          <p className="search-pdf-label">{intl.get('_label.image.Images.Uploadtime')}</p>
          <DatePicker
            className="search-pdf-item"
            onChange={date => this.onChange('uploadStartDate', date)}
            value={uploadStartDate}
            format="y/MM/dd"
          />{' '}
          ~
          <DatePicker
            className="search-pdf-item"
            onChange={date => this.onChange('uploadEndDate', date)}
            value={uploadEndDate}
            format="y/MM/dd"
          />
        </div>
        <div className="search-pdf-condition">
          <p className="search-pdf-label">{intl.get('_label.image.Images.Modifytime')}</p>
          <DatePicker
            className="search-pdf-item"
            onChange={date => this.onChange('modifyStartDate', date)}
            value={modifyStartDate}
            format="y/MM/dd"
          />{' '}
          ~
          <DatePicker
            className="search-pdf-item"
            onChange={date => this.onChange('modifyEndDate', date)}
            value={modifyEndDate}
            format="y/MM/dd"
          />
        </div>
        <div className="search-pdf-condition">
          <p className="search-pdf-label">{intl.get('_label.image.Images.Person')}</p>
          <Dropdown
            clearable
            options={users}
            selection
            search
            onChange={(e, data) => this.onChange('manager', data.value)}
            value={manager} />
        </div>
        <div className="search-pdf-condition">
          <p className="search-pdf-label">{intl.get('_label.image.Images.Review')}</p>
          <Dropdown
            clearable
            options={users}
            selection
            search
            onChange={(e, data) => this.onChange('reviewer', data.value)}
            value={reviewer} />
        </div>
        <div className="search-pdf-condition">
          <p className="search-pdf-label">{intl.get('_label.image.Images.LabelStatus')}</p>
          <Dropdown
            clearable
            options={options}
            selection
            search
            onChange={(e, data) => this.onChange('status', data.value)}
            value={status} />
        </div>
        <div className="search-pdf-condition">
          <p className="search-pdf-label">{intl.get('_label.image.Images.TextInLabelData')}</p>
          <Input
            className="search-pdf-item"
            fluid
            placeholder={intl.get('_label.image.Images.Pleaseentertext')}
            onChange={e => this.onChange('textInLabelData', e.target.value)}
            value={textInLabelData}
          />
        </div>

        <div className="search-pdf-condition">
          <Button type="submit"
            onClick={() => getPageCount({
              imageLink,
              uploadStartDate,
              uploadEndDate,
              modifyStartDate,
              modifyEndDate,
              manager,
              reviewer,
              status,
              textInLabelData
            })}>
            {intl.get('_label.image.Images.Search')}
          </Button>
        </div>
      </Segment>
    );
  }
}
