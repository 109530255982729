import React, { Component } from 'react';
import _ from 'lodash';
import { Select, Button, Input, Tab, Loader } from 'semantic-ui-react';
import FsTextTable from './FsTextTable';
import FsResultFunctionToolBox from './FsResultFunctionToolBox'
import api from '../../../api';
import 'semantic-ui-css/semantic.min.css';
import { genId, genNegativeId } from '../../../label/label-app/utils';
import { downloadCsv, formatCurrency } from '../../Util'
import { halfToFull, fullToHalf } from '../../CharacterUtil';
import moment from 'moment';
import { FMT } from '../../TimeUtil';
import FsKeyValueTable from './FsKeyValueTable'
import { TableMode, PageTypeList, ManualInputStatus, BSheaderList, BScurrentEndBanaceList } from './FsConstant'
import { confirmAlert } from 'react-confirm-alert'
import { metaDataToMap, checkScrumItemWithAll, convertTextToValue } from '../../ScrumItemUtil'
import { SCRUM_ITEM_SUFFIX } from '../../Constant';
import { TYPES, track2DBStorage } from '../../../common/PDFTrackUtil'
import { findIndex, find} from 'lodash'

export default class FsPdfTextMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPageType: "",
      checkedList: [],
      allCheck: false,
      searchText: "",
      category: {},
      pageType: [],
      mode: TableMode.SCRUM,
      detailData: {},
      isDownloading: false,
      scrumMappingList: {},
      errorList: [],
      pdfStatus: null,
      scrumData: {},
      fsKeyValueTableScrollTop: 0,
      metaMap: {},
      isLoading: false,
      allList: [],
      metadata:{},
      tenantPreference: { "template_SS": null },
      pdfInfo: null,
    }
    this.valueList = [];
    this.inputList = [];
  }

  async componentDidMount() {
    await this.handleGetPdfInfo()
    await this.handleGetMetaData(["separation", "fs_formula", "fs_bias", 'fs_bias_positive', 'fs_bias_negative'])
    await this.handleGetTenantPreference({ category: "configure", subCategory: "review_page", name: "template", subName: "SS" })
    await this.handleGetTenantPreference({category: "configure", subCategory: "review_page", name: "button", subName: "admin_report"})
    await this.handleGetTenantPreference({
      category: 'configure',
      subCategory: 'nodejs',
      name: 'SS',
      subName: 'title_split',
    });
    await this.handleGetTenantPreference({
      category: 'configure',
      subCategory: 'nodejs',
      name: 'SS',
      subName: 'top_left_invert',
    });
    await this.handleReload()
  }

  handleGetPdfInfo = async() =>{
    const { projectId, pdfFileId } = this.props
    const res = (await api.get(`/dppapi/predict/${projectId}/pdfs/${pdfFileId}`)).data;
    if (res.success !== false) {
      this.setState({ pdfInfo: res })
    }
  }

  handleGetTenantPreference = async(param) => {
    const res = await api.request({
      url: `/dppapi/predict/tenant/preference/query`,
      method: 'POST',
      data: param
    })
    if (res.data.success){
      this.setState({tenantPreference: {...this.state.tenantPreference, [`${param.name}_${param.subName}`]: res.data.value}})
    }
  }
  handleGetMetaData = async(categoryList) => {
    const { projectId } = this.props
    categoryList.forEach( async(category) => {
      const res = await api.request({
        url: `/dppapi/predict/pdf/${projectId}/metadata/query?category=${category}`,
        method: 'GET'
      })
      if (res.data.success){
        const { metadata } = this.state
        this.setState({ metadata:{...metadata, [category]: res.data.metadatas}})
      }
    })
  }

  handleSetScrollTop = (table, scrollTop) => {
    this.setState({ [table]: scrollTop })
  }

  handleCheck = (detailData) => {
    const { metaMap, metadata } = this.state
    const allList = checkScrumItemWithAll(detailData, metaMap, metadata);
    const errorList = allList.filter(all => all.isError === true)
    this.setState({ allList: allList })
    return errorList
  }

  handleReload = async (keepPageType = false) => {
    const { projectId, pdfFileId } = this.props
    this.setState({
      isLoading: true
    })

    const { data } = await api.request({
      url: `/dppapi/predict/pdf/scrum/mapping/${projectId}/${pdfFileId}`,
      method: 'GET'
    })

    const res = await api.request({
      url: `/dppapi/predict/pdf/${projectId}/metadata/query?category=scrumItem`,
      method: 'GET'
    })
    this.setState({ metaMap:  metaDataToMap(res.data.metadatas), metadatas: res.data.metadatas});

    const headerList = BSheaderList;
    if (data.success && res.data.success) {
      // get DetailData and copy
      const {scrumMaps} = data;
      Object.keys(scrumMaps).forEach(scrumSubTitle => {
        if (scrumSubTitle !== 'manual_input') {
          if (scrumSubTitle === PageTypeList[0]) {
            scrumMaps[scrumSubTitle].forEach(scrum => {
              headerList.forEach(header=>{
                if (scrum.scrumItem === header) {
                  this.valueList.push(scrum)
                }
              })
            })
          }
          data.scrumMaps[scrumSubTitle].forEach(d => {
            d["id"] = genId()
          })
        } else {
          Object.keys(scrumMaps[scrumSubTitle]).forEach(page => {
            if (page === PageTypeList[0]) {
              headerList.forEach(header => {
                if (scrumMaps[scrumSubTitle][page][header]) {
                  let inputValue = {scrumItem: header, value: {id: '-1', text: scrumMaps[scrumSubTitle][page][header], invert: false}}
                  this.inputList.push(inputValue)
                }
              })
            }
          })
        }
      })
      const CurrentEndBanaceList = BScurrentEndBanaceList;
      if (!data.scrumMaps[PageTypeList[2]]) {
        data.scrumMaps[PageTypeList[2]] = [];
      }
      CurrentEndBanaceList.forEach(tmp => {
        const value = headerList[CurrentEndBanaceList.indexOf(tmp)]
        let scrumItemList = [];
        this.inputList.forEach(inputV => {
          if (inputV.scrumItem === value) {
            scrumMaps[PageTypeList[2]].push({id: genId(), scrumItem: tmp, value: {id: '-1', text: inputV.value.text, invert: inputV.value.invert}, item: {}, subTitle: {}})
            scrumItemList.push(value)
          }
        })
        let inputFlag = false;
        this.valueList.forEach(v => {
          scrumItemList.forEach(inputScrum => {
            if (v.scrumItem === inputScrum) {
              inputFlag = true;
            }
          })
          if (v.scrumItem === value && !inputFlag) {
            scrumMaps[PageTypeList[2]].push({id: genId(), scrumItem: tmp, value: {id: '-1', text: v.value.text, invert: v.value.invert}, item: {}, subTitle: {}})
          }
        })
      })
      this.valueList = [];
      this.inputList = [];
      const tmpDetailData = _.cloneDeep(data.scrumMaps)
      this.setState({ detailData: data.scrumMaps, tmpDetailData });

      // convert catagory data to scrumMappingList
      let scrumMappingList = {}
      res.data.metadatas.forEach(md => {
        if (scrumMappingList[md.item]) {
          const formula = this.state.metadata["fs_formula"]
          const aggreationItem = formula.find(f => f.subCategory === md.item && f.item === md.value) 
          if (aggreationItem) {
            const autoAggregation = `${md.value}${SCRUM_ITEM_SUFFIX.AGGREGATION}`;
            scrumMappingList[md.item].push(autoAggregation)
          }
          scrumMappingList[md.item].push(md.value)
        } else {
          const formula = this.state.metadata["fs_formula"]
          const aggreationItem = formula.find(f => f.subCategory === md.item && f.item === md.value) 
          if (aggreationItem) {
            const autoAggregation = `${md.value}${SCRUM_ITEM_SUFFIX.AGGREGATION}`;
            scrumMappingList[md.item] =[autoAggregation]
            scrumMappingList[md.item].push(md.value)
          } else {
            scrumMappingList[md.item] = [md.value]
          } 
        }
      })

      // get ScrumData and copy
      const scrumData = this.convertData(data.scrumMaps, scrumMappingList)
      const errorList = this.handleCheck(this.state.detailData)
      // add aggreation to scrumData
      const scrumDataWithAggreation = this.convertAggreation(_.cloneDeep(scrumData))

      const tmpScrumData = _.cloneDeep(scrumDataWithAggreation)
      this.setState({ scrumData: scrumDataWithAggreation, tmpScrumData, scrumMappingList, errorList});

      const currentPageType = keepPageType ? this.state.currentPageType : ""
      this.genTableInfo(currentPageType, scrumMappingList)
      this.getPdfStatus()
    } else {
      this.setState({ detailData: [], tmpDetailData: [], scrumData: [], tmpScrumData: [] });
      // TODO
      console.log("api error")
    }

    this.setState({
      isLoading: false
    })

  }
  getPdfStatus = async () => {
    const { projectId, pdfFileId } = this.props
    const pdf = await api.request({
      url: `/dppapi/predict/${projectId}/pdfs/${pdfFileId}`,
      method: 'GET'
    })
    this.setState({ pdfStatus: pdf.data.stateFlag })
  }
  convertData = (detailData, scrumMappingList) => {
    const converScrumData = {}
    Object.keys(scrumMappingList).forEach(pt => {
      const tmp = []
      scrumMappingList[pt].forEach(scrumItem => {

        const manualInput = this.getManualInputValue(detailData, pt, scrumItem)
        const row = { id: genId(), scrumItem: scrumItem, values: [], manualInput }
        if (detailData[pt]) {
          detailData[pt].forEach(sm => {
            if (sm.scrumItem === scrumItem) {
              row.values.push(sm.value)
            }
          })
        }
        tmp.push(row)
      })
      converScrumData[pt] = tmp
    })
    return converScrumData
  }

  getManualInputValue = (detailData, currentPageType, scrumItem) => {
    if (detailData["manual_input"] === undefined) {
      return undefined
    }
    if (detailData["manual_input"][currentPageType] === undefined) {
      return undefined
    }
    if (detailData["manual_input"][currentPageType][scrumItem] === undefined) {
      return undefined
    }
    return detailData["manual_input"][currentPageType][scrumItem]
  }

  genTableInfo = async (currentPageType, scrumMappingList) => {

    const pageType = []
    // let currentPageType = ""
    PageTypeList.forEach((name, idx) => {
      if (currentPageType.length === 0 && idx === 0) {
        currentPageType = name
      }
      pageType.push({ value: name, text: name })
    })

    this.getAllCatagory(scrumMappingList)
    this.setState({ pageType, currentPageType })

  }

  getAllCatagory = async (scrumMappingList) => {

    const category = {};
    Object.keys(scrumMappingList).forEach(pageType => {
      const options = []
      scrumMappingList[pageType].forEach((v, idx) => {
        options.push({ id: idx, text: v, value: v });
      })
      category[pageType] = options

    });
    this.setState({ category: category });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState.detailData, this.state.detailData) || !_.isEqual(prevState.allList, this.state.allList)) {
      const scrumData = this.convertData(this.state.detailData, this.state.scrumMappingList)
      const errorList = this.handleCheck(this.state.detailData)
      const scrumDataWithAggreation = this.convertAggreation(_.cloneDeep(scrumData))
      this.setState({ scrumData: scrumDataWithAggreation, errorList })
    }
  }

  convertAggreation = (scrumData) => {
    const { allList } = this.state;
    Object.keys(scrumData).forEach(pt => {
      scrumData[pt].forEach((row, idx) => {
          const allidx = allList.findIndex(all => all.name === row.scrumItem);
          if (allidx !== -1) {
            scrumData[pt][idx - 1].values = [{ text: formatCurrency(allList[allidx].calcVal.toString()) }]
          } 
      })
    })
    return scrumData
  }


  //   getCatagory = async (projectId, cg) => {

  //     const options = []
  //     PageTypeList.forEach(meta => {
  //       options.push({ id: meta.item, text: meta.value, value: meta.value })
  //     })
  //     const { category } = this.state
  //     this.setState({ category: { ...category, [cg]: options } })
  //   }
  // }
  handleMappingItemChange = (id, value, category) => {
    const { detailData } = this.state;
    const newDetailData = _.cloneDeep(detailData)
    const idx = detailData[this.state.currentPageType].findIndex(r => r.id === id);
    newDetailData[this.state.currentPageType][idx][category] = value
    this.props.onEdit(true)
    this.setState({ detailData: newDetailData })
  }

  handleUnLinkScrumItem = ( bboxId ) => {
    const { detailData } = this.state;
    const newDetailData = _.cloneDeep(detailData);
    for (let pageIdx in detailData) {
      for (let rowIdx in detailData[pageIdx]) {
        if (detailData[pageIdx][rowIdx]['value'] && bboxId === detailData[pageIdx][rowIdx]['value']['id']) {
          newDetailData[pageIdx][rowIdx]['scrumItem'] = "";
          this.setState({ detailData: newDetailData });
          this.props.onEdit(true);
          return;
        }
      }
    }
  }

  handlePageTypeChange = (value, fromThumbnail = false) => {
    const { isEditing } = this.props
    if (fromThumbnail) {
      const idx = PageTypeList.indexOf(value)
      if (idx !== -1 && !isEditing) {
        this.setState({ currentPageType: value, searchText: "" })

      }
    } else {
      this.setState({ currentPageType: value, searchText: "" })
      const thumnailRef = this.props.thumbnailRef.current
      this.findTargetPageByPageType(thumnailRef.state.imageList, value, this.props.currentPage)
    }

  }

  findTargetPageByPageType = (imageList, pageType, currentPageId) => {
    if (findIndex(imageList, {pageId: currentPageId.toString(), pageClassification: pageType}) > -1) {
      return
    }
    this.props.onPageChange(Number((find(imageList, {pageClassification: pageType}) || {pageId: currentPageId.toString()}).pageId))
  }

  handleScrumItemChange = (bboxId, scrumItem, presetSearchFlg) => {
    const { detailData, scrumMappingList } = this.state;
    const cpDetailData = _.cloneDeep(detailData);

    // ・,（, ）=> ･, (, ),
    let praseScrumItem = scrumItem.replace(/・/g, '･')
    // praseScrumItem = praseScrumItem.replace(/）/g, ')')
    // praseScrumItem = praseScrumItem.replace(/（/g, '(')

    // get real table key according to scrumItem
    let realTabelKey = ""
    Object.keys(scrumMappingList).forEach(tk => {
      if (scrumMappingList[tk].indexOf(praseScrumItem) !== -1) {
        realTabelKey = tk
      }
    })

    for (let tableKey of Object.keys(cpDetailData)) {
      const itemIndex = _.findIndex(cpDetailData[tableKey], (item) => {
        return item.value && item.value.id === bboxId;
      })
      if (itemIndex >= 0 && realTabelKey !== "") {
        const item = cpDetailData[tableKey][itemIndex]
        item.scrumItem = praseScrumItem
        item.value.text = item.value.text.replace(/\./g, ',')

        if (realTabelKey !== tableKey) {
          cpDetailData[tableKey].splice(itemIndex, 1)
          cpDetailData[realTabelKey].push(item)
        }

        this.handlePageTypeChange(realTabelKey,presetSearchFlg)

        this.setState({
          detailData: cpDetailData
        }, () => this.props.onEdit(true));

        setTimeout(()=>{
          let scrollElement = document.getElementById(praseScrumItem);
          if (scrollElement) {
            scrollElement.scrollIntoView()
          }
        }, 300);
        break;
      }
    }
  }

  handleCancel = () => {
    const { tmpScrumData, tmpDetailData } = this.state
    this.setState({
      scrumData: _.cloneDeep(tmpScrumData),
      detailData: _.cloneDeep(tmpDetailData),
      allCheck: false,
      checkedList: []
    }, () => {
      this.props.onRowSelect("", "", [])
      this.props.onEdit(false)
    })

  }
  handelCheck = (id, checked) => {
    const { detailData, checkedList } = this.state;
    let allDatalength = 0
    Object.keys(detailData).forEach(scrumSubTitle => {
      if ((scrumSubTitle) === this.state.currentPageType) {
        allDatalength = detailData[scrumSubTitle].length
        detailData[scrumSubTitle].forEach(d => {
          if (d.id === id) {
            d.checked = checked
          }
        })
      }
    })

    const cIdx = checkedList.indexOf(id)
    if (cIdx !== -1) {
      checkedList.splice(cIdx, 1)
    } else {
      checkedList.push(id)
    }

    const newAllCheck = checkedList.length === allDatalength ? true : false

    this.setState({ detailData, checkedList, allCheck: newAllCheck })
  }

  handleAllCheck = checked => {
    const checkedList = []
    Object.keys(this.state.detailData).forEach(scrumSubTitle => {
      if (scrumSubTitle === this.state.currentPageType) {
        this.state.detailData[scrumSubTitle].forEach(r => {
          r.checked = checked
          checkedList.push(r.id)
        })
      }
    })
    this.setState({
      allCheck: checked,
      checkedList: checked ? checkedList : []
    })
    // this.setState(this.state)
  }

  handleAdd = () => {

    const row = {
      id: genId(),
      pdfImageId: Number(this.props.currentPage),
      subTitle: { id: genNegativeId(), text: "" },
      item: { id: genNegativeId(), text: "" },
      value: { id: genNegativeId(), text: "" },
      scrumItem: ""
    }

    const { detailData, currentPageType } = this.state;
    detailData[currentPageType] ? detailData[currentPageType].push(row) :
      detailData[currentPageType] = [row]
    this.setState({ detailData })
  }
  handleRemove = () => {
    const { checkedList, detailData, currentPageType } = this.state
    const newData = _.cloneDeep(detailData)
    Object.keys(detailData).forEach(scrumSubTitle => {
      if (scrumSubTitle === currentPageType) {
        const tmp = []
        detailData[scrumSubTitle].forEach(d => {
          if (checkedList.indexOf(d.id) === -1) {
            tmp.push(d)
          }
        })
        newData[scrumSubTitle] = tmp
      }
    })

    this.setState({ detailData: newData, checkedList: [], allCheck: false })
  }

  handleTextChange = (bboxId, text, type) => {
    const { detailData } = this.state
    const newDetailData = _.cloneDeep(detailData)

    if (type === "link") {
      const { selectedCell, onRowSelect } = this.props
      const { id, cellIds, column } = selectedCell

      let newSelectedBox = { id: "", pdfImageId: "", cellIds: [] }
      Object.keys(newDetailData).forEach(scrumSubTitle => {
        if (scrumSubTitle === this.state.currentPageType) {
          newDetailData[scrumSubTitle].forEach(d => {
            if (d.id === id) {

              Object.keys(d).forEach(key => {

                if (d[key].id && column === key && cellIds.indexOf(d[key].id) !== -1) {
                  d[key].id = bboxId
                  d[key].text = text
                  newSelectedBox = { ...selectedCell, cellIds: [bboxId] }
                }
              })
            }
          })
        }
      })

      onRowSelect(newSelectedBox.id, newSelectedBox.pdfImageId, newSelectedBox.cellIds)
      this.setState({ detailData: newDetailData, linking: false })

    } else if (type === "change") {// for popup content edit
      Object.keys(newDetailData).forEach(scrumSubTitle => {
        if (scrumSubTitle !== 'manual_input') {
          newDetailData[scrumSubTitle].forEach(d => {
            Object.keys(d).forEach(key => {
              if (d[key] && d[key].id === bboxId) {
                d[key].text = text
              }
            })
          })
        }
      })

      this.setState({ detailData: newDetailData })
    } else if (type === "delete") {
      Object.keys(newDetailData).forEach(scrumSubTitle => {
        if (scrumSubTitle !== 'manual_input') {
          newDetailData[scrumSubTitle].forEach(d => {
            Object.keys(d).forEach(key => {
              if (d[key] && d[key].id === bboxId) {
                d[key].text = ""
                d[key].id = genNegativeId()
              }
            })
          })
        }
      })
      this.setState({ detailData: newDetailData })
    }
  }

  // convert pdfImagedId to pageNum (1,2,3,4 ...)
  handleNormalized = (pdfImageId) => {
    if (pdfImageId) {
      return pdfImageId - this.props.minPdfImageId + 1
    } else {
      return ""
    }
  }

  handleSetInvertFlag = (values) => {
    let {detailData} = this.state;
    values.forEach(value=>{
      Object.keys(detailData).forEach(scrumSubTitle => {
        if (scrumSubTitle !== 'manual_input') {
          detailData[scrumSubTitle].forEach(d => {
            if (d['value'].id === value.id) {
              d['value'] = {
                id: d['value'].id,
                text: d['value'].text,
                invert: value.invert
              }
            }
          })
        }
      })
    })
    const scrumData = this.convertData(detailData, this.state.scrumMappingList)
    const errorList = this.handleCheck(detailData)
    const scrumDataWithAggreation = this.convertAggreation(_.cloneDeep(scrumData))
    this.setState({ detailData: detailData, scrumData: scrumDataWithAggreation, errorList })
  }

  handleSave = async () => {
    this.setState({ allCheck: false, checkedList: [] })
    const { projectId, pdfFileId, onEdit } = this.props
    const body = {}
    const tmp = _.cloneDeep(this.state.detailData)
    Object.keys(tmp).forEach(scrumSubTitle => {
      if (scrumSubTitle !== 'manual_input') {
        tmp[scrumSubTitle].forEach(d => {
          delete d.id
        })
        if (scrumSubTitle === PageTypeList[2]) {
            BScurrentEndBanaceList.forEach(bs => {
              for (let i = 0; i < tmp[scrumSubTitle].length; i++) {
                if (tmp[scrumSubTitle][i].scrumItem === bs) {
                  tmp[scrumSubTitle].splice(i, 1);
                  if (tmp[scrumSubTitle].length === 0) {
                    break;
                  } else {
                    i--;
                  }
                }
              }
          })
        }
      }
    })

    body['scrumMaps'] = tmp

    const data = await (await api.post(`/dppapi/predict/pdf/scrum/update/${projectId}/${pdfFileId}`,
      JSON.stringify(body),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
    })).data;

    // Track all save operations for the pdf
    try {
      track2DBStorage(projectId, pdfFileId, TYPES.SAVE_SCRUM_MAP)
    } catch (e) {
      console.error(e)
    }

    if (data.success) {
      onEdit(false)
      this.handleReload(true)
    }

  }

  handleSearch = text => {
    if (this.state.currentPageType === PageTypeList[2] && this.state.mode === TableMode.SCRUM ) {
      return;
    } 
    this.setState({ searchText: text })
  }

  handleSearchFromBBoxClick = () => {
    const { scrumData, currentPageType, mode } = this.state
    if (mode === TableMode.DETAIL) return

    const { currentBoxId } = this.props

    let filteredRows = scrumData[currentPageType] === undefined ? [] : scrumData[currentPageType]
    filteredRows.forEach(r => {
      const idx = r.values.findIndex(v => v.id === currentBoxId)
      if (idx !== -1) {
        let scrollElement = document.getElementById(r.scrumItem)
        if (scrollElement) {
          scrollElement.scrollIntoView()
        }
      }
    })
  }

  handleDownload = async () => {
    this.setState({ isDownloading: true })
    const { currentPageType, scrumData, detailData, mode } = this.state;
    const sufix = moment().format(FMT.TIME_STRING)
    let option = {}
    let body = []

    if (mode === TableMode.DETAIL) {
      option = {
        header: ['page num', 'subtitle', 'item', 'value', 'scrum item'],
      }
      let filteredRows = !detailData || (detailData && detailData[currentPageType] === undefined) ? [] : detailData[currentPageType]

      filteredRows.forEach(r => {
        const row = []
        row.push(this.handleNormalized(r.pdfImageId), r.subTitle.text, r.item.text, r.value.text, r.scrumItem)
        body.push(row)
      })
    } else {
      option = {
        header: ['scrum item', 'total value'],
      }
      let filteredRows = !scrumData || (scrumData && scrumData[currentPageType] === undefined) ? [] : scrumData[currentPageType]
      filteredRows.forEach(r => {
        const row = []
        const totalValue = r.manualInput === undefined ? this.handleGetSum(r.values) : r.manualInput
        row.push(r.scrumItem.replace(/当期末残高\(B\/S自動集計\)/gi, "当期末残高(自動集計)"), totalValue)
        body.push(row)
      })
    }
    await downloadCsv(body, option, `KV_${currentPageType}_${sufix}.csv`)
    this.setState({ isDownloading: false })


  }

  handleCellClear = () => {
    const { detailData, } = this.state
    const newDetailData = _.cloneDeep(detailData)

    const { id, cellIds, column } = this.props.selectedCell
    if (cellIds.length !== 1) {
      return
    }
    // clear selected cell
    this.props.onRowSelect("", "", [], "")

    // clear tabel cell
    const bboxId = cellIds[0]
    Object.keys(newDetailData).forEach(scrumSubTitle => {
      if (scrumSubTitle !== 'manual_input') {
        newDetailData[scrumSubTitle].forEach(d => {
          if (d.id === id) { // row id
            Object.keys(d).forEach(key => {
              if (key === column && d[key] && d[key].id === bboxId) { // item id
                d[key].text = ""
                d[key].id = genNegativeId()
              }
            })
          }

        })
      }
    })
    this.setState({ detailData: newDetailData })
  }
  handleGetSum = (values) => {
    if (values.length === 0) return ""
    let sum = 0
    values.forEach(v => {
      var val = convertTextToValue(v.text)
      if(v.invert){
        val = -val
      }
      if (val) {
        sum += val
      }
    })
    const splitSum = sum.toString().split(',')
    let integer = splitSum[0]
    const decimal = splitSum[1] ? `.${splitSum[1]}` : ""
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",")// format 12345678.88888 to 12,345,678.88888

    return `${integer}${decimal}`
  }
  handleLinkBbox2Cell4KVTable = (scrumItem) => {
    const { detailData, currentPageType } = this.state

    const newDetailData = _.cloneDeep(detailData)

    const { selectedBBox: { id, text }, onSelectedBBoxChange, onEdit } = this.props
    if (id !== "" && text !== "") {
      onSelectedBBoxChange("", "")

      // find in detail data 
      let existIn = "", existIndex = -1
      const parseText = text.replace(/\./g, ',')// . => ,
      Object.keys(newDetailData).forEach(scrumSubTitle => {
        if (scrumSubTitle !== 'manual_input') {
          const idx = newDetailData[scrumSubTitle].findIndex(d => d.value && d.value.id === id)
          if (idx !== -1) {
            existIn = scrumSubTitle
            existIndex = idx
          }
        }
      })

      // target exist and in current page
      if (existIndex !== -1 && existIn === currentPageType) {
        newDetailData[existIn][existIndex]["value"].text = parseText
        newDetailData[existIn][existIndex].scrumItem = scrumItem

      } else { // target not exist or in other page

        // if i
        let row = {
          id: genId(),
          pdfImageId: Number(this.props.currentPage),
          subTitle: { id: genNegativeId(), text: "" },
          item: { id: genNegativeId(), text: "" },
          value: { id, text: parseText },
          scrumItem: scrumItem
        }
        //if it exists on other page, we should move it to the current page and change it's scrumitem
        if (existIndex !== -1 && existIn !== currentPageType) {
          const res = newDetailData[existIn].splice(existIndex, 1)
          row = res[0]
          row["scrumItem"] = scrumItem
          row["value"]['text'] = parseText
        }

        if (newDetailData[currentPageType]) {
          newDetailData[currentPageType].push(row)

        } else {
          newDetailData[currentPageType] = [row]
        }

      }

      this.setState({ detailData: newDetailData }, () => {
        onEdit(true)
      })
    }
  }
  handleLinkBbox2Cell4TextTable = (rowId, cellId, column) => {
    const { detailData, } = this.state
    const newDetailData = _.cloneDeep(detailData)

    const { selectedBBox: { id, text }, onSelectedBBoxChange, onEdit } = this.props
    if (id !== "" && text !== "") {
      onSelectedBBoxChange("", "")

      Object.keys(newDetailData).forEach(scrumSubTitle => {
        if (scrumSubTitle !== 'manual_input') {
          newDetailData[scrumSubTitle].forEach(d => {
            if (d.id === rowId) { // row id

              Object.keys(d).forEach(key => {
                if (key === column && d[key] && d[key].id === cellId) { // item id
                  d[key].text = text
                  d[key].id = id
                }
              })
            }

          })
        }
      })
      this.setState({ detailData: newDetailData }, () => {
        onEdit(true)
      })
    }
  }

  handleTabChange = (idx, searchText = "") => {
    // const { mode } = this.state
    // const { isEditing } = this.props
    // if (mode !== idx && isEditing) {
    //   this.tabChangeDialog(idx, searchText)
    // } else {
    //   this.setState({ mode: idx, searchText })
    // }
    this.setState({ mode: idx, searchText })
  }

  tabChangeDialog = (idx, searchText) => {
    confirmAlert({
      title: "warning",
      message: "you must save current page data first, click ok to save",
      buttons: [
        {
          label: "OK",
          onClick: () => {
            // TODO save api
            this.handleSave()
            this.setState({ mode: idx, searchText })
          },
        },
        {
          label: "Cancel",
        },
      ],
      zIndex: 500,
    });
  };

  handleGetFilteredRows = () => {
    const { mode, scrumData, detailData, currentPageType, searchText } = this.state
    if (mode === TableMode.SCRUM) {
      // eslint-disable-next-line 
      let filteredRows = !scrumData || scrumData && scrumData[currentPageType] === undefined ? [] : scrumData[currentPageType]

      if (searchText.length > 0) {
        const halfReg = new RegExp(_.escapeRegExp(halfToFull(searchText)), 'i')
        const fullReg = new RegExp(_.escapeRegExp(fullToHalf(searchText)), 'i')

        const isMatch = (result) => {
          let match = []
          match.push(halfReg.test(result['scrumItem']))
          match.push(fullReg.test(result['scrumItem']))
          const sum = this.handleGetSum(result['values'])
          match.push(halfReg.test(sum))
          match.push(fullReg.test(sum))

          let res = false
          for (let i = 0; i < match.length; i++) {
            if (match[i]) {
              res = true
              break
            }
          }
          return res
        }
        filteredRows = _.filter(filteredRows, isMatch)
      }
      return filteredRows
    } else if (mode === TableMode.DETAIL) {
      // eslint-disable-next-line 
      let filteredRows = !detailData || detailData && detailData[currentPageType] === undefined ? [] : detailData[currentPageType]

      if (searchText.length > 0) {
        const re = new RegExp(_.escapeRegExp(searchText), 'i')

        const isMatch = (result) => {
          let match = []
          match.push(re.test(result['subTitle'].text))
          match.push(re.test(result['item'].text))
          match.push(re.test(result['value'].text))
          match.push(re.test(result['scrumItem']))

          let res = false
          for (let i = 0; i < match.length; i++) {
            if (match[i]) {
              res = true
              break
            }
          }
          return res
        }
        filteredRows = _.filter(filteredRows, isMatch)
      }
      return filteredRows
    }
  }

  handleInputModeChange = (scrumItem, mode) => {
    const { detailData, currentPageType } = this.state
    const newDetailData = _.cloneDeep(detailData)
    if (mode === ManualInputStatus.INPUT) {
      if (newDetailData['manual_input']) {
        if (newDetailData['manual_input'][currentPageType]) {
          newDetailData['manual_input'][currentPageType][scrumItem] = ""
        } else {
          newDetailData['manual_input'][currentPageType] = { [scrumItem]: "" }
        }
      } else {
        newDetailData['manual_input'] = { [currentPageType]: { [scrumItem]: "" } }
      }
    } else {
      delete newDetailData['manual_input'][currentPageType][scrumItem]
    }
    this.setState({ detailData: newDetailData })

  }

  handleManualInputTextChange = (scrumItem, text) => {
    const { detailData, currentPageType } = this.state
    const { onEdit } = this.props
    const newDetailData = _.cloneDeep(detailData)
    newDetailData['manual_input'][currentPageType][scrumItem] = text
    onEdit(true)
    this.setState({ detailData: newDetailData })
  }

  sendReport() {
    confirmAlert({
      title: "管理者へ報告",
      message: "精度が改善必要と思いましたら、管理者へ報告します。",
      buttons: [
        {
          label: "送信",
          onClick: () => {
            this.handleReport()
          },
        },
        {
          label: "キャンセル",
        },
      ],
    });
  };

  handleReport = async () => {
    const { projectId, pdfFileId } = this.props
    await api.get(`/dppapi/predict/pdf/issue/report/${projectId}/${pdfFileId}`)
  }

  render() {
    const {
      pageType,
      currentPageType,
      allCheck,
      checkedList,
      searchText,
      category,
      mode,
      detailData,
      isDownloading,
      errorList,
      pdfStatus,
      fsKeyValueTableScrollTop,
      scrumData,
      metadata,
      tenantPreference,
      pdfInfo,
    } = this.state;
    const {
      onRowSelect,
      selectedCell,
      currentBoxIdForCss,
      projectId,
      pdfFileId,
      selectedBBox,
      isEditing,
      onEdit,
      currentPage
    } = this.props;

    // search
    const filteredRows = this.handleGetFilteredRows()
    const panes = [
      {
        menuItem: '一覧',

      },
      {
        menuItem: '詳細',
      },
    ]


    if (this.state.isLoading) {
      return <Loader active inline="centered" />
    }

    return (
      <div className="old-pdf-textmapping-root" >
        <FsResultFunctionToolBox
          scrumData = {scrumData}
          projectId={projectId}
          pdfFileId={pdfFileId}
          isEditing={isEditing}
          pdfStatus={pdfStatus}
          cb={this.getPdfStatus}
          errorList={errorList}
          pdfInfo={pdfInfo}
        />
        <div className="old-pdf-textmapping-operator">
          <div className={'old-select-group'}>

            <Select
              options={pageType}
              value={currentPageType}
              placeholder="Select a type"
              onChange={(e, { value }) => this.handlePageTypeChange(value)}
            />

          </div>
          <div className={'old-btn-group'} >
            <Button
              disabled={!isEditing}
              primary
              size="medium"
              onClick={() => this.handleSave()}
            >保存
            </Button>
            <Button
              disabled={!isEditing}
              size="medium"
              onClick={() => this.handleCancel()}
            >取消
            </Button>
            {
              tenantPreference["button_admin_report"] === "1" ? <Button
              color="orange"
              size="medium"
              onClick={() => this.sendReport()}
            >管理者へ報告
              </Button> : null
            }
            <Button
              disabled={isEditing || isDownloading}
              loading={isDownloading}
              size="medium"
              icon='download'
              positive
              onClick={() => this.handleDownload()}
            />
          </div>

        </div>

        <div className="old-pdf-textmapping-page-body">
          <div className="old-pdf-textmapping-page-tab">
            <Tab activeIndex={mode} panes={panes} onTabChange={(e, data) => this.handleTabChange(data.activeIndex)} />
            <Input
              icon='search'
              iconPosition='left'
              placeholder='Search...'
              value={searchText}
              onChange={(e, { value }) => this.handleSearch(value)}
              className="old-pdf-textmapping-page-search"
              action={{ icon: 'remove', onClick: () => this.handleSearch(""), disabled: searchText.length === 0 }}
            />
          </div>
          {mode === TableMode.SCRUM ? <FsKeyValueTable
            rows={filteredRows}
            onRowSelect={onRowSelect}
            selectedCell={selectedCell}
            currentBoxIdForCss={currentBoxIdForCss}
            selectedBBox={selectedBBox}
            onGetSum={this.handleGetSum}
            onTabChange={this.handleTabChange}
            onLinkBbox2Cell={this.handleLinkBbox2Cell4KVTable}
            currentPageType={currentPageType}
            detailData={detailData}
            onInputModeChange={this.handleInputModeChange}
            onManualInputTextChange={this.handleManualInputTextChange}
            onEdit={onEdit}
            currentPage={currentPage}
            errorList={errorList}
            fsKeyValueTableScrollTop={fsKeyValueTableScrollTop}
            onSetScrollTop={this.handleSetScrollTop}
            searchText={searchText}
            isEditing={isEditing}
            setInvertFlag={this.handleSetInvertFlag}
            metadata={metadata}
            tenantPreference={tenantPreference}
            metadatas={this.state.metadatas}
          /> : <>
              <div className={'old-btn-group-detail'} >
                {/* <Button
                  disabled={selectedCell.cellIds.length !== 1}
                  size="medium"
                  color="olive"
                  onClick={() => {
                    onEdit(true)
                    onLinkTo()
                  }}
                >
                  {linking ? 'キャンセル' : '紐付ける'}
                </Button>
                <Button
                  disabled={selectedCell.cellIds.length !== 1}

                  size="medium"
                  onClick={() => {
                    onEdit(true)
                    this.handleCellClear()
                  }}
                  negative
                >
                  紐付け解除
                </Button> */}
                <Button
                  disabled={checkedList.length === 0}
                  primary
                  size="medium"
                  onClick={() => {
                    onEdit(true)
                    this.handleRemove()
                  }}
                  icon="minus"
                  negative
                />
                <Button
                  primary
                  size="medium"
                  onClick={() => {
                    onEdit(true)
                    this.handleAdd()
                  }}
                  icon='add'
                  positive
                />
              </div>
              <FsTextTable
                rows={filteredRows}
                onMappingItemChange={this.handleMappingItemChange}
                onCheck={this.handelCheck}
                onAllCheck={this.handleAllCheck}
                allCheck={allCheck}
                checkedList={checkedList}
                onRowSelect={onRowSelect}
                selectedCell={selectedCell}
                onNormalized={this.handleNormalized}
                currentBoxIdForCss={currentBoxIdForCss}
                category={category[currentPageType]}
                onLinkBbox2Cell={this.handleLinkBbox2Cell4TextTable}
                selectedBBox={selectedBBox}
              />
            </>}
        </div>
      </div >
    );
  }
}