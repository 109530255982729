import React from 'react';

import { MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact';
import {
  Button,
  Form,
} from 'semantic-ui-react';
import api from '../../../api';
import intl from 'react-intl-universal';
import { confirmAlert } from 'react-confirm-alert';

export default class AuditsEdit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      errmsg: null,
      audits: {},
    }

    this.handleInput = this.handleInput.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  handleInput(event) {
    const {name, value} = event.target
    const audits = {...this.state.audits}
    audits[name] = value
    this.setState({
      errmsg: null,
      audits
    })
  }

  handleSave(status) {
    confirmAlert({
      title:  intl.get('_system.tenants.audits.Edit.Confirm'),
      message: intl.get('_system.tenants.audits.Edit.Do you want to update this audits?'),
      buttons: [
        {
          label: intl.get('_system.tenants.audits.Edit.Confirm'),
          onClick: async () => {
            var audits = Object.assign({}, this.props.audits, this.state.audits)
            audits.status = status
            this.setState({
              errmsg: null
            })
            const resp = await (await api.put(`/dppapi/system/audits/${audits.id}`, audits)).data

            if (!resp.success) {
              this.setState({
                errmsg: resp.errmsg
              })
              return;
            }
            this.props.toggleEdit(false)
            this.props.refetch()
          },
        },
        {
          label: intl.get('_system.tenants.audits.Edit.Cancel'),
        },
      ],
    });
  }

  render() {
    const { audits } = this.props
    const { errmsg } = this.state

    return (
      <MDBModal size="lg" isOpen={this.props.isShowEdit} toggle={this.props.toggleEdit}>
        <MDBModalHeader>
        {intl.get('_system.tenants.audits.Edit.AuditsEdit')}
        </MDBModalHeader>
        <MDBModalBody>
          <Form>
            <Form.Field style={{ width: '49%', display: 'inline-block' }}>
              <label>{intl.get('_system.tenants.audits.Edit.TenantsName')}</label>
              <Form.Input defaultValue={audits.form && audits.form.tenants_name} readOnly name="tenantname" />
            </Form.Field>
            <Form.Field style={{ width: '51%', display: 'inline-block', paddingLeft: '2%' }}>
              <label>{intl.get('_system.tenants.audits.Edit.TenantsMail')}</label>
              <Form.Input defaultValue={audits.form && audits.form.tenants_mail} readOnly name="tenantmail" />
            </Form.Field>
            <Form.Field>
              <label>{intl.get('_system.tenants.audits.Edit.Address')}</label>
              <Form.Input defaultValue={audits.form && audits.form.address} readOnly name="address" />
            </Form.Field>
            <Form.Field style={{ width: '49%', display: 'inline-block' }}>
              <label>{intl.get('_system.tenants.audits.Edit.LastName')}</label>
              <Form.Input defaultValue={audits.form && audits.form.last_name} readOnly name="lastname" />
            </Form.Field>
            <Form.Field style={{ width: '51%', display: 'inline-block', paddingLeft: '2%' }}>
              <label>{intl.get('_system.tenants.audits.Edit.FirstName')}</label>
              <Form.Input defaultValue={audits.form && audits.form.first_name} readOnly name="firstname" />
            </Form.Field>
            <Form.Field style={{ width: '49%', display: 'inline-block' }}>
              <label>{intl.get('_system.tenants.audits.Edit.LastKanaName')}</label>
              <Form.Input defaultValue={audits.form && audits.form.last_kana_name} readOnly name="lastkananame" />
            </Form.Field>
            <Form.Field style={{ width: '51%', display: 'inline-block', paddingLeft: '2%' }}>
              <label>{intl.get('_system.tenants.audits.Edit.FirstKanaName')}</label>
              <Form.Input defaultValue={audits.form && audits.form.first_kana_name} readOnly name="firstkananame" />
            </Form.Field>
            <Form.Field style={{ width: '49%', display: 'inline-block' }}>
              <label>{intl.get('_system.tenants.audits.Edit.Phone')}</label>
              <Form.Input defaultValue={audits.form && audits.form.phone_num} readOnly name="phone" />
            </Form.Field>
            <Form.Field style={{ width: '51%', display: 'inline-block', paddingLeft: '2%' }}>
              <label>{intl.get('_system.tenants.audits.Edit.UserMail')}</label>
              <Form.Input defaultValue={audits.form && audits.form.user_mail} readOnly name="usermail" />
            </Form.Field>
            <Form.Field>
              <label>{intl.get('_system.tenants.audits.Edit.ContractPlan')}</label>
              <Form.Input defaultValue={audits.form && audits.form.contract_plan} readOnly name="contractplan" />
            </Form.Field>
            <Form.Field>
              <label>{intl.get('_system.tenants.audits.Edit.Comment')}</label>
              <Form.TextArea defaultValue={audits.comment} name="comment" onChange={this.handleInput} rows="3" />
            </Form.Field>
            {audits.status !== 3 && <Button floated='right' onClick={() => this.handleSave(3)}>{intl.get('_system.tenants.audits.Edit.Agree')}</Button>}
            {audits.status === 1 && <Button floated='right' onClick={() => this.handleSave(4)}>{intl.get('_system.tenants.audits.Edit.Refuse')}</Button>}
            <Button floated='right' onClick={() => { this.props.toggleEdit(false); this.setState({ errmsg: null }) }}>{intl.get('_common.Cancel')}</Button>
          </Form>
          <div style={{ marginTop: '2em', color: 'red' }}>{errmsg}</div>
        </MDBModalBody>
      </MDBModal>
    );
  }
}
