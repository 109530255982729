import React, { Component } from 'react'
import api from '../../../api'
import { Button } from 'semantic-ui-react'
import MyContext from '../../../context-manager'
import { getFinancialStatementsExtraInfo } from '../../../project/result-function/ExtraInfo'
import { getCsvExtraInfo } from '../../../project/result-function/CsvExtraInfo'
import { sendTenantRpa } from '../../../project/result-function/ResultFunction'

import { confirmAlert } from 'react-confirm-alert'
import { downloadCsv } from '../../Util'
import moment from 'moment';
import CSV from 'comma-separated-values';
export default class ResultFunctionToolBox extends Component {
  constructor(props) {
    super(props)
    this.state = { resultFunctions: [], rpa: false, nextFlag: true, needUnitConvert: false }
  }
  async componentDidMount() {
    try {
      const { data } = await api.get('/dppapi/predict/resultFunction/' + this.props.projectId);

      if (data.success) {
        this.setState({
          resultFunctions: data.data
        })
      }
      const { metadatas, success} = (await api.get(`/dppapi/predict/prediction/metadata/query?projectId=${this.props.projectId}&category=result_function`)).data;
  
      if (success) {
        const res = metadatas.find(mt=>mt.subCategory === "csv_download" && mt.item === "unit_conversion")
        
        const needUnitConvert = res && res.value !== "0"
        this.setState({ needUnitConvert })
      }
    } catch (e) {
      console.log(e)
    }
  }

  componentWillUnmount() {
    this.setState = () => false;
  }

  handelExecResultFunc = async (resFunc, handleOperateDialog, confirmBtnName) => {
    const { errorList } = this.props;
    if (errorList.length > 0) {
      let nameList = errorList.map((err) => { return err.name });
      confirmAlert({
        title: "注意",
        message: `(自動集計)と(入力)の値が合っていない項目があります、『` + nameList.join('; ') + `』、確認してください。`,
        buttons: [
          {
            label: "閉じる",
          },
          {
            label: confirmBtnName + "に進む",
            onClick: async () => { this.handleRpaPop(resFunc, handleOperateDialog, confirmBtnName) },
          },
        ],
      });
    } else {
      this.handleRpaPop(resFunc, handleOperateDialog, confirmBtnName)
    }
  }

  handleRpaPop = async (resFunc, handleOperateDialog, confirmBtnName) => {
    const getTableInfo = () => {
      return {
        損益計算書: {
          header: ["subTitle", "item", "value", "scrumItem"],
          body: [
            ["【売上原価】", "外注費", "214,461,280", "売上原価"],
            ["【売上原価】", "仕入高", "87,278,953", "売上原価"]
          ]
        },
        貸借対照表: {
          header: ["subTitle", "item", "value", "scrumItem"],
          body: [
            ["【流動資産】", "(1)現金預金", "215,969,728", "営業利益"],
            ["【【流動資産】", "(2)受取手形", "87,278,953", "営業利益"]
          ]
        }
      }

    }
    const getProjectId = () => this.props.projectId
    const getPdfId = () => this.props.pdfFileId
    const getPdfInfo = () => this.props.pdfInfo
    const disRpa = () => {
      this.handleDisRpa()
    }
    try {

      const superCsvDownload = async (data, options, fileName, needBom, projectId = this.props.projectId, pdfFileId = this.props.pdfFileId) => {
        await api.post(`/dppapi/predict/evaluate-preset/${projectId}/${pdfFileId}`)
        downloadCsv(data, options, fileName, needBom);
      }
      // eslint-disable-next-line 
      const executor = new Function('return ' + resFunc)();
      // TODO result_function 
      // delete getProjectId, getPdfId, getFinancialStatementsExtraInfo, getCsvExtraInfo, callback
      await executor({
        getTableInfo, 
        getProjectId, 
        getPdfId,
        handleOperateDialog, 
        getFinancialStatementsExtraInfo, 
        callback: disRpa, 
        downloadCsv: superCsvDownload, 
        fsScrumData: this.props.scrumData, 
        getCsvExtraInfo, 
        needUnitConvert: this.state.needUnitConvert, 
        confirmBtnName, 
        getPdfInfo, 
        sendTenantRpa, 
        moment, 
        CSV
      });
      this.setState({ nextFlag: true })
    } catch (e) {
      console.log(e)
    }
  }
  handleDisRpa = () => {
    this.setState({ rpa: true })
  }
  render() {
    const { resultFunctions } = this.state
    return (
      <div className="old-pdf-textmapping-rsFunc-tool">
        {resultFunctions.map(rf => <MyContext.Consumer key={rf.id} >
          {({ handleOperateDialog, commonDialog }) => {
            return (<Button key={rf.id}
              disabled={this.props.isEditing}
              primary
              size="medium"
              className="old-pdf-textmapping-rsFunc-btn"
              onClick={() => this.handelExecResultFunc(rf.resultFunction, handleOperateDialog, rf.buttonName)}
            >{rf.buttonName}
            </Button>)
          }}
        
        </MyContext.Consumer>)
        }
        {/* {this.props.isEditing ?<div className="pdf-textmapping-rsFunc-message">
          {intl.get('_predict.detail.mapping.ProhibitEditingWarning')}
        </div>:null} */}
      </div >
    )
  }
}