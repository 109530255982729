import React, { Component } from 'react';
import { Container, Menu, Loader, Button, Popup } from 'semantic-ui-react';
import Prepareing from './detail/Prepareing';
import TrainAndEvaluate from './detail/TrainAndEvaluate';
import TensorflowServing from './detail/TensorflowServing';
import api from '../api'

export default class TrainMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'Prepareing',
      tabs: [
        {
          label: 'Prepareing',
          component: 'Prepareing'
        },
        {
          label: 'Train and evaluate',
          component: 'TrainAndEvaluate'
        }
        ,
        {
          label: 'TensorflowServing',
          component: 'TensorflowServing'
        }
      ],
      train: null,
      isLoaded: false,
    }
    this.handleSwitchTab = this.handleSwitchTab.bind(this)
  }

  async componentDidMount() {
    const { match } = this.props;
    const { projectId, trainId } = match.params;
    const train = (await api.get(`/dppapi/train/${projectId}/${trainId}`)).data;
    this.setState({
      train,
      isLoaded: true,
    });
  }

  handleSwitchTab(e, { name, component }) {
    this.setState({
      activeTab: component
    })
  }

  render() {
    const { activeTab, tabs, train, isLoaded } = this.state;

    if (!isLoaded) {
      return <Loader active inline="centered" />;
    }

    return (
      <div>
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {train && train.description ? (
            <Popup
              trigger={
                <Button
                  icon="info"
                  label={train && train.name + ': ' + train.moduleName}
                  size="large"
                  color="blue" />
              }
              content={<p>{train.description}</p>}
              position="bottom left"
              mouseEnterDelay={500}
              hoverable
              onClick={(e) => e.stopPropagation()}
              inverted
            >
            </Popup>
          ) : (
              <Button
                icon="info"
                label={train && train.name + ': ' + train.moduleName}
                size="large"
                color="blue" />
            )}
        </div>
        <Container>
          <Menu pointing secondary>
            {
              tabs.map(tab => {
                return <Menu.Item
                  name={tab.label}
                  component={tab.component}
                  active={activeTab === tab.component}
                  onClick={this.handleSwitchTab}
                  key={tab.label}
                />
              })
            }
          </Menu>
          {
            activeTab === 'Prepareing' && <Prepareing train={train} />
          }
          {
            activeTab === 'TrainAndEvaluate' && <TrainAndEvaluate train={train} />
          }
          {
            activeTab === 'TensorflowServing' && <TensorflowServing train={train}/>
          }
        </Container>
      </div>
    );
  }
}
