import React from 'react';
import { Button, Segment, Dropdown } from 'semantic-ui-react';
import DatePicker from 'react-date-picker';
import { getOptions, getDeleteOptions } from './Constant';
import intl from 'react-intl-universal';
import api from '../../api'
export default class PdfSearch extends React.Component {

  constructor(props) { 
    super(props)
    this.state = {
      tenantOptions: []
    }
  }
  async componentDidMount() { 
    
    const tenants = (await api.get(`/dppapi/system/tenant`)).data;
    if (tenants) { 
      const tenantOptions = []
      tenants.forEach(t => { 
        tenantOptions.push({key: t.id, value: t.id, text: `${t.name} (${t.id})`})
      })
      this.setState({tenantOptions})
    }
  }
  render() {
    const { onSearch, onConditionsChange, conditions } = this.props;
    const { tenantId, stateFlag, uploadTimeStart, uploadTimeEnd, lastEditedStart, lastEditedEnd, deleteFlag } = conditions;

    return (
      <Segment>
        <div className="system-search-pdf-condition">
          <p className="system-search-pdf-label">{intl.get('_system.PdfSearch.Search.Tenant')}</p>
          <Dropdown
            clearable
            options={this.state.tenantOptions}
            selection
            search
            onChange={(e, data) => onConditionsChange('tenantId', data.value)}
            value={tenantId} />
        </div>
        <div className="system-search-pdf-condition">
          <p className="system-search-pdf-label">{intl.get('_system.PdfSearch.Search.StateFlag')}</p>
          <Dropdown
            clearable
            options={getOptions()}
            selection
            search
            onChange={(e, data) => onConditionsChange('stateFlag', data.value)}
            value={stateFlag} />
        </div>
        <div className="system-search-pdf-condition">
          <p className="system-search-pdf-label">{intl.get('_system.PdfSearch.Search.UploadTime')}</p>
          <DatePicker
            className="system-search-pdf-item"
            onChange={date => onConditionsChange('uploadTimeStart', date)}
            value={uploadTimeStart}
            format="y/MM/dd" />
          {' '}
          ~
          <DatePicker
            className="system-search-pdf-item"
            onChange={date => onConditionsChange('uploadTimeEnd', date)}
            value={uploadTimeEnd}
            format="y/MM/dd" />
        </div>
        <div className="system-search-pdf-condition">
          <p className="system-search-pdf-label">{intl.get('_system.PdfSearch.Search.LastEdited')}</p>
          <DatePicker
            className="system-search-pdf-item"
            onChange={date => onConditionsChange('lastEditedStart', date)}
            value={lastEditedStart}
            format="y/MM/dd" />
          {' '}
          ~
          <DatePicker
            className="system-search-pdf-item"
            onChange={date => onConditionsChange('lastEditedEnd', date)}
            value={lastEditedEnd}
            format="y/MM/dd" />
        </div>
        <div className="system-search-pdf-condition">
          <p className="system-search-pdf-label">{intl.get('_system.PdfSearch.Search.DeleteFlag')}</p>
          <Dropdown
            clearable
            options={getDeleteOptions()}
            selection
            search
            onChange={(e, data) => onConditionsChange('deleteFlag', data.value)}
            value={deleteFlag} />
        </div>

        <div className="system-search-pdf-condition">
          <Button type="submit"
            onClick={() => onSearch({
              stateFlag,
              uploadTimeStart,
              uploadTimeEnd,
              lastEditedStart,
              lastEditedEnd,
              deleteFlag
            })}>
            {intl.get('_system.PdfSearch.Search.Search')}
          </Button>
        </div>
      </Segment>
    );
  }
}