import React from 'react'
import { MDBDataTable } from 'mdbreact'
import { Button, Icon, Segment } from 'semantic-ui-react'
import { confirmAlert } from 'react-confirm-alert'
import PreferencesEdit from './Edit'
import ImportPreferences from './ImportPreferences';
import { getCsrfToken } from '../../common/CookieUtil';
import api from '../../api'
import intl from 'react-intl-universal';

export default class Preferences extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoaded: true,
      isShowEdit: false,
      isShowImport: false,
      editPreferences: {},
      data: {}
    }
    this.handleDel = this.handleDel.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.toggleEdit = this.toggleEdit.bind(this)
    this.getPreferences = this.getPreferences.bind(this)
    this.handleExport = this.handleExport.bind(this);
    this.handleImport = this.handleImport.bind(this);
    this.toggleImport = this.toggleImport.bind(this);
  }

  async componentDidMount() {
    await this.getPreferences()
  }

  handleImport() {
    this.toggleImport({
      isShowImport: true
    });
  }

  toggleImport({ isShowImport }) {
    this.setState({
      isShowImport: isShowImport
    });
  }

  async getPreferences() {
    const preferences = (await api.get(`/dppapi/tenant-setting/preferences`)).data;
    const columns = [
      {
        label: intl.get('_setting.tenantSetting.TenantSetting.ID'),
        field: 'id',
        sort: 'asc',
      },
      {
        label: intl.get('_setting.tenantSetting.TenantSetting.Category'),
        field: 'category',
        sort: 'asc',
      },
      {
        label: intl.get('_setting.tenantSetting.TenantSetting.SubCategory'),
        field: 'subCategory',
        sort: 'asc',
      },
      {
        label: intl.get('_setting.tenantSetting.TenantSetting.Name'),
        field: 'name',
        sort: 'asc',
      },
      {
        label: intl.get('_setting.tenantSetting.TenantSetting.SubName'),
        field: 'subName',
        sort: 'asc',
      },
      {
        label: intl.get('_setting.tenantSetting.TenantSetting.Version'),
        field: 'version',
        sort: 'asc',
      },
      // {
      //   label: 'SubVersion',
      //   field: 'subVersion',
      //   sort: 'asc',
      // },
      {
        label: intl.get('_setting.tenantSetting.TenantSetting.Value'),
        field: 'value',
        sort: 'asc',
      },
      // {
      //   label: 'Priority',
      //   field: 'priority',
      //   sort: 'asc',
      // },
      {
        label: intl.get('_setting.tenantSetting.TenantSetting.Operation'),
        field: 'operation',
        sort: 'asc',
      }
    ]
    let rows = []
    if (preferences) {
      rows = preferences.map((data) => {
        return {
          ...data,
          value: (
            <div style={{ width: '100px',wordBreak: 'break-all' }}>
                {data.value}
            </div>
          ),
          valueText: data.value,
          operation: (
          <div>
            <Button 
              icon="pencil" 
              label={intl.get('_setting.tenantSetting.TenantSetting.Edit')}
              size="tiny" 
              onClick={() => this.handleEdit(data)}/>
            <Button
              icon="trash"
              label={intl.get('_setting.tenantSetting.TenantSetting.Delete')}
              size="tiny"
              onClick={() => this.handleDel(data)}
            />
          </div>
          )
        }
      })
    }
    this.setState({
      data: {
        columns: columns,
        rows: rows
      }
    })
  }

  toggleEdit(isShowEdit) {
    this.setState({
      editPreferences: {},
      isShowEdit: isShowEdit
    })
  }

  handleEdit(preferences) {
    this.setState({
      isShowEdit: true,
      editPreferences: preferences
    })
  }
  
  handleDel(preferences) {
    confirmAlert({
      title: intl.get('_setting.tenantSetting.TenantSetting.Delete this record'),
      message: intl.get('_setting.tenantSetting.TenantSetting.Are you sure to do this?'),
      buttons: [
        {
          label: intl.get('_setting.tenantSetting.TenantSetting.Delete'),
          onClick: async () => {
            await api.delete(`/dppapi/tenant-setting/preferences/${preferences.id}`)
            this.getPreferences()
          },
        },
        {
          label: intl.get('_setting.tenantSetting.TenantSetting.Cancel'),
        },
      ],
    })
  }

  async handleExport() {
    confirmAlert({
      message: intl.get('_setting.tenantSetting.TenantSetting.Do you want to get tenantSetting?'),
      buttons: [
        {
          label: intl.get('_setting.tenantSetting.TenantSetting.Cancel'),
        },
        {
          label: intl.get('_setting.tenantSetting.TenantSetting.Download'),
          onClick: async () => {
            let a = document.createElement('a');
            a.href = `/dppapi/tenant-setting/export?token=${getCsrfToken()}`;
            a.click();
            a.remove();
          },
        },
      ],
    })
  }

  render() {
    return (
      <div>
      <Segment>
        <Button.Group floated="right" style={{ marginLeft: '2px' }}>
          <Button icon onClick={this.handleExport}>
            <Icon name="download" />
          </Button>
        </Button.Group>
        <Button.Group floated="right" style={{ marginLeft: '2px' }}>
          <Button icon onClick={this.handleImport}>
            <Icon name="file" />
          </Button>
        </Button.Group>
       <Button.Group floated='right'>
        <Button icon onClick={() => this.handleEdit({})}>
          <Icon name='add' />
        </Button>
        </Button.Group>
      <MDBDataTable
        theadColor="common-search-table-header"
        btn
        striped
        bordered
        hover
        data={this.state.data}
      />
      <PreferencesEdit 
        isShowEdit={this.state.isShowEdit}
        preferences={this.state.editPreferences}
        toggleEdit={this.toggleEdit}
        getPreferences={this.getPreferences}/>
      </Segment>
      <ImportPreferences
        isShowImport={this.state.isShowImport}
        toggleImport={this.toggleImport}
        getPreferences={this.getPreferences}
      />
      </div>
    )
  }
}