import React, { Component } from 'react';
import intl from 'react-intl-universal';

import _ from 'lodash';
import { Input, Ref, Table, Popup, Icon } from 'semantic-ui-react';
import { ChevronRight, CancelIcon } from '../../../assets/icon';

import { getClass } from '../../StyleUtil'
import { ManualInputStatus } from './FsConstant';
import { formatMoney, handleDeleteCommaFromMoney, ignoreSpecialCharForCalc } from '../../Util';
import { EventName, EventType, FixAmountSource, PredictDetailPageLocation, ViewMode } from '../../../common/Analytics/analyticsMetrics';
import { AnalyticsServiceContext } from '../../../common/Analytics/AnalyticsServiceProvider';

import EditBox from './EditBox';
import BoardPopupContent from './BoardPopupContent';
import { confirmAlert } from 'react-confirm-alert';

let isComposition = false;
const isChrome = navigator.userAgent.indexOf('Chrome') > -1;
export default class ValueTableItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      numerical: [],
      keepOpen: false,
      keepHover: false,
      isChanging: false,
      lastKeyPressed : "",
      contentAble: true,
      popItemName: '',
      componentName: '',
      calcInputError: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleComposition = this.handleComposition.bind(this);
    this.contextRef = React.createRef();
  }
  static contextType = AnalyticsServiceContext;

  componentDidUpdate() {
    const { row, selectedCell } = this.props;
    if ((this.state.keepOpen || this.state.keepHover)
      && selectedCell && selectedCell.id && selectedCell.id !== ''
      && this.state.componentName !== 'boardPopup') {
      if (!_.find(row.values, { id: selectedCell.cellIds[1] })) {
        this.setState({ keepOpen: false, keepHover: false });
      }
    }
  }

  handleComposition(e,r) {
    if (e.type === 'compositionend') {
      isComposition = false;
      let data = e.target.value.replace(/[^0-9,-]/g, '');
      if (!isComposition && isChrome) {
        this.handleChange(e, data, r);
      }
    } else {
      isComposition = true;
    }
  }

  handleOpenInputMode = () => {
    const { onInputModeChange, onEdit, row } = this.props;
    this.setState({ editing: true }, () => {
      this.input.focus();
      this.input.select();
    });
    onInputModeChange(row.scrumItem, ManualInputStatus.INPUT);
    onEdit(true);
    this.onSetTableNotHoverable();

    const { inputCalc, currentPageType} = this.props;
    if(inputCalc[currentPageType]){
      if(inputCalc[currentPageType][row.scrumItem]){
        const initValue = inputCalc[currentPageType][row.scrumItem]
        this.setState({
          numerical: [row.scrumItem, initValue, false]
        })
      }
    }
  }

  inputErrorAlter = (item) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        const closeAct = ()=>{onClose();this.handleOpenInputMode()}
        return (
          <div className='custom-ui-confirm-alert-body-RPA'>
            <h1>
              <Icon name="exclamation triangle" className="total-right-icon-error" />
              {intl.get('_predict.detail.mapping.Have Input Error')}
            </h1>
            <div className='custom-ui-confirm-alert-body-inner'>
              {item}
            </div>
            <div className='custom-ui-confirm-alert-button-group'>
              <button
                className='predict-admin-botton-text confirmAlert-button-left'
                onClick={closeAct}>
                {intl.get('_predict.detail.mapping.Close')}
              </button>
            </div>
          </div>
        );
      },
      closeOnClickOutside: false,
    });
  }

  handleSaveData = (initValue, r) => {
    const {
      handleInputChange,
      onGetSum,
      onInputModeChange,
      onEdit
    } = this.props

    let convertText = initValue;
    if (initValue && initValue.startsWith('=')) {
      convertText = convertText.substring(1);
      if(!convertText){
        this.setState({ numerical: [r.scrumItem, '', true], keepHover: false,  editing: false, isChanging: false});
      }else{
        try {
          let editValue = initValue.replace(/,/g, '');
          editValue = ignoreSpecialCharForCalc(editValue);
          convertText = new Intl.NumberFormat('ja-JP', { style: 'decimal', currency: 'CAD', }).format(Math.round(eval(editValue.substring(1))));
          this.setState({ numerical: [r.scrumItem, convertText, false], keepHover: false,  editing: false, isChanging: false });
        } catch (error) {
          convertText = intl.get('_predict.detail.mapping.Formula input error');
          this.setState({ calcInputError: true});
          return false
        }
      }
      
    }else{
      this.setState({
        keepHover: false,
        editing: false,
        isChanging: false,
      });
    }
    if (convertText && convertText.replace('-', '').length > 18) {
      convertText = intl.get('_predict.detail.mapping.More than 14 digits');
      this.setState({ numerical: [r.scrumItem, '', true] });
    }
    handleInputChange(
      r.scrumItem,
      !this.state.numerical[1] || initValue.startsWith('=') ? convertText : this.state.numerical[1],
      initValue && initValue.startsWith('=')&&convertText? initValue: ''
    );
    if (convertText === onGetSum(r.values)) {
      this.setState({ editing: false })
      onInputModeChange(r.scrumItem, ManualInputStatus.LINK);
      onEdit(true);
    }
    this.setState({ calcInputError: false});
    return true
  }

  /* eslint no-eval: 0 */
  handleChange = (e, money, r) => {
    //It can be triggered only when the input method is not used or after using the input method
    if (!isComposition) {
      let target = e.target;

      const startPosition = target.selectionStart;

      const prevMoney = this.state.numerical[1] ? String(this.state.numerical[1]) : this.props.onGetSum(r.values);
      
      const [currentMoney , diffPosition] = handleDeleteCommaFromMoney(prevMoney, money, startPosition, this.state.lastKeyPressed)

      const [convertText, finalPosition, finalSetFlag, headMinusFlag  ] = formatMoney(target.selectionStart, currentMoney);
      if (headMinusFlag) {
        if (convertText.length > 19 && !convertText.startsWith('=')) return;
      } else {
        if (convertText.length > 18 && !convertText.startsWith('=')) return;
      }
      if (finalSetFlag) {
        this.setState({ numerical: [r.scrumItem, convertText, false] });
      }
      this.setState({}, () => {
        target.selectionStart = finalPosition + diffPosition; 
        target.selectionEnd = finalPosition  + diffPosition;
      })
    } else {
      this.setState({ numerical: [r.scrumItem, money, false] });
    }
    this.setState({lastKeyPressed : ""})
    this.setState({ isChanging: true , calcInputError: false});
  }

  onOpenInput = (state) => {
    const {
      handleRowOperation,
      detailIds,
      row,
      selectedCell,
      pdfImageIds
    } = this.props;
    this.setState({ editing: state }, () => {
      if (selectedCell.id === '') {
        handleRowOperation(row, detailIds, pdfImageIds);
      } else {
        if (selectedCell.id !== row.id) {
          let cellIds;
          if ((selectedCell && selectedCell.cellIds === '') || selectedCell.id !== row.id) {
            cellIds = detailIds;
          } else {
            cellIds = selectedCell.cellIds;
          }
          handleRowOperation(row, cellIds, pdfImageIds);
        }
      }
      if (state) {
        this.handleOpenInputMode();
      }
    })
  };

  onHoverChange = (state, name) => {
    this.props.openable && this.setState({ keepHover: state, popItemName: name ? name : 'EditBox' });
  }

  onKeepOpen = (state, componentName) => this.setState({ keepOpen: state, componentName: componentName });

  onSetTableNotHoverable = () => {
    const { selectedCell, handleRowOperation, detailIds, row, pdfImageIds,onOpenModeChange } = this.props;
    if (selectedCell.id === '') { // when this row had been selected by single click
      handleRowOperation(row, detailIds, pdfImageIds);
    }
    onOpenModeChange(false);
  }

  renderCell = (r, checkResult, detailIds, pdfImageIds, isSelected) => {
    const {
      onGetSum,
      detailData,
      currentPageType,
      handleRowOperation,
      isDisabled,
      openable,
      isCancel,
      inputCalc,
      onOpenModeChange,
      isNotOwner,
      onShowExclusionControl,
    } = this.props;
    const { props } = this;
    const { editing, numerical, keepHover, isChanging, calcInputError } = this.state;
    const manualInput = r.manualInput;
    let cellDom;
    const { err, tableItem, isAggreation } = checkResult;
    const divHover = keepHover ? 'edit-box-div-letter' : '';

    const specialStyles = { top: '10px' };
    var renderEditBox = (parentElement) => {
      return <EditBox
        rowScrumItem={r.scrumItem}
        rowValues={r.values}
        manualInput={manualInput}
        onOpenInput={this.onOpenInput}
        onHoverChange={this.onHoverChange}
        isAggreation={isAggreation}
        parentElement={parentElement}
        popupItem={tableItem}
        isDisabled={isDisabled}
        specialClass='edit-box-normal-div-list'
        specialStyles={specialStyles}
        openable={isSelected || openable}
        showEditBtn={!isAggreation}
        isNotOwner={isNotOwner}
        onShowExclusionControl={onShowExclusionControl}
        {...props}
      />;
    }
    var referEditBox = (parentElement) => {
      return <EditBox
        rowScrumItem={r.scrumItem}
        rowValues={r.values}
        manualInput={manualInput}
        onOpenInput={this.onOpenInput}
        onHoverChange={this.onHoverChange}
        isAggreation={isAggreation}
        parentElement={parentElement}
        popupItem={tableItem}
        isDisabled={isDisabled}
        specialClass='edit-box-normal-div-list'
        specialStyles={specialStyles}
        openable={isSelected || openable}
        showEditBtn={false}
        isBSLine={true}
        {...props}
      />;
    }
    if (isAggreation) {
      cellDom = 
        err ? (
        // eslint-disable-next-line
        <Table.Cell textAlign="right" verticalAlign="middle" className="td-aggregation-value">
          {renderEditBox(
            <div className={divHover}>{onGetSum(r.values)}</div>
          )}
        </Table.Cell>
      ) : (
        <Table.Cell textAlign="right" verticalAlign="middle" className="td-aggregation-value">
          {renderEditBox(
            <div className={divHover}>{onGetSum(r.values)}</div>
          )}
        </Table.Cell>
      )
    } else if (!editing) {
      let isError;
      if (detailData.manual_input && currentPageType in detailData.manual_input) {
        const manualValue = detailData.manual_input[currentPageType][r.scrumItem];
        isError =
          manualValue === intl.get('_predict.detail.mapping.Formula input error') ||
          manualValue === intl.get('_predict.detail.mapping.More than 14 digits');
      }
      if(r.isRefer){
        cellDom = (
          <Table.Cell textAlign="right" verticalAlign="middle" className="td-aggregation-value">
            {manualInput === undefined ? (
              referEditBox(
                <div className={`ui simple item edit-box-normal-div-list `}
                  id={'item' + r.id}
                  tabIndex={this.props.tabIndex}
                >
                  {onGetSum(r.values) || '-'}
                </div>)
            ) : (
              referEditBox(
                <div className={`ui simple item edit-box-normal-div-list ${isError ? 'edit-box-div-err' : ''}`}
                  id={'item' + r.id}
                  tabIndex={this.props.tabIndex}
                >
                  <>{detailData.manual_input[r.originalPageType][r.originalItem] || ''}</>
                </div>)
            )}
        </Table.Cell>
        )
      }else{
        cellDom = (
          <Table.Cell
            verticalAlign="middle"
            className={`td-selectable ${!openable ? 'td-unhoverable' : ''}`}
            onClick={(e) => {
              if (e.detail === 1 && !r.isRefer) {
                handleRowOperation(r, detailIds, pdfImageIds);
              }
            }}
          >
            {manualInput === undefined ? (
              renderEditBox(
                <div className={`ui simple item edit-box-item ${keepHover ? 'edit-box-div-border' : ''}`}
                  id={'item' + r.id}
                  tabIndex={this.props.tabIndex}
                  onMouseEnter={() => {
                    this.onHoverChange(true)
                  }}
                  onClick={(e) => {
                    if(e.detail === 2 && this.context.predictDetailViewMode !== "" ){
                      this.context.sendPredictBeautifyDetailMetrics({
                        event_type: EventType.Action,
                        event_name: EventName.FixAmount,
                        custom_parameter: {
                          location : PredictDetailPageLocation.ScrumItem,
                          source : FixAmountSource.TextField,
                          view_mode : ViewMode.List,
                        },
                      })
                    }
                  }}
                >
                  {onGetSum(r.values) || '-'}
                </div>
              )
            ) : (
              renderEditBox(
                <div className={`ui simple item edit-box-item ${keepHover ? 'edit-box-div-border' : ''} ${isError ? 'edit-box-div-err' : ''}`}
                  id={'item' + r.id}
                  tabIndex={this.props.tabIndex}
                  onMouseEnter={() => { this.onHoverChange(true) }}
                >
                  <>{detailData.manual_input[currentPageType][r.scrumItem] || ''}</>
                </div>
              )
            )}
          </Table.Cell>
        );
      }
    } else {
      let initValue;
      if (!numerical[2] && (numerical[1] === undefined || (isCancel && !isChanging))) {   
        if (r.manualInput !== undefined) {
          initValue = r.manualInput;
          if(inputCalc[currentPageType]){
            if(inputCalc[currentPageType][r.scrumItem]){
              initValue = inputCalc[currentPageType][r.scrumItem]
            }
          }
        } else if (r.values.length > 0) {
          initValue = onGetSum(r.values);
        }
      } else {
        initValue = this.state.numerical[1];
      }
      cellDom = (
        <Table.Cell className={`td-selectable ${!openable ? 'td-unhoverable' : ''}`} verticalAlign="middle">
          <Input
            size='mini'
            className={`${initValue && initValue.startsWith('=') ? 'td-selectable-input-calculate' : 'td-selectable-input'} ${calcInputError?'input-mode-calc-error-border-input':''}`}
            value={initValue || ''}
            placeholder='半角数字入力'
            fluid
            ref={(input) => this.input = input}
            onChange={(e, data) => {
              this.handleChange(e, data.value, r);
            }}
            onCompositionStart={(e) => { this.handleComposition(e, r) }}
            onCompositionEnd={(e) => { this.handleComposition(e, r) }}
            onKeyDown={key => {
              if (key.keyCode === 8) {
                this.setState({ lastKeyPressed: "Backspace" })
              } else if (key.keyCode === 46) {
                this.setState({ lastKeyPressed: "Delete" })
              } else if (key.keyCode === 9 || key.keyCode === 13) {
                key.stopPropagation();
                key.preventDefault();
                this.handleSaveData(initValue, r);
                onOpenModeChange(true);
              }
            }}
            onBlur={() => {
              const rootElement = document.getElementById('root');
              rootElement.addEventListener('click', (e) => {
                const res = this.handleSaveData(initValue, r);
                if(!res){
                  e.preventDefault();
                  e.stopPropagation();
                  this.input.focus();
                  this.input.select();
                }
                onOpenModeChange(true);
              }, { once: true });
            }}
          />
          {initValue && initValue.startsWith('=') && !calcInputError ? (<label className="input-mode-calc-hint-label">四則演算記号: +-*/</label>): null}
          {calcInputError ? (<label className="input-mode-calc-hint-error-label">数式を正しく入力してください</label>): null}
        </Table.Cell>
      );
    }
    return cellDom;
  };

  renderAction = (scrumItem, manualInput, isDisabled, isSelected) => {
    const {
      onEdit,
      onInputModeChange,
      handleSearchReferItem,
      row,
      onGetSum,
      selectedCell,
      mappingDetail,
      handleUnLinkScrumItem,
      handleSetOppositeValue,
      handleCellOperation,
      handleRowOperation,
      detailIds,
      pdfImageIds,
      onOpenModeChange,
      pdfInfo,
      handleChangeValue,
      openable,
      onUpdateBBox,
      detailItemChanging,
      isNotOwner,
      onShowExclusionControl,
    } = this.props;
    const { editing, keepOpen, keepHover, contentAble, popItemName } = this.state;
    const detail = mappingDetail(row.scrumItem);
    let popupStyle = {};
    const bodyHeight = document.body.scrollHeight;
    const currentRowElement = scrumItem && document.getElementById(scrumItem) && document.getElementById(scrumItem).getBoundingClientRect();
    if(currentRowElement){
      const currentRowBottom = currentRowElement.bottom;
      const currentRowTop = currentRowElement.top;
      const tableTop = document.getElementsByClassName("fs-key-value-table")[0] ? document.getElementsByClassName("fs-key-value-table")[0].getBoundingClientRect().top : 0;
      const currentHeightBottom = bodyHeight - currentRowBottom - 8;
      const currentHeightTop = currentRowTop - tableTop;
      const popupHeight = detail.length * 76 + (detail.length - 1) * 2;
      if(currentHeightBottom > popupHeight || currentHeightTop > popupHeight){
        popupStyle = {}
      }else if(currentHeightBottom > currentHeightTop){
        popupStyle = { height: `${currentHeightBottom}px` }
      }else{
        popupStyle = { height: `${currentHeightTop}px` }
      }
    }
    let actionDom;
    if (!editing) {
      actionDom = (
        <>
           {}
          <Table.Cell className="td-action" verticalAlign="middle">
            {manualInput === undefined || (row.isRefer)? (
            <span
              className={`board-item-wrap-bottom-icon fix-icon-wrap ${isDisabled ? 'board-item-wrap-bottom-icon-disabled' : ''}`}
              onClick={() => {
                this.setState({
                  numerical: [],
                  editing: false
                });
              }}
            >
                {isDisabled ? <span tabIndex={-1} data-tab-type='skip'><ChevronRight disabled={isDisabled} /></span> : 
                <>
              <Popup
              offset={[8, 0]}
              disabled={!contentAble}
              trigger={
                <span
                  id={"ga-predict-detail-mapping-listview-item-detail" + row.scrumItem}
                  className="close-btn-icon-link"
                  tabIndex={this.props.tabIndex}
                  ref={this.contextRef}
                  onClick={() => {
                    this.setState({ keepOpen: !keepOpen })
                    row.isRefer && handleSearchReferItem(row.originalItem, row.originalPageType)
                    this.context.sendPredictBeautifyDetailMetrics({
                      event_type: EventType.Action,
                      event_name: EventName.OpenDetails,
                      custom_parameter: {
                        view_mode: ViewMode.List,
                      },
                    })
                    !isSelected && handleRowOperation(row, detailIds, pdfImageIds);
                  }}
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      row.isRefer && handleSearchReferItem(row.originalItem, row.originalPageType);
                    }
                    if (event.key === 'Enter' || event.keyCode === 32) {
                      event.preventDefault();
                      setTimeout(() => {
                        document.getElementById("ga-predict-detail-mapping-listview-item-detail" + row.scrumItem).focus();
                      })
                      this.setState({
                        keepOpen: !keepOpen,
                        contentAble: !contentAble
                      });
                    }
                  }}
                >
                  <ChevronRight disabled={isDisabled} />
                </span>
              }
              position="top left"
              content={row.isRefer ?
                intl.get('_predict.detail.mapping.Go to reference')
                : intl.get('_predict.detail.mapping.Open details')}
              on="hover"
              hideOnScroll
              inverted
                  /> 
          
              <Popup
                trigger={""}
                className="value-table-pop-item"
                offset={[8, 0]}
                style={popupStyle}
                context={this.contextRef}
                position="bottom left"
                content={
                  <BoardPopupContent
                    detail={detail}
                    row={row}
                    onGetSum={onGetSum}
                    handleUnLinkScrumItem={handleUnLinkScrumItem}
                    onEdit={onEdit}
                    handleSetOppositeValue={handleSetOppositeValue}
                    handleCellOperation={handleCellOperation}
                    selectedCell={selectedCell}
                    manualInput={row.manualInput}
                    onHoverChange={this.onHoverChange}
                    keepHover={keepHover}
                    popItemName={popItemName}
                    onKeepOpen={this.onKeepOpen}
                    showEditBtn={true}
                    pdfInfo={pdfInfo}
                    handleChangeValue={handleChangeValue}
                    openable={openable}
                    onOpenModeChange={onOpenModeChange}
                    onSetTableNotHoverable={this.onSetTableNotHoverable}
                    onUpdateBBox={onUpdateBBox}
                    detailItemChanging={detailItemChanging}
                    isNotOwner={isNotOwner}
                    onShowExclusionControl={onShowExclusionControl}
                  />
                }
                open={row.manualInput === undefined && keepOpen}
                onClose={(e) => {
                  if (e.type === "scroll") {
                    this.onHoverChange(false)
                    this.setState({ keepOpen: false })
                    return
                  }
                  setTimeout(() => {
                    this.setState({ contentAble: true })
                  })
                }}
                onMount={() => {
                  this.setState({ contentAble: false })
                }}
                hideOnScroll
                basic
                flowing
              />
              </>}
            </span>) :
            (
              <Popup
                trigger={
                  <span
                    onClick={() => {
                      this.setState({
                        numerical: [],
                        editing: false
                      });
                      onOpenModeChange(true);
                      onInputModeChange(scrumItem, ManualInputStatus.LINK);
                      onEdit(true);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        this.setState({
                          numerical: [],
                          editing: false,
                          calcInputError: false
                        });
                        onOpenModeChange(true);
                        onInputModeChange(scrumItem, ManualInputStatus.LINK);
                        onEdit(true);
                      }
                    }}
                    tabIndex={this.props.tabIndex}
                  >
                      <CancelIcon color='#909090' size={'24px'} className='edit-box-icon-cancel' />
                  </span>
                }
                content={intl.get(
                  '_predict.detail.mapping.Cancellation of manual input'
                )}
                on="hover"
                hideOnScroll
                inverted
              />
            )}
          </Table.Cell>
        </>
      );
    } else {
      actionDom = (
        <>
          <Table.Cell
            className="td-action"
            verticalAlign="middle"
            textAlign="center"
           
          >
            <span 
              className="board-item-wrap-bottom-icon-cancel close-btn-icon-link"
              tabIndex={this.props.tabIndex}
              onMouseDown={() => {
                this.setState({
                  numerical: [],
                  editing: false
                })
                onOpenModeChange(true);
                onInputModeChange(scrumItem, ManualInputStatus.LINK);
                onEdit(true);
              }}
            >
              <CancelIcon color='#909090' size={'24px'} className='edit-box-icon-cancel' />
            </span>
          </Table.Cell>
        </>
      );
    }
    return actionDom;
  };

  render() {
    const {
      renderSeparation,
      row,
      tableItems,
      getCellIds,
      err,
      isManual,
      isInput,
      diff,
      selectedCell,
      currentBoxIdForCss,
      isDisabled,
      checkResult,
      detailIds,
      isAggreation,
      isLink,
      handleRowOperation,
      renderAggreationStatus,
      openable,
      operationMethod,
      onOperationMethodChange,
      pdfImageIds,
      macOsKeyOperation,
    } = this.props;
    const isSelected = selectedCell.id === row.id;
    const renderRow = (
      <Ref innerRef={tableItems[`${row.id}${row.scrumItem}`]}>
        <Table.Row
          tabIndex={-1}
          id={row.scrumItem}
          className={
            `${!isAggreation ? 'edit-box-table-tr' : ''} edit-box-table-list
             ${this.state.keepHover && openable ? 'tr-backGround' : ''}
             ${openable ? 'edit-box-able' : 'edit-box-unable unhoverRow'}
             ${getClass(
              'row',
              {
                rowId: row.id,
                cellIds: getCellIds(row.values),
                err: err,
                scrumItem: row.scrumItem,
                isManual,
                isInput,
              },
              selectedCell,
              currentBoxIdForCss,
              'value',
              this.state.keepOpen
            )} ${isLink && !isAggreation ? ' tr-selectable' : ''}
            ${row.isRefer ? ' unhoverRow' : ''}`}
          onClick={e => {
            if(this.context.isClickLinkScrumItemButton){
              this.context.sendPredictBeautifyDetailMetrics({
                event_type: EventType.Action,
                event_name: EventName.LinkScrumItem,
                custom_parameter: {
                  view_mode : ViewMode.List,
                },
              })
              this.context.handleSetIsClickLinkScrumItemButton(false)
            }
            if (!isAggreation && !row.isRefer) {
              if (e.detail === 2) {
                e.stopPropagation();
                // if (!this.props.isNotOwner) {
                this.handleOpenInputMode();
                // }
              }
            }
          }}
          onDoubleClick={() => {
            if (this.context.predictDetailViewMode !== "") {
              this.context.sendPredictBeautifyDetailMetrics({
                event_type: EventType.Action,
                event_name: EventName.FixAmount,
                custom_parameter: {
                  location: PredictDetailPageLocation.ScrumItem,
                  source: FixAmountSource.TextField,
                  view_mode: ViewMode.List,
                },
              })
            }
          }}
          onKeyDown={event => {
            const currentTagName = document.activeElement.tagName.toLowerCase();
            const isCancelButton = event.target.querySelectorAll(".edit-box-icon-cancel").length > 0;
            const autoOpenByTab = (currentTagName === "tr" || currentTagName === "div") && !event.shiftKey;
            // when cancel button and no value item had selected , input model will auto open
            const autoOpenByShiftTab = (((isCancelButton || row.values.length === 0) && currentTagName === "tr") || currentTagName === "span") && event.shiftKey;
            if (event.key === 'Tab' && selectedCell.id !== '' && !isAggreation) {
              this.setState({ keepOpen: false })
              if (autoOpenByTab || autoOpenByShiftTab) {
                this.handleOpenInputMode();
              }
            }
            macOsKeyOperation && macOsKeyOperation(event);
          }}
          onFocus={event => {
            const focusedTarget = event.target.tagName.toLowerCase();
            if (focusedTarget === 'tr' && selectedCell.id !== '' && operationMethod === 'tab' && !isAggreation) {
              event.preventDefault();
              this.handleOpenInputMode();
            }

            if (focusedTarget === 'tr' && selectedCell.id !== '' && operationMethod === 'shift+tab') {
              const operationElement = event.target.querySelectorAll("span[tabIndex]")[0];
              const isCancelButton = event.target.querySelectorAll(".edit-box-icon-cancel").length > 0;
              if (!operationElement.dataset.tabType && !isCancelButton) {
                operationElement.focus();
              } else {
                event.preventDefault();
                this.handleOpenInputMode();
              }
            }
          }}
          onMouseDown={()=>{
            onOperationMethodChange("mouse");
          }}
        >
          {!isAggreation ? (
            <Table.Cell
              verticalAlign="middle"
              onClick={(e) => {
                if (e.detail === 1 && !row.isRefer) {
                  handleRowOperation(row, detailIds, pdfImageIds);
                }
              }}
            >
              <span className="td-selectable-text">{row.scrumItem}</span>
            </Table.Cell>
          ) : 
          <Table.Cell
            verticalAlign="middle"
            onClick={(e) => {
              if (e.detail === 1 && !row.isRefer) {
                handleRowOperation(row, detailIds, pdfImageIds);
              }
            }}
          >
            <span className="td-selectable-text">{row.scrumItem}</span>
          </Table.Cell>}
          {this.renderCell(row, checkResult, detailIds, pdfImageIds, isSelected)}
          {isAggreation
            ? renderAggreationStatus(err, isManual, diff)
            : this.renderAction(
              row.scrumItem,
              row.manualInput,
              isDisabled,
              isSelected
            )}
        </Table.Row>
      </Ref>
    );
    return (
      <React.Fragment>
        {renderSeparation(row.scrumItem)}
        {renderRow}
      </React.Fragment>
    );
  }
}

ValueTableItem.propTypes = {};

ValueTableItem.defaultProps = {};