import React from 'react';
import { Button, Segment, Form, Icon, Message} from 'semantic-ui-react';
import intl from 'react-intl-universal';

export default class RelpaceSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tenantId: '',
      tenants: [],
      isError:this.props,
    };
  }

  async componentDidMount() {
    const { onSearch, tenantId } = this.props;
    onSearch(tenantId);
  }

  render() {
    const { onSearch, handleAdd, tenantId } = this.props;
    const { errors } = this.props
    return (
      <Segment>
        {
          Object.keys(errors).length > 0 &&
          <Message
            error
            list={ Object.keys(errors).map(i => {
              return errors[i].content
            })}
          />
        }
        <Form>
          <Form.Field>
            <Button.Group floated="right" style={{ marginLeft: '2px' }}>
              <Button icon onClick={this.props.handleExport}>
                <Icon name="download" />
              </Button>
            </Button.Group>
            <Button.Group floated="right" style={{ marginLeft: '2px' }}>
              <Button icon onClick={this.props.handleImport}>
                <Icon name="file" />
              </Button>
            </Button.Group>
            <Button.Group floated='right' style={{ marginLeft: '2px' }}>
            <Button icon onClick={handleAdd}>
                <Icon name='add' />
              </Button>
            </Button.Group>
          </Form.Field>
          <Button type="submit"
            onClick={() => onSearch(tenantId)}>
           {intl.get('_system.replacement.Search.Search')}
          </Button>
        </Form>
      </Segment>
    );
  }
}
