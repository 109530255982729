import React, { Component } from 'react';
import {
  Button,
  Form,
  Grid,
  Segment,
  Image,
  Table,
  Header,
} from 'semantic-ui-react';
import api from '../api';
import { getCsrfToken } from '../common/CookieUtil';

const multipart = require('parse-multipart');

export default class UploadImages extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handlePicturesSubmit = this.handlePicturesSubmit.bind(this);
    this.handlePdfSubmit = this.handlePdfSubmit.bind(this);
    this.state = {
      filename: '',
      classifier: '',
      direction: '',
      filesrc: null,
      filedst: null,
    };
  }

  async handlePicturesSubmit(e) {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);

    const response = await api.post('/dppapi/predict/picture', { formData });
    console.log("response",response);
    //var test= new multiparty.Form();
    //response.on = {
    //    "end": null,
    //    "error": null,
    //    "aborted": null
    //};
    //test.parse(response, function(err, fields, files) {
    //var a = {fields: fields, files: files};
    //console.log(a);
    //});
    //this.props.onChange();
    var boundary = '';
    for (let header of response.headers) {
      let key = header[0];
      if (key === 'x-content-type') {
        let value = header[1];
        boundary = value.split('=')[1];
        break;
      }
    }
    const body = await response.data;
    //console.log(body)
    const buff = new Buffer(body);
    //const body = await response.body;
    //const reader = body.getReader();
    //console.log(body)
    //reader.read().then(({ done, value }) => {
    //    console.log(value)
    //    let parts = multipart.Parse(value, boundary);
    //    for(var i=0;i<parts.length;i++){
    //        var part = parts[i];
    //        console.log(part);
    //    }
    //});

    var text = {};
    multipart.Parse(buff, boundary).forEach(function(element) {
      if (element.type === 'text/plain') {
        text[element.filename] = element.data.toString();
      } else if (element.type === 'image/jpeg') {
        const x = atob(new TextDecoder('utf-8').decode(element.data));
        const y = new Array(x.length);
        for (let i = 0; i < x.length; i++) {
          y[i] = x.charCodeAt(i);
        }
        text[element.filename] = URL.createObjectURL(
          new Blob([new Uint8Array(y)], { type: 'image/jpeg' })
        );
      }
    });

    // const text = JSON.parse(body, boundary);
    this.setState({
      filename: text.image_filename,
      classifier: text.image_type,
      direction: text.image_direction,
      filedst: text.image_object,
    });

    form.reset();
  }

  async handlePdfSubmit(e) {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);

    const response = await api.post('/dppapi/predict/pdf-uploads/1', { formData });

    var boundary = '';
    for (let header of response.headers) {
      let key = header[0];
      if (key === 'content-type') {
        let value = header[1];
        boundary = value.split('=')[1];
        break;
      }
    }
    const body = await response.data;
    const buff = new Buffer(body);

    var text = {};
    multipart.Parse(buff, boundary).forEach(function(element) {
      if (element.type === 'text/plain') {
        text[element.filename] = element.data.toString();
      } else if (element.type === 'image/jpeg') {
        const x = atob(new TextDecoder('utf-8').decode(element.data));
        const y = new Array(x.length);
        for (let i = 0; i < x.length; i++) {
          y[i] = x.charCodeAt(i);
        }
        text[element.filename] = URL.createObjectURL(
          new Blob([new Uint8Array(y)], { type: 'image/jpeg' })
        );
      }
    });

    // const text = JSON.parse(body, boundary);
    this.setState({
      filename: text.image_filename,
      classifier: text.image_type,
      direction: text.image_direction,
      filedst: text.image_object,
    });

    form.reset();
  }

  async handlePdfAnalysis(e) {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);

    const response = await api.post('/dppapi/predict/pdf_analysis?projectId=1', { formData });

    const rslt = await response.data;
    if (rslt.success) {
      let a = document.createElement('a');
      a.href = `${rslt.link}?token=${getCsrfToken()}`;
      a.click();
      a.remove();
    } else {
      alert(rslt.errmsg);
    }
    form.reset();
  }

  handleChange(e) {
    this.setState({
      filesrc: URL.createObjectURL(e.target.files[0]),
    });
  }

  render() {
    return (
      <Grid celled="internally">
        <Grid.Row>
          <Grid.Column width={4}>
            <Header>
              <Header.Content>Image to Predict</Header.Content>
            </Header>
            <Segment>
              <Form
                method="post"
                encType="multipart/form-data"
                onSubmit={this.handlePicturesSubmit}
              >
                <Form.Input
                  multiple
                  type="file"
                  id="pictures"
                  name="pictures"
                  accept=".jpg, .jpeg, .png"
                  onChange={e => this.handleChange(e)}
                />
                <Button type="submit">Start Predict</Button>
              </Form>
            </Segment>
          </Grid.Column>

          <Grid.Column width={4}>
            <Header>
              <Header.Content>Pdf to Predict</Header.Content>
            </Header>
            <Segment>
              <Form
                method="post"
                encType="multipart/form-data"
                onSubmit={this.handlePdfSubmit}
              >
                <Form.Input
                  multiple
                  type="file"
                  id="pdf"
                  name="pdf"
                  accept=".pdf"
                />
                <Button type="submit">Start Upload</Button>
              </Form>
            </Segment>
          </Grid.Column>

          <Grid.Column width={4}>
            <Header>
              <Header.Content>Pdf predict result</Header.Content>
            </Header>
            <Segment>
              <Form
                method="post"
                encType="multipart/form-data"
                onSubmit={this.handlePdfAnalysis}
              >
                <Form.Input
                  multiple
                  type="file"
                  id="pdf"
                  name="pdf"
                  accept=".pdf"
                />
                <Button type="submit">start predict</Button>
              </Form>
            </Segment>
          </Grid.Column>

          <Grid.Column width={4}>
            <Header>
              <Header.Content>Prediction result</Header.Content>
            </Header>
            <Segment>
              <Table basic="very" celled collapsing>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <Header as="h5" image>
                        <Header.Content>Image Filename</Header.Content>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>{this.state.filename}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <Header as="h5" image>
                        <Header.Content>Image Classification</Header.Content>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>{this.state.classifier}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <Header as="h5" image>
                        <Header.Content>Image Direction</Header.Content>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>{this.state.direction}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <Header>
              <Header.Content>Image Preview</Header.Content>
            </Header>

            <Segment>
              <Image src={this.state.filesrc} />
            </Segment>
          </Grid.Column>
          <Grid.Column width={16}>
            <Header>
              <Header.Content>Predicted Image</Header.Content>
            </Header>

            <Segment>
              <Image src={this.state.filedst} />
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row />
      </Grid>
    );
  }
}
