import React, { createContext, useEffect, useState, useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { PageName, serviceName } from './analyticsMetrics';
import api from '../../api';

export const AnalyticsServiceContext = createContext({
  sendMetrics: (metrics) => {},
  setProjectId : (projectId) => {},
  setPdfFileId : (pdfFileId) => {},
  sendPredictBeautifyDetailMetrics : (metrics) => {},
  isClickLinkScrumItemButton : false,
  handleSetIsClickLinkScrumItemButton : (isClickLinkScrumItemButton) => {},
  predictDetailViewMode : "",
  handleSetPredictDetailViewMode : (predictDetailViewMode) => {}
});

export function AnalyticsServiceProvider({ children }) {
  const [pendingMetrics, setPendingMetrics] = useState([]);
  const [projectId , setProjectId] = useState("");
  const [pdfFileId , setPdfFileId] = useState("");
  const [isClickLinkScrumItemButton , setIsClickLinkScrumItemButton] = useState(false)
  const [predictDetailViewMode,setPredictDetailViewMode] = useState("")

  const sendData = useCallback((data) => {
    
    api.post(`/dppapi/user-action-log`,data) 
      .then(() => setPendingMetrics([]))
  }, []);

  // Once any event is triggered, delay for more 30 seconds.
  // Force to send metrics if the first event is not sent within 60 seconds.
  const debouncedSendMetrics = useDebouncedCallback(sendData, 30000, { maxWait: 60000 });

  useEffect(() => {
    if (pendingMetrics.length === 0) {
      return;
    }

    debouncedSendMetrics(pendingMetrics);
  }, [debouncedSendMetrics, pendingMetrics]);

  const flushEvents = useCallback(() => {
    debouncedSendMetrics.flush();
  }, [debouncedSendMetrics]);

  useEffect(() => {
    // Flush events before the window is going to be closed.
    window.addEventListener('beforeunload', flushEvents);
    return () => window.removeEventListener('beforeunload', flushEvents);
  }, [flushEvents]);

  const sendMetrics = useCallback((metrics) => {
    setPendingMetrics((s) => [
      ...s,
      {
        ...metrics,
        service_name: serviceName,
        event_timestamp: Math.floor(Date.now() / 1000),
      },
    ]);
  }, []);

  const sendPredictBeautifyDetailMetrics = useCallback((metrics) => {
    const customParameter = {  project_id : projectId , pdf_id : pdfFileId , ...metrics.custom_parameter}
    setPendingMetrics((s) => [
      ...s,
      {
        ...metrics,
        page_name: PageName.PredictDetailPage,
        custom_parameter : customParameter,
        service_name: serviceName,
        event_timestamp: Math.floor(Date.now() / 1000),
      },
    ]); 
  },[projectId,pdfFileId])

  const handleSetIsClickLinkScrumItemButton = useCallback((value) => {
    setIsClickLinkScrumItemButton(value);
  }, []);

  const handleSetPredictDetailViewMode = useCallback((value) => {
    setPredictDetailViewMode(value);
  },[])

  return (
    <AnalyticsServiceContext.Provider
      value={{
        sendMetrics,
        setProjectId,
        setPdfFileId,
        sendPredictBeautifyDetailMetrics,
        isClickLinkScrumItemButton,
        handleSetIsClickLinkScrumItemButton,
        predictDetailViewMode,
        handleSetPredictDetailViewMode
      }}
    >
      {children}
    </AnalyticsServiceContext.Provider>
  );
}
