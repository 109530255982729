import React, { Component } from 'react';
import { Header, Form, Segment, Icon, Message } from 'semantic-ui-react';
import api from '../api';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router-dom';

class RetrievePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: null,
      loading: false,
      errors: {},
    };
    this.handleEmailCheck = this.handleEmailCheck.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
  }

  // async componentDidMount() {
  //   var user_email = getCookie('user_email');
  //   this.setState({
  //     email :user_email
  //   })
  // }

  async handleEmailCheck(e) {
    const { email } = this.state;
    if (this.checkMail(email)) return;
    this.setState({ loading: true, error: null, success: null });
    try {
      await api.post('/dppapi/retrieve/mailcheck', { email: email });
      this.setState({ loading: false });
      window.location = `/dppui/login`;
    } catch (e) {
      this.setState({ loading: false, error: e.data.message });
    }
  }

  handleFocus(event) {
    const { errors } = this.state;
    const { name } = event.target;
    delete errors[name];
    this.clearErrors(errors);
  }

  clearErrors(errors) {
    this.setState({
      errors,
    });
  }

  checkMail(mail) {
    const errors = {};
    //eslint-disable-next-line
    const emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (!emailReg.test(mail)) {
      errors['email'] = {
        content: 'Please enter a valid email.',
        pointing: 'below',
        flag: true,
      };
    }
    this.setState({
      errors: errors,
    });
    return Object.keys(errors).length !== 0;
  }

  render() {
    const { email, loading, error, errors } = this.state;
    return (
      <Segment attached>
        <Header>
          {intl.get('_login.RetrievePage.SendMail')}
          <Icon
            link
            name="close"
            onClick={() => {
              window.location = `/dppui/login`;
            }}
          />
        </Header>
        {Object.keys(errors).length > 0 && (
          <Message
            error
            list={Object.keys(errors).map(i => {
              return errors[i].content;
            })}
          />
        )}
        <Form onSubmit={this.handleEmailCheck}>
          <Form.Input
            onChange={(e, { value }) => this.setState({ email: value })}
            onFocus={this.handleFocus}
            type="text"
            defaultValue={email}
            name="email"
            label={intl.get('_login.RetrievePage.Mail')}
          />
          <Form.Button loading={loading} disabled={loading} secondary={loading}>
            {intl.get('_login.RetrievePage.Send')}
          </Form.Button>
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </Form>
      </Segment>
    );
  }
}

export default withRouter(RetrievePage);
