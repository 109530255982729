import React, { Component } from 'react';
import { Popup, Input, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { LinkOffIcon, CancelIcon } from '../../../assets/icon';
import intl from 'react-intl-universal';
import EditBox from './EditBox';
import _ from 'lodash';
import { formatMoney, ignoreSpecialCharForCalc } from '../../Util';
import { AnalyticsServiceContext } from '../../../common/Analytics/AnalyticsServiceProvider';
import { EventName, EventType, PredictDetailPageLocation, ViewMode } from '../../../common/Analytics/analyticsMetrics';

let isComposition = false;
const isChrome = navigator.userAgent.indexOf('Chrome') > -1;

export default class BoardPopupContent extends Component {

  static contextType = AnalyticsServiceContext;
  constructor(props){
    super(props)
    this.state = {
      clickCount: 0,
      isEditing: {},
      numerical: [],
      isChanging: false,
      selectableInputOffsetTop : 0,
      // detail: _.cloneDeep(props.detail)
    }
    this.input = {};
  }

  componentDidMount(){
    const { detail, onOpenModeChange } = this.props;
    const isEditing = {}
    detail.forEach(d =>{
      isEditing[d.value.id] = false
    })
    onOpenModeChange(true)
    this.setState({isEditing})
  }

  componentWillUnmount(){
    this.props.onOpenModeChange(true)
  }

  componentDidUpdate(prevProps) {
    const { detail, detailItemChanging } = this.props;
    if (prevProps.detailItemChanging !== detailItemChanging && !detailItemChanging) {
      const isEditing = {}
      detail.forEach(d => {
        isEditing[d.value.id] = false
      })
      this.setState({ isEditing })
    }
  }
    
  // prevent double click
  handleClick = (e) => {
    this.setState((prevState) => ({ clickCount: prevState.clickCount + 1 }));

    if (this.state.clickCount === 1) {
      e.stopPropagation()
      this.setState({ clickCount: 0 });
    } else {
      setTimeout(() => {
        this.setState({ clickCount: 0 });
      }, 500); 
    }
  };

  /* eslint no-eval: 0 */
  handleChange = (e, money, r) => {
    //It can be triggered only when the input method is not used or after using the input method
    var convertMoney = money
    var reduceFlag = false
    if(money&&money.startsWith('=')){
      convertMoney = money.substring(1)
      reduceFlag = true
    }
    if (!isComposition) {
      let target = e.target;
      const [convertText, finalPosition, finalSetFlag, headMinusFlag] = formatMoney(target.selectionStart, convertMoney);
      if (headMinusFlag) {
        if (convertText.length > 19 && !convertText.startsWith('=')) return;
      } else {
        if (convertText.length > 18 && !convertText.startsWith('=')) return;
      }
      if (finalSetFlag) {
        this.setState({ numerical: [r.scrumItem, convertText, false] });
      }
      const position = reduceFlag ? finalPosition - 1 : finalPosition;
      this.setState({}, () => {
        target.selectionStart = position;
        target.selectionEnd = position;
      })
    } else {
      this.setState({ numerical: [r.scrumItem, convertMoney, false] });
    }
    this.setState({ isChanging: true});
  }

  handleComposition(e, r) {
    if (e.type === 'compositionend') {
      isComposition = false;
      let data = e.target.value.replace(/[^0-9,-]/g, '');
      if (!isComposition && isChrome) {
        this.handleChange(e, data, r);
      }
    } else {
      isComposition = true;
    }
  }

  onOpenInput = (state, rowValueId) => {

    let isEditing = this.state.isEditing;
    isEditing[rowValueId] = state;
    this.setState({ isEditing: isEditing }, () => {
      if (state && rowValueId) {
        this.handleOpenInputMode(rowValueId);
      }
    })
  }

  handleOpenInputMode = (rowValueId) => {
    const { onOpenModeChange } = this.props;
    this.input[rowValueId].focus();
    this.input[rowValueId].select();
    
    onOpenModeChange(false);
  }

  handleSaveData = (initValue, r) => {
    const {
      onGetSum,
      onUpdateBBox,
      handleChangeValue,
    } = this.props
    this.setState({ isChanging: false });
    let convertText = initValue;
    if (initValue && initValue.startsWith('=')) {
      convertText = convertText.substring(1);
      if (!convertText) {
        this.setState({ numerical: [r.scrumItem, '', true] });
      } else {
        try {
          let editValue = initValue.replace(/,/g, '');
          editValue = ignoreSpecialCharForCalc(editValue)
          convertText = new Intl.NumberFormat('ja-JP', { style: 'decimal', currency: 'CAD', }).format(Math.round(eval(editValue.substring(1))));
          this.setState({ numerical: [r.scrumItem, convertText, false] });
        } catch (error) {
          convertText = intl.get('_predict.detail.mapping.Formula input error');
          this.setState({ numerical: [r.scrumItem, initValue, true]});
          return false;
        }
      }

    }
    if (convertText && convertText.replace('-', '').length > 18) {
      convertText = intl.get('_predict.detail.mapping.More than 14 digits');
      this.setState({ numerical: [r.scrumItem, '', true] });
    }
    const inputText = !this.state.numerical[1] || initValue.startsWith('=') ? convertText : this.state.numerical[1];
    if (convertText === onGetSum([r.value])) {
      this.handleChangeInputMode(false, r.value.id)
    } else {
      this.setState({ numerical: [] });
      if (r.value.invert) {
        const valueObj = r.value
        valueObj.invert = false
        handleChangeValue([valueObj])
      }
      onUpdateBBox(inputText, r.value.id);
    }
    return true;
  }

  handleChangeInputMode = (state, rowValueId) => {
    const isEditing = this.state.isEditing;
    isEditing[rowValueId] = state;
    
    this.setState({ isEditing: isEditing });
  }

  // update bbox item 
  onInputSave = (id) => {
    const { detail } = this.state;
    const { onUpdateBBox, handleChangeValue } = this.props;

    let updatedValueObj = null;
    detail.forEach(item =>{
      if (item.value.id === id){
        updatedValueObj = item.value
      }
    })
    if (updatedValueObj) {
      onUpdateBBox(updatedValueObj.text, id) 
      handleChangeValue([updatedValueObj])
    } 
  }

  renderDetail = (detail, r, idx) => {
    const {
      onGetSum,
      handleUnLinkScrumItem,
      onEdit,
      handleSetOppositeValue,
      selectedCell,
      manualInput,
      onHoverChange,
      keepHover,
      popItemName,
      handleCellOperation,
      onKeepOpen,
      showDetailPop,
      onShowDetailPop,
      showEditBtn,
      onOpenModeChange,
      detailItemChanging,
      isSSPage,
    } = this.props;

    const { numerical, isChanging, isEditing } = this.state;
    const { props } = this;
    const detailItem = detail.map((item, idx) => {
      const clsBottomAnd =
        idx === detail.length - 1
          ? 'un-border-top'
          : 'un-border-top un-border-bottom';
      const clsTopAnd = idx === 0 ? 'un-border-bottom' : clsBottomAnd;
      const clsUnBorder = detail.length > 1 ? clsTopAnd : '';
      let clsBorderSelect = '',
      valuwBorderSelect = '';
      if (selectedCell && !selectedCell.id && selectedCell.cellIds.length > 0) {
        selectedCell.cellIds.forEach(id => {
          if (id === item.value.id) {
            clsBorderSelect = ' board-popup-border-selected';
            valuwBorderSelect = ' board-popup-border-selected-value';
          }
        });
      } else {
        clsBorderSelect = '';
        valuwBorderSelect = ''
      }

      const newItem = _.cloneDeep(item.value);
      newItem.invert = false;
      newItem.text = newItem ? onGetSum([newItem]) : '-';
      newItem.invert = item.value.invert;
      const valueItemId = newItem.id;

      var renderEditBox = (parentElement, cellOperationFunc) => {
        return <EditBox
          rowScrumItem={item.isNewRow || isSSPage || item.item.text === '' ? item.id : item.item.text}
          rowValueId={valueItemId}
          rowValues={[newItem]}
          manualInput={manualInput}
          parentElement={parentElement}
          isDetail={true}
          handleSetOppositeValue={handleSetOppositeValue}
          onEdit={onEdit}
          isEditing={isEditing[valueItemId]}
          onHoverChange={onHoverChange}
          showDetailPop={showDetailPop}
          openable={!_.some(isEditing,Boolean)}
          showEditBtn={showEditBtn}
          onOpenInput={this.onOpenInput}
          onCellOperation={cellOperationFunc}
          {...props}
        />;
      }

      const getPageNum = (item) => {

        const { pdfInfo } = this.props
        let pageNum = ""
        if (item.isNewRow) {
          pageNum = "追加行"
        } else {
          const pageIdx = pdfInfo.images.findIndex(img => img.id === item.pdfImageId)
          if (pageIdx !== -1) {
            pageNum = `p.${pageIdx + 1}`
          } else {
            pageNum = "追加行"
          }
        }
        return pageNum

      }
      let initValue;
      if (!numerical[2] && (numerical[1] === undefined || !isChanging)) {
        initValue = onGetSum([item.value]);
      } else {
        initValue = numerical[1];
      }
      let isHover;
      if (item.isNewRow || isSSPage || item.item.text === '') {
        isHover = popItemName === item.id;
      } else {
        isHover = popItemName === item.item.text;
      }
      return (
        <div
          key={idx}
          className={
            `edit-box-table-tr-popup 
            board-popup-item-wrap${clsBorderSelect} ${clsUnBorder}
            ${keepHover && isHover ? ' tr-backGround-popup' : ''}`
          }
          style={{ width: '250px', marginTop: idx >= 1 ? 2 : 0 }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (e.detail === 1) {
              handleCellOperation(detail, [item]);
            }
            if (e.detail === 2) {
              // if (!this.props.isNotOwner) {
              this.onOpenInput(true, valueItemId);
              // }
            }
          }}
        >
          <div className="board-item-wrap-top">
            <div className="board-item-wrap-top-itemText">{item.item ? item.item.text : ''}</div>
            <div className="board-item-wrap-top-itemPageNum">
              <span>{getPageNum(item)}</span>
            </div>
          </div>
          <div className={`${(!isEditing[item.value.id] || detailItemChanging) ? 'board-item-wrap-bottom' : 'board-item-wrap-bottom-contain'}`}>
            {!isEditing[item.value.id] || detailItemChanging ? (
              <>
                {renderEditBox(
                  <div
                    className={`board-item-wrap-bottom-itemValue${valuwBorderSelect}  
                      ${keepHover && isHover ? 'edit-box-div-border' : ''}`}
                    onMouseEnter={() => {
                      if (!_.some(isEditing, Boolean)) {
                        if (item.isNewRow || isSSPage || item.item.text === '') {
                          onHoverChange(true, item.id);
                        } else {
                          onHoverChange(true, item.item.text);
                        }
                      }
                    }}
                  >
                    {detailItemChanging && isEditing[item.value.id] ?
                      (<Loader active inline size='small' />)
                      :
                      onGetSum([item.value])}

                  </div>,
                  () => {
                    handleCellOperation(detail, [item]);
                  }
                )}
                <div className="btn-unlink-and-plus-minus">
                  <div className="icon-divider" />
                  <Popup
                    disabled={this.props.isNotOwner}
                    trigger={
                      <span
                        id="ga-predict-detail-mapping-cardview-item-unlink"
                        className="board-item-wrap-bottom-icon unlink-icon-style"
                        onClick={() => {
                          if (this.props.isNotOwner) {
                            this.props.onShowExclusionControl(true);
                            return;
                          };
                          if (item.value) {
                            handleUnLinkScrumItem(item.value.id);
                            if (this.context.predictDetailViewMode !== "") {
                              this.context.sendPredictBeautifyDetailMetrics({
                                event_type: EventType.Action,
                                event_name: EventName.UnlinkScrumItem,
                                custom_parameter: {
                                  location: PredictDetailPageLocation.ScrumDetailItem,
                                  view_mode: ViewMode.Card,
                                },
                              })
                            }
                          }
                          if (onKeepOpen) {
                            if (detail.length === 1) {
                              onKeepOpen(false, 'boardPopup');
                              onShowDetailPop && onShowDetailPop(false);
                            } else {
                              onKeepOpen(true, 'boardPopup');
                            }
                          }
                        }}
                        >
                        <LinkOffIcon
                          disabled={this.props.isNotOwner}
                          size={'20px'}
                        />
                      </span>
                    }
                    content={intl.get('_predict.detail.mapping.Cancel cooperation')}
                    position="right center"
                    on="hover"
                    hideOnScroll
                    inverted
                  />
                </div>
              </>
            ) : (
              <>
                <div className="board-item-wrap-bottom">
                  <Input
                    size='mini'
                    className={`fs-detail-td-selectable-input `}
                    value={initValue || ''}
                    placeholder='半角数字入力'
                    fluid
                    ref={(input) => this.input[item.value.id] = input}
                    onChange={(e, data) => {
                      this.handleChange(e, data.value, r);
                    }}
                    onCompositionStart={(e) => { this.handleComposition(e, r) }}
                    onCompositionEnd={(e) => { this.handleComposition(e, r) }}
                    onKeyDown={event => {
                      if (event.keyCode === 13) { // enter key
                        event.stopPropagation();
                        event.preventDefault();
                        this.handleSaveData(initValue, item);
                        onOpenModeChange(true);
                      }
                      if (event.keyCode === 27) { // esc key
                        this.setState({ numerical: [] });
                        onOpenModeChange(true);
                        this.handleChangeInputMode(false, item.value.id);
                      }
                    }}
                    onBlur={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setTimeout(() => {
                        this.setState({ numerical: []});
                        onOpenModeChange(true);
                        // onHoverChange(false);
                        this.handleChangeInputMode(false, item.value.id);
                      }, 10)
                    }}
                  />
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.setState({ numerical: []});
                      onOpenModeChange(true);
                      this.handleChangeInputMode(false, item.value.id);
                    }}
                  >
                    <CancelIcon color='#909090' size={'24px'} className='edit-box-icon-cancel' />
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      );
    });
    return <React.Fragment key={idx}>{detailItem}</React.Fragment>;
  };

  render() {
    const { row, idx, detail } = this.props;

    return <div onClick={(e) => this.handleClick(e)}>{this.renderDetail(detail, row, idx)}</div>;
  }
}

BoardPopupContent.propTypes = {
  detail: PropTypes.array.isRequired,
  row: PropTypes.object.isRequired,
  selectedCell: PropTypes.object.isRequired,
  idx: PropTypes.string,
  onGetSum: PropTypes.func,
  handleUnLinkScrumItem: PropTypes.func,
  onEdit: PropTypes.func,
  handleSetOppositeValue: PropTypes.func,
  handleCellOperation: PropTypes.func,
};

BoardPopupContent.defaultProps = {
  onGetSum: () => {},
  handleUnLinkScrumItem: () => {},
  onEdit: () => {},
  handleSetOppositeValue: () => {},
  handleCellOperation: () => {},
};
