import React, { Component } from 'react';
import { parser } from '../../Util';
import { Input, Select, Popup } from 'semantic-ui-react';
import api from '../../../api'
import _ from 'lodash'
import update from 'immutability-helper'
export default class PdfThumbnail extends Component {
  constructor(props) {
    super(props);
    this.state = { imageList: [], searchText: "", searchResult: [], options: [{ key: '0', text: '', value: '0' }], isOpen: false }
    this.serachRef = React.createRef()
    this.handleChange = this.handleChange.bind(this);
    this.findTargetImageByPriority = this.findTargetImageByPriority.bind(this);
  }

  async componentDidMount() {
    const { projectId, pdfFileId, onPageChange, getMinPdfImageId } = this.props;

    const projectInfo = await api.request({
      url: `/dppapi/predict/project/${projectId}`,
      method: 'GET'
    })
    const options = [];
    projectInfo.data.classOptions.forEach((e, index) => {
      options.push({ key: index, text: e, value: e });
    })
    this.setState({ options: options });

    const data = await api.request({
      url: `/dppapi/predict/pdf/image/preview/${projectId}?pdfFileId=${pdfFileId}`,
      method: 'GET'
    })
    const result = await parser(data)
    let minPdfImageId = 0
    var imageList = [];
    let ps = []
    const templateMap = {}
    if(this.props.textMappingRef&&this.props.textMappingRef.current.state.pdfInfo&&this.props.textMappingRef.current.state.pdfInfo.images){
      this.props.textMappingRef.current.state.pdfInfo.images.forEach(d=>{
        templateMap[d.id]=d.imageTemplate
      })
    }
    result.img.forEach(r => {
      ps.push(new Promise((resolve => {
        if (minPdfImageId === 0 || Number(r.pageId) < minPdfImageId) {
          minPdfImageId = Number(r.pageId)
        }
        if(r.pageId in templateMap){
          r.imageTemplate = templateMap[r.pageId]
        }
        api.get(`/dppapi/predict/pdf/image/pageClass/${projectId}?pageId=${r.pageId}`).then(({ data }) => {
          r.pageClassification = data
          imageList.push(r);
          resolve(data)
        })
      })))
    })

    Promise.all(ps).then(rst => {
      getMinPdfImageId(Number(minPdfImageId))
      
      imageList.sort(function (a, b) {
        return (Number(a.pageId) < Number(b.pageId)) ? -1 : 1;
      })
      
      //page番号表示
      imageList.forEach((img, idx) => {
        img['pageNo'] = idx + 1;
      })
      onPageChange(Number(this.findTargetImageByPriority(imageList).pageId))
      this.setState({ imageList, minPdfImageId })
    })

  }

  findTargetImageByPriority(imageList) {
    const PRIORITY_MAP = {
      '貸借対照表': 0,
      '損益計算書': 1,
      '株主資本等変動計算書': 2
    }
    if (imageList && imageList.length > 0) {
      return _.sortBy(imageList.map(image => {
        return {
          ...image,
          priority: typeof PRIORITY_MAP[image.pageClassification] === 'undefined' ? 9 : PRIORITY_MAP[image.pageClassification]
        }
      }), image => image.priority)[0]
    } else {
      return {
        pageId: '1',
      }
    }
  }

  async handleChange(pageId, value) {
    const { projectId, pdfFileId } = this.props;
    await api.post(`/dppapi/predict/pdf/image/pageClass/${projectId}`, {
      id: pageId,
      pageClassification: value,
      pdfFileId: pdfFileId
    });
    const { imageList } = this.state
    const idx = imageList.findIndex(img => Number(img.pageId) === Number(pageId))

    this.setState({
      imageList: update(imageList, {
        [idx]: {
          $set: {
            ...imageList[idx],
            pageClassification: value
          }
        }
      }),
    });
  }

  imgPanel = (img) => {
    const { onPageChange, currentPage } = this.props;
    const { options } = this.state;

    return (<div
      key={`mini_${img.pageId}`}
      className={`old-pdf-thumbnail-root-img-panel`}
      ref={ ref => {
        if (Number(currentPage) === Number(img.pageId) && ref) {
          setTimeout(()=>{ref.scrollIntoView({block: 'center', behavior: 'smooth'})}, 0)
        }
      }}
      onClick={() => {
        onPageChange(img.pageId)
        //change page type on textMappingTable component
        const idx = this.state.imageList.findIndex(i => Number(i.pageId) === Number(img.pageId))
        if (idx !== -1) {
          const pageType = this.state.imageList[idx].pageClassification
          this.props.textMappingRef &&
            this.props.textMappingRef.current &&
            this.props.textMappingRef.current.handlePageTypeChange(pageType, true)
        }
      }}>
      <div className={` old-pdf-thumbnail-root-img-border ${Number(currentPage) === Number(img.pageId) ? 'pdf-thumbnail-root-img-border-current' : null}`}>
        <div className="old-pdf-thumbnail-root-img-group" >
          <img alt="pdfThumbnail" src={img.url} width='160px' height='200px'></img>
          <div className="old-pdf-thumbnail-root-img-tip">{img.pageNo ? img.pageNo : img.pageId}</div>
          <div className="old-pdf-thumbnail-root-img-template-tip">{img.imageTemplate}</div>
        </div>
      </div>
      <div className="old-pdf-thumbnail-root-img-footer">
        <Select
          options={options}
          defaultValue={img.pageClassification}
          className="old-pdf-thumbnail-root-img-select"
          onChange={(e, change) => {
            this.handleChange(img.pageId, change.value)
            this.props.textMappingRef &&
              this.props.textMappingRef.current &&
              this.props.textMappingRef.current.handlePageTypeChange(change.value, true)
          }}
        />
      </div>
    </div>)
  }
  handleEnter = async e => {
    if (e.keyCode === 13) {
      const { projectId, pdfFileId } = this.props
      const { searchText } = this.state
      try {
        if (searchText === "") {
          setTimeout(() => {
            this.setState({ isOpen: false })
          }, 2000)
          this.setState({ isOpen: true, searchResult: [] })
          return
        }
        const { data } = await api.request({
          url: `/dppapi/predict/pdf/search/brief/${projectId}/${pdfFileId}?keyword=${searchText}`,
          method: 'GET'
        })
        if (data.success) {
          setTimeout(() => {
            this.setState({ isOpen: false })
          }, 2000)
          data.result.length === 0 && this.setState({ isOpen: true })
          this.setState({ searchResult: data.result })
        }

      } catch (e) {
        console.log("api error")
      }
    }
  }

  handleSearchTextChange = text => {
    text === "" && this.setState({ searchResult: [] })
    this.setState({ searchText: text })
    this.setState({ isOpen: false })
  }
  handleClear = () => {
    this.setState({ searchText: "", searchResult: [] })
    this.setState({ isOpen: false })
  }

  // this method is for page-type-change on textMapping component
  handlePageTypeChange = (pageType) => {
    const { imageList } = this.state
    const idx = imageList.findIndex(img => img.pageClassification === pageType)

    if (idx !== -1) {
      const pageId = imageList[idx].pageId
      this.props.onPageChange(Number(pageId))
    }
  }

  render() {
    const { searchText, imageList, searchResult } = this.state
    const { showThumbnail } = this.props
    let fliteredImageList = _.cloneDeep(imageList)
    if (searchResult.length > 0) {
      fliteredImageList = imageList.filter(i => searchResult.indexOf(Number(i.pageId)) !== -1)
    }

    return (
      <div className={`old-pdf-thumbnail-root ${showThumbnail ? "" : "old-pdf-thumbnail-root-hidden"}`}>
        <Popup
          trigger={<Input
            action={{ icon: 'remove', onClick: () => this.handleClear(), disabled: searchText.length === 0 }}
            placeholder='Search...'
            fluid size="mini"
            className="old-pdf-thumbnail-root-search"
            value={searchText}
            onChange={(e, data) => this.handleSearchTextChange(data.value)}
            onKeyDown={(e) => this.handleEnter(e)} />}
          content={`検索してヒットしてる結果が見つかりませんでした。`}
          open={this.state.isOpen}
        />

        {fliteredImageList.map((img) => this.imgPanel(img))}
      </div >
    );
  }
}
