import React, { Component, Fragment } from "react";
import { Marker } from 'react-leaflet';
import L from 'leaflet'
import './BBoxIcon.css'
// import { Icon } from 'semantic-ui-react'

export default class BBoxIcon extends Component {

  render() {
    // -------- Define displayed icons --------
    const icons = {
      formatWarning: {
        show: false,
        html: L.divIcon({ html: `<i aria-hidden="true" style="color:#F6511D;font-size: 1.2em;" class="question icon"></i>` })
      },
      lowAccWarning: {
        show: false,
        html: L.divIcon({ html: `<i aria-hidden="true" style="color:orange;font-size: 1.2em;" class="question icon"></i>` })
      }
    }
    // -------- Define displayed icons --------

    const { figure } = this.props;
    const { points, stateFlag, accuracy, accThreshold } = figure;

    // -------- Add icon's flag --------
    // const detailData = this.props.textMappingRef.current.state.detailData;
    // let isLinked = false

    // check is warning
    icons.formatWarning.show = stateFlag === 5 ? true : false;

    // check low accuray
    if (accuracy && accThreshold && accuracy.length > 0 && figure.det_class === 'value') {
      const lowAcc = Math.min(...((Array.isArray(accuracy) ? accuracy : []).map(a => parseFloat(a))));
      icons.lowAccWarning.show = lowAcc < parseFloat(accThreshold);
    }
    
    return (<Fragment key={figure.id} >
      {
        Object.keys(icons).filter(key => icons[key].show).map((key, index) => (<Marker key={index} position={[points[1]['lat'] + 8, points[1]['lng'] - 20 * index - 4]} icon={icons[key].html} />))
      }
    </Fragment >)
  }

}