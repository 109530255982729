import React from 'react'
import { MDBDataTable } from 'mdbreact'
import { Loader, Button, Segment, Icon, Header } from 'semantic-ui-react'
import { confirmAlert } from 'react-confirm-alert';
import api from '../../api'
import intl from 'react-intl-universal';
import BarChart from '../BarChart';
import Sunburst from '../Sunburst';

export default class Distribution extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      isLoaded: false,
      rows: [],
      labeledRows: [],
      allRows: [],
      bboxRows: [],
      polygonRows: []
    };
    this.init = this.init.bind(this);
    this.load = this.load.bind(this);
    this.refetch = this.refetch.bind(this);
  }

  async componentDidMount() {
    await this.init();
  }

  async init() {
    this.setState({ isLoaded: false });
    const project = (await api.get(
      `/dppapi/labeling/${this.props.projectId}/project`
    )).data;
    let rows = [];
    if (project && project.distribution) {
      rows = this.getRows(JSON.parse(project.distribution));
      this.handleChartRows(rows);
    }
    this.setState({
      isLoaded: true,
      rows,
    });
  }

  handleChartRows = (rows) => {
    var labeledRows = [];
    labeledRows.push(
      {
        "idx": "Labeled",
        "name": "Labeled",
        "size": 0,
        "parent": ""
      }
    )
    var allRows = [];
    allRows.push(
      {
        "idx": "All",
        "name": "All",
        "size": 0,
        "parent": ""
      }
    )
    var bboxRows = [];
    var labelName = [];
    var polygonRows =[];
    rows && rows.forEach(row => {
      if (row.labelType !== "bbox" && row.labelType !== 'polygon') {
        const parentName = row.labelName.substr(0, row.labelName.indexOf('|'));
        const childName = row.labelName.substr(row.labelName.indexOf('|') + 1);
        if (labelName.findIndex(name => name === parentName) === -1) {
          const labeled_parent = {
            idx: parentName,
            name: parentName,
            size: 0,
            parent: 'Labeled',
            value: ''
          }
          if (row.labeled && row.labeled !== 0) {
            labeledRows.push(labeled_parent)
          }
          const all_parent = {
            idx: parentName,
            name: parentName,
            size: 0,
            parent: 'All',
            value: ''
          }
          allRows.push(all_parent)
        }
        const labeled_child = {
          name: childName,
          size: row.labeled || 0,
          parent: parentName,
          value: row.labeled
        }
        const all_child = { 
          name: childName,
          size: row.all || 0,
          parent: parentName,
          value: row.all
        }
        if (row.labeled && row.labeled !== 0) {
          labeledRows.push(labeled_child)
        }
        allRows.push(all_child)
        labelName.push(parentName);
      } else if (row.labelType === 'polygon') {
        if (row.all && row.all !== 0) {
          const polygonRow = {
            type: row.labelName,
            all: row.all,
            labeled: row.labeled || 0
          }
          polygonRows.push(polygonRow)
        }
      } else {
        if (row.all && row.all !== 0) {
          const bboxRow = {
            type: row.labelName,
            all: row.all,
            labeled: row.labeled || 0
          }
          bboxRows.push(bboxRow)
        }  
      }
    })
    labeledRows.forEach(lr=>{
      var parentSize = 0;
      if (lr.idx) {
        labeledRows.forEach(tmp => {
          if (tmp.parent === lr.name) {
            parentSize = tmp.size + parentSize
            lr.value = parentSize
          }
        })
      }
    })
    allRows.forEach(ar=>{
      var parentSize = 0;
      if (ar.idx) {
        allRows.forEach(tmp => {
          if (tmp.parent === ar.name) {
            parentSize = tmp.size + parentSize
            ar.value = parentSize
          }
        })
      }
    })
    this.setState({
      labeledRows,
      allRows,
      bboxRows,
      polygonRows
    })
  }

  async load() {
    this.setState({ isLoaded: false });
    const resp = (await api.get(
      `/dppapi/labeling/${this.props.projectId}/distribution`
    )).data;
    let rows = [];
    if (resp.success) {
      rows = this.getRows(resp.results);
      this.handleChartRows(rows);
    }
    this.setState({
      isLoaded: true,
      rows,
    });
  }

  getRows(distribution) {
    return distribution.length > 0 && Object.keys(distribution[0]).map(label => {
      return { 
        'labelName': label,
        'labelType': distribution[0][label], 
        'labeled': distribution[1][label], 
        'all': distribution[2][label]
      }
    });
  }

  async refetch() {
    confirmAlert({
      title: intl.get('_common.Confirm'),
      message: intl.get('_label.distribution.index.Message'),
      buttons: [
        {
          label: intl.get('_label.distribution.index.Refetch'),
          onClick: async ()=>{await this.load();}
        },
        {
          label: intl.get('_common.Cancel'),
        },
      ],
    })
  }

  render() {
    const  { rows, error, isLoaded, labeledRows, allRows, bboxRows, polygonRows } = this.state
    const columns = [{
      label: intl.get('_label.distribution.index.labelName'),
      field: 'labelName',
    },
    {
      label: intl.get('_label.distribution.index.labelType'),
      field: 'labelType',
    },
    {
      label: intl.get('_label.distribution.index.labeled'),
      field: 'labeled',
    },
    {
      label: intl.get('_label.distribution.index.all'),
      field: 'all',
    }];
    const data = {
      columns: columns,
      rows: rows
    }

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <Loader active inline="centered" />;
    }

    return (
      <Segment>
        <Button.Group floated='right'>
          <Button icon onClick={this.refetch}>
            <Icon name='refresh' />
          </Button>
        </Button.Group>
        <MDBDataTable
          theadColor="common-search-table-header"
          btn
          striped
          bordered
          hover
          data={data}
        />
        { bboxRows.length > 0 && <BarChart
          data = {bboxRows}
          className = {'bbox'}
        /> }
        { polygonRows.length > 0 && <BarChart
          data = {polygonRows}
          className = {'polygon'}
        /> }
        { (labeledRows.length > 1 || allRows.length > 1) && <div style={{ width: 'auto', height: '50px' }}>
          <Header as='h2'>select&select-one&text</Header>
        </div> }
        <div style={{display: 'flex'}}>
          { labeledRows.length > 1 && <Sunburst
            data = {labeledRows}
            className = 'Labeled'
            marginLeft = '0px'
          /> }
          { allRows.length > 1 && <Sunburst
            data = {allRows}
            className = 'All'
            marginLeft = '100px'
          /> }
        </div>
      </Segment>
    )
  }
}
