import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';

import { SCRUM_ITEM_SUFFIX } from '../../Constant';
import { ExclamationTriangleIcon, CheckCircleIcon } from '../../../assets/icon';

import EditBox from './EditBox';
import BoardItem from './BoardItem';

export default class BoardTotalItem extends Component {
  constructor(props) {
    super(props);
    this.itemRef = React.createRef();
    this.state = {
      keepHover: false,
    };
    this.clickCount = 0;
    this.singleClickTimer = '';
  }

  handleSingleClick = () => {
    const { handleCellOperation, row, mappingDetail } = this.props;
    handleCellOperation(row, mappingDetail(row.scrumItem));
  }

  handleDoubleClick = () => {
    this.itemRef &&
      this.itemRef.current &&
      this.itemRef.current.onOpenInput(true);
  }

  handleClicks() {
    this.clickCount++;
    if (this.clickCount === 1) {
      this.singleClickTimer = setTimeout(function () {
        this.clickCount = 0;
        this.handleSingleClick();
      }.bind(this), 300);
    } else if (this.clickCount === 2) {
      clearTimeout(this.singleClickTimer);
      this.clickCount = 0;
      this.handleDoubleClick();
    }
  }

  onHoverChange = (state, name) => {
    this.setState({ keepHover: state, popItemName: name ? name : 'EditBox' });
  }

  handleCheck = (key, values) => {
    const { allList } = this.props;

    let err = false;
    let boardItem = null;
    let diff = 0;

    const isAggreation = key.indexOf(SCRUM_ITEM_SUFFIX.AGGREGATION) !== -1;
    const isInput = key.indexOf(SCRUM_ITEM_SUFFIX.INPUT) !== -1;

    allList.forEach(item => {
      const aggName = item.name + SCRUM_ITEM_SUFFIX.AGGREGATION;
      if (item.name === key || aggName === key) {
        if (item.isError === true) {
          err = true;
          diff = new Intl.NumberFormat('ja-JP', { style: 'decimal', currency: 'CAD', }).format(item.calcVal - item.rawVal);
          diff = item.calcVal - item.rawVal > 0 ? '+' + diff : diff;
        }
        boardItem = item;
      }
    });

    let isManual = false;
    if (values && values.length > 0 && !err) {
      if (values[0].text === '0' || values[0].text === '0') {
        isManual = true;
      }
    }

    return { err, boardItem: boardItem, isAggreation, isInput, isManual, diff };
  };

  renderAggreationItem = (checkCls, val, { isManual, err, boardItem }, isAggreation, diff) => {
    const { onGetSum } = this.props;
    const { props } = this;
    const keepHover = this.state.keepHover;

    const clsTotalRight = `total-right-icon-${checkCls}`;
    const checkTitle = err ? (
      <Popup
        className="board-popup-exclamation-triangle"
        trigger={
          <span className='board-icon-exclamation-triangle'>
            <ExclamationTriangleIcon size='18px' />
          </span>
        }
        offset={[-11, -3]}
        content={diff}
        on='hover'
      />
    ) : (
      <span className='board-icon-check-circle'>
        <CheckCircleIcon size='18px' />
      </span>
    );

    const specialStyles = { left: '0px', bottom: '163px', top: 'auto' };
  
    var renderEditBox = (parentElement) => {
      return <EditBox
        rowScrumItem={val.scrumItem}
        rowValues={val.values}
        manualInput={val.manualInput}
        isAggreation={isAggreation}
        showEditBtn={!isAggreation}
        parentElement={parentElement}
        popupItem={boardItem}
        specialClass={'edit-box-board-total-div'}
        onHoverChange={this.onHoverChange}
        specialStyles={specialStyles}
        boxOffSet={[-25, -8]}
        {...props}
      />;
    }

    return (
      <div className="board-item-total-wrap">
        <div className="board-item-total-left">
          <span className="board-item-total-left-text">{val.scrumItem}</span>
          {renderEditBox(
            <div className={`board-item-total-left-value ${keepHover ? 'edit-box-div-letter' : ''}`}>
              {onGetSum(val.values) || '-'}
            </div>
          )}
        </div>
        <div className={`board-item-total-right-icon ${clsTotalRight}`}>
          {checkTitle}
        </div>
      </div>
    );
  };

  getCellIds = values => {
    return values.map(v => v.id);
  };

  renderInputItem = (checkCls, val) => {
    const {
      isPortalOpen,
      mappingDetail,
      handleCellOperation,
      onGetSum,
      onUpdateBBox,
      handleUnLinkScrumItem,
      detailData,
      currentPageType,
      onInputModeChange,
      onEdit,
      handleSetOppositeValue,
      handleInputChange,
      selectedCell,
      onPopupOpen,
      popups,
      isCancel,
      handleCellAction,
      onOpenModeChange,
      openable,
      onSetFilterItems,
      handleChangeValue,
      detailItemChanging,
      pdfInfo,
      currentBoxIdForCss,
      isNotOwner,
      onShowExclusionControl,
    } = this.props;
    const detail = mappingDetail(val.scrumItem);
    const isDisabled = !val.values || val.values.length <= 0 || isPortalOpen;
    const isPopupVisible =
      this.itemRef && this.itemRef.current && this.itemRef.current.state && this.itemRef.current.state.keepOpen;
    const pointCss = val.isRefer ? 'unhoverRow ' : 'edit-box-table-tr ';
    let classNameFlag = false
    const isAggreation = val.scrumItem && val.scrumItem.indexOf(SCRUM_ITEM_SUFFIX.AGGREGATION) !== -1;
    if (
        (currentBoxIdForCss && this.getCellIds(val.values).indexOf(currentBoxIdForCss) !== -1)
    ) {
      classNameFlag = true
    }
    if (!isAggreation && ((val.id === selectedCell.id && 'value' === selectedCell.column) || isPopupVisible)) {
      classNameFlag = true
    }

    const backGroundColor =
      selectedCell.id === val.id || isPopupVisible || classNameFlag ? 'board-item-backGround style-scroll-board-selected' : '';
    const clsBg = `${pointCss} board-item-wrap total-board-item-${checkCls} ${backGroundColor}`;
    return (
      <div
      id={`${val.scrumItem}-list-board-total`}
      className={
        // isDisabled
        // ? `${clsBg} total-board-item-disabled-${checkCls}`
        // : 
        clsBg
      }
      onClick={(e) => {
        if(val.isRefer) return;
        handleCellAction('total');
        this.handleClicks();
        onSetFilterItems([]);
      }}  
    >
      <BoardItem
        row={val}
        isPortalOpen={isPortalOpen}
        onGetSum={onGetSum}
        detailData={detailData}
        currentPageType={currentPageType}
        onInputModeChange={onInputModeChange}
        onEdit={onEdit}
        handleInputChange={handleInputChange}
        isDisabledItem={isDisabled}
        ref={this.itemRef}
        onPopupOpen={onPopupOpen}
        popups={popups}
        handleSetOppositeValue={handleSetOppositeValue}
        handleCellOperation={handleCellOperation}
        onSelected={this.onSelected}
        isCancel={isCancel}
        handleCellAction={handleCellAction}
        handleSearchReferItem={this.props.handleSearchReferItem}
        onOpenModeChange={onOpenModeChange}
        openable={openable}
        onUpdateBBox={onUpdateBBox}
        handleUnLinkScrumItem={handleUnLinkScrumItem}
        filterItems={this.props.filterItems}
        selectedCell={selectedCell}
        pdfInfo={pdfInfo}
        handleChangeValue={handleChangeValue}
        detailItemChanging={detailItemChanging}
        detail={detail}
        isTotal={true}
        isNotOwner={isNotOwner}
        onShowExclusionControl={onShowExclusionControl}
      />
    </div>
    );
  };

  render() {
    const { row, rowSecond, type } = this.props;

    let value = row;
    if (rowSecond) value = rowSecond;
    const checkResult = this.handleCheck(value.scrumItem, value.values);
    const { err, isManual, isAggreation, diff } = checkResult;
    const checkCls = err ? 'error' : isManual ? 'manual' : 'success';

    return type === 'aggregation'
      ? this.renderAggreationItem(checkCls, row, checkResult, isAggreation, diff)
      : this.renderInputItem(checkCls, row);
  }
}

BoardTotalItem.propTypes = {
  row: PropTypes.object.isRequired,
  rowSecond: PropTypes.object,
  type: PropTypes.string.isRequired,
  errorList: PropTypes.array.isRequired,
  isPortalOpen: PropTypes.bool.isRequired,
  detailData: PropTypes.object.isRequired,
  currentPageType: PropTypes.string.isRequired,
  selectedCell: PropTypes.object.isRequired,
  onGetSum: PropTypes.func,
  mappingDetail: PropTypes.func,
  handleCellOperation: PropTypes.func,
  onInputModeChange: PropTypes.func,
  onEdit: PropTypes.func,
  handleUnLinkScrumItem: PropTypes.func,
  handleSetOppositeValue: PropTypes.func,
  handleInputChange: PropTypes.func,
};

BoardTotalItem.defaultProps = {
  rowSecond: null,
  onGetSum: () => {},
  mappingDetail: () => {},
  handleCellOperation: () => {},
  onInputModeChange: () => {},
  onEdit: () => {},
  handleUnLinkScrumItem: () => {},
  handleSetOppositeValue: () => {},
  handleInputChange: () => {},
};
