import React, { Component } from 'react';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import { Button, Icon, Segment, Loader, Checkbox, Popup } from 'semantic-ui-react';
import api from '../api';
import ProjectAdd from './ProjectAdd'
import intl from 'react-intl-universal';
import { withRouter } from "react-router-dom";
import _ from 'lodash';

class ProjectTable extends Component {
  state = {
    rows: [],
    isShowEdit: false,
    isLoading: false,
    selectList: [],
    columns: [],
  };

  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
    this.getProjects = this.getProjects.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  async componentDidMount() {
    let columns = [
      {
        label: intl.get('_project.ProjectManagementTable.ID'),
        field: 'id',
        sort: 'asc',
      },
      {
        label: intl.get('_project.ProjectManagementTable.Project'),
        field: 'projectName',
        sort: 'asc',
      },
      {
        label: intl.get('_project.ProjectManagementTable.Overview'),
        field: 'overview',
        sort: 'asc',
      },
    ];

    const checkBoxColumn = {
      label: (<>
        <Popup
          trigger={
            <Checkbox key="all" id="all" indeterminate={false} onChange={(e, value) => this.handleAllCheckBox(e, value)} />
          }
          position="top center"
          content={'表示中のファイルを一括選択/選択解除'}
          on="hover"
          wide='very'
          hideOnScroll
          inverted
        />
      </>),
      field: 'checkbox',
      sort: 'disabled'
    }
    if (this.props.isSystemManager) {
      columns = _.concat([checkBoxColumn], columns);
    }
    this.setState({ columns });
    if (!this.props.isSystemManager) {
      await this.getProjects();
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.tenantId !== this.props.tenantId) {
      await this.getProjects();
    }
  }

  handleAdd() {
    this.toggleEdit({isShowEdit: true})
  }

  async handleCheckBox(e, target) {
    this.setRowRef(target.checked, target.id);
    const { selectList, rows } = this.state;
    let selected = _.cloneDeep(selectList);
    let dataRows = _.cloneDeep(rows);
    if(target.checked){
      if(!selected.includes(target.id)){
        selected.push(target.id);
        dataRows.forEach(e=>{
          if(e.id === target.id){
            e.checkbox = (<Checkbox key={e.id} id={e.id} checked={true} onChange={(e, value) => this.handleCheckBox(e, value)}/>);
          }
        })
      }
    }else{
      selected = selected.filter(e=>e!==target.id)
      dataRows.forEach(e=>{
        if(e.id === target.id){
          e.checkbox = (<Checkbox key={e.id} id={e.id} checked={false} onChange={(e, value) => this.handleCheckBox(e, value)}/>);
        }
      })
    }
    this.setState({
      selectList: selected,
      rows: dataRows,
    },()=>{
      let tableState = this.tableRef.current.state;
      this.changeTitle(false);
      this.tableRef.current.setState({
        activePage: tableState.activePage
      })
    })
  }

  changeTitle = (select,indeterminate=false) => {
    let tableState = _.cloneDeep(this.tableRef.current.state);
    const columns = tableState.columns;
    columns[0].label = (<>
      <Popup
        trigger={
          <Checkbox key="all" id="all" indeterminate={indeterminate} checked={select} onChange={(e, value) => this.handleAllCheckBox(e, value)} />
        }
        position="top center"
        content={'表示中のファイルを一括選択/選択解除'}
        on="hover"
        wide='very'
        hideOnScroll
        inverted
      />
    </>)
    this.tableRef.current.setState({
      columns: columns
    })
  }

  async handleAllCheckBox(e, target) {
    const tableState = this.tableRef.current.state;
    const currentPageIdList = tableState.pages[tableState.activePage].map(e=>e.id);
    const { selectList, rows } = this.state;
    let selected = _.cloneDeep(selectList);
    // select all, add current page data to list
    if(target.checked){
      currentPageIdList.forEach(id=>{
        if(!selected.includes(id)){
          this.setRowRef(true, id);
          selected.push(id);
        }
      })
    }else{
      selected = selected.filter(e => !currentPageIdList.includes(e))
      currentPageIdList.forEach(id => {
        if (!selected.includes(id)) {
          this.setRowRef(false, id);
        }
      })
    }
    let dataRows = _.cloneDeep(rows);
    dataRows.forEach(e=>{
      e.checkbox = (<Checkbox key={e.id} id={e.id} checked={selected.includes(e.id)} onChange={(e, value) => this.handleCheckBox(e, value)}/>);
    })
    this.setState({
      selectList: selected,
      rows: dataRows,
    },()=>{
      this.changeTitle(target.checked);
      this.tableRef.current.setState({
        activePage: tableState.activePage
      })
    })

  }

  async setRowRef(isChecked, id) {
    const prevRowsRef = this.props.selectedRowsRef.current;
    if (isChecked) {
      this.props.selectedRowsRef.current = [...prevRowsRef, id];
    } else {
      this.props.selectedRowsRef.current = prevRowsRef.filter(row => row !== id);
    }
  }

  sortRows = (e) => {
    if (this.tableRef && this.tableRef.current && this.tableRef.current.state) {
      this.setState({}, () => {
        let tableState = _.cloneDeep(this.tableRef.current.state);
        this.changeTitle(false)
        const columns = tableState.columns;
        let { rows } = this.state;
        columns.forEach(item => {
          if (item.field === e.column) {
            item.direction = e.direction
            let filedName;
            if (item.field === 'name') {
              filedName = item.field;
            } else if (item.field === 'projectName') {
              filedName = "projectNameText";
            } else if (item.field === 'overview') {
              filedName = "overviewText";
            }
            if (item.sort === 'asc') {
              rows.sort(function (a, b) { return !a[filedName] && a[filedName] !== 0 && b[filedName] ? 1 : a[filedName] && !b[filedName] && b[filedName] !== 0 ? -1 : a[filedName] < b[filedName] ? 1 : -1; });
            } else {
              rows.sort(function (a, b) { return !a[filedName] && a[filedName] !== 0 && b[filedName] ? -1 : a[filedName] && !b[filedName] && b[filedName] !== 0 ? 1 : a[filedName] > b[filedName] ? 1 : -1; });
            }
          }
        })
        this.tableRef.current.setState({
          columns: columns
        })
        this.setState({ rows: rows })
      })
    }
  }

  async handlePageChange(e) {
    const { isSystemManager } = this.props;
    if (isSystemManager) {
      this.changeTitle(false);
    }
  }

  async getProjects() {
    const { tenantId, isSystemManager } = this.props;
    let projects;
    let linkUrl;
    if (isSystemManager) {
      projects = (await api.get(`/dppapi/system/tenant-manage/projects?tenantId=${tenantId}`)).data;
      linkUrl = `/system/tenant/project/${tenantId}/`;
    } else {
      projects = (await api.get('/dppapi/admin/projects')).data;
      linkUrl = '/project/';
    }
    this.setState({
      rows: projects
        .map((p) => {
          const { project } = p;
          let row = {
            id: project.id,
            projectName: (
              <Link key={project.id} to={`${linkUrl}${project.id}`}>
                <div
                  style={{
                    width: 200,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <u>{project.name}</u>
                </div>
              </Link>
            ),
            name: project.name,
            overview:(
              <div>{project.description}</div>
            ),
            projectNameText: project.name,
            overviewText: project.description,
          };

          if (isSystemManager) {
            row.checkbox = (<Checkbox key={project.id} id={project.id} onChange={(e, value) => this.handleCheckBox(e, value)}/>);
          }

          return row;
        }),
    });
  }

  toggleEdit({ isShowEdit }) {
    this.setState({
      isShowEdit: isShowEdit,
    });
  }

  render() {
    const { rows, isShowEdit, isLoading, columns } = this.state;
    const { isSystemManager } = this.props;
    const data = {
      columns: columns,
      rows: rows,
    };
    
    if (isLoading) {
      return (<Segment><Loader active inline="centered" /></Segment>)
    }
    return (
      <div>
        <Segment>
          {!isSystemManager &&
            <Button.Group floated="right">
              <Button icon onClick={this.handleAdd}>
                <Icon name="add" />
              </Button>
            </Button.Group>
          }
          <MDBDataTable
            theadColor={
              `common-search-table-header ${isSystemManager ? "project-management-table-thead-setting" : "project-management-table-thead"}`
            }
            tbodyColor={
              `${isSystemManager ? "project-management-table-tbody-setting" : "project-management-table-tbody"}`
            }
            btn
            striped
            bordered
            hover
            data={data}
            onPageChange={this.handlePageChange}
            onSort={(e, value) => this.sortRows(e, value)}
            ref={this.tableRef}
          />
          <ProjectAdd
            isShowEdit={ isShowEdit }
            toggleEdit={this.toggleEdit}
            successCallback={this.getProjects}
          />
        </Segment>
      </div>
    );
  }
}

export default withRouter(ProjectTable);
