import React, { Component } from 'react';
import BoardItem from './BoardItem';
import PropTypes from 'prop-types';
import { AnalyticsServiceContext } from '../../../common/Analytics/AnalyticsServiceProvider';
import { EventName, EventType, FixAmountSource, PredictDetailPageLocation, ViewMode } from '../../../common/Analytics/analyticsMetrics';
import { SCRUM_ITEM_SUFFIX } from '../../Constant';

export default class BoardList extends Component {
  constructor(props) {
    super(props);
    this.itemRef = React.createRef();
    this.clickCount = 0;
    this.singleClickTimer = '';
    this.state = {
      calcInputError: false,
      calcInputHint: false,
    }
  }
  static contextType = AnalyticsServiceContext;

  handleSingleClick = () => {
    const { handleCellOperation, row, mappingDetail } = this.props;
    handleCellOperation(row, mappingDetail(row.scrumItem));
  }

  handleDoubleClick = () => {
    // if (this.props.isNotOwner) return;
    this.itemRef &&
      this.itemRef.current &&
      this.itemRef.current.onOpenInput(true);
  }

  handleClicks() {
    this.clickCount++;
    if (this.clickCount === 1) {
      this.singleClickTimer = setTimeout(function () {
        this.clickCount = 0;
        this.handleSingleClick();
      }.bind(this), 300);
    } else if (this.clickCount === 2) {
      clearTimeout(this.singleClickTimer);
      this.clickCount = 0;
      this.handleDoubleClick();
    }
  }
  getCellIds = values => {
    return values.map(v => v.id);
  };

  changeCalcInputError = (val) => {
    this.setState({
      calcInputError: val
    })
  }
  changeCalcInputHint = (val) => {
    this.setState({
      calcInputHint: val
    })
  }
  renderPopupList = row => {
    const {
      isPortalOpen,
      handleCellOperation,
      onGetSum,
      onUpdateBBox,
      detailData,
      currentPageType,
      onInputModeChange,
      onEdit,
      handleUnLinkScrumItem,
      handleSetOppositeValue,
      mappingDetail,
      handleInputChange,
      selectedCell,
      handleTextChange,
      selectedBBox,
      popups,
      openable,
      isCancel,
      handleCellAction,
      inputCalc,
      onOpenModeChange,
      pdfInfo,
      handleChangeValue,
      detailItemChanging,
      onSetFilterItems,
      currentBoxIdForCss,
      isNotOwner,
      onShowExclusionControl,
    } = this.props;
    const detail = mappingDetail(row.scrumItem);
    const isDisabled = !row.values || row.values.length <= 0 || isPortalOpen;

    const isPopupVisible =
      this.itemRef && this.itemRef.current && this.itemRef.current.state && this.itemRef.current.state.keepOpen;
    let classNameFlag = false
    const isAggreation = row.scrumItem && row.scrumItem.indexOf(SCRUM_ITEM_SUFFIX.AGGREGATION) !== -1;
    if (
        (currentBoxIdForCss && this.getCellIds(row.values).indexOf(currentBoxIdForCss) !== -1)
    ) {
      classNameFlag = true
    }
    if (!isAggreation && ((row.id === selectedCell.id && 'value' === selectedCell.column) || isPopupVisible)) {
      classNameFlag = true
    }
    return (
      <div
        id={`${row.scrumItem}-list-board`}
        className={`edit-box-table-tr edit-box-table-card board-item-wrap
      ${openable ? 'edit-box-able' : 'edit-box-unable unhoverRow'}
      ${selectedCell.id === row.id || isPopupVisible || classNameFlag? 'board-item-backGround style-scroll-board-selected' : ''}
      ${this.state.calcInputError ? 'board-tr-calc-input-error-hint' : ''}
      ${this.state.calcInputHint ? 'board-tr-calc-input-hint' : ''}
      `}
        onClick={(e) => {
          if(row.isRefer) return;
          handleCellAction('list');
          this.handleClicks();
          onSetFilterItems([]);
          if (this.context.isClickLinkScrumItemButton) {
            this.context.sendPredictBeautifyDetailMetrics({
              event_type: EventType.Action,
              event_name: EventName.LinkScrumItem,
              custom_parameter: {
                view_mode: ViewMode.Card,
              },
            })
            this.context.handleSetIsClickLinkScrumItemButton(false)
          }
        }}
        onDoubleClick={() => {
          if(row.isRefer) return;
          if (this.context.predictDetailViewMode !== "") {
            this.context.sendPredictBeautifyDetailMetrics({
              event_type: EventType.Action,
              event_name: EventName.FixAmount,
              custom_parameter: {
                location: PredictDetailPageLocation.ScrumItem,
                source: FixAmountSource.TextField,
                view_mode: ViewMode.Card,
              },
            })
          }
        }}
      >
        <BoardItem
          row={row}
          isPortalOpen={isPortalOpen}
          onGetSum={onGetSum}
          detailData={detailData}
          currentPageType={currentPageType}
          onInputModeChange={onInputModeChange}
          onEdit={onEdit}
          handleInputChange={handleInputChange}
          isDisabledItem={isDisabled}
          ref={this.itemRef}
          handleTextChange={handleTextChange}
          handleSetOppositeValue={handleSetOppositeValue}
          handleCellOperation={handleCellOperation}
          popups={popups}
          onSelected={this.onSelected}
          selectedCell={selectedCell}
          isCancel={isCancel}
          handleCellAction={handleCellAction}
          inputCalc={inputCalc && inputCalc[row.scrumItem]}
          onOpenModeChange={onOpenModeChange}
          openable={openable}
          onUpdateBBox={onUpdateBBox}
          handleUnLinkScrumItem={handleUnLinkScrumItem}
          selectedBBox={selectedBBox}
          pdfInfo={pdfInfo}
          handleChangeValue={handleChangeValue}
          detailItemChanging={detailItemChanging}
          detail={detail}
          handleSearchReferItem={this.props.handleSearchReferItem}
          isNotOwner={isNotOwner}
          onShowExclusionControl={onShowExclusionControl}
          changeCalcInputError={this.changeCalcInputError}
          calcInputError={this.state.calcInputError}
          changeCalcInputHint={this.changeCalcInputHint}
        />
      </div>
    );
  };

  render() {
    const { row } = this.props;
    // const separation = this.renderSeparation(row.scrumItem);

    return this.renderPopupList(row);
  }
}

BoardList.propTypes = {
  row: PropTypes.object.isRequired,
  metadata: PropTypes.object.isRequired,
  currentPageType: PropTypes.string.isRequired,
  isPortalOpen: PropTypes.bool.isRequired,
  detailData: PropTypes.object.isRequired,
  selectedCell: PropTypes.object.isRequired,
  handleCellOperation: PropTypes.func,
  onGetSum: PropTypes.func,
  onInputModeChange: PropTypes.func,
  onEdit: PropTypes.func,
  handleUnLinkScrumItem: PropTypes.func,
  handleSetOppositeValue: PropTypes.func,
  mappingDetail: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleTextChange: PropTypes.func,
};

BoardList.defaultProps = {
  handleCellOperation: () => {},
  onGetSum: () => {},
  onInputModeChange: () => {},
  onEdit: () => {},
  handleUnLinkScrumItem: () => {},
  handleSetOppositeValue: () => {},
  mappingDetail: () => {},
  handleInputChange: () => {},
  handleTextChange: () => {},
};
