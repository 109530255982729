/**
 * Parse, validate, manipulate, and display dates in javascript. http://momentjs.com
 */
import moment from 'moment';

export const FMT = {
  DATETIME_LOCAL: 'YYYY-MM-DD HH:mm',
}

export function unixMSToDatetime(unixMS, fmt) {
  return moment.unix(unixMS / 1000).format(fmt)
}

