import React from 'react';

import { MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact';
import {
  Button,
  Form,
  Checkbox
} from 'semantic-ui-react';
import api from '../../../api';
import intl from 'react-intl-universal';
import DatePicker from 'react-date-picker';
import '../Tenants.css';
import { toUnixMS, FMT } from '../TimeUtil';
import moment from "moment";
import { ErrorIcon,CalenarIcon, CancelNoCircleIcon } from '../../../assets/icon';

export default class MaintainEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errmsg: null,
      errors: {},
      tenant: {},
      ocrChecked: false,
      aiChecked: false,
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);

  }

  componentDidUpdate(prevProps, prevState) {
    const { tenant, editFlag } = this.props
    const preTenant = prevProps.tenant
    let ocrChecked = false;
    let aiChecked = false;
    if(editFlag&&tenant&&tenant.tenantCreated&&!preTenant.tenantCreated){
      const tenantCreated = tenant.tenantCreated.split(',');
      if(tenantCreated[0]==='1'){
        ocrChecked = true
      }
      if(tenantCreated[1]==='1'){
        aiChecked = true
      }
      this.setState({ocrChecked,aiChecked})
    }
  }
  handleInput(event) {
    const {name, value} = event.target
    const tenant = {...this.state.tenant}
    tenant[name] = value
    this.setState({
      errmsg: null,
      tenant
    })
  }

  onCheckboxChange = (value, type) => { 
    this.setState({ tenant: {...this.state.tenant, [type]: value ? 1: 0}})
  }

  handleFocus(event) {
    const { errors } = this.state;
    const {name} = event.target;
    delete errors[name];
    this.clearErrors(errors);
  }

  toggleEdit() {
    this.setState({
      tenant: {},
      errors: {},
      errmsg: null,
      expiredTimestampAutoRenew: null,
      ocrChecked: false,
      aiChecked: false,
    });
    this.props.toggleEdit(false);
  }

  clearErrors(errors) {
    this.setState({
      errors
    });
  }

  checkMail(tenant){
    const errors = {};
    //eslint-disable-next-line 
    const emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const mailMsg = 'メールアドレスを正しく入力してください';
    if (tenant.mail && !emailReg.test(tenant.mail)) {
      errors['mail'] = {content: mailMsg, pointing: 'below', flag: true};
    }
    if (tenant.userMail && !emailReg.test(tenant.userMail)) {
      errors['userMail'] = {content: mailMsg, pointing: 'below', flag: true};
    }
    
    this.setState({
      errors: errors
    });
    return Object.keys(errors).length === 0;
  }

  checkTenant(tenant, editFlag) {
    const errors = {};
    const { aiChecked, ocrChecked } = this.state;
    const msg = '必須項目です';

    if (!tenant.name) {
      errors['name'] = {content: msg, pointing: 'below', flag: true};
    }
    if (!tenant.mail) {
      errors['mail'] = {content: msg, pointing: 'below', flag: true};
    }
    // if (!tenant.address) {
    //   errors['address'] = {content: 'Please enter a address.', pointing: 'below', flag: true};
    // }
    // if (!tenant.phoneNum) {
    //   errors['phoneNum'] = {content: 'Please enter a phone num.', pointing: 'below', flag: true};
    // }
    if (ocrChecked && !tenant.useCount) {
      errors['useCount'] = {content: msg, pointing: 'below', flag: true};
    }
    if(aiChecked){
      if (tenant.limitAmountUser === '' || isNaN(tenant.limitAmountUser)) {
        errors['limitAmountUser'] = {content: msg, pointing: 'below', flag: true};
      }
      if (tenant.limitAmountProject === '' || isNaN(tenant.limitAmountProject)) {
        errors['limitAmountProject'] = {content: msg, pointing: 'below', flag: true};
      }
      if (tenant.limitAmountPdf === '' || isNaN(tenant.limitAmountPdf)) {
        errors['limitAmountPdf'] = {content: msg, pointing: 'below', flag: true};
      }
    }

    if (tenant.easyocrContractStart) {
      tenant.easyocrContractStart = toUnixMS(moment(tenant.easyocrContractStart));
    } else {
      tenant.easyocrContractStart = 0;
      if(ocrChecked && this.state.expiredTimestampAutoRenew){
        errors['easyocrContractStart'] = {content: msg, pointing: 'below', flag: true};
      }
    }
    if (tenant.easyocrContractEnd) {
      if (typeof (tenant.easyocrContractEnd) === 'string') {
        tenant.easyocrContractEnd = toUnixMS(moment(tenant.easyocrContractEnd).add({
          h: 23,
          m: 59,
          s: 59
        }));
      } 
      
    } else {
      tenant.easyocrContractEnd = 0;
      if(ocrChecked && this.state.expiredTimestampAutoRenew){
        errors['easyocrContractEnd'] = {content: msg, pointing: 'below', flag: true};
      }
    }
    if (tenant.fsContractStart) {
      tenant.fsContractStart = toUnixMS(moment(tenant.fsContractStart));
    } else {
      tenant.fsContractStart = 0;
    }
    if (tenant.fsContractEnd) {
      if (typeof (tenant.fsContractEnd) === 'string') { 
        tenant.fsContractEnd = toUnixMS(moment(tenant.fsContractEnd).add({
          h: 23,
          m: 59,
          s: 59
        }));
      }      
    } else {
      tenant.fsContractEnd = 0;
    }
    if(!editFlag){
      if (!tenant.firstName) {
        errors['firstName'] = {content: msg, pointing: 'below', flag: true};
      }
      // if (!tenant.lastKanaName) {
      //   errors['lastKanaName'] = {content: 'Please enter a last kana name.', pointing: 'below', flag: true};
      // }
      // if (!tenant.firstKanaName) {
      //   errors['firstKanaName'] = {content: 'Please enter a first kana name.', pointing: 'below', flag: true};
      // }
      if (!tenant.userMail) {
        errors['userMail'] = {content: msg, pointing: 'below', flag: true};
      }
    }

    this.setState({
      errors: errors
    });
    return Object.keys(errors).length === 0;
  }

  handleSave = async() =>{
    const { editFlag } = this.props
    const { ocrChecked, aiChecked } = this.state;
    const propsTenant = this.props.tenant;
    if(propsTenant && propsTenant.tenantCreated){
      const checkedList = propsTenant.tenantCreated.split(',')
      if(checkedList[0] === '0'){
        delete propsTenant['easyocrContractStart']
        delete propsTenant['easyocrContractEnd']
        delete propsTenant['useCount']
      }
      if(checkedList[1] === '0'){
        delete propsTenant['limitAmountUser']
        delete propsTenant['limitAmountProject']
        delete propsTenant['limitAmountPdf']
      }
    }
    var tenant = Object.assign({}, propsTenant, this.state.tenant)
    if(!this.checkTenant(tenant, editFlag)) return;
    if(!this.checkMail(tenant)) return;
    tenant['tenantCreated'] = (ocrChecked?'1':'0')+','+(aiChecked?'1':'0')
    this.setState({
      errors: {},
      errmsg: null
    })

    if(!ocrChecked){
      delete tenant['easyocrContractStart']
      delete tenant['easyocrContractEnd']
      delete tenant['useCount']
    }
    if(!aiChecked){
      delete tenant['limitAmountUser']
      delete tenant['limitAmountProject']
      delete tenant['limitAmountPdf']
    }
    let resp;
    if(editFlag){
      resp = await (await api.put(`/dppapi/system/tenant/${tenant.id}`, tenant)).data
    } else {
      resp = await (await api.post(`/dppapi/system/tenant`, tenant)).data
    }
    if(this.state.expiredTimestampAutoRenew) {
      const currentTime = toUnixMS(moment(moment().format(FMT.DATE)).add({
        h: 23,
        m: 59,
        s: 59
      }))
      resp = await (await api.post(`/dppapi/system/tenant/update-expired-time`, {
        tenantId: tenant.id, 
        expiredTime: tenant.easyocrContractEnd,
        currentTime: currentTime
      })).data
    }
    if (!resp.success) {
      this.setState({
        errors: resp.errors,
        errmsg: resp.errmsg
      })
      return;
    }
    this.toggleEdit()
    this.props.refetch()
  }

  getDate = (dateType) => { 
    if (this.props.tenant[dateType]) {
      return new Date(this.props.tenant[dateType])
    }  
  }
  clearDateMsg = (name) => {
    const { errors } = this.state;
    delete errors[name];
    this.clearErrors(errors);
  }
  clickOcrCheckbox = (selected) => {
    const { errors } = this.state;
    if(!selected){
      delete errors['easyocrContractEnd'];
      delete errors['useCount'];
      this.clearErrors(errors);
    }
    this.setState({
      ocrChecked: selected,
      expiredTimestampAutoRenew: null
    })
  }
  clickAiCheckbox = (selected) => {
    const { errors } = this.state;
    if(!selected){
      delete errors['limitAmountUser'];
      delete errors['limitAmountProject'];
      delete errors['limitAmountPdf'];
      this.clearErrors(errors);
    }
    this.setState({
      aiChecked: selected
    })
  }

  render() {
    const { tenant, editFlag, isShowEdit } = this.props
    const { errors, errmsg, aiChecked, ocrChecked } = this.state
    let disableOcr = false;
    let disableAi = false;
    if(editFlag&&tenant&&tenant.tenantCreated){
      const tenantCreated = tenant.tenantCreated.split(',');
      if(tenantCreated[0]==='1'){
        disableOcr = true
      }
      if(tenantCreated[1]==='1'){
        disableAi = true
      }
    }

    return (
      <MDBModal size={'lg'} isOpen={isShowEdit} toggle={this.toggleEdit} className="tenant-edit-modal-content">
       {editFlag ? (
        <div className="tenant-edit-header-edit-div">
          <label className="tenant-edit-header-edit-label">{intl.get('_system.tenants.maintain.Edit.TenantEdit')}</label>
          <div>
            <label className="tenant-edit-header-tenant-label">テナントID:</label>
            <label className="tenant-edit-header-tenantId-label">{tenant.id}</label>
          </div>
        </div>
        ) :
        (<MDBModalHeader className="tenant-edit-header-div">
          {intl.get('_system.tenants.maintain.Edit.TenantAdd')}
        </MDBModalHeader>)}
        <MDBModalBody className="tenant-edit-modal-body">
          <Form>
            <Form.Field error={errors['name'] && errors['name']['flag']} style={{display: 'inline-block',marginBottom: '0px' }}>
              <label className="tenant-edit-body-form-label">{intl.get('_system.tenants.maintain.Edit.TenantsName')}</label>
              <Form.Input name="name"
              className={`tenant-edit-body-form-input ${errors['name'] && errors['name']['flag'] ? 'tenant-edit-body-form-error-input' :''}`}
              onFocus={this.handleFocus} defaultValue={tenant && tenant.name} onChange={this.handleInput}/>
            </Form.Field>
            <Form.Field error={errors['mail'] && errors['mail']['flag']} style={{display: 'inline-block',marginBottom: '0px' , paddingLeft: '16px' }}>
              <label className="tenant-edit-body-form-label">{intl.get('_system.tenants.maintain.Edit.TenantsMail')}</label>
              <Form.Input name="mail" 
              className={`tenant-edit-body-form-input ${errors['mail'] && errors['mail']['flag'] ? 'tenant-edit-body-form-error-input' :''}`}
              onFocus={this.handleFocus} defaultValue={tenant && tenant.mail} onChange={this.handleInput}/>
            </Form.Field>
            {(errors['name']&& errors['mail']['flag']) || (errors['mail'] && errors['mail']['flag'])?
            (<div className="tenant-edit-error-div">
              <p className="tenant-edit-error-label">{errors['name'] && errors['name']['flag'] ? errors['name'].content:""}</p>
              <p className="tenant-edit-error-label">{errors['mail'] && errors['mail']['flag'] ? errors['mail'].content:""}</p>
            </div>)
            :null}
            {!editFlag && 
              <React.Fragment>
                <Form.Field error={errors['firstName'] && errors['firstName']['flag']} style={{  display: 'inline-block',marginBottom: '0px',marginTop: '16px' }}>
                  <label className="tenant-edit-body-form-label">SMFLサポートユーザー名</label>
                  <Form.Input name="firstName" 
                  className={`tenant-edit-body-form-input ${errors['firstName'] && errors['firstName']['flag'] ? 'tenant-edit-body-form-error-input' :''}`}
                  onFocus={this.handleFocus} onChange={this.handleInput}/>
                </Form.Field>
                <Form.Field error={errors['userMail'] && errors['userMail']['flag']} style={{  display: 'inline-block', paddingLeft: '16px',marginBottom: '0px',marginTop: '16px'  }}>
                  <label className="tenant-edit-body-form-label">SMFLサポートメールアドレス</label>
                  <Form.Input name="userMail" 
                  className={`tenant-edit-body-form-input ${errors['userMail'] && errors['userMail']['flag'] ? 'tenant-edit-body-form-error-input' :''}`}
                  onFocus={this.handleFocus} onChange={this.handleInput}/>
                </Form.Field>
                {(errors['firstName'] && errors['firstName']['flag']) || (errors['userMail'] && errors['userMail']['flag'])?
                (<div className="tenant-edit-error-div">
                  <p className="tenant-edit-error-label">{errors['firstName'] && errors['firstName']['flag'] ? errors['firstName'].content:""}</p>
                  <p className="tenant-edit-error-label">{errors['userMail'] && errors['userMail']['flag'] ? errors['userMail'].content:""}</p>
                </div>)
                :null}
              </React.Fragment>}
            <Form.Field className="tenant-edit-subTitle-label">
              <Checkbox 
                label="EasyOCRテナント"
                className={`tenant-edit-div-checkbox ${disableOcr ? 'tenant-edit-div-disable-checkbox':''}`}
                disabled={disableOcr} 
                checked={ocrChecked}
                onChange={(e, data) => {this.clickOcrCheckbox(data.checked);}}
              />
            </Form.Field>
            <div style={{opacity: ocrChecked ? '1':'0.3'}}>
              <Form.Field style={{  display: 'inline-block', width: '320px', marginBottom: '0px'}}>
              <label className="tenant-edit-body-form-label">有償契約期間</label>
              </Form.Field>
              <Form.Field style={{  display: 'inline-block', width: '320px',paddingLeft: '16px', marginBottom: '0px'}}>
              <label className="tenant-edit-body-form-label">読取可能数（月間件数）</label>
              </Form.Field>
              <div style={{display: 'flex', alignItems: 'center'}}>
              <Form.Field style={{  display: 'inline-block', marginBottom: '0px'}}>
                <DatePicker 
                    className={`tenant-edit-item ${errors['easyocrContractStart'] && errors['easyocrContractStart']['flag'] ? 'tenant-edit-error-item' :''}`}
                    onChange={date => {this.clearDateMsg('easyocrContractStart');this.props.onTimeChange(date, 'easyocrContractStart')}}
                    value={this.getDate('easyocrContractStart')}
                    format="y/MM/dd"
                    calendarIcon={<CalenarIcon size="24"/>}
                    clearIcon={<CancelNoCircleIcon size="24"/>}
                    disabled={!ocrChecked}
                  />
              </Form.Field>
              <label className="tenant-edit-item-date-split-label">-</label>
              <Form.Field style={{  display: 'inline-block', marginBottom: '0px'}}>
                <DatePicker 
                    className={`tenant-edit-item ${errors['easyocrContractEnd'] && errors['easyocrContractEnd']['flag'] ? 'tenant-edit-error-item' :''}`}
                    onChange={date => {this.clearDateMsg('easyocrContractEnd');this.props.onTimeChange(date, 'easyocrContractEnd')}}
                    value={this.getDate('easyocrContractEnd')}
                    format="y/MM/dd"
                    calendarIcon={<CalenarIcon size="24"/>}
                    clearIcon={<CancelNoCircleIcon size="24"/>}
                    disabled={!ocrChecked}
                  />
              </Form.Field>
              <Form.Field error={errors['useCount'] && errors['useCount']['flag']} style={{  display: 'inline-block', paddingLeft: '16px', marginBottom: '0px'}}>
                <Form.Input name="useCount" 
                className={`tenant-edit-body-form-input ${errors['useCount'] && errors['useCount']['flag'] ? 'tenant-edit-body-form-error-input' :''}`}
                disabled={!ocrChecked} onFocus={this.handleFocus} defaultValue={disableOcr ? tenant.useCount:''} onChange={this.handleInput}/>
              </Form.Field>
              </div>
              {(errors['easyocrContractStart'] && errors['easyocrContractStart']['flag']) 
              || (errors['easyocrContractEnd'] && errors['easyocrContractEnd']['flag'])
              || (errors['useCount'] && errors['useCount']['flag']) ?
              (<div className="tenant-edit-error-div">
                <p className="tenant-edit-error-label" style={{width: '153px'}}>{errors['easyocrContractStart'] && errors['easyocrContractStart']['flag'] ? errors['easyocrContractStart'].content:""}</p>
                <p className="tenant-edit-error-label" style={{width: '153px'}}>{errors['easyocrContractEnd'] && errors['easyocrContractEnd']['flag'] ? errors['easyocrContractEnd'].content:""}</p>
                <p className="tenant-edit-error-label">{errors['useCount'] && errors['useCount']['flag'] ? errors['useCount'].content:""}</p>
              </div>)
              :null}
              <Form.Field style={{  display: 'inline-block', height: '24px',paddingTop: '2px',marginBottom: '0px', marginTop: '16px'}}>
                <Checkbox 
                  label="ライセンスキーを同時に更新"
                  className="tenant-edit-item-checkbox"
                  checked={this.state.expiredTimestampAutoRenew}
                  onChange={(e, data) => {this.clearDateMsg('easyocrContractStart');this.clearDateMsg('easyocrContractEnd');this.setState({ expiredTimestampAutoRenew: data.checked ? 1: 0})}}
                  disabled={!ocrChecked}
                />
              </Form.Field>
              {editFlag && (
              <Form.Field style={{ marginTop: '4px'}}>
                <div className="tenant-edit-icon-label">
                  <ErrorIcon color='#E8BA00' size='24px'/>
                  <label className="tenant-edit-warning-label">有効なライセンスのみ更新対象となります</label>
                </div>
              </Form.Field>
              )}
            </div>

            <Form.Field className="tenant-edit-subTitle-label">
              <Checkbox 
                label="決算書入力AIテナント"
                className={`tenant-edit-div-checkbox ${disableAi ? 'tenant-edit-div-disable-checkbox':''}`}
                disabled={disableAi} 
                checked={aiChecked}
                onChange={(e, data) => {this.clickAiCheckbox(data.checked)}}
              />
            </Form.Field>
            <div style={{opacity: aiChecked ? '1':'0.3'}}>

            <Form.Field error={errors['limitAmountUser'] && errors['limitAmountUser']['flag']} style={{ display: 'inline-block',marginBottom: '0px' }}>
              <label className="tenant-edit-body-form-label">{intl.get('_system.tenants.maintain.Edit.limitAmountUser')}</label>
              <Form.Input name="limitAmountUser" 
              className={`tenant-edit-body-form-input ${errors['limitAmountUser'] && errors['limitAmountUser']['flag'] ? 'tenant-edit-body-form-error-input' :''}`}
              disabled={!aiChecked} onFocus={this.handleFocus} defaultValue={disableAi ? tenant.limitAmountUser:''} onChange={this.handleInput}/>
            </Form.Field>
            <Form.Field error={errors['limitAmountProject'] && errors['limitAmountProject']['flag']} style={{display: 'inline-block', paddingLeft: '16px',marginBottom: '0px' }}>
              <label className="tenant-edit-body-form-label">{intl.get('_system.tenants.maintain.Edit.limitAmountProject')}</label>
              <Form.Input name="limitAmountProject" 
              className={`tenant-edit-body-form-input ${errors['limitAmountProject'] && errors['limitAmountProject']['flag'] ? 'tenant-edit-body-form-error-input' :''}`}
              disabled={!aiChecked} onFocus={this.handleFocus} defaultValue={disableAi ? tenant.limitAmountProject:''} onChange={this.handleInput}/>
            </Form.Field>
            {(errors['limitAmountUser'] && errors['limitAmountUser']['flag']) || (errors['limitAmountProject'] && errors['limitAmountProject']['flag']) ?
            (<div className="tenant-edit-error-div">
              <p className="tenant-edit-error-label">{errors['limitAmountUser'] && errors['limitAmountUser']['flag'] ? errors['limitAmountUser'].content:""}</p>
              <p className="tenant-edit-error-label">{errors['limitAmountProject'] && errors['limitAmountProject']['flag'] ? errors['limitAmountProject'].content:""}</p>
            </div>)
             :null}

            <Form.Field style={{  display: 'inline-block', width: '320px', marginBottom: '0px', marginTop: '16px'}}>
            <label className="tenant-edit-body-form-label">有償契約期間</label>
            </Form.Field>
            <Form.Field style={{  display: 'inline-block', width: '320px',paddingLeft: '16px', marginBottom: '0px'}}>
            <label className="tenant-edit-body-form-label">アップロード可能ファイル数（年間件数）</label>
            </Form.Field>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Form.Field error={errors['fsContractStart'] && errors['fsContractStart']['flag']} style={{  display: 'inline-block',marginBottom: '0px'}}>
                <DatePicker 
                    className="tenant-edit-item"
                    onChange={date => this.props.onTimeChange(date, 'fsContractStart')}
                    value={this.getDate('fsContractStart')}
                    format="y/MM/dd"
                    calendarIcon={<CalenarIcon size="24"/>}
                    clearIcon={<CancelNoCircleIcon size="24"/>}
                    disabled={!aiChecked}
                  />
              </Form.Field>
              <label className="tenant-edit-item-date-split-label">-</label>
              <Form.Field error={errors['fsContractEnd'] && errors['fsContractEnd']['flag']} style={{  display: 'inline-block',marginBottom: '0px'}}>
                <DatePicker 
                    className="tenant-edit-item"
                    onChange={date => {this.clearDateMsg('fsContractEnd');this.props.onTimeChange(date, 'fsContractEnd')}}
                    value={this.getDate('fsContractEnd')}
                    format="y/MM/dd"
                    calendarIcon={<CalenarIcon size="24"/>}
                    clearIcon={<CancelNoCircleIcon size="24"/>}
                    disabled={!aiChecked}
                  />
              </Form.Field>
              <Form.Field error={errors['limitAmountPdf'] && errors['limitAmountPdf']['flag']} style={{  display: 'inline-block', paddingLeft: '16px',marginBottom: '0px'}}>
                {/* <label className="tenant-edit-body-form-label">アップロード可能ファイル数（年間件数）</label> */}
                <Form.Input name="limitAmountPdf" 
                className={`tenant-edit-body-form-input ${errors['limitAmountPdf'] && errors['limitAmountPdf']['flag'] ? 'tenant-edit-body-form-error-input' :''}`}
                disabled={!aiChecked} onFocus={this.handleFocus} defaultValue={(disableAi ? tenant.limitAmountPdf : '')} onChange={this.handleInput}/>
              </Form.Field>
            </div>
            {errors['limitAmountPdf'] && errors['limitAmountPdf']['flag']?(
              <div className="tenant-edit-error-div">
                <p className="tenant-edit-error-label"></p>
                <p className="tenant-edit-error-label">{errors['limitAmountPdf'].content}</p>
              </div>
            ):null}
            </div>
          </Form>
          <Form style={{ marginTop: '16px'}}>
            <Button floated='right' className="tenant-edit-item-butotn" onClick={() => this.handleSave()}>{intl.get('_system.tenants.maintain.Edit.Save')}</Button>
            <Button floated='right' className="tenant-edit-item-cancel-butotn" onClick={() => { this.setState({ tenant: {}, errors: {}, errmsg: null }); this.toggleEdit() }}>{intl.get('_common.Cancel')}</Button>
          </Form>
          <div style={{ marginTop: '2em', color: 'red' }}>{errmsg}</div>
        </MDBModalBody>
      </MDBModal>
    );
  }
}
