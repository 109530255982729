// Half-width converted to full-width
export function halfToFull(txtstring) { 
  var tmp = ""; 
  for (var i = 0;i < txtstring.length; i++ ) { 
      if (txtstring.charCodeAt(i) === 32) { 
        tmp += String.fromCharCode(12288); 
      } 
      if (txtstring.charCodeAt(i) > 33 && txtstring.charCodeAt(i) < 127) { 
        tmp += String.fromCharCode(txtstring.charCodeAt(i) + 65248); 
      } 
      else {
        tmp += String.fromCharCode(txtstring.charCodeAt(i)); 
      }
  }
  kanaHanZenTbl.forEach((data) => {
    tmp = tmp.replace(data[0], data[1])
  });
  return tmp; 
}

// Convert full-width to half-width
export function fullToHalf(str) { 
  var tmp = ""; 
  for (var i = 0; i < str.length; i++) { 
      if (str.charCodeAt(i) === 12288){
        tmp += String.fromCharCode(str.charCodeAt(i) - 12256);
        continue;
      }
      if (str.charCodeAt(i) > 65280 && str.charCodeAt(i) < 65375) { 
        tmp += String.fromCharCode(str.charCodeAt(i) - 65248); 
      } 
      else { 
        tmp += String.fromCharCode(str.charCodeAt(i)); 
      } 
  }
  kanaHanZenTbl.forEach((data) => {
    tmp = tmp.replace(data[1], data[0])
  });
  return tmp; 
}

const kanaHanZenTbl = [
  ["ｶﾞ", "ガ"], ["ｷﾞ", "ギ"], ["ｸﾞ", "グ"], ["ｹﾞ", "ゲ"], ["ｺﾞ", "ゴ"],
  ["ｻﾞ", "ザ"], ["ｼﾞ", "ジ"], ["ｽﾞ", "ズ"], ["ｾﾞ", "ゼ"], ["ｿﾞ", "ゾ"],
  ["ﾀﾞ", "ダ"], ["ﾁﾞ", "ヂ"], ["ﾂﾞ", "ヅ"], ["ﾃﾞ", "デ"], ["ﾄﾞ", "ド"],
  ["ﾊﾞ", "バ"], ["ﾋﾞ", "ビ"], ["ﾌﾞ", "ブ"], ["ﾍﾞ", "ベ"], ["ﾎﾞ", "ボ"],
  ["ﾊﾟ", "パ"], ["ﾋﾟ", "ピ"], ["ﾌﾟ", "プ"], ["ﾍﾟ", "ペ"], ["ﾎﾟ", "ポ"],
  ["ｳﾞ", "ヴ"],
  ["ｱ", "ア"], ["ｲ", "イ"], ["ｳ", "ウ"], ["ｴ", "エ"], ["ｵ", "オ"],
  ["ｶ", "カ"], ["ｷ", "キ"], ["ｸ", "ク"], ["ｹ", "ケ"], ["ｺ", "コ"],
  ["ｻ", "サ"], ["ｼ", "シ"], ["ｽ", "ス"], ["ｾ", "セ"], ["ｿ", "ソ"],
  ["ﾀ", "タ"], ["ﾁ", "チ"], ["ﾂ", "ツ"], ["ﾃ", "テ"], ["ﾄ", "ト"],
  ["ﾅ", "ナ"], ["ﾆ", "ニ"], ["ﾇ", "ヌ"], ["ﾈ", "ネ"], ["ﾉ", "ノ"],
  ["ﾊ", "ハ"], ["ﾋ", "ヒ"], ["ﾌ", "フ"], ["ﾍ", "ヘ"], ["ﾎ", "ホ"],
  ["ﾏ", "マ"], ["ﾐ", "ミ"], ["ﾑ", "ム"], ["ﾒ", "メ"], ["ﾓ", "モ"],
  ["ﾔ", "ヤ"], ["ﾕ", "ユ"], ["ﾖ", "ヨ"], ["ﾗ", "ラ"], ["ﾘ", "リ"],
  ["ﾙ", "ル"], ["ﾚ", "レ"], ["ﾛ", "ロ"], ["ﾜ", "ワ"], ["ｦ", "ヲ"],
  ["ﾝ", "ン"], ["ｧ", "ァ"], ["ｨ", "ィ"], ["ｩ", "ゥ"], ["ｪ", "ェ"],
  ["ｫ", "ォ"], ["ｬ", "ャ"], ["ｭ", "ュ"], ["ｮ", "ョ"], ["ｯ", "ッ"],
  ["｡", "。"], ["｢", "「"], ["｣", "」"], ["､", "、"], ["･", "・"],
  ["ｰ", "ー"], ["", ""], ["\t", "　"], ["\n", "　"], [" ", "　"],
  ["/", "／"],
];