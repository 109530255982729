import React, { Component } from 'react';
import {
  Button,
  Form,
  Grid,
  Segment,
  Image,
  Table,
  Header,
  Input,
  Dropdown
} from 'semantic-ui-react';
import { imageToB64str } from './demo-utils';
import moment from 'moment';
import DatePicker from 'react-date-picker';
import intl from 'react-intl-universal';
import api from '../api';
import { toUnixMS, FMT } from '../predict/TimeUtil';
import { downloadCsv } from '../predict/Util';
import { getCsrfToken } from '../common/CookieUtil';

const multipart = require('parse-multipart');

export default class DemoHome extends Component {
  constructor(props) {
    super(props);
    this.projectIdInput = React.createRef();
    this.pageClassficationTenantId = React.createRef();
    this.projIdInput = React.createRef();
    this.beforeString = React.createRef();
    this.afterString = React.createRef();
    // this.handleDropDownSelect = this.handleDropDownSelect.bind(this);
    this.handleProjChange = this.handleProjChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePicturesSubmit = this.handlePicturesSubmit.bind(this);
    this.handleLabelsSubmit = this.handleLabelsSubmit.bind(this);
    this.handleEasyOcrSubmit = this.handleEasyOcrSubmit.bind(this);
    this.handlePicturesSubmitCommon = this.handlePicturesSubmitCommon.bind(this);
    this.downloadPdfTrackInfoCSV = this.downloadPdfTrackInfoCSV.bind(this);
    this.state = {
      filename: '',
      classifier: '',
      direction: '',
      filesrc: null,
      filedst: null,
      fileB64: null,
      labelOptions: [],
      labelSelected: null,
      workingStatusStartDate: moment().add(-3, 'd').toDate(),
      workingStatusEndDate: moment().endOf('day').toDate(),
      mailStartDate: moment().add(-3, 'd').toDate(),
      mailEndDate: moment().endOf('day').toDate(),
      uploadStartDate: moment().add(-3, 'd').toDate(),
      uploadEndDate: moment().endOf('day').toDate(),
      exportCSVStartDate: moment().add(-3, 'd').toDate(),
      exportCSVEndDate: moment().endOf('day').toDate(),
      analysisDate: moment().add(-3, 'd').toDate(),
      pdfUploadDate: moment().startOf('day').toDate(),
      easyocrDate: moment().startOf('day').toDate(),
      loginDate: moment().startOf('day').toDate(),
      pageClassficationDate: moment().add(-3, 'd').toDate()
    };
  }

  componentDidMount() {
  }

  async doReplacement() {
    await api.post(`/dppapi/demo/replace-annotations/${this.projIdInput.inputRef.current.value}`, {
      hashId: this.state.labelSelected,
      before: this.beforeString.inputRef.current.value,
      after: this.afterString.inputRef.current.value
    });
  }

  async downloadworkingStatusOverview(workingStatusStartDate, workingStatusEndDate) {
    // console.log(workingStatusStartDate, workingStatusEndDate);
    const response = await fetch(`/dppapi/demo/export-working-status?starttime=${workingStatusStartDate}&endtime=${workingStatusEndDate}&type=overview&proj=${this.projectIdInput.inputRef.current.value}`, {
      method: 'GET',
      headers: {
        'csrf-token': getCsrfToken()
      }
    });

    const body = await response.text();
    const option = {
      header: ['name', 'labelNum', 'reviewNum', 'ymd'],
    }
    const sufix = moment().format(FMT.TIME_STRING)
    downloadCsv(JSON.parse(body).data, option, `working_status_overview_${sufix}.csv`)
  }

  async downloadworkingStatusDetails(workingStatusStartDate, workingStatusEndDate) {
    // console.log(workingStatusStartDate, workingStatusEndDate);
    const response = await fetch(`/dppapi/demo/export-working-status?starttime=${workingStatusStartDate}&endtime=${workingStatusEndDate}&type=details&proj=${this.projectIdInput.inputRef.current.value}`, {
      method: 'GET',
      headers: {
        'csrf-token': getCsrfToken()
      }
    });

    const body = await response.text();
    const option = {
      header: ['name', 'num', 'val', 'ymd'],
    }
    const sufix = moment().format(FMT.TIME_STRING)
    downloadCsv(JSON.parse(body).data, option, `working_status_detail_${sufix}.csv`)
  }

  async handlePicturesSubmitCommon(e, url) {
    e.preventDefault();

    const form = e.target;
    var bodyParm = {
      instances: [
        {
          input_bytes: {
            b64: this.state.fileB64.substring('data:image/png;base64,'.length),
          },
        },
      ],
    };    

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'csrf-token': getCsrfToken()
      },
      body: JSON.stringify(bodyParm),
    });

    //var test= new multiparty.Form();
    //response.on = {
    //    "end": null,
    //    "error": null,
    //    "aborted": null
    //};
    //test.parse(response, function(err, fields, files) {
    //var a = {fields: fields, files: files};
    //console.log(a);
    //});
    //this.props.onChange();
    var boundary = '';
    for (let header of response.headers) {
      let key = header[0];
      if (key === 'x-content-type') {
        let value = header[1];
        boundary = value.split('=')[1];
        break;
      }
    }
    const body = await response.text();
    //console.log(body)
    const buff = new Buffer(body);
    //const body = await response.body;
    //const reader = body.getReader();
    //console.log(body)
    //reader.read().then(({ done, value }) => {
    //    console.log(value)
    //    let parts = multipart.Parse(value, boundary);
    //    for(var i=0;i<parts.length;i++){
    //        var part = parts[i];
    //        console.log(part);
    //    }
    //});

    var text = {};
    multipart.Parse(buff, boundary).forEach(function(element) {
      if (element.type === 'text/plain') {
        text[element.filename] = element.data.toString();
      } else if (element.type === 'image/jpeg') {
        const x = atob(new TextDecoder('utf-8').decode(element.data));
        const y = new Array(x.length);
        for (let i = 0; i < x.length; i++) {
          y[i] = x.charCodeAt(i);
        }
        text[element.filename] = URL.createObjectURL(
          new Blob([new Uint8Array(y)], { type: 'image/jpeg' })
        );
      }
    });

    // const text = JSON.parse(body, boundary);
    this.setState({
      filename: text.image_filename,
      classifier: text.image_type,
      direction: text.image_direction,
      filedst: text.image_object,
    });

    form.reset();
  }

  async handlePicturesSubmit(e) {
      await this.handlePicturesSubmitCommon(e, '/dppapi/demo/predict/picture?bboxFlag=0&ocrFlag=0&direction=0&pageClassification=0');
  }

  async handleLabelsSubmit(e) {
      await this.handlePicturesSubmitCommon(e, '/dppapi/demo/predict/labels');
  }

  async handleEasyOcrSubmit(e) {
    await this.handlePicturesSubmitCommon(e, '/dppapi/demo/predict/picture?bboxFlag=9999&ocrFlag=9999');
  }

  async handleExportCSV(exportCSVStartDate, exportCSVEndDate) {
    const option = {
      header: ['tenant id', 'project id', 'pdf id', 'page name', 'subtitle', 'item', 'clean subtitle', 'clean item', 'value', 'scrum item', 'original item'],
    }
    const sufix = moment().format(FMT.TIME_STRING);
    const { rows } = await (await api.post('/dppapi/demo/predict/export', {
      startDate: exportCSVStartDate && toUnixMS(exportCSVStartDate),
      endDate: exportCSVEndDate && toUnixMS(exportCSVEndDate)
    })).data;
    downloadCsv(rows, option, `analysis_${sufix}.csv`);
  }

  async handleEasyOcrCSV(easyOcrDate) {
    const option = {
      header: ['tenant id', 'user id', 'month', 'easyocr id', 'file name', 'parsing time', 'status', 'corrected degree', 'eraser area', 'count date'],
    }
    const sufix = moment().format(FMT.TIME_STRING);
    const { rows } = await (await api.post('/dppapi/demo/predict/easyocr',{timestamp: toUnixMS(easyOcrDate)})).data;
    downloadCsv(rows, option, `easyocr_${sufix}.csv`);
  }

  async handlelicensekeyEasyOcrCSV(easyOcrDate) {
    const option = {
      header: ['tenant id', 'tenant name', 'license id', 'license description', 'machine id', 'mac address', 'month', 'easyocr id', 'file name', 'parsing time', 'status', 'corrected degree', 'eraser area', 'count date', 'uuid', 'build date'],
    }
    const sufix = moment().format(FMT.TIME_STRING);
    const { rows } = await (await api.post('/dppapi/demo/predict/license-easyocr',{timestamp: toUnixMS(easyOcrDate)})).data;
    downloadCsv(rows, option, `licensekey_prediction_history_${sufix}.csv`);
  }

  async handleLoginCSV(loginDate) {
    const option = {
      header: ['user id', 'name', 'email', 'ip', 'agent', 'login time', 'tenant id', 'tenant name'],
    }
    const sufix = moment().format(FMT.TIME_STRING);
    const { rows } = await (await api.post('/dppapi/demo/predict/login',{timestamp: toUnixMS(loginDate)})).data;
    downloadCsv(rows, option, `login_history_${sufix}.csv`);
  }

  async handlePresetCSV(uploadDate) {
    const option = {
      header: [
        'tenant id', 
        'tenant name', 
        'project id', 
        'project name', 
        'pdf id', 
        'pdf name', 
        'アップロード者id', 
        'アップロード者',
        '担当者id',
        '担当者',
        'RPA連携済み (ユーザ確認済み) ',
        '未RPA連携科目の数',
        'preset有効科目の数',
        'RPA連携科目の数',
        'preset率',
        'BS RPA連携科目の数',
        'BS preset有効科目の数',
        'SS RPA連携科目の数',
        'SS preset有効科目の数',
        'PL RPA連携科目の数',
        'PL preset有効科目の数',
        'SZGK RPA連携科目の数',
        'SZGK preset有効科目の数',
        'HKH RPA連携科目の数',
        'HKH preset有効科目の数',
        '手入力BS',
        '手入力SS',
        '手入力PL',
        '手入力SZGK',
        '手入力HKH',
        '日付',
      ],
    }
    const sufix = moment().format(FMT.TIME_STRING);
    const { rows } = await (await api.post('/dppapi/demo/predict/preset',{timestamp: toUnixMS(uploadDate)})).data;
    downloadCsv(rows, option, `preset_analysis_${sufix}.csv`);
  }

  async handleEvaluateCSV(uploadDate) {
    const option = {
      header: [
        'tenant id', 
        'tenant name', 
        'project id', 
        'project name', 
        'pdf id', 
        'pdf name', 
        'アップロード者id', 
        'アップロード者',
        '担当者id',
        '担当者',
        'RPA連携済み (ユーザ確認済み) ',
        '未RPA連携科目の数',
        'preset有効科目の数',
        'RPA連携科目の数',
        'preset率',
        'BS RPA連携科目の数',
        'BS preset有効科目の数',
        'SS RPA連携科目の数',
        'SS preset有効科目の数',
        'PL RPA連携科目の数',
        'PL preset有効科目の数',
        'SZGK RPA連携科目の数',
        'SZGK preset有効科目の数',
        'HKH RPA連携科目の数',
        'HKH preset有効科目の数',
        '手入力BS',
        '手入力SS',
        '手入力PL',
        '手入力SZGK',
        '手入力HKH',
        '日付',
        '削除',
      ],
    }
    const sufix = moment().format(FMT.TIME_STRING);
    const { rows } = await (await api.post('/dppapi/demo/predict/evaluate',{timestamp: toUnixMS(uploadDate)})).data;
    downloadCsv(rows, option, `evaluate_preset_analysis_${sufix}.csv`);
  }

  onChangeDate = (type, value) => {
    this.setState({ [type]: value });
  };

  async handlePdfAnalysis(e) {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);

    const response = await fetch('/dppapi/demo/predict/pdf-analysis?projectId=1', {
      method: 'POST',
      body: formData,
      headers: {
        'csrf-token': getCsrfToken()
      }
    });

    const body = await response.text();
    const rslt = JSON.parse(body);
    if (rslt.success) {
      let a = document.createElement('a');
      a.href = `${rslt.link}?token=${getCsrfToken()}`;
      a.click();
      a.remove();
    } else {
      alert(rslt.errmsg);
    }
    form.reset();
  }

  // handleDropDownSelect(event, data) {
  //   this.setState({
  //     labelSelected: data.value, 
  //   });
  // }

  async handleProjChange(event) {
    const response = await api.get(`/dppapi/demo/predict/project/${this.projIdInput.inputRef.current.value}`);

    try {
      const rslt = response.data;
      let options = [];
      rslt.form.formParts.forEach((e, index) => {
        if (e.type === "bbox" || e.type === "polygon") {
          options.push({ key: e.id, text: `${e.type} | ${e.name}`, value: e.id });
        }
      })
      
      if (options.length > 0) {
        this.setState({
          labelOptions: options,
          labelSelected: options[0].value, 
        });
      }
    } catch (error) {
      this.setState({
        labelOptions: [],
        labelSelected: null, 
      });
    }
  }

  handleChange(e) {
    const imgUrl = URL.createObjectURL(e.target.files[0]);
    this.setState({
      filesrc: imgUrl,
    });

    imageToB64str(imgUrl, dataUrl => {
      this.setState({
        fileB64: dataUrl,
      });
    });

    this.setState({
      filename: "",
      classifier: "",
      direction: "",
      filedst: null,
    });
  }

  onChangeDate = (type, value) => {
    this.setState({ [type]: value });
  };

  async downloadEasyOCRImages(uploadStartDate, uploadEndDate){
    // console.log(uploadStartDate, uploadEndDate)
    const response = await fetch(`/dppapi/demo/export-images?starttime=${uploadStartDate}&endtime=${uploadEndDate}&dryRun=1`, {
      method: 'GET',
      headers: {
        'csrf-token': getCsrfToken()
      }
    });

    const body = await response.text();
    // console.log(body)
    const rslt = JSON.parse(body);
    if (rslt.success) {
      const a = document.createElement('a');
      a.href = `/dppapi/demo/export-images?starttime=${uploadStartDate}&endtime=${uploadEndDate}&token=${getCsrfToken()}`
      a.click();
      a.remove();
    } else {
      alert(rslt.errmsg);
    }
  }

  async downloadPdfTrackInfoCSV(pdfUploadTime) {
    const startPdfUploadTime = moment(pdfUploadTime).startOf('day').unix();
    const  { data } = await api.get(`/dppapi/demo/pdf/track-infos?startTime=${startPdfUploadTime}`);
    const { success, trackInfos} = data

    const flatTrackInfos = []
    if (success && trackInfos && trackInfos.length > 0) {
      trackInfos.forEach(pdf => {
        if (pdf.trackInfos && pdf.trackInfos.length > 0) {
          let tmpSection = pdf.trackInfos.map(track => { 
            return [ pdf.projectId, pdf.pdfId, track.userId || null, track.userName || '', track.type, track.timestamp, moment(new Date(track.timestamp)).format(FMT.TIME_STANDARD) ] 
          })
          flatTrackInfos.push(...tmpSection)
        }
      })

      if (flatTrackInfos && flatTrackInfos.length > 0) {
        const option = {
          header: ['project id', 'pdf id', 'user id', 'user name', 'type', 'timestamp', 'date'],
        }
        const sufix = moment().format(FMT.TIME_STRING)
        await downloadCsv(flatTrackInfos, option, `pdf_track_${sufix}.csv`)
      }
    }
  }

  async handlePageClassificationCSV(pageClassificationDate, tenantId) {
    const option = {
      header: [
        'tenant ID',
        'project ID',
        'pdf file ID',
        'pdf file status',
        'image page No.',
        'image Name',
        'image link',
        'original page classification id',
        'original page classification name',
        'page classification id',
        'page classification name'
      ],
    }
    const sufix = moment().format(FMT.TIME_STRING);
    const { rows } = await (await api.post('/dppapi/demo/predict/page-classification',{timestamp: toUnixMS(pageClassificationDate), tenantId: tenantId})).data;
    downloadCsv(rows, option, `pageclassification_${sufix}.csv`);
  }

  async handleMailHistoryCSV(mailStartDate, mailEndDate) {
    const option = {
      header: ['tenant id', 'mail type', 'send to', 'cc', 'month', 'mail status', 'created time'],
    }
    const sufix = moment().format(FMT.TIME_STRING);
    const { rows } = await (await api.post('/dppapi/demo/mail-history/export',{
      startDate: mailStartDate && toUnixMS(mailStartDate),
      endDate: mailEndDate && toUnixMS(mailEndDate)
    })).data;
    downloadCsv(rows, option, `mail_history_${sufix}.csv`);
  }

  render() {
    const {
      uploadStartDate,
      uploadEndDate,
      exportCSVStartDate,
      exportCSVEndDate,
      analysisDate,
      pdfUploadDate,
      easyocrDate,
      loginDate,
      workingStatusStartDate,
      workingStatusEndDate,
      pageClassficationDate,
      mailStartDate,
      mailEndDate
    } = this.state;

    return (
      <Grid celled="internally">
        <Grid.Row>
          <Grid.Column width={5}>
            <Header>
              <Header.Content>EasyOcrTest</Header.Content>
            </Header>
            <Segment>
              <Form
                method="post"
                encType="multipart/form-data"
                onSubmit={this.handleEasyOcrSubmit}
              >
                <Form.Input
                  multiple
                  type="file"
                  id="pictures"
                  name="pictures"
                  accept=".jpg, .jpeg, .png"
                  onChange={e => this.handleChange(e)}
                />
                <Button type="submit">{intl.get('_demonstration.DemoHome.Start Predict')}</Button>
              </Form>
            </Segment>
          </Grid.Column>

          <Grid.Column width={5}>
            <Header>
              <Header.Content>{intl.get('_demonstration.DemoHome.Image to bindingbox and ocr')}</Header.Content>
            </Header>
            <Segment>
              <Form
                method="post"
                encType="multipart/form-data"
                onSubmit={this.handleLabelsSubmit}
              >
                <Form.Input
                  multiple
                  type="file"
                  id="pictures"
                  name="pictures"
                  accept=".jpg, .jpeg, .png"
                  onChange={e => this.handleChange(e)}
                />
                <Button type="submit">{intl.get('_demonstration.DemoHome.Start Predict')}</Button>
              </Form>
            </Segment>
          </Grid.Column>

          <Grid.Column width={5}>
            <Header>
              <Header.Content>{intl.get('_demonstration.DemoHome.Pdf predict result')}</Header.Content>
            </Header>
            <Segment>
              <Form
                method="post"
                encType="multipart/form-data"
                onSubmit={this.handlePdfAnalysis}
              >
                <Form.Input
                  multiple
                  type="file"
                  id="pdf"
                  name="pdf"
                  accept=".pdf"
                />
                <Button type="submit">{intl.get('_demonstration.DemoHome.Start Predict')}</Button>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={5}>
            <Header>
              <Header.Content>{intl.get('_demonstration.DemoHome.Image to Predict')}</Header.Content>
            </Header>
            <Segment>
              <Form
                method="post"
                encType="multipart/form-data"
                onSubmit={this.handlePicturesSubmit}
              >
                <Form.Input
                  multiple
                  type="file"
                  id="pictures"
                  name="pictures"
                  accept=".jpg, .jpeg, .png"
                  onChange={e => this.handleChange(e)}
                />
                <Button type="submit">{intl.get('_demonstration.DemoHome.Start Predict')}</Button>
              </Form>
            </Segment>
          </Grid.Column>
          <Grid.Column width={5}>
            <Header>
              <Header.Content>{intl.get('_demonstration.DemoHome.Prediction result')}</Header.Content>
            </Header>
            <Segment>
              <Table basic="very" celled collapsing>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <Header as="h5" image>
                        <Header.Content>{intl.get('_demonstration.DemoHome.Image Classification')}</Header.Content>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>{this.state.classifier}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <Header as="h5" image>
                        <Header.Content>{intl.get('_demonstration.DemoHome.Image Direction')}</Header.Content>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>{this.state.direction}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>
          </Grid.Column>
          <Grid.Column width={5}>
            <Header>
              <Header.Content>{intl.get('_demonstration.DemoHome.EasyOcrDownloadTitle')}</Header.Content>
            </Header>
            <div>
              <Header as="h5" image>
                <Header.Content>{intl.get('_demonstration.DemoHome.EasyOcrDownloadStart')}</Header.Content>
              </Header>
              <DatePicker
                      className="search-pdf-item"
                      onChange={date => this.onChangeDate('uploadStartDate', date)}
                      value={uploadStartDate}
                      format="y/MM/dd"
                    />{' '}
            </div>
            <div>
              <Header as="h5" image>
                <Header.Content>{intl.get('_demonstration.DemoHome.EasyOcrDownloadEnd')}</Header.Content>
              </Header>
              <DatePicker
                      className="search-pdf-item"
                      onChange={date => this.onChangeDate('uploadEndDate', date)}
                      value={uploadEndDate}
                      format="y/MM/dd"
                    />{' '}
            </div>
            <div>
              <Button type="submit"
                onClick={() => this.downloadEasyOCRImages(uploadStartDate,uploadEndDate)}>
                {intl.get('_demonstration.DemoHome.EasyOcrDownloadSubmit')}
              </Button>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>
            <Header>
              <Header.Content>{intl.get('_demonstration.DemoHome.ExportCSV')}</Header.Content>
            </Header>
            <Segment>
              <div>
                <Header as="h5" image>
                  <Header.Content>{intl.get('_demonstration.DemoHome.ExportCSVStart')}</Header.Content>
                </Header>
                <DatePicker
                  className="search-pdf-item"
                  onChange={date => this.onChangeDate('exportCSVStartDate', date)}
                  value={exportCSVStartDate}
                  format="y/MM/dd"
                />{' '}
              </div>
              <div>
                <Header as="h5" image>
                  <Header.Content>{intl.get('_demonstration.DemoHome.ExportCSVEnd')}</Header.Content>
                </Header>
                <DatePicker
                  className="search-pdf-item"
                  onChange={date => this.onChangeDate('exportCSVEndDate', date)}
                  value={exportCSVEndDate}
                  format="y/MM/dd"
                />{' '}
              </div>
              <div style={{marginTop : '15px'}}>
                <Button type="submit"
                  onClick={() => this.handleExportCSV(exportCSVStartDate, exportCSVEndDate)}>
                  {intl.get('_demonstration.DemoHome.Download')}
                </Button>
              </div>
            </Segment>
          </Grid.Column>
          <Grid.Column width={5}>
            <Header>
              <Header.Content>{intl.get('_demonstration.DemoHome.PreDictionPdfTrackTitle')}</Header.Content>
            </Header>
            <div style={{display: 'flex', alignItems: 'center', paddingBottom: 10}}>
              <Header as="h5" image  style={{display: 'flex', alignItems: 'center', margin: 0}}>
                <Header.Content>{intl.get('_demonstration.DemoHome.PreDictionPdfStartTime')}</Header.Content>
              </Header>
              <DatePicker
                    className="search-pdf-item"
                    onChange={date => this.onChangeDate('pdfUploadDate', date)}
                    value={pdfUploadDate}
                    format="y/MM/dd"
                  />{' '}
            </div>
            <Button type="submit"
              onClick={() => this.downloadPdfTrackInfoCSV(pdfUploadDate)}>
              {intl.get('_demonstration.DemoHome.PreDictionPdfTrackDownloadBtn')}
            </Button>
          </Grid.Column>
          <Grid.Column width={5}>
            <Header>
              <Header.Content>{intl.get('_demonstration.DemoHome.AnalysisStatus')}</Header.Content>
            </Header>
            <Segment>
              <div>
                <DatePicker
                  className="search-pdf-item"
                  onChange={date => this.onChangeDate('analysisDate', date)}
                  value={analysisDate}
                  format="y/MM/dd"
                />
              </div>
              <div style={{marginTop : '15px'}}>
                <Button type="submit"
                  onClick={() => this.handleEvaluateCSV(analysisDate)}>
                  {intl.get('_demonstration.DemoHome.Download')}
                </Button>
              </div>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>
            <Header>
              <Header.Content>{intl.get('_demonstration.DemoHome.EasyocrHistory')}</Header.Content>
            </Header>
            <Segment>
              <div>
                <DatePicker
                  className="search-pdf-item"
                  onChange={date => this.onChangeDate('easyocrDate', date)}
                  value={easyocrDate}
                  format="y/MM/dd"
                />
              </div>
              <div style={{marginTop : '15px'}}>
                <Button type="submit"
                  onClick={() => this.handleEasyOcrCSV(easyocrDate)}>
                  {intl.get('_demonstration.DemoHome.Download')}
                </Button>
              </div>
            </Segment>
          </Grid.Column>
          <Grid.Column width={5}>
            <Header>
              <Header.Content>{intl.get('_demonstration.DemoHome.licensekeyPredictionHistory')}</Header.Content>
            </Header>
            <Segment>
              <div>
                <DatePicker
                  className="search-pdf-item"
                  onChange={date => this.onChangeDate('easyocrDate', date)}
                  value={easyocrDate}
                  format="y/MM/dd"
                />
              </div>
              <div style={{marginTop : '15px'}}>
                <Button type="submit"
                  onClick={() => this.handlelicensekeyEasyOcrCSV(easyocrDate)}>
                  {intl.get('_demonstration.DemoHome.Download')}
                </Button>
              </div>
            </Segment>
          </Grid.Column>
          <Grid.Column width={5}>
            <Header>
              <Header.Content>{intl.get('_demonstration.DemoHome.LoginHistory')}</Header.Content>
            </Header>
            <Segment>
              <div>
                <DatePicker
                  className="search-pdf-item"
                  onChange={date => this.onChangeDate('loginDate', date)}
                  value={loginDate}
                  format="y/MM/dd"
                />
              </div>
              <div style={{marginTop : '15px'}}>
                <Button type="submit"
                  onClick={() => this.handleLoginCSV(loginDate)}>
                  {intl.get('_demonstration.DemoHome.Download')}
                </Button>
              </div>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
        <Grid.Column width={5}>
            <Header>
              <Header.Content>{intl.get('_demonstration.DemoHome.PageClassfication')}</Header.Content>
            </Header>
            <Segment>
              <div>
                <DatePicker
                  className="search-pdf-item"
                  onChange={date => this.onChangeDate('pageClassficationDate', date)}
                  value={pageClassficationDate}
                  format="y/MM/dd"
                />
              </div>
              <div>
                <Header as="h5" image>
                  <Header.Content>{intl.get('_demonstration.DemoHome.TenantId')}</Header.Content>
                </Header>
                <Input
                  defaultValue={1}
                  ref={ref => this.pageClassficationTenantId = ref}
                  />
              </div>
              <div style={{marginTop : '15px'}}>
                <Button type="submit"
                  onClick={() => this.handlePageClassificationCSV(pageClassficationDate, this.pageClassficationTenantId.inputRef.current.value)}>
                  {intl.get('_demonstration.DemoHome.EasyOcrDownloadSubmit')}
                </Button>
              </div>
            </Segment>
          </Grid.Column>
          <Grid.Column width={5}>
            <Header>
              <Header.Content>{intl.get('_demonstration.DemoHome.WorkingStatus')}</Header.Content>
            </Header>
            <Segment>
              <div>
                <Header as="h5" image>
                  <Header.Content>{intl.get('_demonstration.DemoHome.WorkingStatusStart')}</Header.Content>
                </Header>
                <DatePicker
                        className="search-pdf-item"
                        onChange={date => this.onChangeDate('workingStatusStartDate', date)}
                        value={workingStatusStartDate}
                        format="y/MM/dd"
                      />{' '}
              </div>
              <div>
                <Header as="h5" image>
                  <Header.Content>{intl.get('_demonstration.DemoHome.WorkingStatusEnd')}</Header.Content>
                </Header>
                <DatePicker
                        className="search-pdf-item"
                        onChange={date => this.onChangeDate('workingStatusEndDate', date)}
                        value={workingStatusEndDate}
                        format="y/MM/dd"
                      />{' '}
              </div>
              <div>
                <Header as="h5" image>
                  <Header.Content>{intl.get('_demonstration.DemoHome.ProjectId')}</Header.Content>
                </Header>
                <Input defaultValue={1} ref={ref => this.projectIdInput = ref} />
              </div>
              <div>
                <Button type="submit"
                  onClick={() => this.downloadworkingStatusOverview(workingStatusStartDate, workingStatusEndDate)}>
                  {intl.get('_demonstration.DemoHome.WorkingStatusOverview')}
                </Button>
              </div>
              <div>
                <Button type="submit"
                  onClick={() => this.downloadworkingStatusDetails(workingStatusStartDate, workingStatusEndDate)}>
                  {intl.get('_demonstration.DemoHome.WorkingStatusDetails')}
                </Button>
              </div>
            </Segment>
          </Grid.Column>
          <Grid.Column width={5}>
            <Header>
              <Header.Content>Replace</Header.Content>
            </Header>
            <Segment>
              <div>
                <Header as="h5" image>
                  <Header.Content>{intl.get('_demonstration.DemoHome.ProjectId')}</Header.Content>
                </Header>
                <Input 
                  defaultValue={1} ref={ref => this.projIdInput = ref} 
                  onChange={e => this.handleProjChange(e)}
                />
              </div>
              <div>
                <Header as="h5" image>
                  <Header.Content>{"Label"}</Header.Content>
                </Header>
                <Dropdown
                  placeholder='Select a label'
                  fluid
                  selection
                  // onChange={this.handleDropDownSelect}
                  options={this.state.labelOptions}
                  value={this.state.labelSelected}
                />
              </div>
              <div>
                <Header as="h5" image>
                  <Header.Content>{"Before"}</Header.Content>
                </Header>
                <Input ref={ref => this.beforeString = ref} />
              </div>
              <div>
                <Header as="h5" image>
                  <Header.Content>{"After"}</Header.Content>
                </Header>
                <Input ref={ref => this.afterString = ref} />
              </div>
              <div>
                <Button type="submit"
                  onClick={() => this.doReplacement()}>
                  {"Replace Annotations"}
                </Button>
              </div>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
        <Grid.Column width={5}>
            <Header>
              <Header.Content>{intl.get('_demonstration.DemoHome.mailHistory')}</Header.Content>
            </Header>
            <Segment>
              <div>
                <Header as="h5" image>
                  <Header.Content>{intl.get('_demonstration.DemoHome.mailStart')}</Header.Content>
                </Header>
                <DatePicker
                        className="search-pdf-item"
                        onChange={date => this.onChangeDate('mailStartDate', date)}
                        value={mailStartDate}
                        format="y/MM/dd"
                      />{' '}
              </div>
              <div>
                <Header as="h5" image>
                  <Header.Content>{intl.get('_demonstration.DemoHome.mailEnd')}</Header.Content>
                </Header>
                <DatePicker
                        className="search-pdf-item"
                        onChange={date => this.onChangeDate('mailEndDate', date)}
                        value={mailEndDate}
                        format="y/MM/dd"
                      />{' '}
              </div>
              <div style={{marginTop : '15px'}}>
                <Button type="submit"
                  onClick={() => this.handleMailHistoryCSV(mailStartDate, mailEndDate)}>
                  {intl.get('_demonstration.DemoHome.Download')}
                </Button>
              </div>
            </Segment>
          </Grid.Column>
          <Grid.Column width={5}></Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <Header>
              <Header.Content>{intl.get('_demonstration.DemoHome.Image Preview')}</Header.Content>
            </Header>

            <Segment>
              <Image src={this.state.filesrc} />
            </Segment>
          </Grid.Column>
          <Grid.Column width={16}>
            <Header>
              <Header.Content>{intl.get('_demonstration.DemoHome.Predicted Image')}</Header.Content>
            </Header>

            <Segment>
              <Image src={this.state.filedst} />
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row />
      </Grid>
    );
  }
}
