import React, { Component } from 'react';
import './index.css'
import {
  Header
} from 'semantic-ui-react';
import OnlineUsersTable from './OnlineUsersTable'
import intl from 'react-intl-universal';

class MonitorOnlineUsers extends Component {
  render() {
    return (
      <div className="ui">
        <Header disabled>{intl.get('_monitor.online-users.index.Online Users')}</Header>
        <OnlineUsersTable />
      </div>
    );
  }
}

export default MonitorOnlineUsers;