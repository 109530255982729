import React from 'react';
import { Form, Message, Button } from 'semantic-ui-react';
import { sortableHandle } from 'react-sortable-hoc';
import MuiMsgTip from './MuiMsgTip'

function renderCategory({ pageId, column, metadatas, onColumnChange, onSwitchTab, error }) {

  if (column.type === 'inputText' || column.type === 'selection') {

    var options = column.type === 'inputText' ? [
      {
        key: "0",
        text: "No Category",
        value: "0"
      }] : [];

    metadatas.forEach(({ category }) => {
      options.push({
        key: category,
        text: category,
        value: category
      })
    });

    return options.length === 0 ? (
      <Message
        negative
        style={{ maxWidth: 400 }}
      >
        No Category, add Category by pressing <Button
          size="mini"
          disabled={error}
          onClick={e =>
            onSwitchTab(e, {
              name: "Metadata",
              component: "ProjectMetadataTable"
            })
          }>
          Metadata
        </Button>
      </Message>
    ) : (
        <Form.Select
          label="Category"
          options={options}
          disabled={error}
          defaultValue={column.autoComplete}
          onChange={(e, change) =>
            onColumnChange(pageId, column, {
              ...column,
              autoComplete: change.value
            }, 'col-catecory')
          }
          style={{ maxWidth: 400 }}
        />
      );
  }
  return null;
}

export default function ColumnItem({ pageId, column, metadatas, onColumnChange, onSwitchTab, error }) {
  const options = [
    { key: 'selectBbox', text: 'Select-bbox', value: 'selectBbox' },
    { key: 'inputText', text: 'Input-text', value: 'inputText' },
    { key: 'selection', text: 'Selection', value: 'selection' },
  ];
  const errContent = column.error ? column.error.content : ""
  return (
    <div
      style={{
        marginTop: '0.7em',
        padding: '1em',
        border: 'solid 1px #efefef',
        background: 'white',
        shadow: 'rgb(204, 204, 204) 0px 1px 2px',
      }}
    >
      <Form className="form-card" style={{ display: 'flex' }}>
        <DragHandle style={{ flex: 0, marginTop: 9 }} />
        <div style={{ flex: 1, padding: '0 0.5em' }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItemsL: "center" }}>
            <div style={{ width: "90%" }}>
              <MuiMsgTip show={errContent.length > 0} content={errContent} />
              <Form.Field
                className="form-item-name"
                placeholder="Column name"
                control="input"
                size="small"
                defaultValue={column.name}
                style={{ top: '-3px' }}
                error={errContent.length > 0}
                disabled={errContent.length === 0 && error}
                onChange={e =>
                  onColumnChange(pageId, column, {
                    ...column,
                    name: e.target.value
                  }, "col-name")
                }
              />
            </div>
            <div style={{ width: "10%", display: "flex", justifyContent: "flex-end" }}>
              <Button
                size="large"
                type="button"
                icon="trash"
                style={{ background: 'transparent' }}
                disabled={errContent.length === 0 && error}
                onClick={() =>
                  onColumnChange(pageId, column, null, 'col-del')
                }
              />
            </div>
          </div>
          <Form.Select
            label="Type"
            options={options}
            disabled={error}
            defaultValue={column.type}
            onChange={(e, change) =>
              onColumnChange(pageId, column, {
                ...column,
                type: change.value
              }, 'col-type')
            }
            style={{ maxWidth: 400 }}
          />
          {renderCategory({ pageId, column, metadatas, onColumnChange, onSwitchTab, error })}
          {column.type === 'inputText' ? <Form.Field
            className="form-item-link"
            label="Link"
            placeholder="http://host:port/dppapi/predict/recommendation"
            control="input"
            size="small"
            defaultValue={column.link ? column.link : ""}
            style={{ top: '-3px' }}
            error={errContent.length > 0}
            disabled={errContent.length === 0 && error}
            onChange={e =>
              onColumnChange(pageId, column, {
                ...column,
                link: e.target.value
              }, "link")
            }
          /> : null}
        </div>
      </Form>
    </div>
  );
}

const dragHandleStyle = {
  background:
    'linear-gradient(180deg,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000)',
  width: 25,
  minWidth: 25,
  height: 20,
  opacity: 0.25,
  cursor: 'move',
};
const DragHandle = sortableHandle(({ style }) => (
  <div style={{ ...dragHandleStyle, ...style }} />
));
