import React, { Component } from 'react';
import { Form, TextArea, Button, Icon, Progress, Popup as SPopup, Dropdown } from 'semantic-ui-react';
import { Popup } from 'react-leaflet';
import _ from 'lodash';
import './MuiPopup.css';
import api from '../../../api';
import intl from 'react-intl-universal';
import Hotkeys from 'react-hot-keys';
import { crop, getDataUrlFromArr } from '../../../common/ImageUtil';
import ItemSearchPopup from './ItemSearchPopup';
export default class MuiPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBBox: { id: '', text: '' },
      figureId: props.figure.id,
      editing: false,
      det_text: _.cloneDeep(props.figure.det_text),
      tmp_det_text: _.cloneDeep(props.figure.det_text),
      scrumItem: {},
      dataUrl: null,
      searchPopOpen: false,
    };
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.figure.det_text !== prevProps.figure.det_text ||
      this.props.selectedBBox !== prevProps.selectedBBox
    ) {
      this.setState({
        selectedBBox: this.props.selectedBBox,
        det_text: _.cloneDeep(this.props.figure.det_text),
        tmp_det_text: _.cloneDeep(this.props.figure.det_text),
      });
    }
  }

  handleEscDown = () => {
    this.props.onSelectedBBoxChange('', '');
    this.setState({ selectedBBox: { id: '', text: '' } });
  };

  onEdit = editing => {
    if (editing) {
      this.setState({ editing: editing });
    } else {
      this.setState({
        editing: editing,
        det_text: _.cloneDeep(this.state.tmp_det_text),
      });
    }
  };
  onDetTextChange = text => {
    this.setState({ det_text: text });
  };

  onDetTextSave = () => {
    const { onChange, figure, textMappingRef } = this.props;
    onChange('detTextChange', {
      figure,
      det_text: this.state.det_text,
    });
    this.setState({ editing: false });

    textMappingRef &&
      textMappingRef.current &&
      textMappingRef.current.handleTextChange(
        figure.id,
        this.state.det_text,
        'change'
      );

    //this.props.onLoad()
  };

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (nextState.det_text === this.state.det_text &&
  //     nextState.editing === this.state.editing &&
  //     shallowEqualObjects(nextProps.figure, this.props.figure)) {
  //     return false;
  //   }
  //   return true;
  // }

  // closePopusOnClick() {
  //   this.popup.current.leafletElement.options.leaflet.map.closePopup();
  // }
  onDetTextOcr = result => {
    const { textMappingRef, figure, onChange } = this.props;
    if (result.success) {
      this.setState({ det_text: result.ocr });
      onChange('detTextChange', {
        figure: figure,
        det_text: result.ocr,
        accuracy: JSON.parse(result.accuracy),
      });
      textMappingRef &&
        textMappingRef.current &&
        textMappingRef.current.handleTextChange(
          figure.id,
          result.ocr,
          'change'
        );
    } else {
      this.setState({ det_text: '', err_msg: result.errmsg });
    }
  };

  getBboxCoordinate = () => {
    const { figure, bounds } = this.props;
    const height = bounds._northEast.lat;

    const y1 = figure.points[0].lat;
    const x1 = figure.points[0].lng;
    const y2 = figure.points[1].lat;
    const x2 = figure.points[1].lng;

    return [
      [Math.round(x1), Math.round(height - y1)],
      [Math.round(x2), Math.round(height - y2)],
    ];
  };

  onPopupOpen = () => {
    this.props.scrumRecommendationFlg && this.onLoadAddToScrumItemButton();
    this.onCrop();
  };

  onCrop = () => {
    const { imageData, imageWidth, imageHeight, figure } = this.props;

    const { cropedImageData, width, height } = crop(
      imageData,
      figure.points,
      imageWidth,
      imageHeight
    );

    const dataUrl = getDataUrlFromArr(cropedImageData, width, height);
    // const img = getImgFromDataUrl(dataUrl)
    this.setState({ dataUrl });
  };

  onLoadAddToScrumItemButton = async () => {
    // loaderImageType is the type of Image which is showing in PDFLoader
    const { textMappingRef, figure, projectId, loaderImageType } = this.props;
    // currentPageType is the PullDown list type name
    const { detailData } = textMappingRef.current.state;
    let scrumItem = {};

    for (let tableKey of Object.keys(detailData)) {
      const itemIndex = _.findIndex(detailData[tableKey], item => {
        return item.value && item.value.id === figure.id;
      });
      if (itemIndex >= 0) {
        scrumItem = detailData[tableKey][itemIndex];

        // infer to scrum item
        const { data } = await api.post('/dppapi/predict/get-sfm', [
          {
            key1: scrumItem.subTitle.text,
            key2: scrumItem.item.text,
            projectId: projectId,
            category: loaderImageType,
          },
        ]);

        const { scrum_field_mappings, success } = data;

        if (
          success &&
          scrum_field_mappings &&
          scrum_field_mappings.length > 0
        ) {
          scrumItem['scrum_field_name'] =
            scrum_field_mappings[0]['scrum_field_name'];
        }

        break;
      }
    }

    this.setState({ scrumItem: scrumItem });
  };

  onAddToScrumItem = () => {
    const { scrumItem } = this.state;
    const { textMappingRef } = this.props;

    textMappingRef.current.handleScrumItemChange(
      scrumItem.value.id,
      scrumItem['scrum_field_name'],
      true
    );
  };

  setSelectBBox = (id, det_text) => {
    this.props.onSelectedBBoxChange(id, det_text);
    this.setState({ selectedBBox: { id: id, text: det_text } });
  };

  toggleSearch = open => {
    this.setState({ searchPopOpen: open });

    var agent = navigator.userAgent.toLowerCase();
    var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    this.props.changeWinActionListener(open);
    if (
      agent.indexOf('win32') >= 0 ||
      agent.indexOf('wow32') >= 0 ||
      agent.indexOf('win64') >= 0 ||
      agent.indexOf('wow64') >= 0
    ) {
      this.props.changeWinActionListener(open);
    }
    if (isMac) {
      if (open) {
        this.props.popupRef.current.leafletElement.options.leaflet.map.scrollWheelZoom.disable();
      } else {
        this.props.popupRef.current.leafletElement.options.leaflet.map.scrollWheelZoom.enable();
      }
    }
    if (open) {
      this.props.onSelectedBBoxChange('', '');
      this.setState({ selectedBBox: { id: '', text: '' } });
      this.props.popupRef.current.leafletElement.options.leaflet.map.doubleClickZoom.disable();
      this.props.popupRef.current.leafletElement.options.leaflet.map.dragging.disable();
    } else {
      this.props.popupRef.current.leafletElement.options.leaflet.map.doubleClickZoom.enable();
      this.props.popupRef.current.leafletElement.options.leaflet.map.dragging.enable();
    }
  };

  render() {
    const {
      det_text,
      editing,
      scrumItem,
      selectedBBox,
      searchPopOpen,
    } = this.state;
    const {
      figure: { id, accuracy = [], accThreshold },
      isEditing,
      labelTypes,
      textMappingRef,
      isLinked,
      popupRef,
      presetMasterSearchFlg,
    } = this.props;

    const detTextClaessOptions = [];
    Object.values(labelTypes).forEach(detClass => {
      detTextClaessOptions.push({ value: detClass, text: detClass });
    });

    // predict scrum item
    const scrumMapingButton = scrumItem.scrum_field_name && (
      <Button
        content={scrumItem.scrum_field_name}
        basic
        color="purple"
        label={{ basic: false, as: 'a', color: 'purple', icon: 'linkify' }}
        labelPosition="left"
        className="mini"
        onClick={this.onAddToScrumItem}
      />
    );
    const hotkeysDOM = (
      <Hotkeys
        keyName="esc"
        onKeyDown={key => {
          if (key === 'esc') {
            this.handleEscDown();
          }
        }}
      />
    );

    const minAcc = Math.min(...(Array.isArray(accuracy) ? accuracy : []));
    const accPercent = Math.floor(minAcc * 100);
    const accColor =
      parseFloat(minAcc) < parseFloat(accThreshold) ? 'yellow' : 'green';
    const det_chars = Array.from(det_text).map((char, index) => {
      const arrPercent = parseFloat(
        (Array.isArray(accuracy) ? accuracy : [])[index]
      );
      if (arrPercent < parseFloat(accThreshold)) {
        return (
          <SPopup
            position="top center"
            key={index}
            content={JSON.stringify(Math.floor(arrPercent * 10000) / 100) + '%'}
            trigger={<span style={{ color: 'red' }}>{char}</span>}
          />
        );
      } else {
        return <span key={index}>{char}</span>;
      }
    });

    return (
      <div>
        {presetMasterSearchFlg && searchPopOpen ? (
          <ItemSearchPopup
            isShowEdit={searchPopOpen}
            toggleSearch={this.toggleSearch}
            textMappingRef={textMappingRef}
            bboxId={id}
            category={this.props.loaderImageType}
          />
        ) : null}
        <Popup
          ref={popupRef}
          closeButton={false}
          className={'old-mui-leaflet-popup-content-wrapper'}
          onOpen={this.onPopupOpen}
        >
          <div key={`popup-${id}`} className={'old-mui-popup-container'}>
            <div className={'old-mui-popup-body'}>
              <div className={'old-mui-popup-det-content-wrapper'}>
                {!editing ? (
                  <div className="old-mui-det-content"> {det_chars}</div>
                ) : (
                  <Form>
                    <TextArea
                      value={det_text}
                      disabled={!editing}
                      onChange={e => this.onDetTextChange(e.target.value)}
                    />
                  </Form>
                )}
              </div>
              <div className="old-mui-popup-value-img-wapper">
                <img
                  alt="old-mui-popup-value-img"
                  className="old-mui-popup-value-img"
                  src={this.state.dataUrl}
                />
              </div>

              <div className="old-mui-popup-action">
                <div className="old-mui-popup-btn-group">
                  {editing ? (
                    <Button.Group size="mini">
                      <Button icon onClick={() => this.onEdit(false)}>
                        <Icon name="cancel" />
                      </Button>
                      <Button
                        icon
                        positive
                        onClick={() => this.onDetTextSave()}
                      >
                        <Icon name="checkmark" />
                      </Button>
                    </Button.Group>
                  ) : (
                    <Button.Group size="mini">
                      <Button
                        className="old-mui-popup-btn"
                        disabled={isEditing}
                        size="mini"
                        positive
                        onClick={() => this.onEdit(true)}
                      >
                        {intl.get('_predict.detail.pdf.MuiPopup.Edit')}
                      </Button>
                      <Button
                        className="old-mui-popup-btn"
                        disabled={
                          selectedBBox.id !== '' && selectedBBox.text !== ''
                        }
                        size="mini"
                        color="violet"
                        onClick={() => this.setSelectBBox(id, det_text)}
                      >
                        {selectedBBox.id === '' && selectedBBox.text === ''
                          ? intl.get('_predict.detail.pdf.MuiPopup.LinkTo')
                          : intl.get(
                              '_predict.detail.pdf.MuiPopup.Choose Cell'
                            )}
                      </Button>
                      {presetMasterSearchFlg === 'true' &&
                      this.props.figure.det_class === 'value' ? (
                        <Button
                          className="old-mui-popup-btn"
                          size="mini"
                          color="blue"
                          onClick={() => this.toggleSearch(true)}
                        >
                          {intl.get(
                            '_predict.detail.pdf.MuiPopup.ItemSearch.Search'
                          )}
                        </Button>
                      ) : null}
                    </Button.Group>
                  )}
                  {hotkeysDOM}
                </div>
              </div>
              <Dropdown
                    className="old-mui-det-class"
                    placeholder="choose class"
                    selection
                    fluid
                    openOnFocus={false}
                    value={this.props.figure.det_class}
                    options={detTextClaessOptions}
                    onChange={(e, { value }) =>
                      this.props.onChange('detTextClassChange', {
                        figure: this.props.figure,
                        det_class: value,
                      })
                    }
                  />
              {isEditing ? (
                <div className="old-pdf-MuiPopup-message">
                  {intl.get(
                    '_predict.detail.pdf.MuiPopup.ProhibitEditingWarning'
                  )}
                </div>
              ) : null}
              <div className="old-mui-popup-passed">
                {!editing && (
                  <React.Fragment>
                    {/* <Checkbox
                        disabled={isEditing}
                        label="確認済"
                        defaultChecked={this.props.figure.checkFlag ? true : false}
                        onChange={(e, { checked }) =>
                          onChange('checkFlagChange', {
                            figure: this.props.figure,
                            checkFlag: checked ? 1 : 0,
                          })
                        }
                      /> */}
                    {scrumMapingButton}
                  </React.Fragment>
                )}
              </div>
              <div className="mui-unlink-form">
                {isLinked && (
                  <Button
                    // className="mui-unlink-btn"
                    // basic
                    color="red"
                    label={{
                      basic: false,
                      as: 'a',
                      color: 'red',
                      icon: 'unlinkify',
                      style: { width: '30px' },
                    }}
                    labelPosition="left"
                    className="mini"
                    content={intl.get('_predict.detail.pdf.MuiPopup.unMapping')}
                    onClick={() => {
                      textMappingRef &&
                        textMappingRef.current &&
                        textMappingRef.current.handleUnLinkScrumItem(id);
                      popupRef.current.leafletElement.options.leaflet.map.closePopup();
                    }}
                  />
                )}
              </div>
              {Array.isArray(accuracy) && accuracy.length > 0 && (
                <div className="old-mui-popup-accuracy">
                  <SPopup
                    key={scrumItem}
                    position="bottom left"
                    content={
                      <div>
                        {(Array.isArray(accuracy) ? accuracy : []).map(
                          (a, i) => (
                            <div key={i}>
                              {' '}
                              {`"${det_text.charAt(i)}": ${Math.floor(
                                a * 10000
                              ) / 100}%`}{' '}
                            </div>
                          )
                        )}
                      </div>
                    }
                    trigger={
                      <Progress
                        percent={accPercent}
                        color={accColor}
                        size="medium"
                      />
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </Popup>
      </div>
    );
  }
}
