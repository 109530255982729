import React, { Component } from 'react';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import { Button, Segment, Loader, Popup, Header } from 'semantic-ui-react';
import api from '../api';
import ProjectDescription from './description'
import intl from 'react-intl-universal';

export default class LabelTable extends Component {
  state = {
    rows: [],
    isLoading: false,
    isShowEditDescription: false,
    editProject: {}
  };

  constructor(props) {
    super(props);

    this.getProjects = this.getProjects.bind(this);
    this.toggleEditDescription = this.toggleEditDescription.bind(this);
  }

  async componentDidMount() {
    await this.getProjects();
  }

  toggleEditDescription({ isShowEditDescription, editProject }) {
    this.setState({
      isShowEditDescription: isShowEditDescription,
      editProject: editProject || {},
    });
  }

  async getProjects() {
    const info = (await api.get(`/dppapi/info`)).data;
    let user = info ? info : '';

    const projects = (await api.get('/dppapi/labeling/projects/' + user.id)).data;
    const { history } = this.props;

    this.setState({
      user,
      rows: projects
        .map((project) => {
          return {
            id: project.id,
            projectName: (
              <Link key={project.id} to={`/label/manage/${project.id}`}>
                <div
                  style={{
                    width: 200,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {project.description ? (
                    <Popup
                      trigger={<u>{project.name}</u>}
                      content={<p>{project.description}</p>}
                      position="right center"
                      mouseEnterDelay={500}
                      hoverable
                      onClick={(e) => e.stopPropagation()}
                      inverted
                    >
                    </Popup>
                  ) : (
                    <u>{project.name}</u>
                  )}
                </div>
              </Link>
            ),
            name: project.name,
            labelInfo: `${project.imagesCount} images, ${
              project.labelsCount
            } labeled, Tags: ${project.form.formParts
              .map((part) => part.name)
              .join(', ')}`,
            operation: (
              <div>
                <Button
                  icon="info"
                  label={intl.get('_label.LabelManagementTable.Overview')}
                  size="tiny"
                  onClick={() => {
                    this.toggleEditDescription({
                      editProject: project,
                      isShowEditDescription: true,
                    });
                  }}
                />
                <Button
                  icon="edit"
                  label={intl.get('_label.LabelManagementTable.Edit')}
                  size="tiny"
                  onClick={() => history.push(`/label/manage/${project.id}`)}
                />
              </div>
            ),
          };
        }),
    });
  }

  render() {
    const { rows, isLoading, isShowEditDescription, editProject, user  } = this.state;
    const columns = [
      {
        label: intl.get('_label.LabelManagementTable.ID'),
        field: 'id',
        sort: 'asc',
      },
      {
        label: intl.get('_label.LabelManagementTable.Project'),
        field: 'projectName',
        sort: 'asc',
      },
      {
        label: intl.get('_label.LabelManagementTable.Label'),
        field: 'labelInfo',
        sort: 'asc',
      },
      {
        label: intl.get('_label.LabelManagementTable.Operation'),
        field: 'operation',
        sort: 'asc',
      },
    ];
    const data = {
      columns: columns,
      rows: rows,
    };
    
    if (isLoading) {
      return (<Segment><Loader active inline="centered" /></Segment>)
    }

    return (
      <div>
        { (user && user.tenantName)? <Header as="h1">{user.tenantName}</Header>: null}
        <Segment>
          <MDBDataTable theadColor="common-search-table-header" btn striped bordered hover data={data} />
          <ProjectDescription
            isShowEdit={ isShowEditDescription }
            project={ editProject }
            toggleEdit={this.toggleEditDescription}
          />
        </Segment>
      </div>
    );
  }
}
