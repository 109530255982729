import React from 'react';
import { Button, Segment } from 'semantic-ui-react';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import intl from 'react-intl-universal';
import '../../tenants/Tenants.css'

export default class IpFileSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      address: '',
      startDate: moment().add(-30, 'd').toDate(),
      endDate: moment().toDate(),
    };
  }

  onChange = (type, value) => {
    this.setState({ [type]: value });
  };

  render() {
    const { onSearch } = this.props;
    const { id,  address,  startDate, endDate,  } = this.state;
    return (
      <Segment>
        <div className="search-tenants-condition">
          <DatePicker
            className="search-tenants-item"
            onChange={date => this.onChange('startDate', date)}
            value={startDate}
            format="y/MM/dd"
          />{' '}
          ~
          <DatePicker
            className="search-tenants-item"
            onChange={date => this.onChange('endDate', date)}
            value={endDate}
            format="y/MM/dd"
          />
        </div>
        <div className="search-tenants-condition">
          <Button type="submit"
            onClick={() => onSearch({
              id,
              address,
              startDate,
              endDate,
            })}>
           {intl.get('_system.blackip.IpFile.Search.Search')}
          </Button>
        </div>
      </Segment>
    );
  }
}
