import React, { Component } from 'react';
import Hotkeys from 'react-hot-keys';
import 'leaflet-path-drag';
import 'leaflet/dist/leaflet.css';
import Provider from '../../detail/pdf/Provider';
import api from '../../../api';
import { Dimmer, Loader } from 'semantic-ui-react';
import { BBoxFigure, PolygonFigure } from './Figure';
import { colorMapping } from '../../../label/label-app/utils';
import { withBounds } from '../../detail/pdf/CalcBoundsHOC';
import intl from 'react-intl-universal';
import MapHeader from './MapHeader';
import MapContent from './MapContent';
import shallowEqualObjects from 'shallow-equal/objects';
import Slide from '@material-ui/core/Slide';

const rdom = require('react-dom');

class Canvas extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      zoom: -1,
      selectedFigureId: null,
      cursorPos: { lat: 0, lng: 0 },
      loading: false,
      showToolBar: false,
      clickFlag: false,
      scrollWheelZoomFlag: false,
      changeTitleLoading: false,
      clickFigureIds: [],
      clickedPoints: [],
      tenantPreference: { scroll_wheel_status: null },
      rereadSuccess: false,
      changeOwnerSuccess: false,
      screenUpdatedSuccess: false,
      imageData: null,
      height: null,
      width: null,
      loaderImageType: null
    };
    this.prevSelectedFigure = null;
    this.skipNextClickEvent = false;
    this.mapRef = React.createRef();
    this.headerRef = React.createRef();
    this.handleViewPortChange = this.handleViewPortChange.bind(this);
    this.clickHighlight = this.clickHighlight.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { onSelectionChange } = this.props;
    const { selectedFigureId } = this.state;

    if (this.prevSelectedFigureId !== selectedFigureId && onSelectionChange) {
      this.clickHighlight({}, false);
      this.prevSelectedFigureId = selectedFigureId;
      if (selectedFigureId) onSelectionChange(selectedFigureId);
    }
    if ((!shallowEqualObjects(this.props.selectedCellIds, prevProps.selectedCellIds) && !this.props.textMappingRef.current.props.currentBoxId)) {
      this.setState({
        clickFigureIds: [],
        selectedFigureId: null,
      });
    }
    if (this.props.selectedCell && this.props.selectedCell.id && !shallowEqualObjects(this.props.selectedCell, prevProps.selectedCell)) {
      this.setState({
        clickFigureIds: [],
        selectedFigureId: null,
      });
    }

    if (prevProps.imageData !== this.props.imageData) {
      this.setState({ imageData: this.props.imageData })
      this.setState({ width: this.props.width })
      this.setState({ height: this.props.height })
      this.setState({ loaderImageType: this.props.loaderImageType })
    }
  }

  async componentDidMount() {
    await this.handleGetTenantPreference({
      category: 'configure',
      subCategory: 'review_page',
      name: 'scrollWheel',
      subName: 'scroll_wheel_status',
    });

    // set scrollWheelStatus at tenantSetting: 
    // *while do not have the set of scrollWheelStatus the scrollWheelZoomFlag is true*
    // 0: win system scroll mouse the canvas is Moving up and down.
    // 1: mac system scroll mouse the canvas is scrooling zoom.
    const scrollWheelStatus = this.state.tenantPreference['scroll_wheel_status'];
    const per = localStorage.getItem('permission') || '';
    let perFlag = true;
    per.split(',').forEach(item => {
      if (item === '2') {
        perFlag = false;
      }
    });
    this.setState({ clickFlag: perFlag });

    if (scrollWheelStatus === '1' || !scrollWheelStatus) {
      this.setState({ scrollWheelZoomFlag: true });
    } else {
      document && document.getElementById('pdfImage') && document.getElementById('pdfImage').addEventListener('wheel', this.handleScroll, true);
      this.setState({ scrollWheelZoomFlag: false });
    }
  }

  handleGetTenantPreference = async param => {
    const res = await api.request({
      url: `/dppapi/predict/tenant/preference/query`,
      method: 'POST',
      data: param,
    });
    if (res.data.success) {
      this.setState({
        tenantPreference: {
          ...this.state.tenantPreference,
          [`${param.subName}`]: res.data.value,
        },
      });
    }
  };

  handleScroll = e => {
    e.preventDefault();
    const ele = rdom.findDOMNode(this);
    const map = this.mapRef.current.leafletElement;
    if (e.deltaY <= 0) {
      /* scrolling up */
      if (ele.scrollTop <= 0) {
        map.panBy([0, -80]);
      }
    } else {
      /* scrolling down */
      // if (ele.scrollTop + ele.clientHeight <= ele.scrollHeight) {
      map.panBy([0, 80]);
    }
  };

  renderFigure(figure, options) {
    const { selectFlag, handleChangeSelectFlag } = this.props;
    const Comp = figure.type === 'bbox' ? BBoxFigure : PolygonFigure;
    return (
      <Comp
        mapRef={this.mapRef}
        key={figure.id}
        figure={figure}
        options={options}
        skipNextClick={() => (this.skipNextClickEvent = true)}
        bounds={this.props.bounds}
        selectedBox={this.props.selectedBox}
        linking={this.props.linking}
        manualInput={this.props.manualInput}
        textMappingRef={this.props.textMappingRef}
        muiPopupRef={this.props.muiPopupRef}
        onSelectBox={this.props.onSelectBox}
        popupRef={this.props.popupRef}
        onSelectedBBoxChange={this.props.onSelectedBBoxChange}
        selectedBBox={this.props.selectedBBox}
        isEditing={this.props.isEditing}
        ocrFlg={this.props.ocrFlg}
        scrumRecommendationFlg={this.props.scrumRecommendationFlg}
        labelTypes={this.props.labelTypes}
        onLoad={this.props.onLoad}
        projectId={this.props.projectId}
        loaderImageType={this.props.loaderImageType}
        selectFlag={selectFlag}
        handleChangeSelectFlag={handleChangeSelectFlag}
        isNotOwner={this.props.isNotOwner}
        onShowExclusionControl={this.props.onShowExclusionControl}
      />
    );
  }

  handleViewPortChange(e) {
    sessionStorage.setItem('zoom' + this.props.pdfId, JSON.stringify(e));
  }

  handleCheck(key, errorList) {
    let err = false;
    let item = null;

    errorList.forEach(errItem => {
      const child = errItem.children.includes(key);
      if (errItem.name === key || child) {
        err = true;
        item = errItem;
      }
    });
    return { err, errItem: item };
  }

  infoItemFigures(id, filteredRows) {
    let result = null;
    filteredRows.forEach(f => {
      const { item, value, subTitle } = f;
      const isItem = item && item.id === id;
      const isValue = value && value.id === id;
      const isSubTitle = subTitle && subTitle.id === id;
      if (isItem || isValue || isSubTitle) {
        result = f;
      }
    });
    return result;
  }

  clickHighlight(fig, renderPoints, reset) {
    this.setState({
      clickFigureIds: [],
      clickedPoints: renderPoints,
    });
    // click the pdf fig have two cases: 1.fig includes value and item 2.it is only have value
    if (fig && fig.value && fig.item && !reset) {
      this.setState({
        clickFigureIds: [fig.value.id, fig.item.id],
      });
    } else if (fig && fig.value && !reset) {
      this.setState({
        clickFigureIds: [fig.value.id, null],
      });
    }
  }
  handleSetMapState = (val) => {
    if (val && val.hasOwnProperty('zoom')) {
      setTimeout(() => {
        this.setState(val);
      }, 50)
    }
  }

  render() {
    const {
      url,
      bounds,
      height,
      width,
      figures,
      unfinishedFigure,
      style,
      selectedCellIds,
      onSelect,
      isEditing,
      textMappingRef,
      thumbnailRef,
      imageData,
      pdfId,
      handelShowPredictLoading,
      effectiveHotkeyPage,
      isNotOwner,
      onShowExclusionControl,
    } = this.props;
    const {
      zoom,
      selectedFigureId,
      loading,
      clickFlag,
      scrollWheelZoomFlag,
      changeTitleLoading,
      clickFigureIds,
      clickedPoints,
      rereadSuccess,
      changeOwnerSuccess,
      screenUpdatedSuccess,
    } = this.state;

    const { zoom: zoomDefault, center: centerDefault } = JSON.parse(
      sessionStorage.getItem('zoom' + pdfId) || '{}'
    );

    const immutable_page_type = ['貸借対照表', '損益計算書', '株主資本等変動計算書'];
    let detailData = {};
    let currentPageType = null;
    let errorList = [];
    let filteredRows = [];
    if (textMappingRef && textMappingRef.current) {
      detailData = textMappingRef.current.state.detailData;
      currentPageType = textMappingRef.current.state.currentPageType;
      errorList = textMappingRef.current.state.errorList;
    }
    if (detailData && currentPageType) {
      const notDetail = !immutable_page_type.includes(currentPageType);
      const isNotDetail = !detailData || notDetail;
      if (!isNotDetail) {
        Object.values(detailData).forEach(valList => {
          if (valList.length) {
            filteredRows = filteredRows.concat(valList);
          }
        })
      }
    }

    const drawing = !!unfinishedFigure;

    const calcDistance = (p1, p2) => {
      const map = this.mapRef.current.leafletElement;
      return map.latLngToLayerPoint(p1).distanceTo(map.latLngToLayerPoint(p2));
    };

    let notMatchedList = [];
    filteredRows.forEach(filteredRow => {
      if (selectedCellIds.indexOf(filteredRow.value.id) !== -1 && !filteredRow.scrumItem) {
        notMatchedList.push(filteredRow.value.id);
        notMatchedList.push(filteredRow.item.id);
        notMatchedList.push(filteredRow.subTitle.id);
      }
    });
    const figuresDOM = figures.map((f, i) => {
      const info = this.infoItemFigures(f.id, filteredRows, errorList);
      let check = null;
      if (info) {
        check = this.handleCheck(info.scrumItem, errorList);
      }
      let color = null;
      if (check) {
        color = check.err ? 'roman' : 'pastelGreen';
      }
      return this.renderFigure(f, {
        editing: selectedFigureId === f.id && !drawing,
        finished: true,
        interactive: !drawing,
        sketch: f.tracingOptions && f.tracingOptions.enabled,
        borderColor: color ? colorMapping[color] : null,
        vertexColor: colorMapping[f.color],
        calcDistance,
        inSelectedCellIds: selectedCellIds.indexOf(f.id) !== -1,
        infoClick: info,
        figures, clickFigureIds,
        clickedPoints,
        clickHighlight: this.clickHighlight,
        imageData: imageData,
        imageWidth: width,
        imageHeight: height,
        selectedCellIds,
        notMatchedList
      });
    });

    const hotkeysDOM = effectiveHotkeyPage === "canvas" ? (
      <Hotkeys
        keyName="backspace,c,f,-,=,left,right,up,down,1,esc"
        onKeyDown={key => {
          const tagName = document.activeElement
            ? document.activeElement.tagName.toLowerCase()
            : null;
          if (['input', 'textarea'].includes(tagName)) {
            return false;
          }
          if (key === 'esc') {
            onSelect(null);
          }
          const map = this.mapRef.current.leafletElement;
          if (key === 'left') {
            map.panBy([-80, 0]);
          }
          if (key === 'right') {
            map.panBy([80, 0]);
          }
          if (key === 'up') {
            map.panBy([0, -80]);
          }
          if (key === 'down') {
            map.panBy([0, 80]);
          }
          if (key === '=') {
            map.setZoom(map.getZoom() + 1);
          }
          if (key === '-') {
            map.setZoom(map.getZoom() - 1);
          }
        }}
      />
    ) : null;

    if (selectedCellIds.length >= 2) {
      let indexes = [],
        i;
      for (i = 0; i < selectedCellIds.length; i++) {
        // eslint-disable-next-line 
        const idx = figures.findIndex(fig => fig.id === selectedCellIds[i]);
        if (idx !== -1) {
          indexes.push(figures[idx]);
        } else if (selectedCellIds[i] === '-1') {
          indexes.push(null);
        }
      }
      let idxs = [];
      let idxSubtitle = [];
      indexes.forEach(i => {
        if (i && i.det_class === 'subtitle') {
          idxSubtitle.push(i);
        } else {
          idxs.push(i);
        }
      });
    }

    let renderedLoader = null;
    if (loading) {
      renderedLoader = <Loader active size="large" />;
    }

    const isShowSuccessDialog = rereadSuccess;
    return (
      <Provider>
        <div
          style={{
            cursor: drawing ? 'crosshair' : 'grab',
            height: 'calc(100% - 54px)',
            ...style,
          }}
          id="pdfImage"
        >
          <div
            style={{
              height: '54px',
              width: '100%',
              background: '#fff',
              borderBottom: '1px solid #D4D4D9',
              position: 'absolute',
              minWidth: '450px',
            }}
          >
            <MapHeader
              ref={this.headerRef}
              mapRef={this.mapRef}
              thumbnailRef={thumbnailRef}
              textMappingRef={textMappingRef}
              isEditing={isEditing}
              loading={loading}
              clickFlag={clickFlag}
              height={height}
              width={width}
              zoomDefault={zoomDefault}
              zoom={zoom}
              pdfId={pdfId}
              changeTitleLoading={changeTitleLoading}
              handleChangeTitleLoading={load => {
                this.setState({ changeTitleLoading: load });
              }}
              isRpaOpen={this.props.isRpaOpen}
              toggleRePredict={this.props.toggleRePredict}
              handelShowPredictLoading={handelShowPredictLoading}
              handleUnuseImage={this.props.handleUnuseImage}
              projectId={this.props.projectId}
              isNotOwner={isNotOwner}
              onShowExclusionControl={onShowExclusionControl}
              pdfInfo={this.props.pdfInfo}
              currentPage={this.props.currentPage}
            />
          </div>
          {changeTitleLoading && (
            <Dimmer active inverted>
              <Loader inverted size="large" />
            </Dimmer>
          )}
          <div id="snackbar" className={isShowSuccessDialog ? `show reread-success-snackbar` : `reread-success-snackbar`}>
            {rereadSuccess && <label className="reread-success-snackbar-text">再読取が完了しました</label>}
          </div>
          <Slide direction="up" in={changeOwnerSuccess}>
            <div
              className="canvas-success-slide"
              style={{
                display: changeOwnerSuccess ? 'block' : 'none',
              }}
            >
              {intl.get('_predict.detail.dialog.The person in charge has been set')}
            </div>
          </Slide>
          <Slide direction="up" in={screenUpdatedSuccess}>
            <div
              className="canvas-success-slide"
              style={{
                display: screenUpdatedSuccess ? 'block' : 'none',
              }}
            >
              {intl.get('_predict.detail.dialog.The screen has been updated')}
            </div>
          </Slide>
          <MapContent
            mapRef={this.mapRef}
            zoomDefault={zoomDefault}
            centerDefault={centerDefault}
            height={height}
            width={width}
            setMapState={this.handleSetMapState}
            handleViewPortChange={this.handleViewPortChange}
            scrollWheelZoomFlag={scrollWheelZoomFlag}
            leftWidth={this.props.leftWidth}
            zoom={zoom}
            figuresDOM={figuresDOM}
            hotkeysDOM={hotkeysDOM}
            renderedLoader={renderedLoader}
            url={url}
            bounds={bounds}
            currentPage={this.props.currentPage}
          />
          {this.props.children}
        </div>
      </Provider>
    );
  }
}

export default withBounds(Canvas);
