import React from 'react'

import { MDBDataTable } from 'mdbreact'
import { Button, Segment } from 'semantic-ui-react'
import { confirmAlert } from 'react-confirm-alert';
import UserEdit from './Edit'
import api from '../../api'
import intl from 'react-intl-universal';
import ImportUser from './ImportUser';
import SearchForm from './SearchForm';
import { getCsrfToken } from '../../common/CookieUtil';
import { getCookie } from '../../common/CookieUtil';

export default class User extends React.Component {
  state = {
    isLoaded: true,
    rows: [],
    isShowEdit: false,
    editUser: {},
    roles: [],
    isShowImport: false,
    searchForm: {},
    tenantID: getCookie('tenant_id'),
    sortColumn: '',
    sortDirection: '',
    columns: []
  }

  constructor(props) {
    super(props)
    this.handleDel = this.handleDel.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.toggleEdit = this.toggleEdit.bind(this)
    this.getUsers = this.getUsers.bind(this)
    this.handleResetPassword = this.handleResetPassword.bind(this)
    
    this.handleExport = this.handleExport.bind(this);
    this.handleImport = this.handleImport.bind(this);
    this.toggleImport = this.toggleImport.bind(this);
    
  }

  async componentDidMount() {
    const { data: roles } = await api.get(`/dppapi/role`);
    this.setState({
        roles: roles
      })
  }

  async getUsers(condition) {

    if(!condition){this.setForm({ oktaAccount: '', name: '', email: '', roleId: ''})}
    this.setState({isLoaded: false});
    const { oktaAccount = '', name = '', email = '', roleId = '' } = condition ? this.state.searchForm : {};
    const { data: users } = await api.get(`/dppapi/user?oktaAccount=${oktaAccount}&name=${name}&email=${email}&roleId=${roleId}`)
    const { roles } =this.state;
    let rows = []
    if (users) {
      rows = users.map( (user, index) => {
        return {
          ...user,
          num: user.id,
          status_icon: (user.status === 0 ? <div className="user-table-status font-blue" searchvalue="利用可能"><div className="status-circle status-blue" />利用可能</div> : <div className="user-table-status font-red " searchvalue="ロック中"><div className="status-circle status-red" />ロック中</div>),
          role_name: (roles.find(role => {
            return user.roleId === role.id
          }) || {} )['name'],
          operation: (
          <div>
            <Button icon="pencil" label={intl.get('_user.user.User.Edit')} size="tiny" onClick={() => this.handleEdit(user)}/>
            <Button icon="pencil" label={intl.get('_user.user.User.Reset password')} size="tiny" onClick={() => this.handleResetPassword(user)}/>
            <Button 
              icon="trash"
              label={intl.get('_user.user.User.Delete')}
              size="tiny"
              onClick={() => this.handleDel(user)}
            />
          </div>
          )
        }
      })
      const columns = this.getColumns();
      this.setState({
        rows: rows,
        columns
      })
    }
    this.setState({isLoaded: true});
  }

  toggleEdit(isShowEdit) {
    this.setState({
      editUser: {},
      isShowEdit: isShowEdit
    })
  }

  handleEdit(user) {
    this.setState({
      isShowEdit: true,
      editUser: user
    })
  }

  async handleDel(user) {
    confirmAlert({
      title: intl.get('_common.Delete'),
      message: intl.get('_user.user.User.Are you sure to do this?'),
      buttons: [
        {
          className: 'user-botton-text confirmAlert-button-left',
          label: intl.get('_user.user.User.Cancel'),
        },
        {
          className: 'primary-btn confirmAlert-button-right',
          label: intl.get('_common.Delete'),
          onClick: async () => {
            await api.delete(`/dppapi/user/${user.id}`)
            this.getUsers()
          },
        },
      ],
    })
  }

  async handleResetPassword(user) {
    confirmAlert({
      title: intl.get('_user.user.User.Reset password'),
      message: intl.get('_user.user.User.Are you sure to reset password?'),
      buttons: [
        {
          className: 'user-botton-text confirmAlert-button-left',
          label: intl.get('_common.Cancel'),
        },
        {
          className: 'primary-btn confirmAlert-button-right',
          label: intl.get('_user.user.User.Reset'),
          onClick: async () => {
            try{
              const rslt = await api.put(`/dppapi/password/admin/reset/${user.id}`);
              if (rslt.status === 200) {
                confirmAlert({
                  title: '',
                  message: intl.get('_user.user.User.EmailSuccessed'),
                  buttons: [
                    {
                      className: 'user-botton-text confirmAlert-button-left',
                      label: intl.get('_user.user.User.Close')
                    }
                  ],
                })
              }
            }catch(e){
              confirmAlert({
                title: '',
                message: e.data.message,
                buttons: [
                  {
                    label: intl.get('_common.Confirm')
                  }
                ],
              })
            }
          },
        },
      ],
    })
  }
  handleImport() {
    this.toggleImport({
      isShowImport: true
    });
  }
  toggleImport({ isShowImport }) {
    this.setState({
      isShowImport: isShowImport
    });
  }
  async handleExport() {
    confirmAlert({
      message: intl.get('_user.user.User.Do you want to get user'),
      buttons: [
        {
          className: 'user-botton-text confirmAlert-button-left',
          label: intl.get('_common.Cancel'),
        },
        {
          className: 'primary-btn confirmAlert-button-right',
          label: intl.get('_common.Download'),
          onClick: async () => {
            let a = document.createElement('a');
            a.href = `/dppapi/user/export?token=${getCsrfToken()}`;
            a.click();
            a.remove();
            this.getUsers()
          },
        },
      ],
    })
  }
  setForm = (searchForm) => {
    this.setState({
      searchForm: { ...this.state.searchForm, ...searchForm },
    });
  };

  sortRows = (value) => {
    let {rows, sortColumn, sortDirection} = this.state;
    let {column} = value;

    const dir = column === sortColumn ? sortDirection==='asc'? 'desc' : 'asc' : 'desc';
    if(column === 'status_icon'){
      if (dir === 'asc') {
        rows.sort(function(a, b) { return a.status_icon.props.searchvalue < b.status_icon.props.searchvalue ? 1 : -1;} );
      } else {
        rows.sort(function(a, b) { return a.status_icon.props.searchvalue > b.status_icon.props.searchvalue ? 1 : -1;} );
      }
    }else if(column){
      if (dir === 'asc') {
        rows.sort(function(a, b) { return a[column] < b[column] ? 1 : -1;} );
      } else {
        rows.sort(function(a, b) { return a[column] > b[column] ? 1 : -1;} );
      }
    }else{
      rows.sort(function(a, b) { return a.id< b.id ? 1 : -1;} );
    }
    const columns = this.getColumns(column,dir);
    if(column){
      this.setState({
        rows: rows,
        sortColumn: column,
        sortDirection: dir,
        columns
      })
    }else{
      this.setState({
        columns
      })
    }
  }

  getColumns = (column='', dir='') =>{
    const columns = this.state.tenantID === '1' ? [
      {
        label: intl.get('_user.user.User.Num'),
        field: 'num',
        sort: this.getSortDirection('num',column, dir),
        width: 100
      },
      {
        label: intl.get('_user.user.User.Status'),
        field: 'status_icon',
        sort: this.getSortDirection('status_icon',column, dir),
        width: 200
      },
      {
        label: intl.get('_user.user.User.Account'),
        field: 'oktaAccount',
        sort: this.getSortDirection('oktaAccount',column, dir),
        width: 100
      },
      {
        label: intl.get('_user.user.User.Name'),
        field: 'name',
        sort: this.getSortDirection('name',column, dir),
        width: 100
      },
      {
        label: intl.get('_user.user.User.E-mail'),
        field: 'email',
        sort: this.getSortDirection('email',column, dir),
        width: 200
      },
      {
        label: intl.get('_user.user.User.Role'),
        field: 'role_name',
        sort: this.getSortDirection('role_name',column, dir),
        width: 200
      },
      {
        field: 'operation',
        sort: 'disabled',
        width: 400
      }
    ] : 
    [
      {
        label: intl.get('_user.user.User.Num'),
        field: 'num',
        sort: this.getSortDirection('num',column, dir),
        width: 100
      },
      {
        label: intl.get('_user.user.User.Status'),
        field: 'status_icon',
        sort: this.getSortDirection('status_icon',column, dir),
        width: 200
      },
      {
        label: intl.get('_user.user.User.Name'),
        field: 'name',
        sort: this.getSortDirection('name',column, dir),
        width: 100
      },
      {
        label: intl.get('_user.user.User.E-mail'),
        field: 'email',
        sort: this.getSortDirection('email',column, dir),
        width: 200
      },
      {
        label: intl.get('_user.user.User.Role'),
        field: 'role_name',
        sort: this.getSortDirection('role_name',column, dir),
        width: 200
      },
      {
        field: 'operation',
        sort: 'disabled',
        width: 400
      }
    ];
    return columns;
  }

  getSortDirection = (column, sortColumn, sortDirection) => {
    const { rows } = this.state;
    if(!rows){
      return 'asc';
    }
    return !sortColumn? 'asc' : (sortColumn===column ? sortDirection : '');
  }

  render() {
    const { rows, columns } = this.state;

    const data = {
      columns: columns,
      rows: rows
    }

    return (
      <div>
      <SearchForm 
      loading={!this.state.isLoaded}
      form={this.state.searchForm}
      roles={this.state.roles}
      setForm={this.setForm} 
      handleSearch={this.getUsers}
      handleExport={this.handleExport}
      handleImport={this.handleImport}
      handleEdit={this.handleEdit}
      />

      <Segment>
        <MDBDataTable
          theadColor="common-search-table-header"
          btn
          striped
          bordered
          entriesLabel='表示件数'
          hover
          searching={false}
          data={data}
          infoLabel={["", "-", "/", "件"]} 
          paginationLabel={["前へ", "次へ"]}
          noRecordsFoundLabel="該当データがありません。条件を変更の上、再度検索してください。"
          onSort={ value => this.sortRows(value) }
        />
      </Segment>

      <UserEdit isShowEdit={this.state.isShowEdit} roles={this.state.roles} user={this.state.editUser} toggleEdit={this.toggleEdit} getUsers={this.getUsers}/>
      <ImportUser
          isShowImport={this.state.isShowImport}
          toggleImport={this.toggleImport}
          getUsers={this.getUsers}
        />
    </div>)
  }
}