import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import './SystemFeedApp.css';
import Canvas from '../Canvas';
import { confirmAlert } from 'react-confirm-alert';
import { colors } from '../../label/label-app/utils';
import { withHistory } from '../SystemwithHistory';
import { withLoadImageData } from '../SystemwithLoadImageData';
import { Table, Dropdown, Button, TextArea } from 'semantic-ui-react';
import intl from 'react-intl-universal';
import api from '../../api';

class SystemFeedApp extends Component {
  constructor(props) {
    super(props);
    const { labels, loading } = props;
    const toggles = {};
    labels.map(label => (toggles[label.id] = true));
    this.state = {
      selected: null,
      toggles,
      selectedFigureId: null,

      // UI
      reassigning: { status: false, type: null },
      // selected key id and value id
      selectedBoxIds: [],
      message: null,
      fileType: null,
      memoText: null,
      feedMemo: this.props.systemInfoData.data.memo,
      feedType: this.props.systemInfoData.data.type,
      historyTime: null,
      saveBtnDisabled: true,
      reportInfo: {},
      loading: loading,
    };

    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.canvasRef = React.createRef();
    this.keyValueBarElm = null;
  }

  handleSelectionChange(figureId) {
    if (figureId) {
      this.setState({ selectedFigureId: figureId });
    } else {
      this.setState({
        reassigning: { status: false, type: null },
        selectedFigureId: null,
      });
    }
  }

  renderRow = systemResult => {
    const dom = systemResult.map((items, idx) => {
      const row = (
        <Table.Row id={items.det_text} key={idx}>
          {items.map((r, i) => {
            return (
              <Table.Cell key={i} style={{ background: '#f9fafb' }}>
                {r}
              </Table.Cell>
            );
          })}
        </Table.Row>
      );
      return <React.Fragment key={idx}>{row}</React.Fragment>;
    });
    return dom;
  };

  renderMeta = (metaData) => {
    const dom = Object.keys(metaData).map((key, idx) => {
      const row =
        <Table.Row id={key} key={String(idx) + "_row"}>
          <Table.Cell key={String(idx) + "_cell_0"} style={{ background: '#f9fafb' }}>{key}</Table.Cell>
          <Table.Cell key={String(idx) + "_cell_1"} style={{ background: '#f9fafb' }}>{JSON.stringify(metaData[key])}</Table.Cell>
        </Table.Row >
      return <React.Fragment key={String(idx) + "_fragment"}>{row}</React.Fragment>
    })
    return dom
  }

  handleSave = () => {
    const { reportInfo, feedType, fileType, feedMemo, memoText } = this.state
    this.setState({ 
      reportInfo: { 
        ...reportInfo, 
        type: fileType ? fileType : feedType, 
        memo: memoText ? memoText : feedMemo 
      },
      message: null 
    })
    confirmAlert({
      title: intl.get('_system.feedback.ApiEdit.Save'),
      message: intl.get('_system.feedback.ApiEdit.Do you want to save?'),
      buttons: [
        {
          label: intl.get('_system.feedback.ApiEdit.Save'),
          onClick: async () => {
            await api.request({
              url: `/dppapi/system/feedback/type/${this.state.reportInfo.id}`,
              method: 'PUT',
              data: this.state.reportInfo,
            });
            this.setState({ 
              saveBtnDisabled: true,
              feedType: fileType ? fileType : feedType,
              feedMemo: memoText ? memoText : feedMemo,
            })
          },
        },
        {
          label: intl.get('_system.feedback.ApiEdit.Cancel'),
        },
      ],
    });
  };

  handleChange = (value, type) => {
    const { fileType, feedType, memoText, feedMemo } = this.state;
    if(type === 'fileType'){
      this.setState({ 
        fileType: value, 
        saveBtnDisabled: value === feedType && (memoText === feedMemo || memoText === null) ? true : false,
        reportInfo: this.props.systemInfoData.data
      });
    } else {
      this.setState({ 
        memoText: value,
        saveBtnDisabled: value === feedMemo && (fileType === feedType || fileType === null) ? true : false,
        reportInfo: this.props.systemInfoData.data
      });
    }
  }

  handleReload = (id) => {
    confirmAlert({
      title: intl.get('_common.Confirm'),
      message: intl.get('_system.feedback.ApiEdit.Update contents'),
      buttons: [
        {
          label: intl.get('_system.feedback.ApiEdit.OK'),
          onClick: () => {
            if (id) {
              this.props.onHistoryReload(id)
            } else {
              this.props.onRefetch()
              this.setState({ historyTime: null});
            }
          }
        },
        {
          label: intl.get('_system.feedback.ApiEdit.Cancel'),
        },
      ],
    });
  }
  
  onMouseDown = (e) => {
    var leftChild = document.getElementById('leftChild');
    var rightChild = document.getElementById('rightChild');
    var iEvent = e;
    var dx = iEvent.clientX;     
    var leftWidth = leftChild.offsetWidth;
    var rightWidth = rightChild.offsetWidth;
    document.onmousemove = (e) => {
      var iEvent = e;
      var diff = iEvent.clientX - dx;
      if ((leftWidth + diff) > 0 && (rightWidth - diff) > 350) {
        leftChild.style.width = (leftWidth + diff) / (leftWidth + rightWidth) * 100 + '%';
        rightChild.style.width = `calc(`+(rightWidth - diff) / (leftWidth + rightWidth) * 100 + `% - 5px)`;
      }
    };
    document.onmouseup = () => {
      this.setState({leftWidth:Date.now()});

      document.onmousemove = null;
      document.onmouseup = null;
    };
    return false;
  }

  handleHistoryChange = (value) => {
    this.setState({ historyTime: value});
  }

  render() {
    const {
      labels,
      imageUrl,
      figures,
      unfinishedFigure,
      height,
      width,
      systemInfoData: {
        data: {
          fileName, ipAddress, macAddress, userName, comment
        }
      },
      systemPredictResult,
      filterTypes,
      historyOptions,
      predictionMetaData,
      onRePredict,
      onCsvDownload,
      loading
    } = this.props;
    const {
      toggles,
      fileType,
      memoText,
      feedMemo,
      feedType,
      historyTime,
      selectedBoxIds,
      saveBtnDisabled,
    } = this.state;

    const systemResult = systemPredictResult.data.texts || [];
    const allFigures = [];
    labels.forEach((label, i) => {
      figures[label.id].forEach(figure => {
        if (
          toggles[label.id] &&
          (label.type === 'bbox' || label.type === 'polygon')
        ) {
          allFigures.push({
            color: colors[i],
            points: figure.points,
            id: figure.id,
            type: figure.type,
            tracingOptions: figure.tracingOptions,
            det_text: figure.det_text,
            linkTo: figure.linkTo,
          });
        }
      });
    });
    figures.__temp.forEach(figure => {
      allFigures.push({
        color: 'gray',
        ...figure,
      });
    });

    return (
      <div className="pdf-portal-root">
        <div id='father' className="pdf-portal-father">
          <div id='leftChild' className="pdf-portal-left-child" >
            <div style={{ position: 'relative', height: '100%', flex: 4, display: 'flex', flexDirection: 'row' }}>
              <Canvas
                url={imageUrl}
                height={height}
                width={width}
                fileName={fileName}
                figures={allFigures}
                unfinishedFigure={unfinishedFigure}
                onReassignment={type =>
                  this.setState({ reassigning: { status: true, type } })
                }
                onSelectionChange={this.handleSelectionChange}
                onRePredict={onRePredict}
                onCsvDownload={onCsvDownload}
                ref={this.canvasRef}
                style={{ flex: 1 }}
                selectedBoxIds={selectedBoxIds}
                loading={loading}
              />
            </div>
          </div>
          <div id="line" className="pdf-portal-line"  onMouseDown={this.onMouseDown} />
          <div id="rightChild" className="pdf-portal-right-child" >
            <div style={{ height: '30vh',overflow:'auto' }}>
              <div style={{fontSize: '15px', fontWeight: 'bold' }}>{intl.get('_system.feedback.index.Info')}</div>
              <div className='table-header'>
                <div className='headerTitle'>{intl.get('_system.feedback.index.FileName')}</div>
                <div className='headerTitle'>{intl.get('_system.feedback.index.UserName')}</div>
                <div className='headerTitle'>{intl.get('_system.feedback.index.MacAddress')}</div>
                <div className='headerTitle'>{intl.get('_system.feedback.index.IpAddress')}</div>
              </div>
              <div className='table-body'>
                <div className='content-box'>{fileName}</div>
                <div className='content-box'>{userName}</div>
                <div className='content-box'>{macAddress}</div>
                <div className='content-box'>{ipAddress}</div>
              </div>
              <div style={{display: 'flex' }}>
                <div style={{ width: '50%' }}>
                  <div style={{fontSize: '15px', fontWeight: 'bold' }}>{intl.get('_system.feedback.parseInfo.Comment')}</div>
                  <TextArea
                    style={{ fontSize: 14, padding: 14, width: '95%'}}
                    placeholder="Comment"
                    disabled
                    defaultValue={comment}
                    name="comment" rows="3"　/></div>
                <div style={{ width: '50%' }}>
                  <div style={{fontSize: '15px', fontWeight: 'bold' }}>{intl.get('_system.feedback.parseInfo.Memo')}</div>
                  <TextArea
                    style={{ fontSize: 14, padding: 14, width: '95%'}}
                    placeholder="Memo"
                    defaultValue={memoText ? memoText : feedMemo}
                    name="memo" rows="3"
                    onChange={(event, { value }) => this.handleChange(value, 'memoText')}
                    maxLength="4000" /></div>
              </div>
              {filterTypes && <div>
                <p style={{fontSize: '15px', fontWeight: 'bold', marginBottom: '2px' }}>
                  {intl.get('_system.feedback.parseInfo.Type')}
                </p>
                <div style={{display: 'flex'}}>
                  <Dropdown
                    placeholder='Select Type'
                    options={filterTypes}
                    value={fileType ? fileType : feedType}
                    search
                    selection
                    clearable
                    multiple={false}
                    disabled={loading}
                    onChange={(event, { value }) =>
                      this.handleChange(value, 'fileType')
                    }
                    style={{ width: 'auto'}}
                  />
                  <div style={{justifyContent: 'flex-end'}}>
                    <Button style={{marginLeft: '10px'}} color='blue' disabled={saveBtnDisabled || loading} onClick={() => this.handleSave()}>
                      {intl.get('_common.Save')}
                    </Button>
                  </div>
                </div>
              </div>}
              { predictionMetaData && Object.keys(predictionMetaData).length ? (
                <div>
                  <div style={{fontSize: '15px', fontWeight: 'bold', marginTop: '5px' }}>{intl.get('_system.feedback.parseInfo.MetaData')}</div>
                  <div style={{ height: 'auto', overflow: 'auto' }}>
                    <Table celled selectable style={{ width: '100%' }}>
                      <Table.Body>
                        {this.renderMeta(predictionMetaData)}
                      </Table.Body>
                    </Table>
                  </div>
                </div>
              ) : (
                null
              )}
              {historyOptions && <div>
                <p style={{fontSize: '15px', fontWeight: 'bold', marginBottom: '2px' }}>
                  {intl.get('_system.feedback.parseInfo.History')}
                </p>
                <div style={{display: 'flex'}}>
                  <Dropdown
                    placeholder='Select History'
                    options={historyOptions}
                    value={historyTime}
                    search
                    selection
                    clearable
                    disabled={loading}
                    multiple={false}
                    onChange={(event, { value }) =>
                      this.handleHistoryChange(value)
                    }
                    style={{ width: '400px'}}
                  />
                  <div style={{justifyContent: 'flex-end'}}>
                    <Button style={{marginLeft: '10px'}} color='blue' disabled={!historyTime || loading} onClick={() => this.handleReload(historyTime)}>
                    表示
                    </Button>
                    <Button style={{marginLeft: '10px'}} color='blue' disabled={loading} onClick={() => this.handleReload()}>
                    最新へ
                    </Button>
                  </div>
                </div>
              </div>}
            </div>
            <div>
              <div style={{fontSize: '15px', fontWeight: 'bold', marginTop: '5px' }}>{intl.get('_system.feedback.parseInfo.Data')}</div>
              <div style={{ height: '65vh', overflow: 'auto' }}>
                <Table celled selectable style={{ width: '100%' }}>
                  <Table.Body>
                    {this.renderRow(systemResult)}
                  </Table.Body>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withLoadImageData(withHistory(SystemFeedApp));
