import React from 'react'
import './UserApp.css'
import Menubar from '../common/Menubar'
import { Container, Menu, Header } from 'semantic-ui-react'
import User from './user/User'
import Role from './role/Role'
import Permission from './permission/Permission'
import intl from 'react-intl-universal';
import api from '../api';
import { filterTab } from '../common/AuthUtil'
export default class UserApp extends React.Component {
  state = {
    activeTab: '',
    tabs: [
      {
        key: 'user/user',
        label: intl.get('_user.UserApp.User'),
        component: 'User'
      },
      {
        key: 'user/role',
        label: intl.get('_user.UserApp.Role'),
        component: 'Role'
      },
      // {
      //   label: 'Permission',
      //   component: 'Permission'
      // },
    ],
    user: {}
  }

  constructor(props) {
    super(props)
    this.handleSwitchTab = this.handleSwitchTab.bind(this)
  }

  async componentDidMount() {
    const info = (await api.get(`/dppapi/info`)).data;
    const policy = info.policy

    const allowTabs = filterTab(this.state.tabs, policy)
    const activeTab = allowTabs.length > 0 ? allowTabs[0]['component']: ''
    this.setState({user: info ? info : '', tabs: allowTabs, activeTab});
  }

  handleSwitchTab(e, { name, component }) {
    this.setState({
      activeTab: component
    })
  }

  render() {
    const { activeTab, tabs, user } = this.state
    return (
      <Menubar active="user">
        { (user && user.tenantName)? <Header as="h1">{user.tenantName}</Header>: null}
        <Container>
          <Menu pointing secondary>
            { 
              tabs.map(tab => {
                return <Menu.Item
                  name = {tab.label}
                  component = {tab.component}
                  active={activeTab === tab.component}
                  onClick={this.handleSwitchTab}
                  key={tab.label}
                />
              })
            }
          </Menu>
            {
              activeTab === '' && <></>
            }
            {
              activeTab === 'User' && <User />
            }
            {
              activeTab === 'Role' && <Role />
            }
            {
              activeTab === 'Permission' && <Permission />
            }
        </Container>
      </Menubar>
    );
  }
}
