import React, { Component, useContext } from 'react';
import { Table, Popup } from 'semantic-ui-react';
import FsMappingItem from './FsMappingItem';
import { COLUMN_TYPE } from '../../Constant';
import { genId } from '../../../label/label-app/utils';
import { DetailTableHeader } from './FsConstant';
import { LinkOffIcon } from '../../../assets/icon';
import intl from 'react-intl-universal';
import { isEqual } from 'lodash';
import EditBox from './EditBox';
import { AnalyticsServiceContext } from '../../../common/Analytics/AnalyticsServiceProvider';
import { EventName, EventType, PredictDetailPageLocation, ViewMode } from '../../../common/Analytics/analyticsMetrics';

function columnStyles(idx, length) {
  if (idx === 0) {
    return { width: 'fit-content', paddingRight: '5px' };
  } else if (idx === 2) {
    return { width: 'fit-content', padding: '2.2px' };
  } else {
    const width = `fit-content`;
    return { width: width, padding: '5px' };
  }
}

export default class FsTextTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      ids: [],
      keepHover: false,
      popItemName: '',
    };
  }

  onHoverChange = (state, name) => this.setState({ keepHover: state, popItemName: name ? name : 'EditBox' });

  handleSetOppositeValue = (values) => {
    values.forEach(value => {
      value.invert = !value.invert;
    });
    this.props.handleChangeValue(values);
  };

  // currentBoxIdForCss is boxID selected in pdf panel
  getClass = (
    type,
    { rowId, cellId },
    selectedCell,
    currentBoxIdForCss = '',
    column
  ) => {
    let className = '';
    switch (type) {
      case 'cell':
        if (
          rowId === selectedCell.id &&
          selectedCell.cellIds.indexOf(cellId) !== -1 &&
          column === selectedCell.column
        ) {
          className = 'selected';
        }
        if (currentBoxIdForCss && cellId === currentBoxIdForCss) {
          className += ' high-light';
        }
        break;
      default:
        className = '';
    }
    return className;
  };

  renderHeader = () => {
    return (
      <Table.Header className="value-table-header">
        <Table.Row style={{ background: '#f9fafb'}} textAlign="left">
          <Table.HeaderCell style={columnStyles(0, null)} className='fs-text-table-table-wrap-table-header'>
            <span className="value-table-header-span">ページ</span>
          </Table.HeaderCell>
          {DetailTableHeader.map((col, idx) => {
            return (
              <Table.HeaderCell
                key={idx}
                style={columnStyles(1, DetailTableHeader.length)}
                className='fs-text-table-table-wrap-table-header'
              >
                {intl.get(`_predict.detail.mapping.${col.header}`)}
              </Table.HeaderCell>
            );
          })}
          <Table.HeaderCell style={columnStyles(2, null)}  className='fs-text-table-table-wrap-table-header'>
            {intl.get('_predict.detail.mapping.Lift')}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    );
  };

  renderBody = () => {
    const {
      rows,
      onMappingItemChange,
      onCheck,
      checkedList,
      onRowSelect,
      selectedCell,
      onNormalized,
      currentBoxIdForCss,
      category,
      onLinkBbox2Cell,
      selectedBBox,
      handleUnLinkScrumItem,
      onEdit,
      onGetSum,
    } = this.props;
    let filterRows = [];
    for (let i = 0; i < rows.length; i++) {
      if (
        !rows[i].scrumItem ||
        (rows[i].scrumItem &&
          rows[i].scrumItem.indexOf('当期末残高(B/S入力)') === -1)
      ) {
        filterRows.push(rows[i]);
      }
    }
    const headerOrder = DetailTableHeader.map(c => {
      return { key: c.name, autoComplete: c.autoComplete, link: c.link };
    });
    return (
      <Table.Body>
        {filterRows.length > 0 &&
          filterRows.map((row, idx) => (
            <Row
              key={idx}
              row={row}
              onMappingItemChange={onMappingItemChange}
              onCheck={onCheck}
              checkedList={checkedList}
              onRowSelect={onRowSelect}
              selectedCell={selectedCell}
              onNormalized={onNormalized}
              currentBoxIdForCss={currentBoxIdForCss}
              getClass={this.getClass}
              category={category}
              headerOrder={headerOrder}
              onLinkBbox2Cell={onLinkBbox2Cell}
              selectedBBox={selectedBBox}
              handleUnLinkScrumItem={handleUnLinkScrumItem}
              ids={this.state.ids}
              setIds={val => this.setState(val)}
              handleSetOppositeValue={this.handleSetOppositeValue}
              onEdit={onEdit}
              onHoverChange={this.onHoverChange}
              keepHover={this.state.keepHover}
              popItemName={this.state.popItemName}
              onGetSum={onGetSum}
            />
          ))}
      </Table.Body>
    );
  };

  render() {
    return (
      <div className="fs-text-table-table-wrap outer-scroll">
        <Table>
          {this.renderHeader()}
          {this.renderBody()}
        </Table>
      </div>
    );
  }
}

function renderCell(
  key,
  cell,
  category,
  id,
  pdfImageId,
  onMappingItemChange,
  selectedCell,
  currentBoxIdForCss,
  getClass,
  onRowSelect,
  headerSetting,
  idx,
  row,
  onLinkBbox2Cell,
  selectedBBox,
  isSelected,
  clickRowIds,
  keepHover,
  popItemName,
  onHoverChange,
  handleSetOppositeValue,
  onEdit,
  onGetSum
) {
  const { autoComplete, link } = headerSetting;

  const mismatchedCell = (
    <Table.Cell
      className={isSelected ? 'select-row-detail' : ''}
      verticalAlign="middle"
      key={`${id}_${genId()}`}
      style={columnStyles(1, DetailTableHeader.length)}
      onClick={clickRowIds}
    >
      {' '}
    </Table.Cell>
  );

  const column = DetailTableHeader.find(col => col.name === key);
  const type = column ? column.type : undefined;
  let renderEditBox;
  switch (type) {
    case COLUMN_TYPE.SELECTBBOX:
      // selectBBox type column must be {id:xxx, text:xxx}
      if (cell.id === undefined || cell.text === undefined) {
        return mismatchedCell;
      }
      var cellText = cell.text;
      if (key === 'value') {
        if (cell.invert) {
          if (!cellText || cellText[0] === '0') {
            // vString = vString;
          } else if (cellText[0] !== '-') {
            cellText = '-' + cellText;
          } else {
            cellText = cellText.substring(1, cellText.length);
          }
        }
        cellText = onGetSum([cell])
        renderEditBox = (parentElement) => {
          return <EditBox
            rowScrumItem={row['item'].text}
            rowValues={[cell]}
            onHoverChange={onHoverChange}
            parentElement={parentElement}
            isDetail={true}
            handleSetOppositeValue={handleSetOppositeValue}
            onEdit={onEdit}
            showEditBtn={false}
          />;
        }
      }

      return (
        <Table.Cell
          verticalAlign="middle"
          key={`${id}_${cell.id}_${idx}`}
          className={`td-text-selectable ${getClass(
            'cell',
            { rowId: id, cellId: cell.id },
            selectedCell,
            currentBoxIdForCss,
            key
          )} ${isSelected ? 'select-row-detail' : ''} ${headerSetting.key === 'value' ? 'page-num edit-box-detail-td' : ''}`}
          style={columnStyles(1, DetailTableHeader.length)}
          // onClick={() => {
          //   if (selectedBBox.id === '' && selectedBBox.text === '') {
          //     onRowSelect(id, pdfImageId, [cell.id], key);
          //   } else {
          //     onLinkBbox2Cell(id, cell.id, key);
          //     onRowSelect(id, pdfImageId, [selectedBBox.id], key);
          //   }
          // }}
          onMouseEnter={() => { key === 'value' && onHoverChange(true, row.item.text) }}
          onMouseLeave={() => { key === 'value' && onHoverChange(false, row.item.text) }}
          onClick={clickRowIds}
        >
          
          {key === 'value' ?
            renderEditBox(
              <div
                className={`${keepHover && popItemName === row.item.text ?
                  'edit-box-div-letter' : ''} ${isSelected ? 'select-row-detail-boldFont'
                    : ''}`}>
                {cellText}
              </div>
            )
            : <span>{cellText}</span>
          }
        </Table.Cell>
      );
    case COLUMN_TYPE.INPUTTEXT:
      return (
        <Table.Cell
          className={isSelected ? 'select-row-detail' : ''}
          verticalAlign="middle"
          key={`${id}_${key}`}
          style={columnStyles(1, DetailTableHeader.length)}
        >
          <FsMappingItem
            id={id}
            options={category}
            value={cell}
            link={link}
            onMappingItemChange={onMappingItemChange}
            category={key}
            type={type}
            row={row}
          />
        </Table.Cell>
      );
    case COLUMN_TYPE.SELECTION:
      // sel type column must be string
      if (typeof cell !== 'string') {
        return mismatchedCell;
      }
      return (
        <Table.Cell
          verticalAlign="middle"
          className={isSelected ? 'select-row-detail' : ''}
          style={columnStyles(1, DetailTableHeader.length)}
          onClick={clickRowIds}
        >
          <FsMappingItem
            id={id}
            options={category[autoComplete] ? category[autoComplete] : []}
            value={cell}
            link={link}
            onMappingItemChange={onMappingItemChange}
            category={key}
            type={type}
          />
        </Table.Cell>
      );
    default:
      return (
        <Table.Cell
          onClick={clickRowIds}
          verticalAlign="middle"
          className={isSelected ? 'select-row-detail' : ''}
          style={columnStyles(1, DetailTableHeader.length)}
        />
      );
  }
}

const Row = ({
  row,
  onMappingItemChange,
  onRowSelect,
  selectedCell,
  onNormalized,
  currentBoxIdForCss,
  getClass,
  category,
  headerOrder,
  onLinkBbox2Cell,
  selectedBBox,
  handleUnLinkScrumItem,
  ids,
  setIds,
  handleSetOppositeValue,
  onEdit,
  onHoverChange,
  keepHover,
  popItemName,
  onGetSum,
}) => {
  const {predictDetailViewMode , sendPredictBeautifyDetailMetrics} = useContext(AnalyticsServiceContext)

  const { id, pdfImageId } = row;
  let isSelected = false;
  if (
    selectedCell.cellIds &&
    selectedCell.cellIds.length > 0 &&
    ids &&
    ids.length > 0
  ) {
    isSelected =
      isEqual(selectedCell.cellIds.sort(), ids.sort()) &&
      id === selectedCell.id;
  }

  const clickRowIds = () => {
    const cellIds = [];
    Object.keys(row).forEach(key => {
      if (row[key].id !== undefined) {
        cellIds.push(row[key].id);
      }
    });
    onRowSelect(id, pdfImageId, cellIds, null);
    setIds({ ids: cellIds });
  };

  return (
    <Table.Row key={id} textAlign="left" className='edit-box-table-tr'>
        <Table.Cell
          verticalAlign="middle"
          style={columnStyles(0, DetailTableHeader.length)}
          className={`page-num ${isSelected ? 'select-row-detail-start' : ''}`}
          onClick={clickRowIds}
        >
          <span className='page-num-span'>{onNormalized(pdfImageId)}</span>
        </Table.Cell>
      {headerOrder.map((header, idx) => {
        return renderCell(
          header.key,
          row[header.key],
          category,
          id,
          pdfImageId,
          onMappingItemChange,
          selectedCell,
          currentBoxIdForCss,
          getClass,
          onRowSelect,
          header,
          idx,
          row,
          onLinkBbox2Cell,
          selectedBBox,
          isSelected,
          clickRowIds,
          keepHover,
          popItemName,
          onHoverChange,
          handleSetOppositeValue,
          onEdit,
          onGetSum
        );
      })}
      <Table.Cell
        verticalAlign="middle"
        textAlign="center"
        className={isSelected ? 'select-row-detail-end' : ''}
      >
        <span className="board-item-wrap-bottom-icon"
          style={{ marginLeft: '-2.8px' }}
        >
          <Popup
            trigger={
              <span
                id="ga-predict-detail-mapping-listview-item-unlink"
                className="board-item-wrap-bottom-icon close-btn-icon-link"
                onClick={() => {
                  if (row.value) {
                    handleUnLinkScrumItem(row.value.id);
                    if(predictDetailViewMode !== "" ){
                      sendPredictBeautifyDetailMetrics({
                        event_type: EventType.Action,
                        event_name: EventName.UnlinkScrumItem,
                        custom_parameter: {
                          location : PredictDetailPageLocation.ScrumDetailItem,
                          view_mode : ViewMode.List,
                        },
                      })
                    }
                  }
                }}
              >
                <LinkOffIcon size={'20px'} />
              </span>
            }
            position="right center"
            content={intl.get('_predict.detail.mapping.Cancel cooperation')}
            on="hover"
            hideOnScroll
            inverted
          />
        </span>
      </Table.Cell>
    </Table.Row>
  );
};
