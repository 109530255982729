import React, { useContext, useEffect, useState } from 'react';
import api from '../../api';
import { Button, Checkbox ,Divider } from 'semantic-ui-react';
import { AnalyticsServiceContext } from '../../common/Analytics/AnalyticsServiceProvider';
import { EventName, EventType, FileOpenSource, PageName } from '../../common/Analytics/analyticsMetrics';
import { CancelIcon, ErrorIcon, ImgDrugFileIcon } from '../../assets/icon';
import Slide from '@material-ui/core/Slide';

function FilesList(props) {
  const { files , deleteFile, executing } = props;
  return(
    <> 
    {files.map((file, index) => (
      <div key={index}>
        <div className='predict-admin-management-upload-result-file-container'>
          <div className='predict-admin-management-upload-result-file-name'>{file.path}</div>
          {!executing && <CancelIcon color='#909090' size={'24px'} className='edit-box-icon-cancel' 
           onClick={() => { deleteFile(index)}}/>}
        </div>
        <Divider fitted/>
      </div>
    ))}
    </>
  )
}

function PdfUpload(props) {
  const { open ,files , setFiles ,onClearErrFiles ,errorMessage , setErrorMessage , errFiles , setErrFiles } = props;

  const [loading, setLoading] = useState(false)
  const [isUploadedButton , setIsUploadedButton] = useState(false)
  const { sendMetrics } = useContext(AnalyticsServiceContext);
  //const [isDragFiles , setIsDragFiles] = useState(false)
  const [showUploadSuccessMsg , setShowUploadSuccessMsg] = useState(false)

  const parsingTypes = {
    "1": "貸借対照表",
    "2": "損益計算書",
    "3": "株主資本等変動計算書",
    "4": "製造原価明細表",
    "5": "販売費及び一般管理費"
  };

  useEffect(() => {
    if(files.length > 0){
        sendMetrics({
          page_name: PageName.PredictPage,
          event_type: EventType.Action,
          event_name: EventName.FileOpen,
          custom_parameter: {
            source : isUploadedButton ? FileOpenSource.Button : FileOpenSource.DragNDrop,
          },  
        },)
      setIsUploadedButton(false)
    }
  },[files])

  async function onUpload() {
    setErrorMessage("")
    const { parsingTypeIdList, predictSearchRef } = props;
    let formData = new FormData()
    files.forEach(file => {
      formData.append('pdf', file, file.name)
    })

    setLoading(true)
    const parsingType = parsingTypeIdList.map(item => {
      return parsingTypes[item];
    })
    try {
      const { data } = await api.post(`/dppapi/predict/pdf-uploads/${props.projectId}?parsingType=${JSON.stringify(parsingType)}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      if (data.success) {
        sendMetrics({
          page_name: PageName.PredictPage,
          event_type: EventType.Action,
          event_name: EventName.FileUpload,
          custom_parameter: {
            document_type_list : parsingType,
            file_num : files.length,
          },  
        })
        setFiles([]) // remove the file from the array
        setShowUploadSuccessMsg(true)
        setTimeout(()=>{
          setShowUploadSuccessMsg(false)
        },4000)
        onClearErrFiles()
      } else {
        setErrorMessage("アップロードに失敗しました");
        setFiles([]); // remove the file from the array

        const errFileDom = data.message.map(msg => (
          <li key={msg.file.originalname} style={{ wordWrap : 'break-word'}}>
            {msg.file.originalname}
          </li>
        ));
        setErrFiles(errFileDom);
      }
    } catch (e) {
      setErrorMessage("アップロードに失敗しました");
    }
    predictSearchRef.current.conditionRef.current.searchCondition();
    setLoading(false);
  }

  function handleDelete(indexToDelete) {
    const updatedFiles = files.filter((_, index) => index !== indexToDelete);
    setFiles(updatedFiles);
  }

  function handleClickUploadButton() {
    onClearErrFiles();
    open();
  }

  return (
    <div className="predict-admin-management-upload-container">
      <div className="predict-admin-title right-left-margin">決算書アップロード</div>
      <p className="predict-admin-object-title">読取対象</p>
      <div className="predict-admin-box">
        <Checkbox className="predict-admin-checkbox" label='貸借対照表' id='1_typeId' defaultChecked={props.parsingTypeIdList.includes("1")} onChange={(e, value) => props.handleCheckboxChange(e, value)}/>
        <Checkbox className="predict-admin-checkbox" label='損益計算書' id='2_typeId' defaultChecked={props.parsingTypeIdList.includes("2")} onChange={(e, value) => props.handleCheckboxChange(e, value)}/>
        <Checkbox className="predict-admin-checkbox" label='株主資本等変動計算書' id='3_typeId' defaultChecked={props.parsingTypeIdList.includes("3")} onChange={(e, value) => props.handleCheckboxChange(e, value)}/>
        <Checkbox className="predict-admin-checkbox" label='製造原価明細表' id='4_typeId' defaultChecked={props.parsingTypeIdList.includes("4")} onChange={(e, value) => props.handleCheckboxChange(e, value)}/>
        <Checkbox className="predict-admin-checkbox" label='販売費及び一般管理費' id='5_typeId' defaultChecked={props.parsingTypeIdList.includes("5")} onChange={(e, value) => props.handleCheckboxChange(e, value)}/>
      </div>
      <div className='predict-admin-management-upload-dropzone' onClick={() => onClearErrFiles()}>
        <div className='predict-admin-management-upload-fileIcon-container'>
          <ImgDrugFileIcon />
        <div className='predict-admin-upload-message'>ドラッグ&ドロップ</div>
      </div>
      <div className="predict-admin-upload-botton-label">
        または
      </div>
        <Button id="ga-predict-list-file-selection" size="mini" className="predict-admin-botton-text" onClick={handleClickUploadButton} disabled={loading}>ファイルを選択</Button>
      </div>
      <div className="right-left-margin">
        <div className='predict-admin-management-upload-result-container'>
          <span className='predict-admin-management-upload-result-file-number'>{files.length}</span>
          <span className='.predict-admin-management-upload-result-selected-message'> 件 選択中</span>
        </div>
        <Button
          loading={loading}
          className={props.projectId.length === 0 || files.length === 0 ? "primary-btn-disabled" : "primary-btn"}
          size="tiny"
          onClick={() => !loading && onUpload()}
          disabled={props.projectId.length === 0 || files.length === 0}>アップロード</Button>
        {errorMessage  && <div className='predict-admin-management-upload-error-message-container'><ErrorIcon size="24px" color="#F26868"/><div className='predict-admin-management-upload-error-message'>{errorMessage}</div></div>}
        <div style={{ marginTop: '16px' }}>{errFiles && errFiles.length > 0 ? <ul style={{ paddingLeft : "0px" , marginLeft : "20px"}}>{errFiles}</ul> : <FilesList files={files} deleteFile={handleDelete} executing={loading}/>}</div>
      </div>
      <Slide direction="up" in={showUploadSuccessMsg}><div className="file-setting-upload">ファイルをアップロードしました</div></Slide>
    </div>
  );
}

export default PdfUpload