import React from 'react';

import { MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact';
import {
  Button,
  Form,
  Dropdown,
  Message
} from 'semantic-ui-react';
import api from '../../api';
import intl from 'react-intl-universal';
import Policy, { policyList } from './Policy';
import { cloneDeep } from 'lodash'

const roleName = {
  project: 'プロジェクト管理',
  label: 'ラベリング',
  demo: 'demo',
  predict: 'ワークスペース',
  user: 'ユーザー管理',
  monitor: 'モニタリング',
  train: 'AI学習',
  system: 'システム管理',
  easyocr: 'EasyOcr',
  easyocr_user: 'easyocr_user',
  tenant_owner: 'テナント管理',
}

const roleIdMap = {
  "1": "project",
  "2": "label",
  "3": "demo",
  "4": "predict",
  "5": "user",
  "6": 'monitor',
  "7": 'train',
  "8": 'system',
  '9': 'easyocr',
  "10": 'easyocr_user',
  '11': 'tenant_owner',
}

export default class RoleEdit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      permissions: [],
      role: {},
      errors: {},
      policy: {}
    }

    this.getPermissions = this.getPermissions.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.checkForm = this.checkForm.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.clearErrors = this.clearErrors.bind(this)
    this.clearRole = this.clearRole.bind(this)
  }

  // componentWillReceiveProps() {
  //   this.getPermissions()
  //   // this.getPolicy()

  // }

  componentDidMount() { 
    this.getPermissions()
    this.getPolicy()
  }

  getPermissions() {
    const permissions = this.props.permissions
    this.setState({
      
      permissions: permissions.filter(p => p.name !== 'easyocr_user' && p.name !== 'monitor').map(p => {
        return {
          key: p.id,
          value: p.id,
          text: roleName[p.name],
        }
      })
    })
  }
  getPolicy() { 
    const { policy, permission } = this.props.role
    let tmpPolicy = []
    try {
      if (policy) tmpPolicy = JSON.parse(policy)
    } catch (error) {
      console.log('policy json format wrong')
    }

    const parsedPolicy = {}
    if (tmpPolicy.length > 0) {
      tmpPolicy.forEach(p => {
        if (!parsedPolicy[p.obj]) {
          parsedPolicy[p.obj] = [p]
        } else {
          parsedPolicy[p.obj].push(p)
        }
      })
    } else if (permission && permission.length > 0) {
      permission.forEach(rid => {
        const roleName = roleIdMap[rid]
        if (!parsedPolicy[roleName] && policyList[roleName]) { 
          parsedPolicy[roleName] =  policyList[roleName]
        }
      })
    }
    

    this.setState({policy: parsedPolicy})  
  }

  handleInput(event) {
    const {name, value} = event.target
    const role = {...this.state.role}
    role[name] = value
    this.setState({
      role: role
    })
  }

  handleChange(event, { name, value }) {
    const role = { ...this.state.role }
    const roleIds = value
    
    let policy = cloneDeep(this.state.policy)
    const roleNames = []
    roleIds.forEach(rid => {
      const roleName = roleIdMap[rid]
      roleNames.push(roleName)
      if (!policy[roleName] && policyList[roleName]) { 
        policy[roleName] =  policyList[roleName]
      }
    })
    Object.keys(policy).forEach(p => { 
      if (roleNames.indexOf(p) === -1) { 
        delete(policy[p])
      }
    })
    
    // if (roleIds.length === 0) policy = []

    role[name] = value
    this.setState({
      role: role,
      policy
    })
  }

  handlePolicyEffectChange = (obj, subObj, act, value) => {
    const  policy  = cloneDeep(this.state.policy)
    policy[obj].forEach(p => { 
      if (p.sub_obj === subObj && p.act === act) { 
        p.eft  = value === false ? "allow": "deny"
      }
    })
    this.setState({policy})
  }

  handleFocus() {
    this.clearErrors()
  }

  clearErrors() {
    this.setState({
      errors: {}
    })
  }

  clearRole() {
    this.setState({
      role: {}
    })
  }

  async handleSave() {
    const { policy } = this.state
    const parsedPolicy = []
    Object.keys(policy).forEach(roleName => { 
      policy[roleName].forEach(p => { 
        delete(p.id)
        parsedPolicy.push(p)
      })
    })
    const role = Object.assign({}, this.props.role, this.state.role, {"policy": JSON.stringify(parsedPolicy)}) 

    if(!this.checkForm(role)) {
      return
    }
    
    await api.request({
      url: `/dppapi/role${this.props.role.id ? '/' + this.props.role.id : ''}`,
      method: this.props.role.id ? 'PUT' : 'POST',
      data: Object.assign({}, role, {
        permission: role.permission.join(',')
      })
    })

    this.props.getRoles()
    this.props.toggleEdit(false)
  }

  checkForm(role) {
    const errors = {}
    if (!role.name) {
      errors['name'] = {content: 'Please enter a role name.', pointing: 'below', flag: true}
    }

    if (role.permission.length === 0) {
      errors['permission'] = {content: 'Please select a permission at least.', pointing: 'below', flag: true}
    }
    
    this.setState({
      errors: errors
    })
    return Object.keys(errors).length === 0
  }

  render() {
    const { role = {}, isShowEdit, toggleEdit } = this.props
    const { errors, policy } = this.state

    return (
      <MDBModal isOpen={isShowEdit} toggle={toggleEdit}>
        <MDBModalHeader>
          {
            role && role.id 
            ? intl.get('_user.role.Role.Edit Role')
            : intl.get('_user.role.Role.Add Role')
          }
        </MDBModalHeader>
        <MDBModalBody>
        {
          Object.keys(errors).length > 0 &&
          <Message
            error
            list={ Object.keys(errors).map(i => {
              return errors[i].content
            })}
          />
        }
          <Form>
            <Form.Field error={errors['name'] && errors['name']['flag']}>
              <label>{intl.get('_user.role.Role.Name')}</label>
              <Form.Input placeholder="Name" defaultValue={role.name} name="name" onChange={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            <Form.Field error={errors['permission'] && errors['permission']['flag']}>
              <label>{intl.get('_user.role.Role.Permission')}</label>
              <Dropdown 
                placeholder='Select permissions'
                options={this.state.permissions} 
                fluid
                multiple
                search
                selection
                pointing='left'
                defaultValue={role.permission}
                name='permission'
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                />
            </Form.Field>
            <Form.Field>
              <label>{intl.get('_user.role.Role.Policy')}</label>
              {/* <Form.TextArea placeholder="policy" defaultValue={role.policy} name="policy" onChange={this.handleInput} onFocus={this.handleFocus}/> */}
              <Policy policy={policy} handlePolicyEffectChange={this.handlePolicyEffectChange}/>
            </Form.Field>
            <Button className='primary-btn confirmAlert-button-right' floated='right' onClick={this.handleSave}>{intl.get('_common.Save')}</Button>
            <Button className='user-botton-text confirmAlert-button-left' floated='right' onClick={() => {this.props.toggleEdit(false)}}>{intl.get('_common.Cancel')}</Button>
          </Form>
        </MDBModalBody>
      </MDBModal>
    );
  }
}
