import React, { Component } from 'react';
import { genId } from './utils';
import { vectorizeSegmentation } from './tracing';
import api from '../../api';

export function withPredictions(Comp) {
  return class PredictionsLayer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        models: [],
      };

      this.makePrediction = this.makePrediction.bind(this);
    }

    async makePrediction(model, options = {}) {
      const { id } = model;
      var {
        imgB64,
        b64Scaling,
        height,
        width,
        projectId,
        imageId,
      } = this.props;

      const preRequest = await api.get(`/dppapi/labeling/${projectId}/mlmodels/${id}`);
      const preResponse = preRequest.data;
      if (!preResponse.success) {
        alert('error occur: ' + preResponse.errmsg);
        return;
      }

      var body;
      if (preResponse.isLocal) {
        body = {
          projectId: projectId,
          imageId: imageId,
        };
        b64Scaling = 1.0;
      } else {
        body = {
          instances: [
            {
              input_bytes: {
                b64: imgB64,
              },
            },
          ],
        };
      }

      const req = await api.post(`/dppapi/labeling/${projectId}/mlmodels/${id}`, body);

      const resp = req.data;

      if (model.type === 'object_detection') {
        if (resp.hasOwnProperty('success') && resp.success === false) {
          alert('error occur: ' + resp.errmsg);
          return [];
        }

        const preds = [];
        resp.predictions.forEach(
          ({
            det_class,
            det_boxes: [y1, x1, y2, x2],
            det_text,
          }) => {
            preds.push({
              det_class: det_class,
              det_text: det_text,
              type: 'bbox',
              color: 'gray',
              points: [
                { lng: x1 * width, lat: (1 - y1) * height },
                { lng: x2 * width, lat: (1 - y2) * height },
              ],
              id: genId(),
              modelId: model.id,
              linkTo: undefined,
            });
          }
        );
        return preds;
      } else if (model.type === 'semantic_segmentation') {
        const imageData = resp.predictions[0].raw_image;
        const { smoothing } = options;
        const vectors = vectorizeSegmentation(imageData, {
          scaling: b64Scaling,
          smoothing,
        });
        return vectors.map(path => ({
          type: 'polygon',
          color: 'gray',
          points: path,
          id: genId(),
          modelId: model.id,
        }));
      }

      return resp.predictions;
    }

    async componentDidMount() {
      const { projectId } = this.props;
      const models = (await api.get(`/dppapi/labeling/${projectId}/mlmodels`)).data;
      this.setState({ models });
    }

    render() {
      const { props, state } = this;
      const { imgB64, ...passedProps } = props;
      const { models } = state;
      const newProps = {
        models,
        makePrediction: this.makePrediction,
      };

      return <Comp {...newProps} {...passedProps} />;
    }
  };
}
