import React from 'react'
import { MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact'
import {
  Button,
  Form,
  Message
} from 'semantic-ui-react'
import moment from 'moment';
import api from '../../api'
import intl from 'react-intl-universal';
import '../tenants/Tenants.css';
import DatePicker from 'react-date-picker';
import { toUnixMS } from '../tenants/TimeUtil';
export default class LicenseEdit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      preferences: {},
      errors: {}
    }

    this.handleInput = this.handleInput.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.checkForm = this.checkForm.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.clearErrors = this.clearErrors.bind(this)
    this.clearPreferences = this.clearPreferences.bind(this)
  }

  handleInput(event) {
    const {name, value} = event.target
    const preferences = {...this.state.preferences}
    preferences[name] = value
    this.setState({
      preferences
    })
  }

  onChange = (name, value) => {
    const preferences = {...this.state.preferences}
    preferences[name] = value
    this.setState({ 
      preferences 
    });
  };

  handleFocus(event) {
    const { errors } = this.state
    const {name} = event.target
    delete errors[name]
    this.clearErrors(errors)
  }

  clearErrors(errors) {
    this.setState({
      errors
    })
  }

  clearPreferences() {
    this.setState({
      preferences: {}
    })
  }

  async handleSave() {
    const preferences = Object.assign({}, this.props.preferences, this.state.preferences)

    const errors = this.checkForm(preferences)
    if (Object.keys(errors).length > 0) {
      this.setState({ errors })
      return
    }
    const res = await api.request({
      url: `/dppapi/system/monitor/licensekeys`,
      method: 'PATCH',
      data: {
        keyId: preferences.keyId,
        ipFilter: preferences.ipFilter,
        description: preferences.description,
        validFrom: preferences.validFrom,
        expiredTime: preferences.expiredTime,
        easyocrEp: preferences.easyocrEp,
        tenantId: preferences.tenantId
      }
    })
    if(res.data && !res.data.success){
      const errors = {}
      if(res.data.errmsg === 'validFrom'){
        errors['validFrom'] = { content: '＜仮＞テナント設定のEasyOCR有効期間外に、ライセンスキーの有効期間が設定されています', pointing: 'below', flag: true }
      }
      if(res.data.errmsg === 'expiredTime'){
        errors['expiredTime'] = { content: '＜仮＞テナント設定のEasyOCR有効期間外に、ライセンスキーの有効期間が設定されています', pointing: 'below', flag: true }
      }
      if(res.data.errmsg === 'easyocrContract'){
        errors['easyocrContract'] = { content: 'Please enter a EasyOCR usage period  in the Tenant settings', pointing: 'below', flag: true }
      }
      this.setState({ errors })
      return
    }

    this.props.getLicenses()
    this.props.toggleEdit(false)
    this.clearErrors({})
    this.clearPreferences()
  }

  checkForm(preferences) {    
    const errors = {}
    if (!preferences.ipFilter) {
      errors['ipFilter'] = {content: 'Please enter a preferences ipFilter.', pointing: 'below', flag: true}
    }

    if (!preferences.validFrom) {
      errors['validFrom'] = { content: 'Please enter a preferences validFrom.', pointing: 'below', flag: true }
    } else { 
      preferences.validFrom = toUnixMS(moment(preferences.validFrom))
    }

    if (!preferences.expiredTime) {
      errors['expiredTime'] = { content: 'Please enter a preferences expiredTime.', pointing: 'below', flag: true }
    } else {
      preferences.expiredTime = typeof(preferences.expiredTime) === 'number' ? preferences.expiredTime : toUnixMS(moment(preferences.expiredTime).add({ h: 23, m: 59, s: 59 }))
    }
    
    return errors
  }
  getDate = (dateType) => { 
    if (this.props.preferences[dateType]) {
      return new Date(this.props.preferences[dateType])
    }  
  }
  clearDateMsg = (name) => {
    const { errors } = this.state;
    delete errors[name];
    this.clearErrors(errors);
  }

  render() {
    const { errors } = this.state;

    return (
    
      <MDBModal isOpen={this.props.isShowEdit} toggle={flag => {this.setState({errors:{}});this.props.toggleEdit(flag)}}>
        <MDBModalHeader>
          { "Modify the license key" } 
        </MDBModalHeader>
        <MDBModalBody>
        {
          Object.keys(errors).length > 0 &&
          <Message
            error
            list={ Object.keys(errors).map(i => {
              return errors[i].content
            })}
          />
        }
          <Form>
            <Form.Field>
              <label>{ "Tenant ID" }</label>
              <Form.Input placeholder="tenantId" name="tenantId" value={this.props.preferences.tenantId} disabled={true}/>
            </Form.Field>
            <Form.Field>
              <label>{ "Page Count" }</label>
              <Form.Input placeholder="pageCount" name="pageCount" value={this.props.preferences.pageCount} disabled={true}/>
            </Form.Field>
            <Form.Field>
              <label>{ "Machine Count" }</label>
              <Form.Input placeholder="machineCount" name="machineCount"  value={this.props.preferences.machineCount} disabled={true}/>
            </Form.Field>
            <Form.Field>
              <label>{ "Contract ID" }</label>
              <Form.Input placeholder="contractId" name="contractId" value={this.props.preferences.contractId} disabled={true}/>
            </Form.Field>
            <Form.Field error={errors['ipFilter'] && errors['ipFilter']['flag']}>
              <label>{ "IP Filter" }</label>
              <Form.Input placeholder="ipFilter" name="ipFilter" onChange={this.handleInput} onFocus={this.handleFocus} defaultValue={this.props.preferences.ipFilter}/>
            </Form.Field>
            <Form.Field>
              <label>{ "Valid Time" }</label>
            </Form.Field>
          </Form>
          <div className="license-key-expiredtime">
            <DatePicker
              className={errors['validFrom'] ?"licensekey-date-error" :"search-tenants-item"}
              onChange={date => {this.clearDateMsg('validFrom');this.props.onTimeChange(date, 'validFrom')}}
              value={this.getDate('validFrom')}
              format="y/MM/dd"
            />
          </div>
          <Form>
            <Form.Field>
              <label>{ "Expired Time" }</label>
            </Form.Field>
          </Form>
          <div className="license-key-expiredtime">
            <DatePicker
              className={errors['expiredTime'] ?"licensekey-date-error" :"search-tenants-item"}
              onChange={date => {this.clearDateMsg('expiredTime');this.props.onTimeChange(date, 'expiredTime')}}
              value={this.getDate('expiredTime')}
              format="y/MM/dd"
            />
          </div>
          <Form>
            <Form.Field>
              <label>{ "EndPoint" }</label>
              <Form.Input placeholder="EndPoint" name="easyocrEp" onChange={this.handleInput} onFocus={this.handleFocus} defaultValue={this.props.preferences.easyocrEp}/>
            </Form.Field>
            <Form.Field>
              <label> {"Description"}</label>
              <Form.TextArea placeholder="Brief Description" defaultValue={this.props.preferences.description} name="description" onChange={this.handleInput} onFocus={this.handleFocus} rows="10"/>
            </Form.Field>
            <Button 
              floated='right' 
              onClick={this.handleSave}
              >{intl.get('_common.Save')}</Button>
            <Button 
              floated='right' 
              onClick={() => {
                this.props.toggleEdit(false)
                this.clearErrors({})
                this.clearPreferences()
              }}
              >{intl.get('_common.Cancel')}</Button>
          </Form>
        </MDBModalBody>
      </MDBModal>
    )
  }
}
