import React, { Component } from 'react';
import PdfContainer from './PdfContainer';
import { Loader, Button, Dimmer } from 'semantic-ui-react';
import { TYPES, track2LocalStorage } from '../../../common/PDFTrackUtil';
import api from '../../../api';
import { getCsrfToken } from '../../../common/CookieUtil';

const IMG_CLASS_MAP = {
  2: TYPES.LOAD_PAGE_PL,
  1: TYPES.LOAD_PAGE_BS,
  6: TYPES.LOAD_PAGE_SS,
};

export default class PdfLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: null,
      image: null,
      isLoaded: false,
      error: null,
      ocrFlg: false,
      scrumRecommendationFlg: true,
    };
    // this.pushUpdate = this.pushUpdate.bind(this);
    this.refetch = this.refetch.bind(this);
  }

  async refetch() {
    this.setState({
      isLoaded: false,
      error: null,
      project: null,
      image: null,
    });

    const { projectId, pdfFileId } = this.props;

    try {
      const a = document.createElement('a');
      a.setAttribute(
        'href',
        `/dppapi/predict/pdf/page/bboxes/${projectId}/${this.props.currentPage}`
      );
      const url = new URL(a.href);

      const data = await (await api.get(url)).data;

      if (data.success) {
        track2LocalStorage(
          projectId,
          pdfFileId,
          IMG_CLASS_MAP[data.image.imageClassification] || TYPES.LOAD_PAGE
        );
        this.setState({
          isLoaded: true,
          project: data,
          image: data.image,
        });
      } else {
        this.setState({
          isLoaded: true,
          error: 'loading error!',
        });
      }
      this.handleSurumRecommendation();
    } catch (error) {
      this.setState({
        isLoaded: true,
        error,
      });
    }
  }
  componentDidMount() {
    if (this.props.currentPage) {
      this.refetch();
    }
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.currentPage &&
      parseInt(prevProps.currentPage) !== parseInt(this.props.currentPage)
    ) {
      if(!prevProps.currentPage && this.props.oldCurrentPage){
        if(parseInt(this.props.oldCurrentPage) !== parseInt(this.props.currentPage)){
          this.refetch();
        }
      }else{
        this.refetch();
      }
      
    }
  }
  handleSurumRecommendation = async () => {
    const { metadatas, success } = (await api.get(
      `/dppapi/predict/prediction/metadata/query?projectId=${this.props.projectId
      }&category=fs_prediction`
    )).data;

    if (success) {
      const res = metadatas.find(
        mt => mt.subCategory === 'pdf' && mt.item === 'scrum_recommendation'
      );
      if (res && res.value === '0') {
        this.setState({ scrumRecommendationFlg: false });
      }
    }
  };

  // async pushUpdate(labelData) {
  //   //const { imageId } = this.props.match.params;
  //   const { projectId } = this.props;
  //   const imageId = 1
  //   await fetch(`/dppapi/labeling/${projectId}/images/` + imageId, {
  //     method: 'PATCH',
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ labelData }),
  //   });
  // }
  handelOcr = (update, coordinate) => {
    const { project } = this.state;
    this.getOCRText(project, coordinate).then(v => {
      update(v);
    });
  };
  async getOCRText(project, coordinate) {
    this.setState({
      ocrFlg: true,
    });
    var url = `/dppapi/predict/pdf/ocr/${this.props.projectId}/${this.props.currentPage
      }`;
    if (typeof coordinate === 'object') {
      url += '?coordinate=' + JSON.stringify(coordinate);
    }
    const response = await api.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    var rslt = '';
    try {
      rslt = JSON.parse(await response.text());
    } catch (error) {
      rslt = '解析失敗';
    }
    this.setState({
      ocrFlg: false,
    });
    return rslt;
  }

  handleLoading = (isLoaded) => {
    this.setState({
      isLoaded: !isLoaded
    })
  }
  render() {
    const {
      image,
      project,
      isLoaded,
      error,
      ocrFlg,
      scrumRecommendationFlg,
    } = this.state;
    const {
      selectedCell,
      linking,
      manualInput,
      textMappingRef,
      projectId,
      pdfFileId,
      currentPage,
      onShortKeyPanelVisible,
      onSelectBox,
      onSelectedBBoxChange,
      selectedBBox,
      isEditing,
      thumbnailRef,
      canvasRef,
      muiPopupRef,
      selectFlag,
      handleChangeSelectFlag,
      effectiveHotkeyPage,
    } = this.props;

    const projectInfo = { projectId, pdfFileId, pdfImageId: currentPage };
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return (
        <div className="pdf-container-root">
          <Dimmer active inverted>
            <Loader active inline="centered" className="pdf-container-loader" />
          </Dimmer>
        </div>
      );
    }

    return (
      <div className="pdf-container-root">
        <Button
          id="ga-predict-detail-thumbnail-display"
          className={`pdf-thumbnail-slide`}
          circular
          color="grey"
          icon={`${this.props.showThumbnail ? 'chevron left' : 'chevron right'}`}
          onClick={() => this.props.onShowThumbnail()}
          onMouseOver={() => {
            const dc = document.getElementsByClassName('pdf-thumbnail-root');
            if (dc && dc.length > 0) {
              dc[0].classList.add('pdf-thumbnail-root-hover');
            }
          }}
          onMouseOut={() => {
            const dc = document.getElementsByClassName('pdf-thumbnail-root');
            if (dc && dc.length > 0) {
              dc[0].classList.remove('pdf-thumbnail-root-hover');
            }
          }}
        />
        <PdfContainer
          labels={project.form.formParts}
          labelData={image.labelData.labels || {}}
          imageUrl={`/dppapi${image.link}&token=${getCsrfToken()}`}
          loaderImageType={image.pageClassification}
          // onLabelChange={this.pushUpdate}
          onOcr={this.handelOcr}
          selectedCell={selectedCell}
          textMappingRef={textMappingRef}
          linking={linking}
          manualInput={manualInput}
          projectInfo={projectInfo}
          onShortKeyPanelVisible={onShortKeyPanelVisible}
          onSelectBox={onSelectBox}
          onLoad={this.refetch}
          popupRef={this.props.popupRef}
          onSelectedBBoxChange={onSelectedBBoxChange}
          selectedBBox={selectedBBox}
          isEditing={isEditing}
          ocrFlg={ocrFlg}
          scrumRecommendationFlg={scrumRecommendationFlg}
          leftWidth={this.props.leftWidth}
          thumbnailRef={thumbnailRef}
          isRpaOpen={this.props.isRpaOpen}
          canvasRef={canvasRef}
          handelShowPredictLoading={this.props.handelShowPredictLoading}
          toggleRePredict={this.props.toggleRePredict}
          isSelectTargetBbox={this.props.isSelectTargetBbox}
          handleRePredictSelectBbox={this.props.handleRePredictSelectBbox}
          selectTargetBbox={this.props.selectTargetBbox}
          muiPopupRef={muiPopupRef}
          selectFlag={selectFlag}
          handleChangeSelectFlag={handleChangeSelectFlag}
          effectiveHotkeyPage={effectiveHotkeyPage}
          handleLoading={this.handleLoading}
          handleUnuseImage={this.props.handleUnuseImage}
          isNotOwner={this.props.isNotOwner}
          onShowExclusionControl={this.props.onShowExclusionControl}
          onShowUpdateErrorDialog={this.props.onShowUpdateErrorDialog}
          onSetHeaderInfo={this.props.onSetHeaderInfo}
          pdfInfo={this.props.pdfInfo}
          currentPage={currentPage}
        />
      </div>
    );
  }
}
