import React from 'react';
import * as d3 from 'd3';

export default class Sunburst extends React.Component {

  componentDidMount = () => {
    this.renderChart();
  }

  renderChart = () => {
    const { data, className } = this.props;
    var root, node;
    root = d3.stratify().id((d) => { return d.idx; }).parentId((d) => { return d.parent; })(data);
    var gWidth = 400,   // Width of the svg palette
      gHeight = 400,   // Height of the svg palette
      radius = (Math.min(gWidth, gHeight) / 2) - 10, // linear or grouped, based on radiobuttons
      svg = d3
        .select('.' + className).append("g").attr("id", "bigG").attr("transform", "translate(" + gWidth / 2 + "," + (gHeight / 2) + ")"),
      color_palettes = ['#f03b20', '#feb24c', '#ffeda0','#007849', '#0375b4', '#ffce00', '#dcd0c0', '#c0b283','#e37222', '#07889b', '#eeaa7b'];
    var x = d3.scaleLinear().range([0, 2 * Math.PI]),
      y = d3.scaleLinear().range([0, radius]), //scaleSqrt
      color = d3.scaleLinear().domain([0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]).range(color_palettes),
      partition = d3.partition();

    // Calculate the d path for each slice.
    var arc = d3.arc()
      .startAngle(function (d) { return Math.max(0, Math.min(2 * Math.PI, x(d.x0))); })
      .endAngle(function (d) { return Math.max(0, Math.min(2 * Math.PI, x(d.x1))); })
      .innerRadius(function (d) { return Math.max(0, y(d.y0)); })
      .outerRadius(function (d) { return Math.max(0, y(d.y1)); });


    // Build the sunburst.
    var first_build = true;
    function update() {
      // Determine how to size the slices.
      root.sum(function (d) { return d.size; });

      if (first_build) {
        // Add a <path d="[shape]" style="fill: [color];"><title>[popup text]</title></path>
        //   to each <g> element; add click handler; save slice widths for tweening
        var gSlices = svg.selectAll("g")
          .data(partition(root)
          .descendants(), function (d) { return d.data.id; })
          .enter()
          .append("g");
        gSlices.exit().remove();
        gSlices.append("path")
          .style("fill", function (d) { return d.parent ? color(d.x0) : "white"; })
          .filter(function (d) {
            return d.id && d.id!== null;
          })
          .on("click", (e, d) => click(d))
          .on("mouseover", (e, d) => mouseover(d))
          .append("title")
          .text(function (d) { return d.data.name; });  // Return white for root.
        gSlices.selectAll('path')
          .filter(function (d) {
            return !d.id || d.id === "";
          })
          .on("mouseover", (e, d) => mouseover(d))
          .append("title").text(function (d) { return d.data.name; }); 
        gSlices.append("text")
          .attr("dy", ".35em")
          .text( function (d) { 
            if (d.data.name.length < 9) {
              return d.data.name
            } 
          })
          .attr("id", function (d) { return "w" + d.data.name; });
        gSlices.filter(function (d) {
          return d.parent === null && className === 'Labeled';
        }).select("text")
          .attr("dy", ".35em")
          .attr("dx", "-1.35em")
         // TODO: was d.data.word
        svg.selectAll("path")
          .append("title")
          .text(function (d) { return d.data.word; })

        first_build = false;
      } else {
        svg.selectAll("path").data(partition(root).descendants());
      }

      svg.selectAll("path").transition("update")
        .duration(750)
        .attrTween("d", function (d, i) {
        return arcTweenPath(d, i);
      });
      svg.selectAll("text")
        .filter(function (d) {
          return d.parent !== null;
        })
        .transition("update")
        .duration(750)
        .attrTween("transform", function (d, i) { return arcTweenText(d, i); })
        .attr('text-anchor', function (d) { return d.textAngle > 180 ? "start" : "end"; })
        .attr("dx", function (d) { return d.textAngle > 180 ? -18 : 18; })
        .attr("opacity", function (e) { return e.x1 - e.x0 > 0.01 ? 1 : 0; });
    }

    // Respond to slice click.
    function click(d) {
      node = d;
      svg.selectAll("path")
        .transition("click")
        .duration(750)
        .attrTween("d", function (d, i) { return arcTweenPath(d, i) });
      svg.selectAll("text")
        .filter(function (d) {
          return d.parent !== null;
        })
        .transition("click")
        .duration(750)
        .attrTween("transform", function (d, i) { return arcTweenText(d, i) })
        .attr('text-anchor', function (d) { return d.textAngle > 180 ? "start" : "end" })
        .attr("dx", function (d) { return d.textAngle > 180 ? -18 : 18 })
        .attr("opacity", function (e) {
          if (e.data.parent === d.id || d.id === className || d.id === e.id) {
            return (e.x1 - e.x0 > 0.01 ? 1 : 0);
          } else {
            return 0;
          }
        })
    }

    var b = { w: 250, h: 30, s: 3, t: 10 }

    function mouseover(d) {
      if (!d.parent) return;
      d3.select('#trail_'+className)
        .selectAll('g')
        .remove();
      
      d3.select('#trail_'+className)
      .selectAll('text')
      .remove();

      d3.select('#trail_'+className)
        .append('g')
        .append('polygon')
        .attr("points", (d, i) => breadcrumbPoints(d, i))
        .style("fill", color(d.x0))

      d3.select('#trail_'+className)
      .append('text')
      .attr("x", (b.w + b.t) / 2)
      .attr("y", b.h / 2)
      .attr("dy", "0.35em")
      .attr("text-anchor", "middle")
      .text(d.data.name)

      d3.select('#value_'+className)
      .text(d.data.value)
    }

    function breadcrumbPoints(d, i) {
      var points = [];
      points.push("0,0");
      points.push(b.w + ",0");
      points.push(b.w + b.t + "," + (b.h / 2));
      points.push(b.w + "," + b.h);
      points.push("0," + b.h);
      if (i > 0) { // Leftmost breadcrumb; don't include 6th vertex.
        points.push(b.t + "," + (b.h / 2));
      }
      return points.join(" ");
    }

    // When switching data: interpolate the arcs in data space.
    //$("#w1Jo").attr("transform").substring(10,$("#w1Jo").attr("transform").search(",")) 
    function arcTweenText(a, i) {

      var oi = d3.interpolate({ x0: (a.x0s ? a.x0s : 0), x1: (a.x1s ? a.x1s : 0), y0: (a.y0s ? a.y0s : 0), y1: (a.y1s ? a.y1s : 0) }, a);
      function tween(t) {
        var b = oi(t);
        var ang = ((x((b.x0 + b.x1) / 2) - Math.PI / 2) / Math.PI * 180);
        b.textAngle = (ang > 90) ? 180 + ang : ang;
        a.centroid = arc.centroid(b);
        return "translate(" + arc.centroid(b) + ")rotate(" + b.textAngle + ")";
      }
      return tween;
    }

    // When switching data: interpolate the arcs in data space.
    function arcTweenPath(a, i) {
      // (a.x0s ? a.x0s : 0) -- grab the prev saved x0 or set to 0 (for 1st time through)
      // avoids the stash() and allows the sunburst to grow into being
      var oi = d3.interpolate({ x0: (a.x0s ? a.x0s : 0), x1: (a.x1s ? a.x1s : 0), y0: (a.y0s ? a.y0s : 0), y1: (a.y1s ? a.y1s : 0) }, a);
      function tween(t) {
        var b = oi(t);
        a.x0s = b.x0;
        a.x1s = b.x1;
        a.y0s = b.y0;
        a.y1s = b.y1;
        return arc(b);
      }
      if (i === 0 && node) {  // If we are on the first arc, adjust the x domain to match the root node at the current zoom level.
        var xd = d3.interpolate(x.domain(), [node.x0, node.x1]);
        var yd = d3.interpolate(y.domain(), [node.y0, 1]);
        var yr = d3.interpolate(y.range(), [node.y0 ? 40 : 0, radius]);

        return function (t) {
          x.domain(xd(t));
          y.domain(yd(t)).range(yr(t));
          return tween(t);
        };
      } else {
        return tween;
      }
    }
    update();
  }




  render() {
    const { className, marginLeft } = this.props;

    return (
      <div style={{ marginLeft: marginLeft, height: '550px' }}>
        <div style={{ width: '400px', height: '50px', display: 'flex' }}>
          <svg id={'trail_'+className} style={{ width: '280px', height: '50px'}}></svg>
          <span id={'value_'+className}>
          </span>
        </div>
        <svg className={className} style={{ width: '400px', height: '400px' }}>

        </svg>
      </div>
    )
  }
}
