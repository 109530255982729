// Half-width converted to full-width
export function halfToFull(txtstring) { 
  var tmp = ""; 
  for (var i = 0;i < txtstring.length; i++ ) { 
      if (txtstring.charCodeAt(i) === 32) { 
        tmp += String.fromCharCode(12288); 
      } 
      if (txtstring.charCodeAt(i) > 33 && txtstring.charCodeAt(i) < 127) { 
        tmp += String.fromCharCode(txtstring.charCodeAt(i) + 65248); 
      } 
      else {
        tmp += String.fromCharCode(txtstring.charCodeAt(i)); 
      }
  }
  kanaHanZenTbl.forEach((data) => {
    tmp = tmp.replace(data[0], data[1])
  });
  return tmp; 
}

// Convert full-width to half-width
export function fullToHalf(str) { 
  var tmp = ""; 
  for (var i = 0; i < str.length; i++) { 
      if (str.charCodeAt(i) === 12288){
        tmp += String.fromCharCode(str.charCodeAt(i) - 12256);
        continue;
      }
      if (str.charCodeAt(i) > 65280 && str.charCodeAt(i) < 65375) { 
        tmp += String.fromCharCode(str.charCodeAt(i) - 65248); 
      } 
      else { 
        tmp += String.fromCharCode(str.charCodeAt(i)); 
      } 
  }
  kanaHanZenTbl.forEach((data) => {
    tmp = tmp.replace(data[1], data[0])
  });
  return tmp; 
}

const kanaHanZenTbl = [
  ["ｶﾞ", "ガ"], ["ｷﾞ", "ギ"], ["ｸﾞ", "グ"], ["ｹﾞ", "ゲ"], ["ｺﾞ", "ゴ"],
  ["ｻﾞ", "ザ"], ["ｼﾞ", "ジ"], ["ｽﾞ", "ズ"], ["ｾﾞ", "ゼ"], ["ｿﾞ", "ゾ"],
  ["ﾀﾞ", "ダ"], ["ﾁﾞ", "ヂ"], ["ﾂﾞ", "ヅ"], ["ﾃﾞ", "デ"], ["ﾄﾞ", "ド"],
  ["ﾊﾞ", "バ"], ["ﾋﾞ", "ビ"], ["ﾌﾞ", "ブ"], ["ﾍﾞ", "ベ"], ["ﾎﾞ", "ボ"],
  ["ﾊﾟ", "パ"], ["ﾋﾟ", "ピ"], ["ﾌﾟ", "プ"], ["ﾍﾟ", "ペ"], ["ﾎﾟ", "ポ"],
  ["ｳﾞ", "ヴ"],
  ["ｱ", "ア"], ["ｲ", "イ"], ["ｳ", "ウ"], ["ｴ", "エ"], ["ｵ", "オ"],
  ["ｶ", "カ"], ["ｷ", "キ"], ["ｸ", "ク"], ["ｹ", "ケ"], ["ｺ", "コ"],
  ["ｻ", "サ"], ["ｼ", "シ"], ["ｽ", "ス"], ["ｾ", "セ"], ["ｿ", "ソ"],
  ["ﾀ", "タ"], ["ﾁ", "チ"], ["ﾂ", "ツ"], ["ﾃ", "テ"], ["ﾄ", "ト"],
  ["ﾅ", "ナ"], ["ﾆ", "ニ"], ["ﾇ", "ヌ"], ["ﾈ", "ネ"], ["ﾉ", "ノ"],
  ["ﾊ", "ハ"], ["ﾋ", "ヒ"], ["ﾌ", "フ"], ["ﾍ", "ヘ"], ["ﾎ", "ホ"],
  ["ﾏ", "マ"], ["ﾐ", "ミ"], ["ﾑ", "ム"], ["ﾒ", "メ"], ["ﾓ", "モ"],
  ["ﾔ", "ヤ"], ["ﾕ", "ユ"], ["ﾖ", "ヨ"], ["ﾗ", "ラ"], ["ﾘ", "リ"],
  ["ﾙ", "ル"], ["ﾚ", "レ"], ["ﾛ", "ロ"], ["ﾜ", "ワ"], ["ｦ", "ヲ"],
  ["ﾝ", "ン"], ["ｧ", "ァ"], ["ｨ", "ィ"], ["ｩ", "ゥ"], ["ｪ", "ェ"],
  ["ｫ", "ォ"], ["ｬ", "ャ"], ["ｭ", "ュ"], ["ｮ", "ョ"], ["ｯ", "ッ"],
  ["｡", "。"], ["｢", "「"], ["｣", "」"], ["､", "、"], ["･", "・"],
  ["ｰ", "ー"], ["", ""], ["\t", "　"], ["\n", "　"], [" ", "　"],
  ["/", "／"],
];

export function filterCharacters(str, filterPattern) {

  // 販管費・製造原価・売上原価 filter 対象　：　words = ['(製)', '[製]', '［製］', '【製】', '〔製〕', '(原)', '営)']
  // str = str.replace(/(\(製\)|【製】|\[製\]|〔製〕|営\)|\(原\))/, '');
  str = str.replace(new RegExp(filterPattern, 'g'), '')

  // ------------------- !!! retain !!! ---------------------------
  // # ascii characters a-z 0x0061-0x007a
  // # ascii characters A-Z 0x0041-0x005a
  // # full width Hiragana: 0x3041 -- 0x3096
  // # full width Katakana: 0x30A0 -- 0x30FF
  // # All CJK kanji: 0x3400 -- 0x4DB5, 0x4E00 -- 0x9FCB, 0xF900 -- 0xFA6A
  // ------------------- !!! retain !!! --------------------------

  // # full width latin characters: 0xff0d -- 0xff5a
  // # Half width katakana: 0xff66 -- 0xff9d
  // # Kanji Radicals: 0x2E80 -- 0x2FD5
  // # Japanese Symboles and Characters: 0x31F0 -- 0x31FF, 0x3220 -- 0x 3243, 0x3280 -- 0x337F
  // # half width (: 0x28
  // # half width ): 0x29
  // # half width ~: 0x7e
  // # character 々: 0x3005
  // # japanese point ･ : 0xff65
  const FILTER_CHAR_REG = /[^\u0041-\u005a\u0061-\u007a\u3041-\u3096\u30a1-\u30ff\u3400-\u4db5\u4e00-\u9fcb\uf900-\ufa6a\u3005]/g;
  return str.replace(FILTER_CHAR_REG, "");
}