import React, { useEffect, useState, useRef } from 'react';
import { Button, Message, Loader, Dimmer, Popup, Input, Icon, Select } from 'semantic-ui-react';
import intl from 'react-intl-universal';
import api from '../../../api';
import { cryptoDecrypt } from '../../../common/EncryptUtil';
import Slide from '@material-ui/core/Slide';
import FsMappingReviewTable from './FsMappingReviewTable';
import moment from 'moment';
import { ReactComponent as ReactSendLogo } from '../../../assets/icon/svg/send_white.svg';

const activeStyle = {
  color: 'var(--selecting_blue, #0072C6) !important',
  background: 'var(--slecting_blue_hovered, #E6EBFA) !important'
}

export default function FsMappingReview({ projectId, pdfId, detailData, onRowSelect, pdfInfo, handleFilterScrumData, currentPage }) {
  const [dataList, setDataList] = useState([]);
  const [filterDataList, setFilterDataList] = useState({ 0: [], 1: [] });
  const [checkedAll, setCheckedAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectId, setSelectedId] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [tab, setTab] = useState(0);
  const [toolsHeight, setToolsHeight] = useState(84);
  const [sendMsgShow, setSendMsgShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectType, setSelectType] = useState(0);
  const [lastEditedName, setLastEditedName] = useState("");
  const toolsRef = useRef(null);
  const infoRef = useRef(null);

  const user = JSON.parse(cryptoDecrypt(localStorage.getItem("user")));

  useEffect(() => {
    let height = 0;
    if (infoRef.current) {
      height += infoRef.current.offsetHeight;
    }
    if (toolsRef.current) {
      height += toolsRef.current.offsetHeight;
    }
    setToolsHeight(height);
  })

  //database データ収集
  const getDatabaseFsList = async () => {
    let databaseFsList = [];
    setIsLoading(true);
    try {
      const { data: { fsList } } = await api.get(`/dppapi/predict/fs-item-review/${projectId}/${pdfId}/query`);
      databaseFsList = fsList.filter(item => !!item.scrumItem);
    } catch (error) {
      setError(error.data.errmsg);
    } finally {
      setIsLoading(false);
    }

    return databaseFsList;
  }

  //fsMapping データ収集
  const getFsMappingList = async (list) => {
    let fsMapping = [];
    if (list.length === 0) {
      return [];
    }
    setIsLoading(true);

    const params = list.map(item => ({
      key1: item.subTitle.text,
      key2: item.item.text,
      projectId: projectId,
      category: item.pageName,
    }))

    try {
      const { data } = await api.post(`/dppapi/predict/fs-item-mapping/${projectId}/query`, { list: params })
      fsMapping = data.scrum_field_mappings;
    } catch (error) {
      setError(error.data.errmsg);
    } finally {
      setIsLoading(false);
    }
    return fsMapping;
  }

  //データ収集
  const handleDataList = async () => {
    let body = [];
    if (detailData) {
      body = handleFilterScrumData(detailData, pdfId);
    }

    const images = pdfInfo.images;
    const dataBaseFsList = await getDatabaseFsList();
    let scrumsWithOrder = [];

    //対象のデータ構造を取得する
    for (let scrum of body) {
      //シリリアル号を有むデータを取得する
      const order = images.find(image => (image.id === scrum.pdfImageId && image.pdfFileId === scrum.pdfId)).image_order;
      //現在のデータ項目がデータベースに存在するかどうかを確認します
      const rslt = dataBaseFsList.find(fs =>
        fs.pdfFileId === scrum.pdfId &&
        fs.category === scrum.pageName &&
        fs.item === scrum.item.text &&
        fs.subTitle === scrum.subTitle.text &&
        fs.pageNum === order &&
        fs.scrumItem === scrum.scrumItem
      );

      if (!rslt) {
        scrumsWithOrder.push({ ...scrum, pageNum: order, status: 0, stateFlag: 0 });
      }
    }

    for (let db of dataBaseFsList) {
      const rslt = body.find(scrum =>
        db.pdfFileId === scrum.pdfId &&
        db.category === scrum.pageName &&
        db.subTitle === scrum.subTitle.text &&
        db.item === scrum.item.text
      );

      if (rslt) {
        scrumsWithOrder.push({ ...rslt, pageNum: db.pageNum, status: 1, scrumItem: db.scrumItem, stateFlag: db.stateFlag });
      } else {
        scrumsWithOrder.push({
          item: { text: db.item },
          originalItem: db.originalItem,
          pageName: db.category,
          pageNum: db.pageNum,
          pdfId: db.pdfFileId,
          pdfImageId: db.pdfImageId,
          scrumItem: db.scrumItem,
          stateFlag: db.stateFlag,
          status: 1,
          subTitle: { text: db.subTitle },
        });
      }
    }

    const fsMapping = await getFsMappingList(scrumsWithOrder);
    //データの scrumItem がすでに最新の場合はデータセットを削除します
    if (fsMapping.length > 0) {
      let ids = [];
      for (let scrum of scrumsWithOrder) {
        const rslt = fsMapping.find(fs =>
          scrum.pageName === fs.category &&
          scrum.subTitle.text === fs.key1 &&
          scrum.item.text === fs.key2
        )
        if (rslt) {
          if (scrum.originalItem !== rslt.scrum_field_name) {
            scrum.originalItem = rslt.scrum_field_name;
          }

          if (scrum.scrumItem === rslt.scrum_field_name && scrum.status === 0) {
            ids.push(scrum.id);
          }
        }
      }
      scrumsWithOrder = scrumsWithOrder.filter(scrum => !ids.includes(scrum.id));
    }
    setDataList(scrumsWithOrder);
    //データをフィルタリングする
    setFilterDataList({
      0: scrumsWithOrder.filter(item => item.status === 0),
      1: scrumsWithOrder.filter(item => item.status === 1)
    });
  }

  useEffect(() => {
    if (pdfInfo.lastEditedId) {
      api.get(`/dppapi/predict/fs-item-mapping/user/${pdfInfo.lastEditedId}`).then(res => setLastEditedName(res.data.name))
    }

    handleDataList();
  }, [])

  //ファジークエリ
  const handleChangeSearch = (dataList, value) => {
    const searchList = dataList.filter(row => {
      return (row.pageName && row.pageName.indexOf(value) !== -1) ||
        (row.subTitle && row.subTitle.text.indexOf(value) !== -1) ||
        (row.originalItem && row.originalItem.indexOf(value) !== -1) ||
        (row.item && row.item.text.indexOf(value) !== -1) ||
        (row.scrumItem && row.scrumItem.indexOf(value) !== -1) ||
        (row.pageNum === Number(value));
    });
    return searchList;
  }

  useEffect(() => {
    let operateList = [];
    if (dataList.length === 0) return;

    const text = searchText.trim();
    //入力によるフィルター
    if (!!text) {
      operateList = handleChangeSearch(dataList, text)
    } else {
      operateList = dataList;
    }

    //タイプでフィルタリングする
    if (!!selectType) {
      const pageName = options.find(option => option.value === selectType).text;
      operateList = operateList.filter(item => item.pageName === pageName);
    }

    setFilterDataList({
      ...filterDataList,
      [tab]: operateList.filter(item => item.status === tab)
    });
  }, [tab, selectType, searchText])

  //初期化
  const handleClear = () => {
    setSelectedIds([]);
    setCheckedAll(false);
    setSelectedId();
    // clear selected cell
    onRowSelect('', currentPage, [], '');
  }

  //データを送信する
  const handleSubmit = async () => {
    let data = dataList.filter(item => selectedIds.includes(item.id) && item.status === 0);
    const params = data.map(item => ({
      item: item.item.text,
      pdfImageId: item.pdfImageId,
      pdfFileId: item.pdfId,
      scrumItem: item.scrumItem,
      originalItem: item.originalItem,
      subTitle: item.subTitle.text,
      category: item.pageName,
      createdById: user.id,
      createdByName: user.name,
      link: pdfInfo.link,
      pageNum: item.pageNum
    }))

    try {
      await api.post(`/dppapi/predict/fs-item-review/${projectId}`, { scrumMaps: JSON.stringify(params) })
      await handleDataList();
      setSendMsgShow(true);
      handleClear();
      setSearchText("");
      setSelectType(0);
    } catch (error) {
      setError(error.data.errmsg);
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setSendMsgShow(false);
      }, 4000)
    }
  }

  const options = [
    { value: 0, text: 'すべての分類' },
    { value: 1, text: '貸借対照表' },
    { value: 2, text: '損益計算書' },
    { value: 3, text: '製造原価明細表' },
    { value: 4, text: '販売費及び一般管理費' },
    { value: 5, text: '株主資本等変動計算書' },
  ];

  const getLength = (list, tab) => {
    return list.filter(item => item.status === tab).length;
  }

  return (
    <div className="mapping-request-wrap">
      <div className="mapping-request-container-wrap">
        {isLoading && <Dimmer active inverted>
          <Loader active inline="centered" />
        </Dimmer>}
        {!!error && <Message negative className="fs-message">{error}</Message>}
        <div className="mapping-request-title-wrap" ref={infoRef}>
          <div className="mapping-request-title-wrap-left">
            <div className="mapping-request-title">{intl.get('_predict.detail.review.Title')}</div>
            <Popup
              on='hover'
              className="mapping-request-popup"
              position='bottom center'
              trigger={<Icon name='question circle' />}
            >
              <div className="mapping-request-popup-content">
                <h1>自動連携先の更新依頼</h1>
                <div>
                  手動で補正された項目の中から、更新が必要である可能性が高いものを表示しています。<br />
                  更新を希望するものを選択し、更新依頼を送信してください。
                </div>
                <ul>
                  <li>誤字があるものは選択しないでください</li>
                  <li>更新依頼を送信後、管理者が適用することで更新されます</li>
                  <li>対応状況は[自動連携先更新]から確認できます</li>
                </ul>
              </div>
            </Popup>
          </div>
          <div className="mapping-request-title-wrap-right">
            <div className="mapping-request-label">ID:{pdfId}</div>
            <div>
              <div className="mapping-request-text">{pdfInfo.originalName}</div>
              <div className="mapping-request-text">最終保存:{moment(pdfInfo.lastEdited).format("YYYY年MM月DD日 HH:mm")}<span>｜</span>{lastEditedName || pdfInfo.lastEditedName}</div>
            </div>
          </div>
        </div>
        <div className="mapping-request-table-wrap">
          <div className="mapping-request-table-tools" ref={toolsRef}>
            <div className="mapping-request-table-tools-left">
              <Button.Group basic>
                <Button style={tab === 0 ? activeStyle : {}} onClick={() => { setTab(0); handleClear(); }}>{intl.get('_predict.detail.review.Tab1')}<span>({getLength(dataList, 0)}件)</span></Button>
                <Button style={tab === 1 ? activeStyle : {}} onClick={() => { setTab(1); handleClear(); }}>{intl.get('_predict.detail.review.Tab2')}<span>({getLength(dataList, 1)}件)</span></Button>
              </Button.Group>
              <Select
                options={options}
                value={selectType}
                onChange={(e, { value }) => { setSelectType(value); handleClear(); }}
                fluid
              />
            </div>
            <div className="mapping-request-table-tools-input">
              <Input value={searchText} icon placeholder="検索" onChange={e => { setSearchText(e.target.value); handleClear(); }}>
                <input />
                {searchText.length === 0 ? (
                  <Icon name="search" />
                ) : (
                  <Icon name="remove" link onClick={() => setSearchText("")} />
                )}
              </Input>
            </div>
          </div>
          <div className='mapping-request-table' style={{ height: `calc(100vh - ${toolsHeight + 133}px)` }}>
            {!isLoading && <FsMappingReviewTable
              dataList={filterDataList[tab]}
              selectId={selectId}
              setSelectedId={setSelectedId}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              checkedAll={checkedAll}
              setCheckedAll={setCheckedAll}
              onRowSelect={onRowSelect}
              tab={tab}
            />}
          </div>
        </div>
      </div>
      <div className="mapping-request-buttons-wrapper">
        <Button size="mini" icon onClick={() => handleSubmit()}
          disabled={selectedIds.length === 0}
          className={selectedIds.length === 0 ? "primary-btn-disabled" : "primary-btn"}>
          <ReactSendLogo style={{ marginRight: '8px', verticalAlign: '-3px' }} />{selectedIds.length}{intl.get('_predict.detail.review.Submit update request')}
        </Button>
      </div>
      {sendMsgShow && <Slide direction="up" in={sendMsgShow}><div className="mapping-request-file-setting-success">更新依頼を送信しました</div></Slide>}
    </div>
  )
}

