import React from 'react';

import { MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact';
import {
  Button,
  Form,
  Dropdown,
  Message
} from 'semantic-ui-react';
import api from '../../../api';
import intl from 'react-intl-universal';

export default class UsersEdit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      roles: [],
      user: {},
      errors: {}
    }

    this.handleInput = this.handleInput.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.checkForm = this.checkForm.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.clearErrors = this.clearErrors.bind(this)
    this.clearUsers = this.clearUsers.bind(this)
    this.getRoles = this.getRoles.bind(this)
  }

  async componentWillReceiveProps() {
    this.getRoles()
  }

  getRoles() {
    const roles = this.props.roles

    this.setState({
      roles: roles.map(role => {
        return {
          key: role.id,
          value: role.id,
          text: role.name
        }
      })
    })
  }

  handleInput(event) {
    const {name, value} = event.target
    const user = {...this.state.user}
    user[name] = value
    this.setState({
      user: user
    })
  }

  handleChange(event, {name, value}) {
    const user = {...this.state.user}
    user[name] = value
    this.setState({
      user: user
    })
  }

  handleFocus() {
    this.clearErrors()
  }

  clearErrors() {
    this.setState({
      errors: {}
    })
  }

  clearUsers() {
    this.setState({
      user: {}
    })
  }

  async handleSave() {
    const user = Object.assign({}, this.props.user, this.state.user)
    const tenantId = this.props.tenantId
    if(!this.checkForm(user)) {
      return
    }
    try {
      await api.request({
        url: `/dppapi/system/user/${tenantId}/${this.props.user.id ? this.props.user.id : ''}`,
        method: this.props.user.id ? 'PUT' : 'POST',
        data: user
      })

      this.props.getUsers()
      this.props.toggleEdit(false)
      this.clearErrors()
      this.clearUsers()
    } catch(e) {
      // handle error
      const { msg } = e.data || {}
      this.setState({
        errors: msg
      })
    }
  }

  checkForm(user) {
    const errors = {}
    if (!user.name) {
      errors['name'] = {content: 'Please enter a user name.', pointing: 'below', flag: true}
    }

    // eslint-disable-next-line 
    const emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
    if (!emailReg.test(user.email)) {
      errors['email'] = {content: 'Please enter a valid email.', pointing: 'below', flag: true}
    }

    // if (!user.pwd) {
    //   errors['pwd'] = {content: 'Please enter a valid password.', pointing: 'below', flag: true}
    // }

    if (!user.roleId) {
      errors['roleId'] = {content: 'Please select a role by the user.', pointing: 'below', flag: true}
    }
    
    this.setState({
      errors: errors
    })
    return Object.keys(errors).length === 0
  }

  render() {
    const { user } = this.props
    const { errors } = this.state
    return (
      <MDBModal isOpen={this.props.isShowEdit} toggle={this.props.toggleEdit}>
        <MDBModalHeader>
          {
            this.props.user && this.props.user.id 
            ? intl.get('_system.tenants.user.User.Edit User')
            : intl.get('_system.tenants.user.User.Add User')
          }
        </MDBModalHeader>
        <MDBModalBody>
        {
          Object.keys(errors).length > 0 &&
          <Message
            error
            list={ Object.keys(errors).map(i => {
              return errors[i].content
            })}
          />
        }
          <Form>
          <Form.Field error={errors['oktaAccount'] && errors['oktaAccount']['flag']}>
              <label>{intl.get('_system.tenants.user.User.Account')}</label>
              <Form.Input placeholder="OktaAccount" defaultValue={user.oktaAccount} name="oktaAccount" onChange={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            <Form.Field error={errors['name'] && errors['name']['flag']}>
              <label>{intl.get('_system.tenants.user.User.Name')}</label>
              <Form.Input placeholder="Name" defaultValue={user.name} name="name" onChange={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            <Form.Field error={errors['email'] && errors['email']['flag']}>
              <label>{intl.get('_system.tenants.user.User.E-mail')}</label>
              <Form.Input placeholder="E-mail" defaultValue={user.email} name="email" onChange={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            {
            !this.props.user.id &&
            <Form.Field error={errors['pwd'] && errors['pwd']['flag']}>
              <label>{intl.get('_system.tenants.user.User.Password')}</label>
              <Form.Input type='password' placeholder="Password" defaultValue={user.pwd} name="pwd" onInpvut={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            }
            {
            // !this.props.user.id &&
            <Form.Field error={errors['accessKey'] && errors['accessKey']['flag']}>
              <label>{intl.get('_system.tenants.user.User.Access Key')}</label>
              <Form.Input placeholder="Access Key" defaultValue={user.accessKey} name="accessKey" onInput={this.handleInput} onFocus={this.handleFocus}/>
            </Form.Field>
            }
            <Form.Field error={errors['roleId'] && errors['roleId']['flag']}>
              <label>{intl.get('_system.tenants.user.User.Role')}</label>
              <Dropdown 
                placeholder='Select a role'
                options={this.state.roles} 
                fluid
                search
                selection
                defaultValue={user.roleId}
                name='roleId'
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                />
            </Form.Field>
            {
            this.props.user.id &&
            <Form.Field error={errors['status'] && errors['status']['flag']}>
              <label>{intl.get('_system.tenants.user.User.Status')}</label>
              <Dropdown 
                placeholder='Select a status'
                options={[{key: 0, value: 0, text: 'Available'}, {key: 1, value: 1, text: 'Locked'}]} 
                fluid
                search
                selection
                defaultValue={user.status}
                name='status'
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                />
            </Form.Field>
            }
            <Button floated='right' onClick={this.handleSave}>{intl.get('_common.Save')}</Button>
            <Button floated='right' onClick={() => {this.props.toggleEdit(false); this.clearErrors();this.clearUsers();}}>{intl.get('_common.Cancel')}</Button>
          </Form>
        </MDBModalBody>
      </MDBModal>
    );
  }
}
