import React, { Component } from 'react';
import { MDBDataTable } from 'mdbreact';
import { Button, Segment } from 'semantic-ui-react';
import { confirmAlert } from 'react-confirm-alert';
import api from '../../api'
import intl from 'react-intl-universal';
import moment from "moment";

class MachineTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      rows: []
    }

    this.getMachines = this.getMachines.bind(this)
    this.handleDel = this.handleDel.bind(this)
  }

  async componentDidMount() {
    await this.getMachines()
  }

  async getMachines() {
    const allMachines = (await api.get('/dppapi/system/monitor/licensekeys/machine')).data
    this.setState({
      rows: allMachines.map((machine, _)=> {
        return {
          operation: (
            <div style={{width: 160}}>
              <Button
                icon="trash"
                label={intl.get('_system.machines.Machines.Delete')}
                size="tiny"
                onClick={() => this.handleDel(machine)}
              />
            </div>
          ),
          id: machine.id,
          createdAt: moment.utc(machine.createdAt).local().format('YYYY-MM-DD HH:mm:ss'),
          easyocrVersion: machine.easyocrVersion,
          ipAddress: machine.ipAddress,
          licenseKeyId: machine.licenseKeyId,
          macAddress: machine.macAddress,
          osInfo: machine.osInfo,
        }
      }).sort((l1, l2) => {
        return l1.keyId - l2.keyId
      })
    })
  }

  handleDel(machine) {
    confirmAlert({
      title: intl.get('_system.preferences.Preferences.Delete this record'),
      message: intl.get('_system.preferences.Preferences.Are you sure to do this?'),
      buttons: [
        {
          label: intl.get('_system.preferences.Preferences.Delete'),
          onClick: async () => {
            await api.delete(`/dppapi/system/monitor/licensekeys/machine/${machine.id}`)
            this.getMachines()
          },
        },
        {
          label: intl.get('_system.preferences.Preferences.Cancel'),
        },
      ],
    })
  }

  render() {
    const { rows } = this.state;
    const data = {
      columns: [
        {
          label: intl.get('_system.machines.Machines.ID'),
          field: 'id',
          sort: 'asc'
        },
        {
          label: intl.get('_system.machines.Machines.LicenseKeyID'),
          field: 'licenseKeyId',
          sort: 'asc'
        },
        {
          label: intl.get('_system.machines.Machines.OSInfo'),
          field: 'osInfo',
          sort: 'asc',
        },
        {
          label: intl.get('_system.machines.Machines.EasyOCRVersion'),
          field: 'easyocrVersion',
          sort: 'asc',
        },
        {
          label: intl.get('_system.machines.Machines.MACAddress'),
          field: 'macAddress',
          sort: 'asc',
        },
        {
          label: intl.get('_system.machines.Machines.IPAddress'),
          field: 'ipAddress',
          sort: 'asc',
        },
        {
          label: intl.get('_system.machines.Machines.CreatedAt'),
          field: 'createdAt',
          sort: 'asc',
        },
        {
          label: intl.get('_system.machines.Machines.Operation'),
          field: 'operation',
          sort: 'asc',
        },
      ],
      rows: rows,
    };

    return (
      <Segment>
        <MDBDataTable theadColor="common-search-table-header" btn striped bordered hover data={data} />
      </Segment>
    );
  }
}

export default MachineTable;
