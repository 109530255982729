import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import './ProjectApp.css';
import Menubar from '../common/Menubar';
import ProjectTab from './ProjectTab'
import ProjectMenu from './ProjectMenu';

class ProjectApp extends Component {
  render() {
    return (
      <Menubar active="project">
        <Switch>
          <Route
            exact
            path="/project/"
            component={ProjectTab}
          />
          <Route path="/project/:projectId" component={ProjectMenu} />
        </Switch>
      </Menubar>
    );
  }
}

export default ProjectApp;
