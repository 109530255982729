export function filterTab(tabs, policy) {
  
  if (!(policy instanceof Array) || policy.length === 0) return tabs

  const allowTabs = []
  const denyTabs = []
  tabs.forEach(tab => { 
    policy.forEach(p => { 
      if ((`${p.obj}/${p.sub_obj}` === tab.key && p.act === 'read' && p.eft === 'deny') ){ 
        denyTabs.push(tab.key)
      }
    })
  })
  tabs.forEach(tab => { 
    if (denyTabs.indexOf(tab.key) === -1) { 
      allowTabs.push(tab) 
    }
  })
  return allowTabs
}
