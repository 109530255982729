import React, { Component } from 'react'
import LicenseKeyTable from './LicenseKeyTable'
import LicenseKeySearch from './Search'
import {toUnixMS, FMT} from '../../common/TimeUtil';
import moment from "moment";
import api from '../../api'

class LicenseKey extends Component {

  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      init: true,
      isLoading: false,
      isShowEdit: false,
      editAudits: {},
      searchParam: null,
      type: null,
      easyocr: null,
      condition: {
        tenantId : 1,
      },
    }

    this.onSearch = this.onSearch.bind(this)
  }

  async onSearch(data) {
    const { 
      createdStartDate, createdEndDate,validStartDate,validEndDate, expiredStartDate, expiredEndDate, searchId, tenantId,contractId, licenseKey, endpoint
    } = data ? data : this.state.condition;
    const createdStartDateMS = createdStartDate ? toUnixMS(moment(createdStartDate).format(FMT.DATE)) : '';
    const createdEndDateMS = createdEndDate ? toUnixMS(moment(createdEndDate).add(1, 'd').format(FMT.DATE)): '';
    const validStartDateMS = validStartDate ? toUnixMS(moment(validStartDate).format(FMT.DATE)) : '';
    const validEndDateMS = validEndDate ? toUnixMS(moment(validEndDate).add(1, 'd').format(FMT.DATE)): '';
    const expiredStartDateMS = expiredStartDate ? toUnixMS(moment(expiredStartDate).format(FMT.DATE)) : '';
    const expiredEndDateMS = expiredEndDate ? toUnixMS(moment(expiredEndDate).add(1, 'd').format(FMT.DATE)): '';

    var url = `tenantId=${tenantId}`;
    if(searchId){
      url = url + `&searchId=${searchId}`;
    }
    if(contractId){
      url = url + `&contractId=${contractId}`;
    }
    if(licenseKey){
      url = url + `&licenseKey=${licenseKey}`;
    }
    if(endpoint){
      url = url + `&endpoint=${endpoint}`;
    }
    if(createdStartDateMS){
      url = url + `&createdStartDate=${createdStartDateMS}`;
    }
    if(createdEndDateMS){
      url = url + `&createdEndDate=${createdEndDateMS}`;
    }
    if(validStartDateMS){
      url = url + `&validStartDate=${validStartDateMS}`;
    }
    if(validEndDateMS){
      url = url + `&validEndDate=${validEndDateMS}`;
    }
    if(expiredStartDateMS){
      url = url + `&expiredStartDate=${expiredStartDateMS}`;
    }
    if(expiredEndDateMS){
      url = url + `&expiredEndDate=${expiredEndDateMS}`;
    }
    const searchParam = url;
    url = `/dppapi/system/monitor/licensekeys?${url}`;
    const licenseDatas = (await api.get(url)).data
    this.setState({licenseDatas, condition: data ? data : this.state.condition, searchParam,})
  }

  render() {
    const { licenseDatas } = this.state;
    return (
      <React.Fragment>
        <div style={{ display: 'flex' }}>
          <div className="ui" style={{ flex: 1 }}>
            <LicenseKeySearch 
              onSearch={this.onSearch}
            />
            {licenseDatas 
            ? (<LicenseKeyTable licenseDatas={licenseDatas} searchParam={this.state.searchParam} reload={this.onSearch}/>)
            : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LicenseKey
