import React, { Component } from 'react';
import api from '../../api';
import {
  Button,
  Dropdown,
  Form,
  Icon,
  Loader,
} from 'semantic-ui-react';
import { downloadCsv } from '../../predict/Util';

class BulkExport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tenants: [],
      selectedTenant: '',
    };
  }

  async componentDidMount() {
    const tenants = (await api.get(`/dppapi/system/tenant`)).data;
    this.setState({
      tenants: tenants.map(data => {
        return { text: data.name, key: data.id, value: data.id }
      })
    })
  }

  handleExportPreset = async () => {
    const { selectedTenant, tenants } = this.state;
    try {
      this.setState({ loading: true });
      const { data } = await api.get(`/dppapi/system/tenant-item-bulk-export-preset?tenantId=${selectedTenant}`);
      const fsItems = data.list;
      if (fsItems && fsItems.length > 0) {
        const tenantName = tenants && tenants.find(d => d.value === selectedTenant) ? tenants.find(d => d.value === selectedTenant).text : '';
        downloadCsv(
          fsItems.map((item) => [
            item.subTitle,
            item.item,
            item.category,
            item.fsItem,
            item.classification
          ]),
          { header: false },
          `Tenant_${tenantName}_Fs_Items_${new Date().getTime()}.csv`
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleExportConvertSubtitle = async () => {
    const { selectedTenant, tenants } = this.state;
    try {
      this.setState({ loading: true });
      const { data } = await api.get(`/dppapi/system/tenant-item-bulk-export-convert-subtitle?tenantId=${selectedTenant}`);
      const items = data.list;
      if (items && items.length > 0) {
        const tenantName = tenants && tenants.find(d => d.value === selectedTenant) ? tenants.find(d => d.value === selectedTenant).text : '';
        downloadCsv(
          items.map((item) => [item.subtitleName, item.convertSubtitle, item.category]),
          { header: false },
          `Tenant_${tenantName}_読替表設定_${new Date().getTime()}.csv`
        );
      }
    } catch (e) {
      console.error(e);
    } finally { 
      this.setState({ loading: false });
    } 
  };

  render() {
    const { tenants, selectedTenant, loading } = this.state;
    const { exportFlag } = this.props;
    const tenantName = tenants && tenants.find(d => d.value === selectedTenant) ? tenants.find(d => d.value === selectedTenant).text : '';
    return (
      <div className="ui" style={{minHeight: '300px'}}>
        {loading
          ? (<Loader active inline="centered" />)
          : (<Form>
              <Form.Field style={{ paddingTop: '25px' }}>
                <Dropdown
                  placeholder='Select Tenant'
                  options={tenants}
                  value={selectedTenant}
                  search
                  selection
                  clearable
                  onChange={(event, { value }) =>
                    this.setState({
                      selectedTenant: value
                    })
                  }
                  style={{ width: 'auto' }}
                />
              </Form.Field>
              <Form.Field>
                <Button
                  onClick={() => {
                    if (exportFlag === 'preset') {
                      this.handleExportPreset();
                    } else if (exportFlag === 'convertSubtitle') {
                      this.handleExportConvertSubtitle();
                    }
                  }}
                  basic={true}
                  color="black"
                  disabled={tenantName === ''}
                >
                  <Icon name="sign-out" />
                  Export
                </Button>
              </Form.Field>
            </Form>)
        }

      </div>
    );
  }
}

export default BulkExport;
